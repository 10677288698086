import React, { Component }                                           from 'react';
import { KirokuCheckBox, KirokuInput, KirokuSectionDropdownShowMore } from '../../../../../../ui';
import { connect }                                                    from 'react-redux';
import { getGroupedOptions }                                          from '../../../../NewRecords/serviceNewRecords';
import { withNamespaces }                                             from 'react-i18next';
import _                                                              from 'lodash';

@connect(state => {
    return {
        teams          : state.KirokuAITeam.teamsBeta,
        teamSelected   : state.KirokuModal.ModalSelectStaffs.teamSelected,
        staffsSelected : state.KirokuModal.ModalSelectStaffs.staffsSelected,
        keywordSelected: state.KirokuModal.ModalSelectStaffs.keywordSelected,

    };
}, dispatch => {
    return {
        handleChangeCheckboxStaff   : (index) => {
            dispatch({
                type: 'HANDLE_CHANGE_CHECKBOX_STAFF_SELECTED',
                index,
            });
        },
        handleChangeKeywords        : (keywordSelected) => {
            dispatch({
                type: 'CHANGE_KEYWORD_SEARCH_STAFF_SELECTED',
                keywordSelected,
            });

        },
        handleSearchTeam            : (teamSelected) => {
            dispatch({
                type: 'HANDLE_SEARCH_TEAM_SELECTED_STAFFS',
                teamSelected,
            });
        },
        handleSearchAllStaffSelected: () => {
            dispatch({
                type: 'HANDLE_SELECT_ALL_STAFF_SELECTED',
            });
        },
    };
})

@withNamespaces()

class StaffSelectedTab extends Component {
    render() {
        const { staffsSelected, teamSelected, teams, t, keywordSelected } = this.props;
        return (
            <div>
                <div className={ 'pt-2 pb-2' }>
                    <div className={'set-height-42'}>
                        <KirokuSectionDropdownShowMore
                            tagType={ 'Team' }
                            placeholder={ t('RecordsBeta.refineByTeam') }
                            options={ getGroupedOptions('TeamId', teams) }
                            value={ teamSelected }
                            onChange={ (teamSelected) => this.props.handleSearchTeam(teamSelected) }
                        />
                    </div>
                </div>
                <div className={ 'pt-2' } style={{paddingBottom: 35}}>
                    <KirokuInput
                        style={{ height : 42, fontSize: 16}}
                        onChange={ (e) => this.props.handleChangeKeywords(e.target.value) }
                        placeholder={ t('RecordsBeta.keyword') }
                        value={ keywordSelected }
                    />
                </div>
                { staffsSelected.length ?
                    <div>
                        <div className={ 'background-color' }
                             style={ { overflow: 'scroll',
                                 minHeight: 'calc(100vh - 450px)',
                                 maxHeight: 'calc(100vh - 450px)',
                                 height: 'auto' } }>
                            <div className={ 'border-bottom pb-3' }>
                                <div className={ 'checkbox-bigger p-3' }>
                                    <KirokuCheckBox onChange={ () => this.props.handleSearchAllStaffSelected() }
                                                    checked={ _.findIndex(staffsSelected, (staff) => !staff.selected) === -1 }
                                    />
                                </div>
                            </div>
                            { staffsSelected.map((staff, i) => {
                                return (
                                    <div className={ 'border-bottom' } key={ i }>
                                        <div className={ 'checkbox-bigger p-3' }>
                                            <KirokuCheckBox content={<span className={'font-text-in-checkbox'}
                                                                           style={{wordBreak: 'break-word'}}
                                                            >{staff.name}</span>}
                                                            onChange={ () => this.props.handleChangeCheckboxStaff(i) }
                                                            checked={ staff.selected }
                                                            id={ `${ staff.id }-${ i }` }/>
                                        </div>
                                    </div>
                                );
                            }) }
                        </div>
                    </div> : <div className={ 'background-color d-flex' }
                                  style={{fontSize: '16px', height: 'auto', minHeight: 'calc(100vh - 450px)'}}>
                        <div style={{ margin: 'auto'}}>
                            {t('RecordsBeta.noStaffSelected')}
                        </div></div> }

            </div>
        );
    }
}

export default StaffSelectedTab;
