import React, {Component} from "react";
import moment             from 'moment';

export default class KirokuAge extends Component {

    getAge = (dob) => {
        if (dob) {
            return moment().diff(dob.toString(), 'years', false);
        }
        return ''
    };

    render() {
        const {dob} = this.props;
        return (
            <span>{this.getAge(dob)}</span>
        );
    }
}
