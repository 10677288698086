import _ from 'lodash';

class QuestionsService {

    rebuildRecordFromOldData(records) {
            return records.map((record) => {
                let isOlaData = _.filter(record.survey_template.questions, (question) => {
                    return (question.type === 'Single' || question.type === 'MultiChoice') && (typeof question.choices[0]) === 'string';
                });
                if (!!isOlaData.length) {
                    return {
                        ...record,
                        survey_template: {
                            ...record.survey_template,
                            questions : this.rebuildQuestionsFromOldData(record.survey_template.questions)
                        }
                    }
                }

                return record;

            })
    }

    rebuildQuestionsFromOldData(questions) {
            return questions.map((question, index) => {
                switch (question.type) {
                    case 'Single' :
                        return {
                            ...question,
                            choices: question.choices.map(choice => ({
                                answer : choice
                            })),
                            index : index,
                            answers : {
                                answer : question.answers,
                                is_choice : _.findIndex(question.choices, choice => choice === question.answers)
                            }
                        };
                    case 'MultiChoice':
                        return {
                            ...question,
                            choices: question.choices.map(choice => ({
                                answer : choice
                            })),
                            index : index,
                            answers : question.choices.map(answer => ({
                                answer : answer,
                                is_choice : _.includes(questions.answers, answer)
                            }))
                        };
                    default :
                        return {
                            ...question,
                            index : index
                        };
                }
            });
    }

    makeAnswerSingle(questions) {
        return questions.map(question => {
            if ((question.type === 'Single' || question.type === 'MultiChoice') && (typeof question.choices[0]) === 'string') {
                return {
                    ...question,
                    choices: question.choices.map(choice => ({
                        answer: choice
                    }))
                }
            }
            return question
        });
    }

    makeUpAnswerWith(questions) {
        return questions.map(question => {
            if (question.type === 'MultiChoice') {
                const answers = question.choices.map((item, index) => (
                    {answer: item.answer, is_choice: item.answer === question.answers[index]}));
                return {...question, answers}
            }
            return question
        });
    }


    getQuestion(action) {
        const flowedQuestions = action.record.survey_template.flowedQuestions;
        const template = {
            ...action.record.survey_template,
            flowedQuestions:  flowedQuestions ? flowedQuestions : this.makeUpDataIsQuestionOldQuestion(action.record.survey_template.questions)
        };
        return {
            ...action.record,
            survey_template: template
        };
    }

    makeUpDataIsQuestionOldQuestion(questions) {
        return questions.map(question => {
            if(question.type === 'Media' && typeof question.answers === 'string') {
                return {
                    ...question,
                    answers: {
                        answer: question.answers,
                        typeAnswer: question.typeAnswer
                    }
                }

            }


            if(question.type === 'MultiChoice' &&  typeof question.answers[0] === 'string' && typeof question.choices[0] === 'string') {
                let arrayIsChoiced = question.choices.map(item => question.answers.includes(item));
                let newAnswer = question.choices.map((item, index) => {
                    return ({
                        answer: item,
                        is_choice: arrayIsChoiced[index]
                    })
                });

                let newChoices = question.choices.map(choice => {return {answer: choice, is_choice: false}});
                return {...question, answers: newAnswer, choices: newChoices}
            }

            if (question.type === 'MultiChoice' && typeof question.answers[0] === 'string') {
                let arrayIsChoiced = question.choices.map(item => question.answers.includes(item.answer));
                let newAnswer = question.choices.map((item, index) => {
                    return ({
                        answer: item.answer,
                        is_choice: arrayIsChoiced[index]
                    })
                });
                return {...question, answers: newAnswer}
            }


            if(question.type === 'Single' && typeof question.answers === 'string' && typeof question.choices[0] === 'string') {
                let indexAnswerChoice;
                for(let i = 0; i < question.choices.length; i++) {
                    if(question.choices[i].toString() === question.answers.toString()) {
                            indexAnswerChoice = i;
                    }
                }

                let newAnswer = {
                    answer: question.answers,
                    is_choice: indexAnswerChoice
                };

                let newChoice = question.choices.map(choice => {
                    return {
                        answer: choice
                    }
                });
                return {
                    ...question,
                    answers: newAnswer,
                    choices: newChoice
                }
            }


            return question
        });
    }


}

export default QuestionsService;
