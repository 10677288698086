import React from "react";

export let getGroupedOptions = (groupItemsKey, groupedOptions) => {
    if(!groupedOptions.length) {
        return [];
    }
    return groupedOptions.map(tmp => {
        switch (groupItemsKey) {
            case 'TemplateId' :
                return {
                    key: `${groupItemsKey}_${tmp.id}`,
                    value: `${tmp.title}${tmp.id}`,
                    label: tmp.title,
                    type: 'Surveys',
                    surveyId: tmp.id,
                    templateId: tmp.id
                };
            case 'StaffId' :
                return {
                    ...tmp,
                    key    : `${groupItemsKey}_${tmp.id}`,
                    value  : `${tmp.name}${tmp.id}`,
                    label  : tmp.name,
                    staffId: tmp.id,
                    type   : 'Users'
                };
            case 'CustomerId' :
                return {
                    key: `${groupItemsKey}_${tmp.id}`,
                    value: `${tmp.name}${tmp.id}`,
                    label: tmp.name,
                    customerId: tmp.id,
                    tagType: 'Customer',
                    type   : 'Customers'
                };
            case 'TeamId' :
                return {
                    key      : `${groupItemsKey}_${tmp.id}`,
                    value    : `${tmp.name}${tmp.id}`,
                    label    : tmp.name,
                    teamId   : tmp.id,
                    customers: tmp.customers,
                    type     : 'Teams',
                    users    : tmp.users

                };
            default :
                return {
                    key  : `default_${tmp.id}`,
                    value: `default${tmp.id}`,
                    label: tmp.title,
                }
        }
    });
};


export const setSizePagination = [
    {
        id   : 1,
        name : '10',
        value: 10
    },
    {
        id   : 2,
        name : '25',
        value: 25
    },
    {
        id   : 3,
        name : '50',
        value: 50
    },
    {
        id   : 4,
        name : '100',
        value: 100
    }
];

export const OptionsSizePaginations = (valuePaginations) => {
    return valuePaginations.map(pagination => ({
        value    : pagination.value,
        label    : pagination.name,
        component: (<div><span className={'text-muted'}>{pagination.name}</span></div>)
    }));
};

export const conditionsDefaultInit = {
    params: {
        pageSize: 10,
        currentPage: 1
    },
    body:  {
        status: 'recorded',
        serviceAt: {
            startDate: null,
            endDate: null,
        },
        customerIds: [],
        templateIds: [],
        orders: [{field: 'records.create_at', type: 'DESC'}]
    }
};

export const conditionDefaultDetailInit = {
    params: {
        pageSize: 10,
        currentPage: 1
    },
    body: {
        status: 'recorded',
        serviceAt: {
            startDate: null,
            endDate: null,
        },
        createdAt: {
            startDate: null,
            endDate: null,
        },
        updatedAt: {
            startDate: null,
            endDate: null,
        },
        customerIds: [],
        templateIds: [],
        staffCreatedByIds: [],
        staffUpdatedByIds: [],
        keywordSearch: "",
        orders: [{field: 'records.create_at', type: 'DESC'}]
    }
};
