import React, { Component } from 'react';
import { Paginator }        from '../../../../ui';
import TableRecords         from './TableRecords';
import { connect }          from 'react-redux';
import { withNamespaces }   from 'react-i18next';
import RecordConditions     from './RecordConditions';
import CustomerSelected     from './CustomerSelected';

@connect(state => {
    return {
        items                     : state.RecordsBeta.items,
        totalPage                 : state.RecordsBeta.totalPage,
        currentPage               : state.RecordsBeta.currentPage,
        recordsBeta               : state.RecordsBeta.recordsBeta,
        orderByUpdateAt           : state.RecordsBeta.orderByUpdateAt,
        sortByServiceAt           : state.RecordsBeta.sortByServiceAt,
        orderByServiceAt          : state.RecordsBeta.orderByServiceAt,
        useSortBy                 : state.RecordsBeta.useSortBy,
        useOrderBy                : state.RecordsBeta.useOrderBy,
        isOpenModalSelectCustomers: state.KirokuModal.ModalSelectCustomers.isOpenModalSelectCustomers,
        typeSearchRead            : state.RecordsBeta.typeSearchRead,
    };
}, dispatch => {
    return {
        changeNumberPage: (currentPage) => {
            dispatch({
                type: 'CHANGE_NUMBER_PAGE_RECORDS',
                currentPage
            })
        },
        changeOrderBy: (sortBy, orderByNew) => {
            dispatch({
                type: 'CHANGE_ORDER_RECORD_BETA',
                sortBy, orderByNew,
            });
        },
        handleChangeInitRecordBeta: () => {
            dispatch({
                type : 'SET_INIT_CONDITION_RECORD_BETA',
            })
        }
    };
})

@withNamespaces()

class RecordsMobile extends Component {

    handleChangePageSize(e) {
        this.props.changeNumberPage(e.kirokuSelected.value);
    }

    handlerOnClickChangePage(e) {
        const {currentPage} = this.props;
        let currentPageNew = e === 'next' ? currentPage + 1 : currentPage -1;
        this.props.changeNumberPage(currentPageNew);
    }

    handleChangeOrderBy(sortBy) {
        const { useSortBy } = this.props;
        if(useSortBy === sortBy) {
            return null;
        }
        this.props.changeOrderBy(sortBy, 'DESC');
    }

    render() {
        const {
                  items,
                  currentPage, t, totalPage,
                  useSortBy, isOpenModalSelectCustomers
              } = this.props;
        return (
            <div className='padding-responsive'>
                <div hidden={isOpenModalSelectCustomers}>
                    <RecordConditions/>
                    <div className="pt-3 d-flex justify-content-between">
                        <div className="color-btn-paginator">
                            <Paginator numberPage={currentPage}
                                       items={items}
                                       onPaginatorChange={this.handleChangePageSize.bind(this)}
                                       onClickNext={this.handlerOnClickChangePage.bind(this, 'next')}
                                       onClickBack={this.handlerOnClickChangePage.bind(this, 'back')}
                                       page={currentPage}
                                       hasNext={currentPage <  totalPage}
                            />
                        </div>
                        <div/>
                        <div className={'pt-2 font-clear-condition-btn pr-1'} onClick={() => this.props.handleChangeInitRecordBeta()}>
                            {t('RecordsBeta.clearCondition')}
                        </div>
                    </div>
                    <div className="pt-3">
                        <div className="d-flex justify-content-center">
                            <div className={`border-width-each-status first-btn font-text-on-btn ${useSortBy === 'serviced_at' ? 'btn-active' : '' }`}
                                 onClick={this.handleChangeOrderBy.bind(this, 'serviced_at')}
                            >
                                { t('RecordsBeta.orderOfServicedAt') }
                            </div>
                            <div className={`border-width-each-status third-btn font-text-on-btn ${useSortBy === 'update_at' ? 'btn-active' : '' }`}
                                 onClick={this.handleChangeOrderBy.bind(this, 'update_at')}
                            >
                                { t('RecordsBeta.orderOfUpdatedAt') }
                            </div>
                        </div>
                    </div>
                    <div className="pt-3">
                        <TableRecords/>
                    </div>
                    <div style={{marginBottom: 80}}>
                        <div className="color-btn-paginator pt-3">
                            <Paginator numberPage={currentPage}
                                       items={items}
                                       location={'top'}
                                       onPaginatorChange={this.handleChangePageSize.bind(this)}
                                       onClickNext={this.handlerOnClickChangePage.bind(this, 'next')}
                                       onClickBack={this.handlerOnClickChangePage.bind(this, 'back')}
                                       page={currentPage}
                                       hasNext={currentPage <  totalPage}
                            />
                        </div>
                    </div>
                </div>
                <div hidden={!isOpenModalSelectCustomers}>
                    <CustomerSelected/>
                </div>
            </div>
        );
    }
}

export default RecordsMobile;
