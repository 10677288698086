import React, {Component} from 'react';
import {connect}          from 'react-redux';
import _                  from 'lodash';
import {withNamespaces}   from 'react-i18next';
import {loginRequired}    from '../../../../middleware-redux/Auth';
import {assignSurveys}    from '../../../../actions/Customer';
import {checkAllSurveys}  from '../../../../actions/Behavior';
import withPermissions    from "../../../HOC/WithPermissions";
import ButtonComponent    from "../../../HOC/ButtonComponent";
import {NOTIFICATION}     from '../../../../actions/Notification';
import {filePathService}  from "../../../../services";
import '../../customers.css';
import {
    KirokuButton,
    KirokuCard,
    KirokuCardList,
    KirokuCardWeb,
    KirokuCheckBox,
    KirokuInputSearch,
    KirokuLoading,
    KirokuModal,
    KirokuModalBody,
    KirokuModalFooter,
    KirokuModalHeader,
}                                             from '../../../../ui';
import TabSurveys                             from "../Web/TabSurveys";
import TabUpload                              from "../Web/TabUpload";
import TabProfileCustomer                     from "../Web/TabProfileCustomer";
import TabRecords                             from "../Web/TabRecords";
import InfoCustomer                           from "../Web/InfoCustomer";

const ButtonAssignSurvey = withPermissions(ButtonComponent);

@withNamespaces()

@loginRequired

@connect(state => {
    return {
        customer: state.KirokuAICustomer.customer,
        documents: state.KirokuAICustomer.documents,
        profile: state.KirokuAISurvey.profile,
        loading: state.Loading,
        loadingResultDocuments: state.KirokuAICustomer.loadingResultDocuments
    }
}, dispatch => {
    return {
        checkAllSurveys: (checked) => {
            dispatch(checkAllSurveys(checked))
        },
        assignSurvey: (customerId, surveyId) => {
            dispatch(assignSurveys(customerId, surveyId))
        },
        notification: (t) => {
            dispatch({
                type: NOTIFICATION,
                typeNotification: 'success',
                message: t('message.AssignSuccess')
            })
        },
    };
})

class CustomerDetailWeb extends Component {

    state = {
        files: [],
        surveyForModal: [],
        surveysAssign: [],
        showSurveys: false,
        showRecords: true,
        showProfile: true,
        showUpLoad: true,
        statusCheck: false,
        show: false,
        checkedSurveys: false,
        loadingImg: false,
        disabledPrev: true,
        disabledNext: false,
        url: '',
        name: '',
    };

    componentWillMount() {
        this.setState({
            surveyForModal: this.props.surveyForModal,
        }, () => {
            this.filterSurvey(this.props.customer, this.state.surveyForModal)
        });
    }

    filterSurvey(customers, surveys) {
        let surveyRecord   = customers.surveys.map(item => item.id);
        let customerRecord = surveys.map(survey => {
            return {
                ...survey,
                isChecked: surveyRecord.includes(survey.id)
            }
        });
        let checked        = _.filter(customerRecord, (survey) => !survey.isChecked);
        this.setState({
            surveyForModal: customerRecord,
            surveysAssign: customerRecord,
            statusCheck: !checked.length
        });
    }

    showSurveys() {
        this.setState({
            showSurveys: false,
            showRecords: true,
            showProfile: true,
            showUpLoad: true
        })
    }

    showRecords() {
        this.setState({
            showSurveys: true,
            showRecords: false,
            showProfile: true,
            showUpLoad: true
        })
    }

    showProfile() {
        this.setState({
            showSurveys: true,
            showRecords: true,
            showProfile: false,
            showUpLoad: true
        })
    }

    showUpLoad() {
        this.setState({
            showSurveys: true,
            showRecords: true,
            showProfile: true,
            showUpLoad: false
        })
    }

    closeModal() {
        this.setState({
            show: !this.state.show
        })
    }

    goBack() {
        this.props.history.goBack()
    }

    onChangeSearch(e) {
        let input   = e.target.value.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
        let surveys = this.state.surveysAssign.filter(survey =>
            survey.title.toLowerCase().search(input.toLowerCase()) !== -1
        );
        let checked = _.filter(surveys, (survey) => !survey.isChecked);
        this.setState({surveyForModal: surveys, statusCheck: !checked.length});
    }

    handleCheckSurveys(surveyId) {
        let {surveyForModal, surveysAssign} = this.state;
        let surveyRecords                   = surveyForModal.map(survey => ({...survey}));
        let survey                          = _.find(surveyRecords, survey => survey.id === surveyId);
        survey.isChecked                    = !survey.isChecked;
        let searchSurveys                   = surveysAssign;
        let surveyAssign                    = _.find(searchSurveys, survey => survey.id === surveyId);
        surveyAssign.isChecked              = !surveyAssign.isChecked;
        let checked                         = _.filter(surveyRecords, (survey) => !survey.isChecked);
        this.setState({
            surveyForModal: surveyRecords,
            surveysAssign: searchSurveys,
            statusCheck: !checked.length
        });
    }

    handleCheckAll() {
        let {surveyForModal, surveysAssign} = this.state;
        let newSurveyRecord                 = surveyForModal.map(survey => ({
            ...survey,
            isChecked: !this.state.statusCheck,
        }));
        let surveyChecked                   = newSurveyRecord.map(item => item.id || []);
        let newSurveysAssign                = surveysAssign.map(survey => {
            if (surveyChecked.includes(survey.id)) {
                return {
                    ...survey,
                    isChecked: !this.state.statusCheck,
                }
            }
            return {
                ...survey
            };
        });
        this.setState({
            surveyForModal: newSurveyRecord,
            surveysAssign: newSurveysAssign,
            statusCheck: !this.state.statusCheck,
        })
    }

    assignSurvey() {
        //get survey assign not survey modal
        let surveyForModal = this.state.surveysAssign.filter(survey => survey.isChecked);
        let customer       = this.props.customer;
        this.props.assignSurvey(customer.id, surveyForModal.map(item => item.id));
        this.props.notification(this.props.t);
        this.setState({
            show: !this.state.show
        })

    }

    isSelectSurvey() {
        return !this.state.surveyForModal.filter(item => item.isChecked).length
    }

    renderCustomerDetail(customer) {
        const {showProfile, showRecords, showSurveys, showUpLoad} = this.state;
        let {t, profile}                                          = this.props;
        return (
            <div className='padding-responsive'>
                <KirokuCardWeb>
                    <div className='p-3 row'>
                        <div className="col-md-3 as">
                            {!this.state.loadingImg ?
                                <div className='customer-detail height-avatar as'
                                     style={{
                                         backgroundImage: `url(${customer.avatar ? customer.avatar : `${filePathService.resolveFilePath('profile-ic.svg')}`})`,
                                         width: 120, height: 120
                                     }}
                                /> : <KirokuLoading/>}
                        </div>
                        <div className='col-md-6'>
                            <InfoCustomer customer={customer} profile={profile}/>
                        </div>
                        <div className='col-md-3 as'>
                            <ButtonAssignSurvey
                                resource-permission="|customerDetail|button-assignSurvey|"
                                color='primary'
                                onClick={this.closeModal.bind(this)}>{t('Customer.AssignSurvey')}
                            </ButtonAssignSurvey>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between pt-10 pl-3' style={{width: 480}}>
                        <div style={{cursor: 'pointer'}} className={`nav-bar ${!showSurveys ? 'active' : ''}`}
                             onClick={this.showSurveys.bind(this)}>{t('common.Survey')}
                        </div>
                        <div style={{cursor: 'pointer'}} className={`nav-bar ${!showRecords ? 'active' : ''}`}
                             onClick={this.showRecords.bind(this)}>{t('common.Records')}
                        </div>
                        <div style={{cursor: 'pointer'}} className={`nav-bar ${!showProfile ? 'active' : ''}`}
                             onClick={this.showProfile.bind(this)}>{t('common.ProfileDetail')}
                        </div>
                        <div style={{cursor: 'pointer'}} className={`nav-bar ${!showUpLoad ? 'active' : ''}`}
                             onClick={this.showUpLoad.bind(this)}>{t('common.UpLoad')}
                        </div>
                    </div>
                </KirokuCardWeb>
            </div>
        )
    }

    render() {
        const {customer, profile, documents, t} = this.props;
        const {surveyForModal, files}           = this.state;
        return (
            <div className='default-background-color'>
                {
                    <div className='customer-detail default-background-color'>
                        <div className='d-flex justify-content-between pt-3 pl-3 pr-3'>
                            <KirokuButton onClick={this.goBack.bind(this)}
                                          color='white'>{t('common.Back')}</KirokuButton>
                        </div>
                        {this.renderCustomerDetail(customer)}
                        <div className='pt-0 padding-responsive'>
                            <TabSurveys
                                profile={profile}
                                customer={customer}
                                closeModal={this.closeModal.bind(this)}
                                hidden={this.state.showSurveys}/>
                            <TabRecords
                                customer={customer}
                                hidden={this.state.showRecords}/>
                            <TabProfileCustomer
                                hidden={this.state.showProfile}
                                customer={customer}/>
                            <TabUpload
                                files={files}
                                documents={documents}
                                customer={customer}
                                id={this.props.id}
                                handleUploadFile={this.props.handleSubmit}
                                hidden={this.state.showUpLoad}/>
                        </div>

                        <KirokuModal show={this.state.show} onCloseModal={this.closeModal.bind(this)}>
                            <KirokuModalHeader>
                                <h6 className="mb-3">{t('CustomerEditor.AssignCustomerToSurvey')}</h6>
                                <KirokuInputSearch onChange={this.onChangeSearch.bind(this)}
                                                   placeholder={t('CustomerEditor.EnterNameSurvey')}/>
                            </KirokuModalHeader>
                            <KirokuModalBody>
                                <div id="body-modal-as">
                                    <KirokuCardList
                                        headerLeft={t('Dashboard.SurveyTemplates')}
                                        headerRight={
                                            <div className='text-primary' style={{cursor: 'pointer'}}>
                                                <span hidden={this.state.statusCheck}
                                                      onClick={this.handleCheckAll.bind(this)}
                                                >{t('common.SelectAll')}</span>
                                                <span hidden={!this.state.statusCheck}
                                                      onClick={this.handleCheckAll.bind(this)}
                                                >{t('common.UnSelect')}</span>
                                            </div>
                                        }
                                    >
                                        <section className="scroll-data-customer">
                                            {surveyForModal.map((survey) => (
                                                <KirokuCard key={survey.id}>
                                                    <KirokuCheckBox
                                                        onChange={this.handleCheckSurveys.bind(this, survey.id)}
                                                        checked={survey.isChecked} content={survey.title}/>
                                                </KirokuCard>
                                            ))}
                                        </section>
                                    </KirokuCardList>
                                </div>
                            </KirokuModalBody>
                            <KirokuModalFooter>
                                <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                                    {t('common.Cancel')}
                                </KirokuButton>
                                <KirokuButton color={'primary'} disabled={this.isSelectSurvey()}
                                              onClick={this.assignSurvey.bind(this)}>
                                    {t('common.Assign')}
                                </KirokuButton>
                            </KirokuModalFooter>
                        </KirokuModal>
                    </div>
                }
            </div>
        )
    }
}

export default CustomerDetailWeb;
