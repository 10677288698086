import React, { Component }                                            from 'react';
import AboutSurvey                                                     from '../../ReviewRecording/AboutSurvey';
import AnswerQuestion                                                  from './AnswerQuestion';
import connect                                                         from 'react-redux/es/connect/connect';
import { createRecord }                                                from '../../../../actions/Record';
import { discardDraftRecoding, loadDraftRecording, saveDraftRecoding } from '../../../../actions/Draft';
import { withNamespaces }                                                       from 'react-i18next';
import {turnOffFlagLoadDraftWeb, turnOfFlagEditingQuestion, turnOnLoadDraftWeb} from '../../../../actions/TakeSurvey';
import moment                 from "moment";
import {recordService}        from "../../../../services";
import {NOTIFICATION}         from "../../../../actions/Notification";
import KiroNotificationBackup
                                                                                from "../../newTakeRecord/RecordEditorFiledAll/KiroNotificationBackup";
import { deleteVisiblyQuestion, getAnswersToQuestion }                          from "../../sevicesInRecord";

@withNamespaces()
@connect(state => {
    return {
        takeSurvey        : state.TakeSurveyWeb,
        flagLoadDraft     : state.TakeSurveyWeb.flagLoadDraft,
        dataDraftRecording: state.KirokuAIDraftRecording.dataDraftRecording,
    }
}, dispatch => {
    return {
        createRecord       : (dataRecord) => {
            dispatch(createRecord(dataRecord))
        },
        saveDraft          : (dataDraft, screen) => {
            dispatch(saveDraftRecoding(dataDraft, screen))
        },
        turnOfFlagLoadDraft: () => {
            dispatch(turnOffFlagLoadDraftWeb())
        },
        turnOnLoadDraftWeb: () => {
            dispatch(turnOnLoadDraftWeb())
        },
        discardDraft: (surveyId, customerId) => {
            dispatch(discardDraftRecoding(surveyId, customerId))
        },
        loadDraft: (surveyId, customerId) => {
            dispatch(loadDraftRecording(surveyId, customerId))
        },
        turnOfFlagEditingQuestion: () => {
            dispatch(turnOfFlagEditingQuestion())
        },
        dispatchNotificationDraft: () => {
            dispatch({
                type: NOTIFICATION,
                typeNotification: 'success',
                message: 'createRecord.SaveDraftSuccess'
            })
        },pageTakeRecord: () => {
            dispatch({
                type: 'TAKE_RECORD_PAGE'
            })
        },
        trackingQuickCreateRecord : (record) => {
            dispatch({
                type : "RECORD_QUICK_CREATED_TRACKING",
                record
            });
        },
        trackingQuickDraftNewRecord : (record) => {
            dispatch({
                type : "NEW_RECORD_QUICK_DRAFTED_TRACKING",
                record
            });
        },
    }
})
class CreateRecordWeb extends Component {

    state = {
        serviced_at: null,
        keyLoadData: null,  //keyLoadData: null(loadRecord) 'statusDraft'(loadDraft) 'backup'(loadDataBackup)
        has_time: false,
    };

    handlerSubmitTakeRecord(flowedQuestions, questions) {
        const {survey, customer} = this.props;
        const surveyTemplate     = {
            ...survey,
            questions: deleteVisiblyQuestion(questions),
            flowedQuestions: deleteVisiblyQuestion(flowedQuestions)
        };

        const dataRecord = {
            surveyTemplate  : surveyTemplate,
            customer        : customer,
            hasDraft        : null,
            draftBy         : null,
            draftedAt       : null,
            serviced_at     : this.state.serviced_at,
            has_time        : this.state.has_time,
            answers         : getAnswersToQuestion(flowedQuestions)
        };
        this.props.createRecord(dataRecord);
        this.props.trackingQuickCreateRecord(dataRecord);
    }

    handlerSaveDraft(flowedQuestions, questions) {
        const {survey, customer} = this.props;
        const surveyTemplate     = {
            ...survey,
            questions: deleteVisiblyQuestion(questions),
            flowedQuestions: deleteVisiblyQuestion(flowedQuestions)
        };
        const statusDataRecord   = {
            surveyTemplate  : surveyTemplate,
            customer        : customer,
            hasDraft        : 'draft',
            draftBy         : JSON.parse(localStorage.getItem('profile')).id,
            draftedAt       : moment().unix(),
            serviced_at     : this.state.serviced_at,
            has_time        : this.state.has_time,
            answers         : getAnswersToQuestion(flowedQuestions)
        };
        recordService.createSurveyRecord(statusDataRecord).then(res => {
                this.props.trackingQuickDraftNewRecord(statusDataRecord);
                this.props.dispatchNotificationDraft();
                window.history.previous = window.location.pathname;
                this.props.pageTakeRecord();
                this.props.history.push(`/records/take-record-after-save-draft/${res.data.id}`);
            }
        ).catch(error => console.log(error));
    }

    componentWillUnmount() {
        this.props.turnOfFlagLoadDraft();
        this.props.turnOfFlagEditingQuestion();
    }

    loadDraftCreateRecord() {
        this.props.turnOnLoadDraftWeb();
        this.setState({
            keyLoadData: 'backup'
        })
    }

    discardDraftCreateRecord() {
        // const {survey, customer} = this.props;
        // this.props.discardDraft(survey.id, customer.id);
    }
    onChangeDate = (serviced_at, hasTime) => {
        this.setState({
            serviced_at: hasTime ? serviced_at : moment.unix(serviced_at).startOf('day').unix(),
            has_time: hasTime
        })
    };
    handleSaveBackupData(dataBackup) {
        const requestSaveDraft = {
            customer : this.props.customer,
            // survey   : this.props.survey,
            dataDraft: dataBackup,
            create_at: moment().unix()
        };
        this.props.saveDraft(requestSaveDraft);
    }

    render() {
        const {customer, survey, surveyRecord,
            flagLoadDraft,
            dataDraftRecording, t
        } = this.props;
        const {keyLoadData} = this.state;

        return (
            <div className='padding-responsive default-background-color'>
                <div className='keeping-about-survey' style={{ top : 70, backgroundColor: '#FFFFFF'}}>
                <KiroNotificationBackup
                    confirm={this.loadDraftCreateRecord.bind(this)}
                    cancel={this.discardDraftCreateRecord.bind(this)}
                    draft={dataDraftRecording}
                />
                <div className='bg-white'>
                    <div className='fix-center'>
                        <div style={{width: '70%'}}>
                            <div id='about-survey' className='pt-4 pb-3'>
                                <AboutSurvey
                                    screen={'takeRecord'}
                                    onChangeDate={this.onChangeDate.bind(this)}
                                    survey={survey}
                                    surveyRecord={surveyRecord}
                                    hasTime={false}
                                    customer={customer}/>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className="survey fix-center" style={{backgroundColor: '#FFFFFF'}}>
                    <div className="pb-3" style={{width:'70%'}}>
                        <div className='info-question'>
                            <div className='content-name-question d-flex'>
                                        <span style={{ wordBreak: 'keep-all' }}
                                              className='title-record-info'>{t('createRecord.Description')}&nbsp;</span>
                                <pre style={{
                                    wordWrap  : 'break-word',
                                    whiteSpace: 'pre-wrap',
                                }}>
                                            {survey.description}
                                        </pre>
                            </div>
                        </div>
                    </div>
                </div>

                <AnswerQuestion survey={survey}
                                history={this.props.history}
                                createRecord={this.handlerSubmitTakeRecord.bind(this)}
                                saveDraft={this.handlerSaveDraft.bind(this)}
                                statusRecord={{
                                    statusDraft: null,
                                    backup: flagLoadDraft ? dataDraftRecording.dataDraft: null
                                }}
                                keyLoadData={keyLoadData}
                                // saveBackupData={this.handleSaveBackupData.bind(this)}
                                saveBackupData={() => {}}
                                customerId={customer.id}
                />
            </div>
        );
    }
}

export default CreateRecordWeb;

