import React, { Component }        from 'react';
import { withNamespaces }          from 'react-i18next';
import KirokuLogicApplicated       from '../../../../../../ui/KirokuLogicApplicated';
import KirokuRadio                 from '../../../../../../ui/KirokuRadio';

@withNamespaces()
class SingleChoice extends Component {

    render() {
        const {question, value, surveyDetail, headerNextQuestion, t} = this.props;

        const is_choice = value.is_choice || value.is_choice === 0 ? value.is_choice : value.index;
        const singleQuestion = question.choices.map(item =>  {
            return {nextQuestion: item.nextQuestion}
        });
        return (
            <div className='content-question-type'>
                <div className='wraper-question none-border-question pt-2'>
                    <div className="background-question">
                        <div className=' d-flex justify-content-between'>
                            <div className=" text-overflow-elip font-heading-questions"> {question.heading ? question.heading : ""}</div>
                            <div hidden={!surveyDetail} className={'float-right'}>
                                <KirokuLogicApplicated padding={'5px 5px 5px 7px'} question={{...question, singleQuestion}}/>
                            </div>
                        </div>
                        <div className='padding-content-question'>
                            <span className='font-content-question'>
                                {question.unrequited ? t('createRecord.option'):''}{question.content}
                            </span>
                        </div>
                    </div>
                    <div className='answer-question size-select-question padding-16-answer-question'>
                        {question.choices.map((choice, index) =>
                            <div key={index}>
                                <KirokuRadio
                                    key={index}
                                    checked={is_choice === index}
                                    readOnly
                                    onChange={() => {}}
                                >
                                    <span className='text-form-area'>{choice.answer}</span>
                                </KirokuRadio>
                                <div
                                    hidden={typeof choice.nextQuestion !== 'number' || !surveyDetail}
                                    className='pl-10 text-overflow-elip text-info'>
                                    <i className='fas fa-arrow-right' style={{paddingTop: 4}}/>
                                    <span> {headerNextQuestion[index]}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default SingleChoice
