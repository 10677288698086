import React, { Component }                                    from 'react';
import KirokuButton                                            from "../../../ui/KirokuButton";
import connect                                                 from "react-redux/es/connect/connect";
import { loadTeam }                                            from "../../../actions/Team";
import { filePathService, makeCustomerService, uploadService } from "../../../services";
import { loadSurveyListPage }                                  from "../../../actions/Survey";
import ModalSelectAssignSurvey                                 from "../ModalSelectAssignSurvey";
import KirokuLoading                                           from "../../../ui/KirokuLoading";
import { withNamespaces }                                      from "react-i18next";
import PersonalInformationWeb                                  from "../CustomerProfile/PersonalInformationWeb";
import KirokuCardWeb                                                 from "../../../ui/KirokuCardWeb";
import CustomerInformationWeb                                        from "./CustomerInformationWeb";
import _                                                             from 'lodash'
// import ModalDraftCustomer                                    from "../ModalDraftCustomer";
import {saveDraft}                                                   from "../../../actions/Customer";
import TagCustomer                                                   from "../TagCustomer";
import Guardianship                                                  from "../Guardianship";
import Certification                                                 from "../Certification";
import ContactsEditorWeb                                             from "../ContactsEditorWeb";

@connect(state => {
    return {
        allTeam: state.KirokuAITeam.allTeam,
        draftCustomer: state.KirokuAICustomer.draftCustomer
    }
}, dispatch => {
    return {
        loadTeam: () => dispatch(loadTeam()),
        loadSurveys: () => {
            dispatch(loadSurveyListPage())
        },
        saveDraft: (typeDraft, draft) => {
            dispatch(saveDraft(typeDraft, draft))
        },
        resetStateStore: () => {dispatch({type: 'RESET_STATE'})}
    };
})

@withNamespaces()

class ComponentCustomerEditorWeb extends Component {
    state = {
        customerRecords: {},
        customer: {},
        teams: [],
        loadingImg: false,
        errors: this.props.errors,
        isEdit: false
    };

    componentWillUnmount() {
        this.props.resetStateStore();
    }

    // autoSaveDraft = () => {
    //     const {customerRecords} = this.state;
    //     if (!_.isEmpty(customerRecords)) {
    //         let customer = makeCustomerService.makeCustomerForDraft(customerRecords);
    //         this.props.saveDraft('customer', customer);
    //     } else {
    //         let customer = makeCustomerService.makeCustomerForDraft(this.props.customer);
    //         this.props.saveDraft('customer', customer);
    //     }
    // };


    componentWillMount() {
        this.setState({
            customer: this.props.customer,
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.customer) {
            this.setState({
                customer: nextProps.customer,
            })
        }
    }

    conditionUpload(file) {
        return (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') && file.size < 10485760;
    }

    handleChangeImage() {
        this.setState({
            loadingImg: !this.state.loadingImg && this.inputElement.files[0]
        });
        if (this.inputElement.files[0] && this.conditionUpload(this.inputElement.files[0])) {
            let image = new FormData();
            image.append('file', this.inputElement.files[0]);
            image.append('name', this.inputElement.files[0].name);

            image.append('description', 'some value user types');
            uploadService.uploadImage(image, 'avatar').then((res) => {
                const customer  = this.state.customer;
                customer.avatar = res.url;
                this.setState({
                    customer: customer,
                    loadingImg: !this.state.loadingImg
                }, () => {
                    this.onChangeCustomer(this.state.customer)
                });
            });
        }
    };

    checkError() {
        return !Object.values(this.state.errors).every(item => !item)
    }

    onChangeCustomer(customer) {
        let newsCustomer = Object.assign(this.state.customerRecords, customer);
        this.setState({
            customerRecords: newsCustomer,
            customer: newsCustomer,
            isEdit: true
        })
    }

    handleSubmitCustomer() {
        if (!_.isEmpty(this.state.customerRecords)) {
            let customer = makeCustomerService.makeCustomerForTag(this.state.customerRecords, this.state.isEdit);
            this.props.handleSubmit({
                ...customer,
                certifications: this.state.customer.certifications
            })
        } else {
            let customer = makeCustomerService.makeCustomerForTag(this.state.customer, this.state.isEdit);
            this.props.handleSubmit({
                ...customer,
                certifications: this.state.customer.certifications
            });
        }
    }

    goBack() {
        this.props.history.goBack()
    }

    currentError = (errors) => {
        this.setState({errors: {...this.state.errors, ...errors}})
    };

    addCertification(certification) {
        this.setState({
            customer: {
                ...this.state.customer,
                certifications: this.state.customer.certifications.length ? this.state.customer.certifications.map(itemCertification => {
                    return {
                        ...itemCertification,
                        ...certification
                    }
                }): [certification]
            }
        })
    }

    render() {
        let {t} = this.props;
        return (
            <div className='padding-responsive'>
                <ModalSelectAssignSurvey
                    onChangeCustomer={this.onChangeCustomer.bind(this)}
                    customer={this.state.customer} history={this.props.history}
                />
                <KirokuCardWeb>
                    <div className='p-3 row'>
                        <div className='col-md-8'>
                            <PersonalInformationWeb
                                currentError={this.currentError.bind(this)}
                                customer={this.state.customer}
                                onChangeCustomer={this.onChangeCustomer.bind(this)}/>
                        </div>
                        <div className='fix-center col-md-4 as'>
                            {!this.state.loadingImg ?
                                <div className='customer-detail height-avatar'
                                     style={{backgroundImage: `url(${this.state.customer.avatar ? this.state.customer.avatar : filePathService.resolveFilePath('profile-ic.svg')})`}}
                                /> : <KirokuLoading/>}
                            <div className='mt-24'>
                                <div>
                                    <KirokuButton color={'success'} onClick={() => this.inputElement.click()}>
                                        {t('CustomerEditor.ChangeAvatar')}
                                    </KirokuButton>
                                    <input type='file' ref={input => this.inputElement = input} hidden
                                           accept="image/jpeg, image/png"
                                           onChange={this.handleChangeImage.bind(this)}
                                    />
                                </div>
                            </div>
                            <div className='content-avatar text-center'>
                                <span>{t('Customer.SupportedFile')} </span>
                                <br/>
                                <span>{t('Customer.Maximum')}</span>
                            </div>
                        </div>
                    </div>
                </KirokuCardWeb>

                <div className='mt-10'>
                    <KirokuCardWeb>
                        <div className='p-3 row'>
                            <div className='col-md-8'>
                                <TagCustomer
                                    currentError={this.currentError.bind(this)}
                                    customer={this.state.customer}
                                    teams={this.props.teams}
                                    surveys={this.props.surveys}
                                    users={this.props.users}
                                    onChangeCustomer={this.onChangeCustomer.bind(this)}/>
                            </div>
                            <div className='col-md-4'/>
                        </div>
                    </KirokuCardWeb>
                </div>

                <div className='mt-10'>
                    <KirokuCardWeb>
                        <div className='p-3'>
                            <CustomerInformationWeb
                                currentError={this.currentError.bind(this)}
                                customer={this.state.customer}
                                teams={this.props.teams}
                                users={this.props.users}
                                onChangeCustomer={this.onChangeCustomer.bind(this)}/>
                        </div>
                    </KirokuCardWeb>
                </div>

                <div className='mt-10'>
                    <KirokuCardWeb>
                        <div className='p-3'>
                            <ContactsEditorWeb
                                customer={this.state.customer}
                                onChangeCustomer={this.onChangeCustomer.bind(this)}
                            />
                        </div>
                    </KirokuCardWeb>
                </div>


                <div className='mt-10'>
                    <KirokuCardWeb>
                        <div className='p-3'>
                            <Certification
                                certifications={this.state.customer.certifications}
                                addCertification={this.addCertification.bind(this)}
                                validateDateRanger={(errDate) => this.setState({
                                    errors: {
                                        ...this.state.errors,
                                        errDateCertifications: errDate
                                    }
                                })}
                                validateNumberCertification={error => this.setState({
                                    errors: {
                                        ...this.state.errors,
                                        errNumberCertifications: error
                                    }
                                })}
                            />

                        </div>
                    </KirokuCardWeb>
                </div>

                <div className='mt-10'>
                    <KirokuCardWeb>
                        <div className='p-3'>
                            <Guardianship
                                currentError={this.currentError.bind(this)}
                                customer={this.state.customer}
                                onChangeCustomer={this.onChangeCustomer.bind(this)}
                            />
                        </div>
                    </KirokuCardWeb>
                </div>

                <div className='d-flex justify-content-center mt-24' style={{minHeight: '130px'}}>
                    <div>
                        <KirokuButton onClick={this.goBack.bind(this)} color='white'>
                            {t('common.Cancel')}
                        </KirokuButton>
                    </div>
                    <div className='ml-20'>
                        <KirokuButton
                            disabled={this.checkError()}
                            onClick={this.handleSubmitCustomer.bind(this)}
                            color='primary'>
                            {t('common.Save')}
                        </KirokuButton>
                    </div>

                </div>
            </div>
        );
    }

}

export default ComponentCustomerEditorWeb;
