import React          from 'react';
import {Redirect}     from "react-router-dom";
import {authService}  from '../services';
import jwt            from "jsonwebtoken";
import config         from "../config";
import {NOTIFICATION} from "../actions/Notification";
import * as Sentry    from '@sentry/browser';
import mixpanel, {prepareProfile} from "../services/MixPanel";

export const authMiddleware = (store) => next => action => {

    if (action.type === 'ENABLE_TFA') {
        return authService.enableTFA(action.payload).then((result) => {
            next({
                ...action,
                tfa: result.data
            })
        })
    }

    if (action.type === 'LOGIN') {
        return authService.login(action.credential, action.typeLogin).then((result) => {
            if (result.status === 206) {
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'error',
                    message: `login.${result.data.message}`
                });
                return next({
                    type: 'ERROR_LOGIN',
                    message: result.data,
                    tfa: true
                })
            }
            let profile      = jwt.verify(result.data.token, config.auth.authPrivateKey);
            let line_account = profile['line_account'] ? JSON.parse(profile['line_account']) : profile['line_account'];
            profile          = {
                ...profile,
                line_account
            };
            localStorage.setItem('token', result.data.token);
            localStorage.setItem('profile', JSON.stringify(profile));
            localStorage.setItem('export_csv', JSON.stringify(profile.show_csv) || false);
            //For mixpanel
            prepareProfile(profile);
            //Dispatch Login action
            store.dispatch({
                type: "LOGGED_IN",
                profile
            });
            window.location.replace('/')
        }).catch((err) => {
            if(err.response) {
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'error',
                    message: `login.${err.response.data.message}`
                });
                next({
                    type: 'ERROR_LOGIN',
                    message: err.response.data
                })
            }
        })
    }

    if (action.type === 'LOGOUT') {
        mixpanel.reset();
        return authService.logout().then(() => {
            window.location = '/login'
        });
    }

    return next(action);
};

export const loginRequired = (WrappedComponent) => {
    class Wrapper extends React.PureComponent {
        render() {
            const loggedIn = localStorage.getItem('token');
            const profile  = JSON.parse(localStorage.getItem('profile'));
            if(loggedIn){
                Sentry.configureScope(function(scope) {
                    scope.setUser({
                        username: profile.username,
                        id      : profile.id,
                        organization_id: profile.organization_id,
                        organization_name: profile.organization_name,
                    });
                });
                //For mixpanel
                prepareProfile(profile);
            }

            return loggedIn ?
                <WrappedComponent {...this.props}/> :
                <Redirect to={'/login'}/>
                ;
        }
    }

    return Wrapper;
};
