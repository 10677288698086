import React, {Component} from 'react';
import {connect}          from 'react-redux';
import KirokuButton       from "../../../../../ui/KirokuButton";
import {
    removeQuestion,
    focusQuestion,
    showReview,
    turnModalEditQuestionSurveyTemplate, pushQuestionEditInStore, copyQuestionForTemplate,
}                         from '../../../../../actions/SurveyTemplate';
import {withNamespaces}   from "react-i18next";
import _                  from "lodash"
import { NOTIFICATION }   from '../../../../../actions/Notification';
import KirokuButtonLink   from '../../../../../ui/KirokuButtonLink';

@withNamespaces()
@connect(state => {
    return {
        surveyTemplate : state.KirokuAISurveyTemplate
    }
}, dispatch => {
    return {
        removeQuestion: (question) => {
            dispatch(removeQuestion(question));
        },

        focusQuestion: (index) => {
            dispatch(focusQuestion(index));
        },
        showReview: () => {
            dispatch(showReview());
        },
        turnModalEditQuestionSurveyTemplate: () => {
            dispatch(turnModalEditQuestionSurveyTemplate())
        },
        pushQuestionEditInStore:(editedQuestion) => {
            dispatch(pushQuestionEditInStore(editedQuestion))
        },
        copyQuestionForTemplate : (question) => dispatch(copyQuestionForTemplate(question)),
        notification: (t) =>  dispatch({
            type: NOTIFICATION,
            typeNotification: 'success',
            message:  t('message.questionCopiedSuccess')
        })
    }
})
class BtnAction extends Component {

    state = {
        disabledCopiedBtn : false
    };

    removeQuestion = () => {
        let question = this.props.question;
        this.props.removeQuestion(question);
    };

    showModalAddLogic = () => {
        this.props.showModalAddLogic();
    };

    showModalEdit = () => {
        const {question} = this.props;

        // this.props.showReview();
        // this.props.focusQuestion(question.index);
        //change questions
        let questionCopy = _.cloneDeep(question);
        this.props.turnModalEditQuestionSurveyTemplate();
        this.props.pushQuestionEditInStore(questionCopy);
    };

    disabledButton() {
        const surveyTemplate =  this.props.surveyTemplate;
        return surveyTemplate.questions.length === this.props.question.index + 1
    }

    handleCopyQuestion() {
        const {t, question} = this.props;
        this.props.notification(t);
        this.props.copyQuestionForTemplate(question);
        this.setState({
            disabledCopiedBtn : !this.state.disabledCopiedBtn
        }, () => {
            setTimeout(() => this.setState({
                disabledCopiedBtn  : false
            }), 3000);
        });
    };


    render() {
        const {t, resetAnswer, isHiddenButton} = this.props;
        const hasResetButton = isHiddenButton ? 355 : 410;
        return (
            <div style={{float: 'right'}} className='btn-action-review'>
                <div className='d-flex' style={{width: hasResetButton}}>
                    <div className={'pl-1'} hidden={isHiddenButton}>
                        <KirokuButtonLink onClick={resetAnswer}>{t('createRecord.resetAnswer')}</KirokuButtonLink>
                    </div>
                    <div className={'pl-1'}>
                        <KirokuButton color='success' disabled={this.state.disabledCopiedBtn} onClick={this.handleCopyQuestion.bind(this)}>{t('Surveys.Copy')}</KirokuButton>
                    </div>
                    <div className={'pl-1'}>
                        <KirokuButton disabled={this.disabledButton()} color={'warning'} onClick={this.showModalAddLogic.bind(this)}>{t('createSurvey.AddLogic')}</KirokuButton>
                    </div>
                    <div className={'pl-1'}>
                        <KirokuButton color={'primary'} onClick={this.showModalEdit.bind(this)}>{t('createSurvey.Edit')}</KirokuButton>
                    </div>
                    <div className={'pl-1'}>
                        <KirokuButton color={'danger'} onClick={this.removeQuestion.bind(this)}>{t('createSurvey.Remove')}</KirokuButton>
                    </div>
                </div>
            </div>
        );
    }
}

export default BtnAction;
