import React, {Component}        from 'react';
import {withNamespaces}          from 'react-i18next';
import {
    KirokuCardBodyWeb,
    KirokuCardWeb,
    KirokuCardTitleWeb, KirokuButton,
} from '../../../../ui';
import {checkUseHeadingQuestion} from "../../../Surveys/SurveyEditor/SurveyService";
import {withSmoothScrolling}      from '../../../../ui/Scrolling.js';

@withSmoothScrolling
@withNamespaces()
class Message extends Component {

    componentDidMount() {
        if(!this.props.disabled) {
            this.props.onAnswer({answer: 'message', validated: {}, questionIndex: this.props.question.index});
            this.props.isCurrentQuestion(this.props.question.index);
        }
    }

    moveToNextQuestion() {
        const {question} = this.props;
        let nextToQuestion = typeof question.nextQuestion === 'number' ? question.nextQuestion - 1 : question.index;
        this.props.smoothScroll.scrollTo(`question-${nextToQuestion}`);
        this.props.isCurrentQuestion(nextToQuestion + 1);
    }

    render() {
        const {question, disabled,
                  t, isEndQuestion, currentQuestion} = this.props;
        const contentQuestion = checkUseHeadingQuestion(question);

        return (
            <div id='component-question'>
                <div id='web-q' className='pb-3'>
                    <KirokuCardWeb>
                        <KirokuCardTitleWeb color={disabled ? 'disable-question' :'card-web'}>
                            <div className='number-question '>
                                <div className="question-number text-overflow-elip">
                                    {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                                </div>
                            </div>
                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb>
                            <span className='content-name-question'>{question.content}</span>
                            <div className='pt-2' hidden={ isEndQuestion || disabled || question.index !== currentQuestion}>
                                <KirokuButton className='btn-kiroku-primary' onClick={this.moveToNextQuestion.bind(this)}> {this.props.t('common.nextToQuestion')} </KirokuButton>
                            </div>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                </div>
            </div>
        );
    }
}

export default Message;
