import React, { Component } from 'react';
import {
    KirokuLabel, KirokuHighLightText,
    KirokuCardBodyWeb, KirokuLoading,
    SearchNotFound,
}                           from '../../../../ui';
import { withNamespaces }   from 'react-i18next';
import { connect }          from 'react-redux';
import { Link }             from 'react-router-dom';
import FormatTimestamp      from '../HelperComponents/FormatTimestamp';

@connect(state => {
    return {
        sortByUpdateAt  : state.RecordsBeta.sortByUpdateAt,
        orderByUpdateAt : state.RecordsBeta.orderByUpdateAt,
        sortByServiceAt : state.RecordsBeta.sortByServiceAt,
        orderByServiceAt: state.RecordsBeta.orderByServiceAt,
        useSortBy       : state.RecordsBeta.useSortBy,
        useOrderBy      : state.RecordsBeta.useOrderBy,
        keywordsEd      : state.RecordsBeta.keywordsEd,
        recordsBeta     : state.RecordsBeta.recordsBeta,
        status          : state.RecordsBeta.statusRecord,
        customerIds     : state.RecordsBeta.customerIds,
        servicedAt      : state.RecordsBeta.servicedAt,
        updatedAt       : state.RecordsBeta.updatedAt,
        templateIds     : state.RecordsBeta.templateIds,
        updateByIds     : state.RecordsBeta.updateByIds,
        containRead     : state.RecordsBeta.typeSearchRead === 'ALL',
        loading         : state.Loading,
        isFinish        : state.RecordsBeta.isFinish
    };
}, dispatch => {
    return {
        changeOrderBy: (sortBy, orderByNew) => {
            dispatch({
                type: 'CHANGE_ORDER_RECORD_BETA',
                sortBy, orderByNew,
            });
        },
    };
})
@withNamespaces()
class TableRecord extends Component {

    handleChangeOrderBy(sortBy) {
        const { useOrderBy } = this.props;
        let orderByNew       = useOrderBy === 'ASC' ? 'DESC' : 'ASC';
        this.props.changeOrderBy(sortBy, orderByNew);
    }

    handleCheckRecordRead = (record) => {
        const profile = localStorage.getItem('profile');
        const checkRecordHasRead = record.read_user.split(",").filter((item) => item.length);
        const convertProfile = JSON.parse(profile);
        return checkRecordHasRead.includes(convertProfile.id.toString());
    };

    render() {

        const {
                  t, orderByServiceAt, useSortBy,
                  orderByUpdateAt, keywordsEd,
                  recordsBeta, loading, isFinish
            } = this.props;
        return (
            <div>
                <div className={'table-record-beta background-color'}>
                    <div style={{ overflowX: 'scroll' }}>
                        <div hidden={!recordsBeta.length} className={'max-width-table-record-beta'}>
                            <div className="p-3">
                                <div className='d-flex'>
                                    <div
                                        className=" header-table-record-beta column-draft">{t('RecordsBeta.status')}</div>
                                    <div
                                        className=" header-table-record-beta column-template">{t('RecordsBeta.recordTemplate')}</div>
                                    <div
                                        className=" header-table-record-beta column-customer-name">{t('RecordsBeta.customerName')}</div>
                                    <div
                                        className=" header-table-record-beta column-updater">{t('RecordsBeta.updatedBy')}</div>
                                    <div
                                        className=" header-table-record-beta d-flex cursor-pointer column-service-at"

                                        onClick={this.handleChangeOrderBy.bind(this, 'serviced_at')}
                                    >
                                        <div>{t('RecordsBeta.servicedAt')}</div>
                                        <div className="p-1"/>
                                        <div
                                            className={`icon-order ${useSortBy === 'serviced_at' ? 'color-blue-icon-order' : ''}`}>
                                            <i hidden={orderByServiceAt === 'DESC'} className="fas fa-lg fa-angle-up"/>
                                            <i hidden={orderByServiceAt === 'ASC'} className="fas fa-lg fa-angle-down"/>
                                        </div>
                                    </div>
                                    <div className=" header-table-record-beta d-flex cursor-pointer column-update-at"

                                         onClick={this.handleChangeOrderBy.bind(this, 'update_at')}
                                    >
                                        <div>{t('RecordsBeta.updatedAt')}</div>
                                        <div className="p-1"/>
                                        <div
                                            className={`icon-order ${useSortBy === 'update_at' ? 'color-blue-icon-order' : ''}`}>
                                            <i hidden={orderByUpdateAt === 'DESC'} className="fas fa-lg fa-angle-up"/>
                                            <i hidden={orderByUpdateAt === 'ASC'} className="fas fa-lg fa-angle-down"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className='mb-0'/>
                            <KirokuCardBodyWeb className='card-body p-0'>
                                {
                                    !loading ?
                                        recordsBeta.map((record, index) => {
                                            return (
                                                <Link key={index}
                                                      to={{
                                                          pathname: '/records-beta-detail',
                                                          search  : `id=${record.id}`,
                                                      }} onClick={() => {window.history.previous = window.location.pathname}}>
                                                    <div className={`p-3 
                                                        ${!this.handleCheckRecordRead(record) ? 'border-read-record' : ''}
                                                        ${index % 2 === 0 ? '' : 'bg-light'}`}>
                                                        <div className='d-flex' style={{ width: '100%' }}>
                                                            <div className="column-draft">
                                                                {record[ 'drafted_at' ] ?
                                                                    <div className='label-drafted-at'>
                                                                        <KirokuLabel color={'warning'}
                                                                                     style={{
                                                                                         width: 59, border: 'solid 1px #EA9B3F',
                                                                                         backgroundColor  : '#EA9B3F',
                                                                                     }}>
                                                                            {t('RecordsBeta.draft')}
                                                                        </KirokuLabel>
                                                                    </div> : null}
                                                            </div>
                                                            <div
                                                                className="font-text-records-table column-template"
                                                                style={{ wordBreak: 'break-word', fontWeight: 300, color: '#007BFF' }}>
                                                                {record.survey_template.title}
                                                            </div>
                                                            <div
                                                                className="font-text-records-table column-customer-name"
                                                                style={{ fontWeight: 300, color: '#202528' }}
                                                            >
                                                                {record.customer.name}
                                                            </div>
                                                            <div className="column-updater">
                                                    <span className="font-text-records-table pr-0"
                                                        style={{ fontWeight: 300, color: '#202528' }}>
                                                    {record.lastUpdatedBy.name}</span>
                                                            </div>
                                                            <div className="column-service-at">
                                                                <FormatTimestamp className={'font-text-date-time-records-table'} timestampUnix={record.serviced_at}/>
                                                            </div>
                                                            <div className="column-update-at">
                                                                <FormatTimestamp className='font-text-date-time-records-table'
                                                                    timestampUnix={record[ 'last_action_at' ]}/>
                                                            </div>
                                                        </div>
                                                        <div hidden={!keywordsEd} style={{ color: '#7F8FA4' }}>
                                                            { record.highlight ? <div
                                                                    dangerouslySetInnerHTML={ { __html: record.highlight['answer_search.answer'] }}/> :
                                                                <KirokuHighLightText
                                                                    className={'background-highText-record-beta'}
                                                                    searchWords={[`${keywordsEd}`]}
                                                                    textToHighlight={record[ 'answer_search' ]}
                                                                />}
                                                        </div>
                                                    </div>
                                                </Link>
                                            );
                                        }) : <KirokuLoading/>}
                            </KirokuCardBodyWeb>
                        </div>
                    </div>
                    <div hidden={recordsBeta.length || !isFinish}>
                        <SearchNotFound/>
                    </div>
                    <div hidden={isFinish}>
                        <KirokuLoading/>
                    </div>

                </div>
            </div>

        );
    }
}

export default TableRecord;
