import React, { Component } from "react";
import { KirokuInput }      from "./index";

export default class PostalCodeInput extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        const {onPostalCodeChange, initial} = this.props;
        return (
            <KirokuInput
                defaultValue={initial}
                maxLength={8}

                onKeyDown={e => {
                    const pressedKey = String.fromCharCode(e.which);
                    if (pressedKey.match(/[A-Za-z]/)) {
                        e.preventDefault();
                    }
                }}
                onChange={e => {

                    if (e.target.value.match(/^\d{4,8}$/)) {
                        let secondPart = e.target.value.split('');
                        // console.log(secondPart);
                        let firstPart  = secondPart.splice(0, 3);

                        e.target.value = firstPart.join('') + '-' + secondPart.join('');
                    }

                    if (e.target.value.length === 8 || e.target.value === '') {
                        onPostalCodeChange(e.target.value);
                    }
                }}
            />
        )
    }
}
