import React, {Component}           from 'react'
import {
    KirokuDropdownClose
}                                   from "../../../ui";
import {connect}                    from "react-redux";
import ModalAssignSurveyToCustomer  from "../ModalAssignSurveyToCustomer";
import {
    checkAllSurveys,
    checkSurvey,
    filterSurveyWithCondition,
    resetSurvey,
    deleteListSurvey
}                                   from "../../../actions/Survey";
import {
    loadTeam,
    resetTeam
}                                   from "../../../actions/Team";
import {loadCustomerForSurvey}      from "../../../actions/Customer";
import {withNamespaces}             from "react-i18next";
import KirokuModal                  from "../../../ui/KirokuModal";
import KirokuModalBody              from "../../../ui/KirokuModalBody";
import KirokuModalFooter            from "../../../ui/KirokuModalFooter";
import KirokuButton                 from "../../../ui/KirokuButton";
import {authService, scrollService} from "../../../services";
import withPermissions              from "../../HOC/WithPermissions";

const BulkAction = withPermissions(KirokuDropdownClose);

@withNamespaces()
@connect((state) => {
    return {
        params: state.KirokuAIBehavior.conditionSurveys.params,
    }
}, (dispatch) => {
    return {
        checkAll: (checked) => {
            dispatch(checkAllSurveys(checked))
        },
        surveySelected: (index) => {
            dispatch(checkSurvey(index))
        },
        loadTeam: () => {
            dispatch(loadTeam())
        },
        loadCustomerForSurvey: () => {
            dispatch(loadCustomerForSurvey())
        },

        resetSurvey: () => {
            dispatch(resetSurvey())
        },
        resetTeam: () => {
            dispatch(resetTeam())
        },

        filterSurveyWithCondition: (params) => {
            dispatch(filterSurveyWithCondition(params))
        },

        deleteListSurvey: (listSurvey, params) => {
            dispatch(deleteListSurvey(listSurvey, params))
        }
    }
})

class ModalAssignCustomer extends Component {

    state = {
        checked: false,
        show: false,
        showModalCustomer: false,
        defaultTeams: [],
        disableButtonApply: false,
        showModalDeleteSurvey: false,
        page: 1,
        pageMin: 1,
        disabledPrev: true,
        disabledNext: false,
        surveys: [],
        countSurvey: {}
    };

    bulkAction = () => {
        if (authService.isAdmin()) {
            return [
                {
                    value: 'assign',
                    component: (<div>{this.props.t('Surveys.AssignToUsers')}</div>),
                    label: this.props.t('Surveys.AssignToUsers')
                },
                {
                    value: 'delete',
                    component: (<div>{this.props.t('Surveys.Delete')}</div>),
                    label: this.props.t('Surveys.Delete')
                },
            ]
        }
        return [
            {
                value: 'assign',
                component: (<div>{this.props.t('Surveys.AssignToUsers')}</div>),
                label: this.props.t('Surveys.AssignToUsers')
            },
        ]
    };

    componentWillMount() {
        this.props.loadCustomerForSurvey();
        this.setState({
            currentState: this.props.currentState,
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.surveyChoice.length) {
            let surveys = nextProps.surveyChoice.filter(survey => survey.isChecked);
            this.setState({
                countSurvey: {
                    name: surveys.map(survey => survey.title).join(', '),
                    id: surveys.map(survey => survey.id),
                    count: surveys.length
                },
                surveys: nextProps.surveyChoice
            })
        }

    }

    surveyChoice() {
        let surveyChoice = this.state.surveys.filter(item => item.isChecked);
        this.setState({
            surveys: surveyChoice,
            disableButtonApply: !surveyChoice.length
        })
    }

    handleCheckAll() {
        this.props.checkAll(this.state.checked);
        this.setState({
            checked: !this.state.checked
        })
    }

    closeModal() {
        this.props.resetSurvey();
        this.setState({
            show: !this.state.show,
            checked: false
        })
    }

    closeModalAssignCustomer() {
        this.setState({
            showModalCustomer: !this.state.showModalCustomer,
            checked: false,
        })
    }

    closeModalDeleteSurvey() {
        this.setState({
            showModalDeleteSurvey: false
        })
    }

    deleteCustomer() {
        this.props.deleteListSurvey(this.state.countSurvey.id, this.props.params);
        this.setState({
            showModalDeleteSurvey: !this.state.showModalDeleteSurvey,
            disabledPrev: true,
            disabledNext: false
        })
    }

    increasePage() {
        let nextPage = this.props.currentPage === this.props.totalPage ? this.props.totalPage : this.props.currentPage + 1;
        if (nextPage === this.props.totalPage) {
            this.setState({
                disabledNext: !this.state.disabledNext,
            })
        }
        scrollService.top();
        this.props.disabledCheckbox();
        this.setState({
            page: nextPage,
            disabledPrev: false,
        }, () => {
            const {disabledPrev, disabledNext} = this.state;
            this.props.handleSelectPage(nextPage, {disabledNext, disabledPrev})
        });
    }

    decreasePage() {
        let previousPage = this.props.currentPage === this.state.pageMin ? 1 : this.props.currentPage - 1;
        if (previousPage === this.state.pageMin) {
            this.setState({
                disabledPrev: true
            })
        }
        scrollService.top();
        this.props.disabledCheckbox();
        this.setState({
            page: previousPage,
            disabledNext: false,
        }, () => {
            const {disabledPrev, disabledNext} = this.state;
            this.props.handleSelectPage(previousPage, {disabledNext, disabledPrev})
        });
    }

    isSelect = () => {
        return !this.state.surveys.filter(survey => survey.isChecked).length
    };

    render() {
        let {t, GPS} = this.props;
        return (
            <div>
                <BulkAction
                    title={t('Surveys.BulkAction')}
                    disabled={this.isSelect()}
                    style={{minWidth: 180}}
                    location={GPS ? '' : 'top'}
                    items={this.bulkAction()}
                    onSelect={event => {
                        if (event.kirokuSelected.value === 'assign') {
                            this.surveyChoice();
                            this.setState({
                                showModalCustomer: !this.state.showModalCustomer
                            });
                        } else {
                            this.setState({showModalDeleteSurvey: !this.state.showModalDeleteSurvey})
                        }
                    }}
                    resource-permission={'|surveyList|button-bulkAction|'}
                />
                <ModalAssignSurveyToCustomer
                    surveys={this.state.surveys}
                    disableButtonApply={this.state.disableButtonApply}
                    closeModalAssignCustomer={this.closeModalAssignCustomer.bind(this)}
                    show={this.state.showModalCustomer}/>
                {/*<ModalDeleteSurveys closeModalDeleteSurvey={this.closeModalDeleteSurvey.bind(this)}*/}
                {/*show={this.state.showModalDeleteSurvey}/>*/}
                <KirokuModal show={this.state.showModalDeleteSurvey} onClick={this.closeModal.bind(this)}>
                    <KirokuModalBody>
                        <p className='text-center pt-3'>
                            {t('Surveys.AreYouWantDelete', {
                                count: this.state.countSurvey.count,
                                name: this.state.countSurvey.name
                            })}
                        </p>
                    </KirokuModalBody>
                    <KirokuModalFooter>
                        <KirokuButton onClick={this.closeModalDeleteSurvey.bind(this)} color={'white'}>
                            {t('common.Cancel')}
                        </KirokuButton>
                        <KirokuButton onClick={this.deleteCustomer.bind(this)} color={'primary'}>
                            {t('common.Confirm')}
                        </KirokuButton>
                    </KirokuModalFooter>
                </KirokuModal>
            </div>
        )
    }
}

export default ModalAssignCustomer;
