import React, { Component }         from 'react';
import KirokuRadio                  from '../../../ui/KirokuRadio';
import { withNamespaces }           from 'react-i18next';
import KirokuCardWeb                from '../../../ui/KirokuCardWeb';
import KirokuCardTitleWeb           from '../../../ui/KirokuCardTitleWeb';
import KirokuCardBodyWeb            from '../../../ui/KirokuCardBodyWeb';
import ButtonEditQuestion           from './ButtonEditQuestion';
import PropTypes                    from 'prop-types';
import connect                      from 'react-redux/es/connect/connect';
import { notificationEditQuestion } from '../../../actions/Survey';
import { checkUseHeadingQuestion }  from '../../Surveys/SurveyEditor/SurveyService';
import KirokuButtonLink             from '../../../ui/KirokuButtonLink';

@withNamespaces()
@connect(state => {
    return { takeSurvey: state.TakeSurveyWeb };
}, dispatch => {
    return {
        editQuestion: (flagEdit, indexQuestion) => {
            dispatch(notificationEditQuestion(flagEdit, indexQuestion));
        },
    };
})
class SingleChoice extends Component {

    state = {
        answers  : {
            answer      : this.props.value ? this.props.value.answer : '',
            nextQuestion: this.props.value ? this.props.value.nextQuestion : '',
            is_choice   : this.props.value ? this.props.value.is_choice : '',
        },
        disabled : this.props.disabled,
        flagEdit : false,
        validated: false,
    };

    componentDidMount() {
        this.props.onAnswer({
            answer   : this.props.value,
            validated: this.props.value.answer ? this.props.value : null,
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                answers: {
                    answer      : nextProps.value ? nextProps.value.answer : '',
                    nextQuestion: nextProps.value ? nextProps.value.nextQuestion : '',
                    is_choice   : nextProps.value ? nextProps.value.is_choice : '',
                },
            });
            nextProps.onAnswer({
                answer   : nextProps.value,
                validated: nextProps.value.answer ? nextProps.value : null,
            });
        }

        if (this.props.disabled !== nextProps.disabled || this.props.flagEdit !== nextProps.flagEdit) {
            this.setState({
                disabled: nextProps.disabled,
                flagEdit: nextProps.flagEdit,
            });
        }
    }


    onChangeItem(index, event) {
        const { question } = this.props;
        const answer       = question.choices[ index ];
        this.setState({
            answers  : { ...answer, is_choice: index },
            validated: answer.answer,
        });
        this.props.onAnswer({
            answer   : { ...answer, is_choice: index },
            validated: index <= question.choices.length ? { ...answer, is_choice: index } : null,
        });


    }

    handleActionEditQuestion(state) {
        this.setState({
            disabled: state.disabled,
            flagEdit: state.flagEdit,
        });
        this.props.editQuestion(state.flagEdit, this.props.question.index);
        if (!state.flagEdit) {
            this.props.onUpdate({
                answer   : this.state.answers,
                validated: this.state.validated,
            }, this.props.question.index, this.props.index);
        }
    }

    handleResetAnswer() {
        this.setState({
            answers   : {},
            validated : false,
        });

        this.props.onAnswer({
            answer : {},
            validated: null,
        });
    }

    render() {
        const {
                  question, t,
                  // index,
                  editor,
                  takeSurvey,
              }                     = this.props;
        const { answers, disabled } = this.state;
        const contentQuestion       = checkUseHeadingQuestion(question);
        const hiddenButtonEdit      = takeSurvey.flagEditing ? takeSurvey.indexQuestion !== question.index : takeSurvey.flagEditing;
        return (
            <div id='component-question'>
                <div id='web-q' className='pb-3'>
                    <KirokuCardWeb>
                        <KirokuCardTitleWeb color={'card-web'}>
                            <div className="d-flex justify-content-between">
                                <div className='number-question question-number text-overflow-elip'>
                                    {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                                </div>
                                <div/>
                                <div hidden={disabled}>
                                    <KirokuButtonLink
                                        className='padding-reset-button-link'
                                        onClick={() => {this.handleResetAnswer()}}
                                    >
                                        {t('createRecord.resetAnswer')}
                                    </KirokuButtonLink>
                                </div>
                            </div>
                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb>
                            <div className={disabled ? 'wrap-overlay-action' : ''}>

                                <span className='content-name-question'>{question.content}</span>
                                <div className='answer-question'>
                                    {question.choices.map((choice, index) =>
                                        <KirokuRadio
                                            key={index}
                                            id={`${question.index}-${index}`}
                                            name={`${question.index}SingleChoice`}
                                            checked={
                                                answers.is_choice === index
                                            }
                                            onChange={disabled ? () => {
                                            } : this.onChangeItem.bind(this, index)}
                                        >
                                            {choice.answer}

                                        </KirokuRadio>,
                                    )}
                                </div>
                            </div>
                            <div>

                                <div hidden={hiddenButtonEdit || editor}>
                                    <ButtonEditQuestion {...this.state}
                                                        isUnrequited={question.unrequited}
                                                        clickEditQuestion={this.handleActionEditQuestion.bind(this)}
                                    />
                                </div>
                            </div>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                </div>
            </div>
        );
    }
}

SingleChoice.propTypes = {
    value: PropTypes.object,
};
export default SingleChoice;
