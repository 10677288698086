export default class DeletedList {

    constructor(axios) {
        this.axios = axios;
    }

    getSurveysByCondition(condition) {
        return this.axios.get('/deleted/surveys',
            {
                params:
                    {
                        page   : condition.page,
                        sortBy : condition.orderBy.field,
                        orderBy: condition.orderBy.type,
                        title  : condition.keyword,
                    }
            }
        );
    }

    getRecordsByCondition(condition) {
        return this.axios.get('/deleted/records',
            {
                params:
                    {
                        page   : condition.page,
                        sortBy : condition.orderBy.field,
                        orderBy: condition.orderBy.type,
                        title  : condition.title,
                        customer: condition.customerName,
                    }
            }
        );
    }

    getCustomersByCondition(condition) {
        return this.axios.get('/deleted/customers',
            {
                params:
                    {
                        page   : condition.page,
                        sortBy : condition.orderBy.field,
                        orderBy: condition.orderBy.type,
                        name   : condition.keyword,
                    }
            }
        );
    }

    getStaffsByCondition(condition) {
        return this.axios.get('/deleted/staffs',
            {
                params:
                    {
                        page   : condition.page,
                        sortBy : condition.orderBy.field,
                        orderBy: condition.orderBy.type,
                        name   : condition.keyword,
                    }
            }
        );
    }
}




