import React, { Component } from 'react';
import CheckDivide          from "../../../ui/CheckDivide";
import SurveyReviewMobile   from "../Mobile/ReviewSurveyMobile";
import SurveyReviewWeb      from "../Web/surveys/ReviewSurvey";
import KirokuTitlepage      from "../../../ui/KirokuTitlepage";
import { withNamespaces }   from "react-i18next";
import connect              from "react-redux/es/connect/connect";
import { currentPage }      from "../../../actions/CurrentPage";

@withNamespaces()
@connect(state => {
    return {
        currentPage: state.KirokuAITeam.currentPage,
        totalPage: state.KirokuAITeam.totalPage,
    }
}, dispatch => {
    return {
        pageNow: (name) => {
            dispatch(currentPage(name))
        },
    }
})

class SurveyCreator extends Component {
    page = {
        title: <KirokuTitlepage title={this.props.t('createSurvey.CreateNewSurvey')}/>,
        actionPage: null
    };

    state = {
        namePage: this.page,
    };

    componentWillMount() {
        this.props.pageNow(this.state.namePage)
    }

    render() {
        return (
            <CheckDivide mobile={<SurveyReviewMobile  {...this.props}/>}
                         web={<div className='default-background-color'><SurveyReviewWeb  {...this.props}/></div>}/>
        )
    }
}

export default SurveyCreator;