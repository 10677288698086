import React, {Component}                                  from 'react';
import { copyQuestionForTemplateOnMobile, removeQuestion } from "../../../../actions/SurveyMobile";
import {connect}                                           from "react-redux";
import {withNamespaces}                                    from "react-i18next";
import { NOTIFICATION }                                    from '../../../../actions/Notification';

@withNamespaces()
@connect(state => {
    return {
        survey: state.KirokuSurveyTemplateMobile
    }
}, dispatch => {
    return {
        removeQuestion: (question) => {
            dispatch(removeQuestion(question))
        },
        copyQuestionForTemplateOnMobile : (question) => {
            dispatch(copyQuestionForTemplateOnMobile(question))
        },
        notification : (t) => {
            dispatch({
                type : NOTIFICATION,
                typeNotification : 'success',
                message : t('message.questionCopiedSuccess')
            })
        }
    }
})
class BehaviorQuestionMobile extends Component {

    state = {
        hiddenButton : false
    };

    componentWillMount() {
        const survey = this.props.survey;
        const indexQuestion = this.props.question.index + 1;
        this.setState({hiddenButton : survey.questions.length === indexQuestion});
    }

    removeQuestion = () => {
        let question = this.props.question;
        this.props.removeQuestion(question);
    };

    showModalAddLogic = () => {
        this.props.showModalAddLogic();
    };

    showModalEdit = () => {
        let question = this.props.question;
        this.props.backToEditQuestion(question);
    };

    handleCopyQuestion = (question) => {
        const {t} = this.props;
        this.props.notification(t);
        this.props.copyQuestionForTemplateOnMobile(question);
    };


    render() {
        const {t, question} = this.props;
        if(!this.state.hiddenButton) {
            return (
                <div className='d-flex fix-icon-align' style={{width: 278}}>
                    <span onClick={() => this.handleCopyQuestion(question)} className={'pl-3'}>
                        <i className="fas fa-copy"/>
                        <span className={`ml-1`}>{t('Surveys.Copy')}</span>
                    </span>
                        <span onClick={this.showModalEdit.bind(this)} className={'pl-3'}>
                        <i className="fas fa-pencil-alt"/>
                        <span className={`ml-1`}>{t('createSurvey.Edit')}</span>
                    </span>

                            <span onClick={this.showModalAddLogic.bind(this)} className={'pl-3'}>
                        <i className="fas fa-link"/>
                        <span className={`ml-1`}>{t('createSurvey.AddLogic')}</span>
                    </span>

                        <span onClick={this.removeQuestion.bind(this)} className={'pl-3'}>
                        <i className="fas fa-trash-alt"/>
                        <span className={`ml-1`}>{t('createSurvey.Remove')}</span>
                    </span>
                </div>
            )
        }
        return (
            <div className='d-flex justify-content-between fix-icon-align' style={{width: 260}}>
                <span onClick={() => this.handleCopyQuestion(question)} className={'pl-3'}>
                    <i className="fas fa-copy"/>
                    <span className={`ml-1`}>{t('Surveys.Copy')}</span>
                </span>
                <span onClick={this.showModalEdit.bind(this)} className={'pl-3'}>
                    <i className="fas fa-pencil-alt"/>
                    <span className={`ml-1`}>{t('createSurvey.Edit')}</span>
                </span>
                <span onClick={this.removeQuestion.bind(this)} className={'pl-3'}>
                    <i className="fas fa-trash-alt"/>
                    <span className={`ml-1`}>{t('createSurvey.Remove')}</span>
                </span>
            </div>
        );
    }
}

export default BehaviorQuestionMobile;
