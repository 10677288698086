import React, { Component }                                           from 'react';
import { KirokuCheckBox, KirokuInput, KirokuSectionDropdownShowMore } from '../../../../../../ui';
import { connect }                                                    from 'react-redux';
import _                                                              from 'lodash';
import { getGroupedOptions }                                          from '../../../../NewRecords/serviceNewRecords';
import { withNamespaces }                                             from 'react-i18next';


@connect(state => {
    return {
        teams       : state.KirokuAITeam.teamsBeta,
        staffs      : state.KirokuModal.ModalSelectStaffs.staffs,
        keywords    : state.KirokuModal.ModalSelectStaffs.keywords,
        teamSelected: state.KirokuModal.ModalSelectStaffs.teamSelected,

    };
}, dispatch => {
    return {
        handleChangeCheckboxStaff: (index) => {
            dispatch({
                type: 'HANDLE_CHANGE_CHECKBOX_STAFF',
                index,
            });
        },

        handleSelectAllStaffs: () => {
            dispatch({
                type: 'HANDLE_SELECT_ALL_CHECKBOX_STAFFS',
            });
        }, handleSearchTeam  : (teamSelected) => {
            dispatch({
                type: 'HANDLE_SEARCH_TEAM',
                teamSelected,
            });
        },
        handleChangeKeywords : (keywords) => {
            dispatch({
                type: 'CHANGE_KEYWORDS',
                keywords,
            });

        },

    };
})

@withNamespaces()

class StaffOptionsTab extends Component {
    render() {

        const {
                  staffs, t,
                  teams,
                  teamSelected,
                  keywords,
              } = this.props;
        return (
            <div>
                <div className={ 'pt-2 pb-2' }>
                    <div className={'set-height-42'}>
                        <KirokuSectionDropdownShowMore
                            tagType={ 'Team' }
                            placeholder={ t('RecordsBeta.refineByTeam') }
                            options={ getGroupedOptions('TeamId', teams) }
                            value={ teamSelected }
                            onChange={ (teamSelected) => this.props.handleSearchTeam(teamSelected) }
                        />
                    </div>
                </div>
                <div className={ 'pt-2'}  style={{paddingBottom: 35}}>
                    <KirokuInput
                        style={{ height : 42, fontSize: 16}}
                        onChange={ (e) => this.props.handleChangeKeywords(e.target.value) }
                        value={ keywords }
                        placeholder={ t('RecordsBeta.keyword') }
                    />
                </div>
                { staffs.length ? <div>
                    <div className={ 'background-color' }
                         style={ {  overflow: 'scroll',
                             minHeight: 'calc(100vh - 450px)',
                             maxHeight: 'calc(100vh - 450px)',
                             height: 'auto' } }>
                        <div className={ 'border-bottom pb-3' }>
                            <div className={ 'checkbox-bigger p-3' }>
                                <KirokuCheckBox onChange={ () => this.props.handleSelectAllStaffs() }
                                                checked={ _.findIndex(staffs, (staff) => (!staff.selected)) === -1 }
                                />
                            </div>
                        </div>
                        { staffs.map((staff, i) => {
                            return (
                                <div className={ 'border-bottom' } key={ i }>
                                    <div className={ 'checkbox-bigger p-3' }>
                                        <KirokuCheckBox content={<span className={'font-text-in-checkbox'}
                                                                       style={{wordBreak: 'break-word'}}
                                                                >{staff.name}</span>}
                                                        checked={ !!staff.selected }
                                                        onChange={ () => this.props.handleChangeCheckboxStaff(i) }
                                                        id={ `${ staff.id }-${ i }` }/>
                                    </div>
                                </div>
                            );
                        }) }
                    </div>
                </div> : <div className={ 'background-color d-flex' }
                              style={ { fontSize: '16px', height: 'auto', minHeight: 'calc(100vh - 450px)'} }>
                    <div style={{ margin: 'auto'}}>
                        { t('RecordsBeta.noStaffSelected') }
                    </div>
                </div> }
            </div>
        );
    }
}

export default StaffOptionsTab;
