import '../record.css'
import React, { Component }         from 'react';
import { KirokuInputArea }          from '../../../ui';
import { withNamespaces }           from 'react-i18next';
import KirokuCardWeb                from '../../../ui/KirokuCardWeb';
import KirokuCardTitleWeb           from '../../../ui/KirokuCardTitleWeb';
import KirokuCardBodyWeb            from '../../../ui/KirokuCardBodyWeb';
import PropTypes                    from 'prop-types';
import ButtonEditQuestion           from './ButtonEditQuestion';
import { connect }                  from 'react-redux';
import { notificationEditQuestion } from '../../../actions/Survey';
import {checkUseHeadingQuestion}    from "../../Surveys/SurveyEditor/SurveyService";
import KirokuButtonLink             from '../../../ui/KirokuButtonLink';


@withNamespaces()
@connect(state => {
        return {takeSurvey: state.TakeSurveyWeb}
    },
    dispatch => {
        return {
            editQuestion: (flagEdit, indexQuestion) => {
                dispatch(notificationEditQuestion(flagEdit, indexQuestion))
            }
        }
    })
class InputText extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value    : props.value || '',
            disabled : this.props.disabled,
            flagEdit : false,
            validated: false
        }
    }

    componentDidMount() {
        const { question } = this.props;
        this.props.onAnswer({
            answer   : this.props.value || '',
            validated: question.unrequited || this.props.value ? this.props.value : null
        })
    }

    handleChange(event) {
        if (!event) {
            event = { target: {value: ''} }
        }
        this.setState({
            value: event.target.value
        });
        this.setState({validated: !!event.target.value});
        this.props.onAnswer({
            answer   : event.target.value,
            validated: event.target.value ? event.target.value : null
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.value !== nextProps.value) {
            this.setState({value: nextProps.value});
        }
        if (this.props.disabled !== nextProps.disabled || this.props.flagEdit !== nextProps.flagEdit) {
            this.setState({
                disabled: nextProps.disabled,
                flagEdit: nextProps.flagEdit
            })
        }

    }

    handleActionEditQuestion(state) {
        this.setState({
            disabled: state.disabled,
            flagEdit: state.flagEdit
        });
        this.props.editQuestion(state.flagEdit, this.props.question.index);
        if (!state.flagEdit) {
            this.props.onUpdate({
                answer   : this.state.value,
                validated: this.state.validated
            }, this.props.question.index, this.props.index)
        }

    }

    render() {
        const {
            editor,
            question, t,
            // index,
            takeSurvey
        } = this.props;
        const {disabled}                       = this.state;
        const hiddenButtonEdit                 = takeSurvey.flagEditing ? takeSurvey.indexQuestion !== question.index : takeSurvey.flagEditing;
        const contentQuestion = checkUseHeadingQuestion(question);

        return (
            <div id='component-question'>
                <div id='web-q' className='pb-3'>
                    <KirokuCardWeb>
                        <KirokuCardTitleWeb color={'card-web'}>
                            <div className='d-flex justify-content-between'>
                                <div className='number-question question-number text-overflow-elip'>
                                    {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                                </div>
                                <div hidden={disabled}>
                                    <KirokuButtonLink
                                        className='padding-reset-button-link'
                                        onClick={() => {this.handleChange()}}
                                    >
                                        {t('createRecord.resetAnswer')}
                                    </KirokuButtonLink>
                                </div>
                            </div>
                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb>
                            <div className={disabled ? 'wrap-overlay-action' : ''}>
                                <span className='content-name-question'>{question.content}</span>
                                <div className='answer-question'>
                                    <KirokuInputArea className='form-area'
                                                     disabled={disabled}
                                                     name={`${question.index}-input`}
                                                     onChange={this.handleChange.bind(this)}
                                                     value={this.state.value}
                                                     style={{maxHeight: 90}}
                                    />
                                </div>
                            </div>
                            <div hidden={hiddenButtonEdit || editor}>

                                <ButtonEditQuestion {...this.state}
                                                    isUnrequited={question.unrequited}
                                                    clickEditQuestion={this.handleActionEditQuestion.bind(this)}
                                />
                            </div>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                </div>
            </div>
        )
    }
}

InputText.propTypes = {
    value: PropTypes.string
};
export default InputText;
