import React, {Component}               from 'react';
import {connect}                        from 'react-redux';
import {KirokuButton}                   from '../../../ui/index';
import DateRange                        from './DateRangeWeb';
import moment                           from 'moment';
import {loadSurveyListPage}             from '../../../actions/Survey';
import {loadUsers}                      from '../../../actions/User';
import {loadRecords}                    from '../../../actions/Record';
import {withNamespaces}                 from 'react-i18next';
import KirokuResetFilterConditionSearch from "../../../ui/KirokuResetFilterConditionSearch";
import KirokuResetFilterCondition       from "../../../ui/KirokuResetFilterCondition";

@withNamespaces()
@connect(state => {
    return {
        ListAllSurveyTemplate: state.KirokuAISurvey.ListAllSurveyTemplate,
        users: state.KirokuAIUser.users,
        ListAllCustomer: state.KirokuAICustomer.ListAllCustomer,
        conditions: state.KirokuAIBehavior.conditionRecords.conditions,
        conditionRemoved: state.KirokuAIBehavior.conditionRecords.conditionRemoved
    }
}, dispatch => {
    return {
        loadSurveys: () => {
            dispatch(loadSurveyListPage());
        },
        loadRecords: (params) => {
            dispatch(loadRecords(params))
        },

        loadUsers: () => {
            dispatch(loadUsers())
        },
    }
})
class FilterRecordsModal extends Component {


    state = {
        showDateRange: false,
        showFilterRecords: false,
        show: false,
        status: '',
        statusRecord: '',
        nameStatus: '',
        startDate: '',
        endDate: '',
        day: '',
        week: '',
        month: '',
        customerId: '',
        templateId: '',
        staffId: '',
        staffName: '',
        customerName: '',
        titleSurvey: ''
    };


    filterByDate = [
        {
            value: 'All date',
            component: (<div>{this.props.t('RecordList.allDate')} </div>),
            label: this.props.t('RecordList.allDate')
        },
        {
            value: 'day',
            component: (<div>{this.props.t('RecordList.day')} </div>),
            label: this.props.t('RecordList.day')
        },
        {
            value: 'week',
            component: (<div>{this.props.t('RecordList.week')}</div>),
            label: this.props.t('RecordList.week')
        },
        {
            value: 'month',
            component: (<div>{this.props.t('RecordList.month')}</div>),
            label: this.props.t('RecordList.month')
        },
        {
            value: 'Custom Date',
            component: (<div onClick={this.closeDateRange.bind(this)}>{this.props.t('RecordList.customDate')}</div>),
            label: this.props.t('RecordList.customDate')
        }
    ];
    dropDownItemsStatus = () => {
        return [
            {
                value: '',
                component: (<div>{this.props.t('common.All')}</div>),
                label: this.props.t('common.All')
            },
            {
                value: 'draft',
                component: (<div>{this.props.t('Surveys.Draft')}</div>),
                label: this.props.t('Surveys.Draft')
            }
        ]
    };
    dropDownItemsSurveys(surveys) {
        const listSurvey = surveys.map(survey => ({
            value: survey.id,
            component: (<div>{survey.title}</div>),
            label: survey.title
        }));
        return [
            {
                value: '',
                component: (<div>{this.props.t('RecordList.allSurvey')}</div>),
                label: this.props.t('RecordList.allSurvey')
            },
            ...listSurvey
        ];
    };

    dropDownItemsCustomers(customers) {
        const listCustomer = customers.map(customer => ({
            value: customer.id,
            component: (<div>{customer.name}</div>),
            label: customer.name
        }));
        return [
            {
                value: '',
                component: (<div>{this.props.t('RecordList.allCustomer')}</div>),
                label: this.props.t('RecordList.allCustomer')
            },
            ...listCustomer
        ];
    };

    dropDownItemsStaffs(staffs) {
        const listStaffs = staffs.map(staff => ({
            value: staff.id,
            component: (<div>{staff.name}</div>),
            label: staff.name
        }));
        return [
            {
                value: '',
                component: (<div>{this.props.t('RecordList.allStaff')}</div>),
                label: this.props.t('RecordList.allStaff')
            },
            ...listStaffs
        ];
    };

    handleCloseModal() {
        this.props.showModal();
    }

    closeDateRange() {
        this.setState({
            showDateRange: !this.state.showDateRange
        });
    }

    handleCustomDate(dateRange) {
        this.setState({
            ...dateRange,
            showDateRange: !this.state.showDateRange
        });
    }

    filterTemplate(event) {
        this.setState({
            templateId: event.kirokuSelected.value,
            titleSurvey: event.kirokuSelected.label
        })
    }

    filterCustomer(event) {
        this.setState({
            customerId: event.kirokuSelected.value,
            customerName: event.kirokuSelected.label
        })
    }

    filterStaff(event) {
        this.setState({
            staffId: event.kirokuSelected.value,
            staffName: event.kirokuSelected.label
        })
    }

    resetSelectTime() {
        this.setState({
            startDate: '',
            endDate: '',
            day: '',
            week: '',
            month: ''
        })
    }

    selectStatus(event) {
        this.setState({
            status : event.kirokuSelected.value,
            statusRecord : event.kirokuSelected.label
        })
    }

    selectTime(event) {
        this.resetSelectTime();
        this.setState({
            [event.kirokuSelected.value]: moment().startOf(event.kirokuSelected.value).unix(),
            nameStatus: event.kirokuSelected.label === "All Date" || event.kirokuSelected.label === "全期間" ? '' : event.kirokuSelected.label
        });
    }

    handleClickApply() {
        let params = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            day: this.state.day,
            week: this.state.week,
            month: this.state.month,
            customerId: this.state.customerId,
            templateId: this.state.templateId,
            staffId: this.state.staffId,
            staffName: this.state.staffName,
            customerName: this.state.customerName,
            titleSurvey: this.state.titleSurvey,
            nameStatus: this.state.nameStatus,
           // statusRecord : this.state.statusRecord,
            status : this.state.status
        };

        this.props.showModal();
        this.props.filterCondition(params);
    }

    handleCancelClick() {
        this.props.showModal();
    }

    componentWillMount() {
        this.props.loadUsers();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.conditionRemoved !== this.props.conditionRemoved) {
            this.setState({
                ...nextProps.conditionRemoved
            })
        }
    }

    render() {
        const {ListAllSurveyTemplate, users, t, ListAllCustomer} = this.props;
        return (
            <div hidden={this.props.show} className='padding-responsive'>
                <div className={'view-1vh'}>
                    <div className='survey-list'>
                        <div className='p-3'>
                            <button className='close' style={{float: 'left'}}
                                    onClick={this.handleCloseModal.bind(this)}>
                                <span style={{fontSize: '24px', color: '#a8aab7'}}>&times;</span>
                            </button>
                            <div className='text-center'>
                                <span className='filter-record'>{t('RecordList.filterRecords')}</span>
                            </div>
                        </div>
                        <div className='p-2 mt-4 filter-modal'>
                            <div>
                                <KirokuResetFilterConditionSearch
                                    defaultValue={t('RecordList.allSurvey')}
                                    title={this.state.titleSurvey}
                                    items={this.dropDownItemsSurveys(ListAllSurveyTemplate)}
                                    icon={'far fa-file-alt text-secondary'}
                                    onSelect={this.filterTemplate.bind(this)}
                                />
                            </div>
                            <div className='mt-10 pt-2'>
                                <KirokuResetFilterCondition
                                    title={this.state.nameStatus}
                                    defaultValue={t('RecordList.allDate')}
                                    items={this.filterByDate}
                                    icon={'far fa-calendar-alt text-secondary'}
                                    onSelect={this.selectTime.bind(this)}
                                />
                            </div>
                            <div className='mt-10 pt-2'>
                                <KirokuResetFilterCondition
                                    title={this.state.statusRecord}
                                    defaultValue={t('common.All')}
                                    items={this.dropDownItemsStatus()}
                                    icon={'far fa-file-alt text-secondary'}
                                    onSelect={this.selectStatus.bind(this)}
                                />
                            </div>
                            <div className='mt-10 pt-2'>
                                <KirokuResetFilterConditionSearch
                                    title={this.state.staffName}
                                    defaultValue={t('RecordList.allStaff')}
                                    filterItems={this.filterStaff.bind(this)}
                                    items={this.dropDownItemsStaffs(users)}
                                    icon={'far fa-user text-secondary'}
                                    onSelect={this.filterStaff.bind(this)}
                                />
                            </div>
                            <div className='mt-10 pt-2'>
                                <KirokuResetFilterConditionSearch
                                    title={this.state.customerName}
                                    defaultValue={t('RecordList.allCustomer')}
                                    filterItems={this.filterCustomer.bind(this)}
                                    items={this.dropDownItemsCustomers(ListAllCustomer)}
                                    icon={'fa fa-users text-secondary'}
                                    onSelect={this.filterCustomer.bind(this)}
                                />
                            </div>
                            <div className='pt-4'>
                                <div className='pr-5 pl-5 fix-center'>
                                    <div className='d-flex justify-content-between'>
                                        <KirokuButton color={'white'}
                                                      onClick={this.handleCancelClick.bind(this)}>{t('common.Cancel')}</KirokuButton>
                                        <div className={'pr-1 pl-1'}/>
                                        <KirokuButton color={'primary'}
                                                      onClick={this.handleClickApply.bind(this)}>{t('common.Apply')}</KirokuButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DateRange show={this.state.showDateRange} closeDateRange={this.closeDateRange.bind(this)}
                           customDate={this.handleCustomDate.bind(this)}/>
            </div>
        );
    }
}

export default FilterRecordsModal;
