import {oldDraftToNewDraft} from './FitlerData';

export default class DraftService {

    constructor(axios) {
        this.axios = axios;
    }

    saveDraft(type, draft) {
        return this.axios.post(`/save-draft/${type}`, draft);
    }

    async getDraftCreatorSurveyRecord(type, surveyId, customerId) {
        return await this.axios.get(`/load-draft/${type}?id=${surveyId}&customerId=${customerId}`).then(res => {
            if (res.data.length !== 0) {
                return res.data[0].data.dataDraft ?
                    {
                        ...res.data[0].data,
                        create_at: res.data[0].create_at
                    } :
                    oldDraftToNewDraft(res.data[0].data)
            }
            return null
        })
    }

    discardDraftRecord(type, surveyId, customerId) {
        return this.axios.delete(`/draft/${type}?id=${surveyId}&customerId=${customerId}`)
    }

    getDraftEditRecording(type, id) {
        return this.axios.get(`/load-draft/${type}?id=${id}`).then(res => {
            if (res.data.length !== 0) {
                return res.data[0].data
            }
            return null
        });
    }

    loadDraft(type, id = '') {
        return this.axios.get(`/load-draft/${type}?id=${id}`).then(res => {
            if (res.data.length !== 0) {
                return res.data[0]
            }
            return null
        });
    }

    discardDraft(type, id = '') {
        return this.axios.delete(`/draft/${type}?id=${id}`);
        //return this.axios.delete(`/draft/${type}?id=${id}`);
    }

    discardDraftEditRecording(type, id) {
        return this.axios.delete(`/draft/${type}?id=${id}`);
    }
}




