class UserService {
    constructor(axios) {
        this.axios = axios;
    }

    async loadDetail(userId) {
        let result               = await this.axios.get(`/user/${userId}`).catch(err => console.log(err));
        let user                 = result.data;
        let teamAssign           = user.teams.map(team => {
            return team.name
        });
        let currentTeam          = teamAssign.join(', ');
        let displayName          = user.line_account ? JSON.parse(user.line_account).displayName : '';
        let currentQualification = user.qualification.join(', ');
        let staffInTeam          = user.teams.map(team => {
            return {
                ...team,
                isChecked: false
            }
        });
        return {
            ...user,
            currentTeam,
            displayName,
            currentQualification,
            staffInTeam
        }
    }

    editRole(userId, role, teamChoice) {
        let currentTeam = teamChoice.map(item => item.id);
        return this.axios.put(`/edit-role/${userId}`, {
            role: role,
            teams: currentTeam
        })
    }

    removeMemberInTeam(userId, listMemberRemove) {
        let listChoice = listMemberRemove.map(team => team.id) || [];
        return this.axios.put(`/remove-from-teams/${userId}`, listChoice)

    }

    users() {
        return this.axios.get(`/users`).then(res => res.data);
    }

    edit(userId, user) {
        return this.axios.put(
            `/update-user/${userId}`, user,
        );
    }

    staffInTeam(teamId) {
        return this.axios.get(`/staff-in-team?team_id=${teamId}`).then(res => res.data)
    }

    loadUserPagination(params, tags) {
        let tagCondition = tags.tags ? tags.tags.map(tag => {
            return {tagId: tag.tagId, tagType: tag.tagType}
        }) : [];
        return this.axios.post(`/user-search`, {tags: tagCondition}, {
            params: {
                name: params.name || '',
                page: params.page || 1,
                teamid: params.teamid || '',
                status: params.status || ''
            }
        }).then(res => res.data)
    }


    assignUserToTeam(users, teams) {
        let currentUsers = users.map(item => item.id);
        let currentTeams = teams.map(item => item.id);
        return this.axios.post(`/team-multiple-assign`, {
            users: currentUsers,
            teams: currentTeams

        }).then(res => res.data)
    }

    changePassword(id, data) {
        return this.axios.put(`/change-password/${id}`, data)
    }

    deleteUsers(userId) {
        return this.axios.delete(`/user/${userId}`)
    }

    createUser(user) {
        return this.axios.post(`/user`, user)
    }

    duplicateCreateUser(user) {
        return this.axios.post(`/duplicate-user`, user)
    }

    checkDuplicate(name) {
        return this.axios.get(`/username/${name}`).then(res => res.data.message);
    }

    updateRole(role, userId, query, tags) {
        return this.axios.put(`update-role-users`, {
            query,
            tags,
            role: role,
            userIds: userId
        }).then(res => res.data)
    }

    getStaffsBeta() {
        return this.axios.get('/users-beta').then(res => res.data)
    }

}

export default UserService;
