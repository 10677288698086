import {LOAD_CURRENT_PAGE} from "../actions/CurrentPage";

const defaultApplicationSate = {};

const KirokuAICurrentPage = (state = defaultApplicationSate, action) => {
    switch (action.type) {
        case  LOAD_CURRENT_PAGE:
            return action.name;
        default :
            return state;
    }

};

export default KirokuAICurrentPage;