import { NOTIFICATION } from '../actions/Notification';

const defaultStateNotification = {
    show                     : false,
    typeNotification         : '',
    message                  : '',
    isNotAutomaticCloseNotice: true,

};

const KirokuAINotification = (state = defaultStateNotification, action) => {
    switch (action.type) {
        case NOTIFICATION :
            return {
                ...state,
                show                     : !state.show,
                typeNotification         : action.typeNotification,
                message                  : action.message,
                isNotAutomaticCloseNotice: !!action.isNotAutomaticCloseNotice,
            };

        case 'SET_NOTIFICATION':
            return {
                ...state,
                show                     : action.show,
                typeNotification         : action.typeNotification,
                message                  : action.message,
                isNotAutomaticCloseNotice: !!action.isNotAutomaticCloseNotice,
            };
        default :
            return state;
    }
};

export default KirokuAINotification;
