import React, { Component }        from 'react';
import { checkUseHeadingQuestion } from "../../Surveys/SurveyEditor/SurveyService";

class CustomerQuestion extends Component {

    static renderQuestion(answer) {
        switch (answer.type) {
            case "Media":
                return (
                    <div className="pb-3">
                        <img className="report-tab" src={answer.answer} alt=""/>
                    </div>
                );
            case "YesNo":
            case "Date":
                return (<div className="answer-content">{answer.answer}</div>);
            default:
                return (<div className="answer-content">{answer.answer}</div>)
        }
    }

    render() {
        const {reports} = this.props;

        return (
            <div className="report-tab container">
                <div className="wrap-content">
                    <div className="A4">
                        <div className="content">
                            <div className="wrap-bg">
                                {reports.customers.map((customer, index) => {
                                    return(
                                        <div className="pb-5" key={index}>
                                            <div>
                                                <label className="report-tab">
                                                    {reports.translate_customerName}
                                                </label>
                                                <div className="border-title-template">
                                                    {customer.customerName}
                                                </div>
                                            </div>
                                            {customer.records.map((record, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div className="pb-2 pt-3">
                                                            <label className="report-tab">
                                                                {reports.translate_title}
                                                            </label>
                                                            <div className="border-customer-name">
                                                                {record.title}
                                                            </div>
                                                        </div>
                                                        {record.questions.map((question, index) => {
                                                            return (
                                                                <div className="" key={index}>
                                                                    <div className="pb-2 pt-3">
                                                                        {checkUseHeadingQuestion(question)}
                                                                    </div>
                                                                    <div className="row ml-0 mr-0 ">
                                                                        <div className="col-3 pl-0 ">
                                                                            <label className="report-tab">
                                                                                {reports.translate_serviced}
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-3 ">
                                                                            <label className="report-tab">
                                                                                {reports.translate_created_by}
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-6 ">
                                                                            <label className="report-tab">
                                                                                {reports.translate_answer}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="answers-list">
                                                                        {question.answers.map((answer, index) => {
                                                                            return (
                                                                                <div className={`pt-2 pb-2 row ml-0 mr-0 answer-item ${answer.answerEnd ? "end-answer" : "answer"}`} key={index}>
                                                                                    <div className="col-3 pl-0">
                                                                                        {answer.serviced_date_time}
                                                                                    </div>
                                                                                    <div className="col-3  ">
                                                                                        {answer.record_by}
                                                                                    </div>
                                                                                    <div className="col-6  ">
                                                                                        {CustomerQuestion.renderQuestion(answer)}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomerQuestion
