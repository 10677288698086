import React, {Component} from 'react';
import {currentPage}      from "../../../actions/CurrentPage";
import KirokuButton       from "../../../ui/KirokuButton";
import KirokuCardList     from "../../../ui/KirokuCardList";
import KirokuCard         from "../../../ui/KirokuCard";
import {connect}          from "react-redux";
import KirokuTitlepage    from "../../../ui/KirokuTitlepage";
import {getDetailTeam}    from "../../../actions/Team";
import KirokuLoading      from "../../../ui/KirokuLoading";
import KirokuAge          from "../../../ui/KirokuAge";
import {withNamespaces}   from "react-i18next";
import {Link}             from "react-router-dom";
import moment             from "moment";

@withNamespaces()
@connect(state => {
    return {
        team: state.KirokuAITeam.team,
        currentPage: state.KirokuAIRecord.currentPage,
        loading: state.KirokuAITeam.loading
    }
}, dispatch => {
    return {
        currentPage: (name) => {
            dispatch(currentPage(name))
        },
        getDetailTeam: (idTeam) => {
            dispatch(getDetailTeam(idTeam))
        },
        resetState: () => {
            dispatch({
                type: 'DEFAULT_TEAM'
            })
        }

    }
})

class TeamDetailMobile extends Component {

    page = {
        title: <KirokuTitlepage title={this.props.t('Team.Team')}/>,
        actionPage: null
    };

    state = {
        showMember: false,
        showCustomer: true,
        namePage: this.page,
        team: [],
        members: [],
        customer: []
    };

    goBack() {
        this.props.history.goBack()
    }

    showMember() {
        this.setState({
            showCustomer: false,
            showMember: true
        })
    }

    showCustomer() {
        this.setState({
            showCustomer: true,
            showMember: false
        })
    }

    componentWillMount() {
        this.props.getDetailTeam(this.props.id);
        this.props.currentPage(this.state.namePage)
    }

    componentWillUnmount() {
        this.props.resetState()
    }

    render() {
        const teams     = this.props.team;
        const members   = this.props.team.members;
        const customers = this.props.team.customers;
        const {t}       = this.props;
        return (
            <div>
                {!this.props.loading ?
                    <div id='mobile' className='padding-responsive'>
                        <KirokuButton color='white' onClick={this.goBack.bind(this)}>{t('common.Back')}</KirokuButton>
                        <div className="pt-2">
                            <div className='card'>
                                <div className='p-2 pt-3'>
                                    <h5>{teams.name}</h5>
                                    <pre
                                        style={{
                                            color: '#7f8fa4',
                                            wordWrap: 'break-word',
                                            whiteSpace: 'pre-wrap'
                                        }}
                                    >{teams.description}</pre>
                                </div>
                            </div>
                        </div>
                        <div className='pt-10'>
                            <div className='card fix-center'>
                                <div className='p-2' style={{width: '100%'}}>
                                    <KirokuButton onClick={this.showCustomer.bind(this)}
                                                  style={{borderRadius: 0, width: '50%'}}
                                                  color={this.state.showMember ? 'white' : 'success'}>{t('common.Customer')}</KirokuButton>
                                    <KirokuButton onClick={this.showMember.bind(this)}
                                                  style={{borderRadius: 0, width: '50%'}}
                                                  color={this.state.showMember ? 'success' : 'white'}>{t('common.Members')}</KirokuButton>
                                </div>
                            </div>
                            <div hidden={this.state.showCustomer}>
                                <KirokuCardList
                                    headerLeft={<span className='customer-name'>{t('common.Name')}</span>}
                                    headerRight={<span className='customer-name'>{t('common.Role')}</span>}
                                >
                                    {members.map(member =>
                                        <KirokuCard key={member.id}
                                                    action={<p>{t(`role.${member.role}`)}</p>}>
                                            <p>
                                                <Link to={`/staffs/staff/${member.id}`}>
                                                    {member.name}
                                                </Link>
                                            </p>
                                        </KirokuCard>
                                    )}
                                </KirokuCardList>
                            </div>
                            <div hidden={this.state.showMember}>
                                <KirokuCardList
                                    headerLeft={<span className='customer-name'>{t('common.Name')}</span>}
                                    headerRight={<span className='customer-name'>{t('common.Age')}</span>}
                                >
                                    {customers.map(customer =>
                                        <KirokuCard key={customer.id}
                                                    action={<KirokuAge
                                                        dob={customer.date_of_birth === null || customer.date_of_birth === '' ? ''
                                                            : moment.unix(customer.date_of_birth)}/>}>
                                            <p>
                                                <Link to={`/customers/customer-detail/${customer.id}`}>
                                                    {customer.name}
                                                </Link>
                                            </p>
                                        </KirokuCard>
                                    )}
                                </KirokuCardList>
                            </div>
                        </div>
                        <div className='pt-10 d-flex justify-content-between pb-5 mb-2'>
                            <KirokuButton color='white'
                                          onClick={this.goBack.bind(this)}>{t('common.Back')}</KirokuButton>
                            <div/>
                        </div>
                    </div>
                    : <KirokuLoading/>}
            </div>
        )
    }
}

export default TeamDetailMobile;
