import React, { Component } from 'react';
import { withNamespaces }   from "react-i18next";
import { Link }             from "react-router-dom";
import { KirokuInputCode }  from "../../ui";
import { connect }          from "react-redux";

@withNamespaces()
@connect(state => {
    return {
        inputNumFirst : state.LoginSupportPage.inputNumFirst,
        inputNumSecond: state.LoginSupportPage.inputNumSecond,
        inputNumThird : state.LoginSupportPage.inputNumThird,
        isConnect     : state.LoginSupportPage.isConnect,

    }
}, dispatch => {
    return {
        changeInputNum    : (name, value) => {
            dispatch({
                type: 'CHANGE_INPUT_NUM',
                name, value
            })
        },
        submitConnect     : () => {
            dispatch({
                type: 'CONNECT_PAGE_SUPPORT',
            })
        },
        setInitPageSupport: () => {
            dispatch({
                type: 'SET_INIT_STATE_PAGE_SUPPORT',
            })
        }
    }
})
class SupportPageMobile extends Component {

    handleOnChange(e) {
        this.props.changeInputNum(e.target.name, e.target.value);
    }

    handleSubmitConnect() {
        this.props.submitConnect();
    }

    handleSetInitPageSupport() {
        this.props.setInitPageSupport();
    }

    render() {
        const {t, inputNumFirst, inputNumSecond, inputNumThird, isConnect} = this.props;
        return (
            <div className='page-login'>
                <div className="box-login">
                    <div className="tile">
                        <div className="language">

                        </div>
                        <div className="card-login text-center padding-location-support-page">
                            <div className="tile-header">

                            </div>
                            <div className="tile-body ">
                                <h2 className="login-logo kiroku-support">
                                    {t('common.KirokuSupport')}
                                </h2>
                                <span style={{color: '#7f8fa4'}}>{t('common.numberConnect')}</span>
                            </div>
                            <div className={'d-flex justify-content-center pt-5'}>
                                <KirokuInputCode
                                    onChange={this.handleOnChange.bind(this)}
                                    autoFocus
                                    value={inputNumFirst}
                                    id={'inputNumFirst'}
                                    name={'inputNumFirst'}
                                    type="text"/>
                                &mdash;
                                <KirokuInputCode
                                    onChange={this.handleOnChange.bind(this)}
                                    value={inputNumSecond}
                                    id={'inputNumSecond'}
                                    name={'inputNumSecond'}
                                    type="text"/>
                                &mdash;
                                <KirokuInputCode
                                    onChange={this.handleOnChange.bind(this)}
                                    id={'inputNumThird'}
                                    name={'inputNumThird'}
                                    value={inputNumThird}
                                    type="text"/>
                            </div>
                            <div className="submit-container clearfix pt-5">
                                {isConnect ?
                                    <button className={'btn-connect-active'} onClick={this.handleSubmitConnect.bind(this)}>
                                        {t('common.connect')}
                                    </button> :
                                    <button className={'btn-connect-disabled'}>
                                        {t('common.connect')}
                                    </button>
                                }
                            </div>

                            <div className="hr-login margin-line-back" style={{marginTop: '130px'}}>
                                <span className='hr-login-content'>{t('common.Back')}</span>
                            </div>
                            {localStorage.getItem('token') ?
                                <div style={{textAlign: 'center'}} className={'pt-2'} onClick={() => {
                                    this.handleSetInitPageSupport();
                                    window.location = '/'
                                }}>
                                    <Link
                                        to={'/'}>{t('common.BackHome')}</Link>
                                </div> :
                                <div style={{textAlign: 'center'}} className={'pt-2'}>
                                    <Link onClick={this.handleSetInitPageSupport.bind(this)}
                                          to={'/login'}>{t('common.loginWithKirokuAI')}</Link>
                                </div>
                            }



                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SupportPageMobile
