import React, {Component}                                 from 'react';
import {connect}                                          from "react-redux";
import {detailSurvey, createQuestion, editSurveyTemplate} from "../../../../actions/SurveyTemplate";
import {updateSurvey}                                     from "../../../../actions/Survey";
import {surveyTemplateService, surveyFactory}             from "../../../../services";
import ReviewSurvey                                       from "../../Web/surveys/ReviewSurvey";
import ComponentSurveyEditor                              from "../../Web/surveys/ComponentSurveyEditor";
import {withNamespaces}                                   from "react-i18next";
import {NOTIFICATION}                                     from "../../../../actions/Notification";

@withNamespaces()
@connect(state => {
    return {
        survey: state.KirokuAISurveyTemplate,
        loading: state.Loading,
        showReview: state.KirokuAISurveyTemplate.showReview,
    }
}, dispatch => {
    return {
        detail: surveyId => dispatch(detailSurvey(surveyId)),
        editSurveyTemplate: surveyTemplate => dispatch(editSurveyTemplate(surveyTemplate)),
        createQuestion: question => dispatch(createQuestion(question)),
        updateSurvey: (id, survey) => dispatch(updateSurvey(id, survey)),
        notification : () => dispatch({
            type: NOTIFICATION,
            typeNotification: 'success',
            message: 'Surveys.UpdatedSuccessfully'})
    }
})
class EditorSurveyWeb extends Component {

    updateSurvey = async () => {
        const {survey} = this.props;
        const surveyEdited = surveyFactory.makeEdit(survey);
        await surveyTemplateService.edit(surveyEdited.id, surveyEdited);
        this.props.notification();
        //TODO - May have performance impact
        this.props.editSurveyTemplate(surveyEdited);
        this.props.updateSurvey(surveyEdited.id, surveyEdited);
        this.props.history.push('/surveys');
    };

    render() {
        const {t} = this.props;
        return (
            this.props.showReview ? (

                    <ComponentSurveyEditor history={this.props.history}
                                           handleSave={this.updateSurvey.bind(this)}
                                           nameButtonSubmit={t('createSurvey.Save')}/>
                ) :
                (<ReviewSurvey history={this.props.history} handleSubmit={this.updateSurvey.bind(this)}/>)
        );
    }
}

export default EditorSurveyWeb;
