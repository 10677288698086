export default class UserFactory {


    makeUser = (user, status) => {

        if (!status) {
            let teams       = user.teams.map(team => {
                return {
                    tagType: "team",
                    tagContent: {
                        ...team,
                    },
                    key: team.id,
                    label: team.name,
                    value: `${team.name}${team.id}`,
                    type: 'Teams'
                }
            });
            let customers   = user.customers.map(customer => {
                return {
                    tagType: "customer",
                    tagContent: {
                        ...customer,
                    },
                    key: customer.id,
                    label: customer.name,
                    value: `${customer.name}${customer.id}`,
                    type: 'Customers'
                }
            });
            let tags        = teams.concat(customers);
            let currentTeam = user.tags.filter(item => item.tagType === 'team');
            let currentCustomer = user.tags.filter(item => item.tagType === 'customer');
            let team = currentTeam.map(value => value.tagContent.id);
            let customer = currentCustomer.map(value => value.tagContent.id);

            return {...user, teams: team, tags: tags, customers : customer};

        } else {
            let currentTeam = user.tags.filter(item => item.tagType === 'team');
            let currentCustomer = user.tags.filter(item => item.tagType === 'customer');
            let teams = currentTeam.map(value => value.tagContent.id);
            let customers = currentCustomer.map(value => value.tagContent.id);

            // return {
            //     avatar: team.avatar,
            //     create_at: team.create_at,
            //     credential_id: team.credential_id,
            //     currentQualification: team.currentQualification,
            //     currentTeam: team.currentTeam,
            //     customers: team.customers,
            //     date_synchronizeLine: team.date_synchronizeLine,
            //     displayName: team.displayName,
            //     id: team.id || null,
            //     line_account: team.line_account,
            //     line_id: team.line_id,
            //     name: team.name,
            //     organization_id: team.organization_id,
            //     qualification: team.qualification,
            //     staffInTeam: team.staffInTeam,
            //     teams: currentTeam,
            //     title: team.title,
            //     username: team.username,
            //     working_form: team.working_form
            // }
            return {...user, teams: teams, tags: user.tags, customers: customers};
        }
    };
}
