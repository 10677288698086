import React, { Component }             from 'react';
import { connect }                                               from 'react-redux';
import { CheckRerenderResponsive, KirokuTitlepage } from '../../../ui';
import { currentPage }                                           from '../../../actions/CurrentPage';
import { withNamespaces }               from 'react-i18next';
import RecordsMobile                    from './mobile/RecordsMobile';
import RecordsWeb                       from './web/RecordsWeb';
import './recordsBeta.css'
import { getTeamsBeta }                 from '../../../actions/Team';
import { getStaffsBeta }                from '../../../actions/User';
import { getSurveysBeta }               from '../../../actions/Survey';
import { withRouter }                   from 'react-router-dom';

function page(t) {
    return {
        title     : <KirokuTitlepage title={ t('Layout.RecordSimpleDetail') }/>,
        actionPage: null,
    };
}

@withRouter
@withNamespaces()
@connect(state => {
    return {
        typeSearch          : state.RecordsBeta.typeSearch,
        recordsBeta         : state.RecordsBeta.recordsBeta,
        customerOfTeamByUser: state.RecordsBeta.customerOfTeamByUser,
        teams               : state.KirokuAITeam.teamsBeta,
        surveys             : state.KirokuModal.ModalSelectSurveys.surveys,
        staffs              : state.KirokuModal.ModalSelectStaffs.staffs,
    };
}, dispatch => {
    return {
        namePage: (name) => {
            dispatch(currentPage(name));
        },
        getTeamsBeta: () => {
            dispatch(getTeamsBeta());
        },
        getSurveysBeta: () => {
            dispatch(getSurveysBeta());
        },

        getStaffsBeta: () => {
            dispatch(getStaffsBeta());
        },

        getCustomersOfTeamByUserId: (userId) => {
            dispatch({
                type: 'GET_CUSTOMERS_OF_TEAM_BY_USER',
                userId
            });
        },
        LoadInitRecordsBeta: (userId) => {
            dispatch({
                type: 'LOAD_INIT_SCREEN_RECORDS_BETA',
                userId
            })
        },
        loadRecordBetaByCondition: () => {
            dispatch({
                type: 'LOAD_RECORDS_BETA_BY_CONDITION',
            })
        },
        loadRecordsBetaWithCondition: (condition) => {
            dispatch({
                type : "LOAD_RECORD_FROM_ANALYSIS",
                condition
            })
        }
    };
})
class RecordsBeta extends Component {

    state = {
        isLoading: false,
        namePage : page(this.props.t),
    };


    componentWillMount() {
        let userId = JSON.parse(localStorage.getItem('profile')).id;
        const { surveys, staffs, teams, customerOfTeamByUser, recordsBeta} = this.props;
        this.props.namePage(this.state.namePage);

        if(!staffs.length || !teams.length) {
            this.props.getTeamsBeta();
            this.props.getStaffsBeta();
        }

        if(!customerOfTeamByUser.length) {
            this.props.getCustomersOfTeamByUserId(userId);
        }

        if(!surveys.length) {
            this.props.getSurveysBeta();
        }
        if(this.props.history.location.params) {
            return this.props.loadRecordsBetaWithCondition(this.props.history.location.params);
        }
        if(!recordsBeta.length) {
            this.props.LoadInitRecordsBeta(userId);
        } else {
            this.props.loadRecordBetaByCondition();
        }

    }

    render() {
        let mobile = <RecordsMobile history={this.props.history}/>;
        let web    = <RecordsWeb history={this.props.history}/>;
        return (
            <div className='default-background-color'>
                <CheckRerenderResponsive mobile={ mobile } web={ web }/>
            </div>
        );
    }
}

export default RecordsBeta;
