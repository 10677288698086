export default {
    genderType : [
        {
            id : 1,
            name : 'CustomerEditor.unselected',
            type : 'unselected'
        },
        {
            id : 2,
            name : 'CustomerEditor.Male',
            type : 'Male'
        },
        {
            id : 3,
            name : 'CustomerEditor.Female',
            type : 'Female'
        }
    ]
}
