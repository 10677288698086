import React, { Component } from 'react';
import AnswerQuestion       from '../../RecordCreator/Web/AnswerQuestion';
import AboutSurvey          from '../../ReviewRecording/AboutSurvey';
import { withNamespaces }   from 'react-i18next';
@withNamespaces()
class PreviewTakeSurveyWeb extends Component {

    handlerSubmitTakeRecord() {

    }

    handlerSaveDraft() {

    }

    render() {
        const {survey, history, t} = this.props;
        return (
            <div className='padding-responsive default-background-color'>
                <div className="keeping-about-survey" style={{ top : 70, backgroundColor: '#eef3f6'}}>
                    <div className='fix-center bg-white'>
                        <div style={{width: '70%'}}>
                            <div id='about-survey' className='pt-4 pb-3'>
                                <AboutSurvey survey={survey} shouldNotShowServicedAt
                                             screen={'takeRecord'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="survey fix-center" style={{backgroundColor: '#FFFFFF'}}>
                    <div className="pb-3" style={{width:'70%'}}>
                        <div className='info-question'>
                            <div className='content-name-question d-flex'>
                                        <span style={{ wordBreak: 'keep-all' }}
                                              className='title-record-info'>{t('createRecord.Description')}&nbsp;</span>
                                <pre style={{
                                    wordWrap  : 'break-word',
                                    whiteSpace: 'pre-wrap',
                                }}>
                                            {survey.description}
                                        </pre>
                            </div>
                        </div>
                    </div>
                </div>
                <AnswerQuestion survey={survey}
                                previewTakeSurvey
                                history={history}
                                createRecord={this.handlerSubmitTakeRecord.bind(this)}
                                saveDraft={this.handlerSaveDraft.bind(this)}
                                saveBackupData={() =>{}}
                />
            </div>
        );
    }
}

export default PreviewTakeSurveyWeb;
