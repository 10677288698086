import React, { Component } from 'react';
import { withNamespaces }   from 'react-i18next';
import { connect }          from 'react-redux';
import BtnAction            from './BtnAction';
import {
    focusQuestion, pushQuestionEditInStore,
    removeQuestion,
    showReview,
    turnModalEditQuestionSurveyTemplate,
}                           from '../../../../../actions/SurveyTemplate';
import ModalAddLogicGeneral from '../../../ModalAddLogicGeneral';
import {
    KirokuLogicApplicated,
    KirokusSortableHandle, KirokuCardBodyWeb,
    KirokuCardTitleWeb, KirokuCardWeb,
}                           from '../../../../../ui';

@withNamespaces()
@connect(null, dispatch => {
    return {
        removeQuestion: (question) => {
            dispatch(removeQuestion(question));
        },

        focusQuestion                      : (index) => {
            dispatch(focusQuestion(index));
        },
        showReview                         : () => {
            dispatch(showReview());
        },
        turnModalEditQuestionSurveyTemplate: () => {
            dispatch(turnModalEditQuestionSurveyTemplate());
        },
        pushQuestionEditInStore            : (editedQuestion) => {
            dispatch(pushQuestionEditInStore(editedQuestion));
        },
    };
})
class Message extends Component {

    state = {
        show: true,
    };

    showModalAddLogic() {
        this.setState({
            show: !this.state.show,
        });
    }

    closeModal() {
        this.setState({
            show: !this.state.show,
        });
    }

    render() {
        const { question, t, ReviewCreate } = this.props;
        return (
            <div id='component-question'>
                <div id='web-q' className='pb-3'>
                    <KirokuCardWeb>
                        <KirokusSortableHandle>
                        <KirokuCardTitleWeb color={'card-web'} style={{ paddingBottom: 10, paddingTop: 10 }}>
                            <div className='d-flex justify-content-between'>
                                    <div className="question-number pt-10px">{t('createSurvey.QuestionOrder', { index: question.index + 1 })}</div>
                                <KirokuLogicApplicated padding={'5px 5px 5px 7px'} question={question}/>
                                <BtnAction
                                    isHiddenButton
                                    question={question}
                                    showModalAddLogic={this.showModalAddLogic.bind(this)}
                                />
                            </div>
                        </KirokuCardTitleWeb>
                        </KirokusSortableHandle>
                        <KirokuCardBodyWeb>
                            <div>
                                <span hidden={ReviewCreate}
                                      className='content-name-question pb-2'>{question.content}</span>
                                <span hidden={!ReviewCreate}
                                      className='content-name-question'>{t('createSurvey.Heading')}: {question.heading}</span>
                                <span hidden={!ReviewCreate}
                                      className='content-name-question pb-2'>{t('createSurvey.Content')}: {question.content}</span>
                                <p hidden={typeof question.nextQuestion !== 'number'}
                                   className='text-info'><i
                                    className="fas fa-arrow-right text-info"> {t('createRecord.Question')}: {question.nextQuestion + 1}</i>
                                </p>
                            </div>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                    <ModalAddLogicGeneral show={this.state.show} question={question}
                                          closeModal={this.closeModal.bind(this)}/>
                </div>
            </div>

        );
    }
}

export default Message;
