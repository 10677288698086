import React, { Component }                                                          from 'react';
import { connect }                                                                   from 'react-redux';
import KirokuButton                                                                  from '../../../../ui/KirokuButton';
import ModalNotificationConfirmExit
                                                                                     from '../../RecordCreator/Mobile/ModalNoticationExit';
import AboutSurvey
                                                                                     from '../../RecordCreator/AboutSurvey';
import Survey
                                                                                     from '../../RecordCreator/Mobile/Survey';
import { withNamespaces }                                                            from 'react-i18next';
import {
    actionBackAnswer,
    actionBackScreenDetail,
    actionBeginAnswer,
    showConfirmExit,
    turnOffFlagDraftEditRecordMobile, turnOnLoadDraftEditRecord,
}                                                                                    from '../../../../actions/TakeSurvey';
import AboutSurveyReview
                                                                                     from '../../ReviewRecording/AboutSurvey';
import { ViewQuestioned }                                                            from '../../ReviewRecording/ViewQuestioned';
import KirokuPageLoader
                                                                                     from '../../../../ui/KirokuPageLoader';
import moment                                                                        from 'moment';
import { discardDraftEditRecording, saveDraftEditRecording }                         from '../../../../actions/Draft';
import { updateRecord }                                                              from '../../../../actions/Record';
import { deleteVisiblyQuestion, getAnswersToQuestion, getAnswerToQuestionFullLogic } from '../../sevicesInRecord';
import ModalLoadStatusRecord
                                                                                     from '../../RecordCreator/Mobile/ModalLoadStatusRecord';
import { recordService }                                                             from '../../../../services';
import { NOTIFICATION }                                                              from '../../../../actions/Notification';
import KirokuModalHeader
                                                                                     from '../../../../ui/KirokuModalHeader';
import KirokuModalFooter
                                                                                     from '../../../../ui/KirokuModalFooter';
import KirokuModal                                                                   from '../../../../ui/KirokuModal';
import { isErrorPermissionEditRecordLocking }                                        from '../../../../axiosConfig/HandleError';


@withNamespaces()
@connect(state => {
    return {
        recordToEdit           : state.KirokuAIRecord.recordToEdit,
        recordToEditBackup     : state.KirokuAIRecord.recordToEditBackup,
        dataDraftEditRecording : state.KirokuAIDraftRecording.dataDraftEditRecording,
        flagLoadDraftEditRecord: state.TakeSurveyMobile.flagLoadDraftEditRecord,
        beginAnswer            : state.TakeSurveyMobile.beginAnswer,
        reviewRecorded         : state.TakeSurveyMobile.reviewRecorded,
        answeredQuestions      : state.TakeSurveyMobile.recorded,
        // lockInfo               : state.KirokuLockInfo.lockInfo,
    };
}, dispatch => {
    return {
        updateRecord              : async (id, dataRecord) => {
            dispatch(updateRecord(id, dataRecord));
        },
        asyncUpdateRecord         : async (id, dataRecord) => {
            return recordService.updateRecord(id, dataRecord);
        },
        updateRecordFall          : () => {
            dispatch({
                type            : NOTIFICATION,
                typeNotification: 'danger',
                message         : 'createRecord.CreateRecordFail',
            });
        },
        updateRecordSuccess       : () => {
            dispatch(
                {
                    type            : NOTIFICATION,
                    typeNotification: 'success',
                    message         : 'createRecord.UpdateRecordSuccess',
                },
            );
        },
        showConfirmExit           : () => {
            dispatch(showConfirmExit());
        },
        actionBeginAnswer         : () => {
            dispatch(actionBeginAnswer());
        },
        saveDraftEditRecording    : (dataEditRecoding) => {
            dispatch(saveDraftEditRecording(dataEditRecoding));
        },
        turnOffFlagDraftEditRecord: () => {
            dispatch(turnOffFlagDraftEditRecordMobile());
        },
        actionBackAnswer          : () => {
            dispatch(actionBackAnswer());
        },
        actionBackScreenDetail    : () => {
            dispatch(actionBackScreenDetail());
        },
        turnOnLoadDraftEditRecord : () => {
            dispatch(turnOnLoadDraftEditRecord());
        },
        discardDraftEditRecording : (id) => {
            dispatch(discardDraftEditRecording(id));
        },
        trackingQuickUpdateRecord : (record) => {
            dispatch({
                type: 'RECORD_QUICK_EDITED_TRACKING',
                record,
            });
        },
        trackingQuickDraftRecord  : (record) => {
            dispatch({
                type: 'RECORD_QUICK_DRAFTED_TRACKING',
                record,
            });
        },
        unLockRecord: (id, actionType) => {
            dispatch({
                type: "UNLOCK_RECORD",
                id, actionType
            })
        },
        pollingActionFirst: (lockableType, recordId) => {
            dispatch({
                type: 'LOCK_INFORMATION_FIRST',
                lockableType, recordId
            });
        }
    };
})
class RecordEditorMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingSubmit   : false,
            loadingSaveDraft: false,
            keyLoadData     : '',
            serviced_at     : this.props.recordToEdit.serviced_at ? this.props.recordToEdit.serviced_at : null,
            has_time        : this.props.recordToEdit.has_time,
            profile         : JSON.parse(localStorage.getItem('profile')),
        };
    }

    handleCancel() {
        this.props.showConfirmExit();
    }

    handleSaveDraft(recorded) {
        this.setState({
            loadingSaveDraft: true,
        });
        const survey         = this.props.recordToEdit.survey_template;
        const surveyTemplate = {
            ...survey,
            questions      : deleteVisiblyQuestion(survey.questions),
            flowedQuestions: deleteVisiblyQuestion(recorded),
        };
        const dataRecord     = {
            surveyTemplate: surveyTemplate,
            customer      : this.props.recordToEdit.customer,
            hasDraft      : 'draft',
            draftBy       : JSON.parse(localStorage.getItem('profile')).id,
            draftedAt     : moment().unix(),
            serviced_at   : this.state.serviced_at,
            has_time      : this.state.has_time,
            answers       : getAnswersToQuestion(recorded),
        };
        this.props.updateRecord(this.props.recordToEdit.id, dataRecord).then(() => {
            this.props.trackingQuickDraftRecord(dataRecord);
            this.setState({
                loadingSaveDraft: false,
            });
        });
    }

    handleBeginAnswer() {
        this.props.actionBeginAnswer();
    }

    handleBack() {
        this.props.actionBackAnswer();
        if(this.state.loadingSubmit) {
            this.setState({
                loadingSubmit: false
            });
        }
    }

    handleSubmit() {
        const survey = this.props.recordToEdit.survey_template;

        const surveyTemplate = {
            ...survey,
            questions      : deleteVisiblyQuestion(getAnswerToQuestionFullLogic(this.props.answeredQuestions, survey.questions)),
            flowedQuestions: deleteVisiblyQuestion(this.props.answeredQuestions),
        };

        const dataRecord = {
            surveyTemplate: surveyTemplate,
            customer      : this.props.recordToEdit.customer,
            hasDraft      : null,
            draftBy       : null,
            draftedAt     : null,
            serviced_at   : this.state.serviced_at,
            has_time      : this.state.has_time,
            answers       : getAnswersToQuestion(this.props.answeredQuestions),
        };

        this.setState({
            loadingSubmit: true,
        });
        this.props.asyncUpdateRecord(this.props.recordToEdit.id, dataRecord).then(() => {
            this.props.unLockRecord(this.props.recordToEdit.id, 'records')
            this.props.updateRecordSuccess();
            this.props.trackingQuickUpdateRecord(dataRecord);
            if (window.history['previous'] === '/records/create-record') {
                window.history.previous = undefined;
                this.props.history.push(`/customers/customer-detail/${ this.props.recordToEdit.customer.id }`);
            } else {
                this.props.history.goBack();
            }
        }, (error) => {
            if(isErrorPermissionEditRecordLocking(error)) {
                this.props.pollingActionFirst('records', this.props.recordToEdit.id)
            }
            this.props.updateRecordFall();
        });

    }

    componentWillUnmount() {
        this.props.turnOffFlagDraftEditRecord();
        this.props.actionBackScreenDetail();
        this.props.actionBackAnswer();
    }

    handleSaveStatusRecord() {
        this.Survey.autoSaveDraft();
    }

    handleLoadBackup() {
        this.props.turnOnLoadDraftEditRecord();
        this.setState({
            keyLoadData: 'backup',
        });
    }

    // handleLoadDraft() {
    //     this.setState({
    //         keyLoadData: 'statusDraft'
    //     })
    // }

    onChangeDate = (serviced_at, hasTime) => {
        this.setState({
            serviced_at: hasTime ? serviced_at : moment.unix(serviced_at).startOf('day').unix(),
            has_time   : hasTime,
        });
    };

    // handleAutoSaveBackupData(dataBackup) {
    //     const {recordToEdit} = this.props;
    //     let draft = {
    //         id            : recordToEdit.id,
    //         // surveyTemplate: recordToEdit.survey_template,
    //         customer_id   : recordToEdit.customer.id,
    //         customer_name : recordToEdit.customer.name,
    //         dataDraft     : dataBackup,
    //         create_at     : moment().unix(),
    //         serviced_at   : this.state.serviced_at,
    //         has_time      : this.state.has_time,
    //     };
    //     this.props.saveDraftEditRecording(draft);
    // }

    render() {
        const {
                  dataDraftEditRecording,
                  flagLoadDraftEditRecord,
                  t, alterLocking,
                  beginAnswer, reviewRecorded, answeredQuestions, recordToEdit,
                  history,
                  lockInfo,
              } = this.props;

        const {
                  loadingSaveDraft, profile,
                  loadingSubmit, keyLoadData,
              } = this.state;

        const survey   = recordToEdit.survey_template;
        const customer = recordToEdit.customer;
        return (
            <div>
                <KirokuModal show={alterLocking}>
                    <KirokuModalHeader>
                        <div className={'text-center'}>
                            <div className='content-name-question d-flex'>
                                <span style={{ wordBreak: 'keep-all' }}
                                      className='title-record-info'>
                                    {`${lockInfo[ 'user_name' ]} ${t('common.editing')}`} &nbsp;
                                    <span id="wave">
                                        <span className="dot"/>
                                        <span className="dot"/>
                                        <span className="dot"/>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </KirokuModalHeader>
                    <KirokuModalFooter>
                        <KirokuButton onClick={ this.handleCancel.bind(this) } color={'light'}>
                            <span>{ t('createRecord.cancel') }</span>
                        </KirokuButton>
                    </KirokuModalFooter>
                </KirokuModal>
                <ModalNotificationConfirmExit history={ history } customer={ customer }
                                              editor={ lockInfo['user_id'] === profile.id }
                                              actionType='records'/>
                <div hidden={ reviewRecorded }>
                    <div className="p-2" style={ { backgroundColor: '#eef3f6' } }>
                        <div className="status-survey d-flex justify-content-between">
                            <KirokuButton color={ 'light' } onClick={ this.handleCancel.bind(this) }>
                                <span>{ t('createRecord.cancel') }</span>
                            </KirokuButton>
                            <span className="status-survey-content">
                            <KirokuButton disabled={ loadingSaveDraft || !beginAnswer || profile.id !== lockInfo.user_id } color={ 'success' }
                                          onClick={ this.handleSaveStatusRecord.bind(this) }>{ t('createRecord.SaveDraft') }</KirokuButton>
                        </span>
                        </div>
                    </div>
                    <div className="keeping-about-survey" style={ { backgroundColor: '#FFFFFF', top: 0 } }>
                        <AboutSurvey editor user={ recordToEdit.user } hidden={ beginAnswer } survey={ survey }
                                     customer={ customer } lockInfo={ lockInfo } isRecord/>
                    </div>
                    <Survey
                        onRef={ ref => {
                            this.Survey = ref;
                        } }
                        hidden={ !beginAnswer }
                        survey={ survey }
                        customer={ customer }
                        history={ history }
                        lockInfo={ lockInfo }
                        statusRecord={ {
                            statusDraft: recordToEdit.statusRecord,
                            backup     : flagLoadDraftEditRecord ? dataDraftEditRecording.dataDraft : null,
                        } }
                        keyLoadData={ keyLoadData }
                        saveDraft={ this.handleSaveDraft.bind(this) }
                        // saveBackupData={this.handleAutoSaveBackupData.bind(this)}
                        saveBackupData={ () => {
                        } }
                    />

                    <div className="action-record" hidden={ beginAnswer || !(profile.id === lockInfo['user_id']) }>
                        <KirokuButton
                            color={ 'primary' }
                            style={ { width: '100%' } }
                            onClick={ this.handleBeginAnswer.bind(this) }
                        >
                            <span className="text-tranf">{ t('createRecord.next') }</span>
                        </KirokuButton>
                    </div>
                </div>


                <div hidden={ !reviewRecorded }>
                    { answeredQuestions ? <div>
                        <div className='survey'>
                            <div className="keeping-about-survey" style={ { backgroundColor: '#FFFFFF', top: 0 } }>
                                <AboutSurveyReview
                                    onChangeDate={ this.onChangeDate.bind(this) }
                                    user={ recordToEdit.user }
                                    survey={ survey }
                                    customer={ customer }
                                    serviced_at={ recordToEdit.serviced_at }
                                    hasTime={ recordToEdit.has_time }
                                    isRecord
                                    isMobile
                                    lockInfo={ lockInfo }
                                    create_at={ recordToEdit.create_at }
                                    screen={ 'editRecord' }
                                />
                            </div>
                            <div className="survey" style={ { backgroundColor: '#FFFFFF' } }>
                                <div className='wraper-question'>
                                    <div className='wraper-question'>
                                        <div className="background-question-review">
                                            <div className='info-question'>
                                                <div className='content-name-question'>
                                                    <div
                                                        className='title-record-info'>{ t('createRecord.Description') }</div>
                                                    { survey.description }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ViewQuestioned questions={ answeredQuestions }/>
                            <div className="action-record">
                                <div>
                                    <KirokuButton color={ 'light' } onClick={ this.handleBack.bind(this) }>
                                        <span className="text-tranf">{ t('createRecord.back') }</span>
                                    </KirokuButton>
                                    { loadingSubmit ?
                                        <KirokuButton color={ 'primary' } disabled>
                                            <span className="text-tranf">{ t('createRecord.Submit') }</span>
                                        </KirokuButton> :
                                        <KirokuButton color={ 'primary' } onClick={ this.handleSubmit.bind(this) }
                                                      disabled={ !(profile.id === lockInfo['user_id']) }>
                                            <span className="text-tranf">{ t('createRecord.Submit') }</span>
                                        </KirokuButton>
                                    }
                                </div>
                            </div>
                        </div>
                    </div> : <KirokuPageLoader/>
                    }
                </div>
                <ModalLoadStatusRecord
                    dataBackup={ dataDraftEditRecording }
                    handleLoadBackup={ this.handleLoadBackup.bind(this) }
                />
            </div>
        );
    }
}

export default RecordEditorMobile;
