import _ from 'lodash';

const defaultBatchActionRecordsBeta = {
    sortByUpdateAt  : 'update_at',
    orderByUpdateAt : 'DESC',
    sortByServiceAt : 'serviced_at',
    orderByServiceAt: 'DESC',
    useSortBy       : 'serviced_at',
    useOrderBy      : 'DESC',
    records     : [],
};

const BatchActionRecordsBeta = (state = defaultBatchActionRecordsBeta, action) => {
    switch (action.type) {
        case 'CHANGE_ORDER_BATCH_ACTION_RECORD_BETA':
            if (action.sortBy === 'serviced_at') {
                return {
                    ...state,
                    sortByServiceAt : 'serviced_at',
                    orderByServiceAt: action.orderByNew,
                    useSortBy       : action.sortBy,
                    useOrderBy      : action.orderByNew,
                    records     : state.records.sort((a, b) => {
                        if(action.orderByNew === 'ASC') {
                            return a.serviced_at - b.serviced_at
                        } else {
                            return b.serviced_at - a.serviced_at;
                        }
                    }),
                };
            }
            if (action.sortBy === 'update_at') {
                return {
                    ...state,
                    sortByUpdateAt : 'update_at',
                    orderByUpdateAt: action.orderByNew,
                    useSortBy      : action.sortBy,
                    useOrderBy     : action.orderByNew,
                    records    : state.records.sort((a, b) => {
                        if(action.orderByNew === 'ASC') {
                            return a.update_at - b.update_at;
                        } else {
                            return b.update_at - a.update_at
                        }
                    }),

                };
            }
            return {
                ...state,
            };

        case 'LOAD_RECORD_BETA_FOR_BATCH_ACTION':
            if(action.useSortBy === 'records.last_action_at') {
                return {
                    ...state,
                    records: action.records,
                    useSortBy       : 'update_at',
                    useOrderBy      : action.useOrderBy,
                    orderByUpdateAt : action.useOrderBy
                };
            }
            return {
                ...state,
                records: action.records,
                useSortBy       : 'serviced_at',
                useOrderBy      : action.useOrderBy,
                orderByServiceAt: action.useOrderBy,
            };
        case 'CHECKED_RECORDS_BATCH_ACTION' :
            let recordsTmp = [...state.records];
            recordsTmp[action.index] = {
                ...recordsTmp[action.index],
                isChecked: !recordsTmp[action.index].isChecked
            };
            return  {
                ...state,
                records: recordsTmp
            };
        case 'CHECKED_ALL_RECORDS_BATCH_ACTION':
            let isSelectAllRecordsSelected = _.findIndex(state.records, (ctm) => (!ctm.isChecked)) === -1;
            let ctmSelectedCheckAll = state.records.map(ctm => ({...ctm, isChecked: !isSelectAllRecordsSelected}));

            return {
                ...state,
                records: ctmSelectedCheckAll
            };
        default:
            return {
                ...state,
            };
    }
};
export default BatchActionRecordsBeta;
