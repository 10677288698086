import React, {Component}                     from 'react';
import {connect}                              from "react-redux";
import {nextQuestionFromSurvey, createSurvey, resetStateCreateSurveyMobile} from "../../../../actions/SurveyMobile";
import {detailSurvey}                         from "../../../../actions/SurveyTemplate";
import KirokuLoading                          from "../../../../ui/KirokuLoading";
import ReviewEditSurvey                       from "../../SurveyEditor/Mobile/ReviewEditSurvey";
import Survey                                 from '../../SurveyEditor/Mobile/Survey';
import Question                               from '../../SurveyEditor/Mobile/Question';
import ComponentAddQuestion                   from '../../SurveyEditor/Mobile/ComponentAddQuestion';

@connect(state => {
    return {
        survey: state.KirokuSurveyTemplateMobile,
        loading: state.KirokuSurveyTemplateMobile.loading,
        showReview: state.KirokuSurveyTemplateMobile.showReview,
        showSurvey: state.KirokuSurveyTemplateMobile.showSurvey,
        index: state.KirokuSurveyTemplateMobile.orderQuestion,
        lastQuestion: state.KirokuSurveyTemplateMobile.lastQuestion,
        typeComponent: state.KirokuSurveyTemplateMobile.typeComponent
    }
}, dispatch => {
    return {
        createSurvey: () => dispatch(createSurvey(true)),
        detail: surveyId => dispatch(detailSurvey(surveyId)),
        backToComponent: (typeComponent, options) => dispatch(nextQuestionFromSurvey(typeComponent, options)),
        resetStateCreateSurveyMobile: () => {
            dispatch(resetStateCreateSurveyMobile())
        }
    }
})
class SurveyEditorMobile extends Component {

    componentWillMount() {
        this.props.detail(this.props.id)
    }

    backToComponent = () => {
        this.props.backToComponent('Survey');
    };

    createSurvey = () => {
        this.props.createSurvey();
        this.props.history.push('/surveys');
    };

    componentMap = {
        Survey: Survey,
        Question: Question,
        Review: ReviewEditSurvey,
        AddQuestion: ComponentAddQuestion
    };

    backToSurveyList = () => {
        this.props.resetStateCreateSurveyMobile();
        return this.props.history.push('/surveys');
    };

    render() {
        const {index, survey} = this.props;
        const Component = this.componentMap[this.props.typeComponent];
        return (
            <div>
                {this.props.survey.id ? (
                        <Component
                            id={this.props.id}
                            show={this.props.showSurvey}
                            question={survey.questions[index]}
                            lastQuestion={this.props.lastQuestion}
                            history={this.props.history}
                            backToSurveyList={this.backToSurveyList.bind(this)}
                            backQuestion={this.backToComponent.bind(this)}
                            handleSubmit={this.createSurvey.bind(this)}
                        />
                    )
                    : (<KirokuLoading/>)}
            </div>
        );
    }
}

export default SurveyEditorMobile;
