import React, {Component} from 'react';
import KirokuButton       from "./KirokuButton";

export default class KirokuActionPage extends Component {
    render() {
        return (
            <span {...this.props} className='title-page-component'>
                <KirokuButton color={'primary'}>
                   <span>{this.props.children}</span>
                </KirokuButton>
            </span>
        )
    }
}