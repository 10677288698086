import React, {Component}                                                  from 'react';
import {Link}                                                              from 'react-router-dom';
import {connect}                                                           from 'react-redux';
import {
    filterSurveyWithCondition,
    handleSearchSurvey,
    setCurrentPage
}                                                                          from '../../../actions/Survey';
import {resetStateSurvey, saveQueryConditionSurvey, removedQueryCondition} from '../../../actions/SurveyTemplate';
import {withNamespaces}                                                    from 'react-i18next'
import {
    KirokuButton,
    KirokuCardWeb,
    KirokuCheckBox,
    KirokuInputSearcher, KirokuLabel,
    KirokuLoading,
    KirokuTable,
    SearchNotFound,
    TBody,
    THeader
}                                                                          from '../../../ui';
import moment                                                              from 'moment';
import {currentPage}                                                       from '../../../actions/CurrentPage';
import ModalFilterSurveyWeb                                                from './ModalFilterSurveyWeb';
// import KirokuCheckDraft                                                    from '../../../ui/KirokuCheckDraft';
import ModalAssignCustomer                                                 from './ModalAssignCustomer';
import {scrollService, surveyBuilderCondition}                             from '../../../services';
import KirokuPagination                                                    from "../../../ui/KirokuPagination";
import withPermissions                                                     from "../../HOC/WithPermissions";
import LinkComponent                                                       from "../../HOC/LinkComponent";
import ThComponent      from "../../HOC/ThComponent";
import TdComponent      from "../../HOC/TdComponent";
import KirokuTitlepage  from "../../../ui/KirokuTitlepage";
import KirokuActionPage from "../../../ui/KirokuActionPage";
import _                from 'lodash';
import config           from '../../../config';

const KirokuAction = withPermissions(KirokuActionPage);
const LinkTo       = withPermissions(LinkComponent);
const BulkAction   = withPermissions(ModalAssignCustomer);
const Th           = withPermissions(ThComponent);
const Td           = withPermissions(TdComponent);

@connect(state => {
    return {
        surveys: state.KirokuAISurvey.surveys,
        isFinish: state.KirokuAISurvey.isFinish,
        totalPage: state.KirokuAISurvey.totalPage,
        currentPage: state.KirokuAISurvey.currentPage,
        surveyForModal: state.KirokuAISurvey.surveyForModal,
        profile: state.KirokuAIUser.profile,
        loading: state.Loading,
        conditions: state.KirokuAIBehavior.conditionSurveys.conditions,
        params: state.KirokuAIBehavior.conditionSurveys.params,
        pagination: state.KirokuAIBehavior.conditionSurveys.pagination,
    }
}, (dispatch) => {
    return {
        handleSearchSurvey: (keyword) => {
            dispatch(handleSearchSurvey(keyword))
        },
        filterSurveyWithCondition: (params) => {
            dispatch(filterSurveyWithCondition(params))
        },
        pageNow: (name) => {
            dispatch(currentPage(name))
        },
        resetStateSurvey: () => {
            dispatch(resetStateSurvey())
        },
        saveQueryCondition: (conditions, params, pagination) => {
            dispatch(saveQueryConditionSurvey(conditions, params, pagination))
        },
        removedQueryCondition: (removeParams) => {
            dispatch(removedQueryCondition(removeParams))
        },
        setCurrentPage: (numberPage) => {
            dispatch(setCurrentPage(numberPage))
        }

    };
})
@withNamespaces()
class SurveysWeb extends Component {
    page = {
        title: <KirokuTitlepage title={this.props.t('common.Surveys')}/>,
        actionPage: <Link
            onClick={this.props.resetStateSurvey} to={'/surveys/survey'}>
            <KirokuAction
                resource-permission={"|surveyList|button-new|"}
            >
                {this.props.t('Surveys.NewSurvey')}
            </KirokuAction>
        </Link>
    };

    state = {
        show: false,
        showModalDelete: false,
        showModal: false,
        showSurveyTemplate: false,
        conditions: {},
        page: 1,
        pageMin: 1,
        totalPage: null,
        currentPage: 1,
        namePage: this.page,
        selected: false,
        surveys: [],
        disabledPrev: true,
        disabledNext: false,
    };

    componentWillMount() {
        if (this.props.conditions.length) {
            this.props.pageNow(this.state.namePage);
            this.makeFilter(this.props.conditions);
        } else {
            this.props.pageNow(this.state.namePage);
            this.props.filterSurveyWithCondition({page: 1});
        }
    }

    makeFilter = (conditions) => {
        let params = surveyBuilderCondition.makeParamsFromCondition(conditions);
        this.setState({
            conditions: {
                ...this.props.params,
            },
            title: params.title,
            page: params.page,
            ...this.props.pagination
        });
        this.props.filterSurveyWithCondition({...params});
    };

    onChangeShowDropDown() {
        this.setState({
            show: !this.state.show
        })
    }

    handleSearch(e) {
        this.setState({
            conditions: {
                ...this.state.conditions,
                title: e.target.value
            },
            title: e.target.value,
            page: 1
        }, () => {
            this.props.setCurrentPage(1);
        });
    }

    filter() {
        let params = surveyBuilderCondition.makeParams(this.state.conditions);
        this.props.filterSurveyWithCondition({...params, title: this.state.title, page: this.state.page});
        this.setState({selected: false})
    }

    handleShowModal() {
        this.setState({
            show: !this.state.show,
            showSurveyTemplate: !this.state.showSurveyTemplate
        })
    }

    makeConditions = (params) => {
        let conditions = Object.keys(params).map(key => {
            if (params[key]) {
                return {[key]: params[key], query: key, value: params[key], label: this.makeLabelCondition(key)}
            }
            return '';
        });

        return conditions.filter(condition => condition !== '');
    };

    makeLabelCondition = (key) => {
        switch (key) {
            case 'status':
                return this.state.conditions.status ? this.props.t('Surveys.Draft') : '';
            case 'creator':
                return this.state.conditions.nameStaff;
            case 'startDate':
                let startDate = this.state.conditions.startDate ? `${moment.unix(this.state.conditions.startDate).format(config.formatDateTh)}` : '';
                return this.props.t(`Surveys.${key}`) + `${startDate}`;
            case 'endDate':
                let endDate = this.state.conditions.endDate ? `${moment.unix(this.state.conditions.endDate).format(config.formatDateTh)}` : '';
                return this.props.t(`Surveys.${key}`) + `${endDate}`;
            default:
                return this.props.t(`RecordList.${key}`)
        }
    };

    filterCondition(params) {
        this.setState({
            conditions: {
                ...params
            },
            page: 1
        }, () => {
            const conditionFilter = surveyBuilderCondition.makeParams(params);
            this.props.filterSurveyWithCondition({...conditionFilter, title: this.state.title, page: this.state.page});
            this.saveQueryCondition();
        });

    }

    closeModal() {
        this.setState({
            showModal: !this.state.showModal,
            showSurveyTemplate: !this.state.showSurveyTemplate
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.currentPage || nextProps.surveys) {
            this.setState({
                currentPage: nextProps.currentPage,
                page: nextProps.currentPage,
                totalPage: nextProps.totalPage,
                surveys: nextProps.surveys,
                disabledPrev: nextProps.currentPage === 1,
                disabledNext: nextProps.currentPage === nextProps.totalPage,
                selected: _.findIndex([...nextProps.surveys], 'isChecked') === 1
            })
        }

        if (nextProps.params !== this.props.params) {
            this.setState({
                conditions: {
                    ...nextProps.params
                },
                title: nextProps.params.title
            })
        }
    }

    handleCheckAll() {

        const {surveys}      = this.state;
        let newSurveysRecord = surveys.map(survey => ({
            ...survey,
            isChecked: !this.state.selected
        }));
        this.setState({
            surveys: newSurveysRecord,
            selected: !this.state.selected
        })
    }

    handleCheckSurveys(index) {
        let count       = 0;
        const {surveys} = this.state;
        surveys[index]  = {
            ...surveys[index],
            isChecked: !surveys[index].isChecked
        };
        this.state.surveys.forEach(result => {
            if (result.isChecked) {
                count++;
            }
        });
        this.setState({
            surveys: surveys,
            selected: count === surveys.length
        });
    }

    disabledCheckbox() {
        this.setState({
            selected: false
        })
    }

    increasePage() {
        let nextPage = this.props.currentPage === this.props.totalPage ? this.props.totalPage : this.props.currentPage + 1;
        if (nextPage === this.props.totalPage) {
            this.setState({
                disabledNext: !this.state.disabledNext,
            })
        }
        scrollService.top();
        this.setState({
            page: nextPage,
            disabledPrev: false,
        }, () => {
            this.filter();
            this.saveQueryCondition();
        });
    }

    decreasePage() {

        let previousPage = this.props.currentPage === this.state.pageMin ? 1 : this.props.currentPage - 1;
        if (previousPage === this.state.pageMin) {
            this.setState({
                disabledPrev: true
            })
        }
        scrollService.top();
        this.setState({
            page: previousPage,
            disabledNext: false,
        }, () => {
            this.filter();
            this.saveQueryCondition();
        });
    }

    handlerSubmitSearch() {
        this.setState({
            page : 1
        }, () => {
            this.filter();
            this.saveQueryCondition();
        });
    }

    saveQueryCondition = () => {
        const {disabledPrev, disabledNext} = this.state;
        const params                       = surveyBuilderCondition.makeParams(this.state.conditions);
        const conditions                   = this.makeConditions({...params, page: this.state.page});
        this.props.saveQueryCondition(conditions, this.state.conditions, {disabledPrev, disabledNext});
    };

    handleRemoveCondition = (condition) => {
        const {conditions}                 = this.props;
        const {staffName}                  = this.state.conditions;
        const {disabledPrev, disabledNext} = this.state;
        let newConditions                  = conditions.filter(itemCondition => itemCondition.label !== condition.label);
        const newParams                    = surveyBuilderCondition.makeParamsFromCondition(newConditions);
        let conditionRemove                = conditions.filter(itemCondition => itemCondition.label === condition.label);
        let removeParams                   = surveyBuilderCondition.makePramsRemoved(conditionRemove);
        this.props.removedQueryCondition(removeParams);
        this.setState({
            conditions: {
                ...newParams,
                staffName
            },
            page: 1
        }, () => {
            this.props.saveQueryCondition(newConditions, this.state.conditions, {disabledPrev, disabledNext});
            this.filter();
        })
    };

    render() {
        let {show, showModalDelete, showModal, showSurveyTemplate, ...currentState} = this.state;
        let {surveys}                                                               = this.state;
        const {t, loading, conditions, currentPage, isFinish}                                 = this.props;
        return (
            <div className='survey-list default-background-color' id={'web'}>
                <div>
                    <ModalFilterSurveyWeb
                        show={!this.state.show}
                        showModal={this.handleShowModal.bind(this)}
                        filterCondition={this.filterCondition.bind(this)}
                    />
                </div>
                <div hidden={this.state.show} className='padding-responsive'>
                    <div className={!surveys.length ? 'display-none-important pt-5' : ''}>
                        <div className='d-flex justify-content-between'>
                            <BulkAction
                                closeModal={this.onChangeShowDropDown.bind(this)}
                                totalPage={this.state.totalPage}
                                currentPage={this.state.page}
                                surveyChoice={this.state.surveys}
                                currentState={currentState}
                                disabledCheckbox={this.disabledCheckbox.bind(this)}
                                show={this.state.show}
                                GPS={'top'}
                                resource-permission={"|surveyList|button-bulkAction|"}
                            />
                            <KirokuPagination
                                className={'pb-3'}
                                disabledPrev={this.state.disabledPrev || this.state.pageMin === this.state.page}
                                disabledNext={this.state.disabledNext || this.state.pageMin === this.props.totalPage}
                                increasePage={this.increasePage.bind(this)}
                                decreasePage={this.decreasePage.bind(this)}
                                currentPage={this.props.currentPage}
                                totalPage={this.props.totalPage}
                            />
                        </div>
                    </div>
                    <div>
                        <KirokuCardWeb>
                            <div>
                                <div className='d-flex justify-content-between p-3'>
                                    <KirokuButton color={'white'} onClick={this.handleShowModal.bind(this)}>
                                        <span className={'pr-2'}>{t('Surveys.FilterBy')}</span>
                                        <i className='fa fa-chevron-down text-secondary'
                                           style={{fontSize: '10px'}}/>
                                    </KirokuButton>
                                    <div className='form-search-survey-web'>
                                        <KirokuInputSearcher
                                            value={this.state.title}
                                            onSubmit={this.handlerSubmitSearch.bind(this)}
                                            onChange={this.handleSearch.bind(this)}
                                            id={'inputSearchWebTemplate'}
                                        />
                                    </div>
                                </div>
                                <div className='pl-3'>
                                    {
                                        conditions.map((condition, index) => {
                                            return condition.value && condition.query !== 'page' && condition.query !== 'title' ?
                                                <span key={index} className='mr-6'>
                                                    <KirokuLabel
                                                        style={{
                                                            border: 'solid 1px #1991eb'
                                                        }}
                                                        onClick={this.handleRemoveCondition.bind(this, condition)}
                                                        color={'default'}>
                                                        <span className='mt-1'>{condition.label}</span>
                                                        <span className='align-icon'>&times;</span>
                                                    </KirokuLabel>
                                                        </span> : ''
                                        })
                                    }
                                </div>
                            </div>
                            {!isFinish ? <KirokuLoading/> :
                                surveys.length ?
                                <div className='pt-2'>
                                    {!loading ?
                                        <KirokuTable>
                                            <THeader>
                                                <tr>
                                                    <Th
                                                        resource-permission={'|surveyList|button-edit|'}
                                                        style={{width: 50}}>
                                                        <KirokuCheckBox onChange={this.handleCheckAll.bind(this)}
                                                                        checked={this.state.selected}/>
                                                    </Th>
                                                    <th style={{minWidth: 180}}>{t('common.Title')}</th>
                                                    <th>{t('common.Description')}</th>
                                                    <th style={{minWidth: 80}}>{t('common.Creator')}</th>
                                                    <th>{t('common.DateCreated')}</th>
                                                    <Th
                                                        resource-permission={'|surveyList|button-edit|'}
                                                        className='text-center'
                                                        style={{width: 250}}>{t('common.Action')}</Th>
                                                </tr>
                                            </THeader>
                                            <TBody>

                                                {
                                                    surveys.map((survey, index) =>
                                                        <tr key={survey.id}>
                                                            <Td resource-permission={'|surveyList|button-edit|'}>
                                                                <KirokuCheckBox
                                                                    onChange={this.handleCheckSurveys.bind(this, index)}
                                                                    checked={survey.isChecked || false}
                                                                /></Td>
                                                            <td>
                                                                <Link to={`/surveys/survey-detail/${survey.id}`}>
                                                                    <span>{survey.title}</span>
                                                                    {/*<span className='pl-2'><KirokuCheckDraft*/}
                                                                    {/*draftable={survey}/></span>*/}
                                                                </Link>
                                                            </td>
                                                            <td>{survey.description}</td>
                                                            <td>{survey.create_by}</td>
                                                            <td style={{whiteSpace : 'nowrap'}}>{moment.unix(survey.create_at).format(config.formatDateTimeTh)}</td>
                                                            <Td resource-permission={'|surveyList|button-edit|'}>
                                                                <div style={{minWidth: 250}}
                                                                    className='d-flex justify-content-between p-1 fix-icon-align'>
                                                                    <LinkTo
                                                                        href={`/surveys/survey/${survey.id}`}
                                                                        icon={<i
                                                                            className="fas fa-pencil-alt text-secondary"/>}
                                                                        text={t('common.Edit')}
                                                                        color={'text-dark'}
                                                                        resource-permission={"|surveyList|button-edit|"}
                                                                    />

                                                                    <LinkTo
                                                                        href={`/surveys/survey-duplicate/${survey.id}`}
                                                                        icon={<i
                                                                            className="fas fa-clone text-secondary"/>}
                                                                        text={t('common.Duplicate')}
                                                                        color={'text-dark'}
                                                                        resource-permission={"|surveyList|button-duplicate|"}
                                                                    />
                                                                    <Link to={{
                                                                        pathname: `/surveys/survey-preview`,
                                                                        state: {id: survey.id}
                                                                    }}>
                                                                        <i className="fas fa-binoculars text-secondary"/><span
                                                                        className='ml-1  text-dark'>{t('common.Preview')}</span>
                                                                    </Link>
                                                                </div>
                                                            </Td>
                                                        </tr>
                                                    )}
                                            </TBody>
                                        </KirokuTable> : <KirokuLoading/>}
                                </div> : <SearchNotFound
                                    message={t('NullPage.survey')}

                                />}

                        </KirokuCardWeb>
                        <div className={!surveys.length ? 'display-none-important' : ''}>
                            <div className='pt-10 d-flex justify-content-between'>
                                <BulkAction
                                    closeModal={this.onChangeShowDropDown.bind(this)}
                                    totalPage={this.state.totalPage}
                                    currentPage={this.state.page}
                                    surveyChoice={this.state.surveys}
                                    currentState={currentState}
                                    disabledCheckbox={this.disabledCheckbox.bind(this)}
                                    show={this.state.show}
                                    resource-permission={"|surveyList|button-bulkAction|"}
                                />
                                <KirokuPagination
                                    disabledPrev={this.state.disabledPrev || this.state.pageMin === this.state.page}
                                    disabledNext={this.state.disabledNext || this.state.pageMin === this.props.totalPage}
                                    increasePage={this.increasePage.bind(this)}
                                    decreasePage={this.decreasePage.bind(this)}
                                    currentPage={currentPage}
                                    totalPage={this.props.totalPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SurveysWeb;
