import {
    GET_SURVEY_BY_ID,
    LOAD_SURVEY_TEMPLATE_OF_CUSTOMER,
}                          from '../actions/Survey';
import { factoryQuestion } from '../components/Records/ClassTypeQuestions/facetoryQuestion';
import CreatorLogicEditRecord
                           from '../components/Records/newTakeRecord/RecordEditorFiledAll/CreatorLogicEditRecord';

function getFlowedQuestions(questions) {
    const creatorLogicEditRecord = new CreatorLogicEditRecord(questions);
    questions.forEach(question => {
        let isDefaultAnswer = false;
        switch (question.type) {
            case 'Single':
                isDefaultAnswer = false;
                question.choices.forEach(question => {
                    if (question.defaultAnswer) {
                        isDefaultAnswer = true;
                    }
                });
                if (isDefaultAnswer) {
                    creatorLogicEditRecord.updateAnswerQuestion(question.index, question.answers);
                }
                break;
            case 'MultiChoice':
                isDefaultAnswer = false;
                question.choices.forEach(question => {
                    if (question.defaultAnswer) {
                        isDefaultAnswer = true;
                    }
                });
                if (isDefaultAnswer) {
                    creatorLogicEditRecord.updateAnswerQuestion(question.index, question.answers);
                }
                break;
            default:
                if (question.defaultAnswer) {
                    creatorLogicEditRecord.updateAnswerQuestion(question.index, question.answers);
                }
        }
    });
    return creatorLogicEditRecord.toRecord();

}

export const SetDefaultAnswerRecordMiddleware = () => next => action => {
    let questions     = [];
    let questionLib   = [];
    let flowQuestions = [];
    switch (action.type) {
        case LOAD_SURVEY_TEMPLATE_OF_CUSTOMER:
            const questionFromAction = action.surveyTemplateOfCustomer.survey.questions;
            questions                = questionFromAction.map(question => factoryQuestion(question, true));
            questionLib              = questions.map(question => {
                return question.toJSON();
            });
            flowQuestions            = getFlowedQuestions(questionLib).length ? getFlowedQuestions(questionLib) : null;
            return next({
                ...action,
                surveyTemplateOfCustomer: {
                    ...action.surveyTemplateOfCustomer,
                    survey      : {
                        ...action.surveyTemplateOfCustomer.survey,
                        questions      : questionLib,
                        flowedQuestions: flowQuestions,
                    },
                    statusRecord: flowQuestions ? {
                        flow     : flowQuestions,
                        questions: questionLib,
                    } : null,
                },
            });
        case GET_SURVEY_BY_ID:
            const questionDetail = action.detailSurvey.questions;
            questions            = questionDetail.map(question => factoryQuestion(question, true));
            questionLib          = questions.map(question => {
                return question.toJSON();
            });
            return next({
                ...action,
                detailSurvey: {
                    ...action.detailSurvey,
                    questions: questionLib,
                },

            });
        default:
            return next(action);
    }
};

