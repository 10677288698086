class MetadataService {

    constructor(axios, config) {
        this.config = config;
        this.axios  = axios;
    }

    get metadata() {
        return this.axios.get('/metadata');
    }
}

export default MetadataService;