import React, {Component}                                          from 'react';
import '../customers.css';
import {connect}                                                   from "react-redux";
import {DEFAULT_CUSTOMER, loadCustomerById, loadCustomerFromDraft} from "../../../actions/Customer";
import {CheckDivide, KirokuPageLoader}                             from "../../../ui";
import {loadSurveyListPage}                                        from "../../../actions/Survey";
import ComponentCustomerEditorMobile
                                                                   from "./ComponentCustomerEditorMobile";
import ComponentCustomerEditorWeb
                                                                   from "./ComponentCustomerEditorWeb";
import {customerService, scrollService}                            from "../../../services";
import KirokuTitlepage
                                                                   from "../../../ui/KirokuTitlepage";
import {withNamespaces}                                            from "react-i18next";
import {loadUsers}                                                 from "../../../actions/User";
import {loadTeam}                                                  from "../../../actions/Team";
import {NOTIFICATION}                                              from "../../../actions/Notification";

@withNamespaces()
@connect(state => {
    return {
        customer: state.KirokuAICustomer.customer,
        surveys: state.KirokuAISurvey.surveys,
        isLoadingCustomer: state.KirokuAICustomer.isLoadingCustomer,
        isDraftLoading: state.KirokuAICustomer.isDraftLoading,
        users: state.KirokuAIUser.users,
        allTeam: state.KirokuAITeam.allTeam,
        loadingUserSuccess: state.KirokuAIUser.loadingUserSuccess,
        isLoadingTeam: state.KirokuAITeam.isLoading
    }
}, dispatch => {
    return {
        loadCustomer: (id) => {
            dispatch(loadCustomerById(id))
        },
        resetState: () => {
            dispatch({
                type: DEFAULT_CUSTOMER
            })
        },
        loadSurveys: () => {
            dispatch(loadSurveyListPage())
        },
        loadUsers: () => {
            dispatch(loadUsers())
        },
        loadTeams: () => {
            dispatch(loadTeam())
        },
        loadDraft: (typeDraft, customerId) => {
            dispatch(loadCustomerFromDraft(typeDraft, customerId))
        },
        notification: () => {
            dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'success',
                    message: 'message.UpdateSuccess'
                }
            )
        },
        trackingUpdateCustomer: (customer) => {
            dispatch({
                type: "CUSTOMER_UPDATED",
                customer,
            })
        }
    };

})

class CustomerEditor extends Component {
    page = {
        title: <KirokuTitlepage title={this.props.t('common.Customer')}/>,
    };

    state = {
        errors: {
            name: false,
            team_id: false,
            certification_number: false
        },
        isLoading: false,
    };

    componentWillMount() {
        scrollService.top();
        this.props.loadTeams();
        this.props.loadUsers();
        this.props.loadSurveys();
        this.props.loadCustomer(this.props.match.params.id);
        this.props.loadDraft('customer', this.props.match.params.id);
    }

    handleCreator(customer) {
        return customerService.edit(customer.id, customer).then(() => {
            this.props.trackingUpdateCustomer(customer);
            this.props.history.push(`/customers/customer-detail/${customer.id}`);
            this.props.notification(this.props.t);
        });
    }

    componentWillUnmount() {
        this.props.resetState();
        this.setState({
            isLoading: false
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.isLoadingTeam && nextProps.loadingUserSuccess && nextProps.isLoadingCustomer) {
            this.setState({
                isLoading: true,
            })
        }
    }

    render() {
        let mobile = <ComponentCustomerEditorMobile
            handleSubmit={this.handleCreator.bind(this)}
            customer={this.props.customer}
            errors={this.state.errors}
            surveys={this.props.surveys}
            users={this.props.users}
            teams={this.props.allTeam}
            history={this.props.history}
        />;
        let web    = <ComponentCustomerEditorWeb
            handleSubmit={this.handleCreator.bind(this)}
            customer={this.props.customer}
            surveys={this.props.surveys}
            users={this.props.users}
            history={this.props.history}
            teams={this.props.allTeam}
            errors={this.state.errors}
        />;

        return this.state.isLoading ? (
            <div className='customer-detail default-background-color'>
                <CheckDivide mobile={mobile} web={web}/>
            </div>
        ) : <KirokuPageLoader/>;
    }
}

export default CustomerEditor;

