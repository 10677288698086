import React, { Component } from "react";

class KirokuShortDateItem extends Component {
    render() {
        return (
            <div {...this.props} className='mr-3 shortcut-content'>{this.props.children}</div>
        )
    }
}

export default KirokuShortDateItem;