import connect              from 'react-redux/es/connect/connect';
import { closeConfirmExit } from '../../../../actions/TakeSurvey';
import KirokuModal          from '../../../../ui/KirokuModal';
import KirokuModalHeader    from '../../../../ui/KirokuModalHeader';
import KirokuModalFooter    from '../../../../ui/KirokuModalFooter';
import KirokuButton         from '../../../../ui/KirokuButton';
import React, { Component } from 'react';
import { withNamespaces }   from 'react-i18next';
import { scrollService }    from '../../../../services';

@withNamespaces()
@connect(state => {
    return {
        modalConfirmExit: state.TakeSurveyMobile.modalConfirmExit,
        recordToEdit: state.KirokuAIRecord.recordToEdit,
    }
}, dispatch => {
    return {
        closeConfirmExit: () => {
            dispatch(closeConfirmExit())
        },
        defaultTakeRecordPage : () =>{
            dispatch({
                type: "DEFAULT_TAKE_RECORD_PAGE"
            })
        },
        unLockRecord: (id, actionType) => {
            dispatch({
                type: "UNLOCK_RECORD",
                id, actionType
            })
        }
    }
})
class ModalNotificationConfirmExit extends Component {

    handleCancel() {
        this.props.closeConfirmExit()
    }

    confirmYes() {
        const {editor, actionType, customerId } = this.props;

        if(window.history.previous === "/records/new-create-record" ||
            window.history.previous === "/records/create-record") {
            this.props.history.push(`/customers/customer-detail/${customerId || this.props.recordToEdit.customer.id }`);
        } else if(window.history.previous === "/records-beta-detail") {
            this.props.history.push('/records-beta');
            window.history.previous = undefined;
            scrollService.top();
        } else {
            this.props.history.goBack();
        }

        if (editor && this.props.recordToEdit) {
            this.props.unLockRecord(this.props.recordToEdit.id, actionType)
        }
        window.history.previous = undefined;
        this.props.closeConfirmExit();
        this.props.defaultTakeRecordPage();
    }

    render() {
        const {modalConfirmExit, t} = this.props;
        return (
            <KirokuModal show={modalConfirmExit}>
                <KirokuModalHeader>
                    <div className={'text-center'}>
                        <h6 className='f-bold'> {t('createRecord.AreYouSureYouWantToLeaveThisPage')}</h6>
                        <p>{t('createRecord.ChangesYouMadeMayNotBeSaved')}</p>
                    </div>
                </KirokuModalHeader>
                <KirokuModalFooter>
                    <KirokuButton onClick={this.handleCancel.bind(this)} color={'white'}>
                        {t('createRecord.No')}
                    </KirokuButton>
                    <KirokuButton onClick={this.confirmYes.bind(this)} color={'primary'}>
                        {t('createRecord.Yes')}
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>
        );
    }
}

export default ModalNotificationConfirmExit;
