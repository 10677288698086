import React, { Component }                                           from 'react';
import { KirokuTable, THeader, TBody, SearchNotFound, KirokuLoading } from '../../../../ui';
import { connect }                                                    from "react-redux";
import { withNamespaces }                                             from "react-i18next";
import FormatTimestamp                                                from "../../CommonComponent/FormatTimestamp";

@withNamespaces()
@connect(state => {
    return {
        records         : state.DeletedRecords.records,
        useOrderBy      : state.DeletedRecords.useOrderBy,
        orderByDelete_at: state.DeletedRecords.orderByDelete_at,
        orderByCreate_at: state.DeletedRecords.orderByCreate_at,
        pageSize        : state.DeletedRecords.pageSize,
        loading         : state.Loading
    }
}, dispatch => {
    return {
        changeOrderby: (field, typeOrder) => {
            dispatch({
                type: 'CHANGE_ORDER_BY',
                field, typeOrder
            })
        },
    }
})
class TableRecords extends Component {

    handleChangeOrderby(fieldOrder) {
        const { useOrderBy, orderByDelete_at, orderByCreate_at } = this.props;

        let typeOrder = useOrderBy.type === 'ASC' ? 'DESC' : 'ASC';

        if (fieldOrder === 'delete_at') {
            typeOrder = orderByDelete_at.type === 'ASC' ? 'DESC' : 'ASC';
        } else {
            typeOrder = orderByCreate_at.type === 'ASC' ? 'DESC' : 'ASC';
        }
        this.props.changeOrderby(fieldOrder, typeOrder);
    }

    render() {
        const { t, useOrderBy, records, loading,  orderByDelete_at, orderByCreate_at } = this.props;
        return (
            <div className={'bg-white'}>
                {records.length ?
                    <div>
                        {!loading ?
                            <KirokuTable>
                                <THeader>
                                    <tr>
                                        <th className="deleted-list-th">{t('DeletedList.customerName')}</th>
                                        <th className="deleted-list-th">{t('DeletedList.templateName')}</th>
                                        <th className="deleted-list-th">
                                            <div className={'d-flex cursor-pointer'}
                                                 onClick={this.handleChangeOrderby.bind(this, 'delete_at')}>
                                                <div>{t('DeletedList.deletedAtField')}</div>
                                                <div className="p-1"/>
                                                <div className={`icon-order 
                                                    ${useOrderBy.field === 'delete_at' ? 'color-blue-icon-order' : ''}`
                                                }>
                                                    <i hidden={orderByDelete_at.type === 'DESC'} className="fas fa-lg fa-angle-up"/>
                                                    <i hidden={orderByDelete_at.type === 'ASC'} className="fas fa-lg fa-angle-down"/>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="deleted-list-th">
                                            <div className={'d-flex cursor-pointer'}
                                                 onClick={this.handleChangeOrderby.bind(this, 'create_at')}
                                            >
                                                <div>{t('DeletedList.createdAtField')}</div>
                                                <div className="p-1"/>
                                                <div className={`icon-order 
                                                    ${useOrderBy.field === 'create_at' ? 'color-blue-icon-order' : ''}`
                                                }>
                                                    <i hidden={orderByCreate_at.type === 'DESC'} className="fas fa-lg fa-angle-up"/>
                                                    <i hidden={orderByCreate_at.type === 'ASC'} className="fas fa-lg fa-angle-down"/>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </THeader>
                                <TBody>
                                    {
                                        records.map((surveyTemplate, index) => {
                                            return (
                                                <tr key={index}
                                                    className={`${index === records.length - 1 ? 'border-bottom-none' : 'border-bottom'}`}>
                                                    <td className={'color-blue title-table-del-name'}>{surveyTemplate.name}</td>
                                                    <td className={'color-blue title-table-del-name'}>{surveyTemplate.title}</td>
                                                    <td className={'width-table-create-del-at title-table-del field-date-time-del'}>
                                                        <FormatTimestamp timestampUnix={surveyTemplate.delete_at} isAlign={true}/></td>
                                                    <td className={'width-table-create-del-at title-table-del field-date-time-del'}>
                                                        <FormatTimestamp timestampUnix={surveyTemplate.create_at} isAlign={true}/></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </TBody>
                            </KirokuTable> : <KirokuLoading/>}
                    </div>
                    : <SearchNotFound/>}
            </div>
        )
    }
}

export default TableRecords
