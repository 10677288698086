import React, {Component}                                                  from 'react';
import { KirokuButton, KirokuModal, KirokuModalFooter, KirokuModalHeader } from '../../ui';
import { withNamespaces }                                                  from 'react-i18next';

@withNamespaces()
class ModalAskReload extends Component {

    render() {
        const {t, closeModal, handleCancel, handleConfirm, isOpen, userName} = this.props
        return <KirokuModal show={isOpen} onCloseModal={() => { closeModal() }}>
            <KirokuModalHeader>
                <div className={'text-center'}>
                    {t('common.It seems the record has been updated by', {userName: userName})}.
                </div>
            </KirokuModalHeader>
            <KirokuModalFooter>
                <KirokuButton
                    onClick={ () => { handleCancel() } }
                    color={'white'}>
                    {t('createRecord.No')}
                </KirokuButton>
                <KirokuButton color={'primary'} style={{ width: 110 }}
                              onClick={ () => { handleConfirm()} }>
                    {t('createRecord.Yes')}
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    }
}
export default ModalAskReload;
