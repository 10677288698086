import React, {Component} from 'react'
import KirokuModal        from "../../../ui/KirokuModal";
import KirokuModalBody    from "../../../ui/KirokuModalBody";
import KirokuModalFooter  from "../../../ui/KirokuModalFooter";
import KirokuButton       from "../../../ui/KirokuButton";
import {connect}          from "react-redux";
import {setRole}          from "../../../actions/User";
import {withNamespaces}   from "react-i18next";

@withNamespaces()

@connect(() => ({}), (dispatch) => {
    return {
        setRoleLeader: (role, userId, params, tags) => {
            dispatch(setRole(role, userId, params, tags))
        },
        initState: () => {
            dispatch({
                type: 'INIT_STATE',
            })
        }
    }
})

class ModalSetRoleLeader extends Component {

    closeModal() {
        this.props.closeModalSetRoleLeader()
    }

    setRole() {
        let userId = this.props.userChoices.map(user => user.id);
        this.props.setRoleLeader('leader', userId, this.props.params, this.props.currentTags);
        this.props.initState();
        this.props.closeModalSetRoleLeader();
    }

    render() {
        let {t} = this.props;
        return (
            <KirokuModal show={this.props.show} onClick={this.closeModal.bind(this)}>
                <KirokuModalBody>
                    <p className='text-center pt-10'>
                        {t('staff.SetRole')}
                    </p>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                        {t('common.Cancel')}
                    </KirokuButton>
                    <KirokuButton onClick={this.setRole.bind(this)}
                                  color={'primary'}>
                        {t('common.Confirm')}
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>

        )
    }

}

export default ModalSetRoleLeader;