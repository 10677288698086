import React, { Component }                     from 'react';
import KirokuInputDate                          from '../../ui/KirokuInputDate';
import moment                                   from 'moment';
import { connect }                              from 'react-redux';
import { queryForAnalysis, resetValueAnalysis } from '../../actions/Analysis';
import { loadCustomerAll, loadCustomerById }    from '../../actions/Customer';
import { withNamespaces }                       from 'react-i18next';
import { KirokuDropDownSearch, KirokuLoading }  from '../../ui';
import DropDownSelectSurvey                     from './DropDownSelectSurvey';
import KirokuTitlepage                          from '../../ui/KirokuTitlepage';
import { currentPage }                          from '../../actions/CurrentPage';
import KirokuCardWeb                            from '../../ui/KirokuCardWeb';
import './style.css';
import { filePathService, scrollService }       from '../../services';
import { Link }                                 from 'react-router-dom';
import CalendarChart                            from './Charts/HelperComponent/CalendarChart';
import _ 										from 'lodash';
const mapStateToProps = state => (
	{
		records: state.KirokuAIAnalysis.records,
		recordsByDate: state.KirokuAIAnalysis.recordsByDate,
		listAllCustomer: state.KirokuAICustomer.ListAllCustomer,
		customer: state.KirokuAICustomer.customer,
		listView: state.KirokuAIAnalysis.listView,
		survey: state.KirokuAIAnalysis.survey,
		loading: state.Loading,
		dataForAnalysis : state.KirokuAIAnalysis.dataForAnalysis
	}
);

const mapDispatchToProps = dispatch => (
	{
		load: (customerId, surveyId, startDate, endDate) => {
			dispatch(queryForAnalysis(customerId, surveyId, startDate, endDate));
		},
		loadCustomerAll: () => {
			dispatch(loadCustomerAll())
		},
		loadCustomerById: id => {
			dispatch(loadCustomerById(id))
		},
		currentPage: (name) => {
			dispatch(currentPage(name))
		},
		resetValueAnalysis: () => {
			dispatch(resetValueAnalysis())
		},
		resetValueDropDownSurveyTemplate: () => {
			dispatch({type: 'RESET_STATE'})
		}
	}
);

@connect(mapStateToProps, mapDispatchToProps)
@withNamespaces()
class Analysis extends Component {

	page = {
		title: <KirokuTitlepage title={this.props.t('common.Analysis')}/>,
		actionPage: null
	};

	state = {
		startDate: moment().add(-1, 'month'),
		endDate: moment(),
		surveyId: NaN,
		customerId: NaN,
		surveyName: this.props.t('Dashboard.SurveyTemplates'),
		customerName: this.props.t('common.Customers'),
		namePage: this.page,
		pleaseCustomer: false,
		pleaseSurvey: false
	};

	componentWillUnmount () {
		this.props.resetValueAnalysis();
		this.props.resetValueDropDownSurveyTemplate()
	}

	componentWillMount () {
		scrollService.top();
		const stateLink = this.props.location.state;
		let customerId = this.state.customerId;
		let surveyId = this.state.surveyId;
		let surveyName = this.state.surveyName;
		let customerName = this.state.customerName;
		if (stateLink) {
			customerId = stateLink.customerId;
			surveyId = stateLink.surveyId;
			surveyName = stateLink.surveyName;
			customerName = stateLink.customerName;
			this.setState({
				surveyId: surveyId,
				customerId: customerId,
				surveyName: surveyName,
				customerName: customerName,
				pleaseCustomer: true,
				pleaseSurvey: true
			});
			this.props.loadCustomerById(customerId);
			this.props.load(customerId, surveyId, moment(this.state.startDate).unix(), moment(this.state.endDate).unix())
		}

		this.props.loadCustomerAll();
		this.props.currentPage(this.state.namePage);
	}

	handleOnChangeDateTime (dateTime, momentTime) {
		this.setState({
			[dateTime]: momentTime
		}, () => {
			this.props.load(this.state.customerId, this.state.surveyId,
				moment(this.state.startDate).startOf('day').unix(),
				moment(this.state.endDate).endOf('day').unix());
		});
	}

	mapDataToDropSelectCustomer = (customers) => {
		const value = customers.map(customer => {
			return {
				value: customer.id,
				component: (
					<div><h6>{customer.name}</h6></div>
				),
				label: customer.name
			}
		});
		return [...value];
	};

	filterCustomer (event) {
		this.setState({
			customerId: event.kirokuSelected.value,
			surveyName: this.props.t('Dashboard.SurveyTemplates'),
			surveyId: NaN,
			pleaseCustomer: true
		});
		this.props.loadCustomerById(event.kirokuSelected.value);
	}

	mapDataToDropSelectSurvey (surveys) {
		if (surveys.length === 0) {
			return [{
				component: (
					<div><h6>{this.props.t('Analysis.noSurveyTemplate')}</h6></div>
				),
				label: this.props.t('Analysis.noSurveyTemplate')
			}];
		}
		const surveysDrop = surveys.map(survey => {
			return {
				value: survey.id,
				component: (
					<div><h6>{survey.title}</h6></div>
				),
				label: survey.title
			}
		});
		return [...surveysDrop];
	}

	filterSurvey (event) {
		this.setState({
			surveyId: event.kirokuSelected.value,
			surveyName: event.kirokuSelected.label,
			pleaseSurvey: true
		}, () => {
			this.props.load(this.state.customerId, event.kirokuSelected.value,
				moment(this.state.startDate).startOf('day').unix(),
				moment(this.state.endDate).endOf('day').unix());
		});
	}

	renderAnswers (question, t) {
		switch (question.type) {
			case "YesNo":
                if(!question.answers) {
                    return t('Analysis.unanswered')
                }
				return t(`createRecord.${question.answers}`);
			case "Single" :
                if(!question.answers.answer) {
                    return t('Analysis.unanswered')
                }
				return question.answers.answer;
			case "MultiChoice" :
				const answerIsChoice = [...question.answers].filter(answer => answer['is_choice']);
                if(!answerIsChoice.length) {
                    return t('Analysis.unanswered')
                }
				return answerIsChoice.map(answer => answer['answer']).join(', ');
			case "Number" :
                if(!question.answers) {
                    return t('Analysis.unanswered')
                }
				return  question.answers;
			case "Time" :
                if(!question.answers) {
                    return t('Analysis.unanswered')
                }
				return moment.unix(question.answers).format('HH:mm');
			case "OnlyDate" :
                if(!question.answers) {
                    return t('Analysis.unanswered')
                }
				return moment.unix(question.answers).format('LL');
			default :
				return;
		}
	}

	renderHistoryAnswer(recordsByDate, question, t) {
		return (
            <div className='col-lg-6 pb-3 pl-2 pr-2 scroll-history-answer'>
                <div className='content-history pl-3'>
                    <div className='mb-1 f-bold pb-2'>{t('Analysis.AnswerHistory')}</div>
                    {recordsByDate.map((record, index) => {
                        return (
                            <ul className='list-style-analysis'
                                key={index}>
                                <li className='list-li'>
                                    <i className="fas fa-circle dot-icon"/><span
                                    className='pl-2 text-list-day'>{record.day}</span>
                                    <ul className='list-style-analysis-2'>
                                        {record.recordOnlyQuestions.map(itemRecord => {
											const isHasTime = _.findIndex(record.recordOnlyQuestions, (itm => itm.has_time)) !== -1;
											return itemRecord['survey_template'].flowedQuestions.map((itemQuestion, index) => {
                                            	if (itemQuestion.content === question.content && itemQuestion.type === question.type) {
													return (
                                                        <li key={index}>
															{itemRecord.has_time ? moment.unix(itemRecord.serviced_at).format('LT') : ''}
                                                            <span className={`pl-2 ${(isHasTime && !itemRecord.has_time) ? 'label-answer-analysis' : ''} `}>
																{this.renderAnswers(itemQuestion, t)}</span>
															&nbsp;&nbsp;&nbsp;
															<Link to={`/records/record/${itemRecord.id}`} target='_blank'>
																{t('Analysis.LinkToRecord')}
															</Link>
                                                        </li>

                                                    )
                                                }
                                                return ''
                                            })
                                        })}
                                    </ul>
                                </li>
                            </ul>
                        )
                    })}
                </div>
            </div>
        )
    }

    checkWidthOfChart(number) {
		if (number < 30) {
			return '20%';
		}else if (number <100) {
			return '40%'
		} else if(number < 150) {
			return '50%';
		} return  '100%'
	}
	handleRedirectPage(record) {
		const {dataForAnalysis} = this.props;
		return this.props.history.push({
			pathname: '/records-beta',
			params: {
				customerId : dataForAnalysis.condition.customerId,
				templateId : dataForAnalysis.condition.templateId,
				startDate :  moment(record.date).startOf('day').unix(),
				endDate : moment(record.date).endOf('day').unix(),
				from : 'analysis'
			}
		})
	}

	render () {
		const {records, listView, listAllCustomer, customer, t, survey, loading, recordsByDate, dataForAnalysis} = this.props;
		const {startDate, endDate, customerName, surveyName, pleaseSurvey, pleaseCustomer} = this.state;
		let newStartDate = moment(startDate).subtract(1, 'days').format('YYYY/M/D');
		let newEndDate = moment(endDate).format('YYYY/M/D');
		let numberMonthSelected = endDate.diff(startDate, 'days');

		let defaultSearch = [];
		return (
			<div className='default-background-color'>
				<div className='padding-responsive'>
					<KirokuCardWeb>
						<div className="padding-responsive">
							<div className={'row'}>
								<div className='col-md-6'>
									<div>
										<label className="mt-2">{t('common.Customer')}</label>
										<KirokuDropDownSearch title={customerName}
															  items={this.mapDataToDropSelectCustomer(listAllCustomer)}
															  icon={'far fa-user text-secondary'}
															  onSelect={this.filterCustomer.bind(this)}
										/>
									</div>
								</div>
								<div className='col-md-6'>
									<div>
										<label className="mt-2">{t('Dashboard.SurveyTemplates')}</label>
										<DropDownSelectSurvey title={surveyName}
															  disabled={!pleaseCustomer}
															  items={this.mapDataToDropSelectSurvey(customer.surveys || defaultSearch)}
															  onSelect={this.filterSurvey.bind(this)}
										/>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-md-6'>
									<label className="mt-2">{t('Surveys.startDate')}</label>
									<KirokuInputDate
										className={"date-chart-search"}
										inputProps={{disabled: false, readOnly: true}}
										onChange={this.handleOnChangeDateTime.bind(this, 'startDate')}
										value={startDate}
									/>
								</div>
								<div className='col-md-6'>
									<label className="mt-2">{t('Surveys.endDate')}</label>
									<KirokuInputDate
										className={"date-chart-search"}
										inputProps={{disabled: false, readOnly: true}}
										onChange={this.handleOnChangeDateTime.bind(this, 'endDate')}
										value={endDate}
									/>
								</div>
							</div>
						</div>
						<hr className='mt-1 mb-1'/>
						{!loading ?
							<div>
								{survey ?
									<div className='padding-responsive pb-0' hidden={listView.length === 0}>
										<div className={`${numberMonthSelected < 100 ? 'chart-smaller': 'chart-larger'}`}
											 style={{ maxWidth:  this.checkWidthOfChart(numberMonthSelected)}}
										>
											<CalendarChart dataAnalysis={dataForAnalysis.data|| []} startDate={newStartDate} endDate={newEndDate} handleOnClick={(record) => this.handleRedirectPage(record)}/>
										</div>
										<div className="info-question">
											<div className='text-title mb-2 mt-1'>{t('common.Title')}</div>
											<div className='question-number mb-2'>{survey.title}</div>
											<div className="text-title mb-2">{t('common.Description')}</div>
											<div className='mb-1'>{survey.description}</div>
										</div>
									</div> :
									<div/>
								}
								{listView.length ?
									<div className='padding-responsive'>
										{!!records[0] && listView.map((question, index) => {
											let QuestionChart = question.chart;
											return (
												<div className='card-aly mb-3' key={index}>
													<div className="bg-title-aly">
														<div className="title-aly">
															{/*{t('createRecord.Question')} {question.questionIndex + 1}*/}
															{question.heading ? question.heading : question.content}
														</div>

													</div>
													<div className="card-body" style={{overflowX: 'auto'}}>
														<div className='row' style={{marginRight: -8, marginLeft: -8}}>
															<div className='col-lg-6 pb-3 pl-2 pr-2 border-analysis'>
																<div className='mb-1'>{question.content}</div>
																<div className='mb-1'>{question.answers}</div>
																<QuestionChart key={index} records={records}
																			   question={question}
																/>
															</div>
                                                            {this.renderHistoryAnswer(recordsByDate, question, t)}
														</div>
													</div>
												</div>
											)
										})}
									</div> :
									<div className="">
										<div className="pt-3 pb-5 text-center">
											<img src={`${filePathService.resolveFilePath('empty-survey.svg')}`}
												 alt="search fail"/>
											<h6 className="pt-3" style={{color: "rgb(9, 30, 66)"}}>
												<div style={{fontWeight: 500}} hidden={pleaseCustomer}>{t('NullPage.record')}</div>
												<div style={{fontWeight: 500}} hidden={(!pleaseSurvey && !pleaseCustomer) || (pleaseSurvey && pleaseCustomer)}>{t('common.PleaseSelect')}</div>
												<div style={{fontWeight: 500}} hidden={(!pleaseCustomer) || (!pleaseSurvey)}>{t('common.NoAnalysis')}</div>
											</h6>
										</div>
									</div>
								}
							</div> : <KirokuLoading/>}
					</KirokuCardWeb>
				</div>
			</div>
		)
	}
}

export default Analysis;
