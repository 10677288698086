import React, {Component}                  from 'react';
import KirokuModalHeader                   from "../../../../ui/KirokuModalHeader";
import KirokuModalBody                     from "../../../../ui/KirokuModalBody";
import KirokuCardList                      from "../../../../ui/KirokuCardList";
import KirokuCard                          from "../../../../ui/KirokuCard";
import KirokuCheckBox                      from "../../../../ui/KirokuCheckBox";
import KirokuModalFooter                   from "../../../../ui/KirokuModalFooter";
import KirokuButton                        from "../../../../ui/KirokuButton";
import KirokuModal                         from "../../../../ui/KirokuModal";
import {connect}                           from "react-redux";
import {documentSelect, documentSelectAll} from "../../../../actions/Document";
import {withNamespaces}                    from "react-i18next";
import ModalRemoveDocument                 from "../ModalRemoveDocument";

@withNamespaces()
@connect(state => {
    return {
        customer: state.KirokuAICustomer.customer,
        documents: state.KirokuAICustomer.documents,
        loading: state.Loading,
    }
}, dispatch => {
    return {
        documentSelect: (index) => {
            dispatch(documentSelect(index))
        },
        documentSelectAll: (status) => {
            dispatch(documentSelectAll(status))
        }
    };
})
class ModalSelectDocument extends Component {

    state = {
        statusChecked: false,
        customers: [],
        customerRecords: [],
        showModalDelete: false,
        countCustomers: {},
        defaultChecked: false
    };

    onChangeSearch() {

    }

    closeModal() {
        this.props.closeModal();
    }

    handleCheckFile(index) {
        this.props.documentSelect(index);
        let documentChecked = this.props.documents.documents.filter(item => item.isChecked).length;
        if (this.props.documents.documents.length === documentChecked) {
            this.setState({
                statusChecked: true
            })
        } else {
            this.setState({
                statusChecked: false
            })
        }
    }

    handleCheckAllFile() {
        this.setState({
            statusChecked: !this.state.statusChecked
        }, () => {
            this.props.documentSelectAll(this.state.statusChecked)
        });
    }

    showModalDelete() {
        this.setState({
            showModalDelete: !this.state.showModalDelete
        });
        this.props.closeModal()
    }

    onSelect() {
        return !this.props.documents.documents.filter(item => item.isChecked).length
    }

    closeModalDelete() {
        this.setState({
            showModalDelete: false
        })
    }

    documentsChoice() {
        return this.props.documents.documents.filter(item => item.isChecked);
    }

    render() {
        let {documents, t} = this.props;
        return (
            <div>
                <KirokuModal show={this.props.show}
                             onCloseModal={this.closeModal.bind(this)}>
                    <KirokuModalHeader>
                        <h6 className="mb-3">{t('common.ChoiceDocument')}</h6>
                    </KirokuModalHeader>
                    <KirokuModalBody>
                        <div id="body-modal-as">
                            <KirokuCardList
                                headerLeft={t('common.DocumentList')}
                                headerRight={
                                    <div className='text-primary' style={{cursor: 'pointer'}}>
                                        <span hidden={this.state.statusChecked}
                                              onClick={this.handleCheckAllFile.bind(this)}
                                        >{t('common.SelectAll')}</span>

                                        <span hidden={!this.state.statusChecked}
                                              onClick={this.handleCheckAllFile.bind(this)}
                                        >{t('common.UnSelect')}</span>
                                    </div>
                                }
                            >
                                <section className="scroll-data-customer">
                                    {documents.documents.map((document, index) => (
                                        <KirokuCard key={document.id}>
                                            <KirokuCheckBox onChange={this.handleCheckFile.bind(this, index)}
                                                            checked={document.isChecked || this.state.defaultChecked}
                                                            content={document.name}/>
                                        </KirokuCard>
                                    ))}
                                </section>
                            </KirokuCardList>
                        </div>
                    </KirokuModalBody>
                    <KirokuModalFooter>
                        <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                            {t('common.Cancel')}
                        </KirokuButton>
                        <KirokuButton disabled={this.onSelect()} onClick={this.showModalDelete.bind(this)}
                                      color={'primary'}>
                            {t('common.Confirm')}
                        </KirokuButton>
                    </KirokuModalFooter>
                </KirokuModal>
                <ModalRemoveDocument
                    closeModal={this.closeModalDelete.bind(this)}
                    documents={this.documentsChoice()}
                    customerId={this.props.customer.id}
                    show={this.state.showModalDelete}
                />
            </div>
        );
    }
}

export default ModalSelectDocument;