import React, {Component}               from 'react';
import {Link}                           from 'react-router-dom';
import {connect}                        from 'react-redux';
import moment                           from 'moment';
import '../customers.css'
import {
    KirokuActionPage,
    KirokuAge,
    KirokuButton,
    KirokuCardWeb,
    KirokuCheckBox,
    KirokuDropdownClose,
    KirokuInputSearcher,
    KirokuLoading,
    KirokuModal,
    KirokuModalBody,
    KirokuModalFooter,
    KirokuPagination,
    KirokuSectionDropdownShowMore,
    KirokuTable,
    KirokuTitlepage,
    TBody,
    THeader
} from '../../../ui';
import {
    deactiveCustomer,
    filterCustomerWithCondition,
    onSearch,
    saveConditionsCustomer
}                                       from '../../../actions/Customer';
import {currentPage}                    from '../../../actions/CurrentPage';
import {withNamespaces}                 from 'react-i18next';
import {filePathService, scrollService} from "../../../services";
import withPermissions                  from "../../HOC/WithPermissions";
import LinkComponent                    from "../../HOC/LinkComponent";
import ThComponent                      from "../../HOC/ThComponent";
import TdComponent                      from "../../HOC/TdComponent";
import KirokuResetFilterCondition from '../../../ui/KirokuResetFilterCondition';
import TabResult                  from "../../Staffs/StaffEditor/TabResult";
import config                     from '../../../config';

const LinkKiroku             = withPermissions(LinkComponent);
const DropDownFilterStatus   = withPermissions(KirokuResetFilterCondition);
const BulkActionCustomerList = withPermissions(KirokuDropdownClose);
const Th                     = withPermissions(ThComponent);
const Td                     = withPermissions(TdComponent);
const ActionPage             = withPermissions(KirokuActionPage);

@withNamespaces()
@connect(state => {
    return {
        conditions: state.KirokuAIBehavior.conditionsCustomer.conditions,
        params: state.KirokuAIBehavior.conditionsCustomer.params,
        loading: state.KirokuAICustomer.loading,
        customersReducer: state.KirokuAICustomer,
        profile: state.KirokuAICustomer.profile,
        defaultTeamTags: state.KirokuAICustomer.defaultTeamTags,
        defaultUserTags: state.KirokuAICustomer.defaultUserTags,
        isLoadingAPI   : state.Loading
    }
}, dispatch => {
    return {
        onSearch: (keyword) => {
            dispatch(onSearch(keyword))
        },
        filterCustomerWithCondition: (params, tags) => {
            dispatch(filterCustomerWithCondition(params, tags))
        },
        currentPageUsed: (name) => {
            dispatch(currentPage(name))
        },
        deactiveCustomers: (customerIds, params, tags) => {
            dispatch(deactiveCustomer(customerIds, params, tags))
        },
        saveConditionsCustomer: (conditions, params) => {
            dispatch(saveConditionsCustomer(conditions, params))
        },
        updateCustomersSuccess: () => {
            dispatch({
                type: "UPDATE_CUSTOMER_SUCCESS"
            })
        }
    };
})
class CustomerListWeb extends Component {
    page  = {
        title: <KirokuTitlepage title={this.props.t('common.Customer')}/>,
        actionPage: <Link to={'/customers/customer-creator'}>
            <ActionPage
                resource-permission={'|customerList|button-new|'}
            >
                {this.props.t('Customer.AddCustomer')}
            </ActionPage></Link>
    };
    state = {
        show: false,
        customerRecords: [],
        showModalDeactive: false,
        check: false,
        deleteCustomer: false,
        statusAction: false,
        pageMin: 1,
        page: 1,
        name: '',
        status: 'active',
        defaultChecked: false,
        namePage: this.page,
        disabledPrev: true,
        disabledNext: false,
        selected: false,
        isDeactive: false,
        conditions: [],
        currentUserTags: [],
        currentTeamTags: [],
        defaultOption: []
    };

    filterItems = [
        {
            value: 'active',
            component: (<div>{this.props.t('common.active')}</div>),
            label: this.props.t('common.active')
        },
        {
            value: 'deactive',
            component: (<div>{this.props.t('common.deactive')}</div>),
            label: this.props.t('common.deactive')
        }
    ];

    bulkActionItems = [
        {
            value: 'deactive',
            component: (<div>{this.props.t('common.deactive')}</div>),
            label: this.props.t('common.deactive')
        }
    ];

    checkDisable = (customerRecords) => {
        return customerRecords.filter(customer => customer.isChecked).length;
    };

    componentWillMount() {
        let defaultTags = [{
            ...this.props.profile,
            tagId: this.props.profile.id,
            key: `user_id${this.props.profile.id}`,
            label: this.props.profile.name,
            value: this.props.profile.name,
            tagType: 'users'
        }];
        if (this.props.conditions.length) {
            this.setState({
                    currentUserTags: this.props.defaultUserTags ? this.props.defaultUserTags : [],
                    currentTeamTags: this.props.defaultTeamTags ? this.props.defaultTeamTags : [],
                    customers: this.props.customers,
                    customerRecords: this.props.customers,
                    totalPages: this.props.totalPages,
                    currentPage: this.props.currentPage,
                    status: this.props.params.status,
                    page: this.props.params.page,
                    name: this.props.params.name,
                    groupedUserOptions:
                        [
                            {
                                label: 'Users',
                                options: this.props.users,
                            }
                        ],
                    groupedTeamOptions: [
                        {
                            label: 'Teams',
                            options: this.props.teams
                        }
                    ]
                }, () => {
                    this.filter();
                    this.saveConditionsCustomers();
                }
            )
        } else {
            this.setState({
                    currentUserTags: this.props.profile.role === "admin" ? [] : defaultTags,
                    currentTeamTags: [],
                    groupedUserOptions:
                        [
                            {
                                label: 'Users',
                                options: this.props.users,
                            }
                        ],
                    groupedTeamOptions: [
                        {
                            label: 'Teams',
                            options: this.props.teams
                        }
                    ],
                    customers: this.props.customers,
                    customerRecords: this.props.customers,
                    totalPages: this.props.totalPages,
                    currentPage: this.props.currentPage,
                    status: this.props.params.status
                },
                () => {
                    this.filter();
                    this.saveConditionsCustomers();
                }
            )
            ;
        }
        this.props.currentPageUsed(this.state.namePage);
    }

    setStatusCustomers(customers) {
        return this.state.customers.map(item => {
            customers.forEach(customer => {
                if (customer.id === item.id) {
                    item.isChecked = customer.isChecked;
                }
            });
            return item;
        });
    }

    handleCheckAll() {
        const {customerRecords} = this.state;
        let newCustomerRecords  = customerRecords.map(customer => ({
            ...customer,
            isChecked: !this.state.selected
        }));

        let customers = this.setStatusCustomers(newCustomerRecords);

        this.setState({
            customerRecords: newCustomerRecords,
            customers: customers,
            selected: !this.state.selected,
            check: this.checkDisable(newCustomerRecords)
        })
    }

    handleCheckSurveys(index) {
        let count               = 0;
        const {customerRecords} = this.state;
        customerRecords[index]  = {
            ...customerRecords[index],
            isChecked: !customerRecords[index].isChecked
        };
        this.state.customerRecords.forEach(result => {
            if (result.isChecked) {
                count++;
            }
        });

        let customers = this.setStatusCustomers(customerRecords);
        this.setState({
            customerRecords: customerRecords,
            customers: customers,
            selected: count === customers.length,

            check: this.checkDisable(customerRecords)
        });

    }

    defaultState = (customers) => {
        return customers.map(customer => ({
            ...customer,
            isChecked: false
        }))
    };

    showModalDeactive() {
        this.setState({
            showModalDeactive: !this.state.showModalDeactive,
            show: !this.state.show
        })
    }

    deactiveCustomer() {
        let customers = this.state.customerRecords.filter(customer => customer.isChecked);
        this.setState({
            customerName: customers.map(customer => customer.name).join(', '),
            id: customers.map(customer => customer.id),
            countCustomer: this.state.check,
            show: !this.state.show
        })

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.customersReducer.isFilter) {
            this.setState({
                    customers: nextProps.customers,
                    selected: false,
                    customerRecords: nextProps.customers,
                    currentUserTags : nextProps.defaultUserTags,
                    currentTeamTags : nextProps.defaultTeamTags
                }, () =>
                    this.props.updateCustomersSuccess()
            );
        }
        if (this.props.params !== nextProps.params) {
            this.setState({
                name: nextProps.params.name,
                status: nextProps.params.status,
                isDeactive: nextProps.params.status === 'deactive',
            })
        }

    }

    closeModal() {
        let defaultState               = this.defaultState(this.state.customers);
        let defaultStateCustomerRecord = this.defaultState(this.state.customerRecords);
        this.setState({
            show: false,
            check: this.checkDisable(defaultState),
            customers: defaultState,
            customerRecords: defaultStateCustomerRecord,
            selected: false
        })
    }

    onSearch(e) {
        this.setState({
            name: e.target.value,
            page: 1
        })
    }

    filterStatusCustomer(status) {
        if (status === 'deactive') {
            this.setState({
                page: 1,
                isDeactive: true,
                status: status || ''
            }, () => this.filter())
        } else {
            this.setState({
                page: 1,
                isDeactive: false,
                status: status || ''
            }, () => this.filter())
        }
    }

    saveConditionsCustomers() {
        let {
                totalPages, pageMin, currentPage, show, customers, customerRecords, loading, disabledPrev, disabledNext,
                selected, showModalDeactive, check, deleteCustomer, statusAction, defaultChecked, namePage, isDeactive,
                ...currentState
            } = this.state;
        this.props.saveConditionsCustomer(this.makeConditions(currentState), currentState);
    }

    makeConditions = (params) => {
        let conditions = Object.keys(params).map(key => {
            if (params[key]) {
                return {[key]: params[key], query: key, value: params[key]}
            }
            return '';
        });
        return conditions.filter(condition => condition !== '');
    };

    filter() {
        let currentTags  = this.state.currentUserTags.concat(this.state.currentTeamTags);
        let {
                totalPages, pageMin, currentPage, show, customers, customerRecords, loading, disabledPrev, disabledNext,
                selected, showModalDeactive, check, deleteCustomer, statusAction, defaultChecked, namePage, isDeactive,
                ...currentState
            } = this.state;
        this.props.filterCustomerWithCondition(currentState, {tags: currentTags});
    }

    increasePage() {
        let nextPage = this.props.currentPage === this.props.totalPages ? this.props.totalPages : this.props.currentPage + 1;
        scrollService.top();
        this.setState({
            page: nextPage,
            selected: false
        }, () => {
            this.filter();
            this.saveConditionsCustomers();
        });
    }

    decreasePage() {
        let previousPage = this.props.currentPage === this.state.pageMin ? 1 : this.props.currentPage - 1;
        scrollService.top();
        this.setState({
            page: previousPage,
            selected: false
        }, () => {
            this.filter();
            this.saveConditionsCustomers()
        });
    }

    deactive() {
        let {
                totalPages, pageMin, currentPage, show, customers, customerRecords, loading, disabledPrev, disabledNext,
                selected, showModalDeactive, check, deleteCustomer, statusAction, defaultChecked, namePage, isDeactive,
                ...currentState
            } = this.state;

        let currentTags  = this.state.currentUserTags.concat(this.state.currentTeamTags);
        this.props.deactiveCustomers(this.state.id, {params : currentState}, {tags: currentTags});
        this.setState({
            show: false,
        })
    }

    isSelect() {
        return !this.state.customers.filter(item => item.isChecked).length
    }

    handlerSubmitSearch() {
        this.setState({page: 1}, () => {
            this.filter();
        });
        this.saveConditionsCustomers()
    }

    handleRemoveCondition(condition) {
        let newConditions = this.state.conditions.map(item => {
            if (condition.query === item.query) {
                return {
                    ...item,
                    value: '',
                    label: '',
                }
            }
            return item;
        });
        let params        = {};
        this.setState({
            conditions: newConditions,
            defaultTag: '',
            currentUserTags: '',
            currentTeamTags: '',
            params: {
                ...this.state.params,
                ...params,
                [condition.query]: '',
            },
            page: 1
        }, () => {
            this.filter();
        });
    }

    handleChangeUserTags = (options) => {
        this.setState({
            currentUserTags: options ? options : this.state.defaultOption
        })
    };

    handleChangeTeamTags = (options) => {
        this.setState({
            currentTeamTags : options ? options : this.state.defaultOption
        })
    };

    handleSearchTags() {
        const currentTags  = this.state.currentUserTags.concat(this.state.currentTeamTags);
        if (currentTags.length) {
            let conditionTag = currentTags.map(tag => {
                return {
                    ...tag,
                    key: tag.id,
                    label: tag.name,
                    value: `${tag.name}${tag.id}`,
                    tagId: tag.id || tag.key,
                    tagType: tag.tagType
                }
            });
            let {
                    totalPages, pageMin, currentPage, show, customers, customerRecords, loading, disabledPrev, disabledNext,
                    selected, showModalDeactive, check, deleteCustomer, statusAction, defaultChecked, namePage, isDeactive,
                    ...currentState
                }            = this.state;

            this.props.filterCustomerWithCondition({...currentState, page: 1}, {tags: conditionTag});
            this.setState({page: 1}, () => {
                this.saveConditionsCustomers();
            });
        } else {
            this.setState({page: 1}, () => {
                this.filter();
                this.saveConditionsCustomers();
            });
        }

    }

    render() {
        let {customers} = this.state;
        let {t}         = this.props;
        return (
            <div>
                <div hidden={!customers.length}>
                    <div
                        className={this.state.isDeactive ? 'd-flex justify-content-end' : `d-flex justify-content-between`}>
                        <div hidden={this.state.isDeactive}>
                            <BulkActionCustomerList
                                resource-permission={'|customerList|button-bulkAction|'}
                                disabled={this.isSelect()}
                                style={{width: 180}}
                                location={'bot'}
                                title={t('common.BulkAction')}
                                items={this.bulkActionItems}
                                onSelect={event => {
                                    if (event.kirokuSelected.value === 'deactive') {
                                        this.deactiveCustomer()
                                    }
                                }}
                            />
                        </div>
                        <KirokuPagination
                            className={'pb-3'}
                            disabledPrev={this.state.pageMin === this.props.currentPage}
                            disabledNext={this.props.currentPage === this.props.totalPages}
                            increasePage={this.increasePage.bind(this)}
                            decreasePage={this.decreasePage.bind(this)}
                            currentPage={this.props.currentPage}
                            totalPage={this.props.totalPages}

                        />
                    </div>
                </div>
                <div className={'pb-2'}>
                    <KirokuSectionDropdownShowMore
                        isSearchTags
                        tagType={'Team'}
                        onChange={this.handleChangeTeamTags.bind(this)}
                        value={this.state.currentTeamTags || []}
                        options={this.state.groupedTeamOptions}
                        handleSearchTags={this.handleSearchTags.bind(this)}
                        placeholder={t('Team.placeholderSearch')}
                    />

                </div>

                <div className={'pb-3'}>
                    <KirokuSectionDropdownShowMore
                        isSearchTags
                        tagType={'Staff'}
                        onChange={this.handleChangeUserTags.bind(this)}
                        value={this.state.currentUserTags || []}
                        options={this.state.groupedUserOptions}
                        handleSearchTags={this.handleSearchTags.bind(this)}
                        placeholder={t('staff.placeholderSearch')}
                    />

                </div>

                <KirokuCardWeb>
                    <div className='d-flex justify-content-between p-3'>
                        <div style={{width: 150}}>
                            <DropDownFilterStatus
                                resource-permission={"|customerList|button-filterDeActive|"}
                                title={t(`common.${this.state.status}`)}
                                defaultValue={t('common.active')}
                                items={this.filterItems}
                                icon={'fas fa-check-circle text-secondary'}
                                onSelect={event => {
                                    if (event.kirokuSelected.value === 'deactive') {
                                        this.filterStatusCustomer(event.kirokuSelected.value);
                                    } else {
                                        this.filterStatusCustomer('active')
                                    }
                                }}
                            />
                        </div>
                        <KirokuInputSearcher
                            value={this.state.name}
                            onSubmit={this.handlerSubmitSearch.bind(this)}
                            onChange={this.onSearch.bind(this)}
                            id={'customerInputSearcherWeb'}
                        />
                    </div>
                    {!this.props.isLoadingAPI ?
                        <div className='p-2'>
                            {customers.length ?
                                <KirokuTable>
                                    <THeader>
                                        <tr>
                                            <Th resource-permission={'|customerList|table-th|'}
                                                hidden={this.state.isDeactive}
                                                style={{width: 50}}>
                                                <KirokuCheckBox onChange={this.handleCheckAll.bind(this)}
                                                                checked={this.state.selected}/>
                                            </Th>
                                            <th>{t('Customer.Name')}</th>
                                            <th style={{width: 70}}>{t('Customer.Age')}</th>
                                            <th>{t('CustomerEditor.KindOfCertificate')}</th>
                                            <th>{t('Customer.LastRecordedDate')}</th>
                                            <th hidden={this.state.isDeactive || this.props.profile.role === "staff"}
                                                className='text-center'
                                                style={{width: 200}}>{t('common.Action')}
                                            </th>
                                        </tr>
                                    </THeader>
                                    <TBody>
                                        {customers.length ? customers.map((customer, index) => {
                                                let translateCertificate = customer.certificate.map(item => {
                                                    return t(`Customer.certificate.${item}`)
                                                });
                                                let certificate          = translateCertificate.join(', ');
                                                return <tr key={customer.id}>
                                                    <Td resource-permission={'|customerList|table-td|'}
                                                        style={{width: 50}}
                                                        hidden={this.state.isDeactive}
                                                    >
                                                        <KirokuCheckBox onChange={this.handleCheckSurveys.bind(this, index)}
                                                                        checked={customer.isChecked || this.state.defaultChecked}/>
                                                    </Td>
                                                    <td>{!this.state.isDeactive ?
                                                        <Link
                                                            to={`/customers/customer-detail/${customer.id}`}>{customer.name}
                                                            {/*<KirokuCheckDraft*/}
                                                            {/*draftable={customer}/>*/}
                                                        </Link> : customer.name}
                                                    </td>
                                                    <td style={{width: 50}}><KirokuAge
                                                        dob={(customer.date_of_birth === null || customer.date_of_birth === '' ? '' : moment.unix(customer.date_of_birth))}/>
                                                    </td>
                                                    <td>{certificate}</td>
                                                    <td style={{whiteSpace : 'nowrap'}}>
                                                        {(customer.last_record_at === null || customer.last_record_at === '' || customer.last_record_at === undefined) ?
                                                            '' : moment.unix(customer.last_record_at).format(config.formatDateTimeTh)}
                                                    </td>
                                                    <td hidden={this.state.isDeactive || this.props.profile.role === "staff"}>
                                                        <div className='d-flex justify-content-between p-1 fix-icon-align'>
                                                            <LinkKiroku
                                                                color={'text-dark'}
                                                                resource-permission={"|customerList|button-edit|"}
                                                                href={`/customers/customer/${customer.id}`}
                                                                text={t('common.Edit')}
                                                                icon={<i className="fas fa-pencil-alt text-secondary"/>}
                                                            />
                                                            <LinkKiroku
                                                                color={'text-dark'}
                                                                resource-permission={"|customerList|button-duplicate|"}
                                                                href={`/customers/customer-duplicate/${customer.id}`}
                                                                icon={<i className="fas fa-clone text-secondary"/>}
                                                                text={t('common.Duplicate')}
                                                            />

                                                        </div>
                                                    </td>
                                                </tr>
                                            }) :
                                            <KirokuCardWeb>
                                                <div className="pt-3 pb-5 fix-center">
                                                    <img src={`${filePathService.resolveFilePath('empty-survey.svg')}`}
                                                         alt="search fail"/>
                                                    <h6 className="pt-3" style={{color: "rgb(9, 30, 66)"}}>
                                                        <b>{t('common.PleaseSelect')}</b>
                                                    </h6>
                                                </div>
                                            </KirokuCardWeb>
                                        }
                                    </TBody>
                                </KirokuTable> :
                                <TabResult messageFirst={'customerForTag1'} messageSecond={'customerForTag2'}>
                                    <img src={`${filePathService.resolveFilePath('empty-survey.svg')}`}
                                         alt="search fail"/>
                                </TabResult>
                            }

                        </div>
                        : <KirokuLoading/>}
                </KirokuCardWeb>
                <div
                    className={this.state.isDeactive ? 'd-flex justify-content-end pt-2' : `d-flex justify-content-between pt-2`}>
                    <div hidden={this.state.isDeactive}>
                        <BulkActionCustomerList
                            resource-permission={'|customerList|button-bulkAction|'}
                            disabled={this.isSelect()}
                            style={{width: 160}}
                            location={'top'}
                            title={t('common.BulkAction')}
                            items={this.bulkActionItems}
                            onSelect={event => {
                                if (event.kirokuSelected.value === 'deactive') {
                                    this.deactiveCustomer()
                                }
                            }}
                        />
                    </div>
                    <KirokuPagination
                        disabledPrev={this.state.pageMin === this.props.currentPage}
                        disabledNext={this.props.currentPage === this.props.totalPages}
                        increasePage={this.increasePage.bind(this)}
                        decreasePage={this.decreasePage.bind(this)}
                        currentPage={this.props.currentPage}
                        totalPage={this.props.totalPages}

                    />
                </div>
                <KirokuModal show={this.state.show} onClick={this.closeModal.bind(this)}>
                    <KirokuModalBody>
                        <p className='text-center pt-3'>
                            {t('Customer.Verify', {customers: this.state.customerName})}
                        </p>
                    </KirokuModalBody>
                    <KirokuModalFooter>
                        <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                            {t('common.Cancel')}
                        </KirokuButton>
                        <KirokuButton disabled={!this.state.check} onClick={this.deactive.bind(this)}
                                      color={'primary'}>
                            {t('common.Confirm')}
                        </KirokuButton>
                    </KirokuModalFooter>
                </KirokuModal>
            </div>
        )
    }
}

export default CustomerListWeb;
