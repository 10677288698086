import React, {Component} from 'react';
import {withNamespaces}   from "react-i18next";
import {KirokuLabel} from "../../../ui";

@withNamespaces()
class TabProfileInformation extends Component {
    render() {
        let {t, profile}               = this.props;
        let {currentTeam}              = profile;
        let qualification              = profile.qualification.map(item => {
            return t(`common.dropdown.${item}`)
        });
        let currentQualification       = qualification.join(', ');
        return (
            <div>
                <h5>{t('CustomerEditor.PersonalInformation')}</h5>
                <div className='row'>
                    <div className="col-md-4">
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('common.Name')}:</span>
                            <br/>
                            <span className='personal-info'>{profile.name}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('common.userName')}:</span>
                            <br/>
                            <span className='personal-info'>{profile.username}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('common.Title')}:</span>
                            <br/>
                            <span className='personal-info'>
                                {profile.title}
                            </span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('common.Role')}:</span>
                            <br/>
                            <span className='personal-info'>
                                {t(`common.${profile.role}`)}
                            </span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('common.AssignToTeam')}:</span>
                            <br/>
                            <span className='personal-info'>{currentTeam}</span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        {/*<div className='mb-10'>*/}
                        {/*    <span className='customer-info f-bold'>{t('common.StaffNumber')}:</span>*/}
                        {/*    <br/>*/}
                        {/*    <span*/}
                        {/*        className='personal-info'>{profile.staff_number}</span>*/}
                        {/*</div>*/}
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('common.WorkingForm')}:</span>
                            <br/>
                            <span
                                className='personal-info'>{profile['working_form'] ? t(`common.${profile['working_form']}`) : ''}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('common.Qualification')}:</span>
                            <br/>
                            <span className='personal-info'>{currentQualification}</span>
                        </div>
                    </div>
                    <div className="col-md-4"/>
                </div>
                <hr/>
                <h5>{t('common.Security')}</h5>
                <div className="row">
                    <div className="col-md-4">
                        <span className='customer-info f-bold'>{t('common.Two-factorAuthentication')}:</span>
                    </div>
                    <div className="col-md-4">
                        {!!profile["2fa"] ? (
                            <KirokuLabel color="primary">
                                {t('common.enabled')}
                            </KirokuLabel>
                        ) : (
                            <KirokuLabel color="danger">
                                {t('common.disabled')}
                            </KirokuLabel>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default TabProfileInformation;
