import React, {Component} from 'react';
import { withNamespaces } from 'react-i18next';


@withNamespaces()
class KirokuNotificationContent extends Component{
    render() {
        const {color, message, t, isNotAutomaticCloseNotice, handleClose} = this.props;
        return (
            <div className='notify-web-a'>
                <div className='notification-container'>
                    <div className={'notification bg-' + color}>
                        <div className={'close-icon'}
                             hidden={!isNotAutomaticCloseNotice}

                        >   <div onClick={handleClose}
                            style={{width: 30}}
                        >
                                <i className="fas fa-times fa-lg"/>
                            </div>
                        </div>
                        <i className={`fas ${color  === 'danger' ? "fa-times-circle fa-lg" : "fa-check-circle"}`}/>
                        <span className='pl-2'>{t(`${message}`)}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default KirokuNotificationContent;
