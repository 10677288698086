import React, { Component }                 from 'react';
import KirokuModalHeader                    from "../../ui/KirokuModalHeader";
import KirokuInputSearch                    from "../../ui/KirokuInputSearch";
import KirokuModalBody                      from "../../ui/KirokuModalBody";
import KirokuCardList                       from "../../ui/KirokuCardList";
import KirokuCard                           from "../../ui/KirokuCard";
import KirokuCheckBox                       from "../../ui/KirokuCheckBox";
import KirokuModalFooter                    from "../../ui/KirokuModalFooter";
import KirokuButton                         from "../../ui/KirokuButton";
import KirokuModal                          from "../../ui/KirokuModal";
//import ModalDeleteCustomers                 from "./ModalDeleteCustomers";
import connect                              from "react-redux/es/connect/connect";
import { loadCustomer, onFilter, onSearch } from "../../actions/Customer";

@connect(state => {
    return {
        customers: state.KirokuAICustomer.customers
    }
}, dispatch => {
    return {
        onSearch: (keyword) => {
            dispatch(onSearch(keyword))
        },

        onFilter     : (type) => {
            dispatch(onFilter(type))
        },
        loadCustomers: () => {
            dispatch(loadCustomer())
        },

    };
})
class ModalSelectCustomers extends Component {
    state = {
        statusCheck    : false,
        customers      : [],
        customerRecords: [],
        showModalDelete: false,
        countCustomers : {},
        defaultChecked : false
    };

    checkDisable = (customerRecords) => {
        return customerRecords.filter(customer => customer.isChecked).length;
    };

    setStatusCustomers(customers) {
        return this.state.customers.map(item => {
            customers.forEach(customer => {
                if (customer.id === item.id) return item.isChecked = customer.isChecked;
            });
            return item;
        });
    }


    handleCheckSurveys(index) {
        const {customerRecords} = this.state;
        customerRecords[index]  = {
            ...customerRecords[index],
            isChecked: !customerRecords[index].isChecked
        };

        let customers = this.setStatusCustomers(customerRecords);
        this.setState({
            customerRecords: customerRecords,
            customers      : customers,
            check          : this.checkDisable(customerRecords)
        });

    }

    handleCheckAll() {
        const {customerRecords} = this.state;
        let newCustomerRecords  = customerRecords.map(customer => ({
            ...customer,
            isChecked: true
        }));

        let customers = this.setStatusCustomers(newCustomerRecords);

        this.setState({
            customerRecords: newCustomerRecords,
            customers      : customers,
            statusCheck    : !this.state.statusCheck,
            check          : this.checkDisable(newCustomerRecords)
        })
    }

    handleUnCheckAll() {
        const {customerRecords} = this.state;
        let newCustomerRecords  = customerRecords.map(customer => ({
            ...customer,
            isChecked: false
        }));

        let customers = this.setStatusCustomers(newCustomerRecords);

        this.setState({
            customerRecords: newCustomerRecords,
            customers      : customers,
            statusCheck    : !this.state.statusCheck,
            check          : this.checkDisable(newCustomerRecords)
        })
    }

    defaultState(customers) {
        return customers.map(customer => ({
            ...customer,
            isChecked: false
        }))
    }


    onChangeSearch(e) {

        let input     = e.target.value.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
        let customers = this.state.customers.filter(customer =>
            customer.name.toLowerCase().search(input.toLowerCase()) !== -1);
        this.setState({
            customerRecords: customers
        });
    }

    showModalDelete() {
        let customers = this.state.customerRecords.filter(customer => customer.isChecked);
        this.setState({
            countCustomers: {
                name: customers.map(customer => customer.name).join(', '),
                id  : customers.map(customer => customer.id),
            },
            showModalDelete: !this.state.showModalDelete,

        });
        this.props.onCloseModalSelectCustomer();
    }
    //
    // countCustomers() {
    //     let customers = this.state.customerRecords.filter(customer => customer.isChecked);
    //     this.setState({
    //         countCustomers: {
    //             name: customers.map(customer => customer.name).join(', '),
    //             id  : customers.map(customer => customer.id),
    //         }
    //     })
    // }

    componentWillReceiveProps(nextProps) {
        if (nextProps.customers.length) {
            this.setState({
                customers      : nextProps.customers.map(customer => ({...customer})),
                customerRecords: nextProps.customers.map(customer => ({...customer}))
            })
        }
    }

    closeModal() {
        let defaultState = this.defaultState(this.state.customerRecords);
        this.setState({
            customerRecords: defaultState,
            check          : this.checkDisable(defaultState),
            statusCheck    : false,
        });
        this.props.onCloseModalSelectCustomer();

    }

    onCloseModalDeactiveCustomer() {
        this.setState({
            showModalDeactive: !this.state.showModalDeactive,
            customerRecords  : this.defaultState(this.state.customerRecords)
        })
    }

    onSearch(e) {
        this.props.onSearch(e.target.value)
    }

    onCloseModalDeleteCustomer() {
        this.setState({
            showModalDelete: !this.state.showModalDelete,
            statusCheck : false
        })
    }

    render() {
        let {customerRecords} = this.state;
        return (
            <div>
                <KirokuModal show={this.props.show}
                             onCloseModal={this.closeModal.bind(this)}>
                    <KirokuModalHeader>
                        <h6 className="mb-3">Choose customers to delete</h6>
                        <KirokuInputSearch onChange={this.onChangeSearch.bind(this)}
                                           placeholder={'Enter customers name'}/>
                    </KirokuModalHeader>
                    <KirokuModalBody>
                        <div id="body-modal-as">
                            <KirokuCardList
                                headerLeft='Customer list'
                                headerRight={
                                    <div className='text-primary' style={{cursor: 'pointer'}}>
                                        <span hidden={this.state.statusCheck}
                                              onClick={this.handleCheckAll.bind(this)}
                                        >Select All</span>

                                        <span hidden={!this.state.statusCheck}
                                              onClick={this.handleUnCheckAll.bind(this)}
                                        >Remove All</span>
                                    </div>
                                }
                            >
                                <section className="scroll-data-customer">
                                    {customerRecords.map((customer, index) => (
                                        <KirokuCard key={customer.id}>
                                            <KirokuCheckBox onChange={this.handleCheckSurveys.bind(this, index)}
                                                            checked={customer.isChecked || this.state.defaultChecked} content={customer.name}/>
                                        </KirokuCard>
                                    ))}
                                </section>
                            </KirokuCardList>
                        </div>
                    </KirokuModalBody>
                    <KirokuModalFooter>
                        <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                            Cancel
                        </KirokuButton>
                        <KirokuButton disabled={!this.state.check} onClick={this.showModalDelete.bind(this)}
                                      color={'primary'}>
                            Apply
                        </KirokuButton>
                    </KirokuModalFooter>
                </KirokuModal>
                {/*<ModalDeleteCustomers*/}
                    {/*onCloseModalDeleteCustomer={this.onCloseModalDeleteCustomer.bind(this)}*/}
                    {/*customers={this.state.countCustomers}*/}
                    {/*countCustomer={this.state.check}*/}
                    {/*show={this.state.showModalDelete}/>*/}
            </div>
        )
    }
}

export default ModalSelectCustomers;
