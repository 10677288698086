import React, {Component} from 'react';
import {
    KirokuButton, KirokuDateRangePicker, KirokuSectionDropdownShowMore, ModalSelectCustomerOfTeam
} from "../../ui";
import {withNamespaces} from "react-i18next";
import {connect} from "react-redux";
import {CHANGE_SERVICE_AT, SELECT_CUSTOMERS, SELECT_TEMPLATES, RESET_CONDITIONS} from '../../actions/Reports'
import {getGroupedOptions} from "../Records/NewRecords/serviceNewRecords";
import _ from 'lodash';

@withNamespaces()
@connect(state => {
    return {
        conditions: state.Reports.conditions,
    }
}, dispatch => {
    return {
        changeDateRangeServiceAt: (dateRange) => {
            dispatch({
                type: CHANGE_SERVICE_AT,
                dateRange
            })
        },
        customersSelected: (customers) => {
            dispatch({
                type: SELECT_CUSTOMERS,
                customers
            })
        },
        templatesSelected: (templates) => {
            dispatch({
                type: SELECT_TEMPLATES,
                templates
            })
        },
        resetCondition: () => {
            dispatch({
                type: RESET_CONDITIONS
            })
        },
        removeDateRange: () => {
            dispatch({
                type: "REMOVE_DATE",
            })
        }
    }
})
class SearchComponents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            groupedOptionsCustomers: [],
            groupedOptionsTemplate: [],
            showModalSelectCustomerOfTeam: false
        }
    }

    componentDidMount() {
        this.setState({
            groupedOptionsCustomers: getGroupedOptions('CustomerId', this.props.listAllCustomer),
            groupedOptionsTemplate: getGroupedOptions('TemplateId' , this.props.listAllSurveyTemplate)
        });
    }

    handleOnChangeDateRanger(dateRange) {
        this.props.changeDateRangeServiceAt(dateRange);
    }

    handleShowModalSelectCustomerOfTeam() {
        this.setState({
            showModalSelectCustomerOfTeam: !this.state.showModalSelectCustomerOfTeam
        })
    }

    handleChangeTagsCustomer(options) {
        this.props.customersSelected(options);
    }

    customerSelectedInModal(listCustomer) {
        this.props.customersSelected(getGroupedOptions('CustomerId', listCustomer.filter((e) => e.isChecked)));
    }

    handleCloseModalSelectCustomerOfTeam() {
        this.setState({
            showModalSelectCustomerOfTeam: !this.state.showModalSelectCustomerOfTeam
        })
    }

    handleChangeTagsTemplate(options) {
        this.props.templatesSelected(options);

    }

    handleResetCondition() {
        this.props.removeDateRange();
        this.props.resetCondition();
    }

    render() {
        const {conditions, t, allTeam, listAllCustomer, submit} = this.props;
        const {groupedOptionsCustomers, showModalSelectCustomerOfTeam, groupedOptionsTemplate} = this.state;

        const currentCustomers = _.isEmpty(conditions.currentCustomers);
        const currentTemplate = _.isEmpty(conditions.currentTemplate);

        return (
            <div className='pt-10'>
                <div className={'pb-2'}>{t('RecordList.ServiceAt')}</div>
                <div className="date-time-range-report">
                    <KirokuDateRangePicker
                        minimumNights={0}
                        startDate={conditions.serviceAt.startDate}
                        endDate={conditions.serviceAt.endDate}
                        onChangeDate={this.handleOnChangeDateRanger.bind(this)}
                    />
                </div>
                <ModalSelectCustomerOfTeam
                    customersSelected={conditions.currentCustomers}
                    show={showModalSelectCustomerOfTeam}
                    customerSelectedInModal={this.customerSelectedInModal.bind(this)}
                    closeModal={this.handleCloseModalSelectCustomerOfTeam.bind(this)}
                    teams={allTeam}
                    customers={listAllCustomer}
                />
                <div className="p-2"/>
                <div className={'pb-2 d-flex'}>
                    <div>{t('RecordList.customers')}</div>
                    <div className="p-2"/>
                    <div className={'cursor-pointer'} style={{color: '#1891eb'}}
                         onClick={this.handleShowModalSelectCustomerOfTeam.bind(this)}
                    >{t('RecordList.SelectCustomerOfTeam')}</div>
                </div>
                <KirokuSectionDropdownShowMore
                    tagType={'Customer'}
                    placeholder={t('Customer.placeholderSearch')}
                    onChange={this.handleChangeTagsCustomer.bind(this)}
                    value={conditions.currentCustomers}
                    options={groupedOptionsCustomers}
                />

                <div className="p-2"/>
                <div className={'pb-2'}>{t('RecordList.surveyTemplate')}</div>
                <KirokuSectionDropdownShowMore
                    tagType={'Template'}
                    placeholder={t('Surveys.placeholderSearch')}
                    onChange={this.handleChangeTagsTemplate.bind(this)}
                    value={conditions.currentTemplate}
                    options={groupedOptionsTemplate}
                />

                <div className="d-flex pt-2 justify-content-center">
                    <KirokuButton color={'light'}
                                  onClick={this.handleResetCondition.bind(this)}>
                        {t('common.Clear')}
                    </KirokuButton>
                    <div className="p-2"/>
                    <KirokuButton color={'primary'} disabled={!!(currentCustomers && currentTemplate) && (!conditions.serviceAt.startDate && !conditions.serviceAt.endDate)}
                                  onClick={() => submit()}>
                        {t('common.submitSearchAdvanced')}
                    </KirokuButton>
                </div>

            </div>
        );
    }
}

export default SearchComponents
