import React, { Component }        from 'react';
import { connect }                 from 'react-redux';
import {
    KirokuButton, KirokuLoading, KirokuModal,
    KirokuModalBody, KirokuModalFooter, KirokuModalHeader,
}                                  from '../../../../ui';
import { withNamespaces }          from 'react-i18next';
import { ComponentViewActionEdit } from '../ComponentTypeChangeRecord';
import FormatTimestamp             from '../HelperComponents/FormatTimestamp';



const RenderDifferenceQuestion = (differences, questionsBefore, questionsAfter) => {
    return differences.map((diff, index) => {
        return <div key={index}>
                <div className={'pl-3 pr-3'}>
                    <ComponentViewActionEdit
                        questionsBefore={questionsBefore}
                        questionsAfter={questionsAfter}
                        diff={diff}
                    />
                </div>
            <hr hidden={index === differences.length - 1}/>
        </div>;
    });
};

@withNamespaces()
@connect(state => {
    return {
        modalViewDifferenceQuestions: state.KirokuModal.ModalViewDifferenceQuestions,
    };
}, dispatch => {
    return {
        isOpenModalViewDifferenceQuestion: () => {
            dispatch({
                type: 'IS_OPEN_MODAL_VIEW_DIFFERENCE_QUESTION',
            });
        },
    };
})
class ModalViewDifferenceQuestions extends Component {
    render() {
        const {
                  isOpenModal, differences,
                  recordAfter, recordBefore,
                  statusRecordChange, servicedAt
              }     = this.props.modalViewDifferenceQuestions;
        const { t } = this.props;
        return (
            <KirokuModal show={isOpenModal}>
                <KirokuModalHeader>
                    <h4>{t('logsRecord.changeLogRecord')}</h4>
                </KirokuModalHeader>
                <KirokuModalBody>
                    <div className='background-color' style={{
                        overflow : 'scroll',
                        minHeight: 'calc(100vh - 250px)',
                        maxHeight: 'calc(100vh - 250px)',
                        height   : 'auto',
                    }}>
                        {
                            recordAfter === null && recordBefore === null ? <div><KirokuLoading/></div> :
                                    <div className={'pb-3'}>
                                        <div className={'pl-3 pr-3 pt-3'}>
                                            <div className={'d-flex'}>
                                                <span style={{fontSize: 16}}>{t('logsRecord.statusRecord')}: &nbsp;</span>
                                                <span hidden={statusRecordChange.statusRecordAfter === statusRecordChange.statusRecordBefore}>
                                                    <div className={'d-flex align-items-center'}>
                                                        <div className={'text-decoration'} style={{fontSize: 16}}>{t(`logsRecord.${statusRecordChange.statusRecordBefore}`)}</div>
                                                        <i className="fas fa-arrow-right pl-2 pr-2"/>
                                                        <div style={{fontSize: 16}}>{t(`logsRecord.${statusRecordChange.statusRecordAfter}`)}</div>
                                                    </div>
                                                </span>
                                                <span hidden={statusRecordChange.statusRecordAfter !== statusRecordChange.statusRecordBefore}>
                                                    <div style={{fontSize: 16}}>{t(`logsRecord.${statusRecordChange.statusRecordAfter}`)}</div>
                                                </span>
                                            </div>
                                            <div className={`${!servicedAt.isChangeServicedAt ? 'd-flex' : ''}`}>
                                                <div style={{fontSize: 16}}>{t('logsRecord.servicedAt')}: &nbsp;</div>
                                                <div className="d-flex">
                                                    <span hidden={!servicedAt.isChangeServicedAt}>
                                                        <div className={'d-flex align-items-center'}>
                                                            <div className={'text-decoration'} style={{fontSize: 16}}>
                                                                <FormatTimestamp timestampUnix={servicedAt.before.servicedAt}
                                                                                 hasTime={servicedAt.before.has_time}/>
                                                            </div>
                                                            <i className="fas fa-arrow-right pl-2 pr-2"/>
                                                            <div style={{fontSize: 16}}>
                                                                <FormatTimestamp timestampUnix={servicedAt.after.servicedAt}
                                                                                 hasTime={servicedAt.after.has_time}/>
                                                            </div>
                                                        </div>
                                                    </span>
                                                    <span hidden={servicedAt.isChangeServicedAt}>
                                                        <div style={{fontSize: 16}}>
                                                            <FormatTimestamp timestampUnix={servicedAt.after.servicedAt}
                                                                             hasTime={servicedAt.after.has_time}/>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                        {differences.length  ?
                                            RenderDifferenceQuestion(differences, recordBefore.survey_template.questions, recordAfter.survey_template.questions)
                                        : <div className={'text-center'}>
                                            <h3>{t('logsRecord.notChange')}</h3>
                                        </div>}
                                    </div>
                        }
                    </div>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton color={'white'} onClick={() => {
                        this.props.isOpenModalViewDifferenceQuestion();
                    }}> {t('common.close')} </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>
        );
    }
}

export default ModalViewDifferenceQuestions;
