import React, {Component}          from 'react';
import TeamDetailMobile            from "./TeamDetailMobile";
import TeamDetailWeb               from "./TeamDetailWeb";
import {scrollService}             from "../../../services";
import { CheckRerenderResponsive } from '../../../ui';

class TeamDetail extends Component {

    componentDidMount() {
        scrollService.top()
    }

    render() {
        let id = parseInt(this.props.match.params.id);
        return (
            <div className='default-background-color'>
                <CheckRerenderResponsive mobile={<TeamDetailMobile id={id} history={this.props.history}/>}
                             web={<TeamDetailWeb id={id} history={this.props.history}/>}/>
            </div>
        )
    }
}

export default TeamDetail;
