import React,{Component} from "react";
import PhoneInput        from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import 'react-responsive-ui/style.css';

export default class KirokuPhoneNumber extends Component{
    state = {
        value: ''
    };

    render() {

        const props = this.props;

        return (
            <PhoneInput
                country="JP"
                {...props}
                type={'phone'}
            />
        );
    }
}