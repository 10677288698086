import React, {Component} from 'react';
import {withNamespaces}   from "react-i18next";
import {connect}          from "react-redux";
import {NOTIFICATION}     from "../actions/Notification";

@withNamespaces()
@connect(state => {
    return {}
}, dispatch => {
    return {
        notification: (t, message) => dispatch({
            type: NOTIFICATION,
            typeNotification: 'error',
            message: t(`login.${message}`)
        })
    }
})
class KirokuError extends Component {
    componentWillMount() {
        this.props.notification(this.props.t, this.props.message)
    }

    render() {
        const {t} = this.props;
        return (
            <div style={{position: 'relative', minHeight: '100vh', minWidth: '100vh'}}>
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    margin: 'auto',
                }}>

                    <div className="text-center" style={{marginTop: '20%'}}>
                        {t(`login.${this.props.message}`)}
                    </div>
                </div>
            </div>

        );
    }
}

export default KirokuError;
