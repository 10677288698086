import React, {Component}     from 'react';
import _                      from "lodash";
import {
    DateQuestion,
    DateTimeQuestion,
    InputText,
    MediaFileQuestion,
    Message,
    MultiChoice,
    SingleChoice, TimeQuestion,
    YesNoQuestion,
    NumberQuestion
}                             from "../../QuestionTypeWebFiledAll";
import {KirokuButton}         from "../../../../../ui";
import CreatorLogicEditRecord from "../CreatorLogicEditRecord";
import {withNamespaces}       from "react-i18next";
import {validateAnswer}       from "../../../sevicesInRecord";
import {hasTimer}             from "../../../../../Timer";
import {connect}              from "react-redux";
import config                 from '../../../../../config';
import { ScrollingAnchor }    from '../../../../../ui/Scrolling.js';

@hasTimer()
@withNamespaces()
@connect(state => {
    return {
        takeRecordPage: state.KirokuAIRecord.takeRecordPage,
        // lockInfo: state.KirokuLockInfo.lockInfo,
    }
}, null)
class RecordEditorNew extends Component {

    constructor(props){
        super(props);
        this.props.timer.record(() => {
            this.autoSaveBackupRecord()
        }, config.timeAutoSaveBackupRecord);
    }

    state = {
        onClick                   : false,
        clickSubmit               : false,
        profile                   : JSON.parse(localStorage.getItem('profile')),
        currentQuestion           : null,
    };

    componentDidMount() {
        this.setState({ currentQuestion : null})
    }

    componentWillMount() {
        this.getInitQuestionLogic()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(this.props.recordToEdit) !== JSON.stringify(prevProps.recordToEdit)) {
            this.getInitQuestionLogic();
        }
    }

    getInitQuestionLogic() {
        const {recordToEdit, statusRecord} = this.props;
        if(this.props.takeRecordPage) {
            this.setState({
                onClick : true
            });
            setTimeout(() => this.setState({
                onClick: false
            }), 2500);
        }
        this.getDraft({
            ...recordToEdit,
            statusRecord: statusRecord.statusDraft
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.keyLoadData !== this.props.keyLoadData && nextProps.keyLoadData) {
            const {statusRecord} = nextProps;
            this.getDraft({
                statusRecord: statusRecord[nextProps.keyLoadData]
            });
        }
    }

    componentWillUnmount() {
        this.props.timer.unRegisterLastJob();

    }

    autoSaveBackupRecord() {
        this.props.saveBackupDataRecord(this.creatorLogicEditRecord.toDraft());
    }

    getDraft(recordToEdit) {
        if(recordToEdit.statusRecord) {
            this.creatorLogicEditRecord = CreatorLogicEditRecord.fromDraft(recordToEdit.statusRecord);
        }else {
            this.creatorLogicEditRecord = new CreatorLogicEditRecord(recordToEdit['survey_template'].questions);
        }
        this.setState({
            questions: this.creatorLogicEditRecord.getQuestions()
        });
    }

    libQuestions = {
        InputText,
        Message,
        MultiChoice,
        YesNo   : YesNoQuestion,
        Date    : DateTimeQuestion,
        Single  : SingleChoice,
        Media   : MediaFileQuestion,
        OnlyDate: DateQuestion,
        Time    : TimeQuestion,
        Number  : NumberQuestion
    };

    handleUpdateAnswer(answer) {
        if(this.state.clickSubmit) {
            this.setState({
                clickSubmit: false
            });
        }
        this.creatorLogicEditRecord.updateAnswerQuestion(answer.questionIndex, answer.answer);
        this.setState({
            questions       : this.creatorLogicEditRecord.getQuestions()
        });

    }

    setDisable() {
        const questions  = this.creatorLogicEditRecord.toRecord();
        const arrayValidateAnswerQuestion = questions.map(question => {
            return {
                validate : question.unrequited ? (question.answers || '') : validateAnswer(question)
            }
        });
        const indexValidate = _.findIndex(arrayValidateAnswerQuestion, ['validate', null]);
        return indexValidate !== -1;
    }

    handleCancel() {
        this.props.handleCancel();
    }

    handleSaveStatusRecord() {
        this.setState({
            onClick: true
        });
        setTimeout(()=> {
            this.setState({
                onClick: false
            })
        }, 3500);

        const flowedQuestion = this.creatorLogicEditRecord.toRecord();
        this.props['saveStatusRecord'](flowedQuestion );
    }

    handleSubmitRecord() {
        this.setState({
            clickSubmit: true
        });
        const flowedQuestion = this.creatorLogicEditRecord.toRecord();
        this.props.updateRecord(flowedQuestion);
    }

    render() {
        const {t, lockInfo, editor} = this.props;
        const {questions, onClick, clickSubmit, profile, currentQuestion} = this.state;
        const isEndQuestion = questions.length - 1;
        const logicEditor = (question) => (lockInfo['user_id'] === profile.id ? question.visibility : true);

        return (
            <div>
                <div className="card">
                    <div className='fix-center'>
                        <div style={{width: '70%'}}>
                            {
                                questions.map((question, index) => {
                                    const Question = this.libQuestions[question.type];
                                    return (
                                        <div key={index}>
                                            <ScrollingAnchor anchor={ `question-${ index }` }>
                                                <Question
                                                    disabled={
                                                        editor ? logicEditor(question) : question.visibility
                                                    }
                                                    index={index}
                                                    key={index}
                                                    question={question}
                                                    value={question.answers}
                                                    onAnswer={e => this.handleUpdateAnswer(e)}
                                                    isEndQuestion={isEndQuestion === index}
                                                    currentQuestion={currentQuestion}
                                                    isCurrentQuestion={(currentQuestion) => {
                                                        this.setState({
                                                            currentQuestion : currentQuestion
                                                        })
                                                    }}

                                                />
                                            </ScrollingAnchor>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='fix-center pt-2 pb-4'>
                            <KirokuButton
                                color={'success'}
                                disabled={this.setDisable() || clickSubmit || onClick || (editor && !(lockInfo[ 'user_id' ] === profile.id))}
                                onClick={this.handleSubmitRecord.bind(this)}
                            >
                                {t('createRecord.Submit')}
                            </KirokuButton>
                        </div>
                    </div>
                </div>

                <div className={'pt-4 fix-center'}>
                    <div className={'d-flex justify-content-between'}>
                        <KirokuButton color={'white'} onClick={this.handleCancel.bind(this)}>
                            {t('createRecord.cancel')}
                        </KirokuButton>
                        <KirokuButton disabled={onClick || (editor && !(lockInfo[ 'user_id' ] === profile.id))}
                            style={{marginLeft: 10}} color={'primary'} onClick={this.handleSaveStatusRecord.bind(this)}>
                            {t('createRecord.SaveDraft')}
                        </KirokuButton>
                    </div>
                </div>

            </div>
        )
    }
}

export default RecordEditorNew;
