import React, {Component}                                    from 'react';
import KirokuButton                                          from "../../../../ui/KirokuButton";
import KirokuInput                                           from "../../../../ui/KirokuInput";
import KirokuInputArea                                       from "../../../../ui/KirokuInputArea";
import {nextQuestionFromSurvey, editSurvey, showAddQuestion} from "../../../../actions/SurveyMobile";
import {connect}                                             from "react-redux";
import {withNamespaces}                                      from "react-i18next";

@withNamespaces()
@connect(state => {
    return {
        survey: state.KirokuSurveyTemplateMobile,
    }
}, dispatch => {
    return {
        toComponent: (typeComponent, options) => {
            dispatch(nextQuestionFromSurvey(typeComponent, options));
        },

        editSurveyTemplate: (survey, error) => {
            dispatch(editSurvey(survey, error))
        },

        showAddQuestion: () => {
            dispatch(showAddQuestion())
        }

    }
})
class SurveyMobile extends Component {

    state =  {
        errorFormNumber : false
    };

    newQuestion = () => {
        this.props.toComponent('AddQuestion', 'InputText');
    };

    onChangeTitle = (e) => {
        const title = e.target.value;
        this.props.editSurveyTemplate({title: title}, {title: !title.length});

    };

    onChangeFormNumber = (e) => {
        let form_number = e.target.value.replace(/[^0-9]/g, '');
        this.props.editSurveyTemplate({form_number: form_number}, {form_number: isNaN(form_number) || (form_number.length !== 2 && form_number !== 0)});

    };

    checkValidationFormNumber = (e) => {
        const error = this.checkErrorFormNumber(e.currentTarget.value);
        this.setState({
            errorFormNumber: error
        });
    };

    checkErrorFormNumber = (number) => {
        if(number.length === 1) {
            this.setState({errorFormNumber: true});
            return 'errorFormNumber';
        }
        return '';
    };

    onChangeDescription = (e) => {
        const description = e.target.value;
        this.props.editSurveyTemplate({description: description}, {description: !description.length});
    };

    nextQuestion = () => {
        this.props.toComponent('Question');
    };

    disableNextQuestion(){
        const {survey} = this.props;
        return !survey.description || !survey.title || isNaN(survey.form_number) || (survey.form_number && survey.form_number.length !== 2) || this.state.errorFormNumber;
    };

    render() {
        const {survey, t} = this.props;
        // const {errorFormNumber} = this.state;
        return (
            <div className='bg-white'>
                <div className='create-header'>
                    <div className='d-flex justify-content-between padding-responsive'>
                        <h4>{t('createSurvey.Summary')}</h4>
                        <KirokuButton color='success' disabled={this.disableNextQuestion()}
                                      onClick={this.newQuestion.bind(this)}>
                            {t('createSurvey.NewQuestion')}
                        </KirokuButton>
                    </div>
                </div>
                <div className='padding-responsive'>
                    <p>{t('createSurvey.Title')}</p>
                    <KirokuInput
                        value={survey.title}
                        onChange={this.onChangeTitle.bind(this)}
                    />

                    {/*<p className='pt-3'>{t('createSurvey.FormNumber')}</p>*/}
                    {/*<KirokuInput*/}
                    {/*    value={survey.form_number ? survey.form_number : ''} maxLength="2"*/}
                    {/*    error={errorFormNumber ? 'form-error' : ''}*/}
                    {/*    onBlur={this.checkValidationFormNumber.bind(this)}*/}
                    {/*    onChange={this.onChangeFormNumber.bind(this)}*/}
                    {/*/>*/}
                    {/*<p hidden={!errorFormNumber} style={{ color: 'red', fontSize : 12}}>{t(`staff.${errorFormNumber}`)}</p>*/}
                    <p className='pt-3'>{t('createSurvey.Description')}</p>
                    <KirokuInputArea name={'description'}
                                     value={survey.description}
                                     onChange={this.onChangeDescription.bind(this)}
                    />
                </div>
                <div className='action-record'>
                    <div className="row" style={{height: 42}}>
                        <div className='col-6 p-0'>
                            <KirokuButton style={{width: '100%', borderRadius: 0, height: '100%'}}
                                          color={'light'}
                                          onClick={this.props.backToSurveyList}
                            >
                                {t('createSurvey.Cancel')}
                            </KirokuButton>
                        </div>
                        <div className='col-6 p-0'>
                            <KirokuButton
                                onClick={this.nextQuestion.bind(this)}
                                disabled={this.disableNextQuestion()}
                                color={'primary'}
                                style={{width: '100%', borderRadius: 0, height: '100%'}}>
                                {t('createSurvey.NextQuestion')}
                            </KirokuButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SurveyMobile;
