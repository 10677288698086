export const LOAD_LOG      = "LOAD_LOG";
export const LOAD_LOG_PAGE = "LOAD_LOG_PAGE";
export const SEARCH_LOGS   = "SEARCH_LOGS";
export const RESET_LOGS  = "RESET_LOGS";

export function loadLogs() {
    return {
        type: LOAD_LOG
    }
}

export function loadLogPage(params) {
    return {
        type: LOAD_LOG_PAGE,
        params
    }
}

export function searchLogs(params) {
    return {
        type: SEARCH_LOGS,
        params

    }
}

export function resetLogs() {
    return {
        type: RESET_LOGS,
    }
}