import {
    ASSIGN_USER_TO_TEAM,
    DELETE_USERS, GET_STAFFS_BETA,
    LOAD_STAFF_IN_TEAM,
    LOAD_USER_BY_ID,
    LOAD_USER_PAGINATION,
    LOAD_USERS, SET_ROLE,
} from '../actions/User';

import { tagService, userService } from '../services';
import {NOTIFICATION}  from "../actions/Notification";

export const userMiddleware = (store) => next => action => {
    switch (action.type) {
        case GET_STAFFS_BETA:
            return userService.getStaffsBeta().then(res => {
                next({
                    ...action,
                    staffs: res,
                })
            });
        case SET_ROLE :
            return userService.updateRole(action.role, action.userId, action.params, action.tags).then(users => {
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'success',
                    message: 'message.UpdateSuccess'
                });

                next({
                    type: SET_ROLE,
                    users: users.users,
                    currentPage: users.currentPage,
                    totalPage: users.totalPage,
                })
            });

        case LOAD_USERS :
            return userService.users().then(users => {
                let rawUser = tagService.makeUserForTags(users);
                next({
                    ...action,
                    users: rawUser
                })
            }).catch(error => console.log(error));
        case LOAD_STAFF_IN_TEAM :
            return userService.staffInTeam(action.teamId).then(staffs => {
                next({
                    type: LOAD_STAFF_IN_TEAM,
                    staffs: staffs.map(item => ({
                        id: item.id,
                        value: item.name,
                        label: item.name
                    }))
                })
            });
        case LOAD_USER_PAGINATION:
            return userService.loadUserPagination(action.params || '', action.tags ? action.tags : []).then(page => {
                next({
                    type: LOAD_USER_PAGINATION,
                    users: page.users.map(user => {
                        return {
                            ...user,
                            isChecked: false
                        }
                    }),
                    totalPage: page.totalPage,
                    currentPage: page.currentPage,
                    tags: action.tags ? action.tags : []
                })
            });
        case ASSIGN_USER_TO_TEAM :
            return userService.assignUserToTeam(action.users, action.teams).then(() => {
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'success',
                    message: 'message.AssignSuccess'
                });

                store.dispatch({
                    type: LOAD_USER_PAGINATION,
                    params: action.params,
                    tags: action.tags
                })

            });
        case DELETE_USERS :
            return userService.deleteUsers(action.userId).then(() => {
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'success',
                    message: 'message.DeleteSuccess'
                });

                store.dispatch({
                    type: LOAD_USER_PAGINATION,
                    params: action.params,
                    tags: action.tags
                });

            });

        case LOAD_USER_BY_ID :
            return userService.loadDetail(action.listId).then(user => {
                let teamAssign           = user.teams.map(team => {
                    return team.name
                });
                let currentTeam          = teamAssign.join(', ');
                let displayName          = user.line_account ? JSON.parse(user.line_account).displayName : '';
                let currentQualification = user.qualification.join(', ');
                next({
                    type: LOAD_USER_BY_ID,
                    user: {
                        ...user,
                        currentTeam,
                        displayName,
                        currentQualification
                    }

                })
            });
        default:
            next(action);
    }
};
