import React, { Component }      from 'react';
import { KirokuRadio }           from '../../../../ui/index';
import { withNamespaces }        from 'react-i18next';
import KirokuLogicApplicated     from "../../../../ui/KirokuLogicApplicated";
import {checkUseHeadingQuestion} from "../../../Surveys/SurveyEditor/SurveyService";

@withNamespaces()
class YesNo extends Component {

    render() {
        const {question, value, t, surveyDetail, headerNextQuestion} = this.props;
        const contentQuestion      = checkUseHeadingQuestion(question);
        return (
            <div className='content-question'>
                <div className='wraper-question'>
                    <div className="background-question">
                        <div className='number-question d-flex justify-content-between'>
                            <div
                                className="question-number text-overflow-elip">
                                {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                            </div>
                            <div hidden={!surveyDetail} className={'float-right'}>
                                <KirokuLogicApplicated padding={'5px 5px 5px 7px'} question={question}/>
                            </div>
                        </div>
                        <span className='content-name-question'>{question.content}</span>
                        <div
                            hidden={typeof question.nextQuestion !== 'number' || !surveyDetail}
                            className='text-info text-overflow-elip pl-10'>
                            <i className='fas fa-arrow-right' style={{paddingTop: 4}}/>
                            <span> {headerNextQuestion.general}</span>
                        </div>
                    </div>
                    <div className='answer-question'>
                        <KirokuRadio onChange={() => {}}
                                     checked={value === 'Yes'}
                                     readOnly
                        >
                            <div style={{ cursor : 'no-drop'}}>{t('createRecord.Yes')}</div>
                        </KirokuRadio>
                        <div
                            hidden={ typeof question.nextQuestionYes !== 'number' || !surveyDetail}
                            className={'text-info text-overflow-elip pl-10'}>
                            <i className='fas fa-arrow-right' style={{paddingTop: 4}}/>
                            <span> {headerNextQuestion.yes}</span>
                        </div>
                        <KirokuRadio onChange={() => {}}
                                     checked={value === 'No'}
                                     readOnly
                        >
                            <div style={{ cursor : 'no-drop'}}>{t('createRecord.No')}</div>
                        </KirokuRadio>
                        <div
                            hidden={ typeof question.nextQuestionNo !== 'number' || !surveyDetail}
                            className={'text-info text-overflow-elip pl-10'}>
                            <i className='fas fa-arrow-right' style={{paddingTop: 4}}/>
                            <span> {headerNextQuestion.no}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default YesNo;
