import React, { Component }        from 'react';
import moment                      from 'moment';
import { withNamespaces }          from 'react-i18next';
import KirokuLogicApplicated       from '../../../../../../ui/KirokuLogicApplicated';
import KirokuInputIcon             from '../../../../../../ui/KirokuInputIcon';

@withNamespaces()
class DateTime extends Component {

    render() {
        const {question, value, surveyDetail, t, headerNextQuestion} = this.props;

        return (
            <div className='content-question-type'>
                <div className='wraper-question none-border-question pt-2'>
                    <div className="background-question">
                        <div className=' d-flex justify-content-between'>
                            <div className="text-overflow-elip font-heading-questions"> {question.heading ? question.heading : ""}</div>
                            <div hidden={!surveyDetail} className={'float-right'}>
                                <KirokuLogicApplicated padding={'5px 5px 5px 7px'} question={question}/>
                            </div>
                        </div>
                        <div className='padding-content-question'>
                            <span className='font-content-question'>
                                {question.unrequited ? t('createRecord.option'):''}{question.content}
                            </span>
                        </div>
                        <div
                            hidden={typeof question.nextQuestion !== 'number' || !surveyDetail}
                            className='pl-10 height-text-next-question text-overflow-elip text-info'>
                            <i className='fas fa-arrow-right' style={{paddingTop: 4}}/>
                            <span> {headerNextQuestion}</span>
                        </div>
                    </div>
                    <div className='answer-question'>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <KirokuInputIcon readOnly name={'date'} icon={'far fa-calendar-alt'}
                                                 style={{
                                                     height: "35px",
                                                     paddingLeft: '2rem',
                                                     color: "#0a1d42",
                                                     pointerEvents: "none"
                                                 }}
                                                 defaultValue={value ? moment.unix(this.props.value).format('YYYY/MM/DD') : ''}
                                />
                                <div className={'mb-2 mt-2'}/>
                                <KirokuInputIcon readOnly name={'clock'} icon={'far fa-clock'}
                                                 style={{
                                                     height: "35px",
                                                     paddingLeft: '2rem',
                                                     color: "#0a1d42",
                                                     pointerEvents: "none"
                                                 }}
                                                 type={'time'}
                                                 value={value ? moment.unix(this.props.value).format('HH:mm') : ''}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DateTime;
