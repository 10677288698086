import React, {Component}          from 'react';
import {connect}                   from 'react-redux';
import {loadDetail, setNullRecord} from '../../../actions/Record';
import KirokuPageLoader            from '../../../ui/KirokuPageLoader';
import AboutSurveyReview           from '../ReviewRecording/AboutSurvey';
import {ViewQuestioned}            from '../ReviewRecording/ViewQuestioned';
import KirokuButton                from '../../../ui/KirokuButton';
import {withNamespaces}            from 'react-i18next';
import {currentPage}               from "../../../actions/CurrentPage";
import KirokuTitlepage             from "../../../ui/KirokuTitlepage";

@withNamespaces()
@connect(state => {
    return {
        record: state.KirokuAIRecord.record
    };
}, dispatch => {
    return {
        loadDetail: (recordId) => {
            dispatch(loadDetail(recordId));
        },
        setNullRecord: () => {
            dispatch(setNullRecord())
        },
        namePage: (name) => {
            dispatch(currentPage(name))
        }
    }
})
class DetailRecord extends Component {

    page(t) {
        return {
            title: <KirokuTitlepage title={t('Layout.Records')}/>,
            actionPage: null
        }
    }

    componentWillMount() {
        // this.props.loadDetail(this.props.match.params.id);
        this.props.loadDetail(this.props.match.params.id);
        this.props.namePage(this.page(this.props.t));



    }

    componentWillUnmount() {
        this.props.setNullRecord()
    }

    render() {
        const {record, t} = this.props;
        return record['survey_template'] ? (
            <div>
                <div id='mobile'>
                    <div className='survey'>
                        <div className="keeping-about-survey" style={{ top : 0, backgroundColor: '#FFF'}}>
                            <div className='pl-2 pt-2'>
                                <KirokuButton color={'light'} onClick={() => this.props.history.goBack()}>
                                    <span className="text-tranf">{t('createRecord.back')}</span>
                                </KirokuButton>
                            </div>
                            <AboutSurveyReview serviced_at={record.serviced_at} canNotEditServicedAt
                                               DetailRecord
                                               screen={'detailRecord'}
                                               hasTime={record.has_time}
                                               create_at={record.create_at}
                                               user={record.user}
                                               survey={record['survey_template']}
                                               customer={record.customer}
                                               isMobile
                                               isRecord/>
                        </div>
                        <div className="survey" style={{backgroundColor: '#FFFFFF'}}>
                            <div className='wraper-question' >
                                <div className='wraper-question'>
                                    <div className="background-question-review">
                                        <div className='info-question'>
                                            <div className='content-name-question d-flex'>
                                        <span style={{ wordBreak: 'keep-all' }}
                                              className='title-record-info'>{t('createRecord.Description')}&nbsp;</span>
                                                <pre style={{
                                                    wordWrap  : 'break-word',
                                                    whiteSpace: 'pre-wrap',
                                                }}>
                                            {record.survey_template.description}
                                        </pre>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ViewQuestioned questions={record['survey_template'].flowedQuestions}
                                            style={{paddingBottom: 0}}
                            />
                        <div className='text-center pt-1 pl-2 pr-2 pb-5 mb-2'>
                            <KirokuButton color={'light'} onClick={() => this.props.history.goBack()}>
                                <span className="text-tranf">{t('createRecord.back')}</span>
                            </KirokuButton>
                        </div>
                    </div>
                </div>

                <div id='web'>
                    <div className='survey default-background-color padding-responsive'>
                        <div className="keeping-about-survey" style={{ top : 70, backgroundColor: '#eef3f6', paddingTop: 12}}>
                            <div className='pb-3'>
                                <KirokuButton color={'light'} onClick={() => this.props.history.goBack()}>
                                    <span className="text-tranf">{t('createRecord.back')}</span>
                                </KirokuButton>
                            </div>
                            <div className={'bg-white'}>
                                <div className='fix-center pt-3'>
                                    <div style={{width: '70%'}}>
                                        <AboutSurveyReview serviced_at={record.serviced_at} canNotEditServicedAt
                                                           DetailRecord
                                                           screen={'detailRecord'}
                                                           create_at={record.create_at}
                                                           hasTime={record.has_time}
                                                           user={record.user}
                                                           survey={record['survey_template']} customer={record.customer}
                                                           isRecord/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="survey fix-center" style={{backgroundColor: '#FFFFFF'}}>
                            <div className="background-question-review" style={{width:'70%'}}>
                                <div className='info-question'>
                                    <div className='content-name-question d-flex'>
                                        <span style={{ wordBreak: 'keep-all' }}
                                              className='title-record-info'>{t('createRecord.Description')}&nbsp;</span>
                                        <pre style={{
                                            wordWrap  : 'break-word',
                                            whiteSpace: 'pre-wrap',
                                        }}>
                                            {record.survey_template.description}
                                        </pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='fix-center pt-3 pb-3 bg-white'>
                            <div style={{width: '70%'}}>
                                <ViewQuestioned questions={record['survey_template'].flowedQuestions}
                                                style={{paddingBottom: 0}}
                                />
                            </div>
                        </div>
                        <div className='text-center pt-4'>
                            <KirokuButton color={'light'} onClick={() => this.props.history.goBack()}>
                                <span className="text-tranf">{t('createRecord.back')}</span>
                            </KirokuButton>
                        </div>
                    </div>
                </div>
            </div>
        ) : <KirokuPageLoader/>;
    }

}

export default DetailRecord;
