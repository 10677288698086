import React, {Component}                   from 'react';
import {Link}                               from "react-router-dom";
import {connect}                            from "react-redux";
import moment                               from "moment";
import {loadLogPage, resetLogs, searchLogs} from "../../actions/Log";
import {withNamespaces}                     from "react-i18next";
import {KirokuInputSearch}                  from "../../ui";
import './log.css';
import {filePathService, scrollService} from "../../services";
import $                                from "jquery";
import debounce                         from "lodash/debounce";
import KirokuLoading                    from "../../ui/KirokuLoading";
import ModalFilterLog                   from "./ModalFilterLog";
import KirokuLabel                      from "../../ui/KirokuLabel";
import {currentConditionLogs}           from "../../actions/Behavior";
import KirokuButton                     from "../../ui/KirokuButton";
import config                           from '../../config';

const LogType = (props) => {
    const {log, t} = props;
    switch (log.type) {
        case "team" :
            let teamMember = log.data ?  (typeof(JSON.parse(log.data)) == 'string' ? JSON.parse(JSON.parse(log.data)) : JSON.parse(log.data)) : [];
            let removedMember = teamMember['removedMember'] ? teamMember['removedMember'] : [];
            let addMember     = teamMember['addedMember'] ? teamMember['addedMember'] : [];

            let removed = removedMember.map(member => member.name);
            let added   = addMember.map(member => member.name);

            let countAddMember    = added.length;
            let countRemoveMember = removed.length;

            let parseStringRemoved = removed.toString();
            let parseStringAdded   = added.toString();

            let memberRemove = parseStringRemoved.replace(/,/g, ', ');
            let memberAdded  = parseStringAdded.replace(/,/g, ', ');

            if (removedMember.length === 0 && memberAdded.length === 0) {
                removedMember = '';
                memberAdded   = ''
            }
            if (removedMember.length === 0 && added.length > 0) {
                removedMember = '';
            }
            if (removedMember.length > 0 && added.length === 0) {
                addMember = '';
            }
            return <span>
                {t(`logs.${log.action}${log.type}`)}
                {memberRemove && t('logs.removeMember', {countRemove: countRemoveMember, removeMember: memberRemove})}
                {memberAdded && t('logs.addMember', {countAdd: countAddMember, addedMember: memberAdded})}
            </span>;

        default :
            return <span>{t(`logs.${log.action}${log.type}`)}</span>

    }
};

@withNamespaces()
@connect(state => {
    return {
        logs: state.KirokuAILog.logs,
        // totalPages: state.KirokuAILog.totalPages,
        currentPage: state.KirokuAILog.currentPage,
        hasNext: state.KirokuAILog.hasNext,
        loading: state.Loading,
        conditionsLogs: state.KirokuAIBehavior.conditionsLogs
    }
}, dispatch => {
    return {
        loadPages: (params) => {
            dispatch(loadLogPage(params))
        },
        searchLogs: (params) => {
            dispatch(searchLogs(params))
        },
        resetLogs: () => {
            dispatch(resetLogs())
        },
        currentConditionLogs: (cdt) => {
            dispatch(currentConditionLogs(cdt))
        },
        saveConditionLogs: conditionsLogs => {
            dispatch({
                type: 'CURRENT_CONDITION_SAVE_LOG',
                conditionsLogs
            })
        },
        changeTabTypeRecord: (tabType) => {
            dispatch({
                type: "CHANGE_TAB_CARD_RECORD_DETAIL",
                tabType
            })
        },
    }
})
class LogsMobile extends Component {

    state = {
        logs: [],
        pageMin: 1,
        page: 1,
        keyword: '',
        showModalFilter: true,
        showLog: false,
        conditions: [],
        params: {
            page: 1,
        }
    };

    listener = () => {
        if ((Math.ceil($(window).scrollTop()) >= $(document).height() - ($(window).height() + 100))
            // && this.props.currentPage !== this.props.totalPages
            && this.props.logs.length && !this.props.loading && document.getElementById('page-log')
            && this.props.hasNext
        ) {
            let page = this.state.page + 1;
            this.setState({
                params: {
                    ...this.state.params,
                    page: page
                }
            });
            this.props.loadPages(this.state.params);
            this.setState({
                    page: page
                }
            );
        }
    };
    componentDidMount() {
        if (this.props.conditionsLogs.length) {
            this.makeCondition(this.props.conditionsLogs);
            scrollService.top();
            window.addEventListener('scroll', this.listener);
        } else {
            scrollService.top();
            this.props.loadPages(this.state.params);
            window.addEventListener('scroll', this.listener);

        }
    }

    componentWillUnmount() {
        this.props.resetLogs();
        scrollService.top();
        window.removeEventListener('scroll', this.listener);
    }


    onClickLink = (log) => {
        if (log.action !== 'delete') {
            switch (log.type) {
                case 'surveyRecord' :
                    if(log.action === 'comment') {
                        this.props.changeTabTypeRecord('COMMENT_TAB');
                        return this.props.history.push(`/records-beta-detail?id=${log['type_id']}`);
                    }
                    return this.props.history.push(`/records/record/${log['type_id']}`);
                case 'surveyTemplate' :
                    return this.props.history.push(`/surveys/survey-detail/${log['type_id']}`);
                case 'team' :
                    return this.props.history.push(`/teams/team-detail/${log['type_id']}`);
                case 'customer' :
                    return this.props.history.push(`/customers/customer-detail/${log['type_id']}`);
                default :
                    return this.props.history.push(`/staffs/staff/${log['type_id']}`);
            }
        }
    };

    renderLabel (log) {
        switch (log.action) {
            case "login":
                return (<KirokuLabel color='dark'
                                     style={{padding: '2px 4px'}}>{this.props.t('common.login')}</KirokuLabel>);
            case "create":
                return (<KirokuLabel color='primary'
                                     style={{padding: '2px 4px'}}>{this.props.t('common.create')}</KirokuLabel>);
            case "update":
                return (<KirokuLabel color='success'
                                     style={{padding: '2px 4px'}}>{this.props.t('common.Update')}</KirokuLabel>);
            case "delete":
                return (<KirokuLabel color='danger'
                                     style={{padding: '2px 4px'}}>{this.props.t('common.Delete')}</KirokuLabel>);
            case "view":
                return (<KirokuLabel color='light'
                                     style={{padding: '2px 4px'}}>{this.props.t('common.ViewDetail')}</KirokuLabel>);
            case "comment":
                return (<KirokuLabel color='warning' style={{padding: '2px 4px'}}>{this.props.t('common.comment')}</KirokuLabel>);
            default:
        }
    };

    onSearch(params) {
        this.setState({
            params: {...params, page: 1}
        }, () => {
            const {params} = this.state;
            this.props.searchLogs(params);
        })
    }

    onChangeDebounce = debounce(value => {
        this.onSearch({
            ...this.state.params,
            keyword: value
        })
    }, 500);

    handleShowModal() {
        this.setState({
            showModalFilter: true,
            showLog: !this.state.showLog
        })
    }

    showModalFilter() {
        this.setState({
            showModalFilter: !this.state.showModalFilter
        })
    }

    makeCondition(conditions) {
        this.setState({
            page: 1,
            conditions
        });

        let params = this.state.params;
        conditions.map(condition => {
            params[condition.query] = condition.value;
            return condition
        });
        this.setState({
            params: params
        }, () => {
            this.onSearch(params);
        });
    }

    filterCondition(conditions) {
        this.makeCondition(conditions);
        this.props.saveConditionLogs(conditions)
    }

    handleRemoveCondition(condition) {
        let newCondition = this.state.conditions.filter(item => item.value !== condition.value);
        let params       = {};
        newCondition.map(condition => {
            params[condition.query] = condition.value;
            return condition
        });
        this.setState({
            conditions: newCondition,
            params: {
                ...this.state.params,
                ...params,
                [condition.query]: '',
            }
        }, () => {
            this.onSearch(this.state.params);
            this.props.currentConditionLogs(newCondition)
        });
    }

    render() {
        const {t, logs}  = this.props;
        let {conditions} = this.state;
        return (
            <div>
                <ModalFilterLog
                    show={this.state.showModalFilter}
                    showModal={this.handleShowModal.bind(this)}
                    filterCondition={this.filterCondition.bind(this)}
                />

                <div hidden={!this.state.showModalFilter} id={'page-log'}>
                    <KirokuButton color={'white'} onClick={this.showModalFilter.bind(this)}>
                        <span className={'pr-2'}>{t('Surveys.FilterBy')}</span>
                        <i className='fa fa-chevron-down text-secondary'
                           style={{fontSize: '10px'}}/>
                    </KirokuButton>

                    <div className='pt-2'>
                        {
                            conditions.map((condition, index) => {
                                return condition.label.length ? <span key={index} className='mr-6'>
                                            <KirokuLabel
                                                style={{
                                                    border: 'solid 1px #1991eb'
                                                }}
                                                onClick={this.handleRemoveCondition.bind(this, condition)}
                                                hidden={!condition.label.length}
                                                color={'default'}>
                                                <span className='mt-1'>{condition.label}</span>
                                                <span className='align-icon'>&times;</span>
                                            </KirokuLabel>
                                    </span> : ''
                            })
                        }
                    </div>

                    <div className='pt-2'>
                        <KirokuInputSearch
                            onChange={e => this.onChangeDebounce(e.target.value)}
                            id={'inputSearchLogs'}
                        />
                    </div>

                    <div className='pt-10'>
                        <div className='card'>
                            {logs.map((log, index) =>
                                <div key={index}>
                                    <div className='d-flex padding-responsive'>
                                        <div className="mr-1 ">
                                            <Link to={`/staffs/staff/${log['user_id']}`}>
                                                <div className='avatar' style={{
                                                    width: 40,
                                                    height: 40,
                                                    borderRadius: '100%',
                                                    backgroundImage: `url(${log.avatar ? log.avatar : filePathService.resolveFilePath('profile-ic.svg')})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center center'
                                                }}/>
                                            </Link>
                                        </div>
                                        <div className="ml-1">
                                        <span className='pr-2'>
											{this.renderLabel(log)}
                                        </span>
                                            <b className='f-bold pr-1'>{log.user ? log.user.username : ''}</b>
                                            <span>
											<LogType log={log} t={t}/>
										</span>
                                            <span
                                                className='date-log pl-3'>{moment.unix(log.create_at).format(config.formatDateTh)}</span>
                                            <p className='mb-1'>
                                                {log['type_name'] ? <b className='text-primary' style={{cursor: 'pointer'}} onClick={this.onClickLink.bind(this, log)}>{log['type_name']}</b> :''}
                                            </p>
                                            <p>
                                                <b className='f-bold'>{log.user ? log.user.username : ''}</b>
                                            </p>
                                        </div>
                                    </div>
                                    <hr className='m-0 p-0'/>
                                </div>
                            )}
                            <div>
                                {this.props.loading ? <KirokuLoading/> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default LogsMobile;
