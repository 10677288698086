import {CURRENT_CONDITION, CURRENT_CONDITION_LOGS, RESET_CONDITION_SURVEY}       from "../actions/Behavior";
import {SAVE_QUERY_CONDITION_SURVEY, REMOVED_QUERY_SURVEY}                       from "../actions/SurveyTemplate";
import {CONDITION_REMOVED, RESET_CONDITION_RECORDS, SAVE_QUERY_CONDITION_RECORD} from "../actions/Record";
import {RESET_CONDITIONS_TEAM, SAVE_CONDITIONS_TEAM}                             from '../actions/Team';
import {RESET_CONDITIONS_CUSTOMER, SAVE_CONDITIONS_CUSTOMERS}                    from '../actions/Customer';

const initState = {
    conditions: [],//staffs
    conditionsLogs: [],
    defaultTags: [],
    conditionSurveys: {
        conditions: [],
        params: {
            title: '',
        },
        pagination: {},
        conditionRemoved: {},
    },
    conditionRecords: {
        conditions: [],
        conditionRemoved: {},
        params: {
            title: '',
        },
        pagination: {},
        defaultTags: [],
        pageSize : 10
    },
    conditionsTeam: {
        conditions: [],
        params: {
            name: '',
            page: 1
        },
    },
    conditionsCustomer: {
        conditions: [],
        params: {
            page: 1,
            status: 'active',
            name: ''
        }
    },
};

const KirokuAIBehavior = (state = initState, action) => {
    switch (action.type) {
        case 'FILTER_CONDITION':
            return {
                ...state,
                filterCondition: action.filterCondition
            };
        case CURRENT_CONDITION :
            return {
                ...state,
                updated: true,
                conditions: action.conditions
            };
        case CURRENT_CONDITION_LOGS :
            return {
                ...state,
                updated: true,
                conditionsLogs: action.conditionsLogs
            };
        case 'CURRENT_CONDITION_SUCCESS' :
            return {
                ...state,
                updated: false,
            };
        case 'CURRENT_CONDITION_LOG_SUCCESS' :
            return {
                ...state,
                updated: false,
            };
        case 'CURRENT_CONDITION_SAVE' :
            return {
                ...state,
                conditions: action.conditions
            };
        case 'RESET_CONDITION_STAFF':
            return {
                ...state,
                updated: true,
                conditions: [],//staffs
            };
        case 'CURRENT_CONDITION_SAVE_LOG' :
            return {
                ...state,
                conditionsLogs: action.conditionsLogs
            };
        case CONDITION_REMOVED :
            return {
                ...state,
                conditionRecords: {
                    ...state.conditionRecords,
                    conditionRemoved: action.conditionRemoved
                },
            };
        case REMOVED_QUERY_SURVEY:
            return {
                ...state,
                conditionSurveys: {
                    ...state.conditionSurveys,
                    conditionRemoved: action.conditionRemoved
                }
            };
        case  SAVE_QUERY_CONDITION_SURVEY :
            return {
                ...state,
                conditionSurveys: {
                    conditions: action.conditions,
                    params: action.params,
                    pagination: action.pagination
                }
            };

        case SAVE_QUERY_CONDITION_RECORD :
            return {
                ...state,
                conditionRecords: {
                    conditions: action.conditions,
                    params: action.params,
                    pagination: action.pagination,
                    defaultTags: action.defaultTags,
                    pageSize: action.pageSize
                }
            };
        case RESET_CONDITION_SURVEY:
            return {
                ...state,
                conditionSurveys: {
                    conditions: [],
                    params: {},
                    pagination: {},
                    conditionRemoved: {
                        showDateRange: false,
                        showFilterRecords: false,
                        show: false,
                        selectItem: false,
                        status: '',
                        nameStatus: '',
                        nameDate: '',
                        startDate: null,
                        endDate: null,
                        day: null,
                        week: null,
                        month: null,
                        creator: '',
                        page: 1,
                        nameStaff: '',
                    },
                },
            };
        case RESET_CONDITION_RECORDS:
            const profile = localStorage.getItem('profile');
            return {
                ...state,
                conditionRecords: {
                    defaultTags : profile.role === 'admin' ? [] : action.tags,
                    conditions: [],
                    conditionRemoved: {
                        showDateRange: false,
                        showFilterRecords: false,
                        show: false,
                        status: '',
                        statusRecord: '',
                        nameStatus: '',
                        startDate: '',
                        endDate: '',
                        day: '',
                        week: '',
                        month: '',
                        customerId: '',
                        templateId: '',
                        staffId: '',
                        staffName: '',
                        customerName: '',
                        titleSurvey: ''
                    },
                    params: {},
                    pagination: {},
                    pageSize: 10
                }
            };
        case SAVE_CONDITIONS_TEAM:
            return {
                ...state,
                conditionsTeam: {
                    conditions: action.conditions,
                    params: action.params,
                }
            };
        case RESET_CONDITIONS_TEAM:
            return {
                ...state,
                conditionsTeam: {
                    conditions: [],
                    params: {
                        name: '',
                        page: 1
                    },
                }
            };
        case SAVE_CONDITIONS_CUSTOMERS:
            return {
                ...state,
                conditionsCustomer: {
                    ...state.conditionsCustomer,
                    conditions: action.conditions,
                    params: action.params,
                }
            };
        case RESET_CONDITIONS_CUSTOMER:
            return {
                ...state,
                conditionsCustomer: {
                    conditions: [],
                    params: {
                        page: 1,
                        status: 'active',
                        name: ''
                    }
                }
            };
        default:
            return state
    }
};

export default KirokuAIBehavior;
