import React, {Component}                                                     from 'react'
import {
    KirokuModalBody,
    KirokuCardList,
    KirokuModalFooter,
    KirokuButton,
    KirokuModal,
    KirokuCard,
    KirokuCheckBox,
    KirokuDropdownClose
}                                                                             from "../../ui";
import {connect}                                                              from "react-redux";
import ModalAssignSurveyToCustomer                                            from "./ModalAssignSurveyToCustomer";
import {checkAllSurveys, checkSurvey, filterSurveyWithCondition, resetSurvey} from "../../actions/Survey";
import {loadTeam, resetTeam}                                                  from "../../actions/Team";
import {loadCustomerForSurvey}                                                from "../../actions/Customer";
import ModalDeleteSurveys                                                     from "./ModalDeleteSurveys";
import {withNamespaces}                                                       from "react-i18next";
import {authService, scrollService}                                           from "../../services";
import withPermissions                                                        from "../HOC/WithPermissions";

const BulkAction = withPermissions(KirokuDropdownClose);

@withNamespaces()
@connect((state) => {
    return {
        teams: state.KirokuAITeam.teams,
        surveyForModal: state.KirokuAISurvey.surveyForModal,
        disableButtonApply: state.KirokuAISurvey.disableButtonApply,
        checked: state.KirokuAISurvey.checked
    }
}, (dispatch) => {
    return {
        checkAll: (checked) => {
            dispatch(checkAllSurveys(checked))
        },
        surveySelected: (index) => {
            dispatch(checkSurvey(index))
        },
        loadTeam: () => {
            dispatch(loadTeam())
        },
        loadCustomerForSurvey: () => {
            dispatch(loadCustomerForSurvey())
        },

        resetSurvey: () => {
            dispatch(resetSurvey())
        },
        resetTeam: () => {
            dispatch(resetTeam())
        },

        filterSurveyWithCondition: (params) => {
            dispatch(filterSurveyWithCondition(params))
        }
    }
})

class ModalAssignUser extends Component {

    state = {
        checked: false,
        show: false,
        showModalCustomer: false,
        disableButtonApply: false,
        showModalDeleteSurvey: false,
        page: 1,
        pageMin: 1,
        disabledPrev: true,
        disabledNext: false
    };

    bulkAction = () => {
        if (authService.isAdmin()) {
            return [
                {
                    value: 'assign',
                    component: (<div>{this.props.t('Surveys.AssignToUsers')}</div>),
                    label: this.props.t('Surveys.AssignToUsers')
                },
                {
                    value: 'delete',
                    component: (<div>{this.props.t('Surveys.Delete')}</div>),
                    label: this.props.t('Surveys.Delete')
                },
            ]
        }
        return [
            {
                value: 'assign',
                component: (<div>{this.props.t('Surveys.AssignToUsers')}</div>),
                label: this.props.t('Surveys.AssignToUsers')
            }
        ]
    };

    componentWillMount() {
        this.setState({
            currentState: this.props.currentState
        })
    }

    handleCheckAll() {
        this.props.checkAll(this.props.checked);
    }

    closeModal() {
        this.props.resetSurvey();
        this.setState({
            show: !this.state.show,
            checked: false
        })
    }

    closeModalAssignCustomer() {
        this.props.resetTeam();
        this.props.resetSurvey();
        this.setState({
            showModalCustomer: !this.state.showModalCustomer,
            checked: false,
        })
    }

    showModalDelete() {
        this.props.loadCustomerForSurvey();
        this.props.loadTeam();
        this.setState({
            show: false,
            showModalCustomer: !this.state.showModalCustomer
        })
    }

    handleCheckSurveys(index) {
        this.props.surveySelected(index);
    }

    closeModalDeleteSurvey() {
        this.setState({
            showModalDeleteSurvey: false
        })
    }

    increasePage() {
        let nextPage = this.props.currentPage === this.props.totalPage ? this.props.totalPage : this.props.currentPage + 1;
        if (nextPage === this.props.totalPage) {
            this.setState({
                disabledNext: !this.state.disabledNext,
            })
        }
        scrollService.top();
        this.setState({
            page: nextPage,
            disabledPrev: false,
        }, () => this.props.handleSelectPage(nextPage));
    }

    decreasePage() {
        let previousPage = this.props.currentPage === this.state.pageMin ? 1 : this.props.currentPage - 1;
        if (previousPage === this.state.pageMin) {
            this.setState({
                disabledPrev: true
            })
        }
        scrollService.top();
        this.setState({
            page: previousPage,
            disabledNext: false,
        }, () => {
            this.props.handleSelectPage(previousPage)
        });
    }

    isSelect() {
        return this.props.surveyForModal.filter(item => item.isChecked)
    }

    render() {
        let {t, GPS} = this.props;
        return (
            <div>
                <BulkAction
                    title={t('Surveys.BulkAction')}
                    style={{minWidth: 130}}
                    location={GPS ? '' : 'top'}
                    items={this.bulkAction()}
                    onSelect={event => {
                        if (event.kirokuSelected.value === 'assign') {
                            this.setState({
                                show: !this.state.show
                            });
                        } else {
                            this.setState({showModalDeleteSurvey: !this.state.showModalDeleteSurvey})
                        }
                    }}
                    resource-permission={'|surveyList|button-bulkAction|'}
                />

                <KirokuModal show={this.state.show}>
                    <KirokuModalBody>
                        <div id="body-modal-as">
                            <KirokuCardList
                                headerLeft={t('Surveys.SelectSurveys')}
                                headerRight={
                                    <div className='text-primary' style={{cursor: 'pointer'}}>
                                        <span hidden={this.props.checked}
                                              onClick={this.handleCheckAll.bind(this)}
                                        >{t('common.SelectAll')}</span>

                                        <span hidden={!this.props.checked}
                                              onClick={this.handleCheckAll.bind(this)}
                                        >{t('common.UnSelect')}</span>
                                    </div>
                                }
                            >
                                <section className="scroll-data-survey">
                                    {this.props.surveyForModal.map((survey, index) => (
                                        <KirokuCard key={survey.id}>
                                            <KirokuCheckBox onChange={this.handleCheckSurveys.bind(this, index)}
                                                            key={index}
                                                            checked={survey.isChecked} content={survey.title}/>
                                        </KirokuCard>
                                    ))}
                                </section>
                            </KirokuCardList>
                        </div>
                    </KirokuModalBody>
                    <KirokuModalFooter>
                        <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                            {t('common.Cancel')}
                        </KirokuButton>
                        <KirokuButton disabled={!this.props.disableButtonApply}
                                      onClick={this.showModalDelete.bind(this)} color={'primary'}>
                            {t('common.Apply')}
                        </KirokuButton>
                    </KirokuModalFooter>
                </KirokuModal>
                <ModalAssignSurveyToCustomer surveys={this.isSelect()}
                                             closeModalAssignCustomer={this.closeModalAssignCustomer.bind(this)}
                                             show={this.state.showModalCustomer}/>
                <ModalDeleteSurveys closeModalDeleteSurvey={this.closeModalDeleteSurvey.bind(this)}
                                    show={this.state.showModalDeleteSurvey}/>
            </div>
        )
    }
}

export default ModalAssignUser;
