import { deletedList } from "../services";

function callAPIDeletedSurveys(condition, store, next, action) {
    return deletedList.getSurveysByCondition(condition).then(res => {
        store.dispatch({
            type: 'SET_FINISH_LOAD_API_DEL_SURVEY'
        });

        store.dispatch({
            ...action,
            ...res.data,
            type: 'SET_SURVEYS_API'
        });

        next({
            ...action,
            ...res.data
        })
    })
}

export const DeletedSurveysMiddleware = store => next => action => {

    const conditionInDeletedSurvey               = store.getState().DeletedSurveys;
    const { nameScreen } = action;

    if (nameScreen !== 'surveys') {
        return next(action);
    }

    switch (action.type) {
        case 'CHANGE_SCREEN':
            let condition = {
                page    : 1,
                orderBy : {
                    field: 'delete_at',
                    type : 'DESC'
                },
                keyword : '',
            };
            return callAPIDeletedSurveys(condition, store, next, action);
        case 'CHANGE_PAGE_NUMBER':
            return callAPIDeletedSurveys({
                page    : action.numberPage,
                orderBy : conditionInDeletedSurvey.useOrderBy,
                keyword : conditionInDeletedSurvey.keywordSubmit,
            }, store, next, action);
        case 'CHANGE_ORDER_BY':
            return callAPIDeletedSurveys({
                page    : 1,
                orderBy : {
                    field: action.field,
                    type : action.typeOrder,
                },
                keyword : conditionInDeletedSurvey.keywordSubmit,
            }, store, next, { ...action, type: 'CHANGE_ORDER_BY_SURVEYS' });
        case 'CHANGE_SEARCH_KEYWORD' :
            return next({
                ...action,
                type: 'CHANGE_KEY_WORD_SURVEYS'
            });
        case 'SUBMIT_SEARCH_KEYWORD':
            return callAPIDeletedSurveys({
                ...conditionInDeletedSurvey,
                orderBy: conditionInDeletedSurvey.useOrderBy,
                page    : 1,
                keyword : conditionInDeletedSurvey.keyword
            }, store, next, {...action, type: 'SUBMIT_SEARCH_KEYWORD_SURVEYS'});
        default :
            return next(action);
    }
};
