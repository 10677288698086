import {Component}        from "react";
import React              from "react";
import KirokuDropdownItem from "./KirokuDropdownItem";
import KirokuInputSearch  from "./KirokuInputSearch";

export default class KirokuResetFilterConditionSearch extends Component {

    handleSelected(event) {
        this.props.onSelect(event);
        this.setState({
            popupVisible: false,
            selected: event.kirokuSelected
        });
    }

    constructor(props) {
        super(props);
        this.handleClick        = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.state = {
            selected: {
                label: ''
            },
            value: '',
            popupVisible: false,
            itemsShow: [],
            itemsSearch: []
        };
    }

    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
    }

    handleOutsideClick(e) {
        if (!this.node) {
            return;
        }

        if (this.node.contains(e.target)) {
            return;
        }

        this.handleClick();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.items.length) {
            this.setState({
                itemsShow: nextProps.items,
                itemsSearch: nextProps.items
            });
        }

        if (nextProps.title !== this.state.selected.label && nextProps.title !== this.state.value) {
            const newSelected = {...this.state.selected, label: nextProps.title};
            this.setState({
                selected: newSelected,
                value: nextProps.title,
            });
        }
    }

    filterItems(e) {
        let input = e.target.value.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
        let items = this.state.itemsShow.filter(item =>
            item.label.toLowerCase().search(input.toLowerCase()) !== -1);
        this.setState({
            itemsSearch: items
        });
    }
    render() {
        return (
            <div ref={node => {
                this.node = node
            }}>
                <div className={`dropdown ${this.props.className ? this.props.className : ''}`}>
                    <button hidden={this.props.hidden}
                            disabled={this.props.disabled}
                            style={this.props.style}
                            className={`overflow btn btn-kiroku ${this.props.className ? this.props.className : ''}`}
                            onClick={this.handleClick.bind(this)}>
                        <i className={`${this.props.icon} left text-kiroku pr-5px`}/>
                        <span
                            className={'text-kiroku left'}>{this.state.selected.label ? this.state.selected.label : this.props.defaultValue}</span>
                        <i className={`${!this.state.popupVisible ? 'fa fa-chevron-down icon-kiroku' : 'fa fa-chevron-up icon-kiroku'}`}/>
                    </button>
                    {this.state.popupVisible && (
                        <div
                            className={`dropdown-kiroku dropdown-menu show ${this.props.location ? this.props.location : ''}`}
                            style={{position: 'absolute', willChange: 'top, left', left: 'auto', top: 'auto'}}>
                            <div className='dropdown-item-kiroku bg-light p-2'>
                                <KirokuInputSearch onChange={this.filterItems.bind(this)}/>
                            </div>
                            <section className='scroll-data-dropdown-search'>
                                {this.state.itemsSearch.map((item, index) => {
                                    const Item = item.component;
                                    return (
                                        <KirokuDropdownItem key={index} onClick={(event) => {
                                            event.kirokuSelected = item;
                                            this.handleClick(event);
                                            this.handleSelected(event)
                                        }}>
                                            {Item}
                                        </KirokuDropdownItem>
                                    )
                                })}
                            </section>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

