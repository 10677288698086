import React, {Component}             from 'react';
import {buildDataSingle}              from "../buildData";
import {KirokuLoading } from '../../../ui';
import {Chart} from "react-google-charts";

class SingleChart extends Component {
    render() {
        const {records, question} = this.props;
        const data = buildDataSingle(records, question);
        const options = {
            pieHole: 0.4,
            legend: 'bottom',
            pieSliceTextStyle: {
                color: 'black',
            },
        };
        return (
            <div>
                <Chart
                    chartType="PieChart"
                    width="100%"
                    height="500px"
                    data={data}
                    loader={<KirokuLoading/>}
                    options={options}
                />
            </div>
        )
    }
}

export default SingleChart;
