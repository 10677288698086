import React, { Component }      from 'react';
import KirokuUploadFile          from '../../../../ui/KirokuUpLoadFile';
import { withNamespaces }        from 'react-i18next';
import KirokuLogicApplicated     from "../../../../ui/KirokuLogicApplicated";
import {checkUseHeadingQuestion} from "../../../Surveys/SurveyEditor/SurveyService";

@withNamespaces()
class MediaFile extends Component {
    render() {
        const {question, surveyDetail, headerNextQuestion, t} = this.props;
        const contentQuestion = checkUseHeadingQuestion(question);
        return (
            <div className='content-question'>
                <div className='wraper-question'>
                    <div className="background-question">
                        <div className='number-question d-flex justify-content-between'>
                            <div
                                className="question-number text-overflow-elip">
                                {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                            </div>
                            <div hidden={!surveyDetail} className={'float-right'}>
                                <KirokuLogicApplicated padding={'5px 5px 5px 7px'} question={question}/>
                            </div>
                        </div>
                        <span className='content-name-question'>{question.content}</span>
                        <div
                            hidden={ typeof question.nextQuestion !== 'number' || !surveyDetail}
                            className='text-info pl-10 text-overflow-elip'>
                            <i className='fas fa-arrow-right' style={{paddingTop: 4}}/>
                            <span> {headerNextQuestion}</span>
                        </div>
                    </div>
                    <div className='answer-question'>
                        <KirokuUploadFile disabled url={this.props.value.answer ? this.props.value.answer : null}
                                          fileType={this.props.value.typeAnswer}
                                          surveyDetail
                                          onChange={() => {}}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default MediaFile;
