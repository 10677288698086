import React, { Component } from 'react';
import KirokuButton         from './KirokuButton';
import { withNamespaces }   from 'react-i18next';
import ModalPreview         from '../components/Customers/CustomerDetail/Web/ModalPreview';
@withNamespaces()
class KirokuUploadFile extends Component {

    state = {
        show: false
    };

    closeModal() {
        this.setState({
            show: false
        })
    }

    render() {
        const {t, fileType, surveyDetail} = this.props;
        return (
            <div className="wrap-upload">
                {
                    surveyDetail && this.props.url ?  <ModalPreview
                        show={this.state.show}
                        closeModal={this.closeModal.bind(this)}
                        response={{
                            path: this.props.url,
                            fileType: fileType.split("/")[1]
                        }}/>: ''
                }

                {this.props.url ?
                    <div className="form-upload">
                        <div className="image-upload"
                             style={{backgroundImage: `url(${this.props.url})`,
                                 cursor: `${ surveyDetail && this.props.url ? 'pointer' : 'default'}`}}
                             onClick={surveyDetail ? () => {this.setState({ show: true })} : () => {}}
                        />
                        <div className="overlay-upload-file" hidden={this.props.disabled}>
                            <div className="content-upload">
                                <KirokuButton color={'success'} onClick={() => this.inputElement.click()}>
                                    <i className="fas fa-cloud-upload-alt cursor-pointer"/>
                                    <span>{t('createRecord.ChangePhoto')}</span>
                                </KirokuButton>
                            </div>
                            <input disabled={this.props.disabled} type='file' onChange={this.props.onChange.bind(this)}
                                   accept="image/jpeg, image/png"
                                   ref={input => this.inputElement = input}
                                   hidden/>
                        </div>
                    </div> :
                    <div className='form-upload'>
                        <div className="image-upload-default"
                             onClick={() => this.inputElement.click()}
                             style={{backgroundColor: 'white' }}>
                            <div className="overlay-upload-file-default">
                                <div className="content-upload">
                                    <i className="fas fa-cloud-upload-alt"/>
                                    <br/>
                                    <span className={`change-photo ${this.props.disabled ? '':  'cursor-pointer'}`}>{t('createRecord.AddAPhoto')}</span>
                                </div>
                            </div>
                            <input type='file' onChange={this.props.onChange.bind(this)}
                                   accept="image/jpeg, image/png"
                                   ref={input => this.inputElement = input}
                                   hidden
                                   disabled={this.props.disabled}
                            />
                        </div>
                    </div>
                }
            </div>
        )
    }
}
export default  KirokuUploadFile;
