import React, { Component }                                              from 'react';
import KirokuQuestionsCards                                              from './KirokuQuestionsCards';
import { Link }                                                          from 'react-router-dom';
import { withNamespaces }                                                from 'react-i18next';
import { connect }                                                       from 'react-redux';
import { authService, recordService }                                    from '../../../../services';
import { NOTIFICATION }                                                  from '../../../../actions/Notification';
import { KirokuButton, KirokuModal, KirokuModalBody, KirokuModalFooter } from '../../../../ui';
@connect(() => {
    return {}
}, dispatch => {
    return {
        notificationMessageDeleteSuccess: () => {
            dispatch({
                type: NOTIFICATION,
                typeNotification: 'success',
                message: 'Surveys.DeletedSuccessfully'
            });
        },
        notificationMessageDeleteFall: () => {
             dispatch({
                 type: NOTIFICATION,
                 typeNotification: 'danger',
                 message: 'Records.deleteFall'
             })
        },
    }
})
@withNamespaces()
class RecordsTab extends Component {

    state = {
        showModalDeleteRecords: false,
    };

    handleDelRecord() {
        this.setState({
            showModalDeleteRecords: true
        })
    }

    closeModalDeleteRecords() {
        this.setState({
            showModalDeleteRecords: false
        })
    }

    deleteRecords() {
        const { record } = this.props;
        recordService.deleteRecord(record.id).then(() => {
            this.props.notificationMessageDeleteSuccess();
            this.props.history.push('/records-beta');
        }).catch(() => {
            this.props.notificationMessageDeleteFall();
        });
    }

    render() {
        const { record, t, show } = this.props;
        return (
            <div className='pt-3' hidden={!show}>
                <KirokuModal show={this.state.showModalDeleteRecords}
                             onClick={this.closeModalDeleteRecords.bind(this)}>
                    <KirokuModalBody>
                        <div className='text-center pt-3'>
                            <b>{t('RecordList.AreYouWantDelete')}</b>
                            <p style={{fontSize: 16}}>{record.title}</p>
                        </div>
                    </KirokuModalBody>
                    <KirokuModalFooter>
                        <KirokuButton onClick={this.closeModalDeleteRecords.bind(this)} color={'white'}>
                            {t('common.Cancel')}
                        </KirokuButton>
                        <KirokuButton onClick={this.deleteRecords.bind(this)} color={'primary'}>
                            {t('common.Confirm')}
                        </KirokuButton>
                    </KirokuModalFooter>
                </KirokuModal>
                <div className="d-flex justify-content-between">
                    {authService.isAdmin() ?
                        <div style={ { height: 62 } } onClick={this.handleDelRecord.bind(this)}
                             hidden={!authService.isAdmin()}
                        >
                            <div className=' pt-2 border-delete-btn font-delete-label cursor-pointer'
                                 style={{ textAlign: 'center', float: 'left'}}
                            >
                                {t('RecordsBeta.delete')}
                            </div>
                        </div> : <div/>
                    }
                    <div style={ { height: 62 } } >
                        <Link to={`/records/edit-filedAll/${record.id}`}
                            onClick={()=> {window.history.previous = window.location.pathname}}
                        >
                            <div className=' pt-2 border-back-btn font-back-label cursor-pointer'
                                 style={{ textAlign: 'center', float: 'right'}}
                            >
                                {t('RecordsBeta.edit')}
                            </div>
                        </Link>
                    </div>
                </div>
                <div className={''}>
                    <KirokuQuestionsCards questions={record.survey_template.flowedQuestions || record.survey_template.questions }/>
                </div>
            </div>
        );
    }
}
export default RecordsTab;
