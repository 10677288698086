import React, {Component} from 'react';
import {
    KirokuButton,
    KirokuCard,
    KirokuCheckBox,
    KirokuModal,
    KirokuModalBody,
    KirokuModalFooter,
    KirokuModalHeader,
    KirokuCardList,
    KirokuLoading,
    KirokuSectionDropdown
} from "./index";
import {withNamespaces}   from "react-i18next";
import _                  from 'lodash';

@withNamespaces()
class ModalSelectCustomerOfTeam extends Component {

    state = {
        currentTags: [],
        customerAfterSearched: [],
        loadingSearch: false,
        listCustomer: [],
    };

    getCustomers = (customers, customersSelected) => {
        let customersNew = JSON.parse(JSON.stringify(customers));
        if(customersSelected.length) {
            return customersNew.map(customer => {
                return {
                    ...customer,
                    isChecked: _.findIndex(customersSelected, ['customerId', customer.id]) > -1
                }
            })
        } else {
            return customersNew.map(customer => {
                return {
                    ...customer,
                    isChecked: false
                }
            })
        }
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.customersSelected) {
            const {customersSelected, customers} = nextProps;
            this.setState({
                listCustomer: this.getCustomers([...customers], customersSelected),
                customerAfterSearched: this.getCustomers([...customers], customersSelected),
                loadingSearch: true
            })
        }
    }

    componentWillMount() {
        const {customersSelected} = this.props;
        this.setState({
            listCustomer: this.getCustomers([...this.props.customers], customersSelected),
            customerAfterSearched: this.getCustomers([...this.props.customers], customersSelected),
            loadingSearch: true
        })
    }

    handleChangeTags(options) {
        this.setState({
            currentTags: options,
            loadingSearch: false
        });
        let customersOfTeam = [];
        if(options.length) {
            options.forEach(itm => {
                customersOfTeam = customersOfTeam.concat(itm.customers);
            });

            customersOfTeam = _.uniqBy(customersOfTeam, 'id');

            let customersOfTeamNew = customersOfTeam.map(customer => {
                const indexCustomerNew = _.findIndex([...this.state.listCustomer], {'id': customer.id});
                if(customer.id === this.state.listCustomer[indexCustomerNew].id) {
                    return  {
                        ...customer,
                        isChecked: this.state.listCustomer[indexCustomerNew].isChecked
                    }
                }
                return {
                    ...customer,
                }
            });

            this.setState({
                customerAfterSearched: customersOfTeamNew,
                loadingSearch: true
            })
        } else {
            this.setState({
                customerAfterSearched: this.state.listCustomer,
                loadingSearch: true
            })
        }

    }

    handleCheckAll(statusCheck) {
        const {customerAfterSearched, listCustomer} = this.state;
        this.setState({
            customerAfterSearched: customerAfterSearched.map(ctm => {return {...ctm, isChecked: statusCheck}}),
        }, () => {
            const {customerAfterSearched} = this.state;
            this.setState({
                listCustomer: listCustomer.map(ctm => {
                    let isIndexCustomerIdOfCustomerAfterSearch = _.findIndex(customerAfterSearched, ['id', ctm.id]) > -1;
                    let isCheck = isIndexCustomerIdOfCustomerAfterSearch ?
                        customerAfterSearched[_.findIndex(customerAfterSearched, ['id', ctm.id])].isChecked
                        : ctm.isChecked;
                    return {
                        ...ctm,
                        isChecked: isCheck
                    }
                })
            })
        })
    }

    handleCheckCustomer(customer) {
        const { listCustomer, customerAfterSearched } =this.state;

        let listCustomerNew = [...listCustomer];
        const indexCustomerOfListCustomer = _.findIndex(listCustomerNew, ['id', customer.id]);

        listCustomerNew[indexCustomerOfListCustomer] = {
            ...listCustomerNew[indexCustomerOfListCustomer],
            isChecked: !listCustomerNew[indexCustomerOfListCustomer].isChecked,
        };

        let customerAfterSearchedNew = [...customerAfterSearched];
        const indexCustomerOfCustomerAfterSearch = _.findIndex(customerAfterSearchedNew, ['id', customer.id]);

        customerAfterSearchedNew[indexCustomerOfCustomerAfterSearch] = {
            ...customerAfterSearchedNew[indexCustomerOfCustomerAfterSearch],
            isChecked: !customerAfterSearchedNew[indexCustomerOfCustomerAfterSearch].isChecked
        };
        this.setState({
            customerAfterSearched   : customerAfterSearchedNew,
            listCustomer            :listCustomerNew
        });
    }

    handleCancel() {
        this.props.closeModal();
        this.setState({
            currentTags: [],
            customerAfterSearched: [],
            loadingSearch: false,
            listCustomer: [],
        })
    }

    handleApplySelectCustomer() {
        this.props.closeModal();
        this.props.customerSelectedInModal(this.state.listCustomer);
        this.setState({
            currentTags: [],
            customerAfterSearched: [],
            loadingSearch: false,
            listCustomer: [],
        })

    }

    isCheckAll = () => {
        const {customerAfterSearched} = this.state;
        return _.findIndex(customerAfterSearched, ['isChecked', false]) === -1
    };

    render() {
        const {t, show, teams} = this.props;
        const {customerAfterSearched, loadingSearch} = this.state;
        return (
            <KirokuModal show={show} onCloseModal={() => this.props.closeModal()}>
                <KirokuModalHeader>
                    <div className={'pt-3'}>
                        <KirokuSectionDropdown
                            placeholderSelectTeam
                            onChange={this.handleChangeTags.bind(this)}
                            value={this.state.currentTags}
                            options={teams}
                        />
                    </div>
                </KirokuModalHeader>
                <KirokuModalBody>
                    <div id="body-modal-as">
                        <KirokuCardList
                            headerLeft={t('common.Name')}
                            headerRight={
                                <div className='text-primary' style={{cursor: 'pointer'}} hidden={customerAfterSearched.length === 0}>
                                        <span hidden={this.isCheckAll()}
                                              onClick={this.handleCheckAll.bind(this, true)}
                                        >{t('common.SelectAll')}</span>

                                    <span hidden={!this.isCheckAll()}
                                          onClick={this.handleCheckAll.bind(this, false)}
                                    >{t('common.UnSelect')}</span>
                                </div>
                            }
                        >
                            <section className="scroll-data-survey">
                                {
                                    loadingSearch ?
                                        customerAfterSearched.map((customer, index) => {
                                            return <KirokuCard key={index}>
                                                <KirokuCheckBox
                                                    onChange={this.handleCheckCustomer.bind(this, customer)}
                                                    key={index}
                                                    id={`${index}-${customer.id}`}
                                                    checked={customer.isChecked} content={customer.name}/>
                                            </KirokuCard>
                                        }) :
                                        <KirokuLoading/>
                                }
                            </section>
                        </KirokuCardList>
                    </div>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton onClick={this.handleCancel.bind(this)} color={'white'}>
                        {t('common.Cancel')}
                    </KirokuButton>
                    <KirokuButton onClick={this.handleApplySelectCustomer.bind(this)} color={'primary'}>
                        {t('common.Apply')}
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>
        )
    }

}

export default ModalSelectCustomerOfTeam;
