import React, {Component} from 'react' ;
import {
    KirokuButton,
    KirokuCard,
    KirokuCardList,
    KirokuCheckBox,
    KirokuLoading,
    KirokuModal,
    KirokuModalBody,
    KirokuModalFooter,
    KirokuModalHeader,
    KirokuSectionDropdownShowMore
} from "./index";
import {withNamespaces}   from "react-i18next";
import _                  from "lodash";

@withNamespaces()
class ModalSelectStaffOfTeam extends Component {
    state = {
        currentTags: [],
        staffsAfterSearched: [],
        loadingSearch: false,
        listStaff: [],
    };

    getStaffs = (staffs, staffSelected) => {
        let staffsNew = JSON.parse(JSON.stringify(staffs));
        if(staffSelected.length) {
            return staffsNew.map(staff => {
                return {
                    ...staff,
                    isChecked: _.findIndex(staffSelected, ['id', staff.id]) > -1
                }
            })
        } else {
            return staffsNew.map(staff => {
                return {
                    ...staff,
                    isChecked: false
                }
            })
        }
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps['staffSelected']) {
            const {staffs, staffSelected} = nextProps;
            this.setState({
                listStaff: this.getStaffs([...staffs], staffSelected),
                staffsAfterSearched: this.getStaffs([...staffs], staffSelected),
                loadingSearch: true
            })
        }
    }

    componentWillMount() {
        const {staffSelected, staffs} = this.props;
        this.setState({
            listStaff: this.getStaffs([...staffs], staffSelected),
            staffsAfterSearched: this.getStaffs([...staffs], staffSelected),
            loadingSearch: true
        })
    }

    handleChangeTags(options) {
        this.setState({
            currentTags: options,
            loadingSearch: false
        });
        let staffsOfTeam = [];
        if(options.length) {
            options.forEach(itm => {
                staffsOfTeam = staffsOfTeam.concat(itm.users);
            });
            staffsOfTeam = _.uniqBy(staffsOfTeam, 'id');
            let staffsOfTeamNew = staffsOfTeam.map(staff => {
                const indexStaffNew = _.findIndex([...this.state.listStaff], {'id': staff.id});
                if(!!this.state.listStaff[indexStaffNew]) {
                    if(staff.id === this.state.listStaff[indexStaffNew].id) {
                        return  {
                            ...staff,
                            isChecked: this.state.listStaff[indexStaffNew].isChecked
                        }
                    }
                    return {
                        ...staff,
                    }
                }
                return {}
            });

            this.setState({
                staffsAfterSearched: staffsOfTeamNew.filter(staff => staff.id),
                loadingSearch: true
            })
        } else {
            this.setState({
                staffsAfterSearched: this.state.listStaff,
                loadingSearch: true
            })
        }

    }

    handleCheckAll(statusCheck) {
        const {staffsAfterSearched, listStaff} = this.state;
        this.setState({
            staffsAfterSearched: staffsAfterSearched.map(ctm => {return {...ctm, isChecked: statusCheck}}),
        }, () => {
            const {staffsAfterSearched} = this.state;
            this.setState({
                listStaff: listStaff.map(ctm => {
                    let isIndexCustomerIdOfCustomerAfterSearch = _.findIndex(staffsAfterSearched, ['id', ctm.id]) > -1;
                    let isCheck = isIndexCustomerIdOfCustomerAfterSearch ?
                        staffsAfterSearched[_.findIndex(staffsAfterSearched, ['id', ctm.id])].isChecked
                        : ctm.isChecked;
                    return {
                        ...ctm,
                        isChecked: isCheck
                    }
                })
            })
        })
    }

    handleCheckStaff(staff) {
        const { listStaff, staffsAfterSearched } =this.state;

        let listStaffNew = [...listStaff];
        const indexStaffOfListStaff = _.findIndex(listStaffNew, ['id', staff.id]);

        listStaffNew[indexStaffOfListStaff] = {
            ...listStaffNew[indexStaffOfListStaff],
            isChecked: !listStaffNew[indexStaffOfListStaff].isChecked,
        };

        let staffsAfterSearchedNew = [...staffsAfterSearched];
        const indexCustomerOfCustomerAfterSearch = _.findIndex(staffsAfterSearchedNew, ['id', staff.id]);

        staffsAfterSearchedNew[indexCustomerOfCustomerAfterSearch] = {
            ...staffsAfterSearchedNew[indexCustomerOfCustomerAfterSearch],
            isChecked: !staffsAfterSearchedNew[indexCustomerOfCustomerAfterSearch].isChecked
        };
        this.setState({
            staffsAfterSearched  : staffsAfterSearchedNew,
            listStaff            :listStaffNew
        });
    }

    handleCancel() {
        this.props.closeModal();
        this.setState({
            currentTags: [],
            staffsAfterSearched: [],
            loadingSearch: false,
            listStaff: [],
        })
    }

    handleApplySelectStaff() {
        this.props.closeModal();
        this.props.staffsSelectedInModal(this.state.listStaff);
        this.setState({
            currentTags: [],
            staffsAfterSearched: [],
            loadingSearch: false,
            listStaff: [],
        })

    }

    isCheckAll = () => {
        const {staffsAfterSearched} = this.state;
        return _.findIndex(staffsAfterSearched, ['isChecked', false]) === -1
    };
    render() {
        const {t, show, teams} = this.props;
        const {staffsAfterSearched, loadingSearch} = this.state;
        return(
            <KirokuModal show={show} onCloseModal={() => this.props.closeModal()}>
                <KirokuModalHeader>
                    <div className={'pt-3'}>
                        <KirokuSectionDropdownShowMore
                            tagType={'Team'}
                            placeholder={t('Team.placeholderSearch')}
                            onChange={this.handleChangeTags.bind(this)}
                            value={this.state.currentTags}
                            options={teams}
                        />
                    </div>
                </KirokuModalHeader>
                <KirokuModalBody>
                    <div id="body-modal-as">
                        <KirokuCardList
                            headerLeft={t('common.Name')}
                            headerRight={
                                <div className='text-primary' style={{cursor: 'pointer'}} hidden={staffsAfterSearched.length === 0}>
                                        <span hidden={this.isCheckAll()}
                                              onClick={this.handleCheckAll.bind(this, true)}
                                        >{t('common.SelectAll')}</span>

                                    <span hidden={!this.isCheckAll()}
                                          onClick={this.handleCheckAll.bind(this, false)}
                                    >{t('common.UnSelect')}</span>
                                </div>
                            }
                        >
                            <section className="scroll-data-survey">
                                {
                                    loadingSearch ?
                                        staffsAfterSearched.map((staff, index) => {
                                            return <KirokuCard key={index}>
                                                <KirokuCheckBox
                                                    onChange={this.handleCheckStaff.bind(this, staff)}
                                                    key={index}
                                                    id={`${index}-${staff.id}`}
                                                    checked={staff.isChecked} content={staff.name}/>
                                            </KirokuCard>
                                        }) :
                                        <KirokuLoading/>
                                }
                            </section>
                        </KirokuCardList>
                    </div>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton onClick={this.handleCancel.bind(this)} color={'white'}>
                        {t('common.Cancel')}
                    </KirokuButton>
                    <KirokuButton onClick={this.handleApplySelectStaff.bind(this)} color={'primary'}>
                        {t('common.Apply')}
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>
        )
    }
}

export default ModalSelectStaffOfTeam;
