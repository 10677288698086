import React, {Component}      from 'react';
import {
    FormatTimestamp,
    KirokuCardDeletedScreen,
    KirokuLoading,
    SearchNotFound,
}                              from "../../../../ui";
import {withNamespaces}        from "react-i18next";
import {connect}               from "react-redux";

@withNamespaces()
@connect(state => {
    return {
        customers : state.DeletedCustomer.customers,
        loading   : state.Loading
    }
}, dispatch => {
    return {

    }
})

class TableCustomerList extends Component{
    render() {
        const {t, customers, loading} = this.props;
        return(
            <div className='bg-white'>
                {!loading ?
                <KirokuCardDeletedScreen>
                {customers.length ?
                    customers.map((customer, index) => (
                        <div key={index}>
                            <div className=' pl-3 pt-3' >
                                <div className='header-card-del pb-2'>{customer.name}</div>
                                <div className='title-card-del'>{t('DeletedList.deletedAt')} : <FormatTimestamp timestampUnix={customer.delete_at}/></div>
                                <div className='title-card-del pb-2'>{t('DeletedList.createdAt')} : <FormatTimestamp timestampUnix={customer.create_at}/></div>
                            </div>
                            <hr className='hr-table-mobile'/>
                        </div>
                    )): <SearchNotFound/>}
                </KirokuCardDeletedScreen> : <KirokuLoading/>}
            </div>
        )
    }
}

export default TableCustomerList;
