import React, {Component}                                    from 'react';
import KirokuButton                                          from "../../../ui/KirokuButton";
import PersonalInformation                                   from "../CustomerProfile/PersonalInformation";
import CustomerInformation                                   from "./CustomerInformation";
import connect                                               from "react-redux/es/connect/connect";
import {loadTeam}                                            from "../../../actions/Team";
import {filePathService, makeCustomerService, uploadService} from "../../../services";
import {loadSurveyListPage}    from "../../../actions/Survey";
import ModalSelectAssignSurvey from "../ModalSelectAssignSurvey";
import KirokuLoading           from "../../../ui/KirokuLoading";
import {withNamespaces}        from "react-i18next";
import _                       from 'lodash'
import {saveDraft}             from "../../../actions/Customer";
//import ModalDraftCustomer                                    from "../ModalDraftCustomer";
import TagCustomer             from "../TagCustomer";
import CertificationMobile     from "../CertificationMobile";
import Guardianship            from "../Guardianship";
import Contacts                from "../Contacts";


//import StateFormProvider           from "../../../FormWithControl/StateFormProvider";
@connect(state => {
    return {
        draftCustomer: state.KirokuAICustomer.draftCustomer
    }
}, dispatch => {
    return {
        loadTeam: () => dispatch(loadTeam()),
        loadSurveys: () => {
            dispatch(loadSurveyListPage())
        },
        saveDraft: (typeDraft, draft) => {
            dispatch(saveDraft(typeDraft, draft))
        },
        resetStateStore: () => {
            dispatch({type: 'RESET_STATE'})
        }
    };
})

@withNamespaces()

class ComponentCustomerEditorMobile extends Component {
    state = {
        customerRecords: {},
        customer: {},
        teams: [],
        loadingImg: false,
        errors: this.props.errors,
        isEdit: false
    };

    componentWillUnmount() {
        this.props.resetStateStore();
    }

    componentWillMount() {
        this.setState({
            customer: this.props.customer,
        });
        this.props.loadTeam();
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.customer) {
    //         this.setState({
    //             customer: nextProps.customer,
    //         })
    //     }
    // }

    conditionUpload(file) {
        return (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') && file.size < 10485760;
    }

    handleChangeImage() {
        this.setState({
            loadingImg: !this.state.loadingImg && this.inputElement.files[0]
        });
        if (this.inputElement.files[0] && this.conditionUpload(this.inputElement.files[0])) {
            let image = new FormData();
            image.append('file', this.inputElement.files[0]);
            image.append('name', this.inputElement.files[0].name);

            image.append('description', 'some value user types');
            uploadService.uploadImage(image, 'avatar').then((res) => {
                const customer  = this.state.customer;
                customer.avatar = res.url;
                this.setState({
                    customer: customer,
                    loadingImg: !this.state.loadingImg
                }, () => {
                    this.onChangeCustomer(this.state.customer)
                });
            });
        }
    };

    checkError() {
        return !Object.values(this.state.errors).every(item => !item)
    }

    onChangeCustomer(customer) {
        let newsCustomer = Object.assign(this.state.customerRecords, customer);
        this.setState({
            customerRecords: newsCustomer,
            customer: newsCustomer,
            isEdit: true
        })
    }

    handleSubmitCustomer() {
        if (!_.isEmpty(this.state.customerRecords)) {
            let customer = makeCustomerService.makeCustomerForTag(this.state.customerRecords, this.state.isEdit);
            this.props.handleSubmit({
                ...customer,
                certifications: this.state.customer.certifications
            })
        } else {
            let customer = makeCustomerService.makeCustomerForTag(this.state.customer, this.state.isEdit);
            this.props.handleSubmit({
                ...customer,
                certifications: this.state.customer.certifications
            })
        }

    }

    goBack() {
        this.props.history.goBack()
    }

    currentError = (errors) => {
        this.setState({errors: {...this.state.errors, ...errors}})
    };

    addCertification(certification) {
        this.setState({
            customer: {
                ...this.state.customer,
                certifications: this.state.customer.certifications.length ? this.state.customer.certifications.map(itemCertification => {
                    return {
                        ...itemCertification,
                        ...certification
                    }
                }): [certification]
            }
        })
    }

    render() {
        let {t} = this.props;
        return (
            <div className='customer-detail default-background-color'>
                <ModalSelectAssignSurvey
                    onChangeCustomer={this.onChangeCustomer.bind(this)}
                    customer={this.state.customer} history={this.props.history}
                />

                <div className='text-center bg-white card-customer-editor'>
                    <div className='fix-center'>
                        {!this.state.loadingImg ?
                            <div className='customer-detail height-avatar'
                                 style={{backgroundImage: `url(${this.state.customer.avatar ? this.state.customer.avatar : `${filePathService.resolveFilePath('profile-ic.svg')}`})`}}
                            /> : <KirokuLoading/>}
                    </div>

                    <div className='mt-24'>
                        <div>
                            <KirokuButton color={'success'} onClick={() => this.inputElement.click()}>
                                {t('CustomerEditor.ChangeAvatar')}
                            </KirokuButton>
                            <input type='file' ref={input => this.inputElement = input} hidden
                                   accept="image/jpeg, image/png"
                                   onChange={this.handleChangeImage.bind(this)}
                            />
                        </div>
                    </div>
                    <div className='content-avatar'>
                        <span>{t('Customer.SupportedFile')}</span>
                        <br/>
                        <span>{t('Customer.Maximum')}</span>
                    </div>

                </div>
                <div className='bg-white mt-10'>
                    <div className='p-3'>
                        <PersonalInformation
                            currentError={this.currentError.bind(this)}
                            customer={this.state.customer}
                            onChangeCustomer={this.onChangeCustomer.bind(this)}/>
                        <hr className="mt-4"/>
                        <TagCustomer
                            currentError={this.currentError.bind(this)}
                            customer={this.state.customer}
                            teams={this.props.teams}
                            surveys={this.props.surveys}
                            users={this.props.users}
                            onChangeCustomer={this.onChangeCustomer.bind(this)}
                        />
                        <hr className="mt-4"/>
                        <CustomerInformation
                            currentError={this.currentError.bind(this)}
                            customer={this.state.customer}
                            teams={this.props.teams}
                            onChangeCustomer={this.onChangeCustomer.bind(this)}/>
                        <hr className="mt-4"/>
                        <Contacts
                            customer={this.state.customer}
                            onChangeCustomer={this.onChangeCustomer.bind(this)}
                        />
                        <hr className="mt-4"/>
                        <CertificationMobile
                            certifications={this.state.customer.certifications}
                            addCertification={this.addCertification.bind(this)}
                            validateDateRanger={(errDate) => this.setState({
                                errors: {
                                    ...this.state.errors,
                                    errDate
                                }
                            })}
                            validateNumberCertification={error => this.setState({
                                errors: {
                                    ...this.state.errors,
                                    errNumberCertifications: error
                                }
                            })}
                        />
                        <hr className="mt-4"/>
                        <Guardianship
                            currentError={this.currentError.bind(this)}
                            customer={this.state.customer}
                            onChangeCustomer={this.onChangeCustomer.bind(this)}
                        />
                    </div>
                </div>

                <div className="center-xs">
                    <div className='group-action-customer-create-mobile mt-24 pb-5'>
                        <div className='w-100-mb'>
                            <KirokuButton onClick={this.goBack.bind(this)} color='white'>
                                {t('common.Cancel')}
                            </KirokuButton>
                        </div>
                        <div className='m-20-mb w-100-mb pb-2'>
                            <KirokuButton disabled={this.checkError()}
                                          onClick={this.handleSubmitCustomer.bind(this)}
                                          color='primary'>
                                {t('common.Save')}
                            </KirokuButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default ComponentCustomerEditorMobile;
