import React, { Component }      from 'react';
import moment                    from 'moment';
import KirokuInputIcon           from '../../../ui/KirokuInputIcon';
import { withNamespaces }        from 'react-i18next';
import {checkUseHeadingQuestion} from "../../Surveys/SurveyEditor/SurveyService";
import KirokuButtonLink          from '../../../ui/KirokuButtonLink';

@withNamespaces()
class TimeQuestion extends Component {

    state = {
        value: null,
        clock: ''
    };

    componentDidMount() {
        this.props.onAnswer({
            answer   : this.state.value,
            validated: this.state.value ? this.state.value : null
        });
    }

    componentWillMount() {
        if (this.props.value) {
            this.setState({
                value: this.props.value,
                clock: moment.unix(this.props.value).format('HH:mm')
            })
        }
    }


    componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            if (nextProps.value) {
                this.setState({
                    value: nextProps.value,
                    clock: moment.unix(nextProps.value).format('HH:mm')
                });
            }
        }
    }

    handleOnChangeDateTime() {

        if (this.state.clock) {
            let answer = moment(`${this.state.clock}`, 'HH:mm').unix();
            this.props.onAnswer({
                answer   : answer,
                validated: answer ? answer : null
            });
        }

        if (!this.state.clock) {
            this.props.onAnswer({
                answer   : null,
                validated: null
            });
        }

    }

    render() {
        const {
            question, t,
            // index
        } = this.props;
        const { clock}        = this.state;
        const contentQuestion      = checkUseHeadingQuestion(question);
        return (
            <div id='component-question'>
                <div className='content-question'>
                    <div className='wraper-question'>
                        <div className="background-question">
                            <div className='number-question d-flex justify-content-between'>
                                <span className="question-number">
                                    {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                                    {/*{t('createRecord.Question')}{index}:*/}
                                </span>
                                <div/>
                                <div>
                                    <KirokuButtonLink
                                        className='padding-reset-button-link'
                                        onClick={() => this.setState({ clock : '' }, () => {this.handleOnChangeDateTime()})}
                                    >
                                        {t('createRecord.resetAnswer')}
                                    </KirokuButtonLink>
                                </div>
                            </div>
                        </div>
                        <div className='answer-question'>
                            <span className='content-name-question padding-question-content '>
                                {question.content}
                            </span>
                            <div className={'mb-1 mt-1'}/>
                            <KirokuInputIcon name={`${question.index}clock`} icon={'far fa-clock'}
                                             type={'time'}
                                             value={clock}
                                             onChange={e => this.setState({clock: e.target.value},
                                                 this.handleOnChangeDateTime.bind(this))}

                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TimeQuestion;
