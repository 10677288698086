import React, { Component }            from 'react';
import { connect }                     from 'react-redux';
import { KirokuCheckBox, KirokuInput } from '../../../../../../ui';
import _                               from 'lodash';
import { withNamespaces }              from 'react-i18next';

@withNamespaces()
@connect(state => {
    return {
        surveys: state.KirokuModal.ModalSelectSurveys.surveys,
        keyword: state.KirokuModal.ModalSelectSurveys.keywordTabOptions,
    };
}, dispatch => {
    return {
        handleSelected: (index) => {
            dispatch({
                type: 'SELECT_SURVEY_MODAL_RECORD_BETA',
                index
            })
        },
        handleSelectAllSurveys: () => {
            dispatch({
                type: 'SELECT_ALL_SURVEY_MODAL_RECORD_BETA',
            })
        },
        onChangeKeyword: (keyword) => {
            dispatch({
                type: 'CHANGE_KEYWORD_SEARCH_TAB_OPTION_SURVEYS',
                keyword
            })
        },

    };
})
class TabOptionsSurveys extends Component {
    render() {
        const { surveys, t, keyword } = this.props;
        return (
            <div>
                <div className={'pt-2'} style={{paddingBottom: 35}}>
                    <KirokuInput
                        style={{ height : 42, fontSize: 16}}
                        value={keyword}
                        onChange={(e) => {this.props.onChangeKeyword(e.target.value)}}
                        placeholder={t('RecordsBeta.keyword')}
                    />
                </div>
                <div>
                    {surveys.length ?
                        <div className='background-color' style={{ overflow: 'scroll',
                            height: 'auto',
                            minHeight: 'calc(100vh - 400px',
                            maxHeight: 'calc(100vh - 400px'
                        }}>
                            <div className={'checkbox-bigger p-3 border-bottom'}>
                                <KirokuCheckBox
                                    checked={_.findIndex(surveys, (ctm) => (!ctm.selected)) === -1}
                                    content={<div>&nbsp;</div>} id={'customer-id'}
                                    onChange={() => this.props.handleSelectAllSurveys()}
                                />
                            </div>
                            {surveys.map((survey, i) => {
                                return (
                                    <div className={'border-bottom'} key={i}>
                                        <div className={'checkbox-bigger p-3'}>
                                            <KirokuCheckBox content={<span
                                                className={'font-text-in-checkbox'}
                                                style={{wordBreak: 'break-word'}}
                                                >
                                                    {survey.title}
                                                </span>}
                                                            id={`${survey.id}-${i}`}
                                                            checked={survey.selected}
                                                            onChange={() => this.props.handleSelected(i)}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div> : <div className='background-color d-flex' style={{ fontSize: '16px', height: 'auto', minHeight: 'calc(100vh - 400px'  }}>
                            <div style={{ margin: 'auto'}}>
                                {t('RecordsBeta.noSurveys')}
                            </div>
                        </div>

                    }
                </div>
            </div>
        );
    }
}

export default TabOptionsSurveys;
