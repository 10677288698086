import React  from 'react';
import moment from "moment";

class FormatTimestamp extends React.Component {
    render() {
        const { timestampUnix } = this.props;
        return (
            <>{moment.unix(timestampUnix).format('llll')}</>
        )
    }
}
export default FormatTimestamp;
