import React                        from 'react';
import {synchronizeLine, resetLine} from "../../actions/Line";
import {withNamespaces}             from "react-i18next";
import {connect}                    from "react-redux";
import {Redirect}                   from "react-router-dom";

@withNamespaces()
@connect(state => {
    return {
        profile: state.KirokuAIUser.profile,
        error: state.KirokuAILine.error,
        hasError: state.KirokuAILine.hasError
    }
}, dispatch => {
    return {
        synchronizeLine: (code) => dispatch(synchronizeLine(code)),
        resetState: () => dispatch(resetLine())
    };
})
class Callback extends React.Component {

    componentWillMount() {
        let code = this.props.location.search;
        this.props.synchronizeLine(code);
    }

    separateTypeError = (error) => {
        if (error.code === 'AUTH_LINE_ERROR') {
            return this.props.t('Callback.line_account_synced');
        } else if (error.message) {
            return this.props.t('Callback.line_account_existed');
        } else if (error.error.error) {
            return this.props.t('Callback.line_sync_error');
        }
    };

    componentDidMount() {
        this.props.resetState();
    }

    render() {
        const {error, hasError} = this.props;
        return (
            <div style={{position: 'relative', minHeight: '100vh', minWidth: '100vh'}}>
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    margin: 'auto',
                }}>
                    {hasError ? (
                        <div className="text-center" style={{marginTop: '20%'}}>
                            {this.separateTypeError(error)}
                        </div>
                    ) : (<Redirect to='/'/>)}
                </div>
            </div>
        );
    }
}

export default Callback;