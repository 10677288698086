import React, {Component}   from 'react';
import QuestionBack         from "./QuestionBack";
import ComponentAddQuestion from "./ComponentAddQuestion";
import {connect}            from "react-redux";
import {
    backQuestion,
    backSurvey,
    changeTypeQuestion,
    createQuestion,
    editQuestion
}                           from "../../../../actions/SurveyMobile";

@connect(state => {
    return {
        type: state.KirokuSurveyTemplateMobile.typeQuestion,
        questions: state.KirokuSurveyTemplateMobile.questions,
        questionBack: state.KirokuSurveyTemplateMobile.questionBack,
        backToSurvey: state.KirokuSurveyTemplateMobile.backToSurvey
    }
}, dispatch => {
    return {
        changeTypeQuestion: (type) => {
            dispatch(changeTypeQuestion(type))
        },

        createQuestion: (question) => {
            dispatch(createQuestion(question))
        },

        backSurvey: () => {
            dispatch(backSurvey())
        },

        backQuestion: () => {
            dispatch(backQuestion())
        },

        onChangeQuestion: (question) => {
            dispatch(editQuestion(question))
        }
    }
})
class Question extends Component {

    handleBackQuestion = () => {
        this.props.backQuestion();
    };

    backToEndQuestion = () => {
        this.props.backQuestion();
    };

    render() {
        const {questionBack} = this.props;
        return (
            <div>
                {questionBack ? (<QuestionBack
                                               question={questionBack}
                                               backQuestion={this.handleBackQuestion.bind(this)}
                />) : (<ComponentAddQuestion type = {this.props.type}
                                             backQuestion={this.backToEndQuestion.bind(this)}/>)}
            </div>
        );
    }
}

export default Question;
