import React, { Component } from "react";

export default class KirokuModalHeader extends Component {
    render() {
        return (
            <div className={'modal-header bg-' + this.props.color}>
                <div className="modal-title">
                    {this.props.children}
                </div>
            </div>

        )
    }
}