import React, {Component}                                from 'react';
import {Link}                                            from 'react-router-dom';
import {connect}                                         from 'react-redux';
import ModalAssignUser                                   from '../ModalAssignUser';
import {scrollService, surveyBuilderCondition}           from '../../../services';
import {
    filterSurveyWithCondition,
    handleSearchSurvey,
    loadSurveyListPage,
    loadSurveyModal,
}                                                        from '../../../actions/Survey';
import {resetMobile}                                     from '../../../actions/SurveyMobile';
import {withNamespaces}                                  from 'react-i18next'
import {
    KirokuButton,
    KirokuCard,
    KirokuCardAction,
    KirokuCardList,
    // KirokuCheckDraft,
    KirokuInputSearcher,
    KirokuLoading,
    KirokuPopoverItem,
    SearchNotFound,
    KirokuLabel
}                                                        from '../../../ui';
import ModalFilterSurvey                                 from './ModalFilterSurvey';
import {currentPage}                                     from '../../../actions/CurrentPage';
import KirokuPagination                                  from "../../../ui/KirokuPagination";
import moment                                            from 'moment';
import {saveQueryConditionSurvey, removedQueryCondition} from "../../../actions/SurveyTemplate";
import withPermission                                    from "../../HOC/WithPermissions";
import LinkTo                                            from "../../HOC/LinkComponent";
import KirokuTitlepage                                   from "../../../ui/KirokuTitlepage";
import config                                            from '../../../config';

const ButtonPermission = withPermission(KirokuButton);
const LinkPermission   = withPermission(LinkTo);
const BulkAction       = withPermission(ModalAssignUser);

class Action extends Component {

    constructor(props) {
        super(props);

        this.handleClick        = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            popupVisible: false
        };
    }

    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible
        }));
    }

    handleOutsideClick(e) {
        if (!this.node) {
            return;
        }
        this.handleClick();
    }

    render() {
        return (
            <div className="action-customer-surveys" ref={node => {
                this.node = node
            }}>
                <KirokuCardAction surveyId={this.props.surveyId}>
                    <div className="action-icon">
                        <i style={{color: '#1991eb'}} className="fas fa-ellipsis-h"
                           onClick={this.handleClick.bind(this)}/>
                    </div>
                    {this.state.popupVisible && (
                        <div onClick={this.handleClick.bind(this)}>
                            {this.props.children}
                        </div>
                    )}
                </KirokuCardAction>
            </div>
        )
    }
}

@connect(state => {
    return {
        surveys: state.KirokuAISurvey.surveys,
        isFinish: state.KirokuAISurvey.isFinish,
        totalPage: state.KirokuAISurvey.totalPage,
        currentPage: state.KirokuAISurvey.currentPage,
        surveyForModal: state.KirokuAISurvey.surveyForModal,
        profile: state.KirokuAIUser.profile,
        loading: state.Loading,
        conditions: state.KirokuAIBehavior.conditionSurveys.conditions,
        params: state.KirokuAIBehavior.conditionSurveys.params,
        pagination: state.KirokuAIBehavior.conditionSurveys.pagination
    }
}, (dispatch) => {
    return {
        pageNow: (name) => {
            dispatch(currentPage(name))
        },
        loadSurveyModal: () => {
            dispatch(loadSurveyModal())
        },
        loadSurveys: () => {
            dispatch(loadSurveyListPage())
        },
        handleSearchSurvey: (keyword) => {
            dispatch(handleSearchSurvey(keyword))
        },
        filterSurveyWithCondition: (params) => {
            dispatch(filterSurveyWithCondition(params))
        },
        namePage: (name) => {
            dispatch(currentPage(name))
        },
        saveQueryCondition: (conditions, params, pagination) => {
            dispatch(saveQueryConditionSurvey(conditions, params, pagination))
        },

        removedQueryCondition: (removeParams) => {
            dispatch(removedQueryCondition(removeParams))
        },
        resetStateSurvey: () => {
            dispatch(resetMobile())
        }
    };
})
@withNamespaces()

class SurveysMobile extends Component {
    page = {
        title: <KirokuTitlepage title={this.props.t('common.Surveys')}/>,
        actionPage: null
    };

    state = {
        show: false,
        showModalDelete: false,
        showModal: true,
        showSurveyTemplate: false,
        page: 1,
        pageMin: 1,
        totalPage: null,
        conditions: {},
        currentPage: 1,
        namePage: this.page,
        disabledPrev: true,
        disabledNext: false
    };

    componentWillMount() {
        if (this.props.conditions.length) {
            this.props.pageNow(this.state.namePage);
            this.makeFilter(this.props.conditions);
        } else {
            this.props.pageNow(this.state.namePage);
            this.props.filterSurveyWithCondition({page: 1});
        }
    }

    makeConditions = (params) => {
        let conditions = Object.keys(params).map(key => {
            if (params[key]) {
                return {[key]: params[key], query: key, value: params[key], label: this.makeLabelCondition(key)}
            }
            return '';
        });

        return conditions.filter(condition => condition !== '');
    };

    makeLabelCondition = (nameCondition) => {
        switch (nameCondition) {
            case 'day':
            case 'week':
            case 'month':
            case 'startDate':
                let startDate = this.state.conditions.startDate ? `${moment.unix(this.state.conditions.startDate).format(config.formatDateTh)}` : '';
                return this.props.t(`Surveys.${nameCondition}`) + `${startDate}`;
            case 'endDate':
                let endDate = this.state.conditions.endDate ? `${moment.unix(this.state.conditions.endDate).format(config.formatDateTh)}` : '';
                return this.props.t(`Surveys.${nameCondition}`) + `${endDate}`;
            case 'creator':
                return this.state.conditions.nameStaff;
            default:
                return this.state.conditions.status ? this.props.t('Surveys.Draft') : '';

        }
    };
    makeFilter         = (conditions) => {
        let params = surveyBuilderCondition.makeParamsFromCondition(conditions);
        this.setState({
            conditions: {
                ...this.props.params,
            },
            title: params.title,
            page: params.page,
            ...this.props.pagination
        });
        this.props.filterSurveyWithCondition({...params});
    };

    onChangeShowDropDown() {
        this.setState({
            show: !this.state.show
        })
    }

    handleSearch(e) {
        this.setState({
            conditions: {
                ...this.state.conditions,
                title: e.target.value
            },
            title: e.target.value || '',
            page: 1
        }, () => {

        });
    }

    saveQueryCondition = () => {
        const {disabledPrev, disabledNext} = this.state;
        const params                       = surveyBuilderCondition.makeParams(this.state.conditions);
        const conditions                   = this.makeConditions({...params, page: this.state.page});
        this.props.saveQueryCondition(conditions, this.state.conditions, {disabledPrev, disabledNext});
    };


    filter() {
        let params = surveyBuilderCondition.makeParams(this.state.conditions);
        this.props.filterSurveyWithCondition({...params, title: this.state.title, page: this.state.page});
    }

    handleShowModal() {
        this.setState({
            showModal: !this.state.showModal,
            showSurveyTemplate: !this.state.showSurveyTemplate
        })
    }

    filterCondition(params) {
        this.setState({
            conditions: {
                ...params,
            },
            page: 1
        }, () => {
            params = surveyBuilderCondition.makeParams(this.state.conditions);
            this.props.filterSurveyWithCondition({...params, title: this.state.title, page: this.state.page});
            this.saveQueryCondition();
        });

    }

    closeModal() {
        this.setState({
            showModal: !this.state.showModal,
            showSurveyTemplate: !this.state.showSurveyTemplate
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.params !== this.props.params) {
            this.setState({
                conditions: {
                    ...nextProps.params
                },
                title: nextProps.params.title
            })
        }
    }

    handlerSubmitSearch() {
        this.setState({
            page : 1
        },() => {
            this.filter();
            this.saveQueryCondition();
        });
    }

    increasePage() {
        let nextPage = this.props.currentPage === this.props.totalPage ? this.props.totalPage : this.props.currentPage + 1;
        if (nextPage === this.props.totalPage) {
            this.setState({
                disabledNext: !this.state.disabledNext,
            })
        }
        scrollService.top();
        this.setState({
            page: nextPage,
            disabledPrev: false,
        }, () => {
            this.filter();
            this.saveQueryCondition();
        });
    }

    decreasePage() {
        let previousPage = this.props.currentPage === this.state.pageMin ? 1 : this.props.currentPage - 1;
        if (previousPage === this.state.pageMin) {
            this.setState({
                disabledPrev: true
            })
        }
        scrollService.top();
        this.setState({
            page: previousPage,
            disabledNext: false,
        }, () => {
            this.filter();
            this.saveQueryCondition();
        });
    }

    handleRemoveCondition = (condition) => {
        const {conditions}                 = this.props;
        const {staffName}                  = this.state.conditions;
        const {disabledPrev, disabledNext} = this.state;
        let newConditions                  = conditions.filter(itemCondition => itemCondition.label !== condition.label);
        const newParams                    = surveyBuilderCondition.makeParamsFromCondition(newConditions);
        let conditionRemove                = conditions.filter(itemCondition => itemCondition.label === condition.label);
        let removeParams                   = surveyBuilderCondition.makePramsRemoved(conditionRemove);
        this.props.removedQueryCondition(removeParams);
        this.setState({
            conditions: {
                ...newParams,
                staffName
            },
            page: 1
        }, () => {
            this.props.saveQueryCondition(newConditions, this.state.conditions, {disabledPrev, disabledNext});
            this.filter();
        })
    };

    render() {
        let {show, showModalDelete, showModal, showSurveyTemplate, ...currentState} = this.state;
        const surveys                                                               = this.props.surveys;
        const {t, loading, conditions, currentPage, isFinish}                                 = this.props;
        return (
            <div className='survey-list default-background-color'>
                <div className='padding-responsive'>
                    <ModalFilterSurvey
                        show={this.state.showModal}
                        showModal={this.handleShowModal.bind(this)}
                        filterCondition={this.filterCondition.bind(this)}
                    />
                    <div hidden={this.state.showSurveyTemplate}>
                        <div className='d-flex justify-content-between '>
                            <div>
                                <KirokuButton color={'white'} onClick={this.closeModal.bind(this)}>
                                    <span className={'pr-2'}>{t('Surveys.FilterBy')}</span>
                                    <i className='fa fa-chevron-down text-secondary' style={{fontSize: '10px'}}/>
                                </KirokuButton>
                            </div>
                            <div>
                                <Link to={'/surveys/survey'}>
                                    <ButtonPermission color={'primary'}
                                                      resource-permission={"|surveyList|button-new|"}
                                    >
                                        {t('Surveys.NewSurvey')}
                                    </ButtonPermission>
                                </Link>
                            </div>
                        </div>
                        <div className='mt-10'>
                            {
                                conditions.map((condition, index) => {
                                    return condition.value && condition.query !== 'page' && condition.query !== 'title' ?
                                        <span key={index} className='mr-6'>
                                            <KirokuLabel
                                                style={{
                                                    border: 'solid 1px #1991eb'
                                                }}
                                                onClick={this.handleRemoveCondition.bind(this, condition)}
                                                color={'default'}>
                                                <span className='mt-1'>{condition.label}</span>
                                                <span className='align-icon'>&times;</span>
                                            </KirokuLabel>
                                    </span> : ''
                                })
                            }

                        </div>
                        <div className='mt-10'>
                            <KirokuInputSearcher
                                value={this.state.title}
                                onSubmit={this.handlerSubmitSearch.bind(this)}
                                onChange={this.handleSearch.bind(this)}
                                id={'inputSearcherSurveysMobile'}
                            />
                            {/*<KirokuInputSearch onBlur={this.handleSearch.bind(this)}*/}
                            {/*placeholder={t('common.Search')}/>*/}
                        </div>

                        <div className={'pt-3'}>
                            <div>
                                <div className={!surveys.length ? 'display-none-important' : ''}>
                                    <div className='pt-2 d-flex justify-content-between'>
                                        <BulkAction closeModal={this.onChangeShowDropDown.bind(this)}
                                                    totalPage={this.state.totalPage}
                                                    currentPage={this.state.page}
                                                    currentState={currentState}
                                                    show={this.state.show}
                                                    GPS={'top'}
                                                    resource-permission={"|surveyList|button-bulkAction|"}
                                        />
                                        <KirokuPagination
                                            className={'pb-2'}
                                            disabledPrev={this.state.disabledPrev || this.state.pageMin === this.props.page}
                                            disabledNext={this.state.disabledNext || this.state.pageMin === this.props.totalPage}
                                            increasePage={this.increasePage.bind(this)}
                                            decreasePage={this.decreasePage.bind(this)}
                                            currentPage={this.props.currentPage}
                                            totalPage={this.props.totalPage}
                                        />
                                    </div>
                                </div>
                                <KirokuCardList
                                    headerLeft={t('common.Survey')}
                                    headerRight={t('common.Action')}
                                >
                                    {!isFinish ? <KirokuLoading/> :
                                        !loading ?
                                        <div>
                                            {surveys.length ?
                                                surveys.map(survey =>
                                                    <KirokuCard key={survey.id} action={
                                                        <Action surveyId={survey.id}>
                                                            <KirokuPopoverItem>
                                                                <div
                                                                    className='d-flex justify-content-between p-1 fix-icon-align'>
                                                                    <LinkPermission
                                                                        href={`/surveys/survey/${survey.id}`}
                                                                        icon={<i className="fas fa-pencil-alt"/>}
                                                                        text={t('common.Edit')}
                                                                        resource-permission={"|surveyList|button-edit|"}
                                                                    />
                                                                    <LinkPermission
                                                                        href={`/surveys/survey-duplicate/${survey.id}`}
                                                                        icon={<i className="fas fa-clone"/>}
                                                                        text={t('common.Duplicate')}
                                                                        resource-permission={"|surveyList|button-duplicate|"}
                                                                    />
                                                                    <Link to={{
                                                                        pathname: `/surveys/survey-preview`,
                                                                        state: {id: survey.id}
                                                                    }}>
                                                                        <i className="fas fa-binoculars"/><span
                                                                        className='ml-1'>{t('common.Preview')}</span>
                                                                    </Link>
                                                                </div>
                                                            </KirokuPopoverItem>
                                                        </Action>}>
                                                        <Link to={`/surveys/survey-detail/${survey.id}`}>
                                                            <span>{survey.title}</span>
                                                            <span className={'pl-2'}>
                                            {/*<KirokuCheckDraft draftable={survey}/>*/}
                                        </span>
                                                        </Link>
                                                        <p className='mr-2'> {survey.description}</p>
                                                    </KirokuCard>) :
                                                <div>
                                                    <SearchNotFound
                                                        message={t('NullPage.survey')}
                                                    />
                                                </div>
                                            }
                                        </div> : <KirokuLoading/>}
                                </KirokuCardList>
                            </div>
                            <div className={!surveys.length ? 'display-none-important' : ''}>
                                <div className='pt-10 d-flex justify-content-between'>
                                    <BulkAction closeModal={this.onChangeShowDropDown.bind(this)}
                                                totalPage={this.state.totalPage}
                                                currentPage={this.state.page}
                                                currentState={currentState}
                                                show={this.state.show}
                                                resource-permission={"|surveyList|button-edit|"}
                                    />
                                    <KirokuPagination
                                        disabledPrev={this.state.disabledPrev || this.state.pageMin === this.props.page}
                                        disabledNext={this.state.disabledNext || this.state.pageMin === this.props.totalPage}
                                        increasePage={this.increasePage.bind(this)}
                                        decreasePage={this.decreasePage.bind(this)}
                                        currentPage={currentPage}
                                        totalPage={this.props.totalPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SurveysMobile;
