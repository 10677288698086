import {
    CHECK_ALL_TEAM,
    GET_DETAIL_TEAM, GET_TEAMS_BETA,
    LOAD_TEAM,
    LOAD_TEAM_FOR_PAGE,
    RESET_TEAM,
    SEARCH_TEAM, SEARCH_TEAM_PAGE_USER,
} from '../actions/Team';

import _                      from 'lodash'

const defaultApplicationSate = {
    title: 'Kiroku',
    teams: [],
    profile: JSON.parse(localStorage.getItem('profile')),
    defaultTeams: [],
    disableButtonApply: false,
    team: {},
    loading: true,
    totalPage: null,
    currentPage: null,
    checked: false,
    isLoading: false,
    allTeam: [],
    teamsBeta: []
};

const KirokuAITeam = (state = defaultApplicationSate, action) => {

    switch (action.type) {
        case GET_TEAMS_BETA:
            return {
                ...state,
                teamsBeta: action.teams
            };
        case LOAD_TEAM:
            return {
                ...state,
                allTeam: action.allTeam,
                isLoading: true,
                defaultTeams: action.listTeam,
                listTeam : action.listTeam
            };
        case LOAD_TEAM_FOR_PAGE:
            return {
                ...state,
                teams: action.teams,
                totalPage: action.totalPage,
                currentPage: action.currentPage,
                loading: true,
            };
        case RESET_TEAM :
            return {
                ...state,
                teams: state.defaultTeams.map(team => ({...team}))
            };
        case 'SELECTED_TEAM' :
            let teamsRecord                    = state.teams;
            teamsRecord[action.index].selected = !teamsRecord[action.index].selected;
            return {
                ...state,
                teams: [...teamsRecord]
            };

        case CHECK_ALL_TEAM :
            let {listTeam, defaultTeams} = state;

            let newTeamRecord   = listTeam.map(team => ({
                ...team,
                isChecked: !state.checked,
            }));
            let teamChecked     = newTeamRecord.map(item => item.id || []);
            let newDefaultTeams = defaultTeams.map(team => {
                if (teamChecked.includes(team.id)) {
                    return {
                        ...team,
                        isChecked: !state.checked
                    }
                }
                return {
                    ...team
                }
            });
            return {
                ...state,
                listTeam: newTeamRecord,
                defaultTeams: newDefaultTeams,
                checked: !state.checked

            };

        case  SEARCH_TEAM_PAGE_USER:
            let teamRecords            = state.listTeam.map(team => ({...team}));
            let team                   = _.find(teamRecords, team => team.id === action.teamId);
            team.isChecked             = !team.isChecked;
            let searchTeams            = state.defaultTeams;
            let searchTeamRecord       = _.find(searchTeams, team => team.id === action.teamId);
            searchTeamRecord.isChecked = !searchTeamRecord.isChecked;
            let checkSingleSelect      = _.filter(teamRecords, (team) => !team.isChecked);
            return {
                ...state,
                listTeam: teamRecords,
                defaultTeams: searchTeams,
                checked: !checkSingleSelect.length
            };

        case 'DEFAULT_TEAM':
            return defaultApplicationSate;

        case SEARCH_TEAM :
            let keywords  = action.keywords;
            let teamslist = state.defaultTeams.filter(team =>
                team['name'].toLowerCase().search(keywords.toLowerCase()) !== -1
            );
            let checked   = _.filter(teamslist, (team) => !team.isChecked);
            return {
                ...state,
                listTeam: teamslist,
                checked: !checked.length
            };
        case GET_DETAIL_TEAM :
            return {
                ...state,
                loading: false,
                team: action.team
            };
        default:
            return state
    }
};

export default KirokuAITeam;
