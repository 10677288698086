import React, {Component} from 'react';
import {
    KirokuCardWeb,
    KirokuButton,
    KirokuSectionDropdown
}                         from "../../../ui/";
import {withNamespaces}   from "react-i18next";
import KirokuInput        from "../../../ui/KirokuInput";
import KirokuMultiSelect  from "../../../ui/KirokuMultiSelect";
import {connect}          from "react-redux";
import {NOTIFICATION}     from "../../../actions/Notification";
import {filePathService, uploadService, userFactory, userService} from "../../../services";
import KirokuDropdownClose                                        from "../../../ui/KirokuDropdownClose";
import KirokuLoading                                              from "../../../ui/KirokuLoading";

@withNamespaces()
@connect(() => {
    return {
        //profile: state.KirokuAIUser.profile
    }
}, (dispatch) => {
    return {
        notification: (t) => {
            dispatch({
                type: NOTIFICATION,
                typeNotification: 'success',
                message: t('message.CreateSuccess')
            })
        },
        trackingCreateStaff : (staff) => {
            dispatch({
                type: "STAFF_CREATED",
                staff
            })
        },
        trackingDuplicateStaff : (staff) => {
            dispatch({
                type: "STAFF_DUPLICATED",
                staff
            })
        },
    }
})
class StaffCreatorWeb extends Component {
    state               = {
        showConfirmPass: false,
        validateUsername: false,
        showNewPass: false,
        errorUser: {
            name: false,
            password: false,
            confirmPassword: false,
            username: false,
            title: false
        },
        errorStaffNumber: false,
        user: {
            name: this.props.user ? this.props.user.name : '',
            password: '',
            working_form: this.props.user ? this.props.user.working_form : '',
            confirmPassword: '',
            username: '',
            title: this.props.user ? this.props.user.title : '',
            avatar: this.props.user ? this.props.user.avatar : '',
            qualification: this.props.user ? this.props.user.qualification: [],
            role: this.props.user ? this.props.user.role : '',
            staff_number : '',
            tags: this.props.user ? this.props.user.tags : []
        },
        messageError: false,
        nameExisted: false,
        currentQualification: this.props.user ? this.getQualification(this.props.user.qualification): null,
        currentCustomerTags: [],
        currentTeamTags : []
    };


    loadDataDuplicateStaff(nextProps) {
            const {user} = nextProps;

            let teams     = user.teams.map(team => {
                return {
                    tagType: "team",
                    tagContent: {
                        ...team,
                    },
                    key: team.id,
                    label: team.name,
                    value: `${team.name}${team.id}`,
                    type: 'Teams'
                }
            });
            let customers = user.customers.length ? user.customers.map(customer => {
                return {
                    tagType: "customer",
                    tagContent: {
                        ...customer,
                    },
                    key: customer.id,
                    label: customer.name,
                    value: `${customer.name}${customer.id}`,
                    type: 'Customer'
                }
            }) : [];
            let optionTeam = nextProps.teams.map(team => {
                return {
                    tagType: "team",
                    tagContent: {
                        ...team,
                    },
                    key: team.id,
                    value: `${team.name}${team.id}`,
                    label: team.name,
                    type: 'Teams'
                }
            });

            let optionCustomers = nextProps.customers.map((customer) => {
                return {
                    tagType: "customer",
                    tagContent: {
                        ...customer,
                    },
                    key: customer.id,
                    value: `${customer.name}${customer.id}`,
                    label: customer.name,
                    type: 'Customers'
                }
            });

            // let currentTags = customers.concat(teams);
            this.setState({
                currentCustomerTags: customers,
                currentTeamTags : teams,
                groupedTeamOptions: [
                    {
                        label: 'Teams',
                        options: optionTeam,
                    }
                ],
                groupedCustomerOptions : [
                    {
                        label: 'Customers',
                        options: optionCustomers,
                    }
                ]
            });
    }

    componentDidMount() {
        if(this.props.isDuplicate) {
            this.loadDataDuplicateStaff(this.props)
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.user && this.props.isDuplicate) {
            this.loadDataDuplicateStaff(nextProps)
        }
    }


    getQualification(qualification) {
        if(qualification.length) {
            return qualification.map(trans => {
                return {
                    key: this.props.t(`common.dropdown.default.${trans}`),
                    label: this.props.t(`common.dropdown.${trans}`),
                    value: this.props.t(`common.dropdown.${trans}`)
                }
            });
        } else {
           return  -1
        }
    }

    KindOfQualification = [
        {
            key: this.props.t('common.dropdown.default.Social_worker'),
            label: this.props.t('common.dropdown.Social_worker'),
            value: this.props.t('common.dropdown.Social_worker'),
        },
        {
            key: this.props.t('common.dropdown.default.Psychiatric_Sosial_Worker'),
            value: this.props.t('common.dropdown.Psychiatric_Sosial_Worker'),
            label: this.props.t('common.dropdown.Psychiatric_Sosial_Worker'),
        },
        {
            key: this.props.t('common.dropdown.default.Care_Worker'),
            label: this.props.t('common.dropdown.Care_Worker'),
            value: this.props.t('common.dropdown.Care_Worker'),
        },
        {
            key: this.props.t('common.dropdown.default.Nurse'),
            label: this.props.t('common.dropdown.Nurse'),
            value: this.props.t('common.dropdown.Nurse'),
        },
        {
            key: this.props.t('common.dropdown.default.Childminder'),
            label: this.props.t('common.dropdown.Childminder'),
            value: this.props.t('common.dropdown.Childminder'),
        },
        {
            key: this.props.t('common.dropdown.default.Occupational_therapist'),
            label: this.props.t('common.dropdown.Occupational_therapist'),
            value: this.props.t('common.dropdown.Occupational_therapist'),
        },
        {
            key: this.props.t('common.dropdown.default.Psychotherapist'),
            label: this.props.t('common.dropdown.Psychotherapist'),
            value: this.props.t('common.dropdown.Psychotherapist'),
        },
        {
            key: this.props.t('common.dropdown.default.Doctor'),
            label: this.props.t('common.dropdown.Doctor'),
            value: this.props.t('common.dropdown.Doctor'),
        },

    ];
    workingForm         = [
        {
            value: 'Full_time',
            component: (<div>{this.props.t('common.Full_time')}</div>),
            label: this.props.t('common.Full_time')
        },
        {
            value: 'Part_time',
            component: (<div>{this.props.t('common.Part_time')}</div>),
            label: this.props.t('common.Part_time')
        },
    ];

    goBack() {
        this.props.history.goBack()
    }

    handleChangeInput(e) {

        if(e.target.name === 'username') {
            let val = e.target.value
                .replace(/[^A-z\u00C0-\u00ff\s'.,-/@+#?!|$%^&*{}><=\-"_`~()0-9]/g, '')
                .replace(/\s+/g, '')
            ;
            return this.handleChangeProfile(val, e.target.name);
        }

        return this.handleChangeProfile(e.target.value, e.target.name);
    }

    handleShowConfirmPass() {
        this.setState({
            showConfirmPass: !this.state.showConfirmPass
        })
    }

    handleShowNewPass() {
        this.setState({
            showNewPass: !this.state.showNewPass
        })
    }

    handleChangeProfile(value, name) {
        if (name === 'tags'){
            this.setState({
                user: {
                    ...this.state.user,
                    [name]: value,
                }
            });
        } else {
            this.setState({
                user: {
                    ...this.state.user,
                    [name]: value,
                },
                errorUser: {
                    ...this.state.errorUser,
                    [name]: !value.length,
                },
                validateUsername: false,
                nameExisted: !value.length ? false : this.state.nameExisted
            })
        }
    }

    selectWorkingForm(workingForm) {
        this.handleChangeProfile(workingForm, 'working_form')
    }

    currentQualification(qualification) {
        let currentQualification = qualification.map(item => item.key);
        this.handleChangeProfile(currentQualification, 'qualification')
    }

    handleChangeQualification = (options) => {
        this.setState({currentQualification: options ? options : this.state.defaultOption}, () => {
            this.currentQualification(this.state.currentQualification)
        });
    };

    validation() {
        let {name, title, username, password, confirmPassword} = this.state.user;
        this.setState({
            errorUser: {
                name: !name.length,
                password: !(password.length > 5),
                confirmPassword: confirmPassword !== password,
                username: !username.length,
                title: !title.length,
                nameExisted: this.state.nameExisted,
            }
        }, () => this.onSubmit());
    }

    onSubmit() {
        if (Object.values(this.state.errorUser).every(item => !item)) {
            if(this.props.isDuplicate) {
                let newUser = userFactory.makeUser(this.state.user, true);
                delete newUser.id;
                userService.duplicateCreateUser(newUser).then(res => {
                    this.props.trackingDuplicateStaff(res.data);
                    this.props.notification(this.props.t);
                    this.props.history.push(`/staffs/staff/${res.data.id}`)
                })
            } else {
                userService.createUser(this.state.user).then((res) => {
                    this.props.trackingCreateStaff(res.data);
                    this.props.notification(this.props.t);
                    this.props.history.push(`/staffs/staff/${res.data.id}`)
                })
            }
        }
    }

    handleCheckExisted(e) {
        let keyword = e.target.value;
        if (keyword.length === 0) {
            return this.setState({
                nameExisted: false,
                errorUser: {
                    ...this.state.errorUser,
                    username: !keyword.length,
                    validateUsername: false
                }
            })
        } else if (keyword.length > 5 && keyword.length < 65) {
            return userService.checkDuplicate(keyword).then((message) => {
                this.setState({
                    nameExisted: message
                })
            });
        } else {
            return this.setState({
                nameExisted: false,
                validateUsername: !this.state.validateUsername
            })
        }

    }

    handleCheckPassword(e) {
        let keyword = e.target.value;
        this.setState({
            errorUser: {...this.state.errorUser, password: !(keyword.length > 5)}
        })
    }

    checkRePassword(e) {
        let keyword = e.target.value;
        this.setState({
            errorUser: {...this.state.errorUser, confirmPassword: this.state.user.password !== keyword}
        })
    }

    validateName(e) {
        this.setState({
            errorUser: {...this.state.errorUser, name: !e.target.value.length}
        })
    }

    validateTitle(e) {
        this.setState({
            errorUser: {...this.state.errorUser, title: !e.target.value.length}
        })
    }

    isDisabled() {
        const name = {
            ...this.state.errorUser,
            nameExisted: this.state.nameExisted
        };
        return !Object.values(name).every(item => !item)
    }

    conditionUpload(file) {
        return (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') && file.size < 10485760;
    }

    handleChangeImage() {
        this.setState({
            loadingImg: !this.state.loadingImg && this.inputElement.files[0]
        });
        if (this.inputElement.files[0] && this.conditionUpload(this.inputElement.files[0])) {
            let image = new FormData();
            image.append('file', this.inputElement.files[0]);
            image.append('name', this.inputElement.files[0].name);
            image.append('description', 'some value user types');
            uploadService.uploadImage(image, 'avatar').then((res) => {
                this.handleChangeProfile(res.url, 'avatar');
                this.setState({
                    loadingImg: !this.state.loadingImg
                })
            });
        }
    };

    handleChangeStaffNumber = (e) => {
        let regex  = e.target.value.replace(/[^0-9]/g, '');
        const user = this.state.user;
        if (regex.length <= 5) {
            user[e.target.name] = regex;
            this.setState({
                user: {
                    ...this.state.user,
                    staff_number: regex
                }
            });
        }
    };

    checkValidationStaffNumber = (e) => {
        const error = this.checkErrorStaffNumber(e.currentTarget.value);
        this.setState({
           errorStaffNumber: error
        });
    };

    checkErrorStaffNumber = (number) => {
        if(number.length < 5 && number.length > 0) {
            this.setState({errorStaffNumber : true});
            return 'ErrorStaffNumber';
        }
        return '';
    };


    handleChangeCustomerTags = (options) => {
        this.setState({
            currentCustomerTags: options ? options : this.state.defaultOption,
            isEdit: true,
        }, () => {
            this.currentTag(this.state.currentCustomerTags.concat(this.state.currentTeamTags))
        });
    };

    handleChangeTeamTags = (options) => {
        this.setState({
            currentTeamTags : options ? options : this.state.defaultOptions,
            isEdit : true
        }, () => {
            this.currentTag(this.state.currentTeamTags.concat(this.state.currentCustomerTags))
        })
    };
    currentTag(tags) {
        this.handleChangeProfile(tags, 'tags');
    }


    render() {
        let role   = JSON.parse(localStorage.getItem('profile'));
        const {t, isDuplicate}  = this.props;
        let {
            user,
            // errorStaffNumber
        } = this.state;
        return (
            <div className='padding-responsive'>
                <div className='pb-3'>
                    <KirokuButton color='white' onClick={this.goBack.bind(this)}>
                        {t('common.Back')}
                    </KirokuButton>
                </div>
                <KirokuCardWeb>
                    <div className='p-3 row'>
                        <div className="col-md-8">
                            <div className='row'>
                                <div className="col-md-6">
                                    <div className="pb-3">
                                        <label className="mt-1">{t('common.Name')}</label>
                                        <KirokuInput
                                            tabIndex='1'
                                            name={'name'}
                                            onBlur={this.validateName.bind(this)}
                                            placeholder={t('common.Name')}
                                            onChange={this.handleChangeInput.bind(this)}
                                            value={user.name}
                                        />
                                        <p hidden={!this.state.errorUser.name}
                                           style={{
                                               color: 'red',
                                               fontSize: '13px'
                                           }}>
                                            {t('staff.nameStaffMustNotNull')}
                                        </p>
                                    </div>
                                    <div className="pb-3">
                                        <label className="mt-1">{t('Login.password')}</label>
                                        <div onBlur={this.handleCheckPassword.bind(this)}
                                             style={{position: 'relative'}}>
                                            <KirokuInput
                                                tabIndex='3'
                                                name='password'
                                                type={!this.state.showNewPass ? 'password' : 'text'}
                                                onChange={this.handleChangeInput.bind(this)}/>
                                            <span className='change-status-password-user'>
                                    <i onClick={this.handleShowNewPass.bind(this)}
                                       className={this.state.showNewPass ? 'fas fa-eye-slash text-secondary' : 'fas fa-eye text-secondary'}/>
                                </span>
                                        </div>
                                        <p hidden={!this.state.errorUser.password}
                                           style={{
                                               color: 'red',
                                               fontSize: '13px'
                                           }}>
                                            {t('errors.userDetail.mustNotNull')}
                                        </p>
                                    </div>
                                    {/* note move file title after uncomment staff number*/}
                                    <div className="pb-2">
                                        <label className="mt-1">{t('staff.title')}</label>
                                        <KirokuInput
                                            tabIndex='5'
                                            onBlur={this.validateTitle.bind(this)}
                                            name='title'
                                            placeholder={t('staff.title')}
                                            value={user.title}
                                            onChange={this.handleChangeInput.bind(this)}
                                        />
                                        <p hidden={!this.state.errorUser.title}
                                           style={{
                                               color: 'red',
                                               fontSize: '13px'
                                           }}>
                                            {t('staff.titleNotNull')}
                                        </p>
                                    </div>



                                    {/*<div className="pb-3">*/}
                                    {/*    <label className="mt-1">{t('common.StaffNumber')}</label>*/}
                                    {/*    <KirokuInput*/}
                                    {/*        tabIndex='5'*/}
                                    {/*        name='staff_number'*/}
                                    {/*        placeholder={t('common.StaffNumber')}*/}
                                    {/*        onChange={this.handleChangeStaffNumber.bind(this)}*/}
                                    {/*        onBlur={this.checkValidationStaffNumber.bind(this)}*/}
                                    {/*        error={errorStaffNumber ? 'form-error' : ''}*/}
                                    {/*        value={user.staff_number || ''}/>*/}
                                    {/*        <p hidden={!errorStaffNumber} style={{*/}
                                    {/*            color: 'red',*/}
                                    {/*            fontSize: 12*/}
                                    {/*        }}>*/}
                                    {/*            {t(`CustomerEditor.validate.${errorStaffNumber}`)}*/}
                                    {/*        </p>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="col-md-6">
                                    <div className="pb-3">
                                        <label className="mt-1">{t('Login.username')}</label>
                                        <KirokuInput
                                            tabIndex='2'
                                            name='username'
                                            placeholder={t('Login.username')}
                                            value={user.username}
                                            onBlur={this.handleCheckExisted.bind(this)}
                                            onChange={this.handleChangeInput.bind(this)}
                                        />
                                        <p hidden={!this.state.errorUser.username}
                                           style={{
                                               color: 'red',
                                               fontSize: '13px'
                                           }}>
                                            {t('staff.userNameNotNull')}
                                        </p>
                                        <p hidden={!this.state.nameExisted}
                                           style={{
                                               color: 'red',
                                               fontSize: '13px'
                                           }}>
                                            {t('staff.nameExisted')}
                                        </p>
                                        <p hidden={!this.state.validateUsername}
                                           style={{
                                               color: 'red',
                                               fontSize: '13px'
                                           }}>
                                            {t('staff.validateLength')}
                                        </p>
                                    </div>
                                    <div className="pb-3">
                                        <label className="mt-1">{t('common.ConfirmNewPassword')}</label>
                                        <div style={{position: 'relative'}}>
                                            <KirokuInput
                                                tabIndex='4'
                                                name='confirmPassword'
                                                onBlur={this.checkRePassword.bind(this)}
                                                type={!this.state.showConfirmPass ? 'password' : 'text'}
                                                onChange={this.handleChangeInput.bind(this)}/>
                                            <span className='change-status-password-user'>
                                                    <i onClick={this.handleShowConfirmPass.bind(this)}
                                                       className={this.state.showConfirmPass ? 'fas fa-eye-slash text-secondary' : 'fas fa-eye text-secondary'}/>
                                            </span>
                                        </div>
                                        <p hidden={!this.state.errorUser.confirmPassword}
                                           style={{
                                               color: 'red',
                                               fontSize: '13px'
                                           }}>
                                            {t('errors.userDetail.notMatch')}
                                        </p>
                                    </div>
                                    <div className="pb-3">
                                        <label className="mt-1">{t('common.WorkingForm')}</label>
                                        <KirokuDropdownClose
                                            title={user.working_form ? t(`common.${user.working_form}`) : t(`common.WorkingForm`)}
                                            items={this.workingForm}
                                            onSelect={event => {
                                                this.selectWorkingForm(event.kirokuSelected.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="pb-3">
                                {/* move filed title at*/}
                                <div className="pb-2">
                                    <label className="mt-1">{t('common.Qualification')}</label>
                                    <KirokuMultiSelect
                                        onChange={this.handleChangeQualification.bind(this)}
                                        value={this.state.currentQualification}
                                        options={this.KindOfQualification}
                                    />
                                </div>
                                <div  hidden={user.id === role.id || !isDuplicate}>
                                    <span className='customer-info f-bold'>{t('common.Customer')}:</span>
                                    <br/>
                                    <KirokuSectionDropdown
                                        onChange={this.handleChangeCustomerTags.bind(this)}
                                        value={this.state.currentCustomerTags}
                                        data={this.state.groupedCustomerOptions}
                                        options={this.state.groupedCustomerOptions}
                                    />
                                </div>
                                <div className='pt-1' hidden={user.id === role.id || !isDuplicate}>
                                    <span className='customer-info f-bold'>{t('common.Team')}:</span>
                                    <br/>
                                    <KirokuSectionDropdown
                                        onChange={this.handleChangeTeamTags.bind(this)}
                                        value={this.state.currentTeamTags}
                                        data={this.state.groupedTeamOptions}
                                        options={this.state.groupedTeamOptions}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='fix-center col-md-4 as'>
                            {!this.state.loadingImg ?
                                <div
                                    style={{
                                        width: 100,
                                        height: 100,
                                        borderRadius: '100%',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center center',
                                        backgroundImage: `url(${user.avatar ? user.avatar : `${filePathService.resolveFilePath('profile-ic.svg')}`})`,
                                    }}
                                />
                                : <KirokuLoading/>}
                            <div className='mt-24'>
                                <div>
                                    <KirokuButton color={'success'}
                                                  onClick={() => this.inputElement.click()}
                                    >
                                        {t('CustomerEditor.ChangeAvatar')}
                                    </KirokuButton>
                                    <input type='file' ref={input => this.inputElement = input} hidden
                                           accept="image/jpeg, image/png"
                                           onChange={this.handleChangeImage.bind(this)}
                                    />
                                </div>
                            </div>
                            <div className='content-avatar text-center'>
                                <span>{t('Customer.SupportedFile')} </span>
                                <br/>
                                <span>{t('Customer.Maximum')}</span>
                            </div>
                        </div>
                        <div className='pl-3 pt-3 d-flex'>
                            <KirokuButton color='white' onClick={this.goBack.bind(this)}>
                                {t('common.Cancel')}
                            </KirokuButton>
                            <div className='pr-1 pl-1'/>
                            <KirokuButton color='primary'
                                          disabled={this.isDisabled()}
                                          onClick={this.validation.bind(this)}>
                                {t('common.Save')}
                            </KirokuButton>
                        </div>
                    </div>
                </KirokuCardWeb>
            </div>
        )
    }
}

export default StaffCreatorWeb;
