import React     from 'react';
import PropTypes from 'prop-types';

export default function ButtonPermissionHOC(WrappedComponent) {

    return class extends React.Component {
        constructor(props) {
            super(props);
            try {
                let profile = JSON.parse(localStorage.getItem('profile'));
                this.role   = profile.role;
            } catch (e) {
                console.error(e);
            }
        }

        get permission() {
            return this.context.permissions[this.role][this.props['resource-permission']];
        }

        static propTypes = {
            "resource-permission": PropTypes.string.isRequired
        };

        static contextTypes = {
            permissions: PropTypes.object.isRequired
        };

        get hasPermission() {
            //let disabled = false;
            //let hidden = false;
            if (this.permission === 'hidden') {
                return {hidden: true};
            }
            //disabled = /[^E|W]/.test(this.permission);

            //return {disabled};
            return '';
        }

        render() {
            return <WrappedComponent {...this.props} {...this.hasPermission}/>;
        }
    }
};

