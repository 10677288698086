export const HIDDEN_LAYOUT = "HIDDEN_LAYOUT";
export const RELOAD_AVATAR = "RELOAD_AVATAR";

export function hiddenLayout () {
	return {
		type: HIDDEN_LAYOUT,
	};
}

export function reloadAvatar () {
	return {
		type: RELOAD_AVATAR,
	}
}
