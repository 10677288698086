import config  from '../config';

class RecordBeta {

    constructor(axios, axiosConfigNotLoading) {
        this.axios = axios;
        this.axiosNotLoading = axiosConfigNotLoading;
    }

    getCustomersByUserId(id) {
        return this.axios.get(`/customers-by-user/${id}`).then(res => res.data);
    }

    getCustomersOfTeamByUserId(id) {
        return this.axios.get(`/customers-of-team-by-user/${id}`).then(res => res.data);
    }
    getRecordsByIds(body) {
        const profile = JSON.parse(localStorage.getItem('profile'));
        if (config.orgUsingElasticsearch.includes(profile.organization_id)) {
            return this.axios.post(`/batch-action-records/elasticsearch`, body).then(res => res.data);
        }
        else {
            return this.axios.post(`/batch-action-records/search`, body).then(res => res.data);
        }
    }

    getRecordsBeta(params, body) {
        const profile = JSON.parse(localStorage.getItem('profile'));
        if (config.orgUsingElasticsearch.includes(profile.organization_id)) {
            return this.axios.post(`elasticsearch-condition`, body, {
                params: {
                    ...params,
                    pageSize: 10
                },
            }).then(res => res.data);
        } else {
            return this.axios.post(`search-condition`, body, {
                params: {
                    ...params,
                    pageSize: 10
                },
            }).then(res => res.data);
        }
    }

    getRecordDetailBeta(params) {
        return this.axios.get(`record-detail`, {
            params: {
                ...params,
            },
        }).then(res => res.data);
    }

    getRecordsByComment(body) {
        return this.axiosNotLoading.post(`/comment-record`, body).then(res => res.data)
    }

    getHistoryRecord(id, params) {
        return this.axiosNotLoading.get(`/record-logs/${id}`, {
            params: {
                page : params

        }}).then(res => res.data)
    }

    getChangelogRecord(recordId, logId) {
        return this.axiosNotLoading.get(`record-changelog/${recordId}`, { params: { logId } }).then(res => res.data)
    }

}
export default RecordBeta;
