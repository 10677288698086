import React, {PureComponent} from 'react';

const width = window.innerWidth;
export default class CheckDivide extends PureComponent {
    render() {
        const isMobile = width <= 768;
        if (isMobile) {
            return (
                <div className={this.props.class}>
                    {this.props.mobile}
                </div>
            );
        } else {
            return (
                <div>
                    {this.props.web}
                </div>
            );
        }
    }
}
