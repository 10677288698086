import React, { Component }                            from 'react';
import CardRecordDetail                                from './mobile/CardRecordDetail';
import RecordsTab                                      from './RecordsTab';
import { loadDetail }                                  from '../../../../actions/Record';
import { connect }                                     from 'react-redux';
import { withNamespaces }                              from 'react-i18next';
import { KirokuButton, KirokuLoading, SearchNotFound } from '../../../../ui';
import { scrollService }                               from '../../../../services';
import CardRecordDetailsWeb                            from './web/CardRecordDetailsWeb';
import qs                                              from 'query-string';
import config                                          from '../../../../config';
import CommentTab                                      from './CommentTab';
import HistoryTab                                      from './HistoryTab';
@withNamespaces()
@connect(state => {
    return {
        record    : state.KirokuAIRecord.record,
        comments : state.KirokuAIRecord.comments,
        recordIds : state.KirokuAIRecord.recordIds,
        indexOfRecordOnRecordIds : state.KirokuAIRecord.indexOfRecordOnRecordIds,
        loading   : state.Loading,
        tabType   :  state.RecordsBeta.tabType
    };
}, dispatch => {
    return {
        loadDetail: (recordId) => {
            dispatch(loadDetail(recordId));
        },
        loadRecordDetailBeta: (recordId, recordIds) => {
            dispatch({
                type: 'LOAD_RECORD_DETAIL_BETA',
                recordId, recordIds
            });
        },
        changeTabTypeRecord: (tabType) => {
            dispatch({
                type: "CHANGE_TAB_CARD_RECORD_DETAIL",
                tabType
            })
        },
        loadRecordLogs: (id, params) => {
            dispatch({
                type : 'LOAD_HISTORY_OF_RECORD',
                id,
                params
            })
        },
        setInitRecordTab: () => {
            dispatch({
                type : 'SET_INIT_RECORD_DETAIL'
            })
        },
        setEmptyRecordIds: (recordIds) => {
            dispatch({
                type : 'SET_RECORD_IDS_OF_RECORD_DETAIL',
                recordIds
            })
        }

    };
})


class RecordsDetail extends Component {

    state = {
        width: window.innerWidth,
        hasPaginator: true,
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        this.props.setEmptyRecordIds([]);
    }

    componentWillMount() {
        scrollService.top();
        const { recordIds } = this.props;
        window.addEventListener('resize', this.handleWindowSizeChange);
        const locationSearch = qs.parse(this.props.location.search, config.formatParams);
        this.setState({
            hasPaginator: !recordIds.includes(locationSearch.id)
        });
        this.props.setInitRecordTab();
        this.props.loadRecordDetailBeta(locationSearch.id, recordIds);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.location.search !== this.props.location.search) {
        const locationSearch = qs.parse(this.props.location.search, config.formatParams);
            const { recordIds } = this.props;
            this.setState({
                hasPaginator: !recordIds.includes(locationSearch.id)
            });
            this.props.loadRecordDetailBeta(locationSearch.id, recordIds);
        }
    }

    handleSwapRecord(action) {
        const { indexOfRecordOnRecordIds, recordIds } = this.props;
        const newIndex             = action === 'next' ? indexOfRecordOnRecordIds + 1 : indexOfRecordOnRecordIds - 1;
        if (newIndex > recordIds.length) {
            throw new Error(`not record index ${newIndex}`);
        }
        const idRecord = recordIds[ newIndex ];
        this.props.history.push({
            ...this.props.location,
            search    : `id=${idRecord}`,
        });

    }

    handleWindowSizeChange = () => {
        this.setState({width: window.innerWidth});
    };

    pageGoBack() {
        window.history.previous = undefined;
        return this.props.history.push('records-beta');
    }

    render() {
        const { record, t, loading, recordIds, indexOfRecordOnRecordIds, history, tabType, comments } = this.props;
        const { width, hasPaginator }   = this.state;
        return (
            !loading ?
                record.create_at ?
                    <div className='default-background-color padding-responsive'>
                        <div className={'about-record-detail-beta'}
                             style={{backgroundColor: '#eef3f6', top: width > 768 ? 70: 0}}>
                            <div className={'pt-2'}/>
                            <div className='pt-1 pl-1 d-flex justify-content-between'>
                                <div className=' pt-2 border-back-btn font-back-label cursor-pointer' style={{ textAlign: 'center' }}
                                     onClick={() => {this.pageGoBack()}}>
                                    {t('RecordsBeta.return')}
                                </div>
                                <div className=' pr-3 ml-3' hidden={(!record.create_at) || !hasPaginator ||  indexOfRecordOnRecordIds === -1}>
                                    <KirokuButton className={'color-btn-paginator border-btn-paginator bg-white btn-back'}
                                                  disabled={!indexOfRecordOnRecordIds}
                                                  onClick={() => {
                                                      this.handleSwapRecord('back');
                                                  }}
                                    > {t('DeletedList.back')} </KirokuButton>
                                    <KirokuButton className={'color-btn-paginator border-btn-paginator bg-white btn-next'}
                                                  onClick={() => {
                                                      this.handleSwapRecord('next');
                                                  }}
                                                  disabled={indexOfRecordOnRecordIds === recordIds.length - 1}
                                    > {t('DeletedList.next')} </KirokuButton>
                                </div>
                            </div>
                            <div className="pt-3">
                                <div className={`card`}>
                                    <div className='pt-3'>
                                        {width > 768 ? <CardRecordDetailsWeb record={record}/> :  <CardRecordDetail record={record}/>}
                                    </div>
                                    <div className='pt-3 d-flex justify-content-around navbar-tab-records records-detail font-text-reset'
                                         style={ { borderBottom: 'solid 2px #dfe3e9', backgroundColor: '#ffffff', color: '#6E798D' } }>
                                        <div className={`nav-bar cursor-pointer bg-white ${tabType === 'RECORD_TAB' ? 'active' : ''}`}
                                             onClick={() => {
                                                this.props.changeTabTypeRecord("RECORD_TAB");
                                                scrollService.top();
                                        }} style={{ width: 100}}>
                                            { t('RecordsBeta.record') }
                                        </div>
                                        <div className={`nav-bar cursor-pointer bg-white ${tabType === 'COMMENT_TAB' ? 'active' : ''} `}
                                             onClick={() => {
                                                 this.props.changeTabTypeRecord("COMMENT_TAB");
                                                 scrollService.top();
                                             }} style={{ width: 100}}>
                                            { t('RecordsBeta.comment') }
                                            <span className='badge'>{comments.length}</span>
                                        </div>
                                        <div className={`nav-bar cursor-pointer bg-white ${tabType === 'HISTORY_TAB' ? 'active' : ''} `}
                                             onClick={() => {
                                                 this.props.changeTabTypeRecord("HISTORY_TAB");
                                                 scrollService.top();
                                             }} style={{ width: 100}}>
                                            { t('RecordsBeta.history') }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'pt-2'}/>
                        </div>
                        <div>
                            <RecordsTab record={record} history={history} show={tabType === 'RECORD_TAB'}/>
                            <CommentTab record={record} history={history} show={tabType === 'COMMENT_TAB'} isWeb={width > 768}/>
                            <HistoryTab record={record} history={history} show={tabType === 'HISTORY_TAB'} historyTab={tabType === 'HISTORY_TAB'} isMobile={ width <= 768}/>
                        </div>
                    </div> : <SearchNotFound message={t('RecordsBeta.recordNotFound')} description={'    '}/>
                : <KirokuLoading/>
        );
    }
}
export default RecordsDetail;
