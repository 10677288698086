import React, {Component} from 'react';
import {withNamespaces} from "react-i18next";

@withNamespaces()
class CardTotalWeb extends Component {
    render() {
        const {changeData, t} =this.props;
        return (
            <div className='padding-responsive'>
                <div className='d-flex justify-content-between'>
                    <div>
                        <div className='title-card-dashboard d-flex'>
                            <i className={this.props.icon}
                               style={{fontSize: '1.3rem', color: '#dfe3e9'}}/>
                               &nbsp;
                                <div style={{lineHeight: 1.2}}>
                                        {this.props.text}
                                </div>
                        </div>
                        <div className='number-card-dashboard'>{changeData.newValue}</div>
                    </div>
                </div>
                <div hidden={changeData.percent === null}>
                    {changeData.isDecrease ?
                        <span className={'text-danger'}>&#9660; {changeData.percent}%</span> :
                        <span className={'text-success'}>&#9650; {changeData.percent}%</span>}&nbsp;
                    <span className={'date-log'}>
                        &nbsp;&nbsp;{t('Dashboard.numberOfRecordPreviousPeriod')}
                        &nbsp;{changeData.orginalValue}
                    </span>
                </div>
            </div>
        )
    }
}

export default CardTotalWeb;
