import {CREATE_SURVEY_MOBILE, DETAIL_SURVEY_MOBILE, UPDATE_SURVEY_MOBILE} from "../actions/SurveyMobile";
import {surveyTemplateService, surveyFactory}                                            from '../services';
import {NOTIFICATION}                                                     from "../actions/Notification";



export const SurveyMobileMiddleware = (store) => next => action => {

    switch (action.type) {
        case CREATE_SURVEY_MOBILE :
            const surveyCreated = surveyFactory.makeCreate(store.getState().KirokuSurveyTemplateMobile);
            return surveyTemplateService.createSurvey(surveyCreated).then(res => {
                next({
                    ...action,
                    type: 'CREATE_SURVEY_MOBILE_DONE'
                });
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'success',
                    message : "Surveys.Created"
                })
            });
        case DETAIL_SURVEY_MOBILE :
            return surveyTemplateService.detail(action.id).then(survey => {
                let newQuestions = [...survey.questions].map(question => {
                    if ((question.type === 'Single' || question.type === 'MultiChoice') && typeof question.choices[0] === 'string') {
                        return {
                            ...question,
                            choices: question.choices.map(choice => {
                                return {answer: choice}
                            }),
                        }
                    }
                    return {...question}
                });
                next({
                    ...action,
                    survey: {
                        ...survey,
                        questions: newQuestions
                    }
                });
            });
        case UPDATE_SURVEY_MOBILE :
            const surveyEdited = surveyFactory.makeEdit(store.getState().KirokuSurveyTemplateMobile);
            return surveyTemplateService.edit(action.id, surveyEdited).then(res => {
                next(action);
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'success',
                    message: 'Surveys.UpdatedSuccessfully'
                })
            });
        default :
            return next(action)
    }
};
