import React, {Component} from 'react'
import {CheckDivide}      from "../../../ui";
import MyProfileWeb       from "./MyProfileWeb";
import MyProfileMobile    from "./MyProfileMobile";
import KirokuTitlepage    from "../../../ui/KirokuTitlepage";
import {scrollService}    from "../../../services";
import {withNamespaces}   from "react-i18next";
import {currentPage}      from "../../../actions/CurrentPage";
import {connect}          from "react-redux";

@withNamespaces()
@connect(() => {
    return {}
}, dispatch => {
    return {
        currentPageUsed: (name) => {
            dispatch(currentPage(name))
        }
    };
})

class MyProfile extends Component {

    page = {
        title: <KirokuTitlepage title={this.props.t('common.myProfile')}/>,
    };

    componentWillMount() {
        scrollService.top();
        this.props.currentPageUsed(this.page);
    }

    render() {
        let web    = <MyProfileWeb history={this.props.history}/>;
        let mobile = <MyProfileMobile history={this.props.history}/>;

        return (
            <div>
                <CheckDivide web={web} mobile={mobile}/>
            </div>
        )
    }
}

export default MyProfile
