import React                    from 'react';
import ReactDOM                 from 'react-dom';
import * as serviceWorker       from './serviceWorker';
import store                    from './store';
import { Provider }             from 'react-redux';
import AppLevelRouter           from './AppLevelRouter';
import i18n                     from './i18n/i18n';
import config                   from './config';
import Timer, { TimerProvider } from './Timer';
import { metadataService }      from './services';

import { Router }           from 'react-router-dom';
import { ScrollProvider }   from './ui/Scrolling.js';
import { SentryProvider }   from './services/Sentry';
import smoothScroll         from 'smoothscroll';
import { MixpanelProvider } from "./services/MixPanel";
import ErrorBoundary        from './components/HOC/ErrorBoundary';
import historyRouter        from './historyRouter';

const lang    = localStorage.getItem('i18nextLng');
const timer   = new Timer(10000);

i18n.changeLanguage(lang ? lang : config.currentLanguages);

metadataService.metadata.then(metadata => {
    ReactDOM.render(
        <SentryProvider options={config.sentry} enabled={config.sentry.enabled}>
            <MixpanelProvider options={config.mixpanel}>
                <Provider store={store}>
                    <TimerProvider timer={timer}>
                        <ScrollProvider smoothScroll={smoothScroll}>
                            <Router history={historyRouter}>
                                <ErrorBoundary>
                                    <AppLevelRouter metadata={metadata.data}/>
                                </ErrorBoundary>
                            </Router>
                        </ScrollProvider>
                    </TimerProvider>
                </Provider>
            </MixpanelProvider>
        </SentryProvider>,
        document.getElementById('root'),
    );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
