import {lineService}                             from "../services";
import {ERROR_LINE, SYNC_WITH_LINE, REVOKE_LINE} from "../actions/Line";
import jwt                                       from "jsonwebtoken";
import config                                    from "../config";
import {SYNC_PROFILE}                            from "../actions/User";

export const lineMiddleWare = (store) => next => action => {
    switch (action.type) {
        case SYNC_WITH_LINE :
            return lineService.synchronizeLine(action.code).then((result) => {
                let profile = jwt.verify(result.data.token, config.auth.authPrivateKey);
                localStorage.setItem('token', result.data.token);
                localStorage.setItem('profile', JSON.stringify(profile));
                store.dispatch({
                    type: SYNC_PROFILE,
                    profile: profile,
                });
            }).catch((error) => {
                if(error.response) {
                    store.dispatch({
                        type: ERROR_LINE,
                        error: {
                            error: error.response.data,
                            hasError: true
                        }
                    })
                }
            });
        case REVOKE_LINE :
            return lineService.revokeLine().then((result) => {
                let profile = jwt.verify(result.data.token, config.auth.authPrivateKey);
                localStorage.setItem('token', result.data.token);
                localStorage.setItem('profile', JSON.stringify(profile));
                next({
                    type: SYNC_PROFILE,
                    profile: profile,
                });
            });
        default :
            return next({
                ...action
            })
    }
};
