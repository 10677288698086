import React, {Component}           from 'react';
import {CheckDivide, KirokuLoading} from "../../../ui";
import {loadUserById}               from "../../../actions/User";
import {connect}                    from "react-redux";
import StaffCreatorWeb              from "../StaffCreator/StaffCreatorWeb";
import StaffCreatorMobile           from "../StaffCreator/StaffCreatorMobile";
import {teamService}                from "../../../services";
import {loadCustomerAll}            from "../../../actions/Customer";


@connect(state => {
    return {
        user: state.KirokuAIUser.user,
        isLoading: state.KirokuAIUser.isLoading,
        customers: state.KirokuAICustomer.ListAllCustomer
    }
}, dispatch => {
    return {
        loadUserById: id => {
            dispatch(loadUserById(id))
        },
        loadCustomers: () => {
            dispatch(loadCustomerAll())
        }
    }
})

class StaffDuplicate extends Component {

    state = {
        teams: []
    };

    componentWillMount() {
        window.history.previous = 'staff-duplicate';
        this.props.loadUserById(this.props.match.params.id);
        this.props.loadCustomers();

        teamService.loadTeams().then((res) => {
            this.setState({
                teams: res
            })
        }).catch(error => console.log(error))
    }

    render() {
        const {teams} = this.state;
        return (
            <div className='default-background-color'>
                {!this.props.isLoading ?
                    <CheckDivide
                        mobile={<StaffCreatorMobile
                            isDuplicate
                            teams={teams}
                            customers={this.props.customers}
                            user={this.props.user}
                            history={this.props.history}/>}
                        web={<StaffCreatorWeb
                            isDuplicate
                            teams={teams}
                            customers={this.props.customers}
                            history={this.props.history}
                            user={this.props.user}
                        />}/>
                    : <KirokuLoading/>
                }
            </div>
        )
    }
}

export default StaffDuplicate;
