import React, {Component}             from 'react';
import '../../Customers/customers.css';
import {
    KirokuButton,
    KirokuCard,
    KirokuCardList,
    KirokuAge,
}                                     from '../../../ui';
import {
    KirokuLoading
}                                     from '../../../ui';
import {connect}                      from 'react-redux';
import {loginRequired}                from '../../../middleware-redux/Auth';
import {Link}                         from 'react-router-dom';
import {withNamespaces}               from "react-i18next";
import {assignSurveys}                from "../../../actions/Customer";
import {filePathService, userService} from "../../../services";
import moment                         from "moment";
import SpanComponent                  from "../../HOC/SpanComponent";
import withPermissions                from "../../HOC/WithPermissions";
import TabResult                      from "../StaffEditor/TabResult";
import TabProfile                     from "../StaffDetail/Mobile/TabProfile";

const Span = withPermissions(SpanComponent);

@withNamespaces()
@loginRequired
@connect(state => {
    return {
        profile: state.KirokuAIUser.profile,
    }
}, dispatch => {
    return {
        assignSurvey: (id, customer) => {
            dispatch(assignSurveys(id, customer))
        }
    };
})
class DetailMyProfileMobile extends Component {

    state = {
        loading: false,
        showSurveys: false,
        showRecords: true,
        showProfile: true,
        statusCheck: false,
        show: false,
        id: null,
        user: {},
        loadingImg: false

    };


    componentWillMount() {
        this.setState({
            loading: true
        });
        this.setState({
            id: JSON.parse(localStorage.getItem('profile')).id
        }, () => {
            userService.loadDetail(this.state.id).then(user => {
                this.setState({
                    user: user,
                    loading: false
                })
            })
        });

    }

    showSurveys() {
        this.setState({
            showSurveys: false,
            showRecords: true,
            showProfile: true
        })
    }

    showRecords() {
        this.setState({
            showSurveys: true,
            showRecords: false,
            showProfile: true
        })
    }

    showProfile() {
        this.setState({
            showSurveys: true,
            showRecords: true,
            showProfile: false
        })
    }

    renderSurvey(team) {
        return (
            <KirokuCard
                key={team.id}
                action={''}
            >
                <Link to={`/teams/team-detail/${team.id}`}>{team.name}&nbsp;&nbsp;</Link>
            </KirokuCard>
        )
    }

    closeModal() {
        this.setState({
            show: !this.state.show
        })
    }

    renderCustomerDetail(user) {
        const {showProfile, showRecords, showSurveys} = this.state;
        let {t}                                       = this.props;
        let qualification                             = user.qualification.map(item => {
            return t(`common.dropdown.${item}`)
        });
        let currentQualification                      = qualification.join(', ');
        return (
            <div className='bg-white card-customer-detail'>
                <div className="fix-center">
                    {!this.state.loadingImg ?
                        <div className='customer-detail height-avatar'
                             style={{backgroundImage: `url(${user.avatar ? user.avatar : filePathService.resolveFilePath('profile-ic.svg')})`}}
                        /> : <KirokuLoading/>}
                    <div className='pt-10'>
                        <div>
                            <h5 className="text-center">
                                {user.name}
                            </h5>
                        </div>
                        <div>
                            <Span resource-permission={'|staffDetail|button-editProfile|'}>
                                <span className='pr-10'>
                                    <i className='fas fa-pencil-alt text-secondary'/>
                                </span>
                                <Link to={`/edit-my-profile`}>{t('common.EditProfile')}</Link>
                            </Span>
                        </div>
                    </div>
                </div>
                <div className="text-left">
                    <div className='mb-10'>
                        <span className='customer-info f-bold'>
                            <span className="mr-1 f-bold">{t('staff.title')}:</span>
                        </span>
                        <span className="mr-2">{user.title}</span>
                    </div>
                    <div className='mb-10'>
                        <span className='customer-info f-bold'>
                            <span className="mr-1 f-bold">{t('common.Role')}:</span>
                        </span>
                        <span className="mr-2">{t(`common.${user.role}`)}</span>
                    </div>
                    <div className='mb-10'>
                        <span className='customer-info f-bold'>
                            <span className="mr-1 f-bold">{t('common.WorkingForm')}:</span>
                        </span>
                        <span className="mr-2">{user['working_form'] ? t(`common.${user['working_form']}`) : ''}</span>
                    </div>
                    <div className='mb-10'>
                        <span className='customer-info f-bold'>
                            <span className="mr-1 f-bold">{t('common.Qualification')}:</span>
                        </span>
                        <span className="mr-2">{currentQualification}</span>
                    </div>
                </div>
                <div className='d-flex justify-content-between pt-10'>
                    <div hidden={this.props.profile.role === "admin"} style={{cursor: 'pointer'}}
                         className={`nav-bar ${!showSurveys ? 'active' : ''}`}
                         onClick={this.showSurveys.bind(this)}>{t('common.Team')}
                    </div>
                    <div hidden={this.props.profile.role === "admin"} style={{cursor: 'pointer'}}
                         className={`nav-bar ${!showRecords ? 'active' : ''}`}
                         onClick={this.showRecords.bind(this)}>{t('common.Customer')}
                    </div>
                    <div style={{cursor: 'pointer'}}
                         className={this.props.profile.role === "admin" ? `nav-bar active` : `nav-bar ${!showProfile ? 'active' : ''}`}
                         onClick={this.showProfile.bind(this)}>{t('common.StaffDetail')}
                    </div>
                </div>
            </div>
        )
    }

    assignSurvey() {
        let surveyForModal = this.state.surveyForModal.filter(survey => survey.isChecked);
        let customer       = this.props.customer;
        let certificate    = customer.certificate.map(item => item.value);
        this.props.assignSurvey(customer.id, {
            ...customer,
            certificate: certificate,
            staffs: customer.staffs.map(staff => staff.id),
            surveyTemplates: surveyForModal.map(survey => survey.id)
        });
        this.props.notification(this.props.t);
        this.setState({
            show: !this.state.show
        })
    }

    goBack() {
        this.props.history.goBack()
    }

    handleEditProfile() {
        this.props.history.push(`/staffs/staff-editor/${this.state.user.id}`)
    }

    handleEditCustomer() {
        this.props.history.push('/customers')
    }

    render() {
        const {user} = this.state;
        let {t}      = this.props;
        return (
            <div className='default-background-color'>
                {!this.state.loading ?
                    <div className='customer-detail default-background-color'>
                        <div className='d-flex justify-content-between p-2'>
                            <KirokuButton onClick={this.goBack.bind(this)}
                                          color='white'>{t('common.Back')}</KirokuButton>
                        </div>
                        {this.renderCustomerDetail(user)}
                        <div className='pt-8'>
                            <div hidden={this.props.profile.role === "admin"}>
                                <KirokuCardList
                                    hidden={this.state.showSurveys}
                                    headerLeft={<span>{t('common.Name')}</span>}
                                    headerRight={<span/>}>
                                    {user.staffInTeam.length ? user.staffInTeam.map(team => this.renderSurvey(team)) :
                                        <div>
                                            <TabResult messageFirst={'team1'} messageSecond={'team2'}>
                                                <img className='pt-2 pb-2'
                                                     src={`${filePathService.resolveFilePath('team-icon@2x.png')}`}
                                                     alt="no team"/>
                                            </TabResult>
                                            <div className='text-center pb-5 pt-3'>
                                                <KirokuButton
                                                    hidden={this.props.profile.role !== "admin"}
                                                    onClick={this.handleEditProfile.bind(this)} color={'primary'}>
                                                    {t('common.EditProfile')}
                                                </KirokuButton>
                                            </div>
                                        </div>
                                    }

                                </KirokuCardList>
                            </div>
                            <div hidden={this.props.profile.role === "admin"}>
                                <KirokuCardList
                                    hidden={this.state.showRecords}
                                    headerLeft={<span>{t('common.Name')}</span>}
                                    headerRight={<span>{t('common.Age')}</span>}>
                                    {user.customers.length ? user.customers.map(customer =>
                                        <KirokuCard
                                            key={customer.id}
                                            action={
                                                <span>
                                                    <KirokuAge dob={(customer.date_of_birth === null || customer.date_of_birth === '' ?
                                                        '' : moment.unix(customer.date_of_birth))}/>
                                                </span>
                                            }
                                        >
                                            <Link
                                                to={`/customers/customer-detail/${customer.id}`}>{customer.name}</Link>
                                        </KirokuCard>
                                    ) : <div>
                                        <div className='text-center pb-5 pt-3'>
                                            <TabResult messageFirst={'customer1'} messageSecond={'customer2'}>
                                                <img className='pt-2 pb-2'
                                                     src={`${filePathService.resolveFilePath('team-icon@2x.png')}`}
                                                     alt="no team"/>
                                            </TabResult>
                                            <KirokuButton
                                                hidden={this.props.profile.role !== "admin"}
                                                onClick={this.handleEditCustomer.bind(this)} color={'primary'}>
                                                {t('NullPage.customerlist')}
                                            </KirokuButton>
                                        </div>
                                    </div>}
                                </KirokuCardList>
                            </div>
                            <div hidden={this.state.showProfile && this.props.profile.role !== "admin"}
                                 className='pb-5'>
                                <div className='card p-2'>
                                    {<TabProfile user={user}/>}
                                </div>
                            </div>
                        </div>
                    </div> : <KirokuLoading/>}

            </div>
        );
    }
}

export default DetailMyProfileMobile;
