import React  from 'react';
import moment from "moment";
import config from '../../../../config';

class FormatTimestamp extends React.Component {

    render() {
        const { timestampUnix, hasTime, className } = this.props;
        let format = hasTime ? 'YYYY年M月D日(ddd) H:mm' : 'YYYY年M月D日(ddd)';
        if (moment.locale() === 'ja'){
            return (
                <span className={className}>{moment.unix(timestampUnix).format(format)}</span>
            )
        }else {
            format = hasTime ? config.formatDateTimeTh : config.formatDateTh;
            return (
                <span className={className}>{timestampUnix ? moment.unix(timestampUnix).format(format): ''}</span>
            )
        }

    }
}
export default FormatTimestamp;
