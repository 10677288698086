export default class TagsServices {

    makeUserForTags(users) {
        return users.map(user => {
            return {
                tagId: user.id,
                ...user,
                isChecked: false,
                _pivot_role: 'staff',
                tagType: "users",
                tagContent: {
                    ...user,
                },
                key: `user_id${user.id}`,
                label: user.name,
                value: `${user.name}${user.id}`,
                type: 'Users'
            }
        });
    }
}
