import {axiosConfigIsLoading, axiosConfig} from '../axiosConfig';
import config                 from '../config';
import SurveyTemplateService  from './SurveyService';
import QuestionsService       from './QuestionService';
import UploadService          from './UploadService';
import CustomerService        from './CustomerService';
import DraftService           from './DraftService';
import RecordService          from './RecordService';
import UserService            from './UserService';
import LanguageService        from './LanguageService';
import AuthService            from './AuthService';
import TeamService            from './TeamService';
import MakeCustomerService    from './MakeCustomerService';
import TeamFactory            from './TeamFactory';
import OrganizationService    from './OrganizationService';
import LogsService            from "./LogsService";
import UserFactory            from "./UserFactory"
import SurveyFactory          from "./SurveyFactory";
import SurveyBuilderConditon  from './SurveyBuilderCondition';
import LineService            from "./LineServices";
import DraftSurveyService     from "./DraftSurveyService";
import DashboardService       from "./DashboardService";
import Scroll                 from "./Scroll";
import NoScroll               from "./NoScroll";
import AnalysisService        from "./AnalysisService";
import FilePathService        from "./FilePathService";
import RecordBuilderCondition from "./RecordBuilderCondition";
import DocumentUploadService  from "./DocumentUploadService";
import MetadataService        from "./MetadataService";
import DownloadService        from "./DownloadService";
import MakeSurveyServices     from "./MakeSurveyServices";
import MakeTeamServices       from "./MakeTeamServices";
import TagsServices           from "./TagsServices";
import PreviewService         from "./PreviewService";
import ExportService          from "./ExportService";
import Reports                from "./Reports"
import DeletedList            from "./DeletedList"
import RecordBeta             from "./RecordBeta"
import LockService            from './LockService';

const noScrollService        = new NoScroll();
const scrollService          = new Scroll();
const dashboardService       = new DashboardService(axiosConfigIsLoading);
const customerService        = new CustomerService(axiosConfigIsLoading);
const questionsService       = new QuestionsService();
const surveyTemplateService  = new SurveyTemplateService(axiosConfigIsLoading);
const uploadService          = new UploadService(axiosConfigIsLoading);
const recordService          = new RecordService(axiosConfigIsLoading);
const draftService           = new DraftService(axiosConfigIsLoading);
const userService            = new UserService(axiosConfigIsLoading);
const languageService        = new LanguageService(axiosConfigIsLoading);
const authService            = new AuthService(axiosConfigIsLoading);
const teamService            = new TeamService(axiosConfigIsLoading);
const makeCustomerService    = new MakeCustomerService();
const makeSurveyService      = new MakeSurveyServices();
const makeTeamService        = new MakeTeamServices();
const tagService             = new TagsServices();
const teamFactory            = new TeamFactory();
const organizationService    = new OrganizationService(axiosConfigIsLoading);
const logsService            = new LogsService(axiosConfigIsLoading);
const userFactory            = new UserFactory();
const surveyFactory          = new SurveyFactory();
const surveyBuilderCondition = new SurveyBuilderConditon();
const lineService            = new LineService(axiosConfigIsLoading, config);
const draftSurveyService     = new DraftSurveyService(axiosConfigIsLoading);
const analysisService        = new AnalysisService(axiosConfigIsLoading);
const filePathService        = new FilePathService();
const recordBuilderCondition = new RecordBuilderCondition();
const documentUploadService  = new DocumentUploadService(axiosConfigIsLoading);
const metadataService        = new MetadataService(axiosConfigIsLoading, config);
const downloadService        = new DownloadService(axiosConfigIsLoading);
const previewService         = new PreviewService(axiosConfigIsLoading);
const exportService          = new ExportService(axiosConfigIsLoading);
const reportsService         = new Reports(axiosConfigIsLoading);
const deletedList            = new DeletedList(axiosConfigIsLoading);
const recordBeta             = new RecordBeta(axiosConfigIsLoading, axiosConfig);
const lockService            = new LockService(axiosConfigIsLoading);
const {Core}                 = window.YubinBango;
export {
    exportService,
    tagService,
    makeTeamService,
    makeSurveyService,
    noScrollService,
    scrollService,
    dashboardService,
    questionsService,
    uploadService,
    surveyTemplateService,
    recordService,
    customerService,
    draftService,
    userService,
    languageService,
    authService,
    teamService,
    makeCustomerService,
    teamFactory,
    organizationService,
    logsService,
    userFactory,
    surveyFactory,
    surveyBuilderCondition,
    lineService,
    draftSurveyService,
    analysisService,
    filePathService,
    recordBuilderCondition,
    metadataService,
    documentUploadService,
    downloadService,
    previewService,
    reportsService,
    Core,
    deletedList,
    recordBeta,
    lockService
}
