import mixpanel from 'mixpanel-browser';

const WILL_BE_TRACKED = {
    PIN_CUSTOMER: (action) => {
        return ['Pin Customer', action]
    },
    ASSIGN_SURVEY_TO_CUSTOMER: (action) => {
        return ['Assign Template to Customer', action]
    },
    ASSIGN_SURVEYS: (action) => {
        return ['Assign Template to Customer', action]
    },
    CREATE_SURVEY: (action) => {
        if (action.duplicate){
            return [ 'Duplicate Template', action]
        }
        return [ 'Create Template', action]
    },
    CREATE_SURVEY_MOBILE: (action) => {
        if (action.duplicate){
            return [ 'Duplicate Template', action]
        }
        return [ 'Create Template', action]
    },
    CREATE_TEAM : (action) => {
        return [ 'Create Team', action]
    },
    TEAM_UPDATED : (action) => {
        return [ 'Update Team', action]
    },
    UPDATE_SURVEY_MOBILE : (action) => {
        return [ 'Update Template', action]
    },
    UPDATE_SURVEY : (action) => {
        return [ 'Update Template', action]
    },
    PREVIEW_TEMPLATE : (action) => {
        return [ 'Preview Template', action]
    },
    CUSTOMER_CREATED : (action) => {
        return [ 'Create Customer', action]
    },
    CUSTOMER_UPDATED : (action) => {
        return [ 'Update Customer', action]
    },
    CUSTOMER_DUPLICATED : (action) => {
        return [ 'Duplicate Customer', action]
    },
    DEACTIVE_CUSTOMER : (action) => {
        return [ 'Deactive Customers', action]
    },
    STAFF_CREATED : (action) => {
        return [ 'Create Staff', action]
    },
    STAFF_UPDATED : (action) => {
        return [ 'Update Staff', action]
    },
    STAFF_DUPLICATED : (action) => {
        return [ 'Duplicate Staff', action]
    },
    DELETE_USERS : (action) => {
        return [ 'Deactive Staffs', action]
    },
    UPDATE_ORGANIZATION : (action) => {
        return [ 'Update Organization', action]
    },
    HANDLE_SUBMIT_COMMENT_RECORD : (action) => {
        return [ 'Comment Record', action]
    },
    SELECT_RECORD_NEW_EXPORT : (action) => {
        return [ 'Export(Record Beta)', action]
    },
    EXPORT_DOCX : (action) => {
        return [ 'Export Word', action]
    },
    RECORD_EDITED_TRACKING : (action) => {
        return [ 'Update Record', action]
    },
    RECORD_DRAFTED_TRACKING : (action) => {
        return [ 'Draft Record', action]
    },
    RECORD_QUICK_EDITED_TRACKING : (action) => {
        return [ 'Quick Update Record', action]
    },
    RECORD_QUICK_DRAFTED_TRACKING : (action) => {
        return [ 'Quick Draft Record', action]
    },
    NEW_RECORD_DRAFTED_TRACKING : (action) => {
        return [ 'Create Draft Record', action]
    },
    RECORD_CREATED_TRACKING : (action) => {
        return [ 'Create Record', action]
    },
    RECORD_QUICK_CREATED_TRACKING : (action) => {
        return [ 'Create Quick Record', action]
    },
    NEW_RECORD_QUICK_DRAFTED_TRACKING : (action) => {
        return [ 'Create Quick Draft Record', action]
    },
    LOGGED_IN: (action) => {
        return [ 'Login', action]
    },
    LOGOUT: (action) => {
        return [ 'Logout', action]
    }

};


export default store => next => action => {
    if (WILL_BE_TRACKED[action.type]) {
        const [eventName, evenData] = WILL_BE_TRACKED[action.type](action, store.getState());
        mixpanel.track(eventName, evenData);
    }
    next(action);
}
