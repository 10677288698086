import '../../record.css'
import React, { Component }      from 'react';
import { KirokuInputArea }       from '../../../../ui';
import { withNamespaces }        from 'react-i18next';
import KirokuLogicApplicated     from "../../../../ui/KirokuLogicApplicated";
import {checkUseHeadingQuestion} from "../../../Surveys/SurveyEditor/SurveyService";

@withNamespaces()
class InputText extends Component {

    render() {
        const {question, surveyDetail, headerNextQuestion, t} = this.props;
        const contentQuestion = checkUseHeadingQuestion(question);
        return (
            <div className='content-question'>
                <div className='wraper-question'>
                    <div className="background-question">
                        <div className='number-question d-flex justify-content-between'>
                            <div className="question-number text-overflow-elip">
                                {question.unrequited ? t('createRecord.option'):''}{contentQuestion}</div>
                            <div hidden={!surveyDetail} className={'float-right'}>
                                <KirokuLogicApplicated padding={'5px 5px 5px 7px'} question={question}/>
                            </div>
                        </div>
                        <span className='content-name-question'>{question.content}</span>
                        <div
                            hidden={typeof question.nextQuestion !== 'number' || !surveyDetail}
                            className='pl-10 text-info text-overflow-elip'>
                            <i className='fas fa-arrow-right' style={{paddingTop: 4}}/>
                            <span> {headerNextQuestion}</span>
                        </div>
                    </div>
                    <div className='answer-question'>
                        <KirokuInputArea className='form-area' readOnly
                                         style={{color : '#495057',  cursor : 'no-drop' }}
                                         value={this.props.value}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default InputText;
