import React, { Component }                                   from 'react';
import { connect }                                            from 'react-redux';
import AboutSurvey                                            from '../../../ReviewRecording/AboutSurvey';
// import {KirokuNotificationDraft}      from "../../../../../ui";
import { withNamespaces }                                     from 'react-i18next';
import { updateRecord }                                       from '../../../../../actions/Record';
import moment                                                 from 'moment';
import KiroNotificationBackup                                 from '../KiroNotificationBackup';
import RecordEditorNew                                        from './RecordEditorNew';
import { saveDraftEditRecording }                             from '../../../../../actions/Draft';
import { getAnswersToQuestion, getAnswerToQuestionFullLogic } from '../../../sevicesInRecord';
import { turnOnModalConfirmExit }                             from '../../../../../actions/TakeSurvey';
import ModalExit                                              from '../../../RecordCreator/Web/ModalConfirmExit';
import { recordService }                                      from '../../../../../services';
import { NOTIFICATION }                                       from '../../../../../actions/Notification';
import { isErrorPermissionEditRecordLocking }                 from '../../../../../axiosConfig/HandleError';

@connect(state => {
    return {
        recordToEdit        : state.KirokuAIRecord.recordToEdit,
        recordToEditBackup  : state.KirokuAIRecord.recordToEditBackup,
        backUpDataEditRecord: state.RecordEditorNew.backUpDataEditRecord,
        // lockInfo: state.KirokuLockInfo.lockInfo,
    };
}, dispatch => {
    return {
        updateRecord                  : async (id, dataRecord) => {
            dispatch(updateRecord(id, dataRecord));
        },
        saveDraftEditRecording        : (dataEditRecoding) => {
            dispatch(saveDraftEditRecording(dataEditRecoding));
        },
        turnOnModalConfirmExit        : () => {
            dispatch(turnOnModalConfirmExit());
        },
        updateRecordSuccess           : () => {
            dispatch(
                {
                    type            : NOTIFICATION,
                    typeNotification: 'success',
                    message         : 'createRecord.UpdateRecordSuccess',
                },
            );
        },
        notificationDraftRecordSuccess: () => {
            dispatch(
                {
                    type            : NOTIFICATION,
                    typeNotification: 'success',
                    message         : 'createRecord.SaveDraftSuccess',
                },
            );
        },
        updateRecordFall              : () => {
            dispatch({
                type            : NOTIFICATION,
                typeNotification: 'danger',
                message         : 'createRecord.CreateRecordFail',
            });
        },
        trackingEditRecord            : (record) => {
            dispatch({
                type: 'RECORD_EDITED_TRACKING',
                record,
            });
        },
        trackingDraftRecord           : (record) => {
            dispatch({
                type: 'RECORD_DRAFTED_TRACKING',
                record,
            });
        },
        unLockRecord: (id, actionType) => {
            dispatch({
                type: "UNLOCK_RECORD",
                id, actionType
            })
        },
        pollingActionFirst: (lockableType, recordId) => {
            dispatch({
                type: 'LOCK_INFORMATION_FIRST',
                lockableType, recordId
            });
        }
    };
})
@withNamespaces()
class RecordEditorFiledAllWeb extends Component {

    state = {
        keyLoadData: null,  //keyLoadData: null(loadRecord) 'statusDraft'(loadDraft) 'backup'(loadDataBackup)
        serviced_at: this.props.recordToEdit.serviced_at ? this.props.recordToEdit.serviced_at : null,
        has_time   : this.props.recordToEdit.has_time || false,
        profile    : JSON.parse(localStorage.getItem('profile')),
    };

    handleCancel() {
        this.props.turnOnModalConfirmExit();
    }

    handleSaveStatusRecord(flowedQuestion) {
        const survey           = this.props.recordToEdit.survey_template;
        const surveyTemplate   = {
            ...survey,
            // questions: this.props.recordToEditBackup.survey_template.questions,
            questions      : getAnswerToQuestionFullLogic(flowedQuestion, survey.questions).map(question => {
                delete question.visibility;
                return question;
            }),
            flowedQuestions: flowedQuestion.map(question => {
                delete question.visibility;
                return question;
            }),
        };
        const statusDataRecord = {
            surveyTemplate: surveyTemplate,
            customer      : this.props.recordToEdit.customer,
            hasDraft      : 'draft',
            draftBy       : JSON.parse(localStorage.getItem('profile')).id,
            draftedAt     : moment().unix(),
            serviced_at   : this.state.serviced_at,
            has_time      : this.state.has_time,
            answers       : getAnswersToQuestion(flowedQuestion),
        };
        this.props.updateRecord(this.props.recordToEdit.id, statusDataRecord);
        this.props.trackingDraftRecord(statusDataRecord);
    }

    handleSubmitRecord(flowedQuestion) {
        const survey         = this.props.recordToEdit.survey_template;
        const surveyTemplate = {
            ...survey,
            questions      : getAnswerToQuestionFullLogic(flowedQuestion, survey.questions).map(question => {
                delete question.visibility;
                return question;
            }),
            flowedQuestions: flowedQuestion.map(question => {
                delete question.visibility;
                return question;
            }),
        };
        const dataRecord     = {
            surveyTemplate: surveyTemplate,
            customer      : this.props.recordToEdit.customer,
            hasDraft      : null,
            draftBy       : null,
            draftedAt     : null,
            serviced_at   : this.state.serviced_at,
            has_time      : this.state.has_time,
            answers       : getAnswersToQuestion(flowedQuestion),
        };
        recordService.updateRecord(this.props.recordToEdit.id, dataRecord).then(res => {
            console.log(res);
            this.props.unLockRecord(this.props.recordToEdit.id, 'records')
            this.props.trackingEditRecord(dataRecord);
            if (window.history.previous === '/records/new-create-record') {
                this.props.history.push(`/customers/customer-detail/${ this.props.recordToEdit.customer.id }`);
                window.history.previous = undefined;
            } else if (window.history.previous === '/records-beta-detail') {
                this.props.history.push('/records-beta');
                window.history.previous = undefined;
            } else {
                this.props.history.goBack();
            }
            if (!dataRecord.hasDraft) {
                this.props.updateRecordSuccess();
            }
            if (dataRecord.hasDraft) {
                this.props.notificationDraftRecordSuccess();
            }
        }).catch(e => {
            if(isErrorPermissionEditRecordLocking(e)) {
                this.props.pollingActionFirst('records', this.props.recordToEdit.id)
            }
            this.props.updateRecordFall();
        });
    }

    // loadDraftRecord() {
    //     this.setState({
    //         keyLoadData: 'statusDraft'
    //     })
    // }

    confirmLoadBackupDraft() {
        this.setState({
            keyLoadData: 'backup',
        });
    }

    saveBackupDataRecord(dataBackup) {
        const requestSaveDraft = {
            id           : this.props.recordToEdit.id,
            customer_id  : this.props.recordToEdit.customer.id,
            customer_name: this.props.recordToEdit.customer.name,
            // survey_template: this.props.recordToEdit.survey_template,
            dataDraft    : dataBackup,
            create_at    : moment().unix(),
            serviced_at  : this.state.serviced_at,
            has_time     : this.state.has_time,
        };
        this.props.saveDraftEditRecording(requestSaveDraft);
    }

    onChangeDate = (serviced_at, hasTime) => {
        this.setState({
            serviced_at: hasTime ? serviced_at : moment.unix(serviced_at).startOf('day').unix(),
            has_time   : hasTime,
        });
    };

    render() {
        const { recordToEdit, backUpDataEditRecord, lockInfo, t } = this.props;
        const { keyLoadData, profile }                            = this.state;

        return (
            <div className='padding-responsive default-background-color'>
                {/*<KirokuNotificationDraft*/ }
                {/*    loadDraft={this.loadDraftRecord.bind(this)}*/ }
                {/*    discardDraft={() => {}}*/ }
                {/*    draft={window.history.previous === "/records/new-create-record" ? null : recordToEdit.statusRecord}*/ }
                {/*/>*/ }
                <div className="keeping-about-survey" style={ { top: 70, backgroundColor: '#eef3f6' } }>
                    <KiroNotificationBackup
                        confirm={ this.confirmLoadBackupDraft.bind(this) }
                        cancel={ () => {
                        } }
                        draft={ backUpDataEditRecord }
                    />

                    <div className='fix-center bg-white'>
                        <div style={ { width: '70%' } }>
                            <div id='about-survey' className='pt-4 pb-3'>
                                <AboutSurvey
                                    editor
                                    onChangeDate={ this.onChangeDate.bind(this) }
                                    serviced_at={ recordToEdit.serviced_at }
                                    hasTime={ recordToEdit.has_time }
                                    user={ recordToEdit.user }
                                    survey={ recordToEdit['survey_template'] }
                                    customer={ recordToEdit.customer } isRecord
                                    create_at={ recordToEdit.create_at }
                                    screen={ 'editRecord' }
                                    lockInfo={ lockInfo }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/*Survey description*/ }
                <div className="survey fix-center" style={ { backgroundColor: '#FFFFFF' } }>
                    <div className="pb-3" style={ { width: '70%' } }>
                        <div className='info-question'>
                            <div className='content-name-question d-flex'>
                                        <span style={ { wordBreak: 'keep-all' } }
                                              className='title-record-info'>{ t('createRecord.Description') }&nbsp;</span>
                                <pre style={ {
                                    wordWrap  : 'break-word',
                                    whiteSpace: 'pre-wrap',
                                } }>
                                            { recordToEdit['survey_template'].description }
                                        </pre>
                            </div>
                        </div>
                    </div>
                </div>
                <RecordEditorNew
                    editor
                    recordToEdit={ recordToEdit }
                    updateRecord={ this.handleSubmitRecord.bind(this) }
                    saveStatusRecord={ this.handleSaveStatusRecord.bind(this) }
                    // saveBackupDataRecord={this.saveBackupDataRecord.bind(this)}
                    saveBackupDataRecord={ () => {
                    } }
                    statusRecord={ {
                        statusDraft: recordToEdit.statusRecord,
                        backup     : backUpDataEditRecord,
                    } }
                    keyLoadData={ keyLoadData }
                    handleCancel={ this.handleCancel.bind(this) }
                    lockInfo={ lockInfo }
                />
                <ModalExit history={ this.props.history } editor={ lockInfo['user_id'] === profile.id }
                           actionType="records"/>
            </div>
        );
    }
}

export default RecordEditorFiledAllWeb;
