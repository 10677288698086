export const cleanQuestions = (questions) => {
    return questions.map(question => {
        if(question.type !== 'MultiChoice' && question.type !== 'Single') {
            delete question['choices'];
        }

        if(question.type !== 'YesNo') {
            delete question['nextQuestionYes'];
            delete question['nextQuestionNo'];
        }

        if(question.type === 'Single' ||  question.type === 'MultiChoice' ) {
            question['choices'] = question['choices'].map(choice => {
                delete choice['defaultAnswer'];
                return choice;
            })
        }

        delete question['defaultAnswer'];

        return {...question}
    });
};
//todo edit modal change logic question
export const keepLogicWhenDelete = (deletedIndex, questions) => {
    return questions.map((question)=>{
        switch (question.type) {
                case 'Single':
                    return {
                        ...question,
                        choices: question.choices.map((choice) => {
                            if (choice.nextQuestion > deletedIndex){
                                return {
                                    ...choice,
                                    nextQuestion: choice.nextQuestion - 1
                                }
                            }
                            if (choice.nextQuestion === deletedIndex){
                                return {
                                    ...choice,
                                    nextQuestion: ''
                                }
                            }
                            return choice;
                        })
                    };

                case 'YesNo' :

                    // next question of question
                    if(question.nextQuestion) {
                        if (question.nextQuestion ===  deletedIndex){
                            return {
                                ...question,
                                nextQuestion: ''
                            };
                        }
                        if (question.nextQuestion >  deletedIndex){
                            return {
                                ...question,
                                nextQuestion: question.nextQuestion - 1
                            };
                        }
                    }

                    //next question of answer question
                    if (question.nextQuestionNo > deletedIndex){
                        question = {
                            ...question,
                            nextQuestionNo: question.nextQuestionNo - 1,
                        };
                    }else if(question.nextQuestionNo === deletedIndex){
                        question = {
                            ...question,
                            nextQuestionNo: '',
                        };
                    }

                    if (question.nextQuestionYes > deletedIndex){
                        question = {
                            ...question,
                            nextQuestionYes: question.nextQuestionYes - 1,
                        };
                    }else if (question.nextQuestionYes === deletedIndex){
                        question = {
                            ...question,
                            nextQuestionYes : ''
                        };
                    }

                    return question;
                default :
                    if (question.nextQuestion ===  deletedIndex){
                        return {
                            ...question,
                            nextQuestion: ''
                        };
                    }
                    if (question.nextQuestion >  deletedIndex){
                        return {
                            ...question,
                            nextQuestion: question.nextQuestion - 1
                        };
                    }
                    return question;
            }
    });
};

export const checkUseHeadingQuestion = (question) => {
    return (typeof question.heading !== 'undefined' && question.heading.trim()) ? question.heading : question.content
};
