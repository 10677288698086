class OrganizationService {
    constructor(axios) {
        this.axios = axios;
    }

    getOrganization() {
        return this.axios.get(`/organization`).then(res => res.data)
    }
    putOrganization(id, data) {
        return this.axios.put(`/organization/${id}`, data).then(res => res.data)
    }

}
export default OrganizationService;