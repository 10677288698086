export const SYNC_WITH_LINE   = 'SYNC_WITH_LINE';
export const ERROR_LINE       = 'ERROR_LINE';
export const RESET_LINE_ERROR = 'RESET_LINE_ERROR';
export const REVOKE_LINE      = 'REVOKE_LINE';

export function synchronizeLine(code) {
    return {
        type: SYNC_WITH_LINE,
        code
    }
}

export function revokeLine() {
    return {
        type: REVOKE_LINE,
        loginRequired: true
    }
}

export function resetLine() {
    return {
        type: RESET_LINE_ERROR
    }
}

export function errorLine(error) {
    return {
        type: ERROR_LINE,
        error
    };
}
