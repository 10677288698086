export default {
    domain         : process.env.REACT_APP_DOMAIN_DEV,
    role           : {
        ADMIN : 'admin',
        STAFF : 'staff',
        LEADER: 'leader',
        GUEST : 'guest',
    },
    localStorageKey: {
        toke_auth: 'token',
    },

    currentLanguages: process.env.REACT_APP_DEFAULT_LANGUAGES || 'ja',

    auth: {
        authPrivateKey: process.env.REACT_APP_AUTH_PRIVATE_KEY,
    },

    line: {
        client_id         : process.env.REACT_APP_LINE_LOGIN_CLIENT_ID_DEV,
        redirect_uri      : process.env.REACT_APP_LINE_REDIRECT_URI_DEV,
        login_redirect_uri: process.env.REACT_APP_LINE_LOGIN_REDIRECT_URI_DEV,
        bot_prompt        : process.env.REACT_APP_LINE_BOT_PROMPT_DEV,
        state_login       : 'login_state',
        state_async       : 'async_state',
    },

    filePath: {
        directory: process.env.REACT_APP_DIRECTORY,
    },

    formatDate              : 'YYYY/MM/DD',
    formatDateTh            : 'YYYY/M/D(ddd)',
    formatDateTimeTh        : 'YYYY/M/D(ddd) HH:mm',
    formatDateTimeYMD       : 'YYYY年M月D日(ddd) H:mm',
    formatDateYMD           : 'YYYY年M月D日(ddd)',
    formatTime              : 'HH:mm',
    timeAutoSaveBackupRecord: 10000,
    sizeTagOnWed            : 11,
    sizeTagOnMobile         : 5,
    pollingTime             : 10000,
    //config format location search
    formatParams            : { arrayFormat: 'comma' },
    routerNotUseLayout      : ['/batch-action-records-beta/search', '/server-maintenance', '/surfly', '/privacy', '/terms'],
    secretKeyDarkSkyAPI     : '3bcc6d8745fb7492758907de1eab2bd8',
    excludeDarkSky          : ['currently', 'minutely', 'hourly', 'alerts', 'flags'],
    sentry                  : {
        dsn        : process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_ENVIRONMENT,
        enabled    : JSON.parse(process.env.REACT_APP_SENTRY_ENABLED),
    },

    mixpanel            : {
        token  : process.env.REACT_APP_MIXPANEL_TOKEN,
        enabled: JSON.parse(process.env.REACT_APP_MIXPANEL_ENABLED),
    },
    questionsTypeAnalyst: ['MultiChoice', 'YesNo', 'Single', 'Number', 'Time', 'OnlyDate'],
    orgUsingElasticsearch : [1]
};
