import React, {Component}               from 'react';
import {connect}                        from "react-redux";
import _                                from 'lodash';
import KirokuLoading                    from "../../../ui/KirokuLoading";
import {loadCustomerById}               from "../../../actions/Customer";
import ComponentCustomerEditorMobile    from "../CustomerEditor/ComponentCustomerEditorMobile";
import ComponentCustomerEditorWeb       from "../CustomerEditor/ComponentCustomerEditorWeb";
import {CheckDivide}                    from "../../../ui";
import {customerService, scrollService} from "../../../services";
import KirokuTitlepage                  from "../../../ui/KirokuTitlepage";
import {currentPage}                    from "../../../actions/CurrentPage";
import {withNamespaces}                 from "react-i18next";
import {NOTIFICATION}                   from "../../../actions/Notification";
import {loadUsers}                      from "../../../actions/User";
import {loadTeam}                       from "../../../actions/Team";

@withNamespaces()
@connect((state) => ({
    customer: state.KirokuAICustomer.customer,
    loadingUserSuccess: state.KirokuAIUser.loadingUserSuccess,
    isLoading: state.KirokuAITeam.isLoading,
    allTeam: state.KirokuAITeam.allTeam,
}), (dispatch) => ({
    getCustomer: params => dispatch(loadCustomerById(params)),
    notification: () => {
        dispatch({
            type: NOTIFICATION,
            typeNotification: 'success',
            message: 'message.CreateSuccess'
        })
    },
    currentPageUsed: (name) => {
        dispatch(currentPage(name))
    },
    loadUsers: () => {
        dispatch(loadUsers())
    },
    loadTeams: () => {
        dispatch(loadTeam())
    },
    trackingDuplicateCustomer: (customer) => {
        dispatch({
            type: "CUSTOMER_DUPLICATED",
            customer,
        })
    }
}))
class DuplicateCustomer extends Component {
    page  = {
        title: <KirokuTitlepage title={this.props.t('common.Customer')}/>,
    };
    state = {
        errors: {
            name: true,
            certification_number: false
        }
    };

    componentDidMount() {
        scrollService.top();
        this.props.loadTeams();
        this.props.loadUsers();
        this.props.getCustomer(this.props.match.params.id);
        this.props.currentPageUsed(this.page);
    }

    handleCreator(customer) {
        return customerService.create(customer).then(customerResult => {
            this.props.trackingDuplicateCustomer(customer);
            if (customerResult.id) {
                this.props.history.push(`/customers/customer-detail/${customerResult.id}`);
                this.props.notification()
            } else {
                this.props.history.push(`/customers`)
            }
        });
    }

    render() {
        const {
                  avatar, name, blood_type, date_of_birth, address, characteristic,
                  certification_number, customer_code, ...customer
              }    = this.props.customer;
        let mobile = <ComponentCustomerEditorMobile
            handleSubmit={this.handleCreator.bind(this)}
            history={this.props.history}
            teams={this.props.allTeam}
            customer={customer}
            errors={this.state.errors}
        />;
        let web    = <ComponentCustomerEditorWeb
            history={this.props.history}
            teams={this.props.allTeam}
            handleSubmit={this.handleCreator.bind(this)}
            customer={customer}
            errors={this.state.errors}
        />;
        return (
            <div className='customer-detail default-background-color'>
                {!_.isEmpty(this.props.customer) && this.props.isLoading && this.props.loadingUserSuccess ?
                    <CheckDivide web={web} mobile={mobile}/> : <KirokuLoading/>}
            </div>
        )
    }
}

export default DuplicateCustomer
