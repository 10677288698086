import React, {Component} from 'react';
import KirokuDropdownItem from "../../../../ui/KirokuDropdownItem";
import {withNamespaces}   from "react-i18next";
import {filePathService}  from "../../../../services";
import _                  from 'lodash';
@withNamespaces()

class DropDownTypeQuestions extends Component {

    handleSelected(event) {
        this.props.onSelect(event);
        this.setState({
            popupVisible: false,
            selected: event.kirokuSelected
        });
    }

    renderValueDropDown(t) {
        let listTypeQuestion = [
            {
                label: <div><span><img src={filePathService.resolveFilePath('input-text.svg')} alt=""/></span><span
                    className={'text-muted pl-2'}>{t('createSurvey.InputText')}</span></div>,
                value: "InputText",
                component: (<div>
                    <div><span><img src={filePathService.resolveFilePath('input-text.svg')} alt=""/></span><span
                        className={'text-muted pl-2'}>{t('createSurvey.InputText')}</span></div>
                </div>)
            },
            {
                label: <div><span><img src={filePathService.resolveFilePath('no.svg')} alt=""/></span><span
                    className={'text-muted pl-2'}>{t('createSurvey.YesNo')}</span></div>,
                value: "YesNo",
                component: (<div>
                    <div><span><img src={filePathService.resolveFilePath('no.svg')} alt=""/></span><span
                        className={'text-muted pl-2'}>{t('createSurvey.YesNo')}</span></div>
                </div>)
            },
            {
                label: <div><span><img src={filePathService.resolveFilePath('date-time.svg')} alt=""/></span><span
                    className={'text-muted pl-2'}>{t('createSurvey.DateTime')}</span></div>,
                value: "Date",
                component: (<div>
                    <div><span><img src={filePathService.resolveFilePath('date-time.svg')} alt=""/></span><span
                        className={'text-muted pl-2'}>{t('createSurvey.DateTime')}</span></div>
                </div>)
            },
            {
                label: <div><span><img src={filePathService.resolveFilePath('multi-select.svg')} alt=""/></span><span
                    className={'text-muted pl-2'}>{t('createSurvey.Single')}</span></div>,
                value: "Single",
                component: (<div>
                    <div><span><img src={filePathService.resolveFilePath('multi-select.svg')} alt=""/></span><span
                        className={'text-muted pl-2'}>{t('createSurvey.Single')}</span></div>
                </div>)
            },
            {
                label: <div><span><img src={filePathService.resolveFilePath('single-select.svg')} alt=""/></span><span
                    className={'text-muted pl-2'}>{t('createSurvey.Multiple')}</span></div>,
                value: "MultiChoice",
                component: (<div>
                    <div><span><img src={filePathService.resolveFilePath('single-select.svg')} alt=""/></span><span
                        className={'text-muted pl-2'}>{t('createSurvey.Multiple')}</span></div>
                </div>)
            },
            {
                label: <div><span><img src={filePathService.resolveFilePath('media2.svg')} alt=""/></span><span
                    className={'text-muted pl-2'}>{t('createSurvey.MediaFile')}</span></div>,
                value: "Media",
                component: (<div>
                    <div><span><img src={filePathService.resolveFilePath('media2.svg')} alt=""/></span><span
                        className={'text-muted pl-2'}>{t('createSurvey.MediaFile')}</span></div>
                </div>)
            },
            {
                label: <div><span><img src={filePathService.resolveFilePath('simple-mess.svg')} alt=""/></span><span
                    className={'text-muted pl-2'}>{t('createSurvey.Simple')}</span></div>,
                value: "Message",
                component: (<div>
                    <div><span><img src={filePathService.resolveFilePath('simple-mess.svg')} alt=""/></span><span
                        className={'text-muted pl-2'}>{t('createSurvey.Simple')}</span></div>
                </div>)
            },
            {
                label: <div><span><img src={filePathService.resolveFilePath('numbers.svg')} alt="" style={{ width: 25}}/></span><span
                    className={'text-muted pl-2'}>{t('createSurvey.NumberQuestion')}</span></div>,
                value: "Number",
                component: (<div>
                    <div><span><img src={filePathService.resolveFilePath('numbers.svg')} alt="" style={{ width : 25}}/></span><span
                        className={'text-muted pl-2'}>{t('createSurvey.NumberQuestion')}</span></div>
                </div>)
            },
            {
                label: <div><span><img src={filePathService.resolveFilePath('date.svg')} alt=""/></span><span
                    className={'text-muted pl-2'}>{t('createSurvey.Date')}</span></div>,
                value: "OnlyDate",
                component: (<div>
                    <div><span><img src={filePathService.resolveFilePath('date.svg')} alt=""/></span><span
                        className={'text-muted pl-2'}>{t('createSurvey.Date')}</span></div>
                </div>)
            },
            {
                label: <div><span><img src={filePathService.resolveFilePath('clock.svg')} alt=""/></span><span
                    className={'text-muted pl-2'}>{t('createSurvey.Time')}</span></div>,
                value: 'Time',
                component: (<div>
                    <div><span><img src={filePathService.resolveFilePath('clock.svg')} alt=""/></span><span
                        className={'text-muted pl-2'}>{t('createSurvey.Time')}</span></div>
                </div>)
            },
        ];

        if (this.props['isCreateSurvey']) {
            _.remove(listTypeQuestion, (i) => i.value === "YesNo")
        }
        return listTypeQuestion;
    }

    constructor() {
        super();

        this.handleClick        = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            popupVisible: false,
            selected: null
        };


    }

    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
    }

    handleOutsideClick(e) {
        if (!this.node) {
            return;
        }
        this.handleClick();
    }


    componentWillMount() {
        let type = this.props.type;
        let item = this.renderValueDropDown(this.props.t).find(question => question.value === type);
        this.setState({
            selected: item,
        })
    }

    render() {
        const selectedLabel = this.state.selected ? this.state.selected.label : this.props.title;
        return (
            <div ref={node => {
                this.node = node
            }}>
                <div className={`dropdown ${this.props.className ? this.props.className : ''}`}>
                    <button style={this.props.style}
                            className={`btn btn-kiroku ${this.props.className ? this.props.className : ''}`}
                            onClick={this.handleClick.bind(this)}>
                        <span className={'text-kiroku left'}>{selectedLabel}</span>
                        <i className={`${!this.state.popupVisible ? 'fa fa-chevron-down icon-kiroku' : 'fa fa-chevron-up icon-kiroku'}`}/>
                    </button>
                    {this.state.popupVisible && (
                        <div
                            className={`dropdown-kiroku dropdown-menu show ${this.props.location ? this.props.location : ''}`}
                            style={{position: 'absolute', willChange: 'top, left', left: 'auto', top: 'auto', overflow: 'scroll', height: 250}}>
                            {this.renderValueDropDown(this.props.t).map((item, index) => {
                                const Item = item.component;
                                return (
                                    <KirokuDropdownItem key={index} onClick={(event) => {
                                        event.kirokuSelected = item;
                                        this.handleClick(event);
                                        this.handleSelected(event);
                                    }}>
                                        {Item}
                                    </KirokuDropdownItem>
                                )
                            })}
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default DropDownTypeQuestions;
