import React, {Component}                               from 'react';
import {
    KirokuCardWeb,
    KirokuCardTitleWeb,
    KirokuButton,
    KirokuCardBodyWeb,
    KirokuInput,
    KirokuInputArea,
}                                                       from "../../../../ui";
import ListQuestion                                     from "./ListQuestion";
import CheckBoxTypeQuestion                             from "./CheckBoxTypeQuestion";
import {connect}                                        from "react-redux";
import {createQuestion, editSurveyTemplate, showReview} from "../../../../actions/SurveyTemplate";
import {withNamespaces}                                 from "react-i18next";
import {saveDraft}                                      from "../../../../actions/DraftSurvey";
import {hasTimer}                                       from "../../../../Timer";
import _                                                from 'lodash';

@hasTimer()
@withNamespaces()
@connect(state => {
    return {
        survey: state.KirokuAISurveyTemplate,
        loadingDraft: state.KirokuDraftSurvey.loadingSaveDraft,
        dataDraft: state.KirokuDraftSurvey.draftSurvey,
        flagLoadDataFromDraft: state.KirokuDraftSurvey.flagLoadDataFromDraft

    }
}, dispatch => {
    return {
        editSurveyTemplate: surveyTemplate => dispatch(editSurveyTemplate(surveyTemplate)),
        createQuestion: question => dispatch(createQuestion(question)),
        showReview: () => dispatch(showReview()),
        saveDraft: (draft) => dispatch(saveDraft(draft)),
    }
})
class ComponentSurveyEditor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            questions: [],
            choices: [{answer: ''}, {answer: ''}],
            content: "",
            heading: "",
            type: "InputText",
            errorFormNumber : false
        };

        // this.props.timer.record(() => {
        //     this.autoSaveDraft()
        // }, 10000);

    }

    handleCheckType(type, choices) {
        switch (type) {
            case 'Single':
            case 'MultiChoice':
                this.setState({
                    type: type,
                    choices: choices
                });
                break;
            default:
                this.setState({
                    type: type,
                });
                break;
        }
    }

    actionSurveyTemplate(surveyTemplate) {
        this.props.editSurveyTemplate(surveyTemplate)
    }

    actionQuestion() {
        const question = this.createQuestionByType(this.state);
        this.props.createQuestion(question);
    }

    createQuestionByType(question) {
        let {content, heading, choices, type} = question;
        let index                             = this.props.survey.questions.length;
        switch (type) {
            case 'Single':
            case 'MultiChoice' :
                return {
                    content, heading, type, choices, index
                };
            default:
                return {
                    content, heading, type, index
                }
        }
    }

    disabledAddQuestion = () => {
        let {choices, content, type} = this.state;
        const errorChoices           = choices.every(choice => choice.answer !== '');
        switch (type) {
            case 'Single':
            case 'MultiChoice':
                return (!content || !errorChoices);
            default :
                return !content
        }

    };

    addQuestion() {
        let {type} = this.state;
        switch (type) {
            case 'Single':
            case 'MultiChoice':
                this.actionQuestion();
                this.setState({
                    choices: [{answer: ''}, {answer: ''}],
                    content: '',
                    heading: '',
                });
                break;
            default:
                this.actionQuestion();
                this.setState({
                    choices: [{answer: ''}, {answer: ''}],
                    content: '',
                    heading: '',
                });
                break;
        }
    }

    onChangeTitle(e) {
        this.actionSurveyTemplate({title: e.target.value})
    }

    onChangeFormNumber(e) {
        let regex = e.target.value.replace(/[^0-9]/g, '');
        this.actionSurveyTemplate({form_number: regex})
    }

    onChangeDescription(e) {
        this.actionSurveyTemplate({description: e.target.value});
    }

    onChangeHeading(e) {
        this.setState({
            heading: e.target.value
        })
    }

    onChangeContent(e) {
        this.setState({
            content: e.target.value
        }, () => this.actionSurveyTemplate())
    }

    checkValidationFormNumber = (e) => {
        const error = this.checkErrorFormNumber(e.currentTarget.value);
        this.setState({
            errorFormNumber: error
        });
    };

    checkErrorFormNumber = (number) => {
        if(number.length === 1) {
            this.setState({errorFormNumber: true});
            return 'errorFormNumber';
        }
        return '';
    };

    handleSave = () => {
        this.props.handleSave();
    };

    autoSaveDraft = () => {
        const {survey} = this.props;
        this.props.saveDraft(survey);
    };

    componentWillUnmount() {
        this.props.timer.unRegisterLastJob();
    }

    cancel = () => {
        this.props.history.push('/surveys');
    };

    validateChoiceQuestion() {
        const {survey}                 = this.props;
        const questions                = [...survey.questions];
        let arrayChecksAnswerQuestions = questions.map(question => {
            if (question['choices']) {
                return _.findIndex(question.choices, ['answer', '']) !== -1
            }
            return !!question['choices']
        });
        return !!(arrayChecksAnswerQuestions.find((item) => item === true))
    }

    validateContentQuestions() {
        const {survey} = this.props;
        return _.findIndex(survey.questions, ['content', '']) !== -1
    }

    disabledSave = () => {
        const {survey} = this.props;
        return !survey.title || !survey.description || !survey.questions.length || isNaN(survey.form_number) ||
            this.validateContentQuestions() || this.validateChoiceQuestion() || this.state.errorFormNumber
    };

    render() {
        const {t, nameButtonSubmit} = this.props;
        let {survey}                = this.props;
        // const {errorFormNumber}     = this.state;
        return (
            <div className='p-3 default-background-color'>
                <div>
                    <KirokuCardWeb>
                        <KirokuCardTitleWeb>
                            <div className='d-flex justify-content-between'>
                                <h4>{t('createSurvey.Summary')}</h4>
                                <KirokuButton
                                    disabled={this.disabledSave()}
                                    onClick={this.props.showReview}
                                    color='success'>
                                    {t('createSurvey.Review')}
                                </KirokuButton>
                            </div>
                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb>
                            <div className='sumary-content'>
                                <p>{t('createSurvey.Title')}</p>
                                <KirokuInput value={survey.title} onChange={this.onChangeTitle.bind(this)}/>
                                {/*<p className='pt-3'>{t('createSurvey.FormNumber')}</p>*/}
                                {/*<KirokuInput value={survey.form_number ? survey.form_number : ''} maxLength="2"*/}
                                {/*             onChange={this.onChangeFormNumber.bind(this)}*/}
                                {/*             onBlur={this.checkValidationFormNumber.bind(this)}*/}
                                {/*             error={errorFormNumber ? 'form-error' : ''}*/}
                                {/*/>*/}
                                {/*<p hidden={!errorFormNumber} style={{ fontSize : 12, color: "red"}}>{t(`staff.${errorFormNumber}`)}</p>*/}
                                <p className='pt-3'>{t('createSurvey.Description')}</p>
                                <KirokuInputArea value={survey.description}
                                                 onChange={this.onChangeDescription.bind(this)}/>
                            </div>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                </div>
                <ListQuestion/>
                <div className='pt-3'>
                    <KirokuCardWeb>
                        <KirokuCardTitleWeb color='success'>
                            <h4 className='text-dark'>{t('createSurvey.CreateQuestion', {index: survey.questions.length + 1})}</h4>
                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb>
                            <div className='sumary-content2'>
                                <p>{t('createSurvey.Heading')}</p>
                                <KirokuInput value={this.state.heading}
                                             onChange={this.onChangeHeading.bind(this)}/>
                                <p className='pt-3'>{t('createSurvey.YourQuestion')}</p>
                                <KirokuInput value={this.state.content}
                                             onChange={this.onChangeContent.bind(this)}/>
                            </div>
                            <CheckBoxTypeQuestion type={this.state.type} choices={this.state.choices}
                                                  isCreateSurvey
                                                  handleCheckType={this.handleCheckType.bind(this)}/>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                </div>

                <div className='d-flex justify-content-between pt-4'>
                    <div>
                        <KirokuButton
                            disabled={this.disabledAddQuestion()}
                            onClick={this.addQuestion.bind(this)}
                            color='primary'>
                            <i className='p-1 fas fa-plus'/>
                            <span className='pl-1'>{t('createSurvey.SaveQuestion')}</span>
                        </KirokuButton>
                    </div>
                    <div className='d-flex'>
                        <KirokuButton color='white' onClick={this.cancel.bind(this)}>
                            {t('createSurvey.Cancel')}
                        </KirokuButton>
                        <div className='pr-2'/>
                        <KirokuButton
                            style={{marginLeft: 12}}
                            onClick={this.handleSave.bind(this)}
                            color='success'
                            disabled={this.disabledSave()}
                        >
                            {nameButtonSubmit}
                        </KirokuButton>
                    </div>
                    <div/>
                </div>
            </div>
        );
    }
}

export default ComponentSurveyEditor;
