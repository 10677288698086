import React, { Component } from 'react';
import { UserAgent }        from 'react-useragent';

class RenderComponentDevice extends Component {
    render() {
        return (
            <UserAgent>
                {({ua}) => {
                    return ua.mobile ?
                           this.props.mobile :
                           this.props.web
                }}
            </UserAgent>
        );
    }
}
export default RenderComponentDevice;
