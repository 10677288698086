import React, {Component} from 'react';
import DetailSurveyMobile from './Mobile/DetailSurveyMobile';
import DetailSurveyWeb                                                from './web/DetailSurveyWeb';
import { CheckRerenderResponsive, KirokuPageLoader, KirokuTitlepage } from '../../../ui';
import {connect}                                                      from 'react-redux';
import {closeScreenDetail, getDetailSurveyById} from '../../../actions/Survey';
import {currentPage} from "../../../actions/CurrentPage";
import {withNamespaces} from "react-i18next";


@connect(state => {
    return {
        currentPage: state.KirokuAITeam.currentPage,
        detailSurvey: state.KirokuAISurvey.detailSurvey
    }
}, dispatch => {
    return {
        getDetailSurveyById: (id) => {
            dispatch(getDetailSurveyById(id))
        },
        closeScreenDetail: () => {
            dispatch(closeScreenDetail())
        },
        pageNow: (name) => {
            dispatch(currentPage(name))
        },
    }
})
@withNamespaces()
class DetailSurvey extends Component {
    page = {
        title: <KirokuTitlepage title={this.props.t('common.Surveys')}/>,
        actionPage: null
    };

    state = {
        namePage: this.page,
    };

    componentWillMount() {
        this.props.getDetailSurveyById(this.props.match.params.id)
        this.props.pageNow(this.state.namePage)
    }

    componentWillUnmount() {
        this.props.closeScreenDetail()
    }

    render() {
        const {detailSurvey} = this.props;

        return detailSurvey ? (
            <div>
                <CheckRerenderResponsive
                    mobile={<DetailSurveyMobile survey={detailSurvey}  history={this.props.history}/>}
                    web={<DetailSurveyWeb history={this.props.history} survey={detailSurvey}/>}
                />
            </div>
        ) : <KirokuPageLoader/>;
    }
}

export default DetailSurvey;
