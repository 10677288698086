import React, {Component}                      from "react";
import {withNamespaces}                        from "react-i18next";
import {connect}                               from "react-redux";
import {currentPage}                           from "../../../actions/CurrentPage";
import KirokuTitlepage                         from "../../../ui/KirokuTitlepage";
import '../dashboard.css';
import LogsDashboardMobile                     from "../mobile/LogsDashboardMobile";
import TaggedCustomers                         from "../TaggedCustomers";
import PitOutRecordMobile                      from "./PitOutRecordMobile";
import {comparisonPreviousTerm, loadDashboard} from "../../../actions/Dashboard";
import {KirokuLoading}                         from "../../../ui";

@withNamespaces()
@connect(state => {
    return {
        dashboard: state.KirokuAIDashboard.dashboard,
        loadingDashboardSuccess: state.KirokuAIDashboard.loadingDashboardSuccess,
    }
}, dispatch => {
    return {
        loadDashboard: () => {
            dispatch(loadDashboard())
        },
        currentPage: (name) => {
            dispatch(currentPage(name))
        },
        comparisonPreviousTerm: () => {
            dispatch(comparisonPreviousTerm())
        },
        customerPin: () => {
            dispatch({
                type: "GET_PIN_CUSTOMER"
            })
        }
    }
})

class TotalMobile extends Component {

    page = {
        title: <KirokuTitlepage title={this.props.t('Dashboard.Home')}/>,
        actionPage: null
    };

    state = {
        namePage: this.page
    };


    componentWillMount() {
        this.props.customerPin();
        this.props.loadDashboard();
        this.props.currentPage(this.state.namePage);
    }

    render() {
        const {customerForPin} = this.props;
        return (
            <div className='padding-responsive'>
                {this.props.loadingDashboardSuccess ?
                    <div>
                        <TaggedCustomers customers={customerForPin}/>
                        <PitOutRecordMobile/>
                        <LogsDashboardMobile dashboard={this.props.dashboard} history={this.props.history}/>
                    </div> : <KirokuLoading/>
                }
            </div>
        );
    }

}

export default TotalMobile;
