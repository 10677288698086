import React, {Component} from 'react';
import {
    KirokuInputSearchOnBlur
}                         from "../../../../ui";
import {connect}          from "react-redux";
import {withNamespaces}   from "react-i18next";

@withNamespaces()
@connect(state => {
    return {
        keyword : state.DeletedCustomer.keyword
    }
}, dispatch => {
    return {
        handleChangeKeyword : (value) => {
            dispatch({
                type : 'CHANGE_SEARCH_KEYWORD',
                keyword : value
            })
        },
        handleSearchKeyWord: () => {
            dispatch({
                type: 'SUBMIT_SEARCH_KEYWORD'
            })
        }
    }
})

class ConditionCustomerList extends Component{

    handleChangeInput(e){
        this.props.handleChangeKeyword(e.target.value);
    }

    handleSearchCustomer() {
        this.props.handleSearchKeyWord();
    }

    render() {
        const {t, keyword} = this.props;
        return (
            <div className={'font-size-condition pd-top-10 width-full'}>
                <KirokuInputSearchOnBlur
                    onSubmit={this.handleSearchCustomer.bind(this)}
                    onBlur={this.handleSearchCustomer.bind(this)}
                    onChange={this.handleChangeInput.bind(this)}
                    value={keyword}
                    placeholder={t('DeletedList.searchByName')}
                />
            </div>
        )
    }
}

export default ConditionCustomerList;
