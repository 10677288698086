import React, {Component}             from "react";
import NotificationBackupMobile       from "../../RecordEditorFiledAll/mobile/NotificationBackupMobile";
import AboutSurvey                    from "../../../ReviewRecording/AboutSurvey";
import RecordEditorNewMobile                                from "../../RecordEditorFiledAll/mobile/RecordEditorNewMobile";
import {recordService}                                      from "../../../../../services";
import {withNamespaces}                                     from "react-i18next";
import {connect}                                            from "react-redux";
import {createRecord}                                       from "../../../../../actions/Record";
import {NOTIFICATION}                                       from "../../../../../actions/Notification";
import {saveDraftRecoding}                                  from "../../../../../actions/Draft";
import moment                                               from "moment";
import {getAnswersToQuestion, getAnswerToQuestionFullLogic} from "../../../sevicesInRecord";
import {showConfirmExit}                                    from "../../../../../actions/TakeSurvey";
import ModalNotificationConfirmExit                         from "../../../RecordCreator/Mobile/ModalNoticationExit";

@withNamespaces()
@connect(state => {
    return {
        surveyTemplateOfCustomer: state.KirokuAISurvey.surveyTemplateOfCustomer,
        surveyTemplateOfCustomerBackup: state.KirokuAISurvey.surveyTemplateOfCustomerBackup,
        dataDraftRecording: state.RecordEditorNew.dataDraftRecording
    }
}, dispatch => {
    return {
        createRecord: (dataRecord) => {
            dispatch(createRecord(dataRecord))
        },
        dispatchNotificationDraft: () => {
            dispatch({
                type: NOTIFICATION,
                typeNotification: 'success',
                message: 'createRecord.SaveDraftSuccess'
            })
        },
        saveDraft: (dataDraft) => {
            dispatch(saveDraftRecoding(dataDraft))
        },
        pageTakeRecord: () => {
            dispatch({
                type: 'TAKE_RECORD_PAGE'
            })
        },
        showConfirmExit: () => {
            dispatch(showConfirmExit())
        },
        trackingTakeRecord : (record) => {
            dispatch({
                type : "RECORD_CREATED_TRACKING",
                record
            });
        },
        trackingDraftRecord : (record) => {
            dispatch({
                type : "NEW_RECORD_DRAFTED_TRACKING",
                record
            });
        },
    }
})
class RecordCreatorFiledAllMobile extends Component {

    state = {
        keyLoadData: null,  //keyLoadData: null(loadRecord) 'statusDraft'(loadDraft) 'backup'(loadDataBackup)
        serviced_at: null,
        has_time: false
    };

    handleCancel() {
        // this.props.history.goBack();
        this.props.showConfirmExit();
    }

    handleSaveStatusRecord(flowedQuestion) {
        const {surveyTemplateOfCustomer} = this.props;
        const surveyTemplate             = {
            ...surveyTemplateOfCustomer.survey,
            questions: getAnswerToQuestionFullLogic(flowedQuestion, surveyTemplateOfCustomer.survey.questions).map(question => {delete question.visibility; return question}),
            flowedQuestions: flowedQuestion.map(question => {delete question.visibility; return question}),
        };

        const statusDataRecord = {
            surveyTemplate: surveyTemplate,
            customer: surveyTemplateOfCustomer.customer,
            hasDraft: 'draft',
            draftBy: JSON.parse(localStorage.getItem('profile')).id,
            draftedAt: moment().unix(),
            serviced_at: this.state.serviced_at,
            has_time: this.state.has_time,
            answers  : getAnswersToQuestion(flowedQuestion)
        };
        recordService.createSurveyRecord(statusDataRecord).then(res => {
            this.props.trackingDraftRecord(statusDataRecord);
            this.props.dispatchNotificationDraft();
            window.history.previous = window.location.pathname;
            this.props.pageTakeRecord();
            this.props.history.push(`/records/edit-filedAll/${res.data.id}`);
        }).catch(error => console.log(error));
    }

    handleSubmitRecord(flowedQuestion) {
        const {surveyTemplateOfCustomer} = this.props;
        const surveyTemplate             = {
            ...surveyTemplateOfCustomer.survey,
            questions: getAnswerToQuestionFullLogic(flowedQuestion, surveyTemplateOfCustomer.survey.questions).map(question => {delete question.visibility; return question}),
            flowedQuestions: flowedQuestion.map(question => {delete question.visibility; return question}),
        };

        const dataRecord = {
            surveyTemplate: surveyTemplate,
            customer: surveyTemplateOfCustomer.customer,
            hasDraft: null,
            draftBy: null,
            draftedAt: null,
            serviced_at: this.state.serviced_at,
            has_time: this.state.has_time,
            answers: getAnswersToQuestion(flowedQuestion)
        };
        this.props.createRecord(dataRecord);
        this.props.trackingTakeRecord(dataRecord);
    }

    confirmLoadBackupDraft() {
        this.setState({
            keyLoadData: 'backup'
        })
    }

    saveBackupDataRecord(dataBackup) {
        const {surveyTemplateOfCustomer} = this.props;
        const requestSaveDraft           = {
            customer: surveyTemplateOfCustomer.customer,
            // survey: surveyTemplateOfCustomer.survey,
            dataDraft: dataBackup,
            create_at: moment().unix()
        };
        this.props.saveDraft(requestSaveDraft);
    }

    onChangeDate(serviced_at, hasTime) {
        this.setState({
            serviced_at: hasTime ? serviced_at : moment.unix(serviced_at).startOf('day').unix(),
            has_time: hasTime
        })
    }

    render() {
        const {surveyTemplateOfCustomer, dataDraftRecording, t} = this.props;
        const {keyLoadData}                                  = this.state;
        return (
            <div className='default-background-color pb-5'>
                <div className="keeping-about-survey" style={{top: 0, backgroundColor: '#FFFFFF'}}>
                    <div className='fix-center pl-2 pr-2'>
                        <NotificationBackupMobile
                            confirm={this.confirmLoadBackupDraft.bind(this)}
                            cancel={() => {
                            }}
                            draft={window.history.previous === "/records/new-create-record" ? null : dataDraftRecording}
                        />
                    </div>
                    <div >
                        <AboutSurvey onChangeDate={this.onChangeDate.bind(this)}
                                     screen={'takeRecord'}
                                     isMobile
                                     hasTime={false}
                                     survey={surveyTemplateOfCustomer.survey}
                                     customer={surveyTemplateOfCustomer.customer} isRecord/>
                    </div>
                </div>
                <div className="survey" style={{backgroundColor: '#FFFFFF'}}>
                    <div className='wraper-question' >
                        <div className='wraper-question'>
                            <div className="background-question-review">
                                <div className='info-question'>
                                    <div className='content-name-question d-flex'>
                                        <span style={{ wordBreak: 'keep-all' }}
                                              className='title-record-info'>{t('createRecord.Description')}&nbsp;</span>
                                        <pre style={{
                                            wordWrap  : 'break-word',
                                            whiteSpace: 'pre-wrap',
                                        }}>
                                            {surveyTemplateOfCustomer.survey.description}
                                        </pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RecordEditorNewMobile
                    recordToEdit={{survey_template: {...surveyTemplateOfCustomer.survey}}}
                    updateRecord={this.handleSubmitRecord.bind(this)}
                    saveStatusRecord={this.handleSaveStatusRecord.bind(this)}
                    // saveBackupDataRecord={this.saveBackupDataRecord.bind(this)}
                    saveBackupDataRecord={() => {}}
                    statusRecord={{
                        statusDraft: surveyTemplateOfCustomer.statusRecord,
                        backup: dataDraftRecording
                    }}
                    keyLoadData={keyLoadData}
                    handleCancel={this.handleCancel.bind(this)}

                />
                <ModalNotificationConfirmExit history={this.props.history}
                                              customerId={surveyTemplateOfCustomer.customer_id}/>
            </div>
        )
    }
}

export default RecordCreatorFiledAllMobile;
