import React, {Component}    from 'react';
import { SortableContainer } from 'react-sortable-hoc';

@SortableContainer
class KirokuSortableContainer extends Component {

    render() {
        return (
            <div {...this.props}>
                {this.props.children}
            </div>
        )
    }
}
export default KirokuSortableContainer;
