import React, {Component}  from 'react';
import KirokuCard          from "../../../../ui/KirokuCard";
import KirokuPopoverItem   from "../../../../ui/KirokuPopoverItem";
import {Link}              from "react-router-dom";
import KirokuCardList      from "../../../../ui/KirokuCardList";
import {withNamespaces}    from "react-i18next";
import {scrollService}     from "../../../../services";
import {connect}           from "react-redux";
import KirokuButton        from "../../../../ui/KirokuButton";
import KirokuInputSearch   from "../../../../ui/KirokuInputSearch";
import KirokuCardAction    from "../../../../ui/KirokuCardAction";
import KirokuDropdownClose from "../../../../ui/KirokuDropdownClose";
import KirokuPagination    from "../../../../ui/KirokuPagination";
import {
    KirokuLoading,
    KirokuTitlepage,
    KirokuSectionDropdownShowMoreMobile
}                          from "../../../../ui";
import ModalSelectStaff                 from "./ModalSelectStaff";
import ModalDeleteStaff                 from "./ModalDeleteStaff";
import ModalFilterStaff                 from "./ModalFilterStaff";
import {currentPage}                    from "../../../../actions/CurrentPage";
import debounce                         from 'lodash/debounce';
import KirokuLabel                      from "../../../../ui/KirokuLabel";
import {currentCondition}               from "../../../../actions/Behavior";
import _                                from 'lodash'

import withPermissions                     from "../../../HOC/WithPermissions";
import ButtonComponent                     from "../../../HOC/ButtonComponent";
import SpanComponent                       from "../../../HOC/SpanComponent";
import ModalSetRole                        from "./ModalSetRole";
import {loadUserPagination}                from "../../../../actions/User";
import LinkComponent                       from "../../../HOC/LinkComponent";

const LinkButton          = withPermissions(ButtonComponent);
const BulkActionStaffList = withPermissions(KirokuDropdownClose);
const Span                = withPermissions(SpanComponent);
const LinkKiroku          = withPermissions(LinkComponent);

class Action extends Component {

    constructor(props) {
        super(props);

        this.handleClick        = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            popupVisible: false
        };
    }

    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
    }

    handleOutsideClick(e) {
        if (!this.node) {
            return;
        }
        this.handleClick();
    }

    render() {
        return (
            <div className="action-customer-surveys" ref={node => {
                this.node = node
            }}>
                <div hidden={this.props.hidden}>
                    <KirokuCardAction surveyId={this.props.surveyId}>
                        <div className="action-icon">
                            <i style={{color: '#1991eb'}} className="fas fa-ellipsis-h"
                               onClick={this.handleClick.bind(this)}/>
                        </div>
                        {this.state.popupVisible && (
                            <div onClick={this.handleClick.bind(this)}>
                                {this.props.children}
                            </div>
                        )}
                    </KirokuCardAction>
                </div>
            </div>
        )
    }
}

const ActionStaffList = withPermissions(Action);

@withNamespaces()
@connect(state => {
    return {
        profile: state.KirokuAIUser.profile,
        loading: state.Loading,
        currentConditions: state.KirokuAIBehavior.conditions,
        usersForPagination: state.KirokuAIUser.usersForPagination,
        users: state.KirokuAIUser.users,
        totalPage: state.KirokuAIUser.totalPage,
        currentPage: state.KirokuAIUser.currentPage,
        defaultCustomerTags: state.KirokuAIUser.defaultCustomerTags,
        defaultTeamTags   : state.KirokuAIUser.defaultTeamTags,
    }
}, (dispatch) => {
    return {
        pageNow: (name) => {
            dispatch(currentPage(name))
        },
        currentCondition: (cdt) => {
            dispatch(currentCondition(cdt))
        },
        saveConditions: conditions => {
            dispatch({
                type: 'CURRENT_CONDITION_SAVE',
                conditions
            })
        },
        loadUserPagination: (defaultPage, tags) => {
            dispatch(loadUserPagination(defaultPage, tags))
        },
    };
})
class StaffListMobile extends Component {

    page  = {
        title: <KirokuTitlepage title={this.props.t('common.Staffs')}/>,
        actionPage: null
    };
    state = {
        role: '',
        showModalSetRole: false,
        showModalFilter: true,
        show: false,
        showModalDelete: false,
        showModal: true,
        showModalSelectStaff: false,
        name: '',
        status: '',
        teamid: '',
        pageMin: 1,
        currentPage: 1,
        totalPage: 1,
        userSelected: {},
        usersForPaginationRecord: [],
        namePage: this.page,
        selected: false,
        usersForPagination: [],
        showModalDeleteUser: false,
        disabledPrev: true,
        disabledNext: false,
        params: {
            page: 1
        },
        teams: [],
        changCondition: false,
        conditions: [],
        keywordSearch: {
            value: '',
            query: 'name',
            label: ''
        },
        groupedCustomerOptions : [],
        groupedTeamOptions     : [],
        userForSearch : [],
        currentCustomerTags: [],
        currentTeamTags    : [],
        defaultTags   : [],
    };

    bulkAction = [
        {
            value: 'leader',
            component: (<div>{this.props.t('Team.SetLeader')}</div>),
            label: this.props.t('Team.SetLeader')
        },
        {
            value: 'staff',
            component: (<div>{this.props.t('Team.SetStaff')}</div>),
            label: this.props.t('Team.SetStaff')
        },
        {
            value: 'assign',
            component: (<div>{this.props.t('common.AssignToTeam')}</div>),
            label: this.props.t('common.AssignToTeam')
        }
    ];

    makeCondition(conditions) {
        this.setState({
            conditions
        });
        let params = this.state.params;
        conditions.map(condition => {
            params[condition.query] = condition.value;
            return condition
        });
        this.setState({
            params: params
        }, () => {
            // this.service(params);
            this.filter();
        });
    }

    filter() {
        let currentTags =  this.state.currentCustomerTags.concat(this.state.currentTeamTags);
        this.props.loadUserPagination(this.state.params, {tags: currentTags});
    }
    componentWillMount() {
        this.setState({
            groupedCustomerOptions: [
                {
                    label  : 'Customers',
                    options: this.props.customers
                }
            ],
            groupedTeamOptions: [
                {
                    label  : 'Customers',
                    options: this.props.teams
                }
            ]
        });
        const {users} = this.props;
        this.setState({
            userForSearch: users.map(item => ({
                ...item,
                isChecked: false,
                _pivot_role: 'staff'
            })),
            userForSearchRecord: users.map(item => ({
                ...item,
                isChecked: false,
                _pivot_role: 'staff'
            }))
        });

        if (this.props.currentConditions.length) {
            scrollService.top();
            let currentTags =  this.state.currentCustomerTags.concat(this.state.currentTeamTags);
            this.setState({
                currentCustomerTags : this.props.defaultCustomerTags ? this.props.defaultCustomerTags : [],
                currentTeamTags : this.props.defaultTeamTags ? this.props.defaultTeamTags : [],
            }, () => {
                this.props.currentCondition(this.props.currentConditions, {tags: currentTags});
                this.makeCondition(this.props.currentConditions);
                this.props.pageNow(this.state.namePage);
            });
        } else {
            scrollService.top();
            this.setState({
                currentCustomerTags : this.props.defaultCustomerTags ? this.props.defaultCustomerTags : [],
                currentTeamTags : this.props.defaultTeamTags ? this.props.defaultTeamTags : [],
            }, () => {
                this.handleReloadPage();
                this.props.pageNow(this.state.namePage);
            });
        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.usersForPagination) {
            if (!nextProps.currentConditions.length) {
                this.setState({
                    params     : {
                        page: 1
                    }
                })
            }
            this.setState({
                usersForPagination: nextProps.usersForPagination,
                conditions        : nextProps.currentConditions,
                currentPage       : nextProps.currentPage,
                totalPage         : nextProps.totalPage,
            });
            if ((nextProps.defaultCustomerTags !== this.props.defaultCustomerTags) || (nextProps.defaultTeamTags !== this.props.defaultTeamTags)) {
                this.setState({
                    currentCustomerTags: nextProps.defaultCustomerTags,
                    currentTeamTags: nextProps.defaultTeamTags
                })

            }
        }
    }

    closeModal() {
        this.setState({showModalSelectStaff: false})
    }

    handleReloadPage(){
        let {...currentState} = this.state;
        let currentTags =  this.state.currentCustomerTags.concat(this.state.currentTeamTags);
        this.props.loadUserPagination(currentState, {tags : currentTags})
    }

    handleSearch(value) {
        let keywordCondition = {
            value: value,
            query: 'name',
            label: ''
        };
        this.setState({
            keywordSearch: keywordCondition,
            params: {
                ...this.state.params,
                name: value || '',
                page: 1
            },
            currentPage: 1
        }, () => {
            this.props.saveConditions([...this.props.currentConditions, keywordCondition]);
            // this.service(this.state.params);
            this.filter();
        })
    }

    handleDeleteUser = (user) => {
        this.setState({
            showModalDeleteUser: !this.state.showModalDeleteUser,
            userSelected: {
                name: user.name,
                id: user.id,
            }
        })
    };


    handleSelectPage() {
        let makeConditionPage = {value: this.state.params.page, query: 'page', label: ''};
        this.props.currentCondition([...this.props.currentConditions, makeConditionPage]);
        // this.service(this.state.params)
        this.setState({params: {...this.state.params, page: this.state.params.page}}, () => {
            this.filter()
        });
    }

    increasePage() {
        let nextPage = this.state.currentPage === this.state.totalPage ? this.state.totalPage : this.state.currentPage + 1;
        scrollService.top();
        this.setState({
            currentPage: nextPage,
            params: {
                ...this.state.params,
                page: nextPage
            },
            totalPage: this.state.totalPage,
        }, () => this.handleSelectPage());
    }

    decreasePage() {
        let previousPage = this.state.currentPage === this.state.pageMin ? 1 : this.state.currentPage - 1;
        scrollService.top();
        this.setState({
            currentPage: previousPage,
            totalPage: this.state.totalPage,
            params: {
                ...this.state.params,
                page: previousPage
            },
        }, () => {
            this.handleSelectPage()
        });
    }

    closeModalDeleteUser() {
        this.setState({
            showModalDeleteUser: !this.state.showModalDeleteUser
        })
    }

    loadUser(page) {
        this.setState({
            params: {
                ...this.state.params,
                page: page
            }
        }, () => {
            this.filter()
        })
    }

    handleShowModal() {
        this.setState({
            showModalFilter: true
        })
    }

    showModalFilter() {
        this.setState({
            showModalFilter: !this.state.showModalFilter
        })
    }

    onChangeDebounce = debounce(value => {
        this.handleSearch(value)
    }, 500);

    filterCondition(conditions) {
        let defaultPage = {value: 1, query: 'page', label: ''};
        this.setState({
            params : {
                page : 1
            }
        }, () => {
            this.makeCondition([...conditions, defaultPage]);
            this.props.saveConditions([...conditions, this.state.keywordSearch])
        });

    }

    handleRemoveCondition(condition) {
        let newCondition = this.state.conditions.filter(item => item.value !== condition.value);
        let params       = {};
        newCondition.map(condition => {
            params[condition.query] = condition.value;
            return condition
        });
        this.setState({
            conditions: newCondition,
            changCondition: true,
            params: {
                ...this.state.params,
                ...params,
                [condition.query]: '',
                page: 1
            }
        }, () => {
            this.filter();
            this.props.currentCondition(newCondition)
        });
    }

    closeModalSetRole() {
        this.setState({
            showModalSetRole: false
        })
    }

    showModalSetRole() {
        this.setState({
            showModalSetRole: !this.state.showModalSetRole
        })
    }

    newUser(users) {
        this.setState({
            userForSearch: users
        })
    }

    userPage(users) {
        this.setState({
            usersForPagination: users
        })
    }

    checkedUser(userCompare) {
        let userForSearch = this.state.userForSearch.map(user => {
            if (user.id === userCompare.id) {
                return {
                    ...user,
                    isChecked: !userCompare.isChecked
                }
            }
            return user;
        });

        let userForSearchRecord = this.state.userForSearchRecord.map(user => {


            if (user.id === userCompare.id) {
                return {
                    ...user,
                    isChecked: !userCompare.isChecked
                }
            }
            return user;
        });

        this.setState({
            userForSearchRecord: userForSearchRecord,
            userForSearch: userForSearch
        })
    }


    make(usersRecord, usersCompare, status) {
        return usersRecord.map(user => {
            return {
                ...user,
                isChecked : (_.findIndex(usersCompare, (item) => user.id ===item.id ) >= 0) ? status : user.isChecked
            }
        })
    }

    checkAllUser(usersCompare, status) {
        this.setState({
            userForSearchRecord: this.make(this.state.userForSearchRecord, usersCompare, status),
            userForSearch: this.make(this.state.userForSearch, usersCompare, status)
        })
    }

    handleChangeCustomerTags = (options) => {
        this.setState({currentCustomerTags: options ? options : []})
    };

    handleChangeTeamTags = (options) => {
        this.setState({currentTeamTags: options ? options : []})
    };

    handleSearchTags = () => {
        let currentTags = this.state.currentCustomerTags.concat(this.state.currentTeamTags);
        if (currentTags.length) {
            let conditionTags = currentTags.map(tag => {
                return {
                    ...tag,
                    key    : tag.id,
                    label  : tag.name,
                    value: `${tag.name}${tag.id}`,
                    tagId  : tag.id || tag.key,
                    tagType: tag.tagType
                }
            });
            this.setState({
                currentPage: 1
            });
            this.props.loadUserPagination({...this.state.params, page: 1}, {tags: conditionTags});
        } else {
            this.setState({
                params: {
                    ...this.state.params,
                    page: 1
                }
            }, () => {
                this.filter();
            })
        }
        this.props.saveConditions([...this.props.currentConditions, {
            value: 1,
            query: 'page',
            label: ''
        }]);
    };

    render() {
        let {t}                              = this.props;
        let {usersForPagination, conditions, params, currentCustomerTags, currentTeamTags} = this.state;
        let currentTags = currentCustomerTags.concat(currentTeamTags);
        return (
            <div className='survey-list default-background-color'>
                <ModalFilterStaff
                    show={this.state.showModalFilter}
                    showModal={this.handleShowModal.bind(this)}
                    filterCondition={this.filterCondition.bind(this)}
                />
                <div hidden={!this.state.showModalFilter} className='padding-responsive'>
                    <div hidden={this.state.showSurveyTemplate}>
                        <div className='d-flex justify-content-between '>
                            <div>
                                <KirokuButton color={'white'} onClick={this.showModalFilter.bind(this)}>
                                    <span className={'pr-2'}>{t('Surveys.FilterBy')}</span>
                                    <i className='fa fa-chevron-down text-secondary'
                                       style={{fontSize: '10px'}}/>
                                </KirokuButton>
                            </div>
                            <div>
                                <Link to={'/staffs/staff-creator'}>
                                    <LinkButton
                                        resource-permission={'|staffList|button-new|'}
                                        color={'primary'}>
                                        {t('common.AddStaff')}
                                    </LinkButton>
                                </Link>
                            </div>
                        </div>

                        <div className='mt-10'>
                            {
                                conditions.map((condition, index) => {
                                    return condition.label.length ? <span key={index} className='mr-6'>
                                            <KirokuLabel
                                                style={{
                                                    border: 'solid 1px #1991eb'
                                                }}
                                                onClick={this.handleRemoveCondition.bind(this, condition)}
                                                hidden={!condition.label.length}
                                                color={'default'}>
                                                <span className='mt-1'>{condition.label}</span>
                                                <span className='align-icon'>&times;</span>
                                            </KirokuLabel>
                                    </span> : ''
                                })
                            }


                        </div>
                        <div className={'pb-2'}>
                            <KirokuSectionDropdownShowMoreMobile
                                isSearchTags
                                tagType={'Team'}
                                onChange={this.handleChangeTeamTags.bind(this)}
                                value={this.state.currentTeamTags || []}
                                data={this.state.groupedTeamOptions}
                                options={this.state.groupedTeamOptions}
                                handleSearchTags={this.handleSearchTags.bind(this)}
                                placeholder={t('Team.placeholderSearch')}
                            />

                        </div>
                        <div>
                            <KirokuSectionDropdownShowMoreMobile
                                isSearchTags
                                tagType={'Customer'}
                                onChange={this.handleChangeCustomerTags.bind(this)}
                                value={this.state.currentCustomerTags || []}
                                data={this.state.groupedCustomerOptions}
                                options={this.state.groupedCustomerOptions}
                                handleSearchTags={this.handleSearchTags.bind(this)}
                                placeholder={t('Customer.placeholderSearch')}
                            />

                        </div>
                        <div className='mt-10'>
                            <KirokuInputSearch
                                value={this.state.params.name ? this.state.params.name : ''}
                                onChange={e =>
                                    this.setState({
                                        params: {
                                            ...this.state.params,
                                            name: e.target.value,
                                            page: 1
                                        }
                                    }, () => {
                                        this.onChangeDebounce(this.state.params.name)
                                    })}
                                placeholder={t('common.Search')}/>
                        </div>
                        <div hidden={!usersForPagination.length}>
                            <div className='pt-2 d-flex justify-content-between'>
                                <BulkActionStaffList
                                    title={t('Surveys.BulkAction')}
                                    style={{minWidth: 130}}
                                    location={'bot'}
                                    items={this.bulkAction}
                                    onSelect={event => {
                                        if (event.kirokuSelected.value === 'assign') {
                                            this.setState({
                                                showModalSelectStaff: !this.state.showModalSelectStaff
                                            })
                                        } else if (event.kirokuSelected.value === 'leader') {
                                            this.setState({
                                                role: 'leader'
                                            });
                                            this.showModalSetRole()
                                        } else {
                                            this.setState({
                                                role: 'staff'
                                            });
                                            this.showModalSetRole()
                                        }
                                    }}
                                    resource-permission={'|staffList|button-bulkAction|'}
                                />
                                <div/>
                                <KirokuPagination
                                    className={'pb-2'}
                                    hidden={this.props.currentPage === 0}
                                    disabledPrev={this.state.currentPage === 1}
                                    disabledNext={this.state.currentPage === this.state.totalPage}
                                    increasePage={this.increasePage.bind(this)}
                                    decreasePage={this.decreasePage.bind(this)}
                                    currentPage={this.state.currentPage}
                                    totalPage={this.state.totalPage}
                                />
                            </div>
                        </div>
                        <div className='pt-10'>
                            <KirokuCardList
                                headerLeft={t('common.Staff')}
                                headerRight={<Span
                                    resource-permission={'|staffList|button-edit|'}>{t('common.Action')}</Span>}
                            >
                                {!this.props.loading ?
                                    <div>
                                        {usersForPagination.map(user =>
                                            <KirokuCard key={user.id} action={
                                                <ActionStaffList
                                                    resource-permission={'|staffList|button-edit|'}
                                                    surveyId={user.id}>
                                                    <KirokuPopoverItem>
                                                        <div
                                                            className='d-flex justify-content-between p-1 fix-icon-align'>
                                                            <Link
                                                                to={`/staffs/staff-editor/${user.id}`}>
                                                                <i className="fas fa-pencil-alt"/><span
                                                                className='ml-1'>{t('common.Edit')}</span>
                                                            </Link>
                                                            <LinkKiroku
                                                                resource-permission={'|staffList|button-duplicate|'}
                                                                href={`/staffs/staff-duplicate/${user.id}`}
                                                                text={t('staff.duplicateStaff')}
                                                                icon={<i className="fas fa-clone"/>}
                                                            />
                                                            <div style={{cursor: 'pointer'}}
                                                                 onClick={() => this.handleDeleteUser(user)}>
                                                                <i className="far fa-trash-alt"/><span
                                                                className='ml-1'>{t('common.Delete')}</span>
                                                            </div>
                                                        </div>
                                                    </KirokuPopoverItem>
                                                </ActionStaffList>}>
                                                <div>
                                                    <Link to={`/staffs/staff/${user.id}`}><span>{user.name}</span></Link>
                                                    <KirokuLabel color='light'
                                                                 style={{
                                                                     padding: '2px 4px',
                                                                     marginLeft: '.5rem'
                                                                 }}>{this.props.t(`common.${user.role}`)}
                                                    </KirokuLabel>
                                                </div>

                                                <span className='mr-2'>{user.title}</span>
                                            </KirokuCard>)}
                                    </div> : <KirokuLoading/>}
                            </KirokuCardList>
                        </div>
                    </div>
                    <div className='pt-10 d-flex justify-content-between'>
                        <BulkActionStaffList
                            title={t('Surveys.BulkAction')}
                            style={{minWidth: 130}}
                            location={'top'}
                            items={this.bulkAction}
                            onSelect={event => {
                                if (event.kirokuSelected.value === 'assign') {
                                    this.setState({
                                        showModalSelectStaff: !this.state.showModalSelectStaff
                                    })
                                } else if (event.kirokuSelected.value === 'leader') {
                                    this.setState({
                                        role: 'leader'
                                    });
                                    this.showModalSetRole()
                                } else {
                                    this.setState({
                                        role: 'staff'
                                    });
                                    this.showModalSetRole()
                                }
                            }}
                            resource-permission={'|staffList|button-bulkAction|'}
                        />
                        <div/>
                        <KirokuPagination
                            hidden={this.props.currentPage === 0}
                            disabledPrev={this.state.pageMin === this.state.currentPage}
                            disabledNext={this.state.currentPage === this.state.totalPage}
                            increasePage={this.increasePage.bind(this)}
                            decreasePage={this.decreasePage.bind(this)}
                            currentPage={this.state.currentPage}
                            totalPage={this.state.totalPage}
                        />
                    </div>
                    <ModalSelectStaff
                        params={params}
                        tags={currentTags}
                        closeModal={this.closeModal.bind(this)}
                        show={this.state.showModalSelectStaff}
                    />

                    <ModalDeleteStaff
                        closeModalDeleteUser={this.closeModalDeleteUser.bind(this)}
                        show={this.state.showModalDeleteUser}
                        users={this.state.userSelected}
                        loadUser={this.loadUser.bind(this)}
                    />

                    <ModalSetRole
                        checkAllUser={this.checkAllUser.bind(this)}
                        checkedUser={this.checkedUser.bind(this)}
                        userPage={this.userPage.bind(this)}
                        role={this.state.role}
                        newUser={this.newUser.bind(this)}
                        userForSearchRecord={this.state.userForSearchRecord}
                        userForSearch={this.state.userForSearch}
                        show={this.state.showModalSetRole}
                        closeModalSetRole={this.closeModalSetRole.bind(this)}
                    />
                </div>
            </div>
        )
    }
}

export default StaffListMobile;

