import React, {Component} from 'react';
import {withNamespaces} from "react-i18next";
import {KirokuButton, KirokuCardWeb, KirokuLoading, SearchNotFound} from "../../ui";
import SearchComponents from "./SearchComponents";
import {connect} from "react-redux";
import {SEARCH_REPORTS, CHANGE_TEMPLATE_REPORTS, PRINT_REPORTS} from '../../actions/Reports';
import {TemplateRecord, TemplateQuestion, TemplateOnly, CustomerRecord, CustomerQuestion, CustomerOnly} from './TemplatesReport'

@withNamespaces()
@connect(state => {
    return {
        loading: state.Loading,
        allTeam: state.KirokuAITeam.allTeam,
        listAllCustomer: state.KirokuAICustomer.ListAllCustomer,
        listAllSurveyTemplate: state.KirokuAISurvey.ListAllSurveyTemplate,
        conditions: state.Reports.conditions,
        reports: state.Reports.reports,
        tabActive: state.Reports.tab.isActive,
    }
}, dispatch => {
    return {
        getTemplateRecord: (condition) => {
            dispatch({
                type: SEARCH_REPORTS,
                condition
            })
        },
        ChangeTemplates: (template) => {
            dispatch({
                type: CHANGE_TEMPLATE_REPORTS,
                template
            })
        },
        printReports: (condition, template) => {
            dispatch({
                type: PRINT_REPORTS,
                condition,
                template
            })
        }
    }
})
class ReportsWeb extends Component {

    static getConditions(conditions) {
        return {
            serviceAt: {
                startDate: conditions.serviceAt.momentStartDate,
                endDate: conditions.serviceAt.momentEndDate,
            },
            customerIds: [...conditions.currentCustomers].map(ctm => ctm.customerId),
            templateIds: [...conditions.currentTemplate].map(sur => sur.surveyId),
        }
    }

    submitHandler() {
        const {conditions} = this.props;
        const conditionQuery = ReportsWeb.getConditions(conditions);
        this.props.getTemplateRecord(conditionQuery);
    }

     onChangeTemplates(template) {
         this.props.ChangeTemplates(template);
    }

    static renderTemplateReports(tabActive, reports, t) {
        switch (tabActive) {
            case "TemplateRecord":
                 return <TemplateRecord reports={reports.TemplateRecord}/>;
            case "TemplateQuestion":
                return <TemplateQuestion reports={reports.TemplateQuestion}/>;
            case "TemplateOnly":
                return <TemplateOnly reports={reports.TemplateOnly}/>;
            case "CustomerRecord":
                return <CustomerRecord reports={reports.CustomerRecord}/>;
            case "CustomerQuestion":
                return <CustomerQuestion reports={reports.CustomerQuestion}/>;
            case "CustomerOnly":
                return <CustomerOnly reports={reports.CustomerOnly}/>;
            default:
                return (
                    <SearchNotFound
                        exportAdmin
                        message={t('Export.showResultSearch')}
                        description={t('Export.TrySearch')}
                    />)
        }
    }

    handlerPrint() {
        const {conditions} = this.props;
        const conditionQuery = ReportsWeb.getConditions(conditions);
        this.props.printReports(conditionQuery, conditions.template);
    }


    render() {
        const {listAllCustomer, listAllSurveyTemplate, allTeam, reports, loading, t, tabActive} = this.props;

        function isValues() {
            switch (tabActive) {
                case "TemplateRecord":
                case "TemplateQuestion":
                case "TemplateOnly":
                    const template = reports[`${tabActive}`]["templates"] || [];
                    return !!template.length;
                case "CustomerRecord":
                case "CustomerQuestion":
                case "CustomerOnly":
                    const customers = reports[`${tabActive}`]["customers"] || [];
                    return !!customers.length;
                default:
                    return false
            }
        }

        return (
            <div className='survey-list default-background-color'>
                <div className='padding-responsive staff-detail'>
                    <KirokuCardWeb>
                        <div className='pt-0 padding-responsive'>
                            <SearchComponents
                                listAllCustomer={listAllCustomer}
                                listAllSurveyTemplate={listAllSurveyTemplate}
                                allTeam={allTeam}
                                submit={this.submitHandler.bind(this)}
                            />
                        </div>
                    </KirokuCardWeb>
                </div>
                {!loading ? (
                    <div>
                        <div>
                            <div className='padding-responsive staff-detail'>
                                <div className='col-md-12' style={{borderBottom: 'solid 1px #dfe3e9',  backgroundColor: "#ffffff" }}>
                                    <div className='d-flex justify-content-between pt-3'>
                                        <div style={{cursor: 'pointer'}}
                                             className={`nav-bar text-center ${tabActive === "TemplateRecord" ? "active bg-white" : ""}`}
                                             onClick={this.onChangeTemplates.bind(this, 'TemplateRecord')}> {t('Report.TemplateRecord')}
                                        </div>
                                        <div style={{cursor: 'pointer'}}
                                             className={`nav-bar text-center ${tabActive === "TemplateQuestion" ? "active bg-white" : ""}`}
                                             onClick={this.onChangeTemplates.bind(this, 'TemplateQuestion')}>{t('Report.TemplateQuestion')}
                                        </div>
                                        <div style={{cursor: 'pointer'}}
                                             className={`nav-bar text-center ${tabActive === "TemplateOnly" ? "active bg-white" : ""}`}
                                             onClick={this.onChangeTemplates.bind(this, 'TemplateOnly')}>{t('Report.TemplateOnly')}
                                        </div>
                                        <div style={{cursor: 'pointer'}}
                                             className={`nav-bar text-center ${tabActive === "CustomerRecord" ? "active bg-white" : ""}`}
                                             onClick={this.onChangeTemplates.bind(this, 'CustomerRecord')}>{t('Report.CustomerRecord')}
                                        </div>
                                        <div style={{cursor: 'pointer'}}
                                             className={`nav-bar text-center ${tabActive === "CustomerQuestion" ? "active bg-white" : ""}`}
                                             onClick={this.onChangeTemplates.bind(this, 'CustomerQuestion')}>{t('Report.CustomerQuestion')}
                                        </div>
                                        <div style={{cursor: 'pointer'}}
                                             className={`nav-bar text-center ${tabActive === "CustomerOnly" ? "active bg-white" : ""}`}
                                             onClick={this.onChangeTemplates.bind(this, 'CustomerOnly')}>{t('Report.CustomerOnly')}
                                        </div>
                                    </div>
                                </div>
                                <KirokuCardWeb>
                                    {isValues() ? (
                                        <div>
                                            <div>
                                                <div className="d-flex pt-2 justify-content-end pr-10">
                                                    <div className="p-2"/>
                                                    <KirokuButton color={'primary'}
                                                                  onClick={this.handlerPrint.bind(this)}>
                                                        {t('common.print')}
                                                    </KirokuButton>
                                                </div>
                                            </div>
                                            {ReportsWeb.renderTemplateReports(tabActive, reports, t)}
                                        </div>
                                    ) : (
                                        <SearchNotFound
                                            exportAdmin
                                            message={t('Export.showResultSearch')}
                                            description={t('Export.TrySearch')}
                                        />
                                    )}
                                </KirokuCardWeb>
                            </div>
                        </div>
                    </div>
                ) : <KirokuLoading/>}
            </div>
        );
    }
}

export default ReportsWeb
