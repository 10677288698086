import {CREATE_QUESTION, FOCUS_QUESTION} from "../actions/SurveyTemplate";

export const questionMiddleware = (store) => next => action => {
    switch (action.type) {
        case CREATE_QUESTION :
            store.dispatch({
                type : FOCUS_QUESTION,
                index : action.question.index
            });
            return next(action);
        default :
            return next(action);
    }
};