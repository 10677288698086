export const LOAD_TEAM             = "LOAD_TEAM";
export const RESET_TEAM            = "RESET_TEAM";
export const GET_DETAIL_TEAM       = "GET_DETAIL_TEAM";
export const CREATE_TEAM           = "CREATE_TEAM";
export const EDIT_TEAM             = "EDIT_TEAM";
export const LOAD_TEAM_FOR_PAGE    = "LOAD_TEAM_FOR_PAGE";
export const CHECK_ALL_TEAM        = "CHECK_ALL_TEAM";
export const SELECT_TEAM           = "SELECT_TEAM";
export const SEARCH_TEAM           = "SEARCH_TEAM";
export const SEARCH_TEAM_PAGE_USER = "SEARCH_TEAM_PAGE_USER";
export const SAVE_CONDITIONS_TEAM  = "SAVE_CONDITIONS_TEAM";
export const RESET_CONDITIONS_TEAM  = "RESET_CONDITIONS_TEAM";
export const GET_TEAMS_BETA         = "GET_TEAMS_BETA";


export function selectTeamPageUser(teamId) {
    return {
        type: SEARCH_TEAM_PAGE_USER,
        teamId: teamId
    }
}

export function loadTeam() {
    return {
        type: LOAD_TEAM,
        loginRequired: true,
    }
}
export function getTeamsBeta() {
    return {
        type: GET_TEAMS_BETA,
    }
}

export function loadTeamsForPage(params) {
    return {
        type: LOAD_TEAM_FOR_PAGE,
        params
    }
}

export function onSearch(keywords) {
    return {
        type: SEARCH_TEAM,
        keywords: keywords
    }
}

export function resetTeam() {
    return {
        type: RESET_TEAM,
        loginRequired: true
    }
}

export function getDetailTeam(teamId) {
    return {
        type: GET_DETAIL_TEAM,
        teamId
    }
}

export function createTeam(team) {
    return {
        type: CREATE_TEAM,
        team: team
    }
}

export function editTeam(teamId, team) {
    return {
        type: EDIT_TEAM,
        team: team,
        teamId: teamId
    }
}

export function checkAllTeam(checked) {
    return {
        type: CHECK_ALL_TEAM,
        isChecked: checked
    }
}

export function selectTeam(index) {
    return {
        type: SELECT_TEAM,
        index: index
    }
}

export function saveConditionsTeam(conditions, params) {
    return {
        type: SAVE_CONDITIONS_TEAM,
        conditions: conditions,
        params: params
    }
}
export function resetConditionsTeams() {
    return {
        type: RESET_CONDITIONS_TEAM
    }

}
