
class AuthService {

    constructor(axios) {
        this.axios  = axios;
    }

    async login(credential, type = 'username'){
        return await this.axios.post(`/login/${type}`, credential);
    }

    async logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('profile');
        localStorage.removeItem('export_csv');
    }

    setToken(token, profile) {
        localStorage.setItem('token', token);
        localStorage.setItem('profile', JSON.stringify(profile));

    }

    isAdmin() {
        return JSON.parse(localStorage.getItem('profile')).role === 'admin';

    }

    isStaff() {
        return this.getRole() === 'staff';
    }

    getRole() {
        return JSON.parse(localStorage.getItem('profile')).role
    }

    async verifyTFA(otpCode){
        return await this.axios.post(`/tfa/verify`, otpCode);
    }

    enableTFA(staffId) {
        return this.axios.post(`/tfa/setup`, {staff: staffId})
    }

    async disableTFA(staff) {
        return await this.axios.post(`/tfa/remove`, staff)
    }


}
export default AuthService;
