import React, { Component }              from 'react';
import moment                            from 'moment';
import { KirokuButton, KirokuInputIcon, KirokuButtonLink } from '../../../../ui';
import { withNamespaces }                from 'react-i18next';
import { checkUseHeadingQuestion }       from '../../../Surveys/SurveyEditor/SurveyService';
import { withSmoothScrolling }           from '../../../../ui/Scrolling.js';

@withSmoothScrolling
@withNamespaces()
class DateQuestion extends Component {

    state = {
        value      : null,
        date       : '',
        validated  : null,
    };

    componentWillMount() {
        if (this.props.value) {
            this.setState({
                value: this.props.value,
                date : moment.unix(this.props.value).format('YYYY-MM-DD'),
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value || this.props.question.index !== nextProps.question.index) {
            this.setState({
                value: nextProps.value,
                date : nextProps.value ? moment.unix(nextProps.value).format('YYYY-MM-DD'): '',
            });
        }
    }

    handleOnChangeDateTime() {

        if (this.state.date) {
            let answer = moment(`${ this.state.date }`, 'YYYY-MM-DD').unix();
            this.setState({
                answer   : answer,
                validated: answer ? answer : null,
            });
            this.props.onAnswer({
                answer   : answer,
                validated: answer ? answer : null,
                questionIndex: this.props.question.index
            });
        }

        if (!this.state.date) {
            this.setState({
                answer   : null,
                validated: null,
            });
            this.props.onAnswer({
                answer   : null,
                validated: null,
                questionIndex: this.props.question.index
            });
        }
        this.props.isCurrentQuestion(this.props.question.index);
    }

    moveToNextQuestion() {
        const { question } = this.props;
        let nextToQuestion;
        nextToQuestion  = question.nextQuestion ? question.nextQuestion - 1 : question.index;
        this.props.smoothScroll.scrollTo(`question-${nextToQuestion }`);
        this.props.isCurrentQuestion(nextToQuestion + 1);
    }

    render() {
        const { question, disabled, t, isEndQuestion, currentQuestion } = this.props;
        const { date }                                               = this.state;
        const contentQuestion                                        = checkUseHeadingQuestion(question);

        return (
            <div id='component-question'>
                <div className='content-question'>
                    <div className='wraper-question'>
                        <div className={disabled ? 'bg-disable-question number-question' :
                            ( date ? 'bg-question-has-answer d-flex justify-content-between number-question'
                                : (!question.unrequited ? 'bg-question-required-answer d-flex justify-content-between' : 'd-flex justify-content-between number-question'))}
                               style={ { padding : 8 } }
                        >
                            <div className={`text-overflow-elip ${disabled ? 'question-number' : this.state.value ? 'font-text-question-has-answer' 
                                : (!question.unrequited ? 'text-question-required-answer' : 'question-number') }`}>
                                {question.unrequited ? t('createRecord.option'):''}{ contentQuestion }
                            </div>
                            <div/>
                            <div hidden={disabled}>
                                <KirokuButtonLink
                                    className='padding-reset-button-link'
                                    onClick={() => {
                                        this.setState({date: ''}, () => {
                                            this.handleOnChangeDateTime();
                                        });
                                    }}
                                >
                                    {t('createRecord.resetAnswer')}
                                </KirokuButtonLink>
                            </div>
                        </div>
                        <span className='content-name-question' style={ { opacity: disabled ? 0.3 : 1 } }>
                            { question.content }
                        </span>
                        <div className='answer-question' style={ { opacity: disabled ? 0.3 : 1 } }>
                            <KirokuInputIcon name={ `${ question.index }date` } icon={ 'far fa-calendar-alt' }
                                             disabled={ disabled }
                                             value={ date }
                                             type={ 'date' }
                                             onChange={ e => this.setState({ date: e.target.value },
                                                 this.handleOnChangeDateTime.bind(this)) }
                            />

                        </div>
                        <div className="d-flex justify-content-between">
                            <div className={ 'p-2' } hidden={ disabled || isEndQuestion || question.index !== currentQuestion}>
                                <KirokuButton className="btn-kiroku-primary" onClick={ this.moveToNextQuestion.bind(this) }>
                                    { t('common.nextToQuestion') }
                                </KirokuButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DateQuestion;
