import React, { Component }        from 'react';
import { checkUseHeadingQuestion } from '../../../Surveys/SurveyEditor/SurveyService';
import KirokuCheckBox              from '../../../../ui/KirokuCheckBox';
import moment                      from 'moment';
import config                      from '../../../../config';
import { KirokuRadio }             from '../../../../ui';
import { withNamespaces }          from 'react-i18next';

@withNamespaces()
class AnswerQuestionGeneric extends Component  {
    render() {
        const { diff } = this.props;
        if(diff.lhs) {
            return (
                <div>
                    <div className={'d-flex align-items-center justify-content-around'}>
                        <div className={'text-decoration text-form-area word-break'} >{diff.lhs}</div>
                        <div className={'p-2'}/>
                        <i className="fas fa-arrow-right fa-2x"/>
                        <div className={'p-2'}/>
                        <div className='text-form-area word-break'>{diff.rhs}</div>
                    </div>
                </div>
            )
        }
        return <div className={'text-form-area word-break'}> {diff.rhs}</div>;
    }
}



class AnswerQuestionDateTime extends Component  {
    render() {
        const { diff } = this.props;
        return (
            <div>
                <div className={'d-flex align-items-center justify-content-around'}>
                    <div className={'text-decoration text-form-area'}>{diff.lhs ? moment.unix(diff.lhs).format(config.formatDateTimeTh) : ''}</div>
                    <div className={'p-2'}/>
                    <i className="fas fa-arrow-right fa-2x"/>
                    <div className={'p-2'}/>
                    <div className='text-form-area'>{diff.rhs ? moment.unix(diff.rhs).format(config.formatDateTimeTh) : ''}</div>
                </div>
            </div>
        )
    }
}
class AnswerQuestionOnlyDate extends Component  {
    render() {
        const { diff } = this.props;
        return (
            <div className={'width-difference-question'}>
                <div className={'d-flex align-items-center justify-content-around'}>
                    <div className={'text-decoration text-form-area'}>{diff.lhs ? moment.unix(diff.lhs).format(config.formatDateTh) : ''}</div>
                    <i className="fas fa-arrow-right"/>
                    <div className='text-form-area'>{diff.rhs ? moment.unix(diff.rhs).format(config.formatDateTh) : ''}</div>
                </div>
            </div>
        )
    }
}
class AnswerQuestionTime extends Component  {
    render() {
        const { diff } = this.props;
        return (
            <div className={'width-difference-question'}>
                <div className={'d-flex align-items-center justify-content-around'}>
                    <div className={'text-decoration text-form-area'}>{diff.lhs ? moment.unix(diff.lhs).format(config.formatTime) : ''}</div>
                    <i className="fas fa-arrow-right"/>
                    <div className='text-form-area'>{diff.rhs ? moment.unix(diff.rhs).format(config.formatTime) : ''}</div>
                </div>
            </div>
        )
    }
}
class AnswerQuestionSingleChoice extends Component  {
    render() {
        const { diff, questionsBefore, questionsAfter } = this.props;
        const choices = questionsBefore[diff.path[0]].choices;
        const answersLhs = questionsBefore[diff.path[0]].answers.is_choice;
        const answersRhs = questionsAfter[diff.path[0]].answers.is_choice;
        return (
            <div className={'width-difference-question'}>
                <div className="d-flex align-items-center justify-content-around">
                    <div className={'answer-question size-select-question'}>
                        {choices.map((item, index) => {
                            return (
                                <div key={index}>
                                    <KirokuRadio
                                                 checked={answersLhs === index}
                                                 id={`SingleChoice-lhs-${index}`}
                                                 onChange={() => {}}
                                    >
                                        <span className={`${answersLhs === index ? 'text-decoration' : ''} text-form-area word-break`}>
                                            {item.answer}
                                        </span>
                                    </KirokuRadio>
                                </div>
                                )
                        })}
                    </div>
                    <i className="fas fa-arrow-right fa-2x"/>
                    <div className={'answer-question size-select-question'}>
                        {choices.map((item, index) => {
                            return (
                                <div key={index}>
                                    <KirokuRadio key={index}
                                                 checked={answersRhs === index}
                                                 id={`SingleChoice-rhs-${index}`}
                                                 onChange={() => {}}
                                    > <span className='text-form-area word-break'>{item.answer}</span>
                                    </KirokuRadio>
                                </div>
                                )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

class AnswerQuestionMultiChoice extends Component  {
    render() {
        const { diff, questionsBefore, questionsAfter } = this.props;
        const answersLhs = questionsBefore[diff.path[0]].answers;
        const answersRhs = questionsAfter[diff.path[0]].answers;
        return (
            <div className={'width-difference-question'}>
                <div className="d-flex align-items-center justify-content-around">
                    <div className='answer-question answer-checkbox-beta'>
                        {answersLhs.map((item, index) => {
                            const isCheckedOfRhs = !!answersRhs[index].is_choice;
                            return (
                                <div className="size-select-question" key={index}>
                                    <KirokuCheckBox
                                                    checked={!!item.is_choice}
                                                    id={`MultiChoice_${index}`}
                                                    content={<span className={`
                                                    ${isCheckedOfRhs !== !!item.is_choice ? 'text-decoration' :''} content-checkbox-beta word-break`}>
                                                        {item.answer}
                                                    </span>}
                                                    onChange={() => {}}
                                    />
                                </div>
                            )
                        })}
                    </div>
                    <i className="fas fa-arrow-right fa-2x"/>
                    <div className='answer-question answer-checkbox-beta'>
                        {answersRhs.map((item, index) => {
                            return (<KirokuCheckBox key={index}
                                                    checked={!!item.is_choice}
                                                    id={`MultiChoice_${index}`}
                                                    content={<span className='content-checkbox-beta word-break'>{item.answer}</span>}
                                                    onChange={() => {}}
                            />)
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

class AnswerQuestionMedia extends Component {

    render() {
        const { diff } = this.props;
        return (
            <div className="d-flex align-items-center">
                <div className={'wrap-upload'}>
                    <div className="form-upload">
                        <div className="image-upload"
                             style={{
                                 backgroundImage: `url(${diff.lhs})`,
                                 minWidth: 100,
                                 height: 100
                             }}
                        />
                    </div>
                </div>
                <i className="fas fa-arrow-right fa-2x"/>
                <div className={'wrap-upload'}>
                    <div className="form-upload">
                        <div className="image-upload"
                             style={{
                                 backgroundImage: `url(${diff.rhs})`,
                                 minWidth: 100,
                                 height: 100
                             }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

@withNamespaces()
class ComponentViewActionEdit extends Component {

    answerQuestion = {
        Media      : AnswerQuestionMedia,
        InputText  : AnswerQuestionGeneric,
        Single     : AnswerQuestionSingleChoice,
        YesNo      : AnswerQuestionGeneric,
        Message    : AnswerQuestionGeneric,
        MultiChoice: AnswerQuestionMultiChoice,
        Date       : AnswerQuestionDateTime,
        OnlyDate   : AnswerQuestionOnlyDate,
        Time       : AnswerQuestionTime,
        Number     : AnswerQuestionGeneric
    };
    render() {
        const { diff,  questionsBefore, questionsAfter } = this.props;
        const AnswerQuestion = this.answerQuestion[questionsAfter[diff.path[0]].type];
        return (<div>
                <h4>{checkUseHeadingQuestion(questionsAfter[diff.path[0]])}</h4>
                <AnswerQuestion diff={diff}
                                questionsBefore={questionsBefore}
                                questionsAfter={questionsAfter}
                />
        </div>);
    }
}

export { ComponentViewActionEdit };
