import React, { Component } from 'react';

export default class KirokuCheckBox extends Component {

    render() {
        return (
            <div style={{display: 'flex'}}>
                <label className="check-group">
                    <input type="checkbox" {...this.props}  />
                    <span className="checkmark"/>
                </label>
                <label className='content-checkbox pl-2' htmlFor={this.props.id}>{this.props.content}</label>
            </div>
        )
    }
}