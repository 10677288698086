import React, {Component} from 'react';
import {CheckDivide}      from "../../../ui";
import StaffEditorWeb     from "./StaffEditorWeb";
import StaffEditorMobile  from "./Mobile/StaffEditorMobile";
import {scrollService}    from "../../../services";
import KirokuTitlepage    from "../../../ui/KirokuTitlepage";
import {withNamespaces}   from "react-i18next";
import connect            from "react-redux/es/connect/connect";
import {currentPage}      from "../../../actions/CurrentPage";
import {loadCustomerAll}  from "../../../actions/Customer";

@withNamespaces()
@connect(state => {
    return {
    }
}, dispatch => {
    return {
        currentPageUsed: (name) => {
            dispatch(currentPage(name))
        },
        loadCustomers: () => {
            dispatch(loadCustomerAll())
        }
    };
})
class StaffEditor extends Component {
    page = {
        title: <KirokuTitlepage title={this.props.t('common.staff')}/>,
    };

    componentWillMount() {
        scrollService.top();
        this.props.currentPageUsed(this.page);
        this.props.loadCustomers()
    }

    render() {
        let web    = <StaffEditorWeb history={this.props.history} id={this.props.match.params.id}/>;
        let mobile = <StaffEditorMobile history={this.props.history} id={this.props.match.params.id}/>;
        return (
            <div>
                <CheckDivide web={web} mobile={mobile}/>
            </div>
        )
    }
}

export default StaffEditor;