import {
    LOAD_CUSTOMER_BY_ID,
    LOAD_CUSTOMER_ALL,
    LOAD_CUSTOMER_LIST_PAGE,
    ASSIGN_SURVEYS,
    DEACTIVE_CUSTOMER,
    LOAD_CUSTOMER_FOR_SURVEY,
    FILTER,
    UPDATE_CUSTOMER,
    FILTER_CUSTOMER_WITH_CONDITION,
    CREATE_CUSTOMER,
    SAVE_DRAFT,
    LOAD_DRAFT_CUSTOMER,
    DISCARD_DRAFT,
    UPLOAD_DOCUMENT,
    FILTER_BY_CONDITION_PAGE_DETAIL,
    PIN_CUSTOMER, GET_CUSTOMERS_BETA,
} from '../actions/Customer';
import {
    customerService, draftService,
    makeCustomerService, documentUploadService
} from "../services";
import {NOTIFICATION} from "../actions/Notification";

export const customerMiddleware = (store) => next => action => {
    switch (action.type) {
        case GET_CUSTOMERS_BETA:
            return customerService.getCustomersBeta().then(res => {
                next({
                    ...action,
                    ...res,
                })
            });
        case  'GET_PIN_CUSTOMER' :
            return customerService.getCustomerPinDashboard().then(customerForPin => {
                next({
                    type: 'GET_PIN_CUSTOMER',
                    customerForPin: customerForPin.customers
                })
            }).catch(err => console.log(err));
        case PIN_CUSTOMER:
            return customerService.pinCustomer(action.customerId).then(() => {
                next({
                    type: PIN_CUSTOMER
                });
                store.dispatch({
                    type: 'GET_PIN_CUSTOMER'
                })
            });
        case FILTER_BY_CONDITION_PAGE_DETAIL:
            return customerService.filterWithConditionPageDetail(action.params).then(result => {
                next({
                    type: FILTER_BY_CONDITION_PAGE_DETAIL,
                    documents: {
                        currentPage: result.currentPage || 1,
                        documents: result.documents,
                        pageSize: result.pageSize,
                        totalPages: result.totalPages || 1,
                        totalDocuments: result.totalDocuments
                    }
                })
            }).catch(err => console.log(err));

        case LOAD_CUSTOMER_BY_ID :
            return customerService.load(action.id).then(loadedCustomer => {
                let makeCustomer = makeCustomerService.getCustomerFromApi(loadedCustomer, action.isDetail);
                next({
                    ...action,
                    customer: makeCustomer
                })
            }).catch(error => console.log(error));
        case LOAD_CUSTOMER_ALL :
            return customerService.customersAll().then(customers => {
                let makeCustomerForTags = makeCustomerService.listAllCustomerForTags(customers);
                next({
                    ...action,
                    listAllCustomer: makeCustomerForTags
                })
            }).catch(err => console.log(err));
        case LOAD_CUSTOMER_LIST_PAGE :
            return customerService.customers().then(page => {
                next({
                    ...action,
                    customers: page.customers.map(customer => ({
                        ...customer,
                        isChecked: false
                    })),
                    totalPages: page.totalPages || 1,
                    currentPage: page.currentPage || 1
                })
            }).catch(err => console.log(err));
        case ASSIGN_SURVEYS :
            return customerService.assignSurveyToCustomer(action.customerId, action.surveys).then(customerEdited => {
                let makeCustomer = makeCustomerService.getCustomerFromApiEdit(customerEdited);
                next({
                    type: ASSIGN_SURVEYS,
                    customer: makeCustomer
                })
            });
        case DEACTIVE_CUSTOMER :
            return customerService.deactiveCustomer(action.id).then((res) => {
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'success',
                    message: 'message.DeactiveSuccess'
                });
                store.dispatch({
                    type: FILTER_CUSTOMER_WITH_CONDITION,
                    params: action.params,
                    tags : action.tags
                })
            });
        case LOAD_CUSTOMER_FOR_SURVEY :
            return customerService.loadCustomerForSurvey().then((customers) => {
                next({
                    type: LOAD_CUSTOMER_FOR_SURVEY,
                    customers: customers.map(customer => {
                        return {
                            ...customer,
                            selected: false,
                            isChecked: false
                        }
                    })
                })
            }).catch(error => console.log(error));
        case FILTER :
            let condition = {
                status: action.filterBy
            };
            return customerService.filter(condition).then(customers => {
                next({
                    type: FILTER,
                    customers: customers
                })
            }).catch(err => console.log(err));
        case  UPDATE_CUSTOMER:
            return customerService.edit(action.customerId, action.customerInformation).then(() => {
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'success',
                    message: 'message.UpdateSuccess'
                });
            });
        case FILTER_CUSTOMER_WITH_CONDITION :
            return customerService.filterWithCondition(action.params ? action.params : {}, action.tags ? action.tags : []).then(page => {
                next({
                    type: FILTER_CUSTOMER_WITH_CONDITION,
                    customers: page.customers,
                    totalPages: page.totalPages || 1,
                    currentPage: page.currentPage || 1,
                    tags: action.tags ? action.tags : []
                })
            }).catch(err => console.log(err));
        case CREATE_CUSTOMER :
            return customerService.create(action.customer).then(res => {
                store.dispatch({
                    type: FILTER_CUSTOMER_WITH_CONDITION,
                });
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'success',
                    message: 'message.CreateSuccess'
                });
            });
        case SAVE_DRAFT:
            return draftService.saveDraft(action.typeDraft, action.draft).then(() => {
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'success',
                    message: 'message.SaveSuccess'
                });
            });
        case LOAD_DRAFT_CUSTOMER :
            return draftService.loadDraft(action.typeDraft, action.customerId).then(draftCustomer => {
                next({
                    type: LOAD_DRAFT_CUSTOMER,
                    dataDraft: draftCustomer
                })
            });
        case DISCARD_DRAFT:
            return draftService.discardDraft(action.typeDraft, action.customerId).then(() => {
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'success',
                    message: 'message.DeleteSuccess'
                });
                next({
                    type: DISCARD_DRAFT
                })
            })
                ;
        case UPLOAD_DOCUMENT:
            return documentUploadService.upload(action.document).then(() => {

                store.dispatch({
                    type: 'LOAD_DOCUMENT',
                    params: {
                        customerId: action.document.customerId
                    }
                })
            });
        default:
            return next(action)
    }

};
