import React                                                             from 'react';
import { withNamespaces }                                                from 'react-i18next';
import { KirokuModalBody, KirokuModalFooter, KirokuButton, KirokuModal } from '../../../ui';

@withNamespaces()
class ModalConfirm extends React.Component {

    closeModal() {
        this.props.closeModal()
    }

    submit() {
        this.props.closeModal();
        this.props.submit()
    }

    render() {
        const { t } = this.props;
        return (
            <KirokuModal show={this.props.show} onClick={this.closeModal.bind(this)}>
                <KirokuModalBody>
                    <p className='text-center mt-8'>
                        {t('message.confirmPublic')}
                    </p>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                        {t('common.Cancel')}
                    </KirokuButton>
                    <KirokuButton onClick={this.submit.bind(this)} color={'primary'}>
                        {t('common.Confirm')}
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>
        );

    }
}

export default ModalConfirm;
