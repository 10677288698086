import React, { Component }        from 'react';
import ModalAddLogicYesNo          from '../ModalAddLogicYesNo';
import BehaviorQuestionMobile       from './BehaviorQuestionMobile';
import { withNamespaces }                                           from 'react-i18next';
import {
    KirokuPopoverItem, KirokuLogicApplicated,
    KirokuCardWeb, KirokuCardTitleWeb,
    KirokuCardBodyWeb, KirokusSortableHandle,
    KirokuRadio,
}                                         from '../../../../ui';
import ActionQuestions                    from './ActionsQuestions';
import { checkUseHeadingQuestion }        from '../../SurveyEditor/SurveyService';
import ComponentUnrequited                from '../../ReviewSurvey/ComponentUnrequited';
import { setDefaultAnswerQuestionMobile } from '../../../../actions/Survey';
import { connect }                        from 'react-redux';
import KirokuButtonLink                   from '../../../../ui/KirokuButtonLink';

@withNamespaces()
@connect(null, dispatch => {
    return {
        setDefaultAnswerQuestion: (question) => {
            dispatch(setDefaultAnswerQuestionMobile(question))
        }
    }
})
class YesNo extends Component {
    state = {
        show: true,
    };

    showModalAddLogic() {
        this.setState({
            show: !this.state.show,
        });
    }

    closeModal() {
        this.setState({
            show: !this.state.show,
        });
    }

    handleSetDefaultAnswer(e) {
        const { question } = this.props;
        let newQuestion = { ...question };
        newQuestion = {
            ...newQuestion,
            defaultAnswer: e.target.value
        };
        this.props.setDefaultAnswerQuestion(newQuestion);
    }

    handleResetDefaultAnswer() {
        const { question } = this.props;
        let questionNew    = { ...question };
        if(questionNew.defaultAnswer) {
            delete questionNew.defaultAnswer;
            this.props.setDefaultAnswerQuestion(questionNew);
        }
    }

    render() {
        const { t }           = this.props;
        const question        = this.props.question;
        const contentQuestion = checkUseHeadingQuestion(question);
        const isLogic = typeof question.nextQuestion === 'number'|| typeof question.nextQuestionNo === 'number'|| typeof question.nextQuestionYes === 'number';
        return (
            <KirokuCardWeb style={{ borderRadius: 4 }}>

                <KirokuCardTitleWeb style={{
                    backgroundColor: '#e9edf1', paddingTop: 10, paddingBottom: 7, paddingRight: 12, paddingLeft: 12,
                }}>
                    <div className="d-flex justify-content-between">
                        <div className={'pr-2'}>
                            <KirokusSortableHandle>
                                <div className='number-question d-flex'>
                                    <span className="question-number pb-2 pt-4px">
                                        {t('createSurvey.QuestionOrder', { index: question.index + 1 })}
                                    </span>
                                    <KirokuLogicApplicated question={question}/>
                                </div>
                            </KirokusSortableHandle>
                        </div>
                        <div className="pt-5px pr-2" hidden={isLogic}>
                            <ComponentUnrequited question={question}
                                                 changeUnrequitedQuestion={(questionIndex) => this.props.changeUnrequitedQuestion(questionIndex)}
                            />
                        </div>
                        <div className={'pr-2'}>
                            <KirokuButtonLink
                                className='padding-reset-button-link'
                                onClick={() => {this.handleResetDefaultAnswer()}}>
                                {t('createRecord.resetAnswer')}
                            </KirokuButtonLink>
                        </div>
                        <div>
                            <ActionQuestions isReviewSurvey>
                                <KirokuPopoverItem>
                                    <BehaviorQuestionMobile question={question}
                                                            showModalAddLogic={this.showModalAddLogic.bind(this)}
                                                            backToEditQuestion={this.props.backToEditQuestion}
                                    />
                                </KirokuPopoverItem>
                            </ActionQuestions>
                        </div>
                    </div>

                </KirokuCardTitleWeb>
                <div className='wrap-overlay-action'>
                    <KirokuCardBodyWeb>
                        <div>
                            <span className='content-name-question pb-2'>{contentQuestion}</span>
                            <p hidden={typeof question.nextQuestion !== 'number'}>
                                <i className='fas fa-arrow-right ml-1 text-info'>
                                    {t('createRecord.Question')}: {question.nextQuestion + 1}
                                </i>
                            </p>
                            <div className='answer-question'>
                                <KirokuRadio onChange={this.handleSetDefaultAnswer.bind(this)}
                                             id={`yes${question.index}`}
                                             name={`questionYesNo${question.index}`}
                                             checked={question.defaultAnswer === 'Yes'}
                                             value={'Yes'}
                                >
                                    {t('createSurvey.Yes')}
                                    <span hidden={typeof question.nextQuestionYes !== 'number'}>
                                        <i className='fas fa-arrow-right ml-1 text-info'>
                                        {t('createRecord.Question')}: {question.nextQuestionYes + 1}</i> </span>
                                </KirokuRadio>
                                <KirokuRadio onChange={this.handleSetDefaultAnswer.bind(this)}
                                             id={`no${question.index}`}
                                             name={`questionYesNo${question.index}`}
                                             checked={question.defaultAnswer === 'No'}
                                             value={'No'}
                                >
                                    {t('createSurvey.No')}
                                    <span hidden={typeof question.nextQuestionNo !== 'number'}>
                                        <i className='fas fa-arrow-right ml-1 text-info'>
                                            {t('createRecord.Question')}: {question.nextQuestionNo + 1}</i>
                                    </span>
                                </KirokuRadio>
                            </div>
                        </div>
                    </KirokuCardBodyWeb>
                </div>

                <ModalAddLogicYesNo show={this.state.show} question={question}
                                    closeModal={this.closeModal.bind(this)}/>
            </KirokuCardWeb>
        );
    }
}

export default YesNo;

