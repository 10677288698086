import React, {Component} from 'react';
import {connect}                                from 'react-redux';
import { CheckRerenderResponsive } from "../../ui";
import SurveysMobile                            from "./Mobile/SurveysMobile";
import SurveysWeb         from "./Web/SurveysWeb";
import {loadSurveyModal}  from "../../actions/Survey";
import {withNamespaces}   from "react-i18next";
import {loadTeam}         from "../../actions/Team";
import {scrollService}    from "../../services";


@withNamespaces()
@connect(state => {
    return {}
}, (dispatch) => {
    return {

        loadSurveyModal: () => {
            dispatch(loadSurveyModal())
        },
        loadTeam: () => {
            dispatch(loadTeam())
        }
    };
})

class Surveys extends Component {

    componentWillMount() {
        scrollService.top();
        this.props.loadSurveyModal();
        this.props.loadTeam();
    }

    render() {
        let mobile = <SurveysMobile/>;
        let web    = <SurveysWeb/>;
        return (
            <CheckRerenderResponsive mobile={mobile} web={web}/>
        )
    }
}

export default Surveys;
