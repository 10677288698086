import React, {Component} from 'react'
import {Route, Switch}    from 'react-router-dom';

import DetailSurvey               from './components/Surveys/DetailSurvey/DetailSuvey';
import SurveyEditor               from "./components/Surveys/SurveyEditor";
import Demo                       from './Demo';
import DemoWeb                    from './DemoWeb';
import Dashboard                  from './components/Dashboard/Dashboard';
import CustomerDetail             from './components/Customers/CustomerDetail/CustomerDetail';
import Surveys                    from './components/Surveys/Surveys';
import Records                    from './components/Records/RecordList/Records';
import RecordEditor               from './components/Records/RecordEditor';
import DuplicateCustomer          from './components/Customers/CustomerDuplicate/DuplicateCustomer';
import CreatorRecord              from './components/Records/RecordCreator';
import CustomerEditor             from './components/Customers/CustomerEditor/CustomerEditor';
import RecordDetail               from './components/Records/DetailRecord/DetailRecord';
import SurveyTemplates            from './components/Surveys/Web/SurveyTeamplates/SurveyTemplates';
import CustomerCreator            from './components/Customers/CustomerCreator/CustomerCreator';
import Teams                      from './components/Teams/Teams';
import TeamCreator                from './components/Teams/TeamCreators/TeamCreator';
import TeamEditor                 from './components/Teams/TeamEditor/TeamEditor';
import TeamDetail                 from './components/Teams/TeamDetail/TeamDetail';
import ReviewRecoded              from './components/Records/RecordCreator/ReviewRecoded';
import Organization               from './components/Organization/Organization';
import Staffs                     from './components/Staffs/StaffList/Staffs';
import StaffDetail                from './components/Staffs/StaffDetail/StaffDetail';
import StaffEditor                from './components/Staffs/StaffEditor/StaffEditor';
import Logs                       from './components/Logs/Logs';
import SurveyCreator              from './components/Surveys/CreatorSurvey/CreatorSurvey';
import ReviewSurvey               from './components/Surveys/ReviewSurvey';
import PreviewTakeSurvey          from './components/Records/PreviewTakeSurvey';
import DuplicateSurvey            from './components/Surveys/DuplicateSurvey';
import AdminExport                from './components/AdminExport/AdminExport';
import Callback                   from "./components/line/callback";
import StaffCreator               from "./components/Staffs/StaffCreator/StaffCreator";
import Maintenance                from "./components/Maintenance";
import CustomerList               from "./components/Customers/CustomerList/CustomerList";
import Analysis              from './components/Analysis/Analysis';
import MyProfile             from "./components/Staffs/MyProfile/MyProfile";
import DetailMyProfile       from "./components/Staffs/MyProfile/DetailMyProfile";
import RecordEditorFiledAll  from "./components/Records/newTakeRecord/RecordEditorFiledAll";
import RecordCreatorFiledAll from "./components/Records/newTakeRecord/RecordCreatorFiledAll";
// import Export                from "./components/AdminExport/NewExport/Export";
import RecordsDetail         from './components/Records/RecordsBeta/RecordsDetail/RecordsDetail';
import NotRecord            from "./components/Records/DetailRecord/NotRecord";
import StaffDuplicate        from "./components/Staffs/StaffDuplicate";
// import RecordList           from "./components/Records/NewRecords/RecordList";
import Reports               from "./components/Reports/Reports";
import Deleted               from "./components/DeletedPage/Deleted";
import RecordsBeta           from "./components/Records/RecordsBeta/RecordsBeta"
import BatchActionRecordBeta from './components/Records/RecordsBeta/web/BatchAction';
import CustomerSelected      from './components/Records/RecordsBeta/mobile/CustomerSelected';
import NotFoundPage          from './components/NotFoundPage';
import SupportPage           from './components/SupportPage';

class RouterProtected extends Component {
    render() {
        return (
            <Switch>
                <Route exact path={'/callback'} component={Callback}/>
                <Route exact path='/component' component={Demo}/>
                <Route exact path='/component-web' component={DemoWeb}/>
                <Route exact path={'/server-maintenance'} component={Maintenance}/>
                <Route exact path={'/internal-server-error'} component={Maintenance}/>
                <Route exact path={'/recordInvalid'} component={NotRecord}/>

                <Route exact path='/' component={Dashboard}/>

                <Route exact path='/customers' component={CustomerList}/>
                <Route exact path='/customers/customer/:id' component={CustomerEditor}/>
                <Route exact path='/customers/customer-detail/:id' component={CustomerDetail}/>
                <Route exact path='/customers/customer-creator' component={CustomerCreator}/>
                <Route exact path='/customers/customer-duplicate/:id' component={DuplicateCustomer}/>


                <Route exact path='/surveys/survey' component={SurveyCreator}/>
                <Route exact path='/surveys' component={Surveys}/>
                <Route exact path='/surveys/survey/:id' component={SurveyEditor}/>
                <Route exact path='/surveys/survey-templates' component={SurveyTemplates}/>
                <Route exact path='/surveys/survey-preview' component={PreviewTakeSurvey}/>
                <Route exact path='/surveys/review-survey' component={ReviewSurvey}/>
                <Route exact path='/surveys/survey-detail/:id' component={DetailSurvey}/>
                <Route exact path='/surveys/survey-duplicate/:id' component={DuplicateSurvey}/>


                <Route exact path='/records' component={Records}/>
                {/*<Route exact path='/record-list' component={RecordList}/>*/}
                <Route exact path='/records/record/:id' component={RecordDetail}/>
                <Route exact path='/records/edit/:recordId' component={RecordEditor}/>
                <Route exact path='/records/edit-filedAll/:recordId' component={RecordEditorFiledAll}/>

                <Route exact path='/records/create-record' component={CreatorRecord}/>
                <Route exact path='/records/new-create-record' component={RecordCreatorFiledAll}/>
                <Route exact path='/records/take-record-review' component={ReviewRecoded}/>
                <Route exact path='/records/take-record-after-save-draft/:recordId' component={RecordEditor}/>


                <Route exact path='/teams' component={Teams}/>
                <Route exact path='/teams/team-detail/:id' component={TeamDetail}/>
                <Route exact path='/teams/team-creator' component={TeamCreator}/>
                <Route exact path='/teams/edit/:id' component={TeamEditor}/>


                <Route exact path='/organization' component={Organization}/>


                <Route exact path='/staffs' component={Staffs}/>
                <Route exact path='/staffs/staff/:id' component={StaffDetail}/>
                <Route exact path='/staffs/staff-editor/:id' component={StaffEditor}/>
                <Route exact path='/staffs/staff-creator' component={StaffCreator}/>
                <Route exact path='/staffs/staff-duplicate/:id' component={StaffDuplicate}/>

                <Route exact path='/edit-my-profile' component={MyProfile}/>

                <Route exact path='/my-profile' component={DetailMyProfile}/>

                <Route exact path='/exports' component={AdminExport}/>
                {/*<Route exact path='/beta-export' component={Export}/>*/}

                <Route exact path='/Logs' component={Logs}/>

                <Route exact path='/analysis' component={Analysis}/>

                <Route exact path='/reports' component={Reports}/>

                <Route exact path='/deleted' component={Deleted}/>

                <Route exact path='/records-beta' component={RecordsBeta}/>
                <Route exact path='/batch-action-records-beta/search' component={BatchActionRecordBeta}/>
                <Route exact path='/records-beta-detail' component={RecordsDetail}/>
                <Route exact path='/select-customer' component={CustomerSelected}/>
                <Route exact path='/surfly' component={SupportPage}/>
                <Route component={NotFoundPage}/>
            </Switch>
        )
    }
}

export default RouterProtected;
