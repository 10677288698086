import React, {Component}                                from 'react';
import {connect}                                         from 'react-redux';
import _                                                 from "lodash";
import {withNamespaces}                                  from "react-i18next";
import {currentPage}                                     from "../../../../actions/CurrentPage";
import {assignSurveys, filterByCondition, loadDocuments} from '../../../../actions/Customer';
import {NOTIFICATION}                                    from "../../../../actions/Notification";
import CustomerProfileMobile                             from "../../CustomerProfile/CustomerProfileMobile";
import {filePathService}            from "../../../../services";
import {
    KirokuButton,
    KirokuCard,
    KirokuCardList,
    KirokuAge,
    KirokuCertificateList,
    KirokuModalHeader,
    KirokuModalBody,
    KirokuModalFooter,
    KirokuModal,
    KirokuInputSearch,
    KirokuCheckBox,
    KirokuLoading
}                                                        from '../../../../ui';
import withPermissions                                   from "../../../HOC/WithPermissions"
import ButtonComponent                                   from "../../../HOC/ButtonComponent";
import LinkComponent    from "../../../HOC/LinkComponent";
import SpanComponent    from "../../../HOC/SpanComponent";
import TabRecordsMobile from "./TabRecordMobile";
import TabUploadMobile  from "./TabUploadMobile";
import TabSurveysMobile from "./TabSurveysMobile";
import KirokuTeamList   from "../../../../ui/KirokuTeamList";

const ButtonAssignSurvey = withPermissions(ButtonComponent);
const LinkButton         = withPermissions(LinkComponent);
const Span               = withPermissions(SpanComponent);

@withNamespaces()
@connect(state => {
    return {
        surveyForModal: state.KirokuAISurvey.surveyForModal,
        documents: state.KirokuAICustomer.documents,
        profile: state.KirokuAISurvey.profile,
        loadingResultDocuments: state.KirokuAICustomer.loadingResultDocuments
    }
}, dispatch => {
    return {
        assignSurvey: (customerId, surveyId) => {
            dispatch(assignSurveys(customerId, surveyId))
        },
        notification: (t) => {
            dispatch({
                type: NOTIFICATION,
                typeNotification: 'success',
                message: t('message.AssignSuccess')
            })
        },
        currentPageUsed: (name) => {
            dispatch(currentPage(name))
        },
        searchDocument: (params) => {
            dispatch(loadDocuments(params))
        },
        reloadListDocument: (params) => {
            dispatch(loadDocuments(params))
        },
        filterByCondition: (params) => {
            dispatch(filterByCondition(params))
        }
    };
})
class CustomerDetailMobile extends Component {

    state = {
        files: [],
        surveyForModal: [],
        surveysAssign: [],
        showSurveys: false,
        showRecords: true,
        showProfile: true,
        showUpLoad: true,
        statusCheck: false,
        show: false,
        loadingImg: false,
        disabledPrev: true,
        disabledNext: false,
        url: '',
        name: '',
    };

    componentWillMount() {
        this.setState({
            surveyForModal: this.props.surveyForModal,
        }, () => {
            this.filterSurvey(this.props.customer, this.state.surveyForModal)
        });
    }

    filterSurvey(customers, surveys) {
        let surveyRecord   = customers.surveys.map(item => item.id);
        let customerRecord = surveys.map(survey => {
            return {
                ...survey,
                isChecked: surveyRecord.includes(survey.id)
            }
        });
        let checked        = _.filter(customerRecord, (survey) => !survey.isChecked);

        this.setState({
            surveyForModal: customerRecord,
            surveysAssign: customerRecord,
            statusCheck: !checked.length
        });
    }

    showSurveys() {
        this.setState({
            showSurveys: false,
            showRecords: true,
            showProfile: true,
            showUpLoad: true
        })
    }

    showRecords() {
        this.setState({
            showSurveys: true,
            showRecords: false,
            showProfile: true,
            showUpLoad: true
        })
    }

    showProfile() {
        this.setState({
            showSurveys: true,
            showRecords: true,
            showProfile: false,
            showUpLoad: true
        })
    }

    showUpLoad() {
        this.setState({
            showSurveys: true,
            showRecords: true,
            showProfile: true,
            showUpLoad: false
        })
    }

    closeModal() {
        this.setState({
            show: !this.state.show
        })
    }

    goBack() {
        this.props.history.goBack()
    }

    onChangeSearch(e) {
        let input   = e.target.value.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
        let surveys = this.state.surveysAssign.filter(survey =>
            survey.title.toLowerCase().search(input.toLowerCase()) !== -1
        );
        let checked = _.filter(surveys, (survey) => !survey.isChecked);
        this.setState({surveyForModal: surveys, statusCheck: !checked.length});
    }

    handleCheckSurveys(surveyId) {
        let {surveyForModal, surveysAssign} = this.state;
        let surveyRecords                   = surveyForModal.map(survey => ({...survey}));
        let survey                          = _.find(surveyRecords, survey => survey.id === surveyId);
        survey.isChecked                    = !survey.isChecked;
        let searchSurveys                   = surveysAssign;
        let surveyAssign                    = _.find(searchSurveys, survey => survey.id === surveyId);
        surveyAssign.isChecked              = !surveyAssign.isChecked;
        let checked                         = _.filter(surveyRecords, (survey) => !survey.isChecked);
        this.setState({
            surveyForModal: surveyRecords,
            surveysAssign: searchSurveys,
            statusCheck: !checked.length
        });
    }

    handleCheckAll() {
        let {surveyForModal, surveysAssign} = this.state;
        let newSurveyRecord                 = surveyForModal.map(survey => ({
            ...survey,
            isChecked: !this.state.statusCheck,
        }));
        let surveyChecked                   = newSurveyRecord.map(item => item.id || []);
        let newSurveysAssign                = surveysAssign.map(survey => {
            if (surveyChecked.includes(survey.id)) {
                return {
                    ...survey,
                    isChecked: !this.state.statusCheck,
                }
            }
            return {
                ...survey
            };
        });
        this.setState({
            surveyForModal: newSurveyRecord,
            surveysAssign: newSurveysAssign,
            statusCheck: !this.state.statusCheck,
        })
    }

    assignSurvey() {
        //get survey assign not survey modal
        let surveyForModal = this.state.surveysAssign.filter(survey => survey.isChecked);
        let customer       = this.props.customer;
        this.props.assignSurvey(customer.id, surveyForModal.map(item => item.id));
        this.props.notification(this.props.t);
        this.setState({
            show: !this.state.show
        });
    }

    isSelectSurvey() {
        return !this.state.surveyForModal.filter(item => item.isChecked).length
    }

    renderCustomerDetail(customer) {
        const {showProfile, showRecords, showSurveys, showUpLoad} = this.state;
        let {t}                                                   = this.props;
        return (
            <div className='bg-white card-customer-detail'>
                <div className="fix-center">
                    {!this.state.loadingImg ?
                        <div className='customer-detail height-avatar'
                             style={{backgroundImage: `url(${customer.avatar ? customer.avatar : `${filePathService.resolveFilePath('profile-ic.svg')}`})`}}
                        /> : <KirokuLoading/>}
                    <div className='pt-10'>
                        <div>
                            <h5 className="text-center">
                                {customer.name}
                            </h5>
                        </div>
                        <div className={'text-center'}>
                            <Span className='pr-10'
                                  resource-permission={"|customerDetail|button-editProfile|"}
                            >
                                <i className='fas fa-pencil-alt text-secondary'/>
                            </Span>
                            <span>
                                <LinkButton
                                    href={`/customers/customer/${customer.id}`}
                                    text={t('common.EditProfile')}
                                    // color={'text-dark'}
                                    resource-permission={"|customerDetail|button-editProfile|"}
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="text-left">
                    <div className='mb-10'>
                        <span className='customer-info f-bold'><span className="mr-2">{t('common.Age')}:
                        </span>
							<KirokuAge dob={customer.date_of_birth}/>
                        </span>
                    </div>
                    <div className='mb-10'>
                        <span className='customer-info f-bold'><span
                            className="mr-2">{t('CustomerEditor.KindOfCertificate')}:</span></span>
                        <KirokuCertificateList certificate={customer.certificate} numberCerlist={1}/>
                    </div>
                    <div className='mb-10'>
                        <span className='customer-info f-bold'><span
                            className="mr-2">{t('common.Characteristic')}:</span></span>
                        <span>{customer.characteristic}</span>
                    </div>
                    <div className='mb-10'>
                        <span className='customer-info f-bold'>
                            <span className="mr-2">{t('common.Staff')}:</span>
                        </span>
                        <KirokuTeamList type={"users"} listTags={customer['staffs']} numberStaff={1}/>
                    </div>
                    <div className='mb-10' style={{display: 'inline-block'}}>
                        <span className='customer-info f-bold'>
                            <span className="mr-2">{t('common.team')}:</span>
                        </span>
                        <KirokuTeamList type={"teams"} listTags={customer['teams']} numberStaff={1}/>
                    </div>
                </div>
                <div className='d-flex justify-content-between pt-10'>
                    <div style={{cursor: 'pointer'}} className={`nav-bar ${!showSurveys ? 'active' : ''}`}
                         onClick={this.showSurveys.bind(this)}>{t('common.Survey')}
                    </div>
                    <div style={{cursor: 'pointer'}} className={`nav-bar ${!showRecords ? 'active' : ''}`}
                         onClick={this.showRecords.bind(this)}>{t('common.Records')}
                    </div>
                    <div style={{cursor: 'pointer'}} className={`nav-bar ${!showProfile ? 'active' : ''}`}
                         onClick={this.showProfile.bind(this)}>{t('common.ProfileDetail')}
                    </div>
                    <div style={{cursor: 'pointer'}} className={`nav-bar ${!showUpLoad ? 'active' : ''}`}
                         onClick={this.showUpLoad.bind(this)}>{t('common.UpLoad')}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const {surveyForModal, files, profile} = this.state;
        let {t, customer, documents}           = this.props;
        return (
            <div className='default-background-color'>
                <div className='customer-detail default-background-color'>
                    <div className='d-flex justify-content-between p-2'>
                        <KirokuButton onClick={this.goBack.bind(this)}
                                      color='white'>{t('common.Back')}</KirokuButton>
                        <ButtonAssignSurvey
                            resource-permission="|customerDetail|button-assignSurvey|"
                            color='primary'
                            onClick={this.closeModal.bind(this)}>{t('Customer.AssignSurvey')}
                        </ButtonAssignSurvey>
                    </div>
                    {this.renderCustomerDetail(customer)}
                    <div className='pt-8'>
                        <TabSurveysMobile
                            profile={profile}
                            customer={customer}
                            closeModal={this.closeModal.bind(this)}
                            hidden={this.state.showSurveys}
                        />
                        <TabRecordsMobile
                            customer={customer}
                            hidden={this.state.showRecords}
                        />
                        <div hidden={this.state.showProfile} className='pb-5'>
                            {<CustomerProfileMobile customer={customer}/>}
                        </div>
                        <TabUploadMobile
                            hidden={this.state.showUpLoad}
                            files={files}
                            documents={documents}
                            customer={customer}
                            id={this.props.id}
                            handleUploadFile={this.props.handleSubmit}
                        />
                    </div>
                    <KirokuModal show={this.state.show} onCloseModal={this.closeModal.bind(this)}>
                        <KirokuModalHeader>
                            <h6 className="mb-3">{t('CustomerEditor.AssignCustomerToSurvey')}</h6>
                            <KirokuInputSearch onChange={this.onChangeSearch.bind(this)}
                                               placeholder={t('CustomerEditor.EnterNameSurvey')}/>
                        </KirokuModalHeader>
                        <KirokuModalBody>
                            <div id="body-modal-as">
                                <KirokuCardList
                                    headerLeft={t('Dashboard.SurveyTemplates')}
                                    headerRight={
                                        <div className='text-primary' style={{cursor: 'pointer'}}>
                                        <span hidden={this.state.statusCheck}
                                              onClick={this.handleCheckAll.bind(this)}
                                        >{t('common.SelectAll')}</span>

                                            <span hidden={!this.state.statusCheck}
                                                  onClick={this.handleCheckAll.bind(this)}
                                            >{t('common.UnSelect')}</span>
                                        </div>
                                    }
                                >
                                    <section className="scroll-data-customer">
                                        {surveyForModal.map((survey) => (
                                            <KirokuCard key={survey.id}>
                                                <KirokuCheckBox
                                                    onChange={this.handleCheckSurveys.bind(this, survey.id)}
                                                    checked={survey.isChecked} content={survey.title}/>
                                            </KirokuCard>
                                        ))}
                                    </section>
                                </KirokuCardList>
                            </div>
                        </KirokuModalBody>
                        <KirokuModalFooter>
                            <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                                {t('common.Cancel')}
                            </KirokuButton>
                            <KirokuButton color={'primary'} disabled={this.isSelectSurvey()}
                                          onClick={this.assignSurvey.bind(this)}>
                                {t('common.Assign')}
                            </KirokuButton>
                        </KirokuModalFooter>
                    </KirokuModal>
                </div>
            </div>
        );
    }
}

export default CustomerDetailMobile;
