import React, {Component}                             from 'react';
import CustomerListWeb                                from "./CustomerListWeb";
import CustomerListMobile                                      from "./CustomerListMobile";
import { CheckRerenderResponsive, KirokuLoading } from "../../../ui";
import {scrollService}                                         from "../../../services";
import {connect}                                      from "react-redux";
import {filterCustomerWithCondition, loadCustomerAll} from "../../../actions/Customer";
import {loadTeam}                                     from "../../../actions/Team";
import {loadUsers}                                    from "../../../actions/User";

@connect(state => {
    return {
        totalPages: state.KirokuAICustomer.totalPages,
        currentPage: state.KirokuAICustomer.currentPage,
        profile: state.KirokuAICustomer.profile,
        customers: state.KirokuAICustomer.customers,
        allTeam: state.KirokuAITeam.allTeam,
        users: state.KirokuAIUser.users,
        defaultTags: state.KirokuAICustomer.defaultTags,
        isLoading: state.KirokuAITeam.isLoading,
        loadingUserSuccess: state.KirokuAIUser.loadingUserSuccess,
        //loading: state.KirokuAICustomer.loading,
        listAllCustomer: state.KirokuAICustomer.ListAllCustomer,
    }
}, dispatch => {
    return {
        loadCustomerAll: () => {
            dispatch(loadCustomerAll())
        },
        loadUsers: () => {
            dispatch(loadUsers())
        },
        loadTeam: () => {
            dispatch(loadTeam())
        },
        filterCustomerWithCondition: (params, tags) => {
            dispatch(filterCustomerWithCondition(params, tags))
        }
    };
})
class CustomerList extends Component {

    state = {
        isLoading: false,
    };

    componentWillMount() {
        scrollService.top();
        this.props.loadUsers();
        this.props.loadCustomerAll();
        this.props.loadTeam();
    }

    componentWillUnmount() {
        this.setState({
            isLoading: false
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.isLoading && nextProps.loadingUserSuccess) {
            this.setState({
                isLoading: true,
            })
        }
    }

    render() {

        let {customers, profile, users, allTeam, listAllCustomer} = this.props;
        let web                                                 = <CustomerListWeb
            currentPage={this.props.currentPage}
            totalPages={this.props.totalPages}
            profile={profile}
            users={users}
            teams={allTeam}
            customers={customers}
            history={this.props.history}/>;
        let mobile                                              = <CustomerListMobile
            currentPage={this.props.currentPage}
            totalPages={this.props.totalPages}
            users={users}
            teams={allTeam}
            profile={profile}
            listAllCustomer={listAllCustomer}
            customers={customers}
            history={this.props.history}/>;
        return (
            <div>
                {(this.state.isLoading) ? <div className='default-background-color'>
                    <div className='padding-responsive'>
                        <CheckRerenderResponsive mobile={mobile} web={web}/>
                    </div>
                </div> : <KirokuLoading/>
                }
            </div>
        )
    }
}

export default CustomerList;
