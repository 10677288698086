import KirokuCertificate  from "./KirokuCertificate";
import React, {Component} from "react";
import {withNamespaces}   from "react-i18next";
import * as _             from "lodash";

@withNamespaces()
class KirokuCertificateList extends Component {

    state = {
        selected: null
    };

    constructor() {
        super();

        this.handleClick        = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            popupVisible: false
        };
    }

    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
    }

    handleOutsideClick(e) {
        if (!this.node) {
            return;
        }
        this.handleClick();
    }

    render() {
        const certificates   = this.props.certificate;
        let {t}              = this.props;
        let showCertificates = certificates.slice(0, this.props['numberCerlist'] || 1);
        let showCertificate  = _.differenceBy(certificates, showCertificates, 'key');
        let moreCertificates = certificates.length - showCertificates.length;
        return (
            <span ref={node => {
                this.node = node
            }} className="mt-2 mr-2 mb-2 certificate-list">
                    <KirokuCertificate certificate={showCertificates}/>
                    <span className="dropdown" hidden={moreCertificates === 0}>
                        <span className="kiroku-btn-data-more"
                              onClick={this.handleClick.bind(this)}>{t('common.viewMoreKindOfCertificate', {value: moreCertificates})}</span>
                        {this.state.popupVisible && (
                            <div className='dropdown-kiroku dropdown-menu headerm show' style={{
                                position: 'absolute',
                                willChange: 'top, left',
                                left: 'auto'
                            }}>
                                {showCertificate.map((certificate, i) => {
                                    return <div key={i} className='dropdown-item-kiroku'>
                                <span className="kiroku-btn-data mr-1">
                                    <span>{t(`Customer.certificate.${certificate.label}`)}</span>
                                </span>
                                    </div>
                                })}
                            </div>
                        )}
                     </span>
            </span>

        );
    }
}

export default KirokuCertificateList;