import React, {Component}        from "react";
import KirokuNotificationContent from "./KirokuNotificationContent";
import {notification}            from "../actions/Notification";
import {connect}                 from "react-redux";

@connect(state => {
    return {
        typeNotification: state.KirokuAINotification.typeNotification,
        show: state.KirokuAINotification.show,
        message : state.KirokuAINotification.message,
        isNotAutomaticCloseNotice : state.KirokuAINotification.isNotAutomaticCloseNotice,
    }
}, dispatch => {
    return {
        notification: () => {
            dispatch(notification())
        }
    }
})
class KirokuNotification extends Component {

    componentWillReceiveProps(nextProps) {
        if (nextProps.show && !nextProps.isNotAutomaticCloseNotice) {
            setTimeout(() => this.props.notification(), 3000);
        }

    }

    render() {
        return (
            <div className={this.props.show ? "on" : "off"}>
                <KirokuNotificationContent message={this.props.message} color={this.props.typeNotification}
                                           isNotAutomaticCloseNotice={this.props.isNotAutomaticCloseNotice}
                                           handleClose={() => { this.props.notification() }}

                />
            </div>
        )
    }
}

export default KirokuNotification;
