import React, {Component}                                             from 'react';
import {connect}                                                      from 'react-redux';
import {checkAllRecords, deleteRecords, resetRecords, selectedRecord} from '../../../actions/Record';
import {
    KirokuButton,
    KirokuCard,
    KirokuCardList,
    KirokuCheckBox,
    KirokuModal,
    KirokuModalBody,
    KirokuModalFooter
}                                                                     from '../../../ui/index';
import {withNamespaces}                                               from 'react-i18next';
import * as moment                                                    from "moment";
import config                                                         from '../../../config';

@withNamespaces()
@connect(state => {
    return {
        records: state.KirokuAIRecord.records,
        disableButtonApply: state.KirokuAIRecord.disableButtonApply,
        statusCheck: state.KirokuAIRecord.statusCheck,
        params: state.KirokuAIBehavior.conditionRecords.params,
    };

}, dispatch => {
    return {
        selectedRecord: (index) => {
            dispatch(selectedRecord(index));
        },

        checkAllRecords: (checked) => {
            dispatch(checkAllRecords(checked));
        },

        resetRecords: () => {
            dispatch(resetRecords());
        },

        deleteRecords: (records, params, tags) => {
            dispatch(deleteRecords(records, params, tags))
        }
    }
})
class ModalBulkDeleteRecord extends Component {

    state = {
        disableButtonApply: false
    };

    handleCheckAll() {
        this.props.checkAllRecords();
    }

    closeModal() {
        this.props.resetRecords();
        this.props.closeModal();
    }

    showModalDelete() {
        let records = this.props.records.filter(record => record.isChecked);
        let {params, currentTags}  = this.props;
        this.props.deleteRecords(records, params, currentTags);
        this.props.resetRecords();
        this.props.closeModal();
    }

    handleCheckRecords(index) {
        this.props.selectedRecord(index);
    }

    render() {
        const {records, t, statusCheck} = this.props;
        return (
            <div className='pt-10 d-flex justify-content-between'>
                <KirokuModal show={this.props.show}>
                    <KirokuModalBody>
                        <div id="body-modal-as">
                            <KirokuCardList
                                headerLeft={t('RecordList.selectRecord')}
                                headerRight={
                                    <div className='text-primary' style={{cursor: 'pointer'}}>
                                        <span hidden={statusCheck}
                                              onClick={this.handleCheckAll.bind(this)}
                                        >{t('common.SelectAll')}</span>

                                        <span hidden={!statusCheck}
                                              onClick={this.handleCheckAll.bind(this)}
                                        >{t('common.UnSelect')}</span>
                                    </div>
                                }
                            >
                                <section className="scroll-data-survey">
                                    {records.map((record, index) => (
                                        <KirokuCard key={record.id}>
                                            <KirokuCheckBox onChange={this.handleCheckRecords.bind(this, index)}
                                                            key={index}
                                                            checked={record.isChecked} content={record.title}/>
                                            <p className='customer-name'>{`${t('Dashboard.customerName')}: ${record.customer.name}`}</p>
                                            <p className='customer-name'>{`${t('createRecord.ServicedAt')}: ${moment.unix(record.serviced_at).format(config.formatDateTh)}`}</p>
                                        </KirokuCard>
                                    ))}
                                </section>
                            </KirokuCardList>
                        </div>
                    </KirokuModalBody>
                    <KirokuModalFooter>
                        <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                            {t('common.Cancel')}
                        </KirokuButton>
                        <KirokuButton disabled={!this.props.disableButtonApply}
                                      onClick={this.showModalDelete.bind(this)} color={'primary'}>
                            {t('common.Delete')}
                        </KirokuButton>
                    </KirokuModalFooter>
                </KirokuModal>
            </div>
        )
    }

}

export default ModalBulkDeleteRecord;
