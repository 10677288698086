export const SAVE_CONDITIONS_CUSTOMERS       = "SAVE_CONDITIONS_CUSTOMERS";
export const RESET_CONDITIONS_CUSTOMER       = "RESET_CONDITIONS_CUSTOMER";
export const LOAD_CUSTOMER_LIST_PAGE         = "LOAD_CUSTOMER_LIST_PAGE";
export const LOAD_CUSTOMER_EDITED            = "LOAD_CUSTOMER_EDITED";
export const LOAD_CUSTOMER_BY_ID             = "LOAD_CUSTOMER_BY_ID";
export const ASSIGN_SURVEYS                  = "ASSIGN_SURVEYS";
export const RESET_CUSTOMER                  = "RESET_CUSTOMER";
export const DEACTIVE_CUSTOMER               = "DEACTIVE_CUSTOMER";
export const LOAD_CUSTOMER_FOR_SURVEY        = "LOAD_CUSTOMER_FOR_SURVEY";
export const SEARCH_CUSTOMER                 = "SEARCH_CUSTOMER";
export const FILTER                          = "FILTER";
export const DELETE_CUSTOMERS                = "DELETE_CUSTOMERS";
export const UPDATE_CUSTOMER                 = "UPDATE_CUSTOMER";
export const FILTER_CUSTOMER_WITH_CONDITION  = "FILTER_CUSTOMER_WITH_CONDITION";
export const CREATE_CUSTOMER                 = "CREATE_CUSTOMER";
export const LOAD_CUSTOMER_ALL               = "LOAD_CUSTOMER_ALL";
export const LOAD_DRAFT_CUSTOMER             = "LOAD_DRAFT_CUSTOMER";
export const SAVE_DRAFT                      = "SAVE_DRAFT";
export const DISCARD_DRAFT                   = "DISCARD_DRAFT";
export const DEFAULT_CUSTOMER                = "DEFAULT_CUSTOMER";
export const UPLOAD_DOCUMENT                 = "UPLOAD_DOCUMENT";
export const FILTER_BY_CONDITION_PAGE_DETAIL = "FILTER_BY_CONDITION_PAGE_DETAIL";
export const PIN_CUSTOMER                    = "PIN_CUSTOMER";
export const GET_CUSTOMERS_BETA              = "GET_CUSTOMERS_BETA";

export function pinCustomer(customer) {
    return {
        type: PIN_CUSTOMER,
        customerId: customer.id
    }
}
export function getCustomersBeta() {
    return {
        type: GET_CUSTOMERS_BETA,
    }
}

export function filterByCondition(params) {
    return {
        type: FILTER_BY_CONDITION_PAGE_DETAIL,
        params
    }
}

export function uploadDocument(document) {
    return {
        type: UPLOAD_DOCUMENT,
        document
    }
}

export function loadDocuments(params) {
    return {
        type: 'LOAD_DOCUMENT',
        params
    }
}

export function discard(typeDraft, customerId) {
    return {
        type: DISCARD_DRAFT,
        typeDraft,
        customerId
    }
}

export function saveDraft(typeDraft, draft) {
    return {
        type: SAVE_DRAFT,
        typeDraft: typeDraft,
        draft: draft
    }
}

export function loadCustomerFromDraft(typeDraft, customerId) {
    return {
        type: LOAD_DRAFT_CUSTOMER,
        typeDraft: typeDraft,
        customerId: customerId,
        loginRequired: true,
    }
}

export function loadCustomer() {
    return {
        type: LOAD_CUSTOMER_LIST_PAGE,
        loginRequired: true,
    }
}

export function loadCustomerAll() {
    return {
        type: LOAD_CUSTOMER_ALL,
        loginRequired: true,
    }
}

export function loadCustomerById(id, isDetail) {
    return {
        type: LOAD_CUSTOMER_BY_ID,
        loginRequired: true,
        id,
        isDetail
    }
}

export function assignSurveys(customerId, surveyId) {
    return {
        type: ASSIGN_SURVEYS,
        loginRequired: true,
        customerId,
        surveys: surveyId
    }
}

export function resetCustomer() {
    return {
        type: RESET_CUSTOMER,
        loginRequired: true,
    }
}

export function deactiveCustomer(id, params, tags) {
    return {
        type: DEACTIVE_CUSTOMER,
        loginRequired: true,
        id,
        params,
        tags
    }
}

export function loadCustomerForSurvey() {
    return {
        type: LOAD_CUSTOMER_FOR_SURVEY,
        loginRequired: true
    }
}

export function onSearch(keywords) {
    return {
        type: SEARCH_CUSTOMER,
        keywords: keywords
    }
}

export function onFilter(filterBy) {
    return {
        type: FILTER,
        filterBy: filterBy
    }
}

export function deleteCustomers(listCustomerId) {
    return {
        type: DELETE_CUSTOMERS,
        listCustomerId: listCustomerId
    }
}

export function handleEditCustomer(customerId, customerInformation) {
    return {
        type: UPDATE_CUSTOMER,
        customerId: customerId,
        customerInformation: customerInformation
    }
}

export function filterCustomerWithCondition(params, tags) {
    return {
        type: FILTER_CUSTOMER_WITH_CONDITION,
        params: params,
        tags
    }
}

export function createCustomer(customer) {
    return {
        type: CREATE_CUSTOMER,
        customer: customer
    }
}

export function saveConditionsCustomer(conditions, params) {
    return {
        type: SAVE_CONDITIONS_CUSTOMERS,
        conditions, params
    }
}


export function resetConditionsCustomer() {
    return {
        type: RESET_CONDITIONS_CUSTOMER
    }
}
