import React, {Component}                                              from 'react';
import {Link}                                                          from 'react-router-dom';
import ModalDeactiveCustomers                                          from '../ModalDeactiveCustomers';
import '../customers.css'
import {
    KirokuButton,
    KirokuCard,
    KirokuCardAction,
    KirokuCardList,
    KirokuCheckBox,
    KirokuDropdownClose,
    KirokuInputSearch,
    KirokuInputSearcher,
    KirokuLoading,
    KirokuModal,
    KirokuModalBody,
    KirokuModalFooter,
    KirokuModalHeader,
    KirokuPagination,
    KirokuPopoverItem,
    KirokuSectionDropdownShowMoreMobile,
    KirokuTitlepage
} from '../../../ui';
import {connect}                                                       from 'react-redux';
import {filterCustomerWithCondition, onSearch, saveConditionsCustomer} from '../../../actions/Customer';
import ModalSelectCustomers                                            from '../ModalSelectCustomers';
import {currentPage}                                                   from '../../../actions/CurrentPage';
import {withNamespaces}                                                from 'react-i18next';
import {filePathService, scrollService}                                from "../../../services";
import _                                                               from "lodash";
import KirokuResetFilterCondition                                      from '../../../ui/KirokuResetFilterCondition';
import withPermissions                                                 from "../../HOC/WithPermissions";
import ButtonComponent                                                 from "../../HOC/ButtonComponent";
import TabResult                                                       from "../../Staffs/StaffEditor/TabResult";

class Action extends Component {

    constructor() {
        super();
        this.handleClick        = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            popupVisible: false
        };
    }

    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible
        }));
    }

    handleOutsideClick() {
        if (!this.node) {
            return;
        }
        this.handleClick();
    }

    render() {
        return (
            <div hidden={this.props.hidden} className="action-customer-surveys" ref={node => {
                this.node = node
            }}>
                <KirokuCardAction surveyId={this.props.surveyId}>
                    <div className="action-icon">
                        <i style={{color: '#1991eb'}} className="fas fa-ellipsis-h"
                           onClick={this.handleClick.bind(this)}/>
                    </div>
                    {this.state.popupVisible && (
                        <div onClick={this.handleClick.bind(this)}>
                            {this.props.children}
                        </div>
                    )}
                </KirokuCardAction>
            </div>
        )
    }
}

const BulkActionResetFilter = withPermissions(KirokuResetFilterCondition);
const BulkAction            = withPermissions(KirokuDropdownClose);
const ButtonAdd             = withPermissions(ButtonComponent);

@connect(state => {
    return {
        conditions: state.KirokuAIBehavior.conditionsCustomer.conditions,
        params: state.KirokuAIBehavior.conditionsCustomer.params,
        loading: state.KirokuAICustomer.loading,
        profile: state.KirokuAICustomer.profile,
        customersReducer: state.KirokuAICustomer,
        totalPages: state.KirokuAICustomer.totalPages,
        currentPage: state.KirokuAICustomer.currentPage,
        defaultTeamTags: state.KirokuAICustomer.defaultTeamTags,
        defaultUserTags: state.KirokuAICustomer.defaultUserTags,
        isLoadingAPI   : state.Loading
    }
}, dispatch => {
    return {
        onSearch: (keyword) => {
            dispatch(onSearch(keyword))
        },
        filterCustomerWithCondition: (params, tags) => {
            dispatch(filterCustomerWithCondition(params, tags))
        },
        currentPageUsed: (name) => {
            dispatch(currentPage(name))
        },
        saveConditionsCustomer: (conditions, params) => {
            dispatch(saveConditionsCustomer(conditions, params))
        },
        updateCustomersSuccess: () => {
            dispatch({
                type: "UPDATE_CUSTOMER_SUCCESS"
            })
        }
    };
})
@withNamespaces()

class CustomerListMobile extends Component {
    page = {
        title: <KirokuTitlepage title={this.props.t('common.Customer')}/>
    };

    state = {
        show: false,
        customers: [],
        customerRecords: [],
        statusCheck: false,
        showModalDeactive: false,
        check: false,
        deleteCustomer: false,
        statusAction: false,
        pageMin: 1,
        page: 1,
        name: '',
        status: 'active',
        defaultChecked: false,
        namePage: this.page,
        disabledPrev: true,
        disabledNext: false,
        isDeactive: false,
        conditions: [],
        currentUserTags: [],
        currentTeamTags: []
    };

    checkDisable = (customerRecords) => {
        return customerRecords.filter(customer => customer.isChecked).length;
    };

    bulkActionItems = [
        {
            value: 'deactive',
            component: (<div>{this.props.t('common.deactive')}</div>),
            label: this.props.t('common.deactive')
        }
    ];

    filterItems = [
        {
            value: 'active',
            component: (<div>{this.props.t('common.active')}</div>),
            label: this.props.t('common.active')
        },
        {
            value: 'deactive',
            component: (<div>{this.props.t('common.deactive')}</div>),
            label: this.props.t('common.deactive')
        }
    ];


    componentWillMount() {
        let defaultTags = [{
            ...this.props.profile,
            tagId: this.props.profile.id,
            key: this.props.profile.id,
            label: this.props.profile.name,
            value: this.props.profile.name,
            tagType: 'users'
        }];
        if (this.props.conditions.length) {
            this.setState({
                    currentUserTags: this.props.defaultUserTags ? this.props.defaultUserTags : [],
                    currentTeamTags: this.props.defaultTeamTags ? this.props.defaultTeamTags : [],
                    customers: this.props.customers,
                    customerRecords: this.props.listAllCustomer,
                    totalPages: this.props.totalPages,
                    currentPage: this.props.currentPage,
                    status: this.props.params.status,
                    page: this.props.params.page,
                    name: this.props.params.name,
                    groupedUserOptions:
                        [
                            {
                                label: 'Users',
                                options: this.props.users,
                            }
                        ],
                    groupedTeamOptions: [
                        {
                            label: 'Teams',
                            options: this.props.teams
                        }
                    ]
                }, () => {
                    this.filter();
                    this.saveConditionsCustomers();
                }
            )
        } else {
            this.setState({
                currentUserTags: this.props.profile.role === "admin" ? [] : defaultTags,
                groupedUserOptions:
                    [
                        {
                            label: 'Users',
                            options: this.props.users,
                        }
                    ],
                groupedTeamOptions: [
                    {
                        label: 'Teams',
                        options: this.props.teams
                    }
                ],
                customers: this.props.customers,
                customerForSearch: this.props.listAllCustomer,
                customerRecords: this.props.listAllCustomer,
                status: this.props.params.status
            }, () => {
                this.filter();
                this.saveConditionsCustomers();
            });

        }
        this.props.currentPageUsed(this.state.namePage);
    }

    handleCheckAll() {
        let {customerForSearch, customerRecords} = this.state;
        let newCustomerRecord                    = customerRecords.map(customer => ({
            ...customer,
            isChecked: !this.state.statusCheck,
        }));
        let customerChecked                      = newCustomerRecord.map(item => item.id || []);
        let newCustomerAssign                    = customerForSearch.map(customer => {
            if (customerChecked.includes(customer.id)) {
                return {
                    ...customer,
                    isChecked: !this.state.statusCheck,
                }
            }
            return {
                ...customer
            };
        });
        this.setState({
            customerForSearch: newCustomerAssign,
            customerRecords: newCustomerRecord,
            statusCheck: !this.state.statusCheck,
        })
    }

    handleCheckSurveys(customerId) {
        let {customerForSearch, customerRecords} = this.state;
        let newCustomerRecords                   = customerRecords.map(customer => ({...customer}));
        let customer                             = _.find(newCustomerRecords, customer => customer.id === customerId);
        customer.isChecked                       = !customer.isChecked;
        let searchCustomers                      = customerForSearch;
        let customersAssign                      = _.find(searchCustomers, customer => customer.id === customerId);
        customersAssign.isChecked                = !customersAssign.isChecked;
        let checked                              = _.filter(newCustomerRecords, (customer) => !customer.isChecked);
        this.setState({
            customerForSearch: searchCustomers,
            customerRecords: newCustomerRecords,
            statusCheck: !checked.length,
        });
    }

    defaultState = (customers) => {
        return customers.map(customer => ({
            ...customer,
            isChecked: false
        }))
    };

    showModalDeactive() {
        this.setState({
            showModalDeactive: !this.state.showModalDeactive,
            show: !this.state.show
        })
    }

    countCustomers() {
        let customers = this.state.customerRecords.filter(customer => customer.isChecked);

        return {
            name: customers.map(customer => customer.name).join(', '),
            id: customers.map(customer => customer.id)
        }
    }

    onChangeSearch(e) {
        let input     = e.target.value.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
        let customers = this.state.customerForSearch.filter(customer =>
            customer.name.toLowerCase().search(input.toLowerCase()) !== -1
        );
        let checked   = _.filter(customers, (customer) => !customer.isChecked);
        this.setState({customerRecords: customers, statusCheck: !checked.length});
    }

    closeModal() {
        let defaultState = this.defaultState(this.state.customerForSearch);
        let defaultStateCustomerRecord = this.defaultState(this.state.customerRecords);
        this.setState({
            show: false,
            check: this.checkDisable(defaultState),
            customerForSearch: defaultState,
            customerRecords: defaultStateCustomerRecord,
            statusCheck: false
        })
    }

    onCloseModalDeactiveCustomer() {
        this.setState({
            showModalDeactive: !this.state.showModalDeactive,
            customerRecords: this.defaultState(this.state.customerRecords),
            defaultChecked: true
        })
    }

    onCloseModalSelectCustomer() {
        this.setState({
            deleteCustomer: !this.state.deleteCustomer
        })
    }

    onSearch(e) {
        this.setState({
            name: e.target.value,
            page: 1,
            params: {
                ...this.state.params,
                name: e.target.value,
            }
        })
    }

    filterStatusCustomer(status) {
        if (status === 'deactive') {
            this.setState({
                page: 1,
                isDeactive: true,
                status: status || ''
            }, () => this.filter())
        } else {
            this.setState({
                page: 1,
                isDeactive: false,
                status: status || ''
            }, () => this.filter())
        }
    }

    filter() {
        let currentTags  = this.state.currentUserTags.concat(this.state.currentTeamTags);
        let params = {
            name: this.state.name,
            status: this.state.status,
            page: this.state.page
        };
        this.props.filterCustomerWithCondition(params, {tags: currentTags});
    }

    increasePage() {
        let nextPage = this.props.currentPage === this.props.totalPages ? this.props.totalPages : this.props.currentPage + 1;
        scrollService.top();
        this.setState({
            page: nextPage,
        }, () => {
            this.filter();
            this.saveConditionsCustomers();
        });
    }

    decreasePage() {
        let previousPage = this.props.currentPage === this.state.pageMin ? 1 : this.props.currentPage - 1;
        scrollService.top();
        this.setState({
            page: previousPage,
        }, () => {
            this.filter();
            this.saveConditionsCustomers();
        });
    }

    saveConditionsCustomers() {
        let params = {
            name: this.state.name,
            status: this.state.status,
            page: this.state.page
        };
        this.props.saveConditionsCustomer(this.makeConditions(params), params);
    }

    makeConditions = (params) => {
        let conditions = Object.keys(params).map(key => {
            if (params[key]) {
                return {[key]: params[key], query: key, value: params[key]}
            }
            return '';
        });
        return conditions.filter(condition => condition !== '');
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.customersReducer.isFilter) {
            this.setState({
                    customers: nextProps.customers,
                    selected: false,
                    customerForSearch: nextProps.listAllCustomer,
                    customerRecords: nextProps.listAllCustomer,
                    currentUserTags : nextProps.defaultUserTags,
                    currentTeamTags : nextProps.defaultTeamTags
                }, () =>
                    this.props.updateCustomersSuccess()
            );
        }
        if (this.props.params !== nextProps.params) {
            this.setState({
                name: nextProps.params.name,
                status: nextProps.params.status,
                isDeactive: nextProps.params.status === 'deactive',
            })
        }
    }

    handlerSubmitSearch() {
        this.setState({
            page: 1
        }, () => {
            this.filter()
        });
        this.saveConditionsCustomers();
    }

    isSelect() {
        return !this.state.customerRecords.filter(item => item.isChecked).length
    }

    handleChangeUserTags = (options) => {
        this.setState({
            currentUserTags: options ? options : this.state.defaultOption
        })
    };

    handleChangeTeamTags = (options) => {
        this.setState({
            currentTeamTags : options ? options : this.state.defaultOption
        })
    };

    handleSearchTags() {
        const currentTags  = this.state.currentUserTags.concat(this.state.currentTeamTags);
        if (currentTags.length) {
            let conditionTag = currentTags.map(tag => {
                return {
                    ...tag,
                    key: tag.id,
                    label: tag.name,
                    value: `${tag.name}${tag.id}`,
                    tagId: tag.id || tag.key,
                    tagType: tag.tagType
                }
            });
            let params       = {
                name: this.state.name,
                status: this.state.status,
                page: 1
            };
            this.props.filterCustomerWithCondition(params, {tags: conditionTag});
            this.setState({
                page: 1
            }, () => {
                this.saveConditionsCustomers();
            })
        } else {
            this.setState({
                page: 1
            }, () => {
                this.filter()
            });
            this.saveConditionsCustomers();
        }
    }

    render() {
        let {customers, customerRecords, name, status, page, currentUserTags, currentTeamTags} = this.state;
        let currentTags  = currentUserTags.concat(currentTeamTags);
        let params = {
            name: name,
            status: status,
            page: page
        };
        let {t}                          = this.props;
        return (
            <div>
                <div className='d-flex justify-content-between pb-2'>
                    <BulkActionResetFilter
                        resource-permission={'|customerList|button-filterDeActive|'}
                        title={t(`common.${this.state.status}`)}
                        defaultValue={t('common.active')}
                        items={this.filterItems}
                        onSelect={event => {
                            this.filterStatusCustomer(event.kirokuSelected.value);
                        }}
                    />
                    <Link to={'/customers/customer-creator'}>
                        <ButtonAdd
                            resource-permission={'|customerList|button-new|'}
                            color={'primary'}>{t('Customer.AddCustomer')}
                        </ButtonAdd>
                    </Link>
                </div>
                <div className={'pb-2'}>
                    <KirokuSectionDropdownShowMoreMobile
                        isSearchTags
                        tagType={'Team'}
                        onChange={this.handleChangeTeamTags.bind(this)}
                        value={this.state.currentTeamTags || []}
                        options={this.state.groupedTeamOptions}
                        handleSearchTags={this.handleSearchTags.bind(this)}
                        placeholder={t('Team.placeholderSearch')}
                    />
                </div>
                <div className={'pb-2'}>
                    <KirokuSectionDropdownShowMoreMobile
                        isSearchTags
                        tagType={'Staff'}
                        onChange={this.handleChangeUserTags.bind(this)}
                        value={this.state.currentUserTags || []}
                        options={this.state.groupedUserOptions}
                        handleSearchTags={this.handleSearchTags.bind(this)}
                        placeholder={t('staff.placeholderSearch')}
                    />
                </div>

                < KirokuInputSearcher
                    value={this.state.name}
                    onSubmit={this.handlerSubmitSearch.bind(this)}
                    onChange={this.onSearch.bind(this)}
                    id={'customerInputSearcherMobile'}
                />
                <div hidden={!customers.length}>
                    <div className='d-flex justify-content-between pt-2'>
                        <div hidden={this.state.isDeactive}>
                            <BulkAction
                                resource-permission={'|customerList|button-bulkAction|'}
                                title={t('common.BulkAction')}
                                items={this.bulkActionItems}
                                location={'bot'}
                                onSelect={event => {
                                    if (event.kirokuSelected.value === 'deactive') {
                                        this.setState({
                                            show: !this.state.show
                                        })
                                    }
                                }}
                            />
                        </div>
                        <div/>
                        <KirokuPagination
                            className={'pb-2'}
                            disabledPrev={this.state.pageMin === this.props.currentPage}
                            disabledNext={this.props.currentPage === this.props.totalPages}
                            increasePage={this.increasePage.bind(this)}
                            decreasePage={this.decreasePage.bind(this)}
                            currentPage={this.props.currentPage}
                            totalPage={this.props.totalPages}
                        />
                    </div>
                </div>
                < div
                    id='card-customer'
                    className='pt-2'>
                    < KirokuCardList
                        headerLeft={
                            <span>{t('common.Name')}</span>
                        }
                        headerRight={
                            <span
                                hidden={this.state.isDeactive || this.props.profile.role === "staff"}>{t('common.Action')}</span>
                        }
                    >
                        {
                            !this.props.isLoadingAPI ?
                                <div>
                                    {customers.length ?
                                        <div>
                                            {customers.map(customer => {
                                                let translateCertificate = customer.certificate.map(item => {
                                                    return t(`Customer.certificate.${item}`)
                                                });
                                                let certificate          = translateCertificate.join(', ');
                                                return <KirokuCard
                                                    key={customer.id}
                                                    action={
                                                        !this.state.isDeactive || this.props.profile.role === "staff" ?
                                                            <Action
                                                                customerId={customer.id}>
                                                                <KirokuPopoverItem>
                                                                    <div
                                                                        className='d-flex justify-content-between p-1 fix-icon-align'>
                                                                        <Link to={`/customers/customer/${customer.id}`}>
                                                                            <i className="fas fa-pencil-alt"/><span
                                                                            className='ml-1'>{t('common.Edit')}</span>
                                                                        </Link>
                                                                        <Link
                                                                            to={`/customers/customer-duplicate/${customer.id}`}>
                                                                            <i className="fas fa-clone"/><span
                                                                            className='ml-1'>{t('common.Duplicate')}</span>
                                                                        </Link>
                                                                    </div>
                                                                </KirokuPopoverItem>
                                                            </Action> : ''
                                                    }
                                                >

                                                    {!this.state.isDeactive ? <Link
                                                        to={`/customers/customer-detail/${customer.id}`}>{customer.name}
                                                        {/*<KirokuCheckDraft draftable={customer}/>*/}
                                                    </Link> : customer.name}
                                                    <p>{certificate}</p>
                                                </KirokuCard>
                                            })}
                                        </div>
                                        : <div className={'mt-2'}>
                                            <TabResult messageFirst={'customerForTag1'} messageSecond={'customerForTag2'}>
                                                <img src={`${filePathService.resolveFilePath('empty-survey.svg')}`}
                                                     alt="search fail"/>
                                            </TabResult>
                                    </div>
                                    }
                                </div> : <KirokuLoading/>
                        }
                    </KirokuCardList>

                </div>
                <div hidden={!customers.length}>
                    <div className='d-flex justify-content-between pt-2'>
                        <div hidden={this.state.isDeactive}>
                            <BulkAction
                                resource-permission={'|customerList|button-bulkAction|'}
                                location={'top'}
                                title={t('common.BulkAction')}
                                items={this.bulkActionItems}
                                onSelect={event => {
                                    if (event.kirokuSelected.value === 'deactive') {
                                        this.setState({
                                            show: !this.state.show
                                        })
                                    }
                                }}
                            />
                        </div>
                        <div/>
                        <KirokuPagination
                            disabledPrev={this.state.pageMin === this.props.currentPage}
                            disabledNext={this.props.currentPage === this.props.totalPages}
                            increasePage={this.increasePage.bind(this)}
                            decreasePage={this.decreasePage.bind(this)}
                            currentPage={this.props.currentPage}
                            totalPage={this.props.totalPages}
                        />
                    </div>
                </div>
                <KirokuModal show={this.state.show}
                             onCloseModal={this.closeModal.bind(this)}>
                    <KirokuModalHeader>
                        <h6 className="mb-3">{t('Customer.ChooseCustomersToDeactive')}</h6>
                        <KirokuInputSearch onChange={this.onChangeSearch.bind(this)}
                                           placeholder={t('Customer.EnterCustomersName')}/>
                    </KirokuModalHeader>
                    <KirokuModalBody>
                        <div id="body-modal-as">
                            <KirokuCardList
                                headerLeft={t('Customer.CustomerList')}
                                headerRight={
                                    <div className='text-primary' style={{cursor: 'pointer'}}>
                                        <span hidden={this.state.statusCheck}
                                              onClick={this.handleCheckAll.bind(this)}>{t('common.SelectAll')}</span>
                                        <span hidden={!this.state.statusCheck}
                                              onClick={this.handleCheckAll.bind(this)}>{t('common.UnSelect')}</span>
                                    </div>
                                }
                            >
                                <section className="scroll-data-customer">
                                    {customerRecords.map((customer, index) => (
                                        <KirokuCard key={customer.id}>
                                            <KirokuCheckBox
                                                key={index}
                                                onChange={this.handleCheckSurveys.bind(this, customer.id)}
                                                checked={customer.isChecked || false}
                                                content={customer.name}/>
                                        </KirokuCard>
                                    ))}
                                </section>
                            </KirokuCardList>
                        </div>
                    </KirokuModalBody>
                    <KirokuModalFooter>
                        <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                            {t('common.Cancel')}
                        </KirokuButton>
                        <KirokuButton disabled={this.isSelect()}
                                      onClick={this.showModalDeactive.bind(this)}
                                      color={'primary'}>
                            {t('common.Apply')}
                        </KirokuButton>
                    </KirokuModalFooter>
                </KirokuModal>

                <ModalDeactiveCustomers
                    tags={currentTags}
                    params={params}
                    onCloseModalDeactiveCustomer={this.onCloseModalDeactiveCustomer.bind(this)}
                    customers={this.countCustomers()}
                    countCustomer={this.state.check}
                    show={this.state.showModalDeactive}/>
                <ModalSelectCustomers
                    onCloseModalSelectCustomer={this.onCloseModalSelectCustomer.bind(this)}
                    show={this.state.deleteCustomer}/>
            </div>
        )
    }
}

export default CustomerListMobile;
