import React, {Component}                               from 'react';
import {Link}                                           from 'react-router-dom';
import {withNamespaces}                                 from 'react-i18next';
import {currentPage}                                    from '../../../actions/CurrentPage';
import KirokuTable                                      from '../../../ui/KirokuTabLe';
import THeader                                          from '../../../ui/THeader';
import TBody                                            from '../../../ui/TBody';
import KirokuTitlepage                                  from '../../../ui/KirokuTitlepage';
import KirokuCardWeb                                    from '../../../ui/KirokuCardWeb';
import KirokuPagination                                 from '../../../ui/KirokuPagination';
import KirokuActionPage                                 from '../../../ui/KirokuActionPage';
import {KirokuInputSearcher, KirokuLoading}             from '../../../ui';
import {scrollService}                                  from "../../../services";
import connect                                          from 'react-redux/es/connect/connect';
import {loadTeam, loadTeamsForPage, saveConditionsTeam} from '../../../actions/Team';
import withPermissions                                  from "../../HOC/WithPermissions";
import LinkComponent                                    from "../../HOC/LinkComponent";
import ThComponent                                      from "../../HOC/ThComponent";
import TdComponent                                      from "../../HOC/TdComponent";

const LinkButton = withPermissions(LinkComponent);
const Th         = withPermissions(ThComponent);
const Td         = withPermissions(TdComponent);
const ActionPage = withPermissions(KirokuActionPage);


@withNamespaces()
@connect(state => {
    return {
        teams: state.KirokuAITeam.teams,
        currentPage: state.KirokuAITeam.currentPage,
        totalPage: state.KirokuAITeam.totalPage,
        conditions: state.KirokuAIBehavior.conditionsTeam.conditions,
        params: state.KirokuAIBehavior.conditionsTeam.params,
        loading: state.Loading,
        profile: state.KirokuAITeam.profile,
    }
}, dispatch => {
    return {
        pageNow: (name) => {
            dispatch(currentPage(name))
        },

        loadTeam: () => {
            dispatch(loadTeam())
        },
        loadTeamForPage: (params) => {
            dispatch(loadTeamsForPage(params))
        },
        saveConditionsTeam: (conditions, params) => {
            dispatch(saveConditionsTeam(conditions, params))
        }

    }
})

class TeamListWeb extends Component {

    page = {
        title: <KirokuTitlepage title={this.props.t('Team.Team')}/>,
        actionPage:
            <Link to={'/teams/team-creator'}>
                <ActionPage
                    resource-permission={'|teamList|button-new|'}
                >
                    {this.props.t('Team.AddTeam')}
                </ActionPage>
            </Link>
    };

    state = {
        namePage: this.page,
        team: [],
        pageMin: 1,
        page: 1,
        disabledPrev: true,
        disabledNext: false,
        selected: false,
        name: ''
    };

    componentWillMount() {
        if (this.props.conditions.length) {
            this.setState({
                page: this.props.params.page,
                name: this.props.params.name
            });
            return this.props.loadTeamForPage(this.props.params);

        }
        this.props.loadTeamForPage({page: 1});
        this.props.pageNow(this.state.namePage);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.params !== this.props.params) {
            this.setState({
                page: nextProps.params.page,
                name: nextProps.params.name
            })
        }
    }

    reloadListTeam() {
        let {namePage, team, disabledPrev, disabledNext, selected, pageMin, ...currentState} = this.state;
        this.props.loadTeamForPage(currentState);
        this.saveConditions();
    }

    onSearch(e) {
        this.setState({
            name: e.target.value,
            page: 1
        })
    }

    makeConditions = (params) => {
        let conditions = Object.keys(params).map(key => {
            if (params[key]) {
                return {[key]: params[key], query: key, value: params[key]}
            }
            return '';
        });

        return conditions.filter(condition => condition !== '');
    };

    saveConditions() {
        let {namePage, team, disabledPrev, disabledNext, selected, pageMin, ...currentState} = this.state;
        this.props.saveConditionsTeam(this.makeConditions(currentState), currentState)
    }

    increasePage() {
        let nextPage = this.props.currentPage === this.props.totalPage ? this.props.totalPage : this.props.currentPage + 1;
        if (nextPage === this.props.totalPage) {
            this.setState({
                disabledNext: !this.state.disabledNext
            })
        }
        scrollService.top();
        this.setState({
            page: nextPage,
            disabledPrev: false,
            selected: false
        }, () => this.reloadListTeam());
    }

    decreasePage() {
        let previousPage = this.props.currentPage === this.state.pageMin ? 1 : this.props.currentPage - 1;
        if (previousPage === this.state.pageMin) {
            this.setState({
                disabledPrev: true
            })
        }
        scrollService.top();
        this.setState({
            page: previousPage,
            disabledNext: false,
            selected: false
        }, () => this.reloadListTeam());
    }

    handlerSubmitSearch() {
        this.setState({page: 1}, () => {
            this.reloadListTeam();
            this.saveConditions();
        })
    }

    render() {
        let {t}     = this.props;
        const teams = this.props.teams;
        return (
            <div id='web'>
                <div className='pt-12 d-flex justify-content-between'>
                    <div/>
                    <div>
                        <KirokuPagination
                            className={'pb-2'}
                            disabledPrev={this.props.currentPage === 1}
                            disabledNext={this.props.currentPage === this.props.totalPage}
                            increasePage={this.increasePage.bind(this)}
                            decreasePage={this.decreasePage.bind(this)}
                            currentPage={this.props.currentPage}
                            totalPage={this.props.totalPage}
                        />
                    </div>
                </div>
                <KirokuCardWeb>
                    <div className='d-flex justify-content-between p-3'>
                        <h5 className=''>{t('Team.TeamsList')}</h5>
                        <KirokuInputSearcher
                            value={this.state.name}
                            onSubmit={this.handlerSubmitSearch.bind(this)}
                            onChange={this.onSearch.bind(this)}
                            id={'inputSearchWebTeam'}
                        />
                    </div>
                    {!this.props.loading ?
                        <KirokuTable>
                            <THeader>
                                <tr>
                                    <th style={{minWidth: 150}}>{t('common.Name')}</th>
                                    <th>{t('common.Description')}</th>
                                    <th style={{minWidth: 100}}>{t('common.Staffs')}</th>
                                    <Th resource-permission={'|teamList|table-th|'}
                                        className='text-center'
                                        style={{minWidth: 250}}>
                                        {t('common.Action')}
                                    </Th>
                                </tr>
                            </THeader>
                            <TBody>
                            {teams.map(team =>
                                <tr key={team.id}>
                                    <td>
                                        <Link to={`/teams/team-detail/${team.id}`}>{team.name}</Link>
                                    </td>
                                    <td>{team.description}</td>
                                    <td>{team.users.length}</td>
                                    <Td className='fix-center' resource-permission={'|teamList|table-td|'}>
                                        <div className='d-flex justify-content-between p-1 fix-icon-align'>
                                            <LinkButton
                                                resource-permission={'|teamList|button-edit|'}
                                                href={`/teams/edit/${team.id}`}
                                                icon={<i className="fas fa-pencil-alt text-secondary"/>}
                                                text={t('common.Edit')}
                                                color={'text-dark'}
                                            />
                                        </div>
                                    </Td>
                                </tr>
                            )}
                            </TBody>
                        </KirokuTable>
                        : <KirokuLoading/>}
                </KirokuCardWeb>

                <div className='pt-10 d-flex justify-content-between'>
                    <div/>
                    <div>
                        <KirokuPagination
                            disabledPrev={this.props.currentPage === 1}
                            disabledNext={this.props.currentPage === this.props.totalPage}
                            increasePage={this.increasePage.bind(this)}
                            decreasePage={this.decreasePage.bind(this)}
                            currentPage={this.props.currentPage}
                            totalPage={this.props.totalPage}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default TeamListWeb;
