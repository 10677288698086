import React, {Component}        from 'react';
import KirokuModal               from "../../ui/KirokuModal";
import KirokuModalHeader         from "../../ui/KirokuModalHeader";
import KirokuModalBody           from "../../ui/KirokuModalBody";
import KirokuModalFooter         from "../../ui/KirokuModalFooter";
import KirokuButton              from "../../ui/KirokuButton";
import {connect}                 from "react-redux";
import {addLogic}                from "../../actions/SurveyTemplate";
import KirokuDropdownLogicSingle from "./KirokuDropdownLogicSingle";
import {withNamespaces}          from "react-i18next";
import _                         from "lodash";

@withNamespaces()
@connect(state => {
    return {
        questions: state.KirokuAISurveyTemplate.questions
    }
}, dispatch => {
    return {
        addLogic: (question) => {
            dispatch(addLogic(question))
        }
    }
})
class ModalAddLogicSingle extends Component {

    constructor(props) {
        super(props);
        this.state = _.cloneDeep(props.question);
        this.state.error = true;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.question !== this.props.question) {
            this.setState(_.cloneDeep(nextProps.question));

        }
    }

    dropDownItems(question, questions) {
        return questions.map((question) => {
            return {
                value: question.index,
                component: (<div>
                    <div><span className={'text-muted'}>{question.content}</span></div>
                </div>),
                label: question.content
            }
        });
    }

    handleSelect = (e, index) => {
        let {choices} = this.state;
        let choice = choices[index];
        choices[index] = {
            ...choice,
            nextQuestion: e.kirokuSelected.value
        };
        this.setState({
            ...this.state,
            choices: [...choices]
        })
    };

    validate = () => {
        let {choices} = this.state;
        let error = choices.every(choice => choice.hasOwnProperty('nextQuestion'));
        this.setState({
            error: error
        }, () => this.handleConfirm())
    };

    handleConfirm = () => {
        let {error, ...question} = this.state;
        if (error) {
            this.props.addLogic({...question});
            this.props.closeModal();
        }
    };

    handleCancel() {
        this.setState({
            ..._.cloneDeep(this.props.question)
        }, () => {
            this.props.closeModal();
        });
    }

    render() {
        const {t} = this.props;
        const question = this.props.question;
        const questions = this.props.questions;
        const items = this.dropDownItems(question, questions);
        const {choices} =  this.state;
        const heightModal = choices.length > 5 ? "50vh" : "30vh";
        return (
            <div>
                <KirokuModal show={!this.props.show}>
                    <KirokuModalHeader>
                        {t('createSurvey.QuestionOrder', {index : question.index + 1})}
                    </KirokuModalHeader>
                    <KirokuModalBody style={{ overflowY: 'auto', height: heightModal}}>
                        <div className='p-3'>
                            <b>{question.heading}</b>
                            <p>{question.content}</p>
                            {
                                choices.map((choice, index) => (
                                    <div className='pb-2' key={index} >
                                        <div>{choice.answer}</div>
                                        <KirokuDropdownLogicSingle key={index} items={items}
                                                                   nextQuestion={choice.nextQuestion}
                                                                   location={index >= choices.length - 1 && choices.length >= 3 ? 'top' : ''}
                                                                   default={choice.nextQuestion}
                                                                   question={question}
                                                                   onSelect={(e) => this.handleSelect(e, index)}/>
                                    </div>

                                ))
                            }
                            <p hidden={this.state.error} style={{color: 'red', fontSize: '12px'}}>{t('createSurvey.PleaseEnterYourNextQuestion')}</p>
                        </div>
                    </KirokuModalBody>
                    <KirokuModalFooter>
                        <KirokuButton onClick={this.handleConfirm.bind(this)} color={'primary'}>
                            {t('createSurvey.Confirm')}
                        </KirokuButton>
                        <KirokuButton onClick={this.handleCancel.bind(this)} color={'white'}>
                            {t('createSurvey.Cancel')}
                        </KirokuButton>
                    </KirokuModalFooter>
                </KirokuModal>
            </div>
        );
    }
}

export default ModalAddLogicSingle;
