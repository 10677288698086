import React, {Component} from 'react';

class KirokuInputArea extends Component {

    render() {
        return (
            <textarea className={`form-area ${this.props.error}`} {...this.props} />
        );
    }

}

export default KirokuInputArea;
