import React, {Component} from 'react';
import {connect}          from 'react-redux';
import {
    KirokuButton,
    KirokuRadio
} from '../../../ui/index';
import {withNamespaces}   from 'react-i18next';
import { NOTIFICATION }   from '../../../actions/Notification';

@withNamespaces()
@connect(state => {
    return {}
}, dispatch => {
    return {
        printReport: (params, records, dateRange) => {
            dispatch({
                type: 'PRINT_REPORT',
                params,
                records,
                dateRange
            })
        },

        exportDocument: (params, records, dateRange) => {
            dispatch({
                type: 'EXPORT_DOCX',
                params,
                records,
                dateRange
            })
        },
        notification: (t) => {
            dispatch({
                type: NOTIFICATION,
                typeNotification: 'danger',
                message:  t('message.overReportDocX')
            })
        }
    }
})
class ModalPrintRecord extends Component {


    state = {
        type: 'TemplateRecord',
        // defaultCheckedTemplate: true,
        // defaultCheckedRecord: true,
        // typeReport: 'Template',
        // print: 'Record'
    };

    handleCloseModal() {
        this.props.showModal();
    }

    handleClickApply() {
        let queryPrint = this.state.type;
        let recordSelected = this.props.records.filter(item => item.isChecked);
        this.props.printReport(queryPrint, recordSelected, this.props.haveDateRange);
        this.props.showModal();
    }

    handleExportToWord() {
        let queryPrint = this.state.type;
        let recordSelected = this.props.records.filter(item => item.isChecked);
        if (recordSelected.length > 100) {
            return this.props.notification(this.props.t)
        } else {
            this.props.exportDocument(queryPrint, recordSelected, this.props.haveDateRange);
            this.props.showModal();
        }
    }

    handleCancelClick() {
        this.props.showModal();
    }

    handleCheckReport(stateReport) {
        this.setState({
            typeReport: stateReport,
            defaultCheckedTemplate: !this.state.defaultCheckedTemplate
        })
    }

    handleCheckTypeReport(type) {
        this.setState({
            print: type,
            defaultCheckedRecord: !this.state.defaultCheckedRecord
        })
    }

    handleCheckType(stateType){
        this.setState({
            type: stateType,
        })
    }

    render() {
        const {t} = this.props;
        return (
            <div hidden={this.props.show} className='padding-responsive'>
                <div className={'view-1vh'}>
                    <div className='survey-list'>
                        <div className='p-3'>
                            <button className='close' style={{float: 'left'}}
                                    onClick={this.handleCloseModal.bind(this)}>
                                <span style={{
                                    fontSize: '24px',
                                    color: '#a8aab7'
                                }}>&times;</span>
                            </button>
                            <div className='text-center'>
                                <span className='filter-record'>{t('common.ReportFilter')}</span>
                            </div>
                        </div>
                        <div className='p-2 mt-4 print-modal'>
                            <div className='wrap-print'>
                                {/*<div className="row">*/}
                                {/*    <div className='col-md-6'>*/}
                                {/*        <KirokuRadio id={'customRadio1'}*/}
                                {/*                     name={'option1'}*/}
                                {/*                     checked={this.state.defaultCheckedTemplate}*/}
                                {/*                     onChange={this.handleCheckReport.bind(this, 'Template')}>*/}
                                {/*            {t('common.filterByTemplate')}*/}
                                {/*        </KirokuRadio>*/}
                                {/*    </div>*/}
                                {/*    <div className='col-md-6'>*/}
                                {/*        <KirokuRadio*/}
                                {/*            // disabled={true}*/}
                                {/*            id={'customRadio'}*/}
                                {/*            checked={!this.state.defaultCheckedTemplate}*/}
                                {/*            name={'option1'}*/}
                                {/*            onChange={this.handleCheckReport.bind(this, 'Customer')}>*/}
                                {/*            {t('common.filterByCustomer')}*/}
                                {/*        </KirokuRadio>*/}
                                {/*    </div>*/}
                                {/*    */}
                                {/*</div>*/}
                                {/*<div className='row'>*/}
                                {/*    <div className='col-md-6'>*/}
                                {/*        <div className=''>*/}
                                {/*            <KirokuRadio*/}
                                {/*                checked={this.state.defaultCheckedRecord}*/}
                                {/*                id={'customRadio3'} name={'option2'}*/}
                                {/*                onChange={this.handleCheckTypeReport.bind(this, 'Record')}>*/}
                                {/*                {t('common.filterByRecord')}*/}
                                {/*            </KirokuRadio>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}

                                {/*    <div className='col-md-6'>*/}
                                {/*        <KirokuRadio*/}
                                {/*            id={'customRadio2'}*/}
                                {/*            name={'option2'}*/}
                                {/*            checked={!this.state.defaultCheckedRecord}*/}
                                {/*            onChange={this.handleCheckTypeReport.bind(this, 'Question')}>*/}
                                {/*            {t('common.filterByQuestion')}*/}
                                {/*        </KirokuRadio>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <div className="print-type row">
                                    <div className="col-md-6">
                                        <KirokuRadio
                                            checked={this.state.type === 'TemplateRecord'}
                                            id={'print-type-template-record'} name={'print-type'}
                                            onChange={this.handleCheckType.bind(this, 'TemplateRecord')}>
                                            {t('Report.TemplateRecord')}
                                        </KirokuRadio>
                                        <KirokuRadio
                                            checked={this.state.type === 'TemplateQuestion'}
                                            id={'print-type-template-question'} name={'print-type'}
                                            onChange={this.handleCheckType.bind(this, 'TemplateQuestion')}>
                                            {t('Report.TemplateQuestion')}
                                        </KirokuRadio>
                                        <KirokuRadio
                                            checked={this.state.type === 'TemplateOnly'}
                                            id={'print-type-template'} name={'print-type'}
                                            onChange={this.handleCheckType.bind(this, 'TemplateOnly')}>
                                            {t('Report.TemplateOnly')}
                                        </KirokuRadio>
                                    </div>
                                    <div className="col-md-6">
                                        <KirokuRadio
                                            checked={this.state.type === 'CustomerRecord'}
                                            id={'print-type-customer-record'} name={'print-type'}
                                            onChange={this.handleCheckType.bind(this, 'CustomerRecord')}>
                                            {t('Report.CustomerRecord')}
                                        </KirokuRadio>
                                        <KirokuRadio
                                            checked={this.state.type === 'CustomerQuestion'}
                                            id={'print-type-customer-question'} name={'print-type'}
                                            onChange={this.handleCheckType.bind(this, 'CustomerQuestion')}>
                                            {t('Report.CustomerQuestion')}
                                        </KirokuRadio>
                                        <KirokuRadio
                                            checked={this.state.type === 'CustomerOnly'}
                                            id={'print-type-customer'} name={'print-type'}
                                            onChange={this.handleCheckType.bind(this, 'CustomerOnly')}>
                                            {t('Report.CustomerOnly')}
                                        </KirokuRadio>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='pt-4'>
                        <div className='pr-5 pl-5 fix-center'>
                            <div className='d-flex justify-content-between'>
                                <KirokuButton color={'white'}
                                              onClick={this.handleCancelClick.bind(this)}>{t('common.Cancel')}</KirokuButton>
                                <div className={'pr-1 pl-1'}/>
                                <KirokuButton color={'primary'}
                                              onClick={this.handleClickApply.bind(this)}>{t('Report.Show')}</KirokuButton>
                                <div className={'pr-1 pl-1'}/>
                                <KirokuButton color={'primary'}
                                              onClick={this.handleExportToWord.bind(this)}>{t('Report.ExportToWord')}</KirokuButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalPrintRecord;
