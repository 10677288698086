import React, { Component } from 'react';
import {
    KirokuButton, KirokuCheckBox,
    KirokuLoading, KirokuNotification, KirokuTable, SearchNotFound,
    TBody, THeader,
}                           from '../../../../ui';
import { connect }          from 'react-redux';
import qs                   from 'query-string';
import _                    from 'lodash';
import { withNamespaces }   from 'react-i18next';
import FormatTimestamp      from '../HelperComponents/FormatTimestamp';
import { Link }             from 'react-router-dom';
import { convertArrayItem } from '../../sevicesInRecord';
import { NOTIFICATION }     from '../../../../actions/Notification';
import config               from '../../../../config';
import ModalPrintRecord     from '../../../AdminExport/NewExport/ModalPrintRecord';
@withNamespaces()
@connect(state => {
    return {
        records            : state.KirokuScreens.BatchActionRecordsBeta.records,
        sortByUpdateAt     : state.KirokuScreens.BatchActionRecordsBeta.sortByUpdateAt,
        orderByUpdateAt    : state.KirokuScreens.BatchActionRecordsBeta.orderByUpdateAt,
        sortByServiceAt    : state.KirokuScreens.BatchActionRecordsBeta.sortByServiceAt,
        orderByServiceAt   : state.KirokuScreens.BatchActionRecordsBeta.orderByServiceAt,
        useSortBy          : state.KirokuScreens.BatchActionRecordsBeta.useSortBy,
        useOrderBy         : state.KirokuScreens.BatchActionRecordsBeta.useOrderBy,
        statusRecord       : state.RecordsBeta.statusRecord,
        totalRecords       : state.RecordsBeta.totalRecords,
        customerIds        : state.RecordsBeta.customerIds,
        servicedAt         : state.RecordsBeta.servicedAt,
        updatedAt          : state.RecordsBeta.updatedAt,
        templateIds        : state.RecordsBeta.templateIds,
        updateByIds        : state.RecordsBeta.updateByIds,
        keywordsEd         : state.RecordsBeta.keywordsEd,
        loading         : state.Loading,

    };
}, dispatch => {
    return {
        loadRecords                : (body) => {
            dispatch({
                type: 'LOAD_RECORD_BETA_FOR_BATCH_ACTION',
                body,
            });
        },
        changeOrderBy                  : (sortBy, orderByNew) => {
            dispatch({
                type: 'CHANGE_ORDER_BATCH_ACTION_RECORD_BETA',
                sortBy, orderByNew,
            });
        },
        handleCheckedRecordsBatchAction: (index) => {
            dispatch({
                type: 'CHECKED_RECORDS_BATCH_ACTION',
                index,
            });
        },
        handleCheckAllRecordsBeta      : () => {
            dispatch({
                type: 'CHECKED_ALL_RECORDS_BATCH_ACTION',
            });
        },
        exportRecords                  : (records, params, tags) => {
            dispatch({
                type: 'SELECT_RECORD_NEW_EXPORT',
                records,
                params,
                tags,
            });
        },
        notification: (t) => {
            dispatch({
                type: NOTIFICATION,
                typeNotification: 'danger',
                message:  t('message.overExport')
            })
        }

    };
})
class BatchActionRecordBeta extends Component {

    state = {
        disableExport   : false,
        showModalPrint  : false,
        locationSearch  : {}
    };


    componentDidMount() {
        let locationSearch = qs.parse(this.props.location.search, config.formatParams);
        let body = {
            status       : locationSearch.status,
            customerIds  : convertArrayItem(locationSearch.customerIds),
            templateIds  : convertArrayItem(locationSearch.templateIds),
            updateByIds  : convertArrayItem(locationSearch.updateByIds),
            keywordSearch: locationSearch.keywordSearch,
            serviceAt    : {
                startDate: locationSearch.serviceAtStartDate ? parseInt(locationSearch.serviceAtStartDate) : null,
                endDate  : locationSearch.serviceAtEndDate ? parseInt(locationSearch.serviceAtEndDate) : null,
            },
            updatedAt    : {
                startDate: locationSearch.updatedAtStartDate ? parseInt(locationSearch.updatedAtStartDate) : null,
                endDate  : locationSearch.updatedAtEndDate ? parseInt(locationSearch.updatedAtEndDate) : null,
            },
            orders       : [{ field: locationSearch.ordersField, type: locationSearch.ordersType }],
            containRead  : locationSearch.containRead === 'true'
        };
        this.setState({ locationSearch });
        this.props.loadRecords(body);
    }

    handleChangeOrderBy(sortBy) {
        const { useOrderBy } = this.props;
        let orderByNew       = useOrderBy === 'ASC' ? 'DESC' : 'ASC';
        this.props.changeOrderBy(sortBy, orderByNew);
    }

    handleCheckedRecordsBatchAction(i) {
        this.props.handleCheckedRecordsBatchAction(i);
    }

    handleExportRecord() {
        const { records, t }      = this.props;
        const numberRecordsChecked = records.filter(itm => itm.isChecked).length;
        if (numberRecordsChecked > 1000) {
            this.props.notification(t);
            this.setState({
                disabledExport  : !this.state.disabledExport,
            }, () => {
                setTimeout(() => this.setState({
                    disabledExport  : false,
                }), 3000);
            });
        } else {
            this.props.exportRecords(records.filter(itm => itm.isChecked), {}, []);
        }
    }

    handleShowModalPrint() {
        this.setState({
            showModalPrint : !this.state.showModalPrint
        })
    }

    getTimeLinePrint() {
        let locationSearch = qs.parse(this.props.location.search, config.formatParams);
        let body = {
            status       : locationSearch.status,
            customerIds  : convertArrayItem(locationSearch.customerIds),
            templateIds  : convertArrayItem(locationSearch.templateIds),
            updateByIds  : convertArrayItem(locationSearch.updateByIds),
            keywordSearch: locationSearch.keywordSearch,
            serviceAt    : {
                startDate: locationSearch.serviceAtStartDate ? parseInt(locationSearch.serviceAtStartDate) : null,
                endDate  : locationSearch.serviceAtEndDate ? parseInt(locationSearch.serviceAtEndDate) : null,
            },
            updatedAt    : {
                startDate: locationSearch.updatedAtStartDate ? parseInt(locationSearch.updatedAtStartDate) : null,
                endDate  : locationSearch.updatedAtEndDate ? parseInt(locationSearch.updatedAtEndDate) : null,
            },
            orders       : [{ field: locationSearch.ordersField, type: locationSearch.ordersType }],
            containRead  : locationSearch.containRead === 'ALL'
        };
        return {
            ...body,
            servicedAt : body.serviceAt,
            updatedAt :  body.updatedAt
        }

    }

    render() {
        const {
                  records, loading, useSortBy,
                  orderByServiceAt, t, orderByUpdateAt,
              }                                  = this.props;

        const { disabledExport } = this.state;

        return (
            <div className={ 'default-background-color padding-responsive' }>
                { !loading ?
                    <div>
                        <KirokuNotification/>
                        <ModalPrintRecord
                            records={[...records]}
                            show={!this.state.showModalPrint}
                            showModal={this.handleShowModalPrint.bind(this)}
                            haveDateRange={this.getTimeLinePrint()}
                        />
                        <div hidden={this.state.showModalPrint}>
                            <div className="d-flex">
                                <div>
                                    <KirokuButton className={ 'btn-active font-text-in-batch-action' }
                                                  hidden={!records.length}
                                                  disabled={ !records.filter(itm => itm.isChecked).length || disabledExport }
                                                  onClick={ this.handleExportRecord.bind(this) }
                                    >
                                        { t('RecordsBeta.exportSelectRecords', { count: records.filter(itm => itm.isChecked).length }) }
                                    </KirokuButton>
                                </div>
                                <div className="pl-3">
                                    <KirokuButton className={ 'btn-active pl-3 font-text-in-batch-action' }
                                                  hidden={!records.length}
                                                  disabled={ !records.filter(itm => itm.isChecked).length || disabledExport }
                                                  onClick={ this.handleShowModalPrint.bind(this) }
                                    >
                                        { t('RecordsBeta.BatchActionReport', { count: records.filter(itm => itm.isChecked).length }) }
                                    </KirokuButton>
                                </div>
                            </div>
                            <div className={ 'pt-3' } hidden={!records.length}/>
                            {records.length ? <KirokuTable>
                                <THeader>
                                    <tr>
                                        <th style={ { minWidth: 50, padding: '1px 1px 17px 38px' } }>
                                            <div className={ 'checkbox-weight' }>
                                                <KirokuCheckBox content={ '' }
                                                                onChange={ () => {
                                                                    this.props.handleCheckAllRecordsBeta();
                                                                } }
                                                                checked={ _.findIndex(records, (ctm) => (!ctm.isChecked)) === -1 }
                                                /></div>
                                        </th>
                                        <th style={ { minWidth: 171 } } className={'font-text-in-batch-action'}>{ t('RecordsBeta.customerName') }</th>
                                        <th style={ { minWidth: 175 } } className={'label-name font-text-in-batch-action'}>{ t('RecordsBeta.recordTemplate') }</th>
                                        <th className="th-date-batch-action-record-beta font-text-in-batch-action"
                                            style={ { minWidth: 250 } }
                                            onClick={ this.handleChangeOrderBy.bind(this, 'serviced_at') }
                                        >
                                            <div className="d-flex">
                                                <div className='font-text-in-batch-action'>{ t('RecordsBeta.ServiceTime') }</div>
                                                <div className="p-1"/>
                                                <div
                                                    className={ `icon-order ${ useSortBy === 'serviced_at' ? 'color-blue-icon-order' : '' }` }>
                                                    <i hidden={ orderByServiceAt === 'DESC' }
                                                       className="fas fa-lg fa-angle-up"/>
                                                    <i hidden={ orderByServiceAt === 'ASC' }
                                                       className="fas fa-lg fa-angle-down"/>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="th-date-batch-action-record-beta"
                                            style={ { minWidth: 250 } }
                                            onClick={ this.handleChangeOrderBy.bind(this, 'update_at') }
                                        >
                                            <div className="d-flex">
                                                <div className={'font-text-in-batch-action'}>{ t('RecordsBeta.updatedDate') }</div>
                                                <div className="p-1"/>
                                                <div
                                                    className={ `icon-order ${ useSortBy === 'update_at' ? 'color-blue-icon-order' : '' }` }>
                                                    <i hidden={ orderByUpdateAt === 'DESC' }
                                                       className="fas fa-lg fa-angle-up"/>
                                                    <i hidden={ orderByUpdateAt === 'ASC' }
                                                       className="fas fa-lg fa-angle-down"/>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </THeader>
                                <TBody>
                                    { records.map((record, i) => {
                                        return (
                                            <tr key={ i } className={'label-record-beta'}>
                                                <td>
                                                    <div className={ 'checkbox-weight' }
                                                         style={ { padding: '1px 1px 17px 23px' } }>
                                                        <KirokuCheckBox content={ '' } checked={ !!record.isChecked }
                                                                        onChange={ this.handleCheckedRecordsBatchAction.bind(this, i) }
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <Link
                                                        to={ `/customers/customer-detail/${ record.customer_id }` }>
                                                        <span
                                                            className={ 'label-name' }>{ record.customer.name }</span>
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Link to={{
                                                        pathname: `/records-beta-detail`,
                                                        search  : `id=${record.id}`,
                                                        }} onClick={() => window.history.previous = window.location.pathname}>
                                                        <span
                                                            className={ 'label-name' }>{ record.title }</span>
                                                    </Link>
                                                </td>
                                                <td>
                                                    <FormatTimestamp timestampUnix={ record.serviced_at }
                                                                     className={'font-text-date-time-records-table'}
                                                                     hasTime={ record.has_time }/>
                                                </td>
                                                <td>
                                                    <FormatTimestamp timestampUnix={ record.last_action_at }
                                                                     className={'font-text-date-time-records-table'}
                                                                     hasTime={ true }/>
                                                </td>
                                            </tr>
                                        );
                                    }) }

                                </TBody>
                            </KirokuTable> : <div className={'default-background-color '} style={{backgroundColor: '#FFF'}}><SearchNotFound/></div> }
                        </div>
                    </div>
                    : <KirokuLoading/>
                }

            </div>
        );
    }
}

export default BatchActionRecordBeta;
