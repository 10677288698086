import React, {Component}    from 'react';
import {Link}                from 'react-router-dom';
import {connect}             from 'react-redux';
import '../../Customers/customers.css';
import {
    KirokuButton,
    KirokuLoading,
    KirokuCardWeb,
}                            from '../../../ui';
import {loginRequired}       from '../../../middleware-redux/Auth';
import {withNamespaces}      from "react-i18next"
import {loadUserById}        from "../../../actions/User";
import {filePathService}     from "../../../services";
import SpanComponent         from "../../HOC/SpanComponent";
import withPermissions       from "../../HOC/WithPermissions";
import TabTeam               from "../StaffDetail/TabTeam";
import TabProfileInformation from "../StaffDetail/TabProfileInformation";
import TabCustomer           from "../StaffList/TabCustomer";

const Span = withPermissions(SpanComponent);

@withNamespaces()
@loginRequired
@connect(state => {
    return {
        user: state.KirokuAIUser.user,
        profile: state.KirokuAIUser.profile,
        // loading: state.Loading,
        isLoading: state.KirokuAIUser.isLoading
    }
}, dispatch => {
    return {
        loadUserById: id => {
            dispatch(loadUserById(id))
        },
        initState: () => {
            dispatch({
                type: "INIT_STATE"
            })
        }
    };
})
class DetailMyProfileWeb extends Component {

    state = {
        id: '',
        showTeams: false,
        showCustomers: true,
        showProfile: true,
        statusCheck: false,
        show: false,
        checkedSurveys: false,
        loadingImg: false

    };


    componentWillMount() {
        this.setState({
            id: JSON.parse(localStorage.getItem('profile')).id
        }, () => {
            this.props.loadUserById(this.state.id);
        });
    }

    showTeams() {
        this.setState({
            showTeams: false,
            showCustomers: true,
            showProfile: true
        })
    }

    showCustomers() {
        this.setState({
            showTeams: true,
            showCustomers: false,
            showProfile: true
        })
    }

    showProfile() {
        this.setState({
            showTeams: true,
            showCustomers: true,
            showProfile: false
        })
    }

    closeModal() {
        this.setState({
            statusCheck: false,
            show: !this.state.show
        })
    }

    renderUserDetail(user) {
        const {showProfile, showCustomers, showTeams} = this.state;
        let {t}                                       = this.props;
        let qualification                             = user.qualification.map(item => {
            return t(`common.dropdown.${item}`)
        });
        let currentQualification                      = qualification.join(', ');

        return (
            <div className='padding-responsive'>
                <KirokuCardWeb>
                    <div className='p-3 row'>
                        <div className="col-md-3">
                            {!this.state.loadingImg ?
                                <div className='customer-detail height-avatar as'
                                     style={{
                                         backgroundImage: `url(${user.avatar ? user.avatar : filePathService.resolveFilePath('profile-ic.svg')})`,
                                         width: 120,
                                         height: 120
                                     }}
                                /> : <KirokuLoading/>}
                        </div>
                        <div className='col-md-6'>
                            <div className='pt-10'>
                                <div className='d-flex'>
                                    <h5 className='pr-3'>{user.name}</h5>
                                    <Span resource-permission={'|staffDetail|button-editProfile|'}>
                                        <span className='pr-10'>
                                            <i className='fas fa-pencil-alt text-secondary'/>
                                        </span>
                                        <Link to={`/edit-my-profile`}>{t('common.EditProfile')}</Link>
                                    </Span>
                                </div>
                            </div>
                            <div className="text-left">
                                <div className='mb-10'>
                                    <span className='customer-info f-bold'>
                                        <span className="mr-2">{t('staff.title')}: {user.title}</span>
                                    </span>
                                </div>
                                <div className='mb-10'>
                                    <span className='customer-info f-bold'>
                                        <span className="mr-2">{t('common.Role')}: {t(`common.${user.role}`)}</span>
                                    </span>
                                </div>
                                <div className='mb-10'>
                                    <span className='customer-info f-bold'>
                                        <span
                                            className="mr-2">{t('common.WorkingForm')}: {user['working_form'] ? t(`common.${user['working_form']}`) : ''}</span>
                                    </span>
                                </div>
                                <div className='mb-10'>
                                    <span className='customer-info f-bold'>
                                        <span
                                            className="mr-2">{t('common.Qualification')}: {currentQualification}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 as'/>
                    </div>
                    <div className='d-flex justify-content-between pt-10 pl-3' style={{width: 400}}>
                        <div hidden={this.props.profile.role === "admin"} style={{cursor: 'pointer'}}
                             className={`nav-bar ${!showTeams ? 'active' : ''}`}
                             onClick={this.showTeams.bind(this)}>{t('common.Team')}
                        </div>
                        <div hidden={this.props.profile.role === "admin"} style={{cursor: 'pointer'}}
                             className={`nav-bar ${!showCustomers ? 'active' : ''}`}
                             onClick={this.showCustomers.bind(this)}>{t('common.Customers')}
                        </div>
                        <div style={{cursor: 'pointer'}}
                             className={this.props.profile.role === "admin" ? `nav-bar active` : `nav-bar ${!showProfile ? 'active' : ''}`}
                             onClick={this.showProfile.bind(this)}>{t('common.StaffDetail')}
                        </div>
                    </div>
                </KirokuCardWeb>
            </div>
        )
    }

    componentWillUnmount() {
        this.props.initState()
    }

    handleCheckAll() {
        this.props.checkAllSurveys(this.state.checkedSurveys);
        this.setState({
            statusCheck: !this.state.statusCheck,
            checkedSurveys: !this.state.checkedSurveys
        })
    }

    assignSurvey() {
        let surveysAssign = this.state.surveysAssign.filter(survey => survey.isChecked);
        let customer      = this.props.customer;
        this.props.assignSurvey(customer.id, {
            ...customer,
            staffs: customer.staffs.map(staff => staff.id),
            surveyTemplates: surveysAssign.map(survey => survey.id)
        });
        this.setState({
            show: !this.state.show
        })
    }

    goBack() {
        this.props.history.goBack()
    }

    render() {
        const {user, t} = this.props;
        return (
            <div className='default-background-color'>
                {!this.props.isLoading ?
                    <div className='customer-detail default-background-color'>
                        <div className='d-flex justify-content-between pt-3 pl-3 pr-3'>
                            <KirokuButton onClick={this.goBack.bind(this)}
                                          color='white'>{t('common.Back')}</KirokuButton>
                        </div>
                        {this.renderUserDetail(user)}
                        <div className='pt-0 padding-responsive'>
                            <div hidden={this.props.profile.role === "admin"}>
                                <TabTeam staffId={user.id} history={this.props.history} show={this.state.showTeams}
                                         user={user.teams}/>
                            </div>
                            <div hidden={this.state.showProfile && this.props.profile.role !== "admin"}>
                                <KirokuCardWeb>
                                    <div className="p-3">
                                        {<TabProfileInformation profile={user}/>}
                                    </div>
                                </KirokuCardWeb>
                            </div>
                            <div hidden={this.props.profile.role === "admin"}>
                                <TabCustomer history={this.props.history} show={this.state.showCustomers} user={user}/>
                            </div>

                        </div>
                    </div> : <KirokuLoading/>
                }
            </div>
        )
    }
}

export default DetailMyProfileWeb;
