import React, { Component }               from 'react';
import { connect }                        from 'react-redux';
import { filePathService, scrollService } from '../../../../services';
import { withNamespaces }                 from 'react-i18next';
import KirokuLabel                        from '../../../../ui/KirokuLabel';
import FormatTimestamp                    from '../HelperComponents/FormatTimestamp';
import $                                  from 'jquery';
import { KirokuLoading }                  from '../../../../ui';
import ModalViewDifferenceQuestions       from './ModalViewDifferenceQuestions';

@connect(state => {
    return {
        historyLogs: state.RecordsBeta.historyLogs,
        logView    : state.RecordsBeta.logView,
        isLoading  : state.RecordsBeta.isLoading,
    };
}, dispatch => {
    return {
        loadRecordLogs: (id, params) => {
            dispatch({
                type: 'LOAD_HISTORY_OF_RECORD',
                id,
                params,
            });
        },
        resetLogs     : () => {
            dispatch({
                type: 'SET_INIT_RECORD_LOG',
            });
        },
        loading       : () => {
            dispatch({
                type: 'LOADING_RECORD_LOG',
            });
        },
        isOpenModalViewDifferenceQuestion: () => {
            dispatch({
                type: 'IS_OPEN_MODAL_VIEW_DIFFERENCE_QUESTION'
            })
        },
        getChangelogRecord: (recordId, logId) => {
            dispatch({
                type: 'GET_CHANGELOG_RECORD',
                recordId, logId
            })
        }
    };
})

@withNamespaces()

class HistoryTab extends Component {
    state = {
        page: 1,
    };

    renderLabel(history) {
        switch (history.action) {
            case 'create':
                return (<KirokuLabel color='primary'
                                     style={ { padding: '2px 4px' } }>{ this.props.t('common.create') }</KirokuLabel>);
            case 'update':
                return (<KirokuLabel color='success'
                                     style={ { padding: '2px 4px' } }>{ this.props.t('common.Update') }</KirokuLabel>);
            case 'delete':
                return (<KirokuLabel color='danger'
                                     style={ { padding: '2px 4px' } }>{ this.props.t('common.Delete') }</KirokuLabel>);
            case 'view':
                return (<KirokuLabel color='light'
                                     style={ { padding: '2px 4px' } }>{ this.props.t('common.ViewDetail') }</KirokuLabel>);
            default:
        }
    };

    componentWillMount() {
        scrollService.top();
        window.addEventListener('scroll', this.handleScroll.bind(this));
    }

    componentWillUnmount() {
        scrollService.top();
        this.props.resetLogs();
        window.addEventListener('scroll', this.handleScroll.bind(this));
    }

    handleScroll() {
        const { record, historyTab, isLoading } = this.props;
        let { page }     = this.state;
        if ((Math.ceil($(window).scrollTop()) >= $(document).height()- 250 - $(window).height())
            && (this.props.historyLogs.logs.length === 15) && (this.props.historyLogs.logs) && historyTab
            && this.props.historyLogs.hasNext && !isLoading
        ) {
            this.setState({
                page: page + 1,
            }, () => {
                this.props.loading();
                this.props.loadRecordLogs({ recordId: record.id }, { page: this.state.page });
            });
        }
    };

    render() {
        const { show, t, logView, isLoading, isMobile, record } = this.props;
        return (
            <div className='pt-3' hidden={ !show }>
                <div className='card'>
                    <div className="pt-3 pb-3 pl-3">
                        { logView.map((history, index) => (
                            <div key={ index }>
                                <div className='d-flex'>
                                    <div className="mr-1">
                                        <div className='avatar' style={ {
                                            width             : 40,
                                            height            : 40,
                                            borderRadius      : '100%',
                                            backgroundImage   : `url(${ (history.user && history.user.avatar) ? history.user.avatar : filePathService.resolveFilePath('profile-ic.svg') })`,
                                            backgroundSize    : 'cover',
                                            backgroundPosition: 'center center',
                                        } }
                                        />
                                    </div>
                                    <div>
                                        <span className='pr-2'>
											{ this.renderLabel(history) }
                                        </span>
                                        <b className='f-bold pr-1 font-text-card-detail'>{ history.user ? history.user.username : ''}</b>
                                        <span>
                                            {
                                                history.action === 'update' && index !== logView.length - 1 ?
                                                <span onClick={() => {
                                                    this.props.isOpenModalViewDifferenceQuestion();
                                                    this.props.getChangelogRecord(record.id, history.id);
                                                }} className={'link-action-modal-show-difference-question font-text-card-detail'}>{ t(`logs.${ history.action }${ history.type }`) }</span>:
                                                <span　className={'font-text-card-detail'}>{ t(`logs.${ history.action }${ history.type }`) }</span>
                                            }
										</span>
                                        <span
                                            className='date-log pl-3'>
                                            {isMobile ?
                                                <div>
                                                    <FormatTimestamp timestampUnix={ history.create_at } hasTime={ true } className={'font-text-card-detail'}/>
                                                </div>  : <FormatTimestamp className={'font-text-card-detail'} timestampUnix={ history.create_at } hasTime={ true }/>
                                            }
                                        </span>
                                        <p className='mb-1 font-text-card-detail'>
                                            { history['type_name'] ? <b>{ history['type_name'] }</b> : '' }
                                        </p>
                                        <p>
                                            <b className='f-bold font-text-card-detail'>{ history.user ? history.user.username : '' }</b>
                                        </p>
                                    </div>
                                </div>
                                <hr style={{marginLeft: '-1rem'}} hidden={ logView.length - 1 === index }/>
                            </div>
                        )) }
                        <div>
                            { isLoading ? <KirokuLoading/> : null }
                        </div>
                    </div>
                </div>

                <ModalViewDifferenceQuestions/>
            </div>
        );
    }
}

export default HistoryTab;
