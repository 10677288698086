import React, {Component}                       from 'react';
import {connect}                                from 'react-redux';
import moment                                   from 'moment';
import {loadSurveyListPage, saveQueryCondition} from '../../../actions/Survey';
import {loadUsers}                              from '../../../actions/User';
import KirokuButton                             from '../../../ui/KirokuButton';
import DateRange                                from '../../Records/RecordList/DateRange';
import {withNamespaces}                         from 'react-i18next';
import KirokuResetFilterCondition               from "../../../ui/KirokuResetFilterCondition";
import KirokuResetFilterConditionSearch         from "../../../ui/KirokuResetFilterConditionSearch";

@withNamespaces()
@connect(state => {
    return {
        surveys: state.KirokuAISurvey.surveys,
        customers: state.KirokuAICustomer.customers,
        users: state.KirokuAIUser.users,
        conditions: state.KirokuAIBehavior.conditionSurveys.conditions,
        conditionRemoved: state.KirokuAIBehavior.conditionSurveys.conditionRemoved
    }
}, dispatch => {
    return {
        loadSurveys: () => {
            dispatch(loadSurveyListPage());
        },
        loadUsers: () => {
            dispatch(loadUsers())
        },

        saveQueryCondition: (conditions) => {
            dispatch(saveQueryCondition(conditions))
        }
    }
})
class ModalFilterSurvey extends Component {

    state = {
        showDateRange: false,
        showFilterRecords: false,
        show: false,
        selectItem: false,
        status: '',
        nameStatus: '',
        startDate: '',
        creator: '',
        endDate: '',
        day: '',
        week: '',
        month: '',
        nameDate: '',
        nameStaff: '',
    };

    dropDownDate = () => {
        return [
            {
                value: 'All date',
                component: (<div>{this.props.t('Surveys.AllDate')}</div>),
                label: this.props.t('Surveys.AllDate')
            },
            {
                value: 'day',
                component: (<div>{this.props.t('Surveys.day')}</div>),
                label: this.props.t('Surveys.day')
            },
            {
                value: 'week',
                component: (<div>{this.props.t('Surveys.week')}</div>),
                label: this.props.t('Surveys.week')
            },
            {
                value: 'month',
                component: (<div>{this.props.t('Surveys.month')}</div>),
                label: this.props.t('Surveys.month')
            },
            {
                value: 'Custom Date',
                component: (<div onClick={this.closeDateRange.bind(this)}>{this.props.t('Surveys.CustomDate')}</div>),
                label: this.props.t('Surveys.CustomDate')
            }
        ]
    };

    dropDownItemsSurveys = () => {
        return [
            {
                value: '',
                component: (<div>{this.props.t('Surveys.All')}</div>),
                label: this.props.t('Surveys.All')
            },
            {
                value: 'draft',
                component: (<div>{this.props.t('Surveys.Draft')}</div>),
                label: this.props.t('Surveys.Draft')
            }
        ]
    };

    dropDownItemsStaffs = (staffs) => {
        let newStaffs = staffs.map(staff => ({
            value: staff.id,
            component: (<div>{staff.name}</div>),
            label: staff.name
        }));
        let defaultSearch = [
            {
                value: '',
                component: (<div>{this.props.t('Surveys.All')}</div>),
                label: this.props.t('Surveys.All')
            }
        ];
        return [...defaultSearch, ...newStaffs]
    };

    handleCloseModal() {
        this.props.showModal();
    }

    closeDateRange() {
        this.setState({
            showDateRange: !this.state.showDateRange
        });
    }

    handleCustomDate(startDate, endDate) {
        this.setState({
            startDate: startDate,
            endDate: endDate,
            showDateRange: !this.state.showDateRange
        });
    }

    filterTemplate(event) {
        this.setState({
            status: event.kirokuSelected.value,
            nameStatus : event.kirokuSelected.label
        })
    }

    filterStaff(event) {
        this.setState({
            creator: event.kirokuSelected.value,
            nameStaff: event.kirokuSelected.label
        })
    }

    resetSelectTime() {
        this.setState({
            startDate: '',
            endDate: '',
            day: '',
            week: '',
            month: '',
        })
    }

    selectTime(event) {
        this.resetSelectTime();
        this.setState({
            [event.kirokuSelected.value]: moment().startOf(event.kirokuSelected.value).unix(),
            nameDate : event.kirokuSelected.label
        });
    }

    handleClickApply() {
        let params = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            day: this.state.day,
            week: this.state.week,
            month: this.state.month,
            status: this.state.status,
            creator: this.state.creator,
            nameStaff: this.state.nameStaff,
            nameStatus : this.state.nameStatus
        };
        this.props.showModal();
        this.props.filterCondition(params);
    }

    handleCancelClick() {
        this.props.showModal();
    }

    componentWillMount() {
        this.props.loadUsers();
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.conditionRemoved !== this.props.conditionRemoved) {
            this.setState({
                ...nextProps.conditionRemoved,
            })
        }
    }

    render() {
        const staffs = this.props.users;
        let {t} = this.props;
        return (
            <div>
                <div hidden={this.props.show || this.state.showDateRange} className={"view-1vh"} id='mobile'>
                    <div className='survey-list'>
                        <div className='p-3'>
                            <button className='close' style={{float: 'left'}}
                                    onClick={this.handleCloseModal.bind(this)}>
                                <span style={{
                                    fontSize: '24px',
                                    color: '#a8aab7',
                                }}>&times;</span>
                            </button>
                            <div className='text-center'>
                                <span className='filter-record'>{t('Surveys.FilterSurvey')}</span>
                            </div>
                        </div>
                        <div className='p-2 mt-4 filter-modal'>
                            {/*<div>*/}
                                {/*<KirokuResetFilterCondition*/}
                                    {/*title={this.state.nameStatus}*/}
                                    {/*defaultValue={t('Surveys.All')}*/}
                                    {/*items={this.dropDownItemsSurveys()}*/}
                                    {/*icon={'far fa-file-alt text-secondary'}*/}
                                    {/*onSelect={this.filterTemplate.bind(this)}*/}
                                {/*/>*/}
                            {/*</div>*/}
                            <div className='mt-10 pt-2'>
                                <KirokuResetFilterCondition
                                    title={this.state.nameDate}
                                    defaultValue={t('Surveys.AllDate')}
                                    items={this.dropDownDate()}
                                    icon={'far fa-calendar-alt text-secondary'}
                                    onSelect={this.selectTime.bind(this)}
                                />
                            </div>
                            <div className='mt-10 pt-2'>
                                <KirokuResetFilterConditionSearch
                                    title={this.state.nameStaff}
                                    defaultValue={t('Surveys.AllStaff')}
                                    filterItems={this.filterStaff.bind(this)}
                                    items={this.dropDownItemsStaffs(staffs)}
                                    icon={'far fa-user text-secondary'}
                                    onSelect={this.filterStaff.bind(this)}
                                />
                            </div>
                            <div className='action-record-modal'>
                                <div className='pr-5 pl-5'>
                                    <div className='d-flex justify-content-between'>
                                        <KirokuButton color={'white'}
                                                      onClick={this.handleCancelClick.bind(this)}>{t('common.Cancel')}</KirokuButton>
                                        <div className="pl-2 pr-2"/>
                                        <KirokuButton color={'primary'}
                                                      onClick={this.handleClickApply.bind(this)}>{t('common.Apply')}</KirokuButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DateRange show={!this.state.showDateRange} closeDateRange={this.closeDateRange.bind(this)}
                           customDate={this.handleCustomDate.bind(this)}/>
            </div>
        );
    }
}

export default withNamespaces('translations')(ModalFilterSurvey);
