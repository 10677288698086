import React, {Component}                       from 'react';
import { CheckRerenderResponsive } from "../../ui";
import TeamListMobile                           from "./TeamList/TeamListMobile";
import TeamListWeb        from "./TeamList/TeamListWeb";
import {scrollService}    from "../../services";



class CustomerList extends Component {
    componentWillMount() {
        scrollService.top();
    }

    render() {
        return (
            <div className='default-background-color'>
                <div className='padding-responsive'>
                    <CheckRerenderResponsive mobile={<TeamListMobile/>} web={<TeamListWeb/>}/>
                </div>
            </div>
        )
    }
}

export default CustomerList;
