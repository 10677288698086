import React, {Component}    from 'react';
import {connect}             from "react-redux";
import KirokuTabLe           from "../../../ui/KirokuTabLe";
import moment                from "moment";
import {
    KirokuLabel, KirokuLoading,
    THeader, TBody
}                            from "../../../ui";
import {withNamespaces}      from "react-i18next";
import {dashboardActivities} from "../../../actions/Dashboard";
import {
    scrollService
}                            from "../../../services";
import $                     from "jquery";
import {Link}                from "react-router-dom";
import config                from '../../../config';

@connect(state => {
    return {
        dashboard: state.KirokuAIDashboard.dashboard,
        activitiesDashboard: state.KirokuAIDashboard.activitiesDashboard,
        currentPage: state.KirokuAIDashboard.activitiesDashboard.currentPage,
        totalPages: state.KirokuAIDashboard.activitiesDashboard.totalPages,
        loading: state.Loading,
        loadingDashboardSuccess: state.KirokuAIDashboard.loadingDashboardSuccess,
    }
}, dispatch => {
    return {
        dashboardActivities: (page) => {
            dispatch(dashboardActivities(page))
        }
    }
})
@withNamespaces()
class LogsDashboard extends Component {

    componentWillMount() {
        scrollService.top();
        window.addEventListener('scroll', this.listener);
    }

    componentWillUnmount() {
        scrollService.top();
        window.removeEventListener('scroll', this.listener);
    }

    onClickLink = (log) => {
        if (log.action !== 'delete') {
            switch (log.type) {
                case 'surveyRecord' :
                    return this.props.history.push(`/records/record/${log['type_id']}`);
                case 'surveyTemplate' :
                    return this.props.history.push(`/surveys/survey-detail/${log['type_id']}`);
                case 'team' :
                    return this.props.history.push(`/teams/team-detail/${log['type_id']}`);
                case 'customer' :
                    return this.props.history.push(`/customers/customer-detail/${log['type_id']}`);
                default :
                    return this.props.history.push(`/staffs/staff/${log['type_id']}`);
            }
        }
    };


    listener = () => {
        const { currentPage } = this.props;

        if ((Math.ceil($(window).scrollTop()) === $(document).height() - $(window).height())
            // && this.props.currentPage !== this.props.totalPages
            && this.props.activitiesDashboard.resLogs.length
            && !this.props.loading
            && document.getElementById('page-log-dashboard')) {
                this.props.dashboardActivities({page: currentPage + 1});
        }
    };

    renderLabel(log) {
        switch (log.action) {
            case "create":
                return (<KirokuLabel color='primary' style={{
                    padding: '4px',
                    margin: '8px 0',
                    width: 93
                }}>{this.props.t('common.create')}</KirokuLabel>);
            case "update":
                return (<KirokuLabel color='success' style={{
                    padding: '4px',
                    margin: '8px 0',
                    width: 93
                }}>{this.props.t('common.Update')}</KirokuLabel>);
            default:
        }
    };

    render() {
        const {t, activitiesDashboard} = this.props;
        return (
            <div>
                <h5 className='pt-3 pb-3 pr-3 recent-title'>
                    {/*{t('Layout.Logs')}*/}
                </h5>
                <div className={'bg-white'} id={'page-log-dashboard'}>
                    <div>
                        {activitiesDashboard.logs.length ?
                            <KirokuTabLe>
                                <THeader className={'tbl-header'}>
                                    <tr>
                                        <th style={{minWidth: 100}}>{t('Dashboard.customerName')}</th>
                                        <th>{t('Dashboard.titleSurveyTemplate')}</th>
                                        <th style={{minWidth: 100}}>{t('Dashboard.staffName')}</th>
                                        <th style={{minWidth: 100}}>{t('Dashboard.actions')}</th>
                                        <th style={{minWidth: 215}}>{t('Dashboard.date')}</th>
                                    </tr>
                                </THeader>
                                <TBody>
                                    {activitiesDashboard.logs.map((log, index) => {
                                        return (
                                            <tr key={index}>
                                                <td><span className='pr-1'>
                                                <Link
                                                    to={`/customers/customer-detail/${log['customer_id']}`}>{log['customer_name']}</Link>
                                            </span></td>
                                                <td>
                                            <span className='mb-1'>
                                                {log['type_name'] ?
                                                    <span
                                                        className='text-primary'
                                                        style={{cursor: 'pointer'}}
                                                        onClick={this.onClickLink.bind(this, log)}
                                                    >
                                                        {log['type_name']}
                                                    </span> : ''
                                                }
                                            </span>
                                                </td>
                                                <td><span className='pr-1'>
                                                <Link to={`/staffs/staff/${log['user_id']}`}>
                                                    {log['staff_name']}
										    </Link>
                                            </span></td>
                                                <td style={{width: 100}}>
                                                    <span className='pr-2'>{this.renderLabel(log)}</span>
                                                </td>
                                                <td>
                                                    <span>{moment.unix(log.create_at).format(config.formatDateTimeTh)}</span>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </TBody>
                            </KirokuTabLe> :
                            <div>
                                <KirokuTabLe>
                                    <THeader className={'tbl-header'}>
                                        <tr>
                                            <th style={{minWidth: 100}}>{t('Dashboard.customerName')}</th>
                                            <th>{t('Dashboard.titleSurveyTemplate')}</th>
                                            <th style={{minWidth: 100}}>{t('Dashboard.staffName')}</th>
                                            <th style={{minWidth: 100}}>{t('Dashboard.actions')}</th>
                                            <th style={{width: 100}}>{t('Dashboard.date')}</th>
                                        </tr>
                                    </THeader>
                                </KirokuTabLe>
                                <div className={'text-center p-2 '}><h5>{t('Dashboard.noActions')}</h5></div>
                            </div>
                        }
                    </div>
                </div>
                <div>
                    {this.props.loading ? <KirokuLoading/> : null}
                </div>
            </div>
        )
    }
}

export default LogsDashboard;
