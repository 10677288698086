import React, {Component}                       from 'react';
import { CheckRerenderResponsive } from "../../../ui";
import StaffDetailWeb                           from "./StaffDetailWeb";
import StaffDetailMobile  from "./Mobile/StaffDetailMobile";
import {scrollService}    from "../../../services";
import KirokuTitlepage    from "../../../ui/KirokuTitlepage";
import {withNamespaces}   from "react-i18next";
import {connect}          from "react-redux";
import {currentPage}      from "../../../actions/CurrentPage";

@withNamespaces()
@connect(state => {
    return {
        customer: state.KirokuAICustomer.customer,
    }
}, dispatch => {
    return {
        currentPageUsed: (name) => {
            dispatch(currentPage(name))
        }
    };
})
class StaffDetail extends Component {
    page = {
        title: <KirokuTitlepage title={this.props.t('common.staff')}/>,
    };

    componentWillMount() {
        scrollService.top();
        this.props.currentPageUsed(this.page);
    }

    render() {
        let web    = <StaffDetailWeb history={this.props.history} id={this.props.match.params.id}/>;
        let mobile = <StaffDetailMobile history={this.props.history} id={this.props.match.params.id}/>;

        return (
            <div>
                <CheckRerenderResponsive web={web} mobile={mobile}/>
            </div>
        )
    }
}

export default StaffDetail;
