import React, {Component}        from 'react';
import {
    KirokuRadio,
    KirokuCardWeb,
    KirokuCardTitleWeb,
    KirokuCardBodyWeb, KirokuButton,
    KirokuButtonLink
}                                from '../../../../ui';
import {withNamespaces}          from 'react-i18next';
import PropTypes                 from 'prop-types';
import {checkUseHeadingQuestion} from "../../../Surveys/SurveyEditor/SurveyService";
import {withSmoothScrolling}     from '../../../../ui/Scrolling.js';
@withSmoothScrolling
@withNamespaces()
class SingleChoice extends Component {

    state = {
        answers: {
            answer: this.props.value ? this.props.value.answer : '',
            nextQuestion: this.props.value ? this.props.value.nextQuestion : '',
            is_choice: this.props.value ? this.props.value.is_choice : ''
        },
        validated: false,
    };

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.question.index !== nextProps.question.index || this.props.value !== nextProps.value) {
            this.setState({
                answers: {
                    answer: nextProps.value ? nextProps.value.answer : '',
                    nextQuestion: nextProps.value ? nextProps.value.nextQuestion : '',
                    is_choice: nextProps.value ? nextProps.value.is_choice : ''
                }
            });
        }
    }


    onChangeItem(index, event) {
        const {question} = this.props;
        const answer = question.choices[index];
        this.setState({
            answers    : { ...answer, is_choice: index },
            validated  : answer.answer,
        });

        this.props.onAnswer({
            answer: { ...answer, is_choice: index },
            validated: index <= question.choices.length ? {...answer, is_choice: index} : null,
            questionIndex: this.props.question.index
        });

        this.props.isCurrentQuestion(this.props.question.index);
    }

    handleResetAnswer() {
        this.setState({
            answers    : {},
            validated  : false,
        });

        this.props.onAnswer({
            answer: {},
            validated: null,
            questionIndex: this.props.question.index
        });

        this.props.isCurrentQuestion(this.props.question.index);
    }

    moveToNextQuestion() {
        const {answers} = this.state;
        const {question } = this.props;
        let nextToQuestion = typeof answers.nextQuestion === 'number' ? answers.nextQuestion - 1 : question.index;
        this.props.smoothScroll.scrollTo(`question-${nextToQuestion }`);
        this.props.isCurrentQuestion(nextToQuestion + 1);
    }

    render() {
        const { disabled, question, t, isEndQuestion, currentQuestion } = this.props;
        const { answers }              = this.state;

        const contentQuestion = checkUseHeadingQuestion(question);


        return (
            <div id='component-question'>
                <div id='web-q' className='pb-3'>
                    <KirokuCardWeb style={{ borderColor: disabled ? '#dfe2e5' : answers.answer ? '#d6e9c6' : (!question.unrequited ? '#ebccd1' : '#dfe2e5')}}>
                        <KirokuCardTitleWeb color={disabled ? 'disable-question' :
                            (answers.answer ? 'question-has-answer d-flex justify-content-between':
                                (!question.unrequited ? 'question-required-answer d-flex justify-content-between' : 'card-web d-flex justify-content-between'))}
                                            style={{ paddingBottom: 15, alignItems : 'center'}}
                        >
                            <div className={`number-question text-overflow-elip 
                            ${disabled ? 'question-number' : answers.answer ? 'font-text-question-has-answer' : (!question.unrequited ? 'text-question-required-answer' : 'question-number')}`}>
                                {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                            </div>
                            <div/>
                            <div hidden={disabled}>
                                <KirokuButtonLink
                                    className='padding-reset-button-link'
                                    onClick={() => {this.handleResetAnswer()}}
                                >
                                    {t('createRecord.resetAnswer')}
                                </KirokuButtonLink>
                            </div>
                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb>
                            <div className={disabled ? 'wrap-overlay-action cursor-disable' : ''}>
                                <span className='content-name-question'>{question.content}</span>
                                <div className="d-flex justify-content-between">
                                    <div className='answer-question'>
                                        {question.choices.map((choice, index) =>
                                            <KirokuRadio
                                                disabled={disabled}
                                                key={index}
                                                id={`${question.index}-${index}`}
                                                name={`${question.index}SingleChoice`}
                                                checked={
                                                    answers.is_choice === index
                                                }
                                                onChange={disabled ? () => {
                                                } : this.onChangeItem.bind(this, index)}
                                            >
                                                <span style={{color : '#495057'}}>{choice.answer}</span>

                                            </KirokuRadio>
                                        )}
                                    </div>
                                    <div className='d-flex'>
                                        <div style={ { margin: 'auto' } } hidden={ disabled || isEndQuestion || question.index !== currentQuestion}>
                                            <KirokuButton className="btn-kiroku-primary"
                                                          onClick={ this.moveToNextQuestion.bind(this) }>
                                                {this.props.t('common.nextToQuestion')}
                                            </KirokuButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                </div>
            </div>
        )
    }
}

SingleChoice.propTypes = {
    value: PropTypes.object
};
export default SingleChoice;
