import {GET_ORGANIZATION, UPDATE_ORGANIZATION} from "../actions/Organization";

const defaultApplicationSate = {
    organization: {},
    profile: JSON.parse(localStorage.getItem('profile')),
};


const KirokuAIOrganization = (state = defaultApplicationSate, action) => {
    switch (action.type) {
        case GET_ORGANIZATION :
            return {
                ...state,
                organization: action.organization,
                profile : state.profile
            };
        case UPDATE_ORGANIZATION :
            return {
                ...state,
                organization: action.organizationCurrent
            };
        default :
            return state;
    }
};

export default KirokuAIOrganization;