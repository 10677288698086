
import React, {Component} from  'react';

class MediaChart extends Component {
    render() {
        return <div>
            {/*todo*/}
            {this.props.records[0].survey_template.questions[this.props.questionIndex].type}
        </div>
    }
}

export default MediaChart;
