import { CHECK_ALL_RECORDS_EXPORT, CHECK_RECORD_EXPORT, SEARCH_RECORD } from '../actions/AdminExport';
import { LOAD_CUSTOMER_ALL }                                            from '../actions/Customer';
import _                                                                from 'lodash';

const defaultState = {
    arrayRecord         : [],
    beginPageExport     : true,
    loadingSearch       : false,
    statusCheckAllRecord: false,
    statusBtnExport     : true,
    condition           : {
        customer : null,
        startDate_created: null,
        endDate_created: null,
        startDate_serviced: null,
        endDate_serviced: null
    },
    surveyChecked       : [],
};

const ReducerAdminExport = (state = defaultState, action) => {
    switch (action.type) {
        case LOAD_CUSTOMER_ALL:
            return {
                ...state,
                customers: action.customers
            };
        case SEARCH_RECORD:
            return {
                ...state,
                arrayRecord  : action.arrayRecord,
                loadingSearch: false,
                statusCheckAllRecord: false,
                statusBtnExport     : _.findIndex(action.arrayRecord, ['isChecked', true]) === -1,
            };
        case 'LOADING':
            return {
                ...state,
                loadingSearch  : true,
                beginPageExport: false
            };
        case 'RESET_STATE_EXPORT':
            return {
                ...defaultState
            };

        case CHECK_RECORD_EXPORT:
            const indexRecord           = _.findIndex(state.arrayRecord, ['id', action.id]);
            const newArrayRecord        = [...state.arrayRecord];
            newArrayRecord[indexRecord] = {
                ...newArrayRecord[indexRecord],
                isChecked: !newArrayRecord[indexRecord].isChecked
            };
            return {
                ...state,
                arrayRecord         : newArrayRecord,
                statusCheckAllRecord: _.findIndex(newArrayRecord, ['isChecked', false]) === -1,
                statusBtnExport     : _.findIndex(newArrayRecord, ['isChecked', true]) === -1,
                surveyChecked: [...newArrayRecord].filter(record => record.isChecked)
            };

        case CHECK_ALL_RECORDS_EXPORT:
            const newArrayRecordAll = state.arrayRecord.map(record => {
                return {
                    ...record,
                    isChecked: !state.statusCheckAllRecord
                }
            });
            return {
                ...state,
                arrayRecord         : newArrayRecordAll,
                statusCheckAllRecord: !state.statusCheckAllRecord,
                statusBtnExport     : state.statusCheckAllRecord,
                surveyChecked: [...newArrayRecordAll].filter(record => record.isChecked)
            };
        case 'SET_CONDITION_SEARCH':
            return {
                ...state,
                condition: {
                    ...action.condition
                }
            };
        default:
            return state
    }
};

export default ReducerAdminExport;
