import React, { Component } from 'react';

export default class KirokuInputSearch extends Component {

    render() {
        return (
            <div style={{position: 'relative'}}>
                <input {...this.props} type="text" className="form-control-kiroku pr-4" />
                <div className='action-input'>
                    <i className="fa fa-search"/>
                </div>
            </div>
        )
    }
}
