import  React , { Component}     from   'react';
import { KirokuLoading }         from '../../../ui';
import { Chart }                 from 'react-google-charts';
import { buildDataTimeQuestion } from '../buildData';

class TimeChart extends Component {
    render() {
        const {records, question} = this.props;
        const options = {
            pieHole: 0.4,
            legend: 'bottom',
            pieSliceTextStyle: {
                color: 'black',
            },
        };
        const data = buildDataTimeQuestion(records, question);
        return <div>
            <Chart
                chartType="PieChart"
                width="100%"
                height="500px"
                data={data}
                loader={<KirokuLoading/>}
                options={options}
            />
        </div>
    }
}

export default TimeChart
