export default class DownloadService {

    constructor(axios) {
        this.axios = axios;
    }

    download(params) {
        return this.axios.get(`/download`, {
            params : {
                ...params
            }
        }).then(res => res);
    }
}
