import {
    TURN_OF_FLAG_EDIT_QUESTION,
    TURN_OFF_FLAG_LOAD_DRAFT_EDIT_RECORD_WEB,
    TURN_OFF_FLAG_LOAD_DRAFT_WEB,
    TURN_OFF_MODAL_CONFIRM_EXIT, TURN_OFF_NOTIFICATION_VALIDATE_MEDIA_FILE,
    TURN_ON_LOAD_DRAFT_EDIT_RECORD,
    TURN_ON_LOAD_DRAFT_WEB,
    TURN_ON_MODAL_CONFIRM_EXIT, TURN_ON_NOTIFICATION_VALIDATE_MEDIA_FILE
} from '../actions/TakeSurvey';

const defaultApplicationSate = {
    flagEditing            : false,
    indexQuestion          : null,
    recordReview           : null,
    recorded               : null,
    survey                 : {},
    customer               : {},
    flagLoadDraft          : false,
    flagLoadDraftEditRecord: false,
    modalConfirmExitWeb    : false,
    flagValidatedUpload    : false
};


const TakeSurveyWeb = (state = defaultApplicationSate, action) => {
    switch (action.type) {

        case TURN_OF_FLAG_EDIT_QUESTION:
            return {
                ...state,
                flagEditing: false,
            };
        case 'NOTIFICATION_EDIT_QUESTION' :
            return {
                ...state,
                flagEditing  : action.flagEdit,
                indexQuestion: action.indexQuestion
            };
        case TURN_ON_LOAD_DRAFT_WEB:
            return {
                ...action,
                ...state,
                flagLoadDraft: true
            };
        case TURN_ON_LOAD_DRAFT_EDIT_RECORD:
            return {
                ...state,
                ...action,
                flagLoadDraftEditRecord: true
            };

        case TURN_ON_MODAL_CONFIRM_EXIT:
            return {
                ...state,
                modalConfirmExitWeb: true
            };

        case TURN_OFF_MODAL_CONFIRM_EXIT:
            return {
                ...state,
                modalConfirmExitWeb: false
            };
        case TURN_OFF_FLAG_LOAD_DRAFT_WEB:
            return {
                ...state,
                flagLoadDraft: false
            };
        case TURN_OFF_FLAG_LOAD_DRAFT_EDIT_RECORD_WEB:
            return {
                ...state,
                flagLoadDraftEditRecord: false
            };

        case TURN_ON_NOTIFICATION_VALIDATE_MEDIA_FILE:
            return {
                ...state,
                flagValidatedUpload: action.flagModal
            };
        case TURN_OFF_NOTIFICATION_VALIDATE_MEDIA_FILE:
            return {
                ...state,
                flagValidatedUpload: action.flagModal
            };
        default:
            return {...state}
    }

};
export default TakeSurveyWeb
