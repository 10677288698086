import {Component} from "react";
import React from "react";

export default class KirokuPopoverItem extends Component {

    render() {
        return (
            <div hidden={this.props.hidden} className="popover dropdown-kiroku dropdown-menu"
                 style={{position: 'absolute', willChange: 'top, left', left: 'auto'}}>
                <div className='dropdown-item-kiroku'>
                    {this.props.children}
                </div>
            </div>
        )
    }
}