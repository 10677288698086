import {Component} from "react";
import React from "react";
import KirokuDropdownItem from "./KirokuDropdownItem";

export default class KirokuDropdown extends Component {
    state = {
        show: false,
        selected: null,
    };

    showDropdown() {
        this.setState({
            show: true
        });
    }

    handleSelected(event) {
        this.props.onSelect(event);
        this.setState({
            show: false,
            selected: event.kirokuSelected,
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.default !== this.props.default) {
            this.setState({
                selected : null
            })
        }
    }

    render() {
        const selectedLabel = this.state.selected ? this.state.selected.label : this.props.title;
        return (
            <div className={`dropdown ${this.props.className ? this.props.className : ''}`}>
                <button style={this.props.style} className={`btn btn-kiroku ${this.props.className ? this.props.className : ''}`} onClick={this.showDropdown.bind(this)}>
                    <span className={'text-kiroku left'}>{selectedLabel}</span>
                    <i className={`${! this.state.show ? 'fa fa-chevron-down icon-kiroku' : 'fa fa-chevron-up icon-kiroku' }`}/>
                </button>
                <div
                    className={this.state.show ?
                        `dropdown-kiroku dropdown-menu show ${this.props.location ? this.props.location : ''}` : "dropdown-kiroku dropdown-menu"}
                    style={{position: 'absolute', willChange: 'top, left', left:'auto', top: 'auto'}}>
                    {this.props.items.map((item, index) => {
                        const Item = item.component;
                        return (
                            <KirokuDropdownItem key={index} onClick={(event) => {
                                event.kirokuSelected = item;
                                this.handleSelected(event);
                            }}>
                                {Item}
                            </KirokuDropdownItem>
                        )
                    })}
                </div>
            </div>
        )
    }
}

