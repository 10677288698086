import React, {Component}      from 'react' ;
import {KirokuSectionDropdown} from "./index";
import _                       from 'lodash';
import config                  from '../config';
import {withNamespaces}        from "react-i18next";

@withNamespaces()

class KirokuSectionDropdownShowMore extends Component {

    state = {
        tagView    : this.props.value,
        tagSelected: this.props.value,
        width      : window.innerWidth,
        isOpenMenu : false,
        hasViewMore: false

    };

    componentWillMount() {
        this.setState({
            tagView : this.cutTags(this.props.value)
        })
    }

    cutTags = (itemsSelected) => {
        const {tagType, t, isMoreTags} = this.props;
        const amountOfTag = itemsSelected.length - config.sizeTagOnWed;
        const  showLabel  = isMoreTags ? `...${t('common.isShowMore')}` : `... ${t(`common.showMore${tagType}`, {value: amountOfTag})}`;
        const removedSelectedTags = [...itemsSelected].map(e => {
            return {
                ...e,
                isClose: true
            }
        });
        if (itemsSelected.length > config.sizeTagOnWed) {
            return [..._.head(_.chunk(removedSelectedTags, config.sizeTagOnWed)),
                {
                    label  : showLabel,
                    key    : 'viewMore',
                    type   : 'viewMore',
                    value  : 'viewMore',
                    isClose: true
                }]
        } else {
            return itemsSelected;
        }

    };

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (this.props.value.length !== nextProps.value.length) {
            this.setState({
                tagSelected: nextProps.value,
                tagView    : this.cutTags(nextProps.value)
            })
        }
    }

    handleChangeTags(options) {
        this.setState({
            tagView    : options,
            tagSelected: options
        }, () => {
            if (!this.state.isOpenMenu) {
                this.handleCloseInput();
            }
        });
    };

    handleOpenInput() {
        const {tagSelected} = this.state;
        this.setState({
                tagView   : tagSelected,
                isOpenMenu: true,
            }
        )
    };

    handleCloseInput() {
        const {tagSelected} = this.state;
        this.props.onChange(tagSelected);
        this.setState({
            tagView   : this.cutTags(tagSelected),
            isOpenMenu: false
        });
    };

    render() {
        const {tagView} = this.state;
        const {options, isSearchTags} = this.props;

        return (
            <KirokuSectionDropdown
                onMenuOpen={this.handleOpenInput.bind(this)}
                onMenuClose={this.handleCloseInput.bind(this)}
                onChange={this.handleChangeTags.bind(this)}
                placeholder={this.props.placeholder}
                value={tagView}
                options={options}
                searchTags={isSearchTags ? <div className='submit-search' onClick={this.props.handleSearchTags}>
                    <div><i className="fa fa-search text--a"/></div>
                </div>: ''}
            />
        )
    }
}

export default KirokuSectionDropdownShowMore;
