import React, {Component}           from 'react';
import {withNamespaces}             from "react-i18next";
import KirokuButton                 from "../../ui/KirokuButton";
import KirokuResetFilterCondition   from "../../ui/KirokuResetFilterCondition";
import {connect}                    from "react-redux";

@withNamespaces()
@connect((state) => {
        return {
            conditionsLogs: state.KirokuAIBehavior
        }
    }, (dispatch) => ({
        updateConditionLogSuccess: () => dispatch({
            type: 'CURRENT_CONDITION_LOG_SUCCESS'
        })
    })
)

class ModalFilterLog extends Component {

    state = {
        showDateRange: true,
        showFilterRecords: false,
        show: false,
        selectItem: false,
        action: '',
        type: '',
        teams: [],
    };

    componentWillMount() {

    }

    dropDownItemsAction = () => {
        return [
            {
                value: 'update',
                component: (<div>{this.props.t('common.update')}</div>),
                label: this.props.t('common.update')
            },
            {
                value: 'create',
                component: (<div>{this.props.t('common.Create')}</div>),
                label: this.props.t('common.Create')
            },
            {
                value: 'delete',
                component: (<div>{this.props.t('common.Delete')}</div>),
                label: this.props.t('common.Delete')
            },
            {
                value: 'view',
                component: (<div>{this.props.t('common.ViewDetail')}</div>),
                label: this.props.t('common.ViewDetail')
            },
            {
                value: 'login',
                component: (<div>{this.props.t('common.login')}</div>),
                label: this.props.t('common.login')
            },
            {
                value: 'synchronize',
                component: (<div>{this.props.t('common.synchronize')}</div>),
                label: this.props.t('common.synchronize')
            }
        ]
    };
    dropDownItemsType   = () => {
        //surveyTemplate, surveyRecord,customer , staff, team, username,line
        return [
            {
                value: 'surveyRecord',
                component: (<div>{this.props.t('common.surveyRecord')}</div>),
                label: this.props.t('common.surveyRecord')
            },
            {
                value: 'surveyTemplate',
                component: (<div>{this.props.t('common.surveyTemplate')}</div>),
                label: this.props.t('common.surveyTemplate')
            },
            {
                value: 'team',
                component: (<div>{this.props.t('common.team')}</div>),
                label: this.props.t('common.team')
            },
            {
                value: 'customer',
                component: (<div>{this.props.t('common.customer')}</div>),
                label: this.props.t('common.customer')
            },
            {
                value: 'staff',
                component: (<div>{this.props.t('common.staff')}</div>),
                label: this.props.t('common.staff')
            },
            {
                value: 'username',
                component: (<div>{this.props.t('common.username')}</div>),
                label: this.props.t('common.username')
            },
            {
                value: 'line',
                component: (<div>{this.props.t('common.line')}</div>),
                label: this.props.t('common.line')
            }

        ]
    };

    filterAction(event) {
        this.setState({
            action: event.kirokuSelected.value,
            nameAction: event.kirokuSelected.label === "All" || event.kirokuSelected.label === "全て" ? '' : event.kirokuSelected.label
        })
    }

    filterType(event) {
        this.setState({
            type: event.kirokuSelected.value,
            nameType: event.kirokuSelected.label === "All" || event.kirokuSelected.label === "全て" ? '' : event.kirokuSelected.label
        })
    }

    handleClickApply() {
        let params = [
            {
                value: this.state.action,
                query: 'action',
                label: this.state.nameAction ? this.state.nameAction : ''
            },
            {
                value: this.state.type,
                query: 'type',
                label: this.state.nameType ? this.state.nameType : ''
            }
        ];
        this.props.showModal();
        this.props.filterCondition(params);
    }

    handleCancelClick() {
        this.props.showModal();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.conditionsLogs.updated) {
            if (nextProps.conditionsLogs.conditionsLogs.length) {
                nextProps.conditionsLogs.conditionsLogs.map(item => {
                    let label = item.query === 'type' ? 'defaultAction' : 'defaultType';
                    if (label === 'defaultAction') {
                        this.setState({
                            nameAction: '',
                            action: ''
                        });
                    } else {
                        this.setState({
                            nameType: '',
                            type: ''
                        });
                    }
                    return item
                });
            } else {
                this.setState({
                    nameAction: '',
                    nameType: '',
                    action: '',
                    type: ''
                })
            }
            this.props.updateConditionLogSuccess()
        }
    }

    render() {
        let {t} = this.props;
        return (
            <div>
                <div hidden={this.props.show} className={"view-1vh"}>
                    <div className='survey-list'>
                        <div className='p-3'>
                            <button className='close' style={{float: 'left'}}
                                    onClick={this.handleCancelClick.bind(this)}>
                                <span style={{
                                    fontSize: '24px',
                                    color: '#a8aab7',
                                }}>&times;</span>
                            </button>
                            <div className='text-center'>
                                <span className='filter-record'>{t('logs.FilterBy')}</span>
                            </div>
                        </div>
                        <div className='p-2 mt-4 filter-modal'>
                            <div>
                                <KirokuResetFilterCondition
                                    title={this.state.nameAction}
                                    defaultValue={t('common.Action')}
                                    items={this.dropDownItemsAction()}
                                    icon={'far fa-file-alt text-secondary'}
                                    onSelect={this.filterAction.bind(this)}
                                />
                            </div>
                            <div className='mt-10 pt-2'>
                                <KirokuResetFilterCondition
                                    title={this.state.nameType}
                                    defaultValue={t('common.Type')}
                                    items={this.dropDownItemsType()}
                                    icon={'far fa-file-alt text-secondary'}
                                    onSelect={this.filterType.bind(this)}
                                />
                            </div>
                            <div className="pt-3">
                                <div className='fix-center'>
                                    <div className='d-flex justify-content-between'>
                                        <KirokuButton color={'white'}
                                                      onClick={this.handleCancelClick.bind(this)}>{t('common.Cancel')}</KirokuButton>
                                        <div className='pl-1 pr-1'/>
                                        <KirokuButton color={'primary'}
                                                      onClick={this.handleClickApply.bind(this)}>{t('common.Apply')}</KirokuButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalFilterLog;
