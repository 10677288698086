import React, {Component}                                                from 'react';
import {connect}                                                         from "react-redux";
import {detailSurvey, nextQuestionFromSurvey, updateSurvey, resetMobile} from "../../../../actions/SurveyMobile";
import KirokuLoading                                                     from "../../../../ui/KirokuLoading";
import ReviewEditSurvey                                                  from "./ReviewEditSurvey";
import Survey                                                            from './Survey';
import Question                                                          from './Question';
import ComponentAddQuestion                                              from './ComponentAddQuestion';
import {hasTimer}                                                        from "../../../../Timer";
import {saveDraft}                                                       from "../../../../actions/DraftSurvey";
import {NOTIFICATION}                                                    from "../../../../actions/Notification";
import {surveyFactory, surveyTemplateService}                            from "../../../../services";

@hasTimer()
@connect(state => {
    return {
        survey: state.KirokuSurveyTemplateMobile,
        loading: state.KirokuSurveyTemplateMobile.loading,
        showReview: state.KirokuSurveyTemplateMobile.showReview,
        showSurvey: state.KirokuSurveyTemplateMobile.showSurvey,
        index: state.KirokuSurveyTemplateMobile.orderQuestion,
        lastQuestion: state.KirokuSurveyTemplateMobile.lastQuestion,
        typeComponent: state.KirokuSurveyTemplateMobile.typeComponent
    }
}, dispatch => {
    return {
        updateSurvey: (id, survey) => dispatch(updateSurvey(id, survey)),
        detail: surveyId => dispatch(detailSurvey(surveyId)),
        backToComponent: (typeComponent, options) => dispatch(nextQuestionFromSurvey(typeComponent, options)),
        saveDraft: (draft) => dispatch(saveDraft(draft)),
        resetMobile: () => dispatch(resetMobile()),
        notification: () => dispatch({
            type: NOTIFICATION,
            typeNotification: 'success',
            message: 'Surveys.UpdatedSuccessfully'
        })
    }
})
class SurveyEditorMobile extends Component {

    // componentWillMount() {
    //     this.props.timer.record(() => {
    //         this.autoSaveDraft()
    //     }, 10000);
    // }

    backToComponent = () => {
        this.props.backToComponent('Survey');
    };

    updateSurvey = async () => {
        const {survey} = this.props;
        const surveyEdited = surveyFactory.makeEdit(survey);
        await surveyTemplateService.edit(surveyEdited.id, surveyEdited);
        this.props.updateSurvey(surveyEdited.id, surveyEdited);
        this.props.notification();
        this.props.history.push('/surveys');
    };

    backToSurveyList = () => {
        this.props.history.push('/surveys');
    };

    componentMap = {
        Survey: Survey,
        Question: Question,
        Review: ReviewEditSurvey,
        AddQuestion: ComponentAddQuestion
    };

    // autoSaveDraft = () => {
    //     const {survey} = this.props;
    //     this.props.saveDraft(survey);
    // };

    componentWillUnmount() {
        this.props.timer.unRegisterLastJob();
        this.props.resetMobile();
    }

    render() {
        const {index, survey} = this.props;
        const Component = this.componentMap[this.props.typeComponent];
        return this.props.survey.id ? (
                <Component
                    id={this.props.id}
                    show={this.props.showSurvey}
                    question={survey.questions[index]}
                    lastQuestion={this.props.lastQuestion}
                    history={this.props.history}
                    backToSurveyList={this.backToSurveyList.bind(this)}
                    backQuestion={this.backToComponent.bind(this)}
                    handleSubmit={this.updateSurvey.bind(this)}
                />
            )
            : (<KirokuLoading/>);
    }
}

export default SurveyEditorMobile;
