import React, {Component} from 'react'
import KirokuModalHeader  from "../../../../ui/KirokuModalHeader";
import KirokuInputSearch  from "../../../../ui/KirokuInputSearch";
import KirokuModalBody    from "../../../../ui/KirokuModalBody";
import KirokuCardList     from "../../../../ui/KirokuCardList";
import KirokuCard         from "../../../../ui/KirokuCard";
import KirokuCheckBox     from "../../../../ui/KirokuCheckBox";
import KirokuModalFooter  from "../../../../ui/KirokuModalFooter";
import KirokuButton       from "../../../../ui/KirokuButton";
import KirokuModal        from "../../../../ui/KirokuModal";
import {withNamespaces}   from "react-i18next";
import connect            from "react-redux/es/connect/connect";
import {setRole}          from "../../../../actions/User";
import {userService}      from "../../../../services";
import {NOTIFICATION}     from "../../../../actions/Notification";
import debounce           from "lodash/debounce";

@withNamespaces()
@connect(() => {
    return {}
}, (dispatch) => {
    return {
        setRole: (role, userId) => {
            dispatch(setRole(role, userId))
        },
        notification: (t) => {
            dispatch({
                type: NOTIFICATION,
                typeNotification: 'success',
                message: t('message.UpdateSuccess')
            })
        }
    };
})
class ModalSetRole extends Component {
    state = {
        users: [],
        usersRecord: [],
        userChoice: [],
        statusCheck: false,
        showModalCustomer: false
    };


    handleCancel() {
        this.props.closeModalSetRole()
    }

    setRole() {
        let {userForSearch} = this.props;
        let userChoice      = userForSearch.filter(item => item.isChecked);
        let user            = userChoice.map(user => user.id);
        userService.updateRole(this.props.role, user, {page: 1}, []).then(users => {
            this.props.userPage(users.users);
            this.props.notification(this.props.t)
        });
        this.props.closeModalSetRole();
    }

    isSelect() {
        return !this.props.userForSearch.filter(item => item.isChecked).length
    }

    handleCheckAll() {
        let {userForSearch} = this.props;
        let users           = userForSearch.map(user => {
            return {
                ...user,
                isChecked: !this.state.statusCheck
            }
        });
        this.setState({
            statusCheck: !this.state.statusCheck
        });
        this.props.checkAllUser(users, !this.state.statusCheck);
    }

    handleCheckUser(userCompare) {
        let {userForSearch} = this.props;
        let array           = userForSearch.map(user => {
            if (user.id === userCompare.id) {
                return {
                    ...user,
                    isChecked: !user.isChecked
                }
            }
            return user
        });

        let checkedUser = array.filter(item => item.isChecked).length;
        this.handleCheckStatus(checkedUser, array);
        this.props.checkedUser(userCompare)
    }

    onSearch(value) {
        let user        = this.props.userForSearchRecord.filter(user =>
            user.name.toLowerCase().search(value.toLowerCase()) !== -1
        );
        let checkedUser = user.filter(item => item.isChecked).length;
        this.handleCheckStatus(checkedUser, user);
        this.props.newUser(user);
    }


    handleCheckStatus(checkedUser, array) {
        if (checkedUser === array.length) {
            this.setState({
                statusCheck: true
            })
        } else {
            this.setState({
                statusCheck: false
            })
        }
    }

    onChangeDebounce = debounce(value => {
        let inputValue = value.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
        this.onSearch(inputValue)
    }, 500);

    render() {
        let {t, userForSearch} = this.props;
        return (
            <KirokuModal show={this.props.show}>
                <KirokuModalHeader>
                    <h6 className="mb-3">{t('common.ChoiceUser')}</h6>
                    <KirokuInputSearch onChange={e => this.onChangeDebounce(e.target.value)}/>
                </KirokuModalHeader>
                <KirokuModalBody>
                    <div id="body-modal-as">
                        <KirokuCardList
                            headerLeft={t('common.Name')}
                            headerRight={
                                <div className='text-primary' style={{cursor: 'pointer'}}>
                                        <span hidden={this.state.statusCheck}
                                              onClick={this.handleCheckAll.bind(this)}
                                        >{t('common.SelectAll')}</span>

                                    <span hidden={!this.state.statusCheck}
                                          onClick={this.handleCheckAll.bind(this)}
                                    >{t('common.UnSelect')}</span>
                                </div>
                            }
                        >
                            <section className="scroll-data-survey">
                                {userForSearch.map((user, index) => (
                                    <KirokuCard key={user.id}>
                                        <KirokuCheckBox
                                            onChange={this.handleCheckUser.bind(this, user)}
                                            key={index}
                                            checked={user.isChecked || false}
                                            content={user.name}/>
                                    </KirokuCard>
                                ))}
                            </section>
                        </KirokuCardList>
                    </div>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton onClick={this.handleCancel.bind(this)} color={'white'}>
                        {t('common.Cancel')}
                    </KirokuButton>
                    <KirokuButton disabled={this.isSelect()}
                                  onClick={this.setRole.bind(this)}
                                  color={'primary'}>
                        {t('common.Apply')}
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>
        );
    }
}

export default ModalSetRole;
