import moment from "moment";
import config from '../config';

class SurveyBuilderCondition {
    makeParams = (rawParams) => {
        return {
            status: rawParams.status,
            startDate: rawParams.startDate,
            endDate: rawParams.endDate,
            day: rawParams.day,
            week: rawParams.week,
            month: rawParams.month,
            creator: rawParams.creator,
            page: rawParams.page,
            title : rawParams.title
        }
    };

    makePramsRemoved = (removeConditions) => {
        let params = {};
        removeConditions.map(condition => {
            if (condition.query === 'creator') {
                params['nameStaff'] = '';
            }
            if (condition.query === 'status') {
                params['nameStatus'] = '';
            }
            if (condition.query === 'startDate' || condition.query ==='endDate') {
                params['nameDate'] = '';
            }
            if (condition.query === 'month' || condition.query === 'day' || condition.query === 'week') {
                params['nameDate'] = '';
            }
            return condition;
        });
        removeConditions.map(condition => {
            params[condition.query] = '';
            return condition;
        });
        return params;
    };

    makeParamsFromCondition = (conditions) => {
        let params = {};
        conditions.map(condition => {
            params[condition.query] = condition.value;
            return condition;
        });
        return params;
    };

    makeConditions = (state, params, t) => {
        return Object.keys(params).map(key => (
            {[key]: params[key], query: key, value: params[key], label: this.makeLabelCondition(state, key, t)}
        ));
    };

    makeLabelCondition = (state, key, t) => {
        switch (key) {
            case 'status':
                return state.status ? t('Surveys.Draft') : '';
            case 'staffId':
                return state.staffName;
            case 'startDate':
                let startDate = state.startDate ? `:${moment.unix(state.startDate).format(config.formatDateTh)}` : '';
                return t(`Surveys.${key}`) + `${startDate}`;
            case 'endDate':
                let endDate = state.endDate ? `:${moment.unix(state.endDate).format(config.formatDateTh)}` : '';
                return t(`Surveys.${key}`) + `${endDate}`;
            case 'page':
                return '';
            default:
                return t(`RecordList.${key}`)
        }
    };
}

export default SurveyBuilderCondition;
