import {HIDDEN_LOADING, SHOW_LOADING} from "../actions/LoadingAction";

const DefaultDataDraft = false;

const Loading = (state = DefaultDataDraft, action) => {
    switch (action.type) {
        case SHOW_LOADING : return true;
        case HIDDEN_LOADING : return false;
        default : return state
    }
};

export default Loading;
