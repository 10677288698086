import {LOAD_DRAFT_EDIT_RECORDING, LOAD_DRAFT_RECORDING} from "../actions/Draft";
import {RESET_RECORD_EDITOR_NEW}                         from "../actions/recordEditorNew";

const defaultApplicationSate = {
    backUpDataEditRecord: null,
    dataDraftRecording: null
};


const RecordEditorNew = (state = defaultApplicationSate, action) => {
    switch (action.type) {
        case LOAD_DRAFT_RECORDING:
            return  {
                ...state,
                dataDraftRecording: action.recordingDraft ? {
                    ...action.recordingDraft,
                    ...action.recordingDraft.dataDraft
                } : null
            };
        case LOAD_DRAFT_EDIT_RECORDING:
            return  {
                ...state,
                backUpDataEditRecord: action.dataDraftEditRecording ? {
                    ...action.dataDraftEditRecording,
                    ...action.dataDraftEditRecording.dataDraft
                } : null
            };
        case RESET_RECORD_EDITOR_NEW:
            return {
                ...defaultApplicationSate
            };

        default: return state
    }
};

export default RecordEditorNew;