import React, { Component } from 'react';


export default class KirokuLoading extends Component {
    render() {
        return (
            <div {...this.props}>
                <div className={'text-center'}>
                    <div className="lds-ellipsis">
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            </div>
        );
    }

}