export default class TeamFactory {


    makeTeam(team) {
        return {
            id: team.id,
            name: team.name,
            description: team.description,
            organization_id: team.organization_id,
            customers: team.customers,
            members: team.users
        }
    }
}