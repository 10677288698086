import React, { Component } from 'react';
import { Link }             from "react-router-dom";
import
{
    KirokuButton, KirokuCard,
    KirokuCheckBox,
    KirokuInput,
    KirokuInputDate,
    KirokuInputTel,
    KirokuLabel,
    KirokuInputSearch,
    KirokuDropdown,
    KirokuModal,
    KirokuCardList,
    KirokuDate,
    KirokuAge,
    KirokuRadio,
    KirokuPhoneNumber,
    KirokuButtonSpinner,
    KirokuModalHeader,
    KirokuModalFooter,
    KirokuModalBody,
    KirokuUploadFile,
    KirokuDropdownIcon,
    KirokuPopoverItem,
    KirokuCardAction,
    KirokuPagination,
    KirokuMultiSelect,
    KirokuDropdownClose,
    Paginator, KirokuButtonLink,
    KirokuComment
} from './ui';

import KirokuLoading         from './ui/KirokuLoading';
import { setSizePagination } from "./components/Records/NewRecords/serviceNewRecords";
import config                from './config';

export const options = [
    {value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true},
    {value: 'blue', label: 'Blue', color: '#0052CC', disabled: true},
    {value: 'purple', label: 'Purple', color: '#5243AA'},
    {value: 'red', label: 'Red', color: '#FF5630', isFixed: true},
    {value: 'orange', label: 'Orange', color: '#FF8B00'},
    {value: 'yellow', label: 'Yellow', color: '#FFC400'},
    {value: 'green', label: 'Green', color: '#36B37E'},
    {value: 'forest', label: 'Forest', color: '#00875A'},
    {value: 'slate', label: 'Slate', color: '#253858'},
    {value: 'silver', label: 'Silver', color: '#666666'},
];

class Action extends Component {

    constructor() {
        super();

        this.handleClick        = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            popupVisible: false,
            url: ''
        };
    }

    handleClick() {
        if (! this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => (
            {
                popupVisible: ! prevState.popupVisible,
            }
        ));
    }

    handleOutsideClick(e) {
        if (! this.node) {
            return;
        }
        this.handleClick();
    }

    render() {
        return (
            <div className="action-customer-surveys" ref={node => {
                this.node = node
            }}>
                <KirokuCardAction>
                    <div className="action-icon">
                        <i style={{color: '#1991eb'}} className="fas fa-ellipsis-h"
                           onClick={this.handleClick.bind(this)}/>
                    </div>
                    {this.state.popupVisible && (
                        <div onClick={this.handleClick.bind(this)}>
                            {this.props.children}
                        </div>
                    )}
                </KirokuCardAction>
            </div>
        )
    }
}

export default class Demo extends Component {
    state = {
        show: false
    };

    closeModal() {
        this.setState({
            show: ! this.state.show
        })
    }

    hanldleChangeImg(e) {
        console.log(e.target.value);
    }

    render() {

        return (
            <div style={{background: '#eef3f6'}}>
                <div className='p-2'>
                    <div>
                        <KirokuComment
                           comment={{
                               name: 'asdas',
                               avatar: 'https://lh3.googleusercontent.com/-_DDNoueoF6M/AAAAAAAAAAI/AAAAAAAAAAA/ACHi3rd8lZJ_28gJ9z3twjIGMlh-S1g5WQ.CMID/s83-c/photo.jpg',
                               create_at: 1573446356,
                               content: ' hoi gi nhieu the'
                           }}
                        >
                            content comment
                        </KirokuComment>
                    </div>

                    <div style={{float: 'left', marginBottom: 2}}>
                        <Paginator sizePagination={setSizePagination}
                                   onPaginatorChange={(e) => {console.log(e);}}
                                   textBack={'Back'}
                                   textNext={'Next'}
                        />
                    </div>
                    <br/>
                    <h2>Dropdown:</h2>
                    <hr/>
                    <KirokuDropdown
                        title={'Dropdown down'}
                        items={[
                            {
                                value: 'item2',
                                component: (
                                    <div><h3>Item 2</h3>
                                        <div><span className={'text-muted'}>This is select description</span></div>
                                    </div>
                                ),
                                label: 'item2'
                            },
                            {
                                value: 'item2',
                                component: (
                                    <div><h3>Item 2</h3>
                                        <div><span className={'text-muted'}>This is select description</span></div>
                                    </div>
                                ),
                                label: 'item2'
                            },
                            {
                                value: {foo: 'bar'},
                                component: (
                                    <div><h3>Item 3</h3>
                                        <div><span className={'text-muted'}>This is select description</span></div>
                                    </div>
                                ),
                                label: 'item3'
                            },
                        ]}
                        onSelect={event => {
                            console.log(event.kirokuSelected)
                        }}
                    />
                    <br/>
                    <KirokuDropdownClose
                        title={'Dropdown outside close'}
                        items={[
                            {
                                value: 'item2',
                                component: (
                                    <div><h3>Item 2</h3>
                                        <div><span className={'text-muted'}>This is select description</span></div>
                                    </div>
                                ),
                                label: 'item2'
                            },
                            {
                                value: 'item2',
                                component: (
                                    <div><h3>Item 2</h3>
                                        <div><span className={'text-muted'}>This is select description</span></div>
                                    </div>
                                ),
                                label: 'item2'
                            },
                            {
                                value: {foo: 'bar'},
                                component: (
                                    <div><h3>Item 3</h3>
                                        <div><span className={'text-muted'}>This is select description</span></div>
                                    </div>
                                ),
                                label: 'item3'
                            },
                        ]}
                        onSelect={event => {
                            console.log(event.kirokuSelected)
                        }}
                    />
                    <br/>
                    <KirokuDropdownIcon
                        title={'Dropdown down'}
                        icon={'far fa-file-alt mr-1'}
                        items={[
                            {
                                value: 'item2',
                                component: (
                                    <div><h3>Item 2</h3>
                                        <div><span className={'text-muted'}>This is select description</span></div>
                                    </div>
                                ),
                                label: 'item2'
                            },
                            {
                                value: 'item2',
                                component: (
                                    <div><h3>Item 2</h3>
                                        <div><span className={'text-muted'}>This is select description</span></div>
                                    </div>
                                ),
                                label: 'item2'
                            },
                            {
                                value: {foo: 'bar'},
                                component: (
                                    <div><h3>Item 3</h3>
                                        <div><span className={'text-muted'}>This is select description</span></div>
                                    </div>
                                ),
                                label: 'item3'
                            },
                        ]}
                        onSelect={event => {
                            console.log(event.kirokuSelected)
                        }}
                    />
                    <br/>
                    <KirokuDropdown
                        title={'Dropdown top'}
                        location={'top'}
                        items={[
                            {
                                value: 'item2',
                                component: (
                                    <div><h3>Item 2</h3>
                                        <div><span className={'text-muted'}>This is select description</span></div>
                                    </div>
                                ),
                                label: 'item2'
                            },
                            {
                                value: 'item2',
                                component: (
                                    <div><h3>Item 2</h3>
                                        <div><span className={'text-muted'}>This is select description</span></div>
                                    </div>
                                ),
                                label: 'item2'
                            },
                            {
                                value: {foo: 'bar'},
                                component: (
                                    <div><h3>Item 3</h3>
                                        <div><span className={'text-muted'}>This is select description</span></div>
                                    </div>
                                ),
                                label: 'item3'
                            },
                        ]}
                        onSelect={event => {
                            console.log(event.kirokuSelected)
                        }}
                    />
                    <br/>
                    <h2>Multi select</h2>
                    <hr/>
                    <KirokuMultiSelect options={options}/>
                    <br/>
                    <h2>Button:</h2>
                    <hr/>
                    <div style={{display: 'flex'}}>
                        <div>
                            <KirokuButton color={'success'}>Button</KirokuButton>
                            <br/>
                            <br/>
                            <KirokuButton color={'primary'}>Button</KirokuButton>
                            <br/>
                            <br/>
                            <KirokuButton color={'white'}>Button</KirokuButton>
                            <br/>
                            <KirokuButtonLink>Button</KirokuButtonLink>
                        </div>
                        &nbsp;
                        <div>
                            <KirokuButton color={'warning'}>Button</KirokuButton>
                            <br/>
                            <br/>
                            <KirokuButton color={'danger'}>Button</KirokuButton>
                            <br/>
                            <br/>
                            <KirokuButton color={'light'}>Button</KirokuButton>
                            <br/>
                        </div>
                    </div>
                    <br/>
                    <h2>Button spinner:</h2>
                    <hr/>
                    <div style={{display: 'flex'}}>
                        <div>
                            <KirokuButtonSpinner color={'success'}/>
                            <br/>
                            <br/>
                            <KirokuButtonSpinner color={'primary'}/>
                            <br/>
                            <br/>
                            <KirokuButtonSpinner color={'white'}/>
                        </div>
                        &nbsp;
                        <div>
                            <KirokuButtonSpinner color={'warning'}/>
                            <br/>
                            <br/>
                            <KirokuButtonSpinner color={'danger'}/>
                            <br/>
                            <br/>
                            <KirokuButtonSpinner color={'light'}/>
                            <br/>
                        </div>
                    </div>
                    <br/>
                    <h2>Checkbox:</h2>
                    <hr/>
                    <div>
                        <div>
                            <KirokuCheckBox disabled={true} content={'chechbox2'}/>
                        </div>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div>
                            <KirokuCheckBox disabled={false} content={'chechbox1'}/>
                        </div>
                    </div>
                    <h2>Radio:</h2>
                    <KirokuRadio id={'customRadio'} name={'example1'}
                                 onChange={(e) => console.log(e)}>example1</KirokuRadio>
                    <KirokuRadio id={'customRadio1'} name={'example1'}
                                 onChange={(e) => console.log(e)}>example2</KirokuRadio>
                    <br/>
                    <h2>Input:</h2>
                    <hr/>
                    <br/>
                    <KirokuInput placeholder={'Placeholder...'} type={'text'}/>
                    <br/>
                    <KirokuInputDate type={'date'} placeholder={'Placeholder...'}/>
                    <br/>
                    <KirokuInputTel type={'tel'} placeholder={'Placeholder...'}/>
                    <br/>
                    <KirokuInputSearch placeholder={'Search...'}/>
                    <br/>
                    <h2>Label</h2>
                    <hr/>
                    <br/>
                    <div>
                        <div style={{display: 'flex'}}>
                            <KirokuLabel color={'success'}>value</KirokuLabel>&nbsp;
                            <br/>
                            <KirokuLabel color={'primary'}>value</KirokuLabel>&nbsp;
                            <br/>
                            <KirokuLabel color={'dark'}>value</KirokuLabel>&nbsp;
                            <br/>
                            <KirokuLabel color={'default'}>value</KirokuLabel>&nbsp;
                        </div>
                        <div style={{display: 'flex'}}>
                            <KirokuLabel color={'muted'}>value</KirokuLabel>&nbsp;
                            <br/>
                            <KirokuLabel color={'warning'}>value</KirokuLabel>&nbsp;
                            <br/>
                            <KirokuLabel color={'danger'}>value</KirokuLabel>&nbsp;
                            <br/>
                            <KirokuLabel color={'light'}>value</KirokuLabel>&nbsp;
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <h2>Cards</h2>
                    <hr/>
                    <KirokuCard>
                        Content here
                    </KirokuCard>
                    <br/>
                    <h2>Card List</h2>
                    <br/>
                    <KirokuCardList
                        headerLeft='Surveys'
                        headerRight='Actions'
                    >
                        <KirokuCard
                            action={
                                <Action>
                                    <KirokuPopoverItem>
                                        <div className='d-flex justify-content-between p-1 fix-icon-align'>
                                            <Link to="/#">
                                                <i className="fas fa-pencil-alt"/><span className='ml-1'>Edit</span>
                                            </Link>
                                            <Link to="/#">
                                                <i className="fas fa-clone"/><span className='ml-1'>Duplicate</span>
                                            </Link>
                                            <Link to="/#">
                                                <i className="fas fa-binoculars"/><span className='ml-1'>Preview</span>
                                            </Link>
                                        </div>
                                    </KirokuPopoverItem>
                                </Action>
                            }
                        >
                            <b>Survey name 1</b>
                            <p>This is description of survey</p>
                        </KirokuCard>
                        <KirokuCard
                            action={
                                <Action>
                                    <KirokuPopoverItem>
                                        <div className='d-flex justify-content-between p-1 fix-icon-align'>
                                            <Link to="/#">
                                                <i className="fas fa-pencil-alt"/><span className='ml-1'>Edit</span>
                                            </Link>
                                            <Link to="/#">
                                                <i className="fas fa-clone"/><span className='ml-1'>Duplicate</span>
                                            </Link>
                                            <Link to="/#">
                                                <i className="fas fa-binoculars"/><span className='ml-1'>Preview</span>
                                            </Link>
                                        </div>
                                    </KirokuPopoverItem>
                                </Action>
                            }
                        >
                            <b>Survey name 1</b>
                            <p>This is description of survey</p>
                        </KirokuCard>
                        <KirokuCard
                            action={
                                <Action>
                                    <KirokuPopoverItem>
                                        <div className='d-flex justify-content-between p-1 fix-icon-align'>
                                            <Link to="/#">
                                                <i className="fas fa-pencil-alt"/><span className='ml-1'>Edit</span>
                                            </Link>
                                            <Link to="/#">
                                                <i className="fas fa-clone"/><span className='ml-1'>Duplicate</span>
                                            </Link>
                                            <Link to="/#">
                                                <i className="fas fa-binoculars"/><span className='ml-1'>Preview</span>
                                            </Link>
                                        </div>
                                    </KirokuPopoverItem>
                                </Action>
                            }
                        >
                            <b>Survey name 1</b>
                            <p>This is description of survey</p>
                        </KirokuCard>
                    </KirokuCardList>
                    <h2>Modal</h2>
                    <hr/>
                    <KirokuButton color={'danger'} onClick={this.closeModal.bind(this)}>Show Modal</KirokuButton>
                    <KirokuModal show={this.state.show} onCloseModal={this.closeModal.bind(this)}>
                        <KirokuModalHeader>
                            LoveRain
                        </KirokuModalHeader>
                        <KirokuModalBody>
                            <i>LoveRain</i>
                        </KirokuModalBody>
                        <KirokuModalFooter>
                            <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                                Cancel
                            </KirokuButton>
                            <KirokuButton color={'primary'}>
                                Red Dot
                            </KirokuButton>
                        </KirokuModalFooter>
                    </KirokuModal>
                    <br/>
                    <hr/>
                    <h2>Date</h2>
                    <KirokuDate date={new Date()} format={config.formatDateTh}/>

                    <h2>Age from Dob</h2>
                    <KirokuAge dob={new Date('1990/12/25')}/>
                    <hr/>
                    <h2>Phone number format</h2>
                    <KirokuPhoneNumber phone={'+84973645596'}/>
                    <br/>
                    <h2>Upload media</h2>
                    <KirokuUploadFile url={this.state.url} onChange={this.hanldleChangeImg.bind(this)}/>
                    <br/>
                    <hr/>
                    {/*<h2>Notification</h2>*/}
                    {/*<KirokuNotification/>*/}
                    <h2>Loading</h2>
                    <KirokuLoading/>
                    <hr/>
                    <h2>Pagination</h2>
                    <KirokuPagination pageNow={1000} totalPage={2000}/>
                </div>
            </div>
        );
    }
}

