import { Web as Screen } from '../components/DeletedPage/ListWeb';
import { Mobile as ScreenMobile } from '../components/DeletedPage/ListWeb';


export function getItems(n) {
    let arrayItem = [];
    let i = 1;
    while (i <= n){
        arrayItem.push({
            value: i,
            name: i
        });
        i++;
    }
    return arrayItem;
}

const initStatus = {
    listScreen : [
        {
            screenName: 'surveys',
        },
        {
            screenName: 'records',
        },
        {
            screenName: 'customers',
        },
        {
            screenName: 'staffs',
        },
    ],
    ComponentFlowScreen: Screen.staffs,
    page: 1,
    hasNext: true,
    isMobile: false,
    totalPages: 1,
    items: getItems(1)
};

export const DeletedList = (state = initStatus, action) => {
    switch (action.type) {
        case 'CHANGE_ORDER_BY_SURVEYS':
        case 'CHANGE_ORDER_BY_RECORDS':
        case 'CHANGE_ORDER_BY_CUSTOMER':
        case 'CHANGE_ORDER_BY_STAFFS':
            return {
                ...state,
                hasNext: 1 < action.totalPages,
                page: action.page,
                totalPages: action.totalPages,
                items: getItems(action.totalPages),
            };
        case 'CHANGE_SCREEN' :
            return {
                ...state,
                hasNext: 1 < action.totalPages,
                page: 1,
                totalPages: action.totalPages,
                items: getItems(action.totalPages),
                ComponentFlowScreen: state.isMobile ? ScreenMobile[action.screen] :  Screen[action.screen]
            };
        case 'CHANGE_PAGE_NUMBER':
            return {
                ...state,
                page: action.numberPage,
                totalPages: action.totalPages,
                items: getItems(action.totalPages),
                hasNext: action.numberPage < action.totalPages
            };
        case 'IS_MOBILE' :
            return {
                ...state,
                isMobile : action.isMobile,
                totalPages: action.totalPages,
                items: getItems(action.totalPages),
                ComponentFlowScreen: action.isMobile ? ScreenMobile.surveys :  Screen.surveys
            };
        case 'SUBMIT_SEARCH_KEYWORD_SURVEYS':
        case 'SUBMIT_SEARCH_KEYWORD_RECORDS':
        case 'SUBMIT_SEARCH_KEYWORD_STAFFS':
        case 'SUBMIT_SEARCH_KEYWORD_CUSTOMERS':
            return {
                ...state,
                page: 1,
                totalPages: action.totalPages,
                items: getItems(action.totalPages),
                hasNext: 1 < action.totalPages
            };
        default :
            return state;
    }
};
