import React, { Component } from 'react';

class AutoResizeInputArea extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { contentComment } = this.props;
        this.element.setAttribute('rows', contentComment.split("\n").length);
    }

    handleOnChange(event) {
        this.props.onChange(event);
        this.element.setAttribute('rows', event.currentTarget.value.split("\n").length);
    }

    render() {
        const { contentComment, onBlur } = this.props;
        return (
            <textarea ref={(ref) => {this.element = ref;}}
                      className={`form-area ${this.props.error}`}
                      onChange={event => {this.handleOnChange(event);}}
                      value={contentComment}
                      style={this.props.style}
                      onBlur={onBlur}
            />
        )
    }
}

export default AutoResizeInputArea;
