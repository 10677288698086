import {
    CHANGE_SERVICE_AT, SELECT_CUSTOMERS, SELECT_TEMPLATES,
    RESET_CONDITIONS, SEARCH_REPORTS, CHANGE_TEMPLATE_REPORTS, REMOVE_REPORTS, PRINT_REPORTS
} from '../actions/Reports'
import momentTZ from "moment-timezone";

const initialState = {
    conditions: {
        serviceAt: {
            startDate: null,
            endDate: null,
            momentStartDate: null,
            momentEndDate: null,
        },
        currentCustomers: [],
        currentTemplate: [],
        template: "TemplateRecord",
    },
    reports: {
        TemplateRecord: {},
        TemplateQuestion: {},
        TemplateOnly: {},
        CustomerRecord: {},
        CustomerQuestion: {},
        CustomerOnly: {}
    },
    tab: {
        isActive: "TemplateRecord"
    },
};

const Reports = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_SERVICE_AT:
            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    serviceAt: {
                        startDate: action.dateRange.startDate,
                        endDate: action.dateRange.endDate,
                        momentStartDate: action.dateRange.momentStartDate,
                        momentEndDate: action.dateRange.momentEndDate,
                    },
                }
            };
        case SELECT_CUSTOMERS:
            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    currentCustomers: action.customers
                }
            };
        case SELECT_TEMPLATES:
            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    currentTemplate: action.templates
                }
            };
        case RESET_CONDITIONS:
            return {
                ...initialState
            };

        case "REMOVE_DATE":
            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    serviceAt: initialState.conditions.serviceAt
                }
            };

        case SEARCH_REPORTS:
            switch (action.report["templateReport"]) {
                case "TemplateRecord":
                    return {
                        ...state,
                        reports: {
                            ...state.reports,
                            TemplateRecord: action.report
                        }
                    };
                case "TemplateQuestion":
                    return {
                        ...state,
                        reports: {
                            ...state.reports,
                            TemplateQuestion: action.report
                        }
                    };
                case "TemplateOnly":
                    return {
                        ...state,
                        reports: {
                            ...state.reports,
                            TemplateOnly: action.report
                        }
                    };
                case "CustomerRecord":
                    return {
                        ...state,
                        reports: {
                            ...state.reports,
                            CustomerRecord: action.report
                        }
                    };
                case "CustomerQuestion":
                    return {
                        ...state,
                        reports: {
                            ...state.reports,
                            CustomerQuestion: action.report
                        }
                    };
                case "CustomerOnly":
                    return {
                        ...state,
                        reports: {
                            ...state.reports,
                            CustomerOnly: action.report
                        }
                    };
                default:
                    return {
                        ...state,
                        reports: initialState.reports

                    }

            }

        case CHANGE_TEMPLATE_REPORTS:
            return {
                ...state,
                tab: {
                    isActive: action.template
                },
                conditions: {
                    ...state.conditions,
                    template: action.template
                }
            };
        case REMOVE_REPORTS:
            return {
                ...state,
                reports: initialState.reports
            };

        case PRINT_REPORTS:
            let token     = localStorage.getItem('token');
            let lang      = localStorage.getItem('i18nextLng') || 'ja';
            let timezone  = momentTZ.tz.guess();
            window.open(`${process.env.REACT_APP_DOMAIN_DEV}/print-reports/report/${action.template}?startDate=${action.condition.serviceAt.startDate ? action.condition.serviceAt.startDate : ''}&customerIds=${action.condition.customerIds}&templateIds=${action.condition.templateIds}&endDate=${action.condition.serviceAt.endDate ? action.condition.serviceAt.endDate : ''}&lang=${lang}&timezone=${timezone}&token=${token}`, "MsgWindow", '_blank');
            return {
                ...state
            };
        default:
            return state;
    }
};

export default Reports
