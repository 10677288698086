import React, {Component}  from 'react';
import {connect}           from "react-redux";
import KirokuModal         from "../../../ui/KirokuModal";
import KirokuModalHeader   from "../../../ui/KirokuModalHeader";
import KirokuModalBody     from "../../../ui/KirokuModalBody";
import KirokuModalFooter   from "../../../ui/KirokuModalFooter";
import KirokuInputSearch   from "../../../ui/KirokuInputSearch";
import KirokuCardList      from "../../../ui/KirokuCardList";
import KirokuCard          from "../../../ui/KirokuCard";
import KirokuCheckBox      from "../../../ui/KirokuCheckBox";
import KirokuButton        from "../../../ui/KirokuButton";
import {resetSelectMember} from "../../../actions/User";
import {withNamespaces}    from "react-i18next";
import {
    checkAllListMember, checkIsChecked,
    checkMemberAddTeam,
    getListMember,
    removeCheckAllListMember,
    searchMember,
    turnOffModalAddMember
}                          from "../../../actions/EditorTeam";

@withNamespaces()
@connect(state => {
    return {
        users: state.KirokuAIUser.users,
        statusModal: state.EditorTeam.showModalAddMember,
        memberList: state.EditorTeam.memberList,
        statusCheck: state.EditorTeam.statusCheck
    }
}, dispatch => {
    return {
        resetSelectMember: () => {
            dispatch(resetSelectMember())
        },
        turnOffModalAddMember: () => {
            dispatch(turnOffModalAddMember())
        },
        checkMemberAddTeam: (index) => {
            dispatch(checkMemberAddTeam(index))
        },
        checkAllListMember: () => {
            dispatch(checkAllListMember())
        },
        removeCheckAllListMember: () => {
            dispatch(removeCheckAllListMember())
        },
        getListMember: () => {
            dispatch(getListMember())
        },
        searchMember: (name) => {
            dispatch(searchMember(name))
        },
        checkIsChecked: () => {
            dispatch(checkIsChecked())
        }
    }
})
class ModalSelectMember extends Component {

    state = {
        keyword: ''
    };

    handleCheckAll() {
        this.props.checkAllListMember();
    }

    handleRemoveCheckAll() {
        this.props.removeCheckAllListMember()
    }

    handleCheckMembers(index) {
        this.props.checkMemberAddTeam(index)
    }

    handleCancel() {
        this.props.turnOffModalAddMember();
        this.setState({
            keyword: ''
        })
    }

    onSearch(e) {
        this.props.searchMember(e.target.value.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, ''));
        this.setState({
            keyword: e.target.value
        })
    }

    componentWillUnmount() {
        this.props.resetSelectMember();
    }

    handleApply() {
        this.props.turnOffModalAddMember();
        this.props.getListMember();
        this.setState({
            keyword: ''
        })
    }

    render() {
        const {t, statusModal, memberList, statusCheck} = this.props;
        const {keyword}                                 = this.state;
        return (
            <KirokuModal show={statusModal}>
                <KirokuModalHeader>
                    <h6 className="mb-3">{t('Team.AddMember')}</h6>
                    <KirokuInputSearch value={keyword} onChange={this.onSearch.bind(this)}
                                       placeholder={t('Team.Customer name or team name')}/>
                </KirokuModalHeader>
                <KirokuModalBody>
                    <div id="body-modal-as">
                        <KirokuCardList
                            headerLeft={t('common.Members')}
                            headerRight={memberList[0] ?
                                <div className='text-primary' style={{cursor: 'pointer'}}>
                                        <span hidden={statusCheck}
                                              onClick={this.handleCheckAll.bind(this)}
                                        >{t('common.SelectAll')}</span>

                                    <span hidden={!statusCheck}
                                          onClick={this.handleRemoveCheckAll.bind(this)}
                                    >{t('common.UnSelect')}</span>
                                </div> : <div/>
                            }
                        >
                            <section className="scroll-data-survey">
                                {memberList.map((member, index) => (
                                    <KirokuCard key={index}
                                                action={<p>{t(`role.${member.role}`)}</p>}>
                                        <KirokuCheckBox
                                            onChange={this.handleCheckMembers.bind(this, index)}
                                            key={index}
                                            checked={member.isChecked}
                                            content={member.name}/>
                                    </KirokuCard>
                                ))}
                            </section>

                        </KirokuCardList>
                    </div>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton onClick={this.handleCancel.bind(this)} color={'white'}>
                        {t('common.Cancel')}
                    </KirokuButton>
                    <KirokuButton onClick={this.handleApply.bind(this)} color={'primary'}>
                        {t('common.Apply')}
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>
        );
    }
}

export default ModalSelectMember;
