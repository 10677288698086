import React, {Component} from 'react';
import {currentPage}      from "../../../actions/CurrentPage";
import {connect}          from "react-redux";
import KirokuTitlepage    from "../../../ui/KirokuTitlepage";
import {getDetailTeam}    from "../../../actions/Team";
import KirokuCardWeb      from "../../../ui/KirokuCardWeb";
import KirokuTable        from "../../../ui/KirokuTabLe";
import THeader            from "../../../ui/THeader";
import TBody              from "../../../ui/TBody";
import KirokuButton       from "../../../ui/KirokuButton";
import {withNamespaces}   from "react-i18next";
import KirokuLoading      from "../../../ui/KirokuLoading";
import KirokuAge          from "../../../ui/KirokuAge";
import {Link}             from "react-router-dom";
import moment             from "moment";

@withNamespaces()
@connect(state => {
    return {
        team: state.KirokuAITeam.team,
        currentPage: state.KirokuAIRecord.currentPage,
        loading: state.KirokuAITeam.loading
    }
}, dispatch => {
    return {
        currentPage: (name) => {
            dispatch(currentPage(name))
        },
        getDetailTeam: (idTeam) => {
            dispatch(getDetailTeam(idTeam))
        },
        resetState: () => {
            dispatch({
                type: 'DEFAULT_TEAM'
            })
        }
    }
})

class TeamDetailWeb extends Component {

    page = {
        title: <KirokuTitlepage title={this.props.t('Team.Team')}/>,
        actionPage: null
    };

    state = {
        showMember: false,
        showCustomer: true,
        namePage: this.page,
        team: [],
        members: [],
        customers: []
    };

    goBack() {
        this.props.history.goBack()
    }

    showMember() {
        this.setState({
            showCustomer: false,
            showMember: true
        })
    }

    showCustomer() {
        this.setState({
            showCustomer: true,
            showMember: false
        })
    }

    componentWillMount() {
        this.props.getDetailTeam(this.props.id);
        this.props.currentPage(this.state.namePage)
    }

    componentWillUnmount() {
        this.props.resetState()
    }

    render() {
        let {t}         = this.props;
        const teams     = this.props.team;
        const members   = this.props.team.members;
        const customers = this.props.team.customers;
        return (
            <div id='web' className="padding-responsive">
                {!this.props.loading ? <div>
                    <div className="pb-2">
                        <KirokuButton color='white'
                                      onClick={this.goBack.bind(this)}>{t('common.Back')}</KirokuButton>
                    </div>
                    <KirokuCardWeb>
                        <div className='d-flex justify-content-between p-3'>
                            <div className=''>
                                <h5>{teams.name}</h5>
                                <pre style={{
                                    color: '#7f8fa4',
                                    wordWrap: 'break-word',
                                    whiteSpace: 'pre-wrap'
                                }}>{teams.description}</pre>
                            </div>
                        </div>
                        <div className='fix-center'>
                            <div className='pl-3 pr-3 pb-3' style={{width: '50%'}}>
                                <KirokuButton onClick={this.showCustomer.bind(this)}
                                              className='btn-switch-1'
                                              color={this.state.showMember ? 'white' : 'success'}>{t('common.Customer')}</KirokuButton>
                                <KirokuButton onClick={this.showMember.bind(this)}
                                              className='btn-switch-2'
                                              color={this.state.showMember ? 'success' : 'white'}>{t('common.Members')}</KirokuButton>
                            </div>
                        </div>
                        <div hidden={this.state.showCustomer}>
                            <KirokuTable>
                                <THeader>
                                    <tr>
                                        <th>{t('common.Name')}</th>
                                        <th>{t('common.Role')}</th>
                                        <th>{t('common.Title')}</th>
                                        <th>{t('common.WorkingForm')}</th>
                                    </tr>
                                </THeader>
                                <TBody>
                                    {members.map(member =>
                                        <tr key={member.id}>
                                            <td>
                                                <Link to={`/staffs/staff/${member.id}`}>
                                                    <span>{member.name}</span>
                                                </Link>
                                            </td>
                                            <td>{t(`role.${member.role}`)}</td>
                                            <td>{member.title}</td>
                                            <td>{t(`common.${member.working_form}`)}</td>
                                        </tr>
                                    )}
                                </TBody>
                            </KirokuTable>
                        </div>
                        <div hidden={this.state.showMember}>
                            <KirokuTable>
                                <THeader>
                                    <tr>
                                        <th>{t('common.Name')}</th>
                                        <th>{t('common.Age')}</th>
                                        <th>{t('Customer.KindOfCertificate')}</th>
                                        <th>{t('CustomerEditor.Characteristic')}</th>
                                    </tr>
                                </THeader>
                                <TBody>
                                    {customers.map(customer => {
                                            let certificate    = customer.certificate.map(item => {
                                                return t(`Customer.certificate.${item}`)
                                            });
                                            let rawCertificate = certificate.join(', ');
                                            return <tr key={customer.id}>
                                                <td>
                                                    <Link to={`/customers/customer-detail/${customer.id}`}>
                                                        {customer.name}
                                                    </Link>
                                                </td>
                                                <td><KirokuAge
                                                    dob={customer.date_of_birth === null || customer.date_of_birth === '' ? '' : moment.unix(customer.date_of_birth)}/>
                                                </td>
                                                <td>{rawCertificate}</td>
                                                <td>{customer.characteristic}</td>
                                            </tr>
                                        }
                                    )}
                                </TBody>
                            </KirokuTable>
                        </div>
                    </KirokuCardWeb>
                    < div className='pt-10 d-flex justify-content-between'>
                        <KirokuButton color='white' onClick={this.goBack.bind(this)}>{t('common.Back')}</KirokuButton>
                        <div/>
                    </div>
                </div> : <KirokuLoading/>}
            </div>
        )
    }
}

export default TeamDetailWeb;
