import React, {Component} from 'react'
import KirokuModal        from "../../../ui/KirokuModal";
import KirokuModalBody    from "../../../ui/KirokuModalBody";
import KirokuModalFooter  from "../../../ui/KirokuModalFooter";
import KirokuButton       from "../../../ui/KirokuButton";
import {connect}          from "react-redux";
import {deleteUsers}      from "../../../actions/User";
import {withNamespaces}   from "react-i18next";

@withNamespaces()

@connect(() => ({}), (dispatch) => {
    return {
        deleteUsers: (id, params, tags) => {
            dispatch(deleteUsers(id, params, tags))
        }
    }
})

class ModalDeleteUser extends Component {

    closeModal() {
        this.props.closeModalDeleteUser()
    }

    deleteCustomer() {
        this.props.deleteUsers(this.props.users.id, this.props.params, {tags : this.props.tags});
        this.props.closeModalDeleteUser();
    }

    render() {
        let {t} = this.props;
        return (
            <KirokuModal show={this.props.show} onClick={this.closeModal.bind(this)}>
                <KirokuModalBody>
                    <p className='text-center pt-10'>
                        {t('staff.messageDelete')}
                    </p>
                    <p className='text-center'>
                        {this.props.users.name}
                    </p>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                        {t('common.Cancel')}
                    </KirokuButton>
                    <KirokuButton onClick={this.deleteCustomer.bind(this)}
                                  color={'primary'}>
                        {t('common.Confirm')}
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>

        )
    }

}

export default ModalDeleteUser;
