import {HIDDEN_LAYOUT,RELOAD_AVATAR} from '../actions/ActionLayout';

const defaultState = {
    hiddenLayout: false,
    reloadAvatar: false
};

const ReduceActionLayout =(state = defaultState, action) => {
    switch (action.type) {
        case HIDDEN_LAYOUT:
            return {
                ...state,
                hiddenLayout: !state.hiddenLayout
            };
        case RELOAD_AVATAR:
            return {
                ...state,
                reloadAvatar: !state.reloadAvatar
            };
        default:
            return state
    }
};

export default ReduceActionLayout;