import React, { Component }                                                  from 'react';
import { KirokuInput, KirokuSectionDropdownShowMoreMobile, KirokuTitlepage } from '../../../../ui';
import TableCustomerSelected                                                 from './TableCustomerSelected';
import { withNamespaces }                                                    from 'react-i18next';
import { connect }                                                           from 'react-redux';
import { currentPage }                                                       from '../../../../actions/CurrentPage';
import { getTeamsBeta }                                                      from '../../../../actions/Team';
import { getCustomersBeta }                                                  from '../../../../actions/Customer';
import { getSurveysBeta }                                                    from '../../../../actions/Survey';
import { getStaffsBeta }                                                     from '../../../../actions/User';
import { getGroupedOptions }                                                 from '../../NewRecords/serviceNewRecords';


function page(t) {
    return {
        title     : <KirokuTitlepage title={t('RecordsBeta.customerSelection')}/>,
        actionPage: null,
    };
}

@withNamespaces()

@connect(state => {
    return {
        teams            : state.KirokuAITeam.teamsBeta,
        isOpenSelected   : state.KirokuModal.ModalSelectCustomers.isOpenSelected,
        teamsSelected    : state.KirokuModal.ModalSelectCustomers.teamsSelected,
        keyword          : state.KirokuModal.ModalSelectCustomers.keyword,
        customersSelected: state.KirokuModal.ModalSelectCustomers.customersSelected,

    };
}, dispatch => {
    return {
        namePage        : (name) => {
            dispatch(currentPage(name));
        },
        getTeamsBeta    : () => {
            dispatch(getTeamsBeta());
        },
        getCustomersBeta: () => {
            dispatch(getCustomersBeta());
        },
        getSurveysBeta  : () => {
            dispatch(getSurveysBeta());
        },

        getStaffsBeta: () => {
            dispatch(getStaffsBeta());
        },

        getCustomersByUserId: (userId) => {
            dispatch({
                type: 'GET_CUSTOMERS_BY_USER',
                userId,
            });
        },

        getCustomersOfTeamByUserId        : (userId) => {
            dispatch({
                type: 'GET_CUSTOMERS_OF_TEAM_BY_USER',
                userId,
            });
        },
        changeIsOpenSelected              : (isOpenSelected) => {
            dispatch({
                type: 'CHANGE_IS_OPEN_SELECTED_CUSTOMERS',
                isOpenSelected,
            });
        },
        handleSelectCustomerInTeam        : (teamsSelected) => {
            dispatch({
                type: 'SELECT_CUSTOMERS_IN_TEAMS',
                teamsSelected,
            });
        },
        handleChangeKeyword               : (keyword) => {
            dispatch({
                type: 'CHANGE_KEYWORD_SEARCH_CUSTOMERS_MODAL',
                keyword,
            });
        },
        handleSelectCustomerInTeamSelected: (teamsSelected) => {
            dispatch({
                type: 'SELECT_CUSTOMERS_IN_TEAMS_SELECTED',
                teamsSelected,
            });
        },
        handleChangeKeywordSelected       : (keyword) => {
            dispatch({
                type: 'CHANGE_KEYWORD_SEARCH_CUSTOMERS_MODAL_SELECTED',
                keyword,
            });
        },
    };
})


class CustomerSelected extends Component {

    state = {
        isLoading: false,
        namePage : page(this.props.t),
    };


    handleClickOpenSelected(isSelectedScreen) {
        this.props.changeIsOpenSelected(isSelectedScreen);
    }

    render() {
        const { t, isOpenSelected, teams, teamsSelected, keyword } = this.props;

        return (
            <div className='default-background-color'>
                <div className="pt-3 pl-1 pr-1">
                    <div className="d-flex justify-content-center">
                        <div
                            className={`border-width-each-status font-text-on-btn first-btn ${isOpenSelected ? 'btn-active' : ''}`}
                            onClick={this.handleClickOpenSelected.bind(this, true)}
                        >
                            {t('RecordsBeta.displaySelection')}
                        </div>
                        <div
                            className={`border-width-each-status font-text-on-btn third-btn ${!isOpenSelected ? 'btn-active' : ''}`}
                            onClick={this.handleClickOpenSelected.bind(this, false)}
                        >
                            {t('RecordsBeta.showAll')}
                        </div>
                    </div>
                </div>
                <div className="pt-3 pl-1 pr-1" hidden={!isOpenSelected}>
                    <div className='font-text-on-btn set-height-42'>
                        <KirokuSectionDropdownShowMoreMobile
                            tagType={'Team'}
                            onChange={(teamsSelected) => {
                                this.props.handleSelectCustomerInTeamSelected(teamsSelected);
                            }}
                            options={getGroupedOptions('TeamId', teams)}
                            value={teamsSelected}
                            placeholder={t('RecordsBeta.refineByTeam')}/>

                    </div>
                    <div className="pt-2 font-text-on-btn">
                        <KirokuInput value={keyword}
                                     className={'css-input-medium'}
                                     onChange={(e) => this.props.handleChangeKeywordSelected(e.target.value)}
                                     placeholder={t('RecordsBeta.filterByCustomerName')}/>
                    </div>
                </div>
                <div className="pt-3 pl-1 pr-1" hidden={isOpenSelected}>
                    <div className='font-text-on-btn set-height-42'>
                        <KirokuSectionDropdownShowMoreMobile
                            tagType={ 'Team' }
                            onChange={ (teamsSelected) => {
                                this.props.handleSelectCustomerInTeam(teamsSelected);
                            } }
                            options={ getGroupedOptions('TeamId', teams) }
                            value={ teamsSelected }
                            placeholder={ t('RecordsBeta.refineByTeam') }/>

                    </div>
                    <div className="pt-2 font-text-on-btn">
                        <KirokuInput value={ keyword }
                                     className={'css-input-medium'}
                                     onChange={ (e) => this.props.handleChangeKeyword(e.target.value) }
                                     placeholder={ t('RecordsBeta.filterByCustomerName') }/>
                    </div>
                </div>
                <div className="pt-3 pb-5-customer-selected" style={{ marginBottom: '25px' }}>
                    <TableCustomerSelected history={this.props.history}/>
                </div>
                <div className='pt-3'/>

            </div>
        );
    }
}

export default CustomerSelected;
