import { applyMiddleware }                    from 'redux';
import { authMiddleware }                     from './Auth';
import { loadTemplateWithCustomerMiddleware } from './Record';
import { customerMiddleware }                 from './Customer';
import { surveyMiddleware }                   from './Survey';
import { teamMiddleware }                     from './Team';
import { draftMiddleware }                    from './Draft';
import { userMiddleware }                     from './User';
import { questionMiddleware }                 from './Question';
import { organizationMiddleware }             from './Organization';
import { logsMiddleware }                     from './Logs';
import { SurveyMobileMiddleware }             from './SurveyMobile';
import { AdminExport }                        from './AdminExport';
import { lineMiddleWare }                     from "./Line";
import { draftMiddleWare }                    from "./DraftSurvey";
import QueryForAnalysis                       from "./AnalysisRecordQuery";
import { dashboardMiddleware }                from './Dashboard'
import { newRecordsMiddleware }             from "./RecordsNew";
import { documentMiddleware }               from "./Document";
import { reportsMiddleware }                from './Reports';
import { DeletedList }                      from './DeletedList';
import { DeletedSurveysMiddleware }         from './DeletedSurveys';
import { DeletedRecordsMiddleware }         from './DeletedRecords';
import { DeletedCustomerMiddleware }        from "./DeletedCustomer";
import { DeletedStaffsMiddleware }          from './DeletedStaffs';
import { recordsBetaMiddleWare }            from './RecordsBeta';
import { batchActionRecordBeta }            from './batchActionRecordBeta';
import { SetDefaultAnswerRecordMiddleware } from './SetDefaultAnswerRecordMiddleware';
import { RecordsForAnalysis }               from './RecordsForAnalysis';
import {locks}                              from './Locks'
import MixPanelActionTracking               from "./MixPanelActionTracking";

export const api = applyMiddleware(
    MixPanelActionTracking,
    logsMiddleware,
    customerMiddleware,
    authMiddleware,
    loadTemplateWithCustomerMiddleware,
    surveyMiddleware,
    teamMiddleware,
    draftMiddleware,
    userMiddleware,
    questionMiddleware,
    organizationMiddleware,
    SurveyMobileMiddleware,
    QueryForAnalysis,
    RecordsForAnalysis,
    SurveyMobileMiddleware,
    AdminExport,
    lineMiddleWare,
    dashboardMiddleware,
    draftMiddleWare,
    documentMiddleware,
    newRecordsMiddleware,
    reportsMiddleware,
    DeletedList, DeletedSurveysMiddleware,
    DeletedRecordsMiddleware, DeletedCustomerMiddleware, DeletedStaffsMiddleware,
    recordsBetaMiddleWare,
    batchActionRecordBeta,
    SetDefaultAnswerRecordMiddleware,
    locks
);
