class CustomerService {
    constructor(axios) {
        this.axios = axios;
    }

    load(customerId) {
        return this.axios.get(`/customer/${customerId}`).then(res => res.data);
    }

    customers() {
        return this.axios.get(`/filter-customers`,
            {
                params: {
                    status: 'active',
                    page: '1'
                }
            }
        ).then(res => res.data);
    }

    customersAll() {
        return this.axios.get('/customers').then(res => res.data)
    }

    create(customer) {
        return this.axios.post(`/customer`, customer).then(res => res.data)
    }

    edit(customerId, customer) {
        return this.axios.put(`/customer/${customerId}`, customer).then(res => res.data);
    }

    deactiveCustomer(id) {
        return this.axios.put('/customers', {customers: id});
    }

    loadCustomerForSurvey() {
        return this.axios.get('/customer-for-survey').then(res => res.data)
    }

    filter(params) {
        return this.axios.get(`/customers`, {params: params}).then(res => res.data)
    }

    filterWithConditionPageDetail(params) {
        return this.axios.get(`/documents-by-customer`, {params: {...params}}).then(res => res.data)
    }

    filterWithCondition(params, tags) {
        let tagCondition = tags.tags ? tags.tags.map(tag => {
            return {tagId: tag.tagId, tagType: tag.tagType}
        }) : [];
        return this.axios.post(`/filter-customers`, {tags: tagCondition}, {
            params: {
                page: params.page || 1,
                name: params.name,
                status: params.status || 'active',
            }
        }).then(res => res.data)
    }

    pinCustomer(customerId) {
        return this.axios.post(`/pin-customer`, {
            customerId: customerId
        }).then(res => res.data)
    }

    getCustomerPinDashboard() {
        return this.axios.get(`customer-pin-of-user`).then(res => res.data)
    }

    assignSurveyToCustomer(customerId, surveys) {
        return this.axios.post(`/assignment-customer-surveys`, {
            customerId: customerId,
            surveys: surveys
        }).then(res => res.data)
    }

    getCustomersBeta() {
        return this.axios.get('/customers-beta').then(res => res.data)
    }
}

export default CustomerService;
