import React, {Component}  from 'react';
import KirokuModal         from "../../../ui/KirokuModal";
import KirokuModalHeader   from "../../../ui/KirokuModalHeader";
import KirokuInputSearch   from "../../../ui/KirokuInputSearch";
import KirokuModalBody     from "../../../ui/KirokuModalBody";
import KirokuCardList      from "../../../ui/KirokuCardList";
import KirokuCard          from "../../../ui/KirokuCard";
import KirokuCheckBox      from "../../../ui/KirokuCheckBox";
import KirokuModalFooter   from "../../../ui/KirokuModalFooter";
import KirokuButton        from "../../../ui/KirokuButton";
import connect             from "react-redux/es/connect/connect";
import {resetSelectMember} from "../../../actions/User";
import {withNamespaces}    from "react-i18next";
import {
    checkAllListMemberRemove,
    checkIsChecked,
    checkMemberRemoveTeam,
    getListMemberTHisRemove,
    removeCheckAllListMemberRemove,
    searchMemberRemove,
    turnOffModalDelete
}                          from "../../../actions/EditorTeam";

@withNamespaces()
@connect(state => {
    return {
        users: state.KirokuAIUser.users,
        statusModalDelete: state.EditorTeam.showModalDelete,
        statusDell: state.EditorTeam.statusDell,
        listMemberThisRemove: state.EditorTeam.listMemberThisRemove,
    }
}, dispatch => {
    return {
        turnOffModalDelete: () => {
            dispatch(turnOffModalDelete())
        },
        resetSelectMember: () => {
            dispatch(resetSelectMember())
        },
        checkAllListMemberRemove: () => {
            dispatch(checkAllListMemberRemove())
        },
        checkMemberRemoveTeam: (index) => {
            dispatch(checkMemberRemoveTeam(index))
        },
        removeCheckAllListMemberRemove: () => {
            dispatch(removeCheckAllListMemberRemove())
        },
        searchMemberRemove: (name) => {
            dispatch(searchMemberRemove(name))
        },
        checkIsChecked: () => {
            dispatch(checkIsChecked())
        },
        getListMemberTHisRemove: () => {
            dispatch(getListMemberTHisRemove())
        },
        memberRemove: () => {
            dispatch({
                type: 'MEMBER_CHOICE_REMOVE'
            })
        }
    }
})
class ModalDeleteMember extends Component {

    state = {
        keyword: ''
    };

    componentWillMount() {
        this.props.getListMemberTHisRemove()
    }

    handleCheckAll() {
        this.props.checkAllListMemberRemove();
    }

    handleRemoveCheckAll() {
        this.props.removeCheckAllListMemberRemove()
    }

    handleCheckMembers(index) {
        this.props.checkMemberRemoveTeam(index)
    }

    handleCancel() {
        this.props.turnOffModalDelete();
        this.setState({
            keyword: ''
        })
    }

    onSearch(e) {
        this.props.searchMemberRemove(e.target.value);
        this.setState({
            keyword: e.target.value
        })
    }

    componentWillUnmount() {
        this.props.resetSelectMember();

    }

    handleApply() {
        this.props.turnOffModalDelete();
        this.props.memberRemove();
        this.setState({
            keyword: ''
        })
    }

    render() {
        const {t, statusModalDelete, listMemberThisRemove, statusDell} = this.props;
        const {keyword}                                                = this.state;
        return (
            <KirokuModal show={statusModalDelete}>
                <KirokuModalHeader>
                    <h6 className="mb-3">{t('Team.DeleteMember')}</h6>
                    <KirokuInputSearch value={keyword} onChange={this.onSearch.bind(this)}
                                       placeholder={t('Team.Customer name or team name')}/>
                </KirokuModalHeader>
                <KirokuModalBody>
                    <div id="body-modal-as">
                        <KirokuCardList
                            headerLeft={t('common.Members')}
                            headerRight={listMemberThisRemove[0] ?
                                <div className='text-primary' style={{cursor: 'pointer'}}>
                                        <span hidden={statusDell}
                                              onClick={this.handleCheckAll.bind(this)}
                                        >{t('common.SelectAll')}</span>

                                    <span hidden={!statusDell}
                                          onClick={this.handleRemoveCheckAll.bind(this)}
                                    >{t('common.UnSelect')}</span>
                                </div> : <div/>
                            }
                        >
                            <section className="scroll-data-survey">
                                {listMemberThisRemove.map((member, index) => (
                                    <KirokuCard key={index}
                                                action={<p>{t(`role.${member.role}`)}</p>}>
                                        <KirokuCheckBox
                                            onChange={this.handleCheckMembers.bind(this, index)}
                                            key={index}
                                            checked={member.isChecked}
                                            content={member.name}/>
                                    </KirokuCard>
                                ))}
                            </section>

                        </KirokuCardList>
                    </div>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton onClick={this.handleCancel.bind(this)} color={'white'}>
                        {t('common.Cancel')}
                    </KirokuButton>
                    <KirokuButton onClick={this.handleApply.bind(this)} color={'danger'}>
                        {t('common.Delete')}
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>
        );
    }
}

export default ModalDeleteMember;