import {Component} from "react";
import React from "react";

export default class KirokuActionSurvey extends Component {

    render() {
        return (
            <div className='kiroku-action-survey'>
                <i className={'p-1 fas fa-' + this.props.icon}/>
            </div>
        )
    }
}
