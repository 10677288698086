import React, {Component}                                     from 'react';
import {NavLink}                                              from 'react-router-dom';
import {withNamespaces}                                       from 'react-i18next';
import {connect}                                              from 'react-redux';
import Tooltip                                                from 'react-tooltip-lite';
import {filterSurveyWithCondition, setCurrentPage}            from '../actions/Survey';
import {resetConditionSurvey}                                 from '../actions/Behavior';
import {loadRecords, resetCondition, setNumberPageRecords}    from '../actions/Record';
import {loadUserPagination}                                   from "../actions/User";
import {loadTeamsForPage, resetConditionsTeams}               from '../actions/Team';
import {filterCustomerWithCondition, resetConditionsCustomer} from '../actions/Customer';
import {resetStateCreateSurveyMobile}                         from "../actions/SurveyMobile";
import {filePathService, authService}                                      from "../services";
import {
    RESET_CONDITIONS,
}                                                             from "../actions/Reports";

@connect(state => {
    return {
        profile: state.KirokuAIUser.profile,
        defaultTags: state.KirokuAICustomer.defaultTags,
        customerTagged: state.KirokuAIRecord.customerTagged,
    }
}, dispatch => {
    return {
        setCurrentPage: (numberPage) => {
            dispatch(setCurrentPage(numberPage))
        },
        filterSurveyWithCondition: (params) => {
            dispatch(filterSurveyWithCondition(params))
        },
        resetConditionSurvey: () => {
            dispatch(resetConditionSurvey())
        },
        loadRecords: (params, tags) => {
            dispatch(loadRecords(params, tags));
        },
        resetCondition: (tags) => {
            dispatch(resetCondition(tags))
        },
        setNumberPageRecords: (numberPage) => {
            dispatch(setNumberPageRecords(numberPage))
        },
        resetConditionStaff: () => {
            dispatch({
                type: 'RESET_CONDITION_STAFF',
            })
        },
        loadUserPagination: (defaultPage, tags) => {
            dispatch(loadUserPagination(defaultPage, tags))
        },
        loadTeamForPage: (params) => {
            dispatch(loadTeamsForPage(params))
        },
        filterCustomerWithCondition: (params, tags) => {
            dispatch(filterCustomerWithCondition(params, tags))
        },
        resetConditionsTeams: () => {
            dispatch(resetConditionsTeams())
        },
        resetConditionsCustomer: () => {
            dispatch(resetConditionsCustomer())
        },
        resetStateCreateSurveyMobile: () => {
            dispatch(resetStateCreateSurveyMobile())
        },
        resetStaffTag: () => {
            dispatch({
                type: 'RESET_STAFF_TYPE'
            })
        },
        setInitConditionsPageSimpleRecord:  () => {
            dispatch({
                type: 'SET_INIT_CONDITIONS_PAGE_RECORDS'
            })
        },
        loadRecordsByConditions: (params, conditions) => {
            dispatch({
                type: 'LOAD_RECORD_BY_CONDITION_BASIC',
                params, conditions
            })
        },
        resetConditionsReport: () => {
            dispatch({
                type : RESET_CONDITIONS
            })
        },
        removeDateRange: () => {
            dispatch({
                type: "REMOVE_DATE",
            })
        },
        handleChangeScreen : (screen) => {
            dispatch({
                type : 'CHANGE_SCREEN',
                screen
            })
        },
        handleChangeInitRecordBeta: () => {
            dispatch({
                type : 'SET_INIT_CONDITION_RECORD_BETA',
            })
        }
    }
})
@withNamespaces()
class MainMenu extends Component {

    state = {
        width: window.innerWidth,
    };

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({width: window.innerWidth});
    };

    onClickNavLink() {
        this.props.onChangeNavLink();
    }

    setInitPageRecordBeta() {
        this.props.handleChangeInitRecordBeta();
    }

    setPageSurvey() {
        this.props.setCurrentPage(1);
        this.props.filterSurveyWithCondition({page: 1});
        this.props.resetConditionSurvey();
        this.props.resetStateCreateSurveyMobile();
    }

    setPageRecords() {
        this.props.setNumberPageRecords(1);
        this.props.loadRecords({page: 1}, this.props.customerTagged);
        this.props.resetCondition(this.props.customerTagged);
    }

    setPageStaff() {
        this.props.resetConditionStaff();
        this.props.resetStaffTag();
        this.props.loadUserPagination({}, {tags: []});
    }

    setPageTeams() {
        this.props.loadTeamForPage({page: 1});
        this.props.resetConditionsTeams();
    }

    setPageCustomer() {
        this.props.filterCustomerWithCondition({},
            {
                tags: this.props.profile.role === "admin" ? [] : [{
                    ...this.props.profile,
                    tagId: this.props.profile.id,
                    tagType: 'users',
                    key: `user_id${this.props.profile.id}`,
                    label: this.props.profile.name,
                    value: this.props.profile.name,

                }]
            });
        this.props.resetConditionsCustomer()
    }

    loadRecordsSimpleDetail() {
        this.props.setInitConditionsPageSimpleRecord();
        this.props.loadRecordsByConditions({currentPage: 1, pageSize: 10}, {
            status: 'recorded',
            serviceAt: {
                startDate: null,
                endDate: null
            },
            customerIds: [],
            templateIds: [],
            orders: []
        });
    }

    setPageReport() {
        this.props.removeDateRange();
        this.props.resetConditionsReport();
    }

    setDefaultValueDeletedList() {
        this.props.handleChangeScreen('surveys');
    }

    render() {
        let {t}     = this.props;
        let {width}      = this.state;
        const isMobile = width <= 768;
        return (
            <nav className="slider-navigation">
                <ul className="menu">
                    <li className="menu-category">{t('Layout.Menu')}</li>
                    <li onClick={this.onClickNavLink.bind(this)}>
                        <NavLink exact to={'/'} className="menu-item">
                            <Tooltip content={t('Layout.Home')} className="tooltip-target"
                                     direction='right' useHover={!this.props.shouldUseTooltip}>
                                <div className="menu-link" >
                                    <i className="fa fa-home" style={{fontSize: "1.3em", marginLeft: "6px"}}/>
                                    <span className="title-menu">{t('Layout.Home')}</span>
                                </div>
                            </Tooltip>
                        </NavLink>
                    </li>
                    <li onClick={this.onClickNavLink.bind(this)}>
                        <NavLink to={'/surveys'} className="menu-item" onClick={this.setPageSurvey.bind(this)}>
                            <Tooltip content={t('Layout.SurveyTemplates')} className="tooltip-target"
                                     direction='right' useHover={!this.props.shouldUseTooltip}>
                                <div className="menu-link" >
                                    <i className="fa fa-clipboard"/>
                                    <span className="title-menu">{t('Layout.SurveyTemplates')}</span>
                                </div>
                            </Tooltip>
                        </NavLink>
                    </li>
                    <li onClick={this.onClickNavLink.bind(this)}>
                        <NavLink to={'/records'} className="menu-item" onClick={this.setPageRecords.bind(this)}>
                            <Tooltip content={t('Layout.Records')} className="tooltip-target"
                                     direction='right' useHover={!this.props.shouldUseTooltip}>
                                <div className="menu-link">
                                    <i className="fas fa-file-signature" style={{fontSize: "1.06em", marginLeft: "7px"}}/>
                                    <span className="title-menu">{t('Layout.Records')}</span>
                                </div>
                            </Tooltip>
                        </NavLink>
                    </li>
                    <li onClick={this.onClickNavLink.bind(this)}>
                        <NavLink to={'/teams'} className="menu-item" onClick={this.setPageTeams.bind(this)}>
                            <Tooltip content={t('Layout.Team')} className="tooltip-target"
                                     direction='right' useHover={!this.props.shouldUseTooltip}>
                                <div className="menu-link" >
                                    <i className="fa fa-users"/>
                                    <span className="title-menu">{t('Layout.Team')}</span>
                                </div>
                            </Tooltip>
                        </NavLink>
                    </li>
                    <li onClick={this.onClickNavLink.bind(this)}>
                        <NavLink to={'/customers'} className="menu-item" onClick={this.setPageCustomer.bind(this)}>
                            <Tooltip content={t('Layout.Customers')} className="tooltip-target"
                                     direction='right' useHover={!this.props.shouldUseTooltip}>
                                <div className="menu-link" >
                                    <i className="fa fa-user" style={{fontSize: "1.2em"}}/>
                                    <span className="title-menu">{t('Layout.Customers')}</span>
                                </div>
                            </Tooltip>
                        </NavLink>
                    </li>
                    <li onClick={this.onClickNavLink.bind(this)}>
                        <NavLink onClick={this.setPageStaff.bind(this)} to={'/staffs'} className="menu-item">
                            <Tooltip content={t('Layout.Staffs')} className="tooltip-target"
                                     direction='right' useHover={!this.props.shouldUseTooltip}>
                                <div className="menu-link">
                                    <i className="fa fa-user-circle" style={{fontSize: "1.1em"}}/>
                                    <span className="title-menu">{t('Layout.Staffs')}</span>
                                </div>
                            </Tooltip>
                        </NavLink>
                    </li>
                    <li onClick={this.onClickNavLink.bind(this)}>
                        <NavLink to={'/organization'} className="menu-item">
                            <Tooltip content={t('Layout.Organization')} className="tooltip-target"
                                     direction='right' useHover={!this.props.shouldUseTooltip}>
                                <div className="menu-link">
                                    <i className="fa fa-briefcase"/>
                                    <span className="title-menu">{t('Layout.Organization')}</span>
                                </div>
                            </Tooltip>
                        </NavLink>
                    </li>
                    <li id='web' hidden={authService.getRole() === 'staff'} onClick={this.onClickNavLink.bind(this)}>
                        <NavLink to={'/exports'} className="menu-item">
                            <Tooltip content={t('Layout.AdminExport')} className="tooltip-target"
                                     direction='right' useHover={!this.props.shouldUseTooltip}>
                                <div className="menu-link">
                                    <i className="fa fa-download"/>
                                    <span className="title-menu">{t('Layout.AdminExport')}</span>
                                </div>
                            </Tooltip>
                        </NavLink>
                    </li>
                    <li onClick={this.onClickNavLink.bind(this)}>
                        <NavLink to={'/analysis'} className="menu-item">
                            <Tooltip content={t('Layout.Analysis')} className="tooltip-target"
                                     direction='right' useHover={!this.props.shouldUseTooltip}>
                                <div className="menu-link">
                                    <i className="fa fa-line-chart"/>
                                    <span className="title-menu">{t('Layout.Analysis')}</span>
                                </div>
                            </Tooltip>
                        </NavLink>
                    </li>
                    <li onClick={this.onClickNavLink.bind(this)}>
                        <NavLink to={'/logs'} className="menu-item">
                            <Tooltip content={t('Layout.Logs')} className="tooltip-target"
                                     direction='right' useHover={!this.props.shouldUseTooltip}>
                                <div className="menu-link">
                                    <i className="fa fa-file"/>
                                    <span className="title-menu">{t('Layout.Logs')}</span>
                                </div>
                            </Tooltip>
                        </NavLink>
                    </li>
                    <li className="menu-category">{t('Layout.MenuBeta')}</li>
                    {/*<li onClick={this.onClickNavLink.bind(this)}>*/}
                    {/*    <NavLink to={'/record-list'} className="menu-item" onClick={this.loadRecordsSimpleDetail.bind(this)}>*/}
                    {/*        <Tooltip content={t('Layout.RecordSimpleDetail')} className="tooltip-target"*/}
                    {/*                 direction='right' useHover={!this.props.shouldUseTooltip}>*/}
                    {/*            <div className="menu-link">*/}
                    {/*                <i className="fas fa-file-signature" style={{fontSize: "1.06em", marginLeft: "7px"}}/>*/}
                    {/*                <span className="title-menu">{t('Layout.RecordSimpleDetail')}</span>*/}
                    {/*            </div>*/}
                    {/*        </Tooltip>*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}
                    <li onClick={this.onClickNavLink.bind(this)}>
                        <NavLink to={'/records-beta'} className="menu-item" onClick={this.setInitPageRecordBeta.bind(this)}>
                            <Tooltip content={t('Layout.RecordSimpleDetail')} className="tooltip-target"
                                     direction='right' useHover={!this.props.shouldUseTooltip}>
                                <div className="menu-link">
                                    <i className="fas fa-file-signature" style={{fontSize: "1.06em", marginLeft: "7px"}}/>
                                    <span className="title-menu">{t('Layout.RecordSimpleDetail')}</span>
                                </div>
                            </Tooltip>
                        </NavLink>
                    </li>
                    {isMobile ? (<div/>) : (
                        <li onClick={this.onClickNavLink.bind(this)}>
                            <NavLink to={'/reports'} className="menu-item" onClick={this.setPageReport.bind(this)}>
                                <Tooltip content={t('Layout.ReportsTab')} className="tooltip-target"
                                         direction='right' useHover={!this.props.shouldUseTooltip}>
                                    <div className="menu-link">
                                        <i className="far fa-chart-bar"/>
                                        <span className="title-menu">{t('Layout.ReportsTab')}</span>
                                    </div>
                                </Tooltip>
                            </NavLink>
                        </li>
                    )}
                    <li onClick={this.onClickNavLink.bind(this)}>
                        <NavLink to='/surfly' className="menu-item" onClick={() => {
                            this.onClickNavLink.bind(this);
                            window.location = '/surfly'
                        }}>
                            <Tooltip content={t('common.supportPage')} className="tooltip-target"
                                     direction='right' useHover={!this.props.shouldUseTooltip}>
                                <div className="menu-link">
                                    <div
                                        style={{width: 17, height: 17, backgroundImage: `url(${filePathService.resolveFilePath('support.svg')})`}}/>
                                    <span className="title-menu">{t('common.supportPage')}</span>
                                </div>
                            </Tooltip>
                        </NavLink>
                    </li>
                    <li onClick={this.onClickNavLink.bind(this)}>
                        <NavLink to='/deleted' className="menu-item" onClick={this.setDefaultValueDeletedList.bind(this)}>
                            <Tooltip content={t('common.Deleted')} className="tooltip-target"
                                     direction='right' useHover={!this.props.shouldUseTooltip}>
                                <div className="menu-link">
                                    <i className="fa fa-trash fa-lg"/>
                                    <span className="title-menu">{t('common.Deleted')}</span>
                                </div>
                            </Tooltip>
                        </NavLink>
                    </li>
                </ul>
            </nav>
        )
    }
}

export default MainMenu;
