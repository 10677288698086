import React, {Component}   from 'react';
import {
    KirokuRadio,
    KirokuInput,
}                           from "../../../../../ui";
import KirokuActionSurvey   from "../../../../../ui/KirokuActionSurvey";
import {connect}            from "react-redux";
import {throwErrorQuestion} from "../../../../../actions/SurveyTemplate";
import {withNamespaces}     from "react-i18next";
import { filePathService }  from '../../../../../services';
import _                    from 'lodash';
@withNamespaces()
@connect((state) => {
    return {
        errorQuestion: state.KirokuAISurveyTemplate.errorQuestion,
    };
}, (dispatch) => ({
    throwError: (name, value) => {
        dispatch(throwErrorQuestion(name, value))
    }
}))
class CheckBoxTypeQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listTypeQuestion: this.getTypeQuestion(props['isCreateSurvey']),
            choices: [{answer: ''}, {answer: ''}],
            toggle: false,
            type: 'InputText',
        };
    }


    getTypeQuestion = (isCreateSurvey) => {
        let listTypeQuestions = [
            {
                label: <div><span><img src={filePathService.resolveFilePath('input-text.svg')} alt=""/></span><span
                    className={'text-muted pl-2'}>{this.props.t('createSurvey.InputText')}</span></div>,
                value: "InputText",
            },
            {
                label: <div><span><img src={filePathService.resolveFilePath('no.svg')} alt=""/></span><span
                    className={'text-muted pl-2'}>{this.props.t('createSurvey.YesNo')}</span></div>,
                value: "YesNo",

            },
            {
                label: <div><span><img src={filePathService.resolveFilePath('date-time.svg')} alt=""/></span><span
                    className={'text-muted pl-2'}>{this.props.t('createSurvey.DateTime')}</span></div>,
                value: "Date",
            }, {
                label: <div><span><img src={filePathService.resolveFilePath('multi-select.svg')} alt=""/></span><span
                    className={'text-muted pl-2'}>{this.props.t('createSurvey.Single')}</span></div>,
                value: "Single",
            },
            {
                label: <div><span><img src={filePathService.resolveFilePath('single-select.svg')} alt=""/></span><span
                    className={'text-muted pl-2'}>{this.props.t('createSurvey.Multiple')}</span></div>,
                value: "MultiChoice",
            },
            {
                label: <div><span><img src={filePathService.resolveFilePath('media2.svg')} alt=""/></span><span
                    className={'text-muted pl-2'}>{this.props.t('createSurvey.MediaFile')}</span></div>,
                value: "Media"
            },
            {
                label: <div><span><img src={filePathService.resolveFilePath('simple-mess.svg')} alt=""/></span><span
                    className={'text-muted pl-2'}>{this.props.t('createSurvey.Simple')}</span></div>,
                value: "Message",
            },
            {
                label: <div><span><img src={filePathService.resolveFilePath('numbers.svg')} alt="" style={{ width: 25}}/></span><span
                    className={'text-muted pl-2'}>{this.props.t('createSurvey.NumberQuestion')}</span></div>,
                value: "Number",
            },
            {
                label: <div><span><img src={filePathService.resolveFilePath('date.svg')} alt=""/></span><span
                     className={'text-muted pl-2'}>{this.props.t('createSurvey.Date')}</span></div>,
                value: 'OnlyDate'
            },
            {
                label: <div><span><img src={filePathService.resolveFilePath('clock.svg')} alt=""/></span><span
                     className={'text-muted pl-2'}>{this.props.t('createSurvey.Time')}</span></div>,
                value: 'Time'
            }
        ];


        if (isCreateSurvey) {
            _.remove(listTypeQuestions, (i) => {
                return i.value === 'YesNo';
            });
        }
        return listTypeQuestions
    };

    componentWillMount() {
        let type = this.props.type;
        this.setState({
            toggle: ((type === 'Single' || type === 'MultiChoice')),
        })
    }

    onChangeQuestion(e) {
        this.setState({
            toggle: (e.target.value === 'Single' || e.target.value === 'MultiChoice'),
            type: e.target.value
        });
        this.props.handleCheckType(e.target.value, this.state.choices)
    };

    removeAnswer(e, index) {
        if (this.state.choices.length > 2) {
            let newChoices = this.state.choices;
            newChoices.splice(index, 1);
            this.props.handleCheckType(this.state.type, newChoices);
            return this.setState({
                choices: newChoices
            });
        }
    }

    addAnswer() {
        let newChoices = this.state.choices;
        newChoices.push({answer: ''});
        this.setState({
            choices: newChoices
        }, this.getLastChoiceElement);
        this.props.handleCheckType(this.state.type, newChoices)
    }

    answerOnchange(e, index) {
        let newChoices = this.state.choices;
        newChoices[index].answer = e.target.value;
        this.setState({
            choices: newChoices
        });
        this.props.handleCheckType(this.state.type, newChoices)
    }

    getLastChoiceElement() {
        const {choices} = this.state;
        const element = document.querySelectorAll(`#CheckBoxTypeQuestion${this.props.number} > .sumary-content > .d-flex > input[name="answer"]`)[choices.length-1];
        element.focus();
    }

    componentDidMount() {
        this.setState({
            type: this.props.type || '',
            choices: this.props.choices || [{answer: ''}, {answer: ''}],
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.choices) {
            this.setState({
                type: nextProps.type,
                choices: nextProps.choices
            })
        }

        if(nextProps.type !== this.props.type) {
            this.setState({
                type: nextProps.type,
                toggle: ((nextProps.type === 'Single' || nextProps.type === 'MultiChoice')),
            })
        }
    }

    render() {
        let {t} = this.props;
        const {choices} = this.state;
        return (
            <div>
                <p className='pt-3'>{t('createSurvey.ChooseType')}</p>
                <div className='group-select-type-question-on-survey'>
                    <div className='d-flex justify-content-between'>
                        {this.state.listTypeQuestion.map((type, index) =>
                            <KirokuRadio value={type.value}
                                         onChange={this.onChangeQuestion.bind(this)}
                                         checked={this.props.type === type.value}
                                         name={'radio' + this.props.number}
                                         id={`${this.props.number} ${index}`}
                                         key={index}
                            >
                                {type.label}
                            </KirokuRadio>
                        )}
                    </div>
                </div>
                {
                    this.state.toggle ? (
                        <div id={`CheckBoxTypeQuestion${this.props.number}`}>
                            {
                                choices ? choices.map((choice, index) =>
                                    <div key={index} className='sumary-content'>
                                        <p className='pt-3'>{t('createSurvey.Answer')}</p>
                                        <div className='d-flex'>
                                            <KirokuInput value={choice.answer}
                                                         name={'answer'}
                                                         onChange={(e) => this.answerOnchange(e, index)}
                                                         placeholder={t('createSurvey.EnterAnAnswerChoice')}/>
                                            <div className='action-fix pl-4'>
                                                <div onClick={(e) => this.removeAnswer(e, index)}>
                                                    <KirokuActionSurvey icon={'minus'}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div hidden={choice.answer}
                                             style={{
                                                 color: 'red',
                                                 fontSize: 12
                                             }}
                                             className='mt-2'
                                        >
                                            <p>{t('createSurvey.AnswerMustNotNull')}</p>
                                        </div>
                                    </div>
                                ) : ''
                            }
                            <div className='pt-3 action-fix-plus'>
                                <div onClick={this.addAnswer.bind(this)} className='d-flex'>
                                    <div style={{cursor: "pointer", display:'flex'}}>
                                        <KirokuActionSurvey icon={'plus'}/>
                                        <span className='pl-2'>{t('createSurvey.AddOtherAnswer')}</span>
                                    </div>
                                </div>
                            </div>
                            <div hidden={this.props.errorQuestion.answer}>
                                <p>{t('createSurvey.AnswerMustNotNull')}</p>
                            </div>
                        </div>
                    ) : <div/>
                }

            </div>
        );
    }
}

export default CheckBoxTypeQuestion;
