import React, { Component }    from "react";

export default class KirokuCardList extends Component {
    render() {
        const HeaderLeft  = this.props.headerLeft;
        const HeaderRight = this.props.headerRight;

        return (
            <div hidden={this.props.hidden} className='card'>
                <div className='media-list media-list-hover media-list-divided scrollable ps-container ps-theme-default'>
                    <div className='customize-media media media-single'>
                        <div className='media-body'>
                            {HeaderLeft}
                        </div>
                        <div className="media-right">
                            {HeaderRight}
                        </div>
                    </div>
                    {this.props.children}
                </div>
            </div>
        )
    }
}
