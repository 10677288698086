import {KirokuModalBody,
    KirokuModalFooter,
    KirokuModal,
    KirokuButton}                                from '../../../../ui';
import React, { Component }                      from 'react';
import { connect }                               from 'react-redux';
import { withNamespaces }                        from 'react-i18next';
import { turnOffNotificationValidatedMediaFile } from '../../../../actions/TakeSurvey';


@withNamespaces()
@connect(state => {
    return {
        flagValidatedUpload: state.TakeSurveyWeb.flagValidatedUpload
    }
}, dispatch => {
    return {
        turnOffNotificationValidatedMediaFile: () => {
            dispatch(turnOffNotificationValidatedMediaFile())
        }
    }
})
class ModalNotificationValidateUpload extends Component {

    closeModal() {
        this.props.turnOffNotificationValidatedMediaFile()
    }

    render() {
        const {t, flagValidatedUpload} = this.props;
        return (
            <KirokuModal show={flagValidatedUpload} onCloseModal={this.closeModal.bind(this)}>
                <KirokuModalBody>
                    <div style={{textAlign: 'center', paddingTop: 2}}>

                        {t('createRecord.NotificationUpload')}
                    </div>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                        {t('createRecord.Yes')}
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>
        );
    }

}

export default ModalNotificationValidateUpload;