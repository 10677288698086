import React, { Component }        from 'react'
import { checkUseHeadingQuestion } from "../../Surveys/SurveyEditor/SurveyService";

class CustomerRecord extends Component {

    static renderQuestion(question) {
        switch (question.type) {
            case "Media":
                return (
                    <div className="pt-2">
                        <img className="report-tab" src={question.answers} alt=""/>
                    </div>
                );
            case "YesNo":
            case "Date":
                return (<div className="answer-content">{question.answers}</div>);
            default:
                return (<div className="answer-content">{question.answers}</div>)
        }
    }

    render() {
        const {reports} = this.props;
        return (
            <div className="report-tab container">
                <div className="wrap-content">
                    <div className="A4">
                        <div className="content">
                            <div className="wrap-bg">
                                {reports.customers.map((customer, index) => {
                                    return(
                                        <div className="wrap-bg" key={index}>
                                            <div className="pb-3">
                                                <div>
                                                    <label className="report-tab">
                                                        {reports.translate_customerName}
                                                    </label>
                                                    <div className="border-title-template">
                                                        {customer.customerName}
                                                    </div>
                                                </div>
                                                {customer.groupRecordByTemplate.map((template, index) => {
                                                    return(
                                                        <div key={index}>
                                                            <div className="pt-3">
                                                                <label className="report-tab">
                                                                    {reports.translate_title}
                                                                </label>
                                                                <div className="border-customer-name">
                                                                    {template.templateTitle}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                {template.records.map((record, index) => {
                                                                    return (
                                                                        <div
                                                                            className={`pt-3 striped-item border-bottom-inline`}
                                                                            key={index}>
                                                                            <div
                                                                                className="row fix-margin-row border-bottom-dashed ">
                                                                                <div
                                                                                    className="col-3 fix-padding-col pb-10px">
                                                                                    <label className="report-tab">
                                                                                        {reports.translate_serviced}
                                                                                    </label>
                                                                                    <div>{record.serviced_date_time}</div>
                                                                                </div>
                                                                                <div className={'col-9 pb-10px'}>
                                                                                    <label className="report-tab">
                                                                                        {reports.translate_created_by}
                                                                                    </label>
                                                                                    <div>
                                                                                        {record.record_by}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="answers-list">
                                                                                {record.flowedQuestions.map((question, index) => {
                                                                                    return (
                                                                                        <div
                                                                                            className="flex-column justify-content-between pt-2 answer-item"
                                                                                            key={index}>
                                                                                            <div
                                                                                                className={index === record.flowedQuestions.length - 1 ?
                                                                                                    'row fix-margin-row pb-2' :
                                                                                                    'border-bottom-dashed row fix-margin-row'}>
                                                                                                <label
                                                                                                    className="report-tab col-3 pb-10px  fix-padding-col">
                                                                                                    {checkUseHeadingQuestion(question)}
                                                                                                </label>
                                                                                                <div className="col-9">
                                                                                                    {CustomerRecord.renderQuestion(question)}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomerRecord
