import {
    LOADING_DRAFT_SURVEY,
    SAVE_DRAFT_EDIT_SURVEY,
    DISCARD_DRAFT_SURVEY,
    LOADING_SURVEY_FROM_DRAFT
}                                                                 from "../actions/DraftSurvey";
import {surveyTemplateService, draftSurveyService, surveyFactory} from "../services";

export const draftMiddleWare = (store) => next => action => {
    switch (action.type) {
        case SAVE_DRAFT_EDIT_SURVEY :
            const draft = surveyFactory.makeEdit(action.draft);
            return surveyTemplateService.saveAsDraft(draft).then(res => {
                next({
                    ...action
                })
            });
        case LOADING_DRAFT_SURVEY :
            return draftSurveyService.loadDraft(action.typeDraft, action.id).then(draftSurvey => {
                next({
                    ...action,
                    dataDraft: draftSurvey
                })
            });
        case DISCARD_DRAFT_SURVEY :
            return draftSurveyService.discard(action.typeDraft, action.id).then(res => {
                next({
                    ...action,
                    dataDraft: null
                })
            });
        case LOADING_SURVEY_FROM_DRAFT :
            const surveyFromDraft = store.getState().KirokuDraftSurvey.draftSurvey;
            return next({
                ...action,
                survey : surveyFromDraft.data
            });
        default :
            return next(action);
    }
};
