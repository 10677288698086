import React, { Component }   from 'react';
import arrayMove              from 'array-move';
import { connect }            from 'react-redux';
import { withNamespaces }     from 'react-i18next';
import { KirokuButton }       from '../../../../ui';
import {
    nextQuestionFromSurvey,
    clickEditReviewToEditQuestion,
}                             from '../../../../actions/SurveyMobile';
import ReviewSurvey           from '../../Mobile/ReviewSurveyMobile/ReviewSurvey';
import { handleDragQuestion } from '../../../../actions/Survey';
import { changeUnRequited }   from '../../../../actions/SurveyTemplate';

@withNamespaces()
@connect(state => {
    return {
        survey: state.KirokuSurveyTemplateMobile
    }
}, dispatch => {
    return {
        back: (typeComponent) => {
            dispatch(nextQuestionFromSurvey(typeComponent))
        },
        backToEdit: (typeComponent, index) => {
            dispatch(clickEditReviewToEditQuestion(typeComponent, index))
        },
        changeUnrequitedQuestion: (questionIndex) => changeUnRequited(dispatch)(questionIndex),
        handleDragQuestion: (questions, currentIndex, nextIndex) => handleDragQuestion(dispatch)(questions, currentIndex, nextIndex)
    }
})
class ReviewEditSurvey extends Component {

    state = {
        showBehavior: true
    };

    clickOptions = () => {
        this.setState({
            showBehavior: !this.state.showBehavior
        })
    };

    backToQuestion = () => {
        const {questions} = this.props.survey;
        return !questions.length ? this.props.back('Survey') : this.props.back('Question');
    };

    handleSubmit = () => {
        this.props.handleSubmit()
    };

    backToEditQuestion = (index) => {
        this.props.backToEdit('Question', index);
    };

    disableSubmit = () => {
        const {questions} = this.props.survey;
        return !questions.length;
    };

    onDragEnd(event) {
        const { survey } = this.props;
        const indexQuestionFor = event.source.index;
        const indexQuestionTo = event.destination.index;
        let newItems = [...survey.questions];
        this.props.handleDragQuestion(arrayMove(newItems,indexQuestionFor, indexQuestionTo), indexQuestionFor, indexQuestionTo);
    }

    render() {
        const {t} = this.props;
        const survey = this.props.survey;
        return (
            <React.Fragment>
                <ReviewSurvey survey={survey}
                              showBehavior={this.state.showBehavior}
                              backToEditQuestion={this.backToEditQuestion.bind(this)}
                              onDragEnd={this.onDragEnd.bind(this)}
                              changeUnrequitedQuestion={(questionIndex) => this.props.changeUnrequitedQuestion(questionIndex)}
                />
                <div className='action-record' style={{zIndex: 6}}>
                    <div className="row" style={{height: 42}}>
                        <div className='col-6 p-0'>
                            <KirokuButton style={{width: '100%', borderRadius: 0, height: '100%'}}
                                          color={'light'}
                                          onClick={this.backToQuestion.bind(this)}
                            >
                                {t('createSurvey.Back')}
                            </KirokuButton>
                        </div>
                        <div className='col-6 p-0'>
                            <KirokuButton
                                color={'primary'}
                                style={{width: '100%', borderRadius: 0, height: '100%'}}
                                disabled={this.disableSubmit()}
                                onClick={this.handleSubmit.bind(this)}
                            >
                                {t('createSurvey.Submit')}
                            </KirokuButton>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ReviewEditSurvey;
