import React, {Component}     from 'react';
import ModalAddLogicGeneral   from "../ModalAddLogicGeneral";
import BehaviorQuestionMobile             from "./BehaviorQuestionMobile";
import {withNamespaces}                   from "react-i18next";
import ActionQuestions                    from "./ActionsQuestions";
import {checkUseHeadingQuestion}          from "../../SurveyEditor/SurveyService";
import { connect }                        from 'react-redux';
import { setDefaultAnswerQuestionMobile } from '../../../../actions/Survey';
import {
    KirokuPopoverItem, KirokuLogicApplicated,
    KirokuCardWeb, KirokuCardTitleWeb,
    KirokuCardBodyWeb, KirokusSortableHandle,
    KirokuCheckBox
}                                         from '../../../../ui';
import ComponentUnrequited                from '../../ReviewSurvey/ComponentUnrequited';
import KirokuButtonLink                   from '../../../../ui/KirokuButtonLink';

@connect(null , dispatch => {
    return {
        setDefaultAnswerQuestion: (question) => {
            dispatch(setDefaultAnswerQuestionMobile(question))
        }
    }
})
@withNamespaces()
class MultiChoice extends Component {
    state = {
        show: true
    };

    showModalAddLogic() {
        this.setState({
            show: !this.state.show
        })
    }

    closeModal() {
        this.setState({
            show: !this.state.show
        })
    }

    setDefaultAnswer(indexAnswerDefault) {
        const { question } = this.props;
        let questionNew = {...question};
        questionNew.choices[ indexAnswerDefault ] = {
            ...questionNew.choices[ indexAnswerDefault ],
            defaultAnswer: !questionNew.choices[ indexAnswerDefault ].defaultAnswer,
        };
        this.props.setDefaultAnswerQuestion(questionNew);

    }

    handleResetDefaultAnswer() {
        const { question } = this.props;
        let questionNew    = { ...question };
        questionNew.choices = questionNew.choices.map((choice) => {
            if(choice.defaultAnswer) {
                delete choice.defaultAnswer;
            }
            return choice;
        });
        this.props.setDefaultAnswerQuestion(questionNew);
    }

    render() {
        const {t} = this.props;
        const question = this.props.question;
        const contentQuestion = checkUseHeadingQuestion(question);
        return (
            <KirokuCardWeb style={{borderRadius: 4}}>
                <KirokuCardTitleWeb style={{backgroundColor: '#e9edf1', paddingTop: 10, paddingBottom: 7, paddingRight: 12, paddingLeft: 12}}>
                    <div className={'d-flex justify-content-between'}>
                        <div className={'pr-2'}>
                            <KirokusSortableHandle>
                                <div className='number-question d-flex' >
                                    <span className="question-number pb-2 pt-4px">{t('createSurvey.QuestionOrder', {index : question.index + 1})} </span>
                                    <KirokuLogicApplicated question={question}/>
                                </div>
                            </KirokusSortableHandle>
                        </div>
                        <div className={'pt-5px pr-2'} hidden={typeof question.nextQuestion === 'number'}>
                            <ComponentUnrequited question={question}
                                                 changeUnrequitedQuestion={(questionIndex) => this.props.changeUnrequitedQuestion(questionIndex)}
                            />
                        </div>
                        <div className={'pr-2'}>
                            <KirokuButtonLink
                                className='padding-reset-button-link'
                                onClick={() => {this.handleResetDefaultAnswer()}}>
                                {t('createRecord.resetAnswer')}
                            </KirokuButtonLink>
                        </div>
                        <div>
                            <ActionQuestions isReviewSurvey>
                                <KirokuPopoverItem>
                                    <BehaviorQuestionMobile question={question}
                                                            showModalAddLogic={this.showModalAddLogic.bind(this)}
                                                            backToEditQuestion={this.props.backToEditQuestion}
                                    />
                                </KirokuPopoverItem>
                            </ActionQuestions>
                        </div>
                    </div>

                </KirokuCardTitleWeb>
                <div className='wrap-overlay-action'>
                    <KirokuCardBodyWeb>
                        <div>
                            <span className='content-name-question pb-2'>{contentQuestion}</span>
                            <p hidden={typeof question.nextQuestion !== 'number'} className='text-info'> <i className='fas fa-arrow-right text-info'>  {t('createRecord.Question')}: {question.nextQuestion+1}</i> </p>
                            <div className='answer-question'>
                                {question.choices.map((item, index) =>
                                    <KirokuCheckBox key={index}
                                                    checked={!!item.defaultAnswer}
                                                    id={`MultiChoice_${index}`}
                                                    content={item.answer}
                                                    onChange={this.setDefaultAnswer.bind(this, index)}
                                    />
                                )}
                            </div>
                        </div>
                    </KirokuCardBodyWeb>
                </div>

                <ModalAddLogicGeneral show={this.state.show} question={question}
                                      closeModal={this.closeModal.bind(this)}/>
            </KirokuCardWeb>
        );
    }
}

export default MultiChoice;
