import React, {Component}          from 'react'
import {
    KirokuDropdownClose
}                                  from "../../../ui";
import {connect}                   from "react-redux";
import {filterSurveyWithCondition} from "../../../actions/Survey";
import {resetTeam}                 from "../../../actions/Team";
import KirokuPagination            from "../../../ui/KirokuPagination";
import {withNamespaces}            from "react-i18next";
import ModalAssignUserToTeam       from "./ModalAssignUserToTeam";
import ModalDeleteUser             from "./ModalDeleteUser";
import {scrollService}             from "../../../services";
import withPermissions             from "../../HOC/WithPermissions";
import ModalSetRoleLeader          from "./ModalSetRoleLeader";
import ModalSetRoleStaff           from "./ModalSetRoleStaff";

const BulkActionStaffList = withPermissions(KirokuDropdownClose);

@withNamespaces()
@connect((state) => {
    return {
        profile: state.KirokuAIUser.profile,
    }
}, (dispatch) => {
    return {
        resetTeam: () => {
            dispatch(resetTeam())
        },
        filterSurveyWithCondition: (params) => {
            dispatch(filterSurveyWithCondition(params))
        },
    }
})

class BulkAction extends Component {

    state = {
        nameUser: '',
        currentRole: '',
        showModalLeader: false,
        showModalStaff: false,
        checked: false,
        show: false,
        showModalCustomer: false,
        teams: [],
        defaultTeams: [],
        disableButtonApply: false,
        showModalDeleteUser: false,
        page: 1,
        pageMin: 1,
        users: [],
        userSelected: {}
    };

    bulkAction = [
        {
            value: 'leader',
            component: (<div>{this.props.t('Team.SetLeader')}</div>),
            label: this.props.t('Team.SetLeader')
        },
        {
            value: 'staff',
            component: (<div>{this.props.t('Team.SetStaff')}</div>),
            label: this.props.t('Team.SetStaff')
        },
        {
            value: 'assign',
            component: (<div>{this.props.t('common.AssignToTeam')}</div>),
            label: this.props.t('common.AssignToTeam')
        },
    ];

    componentWillMount() {
        this.setState({
            currentState: this.props.currentState
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.usersChoice.length) {
            this.setState({
                users: nextProps.usersChoice
            })
        }

    }

    userChoice() {
        let userChoice = this.state.users.filter(item => item.isChecked);
        this.setState({
            users: userChoice,
            disableButtonApply: !userChoice.length
        })
    }

    closeModal() {
        this.props.resetSurvey();
        this.setState({
            show: !this.state.show,
            checked: false
        })
    }

    closeModalAssignUserToTeam() {
        this.setState({
            showModalCustomer: !this.state.showModalCustomer,
            checked: false,
        })
    }

    closeModalAssign() {
        this.setState({
            showModalCustomer: !this.state.showModalCustomer,
            checked: false,
        })
    }

    closeModalDeleteSurvey() {
        this.setState({
            showModalDeleteSurvey: false
        })
    }

    increasePage() {
        let nextPage = this.props.currentPage === this.props.totalPage ? this.props.totalPage : this.props.currentPage + 1;
        scrollService.top();
        this.props.disabledCheckbox();
        this.setState({
            page: nextPage,
        }, () => this.props.handleSelectPage(nextPage));
    }

    decreasePage() {
        let previousPage = this.props.currentPage === this.state.pageMin ? 1 : this.props.currentPage - 1;
        scrollService.top();
        this.props.disabledCheckbox();
        this.setState({
            page: previousPage,
        }, () => {
            this.props.handleSelectPage(previousPage)
        });
    }

    isSelect() {
        return !this.state.users.filter(item => item.isChecked).length
    }

    closeModalDeleteUser() {
        this.props.closeModalDel()
    }

    showModalSetRoleLeader() {
        this.setState({
            showModalLeader: !this.state.showModalLeader,
            currentRole: 'leader'
        })
    }

    showModalSetRoleStaff() {
        this.setState({
            showModalStaff: !this.state.showModalStaff,
            currentRole: 'staff'
        })
    }

    closeModalSetRoleLeader() {
        this.setState({
            showModalLeader: false
        })
    }

    closeModalSetRoleStaff() {
        this.setState({
            showModalStaff: false
        })
    }

    selectUser() {
        return this.props.usersChoice.filter(item => item.isChecked);
    }

    render() {
        let {t, className} = this.props;
        return (
            <div>
                <div className='d-flex justify-content-between'>
                    <BulkActionStaffList
                        resource-permission={'|staffList|button-bulkAction|'}
                        location={this.props.location}
                        disabled={this.isSelect()}
                        title={t('Surveys.BulkAction')}
                        style={{minWidth: 180}}
                        items={this.bulkAction}
                        onSelect={event => {
                            if (event.kirokuSelected.value === 'assign') {
                                this.userChoice();
                                this.setState({
                                    showModalCustomer: !this.state.showModalCustomer
                                });
                            } else if (event.kirokuSelected.value === 'leader') {
                                this.showModalSetRoleLeader()
                            } else {
                                this.showModalSetRoleStaff()
                            }
                        }}
                    />

                    <KirokuPagination
                        className={className ? className : ''}
                        hidden={this.props.currentPage === 0}
                        disabledPrev={this.state.pageMin === this.props.currentPage}
                        disabledNext={this.props.currentPage === this.props.totalPage}
                        increasePage={this.increasePage.bind(this)}
                        decreasePage={this.decreasePage.bind(this)}
                        currentPage={this.props.currentPage}
                        totalPage={this.props.totalPage}
                    />
                </div>

                <ModalDeleteUser
                    closeModalDeleteUser={this.closeModalDeleteUser.bind(this)}
                    show={this.props.showModalDeleteUser}
                    tags={this.props.currentTags}
                    params={this.props.params}
                    users={this.props.userSelected}
                />

                <ModalAssignUserToTeam
                    params={this.props.params}
                    users={this.state.users}
                    closeModalAssign={this.closeModalAssign.bind(this)}
                    disableButtonApply={this.state.disableButtonApply}
                    close={this.closeModalAssignUserToTeam.bind(this)}
                    show={this.state.showModalCustomer}
                    tags={this.props.currentTags}
                />
                <ModalSetRoleLeader
                    currentTags={this.props.currentTags}
                    params={this.props.params}
                    currentRole={this.state.currentRole}
                    userChoices={this.selectUser()}
                    closeModalSetRoleLeader={this.closeModalSetRoleLeader.bind(this)}
                    show={this.state.showModalLeader}

                />
                <ModalSetRoleStaff
                    currentTags={this.props.currentTags}
                    params={this.props.params}
                    userChoices={this.selectUser()}
                    show={this.state.showModalStaff}
                    closeModalSetRoleStaff={this.closeModalSetRoleStaff.bind(this)}
                />
            </div>
        )
    }
}

export default BulkAction;
