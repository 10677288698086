const initStatus = {
    staffs: [],
    keyword: '',
    keywordSubmit: '',
    orderByDelete_at: {
        field: 'delete_at',
        type: 'DESC'
    },
    orderByCreate_at: {
        field: 'create_at',
        type: 'DESC'
    },
    useOrderBy: {
        field: 'delete_at',
        type: 'DESC'
    },
};

const DeletedStaffs = (state = initStatus, action) => {

    switch (action.type) {
        case 'CHANGE_KEY_WORD_STAFFS':
            return {
                ...state,
                keyword: action.keyword
            };
        case 'SUBMIT_SEARCH_KEYWORD_STAFFS':
            return {
                ...state,
                keywordSubmit: state.keyword
            };
        case 'CHANGE_ORDER_BY_STAFFS':
            if (action.field === 'delete_at') {
                return  {
                    ...state,
                    orderByDelete_at: {
                        field: action.field,
                        type: action.typeOrder
                    },
                    useOrderBy: {
                        field: action.field,
                        type: action.typeOrder
                    }
                }
            }

            return {
                ...state,
                orderByCreate_at: {
                    field: action.field,
                    type: action.typeOrder
                },
                useOrderBy: {
                    field: action.field,
                    type: action.typeOrder
                }
            };
        case 'SET_USER_API':
            return {
                ...state,
                staffs: action.users,
            };
        case 'CHANGE_SCREEN':
            return {
                ...state,
                keyword: '',
                keywordSubmit: '',
                orderByDelete_at: {
                    field: 'delete_at',
                    type: 'DESC'
                },
                orderByCreate_at: {
                    field: 'create_at',
                    type: 'DESC'
                },
                useOrderBy: {
                    field: 'delete_at',
                    type: 'DESC'
                },
            };
        default :
            return state;
    }
};
export default DeletedStaffs;
