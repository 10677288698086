import { Component } from "react";
import React         from "react";

export default class KirokuInputCode extends Component {

    render() {
        return (
            <input {...this.props} className={`form-control-kiroku input-code ${this.props.error}`}/>
        )
    }
}
