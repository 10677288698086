import React, {Component}   from "react";
import Select, {components} from 'react-select';
import {withNamespaces}     from "react-i18next";
import {filePathService}    from "../services";


class CustomValueContainer extends Component {

    renderAvatar() {
        if ('Users' === this.props.data.type) {
            return (
                <div style={{
                    display: 'flex',
                    width: 24,
                    height: 24,
                    backgroundImage: `url(${this.props.data.tagContent.avatar ? this.props.data.tagContent.avatar : `${filePathService.resolveFilePath('icons8-account-24.png')}`})`,
                    backgroundPosition: 'center, center',
                    backgroundSize: 'cover',
                    borderRadius: '2px 0 0 2px',
                }}/>
            )
        }

        return null;
    }

    render() {
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                {this.renderAvatar()}
                <components.MultiValueLabel {...this.props}/>
            </div>
        )
    }
}

const styles = {
    multiValue: (base, state) => {
        if (state.data.type === 'Teams') {
            return {
                ...base,
                backgroundColor: '#36af47'
            }
        } else if (state.data.type === 'Users') {
            return {
                ...base,
                backgroundColor: '#17a2b8'
            }
        } else if (state.data.type === 'Surveys'){
            return {
                ...base,
                backgroundColor: '#516173'
            }
        } else if (state.data.type === 'viewMore'){
            return {
                backgroundColor: '#ffffff'
            }
        }else {
            return {
                ...base,
                backgroundColor: '#80a42a'
            }
        }
    },
    multiValueLabel: (base, state) => {
        if (state.data.type === 'viewMore') {
            return {...base, color: '', paddingRight: 6}
        }
        return state.data ? {...base, fontWeight: 'bold', color: 'white', paddingRight: 6} : base;
    },
    multiValueRemove: (base, state) => {
        if (state.data.isClose) {
            return {...base, fontWeight: 'bold', display : 'none', paddingRight: 6}
        }
        return state.data ? {...base, fontWeight: 'bold', color: 'white',} : base;
    }
};

@withNamespaces()
class KirokuSectionDropdown extends Component {
    state = {
        data: [
            {
                label: '',
                options: [],
            }
        ]
    };

    renderColor(data) {
        switch (data.label) {
            case "Teams":
                return 'title-label-select-team';
            case "User":
                return 'title-label-select-user';
            case "Surveys":
                return 'title-label-select-survey';
            case "Customers" :
                return 'title-label-select-customer';
            case "viewMore" :
                return 'title-label-select-viewMore';
            default:
        }
    }

    renderBadge(data) {
        switch (data.label) {
            case "Teams":
                return 'badge-select-team';
            case "User":
                return 'badge-select-user';
            case "Surveys":
                return 'badge-select-survey';
            case "Customers":
                return 'badge-select-customer';
            default:
        }
    }

    formatGroupLabel = (data) => {
        return <div>
            <span className={`${this.renderColor(data)}`}>{this.props.t(`label.${data.label}`)}</span>
            <span style={{float: 'right'}} className={`${this.renderBadge(data)}`}>{data.options.length}</span>
        </div>
    };

    render() {
        const {t, searchCustomerTemplate, placeholderSelectTeam} = this.props;
        return (
            <div id='multi-select' style={{position: 'relative'}}>
                <div style={{height: '100%'}}>
                    <Select
                        isMulti
                        placeholder={placeholderSelectTeam ? t('common.SelectTeamsModalAssignToCustomer') : t('common.Select')}
                        noOptionsMessage={() => t('common.noOptions')}
                        components={{
                            MultiValueLabel: (innerProps) => {
                                return (
                                    <CustomValueContainer {...innerProps}/>
                                )
                            }
                        }}
                        closeMenuOnSelect={!!searchCustomerTemplate}
                        formatGroupLabel={this.formatGroupLabel}
                        {...this.props}
                        styles={styles}
                    />
                    {this.props.searchTags}
                </div>
            </div>
        )
    }
}

export default KirokuSectionDropdown;
