import { questionsService } from './index';
import _                    from 'lodash';


class SurveyTemplateService {
    constructor(axios) {
        this.axios = axios;
    }

    createSurvey(survey) {
        return this.axios.post(`/survey-template`, survey)
    }

    loadSurveys() {
        return this.axios.get(`/pagination-survey-templates`, {
            params: {
                page: 1
            }
        }).then(res => res.data);
    };

    edit(surveyId, survey) {
        return this.axios.put(`/survey-template/${surveyId}`, survey).then(res => res.data);
    }

    detail(id) {
        return this.axios.get(`/survey-template/${id}`).then(res => res.data);
    }

    loadSurveysModal() {
        return this.axios.get(`/survey-templates`).then(res => res.data);
    };

    assignSurveys(surveys, customers) {
        return this.axios.post(`/assignment-surveys-customers`, {
            surveys,
            customers
        })
    }

    loadRecords(params = {}) {
        return this.axios.get(`/survey-records`, {
            params: params
        }).then(res => res.data);
    };

    filterSurvey(params = {}) {
        return this.axios.get(`/pagination-survey-templates`, {
            params: params
        }).then(res => res.data)
    }

    returnAnswer = {
        InputText  : '',
        Media      : {},
        Date       : null,
        message    : '',
        Single     : {},
        MultiChoice: [],
        YesNo      : ''
    };

    getInitAnswerQuestion(question) {
        return this.returnAnswer[question.type]
    }

    async loadSurveyTemplateOfCustomer(surveyId, customerId) {
        const response     = await this.axios.get(`/survey-template-by-customer?customer_id=${customerId}&survey_id=${surveyId}`);
        let questions      = questionsService.makeAnswerSingle(response.data['survey'].questions);
        const newQuestions = questions.map(
            (question, index) => {
                return {
                    ...question,
                    answers: this.getInitAnswerQuestion(question),
                    index  : index
                }
            });

        const newQuestionsWithAnswer = questionsService.makeUpAnswerWith(newQuestions);

        let shouldHasPreviousQuestions = newQuestionsWithAnswer.reduce((indexesOfHavingPrevious, currentQuestion) => {
            if (currentQuestion.nextQuestionYes) {
                indexesOfHavingPrevious.push(currentQuestion.nextQuestionYes);
                indexesOfHavingPrevious.push(currentQuestion.nextQuestionNo)
            }

            return indexesOfHavingPrevious;
        }, []);

        shouldHasPreviousQuestions = _.uniq(shouldHasPreviousQuestions);
        shouldHasPreviousQuestions.forEach(questionIndex => newQuestionsWithAnswer[questionIndex].hasPrevious = true);
        response.data.survey.questions = newQuestionsWithAnswer;
        return {
            ...response.data
        };
    }

    deleteSurveys(listId) {
        return this.axios.put(`/survey-templates`, {surveys: listId}).then(res => res.status);
    }

    saveAsDraft(draft) {
        return this.axios.post(`/save-draft/surveyTemplate`, draft);
    }

    getSurveysBeta() {
        return this.axios.get('/surveys-beta').then(res => res.data)
    }
}

export default SurveyTemplateService;
