import {LOAD_LOG, LOAD_LOG_PAGE, SEARCH_LOGS} from "../actions/Log";
import {logsService}                          from "../services";

export const logsMiddleware = () => next => action => {
    switch (action.type) {
        case LOAD_LOG:
            return logsService.load().then(res => {
                next({
                    type: LOAD_LOG,
                    logs: res
                })
            });
        case LOAD_LOG_PAGE :
            return logsService.loadPage(action.params || '').then(res => {
                next({
                    ...action,
                    logs: res.logs,
                    totalPages: res.totalPages || 1,
                    currentPage: res.currentPage || 1,
                    hasNext: res.hasNext
                })
            });
        case SEARCH_LOGS:
            return logsService.loadPage(action.params || '').then(res => {
                next({
                    ...action,
                    logs: res.logs,
                    totalPages: res.totalPages || 1,
                    currentPage: res.currentPage || 1,
                    hasNext: res.hasNext
                })
            });
        default:
            return next(action)
    }
};
