import React, { Component }                     from 'react';
import KirokuUploadFile                         from '../../../ui/KirokuUpLoadFile';
import { KirokuLoading }                        from '../../../ui';
import { uploadService }                        from '../../../services';
import { withNamespaces }                       from 'react-i18next';
import KirokuCardWeb                            from '../../../ui/KirokuCardWeb';
import KirokuCardTitleWeb                       from '../../../ui/KirokuCardTitleWeb';
import KirokuCardBodyWeb                        from '../../../ui/KirokuCardBodyWeb';
import ButtonEditQuestion                       from './ButtonEditQuestion';
import PropTypes                                from 'prop-types';
import connect                                  from 'react-redux/es/connect/connect';
import { notificationEditQuestion }             from '../../../actions/Survey';
import { conditionUpload }                      from '../../../services/FitlerData';
import ModalNotificationValidateUpload          from './ModalNotificationValidateUpload';
import { turnOnNotificationValidatedMediaFile } from '../../../actions/TakeSurvey';
import { checkUseHeadingQuestion }              from '../../Surveys/SurveyEditor/SurveyService';
import KirokuButtonLink                         from '../../../ui/KirokuButtonLink';

@withNamespaces()
@connect(state => {
    return { takeSurvey: state.TakeSurveyWeb };
}, dispatch => {
    return {
        editQuestion                        : (flagEdit, indexQuestion) => {
            dispatch(notificationEditQuestion(flagEdit, indexQuestion));
        },
        turnOnNotificationValidatedMediaFile: () => {
            dispatch(turnOnNotificationValidatedMediaFile());
        },
    };
})
class MediaFileQuestion extends Component {

    state = {
        imageUrl    : this.props.value.answer ? this.props.value.answer : null,
        typeAnswer  : this.props.value.typeAnswer ? this.props.value.typeAnswer : null,
        loadingImage: false,
        disabled    : this.props.disabled,
        flagEdit    : false,
        validated   : false,
    };

    componentDidMount() {
        this.props.onAnswer({
            answer   : this.props.value,
            validated: this.props.value.answer ? this.props.value : null,
        });
    }

    componentWillReceiveProps(nextProps) {
        if(!nextProps.value) {
           return this.setState({
                imageUrl: null,
                typeAnswer: null,
            })
        }

        if (this.props.value.answer !== nextProps.value.answer) {
            this.setState({
                imageUrl  : nextProps.value.answer ? nextProps.value.answer : null,
                typeAnswer: nextProps.value.typeAnswer ? nextProps.value.typeAnswer : null,
            });
        }
        if (this.props.disabled !== nextProps.disabled) {
            this.setState({
                disabled: nextProps.disabled,
                flagEdit: nextProps.flagEdit,
            });
        }

    }


    handleChangePhoto(e) {
        if (e.currentTarget.files[ 0 ]) {
            if (conditionUpload(e.currentTarget.files[ 0 ])) {

                this.setState({
                    typeAnswer  : e.currentTarget.files[ 0 ].type,
                    loadingImage: true,
                });
                let formData = new FormData();
                formData.append('file', e.currentTarget.files[ 0 ]);
                formData.append('name', e.currentTarget.files[ 0 ].name);

                uploadService.uploadImage(formData, 'avatar').then(res => {
                    this.setState({
                        validated   : res.url.length !== 0,
                        imageUrl    : res.url,
                        loadingImage: false,
                    });
                    this.props.onAnswer({
                        answer   : { answer: res.url, typeAnswer: this.state.typeAnswer },
                        validated: res.url ? res.url : null,
                    });
                });
            } else {
                this.props.turnOnNotificationValidatedMediaFile();
            }
        }
    }

    handleActionEditQuestion(state) {
        this.setState({
            disabled: state.disabled,
            flagEdit: state.flagEdit,
        });
        this.props.editQuestion(state.flagEdit, this.props.question.index);
        if (!state.flagEdit) {
            this.props.onUpdate({
                answer   : {
                    answer    : this.state.imageUrl,
                    typeAnswer: this.state.typeAnswer,
                },
                validated: this.state.validated,
            }, this.props.question.index, this.props.index);
        }
    }

    handleResetAnswer() {
        this.setState({
            validated   : false,
            imageUrl    : '',
            loadingImage: false,
        });
        this.props.onAnswer({
            answer   : { answer: '', typeAnswer: this.state.typeAnswer },
            validated: null
        });
    }


    render() {
        const {
                  question, t,
                  // index,
                  editor,
                  takeSurvey,
              }                = this.props;
        const { disabled }     = this.state;
        const hiddenButtonEdit = takeSurvey.flagEditing ? takeSurvey.indexQuestion !== question.index : takeSurvey.flagEditing;
        const contentQuestion  = checkUseHeadingQuestion(question);

        return (
            <div id='component-question'>

                <ModalNotificationValidateUpload/>

                <div id='web-q' className='pb-3'>
                    <KirokuCardWeb>
                        <KirokuCardTitleWeb color={'card-web'}>
                            <div className="d-flex justify-content-between">
                                <div className='number-question question-number text-overflow-elip'>
                                    {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                                </div>
                                <div/>
                                <div hidden={disabled}>
                                    <KirokuButtonLink
                                        className='padding-reset-button-link'
                                        onClick={() => {this.handleResetAnswer()}}
                                    >
                                        {t('createRecord.resetAnswer')}
                                    </KirokuButtonLink>
                                </div>
                            </div>
                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb>
                            <div className={disabled ? 'wrap-overlay-action' : ''}>
                                <span className='content-name-question'>{question.content}</span>
                                <div className='answer-question'>
                                    {this.state.loadingImage ? <KirokuLoading/>
                                        : <div>
                                            <KirokuUploadFile disabled={disabled}
                                                              url={this.state.imageUrl}
                                                              onChange={this.handleChangePhoto.bind(this)}/>
                                        </div>

                                    }
                                </div>

                            </div>
                            <div hidden={hiddenButtonEdit || editor}>

                                <ButtonEditQuestion {...this.state}
                                                    isUnrequited={question.unrequited}
                                                    clickEditQuestion={this.handleActionEditQuestion.bind(this)}
                                />
                            </div>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                </div>
            </div>
        );
    }
}

MediaFileQuestion.propTypes = {
    value: PropTypes.object,
};
export default MediaFileQuestion;
