import { customerService, recordBeta } from '../services';

function getRecordsBetaAPI(conditionAPI, next, action, store) {
    return recordBeta.getRecordsBeta(conditionAPI.params, conditionAPI.body).then(res => {
        store.dispatch({
            type: 'SET_FINISH_LOAD_API',
        });

        store.dispatch({
            type: 'SET_RECORDS_BETA',
            ...res,
        });

        next({
            ...action,
            ...res,
        });
    }).catch(error => console.log(error));
}

export const recordsBetaMiddleWare = (store) => next => action => {
    let stateRecordsBeta = store.getState().RecordsBeta;
    const {
              libCustomers,
              isOpenSelected,
              libCustomersSelected,
          }              = store.getState().KirokuModal.ModalSelectCustomers;
    const {
              currentPage, statusRecord,
              customerIds, templateIds,
              updateByIds, servicedAt,
              updatedAt, keywordsEd,
              useOrderBy, useSortBy,
              keywords, customerTagByUser,
              customerOfTeamByUser, typeSearchRead
          }              = stateRecordsBeta;
    let conditionAPI     = {
        params : {
            currentPage,
        }, body: {
            status       : statusRecord,
            customerIds  : customerIds,
            templateIds  : templateIds,
            updateByIds  : updateByIds,
            serviceAt    : {
                startDate: servicedAt.momentStartDate || null,
                endDate  : servicedAt.momentEndDate || null
            },
            updatedAt    : {
                startDate: updatedAt.momentStartDate || null,
                endDate  : updatedAt.momentEndDate || null
            },
            keywordSearch: keywordsEd,
            orders       : [
                {
                    field: useSortBy === 'update_at' ? 'records.last_action_at' : 'records.serviced_at',
                    type : useOrderBy,
                },
            ],
            containRead    : typeSearchRead === 'ALL'
        },
    };

    switch (action.type) {
        case 'LOAD_RECORDS_BETA_BY_CONDITION':
            return getRecordsBetaAPI(conditionAPI, next, action, store);
        case 'SET_INIT_CONDITION_RECORD_BETA':
            let userId = JSON.parse(localStorage.getItem('profile')).id;
            let customersByUserIdInit = recordBeta.getCustomersByUserId(userId);
            let customersBetaInit = customerService.getCustomersBeta();
            return Promise.all([customersByUserIdInit, customersBetaInit]).then(resolve => {
                return getRecordsBetaAPI({
                    params : {
                        currentPage: 1,
                    },
                    body: {
                        status       : 'recorded',
                        templateIds  : [],
                        updateByIds  : [],
                        serviceAt    : {
                            startDate: null,
                            endDate  : null
                        },
                        updatedAt    : {
                            startDate: null,
                            endDate  : null
                        },
                        keywordSearch: '',
                        orders       : [
                            {
                                field: 'records.serviced_at',
                                type : 'DESC',
                            },
                        ],
                        containRead  : false,
                        customerIds: resolve[0].customers.map(ctm => (ctm.id)),
                    },
                }, next, { ...action, type: 'GET_CUSTOMERS_BY_USER_AND_CUSTOMERS_BETA_INIT',
                    customersByUserId: resolve[0].customers,
                    customersBeta: resolve[1].customers,
                }, store);
            });
        case 'CHANGE_SERVICE_AT_RECORD_BETA':
            return getRecordsBetaAPI({
                params: {
                    ...conditionAPI.params,
                    currentPage: 1,
                },
                body: {
                    ...conditionAPI.body,
                    serviceAt: {
                        startDate: action.dateRange.momentStartDate,
                        endDate  : action.dateRange.momentEndDate,
                    },
                },
            }, next, action, store);
        case 'CHANGE_UPDATE_AT_RECORD_BETA':
            return getRecordsBetaAPI({
                ...conditionAPI,
                params: {
                    ...conditionAPI.params,
                    currentPage: 1,
                },
                body: {
                    ...conditionAPI.body,
                    updatedAt: {
                        startDate: action.dateRange.momentStartDate,
                        endDate  : action.dateRange.momentEndDate,
                    },
                },
            }, next, action, store);
        case 'CHANGE_TYPE_SEARCH':
            let customerIds = customerOfTeamByUser.map(ctm => ctm.id);
            if (action.typeSearch === 'SEARCH_CUSTOM_CUSTOMER') {
                customerIds = libCustomers.filter(ctm => !!ctm.selected)
                    .map(ctm => ctm.id);
                if (isOpenSelected) {
                    customerIds = libCustomersSelected.filter(ctm => ctm.selected).map(ctm => ctm.id);
                }
            }

            if (action.typeSearch === 'SEARCH_BY_CUSTOMER_TAG') {
                customerIds = customerTagByUser.map(ctm => ctm.id);
            }

            if (action.typeSearch === 'SEARCH_BY_TEAM_TAG') {
                customerIds = customerOfTeamByUser.map(ctm => ctm.id);
            }

            return getRecordsBetaAPI({
                params: {
                    ...conditionAPI.params,
                    currentPage: 1,
                },
                body: {
                    ...conditionAPI.body,
                    customerIds: customerIds,
                },
            }, next, {...action, customerIds}, store);
        case 'CHANGE_NUMBER_PAGE_RECORDS':
            return getRecordsBetaAPI({
                params: {
                    ...conditionAPI.params,
                    currentPage: action.currentPage,
                }
                , body: {
                    ...conditionAPI.body,
                },
            }, next, action, store);
        case 'CHANGE_ORDER_RECORD_BETA':
            return getRecordsBetaAPI({
                params: {
                    ...conditionAPI.params,
                    currentPage: 1,
                }
                , body: {
                    ...conditionAPI.body,
                    orders: [
                        {
                            field: action.sortBy === 'update_at' ? 'records.last_action_at' : 'records.serviced_at',
                            type : action.orderByNew,
                        },
                    ],
                },
            }, next, action, store);

        case 'CHANGE_STATUS_RECORDED':
            return getRecordsBetaAPI({
                params: {
                    ...conditionAPI.params,
                    currentPage: 1,
                },
                body: {
                    ...conditionAPI.body,
                    status: action.statusRecord,
                },
            }, next, action, store);
        case 'CHANGE_KEYWORD_SUBMIT':
            return getRecordsBetaAPI({
                params: {
                    ...conditionAPI.params,
                    currentPage: 1
                },
                body: {
                    ...conditionAPI.body,
                    keywordSearch: keywords,
                },
            }, next, action, store);

        case 'GET_CUSTOMERS_OF_TEAM_BY_USER':
            return recordBeta.getCustomersOfTeamByUserId(action.userId).then(res => {
                next({
                    ...action,
                    ...res,
                    customers: res.customers,
                });
            });
        case 'LOAD_INIT_SCREEN_RECORDS_BETA':
            let customersByUserId = recordBeta.getCustomersByUserId(action.userId);
            let customersBeta = customerService.getCustomersBeta();
            return Promise.all([customersByUserId, customersBeta]).then(resolve => {
                return getRecordsBetaAPI({
                    ...conditionAPI,
                    body: {
                        ...conditionAPI.body,
                        customerIds: resolve[0].customers.map(ctm => (ctm.id)),
                    },
                }, next, { ...action, type: 'GET_CUSTOMERS_BY_USER_AND_CUSTOMERS_BETA',
                    customersByUserId: resolve[0].customers,
                    customersBeta: resolve[1].customers,
                }, store);
            });
        case 'HANDLE_CONFIRM_MODAL_SELECT_SURVEYS':
            return getRecordsBetaAPI({
                params: {
                    ...conditionAPI.params,
                    currentPage: 1
                },
                body: {
                    ...conditionAPI.body,
                    templateIds: action.templateIds
                },
            }, next, action, store);
        case 'HANDLE_CONFIRM_STAFF_MODAL_SEARCH':
            return getRecordsBetaAPI({
                params: {
                    ...conditionAPI.params,
                    currentPage: 1
                },
                body: {
                    ...conditionAPI.body,
                    updateByIds: action.staffListSelected.map(item => item.id)
                },
            }, next, action, store);
        case 'LOAD_RECORD_DETAIL_BETA':
            return recordBeta.getRecordDetailBeta({
                recordId: action.recordId,
            }).then(res => {
                next({
                    ...action,
                    ...res,
                    recordIds: action.recordIds
                })
            });
        case 'HANDLE_SUBMIT_COMMENT_RECORD' :
            return recordBeta.getRecordsByComment({
                    content  : action.body.contentComment,
                    record_id: action.body.record_id,
                    title    : action.body.title,
                    customer : action.body.customer
            }).then((res) => {
                store.dispatch({
                    type: 'LOADING_COMMENT_RECORD',
                });
                next({
                    ...action,
                   comments: res
                })
            });
        case 'LOAD_HISTORY_OF_RECORD':
            return recordBeta.getHistoryRecord(action.id.recordId
            , action.params.page).then((res) => {
                store.dispatch({
                    type : 'HAS_LOAD_RECORD_LOG'
                });
                next({
                    ...action,
                    historyLogs : res,
                });
            });

        case 'CHANGE_TYPE_SEARCH_READ_UNREAD' :
            return getRecordsBetaAPI({
                params: {
                    ...conditionAPI.params,
                    currentPage: 1
                },
                body: {
                    ...conditionAPI.body,
                    containRead: action.typeSearchRead === 'ALL'
                },
            }, next, action, store);
        case 'GET_CHANGELOG_RECORD':
            return recordBeta.getChangelogRecord(action.recordId, action.logId).then(res => {
                const recordAfter = JSON.parse(res.log.data);
                const recordBefore = JSON.parse(res.old.data);

                return next({
                    ...action,
                    recordAfter,
                    recordBefore
                });

            }).catch(error => console.log(error));
        case 'LOAD_RECORD_FROM_ANALYSIS' :
            return customerService.getCustomersBeta().then(metaDataCustomers => {
                return getRecordsBetaAPI({
                    params: {
                        ...conditionAPI.params,
                        currentPage: 1
                    },
                    body: {
                        ...conditionAPI.body,
                        templateIds: [action.condition.templateId],
                        customerIds : [action.condition.customerId],
                        serviceAt    : {
                            startDate:  action.condition.startDate,
                            endDate  : action.condition.endDate
                        },
                        containRead : true
                    },
                }, next, {
                    ...action,
                    metaDataCustomers:metaDataCustomers.customers
                }, store);
            });
        case 'CHANGE_TAB_CARD_RECORD_DETAIL': {
            const recordHistory = store.getState().RecordsBeta
            if(action.tabType === "HISTORY_TAB" &&
                recordHistory.historyLogs.currentPage === 1 &&
                !recordHistory.historyLogs.logs.length
            ) {
                const recordDetailState = store.getState().KirokuAIRecord
                store.dispatch({
                    type: 'LOADING_RECORD_LOG',
                });
                store.dispatch({
                    type : 'LOAD_HISTORY_OF_RECORD',
                    id: {
                        recordId: recordDetailState.record.id
                    },
                    params: {
                        page: 1
                    }
                });
            }
            return next(action);
        }
        default:
            return next(action);
    }
};
