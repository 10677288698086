import React, {Component} from 'react';
import {withNamespaces}   from "react-i18next";
import THeader            from "../../../ui/THeader";
import TBody              from "../../../ui/TBody";
import KirokuTable        from "../../../ui/KirokuTabLe";
import {filePathService}  from "../../../services";
import connect            from "react-redux/es/connect/connect";
import {NOTIFICATION}     from "../../../actions/Notification";
import TabResult          from "./TabResult";
import {Link}             from "react-router-dom";

@withNamespaces()

@connect(() => {
    return {
        //profile: state.KirokuAIUser.profile
    }
}, (dispatch) => {
    return {
        notification: (t) => {
            dispatch({
                type: NOTIFICATION,
                typeNotification: 'success',
                message: t('message.UpdateSuccess')
            })
        }
    }
})
class ListTeam extends Component {
    state = {
        selected: false
    };

    componentWillMount() {
        this.setState({
            teams: this.props.teams,
            user: this.props.user
        })
    }

    newRoleTeam(newTeam) {
        this.setState({
            teams: newTeam
        })
    }

    removeMember(teamChoice) {
        let newTeam = this.state.teams.filter(team => !teamChoice.includes(team.id));
        this.setState({
            teams: newTeam
        });
        this.props.currentTeam()
    }

    render() {
        let {teams} = this.state;
        let {t}     = this.props;
        return (
            <div>
                <KirokuTable>
                    <THeader>
                        <tr>
                            <th style={{width: 200}}>{t('staff.Name')}</th>
                            <th>{t('common.Description')}</th>
                            <th/>
                        </tr>
                    </THeader>
                    <TBody>
                        {teams.map((team) =>
                            <tr key={team.id}>
                                <td>
                                    <Link to={`/teams/team-detail/${team.id}`}>
                                        <p>{team.name}</p>
                                    </Link>
                                </td>
                                <td>{team.description}</td>
                                <td/>
                            </tr>
                        )}
                    </TBody>
                </KirokuTable>
            </div>
        );
    }
}

class TabManageTeam extends Component {
    currentTeam() {
        this.props.currentTeam()
    }

    render() {
        let {teams} = this.props;
        return (
            <div hidden={this.props.show}>
                {teams.length ?
                    <ListTeam currentTeam={this.currentTeam.bind(this)} history={this.props.history}
                              user={this.props.user} teams={teams}/> :
                    <TabResult messageFirst={'team1'} messageSecond={'team2'}>
                        <img className='pt-2 pb-2'
                             src={`${filePathService.resolveFilePath('team-icon@2x.png')}`} alt="no team"/>
                    </TabResult>}
            </div>
        )
    }
}

export default TabManageTeam;