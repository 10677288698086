import React, { Component }    from 'react';
import { KirokuInputSearchOnBlur } from "../../../../ui";
import { connect }             from "react-redux";
import { withNamespaces }      from "react-i18next";

@withNamespaces()
@connect(state => {
    return {
        title       : state.DeletedRecords.title,
        customerName: state.DeletedRecords.customerName
    }
}, dispatch => {
    return {
        handleSearchKeyWord: (value, nameInput) => {
            dispatch({
                type: 'CHANGE_SEARCH_KEYWORD',
                value, nameInput
            })
        },
        handlerSubmitSearch: () => {
            dispatch({
                type: 'SUBMIT_SEARCH_KEYWORD',
            })
        }
    }
})
class ConditionRecords extends Component {

    handlerSubmitSearch() {
        this.props.handlerSubmitSearch();
    }

    handleChangeInput(name, e) {
        this.props.handleSearchKeyWord(e.target.value, name);
    }

    render() {
        const { t, title, customerName } = this.props;
        return (
            <div className={'d-flex'} style={{width: '100%'}}>
                <div className={'font-size-condition'}>
                    <KirokuInputSearchOnBlur
                        placeholder={t('DeletedList.searchByCustomer')}
                        value={customerName}
                        onSubmit={this.handlerSubmitSearch.bind(this)}
                        onBlur={this.handlerSubmitSearch.bind(this)}
                        onChange={this.handleChangeInput.bind(this, 'customerName')}
                        id={'inputSearchSurveysDeleted'}
                    />
                </div>
                <div className="p-2"/>
                <div className={'font-size-condition'}>
                    <KirokuInputSearchOnBlur
                        placeholder={t('DeletedList.searchByTemplate')}
                        value={title}
                        onSubmit={this.handlerSubmitSearch.bind(this)}
                        onBlur={this.handlerSubmitSearch.bind(this)}
                        onChange={this.handleChangeInput.bind(this, 'title')}
                        id={'inputSearchSurveysDeleted'}
                    />
                </div>

            </div>
        )
    }
}

export default ConditionRecords
