import axios       from 'axios';
import config      from '../config';
import HandleError from './HandleError';

const axiosConfig = axios.create({
    baseURL: config.domain,
    timeout: 10000,
});

axiosConfig.interceptors.request.use(axiosConfig => {
    axiosConfig.headers.Authorization = localStorage.getItem('token') || '';
    return axiosConfig;
}, error => {
    return Promise.reject(error);
});

axiosConfig.interceptors.response.use(
    response => {
        return response;
    }, error => {
        HandleError(error);
        return Promise.reject(error);
    },
);

export default axiosConfig;
