import GenericQuestion from "./GenericQuestion";

class Number extends GenericQuestion {

    getNext(answer) {
        if(this.payload.nextQuestion === 0) {
            return this.payload.nextQuestion
        }
        return this.payload.nextQuestion || this.payload.index + 1
    }
}
export default Number;
