import React, {Component } from 'react';
import KirokuModalHeader   from "../../../../ui/KirokuModalHeader";
import KirokuModalBody     from "../../../../ui/KirokuModalBody";
import KirokuModalFooter   from "../../../../ui/KirokuModalFooter";
import KirokuButton        from "../../../../ui/KirokuButton";
import KirokuModal         from "../../../../ui/KirokuModal";
import {withNamespaces}    from "react-i18next";
import * as moment         from "moment";
import config              from '../../../../config';

@withNamespaces()
class ModalLoadStatusRecord extends Component {

    state = {
        show: this.props.dataBackup
    };
    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.dataBackup !== this.props.dataBackup){
            this.setState({
                show: nextProps.dataBackup
            })
        }
    }

    actionModal() {
        this.setState({
            show: !this.state.show
        })
    }

    handleLoadBackup() {
        this.props.handleLoadBackup();
        this.actionModal();
    }

    handleDiscard() {

        this.actionModal();
    }

    render() {
        const {t, dataBackup} = this.props;
        return (
            <KirokuModal show={this.state.show} onCloseModal={this.actionModal.bind(this)}>
                <KirokuModalHeader>
                    <div className={'text-center'}>
                        <div className='f-bold'>
                            {/*{t('createRecord.RecoverRecordDraft')}*/}
                            {t('createRecord.TitleModalRecoverRecordBackup')}
                        </div>
                    </div>
                </KirokuModalHeader>
                <KirokuModalBody>
                    <div className='fix-center pt-3 pb-3'>
                        <i style={{fontSize: 50}} className="fas fa-file-download text-secondary pb-3"/>
                        <div>
                            {t('createRecord.YouHaveABackupRecordForCustomer')}
                            {/*{t('createRecord.YouHaveADraftRecordForCustomer')}*/}
                        </div>
                        <div
                            className='f-bold'>
                            {dataBackup ? moment.unix(dataBackup.create_at).format(config.formatDateTimeTh) : ''}
                            </div>
                    </div>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <div className='d-flex justify-content-between' style={{width: 250}}>
                        <KirokuButton style={{width: 120}} onClick={this.handleDiscard.bind(this)} color={'white'}>
                            {t('createRecord.discard')}
                        </KirokuButton>

                        <KirokuButton
                            disabled={!this.props['dataBackup']}
                            color={'primary'} style={{width: 120}}
                            onClick={this.handleLoadBackup.bind(this)}
                        >
                            {t('createRecord.loadDraft')}
                        </KirokuButton>
                    </div>
                </KirokuModalFooter>
            </KirokuModal>
        );
    }
}

export default ModalLoadStatusRecord;
