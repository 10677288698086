export const CLEAR_TAKE_SURVEY               = 'CLEAR_TAKE_SURVEY';
export const TAKE_RECORD                     = 'TAKE_RECORD';
export const TURN_ON_LOAD_DRAFT              = 'TURN_ON_LOAD_DRAFT';
export const ACTION_BEGIN_ANSWER             = 'ACTION_BEGIN_ANSWER';
export const ACTION_BACK_SCREEN_DETAIL       = 'ACTION_BACK_SCREEN_DETAIL';
export const ACTION_LOAD_PAGE_DETAIL         = 'ACTION_LOAD_PAGE_DETAIL';
export const ACTION_BACK_ANSWER              = 'ACTION_BACK_ANSWER';
export const SHOW_CONFIRM_EXIT               = 'SHOW_CONFIRM_EXIT';
export const CLOSE_CONFIRM_EXIT              = 'CLOSE_CONFIRM_EXIT';
export const TURN_ON_LOAD_DRAFT_WEB          = 'TURN_ON_LOAD_DRAFT_WEB';
export const TURN_ON_LOAD_DRAFT_EDIT_RECORD  = 'TURN_ON_LOAD_DRAFT_EDIT_RECORD';
export const TURN_ON_MODAL_CONFIRM_EXIT      = 'TURN_ON_MODAL_CONFIRM_EXIT';
export const TURN_OFF_MODAL_CONFIRM_EXIT     = 'TURN_OFF_MODAL_CONFIRM_EXIT';

export const TURN_OFF_FLAG_LOAD_DRAFT_WEB    = 'TURN_OFF_FLAG_LOAD_DRAFT_WEB';
export const TURN_OFF_FLAG_LOAD_DRAFT_MOBILE = 'TURN_OFF_FLAG_LOAD_DRAFT_MOBILE';

export const TURN_OFF_FLAG_LOAD_DRAFT_EDIT_RECORD_WEB    = 'TURN_OFF_FLAG_LOAD_DRAFT_EDIT_RECORD_WEB';
export const TURN_OFF_FLAG_LOAD_DRAFT_EDIT_RECORD_MOBILE = 'TURN_OFF_FLAG_LOAD_DRAFT_EDIT_RECORD_MOBILE';


export const TURN_ON_NOTIFICATION_VALIDATE_MEDIA_FILE = 'TURN_ON_NOTIFICATION_VALIDATE_MEDIA_FILE';
export const TURN_OFF_NOTIFICATION_VALIDATE_MEDIA_FILE = 'TURN_OFF_NOTIFICATION_VALIDATE_MEDIA_FILE';

export const TURN_OF_FLAG_EDIT_QUESTION = 'TURN_OF_FLAG_EDIT_QUESTION';

export function clearTakeSurvey() {
    return {
        type: CLEAR_TAKE_SURVEY
    }
}

export function createRecord(record) {
    return {
        type: TAKE_RECORD,
        record
    }
}


export function turnOnLoadDraft() {
    return {
        type: TURN_ON_LOAD_DRAFT
    }
}

export function turnOffFlagLoadDraftMobile() {
    return {
        type: TURN_OFF_FLAG_LOAD_DRAFT_MOBILE
    }
}

export function turnOffFlagLoadDraftWeb() {
    return {
        type: TURN_OFF_FLAG_LOAD_DRAFT_WEB
    }
}

export function turnOnLoadDraftWeb() {
    return {
        type: TURN_ON_LOAD_DRAFT_WEB
    }
}

export function actionBeginAnswer() {
    return {
        type: ACTION_BEGIN_ANSWER
    }
}

export function actionBackScreenDetail() {
    return {
        type: ACTION_BACK_SCREEN_DETAIL
    }
}

export function actionLoadPageReview(recorded) {
    return {
        type: ACTION_LOAD_PAGE_DETAIL,
        recorded
    }
}

export function actionBackAnswer() {
    return {
        type: ACTION_BACK_ANSWER
    }
}

export function showConfirmExit() {
    return {
        type: SHOW_CONFIRM_EXIT
    }
}

export function closeConfirmExit() {
    return {
        type: CLOSE_CONFIRM_EXIT
    }
}


export function turnOnLoadDraftEditRecord() {
    return {
        type: TURN_ON_LOAD_DRAFT_EDIT_RECORD
    }
}


export function turnOnModalConfirmExit() {
    return {
        type: TURN_ON_MODAL_CONFIRM_EXIT,
    }
}

export function turnOffModalConfirmExit() {
    return {
        type: TURN_OFF_MODAL_CONFIRM_EXIT
    }
}


export function turnOffFlagDraftEditRecordWeb() {
    return {
        type: TURN_OFF_FLAG_LOAD_DRAFT_EDIT_RECORD_WEB
    }
}

export function turnOffFlagDraftEditRecordMobile() {
    return {
        type: TURN_OFF_FLAG_LOAD_DRAFT_EDIT_RECORD_MOBILE
    }
}

export function turnOnNotificationValidatedMediaFile() {
    return {
        type: TURN_ON_NOTIFICATION_VALIDATE_MEDIA_FILE,
        flagModal: true
    }
}

export function turnOffNotificationValidatedMediaFile() {
    return {
        type: TURN_OFF_NOTIFICATION_VALIDATE_MEDIA_FILE,
        flagModal: false
    }
}

export function turnOfFlagEditingQuestion() {
    return {
        type: TURN_OF_FLAG_EDIT_QUESTION
    }
}
