import React from 'react';
import {KirokuButton, KirokuInput} from "../../../ui";
import {withNamespaces} from "react-i18next";
import {connect} from "react-redux";
import {NOTIFICATION} from "../../../actions/Notification";
import {authService} from "../../../services";

@withNamespaces()
@connect((state) => ({
    tfa: state.KirokuAIReject.tfa,
    isAdmin: state.KirokuAIUser.profile
}), (dispatch) => {
    return {
        notification: () => {
            dispatch({
                type: NOTIFICATION,
                typeNotification: 'success',
                message: 'message.UpdateSuccess'
            })
        },
        enableTFAAPI: (staffId) => {
            dispatch({
                type: "ENABLE_TFA",
                payload: staffId
            })
        },
        clearTFA: () => {
            dispatch({
                type: "CLEAR_TFA"
            })
        }
    }
})
class CustomerTwoFactorWeb extends React.Component {

    state = {
        messageError: false,
        errorOTP: false,
        otp: ''
    };

    enableTFA() {
        if (`${this.props.isAdmin.id}` === `${this.props.id}`) {
            this.props.enableTFAAPI()
        } else {
            this.props.enableTFAAPI(this.props.id)
        }
    }

    handleSubmitOTP() {
        if (Object.values(this.state.errors).every(item => !item)) {
            let data = {};
            if (`${this.props.isAdmin.id}` === `${this.props.id}`) {
                data = {
                    otp: this.state.otp
                };
            } else {
                data = {
                    otp: this.state.otp,
                    staff: this.props.id
                };
            }
            authService.verifyTFA(data).then(() => {
                this.props.notification();
                if (`${this.props.isAdmin.id}` === `${this.props.id}`) {
                    return this.props.history.push(`/my-profile`)
                } else {
                    this.props.history.push(`/staffs/staff/${this.props.id}`);
                }
            }).catch(err => {
                if (err.response && err.response.status === 400) {
                    this.setState({
                        errorOTP: true,
                        otp: ''
                    })
                }
            })
        }
    }

    disableTFA() {
        let data = {};
        if (`${this.props.isAdmin.id}` !== `${this.props.id}`) {
            data = {
                staff: this.props.id
            }
        }
        authService.disableTFA(data).then(() => {
            this.props.notification();
            if (`${this.props.isAdmin.id}` === `${this.props.id}`) {
                return this.props.history.push(`/my-profile`)
            } else {
                this.props.history.push(`/staffs/staff/${this.props.id}`);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    handleChangeInput(e) {
        this.setState({
            [e.target.name]: e.currentTarget.value,
            errors: {
                [e.target.name]: false
            },
            errorOTP: !e.currentTarget.value,
            messageError: false
        })
    }

    componentWillUnmount() {
        return this.props.clearTFA()
    }

    render() {
        let {t, tfa, isTFA} = this.props;

        return (
            <div>
                <div className="row" style={{width: '100%'}}>
                    <div className='col-md-10'>
                        <h5>{t('common.Two-factorAuthentication')}</h5>
                    </div>
                    <div className='col-md-2'>
                        {!!isTFA? (
                            <KirokuButton color="danger" onClick={this.disableTFA.bind(this)}>{t('common.disable')}</KirokuButton>
                        ) : (
                            <KirokuButton color="success" onClick={this.enableTFA.bind(this)}>{t('common.enable')}</KirokuButton>
                        )}
                    </div>
                    {!!tfa ? (
                        <div className="fix-center ml-4" style={{width: "100%"}}>
                            <h5 style={{"borderBottom": "1px solid #a8a8a8", "paddingBottom": "5px"}}>{t('common.Setup TFA')}</h5>
                            <span>
                                <p>{t('common.SetupDes')}</p>
                                <img src={tfa.qrCode} alt="QRCode" className="img-thumbnail"
                                     style={{"display": "block", "margin": "auto"}}/>
                                <p>{t('common.Secret Key')} - {tfa.secret}</p>
                                <p>{t('common.Auth Type - Time Based - OTP')}</p>
                                <div style={{position: 'relative'}}>
                                    <KirokuInput
                                        value={this.state.otp || ''}
                                        name='otp'
                                        type='text'
                                        onChange={this.handleChangeInput.bind(this)}/>
                                    <p hidden={!this.state.errorOTP}
                                       style={{
                                           color: 'red',
                                           fontSize: '13px'
                                       }}>{t('common.requireFailOTP')}</p>
                                </div>
                                <div className="fix-center pt-8">
                                    <KirokuButton color="primary"
                                                  disabled={!this.state.otp}
                                                  onClick={this.handleSubmitOTP.bind(this)}>{t('common.EnableTFA')}</KirokuButton>
                                </div>
                            </span>

                        </div>
                    ) : null}
                </div>
            </div>
        );
    }

}

export default CustomerTwoFactorWeb;
