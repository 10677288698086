import {Component}                                                    from "react";
import React                                                          from "react";
import {KirokuCertificate, KirokuStaffTagList, KirokuAge, KirokuDate} from "../../../ui";
import {withNamespaces}                                               from "react-i18next";
import moment                                                         from "moment";

@withNamespaces()
class CustomerProfile extends Component {
    render() {
        const customer = this.props.customer;
        let {t} = this.props;
        return (
            <div>
                <h5>{t('CustomerEditor.PersonalInformation')}</h5>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('common.Name')}:</span>
                            <br/>
                            <span className='personal-info'>{customer.name}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('common.Age')}:</span>
                            <br/>
                            <span className='personal-info'>
                                 <KirokuAge dob={customer.date_of_birth}/>
                            </span>
                        </div>
                        {/*<div className='mb-10'>*/}
                        {/*    <span className='customer-info f-bold'>{t('CustomerEditor.PhoneNumber')}:</span>*/}
                        {/*    <br/>*/}
                        {/*    <span className='personal-info'>{customer.phone_number}</span>*/}
                        {/*</div>*/}
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('CustomerEditor.BloodType')}:</span>
                            <br/>
                            <span
                                className='personal-info'>{(customer.blood_type === 'Unknown' ? t('CustomerEditor.Unknown') : customer.blood_type) || 'Unknown'}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('common.Characteristic')}:</span>
                            <br/>
                            <span className='personal-info'>{customer.characteristic}</span>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        {/*<div className='mb-10'>*/}
                        {/*    <span className='customer-info f-bold'>{t('CustomerEditor.CustomerCode')}:</span>*/}
                        {/*    <br/>*/}
                        {/*    <span className='personal-info'>{customer.customer_code}</span>*/}
                        {/*</div>*/}
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('CustomerEditor.CustomerGender')}:</span>
                            <br/>
                            <span className='personal-info'>{t(`CustomerEditor.${customer.gender}`)}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('CustomerEditor.PostCode')}:</span>
                            <br/>
                            <span className='personal-info'>{customer.post_code}</span>
                        </div>

                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('common.Address')}:</span>
                            <br/>
                            <span className='personal-info'>{customer.address}</span>
                        </div>

                    </div>
                    {/*<div className='col-md-4'>*/}
                    {/*    <div className='mb-10'>*/}
                    {/*        <span className='customer-info f-bold'>{t('CustomerEditor.EmergencyContact')}:</span>*/}
                    {/*        <br/>*/}
                    {/*        <span className='personal-info'>{customer.emergency_contact}</span>*/}
                    {/*    </div>*/}
                    {/*<div className='mb-10'>*/}
                    {/*    <span className='customer-info f-bold'>{t('CustomerEditor.Certificate')}:</span>*/}
                    {/*    <br/>*/}
                    {/*    <span className='personal-info'>{customer.certification_number}</span>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                </div>
                <hr/>
                <h5>{t('CustomerEditor.CustomerInformation')}</h5>
                <div className="row">
                    <div className="col-md-4">
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('CustomerEditor.TeamName')}:</span>
                            <br/>
                            <span className='personal-info'>{customer.team ? customer.team.name : ''}</span>
                        </div>
                        {/*<div className='mb-10'>*/}
                        {/*    <span className='customer-info f-bold'>{t('CustomerEditor.TermCertificationStart')}:</span>*/}
                        {/*    <br/>*/}
                        {/*    <span className='personal-info'>*/}
                        {/*        <KirokuDate date={customer.certification_start_date}*/}
                        {/*                    timestamp={true}/></span>*/}
                        {/*</div>*/}
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('CustomerEditor.StartDateOfContact')}:</span>
                            <br/>
                            <span className='personal-info'><KirokuDate date={customer.contract_start}
                                                                        timestamp={true}/></span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('CustomerEditor.SupportClassification')}:</span>
                            <br/>
                            <span
                                className='personal-info'>{(customer.classification === 0 ? t('CustomerEditor.NotApplicable') : customer.classification) || 0}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('CustomerEditor.KindOfCertificate')}:</span>
                            <span className='personal-info'>
                                <KirokuCertificate certificate={customer.certificate}/>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('CustomerEditor.StaffInCharge')}:</span>
                            <span className='personal-info'>
                                <KirokuStaffTagList type={'users'} listTags={customer.staffs}/>
                             </span>
                        </div>
                        {/*<div className='mb-10'>*/}
                        {/*    <span className='customer-info f-bold'>{t('CustomerEditor.TermCertificationEnd')}:</span>*/}
                        {/*    <br/>*/}
                        {/*    <span className='personal-info'><KirokuDate date={customer.certification_end_date}*/}
                        {/*                                                timestamp={true}/></span>*/}
                        {/*</div>*/}
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('CustomerEditor.EndDateOfContact')}:</span>
                            <br/>
                            <span className='personal-info'><KirokuDate date={customer.contract_end} timestamp={true}/> </span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('common.team')}:</span>
                            <span className='personal-info'>
                                <KirokuStaffTagList type={'teams'} listTags={customer.teams}/>
                             </span>
                        </div>

                    </div>
                    <div className="col-md-4"/>
                </div>
                <hr/>
                <h5>{t('CustomerEditor.contact.ContactInformation')}</h5>
                <div>
                    {customer.contacts.length ? customer.contacts.map((contact, index) => (
                            <div className='row' key={index}>
                                <div className="col-md-4">
                                    <div className='mb-10'>
                                        <span className='customer-info f-bold'>{t('common.Name')}:</span>
                                        <br/>
                                        <span className='personal-info'>{contact.name}</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='mb-10'>
                                        <span className='customer-info f-bold'>{t('common.Relationship')}:</span>
                                        <br/>
                                        <span
                                            className='personal-info'>{t(`CustomerEditor.contact.${contact.relationship}`)}</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='mb-10'>
                                        <span className='customer-info f-bold'>{t('common.Phone')}:</span>
                                        <br/>
                                        <span className='personal-info'>{contact.phone_number}</span>
                                    </div>

                                </div>
                                <div className='col-md-12'>
                                    <div className='mb-10'>
                                        <span className='customer-info f-bold'>{t('common.Memo')}:</span>
                                        <br/>
                                        <span className='personal-info'>{contact.memo}</span>
                                    </div>
                                </div>
                            </div>
                        )) :
                        <div className='row'>
                            <div className="col-md-4">
                                <div className='mb-10'>
                                    <span className='customer-info f-bold'>{t('common.Name')}:</span>
                                    <br/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='mb-10'>
                                    <span className='customer-info f-bold'>{t('common.Relationship')}:</span>
                                    <br/>

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='mb-10'>
                                    <span className='customer-info f-bold'>{t('common.Phone')}:</span>
                                    <br/>
                                </div>

                            </div>
                            <div className='col-md-12'>
                                <div className='mb-10'>
                                    <span className='customer-info f-bold'>{t('common.Memo')}:</span>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <hr/>
                <h5>{t('CustomerEditor.Certification')}</h5>
                {customer.certifications.length ?
                    customer.certifications.map((item, index) =>
                        <div className="row" key={index}>
                            <div className="col-md-4">
                                <div className='mb-10'>
                                <span
                                    className='customer-info f-bold'>{t('CustomerEditor.CertificationNumber')}:</span>
                                    <br/>
                                    <span
                                        className='personal-info'>{item.certification_number}</span>
                                </div>
                                <span className='customer-info f-bold'>{t('CustomerEditor.Service')}:</span>
                                <ul>

                                    {item.services.map((service, index) =>
                                        <li key={index}>
                                            <div className='mb-10'>
                                        <span
                                            className='customer-info'>{t('CustomerEditor.CertificationServiceName')}:</span>
                                                <span className='personal-info'> {service.name}</span>
                                                <br/>

                                            </div>
                                            <div className='mb-10'>
                                        <span
                                            className='customer-info'>{t('CustomerEditor.CertificationServiceStartDate')}:
                                        </span>
                                                <span className='personal-info'>
                                            <KirokuDate date={service.startDate}
                                                        timestamp={true}/>
                                        </span>
                                                <br/>
                                            </div>
                                            <div className='mb-10'>
                                        <span
                                            className='customer-info'>{t('CustomerEditor.CertificationServiceEndDate')}:
                                        </span>
                                                <span className='personal-info'>
                                            <KirokuDate date={service.endDate}
                                                        timestamp={true}/></span>
                                                <br/>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <div className="col-md-4">
                                <div className='mb-10'>
                                <span
                                    className='customer-info f-bold'>{t('CustomerEditor.CertificationIssuer')}:</span>
                                    <br/>
                                    <span
                                        className='personal-info'>{item.issuer}</span>
                                </div>
                                <span className=' customer-info f-bold'>{t('CustomerEditor.monitoringTerms')}:</span>
                                <div className='mb-10'>
                                    {item.monitoring_terms.map((term, index) =>
                                        <li key={index}>
                                            <span
                                                className='personal-info'>{moment.unix(term.date).format('YYYY/MM')}</span>
                                        </li>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-4"/>
                        </div>
                    )
                    :
                    <div className="row">
                        <div className="col-md-4">
                            <div className='mb-10'>
                                <span
                                    className='customer-info f-bold'>{t('CustomerEditor.CertificationNumber')}:</span>
                            </div>
                            <span className='customer-info f-bold'>{t('CustomerEditor.Service')}:</span>
                        </div>
                        <div className="col-md-4">
                            <div className='mb-10'>
                                <span
                                    className='customer-info f-bold'>{t('CustomerEditor.CertificationIssuer')}:</span>
                            </div>
                            <span className=' customer-info f-bold'>{t('CustomerEditor.monitoringTerms')}:</span>
                        </div>
                        <div className="col-md-4"/>
                    </div>
                }
                <hr/>
                <h5>{t('CustomerEditor.Guardianship')}</h5>
                <div className="row">
                    <div className="col-md-4">
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('CustomerEditor.GuardianshipType')}:</span>
                            <br/>
                            <span
                                className='personal-info'>{customer.guardianship_type ? t(`CustomerEditor.guardianshipTypes.${customer.guardianship_type}`) : ''}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('CustomerEditor.AppointedType')}:</span>
                            <br/>
                            <span className='personal-info'>{customer.appointed_type || ''}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('CustomerEditor.CompetentCourt')}:</span>
                            <br/>
                            <span className='personal-info'>{customer.competent_court || ''}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('CustomerEditor.DecisionDate')}:</span>
                            <br/>
                            <span className='personal-info'><KirokuDate date={customer.decision_date}
                                                                        timestamp={true}/></span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('CustomerEditor.CaseNumber')}:</span>
                            <br/>
                            <span className='personal-info'>{customer.case_number || ''}</span>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('CustomerEditor.RegistrationNumber')}:</span>
                            <br/>
                            <span className='personal-info'>{customer.registration_number || ''}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('CustomerEditor.PermanentAddress')}:</span>
                            <br/>
                            <span className='personal-info'>{customer.permanent_address || ''}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('CustomerEditor.ResidenceCardAddress')}:</span>
                            <br/>
                            <span className='personal-info'>{customer.residence_card_address || ''}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('CustomerEditor.FacilityName')}:</span>
                            <br/>
                            <span className='personal-info'>{customer.facility_name || ''}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('CustomerEditor.ReportMonth')}:</span>
                            <br/>
                            <span className='personal-info'>{customer.report_month || ''}</span>
                        </div>
                    </div>
                    <div className="col-md-4"/>
                </div>
            </div>
        );
    }
}

export default CustomerProfile;
