import {COMPARISON_PREVIOUS_TERM, DASHBOARD_ACTIVITIES, LOAD_DASHBOARD} from "../actions/Dashboard";
import {dashboardService} from "../services";

export const dashboardMiddleware = (store) => next => action => {
    switch (action.type) {
        case LOAD_DASHBOARD:
            return dashboardService.dashboard().then(dashboard => {
                next({
                    type: LOAD_DASHBOARD,
                    dashboard: dashboard
                })
            }).catch(error => console.log(error));
        case COMPARISON_PREVIOUS_TERM:
            return dashboardService.comparisonPreviousTerm().then(res => {
                next({
                    ...action,
                    ...res
                })
            });
        case DASHBOARD_ACTIVITIES:
            return dashboardService.dashboardActivities(action.page).then(res => {
                next({
                    ...action,
                    ...res
                })
            }).catch(error => console.log(error));

        default:
            return next(action)
    }
};
