import React, {Component}         from 'react';
import {connect}                  from "react-redux";
import KirokuResetFilterCondition from "../../../../ui/KirokuResetFilterCondition";

@connect(state => {
    return {}
}, (dispatch) => {
    return {
        resetState: () => {
            dispatch({
                type: 'RESET_STATE_RESULT_DETAIL_CUSTOMER'
            })
        }
    }
})
class FilterType extends Component {
    state            = {
        params: {},
        type: ''
    };
    dropDownItemType =
        [
            {
                value: 'All',
                component: (<div>{this.props.t('Surveys.AllType')}</div>),
                label: this.props.t('common.All')
            },
            {
                value: 'pdf',
                component: (<div>pdf</div>),
                label: 'pdf'
            },
            {
                value: 'xlsx',
                component: (<div>xlsx</div>),
                label: 'xlsx'
            },
            {
                value: 'png',
                component: (<div>png</div>),
                label: 'png'
            },
            {
                value: 'jpeg',
                component: (<div>jpeg</div>),
                label: 'jpeg'
            },
            {
                value: 'jpg',
                component: (<div>jpg</div>),
                label: 'jpg'
            }
        ];

    handleChoiceType(e) {
        let params = [
            {
                value: e.kirokuSelected.value === 'All' ? '' : e.kirokuSelected.value,
                query: 'type',
                label: e.kirokuSelected.label ? e.kirokuSelected.label : ''
            }
        ];
        this.props.filterCondition(params);
        this.props.resetState()
    }

    render() {
        let {t} = this.props;
        return (
            <div>
                <KirokuResetFilterCondition
                    style={{width: 160}}
                    icon='fas fa-check-circle'
                    title={t('Surveys.AllType')}
                    items={this.dropDownItemType}
                    onSelect={this.handleChoiceType.bind(this)}
                />
            </div>
        );
    }
}

export default FilterType;
