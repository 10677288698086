import React, {Component} from "react";
import KirokuButton       from "./KirokuButton";
import {withNamespaces}   from "react-i18next";
import {CheckDivide}      from "./index";

@withNamespaces()

class KirokuPagination extends Component {

	increasePage () {
		this.props.increasePage();
	}

	decreasePage () {
		this.props.decreasePage()
	}

	render () {
		let {t, className} = this.props;
		return (
			<CheckDivide
				mobile={<div hidden={this.props.hidden} style={{width: '170px'}} className={className ? className : 'pb-5'}>
					<div className='d-flex justify-content-between' style={{height: '36px'}}>
						<KirokuButton disabled={this.props.disabledPrev} onClick={this.decreasePage.bind(this)}
									  color={'white'}
									  style={{padding: '5px 14px', color: '#7f8fa4'}}>
							<i className="fas fa-caret-left"/>
						</KirokuButton>
						<div className='content-pagination' style={{width: 100}}>
							<span className='mr-1'>{t('Surveys.Page', {currentPage: this.props['currentPage']})}</span>
						</div>
						<KirokuButton disabled={this.props.disabledNext} onClick={this.increasePage.bind(this)}
									  color={'white'}
									  style={{padding: '5px 14px', color: '#7f8fa4'}}>
							<i className="fas fa-caret-right"/>
						</KirokuButton>
					</div>
					<div
						className="total-pagination pt-1">{t('Surveys.TotalPage', {countPage: this.props['totalPage']})}</div>
				</div>
				}
				web={
					<div hidden={this.props.hidden} className={className ? className : 'pb-5'}>
						<div className='d-flex justify-content-between' style={{height: '36px'}}>
							<KirokuButton disabled={this.props.disabledPrev} onClick={this.decreasePage.bind(this)}
										  color={'white'}
										  style={{padding: '5px 14px', color: '#7f8fa4'}}>
								<i className="fas fa-caret-left"/>
							</KirokuButton>
							<div className='content-pagination'>
								<span
									className='mr-1'>{t('Surveys.Page', {currentPage: this.props['currentPage']})} 〳 {t('Surveys.TotalPage', {countPage: this.props['totalPage']})}</span>
							</div>
							<KirokuButton disabled={this.props.disabledNext} onClick={this.increasePage.bind(this)}
										  color={'white'}
										  style={{padding: '5px 14px', color: '#7f8fa4'}}>
								<i className="fas fa-caret-right"/>
							</KirokuButton>
						</div>
					</div>
				}
			/>
		);
	}
}

export default KirokuPagination;