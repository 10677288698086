import React, {Component} from 'react';
import KirokuCardWeb      from "../../../ui/KirokuCardWeb";
import KirokuTable        from "../../../ui/KirokuTabLe";
import THeader            from "../../../ui/THeader";
import TBody              from "../../../ui/TBody";
import {Link}             from "react-router-dom";
import {withNamespaces}   from "react-i18next";
import TabResult          from "../StaffEditor/TabResult";
import {
    authService,
    filePathService
} from "../../../services";
import {KirokuButton}     from "../../../ui";

@withNamespaces()

class TabTeam extends Component {

    handleEditProfile() {
        this.props.history.push(`/staffs/staff-editor/${this.props.staffId}`)
    }

    render() {
        let {t, user} = this.props;
        return (
            <div hidden={this.props.show}>
                <KirokuCardWeb>
                    <div className="p-3">
                        {user.length ? <KirokuTable>
                                <THeader>
                                    <tr>
                                        <th>{t('common.TeamName')}</th>
                                        <th/>
                                    </tr>
                                </THeader>
                                <TBody>
                                {user.map(team =>
                                    <tr key={team.id}>
                                        <td>
                                            <Link to={`/teams/team-detail/${team.id}`}>
                                                <p>{team.name}</p>
                                            </Link>
                                        </td>
                                        <td/>
                                    </tr>
                                )}
                                </TBody>
                            </KirokuTable> :
                            <div>
                                <TabResult messageFirst={'team1'} messageSecond={'team2'}
                                >
                                    <img className='pt-2 pb-2'
                                         src={`${filePathService.resolveFilePath('team-icon@2x.png')}`}
                                         alt="no team"/>
                                </TabResult>
                                <div className='text-center' hidden={!authService.isAdmin()}>
                                    <KirokuButton onClick={this.handleEditProfile.bind(this)} color={'primary'}>
                                        {t('common.EditProfile')}
                                    </KirokuButton>
                                </div>
                            </div>}
                    </div>
                </KirokuCardWeb>
            </div>
        )
    }
}


export default TabTeam;
