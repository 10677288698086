export const LOAD_RECORDS                  = 'LOAD_RECORDS';
export const CREATE_RECORDS                = 'CREATE_RECORDS';
export const LOAD_DETAIL                   = 'LOAD_DETAIL';
export const SELECTED_RECORD               = 'SELECTED_RECORD';
export const CHECK_ALL_RECORDS             = 'CHECK_ALL_RECORDS';
export const RESET_RECORDS                 = 'RESET_RECORDS';
export const DELETE_RECORDS                = 'DELETE_RECORDS';
export const UPDATE_RECORDS                = 'UPDATE_RECORDS';
export const RECORDS_DASHBOARD             = 'RECORDS_DASHBOARD';
export const LOAD_RECORD_TO_EDIT           = 'LOAD_RECORD_TO_EDIT';
export const CLEAR_RECORD_TO_EDIT_IN_STORE = 'CLEAR_RECORD_TO_EDIT_IN_STORE';
export const SET_NULL_RECORD               = 'SET_NULL_RECORD';
export const SAVE_QUERY_CONDITION_RECORD   = 'SAVE_QUERY_CONDITION_RECORD';
export const CONDITION_REMOVED             = 'CONDITION_REMOVED';
export const SET_NUMBER_PAGE_LIST_RECORD   = 'SET_NUMBER_PAGE_LIST_RECORD';
export const RESET_CONDITION_RECORDS       = 'RESET_CONDITION_RECORDS';
export const CUSTOMER_FOR_TAGS_RECORDS     = 'CUSTOMER_FOR_TAGS_RECORDS';
export const GET_RECORDS_FOR_DETAIL_PAGE   = 'GET_RECORDS_FOR_DETAIL_PAGE';
export const SET_INIT_RECORD_DETAIL_PAGE   =  'SET_INIT_RECORD_DETAIL_PAGE';

export function conditionRemoved(conditionRemoved) {
    return {
        type: CONDITION_REMOVED,
        conditionRemoved,
    }
}

export function customerForTagsRecords() {
    return {
        type: CUSTOMER_FOR_TAGS_RECORDS,
    }
}

export function saveQueryCondition(conditions, params, pagination, defaultTags, pageSize) {
    return {
        type: SAVE_QUERY_CONDITION_RECORD,
        conditions: conditions,
        params: params,
        pagination: pagination,
        defaultTags: defaultTags,
        pageSize : pageSize
    }
}

export function updateRecord(id, dataRecord) {
    return {
        type: UPDATE_RECORDS,
        id, dataRecord
    }

}

export function loadRecordsDashBoard() {
    return {
        type: RECORDS_DASHBOARD,
        loginRequired: true
    }
}

export function loadRecords(params, tags) {
    return {
        type: LOAD_RECORDS,
        loginRequired: true,
        params,
        tags
    }
}

export function loadDetail(recordId) {
    return {
        type: LOAD_DETAIL,
        id: recordId
    }
}

export function createRecord(record) {
    return {
        type: CREATE_RECORDS,
        loginRequired: true,
        record
    }

}

export function selectedRecord(index) {
    return {
        type: SELECTED_RECORD,
        loginRequired: true,
        index: index
    }
}

export function checkAllRecords(checked) {
    return {
        type: CHECK_ALL_RECORDS,
        loginRequired: true,
        isChecked: checked
    }
}

export function resetRecords() {
    return {
        type: RESET_RECORDS,
        loginRequired: true
    }
}

export function deleteRecords(records, params, tags) {
    return {
        type: DELETE_RECORDS,
        loginRequired: true,
        records: records.map(record => {
            return {
                id: record.id,
                customer: {
                    id  : record.customer.id,
                    name: record.customer.name
                }
            }
        }),
        params: params,
        tags
    }
}


export function loadRecordToEdit(id) {
    return {
        type: LOAD_RECORD_TO_EDIT,
        id
    }
}

export function clearRecordToEditInStore() {
    return {
        type: CLEAR_RECORD_TO_EDIT_IN_STORE
    }

}

export function loadAllSurveys() {
    return {
        type: "LOAD_ALL_SURVEY_TEMPLATE"
    }
}

export function setNullRecord() {
    return {
        type: SET_NULL_RECORD
    }
}

export function setNumberPageRecords(pageNumber) {
    return {
        type: SET_NUMBER_PAGE_LIST_RECORD,
        pageNumber
    }
}

export function resetCondition(tags) {
    return {
        type: RESET_CONDITION_RECORDS,
        tags
    }
}

export function getRecordForDetailPage(params) {
    return {
        type : GET_RECORDS_FOR_DETAIL_PAGE,
        params
    }
}
export function setInitRecordsForDetailPage() {
    return {
        type : SET_INIT_RECORD_DETAIL_PAGE
    }
}
