import React, {Component} from 'react';
import {
    FormatTimestamp, SearchNotFound,
    KirokuCardDeletedScreen, KirokuLoading,
}                         from "../../../../ui";
import {withNamespaces}   from "react-i18next";
import { connect }        from "react-redux";

@withNamespaces()
@connect(state => {
    return {
        loading: state.Loading,
        surveyTemplates: state.DeletedSurveys.surveyTemplates,
        isFinish       : state.DeletedSurveys.isFinish
    }
}, dispatch => {
    return {

    }
})
class TableSurveyTemplate extends Component {

    render() {
        const { surveyTemplates, t, loading, isFinish } = this.props;
        return(
            <div className='bg-white'>
                {!isFinish ? <KirokuLoading/> :
                    !loading ?
                    surveyTemplates.length ?
                    <KirokuCardDeletedScreen>
                        {surveyTemplates.map((surveyTemplate, index) => (
                            <div key={index}>
                                <div className='pl-3 pt-3'>
                                    <div className='header-card-del pb-2'>{surveyTemplate.title}</div>
                                    <div className='title-card-del'>{t('DeletedList.deletedAt')} : <FormatTimestamp timestampUnix={surveyTemplate.delete_at}/></div>
                                    <div className='title-card-del'>{t('DeletedList.createdAt')} : <FormatTimestamp timestampUnix={surveyTemplate.create_at}/></div>
                                </div>
                                <hr className='hr-table-mobile'/>
                            </div>
                        ))}
                    </KirokuCardDeletedScreen> :<SearchNotFound/> : <KirokuLoading/> }
            </div>
        )
    }
}

export default TableSurveyTemplate;
