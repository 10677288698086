import React, { Component } from 'react';


class ShortText extends Component {


    shorten(str, n) {
        if (str.length > n) {
            return (str.substr(0, n).concat('...'));
        } else {
            return (str.substr(0, n));
        }
    }

    render() {
        let {text, length} = this.props;

        return (
            <span title={text} className='text-short'>
                {this.shorten(text, length)}
            </span>
        );
    }

}

export default ShortText;