import GenericQuestion from "./GenericQuestion";

class MultiChoice extends GenericQuestion {

    setDefaultAnswer() {
        const answers = this.payload.choices.map(choice => {
            return {
                ...choice,
                answer   : typeof choice === 'string' ? choice : choice.answer,
                is_choice: !!choice['defaultAnswer']
            }
        });
        this.payload.answers = answers;
        delete answers['defaultAnswer'];
        return answers;
    }

    deleteDefaultAnswer() {
        this.payload.choices = this.payload.choices.map(choice => {
            delete choice.defaultAnswer;
            return choice;
        })
    }

    getNext(answer) {
        if(this.payload.nextQuestion === 0) {
            return this.payload.nextQuestion
        }
        return this.payload.nextQuestion || this.payload.index + 1
    }
}
export default MultiChoice;
