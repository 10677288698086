import React, { Component }        from 'react';
import '../templates.css'
import { checkUseHeadingQuestion } from "../../Surveys/SurveyEditor/SurveyService";

class TemplateRecord extends Component {

    static renderQuestion(question) {
        switch (question.type) {
            case "Media":
                return (
                    <div className="">
                        <img className="report-tab pb-10px" src={question.answers} alt=""/>
                    </div>
                );
            case "YesNo":
            case "Date":
                return (<div className="answer-content pb-10px">{question.answers}</div>);
            default:
                return (<div className="answer-content pb-10px">{question.answers}</div>)
        }
    }

    render() {
        const {reports} = this.props;
        return (
            <div className="report-tab container">
                <div className="wrap-content">
                    <div className="A4">
                        <div className="content">
                            {reports.templates.map((template, index) => {
                                return (
                                    <div className="wrap-bg" key={index}>
                                        {template.groupCustomer.map((customer, index) => {
                                            return(
                                                <div style={{paddingBottom: 24}} key={index}>
                                                    <div>
                                                        <div>
                                                            <label className="report-tab">
                                                                {template.translate_title}
                                                            </label>
                                                            <div className="border-title-template pb-10px">
                                                                {template.title}
                                                            </div>
                                                        </div>
                                                        <div className="pt-10px">
                                                            <label className="report-tab">
                                                                {customer.translate_customerName}
                                                            </label>
                                                            <div className="border-customer-name">
                                                                {customer.customerName}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {customer.records.map((record, index) => {
                                                            return (
                                                                <div key={index}
                                                                     className={'pt-4 border-bottom-inline'}>
                                                                    <div
                                                                        className="row fix-margin-row border-bottom-dashed">
                                                                        <div className="col-3 fix-padding-col pb-10px">
                                                                            <label className="report-tab">
                                                                                {record.translate_serviced}
                                                                            </label>
                                                                            <div>
                                                                                {record.serviced_date_time}
                                                                            </div>
                                                                        </div>
                                                                        <div className={'col-9 pb-10px'}>
                                                                            <label className="report-tab">
                                                                                {record.translate_created_by}
                                                                            </label>
                                                                            <div>
                                                                                {record.created_by}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="answers-list">
                                                                        {record.questions.map((question, index) => {
                                                                            return (
                                                                                <div
                                                                                    className="flex-column justify-content-between pt-2 answer-item"
                                                                                    key={index}>
                                                                                    <div className={
                                                                                        `${index === record.questions.length - 1 ?
                                                                                            '' : 'border-bottom-dashed'} 
                                                                                                row fix-margin-row `
                                                                                    }
                                                                                    >
                                                                                        <label
                                                                                            className="report-tab col-3 pb-10px fix-padding-col">
                                                                                            {checkUseHeadingQuestion(question)}
                                                                                        </label>
                                                                                        <div className={'col-9'}>
                                                                                            {TemplateRecord.renderQuestion(question)}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TemplateRecord
