import React, { Component }               from 'react';
import {
    KirokuCardWeb, KirokuCheckDraft,
    KirokuLoading, KirokuTable,
    TBody, THeader,
}                                         from '../../../../ui';
import { Link }                           from 'react-router-dom';
import moment                             from 'moment';
import { filePathService, scrollService } from '../../../../services';
import { withNamespaces }                 from 'react-i18next';
import TabResult
                                          from '../../../Staffs/StaffEditor/TabResult';
import config                             from '../../../../config';
import { connect }                        from 'react-redux';
import { getRecordForDetailPage }         from '../../../../actions/Record';
import $                                  from 'jquery';

@withNamespaces()
@connect(state => {
    return {
        records    : state.KirokuAIRecord.recordForDetail,
        loading    : state.Loading,
        currentPage: state.KirokuAIRecord.currentPage,
        hasNext    : state.KirokuAIRecord.hasNext
    };
}, dispatch => {
    return {
        getRecordForDetailPage: (params) => {
            dispatch(getRecordForDetailPage(params));
        },
    };
})

class TabRecords extends Component {

    componentWillMount() {
        scrollService.top();
        window.addEventListener('scroll', this.listener);
    }

    componentWillUnmount() {
        scrollService.top();
        window.removeEventListener('scroll', this.listener);
    }

    listener = () => {
        if ((Math.ceil($(window).scrollTop()) + 10 >= $(document).height() - $(window).height())
            && !this.props.loading
            && !this.props.hidden
            && this.props.hasNext
        ) {
            return this.props.getRecordForDetailPage({ page: this.props.currentPage + 1 , customerId: this.props.customer.id });
        }
        return null;
    };

    render() {
        const { t, records } = this.props;
        return (
            <div hidden={ this.props.hidden }>
                <KirokuCardWeb>
                    <div className="p-3">
                        { records.length ?
                            <KirokuTable>
                                <THeader>
                                    <tr>
                                        <th>{ t('common.Name') }</th>
                                        <th>{ t('common.Description') }</th>
                                        {/*<th>{t('common.DateCreated')}</th>*/ }
                                        <th style={ { whiteSpace: 'nowrap' } }>{ t('common.ServiceTime') }</th>
                                        <th style={ { width: 240 } } className='text-center'>{ t('common.Action') }</th>
                                    </tr>
                                </THeader>
                                <TBody>
                                    { records.map(record =>
                                        <tr key={ record.id }>
                                            <td><Link
                                                to={ `/records/record/${ record.id }` }>{ record.title }</Link>
                                                <span className='ml-6'>
                                                <KirokuCheckDraft
                                                    draftable={ record }/>
                                            </span></td>
                                            <td>{ record['survey_template'].description }</td>
                                            {/*<td>{moment(record.create_at * 1000).format('YYYY/M/D HH:mm')}</td>*/ }
                                            <td style={ { whiteSpace: 'nowrap' } }>{ record.has_time ? moment.unix(record.serviced_at).format(config.formatDateTimeTh) : moment.unix(record.serviced_at).format(config.formatDateTh) }</td>
                                            <td className='fix-center'>
                                                <div
                                                    className='d-flex justify-content-between p-1 fix-icon-align'>
                                                    <Link to={ `/records/edit-filedAll/${ record.id }` }
                                                          style={ { minWidth: 50 } }
                                                    >
                                                        <i className="fas fa-pencil-alt text-secondary"/>
                                                        <span className='ml-1  text-dark'>{ t('common.Edit') }</span>
                                                    </Link>
                                                    <div className="pr-1 pl-1"/>
                                                    <Link to={ `/records/edit/${ record.id }` }>
                                                        <i className="fas fa-pencil-alt text-secondary"/><span
                                                        className='ml-1  text-dark'>{ t('common.EditRecordOldUI') }</span>
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>,
                                    ) }
                                </TBody>
                            </KirokuTable> :
                            <TabResult messageFirst={ 'record' }>
                                <img
                                    className='pt-2 pb-2'
                                    src={ `${ filePathService.resolveFilePath('empty-survey.svg') }` }
                                    alt="error img"/>
                            </TabResult>
                        }
                    </div>
                    { this.props.loading && records.length  ? <KirokuLoading/> : null }
                </KirokuCardWeb>
            </div>
        );
    }

}

export default TabRecords;
