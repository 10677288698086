import React, {Component}    from 'react'
import {CheckDivide}         from "../../../ui";
import DetailMyProfileWeb    from "./DetailMyProfileWeb";
import DetailMyProfileMobile from "./DetailMyProfileMobile";

class DetailMyProfile extends Component {
    render() {
        let web    = <DetailMyProfileWeb history={this.props.history}/>;
        let mobile = <DetailMyProfileMobile history={this.props.history}/>;

        return (
            <div>
                <CheckDivide web={web} mobile={mobile}/>
            </div>
        )
    }
}

export default DetailMyProfile