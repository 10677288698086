import React, { Component }  from 'react';
import { buildDataOnlyDate } from '../buildData';
import { withNamespaces }    from 'react-i18next';
import CalendarChart         from './HelperComponent/CalendarChart';
import moment                from 'moment';


@withNamespaces()

class OnlyDateChart extends Component {

    render() {
        const {records, question} = this.props;
        const dataAnalysis = buildDataOnlyDate(question, records);
        let formatStartDate = moment.unix(dataAnalysis.startDate).subtract(2,'days');
        let formatEndDate = moment.unix(dataAnalysis.endDate).add(1, 'days');
        let newStartDate = formatStartDate.format('YYYY/M/D');
        let newEndDate = formatEndDate.format('YYYY/M/D');
        let numberMonthSelected = formatEndDate.endOf('month').diff(formatStartDate.startOf('month'), 'months');

        return (
            <div className={'pt-2'} style={{ maxWidth: numberMonthSelected > 5 ? '100%' : '50%'  }}>
                <CalendarChart dataAnalysis={dataAnalysis.data} startDate={newStartDate} endDate={newEndDate} handleOnClick={() => {}}/>
            </div>
        );
    }
}

export default OnlyDateChart;
