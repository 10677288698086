import React, {Component}    from 'react';
import {connect}             from "react-redux";
import moment                from "moment";
import KirokuCard            from "../../../ui/KirokuCard";
import KirokuLabel           from "../../../ui/KirokuLabel";
import {withNamespaces}      from "react-i18next";
import {dashboardActivities} from "../../../actions/Dashboard";
import {scrollService}       from "../../../services";
import $                     from "jquery";
import {KirokuLoading}       from "../../../ui";
import {Link}                from "react-router-dom";
import config                from '../../../config';
@connect(state => {
    return {
        dashboard: state.KirokuAIDashboard.dashboard,
        activitiesDashboard: state.KirokuAIDashboard.activitiesDashboard,
        currentPage: state.KirokuAIDashboard.activitiesDashboard.currentPage,
        totalPages: state.KirokuAIDashboard.activitiesDashboard.totalPages,
        loading: state.Loading,
    }
}, dispatch => {
    return {
        dashboardActivities: (page) => {
            dispatch(dashboardActivities(page))
        }
    }
})
@withNamespaces()
class LogsDashboardMobile extends Component {

    state = {
        page: 1
    };

    componentWillMount() {
        // const {page} = this.state;
        // this.props.dashboardActivities({page: page});
        scrollService.top();
        window.addEventListener('scroll', this.listener);
    }

    componentWillUnmount () {
        scrollService.top();
        window.removeEventListener('scroll', this.listener);
    }

    listener = () => {
        const { currentPage } = this.props;
        if ((Math.ceil($(window).scrollTop()) >= $(document).height() - ($(window).height() + 100))
            // && this.props.currentPage !== this.props.totalPages
            && this.props.activitiesDashboard.resLogs.length
            && !this.props.loading
            && document.getElementById('page-log-dashboard')) {
                this.props.dashboardActivities({page: currentPage + 1});
        }
    };

    onClickLink = (log) => {
        if (log.action !== 'delete') {
            switch (log.type) {
                case 'surveyRecord' :
                    return this.props.history.push(`/records/record/${log['type_id']}`);
                case 'surveyTemplate' :
                    return this.props.history.push(`/surveys/survey-detail/${log['type_id']}`);
                case 'team' :
                    return this.props.history.push(`/teams/team-detail/${log['type_id']}`);
                case 'customer' :
                    return this.props.history.push(`/customers/customer-detail/${log['type_id']}`);
                default :
                    return this.props.history.push(`/staffs/staff/${log['type_id']}`);
            }
        }
    };


    renderLabel (log) {
        switch (log.action) {
            case "login":
                return (<KirokuLabel color='dark' style={{padding: '2px 4px', margin: 0}}>{this.props.t('common.login')}</KirokuLabel>);
            case "create":
                return (<KirokuLabel color='primary' style={{padding: '2px 4px', margin: 0}}>{this.props.t('common.create')}</KirokuLabel>);
            case "update":
                return (<KirokuLabel color='success' style={{padding: '2px 4px', margin: 0}}>{this.props.t('common.Update')}</KirokuLabel>);
            case "delete":
                return (<KirokuLabel color='danger' style={{padding: '2px 4px', margin: 0}}>{this.props.t('common.Delete')}</KirokuLabel>);
            case "view":
                return (<KirokuLabel color='light' style={{padding: '2px 4px', margin: 0}}>{this.props.t('common.ViewDetail')}</KirokuLabel>);
            default:
        }
    };


    render() {
        const {t, activitiesDashboard} = this.props;
        return (
            <div>
                <h5 className='pt-3 pb-3 pr-3 recent-title'>
                    {/*{t('Dashboard.recordsHistory')}*/}
                </h5>
                <div className={'bg-white'}  id={'page-log-dashboard'}>
                    <KirokuCard>
                        {activitiesDashboard.logs.length ? activitiesDashboard.logs.map((log, index) => {
                                return (
                                    <div key={index}>
                                        <div className='pr-3 pt-2'>
                                            <div className="pr-2 d-flex">
                                                <b className='f-bold'>{t('Dashboard.customerName')}:&nbsp;</b>
                                                <span>
                                                    <Link to={`/customers/customer-detail/${log['customer_id']}`}>{log['customer_name']}</Link>
                                                </span>
                                            </div>
                                            <div>
                                                <b className='f-bold'>{t('Dashboard.titleSurveyTemplate')}:&nbsp;</b>
                                                <span>
                                                    {log['type_name'] ? <span
                                                        className='text-primary' style={{cursor: 'pointer'}} onClick={this.onClickLink.bind(this, log)}
                                                    >{log['type_name']}</span> :''}
                                                </span>
                                            </div>
                                            <div>
                                                <b className='f-bold'>{t('Dashboard.actions')}:&nbsp;</b>
                                                <span>{this.renderLabel(log)}</span>
                                            </div>
                                            <div className={'d-flex'}>
                                                <b className='f-bold'>{t('Dashboard.staffName')}:&nbsp;</b>
                                                <span>
                                                    <Link to={`/staffs/staff/${log['user_id']}`}>{log['staff_name']}</Link>
                                                </span>
                                            </div>
                                            <div>
                                                <b className='f-bold'>{t('Dashboard.date')}:&nbsp;</b>
                                                <span>
                                                    {moment.unix(log.create_at).format(config.formatDateTimeTh)}
                                                </span>
                                            </div>
                                        </div>
                                        <hr className='m-0 p-0'/>
                                    </div>
                                )
                        }): <div className={'text-center'}>
                                    <h5>{t('Dashboard.noActions')}</h5>
                            </div>
                        }
                    </KirokuCard>
                </div>
                <div>
                    {this.props.loading ? <KirokuLoading/> : null}
                </div>
            </div>
        )
    }
}
export default LogsDashboardMobile;

