class TeamService {
    constructor(axios) {
        this.axios = axios;
    }

    loadTeams() {
        return this.axios.get('/teams').then(res => res.data)
    }

    loadTeamsForPage(params) {
        return this.axios.get('/team-for-filter', {
            params: {
                page: params.page || 1,
                name : params.name || ''
            }
        }).then(res => res.data)
    }

    getDetail(id) {
        return this.axios.get(`/team/${id}`).then(res => res.data)
    }

    create(team) {
        return this.axios.post(`/team`, team).then(res => res.data)
    }

    edit(id, team) {
        return this.axios.put(`/team/${id}`, team).then(res => res.data)
    }

    filter(params) {
        return this.axios.get(`/teams`, {params: params}).then(res => res.data)
    }

    getTeamsBeta() {
        return this.axios.get('/teams-beta').then(res => res.data)
    }
}

export default TeamService;
