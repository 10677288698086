import React, {Component} from 'react';
import '../../customers.css';

class FormUploadCustomer extends Component {
	render () {
		return (
			<div className='wrap-upload-customer active'>
				<div className='fix-center pt-3 pb-3'>
					<div className="border-icon-upload active">
						<i className="fas fa-file-upload color-icon-upload-customer"/>
					</div>
					<div className='text-upload-customer'>
						{this.props.children}
					</div>
				</div>
			</div>
		)
	}
}

export default FormUploadCustomer;
