import React, {Component} from 'react';
import './responsive.css';
import './index.css';
import './deleteScreen.css';
import {loginRequired}    from "./middleware-redux/Auth";
import CheckDivide        from "./ui/CheckDivide";
import LayoutWeb          from "./LayoutWeb";
import LayoutMobile       from "./LayoutMobile";

@loginRequired
class App extends Component {
    render() {
        return <CheckDivide web={<LayoutWeb/>}
                            mobile={<LayoutMobile/>}/>
    }
}

export default App;
