import React, {Component} from 'react';
import "../teams.css"
import connect            from "react-redux/es/connect/connect";
import {getDetailTeam}    from "../../../actions/Team";
import _                  from "lodash"
import {KirokuLoading}    from "../../../ui";
import {loadUsers}        from "../../../actions/User";
import TeamUpdateWeb      from "./TeamUpdateWeb";
import {teamService}      from "../../../services";
import {NOTIFICATION}     from "../../../actions/Notification";

@connect(state => {
    return {
        team: state.KirokuAITeam.team,
    }
}, dispatch => {
    return {
        notification: (t) => {
            dispatch({
                type: NOTIFICATION,
                typeNotification: 'success',
                message: t('Team.YouUpdateTeamAreSuccessful')
            })
        },
        getDetailTeam: (idTeam) => {
            dispatch(getDetailTeam(idTeam))
        },
        getUsers: () => {
            dispatch(loadUsers())
        },
        resetState: () => {
            dispatch({
                type: 'DEFAULT_TEAM'
            })
        },
        trackingTeamUpdated: (team) => {
            dispatch({
                type: 'TEAM_UPDATED',
                team
            })
        }
    }
})

class TeamEditorWeb extends Component {

    state = {
        team: [],
        members: []
    };

    componentWillMount() {
        this.props.getDetailTeam(this.props.id);
        this.props.getUsers()
    }

    goBack() {
        this.props.history.goBack()
    }

    editTeam(team) {
        return teamService.edit(this.props.id, team).then(() => {
            this.props.trackingTeamUpdated(team)
            this.props.notification(this.props.t);
            this.props.history.push(`/teams/team-detail/${this.props.id}`)
            // store.dispatch({
            //     type: NOTIFICATION,
            //     typeNotification: 'success',
            //     message: 'Team.YouUpdateTeamAreSuccessful'
            // });
            // store.dispatch({
            //     type: LOAD_TEAM_FOR_PAGE
            // })
        });
    }

    componentWillUnmount() {
        this.props.resetState()
    }

    render() {
        return (
            <div className='default-background-color'>
                {!_.isEmpty(this.props.team) ?
                    <div id='web'>
                        <TeamUpdateWeb
                            team={this.props.team}
                            history={this.props.history}
                            handleSubmit={this.editTeam.bind(this)}
                        />
                    </div>
                    : <KirokuLoading/>}
            </div>
        )
    }
}

export default TeamEditorWeb;