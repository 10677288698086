import React, {Component} from 'react';
import {connect} from "react-redux";
import {comparisonPreviousTerm} from "../../../actions/Dashboard";
import {withNamespaces} from "react-i18next";
import CardTotalWeb from "../web/CardTotalWeb";

@withNamespaces()
@connect(state => {
    return {
        lastMonth: state.KirokuAIDashboard.lastMonth,
        lastWeek: state.KirokuAIDashboard.lastWeek,
        yesterday: state.KirokuAIDashboard.yesterday,
        myCustomers: state.KirokuAIDashboard.myCustomers,
        dashboard: state.KirokuAIDashboard.dashboard,
        loading: state.Loading,
        profile: state.KirokuAIDashboard.profile
    }
}, dispatch => {
    return {
        comparisonPreviousTerm: () => {
            dispatch(comparisonPreviousTerm())
        }
    }
})

class PitOutRecordMobile extends Component {

    componentWillMount() {
        // this.props.comparisonPreviousTerm();
    }

    render() {
        const {t, dashboard} = this.props;
        return (
            <div>
                <h5 className='pt-3 pb-3 pr-3 recent-title'>{this.props.dashboard['organization_name'] + t('Dashboard.pitOutRecord')}</h5>
                <div className='justify-content-between'>
                        <div className='bg-white mt-2' >
                            <CardTotalWeb text={t(`Dashboard.todayRecordNumber`)}
                                          icon={'fas fa-file-signature'}
                                          changeData={dashboard['comparedData'].yesterday}
                            />
                        </div>
                        <div className='bg-white mt-2' >
                            <CardTotalWeb text={t(`Dashboard.lastSevenDayRecordNumber`)}
                                          icon={'fas fa-file-signature'}
                                          changeData={dashboard['comparedData'].lastWeek}
                            />
                        </div>
                        <div className='bg-white mt-2' >
                            <CardTotalWeb text={t(`Dashboard.lastThirtyDayRecordNumber`)}
                                          icon={'fas fa-file-signature'}
                                          changeData={dashboard['comparedData'].lastMonth}
                            />
                        </div>
                </div>
            </div>
        )
    }
}

export default PitOutRecordMobile;