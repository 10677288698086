import {
    KirokuButton, KirokuModal,
    KirokuModalBody, KirokuModalHeader,
}                           from '../../../../../../ui';
import React, { Component } from 'react';
import { connect }          from 'react-redux';
import { withNamespaces }   from 'react-i18next';
import TabSelected          from './TabSelected';
import TabOptionsCustomers  from './TabOptionsCustomers';

@withNamespaces()
@connect(state => {
    return {
        libCustomers              : state.KirokuModal.ModalSelectCustomers.libCustomers,
        isOpenSelected            : state.KirokuModal.ModalSelectCustomers.isOpenSelected,
        libCustomersSelected      : state.KirokuModal.ModalSelectCustomers.libCustomersSelected,
        isOpenModalSelectCustomers: state.KirokuModal.ModalSelectCustomers.isOpenModalSelectCustomers,
    };
}, dispatch => {
    return {
        changeIsOpenModalSelectCustomers     : () => {
            dispatch({
                type: 'CHANGE_IS_OPEN_MODAL_SELECT_CUSTOMERS',
            });
        },
        changeIsOpenSelected                 : (isOpenSelected) => {
            dispatch({
                type: 'CHANGE_IS_OPEN_SELECTED_CUSTOMERS',
                isOpenSelected,
            });
        },
        handleSelectCustomerInTeam           : (teamsSelected) => {
            dispatch({
                type: 'SELECT_CUSTOMERS_IN_TEAMS',
                teamsSelected,
            });
        },
        handleChangeKeyword                  : (keyword) => {
            dispatch({
                type: 'CHANGE_KEYWORD_SEARCH_CUSTOMERS_MODAL',
                keyword,
            });
        },
        handleChangeCheckboxCustomers        : (index) => {
            dispatch({
                type: 'HANDLE_CHANGE_CHECKBOX_CUSTOMERS',
                index,
            });
        },
        handleChangeCheckboxCustomersSelected: (index) => {
            dispatch({
                type: 'HANDLE_CHANGE_CHECKBOX_CUSTOMERS_SELECTED',
                index,
            });
        },
        handleSelectAllCustomersSelected     : () => {
            dispatch({
                type: 'HANDLE_SELECT_ALL_CUSTOMERS_SELECTED',
            });
        },
        handleSelectAllCustomers             : () => {
            dispatch({
                type: 'HANDLE_SELECT_ALL_CUSTOMERS',
            });
        },
        handleResetUserChanged               : () => {
            dispatch({
                type: 'HANDLE_RESET_USER_CHANGED',
            });
        },
        changeTypeSearch                     : (typeSearch) => {
            dispatch({
                type: 'CHANGE_TYPE_SEARCH',
                typeSearch,
            });
        },
        setStatusOldModalSelect              : () => {
            dispatch({
                type: 'CANCEL_SELECT_CUSTOMER',
            });
        },
        confirmCustomersCustom               : () => {
            dispatch({
                type: 'CONFIRM_MODAL_CUSTOMERS',
            });
        },
    };
})
class ModalSelectCustomers extends Component {


    handleConfirmListCustomCustomer() {
        this.props.confirmCustomersCustom();
        this.props.changeTypeSearch('SEARCH_CUSTOM_CUSTOMER');
        this.props.changeIsOpenModalSelectCustomers();
    }

    render() {
        const {
                  isOpenModalSelectCustomers, isOpenSelected, t,
                  // libCustomers, libCustomersSelected,
              }                   = this.props;
        // const countCustomerSelect = isOpenSelected ? libCustomersSelected.filter(ctm => ctm.selected).length : libCustomers.filter(ctm => !!ctm.selected).length;
        return (
            <div className="modal-select-record-beta">
                <KirokuModal show={ isOpenModalSelectCustomers }
                             onCloseModal={ () => this.props.changeIsOpenModalSelectCustomers() }>
                    <KirokuModalHeader>
                        <div className={ 'text-center font-header-on-modal' }> { t('RecordsBeta.customerSelection') }</div>
                    </KirokuModalHeader>

                    <KirokuModalBody style={ { backgroundColor: '#eef3f6', padding: '17px 5px 1px 5px' } }>
                        { isOpenSelected ? <TabSelected/> : <TabOptionsCustomers/> }
                    </KirokuModalBody>
                    <div style={ { backgroundColor: '#101F41', padding: '2em' } }
                         className="d-flex justify-content-around">
                        <KirokuButton onClick={ () => {
                            this.props.setStatusOldModalSelect();
                            this.props.changeIsOpenModalSelectCustomers();
                        } }
                                      className={ 'btn-cancel-on-modal' }
                        >
                            { t('RecordsBeta.cancel') }
                        </KirokuButton>
                        <div className="align-self-center" style={ { color: '#FFF' } }>
                            {/*{ countCustomerSelect ? t('RecordsBeta.isSelected', { count: countCustomerSelect }) : t('RecordsBeta.notSelected') } */}
                        </div>
                        <KirokuButton className={'btn-confirm-on-modal'}
                                      onClick={ this.handleConfirmListCustomCustomer.bind(this) }
                        >
                            { t('RecordsBeta.confirm') }
                        </KirokuButton>
                    </div>
                </KirokuModal>
            </div>
        );
    }
}

export default ModalSelectCustomers;
