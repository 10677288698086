import {
    ASSIGN_SURVEYS, DEFAULT_CUSTOMER, DISCARD_DRAFT, FILTER_BY_CONDITION_PAGE_DETAIL,
    FILTER_CUSTOMER_WITH_CONDITION, GET_CUSTOMERS_BETA,
    LOAD_CUSTOMER_ALL,
    LOAD_CUSTOMER_BY_ID,
    LOAD_CUSTOMER_EDITED,
    LOAD_CUSTOMER_FOR_SURVEY,
    LOAD_CUSTOMER_LIST_PAGE, LOAD_DRAFT_CUSTOMER, PIN_CUSTOMER,
    RESET_CUSTOMER,
    SEARCH_CUSTOMER,
    UPDATE_CUSTOMER,
} from '../actions/Customer';

import {
    REMOVE_DOCUMENT, SELECT_ALL_DOCUMENT, SELECT_DOCUMENT, SET_PRIVATE_DOCUMENT, ASSIGN_USERS,
} from '../actions/Document';

const defaultApplicationSate = {
    title                    : 'Kiroku',
    draftCustomer            : '',
    surveys                  : [],
    profile                  : JSON.parse(localStorage.getItem('profile')),
    token                    : localStorage.getItem('token'),
    customers                : [],
    customerAll              : [],
    customer                 : {},
    customerForSurvey        : [],
    customerForSurveyDefault : [],
    isDraftLoading           : false,
    isLoadingCustomer        : false,
    loadingCustomerList      : false,
    ListAllCustomer          : [],
    documents                : {},
    loadingUpload            : false,
    loadingResultDocuments   : false,
    loadingDocumentSuccess   : false,
    flag                     : false,
    loadingAllCustomer       : false,
    defaultTags              : [],
    loading                  : false,
    isFilter                 : false,
    customerForPin           : [],
    loadingCustomerPinSuccess: false,
    defaultUserTags          : [],
    defaultTeamTags          : [],
    customersBeta            : [],
};

const KirokuAICustomer = (state = defaultApplicationSate, action) => {
          switch (action.type) {
              case GET_CUSTOMERS_BETA:
                  return {
                      ...state,
                      customersBeta: action.customers,
                  };
              case FILTER_BY_CONDITION_PAGE_DETAIL:
                  return {
                      ...state,
                      loadingResultDocuments: true,
                      loadingDocumentSuccess: true,
                      flag                  : false,
                      documents             : action.documents,
                  };
              case PIN_CUSTOMER:
                  return {
                      ...state,
                      loadingCustomerPinSuccess: false,
                  };
              case 'GET_PIN_CUSTOMER':
                  return {
                      ...state,
                      customerForPin           : action.customerForPin.map(item => ({ ...item, hoverStar: !item.pinned_at })),
                      loadingCustomerPinSuccess: true,
                  };
              case 'HOVER_START_OUT' :
                  let customerPinRecord                       = [...state.customerForPin];
                  customerPinRecord[ action.index ].hoverStar = action.status;
                  return {
                      ...state,
                      customerForPin: customerPinRecord,
                  };
              case DEFAULT_CUSTOMER :
                  return {
                      ...state,
                      isLoadingCustomer     : false,
                      loadingDocumentSuccess: false,
                  };
              case LOAD_CUSTOMER_BY_ID:
                  return {
                      ...state,
                      customer         : action.customer,
                      isLoadingCustomer: true,
                  };
              case LOAD_CUSTOMER_ALL :
                  return {
                      ...state,
                      ListAllCustomer   : action.listAllCustomer,
                      loadingAllCustomer: true,
                      updated           : true,
                  };
              case 'UPDATED_CUSTOMER_SUCCESS' :
                  return {
                      ...state,
                      updated: false,
                  };
              case LOAD_CUSTOMER_LIST_PAGE :
                  return {
                      ...state,
                      defaultCustomers   : [...action.customers],
                      customers          : action.customers,
                      totalPages         : action.totalPages,
                      currentPage        : action.currentPage,
                      isDraftLoading     : false,
                      loadingCustomerList: true,
                  };

              case RESET_CUSTOMER :
                  return {
                      ...state,
                      customers: [...state.defaultCustomers],
                  };

              case LOAD_CUSTOMER_EDITED :
                  return {
                      ...state,
                      customer         : action.customer,
                      isLoadingCustomer: false,
                  };
              case 'CUSTOMER_NEW' :
                  let newCustomers = state.customers.filter(item => !action.id.includes(item.id));
                  return {
                      ...state,
                      defaultCustomers: newCustomers,
                      customers       : newCustomers,
                  };

              case LOAD_CUSTOMER_FOR_SURVEY :

                  return {
                      ...state,
                      customers        : action.customers,
                      customerForSurvey: action.customers.map(item => ({ ...item })),
                  };

              case SEARCH_CUSTOMER :
                  let keywords  = action.keywords;
                  let customers = [...state.defaultCustomers].filter(customer =>
                      customer[ 'name' ].toLowerCase().search(keywords.toLowerCase()) !== -1,
                  );
                  return {
                      ...state,
                      customers: customers,
                  };
              case FILTER_CUSTOMER_WITH_CONDITION :
                  // let profile = JSON.parse(localStorage.getItem('profile'));
                  //       let defaultTags = [{
                  //           ...profile,
                  //           tagId: profile.id,
                  //           key: `user_id${profile.id}`,
                  //           label: profile.name,
                  //           value: profile.name,
                  //           tagType: 'users'
                  //       }];
                  return {
                      ...state,
                      customers      : action.customers,
                      totalPages     : action.totalPages,
                      currentPage    : action.currentPage || 1,
                      defaultTags    : action.tags,
                      defaultTeamTags: action.tags.tags.filter(tag => tag.type === 'Teams'),
                      defaultUserTags: action.tags.tags.filter(tag => {
                          return tag.type === 'Users' || tag.tagType === 'users';
                      }),
                      loading        : true,
                      isFilter       : true,
                  };
              case 'UPDATE_CUSTOMER_SUCCESS' :
                  return {
                      ...state,
                      isFilter: false,
                  };
              case UPDATE_CUSTOMER:
                  return {
                      ...state,
                      customer: action.customer,
                  };
              case ASSIGN_SURVEYS :

                  let certificate     = action.customer.certificate.map(item => ({
                      key  : item,
                      label: item,
                      value: item,
                  }));
                  let currentCustomer = { ...action.customer, certificate };
                  return {
                      ...state,
                      customer: currentCustomer,
                  };
              case LOAD_DRAFT_CUSTOMER :
                  return {
                      ...state,
                      draftCustomer : action.dataDraft,
                      isDraftLoading: true,

                  };
              case 'LOAD_DRAFT' :
                  return {
                      ...state,
                      draftCustomer: state.draftCustomer,
                  };

              case DISCARD_DRAFT :
                  return {
                      ...state,
                      isDraftLoading: true,
                      draftCustomer : '',
                  };
              case 'RESET_STATE' :
                  return {
                      ...defaultApplicationSate,
                      defaultTeamTags: state.defaultTeamTags,
                      defaultUserTags: state.defaultUserTags,
                  };


              case 'RESET_STATE_RESULT_DETAIL_CUSTOMER':
                  return {
                      ...state,
                      loadingResultDocuments: false,
                  };
              case 'LOAD_DOCUMENT' :
                  return {
                      ...state,
                      loadingResultDocuments: true,
                      loadingDocumentSuccess: true,
                      flag                  : true,
                      documents             : {
                          ...action.documents,
                      },
                  };
              case SELECT_DOCUMENT:
                  let rawDocument             = state.documents.documents;
                  rawDocument[ action.index ] = {
                      ...rawDocument[ action.index ],
                      isChecked: !rawDocument[ action.index ].isChecked,
                  };
                  return {
                      ...state,
                      documents: {
                          ...state.documents,
                          documents: rawDocument,
                      },
                  };
              case SELECT_ALL_DOCUMENT :
                  let checkAllDocument = state.documents.documents;
                  let raw              = checkAllDocument.map(documents => {
                      return {
                          ...documents,
                          isChecked: action.checked,
                      };
                  });
                  return {
                      ...state,
                      documents: {
                          ...state.documents,
                          documents: raw,
                      },
                  };
              case REMOVE_DOCUMENT :
                  return {
                      ...state,
                      documents: {
                          ...action.documents,
                      },
                  };
              case SET_PRIVATE_DOCUMENT:
                  return {
                      ...state,
                      documents: {
                          ...action.documents,
                      },
                  };
              case ASSIGN_USERS:
                  return {
                      ...state,
                      documents: {
                          ...action.documents,
                      },
                  };
              default:
                  return state;
          }
      }
;

export default KirokuAICustomer;
