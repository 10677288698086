import React, {Component}                                                      from 'react';
import {withNamespaces}                                                        from "react-i18next";
import '../staff.css'
import {KirokuInput}                                                           from "../../../ui";
import KirokuDropdownClose                                                     from "../../../ui/KirokuDropdownClose";
import KirokuMultiSelect                                                       from "../../../ui/KirokuMultiSelect";
import KirokuButton                                                            from "../../../ui/KirokuButton";
import {uploadService, userFactory, userService, filePathService, authService} from "../../../services";
import connect                       from "react-redux/es/connect/connect";
import {NOTIFICATION}                from "../../../actions/Notification";
import jwt                           from "jsonwebtoken";
import config                        from "../../../config";
import {reloadAvatar}                from "../../../actions/ActionLayout";
import {setRole}                     from "../../../actions/User";
import KirokuSectionDropdown         from "../../../ui/KirokuSectionDropdown";

@withNamespaces()
@connect((state) => {
    return {
        isAdmin: state.KirokuAIUser.profile
    }
}, (dispatch) => {
    return {
        notification: (t) => {
            dispatch({
                type: NOTIFICATION,
                typeNotification: 'success',
                message: t('message.UpdateSuccess')
            })
        },
        reloadAvatarMain: () => {
            dispatch(reloadAvatar())
        },
        setRole: (role, userId) => {
            dispatch(setRole(role, userId))
        },
        trackingUpdateStaff : (staff) => {
            dispatch({
                type: "STAFF_UPDATED",
                staff
            })
        },
    }
})
class TabProfile extends Component {

    state = {
        currentQualification: [],
        currentTeam: [],
        optionTeam: [],
        errorProfile: {},
        errorStaffNumber: false,
        profile: {
            staff_number : ''
        },
        role: '',
        groupedOptionCustomers: [],
        currentCustomerTags: [],
        groupOptionTeams : [],
        currentTeamTags : [],
        defaultOption: [],
        isEdit: false,
        loading: false,
    };

    componentWillMount() {
        let qualification = this.props.profile.qualification.map(trans => {
            return {
                key: this.props.t(`common.dropdown.default.${trans}`),
                label: this.props.t(`common.dropdown.${trans}`),
                value: this.props.t(`common.dropdown.${trans}`)
            }
        });

        let teams     = this.props.profile.teams.map(team => {
            return {
                tagType: "team",
                tagContent: {
                    ...team,
                },
                key: team.id,
                label: team.name,
                value: `${team.name}${team.id}`,
                type: 'Teams'
            }
        });
        let customers = this.props.profile.customers.map(customer => {
            return {
                tagType: "customer",
                tagContent: {
                    ...customer,
                },
                key: customer.id,
                label: customer.name,
                value: `${customer.name}${customer.id}`,
                type: 'Customer'
            }
        });

        let optionTeam = this.props.teams.map(team => {
            return {
                tagType: "team",
                tagContent: {
                    ...team,
                },
                key: team.id,
                value: `${team.name}${team.id}`,
                label: team.name,
                type: 'Teams'
            }
        });

        let optionCustomers = this.props.customers.map((customer) => {
            return {
                tagType: "customer",
                tagContent: {
                    ...customer,
                },
                key: customer.id,
                value: `${customer.name}${customer.id}`,
                label: customer.name,
                type: 'Customers'
            }
        });

        this.setState({
            currentQualification: qualification,
            currentCustomerTags: customers,
            currentTeamTags : teams,
            profile: {
                ...this.state.profile,
                ...this.props.profile
            },
            groupedOptionCustomers: [
                {
                    label: 'Customers',
                    options: optionCustomers,
                },
            ],
            groupOptionTeams : [
                {
                    label: 'Teams',
                    options: optionTeam,
                }
            ]
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            profile: {
                ...nextProps.profile,
                ...this.state.profile
            }
        });
    }

    workingForm = [
        {
            value: 'Full_time',
            component: (<div>{this.props.t('common.Full_time')}</div>),
            label: this.props.t('common.Full_time')
        },
        {
            value: 'Part_time',
            component: (<div>{this.props.t('common.Part_time')}</div>),
            label: this.props.t('common.Part_time')
        },
    ];
    selectRole  = [
        {
            value: 'staff',
            component: (<div>{this.props.t('common.Staff')}</div>),
            label: this.props.t('common.Staff')
        },
        {
            value: 'leader',
            component: (<div>{this.props.t('role.leader')}</div>),
            label: this.props.t('role.leader')
        },
    ];

    KindOfQualification = [
        {
            key: this.props.t('common.dropdown.default.Social_worker'),
            label: this.props.t('common.dropdown.Social_worker'),
            value: this.props.t('common.dropdown.Social_worker'),
        },
        {
            key: this.props.t('common.dropdown.default.Psychiatric_Sosial_Worker'),
            value: this.props.t('common.dropdown.Psychiatric_Sosial_Worker'),
            label: this.props.t('common.dropdown.Psychiatric_Sosial_Worker'),
        },
        {
            key: this.props.t('common.dropdown.default.Care_Worker'),
            label: this.props.t('common.dropdown.Care_Worker'),
            value: this.props.t('common.dropdown.Care_Worker'),
        },
        {
            key: this.props.t('common.dropdown.default.Nurse'),
            label: this.props.t('common.dropdown.Nurse'),
            value: this.props.t('common.dropdown.Nurse'),
        },
        {
            key: this.props.t('common.dropdown.default.Childminder'),
            label: this.props.t('common.dropdown.Childminder'),
            value: this.props.t('common.dropdown.Childminder'),
        },
        {
            key: this.props.t('common.dropdown.default.Occupational_therapist'),
            label: this.props.t('common.dropdown.Occupational_therapist'),
            value: this.props.t('common.dropdown.Occupational_therapist'),
        },
        {
            key: this.props.t('common.dropdown.default.Psychotherapist'),
            label: this.props.t('common.dropdown.Psychotherapist'),
            value: this.props.t('common.dropdown.Psychotherapist'),
        },
        {
            key: this.props.t('common.dropdown.default.Doctor'),
            label: this.props.t('common.dropdown.Doctor'),
            value: this.props.t('common.dropdown.Doctor'),
        },

    ];

    currentQualification(qualification) {
        let currentQualification = qualification.map(item => item.key);
        this.handleChangeProfile(currentQualification, 'qualification')
    }

    handleChangeQualification = (options) => {
        this.setState({currentQualification: options ? options : this.state.defaultOption}, () => {
            this.currentQualification(this.state.currentQualification)
        });
    };

    handleChangeInput(e) {
        this.handleChangeProfile(e.target.value, e.target.name)
    }

    handleChangeProfile(value, name) {
        this.setState({
            profile: {
                ...this.state.profile,
                [name]: value,
            },
            errorProfile: {
                ...this.state.errorProfile,
                [name]: !value.length

            }
        })
    }

    conditionUpload(file) {
        return (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') && file.size < 10485760;
    }

    handleChangeImage() {
        this.setState({
            loadingImg: !this.state.loadingImg && this.inputElement.files[0]
        });
        if (this.inputElement.files[0] && this.conditionUpload(this.inputElement.files[0])) {
            let image = new FormData();
            image.append('file', this.inputElement.files[0]);
            image.append('name', this.inputElement.files[0].name);
            image.append('description', 'some value user types');
            uploadService.uploadImage(image, 'avatar').then((res) => {
                this.handleChangeProfile(res.url, 'avatar')
            });
        }
    };

    goBack() {
        this.props.history.goBack()
    }

    handleSubmitUser() {
        let user = userFactory.makeUser(this.state.profile, this.state.isEdit);
        this.setState({
            loading: true,
        })
        userService.edit(user.id, user).then((res) => {
            this.setState({
                loading: false,
            })
            this.props.trackingUpdateStaff(user);
            if (`${this.props.isAdmin.id}` === `${this.props.id}`) {
                let profile = jwt.verify(res.data.token, config.auth.authPrivateKey);
                authService.setToken(res.data.token, profile);
                this.props.reloadAvatarMain();
                this.props.notification(this.props.t);
                this.props.history.push(`/my-profile`)
            } else {
                this.props.reloadAvatarMain();
                this.props.notification(this.props.t);
                this.props.history.push(`/staffs/staff/${res.data.id}`);
            }

        });
    }

    roleChoose(role) {
        this.handleChangeProfile(role, 'role');
    }

    selectWorkingForm(workingForm) {
        this.handleChangeProfile(workingForm, 'working_form')
    }

    isDisabled() {
        const {name} = this.state.errorProfile;
        return !Object.values({name}).every(item => !item) || this.state.loading
    }

    handleChangeTags = (options) => {
        this.setState({
            currentCustomerTags: options ? options : this.state.defaultOption,
            isEdit: true,
        }, () => {
            this.currentTags(this.state.currentCustomerTags.concat(this.state.currentTeamTags))
        });
    };

    handleChangeTagsTeam = (options) => {
        this.setState({
            currentTeamTags : options ? options : this.state.defaultOption,
            isEdit : true
        }, () => {
            this.currentTags(this.state.currentTeamTags.concat(this.state.currentCustomerTags))
        })
    };

    currentTags(tags){
        this.handleChangeProfile(tags, 'tags');
    }


    handleChangeStaffNumber = (e) => {
        let regex  = e.target.value.replace(/[^0-9]/g, '');
        const profile = this.state.profile;
        if (regex.length <= 5) {
            profile[e.target.name] = regex;
            this.setState({
                profile: {
                    ...this.state.profile,
                    staff_number: regex
                }
            });
        }
    };

    checkValidationStaffNumber = (e) => {
        const error = this.checkErrorStaffNumber(e.currentTarget.value);
        this.setState({
                errorStaffNumber: error
        });
    };

    checkErrorStaffNumber = (number) => {
        if(number.length < 5 && number.length > 0) {
            this.setState({errorStaffNumber: true});
            return 'ErrorStaffNumber';
        }
        return '';
    };

    render() {
        let role      = JSON.parse(localStorage.getItem('profile'));
        let {t}       = this.props;
        let {   profile,
            // errorStaffNumber,
                currentCustomerTags,
                groupedOptionCustomers,
                currentTeamTags,
                groupOptionTeams
        } = this.state;
        return (
            <div hidden={this.props.show} className='pt-10'>
                <div className='row'>
                    <div className="col-md-8">
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='mb-10'>
                                    <span className='customer-info f-bold'>{t('common.Name')}:</span>
                                    <br/>
                                    <KirokuInput
                                        name='name'
                                        onChange={this.handleChangeInput.bind(this)}
                                        value={profile.name}
                                    />
                                    <p hidden={!this.state.errorProfile.name}
                                       style={{
                                           color: 'red',
                                           fontSize: '13px'
                                       }}>
                                        {t('errors.nameMustNotNull')}
                                    </p>
                                    {/*<span className='personal-info'>{profile.name}</span>*/}
                                </div>
                                <div className='mb-10'>
                                    <span className='customer-info f-bold'>{t('staff.title')}:</span>
                                    <br/>
                                    <span className='personal-info'>
                                        <KirokuInput
                                            name='title'
                                            onChange={this.handleChangeInput.bind(this)}
                                            value={profile.title}/>
                                    </span>
                                </div>
                                {/*<div className='mb-10'>*/}
                                {/*    <span className='customer-info f-bold'>{t('common.StaffNumber')}:</span>*/}
                                {/*    <br/>*/}

                                {/*    <span className='personal-info'>*/}
                                {/*        <KirokuInput*/}
                                {/*            name='staff_number'*/}
                                {/*            disabled={!authService.isAdmin()}*/}
                                {/*            placeholder={t('common.StaffNumber')}*/}
                                {/*            onChange={this.handleChangeStaffNumber.bind(this)}*/}
                                {/*            onBlur={this.checkValidationStaffNumber.bind(this)}*/}
                                {/*            error={errorStaffNumber ? 'form-error' : ''}*/}
                                {/*            value={profile.staff_number || ''}/>*/}
                                {/*            <p hidden={!errorStaffNumber} style={{*/}
                                {/*                color: 'red',*/}
                                {/*                fontSize: 12*/}
                                {/*            }}>*/}
                                {/*                 {t(`CustomerEditor.validate.${errorStaffNumber}`)}*/}
                                {/*            </p>*/}

                                {/*    </span>*/}
                                {/*</div>*/}
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-10'>
                                    <span className='customer-info f-bold'>{t('common.WorkingForm')}:</span>
                                    <br/>
                                    <KirokuDropdownClose
                                        title={profile['working_form'] ? t(`common.${profile['working_form']}`) : t(`common.WorkingForm`)}
                                        items={this.workingForm}
                                        onSelect={event => {
                                            this.selectWorkingForm(event.kirokuSelected.value);
                                        }}
                                    />
                                    {/*<span className='personal-info'>{t(`common.${profile['working_form']}`)}</span>*/}
                                </div>
                                <div className='mb-10'
                                     hidden={this.props.isAdmin.id === this.state.profile.id}>
                                    <span className='customer-info f-bold'>{t('common.Role')}:</span>
                                    <br/>
                                    <KirokuDropdownClose
                                        title={profile.role ? t(`common.${profile.role}`) : t(`common.Role`)}
                                        items={this.selectRole}
                                        onSelect={event => {
                                            this.roleChoose(event.kirokuSelected.value);
                                        }}
                                    />
                                    {/*<span className='personal-info'>{t(`common.${profile['working_form']}`)}</span>*/}
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='mb-10'>
                                    <span className='customer-info f-bold'>{t('common.Qualification')}:</span>
                                    <br/>
                                    <KirokuMultiSelect
                                        onChange={this.handleChangeQualification.bind(this)}
                                        value={this.state.currentQualification}
                                        options={this.KindOfQualification}
                                    />
                                </div>
                            </div>
                        </div>
                        <div hidden={this.props.profile.id === role.id}>
                            <span className='customer-info f-bold'>{t('common.Customer')}:</span>
                            <br/>
                            {/*<KirokuMultiSelect*/}
                            {/*onChange={this.handleChangeTeam.bind(this)}*/}
                            {/*value={this.state.currentTeam}*/}
                            {/*options={this.state.optionTeam}*/}
                            {/*/>*/}
                            {/*<br/>*/}
                            <KirokuSectionDropdown
                                onChange={this.handleChangeTags.bind(this)}
                                value={currentCustomerTags}
                                options={groupedOptionCustomers}
                            />
                        </div>
                        <div hidden={this.props.profile.id === role.id} className='pt-1'>
                            <span className='customer-info f-bold'>{t('common.Team')}:</span>
                            <br/>
                            <KirokuSectionDropdown
                                onChange={this.handleChangeTagsTeam.bind(this)}
                                value={currentTeamTags}
                                options={groupOptionTeams}
                            />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className='staff-detail avatar-user-detail as'
                             style={{
                                 backgroundImage: `url(${profile.avatar ? profile.avatar : filePathService.resolveFilePath(`profile-ic.svg`)})`,
                             }}
                        />
                        <div className='mt-24'>
                            <div className='text-center'>
                                <KirokuButton color={'success'} onClick={() => this.inputElement.click()}>
                                    {t('CustomerEditor.ChangeAvatar')}
                                </KirokuButton>
                                <input type='file' ref={input => this.inputElement = input} hidden
                                       accept="image/jpeg, image/png"
                                       onChange={this.handleChangeImage.bind(this)}
                                />
                            </div>
                        </div>
                        <div className='content-avatar text-center pt-3'>
                            <span>{t('Customer.SupportedFile')} </span>
                            <br/>
                            <span>{t('Customer.Maximum')}</span>
                        </div>
                    </div>
                </div>
                <div className='pt-3'>
                    <span>
                        <KirokuButton onClick={this.goBack.bind(this)} color='white'>
                            {t('common.Cancel')}
                        </KirokuButton>
                    </span>
                    <span className='ml-20'>
                        <KirokuButton disabled={this.isDisabled()}
                                      onClick={this.handleSubmitUser.bind(this)}
                                      color='primary'>
                            {t('common.Save')}
                        </KirokuButton>
                    </span>
                </div>
                {/* <div>
                    <h5>{t('common.Line account information')}</h5>
                    <p>You have not synchronized your account with Line.</p>
                    <KirokuButton color='success'>Sync with LINE</KirokuButton>
                </div>*/}

            </div>
        );
    }
}

export default TabProfile;
