import React, { Component } from 'react';
import { withNamespaces }   from 'react-i18next';
import { Link }             from 'react-router-dom';
import moment               from 'moment';
import Datetime             from 'react-datetime';
import config               from '../../../config';
import { withUserAgent }    from 'react-useragent';

@withUserAgent
@withNamespaces()

class AboutSurvey extends Component {
    state = {
        show          : false,
        servicedAtTrue: false,
        profile: JSON.parse(localStorage.getItem('profile'))
    };


    getServiceAt() {
        const { screen, serviced_at, create_at } = this.props;
        switch (screen) {
            case 'editRecord':
            case 'detailRecord':
                return serviced_at ? moment.unix(serviced_at) : moment.unix(create_at);
            default:
                return moment();
        }

    }

    componentWillMount() {
        this.setState({
            dateTime          : this.getServiceAt(),
            dateTimeLastChange: this.getServiceAt(),
            hasTime           : this.props.hasTime,
            hasTimeLastChange : this.props.hasTime,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.serviced_at !== prevProps.serviced_at || this.props.hasTime !== prevProps.hasTime) {
            this.setState({
                dateTime          : this.getServiceAt(),
                dateTimeLastChange: this.getServiceAt(),
                hasTime           : this.props.hasTime,
                hasTimeLastChange : this.props.hasTime,
            });
        }
    }

    showModalSelectDateTime() {
        this.setState({
            show: !this.state.show,
        });
    }

    handleOnChangeDateTime(datetime) {
        this.setState({
            dateTime: datetime,
        });
    }

    hiddenInputDateTime() {
        this.setState({
                show          : false,
                servicedAtTrue: false,
                hasTime       : this.state.hasTimeLastChange,
                dateTime      : this.state.dateTimeLastChange,
            },
            () => {
                this.props.onChangeDate(this.props.serviced_at ? this.props.serviced_at : null, this.props.hasTime);
            },
        );
    }

    onSelectValue() {
        this.setState({
            show              : false,
            servicedAtTrue    : true,
            dateTime          : this.state.dateTime,
            dateTimeLastChange: this.state.dateTime,
            hasTime           : this.state.hasTime,
            hasTimeLastChange : this.state.hasTime,
        }, () => {
            this.props.onChangeDate(moment(this.state.dateTime).unix(), this.state.hasTime);
        });
    }

    handlerButtonTime() {
        this.setState({
            hasTime: !this.state.hasTime,
        });
    }

    render() {
        const { survey, customer, hidden, t, user, DetailRecord, isRecord, lockInfo, editor, ua } = this.props;
        const { dateTime, hasTime, profile }                                                            = this.state;

        function getTime(dateTime, hasTime) {
            if (dateTime) {
                if (!hasTime) {
                    return <span className='question-number'>{moment(dateTime).format(config.formatDateTh)}</span>
                }
                return <span className='question-number'>{moment(dateTime).format(config.formatDateTimeTh)}</span>
            } else {
                return <span className='question-number'>{moment().format(config.formatDateTimeTh)}</span>
            }

        }

        let AddTime = hasTime ? 'H:mm' : false;

        return (
            <div className='content-question' hidden={hidden}>
                <div className='wraper-question'>
                    <div className='wraper-question'>
                        <div className="background-question-review">
                            <div className='info-question' id='create-record-fix'>
                                <div className='number-question'>
                                    <span className="question-number">{survey.title} </span>
                                </div>
                                {customer ?
                                    <div className='content-heading-question d-flex'>
                                        <span className='title-record-info'>{t('createRecord.Customer')}&nbsp;</span>
                                        {DetailRecord ?
                                            <Link to={`/customers/customer-detail/${customer.id}`}>
                                                {customer.name}
                                            </Link> : customer.name}

                                    </div>
                                    : ''}
                                <div className='content-heading-question d-flex'>
                                    <span className='title-record-info'>{t('createRecord.Staff')}&nbsp;</span>
                                    {user ?
                                        DetailRecord ?
                                            <Link to={`/staffs/staff/${user.id}`}>{user.name}</Link> : user.name
                                        : DetailRecord ?
                                            <Link
                                                to={`/staffs/staff/${profile.id}`}>{profile.name}</Link> : profile.name
                                    }
                                </div>
                                {!isRecord ? (
                                    <div className='content-name-question d-flex'>
                                        {/*<span className='title-record-info'>{t('createSurvey.FormNumber')}:&nbsp;</span>*/}
                                        {/*<span>{survey.form_number}</span>*/}
                                    </div>
                                ) : ''}

                                <div hidden={this.props.shouldNotShowServicedAt}>
                                    <div className='content-name-question d-flex' style={{ flexWrap: 'wrap' }}>

                                        <div style={{ position: 'relative', margin: 'auto 0' }}
                                             className='title-record-info'>{t('createRecord.ServicedAt')}:&nbsp;</div>
                                        <div hidden={this.state.show}>
                                            {/*{this.state.dateTime ? moment(this.state.dateTime).format(config.formatDateTimeTh) : moment().format(config.formatDateTimeTh)}*/
                                                getTime(dateTime, hasTime)
                                            }
                                            <span hidden={this.props.canNotEditServicedAt || (editor && !(profile.id === lockInfo['user_id']))}
                                                  onClick={this.showModalSelectDateTime.bind(this)}
                                                  style={{
                                                      color   : 'blue',
                                                      cursor  : 'pointer',
                                                      position: 'relative',
                                                      margin  : 'auto 0',
                                                  }} className='ml-2'>
                                                        <i className='fa fa-pencil'/>
                                                &nbsp;{t('createRecord.Edit')}
                                             </span>
                                        </div>
                                        <div hidden={!this.state.show}>
                                            <div className='d-flex' style={{ flexWrap: 'no-wrap' }}>
                                                <Datetime
                                                    className='serviced-at'
                                                    timeFormat={AddTime}
                                                    closeOnSelect={!ua.mobile}
                                                    value={dateTime}
                                                    dateFormat={'YYYY/MM/DD'}
                                                    onChange={this.handleOnChangeDateTime.bind(this)}
                                                    locale={t('createRecord.Date')}
                                                    inputProps={{ readOnly: true }}
                                                    monthYearLocale={localStorage.getItem('i18nextLng')}
                                                />
                                                <span onClick={this.onSelectValue.bind(this)}
                                                      style={{
                                                          color   : 'green',
                                                          cursor  : 'pointer',
                                                          position: 'relative',
                                                          margin  : 'auto 0',
                                                      }} className='ml-2'>
                                                <i className="fas fa-2x fa-check-circle"/>
                                            </span>
                                                <span onClick={this.hiddenInputDateTime.bind(this)}
                                                      style={{
                                                          color   : 'red',
                                                          cursor  : 'pointer',
                                                          position: 'relative',
                                                          margin  : 'auto 0',
                                                      }} className='ml-2'>
                                            <i className="fas fa-2x fa-times-circle"/>
                                            </span>
                                                <span className='ml-2'
                                                      style={{
                                                          color   : 'blue',
                                                          cursor  : 'pointer',
                                                          position: 'relative',
                                                          margin  : 'auto 0',
                                                      }}
                                                      onClick={this.handlerButtonTime.bind(this)}>
                                                    {hasTime ? t('createRecord.RemoveTime') : t('createRecord.AddTime')}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {editor && !(profile.id === lockInfo[ 'user_id' ]) && (
                                    <div className='content-name-question d-flex' style={{flexWrap: 'wrap' }}>
                                        <span style={{ wordBreak: 'keep-all'}}
                                              className='title-record-info'>{t('common.editing', {user: lockInfo[ 'user_name' ]})} &nbsp;
                                            <span id="wave">
                                                <span className="dot"/>
                                                <span className="dot"/>
                                                <span className="dot"/>
                                            </span>
                                        </span>
                                    </div>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutSurvey;
