import React, {Component} from 'react'
import {withNamespaces}   from "react-i18next";
import KirokuModal        from "../../../../ui/KirokuModal";
import KirokuModalBody    from "../../../../ui/KirokuModalBody";
import KirokuModalFooter  from "../../../../ui/KirokuModalFooter";
import KirokuButton       from "../../../../ui/KirokuButton";
import {userService}      from "../../../../services";
import connect            from "react-redux/es/connect/connect";
import {NOTIFICATION}     from "../../../../actions/Notification";

@withNamespaces()
@connect(() => {
    return {
        //profile: state.KirokuAIUser.profile
    }
}, (dispatch) => {
    return {
        notification: (t) => {
            dispatch({
                type: NOTIFICATION,
                typeNotification: 'success',
                message: t('message.UpdateSuccess')
            })
        }
    }
})
class ModalDeleteStaff extends Component {
    state = {
        defaultPage: 1
    };

    closeModal() {
        this.props.closeModalDeleteUser()
    }

    deleteCustomer() {
        userService.deleteUsers(this.props.users.id).then(() => {
            this.props.loadUser(this.state.defaultPage);
            this.props.closeModalDeleteUser();
            this.props.notification(this.props.t);
        });
    }

    render() {
        let {t} = this.props;
        return (
            <KirokuModal show={this.props.show} onClick={this.closeModal.bind(this)}>
                <KirokuModalBody>
                    <p className='text-center pt-10'>
                        {t('staff.messageDelete')}
                    </p>
                    <p className='text-center'>
                        {this.props.users.name}
                    </p>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                        {t('common.Cancel')}
                    </KirokuButton>
                    <KirokuButton onClick={this.deleteCustomer.bind(this)}
                                  color={'primary'}>
                        {t('common.Confirm')}
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>

        )
    }

}

export default ModalDeleteStaff;