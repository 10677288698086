import React, {Component}     from 'react';
import ModalAddLogicGeneral   from "../ModalAddLogicGeneral";
import BehaviorQuestionMobile    from "./BehaviorQuestionMobile";
import {withNamespaces}          from "react-i18next";
import ActionQuestions           from "./ActionsQuestions";
import {checkUseHeadingQuestion} from "../../SurveyEditor/SurveyService";
import ComponentUnrequited       from '../../ReviewSurvey/ComponentUnrequited';
import {
    KirokuPopoverItem, KirokuLogicApplicated,
    KirokuCardWeb, KirokuCardTitleWeb,
    KirokuCardBodyWeb, KirokusSortableHandle,
    KirokuUploadFile
}                                  from '../../../../ui';

@withNamespaces()
class MediaFile extends Component {
    state = {
        show: true
    };

    showModalAddLogic() {
        this.setState({
            show: !this.state.show
        })
    }

    closeModal() {
        this.setState({
            show: !this.state.show
        })
    }

    render() {
        const {t} = this.props;
        const question = this.props.question;
        const contentQuestion = checkUseHeadingQuestion(question);
        return (
            <KirokuCardWeb style={{borderRadius: 4}}>

                <KirokuCardTitleWeb style={{backgroundColor: '#e9edf1', paddingTop: 10, paddingBottom: 7, paddingRight: 12, paddingLeft: 12}}>
                    <div className="d-flex justify-content-between">
                        <div className={'pr-2'}>
                            <KirokusSortableHandle>
                            <div className='number-question d-flex' >
                                <span className="question-number pb-2 pt-4px">{t('createSurvey.QuestionOrder', {index : question.index + 1})} </span>
                                <KirokuLogicApplicated question={question}/>
                            </div>
                            </KirokusSortableHandle>
                        </div>
                        <div className="pt-5px pr-2" hidden={typeof question.nextQuestion === 'number'}>
                            <ComponentUnrequited question={question}
                                                 changeUnrequitedQuestion={(questionIndex) => this.props.changeUnrequitedQuestion(questionIndex)}
                            />
                        </div>
                        <div>
                            <ActionQuestions>
                                <KirokuPopoverItem>
                                    <BehaviorQuestionMobile question={question}
                                                            showModalAddLogic={this.showModalAddLogic.bind(this)}
                                                            backToEditQuestion={this.props.backToEditQuestion}
                                    />
                                </KirokuPopoverItem>
                            </ActionQuestions>
                        </div>
                    </div>
                </KirokuCardTitleWeb>
                <div className='wrap-overlay-action'>
                    <KirokuCardBodyWeb>
                        <div>
                            <span className='content-name-question pb-2'>{contentQuestion}</span>
                            <p hidden={typeof question.nextQuestion !== 'number'} className='text-info'>
                                <i className='fas fa-arrow-right text-info'>
                                    {t('createRecord.Question')}: {question.nextQuestion+1}
                                </i>
                            </p>
                            <div className='answer-question'>
                                <KirokuUploadFile
                                    disabled
                                    onChange={() => {}}/>
                            </div>
                        </div>
                    </KirokuCardBodyWeb>
                </div>

                <ModalAddLogicGeneral show={this.state.show} question={question}
                                      closeModal={this.closeModal.bind(this)}/>
            </KirokuCardWeb>
        );
    }
}

export default MediaFile;
