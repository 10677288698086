import {
    ADD_LOGIC, ADD_LOGIC_YES_NO, DELETE_QUESTION,
    CREATE_QUESTION, EDITOR_QUESTION, SHOW_REVIEW,
    EDITOR_SURVEYTEMPLATE, FOCUS_QUESTION, DETAIL_SURVEY,
    EDIT_TYPE_QUESTION, ERROR_SURVEY,
    ERROR_QUESTION, RESET, UP_QUESTION,
    TURN_MODAL_EDIT_QUESTION_TEMPLATE, PUSH_QUESTION_EDIT_IN_STORE,
    CHANGE_UNREQUITED_QUESTION, COPY_QUESTION_FOR_TEMPLATE,
}                                                                           from '../actions/SurveyTemplate';
import { CREATE_SURVEY, DRAG_QUESTION_SURVEY, SET_DEFAULT_ANSWER_QUESTION } from '../actions/Survey';
import { LOADING_SURVEY_FROM_DRAFT }                                        from '../actions/DraftSurvey';
import {
    cleanQuestions,
    keepLogicWhenDelete,
}                                                                           from '../components/Surveys/SurveyEditor/SurveyService';

const defaultApplicationSate = {
    title: '',
    description: '',
    questions: [],
    focusQuestion: '',
    showReview: true,
    modalEditQuestion: false,
    form_number: '',
    editedQuestion: {
        index: 1,
        content: '',
        heading: ''
    },
    errorSurvey: {
        title: true,
        description: true,
        questions: true,
    },
    errorQuestion: {
        answer: true,
        content: true
    },
};

const createQuestionByTy = (question) => {
    let {type, choices, ...other} = question;
    switch (type) {
        case 'Single':
        case 'MultiChoice' :
            return {
                type,
                choices,
                ...other
            };
        default :
            return {
                type,
                ...other
            }
    }
};

const resetLogicQuestions = (question) => {
    switch (question.type) {
        case 'Single':
        case 'MultiChoice' :
            return {
                content: question.content,
                heading: question.heading,
                type: question.type,
                choices: question.choices.map(choice => ({
                    answer: choice.answer
                }))
            };
        default :
            return {
                content: question.content,
                heading: question.heading,
                type: question.type
            }
    }
};

export const updateQuestionOrder = (questions, current, next) => {
    let currentIndex = questions[current];
    let nextIndex = questions[next];
    questions[current] = nextIndex;
    questions[next] = currentIndex;
    return questions.map((question, i) => {
        return {
            ...question,
            index: i
        }
    });
};

export const keepLogic = (questions, currentIndex, nextIndex) => {
    return questions.map((question) => {
        switch (question.type) {
            case 'Single':
                return {
                    ...question,
                    choices: question.choices.map((choice) => {
                        if (choice.nextQuestion === currentIndex){
                            return {
                                ...choice,
                                nextQuestion: nextIndex
                            }
                        }
                        if (choice.nextQuestion === nextIndex){
                            return {
                                ...choice,
                                nextQuestion: currentIndex
                            }
                        }
                        return choice;
                    })
                };

            case 'YesNo' :
                if (question.nextQuestionNo === currentIndex && question.nextQuestionYes === nextIndex){
                    return {
                        ...question,
                        nextQuestionNo: nextIndex,
                        nextQuestionYes : currentIndex
                    };
                }else if(question.nextQuestionNo === nextIndex && question.nextQuestionYes === currentIndex){
                    return {
                        ...question,
                        nextQuestionNo: currentIndex,
                        nextQuestionYes : nextIndex
                    };
                }else if (question.nextQuestionNo === currentIndex){
                    return {
                        ...question,
                        nextQuestionNo: nextIndex,
                    };
                }else if (question.nextQuestionYes === nextIndex){
                    return {
                        ...question,
                        nextQuestionYes : currentIndex
                    };
                }else if (question.nextQuestionYes === currentIndex){
                    return {
                        ...question,
                        nextQuestionYes : nextIndex
                    };
                }else if (question.nextQuestionNo === nextIndex){
                    return {
                        ...question,
                        nextQuestionNo : currentIndex
                    };
                }else {
                    if (question.nextQuestion === currentIndex) {
                        return {
                            ...question,
                            nextQuestion: nextIndex
                        };
                    }
                    if (question.nextQuestion === nextIndex){
                        return {
                            ...question,
                            nextQuestion: currentIndex
                        };
                    }
                }
                return question;
            default :
                if (question.nextQuestion ===  currentIndex){
                    return {
                        ...question,
                        nextQuestion: nextIndex
                    };
                }
                if (question.nextQuestion ===  nextIndex){
                    return {
                        ...question,
                        nextQuestion: currentIndex
                    };
                }
                return question;
        }
    });
};



const kirokuSurveyTemplate = (state = defaultApplicationSate, action) => {
    let newQuestions = state.questions;
    switch (action.type) {
        case CHANGE_UNREQUITED_QUESTION:
            let questionsBeRequited = [...state.questions];
            if(action.questionIndex <= questionsBeRequited.length && questionsBeRequited[action.questionIndex]) {
                questionsBeRequited[action.questionIndex] = {
                    ...questionsBeRequited[action.questionIndex],
                    unrequited: !questionsBeRequited[action.questionIndex].unrequited
                };
                return {
                    ...state,
                    questions: questionsBeRequited
                };
            }
            return state;
        case DRAG_QUESTION_SURVEY:
            let questionsAfterDrag = action.questions.map((question, index) => {
                return {
                    ...question,
                    index
                }
            });
            if(action.currentIndex < action.nextIndex) {
                for(let i = action.currentIndex; i < action.nextIndex; i ++) {
                    questionsAfterDrag = keepLogic(questionsAfterDrag, i, i + 1);
                }
            }else {
                for(let i = action.currentIndex; i > action.nextIndex; i --) {
                    questionsAfterDrag = keepLogic(questionsAfterDrag, i, i - 1);
                }
            }

            return {
                ...state,
                questions: questionsAfterDrag
            };
        case CREATE_QUESTION :
            newQuestions.push({
                ...action.question,
            });
            return {
                ...state,
                questions: newQuestions,
                errorQuestion: {
                    answer: true,
                    content: true,
                },
                errorSurvey : {
                    ...state.errorSurvey,
                    questions : !state.questions.length
                }
            };
        case EDITOR_QUESTION :
            let question = newQuestions[action.index];
            question = action.question;
            newQuestions[action.index] = createQuestionByTy(question);
            return {
                ...state,
                questions: newQuestions,
            };
        case EDIT_TYPE_QUESTION :
            let questionTypeEdit = state.questions[action.index];
            let newQuestionEdit = Object.assign(questionTypeEdit, action.question);
            state.questions[action.index] = createQuestionByTy(newQuestionEdit);
            // let resetQuestions = resetLogicQuestions(state.questions);
            let questionsKeepLogicClean = cleanQuestions(state.questions);
            return {
                ...state,
                questions: questionsKeepLogicClean
            };

        case EDITOR_SURVEYTEMPLATE :
            let newsSurveyTemplate = Object.assign(state, action.surveyTemplate);
            return {
                ...newsSurveyTemplate
            };
        case ADD_LOGIC :
            state.questions[action.question.index] = action.question;
            return {
                ...state,
            };
        case  ADD_LOGIC_YES_NO :
            state.questions[action.question.index] = action.question;
            return {
                ...state,
            };
        case DELETE_QUESTION :
            newQuestions.splice(action.question.index, 1);
            newQuestions = newQuestions.map((question, index) => {
                question.index = index;
                return question;
            });
            newQuestions = keepLogicWhenDelete(action.question.index, newQuestions);
            return {
                ...state,
                questions: newQuestions,
                errorSurvey: {
                    ...state.errorSurvey,
                    questions: !state.questions.length,
                },
            };
        case FOCUS_QUESTION :
            return {
                ...state,
                focusQuestion: action.index
            };
        case SHOW_REVIEW :
            return {
                ...state,
                showReview: !state.showReview
            };
        case DETAIL_SURVEY :
            return {
                ...state,
                showReview: true,
                ...action.survey
            };
        case ERROR_SURVEY :
            return {
                ...state,
                errorSurvey: {
                    ...state.errorSurvey,
                    [action.name]: action.value,
                }
            };
        case ERROR_QUESTION :
            return {
                ...state,
                errorQuestion: {
                    ...state.errorQuestion,
                    [action.name]: action.value,
                }
            };
        case CREATE_SURVEY :
            return {
                ...state,
                title: '',
                description: '',
                questions: [],
                focusQuestion: '',
                showReview: true,

                errorSurvey: {
                    title: true,
                    description: true,
                    questions: true,
                },
                errorQuestion: {
                    answer: true,
                    content: true
                },

            };
        case RESET :
            return {
                ...state,
                title: '',
                description: '',
                questions: [],
                form_number: '',
                focusQuestion: '',
                showReview: true,

                errorSurvey: {
                    title: true,
                    description: true,
                    questions: true,
                },
                errorQuestion: {
                    answer: true,
                    content: true
                },
            };
        case UP_QUESTION :
            let result = Array.from(state.questions);
            result = updateQuestionOrder(result, action.currentIndex, action.nextIndex);
            result = keepLogic(result, action.currentIndex, action.nextIndex);
            // result = keepLogic(result, action.nextIndex, action.currentIndex);
            // const [removed] = result.splice(action.currentIndex, 1);
            // result.splice(action.nextIndex, 0, removed);
            // result = resetLogicQuestions(result);
            return {
                ...state,
                questions : result
            };
        case LOADING_SURVEY_FROM_DRAFT:
            return {
                ...state,
                ...action.survey
            };
        case TURN_MODAL_EDIT_QUESTION_TEMPLATE:
            return {
                ...state,
                modalEditQuestion: !state.modalEditQuestion
            };
        case PUSH_QUESTION_EDIT_IN_STORE:
            return {
                ...state,
                editedQuestion: action.editedQuestion
            };
        case SET_DEFAULT_ANSWER_QUESTION:
            let questions = [...state.questions];
            questions[action.question.index] = action.question;
            return  {
                ...state,
                questions
            };
        case COPY_QUESTION_FOR_TEMPLATE :
            const questionsAfterCopy = [...state.questions];
            questionsAfterCopy.push(resetLogicQuestions(action.question));
            return {
                ...state,
                questions : questionsAfterCopy.map((questionCopy, index) => {
                    return {
                        ...questionCopy,
                        index
                    }
                })
            };
        default :
            return state
    }

};

export default kirokuSurveyTemplate;
