import {ANALYSIS_QUERY} from "../actions/Analysis";
import {analysisService} from "../services";

export default store => next => action => {
    switch (action.type) {
        case ANALYSIS_QUERY:
            return analysisService.query(action.query.customerId, action.query.surveyId, action.query.startDate, action.query.endDate).then(res => {
                const isOldData =  analysisService.isOldData(res.data.records);
                next({
                    ...action,
                    records: res.data.records.filter((record, index) => !isOldData[index]),
                    survey: res.data.survey
                });
            });

        default:
            return next(action);
    }
}
