export const SEARCH_RECORD               = 'SEARCH_RECORD';
export const CHECK_RECORD_EXPORT         = 'CHECK_RECORD_EXPORT';
export const CHECK_ALL_RECORDS_EXPORT    = 'CHECK_ALL_RECORDS_EXPORT';

export function searchRecord(condition) {
    return {
        type: SEARCH_RECORD,
        condition

    }
}

export function checkRecord(id) {
    return {
        type: CHECK_RECORD_EXPORT,
        id
    }
}

export function handlerCheckAll() {
    return {
        type: CHECK_ALL_RECORDS_EXPORT
    }
}
export function resetStateExport() {
    return {
        type: 'RESET_STATE_EXPORT'
    }
}
export function setConditionSearch(condition) {
    return {
        type: 'SET_CONDITION_SEARCH',
        condition
    }
}