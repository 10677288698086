import '../../record.css'
import React, {Component}        from 'react';
import {withNamespaces}          from 'react-i18next';
import PropTypes                 from 'prop-types';
import {
    KirokuCardWeb,
    KirokuCardTitleWeb,
    KirokuCardBodyWeb,
    KirokuInputArea, KirokuButton,
    KirokuButtonLink
}                                from '../../../../ui';
import {checkUseHeadingQuestion} from "../../../Surveys/SurveyEditor/SurveyService";
import {withSmoothScrolling}     from '../../../../ui/Scrolling.js';

@withSmoothScrolling
@withNamespaces()
class InputText extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value      : props.value || '',
            validated  : false,
        }
    }

    componentDidMount() {

    }

    handleChange(event) {
        if (!event) {
            event = { target: {value: ''} }
        }
        this.setState({
            value: event.target.value,
            validated: !!event.target.value,
        });

        this.props.onAnswer({
            answer   : event.target.value,
            validated: event.target.value ? event.target.value : null,
            questionIndex: this.props.question.index
        });
        this.props.isCurrentQuestion(this.props.question.index);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.question.index !== nextProps.question.index || this.props.value !== nextProps.value) {
            this.setState({value: nextProps.value});
        }
    }

    moveToNextQuestion() {
        const {question} = this.props;
        let nextToQuestion;
        nextToQuestion = typeof question.nextQuestion === 'number' ? question.nextQuestion - 1 : question.index;
        this.props.smoothScroll.scrollTo(`question-${nextToQuestion }`);
        this.props.isCurrentQuestion(nextToQuestion + 1);
    }

    render() {
        const {
            question, t,
            disabled, isEndQuestion, currentQuestion
        } = this.props;
        const contentQuestion = checkUseHeadingQuestion(question);
        return (
            <div id='component-question'>
                <div id='web-q' className='pb-3 '>
                    <KirokuCardWeb style={{ borderColor: disabled ? '#dfe2e5' : this.state.value ? '#d6e9c6' : (!question.unrequited ? '#ebccd1' : '#dfe2e5')}}>
                        <KirokuCardTitleWeb color={disabled ? 'disable-question' :
                            (this.state.value ? 'question-has-answer d-flex justify-content-between'
                                : (!question.unrequited ? 'question-required-answer d-flex justify-content-between' : 'card-web d-flex justify-content-between'))}
                                            style={{ paddingBottom: 15, alignItems : 'center'}}
                        >
                            <div className={`number-question text-overflow-elip 
                            ${disabled ? 'question-number' : this.state.value ? 'font-text-question-has-answer' : (!question.unrequited ? 'text-question-required-answer' : 'question-number') }`}
                            >
                                {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                            </div>
                            <div hidden={disabled}>
                                <KirokuButtonLink
                                    className='padding-reset-button-link'
                                    onClick={() => {this.handleChange()}}
                                >
                                    {t('createRecord.resetAnswer')}
                                </KirokuButtonLink>
                            </div>
                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb>
                            <div className={disabled ? 'wrap-overlay-action cursor-disable' : ''} >
                                <span className='content-name-question'>{question.content}</span>
                                <div className='answer-question'>
                                    <KirokuInputArea className='form-area'
                                                     disabled={disabled}
                                                     name={`${question.index}-input`}
                                                     onChange={this.handleChange.bind(this)}
                                                     value={this.state.value}
                                                     style={{maxHeight: 90, color : '#495057'}}
                                    />

                                    <div style={{ float: "right"}} hidden={ disabled || isEndQuestion || question.index !== currentQuestion}>
                                        <KirokuButton className="btn-kiroku-primary" onClick={this.moveToNextQuestion.bind(this)}>
                                            {this.props.t('common.nextToQuestion')}
                                        </KirokuButton>
                                    </div>
                                </div>
                            </div>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                </div>
            </div>
        )
    }
}

InputText.propTypes = {
    value: PropTypes.string
};
export default InputText;
