import React, {Component} from 'react';
import SurveyMobile       from "./SurveyMobile";
import Question           from '../QuestionTypeSurveyMobile';
import {connect}          from "react-redux";
import {resetMobile}      from "../../../../actions/SurveyMobile";

@connect(state => {
    return {
        type: state.KirokuSurveyTemplateMobile.typeQuestion,
        hiddenSurvey: state.KirokuSurveyTemplateMobile.hiddenSurvey,
        title: state.KirokuSurveyTemplateMobile.title,
        form_number: state.KirokuSurveyTemplateMobile.form_number,
        description: state.KirokuSurveyTemplateMobile.description
    }
}, dispatch => {
    return {
        resetMobile: () => dispatch(resetMobile())
    }
})
class CreatorSurvey extends Component {

    componentWillUnmount() {
        // this.props.resetMobile();
    }

    render() {
        const {type} = this.props;
        return (
            <div className='bg-white'>
                {type ? <Question type={type} history={this.props.history}/>
                    : <SurveyMobile survey={this.props} history={this.props.history}/>}
            </div>
        );
    }
}

export default CreatorSurvey;
