import {
    LOADING_DRAFT_SURVEY,
    SAVE_DRAFT_EDIT_SURVEY,
    RESET_DRAFT, DISCARD_DRAFT_SURVEY,
} from "../actions/DraftSurvey";

const defaultState = {
    draftSurvey : null,
    loadingSaveDraft : false,
    flagLoadDataFromDraft : false,
};

function reset() {
    return {
        draftSurvey : null,
        loadingSaveDraft : false,
        flagLoadDataDraft : false,
    };
}

const KirokuDraftSurvey = (state = defaultState, action) => {
    switch (action.type) {
        case SAVE_DRAFT_EDIT_SURVEY :
            return {
                ...state,
                loadingSaveDraft : false
            };
        case LOADING_DRAFT_SURVEY :
            return {
                ...state,
                draftSurvey: action.dataDraft,
            };
        case RESET_DRAFT :
            return reset();
        case DISCARD_DRAFT_SURVEY :
            return reset();
        default :
            return state;
    }
};

export default KirokuDraftSurvey;
