import React, {Component} from 'react';
import {
    CheckRerenderResponsive,
    KirokuTitlepage,
} from '../../ui';
import DeletedWebPage     from "./DeletedWebPage";
import DeletedMobilePage  from "./DeletedMobilePage";
import {scrollService}    from "../../services";
import {withNamespaces}   from "react-i18next";
import {connect}          from "react-redux";
import {currentPage}      from "../../actions/CurrentPage";

function page(t) {
    return {
        title: <KirokuTitlepage title={t('DeletedList.title')}/>,
    }
}

@withNamespaces()
@connect(state => {
    return {

    }

}, dispatch => {
    return {
        namePage: (name) => {
            dispatch(currentPage(name))
        }
    }
})



class Deleted extends Component{

    state = {
        namePage: page(this.props.t),
    };

    componentWillMount() {
        scrollService.top();
        this.props.namePage(this.state.namePage);
    }

    render() {
        let web= <DeletedWebPage/>;
        let mobile= <DeletedMobilePage/>;
        return (
            <CheckRerenderResponsive web={web} mobile={mobile}/>
        )
    }
}

export default Deleted;
