import React, { Component }                  from 'react';
import { Router, Route, Switch, withRouter } from 'react-router-dom';
import qs                                    from 'qs';
import Login                                 from './components/Login/Login';
import App              from './App';
import LoginCallback    from './components/line/LoginCallback';
import config           from './config';
import { connect }      from 'react-redux';
import PropTypes        from 'prop-types';
import SupportPage      from './components/SupportPage';
import Privacy          from './components/Privacy';
import Terms            from './components/Terms';
import { withMixpanel } from './services/MixPanel';

@withMixpanel
@withRouter
@connect(state => {
    return {
        error: state.KirokuAIReject.error,
    };
}, dispatch => {
    return {
        login: (credential, typeLogin) =>
            dispatch({
                type: 'LOGIN',
                credential,
                typeLogin,
            }),
    };
})
class AppLevelRouter extends Component {

    constructor(props) {
        super(props);
        this.permissions = this.props.metadata[ 'permissions' ];
    }

    static childContextTypes = {
        //locale     : PropTypes.object.isRequired,
        permissions: PropTypes.object.isRequired,
    };

    getChildContext() {
        return {
            permissions: this.permissions,
        };
    }

    componentDidMount() {
        let querySearch = qs.parse(this.props.history['location'].search, {
            plainObjects     : true,
            ignoreQueryPrefix: true,
        });

        if (querySearch[ 'state' ] === config.line.state_login) {
            this.props.login({ code: querySearch[ 'code' ] }, 'line');
        }
    }

    render() {
        // Demo for using sentry
        // this.props.sentry.captureMessage('Sphinx custom message to sentry');
        return (
            <Router history={this.props.history}>
                <Switch>
                    <Route exact path='/login' component={Login}/>
                    <Route exact name='Callback' path='/login/callback' component={LoginCallback}/>
                    <Route exact path='/surfly' component={SupportPage}/>
                    <Route exact path='/privacy' component={Privacy}/>
                    <Route exact path='/terms' component={Terms}/>
                    <Route exact path='' component={App}/>
                </Switch>
            </Router>
        );
    }
}

export default AppLevelRouter;
