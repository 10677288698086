export const CHECK_ALL_SURVEYS      = "CHECK_ALL_SURVEYS";
export const ON_SEARCH              = "ON_SEARCH";
export const CURRENT_CONDITION      = "CURRENT_CONDITION";
export const CURRENT_CONDITION_LOGS = "CURRENT_CONDITION_LOGS";
export const RESET_CONDITION_SURVEY  = "RESET_CONDITION_SURVEY";

export function checkAllSurveys(checked) {
    return {
        type: 'CHECK_ALL_SURVEYS',
        loginRequired: true,
        isChecked: checked
    }
}

export function onSearch(keywords) {
    return {
        type: ON_SEARCH,
        keywords: keywords
    }
}

export function currentCondition(conditions) {
    return {
        type: CURRENT_CONDITION,
        conditions: conditions
    }
}

export function currentConditionLogs(conditions) {
    return {
        type: CURRENT_CONDITION_LOGS,
        conditionsLogs: conditions
    }
}

export function resetConditionSurvey() {
    return {
        type: RESET_CONDITION_SURVEY,
    }
}