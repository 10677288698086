import {makeCustomerService, recordService} from '../services';
import {
    CREATE_RECORDS, CUSTOMER_FOR_TAGS_RECORDS,
    DELETE_RECORDS,
    LOAD_DETAIL,
    LOAD_RECORD_TO_EDIT,
    LOAD_RECORDS,
    RECORDS_DASHBOARD,
    UPDATE_RECORDS,
    GET_RECORDS_FOR_DETAIL_PAGE
}                                             from '../actions/Record';
import {NOTIFICATION}                         from '../actions/Notification';
import {TAKE_RECORD}                          from '../actions/TakeSurvey';
import createBrowserHistory                   from 'history/createBrowserHistory'
import { isErrorPermissionEditRecordLocking } from '../axiosConfig/HandleError';

const history = createBrowserHistory();

export const loadTemplateWithCustomerMiddleware = (store) => next => action => {
    switch (action.type) {
        case CUSTOMER_FOR_TAGS_RECORDS:
            return recordService.customerForTagRecords().then(result => {
                let customers = makeCustomerService.listAllCustomerForTags(result);
                next({
                    type: CUSTOMER_FOR_TAGS_RECORDS,
                    customerTagged: customers
                })
            }).catch(error => console.log(error));

        case UPDATE_RECORDS:
            return recordService.updateRecord(action.id, action.dataRecord).then(res => {
                next({
                    ...action
                });
                if (!action.dataRecord.hasDraft) {
                    store.dispatch({
                        type: NOTIFICATION,
                        typeNotification: 'success',
                        message: 'createRecord.UpdateRecordSuccess'
                    })
                }
                if (action.dataRecord.hasDraft) {
                    store.dispatch({
                        type: NOTIFICATION,
                        typeNotification: 'success',
                        message: 'createRecord.SaveDraftSuccess'
                    })
                }
            }).catch(e => {
                if(isErrorPermissionEditRecordLocking(e)) {
                    store.dispatch({
                        type: 'LOCK_INFORMATION_FIRST',
                        lockableType: 'records', recordId: action.id
                    });
                }
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'danger',
                    message: 'createRecord.CreateRecordFail'
                })
            });
        case LOAD_DETAIL:
            return recordService.detail(action.id).then(res => {
                next({
                    ...action,
                    record: res
                });
            }).catch(err => console.log(err));
        case LOAD_RECORDS:
            return recordService.loadRecords(action.params, action.tags ? action.tags : []).then(res => {
                next({
                    ...action,
                    records: res.records,
                    totalPage: res.totalPage || 1,
                    currentPage: res.currentPage || 1
                })
            }).catch(error => console.log(error));
        case RECORDS_DASHBOARD :
            return recordService.loadRecordsDashBoard().then(res => {
                next({
                    ...action,
                    records: res,
                })
            });
        case CREATE_RECORDS:
            return recordService.createSurveyRecord(action.record).then(res => {
                next({
                    ...action
                });
                if (!action.record.hasDraft) {
                    history.goBack();
                    store.dispatch({
                        type: NOTIFICATION,
                        typeNotification: 'success',
                        message: 'createRecord.CreateRecordSuccess'
                    })
                }
                if (action.record.hasDraft) {
                    store.dispatch({
                        type: NOTIFICATION,
                        typeNotification: 'success',
                        message: 'createRecord.SaveDraftSuccess'
                    })
                }
            }).catch(error => {
                console.log(error)
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'danger',
                    message: 'createRecord.CreateRecordFail'
                })
            });
        case TAKE_RECORD :
            return recordService.createSurveyRecord(action.record).then(res => {
                next({
                    ...action
                });
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'success'
                })
            }).catch(error => {
                console.log(error);
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'danger',
                    message: ''
                })
            });
        case DELETE_RECORDS :
            return recordService.deleteRecords(action.records).then(res => {
                next({
                    ...action
                });
                store.dispatch({
                    type: LOAD_RECORDS,
                    params: action.params,
                    tags : action.tags
                });
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'success',
                    message: 'Surveys.DeletedSuccessfully'
                })
            });
        case LOAD_RECORD_TO_EDIT:
            return recordService.detail(action.id).then(res => {
                next({
                    ...action,
                    recordToEdit: res
                });
            }).catch(err => console.log(err));
        case GET_RECORDS_FOR_DETAIL_PAGE:
            return recordService.getRecordForDetailPage(action.params).then(res => {
                next({
                    ...action,
                    ...res,
                })
            }).catch(error => console.log(error));

        default:
            next(action)

    }


};

