export const SAVE_DRAFT_RECORDING              = 'SAVE_DRAFT_RECORDING';
export const LOAD_DRAFT_RECORDING              = 'LOAD_DRAFT_RECORDING';
export const DISCARD_DRAFT_RECORDING           = 'DISCARD_DRAFT_RECORDING';
export const RESTART_DATA_DRAFT_RECORDING      = 'RESTART_DATA_DRAFT_RECORDING';
export const LOAD_DRAFT_EDIT_RECORDING         = 'LOAD_DRAFT_EDIT_RECORDING';
export const SAVE_DRAFT_EDIT_RECORDING         = 'SAVE_DRAFT_EDIT_RECORDING';
export const RESTART_DATA_DRAFT_EDIT_RECORDING = 'RESTART_DATA_DRAFT_EDIT_RECORDING';
export const DISCARD_DRAFT_EDIT_RECORDING      = 'DISCARD_DRAFT_EDIT_RECORDING';


export function saveDraftRecoding(dataDraft) {
    return {
        type: SAVE_DRAFT_RECORDING,
        dataDraft,
    }
}

export function saveDraftEditRecording(dataEditRecoding) {
    return {
        type: SAVE_DRAFT_EDIT_RECORDING,
        dataEditRecoding,
    }
}

export function restartDataDraftEditRecording() {
    return {
        type: RESTART_DATA_DRAFT_EDIT_RECORDING
    }

}

export function discardDraftEditRecording(surveyId) {
    return {
        type: DISCARD_DRAFT_EDIT_RECORDING,
        surveyId
    }
}

export function loadDraftEditRecording(id) {
    return {
        type: LOAD_DRAFT_EDIT_RECORDING,
        id
    }

}


export function loadDraftRecording(surveyId, customerId) {
    return {
        type: LOAD_DRAFT_RECORDING,
        surveyId,
        customerId
    }
}

export function discardDraftRecoding(surveyId, customerId) {
    return {
        type: DISCARD_DRAFT_RECORDING,
        surveyId, customerId
    }
}

export function restartDataDraftRecording() {
    return {
        type: RESTART_DATA_DRAFT_RECORDING
    }

}

