import React, { Component }               from 'react';
import ModalAddLogicSingle                from '../ModalAddLogicSingle';
import {
    KirokuCardTitleWeb, KirokusSortableHandle,
    KirokuCardWeb, KirokuCardBodyWeb,
    KirokuRadio, KirokuPopoverItem,
    KirokuLogicApplicated,
}                                         from '../../../../ui';
import BehaviorQuestionMobile             from './BehaviorQuestionMobile';
import { withNamespaces }                 from 'react-i18next';
import ActionQuestions                    from './ActionsQuestions';
import { checkUseHeadingQuestion }        from '../../SurveyEditor/SurveyService';
import { connect }                        from 'react-redux';
import { setDefaultAnswerQuestionMobile } from '../../../../actions/Survey';
import ComponentUnrequited                from '../../ReviewSurvey/ComponentUnrequited';
import _                                  from 'lodash';
import KirokuButtonLink                   from '../../../../ui/KirokuButtonLink';

@connect(null, dispatch => {
    return {
        setDefaultAnswerQuestion: (question) => {
            dispatch(setDefaultAnswerQuestionMobile(question));
        },
    };
})
@withNamespaces()
class SingleChoice extends Component {
    state = {
        show: true,
    };

    showModalAddLogic() {
        this.setState({
            show: !this.state.show,
        });
    }

    closeModal() {
        this.setState({
            show: !this.state.show,
        });
    }

    setDefaultAnswer(indexAnswerDefault) {
        const { question }  = this.props;
        let questionNew     = { ...question };
        questionNew.choices = questionNew.choices.map((choice, index) => {
            return {
                ...choice,
                defaultAnswer: index === indexAnswerDefault,
            };
        });
        this.props.setDefaultAnswerQuestion(questionNew);
    }

    handleResetDefaultAnswer() {
        const { question } = this.props;
        let questionNew    = { ...question };
        questionNew.choices = questionNew.choices.map((choice) => {
            if(choice.defaultAnswer) {
                delete choice.defaultAnswer;
            }
            return choice;
        });
        this.props.setDefaultAnswerQuestion(questionNew);
    }

    render() {
        const { t }           = this.props;
        const question        = this.props.question;
        const singleQuestion  = question.choices.map(item => {
            return { nextQuestion: item.nextQuestion };
        });
        const contentQuestion = checkUseHeadingQuestion(question);
        const isLogicSingle = _.findIndex(singleQuestion, (o) => {return typeof o.nextQuestion === 'number'}) !== -1;
        let isLogic  = question.nextQuestion === 0 || question.nextQuestion || isLogicSingle;
        return (
            <KirokuCardWeb style={{ borderRadius: 4 }}>
                <KirokuCardTitleWeb style={{
                    backgroundColor: '#e9edf1', paddingTop: 10, paddingBottom: 7, paddingRight: 12, paddingLeft: 12,
                }}>
                    <div className={'d-flex justify-content-between'}>
                        <div className={'pr-2'}>
                            <KirokusSortableHandle>
                                <div className='number-question d-flex'>
                                    <span className="question-number pb-2 pt-4px">{t('createSurvey.QuestionOrder', { index: question.index + 1 })} </span>
                                    <KirokuLogicApplicated question={{ ...question, singleQuestion }}/>
                                </div>
                            </KirokusSortableHandle>
                        </div>
                        <div className={'pt-5px pr-2'} hidden={isLogic}>
                            <ComponentUnrequited question={question}
                                                 changeUnrequitedQuestion={(questionIndex) => this.props.changeUnrequitedQuestion(questionIndex)}
                            />
                        </div>
                        <div className={'pr-2'}>
                            <KirokuButtonLink
                                className='padding-reset-button-link'
                                onClick={() => {this.handleResetDefaultAnswer()}}>
                                {t('createRecord.resetAnswer')}
                            </KirokuButtonLink>
                        </div>
                        <div>
                            <ActionQuestions isReviewSurvey>
                                <KirokuPopoverItem>
                                    <BehaviorQuestionMobile question={question}
                                                            showModalAddLogic={this.showModalAddLogic.bind(this)}
                                                            backToEditQuestion={this.props.backToEditQuestion}
                                    />
                                </KirokuPopoverItem>
                            </ActionQuestions>
                        </div>
                    </div>
                </KirokuCardTitleWeb>

                <div className='wrap-overlay-action'>
                    <KirokuCardBodyWeb>

                        <div>
                            <span className='content-name-question pb-2'>{contentQuestion}</span>
                            <p className='text-info' hidden={typeof question.nextQuestion !== 'number'}><i
                                className='fas fa-arrow-right'>  {t('createRecord.Question')}: {question.nextQuestion + 1}</i>
                            </p>
                            <div className='answer-question'>
                                {question.choices.map((choice, index) =>
                                    <div key={index}>
                                        <KirokuRadio
                                            key={index}
                                            id={`${question.index}-${index}`}
                                            name={`singleChoice_${question.index}`}
                                            checked={!!choice.defaultAnswer}
                                            onChange={this.setDefaultAnswer.bind(this, index)}
                                        >
                                            {choice.answer}
                                        </KirokuRadio>
                                        <p hidden={typeof choice.nextQuestion !== 'number'}
                                           className='text-info'><i
                                            className='fas fa-arrow-right text-info'>  {t('createRecord.Question')}: {choice.nextQuestion + 1}</i>
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </KirokuCardBodyWeb>
                </div>

                <ModalAddLogicSingle show={this.state.show} question={question}
                                     closeModal={this.closeModal.bind(this)}/>
            </KirokuCardWeb>
        );
    }
}

export default SingleChoice;
