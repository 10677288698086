class LineService {
    constructor(axios, config) {
        this.axios  = axios;
        this.config = config;
    }

    async synchronizeLine(code) {
        return await this.axios.get(`${this.config.domain}/line/sync/${code}`);
    }

    getProfile(lineToken) {
        return this.axios.get(`${this.config.domain}/line/profile?line_token=${lineToken}`);
    }

    async revokeLine() {
        return await this.axios.post(`${this.config.domain}/line/cancel-sync`);
    }

}

export default LineService;
