import React, { Component }                                            from 'react';
import KirokuModal                                                     from "../../ui/KirokuModal";
import KirokuModalBody                                                 from "../../ui/KirokuModalBody";
import KirokuCardList                                                  from "../../ui/KirokuCardList";
import KirokuCard                                                      from "../../ui/KirokuCard";
import KirokuCheckBox                                                  from "../../ui/KirokuCheckBox";
import KirokuModalFooter                                               from "../../ui/KirokuModalFooter";
import KirokuButton                                                    from "../../ui/KirokuButton";
import connect                                                         from "react-redux/es/connect/connect";
import { checkAllSurveys, checkSurvey, deleteListSurvey, resetSurvey } from "../../actions/Survey";
import {withNamespaces}                                                from "react-i18next";

@withNamespaces()
@connect((state) => {
    return {
        surveyForModal    : state.KirokuAISurvey.surveyForModal,
        disableButtonApply: state.KirokuAISurvey.disableButtonApply,
        params: state.KirokuAIBehavior.conditionSurveys.params,
    }
}, (dispatch) => {
    return {
        resetSurvey   : () => {
            dispatch(resetSurvey())
        },
        surveySelected: (index) => {
            dispatch(checkSurvey(index))
        },
        checkAll      : (checked) => {
            dispatch(checkAllSurveys(checked))
        },

        deleteListSurvey: (listSurvey, params) => {
            dispatch(deleteListSurvey(listSurvey, params))
        }
    }
})
class ModalDeleteSurveys extends Component {

    state = {
        checked             : false,
        disableButtonApply  : false,
        showModalDelete     : false,
        surveyForModal      : [],
        surveyForModalRecord: [],
        countSurvey         : {}
    };

    handleCheckAll() {
        this.props.checkAll(this.state.checked);
        this.setState({
            checked: !this.state.checked
        })
    }

    closeModal() {
        this.props.closeModalDeleteSurvey();
        this.props.resetSurvey();
        this.setState({
            showModalDelete: false
        })
    }

    showModalDelete() {
        let surveys = this.state.surveyForModalRecord.filter(survey => survey.isChecked);
        this.setState({
            countSurvey    : {
                name : surveys.map(survey => survey.title).join(', '),
                id   : surveys.map(survey => survey.id),
                count: surveys.length
            },
            show           : false,
            showModalDelete: !this.state.showModalDelete
        });
        this.props.closeModalDeleteSurvey();

    }

    handleCheckSurveys(index) {
        this.props.surveySelected(index);
    }

    deleteCustomer() {
        this.props.deleteListSurvey(this.state.countSurvey.id, this.props.params);
        this.setState({
            showModalDelete: !this.state.showModalDelete
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.surveyForModal.length) {
            this.setState({
                surveyForModal      : nextProps.surveyForModal,
                surveyForModalRecord: nextProps.surveyForModal
            })
        }
    }

    render() {
        const {t} = this.props;
        return (
            <div className='pt-10 d-flex justify-content-between'>
                <KirokuModal show={this.props.show}>
                    <KirokuModalBody>
                        <div id="body-modal-as">
                            <KirokuCardList
                                headerLeft= {t('Surveys.SelectSurveys')}
                                headerRight={
                                    <div className='text-primary' style={{cursor: 'pointer'}}>
                                        <span hidden={this.state.checked}
                                              onClick={this.handleCheckAll.bind(this)}
                                        >{t('common.SelectAll')}</span>

                                        <span hidden={!this.state.checked}
                                              onClick={this.handleCheckAll.bind(this)}
                                        >{t('common.UnSelect')}</span>
                                    </div>
                                }
                            >
                                <section className="scroll-data-survey">
                                    {this.state.surveyForModal.map((survey, index) => (
                                        <KirokuCard key={survey.id}>
                                            <KirokuCheckBox onChange={this.handleCheckSurveys.bind(this, index)}
                                                            key={index}
                                                            checked={survey.isChecked} content={survey.title}/>
                                        </KirokuCard>
                                    ))}
                                </section>
                            </KirokuCardList>
                        </div>
                    </KirokuModalBody>
                    <KirokuModalFooter>
                        <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                            {t('common.Cancel')}
                        </KirokuButton>
                        <KirokuButton disabled={!this.props.disableButtonApply}
                                      onClick={this.showModalDelete.bind(this)} color={'primary'}>
                            {t('common.Delete')}
                        </KirokuButton>
                    </KirokuModalFooter>
                </KirokuModal>


                <KirokuModal show={this.state.showModalDelete} onClick={this.closeModal.bind(this)}>
                    <KirokuModalBody>
                        <p className='text-center'>
                            {t('Surveys.AreYouWantDelete', {count :this.state.countSurvey.count , name : this.state.countSurvey.name})}
                        </p>
                    </KirokuModalBody>
                    <KirokuModalFooter>
                        <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                            {t('common.Cancel')}
                        </KirokuButton>
                        <KirokuButton onClick={this.deleteCustomer.bind(this)} color={'primary'}>
                            {t('common.Confirm')}
                        </KirokuButton>
                    </KirokuModalFooter>
                </KirokuModal>

            </div>
        )
    }
}

export default ModalDeleteSurveys;
