import React, {Component} from 'react';
import {withNamespaces}   from "react-i18next";
import {connect}          from "react-redux";
import KirokuModal        from "../../../ui/KirokuModal";
import KirokuModalBody    from "../../../ui/KirokuModalBody";
import KirokuModalFooter  from "../../../ui/KirokuModalFooter";
import KirokuButton       from "../../../ui/KirokuButton";
import {removeDocument}   from "../../../actions/Document";

@withNamespaces()

@connect(() => ({}), dispatch => {
    return {
        remove: (customerId, listId) => {
            dispatch(removeDocument(customerId, listId))
        }
    };
})
class ModalRemoveDocument extends Component {

    closeModal() {
        this.props.closeModal()
    }

    removeDocument() {
        let listId = this.props.documents.map(document => document.id);
        this.props.closeModal();
        this.props.remove(this.props.customerId, listId);
    }

    render() {
        const {t} = this.props;
        return (
            <KirokuModal show={this.props.show} onClick={this.closeModal.bind(this)}>
                <KirokuModalBody>
                    <p className='text-center mt-8'>
                        {t('message.deleteDocument', {count: this.props.documents.length})}
                    </p>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                        {t('common.Cancel')}
                    </KirokuButton>
                    <KirokuButton onClick={this.removeDocument.bind(this)} color={'primary'}>
                        {t('common.Confirm')}
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>
        )
    }
}

export default ModalRemoveDocument;