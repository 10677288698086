import React, {Component}                       from 'react';
import "../teams.css";
import KirokuButton                             from "../../../ui/KirokuButton";
import KirokuInput                              from "../../../ui/KirokuInput";
import KirokuInputArea                          from "../../../ui/KirokuInputArea";
import {withNamespaces}                         from "react-i18next";
import KirokuCard                               from "../../../ui/KirokuCard";
import KirokuCardList                           from "../../../ui/KirokuCardList";
import connect                                  from "react-redux/es/connect/connect";
import {checkAllMember, checkMember, loadUsers} from "../../../actions/User";
import ModalSelectMember                        from "./ModalSelectMember";
import ModalSetRole                             from "./ModalSetRole";
import KirokuDropdownClose                      from "../../../ui/KirokuDropdownClose";
import ModalDeleteMember                        from "./ModalDeleteMember";
import {filePathService}                        from "../../../services";
import {
    checkIsChecked, getListMemberSetRole,
    getListMemberTHisRemove,
    turnOnModalAddMember,
    turnOnModalDelete,
    turnOnModalSetRole
}                                               from "../../../actions/EditorTeam";
import {createTeam}                             from "../../../actions/Team";

@withNamespaces()
@connect(state => {
    return {
        users: state.KirokuAIUser.users,
        membersChoice: state.EditorTeam.membersChoice,
        statusModal: state.EditorTeam.showModalDelete,
    }
}, dispatch => {
    return {
        turnOnModalAddMember: () => {
            dispatch(turnOnModalAddMember())
        },
        turnOnModalSetRole: () => {
            dispatch(turnOnModalSetRole())
        },
        turnOnModalDelete: () => {
            dispatch(turnOnModalDelete())
        },
        checkIsChecked: () => {
            dispatch(checkIsChecked())
        },
        memberSelect: (index) => {
            dispatch(checkMember(index))
        },
        checkAll: (checked) => {
            dispatch(checkAllMember(checked))
        },
        createTeam: (team) => {
            dispatch(createTeam(team))
        },
        getUsers: () => {
            dispatch(loadUsers())
        },
        getListMemberTHisRemove: () => {
            dispatch(getListMemberTHisRemove())
        },
        getListMemberSetRole: () => {
            dispatch(getListMemberSetRole())
        }
    }
})

class TeamUpdateMobile extends Component {

    bulkActionItems = [
        {
            value: 'delete',
            component: (<div>{this.props.t('common.Delete')}</div>),
            label: this.props.t('common.Delete')
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            team: this.props.team,
            name: '',
            description: '',
            members: [],
            errors: {},
        }
    }

    onChangeTeam(e) {
        let newTeam            = this.state.team;
        newTeam[e.target.name] = e.target.value;
        this.setState({
            team: newTeam
        });

    }

    onSubmit() {
        let team = {
            ...this.state.team,
            members: this.props.membersChoice
        };
        this.props.handleSubmit(team);
    }

    goBack() {
        this.props.history.goBack()
    }

    showModalAddMember() {
        this.props.turnOnModalAddMember();
        this.props.checkIsChecked();
    }

    showModalDelMember() {
        this.props.turnOnModalDelete();
        this.props.getListMemberTHisRemove();
    }

    showModalSetRole() {
        this.props.turnOnModalSetRole();
        this.props.getListMemberSetRole();
    }

    validateCreator(e) {
        this.setState({
            errors: {
                ...this.state.errors,
                [e.target.name]: !e.currentTarget.value.length
            }
        });
    }

    handleUpdate() {
        const {team} = this.state;
        return team.name.length === 0 ||
            team.description.length === 0 ||
            !Object.values(this.state.errors).every(item => !item)
    }

    render() {
        const {t, membersChoice} = this.props;
        const {team, errors}     = this.state;
        return (
            <div className='padding-responsive'>
                <div>
                    <div className='d-flex justify-content-between'>
                        <KirokuButton color='white'
                                      onClick={this.goBack.bind(this)}>{t('common.back')}</KirokuButton>
                    </div>
                    <div className="pt-2">
                        <div className='card'>
                            <div className='p-2 pt-3'>
                                <h6>{t('Team.AddTeam')}</h6>
                                <div className='pt-2 pb-2'>{t('Team.TeamName')}</div>
                                <KirokuInput value={team.name} name='name'
                                             onChange={this.onChangeTeam.bind(this)}
                                             onBlur={this.validateCreator.bind(this)}
                                             error={errors.name ? 'form-error' : ''}
                                />
                                <p hidden={!errors.name} style={{
                                    color: 'red',
                                    fontSize: 12
                                }}>{t('Team.Team name must not null')}</p>
                                <div className='pt-2 pb-2'>{t('common.Description')}</div>
                                <KirokuInputArea name='description' value={team.description}
                                                 onChange={this.onChangeTeam.bind(this)}
                                                 onBlur={this.validateCreator.bind(this)}
                                                 error={errors.description ? 'form-error' : ''}
                                />
                                <p hidden={!errors.description} style={{
                                    color: 'red',
                                    fontSize: 12
                                }}>{t('Team.Description must not null')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="pt-2">
                        <div className='card'>
                            <div className='p-2 pt-3'>
                                <h6>{t('common.Members')}</h6>
                                <div>
                                    {membersChoice.length ?
                                        <KirokuCardList
                                            headerLeft={<span className='customer-name'>{t('common.Name')}</span>}
                                            headerRight={<span className='customer-name'>{t('common.Role')}</span>}
                                        >
                                            {membersChoice.map(member =>
                                                <KirokuCard key={member.id}
                                                            action={<p>{t(`role.${member['role']}`)}</p>}>
                                                    <p>{member.name}</p>
                                                </KirokuCard>
                                            )}
                                        </KirokuCardList> :
                                        <div className='fix-center'>
                                            <div>
                                                <div className='teams-image'
                                                     style={{backgroundImage: `url(${filePathService.resolveFilePath('profile-ic.svg')})`}}/>
                                            </div>
                                            <div>
                                                <p className='text-center'>{t('Team.Opps')}</p>
                                                <p className='text-center'>{t('Team.PleaseTeam')}</p>
                                            </div>
                                        </div>
                                    }
                                    <div className="pt-3 pb-3 text-center">
                                        <KirokuButton color={'success'}
                                                      onClick={this.showModalAddMember.bind(this)}>{t('Team.AddMember')}</KirokuButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {membersChoice.length ?
                            <div className="pt-2 d-flex mb-2">
                                <KirokuDropdownClose
                                    style={{minWidth: 130}}
                                    location={'top'}
                                    title={t('common.BulkAction')}
                                    items={this.bulkActionItems}
                                    onSelect={event => {
                                        if (event.kirokuSelected.value === 'delete') {
                                            this.showModalDelMember();
                                        } else {
                                            this.showModalSetRole()
                                        }
                                    }}
                                />
                            </div> : <div/>}
                    </div>
                    <ModalDeleteMember/>
                    <ModalSelectMember/>
                    <ModalSetRole/>
                    <div className='fix-center'>
                        <div className='pr-5 pl-5 pt-3 pb-3'>
                            <div className='d-flex justify-content-between'>
                                <KirokuButton color={'white'}
                                              onClick={this.goBack.bind(this)}>{t('common.Cancel')}</KirokuButton>
                                <div className="pl-1 pr-1"/>
                                <KirokuButton color={'primary'} disabled={this.handleUpdate()}
                                              onClick={this.onSubmit.bind(this)}>{t('common.Update')}</KirokuButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TeamUpdateMobile;