import React, {Component} from 'react';
import moment             from 'moment';
import {withNamespaces}   from "react-i18next";
import {connect}          from "react-redux";
import {KirokuButton}     from "../../../../../ui";
import {NOTIFICATION}     from "../../../../../actions/Notification";
import config             from '../../../../../config';

@withNamespaces()
@connect(() => {
    return {
        //profile: state.KirokuAIUser.profile
    }
}, (dispatch) => {
    return {
        notification: (t) => {
            dispatch({
                type: NOTIFICATION,
                typeNotification: 'success',
                message: t('message.LoadSuccess')
            })
        }
    }
})
class NotificationBackupMobile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: !!this.props.draft
        }
    }

    closeModal = () => {
        this.setState({
            show: false
        })
    };

    loadDraft = () => {
        this.props.confirm();
        this.props.notification(this.props.t);
        this.closeModal();
    };

    discardDraft = () => {
        this.props.cancel();
        this.closeModal();
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.draft !== this.props.draft) {
            this.setState({
                show: !!nextProps.draft
            })
        }
    }

    render() {
        const {t, draft} = this.props;
        return (
            !!draft ? (
                <div hidden={!this.state.show} className='card w-100-mb mb-3' style={{background: '#d4edda'}}>
                    <div className='padding-responsive justify-content-between'>
                        <div style={{margin: 'auto 0'}}>
                            <i className="fa fa-info-circle"/>
                            <span>&nbsp;
                                {t('common.backupRecord')}
                                {moment.unix(draft.create_at).format(config.formatDateTimeTh)}</span>
                        </div>
                        <div/>
                    </div>
                    <div className='fix-center'>
                        <div className='d-flex justify-content-between pl-2 pr-2 pb-2' style={{width: 195}}>
                            <div>
                                <KirokuButton
                                    color={'success'}
                                    onClick={this.loadDraft.bind(this)}
                                >
                                    {t('createRecord.loadDraft')}
                                </KirokuButton>
                            </div>
                            <div className='pr-1 pl-1'/>
                            <div>
                                <KirokuButton
                                    color={'white'}
                                    onClick={this.discardDraft.bind(this)}>
                                    {t('common.close')}
                                </KirokuButton>
                            </div>
                        </div>
                    </div>
                </div>
            ) : ''
        );
    }
}

export default NotificationBackupMobile;
