import React, {Component} from 'react';
import './style.css'
import ReviewSurvey       from './ReviewSurvey';
import Survey             from "./Survey";
import {connect}          from 'react-redux';
import {currentPage}      from "../../../../actions/CurrentPage";
import KirokuTitlepage    from "../../../../ui/KirokuTitlepage";
import {createSurvey}     from "../../../../actions/Survey";
import {withNamespaces}   from "react-i18next";
import {loadDraft}        from "../../../../actions/DraftSurvey";
import {hasTimer}         from "../../../../Timer";

@hasTimer()
@withNamespaces()
@connect(state => {
    return {
        showReview: state.KirokuAISurveyTemplate.showReview
    }
}, dispatch => {
    return {
        currentPage: (name) => {
            dispatch(currentPage(name))
        },

        createSurvey : () => {
            dispatch(createSurvey())
        },

        loadDraft : (typeDraft) => dispatch(loadDraft(typeDraft))
    }
})
class SurveyCreator extends Component {
    page = {
        title: <KirokuTitlepage title={this.props.t('createSurvey.CreateNewSurvey')}/>,
    };

    componentWillMount() {
        this.props.currentPage(this.page);
        // this.props.loadDraft('surveyTemplate');
    }

    handleSubmit = () => {
        this.props.createSurvey();
        this.props.history.push('/surveys');
    };

    render() {
        const showReview = this.props.showReview;
        return (
            <div>
                {
                    showReview ? (
                        <Survey history={this.props.history}/>
                    ) : (<ReviewSurvey history={this.props.history} survey={this.state} handleSubmit={this.handleSubmit.bind(this)}/>)
                }
            </div>

        );
    }
}

export default SurveyCreator;
