import {Component}      from "react";
import React            from "react";
import {withNamespaces} from "react-i18next";

@withNamespaces()
class KirokuCertificate extends Component {
    render() {
        const showCertificates = this.props['certificate'];
        let {t}                = this.props;
        return (
            <span>
                {showCertificates.map((showCertificate, index) =>
                    <span key={index}
                          className="kiroku-btn-data mr-1">{t(`Customer.certificate.${showCertificate.label}`)}</span>
                )}
            </span>
        );
    }
}

export default KirokuCertificate;