import React, { Component } from 'react';
import Highlighter          from "react-highlight-words";
import PropTypes            from "prop-types";

class KirokuHighLightText extends Component {

    render() {
        const {searchWords, textToHighlight, className} = this.props;
        return (
            <Highlighter
                highlightClassName={`highLightText `+ className}
                searchWords={searchWords}
                autoEscape={true}
                textToHighlight={textToHighlight}
            />
        );
    }
}

KirokuHighLightText.propTypes = {
    searchWords: PropTypes.array,
    textToHighlight: PropTypes.string,
};

export default KirokuHighLightText;

