import React, { Component } from "react";

export default class KirokuModalBody extends Component {
    render() {
        return (
            <div className="modal-body" {...this.props}>
                {this.props.children}
            </div>
        )
    }
}
