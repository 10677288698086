import {
    returnAnswerDate,
    returnAnswerInputText,
    returnAnswerMedia,
    returnAnswerMultiChoice,
    returnAnswerSingle,
    returnAnswerYesNo
}                       from './ReturnAnswerQuestion';
import {validateAnswer} from "../components/Records/sevicesInRecord";


export function setDisableQuestionNotAnswers(flowedQuestions, questions) {
    const questionsDisable = questions.map(question => {return {...question, visibility: true}});
    flowedQuestions.forEach(question => {
        questionsDisable[question.index] = {
            ...questionsDisable[question.index],
            answers: question.answers,
            visibility: false
        }
    });
    return questionsDisable;
}
export function setDisableQuestionNotAnswersNotStatus(flowedQuestions, questions) {
    const questionsDisable = questions.map(question => {return {...question, visibility: true}});

    flowedQuestions.forEach(question => {
        questionsDisable[question.index] = {
            ...questionsDisable[question.index],
            visibility: false
        }
    });
    const questionLastOfFlowedQuestions = flowedQuestions[flowedQuestions.length-1];
    if(validateAnswer(questionLastOfFlowedQuestions)) {
        const indexNextQuestion = getNextQuestion(validateAnswer(questionLastOfFlowedQuestions), questionLastOfFlowedQuestions);
       if(indexNextQuestion < questions.length) {
            questionsDisable[indexNextQuestion] = {
                ...questionsDisable[indexNextQuestion],
                visibility: false
            }
       }
    }


    return questionsDisable;
}

function getNextQuestion(answer, question) {
    if(question.type === 'YesNo') {
        if (answer === 'Yes') {
            return question.nextQuestionYes || question.nextQuestion || question.index + 1
        }

        if (answer === 'No') {
            return question.nextQuestionNo || question.nextQuestion || question.index + 1
        }
    }

    if(question.type === 'Single') {
        return answer.nextQuestion || question.index + 1
    }
    return  question.nextQuestion || question.index + 1
}

function getAnswerQuestion(question) {
    let answers        = question.answers;
    const typeQuestion = {
        InputText  : returnAnswerInputText(answers),
        Media      : returnAnswerMedia(question),
        Date       : returnAnswerDate(answers),
        Message    : '',
        Single     : returnAnswerSingle(answers),
        MultiChoice: returnAnswerMultiChoice(answers),
        YesNo      : returnAnswerYesNo(answers)
    };
    return typeQuestion[question.type]
}


export function oldDraftToNewDraft(oldDraft) {
    try {
        const questions = oldDraft.survey.questions.map((question, index) => {
            return {
                ...question,
                answers: getAnswerQuestion(question)
            }
        });
        const fow       = oldDraft.questionsView.map(question => {
            return {
                ...question,
                answers: getAnswerQuestion(question)
            }
        });
        return {
            create_at: oldDraft.create_at,
            customer : oldDraft.customer,
            survey   : oldDraft.survey,
            dataDraft: {
                flow     : fow,
                questions: questions
            }
        }
    } catch (e) {
        return null
    }
}

export function filterDataRecordToEdit(recordToEdit) {
    const surveyTemplate = recordToEdit.survey_template;
    if (surveyTemplate.flowedQuestions) {
        return recordToEdit;
    }
    return providerClearQuestions(recordToEdit);
}


export function ClearLogicQuestionWithDraftEditRecord(draftEditRecord) {
    if (draftEditRecord.dataDraft) {
        return draftEditRecord;
    }
    return {
        ...draftEditRecord,
        dataDraft: {
            flowedQuestions: returnQuestionNewNotLogic(draftEditRecord.questions),
            questions      : returnQuestionNewNotLogic(draftEditRecord.surveyTemplate.questions)
        }
    }

}


function returnQuestionNewNotLogic(questions) {
    return questions.map((question, index) => {

        if(question.type === 'MultiChoice' &&  typeof question.answers[0] === 'string' && typeof question.choices[0] === 'string') {
            let arrayIsChoiced = question.choices.map(item => question.answers.includes(item));
            let newAnswer = question.choices.map((item, index) => {
                return ({
                    answer: item,
                    is_choice: arrayIsChoiced[index]
                })
            });

            let newChoices = question.choices.map(choice => {return {answer: choice, is_choice: false}});
            return {
                type   : question.type,
                index  : index,
                content: question.content,
                heading: question.heading,
                answers: newAnswer,
                choices: newChoices
            }
        }

        //data cu tu thoi dau cua survey
        if(question.type === 'Single' && typeof question.answers === 'string' && typeof question.choices[0] === 'string') {
            let indexAnswerChoice;
            for(let i = 0; i < question.choices.length; i++) {
                if(question.choices[i].toString() === question.answers.toString()) {
                    indexAnswerChoice = i;
                }
            }

            let newAnswer = {
                answer: question.answers,
                is_choice: indexAnswerChoice
            };

            let newChoices = question.choices.map(choice => {
                return {
                    answer: choice
                }
            });
            return {
                type   : question.type,
                index  : index,
                content: question.content,
                heading: question.heading,
                answers: newAnswer,
                choices: newChoices
            }
        }

        if (question.choices) {
            return {
                type   : question.type,
                index  : index,
                content: question.content,
                heading: question.heading,
                answers: getAnswerByQuestion(question),
                choices: question.choices.map(choice => {return {answer: choice.answer}})
            }
        }
        return {
            type   : question.type,
            index  : index,
            content: question.content,
            heading: question.heading,
            answers: getAnswerByQuestion(question)
        }
    });
}

function providerClearQuestions(recordToEdit) {
    const questions = recordToEdit.survey_template.questions;
    return {
        ...recordToEdit,
        survey_template: {
            ...recordToEdit.survey_template,
            questions: returnQuestionNewNotLogic(questions),
            flowedQuestions: returnQuestionNewNotLogic(questions)
        }
    };
}

function getAnswerByQuestion(question) {
    const answers = question.answers;
    switch (question.type) {
        case 'Media':
            if(answers.answer) {
                return answers
            }
            return {
                answer    : question.answers,
                typeAnswer: question.typeAnswer
            };
        case 'Single':
            let newIsChoice = answers.index === 0 ? answers.index :
                answers.index ? answers.index : answers.is_choice;
            return {
                answer   : answers.answer,
                is_choice: newIsChoice
            };
        case 'MultiChoice':
            if(typeof answers[0] === 'string' ) {
                let arrayIsChoiced = question.choices.map(item => question.answers.includes(item.answer));
                return question.choices.map((item, index) => {
                    return ({
                        answer: item.answer,
                        is_choice: arrayIsChoiced[index]
                    })
                });
            }
            return answers;
        default:
            return question.answers;
    }
}


export function conditionUpload(file) {
    return (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') && file.size < 10485760;

}
