import { GET_DETAIL_TEAM, GET_TEAMS_BETA, LOAD_TEAM, LOAD_TEAM_FOR_PAGE }   from "../actions/Team";
import { makeTeamService, teamFactory, teamService } from "../services";

export const teamMiddleware = (store) => next => action => {
    switch (action.type) {
        case GET_TEAMS_BETA:
            return teamService.getTeamsBeta().then(res => {
                next({
                    ...action,
                    ...res,
                })
            });
        case LOAD_TEAM :
            return teamService.loadTeams().then(teams => {
                let rawTeam = makeTeamService.makeTeamForTags(teams);
                next({
                    ...action,
                    allTeam: rawTeam,
                    listTeam: teams.map(team => {
                        return {
                            ...team,
                            isChecked: false
                        }
                    })
                })
            }).catch(error => console.log(error));

        case LOAD_TEAM_FOR_PAGE:
            return teamService.loadTeamsForPage(action.params || '').then(res => {
                let rawTeam = makeTeamService.makeTeamForTags(res.teams);
                next({
                    ...action,
                    teams: rawTeam,
                    totalPage: res.totalPage || 1,
                    currentPage: res.currentPage || 1
                })
            }).catch(error => console.log(error));
        case GET_DETAIL_TEAM :
            return teamService.getDetail(action.teamId).then(team => {
                let newUser = team.users.map(member => {
                    return {
                        ...member,
                        tagId: member.id,
                        tagType: "users",
                        tagContent: {
                            ...member,
                        },
                        key: `staff_id${member.id}`,
                        label: member.name,
                        value: `${member.name}${member.id}`,
                        type: 'Users',
                        isChecked: true
                    }
                });
                store.dispatch({
                    type: 'GROUP_USER',
                    users: newUser,
                });
                let newTeam = teamFactory.makeTeam({...team, users: newUser});
                next({
                    type: GET_DETAIL_TEAM,
                    team: newTeam
                })
            });

        // case CREATE_TEAM :
        //     return teamService.create(action.team).then(() => {
        //         store.dispatch({
        //             type: LOAD_TEAM,
        //         });
        //         store.dispatch({
        //             type: NOTIFICATION,
        //             typeNotification: 'success',
        //             message: 'Team.YouUpdateTeamAreSuccessful'
        //         });
        //     });
        // case EDIT_TEAM :
        //     return teamService.edit(action.teamId, action.team).then(() => {
        //         store.dispatch({
        //             type: NOTIFICATION,
        //             typeNotification: 'success',
        //             message: 'Team.YouUpdateTeamAreSuccessful'
        //         });
        //         // store.dispatch({
        //         //     type: LOAD_TEAM_FOR_PAGE
        //         // })
        //     });
        default:
            return next(action)
    }
};
