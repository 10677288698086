import React, {Component}                       from 'react';
import KirokuButton                             from "../../../ui/KirokuButton";
import KirokuInput                              from "../../../ui/KirokuInput";
import KirokuInputArea                          from "../../../ui/KirokuInputArea";
import {withNamespaces}                         from "react-i18next";
import connect                                  from "react-redux/es/connect/connect";
import {checkAllMember, checkMember, loadUsers} from "../../../actions/User";
import ModalSelectMember                        from "./ModalSelectMember";
import ModalSetRole                             from "./ModalSetRole";
import KirokuCardWeb                            from "../../../ui/KirokuCardWeb";
import KirokuTabLe                              from "../../../ui/KirokuTabLe";
import THeader                                  from "../../../ui/THeader";
import {createTeam}                             from "../../../actions/Team";
import TBody                                    from "../../../ui/TBody";
import KirokuDropdownClose                      from "../../../ui/KirokuDropdownClose";
import ModalDeleteMember                        from "./ModalDeleteMember";
import "../teams.css";
import {
    checkIsChecked, getListMemberSetRole, getListMemberTHisRemove,
    turnOnModalAddMember,
    turnOnModalDelete,
    turnOnModalSetRole,
}                                               from "../../../actions/EditorTeam";
import {KirokuLoading}                          from "../../../ui";
import {filePathService}                        from "../../../services";

@withNamespaces()
@connect(state => {
    return {
        users: state.KirokuAIUser.users,
        membersChoice: state.EditorTeam.membersChoice,
        statusModal: state.EditorTeam.showModalDelete,
        loading: state.Loading
    }
}, dispatch => {
    return {
        turnOnModalAddMember: () => {
            dispatch(turnOnModalAddMember())
        },
        turnOnModalSetRole: () => {
            dispatch(turnOnModalSetRole())
        },
        turnOnModalDelete: () => {
            dispatch(turnOnModalDelete())
        },
        checkIsChecked: () => {
            dispatch(checkIsChecked())
        },
        memberSelect: (index) => {
            dispatch(checkMember(index))
        },
        checkAll: (checked) => {
            dispatch(checkAllMember(checked))
        },
        createTeam: (team) => {
            dispatch(createTeam(team))
        },
        getUsers: () => {
            dispatch(loadUsers())
        },
        getListMemberTHisRemove: () => {
            dispatch(getListMemberTHisRemove())
        },
        getListMemberSetRole: () => {
            dispatch(getListMemberSetRole())
        }
    }
})

class TeamUpdateWeb extends Component {

    bulkActionItems = [
        {
            value: 'delete',
            component: (
                <div>{this.props.t('common.Delete')}</div>
            ),
            label: this.props.t('common.Delete')
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            team: this.props.team,
            name: '',
            description: '',
            members: [],
            errors: {},
        }
    }

    onChangeTeam(e) {
        let newTeam            = this.state.team;
        newTeam[e.target.name] = e.target.value;
        this.setState({
            team: newTeam
        });

    }

    onSubmit() {
        let team = {
            ...this.state.team,
            members: this.props.membersChoice
        };
        this.props.handleSubmit(team);
    }

    goBack() {
        this.props.history.goBack()
    }

    showModalAddMember() {
        this.props.turnOnModalAddMember();
        this.props.checkIsChecked();
    }

    showModalDelMember() {
        this.props.turnOnModalDelete();
        this.props.getListMemberTHisRemove();
    }

    showModalSetRole() {
        this.props.turnOnModalSetRole();
        this.props.getListMemberSetRole();
    }

    validateCreator(e) {
        this.setState({
            errors: {
                ...this.state.errors,
                [e.target.name]: !e.currentTarget.value.length
            }
        });
    }

    handleUpdate() {
        const {team} = this.state;
        return team.name.length === 0 ||
            team.description.length === 0 ||
            !Object.values(this.state.errors).every(item => !item)
    }

    render() {
        const {t, membersChoice} = this.props;
        const {team, errors}     = this.state;
        return (
            <div className='padding-responsive'>
                {!this.props.loading ?
                    <div>
                        <div className='d-flex justify-content-between pb-3'>
                            <KirokuButton color='white'
                                          onClick={this.goBack.bind(this)}>{t('common.back')}
                            </KirokuButton>
                        </div>
                        <KirokuCardWeb>
                            <div className='p-3'>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h6>{t('Team.AddTeam')}</h6>
                                        <div className='pt-2 pb-2'>{t('Team.TeamName')}</div>
                                        <KirokuInput value={team.name}
                                                     name='name'
                                                     onChange={this.onChangeTeam.bind(this)}
                                                     onBlur={this.validateCreator.bind(this)}
                                                     error={errors.name ? 'form-error' : ''}

                                        />
                                        <p hidden={!errors.name} style={{
                                            color: 'red',
                                            fontSize: 12
                                        }}>{t('Team.Team name must not null')}</p>
                                        <div className='pt-2 pb-2'>{t('common.Description')}</div>
                                        <KirokuInputArea name='description' value={team.description}
                                                         onChange={this.onChangeTeam.bind(this)}
                                                         onBlur={this.validateCreator.bind(this)}
                                                         error={errors.description ? 'form-error' : ''}
                                        />

                                        <p hidden={!errors.description} style={{
                                            color: 'red',
                                            fontSize: 12
                                        }}>{t('Team.Description must not null')}</p>
                                    </div>
                                </div>
                            </div>
                        </KirokuCardWeb>
                        <div className="pt-3">
                            <KirokuCardWeb>
                                <h6 className='p-3'>{t('common.Members')}</h6>
                                {membersChoice.length ?
                                    <div>
                                        <KirokuTabLe>
                                            <THeader>
                                                <tr>
                                                    <th>{t('common.Name')}</th>
                                                    <th>{t('common.Role')}</th>
                                                    <th>{t('common.Title')}</th>
                                                    <th>{t('common.WorkingForm')}</th>
                                                </tr>
                                            </THeader>
                                            <TBody>
                                                {membersChoice.map((member, index) =>
                                                    <tr key={index}>
                                                        <td>{member.name}</td>
                                                        <td>{t(`role.${member['role']}`)}</td>
                                                        <td>{member.title}</td>
                                                        <td>{member.working_form ? t(`common.${member.working_form}`): ''}</td>
                                                    </tr>
                                                )}
                                            </TBody>
                                        </KirokuTabLe>
                                        <div className='p-3 d-flex justify-content-between'>
                                            <KirokuDropdownClose
                                                style={{minWidth: 160}}
                                                location={'top'}
                                                title={t('common.BulkAction')}
                                                items={this.bulkActionItems}
                                                onSelect={event => {
                                                    if (event.kirokuSelected.value === 'delete') {
                                                        this.showModalDelMember();
                                                    } else {
                                                        this.showModalSetRole()
                                                    }
                                                }}
                                            />
                                            <KirokuButton color='success'
                                                          onClick={this.showModalAddMember.bind(this)}>
                                                {t('Team.AddMember')}
                                            </KirokuButton>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <KirokuTabLe>
                                            <THeader>
                                                <tr>
                                                    <th>{t('common.Name')}</th>
                                                    <th>{t('common.Role')}</th>
                                                    <th>{t('common.Title')}</th>
                                                    <th>{t('common.WorkingForm')}</th>
                                                </tr>
                                            </THeader>
                                        </KirokuTabLe>
                                        <div className='fix-center pt-3'>
                                            <div>
                                                <div className='teams-image'
                                                     style={{backgroundImage: `url(${filePathService.resolveFilePath('profile-ic.svg')})`}}/>
                                            </div>
                                            <div>
                                                <p className='text-center'>{t('Team.Opps')}</p>
                                                <p className='text-center'>{t('Team.PleaseTeam')}</p>
                                            </div>
                                        </div>
                                        <div className="pt-3 pb-3 text-center">
                                            <KirokuButton color='success'
                                                          onClick={this.showModalAddMember.bind(this)}>
                                                {t('Team.AddMember')}
                                            </KirokuButton>
                                        </div>
                                    </div>}
                            </KirokuCardWeb>
                        </div>
                        <ModalDeleteMember/>
                        <ModalSelectMember/>
                        <ModalSetRole/>
                        <div className='fix-center'>
                            <div className='pt-3 pb-3' style={{width: 200}}>
                                <div className='d-flex justify-content-between'>
                                    <KirokuButton color={'white'}
                                                  onClick={this.goBack.bind(this)}>{t('common.Cancel')}</KirokuButton>
                                    <KirokuButton color={'primary'} disabled={this.handleUpdate()}
                                                  onClick={this.onSubmit.bind(this)}>{t('common.Update')}</KirokuButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <KirokuLoading/>}
            </div>
        )
    }
}

export default TeamUpdateWeb;