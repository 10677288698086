import GenericQuestion from "./GenericQuestion";

class InputText extends GenericQuestion {

    answer(answer) {
        if(!answer) {
            this.payload.answers = '';
            return this.getNext('');
        }
        this.payload.answers = answer;
        return this.getNext(answer);
    }

    getNext(answer) {

        if(!answer) {
            return this.payload.index + 1;
        }

        if(this.payload.nextQuestion === 0) {
            return this.payload.nextQuestion;
        }

        return this.payload.nextQuestion || this.payload.index + 1
    }
}
export default InputText;
