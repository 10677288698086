import React, { Component } from "react";

export default class KirokuCardWeb extends Component {
    render() {
        return (
            <div className='card card-kiroku-web' {...this.props}>
                {this.props.children}
            </div>
        )
    }
}