import React, {Component}               from 'react';
import Survey                           from './Survey';
import {connect}                        from 'react-redux';
import {withNamespaces}                 from 'react-i18next';
import {KirokuButton, KirokuPageLoader} from '../../../../ui';
import AboutSurvey                      from '../AboutSurvey';
import AboutSurveyReview                from '../../ReviewRecording/AboutSurvey';
import {ViewQuestioned}                                     from '../../ReviewRecording/ViewQuestioned';
import {createRecord}                                                                from '../../../../actions/Record';
import {
    actionBackAnswer, actionBackScreenDetail,
    actionBeginAnswer,
    showConfirmExit,
    turnOffFlagLoadDraftMobile
}                                                                                    from '../../../../actions/TakeSurvey';
import ModalNotificationConfirmExit                                                  from './ModalNoticationExit';
import { saveDraftRecoding }                                                         from '../../../../actions/Draft';
import { deleteVisiblyQuestion, getAnswersToQuestion, getAnswerToQuestionFullLogic } from '../../sevicesInRecord';
import moment                                                                        from "moment";
import {recordService}                                                               from "../../../../services";
import {NOTIFICATION}                                                                from "../../../../actions/Notification";
import ModalNotificationDraft                                                        from "./ModalNotificationDraft";

@withNamespaces()
@connect(state => {
    return {
        dataDraftRecording: state.KirokuAIDraftRecording.dataDraftRecording,
        flagLoadDraft     : state.TakeSurveyMobile.flagLoadDraft,
        beginAnswer       : state.TakeSurveyMobile.beginAnswer,
        reviewRecorded    : state.TakeSurveyMobile.reviewRecorded,
        answeredQuestions : state.TakeSurveyMobile.recorded,
        takeRecordPage: state.KirokuAIRecord.takeRecordPage
    }
}, dispatch => {
    return {
        createRecord          : async (dataRecord) => {
            dispatch(createRecord(dataRecord))
        },
        actionBackScreenDetail: () => {
            dispatch(actionBackScreenDetail())
        },
        actionBeginAnswer     : () => {
            dispatch(actionBeginAnswer())
        },

        actionBackAnswer: () => {
            dispatch(actionBackAnswer())
        },

        showConfirmExit: () => {
            dispatch(showConfirmExit())
        },

        saveDraft          : (dataDraft) => {
            dispatch(saveDraftRecoding(dataDraft))
        },
        turnOfFlagLoadDraft: () => {
            dispatch(turnOffFlagLoadDraftMobile())
        },
        dispatchNotificationDraft: () => {
            dispatch({
                type: NOTIFICATION,
                typeNotification: 'success',
                message: 'createRecord.SaveDraftSuccess'
            })
        },pageTakeRecord: () => {
            dispatch({
                type: 'TAKE_RECORD_PAGE'
            })
        },
        trackingQuickCreateRecord : (record) => {
            dispatch({
                type : "RECORD_QUICK_CREATED_TRACKING",
                record
            });
        },
        trackingQuickDraftNewRecord : (record) => {
            dispatch({
                type : "NEW_RECORD_QUICK_DRAFTED_TRACKING",
                record
            });
        },
    }
})
class CreateRecordMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingSubmit   : false,
            loadingSaveDraft: false,
            statusClick: false,
            serviced_at: null,
            has_time: false
        };
    }

    handleBeginAnswer() {
        this.props.actionBeginAnswer();

    }

    handleCancel() {
        this.props.showConfirmExit()
    }

    handleBack() {
        this.props.actionBackAnswer();
        if(this.state.loadingSubmit) {
            this.setState({
                loadingSubmit: false
            });
        }
    }

    handleSubmit() {
        const {survey, answeredQuestions} = this.props;
        const dataRecord = {
            surveyTemplate: {
                ...this.props.survey,
                questions      : deleteVisiblyQuestion(getAnswerToQuestionFullLogic(answeredQuestions, survey.questions)),
                flowedQuestions: deleteVisiblyQuestion(answeredQuestions)
            },
            customer      : this.props.customer,
            hasDraft      : null,
            draftBy       : null,
            draftedAt     : null,
            serviced_at   : this.state.serviced_at,
            has_time: this.state.has_time,
            answers       : getAnswersToQuestion(answeredQuestions)
        };
        this.setState({
            loadingSubmit: true
        });
        this.props.createRecord(dataRecord);
        this.props.trackingQuickCreateRecord(dataRecord);
    }

    handleSaveDraft(answeredQuestions) {
        const {survey, customer} = this.props;
        const dataRecord = {
            surveyTemplate: {
                ...this.props.survey,
                questions      : deleteVisiblyQuestion(getAnswerToQuestionFullLogic(answeredQuestions, survey.questions)),
                flowedQuestions: deleteVisiblyQuestion(answeredQuestions),
            },
            customer      : customer,
            hasDraft      : 'draft',
            draftBy       : JSON.parse(localStorage.getItem('profile')).id,
            draftedAt     : moment().unix(),
            serviced_at   : this.state.serviced_at,
            has_time: this.state.has_time,
            answers       : getAnswersToQuestion(answeredQuestions)
        };
        this.setState({
            loadingSaveDraft: true
        });

        recordService.createSurveyRecord(dataRecord).then(res => {
            this.setState({
                loadingSaveDraft: false
            });
            this.props.dispatchNotificationDraft();
            this.props.trackingQuickDraftNewRecord(dataRecord);
            window.history.previous = window.location.pathname;
            this.props.pageTakeRecord();
            this.props.history.push(`/records/take-record-after-save-draft/${res.data.id}`);
        }).catch(error => console.log(error));
    }

    onChangeDate = (serviced_at, hasTime) => {
        this.setState({
            serviced_at: hasTime ? serviced_at : moment.unix(serviced_at).startOf('day').unix(),
            has_time: hasTime
        })
    };

    componentWillMount() {
        if(this.props.takeRecordPage) {
            this.setState({
                statusClick : true
            });
            setTimeout(() => this.setState({
                statusClick: false
            }), 2500);
        }
    }

    componentWillUnmount() {
        this.props.turnOfFlagLoadDraft();
        this.props.actionBackScreenDetail();
        this.props.actionBackAnswer();
    }

    handleSaveStatusRecord() {
        this.Survey.autoSaveDraft();
        this.setState({
            statusClick: !this.state.statusClick
        }, () => {
            setTimeout(() => this.setState({
                statusClick: false
            }), 3500);
        })
    }

    // handleAutoSaveBackupData(dataBackup) {
    //     const requestSaveDraft = {
    //         customer : this.props.customer,
    //         // survey   : this.props.survey,
    //         dataDraft: dataBackup,
    //         create_at: moment().unix()
    //     };
    //     this.props.saveDraft(requestSaveDraft);
    // }

    render() {
        const {
                  survey, customer, t,
                  flagLoadDraft,
                  dataDraftRecording,
                  beginAnswer, reviewRecorded,
                  answeredQuestions
              } = this.props;

        const {
                  loadingSaveDraft,
                  loadingSubmit
              } = this.state;
        return (
            <div>
                <div hidden={reviewRecorded}>
                    <div className="p-2" style={{backgroundColor: '#eef3f6'}}>
                        <div className="status-survey d-flex justify-content-between">
                            <KirokuButton color={'light'} onClick={this.handleCancel.bind(this)}>
                                <span>{t('createRecord.cancel')}</span>
                            </KirokuButton>
                            <div className="status-survey-content">
                                <KirokuButton color={'success'} disabled={this.state.statusClick || loadingSaveDraft || !beginAnswer} onClick={this.handleSaveStatusRecord.bind(this)}>{t('createRecord.SaveDraft')}</KirokuButton>
                            </div>
                        </div>
                        <ModalNotificationConfirmExit history={this.props.history} customerId={customer.id}/>
                    </div>

                    <AboutSurvey hidden={beginAnswer}
                                 survey={survey}
                                 screen={'takeRecord'}
                                 hasTime={false}
                                 customer={customer} isRecord/>
                    <Survey
                            onRef={ref => { this.Survey = ref; }}
                            hidden={!beginAnswer}
                            survey={survey}
                            customer={customer}
                            history={this.props.history}
                            statusRecord={{
                                statusDraft: null,
                                backup: flagLoadDraft ? dataDraftRecording.dataDraft: null
                            }}
                            keyLoadData={flagLoadDraft ? 'backup': null}
                            saveDraft={this.handleSaveDraft.bind(this)}
                            // saveBackupData={this.handleAutoSaveBackupData.bind(this)}
                            saveBackupData={() => {}}
                    />

                    <div className="action-record" hidden={beginAnswer}>
                        <KirokuButton color={'primary'} style={{width: '100%', borderRadius: 0, height: 42}}
                                      onClick={this.handleBeginAnswer.bind(this)}>
                            <span className="text-tranf">
                                {t('createRecord.next')}
                            </span>
                        </KirokuButton>
                    </div>
                </div>

                <div hidden={!reviewRecorded}>
                    {answeredQuestions ? <div>
                        <div className='survey'>
                            <div className="keeping-about-survey" style={{ backgroundColor: '#FFFFFF', top: 0 }}>
                                <AboutSurveyReview onChangeDate={this.onChangeDate.bind(this)} survey={survey}
                                                   isMobile
                                                   isRecord
                                                   customer={customer}/>
                            </div>
                            <div className='wraper-question'>
                                <div className='wraper-question'>
                                    <div className="background-question-review">
                                        <div className='info-question'>
                                            <div className='content-name-question d-flex'>
                                    <span style={{ wordBreak: 'keep-all' }}
                                          className='title-record-info'>{t('createRecord.Description')}&nbsp;</span>
                                                <pre style={{
                                                    wordWrap  : 'break-word',
                                                    whiteSpace: 'pre-wrap',
                                                }}>
                                        {survey.description}
                                    </pre>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ViewQuestioned questions={answeredQuestions} />
                            <div className="action-record" style={{zIndex: 998}}>
                                <div>
                                    <KirokuButton color={'light'} onClick={this.handleBack.bind(this)}>
                                        <span className="text-tranf">{t('createRecord.back')}</span>
                                    </KirokuButton>
                                    {loadingSubmit ?
                                        <KirokuButton color={'primary'} disabled>
                                            <span className="text-tranf">{t('createRecord.Submit')}</span>
                                        </KirokuButton> :
                                     <KirokuButton color={'primary'} onClick={this.handleSubmit.bind(this)}>
                                         <span className="text-tranf">{t('createRecord.Submit')}</span>
                                     </KirokuButton>
                                    }
                                </div>
                            </div>
                        </div>
                    </div> : <KirokuPageLoader/>
                    }
                </div>
                <ModalNotificationDraft/>
            </div>
        );
    }
}

export default CreateRecordMobile;
