import React, {Component} from 'react';
import InputText          from './InputText';
import MediaFile          from './MediaFile';
import Message            from './Message';
import SingleChoice       from "./SingleChoice";
import YesNo              from "./YesNo";
import MultiChoice        from "./MultiChoice";
import DateTime           from "./DateTime";
import Time               from "./Time";
import OnlyDate           from "./OnlyDate";
import Number             from "./Number";
import {connect}          from "react-redux";
import {
    changeTypeQuestion,
    createQuestion,
    editQuestionBack,
    editQuestion,
    backSurvey,
    backToAddQuestion, changeBackQuestion
} from "../../../../actions/SurveyMobile";
import KirokuButton       from "../../../../ui/KirokuButton";
import {Link}             from "react-router-dom";
import {withNamespaces}   from "react-i18next";
import _                  from 'lodash';

@withNamespaces()
@connect(state => {
    return {
        type: state.KirokuSurveyTemplateMobile.typeQuestion,
        questions: state.KirokuSurveyTemplateMobile.questions,
        questionBack: state.KirokuSurveyTemplateMobile.questionBack,
        backToSurvey: state.KirokuSurveyTemplateMobile.backToSurvey,
        error: state.KirokuSurveyTemplateMobile.errorQuestion,
        errorSurvey: state.KirokuSurveyTemplateMobile.errorSurvey
    }
}, dispatch => {
    return {
        changeTypeQuestion: (type) => {
            dispatch(changeTypeQuestion(type))
        },

        createQuestion: (question) => {
            dispatch(createQuestion(question))
        },

        backSurvey: () => {
            dispatch(backSurvey())
        },

        onChangeQuestion: async (questionBack, error) => {
            dispatch(editQuestionBack(questionBack, error))
        },

        backToAddQuestion: () => {
            dispatch(backToAddQuestion())
        },

        updateQuestion: (questionBack) => {
            dispatch(editQuestion(questionBack))
        },
        changeBackQuestion: (questionToBack) => {
            dispatch(changeBackQuestion(questionToBack))
        }
    }
})
class QuestionBack extends Component {

    questionMap = {
        InputText: InputText,
        Single: SingleChoice,
        YesNo: YesNo,
        Message: Message,
        MultiChoice: MultiChoice,
        Date: DateTime,
        Media: MediaFile,
        Time : Time,
        OnlyDate : OnlyDate,
        Number  : Number
    };

    onSelectQuestion = (e) => {
        let type = e.kirokuSelected.value;
        this.props.updateQuestion({...this.props.questionBack, type: type});
    };

    disabledReview = () => {
        const {questionBack} = this.props;
        if(questionBack.type === 'Single' || questionBack.type === 'MultiChoice') {
            const IsChoiceQuestion = _.findIndex(questionBack.choices, {'answer': ''}) === -1;
            return !Object.values(this.props.errorSurvey).every(error => !error) || !questionBack.content || !IsChoiceQuestion;
        }
        return !Object.values(this.props.errorSurvey).every(error => !error) || !questionBack.content;
    };

    showReview = () => {

    };

    disabledNewQuestion = () => {
        const {questionBack} = this.props;
        switch (questionBack.type) {
            case 'Single':
            case 'MultiChoice' :
                const errorChoices = questionBack.choices.every(choice => choice.answer !== '');
                return (!questionBack.content || !errorChoices);
            default :
                return !questionBack.content;
        }
    };

    createQuestion = () => {
        this.props.backToAddQuestion();
    };

    onChangeHeading = (heading) => {
        this.props.updateQuestion({...this.props.questionBack, heading: heading});
    };

    onChangeContent = (content) => {
        this.props.updateQuestion({...this.props.questionBack, content: content});
    };

    removeAnswer(e, index) {
        let {choices} = this.props.questionBack;
        if (choices.length > 2) {
            let newChoices = choices.map(choice => ({...choice}));
            newChoices.splice(index, 1);
            this.props.updateQuestion({...this.props.questionBack, choices: newChoices})
        }
    }

    addAnswer() {
        let choices = this.props.questionBack.choices.map(choice => ({...choice}));
        choices.push({answer: ''});
        let errorChoices = choices.every(choice => choice.answer !== '');
        this.props.onChangeQuestion({choices: choices}, {answer: errorChoices}).then(() => {
           return this.getLastChoiceElement(choices);
        });
    }

    getLastChoiceElement = (choices) => {
        window.scrollTo(0,document.body.scrollHeight);
        const element = document.querySelectorAll('#choicesQuestions > div > .d-flex > input[name="answer"]')[choices.length-1];
        element.focus();
    };

    answerOnchange(answer, index) {
        let newChoices = this.props.questionBack.choices.map(choice => ({...choice}));
        newChoices[index].answer = answer;
        let errorChoices = newChoices.every(choice => choice.answer !== '');
        this.props.onChangeQuestion({choices: newChoices}, {answer: errorChoices});
        this.props.updateQuestion({...this.props.questionBack, choices: newChoices})
    }

    backQuestion = () => {
        this.props.backQuestion();
    };

    nextQuestionOrder() {
        const {questions, questionBack} = this.props;
        const questionNext = questions[questionBack.index + 1];
        this.props.changeBackQuestion(questionNext)
    }
    render() {
        const {questionBack, t} = this.props;
        const Question = this.questionMap[questionBack.type];
        const numberQuestion = questionBack.index + 1;
        return (
            <div>
                <div className='create-header'>
                    <div className='d-flex justify-content-between padding-responsive'>
                        <h4>
                            {t('createSurvey.QuestionOrder', {index: numberQuestion})}
                        </h4>
                        <Link to={'/surveys/review-survey'}>
                            <KirokuButton color='success' disabled={this.disabledReview()}
                                          onClick={this.showReview.bind(this)}>
                                {t('createSurvey.Review')}
                            </KirokuButton>
                        </Link>
                    </div>
                </div>
                <Question type={questionBack.type}
                          heading={questionBack.heading || ''}
                          content={questionBack.content || ''}
                          choices={questionBack.choices}
                          onChangeHeading={this.onChangeHeading.bind(this)}
                          onChangeContent={this.onChangeContent.bind(this)}
                          answerOnchange={this.answerOnchange.bind(this)}
                          addAnswer={this.addAnswer.bind(this)}
                          removeAnswer={this.removeAnswer.bind(this)}
                          onSelect={this.onSelectQuestion.bind(this)}
                          isCreateSurvey
                />
                <div className='action-record'>
                    <div className="row">
                        <KirokuButton
                            disabled={this.disabledNewQuestion()}
                            color={'primary'}
                            style={{borderRadius: 0, width: '100%', marginBottom: 3}}
                            onClick={this.createQuestion.bind(this)}
                        >
                            {t('createSurvey.NewQuestion')}
                        </KirokuButton>
                        <div className='col-6 p-0'>
                            <KirokuButton style={{width: '100%', borderRadius: 0}} color={'light'}
                                          onClick={this.backQuestion.bind(this)}>
                                {t('createSurvey.Previous')}
                            </KirokuButton>
                        </div>
                        <div className='col-6 p-0'>
                            <KirokuButton style={{width: '100%', borderRadius: 0}} color={'light'}
                                          onClick={this.nextQuestionOrder.bind(this)}>
                                {t('common.Next')}
                            </KirokuButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default QuestionBack;
