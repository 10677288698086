import React, { Component }                                                from 'react';
import KirokuCardDeletedScreen
                                                                           from '../../../../ui/KirokuCardDeletedScreen';
import { KirokuHighLightText, KirokuLabel, KirokuLoading, SearchNotFound } from '../../../../ui';
import { withNamespaces }                                                  from 'react-i18next';
import { Link }                                                            from 'react-router-dom';
import { connect }                                                         from 'react-redux';
import FormatTimestamp                                                     from '../HelperComponents/FormatTimestamp';
@withNamespaces()
@connect(state => {
    return {
        keywordsEd : state.RecordsBeta.keywordsEd,
        records    : state.RecordsBeta.recordsBeta,
        useSortBy  : state.RecordsBeta.useSortBy,
        useOrderBy : state.RecordsBeta.useOrderBy,
        status     : state.RecordsBeta.statusRecord,
        customerIds: state.RecordsBeta.customerIds,
        servicedAt : state.RecordsBeta.servicedAt,
        updatedAt  : state.RecordsBeta.updatedAt,
        templateIds: state.RecordsBeta.templateIds,
        updateByIds: state.RecordsBeta.updateByIds,
        containRead: state.RecordsBeta.typeSearchRead ==='ALL',
        loading    : state.Loading,
        isFinish   : state.RecordsBeta.isFinish
    };
}, () => {
    return {};
})

class TableRecords extends Component {

    handleCheckRecordRead = (record) => {
        const profile = localStorage.getItem('profile');
        const checkRecordHasRead = record.read_user.split(",").filter((item) => item.length);
        const convertProfile = JSON.parse(profile);
        return checkRecordHasRead.includes(convertProfile.id.toString());
    };

    render() {
        const {
                  t, records, keywordsEd, loading, isFinish
        } = this.props;
        return (
            isFinish ? !loading ?
                <div className='bg-white'>
                    <KirokuCardDeletedScreen>
                        { records.length ? records.map((record, index) => (

                            <Link
                                    key={index}
                                    to={{
                                        pathname: "/records-beta-detail",
                                        search: `id=${record.id}`
                                    }}
                                    onClick={() => {window.history.previous = window.location.pathname}}
                            >
                                <div className={`${!this.handleCheckRecordRead(record) ? 'border-read-record' : ''}`}>
                                    <div className={ ` pl-3 font-customer-name ${ index === 0 ? 'pt-3' : 'pt-1' }` }>
                                        <div className={ 'label-record-beta' } style={ { margin: 0 } }>
                                            <span className="label-name">{ record.customer.name }</span>
                                            <div className="pr-3 pt-1 font-label-draft" style={ { float: 'right' } }
                                                 hidden={ !record.drafted_at }>
                                                <KirokuLabel color={ 'warning' }
                                                             style={ {
                                                                 width: '94px', height: '30px',
                                                                 border               : 'solid 1px #EA9B3F',
                                                                 backgroundColor      : '#EA9B3F',
                                                             } }
                                                >{ t('RecordsBeta.draft') }</KirokuLabel>
                                            </div>
                                            <span className="font-name-san">{ t('RecordsBeta.noun') }</span>
                                        </div>
                                        </div>
                                    <div className='pl-3 label-record-beta' style={ { wordBreak: 'break-word' } }>
                                        <span className='label-name'> { record.title } </span>
                                    </div>
                                    <div className={ 'pl-3 pt-2 pb-3' }>
                                        <div className=' d-flex justify-content-start m-0' style={ { color: '#202528' } }>
                                            <div style={ { textAlign: 'end', minWidth: 117 } }
                                            >
                                                { t('common.ServiceTime') }:
                                            </div>
                                            <div className='pl-2'>
                                                <FormatTimestamp className={'font-text-record-card'} timestampUnix={ record.serviced_at } hasTime={record.has_time}/>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-start m-0' style={ { color: '#202528' } }>
                                            <div style={ { textAlign: 'end', minWidth: 117 } }
                                            >
                                                { keywordsEd ? `${ t('RecordsBeta.updatedDate') }:` : `${ t('RecordsBeta.createdAtAndUpdatedAt') }:` }
                                            </div>
                                            <div className='pl-2'>
                                                <FormatTimestamp className={'font-text-record-card'} timestampUnix={ record.last_action_at } hasTime={true}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pl-3 pb-3 pt-1 font-highlight-keyword "
                                         hidden={ !keywordsEd }
                                         style={ { wordBreak: 'break-word' } }>
                                        { record.highlight ? <div
                                                dangerouslySetInnerHTML={ { __html: record.highlight['answer_search.answer'] }}/> :
                                            <KirokuHighLightText
                                                className={ 'background-highText-record-beta' }
                                                searchWords={ [`${ keywordsEd }`] }
                                                textToHighlight={ record['answer_search'] }
                                            />}
                                    </div>
                                    <hr className='m-0'/>
                                </div>
                            </Link>
                        )) : <SearchNotFound/> }
                    </KirokuCardDeletedScreen>
                </div> : <KirokuLoading/>: <KirokuLoading/>
        );
    }
}

export default TableRecords;
