import * as React  from 'react';
import { connect } from 'react-redux';

export const withPolling = ( lockableType, duration = 2000) => Component =>  {
    const Wrapper = () => (
        @connect(state => {
                return {
                    isCalling : state.KirokuLockInfo.isCalling
                };
            },
            dispatch => {
                return {
                    pollingAction: (lockableType, recordId) => {
                        dispatch({
                            type: 'LOCK_INFORMATION',
                            lockableType, recordId
                        });
                    },
                    pollingActionFirst: (lockableType, recordId) => {
                        dispatch({
                            type: 'LOCK_INFORMATION_FIRST',
                            lockableType, recordId
                        });
                    },
                };
            })
        class extends React.Component {
            componentDidMount() {
                const recordId = this.props.match.params.recordId;
                this.props.pollingActionFirst(lockableType, recordId);
                this.dataPolling = setInterval(
                    () => {
                        if(this.props.isCalling) {
                            this.props.pollingAction(lockableType, recordId);
                        }
                    },
                    duration);
            }

            componentWillUnmount() {
                clearInterval(this.dataPolling);
            }

            render() {
                return <Component {...this.props}/>;
            }
        });
    return Wrapper();
};

