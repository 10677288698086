import React, { Component }                                              from 'react';
import { KirokuButton, KirokuModal, KirokuModalBody, KirokuModalFooter } from "../../ui";
import { withNamespaces }                                                from "react-i18next";

@withNamespaces()
class ModalConfirmOverwriteAddress extends Component {

    closeModal() {
        this.props.closeModalConfirmOverwrite();
    }

    handleConfirmOverwrite() {
        this.props.handleConfirmOverwrite();
    }

    render() {
        const {show, t, addr} = this.props;
        return (
            <KirokuModal show={show} onClick={this.closeModal.bind(this)}>
                <KirokuModalBody>
                    <div style={{
                        padding: '16px 15px 15px 22px'
                    }}>
                        <div
                            className={'text-question-confirm-addr-header'}>{t('CustomerEditor.Is it OK to overwrite address field?')}</div>
                        <div
                            className={'text-question-confirm-addr'}>{t('CustomerEditor.Current Inputted Address')}</div>
                        <div style={{fontSize: 16}}>{addr.old}</div>
                        <div
                            className={'text-question-confirm-addr'}>{t('CustomerEditor.We will overwrite this address')}</div>
                        <div style={{fontSize: 16}}>{addr.new}</div>
                    </div>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <div className="d-flex justify-content-around">
                        <KirokuButton color={'white'} className={'btn-cancel-confirm-overwrite-addr'}
                                      onClick={this.closeModal.bind(this)}>
                            <div style={{color: '#1891eb'}}>
                                {t('common.Cancel')}
                            </div>
                        </KirokuButton>
                        <div style={{width: 100}}/>
                        <KirokuButton color={'primary'}
                                      onClick={this.handleConfirmOverwrite.bind(this)}
                        >{t('CustomerEditor.Overwrite')}</KirokuButton>
                    </div>
                </KirokuModalFooter>
            </KirokuModal>
        )
    }
}

export default ModalConfirmOverwriteAddress;
