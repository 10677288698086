import React, {Component}               from 'react';
import KirokuButton                     from "../../../../ui/KirokuButton";
import KirokuInput                      from "../../../../ui/KirokuInput";
import KirokuInputArea                  from "../../../../ui/KirokuInputArea";
import {connect}                        from "react-redux";
import {
    changeBackQuestion,
    changeTypeQuestion,
    editSurvey,
    resetStateCreateSurveyMobile
} from "../../../../actions/SurveyMobile";
import {Link}                           from "react-router-dom";
import {withNamespaces}                 from "react-i18next";

@withNamespaces()
@connect(state => {
    return {
        title: state.KirokuSurveyTemplateMobile.title,
        form_number: state.KirokuSurveyTemplateMobile.form_number,
        description: state.KirokuSurveyTemplateMobile.description,
        error: state.KirokuSurveyTemplateMobile.errorSurvey,
        review: state.KirokuSurveyTemplateMobile.showReview,
        questions: state.KirokuSurveyTemplateMobile.questions,
    }
}, dispatch => {
    return {
        editSurveyTemplate: (survey, error) => {
            dispatch(editSurvey(survey, error))
        },
        changeTypeQuestion: (type) => {
            dispatch(changeTypeQuestion(type))
        },
        changeBackQuestion: (questionToBack) => {
            dispatch(changeBackQuestion(questionToBack))
        },
        resetStateCreateSurveyMobile: () => {
          dispatch(resetStateCreateSurveyMobile())
        }
    }
})
class SurveyMobile extends Component {

    state = {
        errorFormNumber : false
    };

    showReview = () => {

    };

    disabledReview = () => {
        const {error} = this.props;
        return !Object.values(error).every(error => !error);
    };

    onChangeTitle = (e) => {
        const title = e.target.value;
        this.props.editSurveyTemplate({title: title}, {title: !title.length});

    };

    onChangeDescription = (e) => {
        const description = e.target.value;
        this.props.editSurveyTemplate({description: description}, {description: !description.length});
    };

    handleNextQuestion = () => {
        this.props.changeTypeQuestion('InputText');
    };

    checkValidationFormNumber = (e) => {
        const error = this.checkErrorFormNumber(e.currentTarget.value);
        this.setState({
            errorFormNumber: error
        });
    };

    checkErrorFormNumber = (number) => {
        if(number.length === 1) {
            this.setState({errorFormNumber: true});
            return 'errorFormNumber';
        }
        return '';
    };

    cancel = () => {
        this.props.resetStateCreateSurveyMobile();
        this.props.history.push('/surveys');
    };

    onChangeFormNumber = (e) => {
        let form_number = e.target.value.replace(/[^0-9]/g, '');
        this.props.editSurveyTemplate({form_number: form_number}, {form_number: isNaN(form_number) || (form_number && form_number.length !== 2)});

    };

    actionNextQuestion() {
        const firstQuestion = this.props.questions[0];
        this.props.changeBackQuestion(firstQuestion);
        this.props.changeTypeQuestion(firstQuestion.type)
    }

    render() {
        const {t, questions} = this.props;
        // const {errorFormNumber} = this.state;
        return (
            <div className='bg-white'>
                <div className='create-header'>
                    <div className='d-flex justify-content-between padding-responsive'>
                        <h4>{t('createSurvey.Summary')}</h4>
                        <Link to={'/surveys/review-survey'}>
                            <KirokuButton color='success' disabled={this.disabledReview()}
                                          onClick={this.showReview.bind(this)}>
                                {t('createSurvey.Review')}
                            </KirokuButton>
                        </Link>
                    </div>
                </div>
                <div className='padding-responsive'>
                    <p>{t('createSurvey.Title')}</p>
                    <KirokuInput name={'title'}
                                 value={this.props.title}
                                 onChange={this.onChangeTitle.bind(this)}
                    />
                    {/*<p className='pt-3'>{t('createSurvey.FormNumber')}</p>*/}
                    {/*<KirokuInput name={'form_number'}*/}
                    {/*             value={this.props.form_number}*/}
                    {/*             maxLength="2"*/}
                    {/*             onChange={this.onChangeFormNumber.bind(this)}*/}
                    {/*             onBlur={this.checkValidationFormNumber.bind(this)}*/}
                    {/*             error={errorFormNumber ? 'form-error' : ''}*/}
                    {/*/>*/}
                    {/*<p hidden={!errorFormNumber} style={{ fontSize : 12, color: "red"}}>{t(`staff.${errorFormNumber}`)}</p>*/}
                    <p className='pt-3'>{t('createSurvey.Description')}</p>
                    <KirokuInputArea name={'description'}
                                     value={this.props.description}
                                     onChange={this.onChangeDescription.bind(this)}
                    />
                </div>
                <div className='action-record'>
                    <div className="row" >
                        <KirokuButton
                            onClick={this.handleNextQuestion.bind(this)}
                            // disabled={errorFormNumber}
                            color={'primary'} style={{width: '100%', borderRadius: 0, height: '100%', marginBottom: 3}}>
                            {t('createSurvey.StartCreateQuestion')}
                        </KirokuButton>

                        <div className='col-6 p-0'>
                            <KirokuButton onClick={this.cancel.bind(this)} style={{width: '100%', borderRadius: 0, height: '100%'}} color={'light'}>
                                {t('createSurvey.Cancel')}
                            </KirokuButton>
                        </div>
                        <div className='wrap-next-question col-6 p-0'>
                            <KirokuButton
                                // disabled={!questions.length || errorFormNumber}
                                disabled={!questions.length}
                                onClick={this.actionNextQuestion.bind(this)}
                                style={{width: '100%', borderRadius: 0, height: '100%'}}>
                                {t('common.Next')}
                            </KirokuButton>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default SurveyMobile;
