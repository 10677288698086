export const CREATE_QUESTION = 'CREATE_QUESTION';
export const EDITOR_QUESTION = 'EDITOR_QUESTION';
export const EDITOR_SURVEYTEMPLATE = 'EDITOR_SURVEYTEMPLATE';
export const ADD_LOGIC = 'ADD_LOGIC';
export const ADD_LOGIC_YES_NO = 'ADD_LOGIC_YES_NO';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const FOCUS_QUESTION = 'FOCUS_QUESTION';
export const SHOW_REVIEW = 'SHOW_REVIEW';
export const DETAIL_SURVEY = 'DETAIL_SURVEY';
export const EDIT_TYPE_QUESTION = 'EDIT_TYPE_QUESTION';
export const ERROR_SURVEY = 'ERROR_SURVEY';
export const ERROR_QUESTION = 'ERROR_QUESTION';
export const RESET = 'RESET';
export const UP_QUESTION = 'UP_QUESTION';
export const DOWN_QUESTION = 'DOWN_QUESTION';
export const SAVE_QUERY_CONDITION_SURVEY = 'SAVE_QUERY_CONDITION_SURVEY';
export const REMOVED_QUERY_SURVEY = 'REMOVED_QUERY_SURVEY';
export const TURN_MODAL_EDIT_QUESTION_TEMPLATE = 'TURN_MODAL_EDIT_QUESTION_TEMPLATE';
export const PUSH_QUESTION_EDIT_IN_STORE = 'PUSH_QUESTION_EDIT_IN_STORE';
export const COPY_QUESTION_FOR_TEMPLATE = 'COPY_QUESTION_FOR_TEMPLATE';
export const CHANGE_UNREQUITED_QUESTION = 'CHANGE_UNREQUITED_QUESTION';

export function removedQueryCondition(conditionRemoved) {
    return {
        type : REMOVED_QUERY_SURVEY,
        conditionRemoved
    }
}

export function upQuestion(currentIndex, nextIndex) {
    return {
        type : UP_QUESTION,
        currentIndex,
        nextIndex
    }
}

export function downQuestion(currentIndex, nextIndex) {
    return {
        type : DOWN_QUESTION,
        currentIndex,
        nextIndex
    }
}

export function resetStateSurvey() {
    return {
        type: RESET
    }
}

export function throwErrorSurvey(name, value) {
    return {
        type: ERROR_SURVEY,
        name,
        value,
    }

}

export function throwErrorQuestion(name, value) {
    return {
        type: ERROR_QUESTION,
        name,
        value
    }
}

export function editTypeQuestion(question, index) {
    return {
        type: EDIT_TYPE_QUESTION,
        question,
        index
    }
}

export function detailSurvey(id) {
    return {
        type: DETAIL_SURVEY,
        loginRequired: true,
        id
    }
}

export function showReview() {
    return {
        type: SHOW_REVIEW
    }
}

export function focusQuestion(index) {
    return {
        type: FOCUS_QUESTION,
        index
    }
}

export function createQuestion(question) {
    return {
        type: CREATE_QUESTION,
        question
    }
}

export function removeQuestion(question) {
    return {
        type: DELETE_QUESTION,
        question
    }
}

export function addLogic(question) {
    return {
        type: ADD_LOGIC,
        question: {
            ...question,
        }
    }
}

export function editQuestion(question, index) {
    return {
        type: EDITOR_QUESTION,
        question,
        index
    }
}

export function editSurveyTemplate(surveyTemplate) {
    return {
        type: EDITOR_SURVEYTEMPLATE,
        surveyTemplate
    }
}

export function saveQueryConditionSurvey(conditions, params, pagination) {
    return {
        type: SAVE_QUERY_CONDITION_SURVEY,
        conditions : conditions,
        params : params,
        pagination : pagination
    }
}


export function turnModalEditQuestionSurveyTemplate() {
    return {
        type: TURN_MODAL_EDIT_QUESTION_TEMPLATE
    }
}

export function pushQuestionEditInStore(editedQuestion) {
    return {
        type: PUSH_QUESTION_EDIT_IN_STORE,
        editedQuestion
    }
}

export function copyQuestionForTemplate(question) {
    return {
        type : COPY_QUESTION_FOR_TEMPLATE,
        question
    }
}


export const changeUnRequited  = (dispatch) => (questionIndex) => {
    dispatch({
        type: CHANGE_UNREQUITED_QUESTION,
        questionIndex
    })
};
