export const SELECT_DOCUMENT     = "SELECT_DOCUMENT";
export const SELECT_ALL_DOCUMENT = "SELECT_ALL_DOCUMENT";
export const REMOVE_DOCUMENT     = "REMOVE_DOCUMENT";
export const SET_PRIVATE_DOCUMENT = "SET_PRIVATE_DOCUMENT";
export const ASSIGN_USERS                    = "ASSIGN_USERS";

export function documentSelect(index) {
    return {
        type: SELECT_DOCUMENT,
        index: index
    }
}

export function documentSelectAll(status) {
    return {
        type: SELECT_ALL_DOCUMENT,
        checked: status
    }
}

export function removeDocument(customerId, listId) {
    return {
        type: REMOVE_DOCUMENT,
        customerId,
        listId
    }
}

export function setPrivateDocument(customerId, listId, privateDocument) {
    return {
        type: SET_PRIVATE_DOCUMENT,
        customerId,
        listId,
        privateDocument
    }
}

export function assignUsersDocument(customerId, documentId, userIds) {
    return {
        type: ASSIGN_USERS,
        loginRequired: true,
        customerId,
        documentId,
        users: userIds
    }
}
