export default class LockService {
    constructor(axios) {
        this.axios = axios;
    }

    lockInfo(type, recordId) {
        return this.axios.get(`/lock-information/${type}`, {
            params: {
                ids: recordId
            }
        }).then(res => res.data)
    }

    unLock(type, recordId) {
        return this.axios.get(`/unlock/${type}`, {
            params: {
                ids: recordId
            }
        }).then(res => res.data)
    }
}
