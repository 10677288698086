import React, {Component} from 'react';
import KirokuModal        from "../../ui/KirokuModal";
import KirokuModalHeader  from "../../ui/KirokuModalHeader";
import KirokuInputSearch from "../../ui/KirokuInputSearch";
import KirokuModalBody   from "../../ui/KirokuModalBody";
import KirokuCardList    from "../../ui/KirokuCardList";
import KirokuCard        from "../../ui/KirokuCard";
import KirokuCheckBox    from "../../ui/KirokuCheckBox";
import KirokuModalFooter from "../../ui/KirokuModalFooter";
import KirokuButton      from "../../ui/KirokuButton";
import connect           from "react-redux/es/connect/connect";
import {checkAllSurveys} from "../../actions/Behavior";
import {withNamespaces}  from "react-i18next";
@withNamespaces()
@connect((state) => {
    return {
        surveyForModal: state.KirokuAISurvey.surveyForModal,
    }
}, dispatch => {
    return {
        checkAllSurveys: (checked) => {
            dispatch(checkAllSurveys(checked))
        },
    };
})
class ModalSelectAssignSurvey extends Component {

    componentWillMount() {
        this.setState({
            customer: this.props.customer
        })
    }

    state = {
        show: false,
        checkedSurveys: false,
        statusCheck: false,
        surveysAssign: [],
    };

    closeModal() {
        this.setState({
            show: false,
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.surveyForModal.length) {
            this.setState({
                surveysAssign: nextProps.surveyForModal,
                surveys: nextProps.surveyForModal,
            })

        }
    }

    onChangeSearch(e) {
        let input   = e.target.value.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
        let surveys = this.state.surveys.filter(survey =>
            survey.title.toLowerCase().search(input.toLowerCase()) !== -1
        );
        this.setState({surveysAssign: surveys});
    }


    handleCheckSurveys(index) {
        const {surveysAssign} = this.state;
        surveysAssign[index]  = {
            ...surveysAssign[index],
            isChecked: !surveysAssign[index].isChecked
        };
        this.setState({surveysAssign: surveysAssign})
    }

    handleCheckAll() {
        this.props.checkAllSurveys(this.state.checkedSurveys);
        this.setState({
            statusCheck: !this.state.statusCheck,
            checkedSurveys: !this.state.checkedSurveys
        })
    }

    assignSurvey() {
        let newCustomer     = this.state.customer;
        newCustomer.surveys = this.state.surveysAssign.filter(survey => survey.isChecked);
        this.setState({
            customer: newCustomer
        });
        this.props.onChangeCustomer(this.state.customer);
        this.setState({
            show: !this.state.show
        })
    }

    goBack() {
        this.props.history.goBack()
    }

    showModalAssignSurvey() {
        this.setState({
            show: !this.state.show
        })
    }

    render() {
        let {surveysAssign} = this.state;
        let {t} = this.props;
        return (
            <div>
                <div id='web'>
                    <div className='d-flex justify-content-between pb-3'>
                        <KirokuButton onClick={this.goBack.bind(this)} color={'white'}>
                            {t('common.Back')}
                        </KirokuButton>
                    </div>
                </div>
                <div className='p-2 d-flex justify-content-between' id='mobile'>
                    <KirokuButton onClick={this.goBack.bind(this)} color={'white'}>
                        {t('common.Back')}
                    </KirokuButton>
                </div>
                <KirokuModal show={this.state.show} onCloseModal={this.closeModal.bind(this)}>
                    <KirokuModalHeader>
                        <h6 className="mb-3">{t('Customer.AssignCustomerToSurvey')}</h6>
                        <KirokuInputSearch onChange={this.onChangeSearch.bind(this)}
                                           placeholder={t('Customer.SurveyTemplateName')}/>
                    </KirokuModalHeader>
                    <KirokuModalBody>
                        <div id="body-modal-as">
                            <KirokuCardList
                                headerLeft={t('Surveys.Survey')}
                                headerRight={
                                    <div className='text-primary' style={{cursor: 'pointer'}}>
                        <span hidden={this.state.statusCheck}
                              onClick={this.handleCheckAll.bind(this)}
                        >{t('common.SelectAll')}</span>

                                        <span hidden={!this.state.statusCheck}
                                              onClick={this.handleCheckAll.bind(this)}
                                        >{t('common.UnSelect')}</span>
                                    </div>
                                }
                            >
                                <section className="scroll-data-customer">
                                    {surveysAssign.map((survey, index) => (
                                        <KirokuCard key={survey.id}>
                                            <KirokuCheckBox onChange={this.handleCheckSurveys.bind(this, index)}
                                                            checked={survey.isChecked} content={survey.title}/>
                                        </KirokuCard>
                                    ))}
                                </section>
                            </KirokuCardList>
                        </div>
                    </KirokuModalBody>
                    <KirokuModalFooter>
                        <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                           {t('common.Cancel')}
                        </KirokuButton>
                        <KirokuButton color={'primary'} onClick={this.assignSurvey.bind(this)}>
                            {t('common.Assign')}
                        </KirokuButton>
                    </KirokuModalFooter>
                </KirokuModal>
            </div>
        )
    }
}

export default ModalSelectAssignSurvey;
