import React, {Component}                            from 'react';
import {connect}                                                                from 'react-redux';
import { CheckRerenderResponsive, KirokuLoading, KirokuTitlepage } from "../../../ui";
import RecordsMobile                                                            from "./RecordsMobile";
import RecordsWeb                                    from "./RecordsWeb";
import {currentPage}                                 from "../../../actions/CurrentPage";
import {
    checkAllRecords,
    deleteRecords,
    loadAllSurveys,
    loadRecords,
    saveQueryCondition,
    selectedRecord,
    customerForTagsRecords
}                                                    from "../../../actions/Record";
import {loadUsers}                                   from "../../../actions/User";
import {loadCustomerAll}                             from "../../../actions/Customer";
import {withNamespaces}                              from "react-i18next";
import {scrollService}                               from "../../../services";

function page(t) {
    return {
        title: <KirokuTitlepage title={t('Layout.Records')}/>,
        actionPage: null
    }
}

@withNamespaces()
@connect(state => {
    return {
        records: state.KirokuAIRecord.records,
        loadingCustomer: state.KirokuAIRecord.loadingCustomer,
        ListAllSurveyTemplate: state.KirokuAISurvey.ListAllSurveyTemplate,
        ListAllCustomer: state.KirokuAICustomer.ListAllCustomer,
        users: state.KirokuAIUser.users,
        currentPage: state.KirokuAIRecord.currentPage,
        totalPage: state.KirokuAIRecord.totalPage,
        showNotification: state.KirokuAIRecord.showNotification,
        loading: state.Loading,
        profile: state.KirokuAIUser.profile,
        statusCheck: state.KirokuAIRecord.statusCheck,
        conditions: state.KirokuAIRecord.conditions,
        loadingAllCustomer: state.KirokuAICustomer.loadingAllCustomer,
        loadingRecord: state.KirokuAIRecord.loadingRecord,
    }
}, dispatch => {
    return {
        namePage: (name) => {
            dispatch(currentPage(name))
        },
        checkAllRecords: (checked) => {
            dispatch(checkAllRecords(checked))
        },

        loadUsers: () => {
            dispatch(loadUsers())
        },
        customerForTagsRecords: () => {
            dispatch(customerForTagsRecords())
        },
        loadRecords: (params) => {
            dispatch(loadRecords(params));
        },

        loadAllSurveys: () => {
            dispatch(loadAllSurveys())
        },

        loadAllCustomer: () => {
            dispatch(loadCustomerAll())
        },

        selectedRecord: (index) => {
            dispatch(selectedRecord(index))
        },

        deleteRecords: (records, params) => {
            dispatch(deleteRecords(records, params))
        },

        saveQueryCondition: (conditions) => {
            dispatch(saveQueryCondition(conditions))
        },
        defaultState: () => {
            dispatch({
                type: 'INIT_STATE'
            })
        }
    }
})
class Records extends Component {

    state = {
        isLoading: false,
        namePage: page(this.props.t),
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.loadingCustomer && nextProps.loadingAllCustomer && nextProps.loadingRecord) {
            this.setState({
                isLoading: true,
            })
        }
    }

    componentWillUnmount() {
        this.props.defaultState()
    }

    componentWillMount() {
        scrollService.top();
        this.props.loadAllSurveys();
        this.props.loadAllCustomer();
        this.props.loadUsers();
        this.props.namePage(this.state.namePage);
        this.props.customerForTagsRecords();
    }

    render() {
        let mobile = <RecordsMobile/>;
        let web    = <RecordsWeb/>;
        return (
            <div className='default-background-color'>
                {
                    this.state.isLoading ? <CheckRerenderResponsive mobile={mobile} web={web}/> : <KirokuLoading/>
                }
            </div>
        )
    }
}

export default Records;
