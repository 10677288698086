import React, {Component}               from 'react';
import CheckDivide                      from "../../../ui/CheckDivide";
import SurveyEditorMobile               from "./Mobile/SurveyEditorMobile";
import SurveyEditorWeb                  from "./Web/EditorSurveyWeb";
import KirokuTitlepage                  from "../../../ui/KirokuTitlepage";
import {withNamespaces}                 from "react-i18next";
import connect                          from "react-redux/es/connect/connect";
import {currentPage}                    from "../../../actions/CurrentPage";
import {detailSurvey, resetStateSurvey} from "../../../actions/SurveyTemplate";
import {KirokuLoading}                  from "../../../ui";
import {loadDraft, resetDraft}          from "../../../actions/DraftSurvey";
import {resetMobile}                    from "../../../actions/SurveyMobile";

@withNamespaces()
@connect(state => {
    return {
        currentPage: state.KirokuAITeam.currentPage,
        totalPage: state.KirokuAITeam.totalPage,
        survey : state.KirokuAISurveyTemplate,
        dataDraft : state.KirokuDraftSurvey.dataDraft
    }
}, dispatch => {
    return {
        pageNow: (name) => {
            dispatch(currentPage(name))
        },

        reset : () => {
            dispatch(resetStateSurvey())
        },

        resetMobile : () => {
            dispatch(resetMobile())
        },

        resetDraft : () => dispatch(resetDraft()),
        detail: surveyId => dispatch(detailSurvey(surveyId)),
        loadDraft : (type, id) => dispatch(loadDraft(type, id))
    }
})

class SurveyEditor extends Component {
    page = {
        title: <KirokuTitlepage title={this.props.t('createSurvey.EditSurvey')}/>,
        actionPage: null
    };

    state = {
        namePage: this.page,
    };

    componentWillMount() {
        this.props.pageNow(this.state.namePage);
        this.props.detail(this.props.match.params.id);
        // this.props.loadDraft('surveyTemplate', this.props.match.params.id);
    }

    componentWillUnmount() {
        this.props.reset();
        // this.props.resetDraft();
        this.props.resetMobile();
    }

    render() {
        return this.props.survey.id ? (
                <CheckDivide mobile={<SurveyEditorMobile id={this.props.match.params.id} history={this.props.history}/>}
                             web={<SurveyEditorWeb id={this.props.match.params.id} history={this.props.history}/>}/>
            ) : <KirokuLoading/>
    }
}

export default SurveyEditor;
