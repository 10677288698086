import React, {Component}                                 from "react";
import CheckBoxTypeQuestion                               from "./CheckBoxTypeQuestion";
import {connect}        from 'react-redux';
import {
            editQuestion,
            editTypeQuestion,
            throwErrorSurvey,
            copyQuestionForTemplate
    }                   from "../../../../actions/SurveyTemplate";
import {withNamespaces} from "react-i18next";
import {
    KirokuButton,
    KirokuCardWeb,
    KirokuCardBodyWeb,
    KirokuCardTitleWeb,
    KirokuInput,
}                       from '../../../../ui';
import { NOTIFICATION } from '../../../../actions/Notification';

@withNamespaces()
@connect(state => ({
    focusQuestion: state.KirokuAISurveyTemplate.focusQuestion,
    error: state.KirokuAISurveyTemplate.errorSurvey
}), dispatch => ({
    throwErrorSurvey: (name, value) => dispatch(throwErrorSurvey(name, value)),
    onChangeQuestion: (question, index) => dispatch(editQuestion(question, index)),
    onChangeTypeQuestion: (question, index) => dispatch(editTypeQuestion(question, index)),
    copyQuestionForTemplate : (question) => dispatch(copyQuestionForTemplate(question)),
    notification: (t) =>  dispatch({
            type: NOTIFICATION,
            typeNotification: 'success',
            message:  t('message.questionCopiedSuccess')
    })
}))
class Question extends Component {
    state = {
        disabledCopiedBtn : false
    };

    onChangeHeading(e) {
        this.props.onChangeQuestion({
            ...this.props.question,
            heading: e.target.value
        }, this.props.index)
    }

    onChangeContent(e) {
        let name = e.target.name;
        this.props.throwErrorSurvey(name, !e.target.value.length);
        this.props.onChangeQuestion({
            ...this.props.question,
            content: e.target.value,
        }, this.props.index)
    }

    handleSelectType(type, choices) {
        this.setState({
            type,
            choices
        });
        this.props.onChangeTypeQuestion({type, choices}, this.props.index);
    }

    // componentDidMount() {
    //     let {question} = this.props;
    //     if (question.index === this.props.focusQuestion) {
    //         this.focusQuestion();
    //     }
    // }

    focusQuestion() {
        let element  = this.refs[`question${this.props.focusQuestion}`];
        let location = element.getBoundingClientRect();
        element.focus();
        element.scrollTop = location.top;
    }

    handleCopyQuestion() {
        const {t, question} = this.props;
        let newQuestion = '';
        try {
            newQuestion = JSON.parse(JSON.stringify(question));
        } catch (e) {
            throw new Error('question is not undefined');
        }
        this.props.notification(t);
        this.props.copyQuestionForTemplate(newQuestion);
        this.setState({
            disabledCopiedBtn : !this.state.disabledCopiedBtn
        }, () => {
            setTimeout(() => this.setState({
                disabledCopiedBtn  : false
            }), 3000);
        });
    };

    render() {
        let {t, isCreateSurvey} = this.props;
        const question = this.props.question;
        return (
            <div className='pb-2'>
                <div ref={`question${question.index}`} tabIndex={`${question.index}`}>
                    <KirokuCardWeb>
                        <KirokuCardTitleWeb color='success'>
                            <div className="d-flex justify-content-between pb-2">
                                <h4 className='text-dark'>{t('createSurvey.QuestionOrder', {index: this.props.index + 1})}</h4>
                                <div/>
                                <KirokuButton color='success' disabled={this.state.disabledCopiedBtn} onClick={this.handleCopyQuestion.bind(this)}>{this.props.t('Surveys.Copy')}</KirokuButton>
                            </div>
                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb>
                            <div className='sumary-content2'>
                                <p>{t('createSurvey.Heading')}</p>
                                <KirokuInput value={question.heading} name={'heading'}
                                             onChange={this.onChangeHeading.bind(this)}
                                />
                                <p className='pt-3'>{t('createSurvey.YourQuestion')}</p>
                                <KirokuInput value={question.content} name={'content'}
                                             onChange={this.onChangeContent.bind(this)}
                                             error={!question.content ? 'form-error' : ''}
                                />
                                <p hidden={!!question.content} style={{
                                    color: 'red',
                                    fontSize: 12
                                }}>{t('createSurvey.QuestionMustNotNull')}</p>
                            </div>
                            <CheckBoxTypeQuestion number={this.props.index} choices={question.choices}
                                                  isCreateSurvey={question.type !== 'YesNo' || isCreateSurvey}
                                                  type={question.type}
                                                  handleCheckType={this.handleSelectType.bind(this)}/>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                </div>
            </div>
        );
    }
}

export default Question;
