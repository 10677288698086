import React, { Component }         from 'react';
import { connect }                  from 'react-redux';
import { withNamespaces }           from 'react-i18next';
import BtnAction                    from './BtnAction';
import ModalAddLogicGeneral         from '../../../ModalAddLogicGeneral';
import {
    focusQuestion, pushQuestionEditInStore,
    removeQuestion,
    showReview,
    turnModalEditQuestionSurveyTemplate,
}                                   from '../../../../../actions/SurveyTemplate';
import { checkUseHeadingQuestion }  from '../../../SurveyEditor/SurveyService';
import { setDefaultAnswerQuestion } from '../../../../../actions/Survey';
import ComponentUnrequited          from '../../../ReviewSurvey/ComponentUnrequited';
import {
    KirokuInputArea, KirokusSortableHandle,
    KirokuLogicApplicated, KirokuCardWeb,
    KirokuCardTitleWeb, KirokuCardBodyWeb,
}                                   from '../../../../../ui';

@withNamespaces()
@connect(null, dispatch => {
    return {
        removeQuestion: (question) => {
            dispatch(removeQuestion(question));
        },

        focusQuestion                      : (index) => {
            dispatch(focusQuestion(index));
        },
        showReview                         : () => {
            dispatch(showReview());
        },
        turnModalEditQuestionSurveyTemplate: () => {
            dispatch(turnModalEditQuestionSurveyTemplate());
        },
        pushQuestionEditInStore            : (editedQuestion) => {
            dispatch(pushQuestionEditInStore(editedQuestion));
        },
        setDefaultAnswerQuestion           : (question) => {
            dispatch(setDefaultAnswerQuestion(question));
        },
    };
})
class InputText extends Component {
    state = {
        show: true,
    };

    showModalAddLogic() {
        this.setState({
            show: !this.state.show,
        });
    }

    closeModal() {
        this.setState({
            show: !this.state.show,
        });
    }

    handleSetDefaultAnswer(event) {
        const { question } = this.props;
        let questionNew    = { ...question };
        questionNew        = {
            ...questionNew,
            defaultAnswer: event.target.value,
        };
        this.props.setDefaultAnswerQuestion(questionNew);
    }

    handleResetDefaultAnswer() {
        const { question } = this.props;
        let questionNew    = { ...question };
        if(questionNew.defaultAnswer) {
            delete questionNew.defaultAnswer;
            this.props.setDefaultAnswerQuestion(questionNew);
        }
    }

    render() {
        let { t }                        = this.props;
        const { question, ReviewCreate } = this.props;
        const contentQuestion            = checkUseHeadingQuestion(question);
        return (
            <div id='component-question'>
                <div id='web-q' className='pb-3'>
                    <KirokuCardWeb>
                        <KirokusSortableHandle>
                        <KirokuCardTitleWeb color={'card-web'} style={{ paddingBottom: 10, paddingTop: 10 }}>
                            <div className='d-flex justify-content-between'  style={{ flexWrap: 'wrap'}}>
                                    <div className="question-number pt-10px">{t('createSurvey.QuestionOrder', { index: question.index + 1 })}</div>
                                <KirokuLogicApplicated padding={'5px 5px 5px 7px'} question={question}/>
                                <BtnAction
                                    resetAnswer={() => this.handleResetDefaultAnswer()}
                                    question={question}
                                    showModalAddLogic={this.showModalAddLogic.bind(this)}
                                />
                            </div>
                        </KirokuCardTitleWeb>
                        </KirokusSortableHandle>
                        <KirokuCardBodyWeb>
                            <div hidden={typeof question.nextQuestion === 'number'}>
                                <ComponentUnrequited question={question}
                                                     changeUnrequitedQuestion={(questionIndex) => this.props.changeUnrequitedQuestion(questionIndex)}
                                />
                            </div>
                            <span hidden={ReviewCreate} className='content-name-question pb-2'>{contentQuestion}</span>
                            <span hidden={!ReviewCreate}
                                  className='content-name-question'>{t('createSurvey.Heading')}: {question.heading}</span>
                            <span hidden={!ReviewCreate}
                                  className='content-name-question pb-2'>{t('createSurvey.Content')}: {question.content}</span>
                            <p className='text-info'
                               hidden={typeof question.nextQuestion !== 'number'}
                            >
                                <i className='fas fa-arrow-right text-info'>
                                    {t('createRecord.Question')} : {question.nextQuestion + 1}
                                </i>
                            </p>
                            <KirokuInputArea className='form-area'
                                             value={question.defaultAnswer || ''}
                                             onChange={this.handleSetDefaultAnswer.bind(this)}
                            />
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                    <ModalAddLogicGeneral show={this.state.show} question={question}
                                          closeModal={this.closeModal.bind(this)}/>
                </div>
            </div>
        );
    }
}

export default InputText;
