import Condition               from "./surveyTemplates/Condition";
import ConditionRecords        from "./records/ConditionRecords";
import TableSurvey             from "./surveyTemplates/TableSurvey";
import TableRecords            from "./records/TableRecord";
import TableStaff              from "./staffs/TableStaff";
import ConditionStaffs         from "./staffs/ConditionStaffs";
import TableCustomer           from "./customers/TableCustomer";
import ConditionCustomer       from "./customers/ConditionCustomer";
import TableCustomerList       from "../ListMobile/Customer/TableCustomerList";
import ConditionCustomerList   from "../ListMobile/Customer/ConditionCustomerList";
import TableSurveyTemplate     from "../ListMobile/SurveyTemplate/TableSurveyTemplate";
import ConditionSurveyTemplate from "../ListMobile/SurveyTemplate/ConditionSurveyTemplate";
import TableRecordList         from "../ListMobile/Record/TableRecordList";
import ConditionRecordList     from "../ListMobile/Record/ConditionRecordList";
import TableStaffList          from "../ListMobile/Staff/TableStaffList";
import ConditionStaffList      from "../ListMobile/Staff/ConditionStaffList";

export const Web = {
    surveys: {
        table    : TableSurvey,
        condition: Condition,
        name     : 'surveys'
    },
    records        : {
        table    : TableRecords,
        condition: ConditionRecords,
        name     : 'records'
    },
    customers: {
        table    : TableCustomer,
        condition: ConditionCustomer,
        name     : 'customers'
    },
    staffs: {
        table    : TableStaff,
        condition: ConditionStaffs,
        name     : 'staffs'
    },
};

export const Mobile = {
    surveys: {
        table    : TableSurveyTemplate,
        condition: ConditionSurveyTemplate,
        name     : 'surveys'
    },
    records        : {
        table    : TableRecordList,
        condition: ConditionRecordList,
        name     : 'records'

    },
    customers      : {
        table    : TableCustomerList,
        condition: ConditionCustomerList,
        name     : 'customers'
    },
    staffs         : {
         table   : TableStaffList,
        condition: ConditionStaffList,
        name     : 'staffs'
    }
};
