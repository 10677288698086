import React, {Component} from 'react';
const TimerContext = React.createContext('timer');


export default class Timer {
    constructor(defaultInterval) {
        this.defaultInterval = defaultInterval;
        this.lastJobId = null;
    }

    record(callback, interval = null) {
        this.lastJobId = setInterval(callback, interval || this.defaultInterval);
        return this.lastJobId;
    }

    unregister(jobId) {
        clearInterval(jobId);
    }

    unRegisterLastJob() {
        if (this.lastJobId) {
            this.unregister(this.lastJobId)
        }
    }
}

export function hasTimer() {
    return (TargetComponent) => {
        return class  extends Component {
            render() {
                return (
                    <TimerContext.Consumer>
                        {timer => <TargetComponent {...this.props} timer={timer}/>}
                    </TimerContext.Consumer>
                )
            }
        }
    }
}


export class TimerProvider extends Component {

    render() {
        const timer = this.props.timer;
        return (
            <TimerContext.Provider value={timer}>
                {this.props.children}
            </TimerContext.Provider>
        )
    }
}
