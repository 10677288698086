import React, { Component } from "react";
import LoginForm            from "./LoginForm";
import { Redirect }         from "react-router-dom";

export default class Login extends Component {
    render() {
        const shouldRedirect = localStorage.getItem('token') && localStorage.getItem('profile');

        return shouldRedirect ? <Redirect to='/'/> : <LoginForm/>;
    }
}
