import React, { Component } from 'react';
import { withNamespaces }   from 'react-i18next';
import {filePathService}    from "../services";

@withNamespaces()
class SearchNotFound extends Component {

    style = {
        surveyMobile: {
            width: 40,
            height: 'auto',
            paddingBottom: 10
        },
        exportAdmin : {width: 150, height: 'auto'},
    };

    render() {
        const {screen, t }= this.props;
        return (
            <div className='pt-3 pb-5 text-center'>
                <img src={`${filePathService.resolveFilePath('empty-survey.svg')}`} alt="search fail" style={this.style[screen]}/>
                <h5 style={{color: '#091e42'}} className={'pt-3'}>
                    <b>{this.props.message || t('common.NoResults')}</b>
                </h5>
                <p>{this.props.description || t('common.TryBroadeningYourFilters')}</p>
            </div>
        );
    }
}

export default SearchNotFound;