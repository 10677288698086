import React, { Component }               from 'react';
import {
    KirokuDropdownResetTitle,
    Paginator
} from "../../ui";
import { withNamespaces }                 from "react-i18next";
import { connect }                        from "react-redux";
import { setSizePagination }              from "../Records/NewRecords/serviceNewRecords";

@withNamespaces()
@connect(state => {
    return {
        loading            : state.Loading,
        ComponentFlowScreen: state.DeletedList.ComponentFlowScreen,
        listScreen         : state.DeletedList.listScreen,
        pageSize           : state.DeletedList.pageSize,
        page               : state.DeletedList.page,
        hasNext            : state.DeletedList.hasNext,
        items              : state.DeletedList.items,
        stateStaff         : state.DeletedStaffs,
        surveyTemplates    : state.DeletedSurveys.surveyTemplates,
        records            : state.DeletedRecords.records,
        customers          : state.DeletedCustomer.customers,
        staffs             : state.DeletedStaffs.staffs,
    }
}, dispatch => {
    return {
        handleChangeScreen: (screen) => {
            dispatch({
                type: 'CHANGE_SCREEN',
                screen
            })
        },
        changePageNumber  : (numberPage) => {
            dispatch({
                type: 'CHANGE_PAGE_NUMBER',
                numberPage
            })
        },
        checkIsMobile     : () => {
            dispatch({
                type    : 'IS_MOBILE',
                isMobile: false
            })
        },
    }
})
class DeletedWebPage extends Component {


    componentDidMount() {
        this.props.handleChangeScreen('surveys');
        this.props.checkIsMobile();
    }

    handlerOnSelectScreen(e) {
        this.props.handleChangeScreen(e.kirokuSelected.value)
    }

    handleChangePageSize(e) {
        this.props.changePageNumber(e.kirokuSelected.value);
    }

    handlerOnClickChangePage(statusChange) {
        const { page }   = this.props;
        const numberPage = statusChange === 'next' ? page + 1 : page - 1;
        this.props.changePageNumber(numberPage);
    }

    isDataListDeleted() {
        const { ComponentFlowScreen, surveyTemplates, records, customers, staffs } = this.props;

        switch (ComponentFlowScreen.name) {
            case 'surveys':
                return !surveyTemplates.length;
            case 'records':
                return !records.length;
            case 'customers' :
                return !customers.length;
            case 'staffs':
                return  !staffs.length;
            default:
                return true;

        }
    }

    render() {
        const { t, ComponentFlowScreen, listScreen, page, hasNext, items } = this.props;
        const Table                                                           = ComponentFlowScreen.table;
        const Conditions                                                      = ComponentFlowScreen.condition;
        return (
            <div className={'default-background-color'} id={'deleteWebPage'}>
                <div className="padding-responsive">
                    <div className="d-flex pb-1 pt-1">
                        <div style={{ width: 161 }}>
                            <KirokuDropdownResetTitle
                                selectScreen
                                title={t(`DeletedList.${ComponentFlowScreen.name}`)}
                                items={listScreen.map(screen => {
                                    return {
                                        value    : screen.screenName,
                                        component: (<div><h6 style={{margin: 0, color: '#202528'}}>{t(`DeletedList.${screen.screenName}`)}</h6></div>),
                                        label    : t(`DeletedList.${screen.screenName}`)
                                    }
                                })}
                                onSelect={this.handlerOnSelectScreen.bind(this)}
                            />
                        </div>
                        <div className="p-2"/>
                        <Conditions/>
                    </div>

                    <div className={'pb-2 pt-3'} hidden={this.isDataListDeleted()}>
                        <Paginator sizePagination={setSizePagination}
                                   numberPage={page}
                                   items={items}
                                   onPaginatorChange={this.handleChangePageSize.bind(this)}
                                   onClickNext={this.handlerOnClickChangePage.bind(this, 'next')}
                                   onClickBack={this.handlerOnClickChangePage.bind(this, 'back')}
                                   page={page}
                                   hasNext={hasNext}
                        />
                    </div>

                    <Table/>

                    <div className={'pt-2'} hidden={this.isDataListDeleted()}>
                        <Paginator sizePagination={setSizePagination}
                                   numberPage={page}
                                   onPaginatorChange={this.handleChangePageSize.bind(this)}
                                   location={'top'}
                                   onClickNext={this.handlerOnClickChangePage.bind(this, 'next')}
                                   onClickBack={this.handlerOnClickChangePage.bind(this, 'back')}
                                   page={page}
                                   items={items}
                                   hasNext={hasNext}
                        />
                    </div>

                </div>
            </div>
        )
    }
}

export default DeletedWebPage;
