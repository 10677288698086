import '../../record.css';
import React, { Component }              from 'react';
import { KirokuButton, KirokuInputArea, KirokuButtonLink } from '../../../../ui';
import { withNamespaces }                from 'react-i18next';
import PropTypes                         from 'prop-types';
import { checkUseHeadingQuestion }       from '../../../Surveys/SurveyEditor/SurveyService';
import { withSmoothScrolling }           from '../../../../ui/Scrolling.js';

@withSmoothScrolling
@withNamespaces()
class InputText extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value : props.value,
        };
    }

    handleChange(event) {
        if (!event) {
            event = { target: {value: ''} };
        }

        this.setState({
            value      : event.target.value,
        });

        this.props.onAnswer({
            answer       : event.target.value,
            validated    : event.target.value ? event.target.value : null,
            questionIndex: this.props.question.index,
        });

        this.props.isCurrentQuestion(this.props.question.index);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.value !== nextProps.value || this.props.question.index !== nextProps.question.index) {
            this.setState({ value: nextProps.value });
        }
    }

    moveToNextQuestion() {
        const { question } = this.props;
        let nextToQuestion;
        nextToQuestion   = question.nextQuestion ? question.nextQuestion - 1 : question.index;
        this.props.smoothScroll.scrollTo(`question-${ nextToQuestion }`);
        this.props.isCurrentQuestion(nextToQuestion + 1);
    }

    render() {
        const { question, disabled,
                  t, isEndQuestion, currentQuestion } = this.props;
        const contentQuestion                                               = checkUseHeadingQuestion(question);

        return (
            <div className='content-question'>
                <div className='wraper-question'>
                    <div className="background-question">
                        <div className={disabled ? 'bg-disable-question number-question' :
                            (this.state.value ? 'bg-question-has-answer d-flex justify-content-between number-question'
                                : (!question.unrequited ? 'bg-question-required-answer d-flex justify-content-between' : 'd-flex justify-content-between number-question'))}
                             style={{ padding : 8 }}
                        >
                            <div className={`text-overflow-elip ${disabled ? 'question-number' : this.state.value ? 'font-text-question-has-answer' 
                                : (!question.unrequited ? 'text-question-required-answer' : 'question-number') }`}>
                                {question.unrequited ? t('createRecord.option'):''}{ contentQuestion }
                            </div>
                            <div/>
                            <div hidden={disabled}>
                                <KirokuButtonLink
                                    className='padding-reset-button-link'
                                    onClick={() => {this.handleChange()}}>
                                    {t('createRecord.resetAnswer')}
                                </KirokuButtonLink>
                            </div>
                        </div>
                        <span className='content-name-question' style={ { opacity: disabled ? 0.3 : 1 } }>
                            { question.content }
                        </span>
                    </div>
                    <div className='answer-question' style={ { opacity: disabled ? 0.3 : 1 } }>
                        <div>
                            <KirokuInputArea className='form-area'
                                             disabled={ disabled }
                                             name={ `${ question.index }input` }
                                             onChange={ this.handleChange.bind(this) }
                                             value={ this.state.value }/>
                        </div>
                        <div className='p-2' hidden={ isEndQuestion || disabled || question.index !== currentQuestion }>
                            <KirokuButton className="btn-kiroku-primary" onClick={ this.moveToNextQuestion.bind(this) }>
                                { this.props.t('common.nextToQuestion') }
                            </KirokuButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

InputText.propTypes = {
    value: PropTypes.string,
};
export default InputText;
