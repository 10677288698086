import React, {Component}              from 'react';
import {connect}                       from 'react-redux';
import {
    conditionRemoved,
    loadAllSurveys,
    loadRecords,
    saveQueryCondition,
    setNumberPageRecords
}                                      from '../../../actions/Record';
import {loadSurveyListPage}            from '../../../actions/Survey';
import {loadCustomer, loadCustomerAll} from '../../../actions/Customer';
import {loadUsers}                     from '../../../actions/User';
import {
    KirokuButton,
    KirokuCard,
    KirokuCardList,
    KirokuCheckDraft,
    KirokuDropdownClose,
    KirokuInputSearcher, KirokuLabel,
    KirokuLoading,
    SearchNotFound,
    ShortText,
    KirokuSectionDropdownShowMoreMobile
}                                      from '../../../ui';
import FilterRecordsModal              from './FilterRecordsModal';
import KirokuCardAction                from '../../../ui/KirokuCardAction';
import KirokuPopoverItem               from '../../../ui/KirokuPopoverItem';
import {Link}                          from 'react-router-dom';
import KirokuPagination                from '../../../ui/KirokuPagination';
import ModalBulkDeleteRecord           from './ModalBulkDeleteRecord';

import KirokuTitlepage                         from '../../../ui/KirokuTitlepage';
import {currentPage}                           from '../../../actions/CurrentPage';
import {withNamespaces}                        from 'react-i18next';
import {scrollService, recordBuilderCondition} from '../../../services';
import DateRangeMobile                         from './DateRange';
import moment                                  from "moment";

import withPermission         from "../../HOC/WithPermissions";
import {authService}          from "../../../services";
import PaginationRecordMobile from "./PaginationRecordMobile";
import {setSizePagination}    from "../NewRecords/serviceNewRecords";
import config                 from '../../../config';

const BulkAction = withPermission(KirokuDropdownClose);

const bulkActionItems = (t) => {
    return [
        {
            value: 'delete',
            component: (
                <div>{t('common.Delete')}</div>
            ),
            label: t('common.Delete')
        }
    ]
};

function page(t) {
    return {
        title: <KirokuTitlepage title={t('Layout.Records')}/>,
        actionPage: null
    }
}

class Action extends Component {

    constructor(props) {
        super(props);

        this.handleClick        = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            popupVisible: false
        };
    }

    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => (
            {
                popupVisible: !prevState.popupVisible
            }
        ));
    }

    handleOutsideClick(e) {
        if (!this.node) {
            return;
        }
        this.handleClick();
    }

    render() {
        return (
            <div className="action-customer-surveys" id='edit-record' ref={node => {
                this.node = node
            }}>
                <KirokuCardAction surveyId={this.props.surveyId}>
                    <div className="action-icon" onClick={this.handleClick.bind(this)}>
                        <i style={{color: '#1991eb'}} className="fas fa-ellipsis-h "/>
                        {
                            this.state.popupVisible &&
                            (
                                <div onClick={this.handleClick.bind(this)}>
                                    {this.props.children}
                                </div>
                            )
                        }
                    </div>
                </KirokuCardAction>
            </div>
        )
    }
}

@withNamespaces()
@connect(state => {
    return {
        records: state.KirokuAIRecord.records,
        ListAllSurveyTemplate: state.KirokuAISurvey.ListAllSurveyTemplate,
        ListAllCustomer: state.KirokuAICustomer.ListAllCustomer,
        users: state.KirokuAIUser.users,
        currentPage: state.KirokuAIRecord.currentPage,
        totalPage: state.KirokuAIRecord.totalPage,
        showNotification: state.KirokuAIRecord.showNotification,
        loading: state.Loading,
        conditions: state.KirokuAIBehavior.conditionRecords.conditions,
        params: state.KirokuAIBehavior.conditionRecords.params,
        pagination: state.KirokuAIBehavior.conditionRecords.pagination,
        customerTagged: state.KirokuAIRecord.customerTagged,
        showNotificatieon: state.KirokuAIRecord.showNotification,
        profile: state.KirokuAIUser.profile,
        statusCheck: state.KirokuAIRecord.statusCheck,
        defaultTags: state.KirokuAIBehavior.conditionRecords.defaultTags,
        pageSize: state.KirokuAIBehavior.conditionRecords.pageSize

    }
}, dispatch => {
    return {
        loadRecords: (params, tags) => {
            dispatch(loadRecords(params, tags));
        },
        loadSurveys: () => {
            dispatch(loadSurveyListPage());
        },
        loadCustomers: () => {
            dispatch(loadCustomer());
        },
        loadUsers: () => {
            dispatch(loadUsers());
        },
        namePage: (name) => {
            dispatch(currentPage(name))
        },
        loadAllSurveys: () => {
            dispatch(loadAllSurveys())
        },
        loadAllCustomer: () => {
            dispatch(loadCustomerAll())
        },

        saveQueryCondition: (conditions, params, pagination, defaultTags, pageSize) => {
            dispatch(saveQueryCondition(conditions, params, pagination, defaultTags, pageSize))
        },

        removedCondition: (removedCondition) => {
            dispatch(conditionRemoved(removedCondition))
        },
        setNumberPageRecords: (numberPage) => {
            dispatch(setNumberPageRecords(numberPage))
        }
    }
})
class RecordsMobile extends Component {

    customDate = [
        {
            value: 'All date',
            component: (
                <div>{this.props.t('RecordList.allDate')} </div>
            ),
            label: this.props.t('RecordList.allDate')
        },
        {
            value: 'day',
            component: (
                <div>{this.props.t('RecordList.day')} </div>
            ),
            label: this.props.t('RecordList.day')
        },
        {
            value: 'week',
            component: (
                <div>{this.props.t('RecordList.week')}</div>
            ),
            label: this.props.t('RecordList.week')
        },
        {
            value: 'month',
            component: (
                <div>{this.props.t('RecordList.month')}</div>
            ),
            label: this.props.t('RecordList.month')
        },
        {
            value: 'Custom Date',
            component: (
                <div onClick={this.closeDateRange.bind(this)}><ShortText text={this.props.t('RecordList.customDate')}
                                                                         length={8}/></div>
            ),
            label: this.props.t('RecordList.customDate')

        }
    ];

    state = {
        showModal: true,
        showRecords: false,
        conditions: {},
        page: 1,
        pageMin: 1,
        title: '',
        showBulkAction: false,
        namePage: page(this.props.t),
        disabledPrev: true,
        disabledNext: false,
        currentTags: [],
        groupedOptions: [],
        params: {
            page: 1
        },
        defaultValue : 10,
        pageSize : 10
    };

    closeDateRange() {

    }

    closeModal() {
        this.setState({
            showModal: !this.state.showModal,
            showRecords: !this.state.showRecords
        })
    }

    componentWillMount() {
        if (this.props.conditions.length) {
            this.setState({
                currentTags: this.props.defaultTags,
                groupedOptions: this.props.ListAllCustomer
            });
            return this.makeConditions(this.props.conditions);
        } else {
            this.setState({
                currentTags: this.props.customerTagged ? this.props.customerTagged : [],
                groupedOptions: this.props.ListAllCustomer
            });
            this.props.loadRecords({page: 1}, this.props.customerTagged);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.params !== this.props.params) {
            this.setState({
                conditions: {
                    ...nextProps.params,
                },
                title: nextProps.params.title
            })
        }
        if (nextProps.currentPage || nextProps.records) {
            this.setState({
                // page: nextProps.currentPagex,
                disabledPrev: nextProps.currentPage === 1,
                disabledNext: nextProps.currentPage === nextProps.totalPage,
                defaultValue: nextProps.pageSize
            })
        }
        if (this.props.defaultTags !== nextProps.defaultTags) {
            this.setState({
                currentTags : nextProps.defaultTags
            })
        }
    }

    selectPaginationForPage = (valuePaginations) => {
        return valuePaginations.map(pagination => ({
            value: pagination.value,
            label : pagination.name,
            component : (<div><span className={'text-muted'}>{pagination.name}</span></div>)
        }));
    };

    choicePagination = (paginate) => {
        this.setState({
            conditions: {
                ...this.state.conditions,
                pageSize: paginate
            },
            page : 1,
            pageSize : paginate,
        }, () => {
            this.filterRecords();
            this.saveQueryCondition();
        });
    };


    makeConditions = (conditions) => {
        let params = recordBuilderCondition.makeParamsFromCondition(conditions);
        this.setState({
            conditions: {
                ...this.props.params,
            },
            title: params.title,
            page: params.page,
            ...this.props.pagination
        });
        this.props.defaultTags.length ? this.props.loadRecords(params, this.props.defaultTags) : this.props.loadRecords(params);
    };

    handleShowModal() {
        this.setState({
            showModal: !this.state.showModal,
            showRecords: !this.state.showRecords
        })
    }

    filterCondition(params) {
        this.setState({
            conditions: {
                ...params,
                title: this.state.title,
                pageSize: this.state.pageSize
            },
            page: 1
        }, () => {
            this.filterRecords();
            this.saveQueryCondition();
            this.props.setNumberPageRecords(this.props.currentPage);
        });
    }

    saveQueryCondition = () => {
        const {disabledPrev, disabledNext} = this.state;
        const stateConditions              = recordBuilderCondition.makeParams(this.state.conditions);
        const conditions                   = this.makeQueryConditions({...stateConditions, page: this.state.page});
        this.props.saveQueryCondition(conditions, this.state.conditions, {
            disabledNext,
            disabledPrev
        }, this.state.currentTags, this.state.pageSize);
    };

    filterRecords() {
        const params = recordBuilderCondition.makeParams(this.state.conditions);
        this.props.loadRecords({...params, page: this.state.page, title: this.state.title}, this.state.currentTags);
        this.setState({selected: false});
        this.props.setNumberPageRecords(this.props.currentPage);
    }

    increasePage() {
        let nextPage = this.props.currentPage === this.props.totalPage ? this.props.totalPage : this.props.currentPage + 1;
        if (nextPage === this.props.totalPage) {
            this.setState({
                disabledNext: !this.state.disabledNext
            })
        }
        scrollService.top();
        this.setState({
            page: nextPage,
            disabledPrev: false,
            defaultValue: this.props.pageSize,
            pageSize: this.props.pageSize
        }, () => {
            this.props.setNumberPageRecords(nextPage);
            this.filterRecords();
            this.saveQueryCondition();
        });
    }

    decreasePage() {
        let previousPage = this.props.currentPage === this.state.pageMin ? 1 : this.props.currentPage - 1;
        if (previousPage === this.state.pageMin) {
            this.setState({
                disabledPrev: true
            })
        }
        scrollService.top();
        this.setState({
            page: previousPage,
            disabledNext: false,
            defaultValue: this.props.pageSize,
            pageSize: this.props.pageSize
        }, () => {
            this.props.setNumberPageRecords(previousPage);
            this.filterRecords();
            this.saveQueryCondition();
        });
    }

    inputSearch(e) {
        this.setState({
            title: e.target.value,
            conditions: {
                ...this.state.conditions,
                title: e.target.value
            }
        })
    }

    makeQueryConditions = (params) => {
        return Object.keys(params).map(key => (
            {[key]: params[key], query: key, value: params[key], label: this.makeLabelCondition(key)}
        ));
    };

    makeLabelCondition = (key) => {
        switch (key) {
            case 'status' :
                return this.state.conditions.status ? this.props.t('Surveys.Draft') : '';
            case 'customerId':
                return this.state.conditions.customerName;
            case 'templateId':
                return this.state.conditions.titleSurvey;
            case 'staffId':
                return this.state.conditions.staffName;
            case 'startDate':
                let startDate = this.state.conditions.startDate ? `${moment.unix(this.state.conditions.startDate).format(config.formatDateTh)}` : '';
                return this.props.t(`Surveys.${key}`) + `${startDate}`;
            case 'endDate':
                let endDate = this.state.conditions.endDate ? `${moment.unix(this.state.conditions.endDate).format(config.formatDateTh)}` : '';
                return this.props.t(`Surveys.${key}`) + `${endDate}`;
            default:
                return this.props.t(`RecordList.${key}`)
        }
    };

    showModalBulkDeleteRecords() {
        this.setState({
            showBulkAction: !this.state.showBulkAction
        })
    }

    onSubmitSearch() {
        this.setState({
            page: 1
        }, () => {
            const {page, title} = this.state;
            this.props.loadRecords({...this.state.conditions, page, title}, this.state.currentTags);
            this.saveQueryCondition();
        });
    }

    handleRemoveCondition = (condition) => {
        const {conditions}                           = this.props;
        const {staffName, customerName, titleSurvey} = this.state.conditions;
        const {disabledPrev, disabledNext}           = this.state;
        let newConditions                            = conditions.filter(itemCondition => itemCondition.label !== condition.label);
        let conditionRemove                          = conditions.filter(itemCondition => itemCondition.label === condition.label);
        let removeParams                             = recordBuilderCondition.makeParamsRemoved(conditionRemove);
        this.props.removedCondition(removeParams);
        const newParams = recordBuilderCondition.makeParamsFromCondition(newConditions);
        this.setState({
            conditions: {
                ...newParams,
                staffName,
                customerName,
                titleSurvey,
                title: this.state.title
            },
            page: 1,
            pageSize: this.state.pageSize
        }, () => {
            this.props.saveQueryCondition(newConditions, this.state.conditions, {disabledPrev, disabledNext},
                this.state.currentTags, this.state.pageSize);
            this.filterRecords();
        })
    };

    handleSearchTags() {
        let currentState = {
            title: this.state.title,
            page: 1,
            ...this.state.conditions
        };
        this.props.loadRecords(currentState, this.state.currentTags);
        this.setState({
            page: 1
        }, () => {
            this.saveQueryCondition();
        });
    }

    handleChangeTags = (options) => {
        this.setState({
            currentTags: options ? options : [],
            page: 1
        }, () => this.saveQueryCondition())
    };

    render() {
        const {records, currentPage, totalPage, t, loading, conditions} = this.props;
        return (
            <div className='survey-list default-background-color'>
                <FilterRecordsModal show={this.state.showModal}
                                    showModal={this.handleShowModal.bind(this)}
                                    filterCondition={this.filterCondition.bind(this)}
                                    DateRange={DateRangeMobile}
                />
                <div hidden={this.state.showRecords} className='padding-responsive'>
                    <div>
                        <div className='d-flex justify-content-between '>
                            <div>
                                <KirokuButton color={'white'} onClick={this.closeModal.bind(this)}>
                                    <span className={'pr-2'}>{t('RecordList.filterBy')}</span>
                                    <i className='fa fa-chevron-down text-secondary' style={{fontSize: '10px'}}/>
                                </KirokuButton>
                            </div>
                        </div>
                        <div className='mt-2'>
                            <KirokuSectionDropdownShowMoreMobile
                                isSearchTags
                                tagType={'Customer'}
                                placeholder={t('Customer.placeholderSearch')}
                                onChange={this.handleChangeTags.bind(this)}
                                value={this.state.currentTags}
                                options={this.state.groupedOptions}
                                handleSearchTags={this.handleSearchTags.bind(this)}
                            />
                        </div>
                        <div className='mt-10'>
                            {
                                conditions.map((condition, index) => {
                                    return condition.value && condition.query !== 'page' && condition.query !== 'title' && condition.query !== 'pageSize' ?
                                        <span key={index} className='mr-6'>
                                            <KirokuLabel
                                                style={{
                                                    border: 'solid 1px #1991eb'
                                                }}
                                                onClick={this.handleRemoveCondition.bind(this, condition)}
                                                color={'default'}>
                                                <span className='mt-1'>{condition.label}</span>
                                                <span className='align-icon'>&times;</span>
                                            </KirokuLabel>
                                    </span> : ''
                                })
                            }
                        </div>
                        <div className='mt-10'>
                            <KirokuInputSearcher
                                value={this.state.title}
                                onChange={this.inputSearch.bind(this)}
                                onSubmit={this.onSubmitSearch.bind(this)}
                                id={'inputSearchRecordMobile'}
                            />
                        </div>
                        <div className={!records.length ? 'display-none-important' : ''}>
                            <div className={`pt-10 d-flex justify-content-between`}>
                                {authService.isAdmin() ?
                                <BulkAction
                                    title={t('RecordList.BulkAction')}
                                    items={bulkActionItems(t)}
                                    onSelect={event => {
                                        if (event.kirokuSelected.value === 'delete') {
                                            this.showModalBulkDeleteRecords();
                                        }
                                    }}
                                    resource-permission={'|recordList|button-bulkAction|'}
                                /> : ''}
                            <div/>
                            <div className='page-size-custom d-flex' style={{alignItems: 'baseline'}}>
                                <div className=''>{this.props.t('common.PageSize')}:&nbsp;&nbsp;</div>
                                    <KirokuDropdownClose
                                        style={{minWidth : '70px'}}
                                        title={this.state.defaultValue}
                                        items={this.selectPaginationForPage(setSizePagination)}
                                        onSelect={ event => {
                                            this.choicePagination(event.kirokuSelected.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="mt-10">
                                <div className="d-flex justify-content-between">
                                    <div/>
                                    <PaginationRecordMobile
                                        disabledPrev={this.state.disabledPrev || this.state.pageMin === this.state.page}
                                        disabledNext={this.state.disabledNext || this.state.pageMin === this.props.totalPage}
                                        increasePage={this.increasePage.bind(this)}
                                        decreasePage={this.decreasePage.bind(this)}
                                        currentPage={currentPage}
                                        totalPage={totalPage}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className='pt-10'>
                            <KirokuCardList
                                headerLeft={<span className='customer-name'>{t('RecordList.Surveys')}</span>}
                                headerRight={<span className='customer-name'>{t('RecordList.Action')}</span>}
                            >
                                {!loading ?
                                    <div>
                                        {records.length ?
                                            records.map(record =>
                                                <KirokuCard
                                                    key={record.id} action={
                                                    <Action surveyId={record.id}>
                                                        <KirokuPopoverItem>
                                                            <div
                                                                className='d-flex justify-content-between p-1 fix-icon-align'>
                                                                <Link to={`/records/edit-filedAll/${record.id}`}>
                                                                    <i className="fas fa-pencil-alt"/><span
                                                                    className='ml-1'>{t('common.Edit')}</span>
                                                                </Link>
                                                                <div className={'pr-1 pl-1'}/>
                                                                <Link to={`/records/edit/${record.id}`}>
                                                                    <i className="fas fa-pencil-alt"/><span
                                                                    className='ml-1'>{t('common.EditRecordOldUI')}</span>
                                                                </Link>
                                                            </div>
                                                        </KirokuPopoverItem>
                                                    </Action>}
                                                >

                                                    <p><Link
                                                        to={`/records/record/${record.id}`}>{record.title} </Link><KirokuCheckDraft
                                                        draftable={record}/></p>
                                                    <p className='customer-name'>{record.customer.name}</p>
                                                </KirokuCard>
                                            ) : <SearchNotFound/>}
                                    </div> : <KirokuLoading/>}
                            </KirokuCardList>
                        </div>
                        <div className={!records.length ? 'display-none-important' : ''}>
                            <div className={`pt-10 d-flex justify-content-between`}>
                                {authService.isAdmin() ?
                                <BulkAction
                                    location={'top'}
                                    title={t('RecordList.BulkAction')}
                                    items={bulkActionItems(t)}
                                    onSelect={event => {
                                        if (event.kirokuSelected.value === 'delete') {
                                            this.showModalBulkDeleteRecords();
                                        }
                                    }}
                                    resource-permission={'|recordList|button-bulkAction|'}
                                /> : '' }
                                <div/>
                                <div>
                                    <KirokuPagination
                                        disabledPrev={this.state.disabledPrev || this.state.pageMin === this.state.page}
                                        disabledNext={this.state.disabledNext || this.state.pageMin === this.props.totalPage}
                                        increasePage={this.increasePage.bind(this)}
                                        decreasePage={this.decreasePage.bind(this)}
                                        currentPage={currentPage}
                                        totalPage={totalPage}
                                    />
                                </div>
                            </div>
                        </div>
                        <ModalBulkDeleteRecord
                            currentTags={this.state.currentTags}
                            show={this.state.showBulkAction}
                            closeModal={this.showModalBulkDeleteRecords.bind(this)}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default RecordsMobile;
