import React, { Component } from 'react';

class KirokuRadio extends Component {

    render() {
        return (
            <div className="custom-control custom-radio mt-2">
                <input type="radio" className="custom-control-input" disabled={this.props.disabled} value={this.props.value} onChange={this.props.onChange.bind(this)} checked={this.props.checked} id={this.props.id} name={this.props.name}/>
                <label className="custom-control-label padding-top-2px" style={this.props.style || {}} htmlFor={this.props.id}>
                    <span className='setFontSpan'>
                        {this.props.children}
                    </span>
                </label>
            </div>
        );
    }
}


export default KirokuRadio;
