import React, { Component }        from 'react';
import moment                      from 'moment';
import { withNamespaces }          from 'react-i18next';
import KirokuLogicApplicated       from '../../../../../../ui/KirokuLogicApplicated';
import KirokuInputIcon             from '../../../../../../ui/KirokuInputIcon';
import config                      from '../../../../../../config';

@withNamespaces()
class DateQuestion extends Component {

    render() {
        const { question, value, surveyDetail, headerNextQuestion, t } = this.props;
        return (
            <div className='content-question-type'>
                <div className='wraper-question none-border-question pt-2'>
                    <div className="background-question">
                        <div className=' '>
                            <div
                                className="question-number font-heading-questions"> { question.heading ? question.heading : "" }</div>
                            <span hidden={ !surveyDetail } className={ 'float-right' }>
                                <KirokuLogicApplicated padding={ '5px 5px 5px 7px' } question={ question }/>
                            </span>
                        </div>
                        <div className="padding-content-question">
                            <span className='font-content-question'>
                                {question.unrequited ? t('createRecord.option'):''}{ question.content }</span>
                        </div>
                        <div
                            hidden={ typeof question.nextQuestion !== 'number' || !surveyDetail }
                            className='text-info pl-10 text-overflow-elip'>
                            <i className='fas fa-arrow-right' style={ { paddingTop: 4 } }/>
                            <span> { headerNextQuestion }</span>
                        </div>
                    </div>
                    <div className='answer-question padding-16-answer-question'>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <KirokuInputIcon disabled name={ 'date' } icon={ 'far fa-calendar-alt' }
                                                 value={ value ? moment.unix(this.props.value).format(config.formatDate) : '' }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DateQuestion;
