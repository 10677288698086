const initStatus = {
    records: [],
    title : '',
    titleSubmit : '',
    customerName : '',
    customerNameSubmit : '',
    orderByDelete_at: {
        field: 'delete_at',
        type: 'DESC'
    },
    orderByCreate_at: {
        field: 'create_at',
        type: 'DESC'
    },
    useOrderBy: {
        field: 'delete_at',
        type: 'DESC'
    },
};

const DeletedRecords = (state = initStatus, action) => {

    switch (action.type) {
        case 'SET_RECORDS_API':
            return {
                ...state,
                records: action.records
            };
        case 'CHANGE_SCREEN':
            return {
                ...state,
                title: '',
                titleSubmit: '',
                customerNameSubmit : '',
                customerName: '',
                orderByDelete_at: {
                    field: 'delete_at',
                    type: 'DESC'
                },
                orderByCreate_at: {
                    field: 'create_at',
                    type: 'DESC'
                },
                useOrderBy: {
                    field: 'delete_at',
                    type: 'DESC'
                },
            };
        case 'CHANGE_ORDER_BY_RECORDS':
            if (action.field === 'delete_at') {
                return  {
                    ...state,
                    orderByDelete_at: {
                        field: action.field,
                        type: action.typeOrder
                    },
                    useOrderBy: {
                        field: action.field,
                        type: action.typeOrder
                    }
                }
            }

            return {
                ...state,
                orderByCreate_at: {
                    field: action.field,
                    type: action.typeOrder
                },
                useOrderBy: {
                    field: action.field,
                    type: action.typeOrder
                }
            };
        case 'CHANGE_SEARCH_KEYWORD_RECORDS':
            return {
                ...state,
                [action.nameInput]: action.value
            };
        case 'SUBMIT_SEARCH_KEYWORD_RECORDS':
            return {
                ...state,
                customerNameSubmit: state.customerName,
                titleSubmit: state.title
            };
        default :
            return state;
    }
};
export default DeletedRecords;
