export const LOAD_RECORDS_WITH_CONDITIONS              = 'LOAD_RECORDS_WITH_CONDITIONS';

export function loadRecordsWithConditions(params, conditions) {
    return {
        type: LOAD_RECORDS_WITH_CONDITIONS,
        params,
        conditions
    }
}

