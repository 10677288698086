import React, { Component }         from 'react';
import { KirokuRadio }              from '../../../ui';
import { withNamespaces }           from 'react-i18next';
import KirokuCardWeb                from '../../../ui/KirokuCardWeb';
import KirokuCardTitleWeb           from '../../../ui/KirokuCardTitleWeb';
import KirokuCardBodyWeb            from '../../../ui/KirokuCardBodyWeb';
import ButtonEditQuestion           from './ButtonEditQuestion';
import PropTypes                    from 'prop-types';
import InputText                    from './InputText';
import { connect }                  from 'react-redux';
import { notificationEditQuestion } from '../../../actions/Survey';
import { checkUseHeadingQuestion }  from '../../Surveys/SurveyEditor/SurveyService';
import KirokuButtonLink             from '../../../ui/KirokuButtonLink';

@withNamespaces()
@connect(state => {
    return { takeSurvey: state.TakeSurveyWeb };
}, dispatch => {
    return {
        editQuestion: (flagEdit, indexQuestion) => {
            dispatch(notificationEditQuestion(flagEdit, indexQuestion));
        },
    };
})
class YesNoQuestion extends Component {

    state = {
        value    : this.props.value,
        disabled : this.props.disabled,
        flagEdit : false,
        validated: false,
    };

    componentDidMount() {
        this.setState({
            value   : this.props.value || null,
            disabled: this.props.disabled,
        });
        this.props.onAnswer({
            answer   : this.props.value || null,
            validated: this.props.value || null,
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                value: nextProps.value,
            });
            nextProps.onAnswer({
                answer   : nextProps.value || null,
                validated: nextProps.value || null,
            });
        }
        if (this.props.disabled !== nextProps.disabled || this.props.flagEdit !== nextProps.flagEdit) {
            this.setState({
                disabled: nextProps.disabled,
                flagEdit: nextProps.flagEdit,
            });
            if (!nextProps.flagEdit) {
                nextProps.onAnswer({
                    answer   : nextProps.value || null,
                    validated: nextProps.value || null,
                });
            }
            // reformat code
        }

    }

    handleOnChange(e) {
        if (!e) {
            e = { target: {value: ''}};
        }
        this.setState({
            value    : e.target.value || null,
            validated: e.target.value || null,
        });
        this.props.onAnswer({
            answer   : e.target.value || null,
            validated: e.target.value || null,
        });
    }

    handleActionEditQuestion(state) {
        this.setState({
            disabled: state.disabled,
            flagEdit: state.flagEdit,
        });
        this.props.editQuestion(state.flagEdit, this.props.question.index);
        if (!state.flagEdit) {
            this.props.onUpdate({
                answer   : this.state.value,
                validated: this.state.validated,
            }, this.props.question.index, this.props.index);
        }

    }

    render() {
        const {
                  question, t,
                  // index,
                  editor,
                  takeSurvey,
              }                   = this.props;
        const { disabled, value } = this.state;
        const hiddenButtonEdit    = takeSurvey.flagEditing ? takeSurvey.indexQuestion !== question.index : takeSurvey.flagEditing;
        const contentQuestion     = checkUseHeadingQuestion(question);

        return (
            <div id='component-question'>
                <div id='web-q' className='pb-3'>
                    <KirokuCardWeb>
                        <KirokuCardTitleWeb color={'card-web'}>
                            <div className="d-flex justify-content-between">
                                <div className='number-question question-number text-overflow-elip'>
                                    {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                                </div>
                                <div/>
                                <div hidden={disabled}>
                                    <KirokuButtonLink
                                        className='padding-reset-button-link'
                                        onClick={() => {this.handleOnChange()}}
                                    >
                                        {t('createRecord.resetAnswer')}
                                    </KirokuButtonLink>
                                </div>
                            </div>
                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb>
                            <div className={disabled ? 'wrap-overlay-action' : ''}>
                                <span className='content-name-question'>{question.content}</span>
                                <div className='answer-question' style={{ padding: 0, marginTop: '20px' }}>
                                    <KirokuRadio onChange={disabled ? () => {
                                    } : this.handleOnChange.bind(this)}
                                                 id={`${question.index}yes`} value={'Yes'}
                                                 name={`questionYesNo${question.index}`}
                                                 checked={value === 'Yes'}
                                                 style={{ width: '100%' }}
                                    >
                                        {t('createRecord.Yes')}
                                    </KirokuRadio>
                                    <KirokuRadio onChange={disabled ? () => {
                                    } : this.handleOnChange.bind(this)}
                                                 id={`${question.index}no`} value={'No'}
                                                 name={`questionYesNo${question.index}`}
                                                 checked={value === 'No'}
                                                 style={{ width: '100%' }}
                                    >
                                        {t('createRecord.No')}
                                    </KirokuRadio>
                                </div>
                            </div>
                            <div hidden={hiddenButtonEdit || editor}>
                                <ButtonEditQuestion {...this.state}
                                                    isUnrequited={question.unrequited}
                                                    clickEditQuestion={this.handleActionEditQuestion.bind(this)}
                                />
                            </div>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                </div>
            </div>
        );
    }
}

InputText.propTypes = {
    value: PropTypes.string,
};
export default YesNoQuestion;
