import React, {Component}         from 'react';
import KirokuButton               from "../../../../ui/KirokuButton";
import {withNamespaces}           from "react-i18next";
// import {teamService}              from "../../../../services";
import {connect}                  from "react-redux";
import KirokuResetFilterCondition from "../../../../ui/KirokuResetFilterCondition";

@withNamespaces()
@connect((state) => {
        return {
            currentConditions: state.KirokuAIBehavior,
            queryCondition: state.KirokuAIBehavior.filterCondition
        }
    },
    (dispatch) => ({
        updateConditionSuccess: () => dispatch({
            type: 'CURRENT_CONDITION_SUCCESS'
        })
    })
)

class ModalFilterSurvey extends Component {

    state = {
        showDateRange: true,
        showFilterRecords: false,
        show: false,
        selectItem: false,
        status: '',
        teamid: '',
        teams: [],
        params: {},
    };

    componentWillMount() {
        // teamService.loadTeams().then(teams => {
        //     this.setState({
        //         teams: teams
        //     })
        // });
    }

    dropDownItemsSurveys = () => {
        return [
            {
                value: 'Full_time',
                component: (
                    <div>{this.props.t('common.Full_time')}</div>
                ),
                label: this.props.t('common.Full_time')
            },
            {
                value: 'Part_time',
                component: (
                    <div>{this.props.t('common.Part_time')}</div>
                ),
                label: this.props.t('common.Part_time')
            }
        ]
    };

    // dropDownItemsTeams = (teams) => {
    //     return teams.map(team => (
    //         {
    //             value: team.id,
    //             component: (
    //                 <div>{team.name}</div>
    //             ),
    //             label: team.name
    //         }
    //     ));
    // };

    filterStatus(event) {
        this.setState({
            status: event.kirokuSelected.value,
            nameStatus: event.kirokuSelected.label === "All" || event.kirokuSelected.label === "全て" ? '' : event.kirokuSelected.label,
        })
    }

    // filterTeam(event) {
    //     this.setState({
    //         teamid: event.kirokuSelected.value,
    //         nameTeam: event.kirokuSelected.label === "All" || event.kirokuSelected.label === "全て" ? '' : event.kirokuSelected.label,
    //     })
    // }

    handleClickApply() {
        let params = [
            {
                value: this.state.status,
                query: 'status',
                label: this.state.nameStatus ? this.state.nameStatus : '',
                page : 1
            },
        //     {
        //         value: this.state.teamid,
        //         query: 'teamid',
        //         label: this.state.nameTeam ? this.state.nameTeam : ''
        //     }
        ];
        this.props.showModal();
        this.props.filterCondition(params);
        //this.props.currentFilterCondition(params)
    }

    handleCancelClick() {
        this.props.showModal();
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.currentConditions.updated) {
            if (nextProps.currentConditions.conditions.length) {
                nextProps.currentConditions.conditions.map(item => {
                    if (item.query === 'status') {
                        this.setState({
                            nameStatus: item.label,
                            status: item.value
                        });
                    }
                    if (item.query === 'teamid') {
                        this.setState({
                            nameTeam: item.label,
                            teamid: item.value
                        });
                    }
                    return item;
                });
            } else {
                this.setState({
                    nameStatus: '',
                    nameTeam: '',
                    status: '',
                    teamid: ''
                })
            }
            this.props.updateConditionSuccess()
        }
    }

    render() {
        // const {teams} = this.state;
        let {t}       = this.props;
        return (
            <div hidden={this.props.show} className='padding-responsive'>
                <div className={"view-1vh"}>
                    <div className='survey-list'>
                        <div className='padding-responsive'>
                            <button className='close' style={{float: 'left'}}
                                    onClick={this.handleCancelClick.bind(this)}>
                                <span style={{
                                    fontSize: '24px',
                                    color: '#a8aab7',
                                }}>&times;</span>
                            </button>
                            <div className='text-center'>
                                <span className='filter-record'>{t('common.FilterStaff')}</span>
                            </div>
                        </div>
                        <div className='p-2 mt-4 filter-modal'>
                            <div>
                                <KirokuResetFilterCondition
                                    title={this.state.nameStatus}
                                    defaultValue={t(`common.WorkingForm`)}
                                    items={this.dropDownItemsSurveys()}
                                    icon={'far fa-file-alt text-secondary'}
                                    onSelect={this.filterStatus.bind(this)}
                                />
                            </div>
                            {/*<div className='mt-10 pt-2'>*/}
                                {/*<KirokuResetFilterCondition*/}
                                    {/*title={this.state.nameTeam}*/}
                                    {/*defaultValue={t('common.Team')}*/}
                                    {/*items={this.dropDownItemsTeams(teams)}*/}
                                    {/*icon={'far fa-user text-secondary'}*/}
                                    {/*onSelect={this.filterTeam.bind(this)}*/}
                                {/*/>*/}
                            {/*</div>*/}
                            <div className='pt-3'>
                                <div className='fix-center'>
                                    <div className='d-flex justify-content-between'>
                                        <KirokuButton color={'white'}
                                                      onClick={this.handleCancelClick.bind(this)}>{t('common.Cancel')}</KirokuButton>
                                        <div className='pl-1 pr-1'/>
                                        <KirokuButton color={'primary'}
                                                      onClick={this.handleClickApply.bind(this)}>{t('common.Apply')}</KirokuButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalFilterSurvey;
