import {TYPED, TYPING} from "../actions/LoadingAction";

const DefaultDataDraft = {
    typingText: false
};

const LibState = (state = DefaultDataDraft, action) => {
    switch (action.type) {
        case TYPED :
            return {
                ...state,
                typingText: false
            };
        case TYPING :
            return {
            ...state,
            typingText: true
        };
        default : return state
    }
};

export default LibState;
