import GenericQuestion from "./GenericQuestion";

class YesNo extends GenericQuestion {

    answer(answer) {

        if(!answer) {
            this.payload.answers = '';
            return this.getNext('');
        }

        this.payload.answers = answer;
        return this.getNext(answer);
    }

    getNext(answer) {

        if(!answer) {
            return this.payload.index + 1;
        }

        if (typeof this.payload.nextQuestion === 'number') {
            return this.payload.nextQuestion
        }

        if (answer === 'Yes') {
            if(this.payload.nextQuestionYes === 0) {
                return this.payload.nextQuestionYes
            }
            return this.payload.nextQuestionYes || this.payload.index + 1
        }

        if (answer === 'No') {
            if(this.payload.nextQuestionNo === 0) {
                return this.payload.nextQuestionNo
            }
            return this.payload.nextQuestionNo || this.payload.index + 1
        }

        return this.payload.index + 1;
    }
}
export default YesNo;
