const initialState = {
    message:'',
    error: false,
    tfa: null,
    isTfa: false
};

const KirokuAIReject = (state = initialState, action) => {
    switch (action.type) {
        case "ERROR_LOGIN":
            return {
                ...state,
                error : true,
                message: action.message,
                isTfa: action.tfa
            };
        case "ENABLE_TFA":
            return {
                ...state,
                tfa: action.tfa
            };
        case "CLEAR_TFA":
            return {
                ...state,
                tfa: null
            };
        default:
            return state
    }
};

export default KirokuAIReject;
