import React, {Component}                                                  from 'react';
import '../customers.css';
import CustomerDetailWeb    from "./Web/CustomerDetailWeb";
import CustomerDetailMobile                     from "./Mobile/CustomerDetailMobile";
import { CheckRerenderResponsive } from "../../../ui";
import connect           from "react-redux/es/connect/connect";
import {
    DEFAULT_CUSTOMER, loadCustomerById, uploadDocument, loadDocuments
}                        from "../../../actions/Customer";
import {loadSurveyModal} from "../../../actions/Survey";
import {scrollService}   from "../../../services";
import KirokuTitlepage   from "../../../ui/KirokuTitlepage";
import {currentPage}     from "../../../actions/CurrentPage";
import {withNamespaces}  from "react-i18next";
import KirokuLoading     from "../../../ui/KirokuLoading";
import { loadUsers }                                           from '../../../actions/User';
import { getRecordForDetailPage, setInitRecordsForDetailPage } from '../../../actions/Record';

@withNamespaces()
@connect(state => {
    return {
        customer: state.KirokuAICustomer.customer,
        isLoadingCustomer: state.KirokuAICustomer.isLoadingCustomer,
        isLoadingSurvey: state.KirokuAISurvey.isLoadingSurvey,
        surveyForModal: state.KirokuAISurvey.surveyForModal,
        loadingResultDocuments: state.KirokuAICustomer.loadingResultDocuments,
        loadingDocumentSuccess: state.KirokuAICustomer.loadingDocumentSuccess
    }
}, dispatch => {
    return {
        loadCustomerById: (id, isDetail) => {
            dispatch(loadCustomerById(id, isDetail))
        },
        loadSurveys: () => {
            dispatch(loadSurveyModal())
        },
        currentPageUsed: (name) => {
            dispatch(currentPage(name))
        },
        defaultCustomer: () => {
            dispatch({
                type: DEFAULT_CUSTOMER
            })
        },
        uploadDocument: (document) => {
            dispatch(uploadDocument(document))
        },
        loadDocuments: (params) => {
            dispatch(loadDocuments(params))
        },
        loadUsers: () => {
            dispatch(loadUsers())
        },
        getRecordForDetailPage: (params) => {
            dispatch(getRecordForDetailPage(params))
        },
        setInitRecordsForDetailPage: () => {
            dispatch(setInitRecordsForDetailPage())
        },
    };
})
class CustomerDetail extends Component {
    page = {
        title: <KirokuTitlepage title={this.props.t('common.Customer')}/>,
    };

    componentWillMount() {
        scrollService.top();
        this.props.loadCustomerById(this.props.match.params.id, true);
        this.props.loadUsers();
        this.props.loadSurveys();
        this.props.loadDocuments({customerId: this.props.match.params.id, page: this.props.page || 1});
        this.props.currentPageUsed(this.page);
        this.props.getRecordForDetailPage({page: 1, customerId: this.props.match.params.id});
    }

    componentWillUnmount() {
        this.props.defaultCustomer();
        this.props.setInitRecordsForDetailPage();
    }

    uploadDocument = (document) => {
        this.props.uploadDocument(document);
    };

    render() {
        let {customer} = this.props;
        let web        = <CustomerDetailWeb surveyForModal={this.props.surveyForModal}
                                            customer={customer}
                                            history={this.props.history}
                                            handleSubmit={this.uploadDocument.bind(this)}
                                            id={this.props.match.params.id}

        />;
        let mobile     = <CustomerDetailMobile surveyForModal={this.props.surveyForModal}
                                               customer={customer}
                                               history={this.props.history}
                                               handleSubmit={this.uploadDocument.bind(this)}
                                               id={this.props.match.params.id}
        />;
        return (
            <div>
                {this.props.isLoadingCustomer && this.props.isLoadingSurvey && this.props.loadingDocumentSuccess ?
                    <div className='default-background-color'>
                        <CheckRerenderResponsive mobile={mobile} web={web}/>
                    </div> : <KirokuLoading/>
                }
            </div>

        )
    }
}

export default CustomerDetail;
