const initSatePageSupport = {
    inputNumFirst : '',
    inputNumSecond: '',
    inputNumThird : '',
    isConnect     : false,
    disabledInputs: false,
    disabledThirdInput : false
};

const getInputFocus = (inputNumber) => {
    if (inputNumber === 'inputNumFirst') return 'inputNumSecond';
    if (inputNumber === 'inputNumSecond') return 'inputNumThird';
    if (inputNumber === 'inputNumThird') return 'inputNumFirst';
};

const isConnect = (state) => {
    const {inputNumFirst, inputNumSecond, inputNumThird} = state;
    return inputNumFirst.length === 3 && inputNumSecond.length === 3 && inputNumThird.length === 3
};

const disabledInputs =(state) => {
    const {inputNumFirst} = state;
    return inputNumFirst.length === 3
};


const disabledThirdInput = (state) => {
    const { inputNumSecond} = state;
    return inputNumSecond.length === 3;
};

const LoginSupportPage = (state = initSatePageSupport, action) => {
    switch (action.type) {
        case 'CHANGE_INPUT_NUM':

            if (action.value.replace(/[^0-9]/g, '').length === 3) {
                document.getElementById(getInputFocus(action.name)).focus();
            }
            if (action.value.length < 4) {
                const newState = {
                    ...state,
                    [action.name]: action.value.replace(/[^0-9]/g, '')
                };
                return {
                    ...newState,
                    isConnect : isConnect(newState),
                    disabledInputs: disabledInputs(newState),
                    disabledThirdInput : disabledThirdInput(newState)
                };
            }
            return state;
        case 'CONNECT_PAGE_SUPPORT':
            let numberConnect = `https://surfly.io/${state.inputNumFirst}-${state.inputNumSecond}-${state.inputNumThird}`;
            window.open(numberConnect);
            return state;
        case 'SET_INIT_STATE_PAGE_SUPPORT':
            return initSatePageSupport;
        default :
            return state
    }
};

export default LoginSupportPage;
