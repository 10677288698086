import React, { Component } from 'react';
import { withNamespaces }   from 'react-i18next';

import {
    DateQuestion,
    DateTimeQuestion,
    InputText,
    MediaFileQuestion,
    Message,
    MultiChoice,
    SingleChoice,
    TimeQuestion,
    YesNoQuestion,
    NumberQuestion
} from '../../QuestionTypeMobile';


import { hasTimer }                                                        from '../../../../Timer';
import CreatorLogic                                                        from '../CreatorLogic';
import { KirokuButton }                                                    from '../../../../ui';
import { connect }                                                         from 'react-redux';
import { actionBackScreenDetail, actionBeginAnswer, actionLoadPageReview } from '../../../../actions/TakeSurvey';
import { validateAnswer }                                                  from '../../sevicesInRecord';


@hasTimer()
@withNamespaces()
@connect(null, dispatch => {
    return {
        actionBackScreenDetail: () => {
            dispatch(actionBackScreenDetail())
        },
        actionLoadPageReview  : (recorded) => {
            dispatch(actionLoadPageReview(recorded))
        },
        actionBeginAnswer     : () => {
            dispatch(actionBeginAnswer())
        }
    }
})
class Survey extends Component {

    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillUnmount() {
        this.props.onRef(undefined);
        this.props.timer.unRegisterLastJob();
    }

    questionsMap = {
        InputText,
        Message,
        MultiChoice,
        YesNo : YesNoQuestion,
        Date  : DateTimeQuestion,
        Single: SingleChoice,
        Media : MediaFileQuestion,
        OnlyDate: DateQuestion,
        Time:TimeQuestion,
        Number : NumberQuestion
    };

    constructor(props) {
        super(props);
        this.state = {
            currentQuestion: {},
            currentAnswer  : null,
            questionOrder  : 1,
            reviewRecord   : false
        };
        this.props.timer.record(() => {
            this.autoBackupData()
        });
    }


    autoBackupData() {
        this.props['saveBackupData'](this.createLogic.toDraft());
    }

    autoSaveDraft() {
        this.props.saveDraft(this.createLogic.toRecord());
    }

    getDraft(dataDraft, survey) {
        if (dataDraft) {
            this.createLogic = CreatorLogic.fromDraft(dataDraft);

            this.setState({
                currentQuestion: this.createLogic.current().payload,
                questionOrder  : dataDraft.flow.length,
                reviewRecord   : this.createLogic.getEndQuestion(),
                currentAnswer  : validateAnswer(this.createLogic.current().payload)
            });
            this.props.actionBeginAnswer()
        } else {
            this.createLogic = new CreatorLogic(survey.questions);
            this.setState({
                currentQuestion: this.createLogic.current().payload,
                questionOrder  : 1,
                reviewRecord   : this.createLogic.getEndQuestion()
            });
        }
    }

    componentWillMount() {
        const {survey, statusRecord}   = this.props;
        if(window.history.previous === "/records/create-record") {
            this.getDraft(statusRecord.statusDraft, survey);
        } else {
            this.getDraft(null, survey);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.survey !== prevProps.survey) {
            const {survey, statusRecord}   = this.props;
            if(window.history.previous === "/records/create-record") {
                this.getDraft(statusRecord.statusDraft, survey);
            } else {
                this.getDraft(null, survey);
            }
        }
    }


    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.keyLoadData !== nextProps.keyLoadData) {
            this.getDraft(nextProps.statusRecord[nextProps.keyLoadData], nextProps.survey);
        }
    }

    handleNextQuestion() {
        this.createLogic.next(this.state.currentAnswer);
        this.setState({
            reviewRecord   : this.createLogic.getEndQuestion(),
            currentAnswer  : this.createLogic.getEndQuestion()? this.createLogic.current().payload.answers : null,
            questionOrder  : this.state.questionOrder + 1,
            currentQuestion: this.createLogic.current().payload
        })
    }

    handleOnAnswer(answer) {
        this.setState({
            currentAnswer: answer.validated
        });
        this.createLogic.current().answer(answer.answer);
    }

    handleBackQuestion() {
        if (this.state.questionOrder === 1) {
            this.props.actionBackScreenDetail();
        } else {
            this.createLogic.back();
            this.setState({
                reviewRecord   : false,
                questionOrder  : this.state.questionOrder - 1,
                currentQuestion: this.createLogic.current().payload
            });

        }

    }

    redirectPageReview() {
        const recorded = this.createLogic.toRecord();
        this.props.actionLoadPageReview(recorded);
    }

    render() {
        const {t} = this.props;
        const {
                  currentQuestion,
                  questionOrder,
                  reviewRecord,
                  currentAnswer
              }   = this.state;
        const Question = this.questionsMap[currentQuestion.type];
        return (

            <div className="survey" hidden={this.props.hidden}>
                <Question
                    question={currentQuestion}
                    value={currentQuestion.answers}
                    onAnswer={this.handleOnAnswer.bind(this)}
                    index={questionOrder}
                />
                <div className="action-record">
                    <KirokuButton
                        onClick={this.handleBackQuestion.bind(this)}
                        color={'light'}>{t('createRecord.back')}</KirokuButton>
                    <KirokuButton
                        hidden={reviewRecord}
                        disabled={ !currentAnswer && !currentQuestion.unrequited }
                        onClick={this.handleNextQuestion.bind(this)}
                        color={'primary'}>{t('createRecord.next')}</KirokuButton>


                    <KirokuButton
                        hidden={!reviewRecord}
                        disabled={!currentAnswer && !currentQuestion.unrequited}
                        onClick={this.redirectPageReview.bind(this)}
                        color={'primary'}>
                        {t('createRecord.Review')}
                    </KirokuButton>
                </div>
            </div>
        );
    }
}

export default Survey;




