import React, { Component }      from 'react';
import moment                    from 'moment';
import KirokuInputIcon           from '../../../../ui/KirokuInputIcon';
import { withNamespaces }        from 'react-i18next';
import KirokuLogicApplicated     from "../../../../ui/KirokuLogicApplicated";
import {checkUseHeadingQuestion} from "../../../Surveys/SurveyEditor/SurveyService";
import config                    from '../../../../config';

@withNamespaces()
class DateQuestion extends Component {

    render() {
        const {question, value, surveyDetail, headerNextQuestion, t} = this.props;
        const contentQuestion      = checkUseHeadingQuestion(question);
        return (
            <div className='content-question'>
                <div className='wraper-question'>
                    <div className="background-question">
                        <div className='number-question '>
                            <div
                                className="question-number text-overflow-elip">
                                {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                            </div>
                            <span hidden={!surveyDetail} className={'float-right'}>
                                <KirokuLogicApplicated padding={'5px 5px 5px 7px'} question={question}/>
                            </span>
                        </div>
                        <span className='content-name-question'>{surveyDetail ? question.content :contentQuestion}</span>
                        <div
                            hidden={typeof question.nextQuestion !== 'number' || !surveyDetail}
                            className='text-info pl-10 text-overflow-elip'>
                            <i className='fas fa-arrow-right' style={{paddingTop: 4}}/>
                            <span> {headerNextQuestion}</span>
                        </div>
                    </div>
                    <div className='answer-question'>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <KirokuInputIcon disabled name={'date'} icon={'far fa-calendar-alt'}
                                                 value={value ? moment.unix(this.props.value).format(config.formatDateTh) : ''}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DateQuestion;
