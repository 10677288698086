import React, { Component }       from 'react';
import {
    DateQuestion,
    DateTime,
    InputText,
    MediaFile,
    Message,
    MultiChoice,
    SingleChoice,
    TimeQuestion,
    YesNo,
    NumberQuestion,
}                                 from './QuestionTypeReview';
import { getHeadingNextQuestion } from '../sevicesInRecord';


export class ViewQuestioned extends Component {

    questionsMap = {
        InputText,
        Message,
        MultiChoice,
        YesNo : YesNo,
        Date  : DateTime,
        Single: SingleChoice,
        Media : MediaFile,
        OnlyDate: DateQuestion,
        Time: TimeQuestion,
        Number : NumberQuestion
    };

    render() {
        const { questions }  = this.props;
        return (
            <div id='question-review' style={{paddingBottom: '50px', ...this.props.style}}>
                {questions.map((question, index) => {
                    const Question = this.questionsMap[question.type];
                    if (question.type !== 'Message') {
                        return <Question
                            key={question.index}
                            question={{...question, index: index}}
                            headerNextQuestion={getHeadingNextQuestion(question, questions)}
                            value={question.answers}
                        />
                    }
                    return null
                })}
            </div>
        );
    }
}


