import '../record.css'
import React, {Component}        from 'react';
import {KirokuInput}             from '../../../ui';
import {withNamespaces}          from 'react-i18next';
import PropTypes                 from 'prop-types';
import {checkUseHeadingQuestion} from "../../Surveys/SurveyEditor/SurveyService";
import KirokuButtonLink          from '../../../ui/KirokuButtonLink';

@withNamespaces()
class NumberQuestion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        }
    }

    componentDidMount() {
        this.props.onAnswer({
            answer: this.state.value,
            validated: this.state.value ? this.state.value : null
        });
    }

    handleChange(event) {
        if (!event) {
            event = { target: {value: ''} }
        }
        let value = event.target.value.replace(/(?!^-)[^0-9.]/g, "").replace(/(\..*)\./g, '$1');
        this.setState({
            value: value
        });

        this.props.onAnswer({
            answer   : value,
            validated: value ? value : null
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.value !== nextProps.value || this.props.question.index !== nextProps.question.index) {
            this.setState({value: nextProps.value});
            nextProps.onAnswer({
                answer   : nextProps.value,
                validated: nextProps.value ? nextProps.value : null
            })
        }
    }

    render() {
        const {
                  question, t
                  //index
              } = this.props;
        const contentQuestion = checkUseHeadingQuestion(question);
        return (
            <div id='component-question'>
                <div className='content-question'>
                    <div className='wraper-question'>
                        <div className="background-question">
                            <div className='number-question d-flex justify-content-between'>
                                <span className="question-number">
                                    {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                                </span>
                                <div/>
                                <div>
                                    <KirokuButtonLink
                                        className='padding-reset-button-link'
                                        onClick={() => {this.handleChange()}}>
                                        {t('createRecord.resetAnswer')}
                                    </KirokuButtonLink>
                                </div>
                            </div>

                        </div>
                        <div className='answer-question'>
                            <span className='content-name-question padding-question-content '>
                                {question.content}
                            </span>
                            <KirokuInput name={`${question.index}input`}
                                         type='number'
                                         inputMode="decimal"
                                         onChange={this.handleChange.bind(this)}
                                         placeholder={t('common.placeholderNumberQuestion')}
                                         value={this.state.value || ''}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

NumberQuestion.propTypes = {
    value: PropTypes.string
};
export default NumberQuestion;
