import { withNamespaces }       from 'react-i18next';
import connect                  from 'react-redux/es/connect/connect';
import { turnOnLoadDraft }      from '../../../../actions/TakeSurvey';
import KirokuModal              from '../../../../ui/KirokuModal';
import KirokuModalHeader        from '../../../../ui/KirokuModalHeader';
import moment                   from 'moment';
import KirokuModalFooter        from '../../../../ui/KirokuModalFooter';
import KirokuButton             from '../../../../ui/KirokuButton';
import React, { Component }     from 'react';
import KirokuModalBody          from '../../../../ui/KirokuModalBody';
import { discardDraftRecoding } from '../../../../actions/Draft';
import config                   from '../../../../config';

@withNamespaces()
@connect(state => {
    return {
        dataDraftRecording      : state.KirokuAIDraftRecording.dataDraftRecording,
        surveyTemplateOfCustomer: state.KirokuAISurvey.surveyTemplateOfCustomer
    }
}, dispatch => {
    return {
        turnOnLoadDraft: () => {
            dispatch(turnOnLoadDraft())
        },
        discardDraft   : (surveyId, customerId) => {
            dispatch(discardDraftRecoding(surveyId, customerId))
        }
    }
})
class ModalNotificationDraft extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalDraft: null
        };
    }

    componentWillMount() {
        const {dataDraftRecording} = this.props;
        this.setState({modalDraft: dataDraftRecording})
    }

    handleDiscard() {
        // const surveyTemplateOfCustomer = this.props.surveyTemplateOfCustomer;
        // this.props.discardDraft(
        //     surveyTemplateOfCustomer.survey.id,
        //     surveyTemplateOfCustomer.customer.id
        // );
        this.closeModal();
    }

    closeModal() {
        this.setState({modalDraft: false})
    }

    handleLoadDraft() {
        this.props.turnOnLoadDraft();
        this.closeModal();
    }

    render() {
        const {t}             = this.props;
        const createDateDraft = this.props.dataDraftRecording;
        return (
            createDateDraft ?
            <KirokuModal show={this.state.modalDraft} onCloseModal={this.closeModal.bind(this)}>
                <KirokuModalHeader>
                    <div className={'text-center'}>
                        <div className='f-bold'>
                            {/*{t('createRecord.RecoverRecordDraft')}*/}
                            {t('createRecord.TitleModalRecoverRecordBackup')}
                        </div>
                    </div>
                </KirokuModalHeader>
                <KirokuModalBody>
                    <div className='fix-center pt-3 pb-3'>
                        <i style={{fontSize: 50}} className="fas fa-file-download text-secondary pb-3"/>
                        <div>
                            {t('createRecord.YouHaveABackupRecordForCustomer')}
                            {/*{t('createRecord.YouHaveADraftRecordForCustomer')}*/}
                        </div>
                        <div
                            className='f-bold'>{createDateDraft ? moment.unix(createDateDraft.create_at).format(config.formatDateTimeTh) : ''}</div>
                    </div>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <div className='d-flex justify-content-between' style={{width: 250}}>
                        <KirokuButton style={{width: 120}} onClick={this.handleDiscard.bind(this)} color={'white'}>
                            {t('createRecord.discard')}
                        </KirokuButton>
                        <KirokuButton color={'primary'} style={{width: 120}}
                                      onClick={this.handleLoadDraft.bind(this)}
                        >
                            {t('createRecord.loadDraft')}
                        </KirokuButton>
                    </div>
                </KirokuModalFooter>
            </KirokuModal> : <div/>
        )
    }
}

export default ModalNotificationDraft;
