import { GET_SURVEYS_BETA } from '../../actions/Survey';
import _                    from 'lodash';

const defaultModalSelectSurveys = {
    surveys                 : [],
    libSurveys              : [],
    surveysSelected         : [],
    libSurveysSelected      : [],
    isOpenTabSelected       : false,
    isOpenModalSelectSurveys: false,
    keywordTabOptions       : '',
    keyword                 : '',
    historyModalSelectSurveys: {
        surveys                 : [],
        libSurveys              : [],
        surveysSelected         : [],
        libSurveysSelected      : [],
        isOpenTabSelected       : false,
        isOpenModalSelectSurveys: false,
        keywordTabOptions       : '',
        keyword                 : '',
    }
};
const selectSurveysIsSelectedOfSurveys = (surveysSelected, surveys) => {
    return surveys.map(survey => {
        return {
            ...survey,
            selected: _.findIndex(surveysSelected, ['id', survey.id]) !== -1,
        };
    });
};

function setSelectedSurvey(surveys, isSelected) {
    return surveys.map(item => ({ ...item, selected: isSelected }));
}

const ModalSelectSurveys = (state = defaultModalSelectSurveys, action) => {
    switch (action.type) {
        case 'GET_CUSTOMERS_BY_USER_AND_CUSTOMERS_BETA_INIT':
            return {
                ...state,
                surveysSelected         : [],
                libSurveysSelected      : [],
                libSurveys: state.libSurveys.map(item => ({ ...item, selected: false })),
                surveys   : state.libSurveys.map(item => ({ ...item, selected: false })),
                historyModalSelectSurveys: {
                    ...state,
                    surveysSelected         : [],
                    libSurveysSelected      : [],
                    libSurveys: state.libSurveys.map(item => ({ ...item, selected: false })),
                    surveys   : state.libSurveys.map(item => ({ ...item, selected: false })),
                }
            };
        case GET_SURVEYS_BETA:
            return {
                ...state,
                surveys   : action.surveys.map(item => ({ ...item, selected: false })),
                libSurveys: action.surveys.map(item => ({ ...item, selected: false })),
                historyModalSelectSurveys: {
                    ...state,
                    surveys   : action.surveys.map(item => ({ ...item, selected: false })),
                    libSurveys: action.surveys.map(item => ({ ...item, selected: false })),
                }
            };
        case 'CHANGE_IS_OPEN_MODAL_SELECT_SURVEYS':
            return {
                ...state,
                isOpenModalSelectSurveys: !state.isOpenModalSelectSurveys,
                historyModalSelectSurveys: {
                    ...state,
                    isOpenModalSelectSurveys: !state.isOpenModalSelectSurveys,
                }
            };

        case 'CHANGE_IS_OPEN_TAB_SELECTED_MODAL_SELECT_SURVEYS':
            if (action.isOpenTabSelected === state.isOpenTabSelected) {
                return state;
            }
            let libSurveys = [...state.libSurveys].map(it => ({ ...it, selected: false }));

            state.libSurveysSelected.filter((itemSurvey) => itemSurvey.selected)
                .forEach(item => {
                    let index           = _.findIndex(libSurveys, (itmLibSurveys) => itmLibSurveys.id === item.id);
                    libSurveys[ index ] = {
                        ...libSurveys[ index ],
                        selected: item.selected,
                    };
                });

            return {
                ...state,
                keyword           : '',
                keywordTabOptions : '',
                isOpenTabSelected : action.isOpenTabSelected,
                surveys           : libSurveys,
                libSurveys        : libSurveys,
                surveysSelected   : state.libSurveys.filter((itemSurvey) => itemSurvey.selected),
                libSurveysSelected: state.libSurveys.filter((itemSurvey) => itemSurvey.selected),
                historyModalSelectSurveys: {
                    ...state.historyModalSelectSurveys,
                    isOpenTabSelected : action.isOpenTabSelected,
                }
            };

        case 'SELECT_SURVEY_MODAL_RECORD_BETA':
            let surveys             = [...state.surveys];
            surveys[ action.index ] = {
                ...surveys[ action.index ],
                selected: !surveys[ action.index ].selected,
            };

            let indexSurveyInLibSurvey                = _.findIndex(state.libSurveys, (itemSurvey) => (itemSurvey.id === surveys[ action.index ].id));
            let stateLibSurveys                       = [...state.libSurveys];
            stateLibSurveys[ indexSurveyInLibSurvey ] = {
                ...stateLibSurveys[ indexSurveyInLibSurvey ],
                selected: !stateLibSurveys[ indexSurveyInLibSurvey ].selected,
            };

            return {
                ...state,
                surveys   : surveys,
                libSurveys: stateLibSurveys,
            };
        case 'SELECT_ALL_SURVEY_MODAL_RECORD_BETA':
            let libSurveyNew = [...state.libSurveys];
            let surveyNew    = state.surveys.map(itemSurvey => {
                let indexItemSurveyInLibSurvey = _.findIndex(libSurveyNew, (surveys) => surveys.id === itemSurvey.id);
                if (indexItemSurveyInLibSurvey !== -1) {
                    libSurveyNew[ indexItemSurveyInLibSurvey ] = {
                        ...libSurveyNew[ indexItemSurveyInLibSurvey ],
                        selected: !(_.findIndex(state.surveys, (ctm) => (!ctm.selected)) === -1),
                    };
                }
                return {
                    ...itemSurvey,
                    selected: !(_.findIndex(state.surveys, (ctm) => (!ctm.selected)) === -1),
                };
            });
            return {
                ...state,
                surveys   : surveyNew,
                libSurveys: libSurveyNew,
            };
        case 'SELECT_SURVEY_MODAL_RECORD_BETA_SELECTED':
            let surveysSelected             = [...state.surveysSelected];
            surveysSelected[ action.index ] = {
                ...surveysSelected[ action.index ],
                selected: !surveysSelected[ action.index ].selected,
            };

            let indexSurveyInLibSurveySelected                        = _.findIndex(state.libSurveysSelected, (itemSurvey) => (itemSurvey.id === surveysSelected[ action.index ].id));
            let stateLibSurveysSelected                               = [...state.libSurveysSelected];
            stateLibSurveysSelected[ indexSurveyInLibSurveySelected ] = {
                ...stateLibSurveysSelected[ indexSurveyInLibSurveySelected ],
                selected: !stateLibSurveysSelected[ indexSurveyInLibSurveySelected ].selected,
            };

            return {
                ...state,
                surveysSelected   : surveysSelected,
                libSurveysSelected: stateLibSurveysSelected,
            };
        case 'SELECT_ALL_SURVEY_MODAL_RECORD_BETA_SELECTED':
            let libSurveySelectedNew = [...state.libSurveysSelected];
            let surveySelectedNew    = [...state.surveysSelected].map(itnSurvey => {
                let indexItemSurveyInLibSurveySelected = _.findIndex(libSurveySelectedNew, (surveys) => surveys.id === itnSurvey.id);
                if (indexItemSurveyInLibSurveySelected !== -1) {
                    libSurveySelectedNew[ indexItemSurveyInLibSurveySelected ] = {
                        ...libSurveySelectedNew[ indexItemSurveyInLibSurveySelected ],
                        selected: !(_.findIndex(state.surveysSelected, (ctm) => (!ctm.selected)) === -1),
                    };
                }
                return {
                    ...itnSurvey,
                    selected: !(_.findIndex(state.surveysSelected, (ctm) => (!ctm.selected)) === -1),
                };
            });
            return {
                ...state,
                surveysSelected   : surveySelectedNew,
                libSurveysSelected: libSurveySelectedNew,
            };
        case 'CHANGE_KEYWORD_SEARCH_TAB_OPTION_SURVEYS':
            let keySearch = action.keyword.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
            let libSurvey = [...state.libSurveys];
            libSurvey     = libSurvey.filter((itmSur) => itmSur.title.toLowerCase().search(keySearch.toLowerCase()) !== -1);
            return {
                ...state,
                surveys          : libSurvey,
                keywordTabOptions: keySearch,
            };
        case 'CHANGE_KEYWORD_SEARCH_TAB_SELECTED_SURVEYS':
            let keySearchSelected = action.keyword.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
            let libSurveySelected = [...state.libSurveysSelected];
            libSurveySelected     = libSurveySelected.filter((itmSur) => itmSur.title.toLowerCase().search(keySearchSelected.toLowerCase()) !== -1);
            return {
                ...state,
                surveysSelected: libSurveySelected,
                keyword        : keySearchSelected,
            };
        case 'HANDLE_CONFIRM_MODAL_SELECT_SURVEYS':
            const listSurveys = state.isOpenTabSelected ? state.libSurveysSelected : state.libSurveys;
            return {
                ...state,
                surveysSelected   : listSurveys.filter((item) => (item.selected)),
                libSurveysSelected: listSurveys.filter((item) => (item.selected)),
                surveys           : selectSurveysIsSelectedOfSurveys(listSurveys.filter((item) => (item.selected)), state.libSurveys),
                libSurveys        : selectSurveysIsSelectedOfSurveys(listSurveys.filter((item) => (item.selected)), state.libSurveys),
                keyword           : '',
                keywordTabOptions : '',
                isOpenTabSelected: listSurveys.filter((item) => (item.selected)).length,
                historyModalSelectSurveys: {
                    ...state,
                    keyword           : '',
                    keywordTabOptions : '',
                    surveysSelected   : listSurveys.filter((item) => (item.selected)),
                    libSurveysSelected: listSurveys.filter((item) => (item.selected)),
                    surveys           : selectSurveysIsSelectedOfSurveys(listSurveys.filter((item) => (item.selected)), state.libSurveys),
                    libSurveys        : selectSurveysIsSelectedOfSurveys(listSurveys.filter((item) => (item.selected)), state.libSurveys),
                    isOpenTabSelected: listSurveys.filter((item) => (item.selected)).length,
                }
            };
        case 'CANCEL_MODAL_SELECT_SURVEYS':
            return {
                ...state.historyModalSelectSurveys,
                isOpenTabSelected: state.historyModalSelectSurveys.libSurveysSelected.filter((item) => (item.selected)).length,
            };
        case 'LOAD_RECORD_FROM_ANALYSIS':
            const isSelectedTemplate = setSelectedSurvey(state.libSurveys.filter(item => item.id === action.condition.templateId), true);
            return {
                ...state,
                surveysSelected : isSelectedTemplate,
                libSurveysSelected: isSelectedTemplate,
                isOpenTabSelected : true,
                historyModalSelectSurveys: {
                    ...state.historyModalSelectSurveys,
                    surveysSelected :  isSelectedTemplate,
                    libSurveysSelected: isSelectedTemplate,
                    isOpenTabSelected : true,
                }

            };
        default:
            return state;
    }
};
export default ModalSelectSurveys;
