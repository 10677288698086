const initStatus = {
    customers: [],
    keyword: '',
    keywordSubmit: '',
    orderByDelete_at: {
        field: 'delete_at',
        type: 'DESC'
    },
    orderByCreate_at: {
        field: 'create_at',
        type: 'DESC'
    },
    useOrderBy: {
        field: 'delete_at',
        type: 'DESC'
    },
};

const DeletedCustomer = (state = initStatus, action) => {

    switch (action.type) {
        case 'CHANGE_SCREEN':
            return {
                ...state,
                keyword: '',
                keywordSubmit: '',
                orderByDelete_at: {
                    field: 'delete_at',
                    type: 'DESC'
                },
                orderByCreate_at: {
                    field: 'create_at',
                    type: 'DESC'
                },
                useOrderBy: {
                    field: 'delete_at',
                    type: 'DESC'
                },
            };
        case 'CHANGE_KEY_WORD_CUSTOMER' :
            return {
                ...state,
                keyword: action.keyword
            };
        case 'SET_CUSTOMERS_API':
            return {
                ...state,
                customers: action.customer,
            };
        case 'SUBMIT_SEARCH_KEYWORD_CUSTOMERS' :
            return  {
                ...state,
                keywordSubmit : state.keyword
            };
        case 'CHANGE_ORDER_BY_CUSTOMER' :
            if (action.field === 'delete_at') {
                return  {
                    ...state,
                    orderByDelete_at: {
                        field: action.field,
                        type: action.typeOrder
                    },
                    useOrderBy: {
                        field: action.field,
                        type: action.typeOrder
                    }
                }
            }

            return {
                ...state,
                orderByCreate_at: {
                    field: action.field,
                    type: action.typeOrder
                },
                useOrderBy: {
                    field: action.field,
                    type: action.typeOrder
                }
            };

        default :
            return state;
    }
};

export default DeletedCustomer;
