import React, { Component } from 'react';
import { CheckDivide }      from "../../ui";
import SupportPageMobile    from "./SupportPageMobile";
import SupportPageWeb       from "./SupportPageWeb";
import connect              from 'react-redux/lib/connect/connect';
import { hiddenLayout }     from '../../actions/ActionLayout';

@connect(state => {
    return {
        hiddenLayout: state.ReduceActionLayout.hiddenLayout
    }
}, dispatch => {
    return {
        hiddenLayout: () => {
            dispatch(hiddenLayout())
        }
    }
})
class SupportPage extends Component {

    componentWillMount() {
        this.props.hiddenLayout()
    }

    render() {
        return (
            <CheckDivide
                mobile={<SupportPageMobile/>}
                web={<SupportPageWeb/>}
            />
        )
    }
}

export default SupportPage;
