export default class PreviewService {

    constructor(axios) {
        this.axios = axios;
    }

    preview(params) {
        return this.axios.get(`/preview`, {
            params : {
                ...params
            }
        }).then(res => res.data);
    }
}
