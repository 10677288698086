import {
    SAVE_DRAFT_RECORDING,
    LOAD_DRAFT_RECORDING,
    DISCARD_DRAFT_RECORDING, LOAD_DRAFT_EDIT_RECORDING, SAVE_DRAFT_EDIT_RECORDING, DISCARD_DRAFT_EDIT_RECORDING
} from '../actions/Draft';

import { draftService }                          from '../services';
import { ClearLogicQuestionWithDraftEditRecord } from '../services/FitlerData';
import { NOTIFICATION }                          from '../actions/Notification';

export const draftMiddleware = (store) => next => action => {
    switch (action.type) {
        case LOAD_DRAFT_EDIT_RECORDING:
            return draftService.getDraftEditRecording('surveyRecord', action.id).then(draftEditRecord => {
                next({
                    ...action,
                    dataDraftEditRecording: draftEditRecord ? ClearLogicQuestionWithDraftEditRecord(draftEditRecord) : null
                })
            });

        case LOAD_DRAFT_RECORDING:
            return draftService.getDraftCreatorSurveyRecord('stateRecord', action.surveyId, action.customerId)
                .then(recordingDraft => {
                    next({
                        ...action,
                        recordingDraft: recordingDraft
                    })
                })
                .catch(error => console.log(error));

        case SAVE_DRAFT_EDIT_RECORDING:
            return draftService.saveDraft('surveyRecord', action.dataEditRecoding).then(res => {
                next({
                    ...action
                });
            }).catch(e => {
                store.dispatch({
                    type            : NOTIFICATION,
                    typeNotification: 'danger',
                    message         : 'createRecord.SaveDraftFail'
                })
            });

        case SAVE_DRAFT_RECORDING:
            return draftService.saveDraft('stateRecord', action.dataDraft).then(result => {
                next({
                    ...action,
                    idDraftRecording: result.data.id
                });
            }).catch(e => {
                store.dispatch({
                    type            : NOTIFICATION,
                    typeNotification: 'danger',
                    message         : 'createRecord.SaveDraftFail'
                })
            });

        case DISCARD_DRAFT_EDIT_RECORDING:
            return draftService.discardDraftEditRecording('surveyRecord', action.surveyId).then(res => {
                next({
                    ...action,
                    dataDraftEditRecording: null
                })

            });

        case DISCARD_DRAFT_RECORDING:
            return draftService.discardDraftRecord('stateRecord', action.surveyId, action.customerId).then(res => {
                next({
                    ...action,
                    recordingDraft: []
                })
            });

        default:
            return next(action)
    }
};
