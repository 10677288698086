import '../record.css'
import React, {Component}        from 'react';
import {KirokuInputArea}         from '../../../ui';
import {withNamespaces}          from 'react-i18next';
import PropTypes                 from 'prop-types';
import {checkUseHeadingQuestion} from "../../Surveys/SurveyEditor/SurveyService";
import KirokuButtonLink          from '../../../ui/KirokuButtonLink';

@withNamespaces()
class InputText extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        }
    }

    componentDidMount() {
        const { question, value } = this.props;
        this.props.onAnswer({
            answer: value,
            validated: (question.unrequited || value) ? value : null
        });
    }

    handleChange(event) {
        if (!event) {
            event = { target: {value: ''} }
        }
        this.setState({
            value: event.target.value
        });

        this.props.onAnswer({
            answer   : event.target.value,
            validated: event.target.value ? event.target.value : null
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.value !== nextProps.value || this.props.question.index !== nextProps.question.index) {
            this.setState({value: nextProps.value});
             nextProps.onAnswer({
                answer   : nextProps.value,
                validated: nextProps.value ? nextProps.value : null
            })
        }
    }

    render() {
        const {
            question, t,
            // index
        } = this.props;
        const contentQuestion = checkUseHeadingQuestion(question);
        return (
            <div id='component-question'>
                <div className='content-question'>
                    <div className='wraper-question'>
                        <div className="background-question">
                            <div className='number-question d-flex justify-content-between'>
                                <span className="question-number">
                                    {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                                </span>
                                <div/>
                                <div>
                                    <KirokuButtonLink
                                        className='padding-reset-button-link'
                                        onClick={() => {this.handleChange()}}>
                                        {t('createRecord.resetAnswer')}
                                    </KirokuButtonLink>
                                </div>
                            </div>
                        </div>
                        <div className='answer-question'>
                            <span className='content-name-question padding-question-content' >
                                {question.content}
                            </span>
                            <KirokuInputArea className='form-area'
                                             name={`${question.index}input`}
                                             onChange={this.handleChange.bind(this)}
                                             value={this.state.value || ''}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

InputText.propTypes = {
    value: PropTypes.string
};
export default InputText;
