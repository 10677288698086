import React, {Component}         from 'react';
import KirokuInput                from "../../../../ui/KirokuInput";
import {withNamespaces}           from "react-i18next";
import KirokuButton               from "../../../../ui/KirokuButton";
import {authService, userService} from "../../../../services";
import connect                    from "react-redux/es/connect/connect";
import {NOTIFICATION}             from "../../../../actions/Notification";
import CustomerTwoFactorWeb       from "../../../Customers/CustomerTwo-Factor/CustomerTwoFactorWeb";

@withNamespaces()
@connect(() => ({}), (dispatch) => {
    return {
        notification: (t) => {
            dispatch({
                type: NOTIFICATION,
                typeNotification: 'success',
                message: t('message.UpdateSuccess')
            })
        }
    }
})
class TabPassMobile extends Component {

    state = {
        errors: {
            oldPassword: false,
            newPassword: false,
            confirmPassword: false,
        },
        messageError: false,
        showOldPass: false,
        showNewPass: false,
        showConfirmPass: false,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    };

    handleSubmit() {
        if (Object.values(this.state.errors).every(item => !item)) {
            let data = {
                password: this.state.oldPassword,
                newPassword: this.state.newPassword,
                confirmPassword: this.state.confirmPassword

            };
            userService.changePassword(this.props.id, data).then(() => {
                this.props.notification(this.props.t);
                this.setState({
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: '',
                });
            }).catch(err => {
                if (err.response && err.response.status === 400) {
                    this.setState({
                        messageError: true,
                        newPassword: '',
                        confirmPassword: '',
                    })
                }
            })
        }

    }

    handleChangeInput(e) {
        this.setState({
            [e.target.name]: e.currentTarget.value,
            errors: {
                [e.target.name]: false
            },
            messageError: false
        })
    }

    checkValidate() {
        let {oldPassword, newPassword, confirmPassword} = this.state;
        this.setState({
            errors: {
                oldPassword: !(oldPassword.length > 5),
                newPassword: !(newPassword.length > 5),
                confirmPassword: confirmPassword !== newPassword,
            },

        }, () => {
            this.handleSubmit()
        });
    }

    isDisabled() {
        return !Object.values(this.state.errors).every(item => !item)
    }

    handleShowOldPass() {
        this.setState({
            showOldPass: !this.state.showOldPass
        })
    }

    handleShowNewPass() {
        this.setState({
            showNewPass: !this.state.showNewPass
        })
    }

    handleShowConfirmPass() {
        this.setState({
            showConfirmPass: !this.state.showConfirmPass
        })
    }

    goBack() {
        this.props.history.goBack()
    }

    render() {
        let {t, isTFA, id} = this.props;
        return (
            <div>
                <h5>{t('common.ChangePassword')}</h5>
                <div>
                    <label className='mt-1'>{t('common.OldPassword')}:</label>
                    <span className='personal-info'>
                        <div style={{position: 'relative'}}>
                            <KirokuInput
                                value={this.state.oldPassword || ''}
                                type={!this.state.showOldPass ? 'password' : 'text'}
                                name='oldPassword'
                                onChange={this.handleChangeInput.bind(this)}/>
                            <span className='change-status-password-user'>
                                <i onClick={this.handleShowOldPass.bind(this)}
                                   className={this.state.showOldPass ? 'fas fa-eye-slash text-secondary' : 'fas fa-eye text-secondary'}/>
                            </span>
                        </div>
                    </span>
                    <p hidden={!this.state.errors.oldPassword}
                       style={{
                           color: 'red',
                           fontSize: '13px'
                       }}>
                        {t('errors.userDetail.mustNotNull')}
                    </p>
                    <p hidden={!this.state.messageError}
                       style={{
                           color: 'red',
                           fontSize: '13px'
                       }}>
                        {t('errors.userDetail.currentPassword')}
                    </p>
                    <label className='mt-1'>{t('common.NewPassword')}:</label>
                    <span className='personal-info'>
                        <div style={{position: 'relative'}}>
                            <KirokuInput
                                value={this.state.newPassword || ''}
                                type={!this.state.showNewPass ? 'password' : 'text'}
                                name='newPassword'
                                onChange={this.handleChangeInput.bind(this)}/>
                            <span className='change-status-password-user'>
                                <i onClick={this.handleShowNewPass.bind(this)}
                                   className={this.state.showNewPass ? 'fas fa-eye-slash text-secondary' : 'fas fa-eye text-secondary'}/>
                            </span>
                        </div>
                    </span>
                    <p hidden={!this.state.errors.newPassword}
                       style={{
                           color: 'red',
                           fontSize: '13px'
                       }}>
                        {t('errors.userDetail.mustNotNull')}
                    </p>
                    <label className='mt-1'>{t('common.ConfirmNewPassword')}:</label>
                    <span className='personal-info'>
                        <div style={{position: 'relative'}}>
                            <KirokuInput
                                value={this.state.confirmPassword || ''}
                                name='confirmPassword'
                                type={!this.state.showConfirmPass ? 'password' : 'text'}
                                onChange={this.handleChangeInput.bind(this)}/>
                            <span className='change-status-password-user'>
                                <i onClick={this.handleShowConfirmPass.bind(this)}
                                   className={this.state.showConfirmPass ? 'fas fa-eye-slash text-secondary' : 'fas fa-eye text-secondary'}/>
                            </span>
                        </div>
                    </span>
                    <p hidden={!this.state.errors.confirmPassword}
                       style={{
                           color: 'red',
                           fontSize: '13px'
                       }}>
                        {t('errors.userDetail.notMatch')}
                    </p>
                    <div className='pt-3 pb-3'>
                        <div className='fix-center'>
                            <div className='d-flex' style={{width: 200}}>
                                <KirokuButton onClick={this.goBack.bind(this)} color='white'>{t('common.Cancel')}</KirokuButton>
                                <div className='pl-1 pr-1'/>
                                <KirokuButton disabled={this.isDisabled()} onClick={this.checkValidate.bind(this)}
                                              color='primary'>{t('common.Update')}</KirokuButton>
                            </div>

                        </div>
                    </div>
                </div>
                {authService.isAdmin() ? (
                    <CustomerTwoFactorWeb history={this.props.history} isTFA={isTFA} id={id}/>
                ): null}
            </div>
        )
    }
}

export default TabPassMobile;
