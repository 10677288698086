import React, { Component }   from 'react';
import { ViewQuestionedBeta } from './QuestionsLibrary/ViewQuestionedBeta';

export default class KirokuQuestionsCards extends Component {
    render() {
        const { questions } = this.props;
        return (
            <div>
                <ViewQuestionedBeta questions={questions} style={ { width: '100%' } }/>
            </div>
        );
    }
}
