import React, {Component} from 'react';
import {withNamespaces} from "react-i18next";
import connect from "react-redux/es/connect/connect";
import {hiddenLayout} from "../actions/ActionLayout";
import './style.css'

@withNamespaces()
@connect(state => {
    return {
        hiddenLayout: state.ReduceActionLayout.hiddenLayout
    }
}, dispatch => {
    return {
        hiddenLayout: () => {
            dispatch(hiddenLayout())
        }
    }
})
class NotFoundPage extends Component {

    goBack() {
        this.props.history.push(`/`);
    }

    componentWillMount() {
        this.props.hiddenLayout()
    }

    render() {
        const {t} = this.props;
        return (
            <div className='page-error default-background-color'>
                <div className="wrapper-page-error">
                    <main>
                        <div>
                            <span className={'text-404'}>404</span>
                            <h2 className='text-page-not-found'>{t('common.pageNotFound')}</h2>
                            <a href="/" className="btn btn-kiroku btn-kiroku-primary" style={{width: 150}}>
                                {t('common.BackHome')}
                            </a>
                        </div>
                    </main>
                </div>
            </div>
        )
    }
}

export default NotFoundPage;
