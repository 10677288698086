export const SAVE_DRAFT_EDIT_SURVEY = 'SAVE_DRAFT_EDIT_SURVEY';
export const LOADING_DRAFT_SURVEY = 'LOADING_DRAFT_SURVEY';
export const LOADING_SURVEY_FROM_DRAFT = 'LOADING_SURVEY_FROM_DRAFT';
export const DISCARD_DRAFT_SURVEY = 'DISCARD_DRAFT_SURVEY';
export const RESET_DRAFT = 'RESET_DRAFT';

export function saveDraft(draft) {
    return {
        type: SAVE_DRAFT_EDIT_SURVEY,
        draft,
    }
}

export function loadDraft(typeDraft, id) {
    return {
        type: LOADING_DRAFT_SURVEY,
        typeDraft,
        id
    }
}

export function loadSurveyFromDraft() {
    return {
        type : LOADING_SURVEY_FROM_DRAFT
    }
}

export function resetDraft() {
    return {
        type : RESET_DRAFT,
    }
}

export function discard(typeDraft, id) {
    return {
        type : DISCARD_DRAFT_SURVEY,
        typeDraft,
        id
    }
}