import {Date, InputText, Media, Message, MultiChoice, OnlyDate, Single, Time, YesNo, Number} from "./index";

export const factoryQuestion = (question, setDefaultAnswer = false) => {

    const map = {
        InputText,
        YesNo,
        Message,
        MultiChoice,
        Date,
        Single,
        Media,
        OnlyDate,
        Time,
        Number
    };

    const QuestionClass = map[question.type];

    if (!QuestionClass) {
        throw new Error(`Question type [${question.type}] does not supported`);
    }

    let questionClass = new QuestionClass(question);
    if(setDefaultAnswer) {
        questionClass.setDefaultAnswer();
        questionClass.deleteDefaultAnswer();
    }
    return questionClass;
};
