import { Component }      from "react";
import React              from "react";
import { withNamespaces } from "react-i18next";
// import {authService}      from "../../../../services";
import {KirokuLabel} from "../../../../ui";

@withNamespaces()
class TabProfile extends Component {
    render() {
        const {user}             = this.props;
        let {t}                  = this.props;
        let qualification        = user.qualification.map(item => {
            return t(`common.dropdown.${item}`)
        });
        let currentQualification = qualification.join(', ');
        return (
            <div>
                <h5>{t('CustomerEditor.PersonalInformation')}</h5>
                <div>
                    <div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('common.Name')}:</span>
                            <br/>
                            <span className='personal-info'>{user.name}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('common.userName')}:</span>
                            <br/>
                            <span className='personal-info'>{user.username}</span>
                        </div>
                        {/*<div className='mb-10' hidden={!authService.isAdmin()}>*/}
                        {/*    <span className='customer-info f-bold'>{t('common.StaffNumber')}:</span>*/}
                        {/*    <br/>*/}
                        {/*    <span className='personal-info'>{user.staff_number}</span>*/}
                        {/*</div>*/}
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('common.Title')}:</span>
                            <br/>
                            <span className='personal-info'>
                                 {user.title}
                            </span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('common.Role')}:</span>
                            <br/>
                            <span className='personal-info'>
                                {t(`common.${user.role}`)}
                            </span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('common.AssignToTeam')}:</span>
                            <br/>
                            <span className='personal-info'>{user.currentTeam}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('common.WorkingForm')}:</span>
                            <br/>
                            <span
                                className='personal-info'>{user['working_form'] ? t(`common.${user['working_form']}`) : ''}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info f-bold'>{t('common.Qualification')}:</span>
                            <br/>
                            <span className='personal-info'>{currentQualification}</span>
                        </div>
                    </div>
                    <div className='col-md-8'/>
                </div>
                <hr/>
                <h5>{t('common.Security')}</h5>
                <div className="row">
                    <div className="col-md-4">
                        <span className='customer-info f-bold'>{t('common.Two-factorAuthentication')}:</span>
                    </div>
                    <div className="col-md-4 pt-1">
                        {!!user["2fa"]? (
                            <KirokuLabel color="primary">
                                {t('common.enabled')}
                            </KirokuLabel>
                        ) : (
                            <KirokuLabel color="danger">
                                {t('common.disabled')}
                            </KirokuLabel>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default TabProfile;
