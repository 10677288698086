
const initialState = {
    lockInfo: {},
    isDisplayAskReload: false,
    isCalling: true,
    alterLocking: false,
};

const KirokuLockInfo = (state = initialState, action) => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    switch (action.type) {
        case 'ALTER_LOCKING':
            const stateAfterAlter = {
                ...state,
                alterLocking: !!action.alterLocking
            }

            if(stateAfterAlter.isDisplayAskReload && stateAfterAlter.alterLocking) {
                return {
                    ...stateAfterAlter,
                    isDisplayAskReload: false
                }
            }

            return stateAfterAlter;
        case 'MODAL_ASK_RELOAD':

            const stateAfterAskReload = {
                ...state,
                isDisplayAskReload: !!action.isOpen
            }

            if(stateAfterAskReload.isDisplayAskReload && stateAfterAskReload.alterLocking) {
                return {
                    ...stateAfterAskReload,
                    alterLocking: false
                }
            }

            return stateAfterAskReload;
        case 'LOCK_INFORMATION_FIRST':
            return {
                ...state,
                lockInfo: action.lockInfo,
                alterLocking: profile.id !== action.lockInfo.user_id
            };
        case 'LOCK_INFORMATION':
            if(action.lockInfo.user_id !== state.lockInfo.user_id) {
                return {
                    ...state,
                    lockInfo: action.lockInfo,
                };
            }
            return state;
        case 'SET_CALLING_LOCK_STATUS':
            return {
                ...state,
                isCalling: action.isCalling
            }
        default:
            return state
    }
};

export default KirokuLockInfo;
