import {Component} from "react";
import React from "react";

export default class KirokuInput extends Component {

    render() {
        return (
            <input {...this.props} className={`form-control-kiroku ${this.props.error} ${this.props.className}`}/>
        )
    }
}
