import {Component}          from "react";
import React                from "react";
import {KirokuDropdownItem} from "../../ui";

export default class KirokuDropdown extends Component {
    state = {
        selected: null
    };

    handleSelected(event) {
        if(event.kirokuSelected.value > this.props.question.index) {
            this.props.onSelect(event);
            this.setState({
                popupVisible: false,
                selected: event.kirokuSelected
            });
        }
    }

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.state = {
            popupVisible: false
        };
    }

    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
    }

    handleOutsideClick(e) {
        if (!this.node) {
            return;
        }
        this.handleClick();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.default !== this.props.default) {
            this.setState({
                selected : nextProps.default
            })
        }
        if(this.props.nextQuestion !== nextProps.nextQuestion) {
            let nextQuestion = nextProps.nextQuestion;
            let items = nextProps.items;
            let selected = items.find(item => item.value === nextQuestion);
            this.setState({
                selected: selected
            })
        }
    }

    componentWillMount() {
        let nextQuestion = this.props.nextQuestion;
        let items = this.props.items;
        let selected = items.find(item => item.value === nextQuestion);
        this.setState({
            selected: selected
        })
    }

    render() {
        const selectedLabel = this.state.selected ? this.state.selected.label : this.props.title;
        return (
            <div  ref={node => {this.node = node}}>
                <div className={`dropdown ${this.props.className ? this.props.className : ''}`}>
                    <button style={this.props.style}
                            className={`btn btn-kiroku ${this.props.className ? this.props.className : ''}`}
                            onClick={this.handleClick.bind(this)}>
                        <span className={'text-kiroku left'} style={{ textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '100%'}}>{selectedLabel}</span>
                        <i className={`${!this.state.show ? 'fa fa-chevron-down icon-kiroku' : 'fa fa-chevron-up icon-kiroku'}`}/>
                    </button>
                    {this.state.popupVisible && (
                        <div
                            className={`dropdown-kiroku dropdown-menu show ${this.props.location ? this.props.location : ''}`}
                            style={{position: 'absolute', willChange: 'top, left', left: 'auto', top: 'auto'}}>
                            <section className='scroll-data-dropdown-search'>
                                {this.props.items.map((item, index) => {
                                    const Item = item.component;
                                    if(item.value <= this.props.question.index) {
                                        return <KirokuDropdownItem
                                            style={{backgroundColor: '#e8ecf1', cursor: 'not-allowed'}}
                                            key={index} onClick={(event) => {
                                            event.kirokuSelected = item;
                                            this.handleClick(event);
                                            this.handleSelected(event)
                                        }}>
                                            {Item}
                                        </KirokuDropdownItem>
                                    }
                                    return (
                                        <KirokuDropdownItem
                                            key={index} onClick={(event) => {
                                            event.kirokuSelected = item;
                                            this.handleClick(event);
                                            this.handleSelected(event)
                                        }}>
                                            {Item}
                                        </KirokuDropdownItem>
                                    )
                                })}
                            </section>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

