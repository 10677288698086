import React, {Component}      from 'react';
import KirokuCard              from "../../../../ui/KirokuCard";
import {Link}                  from "react-router-dom";
import KirokuCardList          from "../../../../ui/KirokuCardList";
import {withNamespaces}        from "react-i18next";
import connect                 from "react-redux/es/connect/connect";
import {NOTIFICATION}          from "../../../../actions/Notification";
import TabResult               from "../TabResult";
import {filePathService}       from "../../../../services";

@withNamespaces()
@connect(() => {
    return {
        //profile: state.KirokuAIUser.profile
    }
}, (dispatch) => {
    return {
        notification: (t) => {
            dispatch({
                type: NOTIFICATION,
                typeNotification: 'success',
                message: t('message.UpdateSuccess')
            })
        }
    }
})

class ListTeam extends Component {
    state = {
        user: {},
        role: '',
        showModalStaff: false,
        showModalDelete: false,
    };

    componentWillMount() {
        this.setState({
            teams: this.props.teams,
            user: this.props.user
        })
    }


    newRoleTeam(newTeam) {
        this.setState({
            teams: newTeam
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.teams.length) {
            this.setState({
                teams: nextProps.teams
            })
        }
    }

    defaultTeam() {
        let defaultTeam = this.state.teams.map(team => ({...team, isChecked: false}));
        this.setState({
            teams: defaultTeam
        })
    }

    render() {
        let {t}           = this.props;
        let {teams} = this.state;
        return (
            <div>
                <KirokuCardList headerLeft={<span>{t('staff.Name')}</span>}
                                headerRight={<span/>}>
                    {teams.map(team =>
                        <KirokuCard
                            key={team.id}
                            action={<span/>}
                        >
                            <Link
                                to={`/teams/team-detail/${team.id}`}>{team.name}</Link>
                        </KirokuCard>
                    )}
                </KirokuCardList>
            </div>
        );
    }
}

class TabManageTeamMobile extends Component {
    currentTeam() {
        this.props.currentTeam()
    }

    render() {
        let {teams} = this.props;
        return (
            <div hidden={this.props.show}>
                {teams.length ?
                    <ListTeam currentTeam={this.currentTeam.bind(this)} history={this.props.history}
                              avatar={this.props.avatar} user={this.props.user}
                              teams={teams}/> :
                    <div className='bg-white card-customer-editor'>
                        <TabResult messageFirst={'team1'} messageSecond={'team2'}>
                            <img className='pt-2 pb-2'
                                 src={`${filePathService.resolveFilePath('team-icon@2x.png')}`}
                                 alt="no team"/>
                        </TabResult>
                    </div>
                }
            </div>
        )
    }
}

export default TabManageTeamMobile;