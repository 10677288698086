import React, {Component}                              from 'react';
import CreatorRecordWeb                                from './Web';
import connect                                         from 'react-redux/es/connect/connect';
import {loadDraftRecording, restartDataDraftRecording} from '../../../actions/Draft';
import {loadSurveyTemplateOfCustomer}                  from '../../../actions/Survey';
import {KirokuPageLoader}                              from '../../../ui';
import CreateRecordMobile                              from './Mobile';
import RenderComponentDevice                           from '../../../ui/RenderComponentDevice';
import {clearTakeSurvey}                               from '../../../actions/TakeSurvey';

@connect(state => {
    return {
        surveyTemplateOfCustomer: state.KirokuAISurvey.surveyTemplateOfCustomer
    }
}, dispatch => {
    return {
        loadDraft: (surveyId, customerId) => {
            dispatch(loadDraftRecording(surveyId, customerId))
        },

        loadSurveyTemplateOfCustomer: (surveyId, customerId) => {
            dispatch(loadSurveyTemplateOfCustomer(surveyId, customerId))
        },

        clearSurveyTemplateOfCustomer: () => {
            dispatch(clearTakeSurvey())
        },
        restartDataDraftRecording: () => {
            dispatch(restartDataDraftRecording())
        }

    }
})
class CreatorRecord extends Component {

    componentWillMount() {
        const surveyId   = this.props.location.state.surveyId;
        const customerId = this.props.location.state.customerId;
        // this.props.loadDraft(surveyId, customerId);
        this.props.loadSurveyTemplateOfCustomer(surveyId, customerId);
    }


    componentWillUnmount() {
        this.props.clearSurveyTemplateOfCustomer();
        // this.props.restartDataDraftRecording();
    }

    render() {
        const {surveyTemplateOfCustomer} = this.props;
        return surveyTemplateOfCustomer ?
            <div>
                <RenderComponentDevice
                    mobile={<CreateRecordMobile
                        history={this.props.history}
                        survey={surveyTemplateOfCustomer.survey}
                        customer={surveyTemplateOfCustomer.customer}
                    />}
                    web={<CreatorRecordWeb
                        history={this.props.history}
                        survey={surveyTemplateOfCustomer.survey}
                        customer={surveyTemplateOfCustomer.customer}
                        surveyRecord={{...surveyTemplateOfCustomer.survey}}
                    />}
                />
            </div> : <KirokuPageLoader/>;
    }
}

export default CreatorRecord;

