import React, { Component } from "react";

export default class KirokuCardTitleWeb extends Component {
    render() {
        return (
            <div className={'card-title card-title-web bg-' + this.props.color} {...this.props}>
                {this.props.children}
            </div>
        )
    }
}