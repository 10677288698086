import {SEARCH_RECORD} from '../actions/AdminExport';
import {
    exportService,
    recordService
}                      from '../services';
import momentTZ        from "moment-timezone";
import {NOTIFICATION}  from "../actions/Notification";

export const AdminExport = (store) => next => action => {
    switch (action.type) {
        case SEARCH_RECORD:
            store.dispatch({
                type: 'LOADING'
            });
            return setTimeout(() => {
                return recordService.searchRecordByCondition(action.condition).then(data => {
                    next({
                        ...action,
                        arrayRecord: data.map(record => {
                            return {
                                ...record,
                                isChecked: false
                            }
                        })
                    });
                });

            }, 1000);

        case 'EXPORT_ALL' :
            return exportService.exportAll(action.condition, action.tags ? action.tags : []).then(result => {
                window.open(result.url)
            });
        case 'NEW_EXPORT_ALL' :
            return exportService.newExportAll(action.condition).then(result => {
                window.open(result.url)
            });
        case 'EXPORT_CSV_ALL':
            return exportService.exportCSVAll(action.condition).then(result => {
                window.open(result.url)
            }).catch((err) => {
                if(err.response) {
                    store.dispatch({
                        type: NOTIFICATION,
                        typeNotification: 'danger',
                        message: `Export.${err.response.data.message}`
                    })
                }
            });
        case 'NEW_EXPORT':
            return exportService.loadRecordsNewExport(action.tags ? action.tags : [], action.params).then(result => {
                next({
                    type: 'NEW_EXPORT',
                    recordNewExport: result.records,
                    totalPage: result.totalPage || 1,
                    currentPage: result.currentPage || 1,
                    totalRecords : result.totalRecords || 1
                })
            }).catch(error => console.log(error));
        case "PRINT_REPORT": {
            const token     = localStorage.getItem('token');
            let recordIds = action.records.map(record => record.id);
            let lang      = localStorage.getItem('i18nextLng') || 'ja';
            let timezone  = momentTZ.tz.guess();
            let dateRange = action.dateRange;
            return window.open(`${process.env.REACT_APP_DOMAIN_DEV}/printer/ids/${action.params}?startDate=${dateRange.startDate ? dateRange.startDate : ''}&endDate=${dateRange.endDate ? dateRange.endDate : ''}&page=1&recordIds=${recordIds}&lang=${lang}&timezone=${timezone}&token=${token}`, "MsgWindow", '_blank');
        }
        case 'SELECT_RECORD_NEW_EXPORT':
            return exportService.selectedRecord(action.records, action.params, action.tags ? action.tags : [])
                .then(result => {
                    window.open(result.url)
                })
                .catch(error => console.log(error));
        case 'EXPORT_CSV_IDS':
            return exportService.selectedRecordCsv(action.records, action.params, action.tags ? action.tags : []).then(result => {
                window.open(result.url)
            }).catch((err) => {
                if(err.response) {
                    store.dispatch({
                        type: NOTIFICATION,
                        typeNotification: 'danger',
                        message: `Export.${err.response.data.message}`
                    })
                }
            });
        case 'EXPORT_DOCX': {
            return exportService.exportDocx(action.params, action.records, action.dateRange).then(result => {
                window.open(result.url)
            });
            // return window.open(`${process.env.REACT_APP_DOMAIN_DEV}/report/docx/ids/${action.params}?startDate=${dateRange.startDate ? dateRange.startDate : ''}&endDate=${dateRange.endDate ? dateRange.endDate : ''}&page=1&recordIds=${recordIds}&lang=${lang}&timezone=${timezone}&token=${token}`, "MsgWindow", '_blank');
        }
        default :
            next({
                ...action
            })

    }
};
