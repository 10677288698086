import {SEARCH_REPORTS} from '../actions/Reports';
import {reportsService} from '../services'
import createBrowserHistory from 'history/createBrowserHistory'
import {NOTIFICATION} from "../actions/Notification";

export const history = createBrowserHistory();

export const reportsMiddleware = (store) => next => action => {
    switch (action.type) {
        case SEARCH_REPORTS:
            let templates = ["TemplateRecord", "TemplateQuestion", "TemplateOnly", "CustomerRecord", "CustomerQuestion", "CustomerOnly"];
            let getTemplates = templates.map(function (template) {
                return reportsService.searchReportsByCondition({...action.condition, template: template})
                    .then(res => {
                        next({
                            ...action,
                            ...res
                        })
                    })
            });
            return Promise.all(getTemplates)
                .catch((err) => {
                    if(err.response) {
                        store.dispatch({
                            type: NOTIFICATION,
                            typeNotification: 'danger',
                            message: `Export.${err.response.data.message}`
                        })
                    }
                });
        default:
            next(action)

    }
};
