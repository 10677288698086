import React, {Component} from 'react';
import KirokuInputDate    from "../../../ui/KirokuInputDate";

import KirokuMultiSelect   from "../../../ui/KirokuMultiSelect";
import {connect}           from "react-redux";
import KirokuDropdownClose from "../../../ui/KirokuDropdownClose";
import moment              from "moment";
import {withNamespaces}    from 'react-i18next'

@connect((state) => {
    return {
        staffs: state.KirokuAIUser.staffs,
        teams: state.KirokuAITeam.teams
    }
}, () => ({}))

@withNamespaces()
class CustomerInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer: this.props.customer,
            teams: this.props.teams,
            messageCertificate: false,
            messageContract: false,
            messageCertificateBigger: false,
            messageContractBigger: false,
            errors: {},
        }
    }

    state = {
        options: [],
        selectedOption: [],
        defaultOption: [],
        currentStaffs: [],
        currentCertificate: [],
        teams: [],
        teamId: false
    };

    classifications = [
        {
            id: 1,
            name: this.props.t('CustomerEditor.NotApplicable'),
            value: 0
        },
        {
            id: 2,
            name: '1',
            value: 1
        },
        {
            id: 3,
            name: '2',
            value: 2
        },
        {
            id: 4,
            name: '3',
            value: 3
        },
        {
            id: 5,
            name: '4',
            value: 4
        },
        {
            id: 6,
            name: '5',
            value: 5
        },
        {
            id: 7,
            name: '6',
            value: 6
        },
    ];

    KindOfCertificateOptions = [
        {
            key: this.props.t('CustomerEditor.certificate.intellectual'),
            label: this.props.t('CustomerEditor.intellectual'),
            value: this.props.t('CustomerEditor.intellectual'),
        },
        {
            key: this.props.t('CustomerEditor.certificate.physical'),
            value: this.props.t('CustomerEditor.physical'),
            label: this.props.t('CustomerEditor.physical'),
        },
        {
            key: this.props.t('CustomerEditor.certificate.mental'),
            label: this.props.t('CustomerEditor.mental'),
            value: this.props.t('CustomerEditor.mental'),
        },
        {
            key: this.props.t('CustomerEditor.certificate.elderly'),
            label: this.props.t('CustomerEditor.elderly'),
            value: this.props.t('CustomerEditor.elderly'),
        },
        {
            key: this.props.t('CustomerEditor.certificate.other'),
            label: this.props.t('CustomerEditor.other'),
            value: this.props.t('CustomerEditor.other'),
        },

    ];

    componentWillMount() {
        let certificate = this.props.customer.certificate.map(trans => {
            return {
                key: this.props.t(`CustomerEditor.certificate.${trans.key}`),
                label: this.props.t(`CustomerEditor.${trans.label}`),
                value: this.props.t(`CustomerEditor.${trans.value}`)
            }
        });
        this.setState({
            currentStaffs: this.props.customer.staffs,
            currentCertificate: certificate
        });
    }

    currentCertificate(certificate) {
        let newCustomer         = this.state.customer;
        newCustomer.certificate = certificate;
        this.setState({
            customer: newCustomer
        });
        this.props.onChangeCustomer(this.state.customer)
    }

    handleChange = (options) => {
        this.setState({currentStaffs: options ? options : this.state.defaultOption}, () => {
            this.currentStaff(this.state.currentStaffs)
        });
    };

    handleChangeCertificate = (options) => {
        this.setState({currentCertificate: options ? options : this.state.defaultOption}, () => {
            this.currentCertificate(this.state.currentCertificate)
        });
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.teams.length || nextProps.customer) {
            this.setState({
                customer: nextProps.customer,
                teams: nextProps.teams,
            })
        }
    }

    selectClassfication = (classfications) => {
        return classfications.map(classfication => ({
            value: classfication.value,
            component: (<div><span className={'text-muted'}>{classfication.name}</span></div>),
            label: classfication.name
        }))
    };

    certificate() {
        return this.props.customer.certificate.map(item => item).join(', ');
    }


    choiceClassfication(currentClassfication) {
        let newCustomer            = this.state.customer;
        newCustomer.classification = currentClassfication;
        this.setState({
            customers: currentClassfication
        });
        this.props.onChangeCustomer(this.state.customer)
    }

    validate(date, dateType, customer) {
        switch (dateType.name) {
            case 'certification_start_date':
                this.setState({
                    messageCertificate: !date ? customer.certification_end_date : !date
                });
                if (moment(date).unix() > customer.certification_end_date) {
                    this.setState({
                        messageCertificateBigger: true
                    });
                } else {
                    this.setState({
                        messageCertificateBigger: false
                    });
                }
                return moment(date).unix();
            case 'certification_end_date' :
                this.setState({
                    messageCertificate: customer.certification_start_date ? false : !!date
                });
                if (moment(date).unix() < customer.certification_start_date) {
                    this.setState({
                        messageCertificateBigger: true
                    })
                } else {
                    this.setState({
                        messageCertificateBigger: false
                    })
                }
                return moment(date).unix();
            case 'contract_start' :
                this.setState({
                    messageContract: !date ? customer.contract_end : !date
                });
                if (customer.contract_end && moment(date).startOf('day').unix() > customer.contract_end) {
                    this.setState({
                        messageContractBigger: true
                    })
                } else {
                    this.setState({
                        messageContractBigger: false
                    })
                }
                return moment(date).startOf('day').unix();
            case 'contract_end' :
                this.setState({
                    messageContract: customer.contract_start ? false : !!date
                });
                if (moment(date).endOf('day').unix() < customer.contract_start) {
                    this.setState({
                        messageContractBigger: true
                    })
                } else {
                    this.setState({
                        messageContractBigger: false
                    })
                }
                return moment(date).endOf('day').unix();
            default :
                return date;
        }
    }

    initStateValidation(typeDate) {
        switch (typeDate.name) {
            case 'certification_start_date':
                return this.setState({
                    messageCertificate: false,
                });
            case 'certification_end_date':
                return this.setState({
                    messageCertificateBigger: false,
                });
            case 'contract_start':
                return this.setState({
                    messageContract: false,
                });
            case 'contract_end':
                return this.setState({
                    messageContractBigger: false
                });
            default:
                return typeDate
        }
    }

    checkDate = (date, nameDate, customer) => {
        if (moment(date, 'YYYY/MM/DD', true).isValid()) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    [nameDate.name]: false
                },
            });
            return this.validate(date, nameDate, customer)
        } else {
            this.setState({
                errors: {
                    ...this.state.errors,
                    [nameDate.name]: date.length
                },
            });
            this.initStateValidation(nameDate);
            if (!date.length) {
                return this.validate(date, nameDate, customer)
            }
        }
    };

    selectTeam() {
        this.props.currentError({team_id: false})
    }

    handleDate = (date, nameDatePicker) => {
        let customer                     = this.state.customer;
        let newCustomer                  = this.state.customer;
        newCustomer[nameDatePicker.name] = this.checkDate(date, nameDatePicker, customer);
        this.setState({
            customer: newCustomer
        }, () => {
            this.props.onChangeCustomer(this.state.customer);
            this.props.currentError({
                ...this.state.errors,
                messageCertificate: this.state.messageCertificate,
                messageContract: this.state.messageContract,
                messageCertificateBigger: this.state.messageCertificateBigger,
                messageContractBigger: this.state.messageContractBigger
            });
        });

    };
    config     = {
        certification_start_date: {
            name: 'certification_start_date',
        },
        certification_end_date: {
            name: 'certification_end_date',
        },
        contract_start: {
            name: 'contract_start',
        },
        contract_end: {
            name: 'contract_end',
        }
    };

    render() {
        const {customer} = this.state;
        let {t}          = this.props;
        return (
            <div>
                <h5>{t('CustomerEditor.CustomerInformation')}</h5>
                <div>
                    {/*<label className='mt-1'>{t('CustomerEditor.TermCertificationStart')}</label>*/}
                    {/*<KirokuInputDate*/}
                    {/*    name='certification_start_date'*/}
                    {/*    onChange={(date) => this.handleDate(date, this.config.certification_start_date)}*/}
                    {/*    defaultValue={moment(customer.certification_start_date * 1000 || null)}*/}
                    {/*/>*/}
                    {/*<p hidden={!this.state.messageCertificate} style={{*/}
                    {/*    color: 'red',*/}
                    {/*    fontSize: 12*/}
                    {/*}}>{t('CustomerEditor.validate.ErrorStartDateCertification')}</p>*/}
                    {/*<p hidden={!this.state.errors.certification_start_date} style={{*/}
                    {/*    color: 'red',*/}
                    {/*    fontSize: 12*/}
                    {/*}}>{t('CustomerEditor.validate.ValidDate')}</p>*/}
                    <label className='mt-1'>{t('CustomerEditor.StartDateOfContact')}</label>
                    <KirokuInputDate
                        name='contract_start'
                        onChange={(date) => this.handleDate(date, this.config.contract_start)}
                        defaultValue={customer.contract_start ? moment.unix(customer.contract_start || null) : ''}
                    />
                    <p hidden={!this.state.messageContract} style={{
                        color: 'red',
                        fontSize: 12
                    }}>{t('CustomerEditor.validate.ErrorStartDateContact')}</p>
                    <p hidden={!this.state.errors.contract_start} style={{
                        color: 'red',
                        fontSize: 12
                    }}>{t('CustomerEditor.validate.ValidDate')}</p>
                    <label className="mt-1">{t('CustomerEditor.EndDateOfContact')}</label>
                    <KirokuInputDate
                        name='contract_end'
                        onChange={(date) => this.handleDate(date, this.config.contract_end)}
                        defaultValue={customer.contract_end ? moment.unix(customer.contract_end || null) : ''}
                    />
                    <p hidden={!this.state.messageContractBigger} style={{
                        color: 'red',
                        fontSize: 12
                    }}>{t('CustomerEditor.validate.ErrorStartDateCertification')}</p>
                    <p hidden={!this.state.errors.contract_end} style={{
                        color: 'red',
                        fontSize: 12
                    }}>{t('CustomerEditor.validate.ValidDate')}</p>
                    <label className='mt-1'>{t('CustomerEditor.SupportClassification')}</label>
                    <KirokuDropdownClose
                        title={(customer.classification === 0 ? t('CustomerEditor.NotApplicable') : customer.classification) || 0}
                        items={this.selectClassfication(this.classifications)}
                        onSelect={event => {
                            this.choiceClassfication(event.kirokuSelected.value)
                        }}
                    />
                    {/*<label className="mt-1">{t('CustomerEditor.TermCertificationEnd')}</label>*/}
                    {/*<KirokuInputDate*/}
                    {/*    name='certification_end_date'*/}
                    {/*    onChange={(date) => this.handleDate(date, this.config.certification_end_date)}*/}
                    {/*    placeholder={'09/10/1996'}*/}
                    {/*    defaultValue={moment(customer.certification_end_date * 1000 || null)}*/}
                    {/*/>*/}
                    {/*<p hidden={!this.state.messageCertificateBigger} style={{*/}
                    {/*    color: 'red',*/}
                    {/*    fontSize: 12*/}
                    {/*}}>{t('CustomerEditor.validate.ErrorStartDateContact')}</p>*/}
                    {/*<p hidden={!this.state.errors.certification_end_date} style={{*/}
                    {/*    color: 'red',*/}
                    {/*    fontSize: 12*/}
                    {/*}}>{t('CustomerEditor.validate.ValidDate')}</p>*/}
                    <label className="mt-1">{t('CustomerEditor.KindOfCertificate')}</label>
                    <KirokuMultiSelect
                        onChange={this.handleChangeCertificate.bind(this)}
                        value={this.state.currentCertificate}
                        options={this.KindOfCertificateOptions}

                    />
                </div>
            </div>
        )
    }
}

export default CustomerInformation;
