import {Component} from "react";
import React       from "react";

export default class KirokuInputIcon extends Component {

    render() {
        return (
            <div style={{position: 'relative'}}>
                <div className='action-input-icon text-secondary' style={{cursor: 'not-allowed'}}>
                    <i className={this.props.icon}/>
                </div>
                <input className="form-control-kiroku"
                       style={{height: "35px", paddingLeft: '2rem'}} {...this.props}/>
            </div>
        )
    }
}
