import React, {Component}       from 'react';
import {connect}                from "react-redux";
import {withNamespaces}         from 'react-i18next';
import {
    KirokuSectionDropdown,
    ModalSelectStaffOfTeam
} from "../../ui";
import _                        from 'lodash';
@connect((state) => {
    return {
        staffs: state.KirokuAIUser.staffs,
        users: state.KirokuAIUser.users,
        allTeam: state.KirokuAITeam.allTeam
    }
}, () => ({}))

@withNamespaces()
class TagCustomer extends Component {
    state = {
        options: [],
        selectedOption: [],
        defaultOption: [],
        currentStaffs: [],
        currentCertificate: [],
        teams: [],
        errors: {},
        teamId: false,
        defaultTeam: [],
        currentTeams: [],
        defaultSurvey: [],
        currentSurveys: [],
        currentTags: [],
        groupedOptions: [],
        currentTagsStaff: []
    };

    constructor(props) {
        super(props);
        this.state = {
            customer: this.props.customer,
            teams: this.props.teams
        }
    }

    componentWillMount() {
        // let currentTags = this.props.customer.staffs.concat(this.props.customer.teams);
        let currentTagsStaff = this.props.customer.staffs;
        let currentTagsTeam = this.props.customer.teams;

        this.setState({
            defaultTeam: [],
            // currentTags: currentTags,
            currentTagsStaff: currentTagsStaff,
            currentTagsTeam: currentTagsTeam,
            defaultSurvey: [],
            StaffOptions: [
                {
                    label: 'User',
                    options: this.props.users,
                }
            ],
            TeamOptions: [
                {
                    label: 'Teams',
                    options: this.props.teams,
                },
            ],
            showModalSelectStaffOfTeam: false
            // groupedOptions: [
            //     {
            //         label: 'Teams',
            //         options: this.props.teams,
            //     },
            //     {
            //         label: 'User',
            //         options: this.props.users,
            //     }
            // ]
        });
    }

    selectTeam() {
        this.props.currentError({team_id: false})
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.customer) {
            this.setState({
                customer: nextProps.customer,
            })
        }

    }

    // handleChangeTags = (options) => {
    //     this.setState({currentTags: options ? options : this.state.defaultOption}, () => {
    //         this.currentTag(this.state.currentTags)
    //     });
    // };

    handleChangeStaffTags = (options) => {
        this.setState({
            currentTagsStaff: options ? options : this.state.defaultOption,
        }, () => {
            this.currentTag(this.state.currentTagsStaff.concat(this.state.currentTagsTeam))
        });
    };

    handleChangeTeamTags = (options) => {
        this.setState({currentTagsTeam: options ? options : this.state.defaultOption}, () => {
            this.currentTag(this.state.currentTagsTeam.concat(this.state.currentTagsStaff))
        });
    };

    currentTag(tags) {
        let newCustomer  = this.state.customer;
        newCustomer.tags = tags;
        this.setState({
            customer: newCustomer,
        });
        this.props.onChangeCustomer(this.state.customer)
    }

    handleTagAll(type){
        const {TeamOptions,currentTagsTeam, currentTagsStaff, StaffOptions} = this.state;
        const {users, teams} = this.props;
        switch (type) {
            case 'Team':
                if (_.head(TeamOptions).options.length !== currentTagsTeam.length) {
                    this.handleChangeTeamTags(teams);
                }
                break;
            case 'Staff':
                if(_.head(StaffOptions).options.length !== currentTagsStaff.length) {
                    this.handleChangeStaffTags(users);
                }
                break;
            default:
                break;
        }
    }

    staffSelectedInModal(staffs) {
        let staffNew = staffs.filter((e) => e.isChecked);
        this.setState({
            currentTagsStaff: staffNew,
        });
        this.currentTag(staffNew.concat(this.state.currentTagsTeam))
    }

    handleActionModalSelectStaffOfTeam() {
        this.setState({
            showModalSelectStaffOfTeam: !this.state.showModalSelectStaffOfTeam
        })
    }

    render() {
        let {t, allTeam, users} = this.props;
        const {showModalSelectStaffOfTeam} = this.state;
        return (
            <div>
                <h5>{t('common.tag')}</h5>
                <div className='row'>
                    <br/>
                    <div className="col-md-12">
                        <div className="d-flex">
                            <label className="mt-1">{t('common.staff')}</label>
                            <div className="p-2"/>
                            <div className={'cursor-pointer mt-1'} style={{color: '#1891eb'}} onClick={this.handleTagAll.bind(this, 'Staff')}>
                                {t('Customer.TagAll')}
                            </div>
                            <div className="p-2"/>
                            <div className={'cursor-pointer mt-1'} style={{color: '#1891eb'}}
                                onClick={this.handleActionModalSelectStaffOfTeam.bind(this)}
                            >
                                {t('RecordList.SelectCustomerOfTeam')}
                            </div>
                            <ModalSelectStaffOfTeam
                                staffSelected={this.state.currentTagsStaff}
                                show={showModalSelectStaffOfTeam}
                                staffsSelectedInModal={this.staffSelectedInModal.bind(this)}
                                closeModal={this.handleActionModalSelectStaffOfTeam.bind(this)}
                                teams={allTeam}
                                staffs={users}
                            />
                        </div>
                        <KirokuSectionDropdown
                            onChange={this.handleChangeStaffTags.bind(this)}
                            value={this.state.currentTagsStaff || []}
                            options={this.state.StaffOptions}
                        />
                        <div className="d-flex">
                            <label className="mt-2">{t('common.team')}</label>
                            <div className="p-2"/>
                            <div className={'cursor-pointer mt-2'} style={{color: '#1891eb'}} onClick={this.handleTagAll.bind(this, 'Team')}>
                                {t('Customer.TagAll')}
                            </div>
                        </div>
                        <KirokuSectionDropdown
                            onChange={this.handleChangeTeamTags.bind(this)}
                            value={this.state.currentTagsTeam || []}
                            options={this.state.TeamOptions}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default TagCustomer;
