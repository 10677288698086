import React, { Component }                                     from 'react';
import moment                                                   from 'moment';
import PropTypes                                                from 'prop-types';
import { withNamespaces }                                       from 'react-i18next';
import Datetime                                                 from 'react-datetime';
import ButtonEditQuestion                                       from './ButtonEditQuestion';
import config                                                   from '../../../config';
import { KirokuCardBodyWeb, KirokuCardTitleWeb, KirokuCardWeb } from '../../../ui';
import connect                                                  from 'react-redux/es/connect/connect';
import { notificationEditQuestion }                             from '../../../actions/Survey';
import {checkUseHeadingQuestion}                                from "../../Surveys/SurveyEditor/SurveyService";
import KirokuButtonLink                                         from '../../../ui/KirokuButtonLink';

@withNamespaces()
@connect(state => {
    return {takeSurvey: state.TakeSurveyWeb}
}, dispatch => {
    return {
        editQuestion: (flagEdit, indexQuestion) => {
            dispatch(notificationEditQuestion(flagEdit, indexQuestion))
        }
    }
})
class DateTimeQuestion extends Component {

    state = {
        value    : null,
        disabled : this.props.disabled,
        flagEdit : false,
        validated: false
    };


    componentWillMount() {
        this.setState({
            value: this.props.value ? moment.unix(this.props.value) : null
        });
        this.props.onAnswer({
            answer   : this.props.value,
            validated: this.props.value ? this.props.value : null
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                value: nextProps.value ? moment.unix(nextProps.value) : null
            });

        }
        if (this.props.disabled !== nextProps.disabled || this.props.flagEdit !== nextProps.flagEdit) {
            this.setState({
                disabled: nextProps.disabled,
                flagEdit: nextProps.flagEdit
            })
        }
    }

    handleActionEditQuestion(state) {
        this.setState({
            disabled: state.disabled,
            flagEdit: state.flagEdit
        });
        this.props.editQuestion(state.flagEdit, this.props.question.index);

        if (!state.flagEdit) {
            this.props.onUpdate({
                answer   : moment(this.state.value).unix(),
                validated: this.state.validated
            }, this.props.question.index, this.props.index)
        }
    }


    handleOnChangeDateTime(momentTime) {
        if (!momentTime) {
            momentTime = null
        }
        this.setState({
            value    : momentTime,
            validated: momentTime ?  moment.unix(momentTime) : null,
        });
        this.props.onAnswer({
            answer   : moment(momentTime).unix(),
            validated: momentTime ? moment.unix(momentTime) : null
        })
    }

    render() {
        const {question, t, takeSurvey, editor} = this.props;
        const {disabled, value}                = this.state;
        const hiddenButtonEdit                 = takeSurvey.flagEditing ? takeSurvey.indexQuestion !== question.index : takeSurvey.flagEditing;
        const contentQuestion = checkUseHeadingQuestion(question);

        return (
            <div id='component-question'>
                <div id='web-q' className='pb-3'>
                    <KirokuCardWeb>
                        <KirokuCardTitleWeb color={'card-web'}>
                            <div className="d-flex justify-content-between">
                                <div className='number-question question-number text-overflow-elip'>
                                    {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                                </div>
                                <div/>
                                <div hidden={disabled}>
                                    <KirokuButtonLink
                                        className='padding-reset-button-link'
                                        onClick={() => {this.handleOnChangeDateTime()}}
                                    >
                                        {t('createRecord.resetAnswer')}
                                    </KirokuButtonLink>
                                </div>

                            </div>
                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb>
                            <div className={disabled ? 'wrap-overlay-action' : ''}>
                                <span className='content-name-question'>{question.content}</span>
                                <div className='answer-question' style={{maxWidth: 250}}>

                                    <Datetime
                                        inputProps={{disabled: disabled}}
                                        value={value}
                                        timeFormat={true}
                                        closeOnSelect={true}
                                        dateFormat={config.formatDate}
                                        onChange={this.handleOnChangeDateTime.bind(this)}
                                        locale={t('createRecord.Date')}
                                        monthYearLocale={localStorage.getItem('i18nextLng')}
                                    />
                                </div>

                            </div>
                            <div hidden={hiddenButtonEdit || editor}>
                                <ButtonEditQuestion {...this.state}
                                                    isUnrequited={question.unrequited}
                                                    clickEditQuestion={this.handleActionEditQuestion.bind(this)}
                                />
                            </div>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                </div>
            </div>
        );
    }
}

DateTimeQuestion.propTypes = {
    value: PropTypes.number
};
export default DateTimeQuestion;
