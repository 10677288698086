import {Component}                                                                        from "react";
import React                                                                              from "react";
import {withNamespaces}                                                                   from "react-i18next";
import KirokuInput
                                                                                          from "../../../../ui/KirokuInput";
import KirokuDropdownClose
                                                                                          from "../../../../ui/KirokuDropdownClose";
import KirokuMultiSelect
                                                                                          from "../../../../ui/KirokuMultiSelect";
import KirokuButton
                                                                                          from "../../../../ui/KirokuButton";
import {authService, lineService, teamService, userFactory, userService}                  from "../../../../services";
import connect
                                                                                          from "react-redux/es/connect/connect";
import {NOTIFICATION}        from "../../../../actions/Notification";
import config                from "../../../../config";
import jwt                   from "jsonwebtoken";
import {reloadAvatar}        from "../../../../actions/ActionLayout";
import {KirokuLabel}         from "../../../../ui";
import KirokuSectionDropdown from "../../../../ui/KirokuSectionDropdown";


@withNamespaces()
@connect((state) => {
    return {
        isAdmin: state.KirokuAIUser.profile
    }
}, (dispatch) => {
    return {
        notification: (t) => {
            dispatch({
                type: NOTIFICATION,
                typeNotification: 'success',
                message: t('message.UpdateSuccess')
            })
        },
        reloadAvatarMain: () => {
            dispatch(reloadAvatar())
        },
        trackingUpdateStaff : (staff) => {
            dispatch({
                type: "STAFF_UPDATED",
                staff
            })
        },
    }
})
class   TabProfileEditor extends Component {
    state = {
        currentQualification: [],
        currentTeam: [],
        optionTeam: [],
        errorProfile: {},
        errorStaffNumber: false,
        user: {
            staff_number: ''
        },
        groupCustomerOptions: [],
        groupTeamOptions: [],
        currentCustomerTags: [],
        currentTeamTags: [],
        defaultOption: [],
        isEdit: false,
        loading: false,
    };

    componentWillMount() {
        let qualification = this.props.user.qualification.map(trans => {
            return {
                key: this.props.t(`common.dropdown.default.${trans}`),
                label: this.props.t(`common.dropdown.${trans}`),
                value: this.props.t(`common.dropdown.${trans}`)
            }
        });
        let teams     = this.props.user.teams.map(team => {
            return {
                tagType: "team",
                tagContent: {
                    ...team,
                },
                key: team.id,
                label: team.name,
                value: `${team.name}${team.id}`,
                type: 'Teams'
            }
        });
        let customers = this.props.user.customers.map(customer => {
            return {
                tagType: "customer",
                tagContent: {
                    ...customer,
                },
                key: customer.id,
                label: customer.name,
                value: `${customer.name}${customer.id}`,
                type: 'Customer'
            }
        });

        let optionTeam = this.props.teams.map(team => {
            return {
                tagType: "team",
                tagContent: {
                    ...team,
                },
                key: team.id,
                label: team.name,
                value: `${team.name}${team.id}`,
                type: 'Teams'
            }
        });

        let optionCustomers = this.props.customers.map((customer) => {
            return {
                tagType: "customer",
                tagContent: {
                    ...customer,
                },
                key: customer.id,
                value: `${customer.name}${customer.id}`,
                label: customer.name,
                type: 'Customers'
            }
        });

        this.setState({
            currentQualification: qualification,
            currentCustomerTags: customers,
            currentTeamTags : teams,
            user: {
                ...this.state.user,
                ...this.props.user
            },
            groupCustomerOptions: [
                {
                    label: 'Customers',
                    options: optionCustomers,
                },
            ],
            groupTeamOptions: [
                {
                    label: 'Teams',
                    options: optionTeam,
                },

            ]
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            user: {
                ...this.state.user,
                ...nextProps.user,
            }
        });
    }

    handleChangeInput(e) {
        this.handleChangeProfile(e.target.value, e.target.name)
    }

    handleChangeProfile(value, name) {
        this.setState({
            user: {
                ...this.state.user,
                [name]: value,
            },
            errorProfile: {
                ...this.state.errorProfile,
                [name]: ! value.length

            }
        })
    }

    selectWorkingForm = (workingForm) => {
        this.handleChangeProfile(workingForm, 'working_form')
    };

    currentQualification(qualification) {
        let currentQualification = qualification.map(item => item.key);
        this.handleChangeProfile(currentQualification, 'qualification')
    }

    handleChangeTagTeam          = (options) => {
        this.setState({
            currentTeamTags: options ? options : this.state.defaultOption,
            isEdit : true
        }, () => {
            this.currentTag(this.state.currentTeamTags.concat(this.state.currentCustomerTags))
        });
    };
    handleChangeQualification = (options) => {
        this.setState({currentQualification: options ? options : this.state.defaultOption}, () => {
            this.currentQualification(this.state.currentQualification)
        });
    };

    goBack() {
        this.props.history.goBack()
    }

    handleSubmitUser() {
        let user = userFactory.makeUser(this.state.user, this.state.isEdit);
        this.setState({
            loading: true,
        })
        userService.edit(user.id, user).then((res) => {
            this.setState({
                loading: false,
            })
            this.props.trackingUpdateStaff(user);
            if (`${this.props.isAdmin.id}` === `${this.props.id}`) {
                let profile = jwt.verify(res.data.token, config.auth.authPrivateKey);
                authService.setToken(res.data.token, profile);
                this.props.reloadAvatarMain();
                this.props.notification(this.props.t);
                this.props.history.push(`/my-profile`)
            } else {
                this.props.reloadAvatarMain();
                this.props.notification(this.props.t);
                this.props.history.push(`/staffs/staff/${res.data.id}`);
            }
        });
    }

    isDisabled() {
        const {name} = this.state.errorProfile;
        return !Object.values({name}).every(item => !item) || this.state.loading
    }

    workingForm         = [
        {
            value: 'Full_time',
            component: (
                <div>{this.props.t('common.Full_time')}</div>
            ),
            label: this.props.t('common.Full_time')
        },
        {
            value: 'Part_time',
            component: (
                <div>{this.props.t('common.Part_time')}</div>
            ),
            label: this.props.t('common.Part_time')
        },
    ];
    KindOfQualification = [
        {
            key: this.props.t('common.dropdown.default.Social_worker'),
            label: this.props.t('common.dropdown.Social_worker'),
            value: this.props.t('common.dropdown.Social_worker'),
        },
        {
            key: this.props.t('common.dropdown.default.Psychiatric_Sosial_Worker'),
            value: this.props.t('common.dropdown.Psychiatric_Sosial_Worker'),
            label: this.props.t('common.dropdown.Psychiatric_Sosial_Worker'),
        },
        {
            key: this.props.t('common.dropdown.default.Care_Worker'),
            label: this.props.t('common.dropdown.Care_Worker'),
            value: this.props.t('common.dropdown.Care_Worker'),
        },
        {
            key: this.props.t('common.dropdown.default.Nurse'),
            label: this.props.t('common.dropdown.Nurse'),
            value: this.props.t('common.dropdown.Nurse'),
        },
        {
            key: this.props.t('common.dropdown.default.Childminder'),
            label: this.props.t('common.dropdown.Childminder'),
            value: this.props.t('common.dropdown.Childminder'),
        },
        {
            key: this.props.t('common.dropdown.default.Occupational_therapist'),
            label: this.props.t('common.dropdown.Occupational_therapist'),
            value: this.props.t('common.dropdown.Occupational_therapist'),
        },
        {
            key: this.props.t('common.dropdown.default.Psychotherapist'),
            label: this.props.t('common.dropdown.Psychotherapist'),
            value: this.props.t('common.dropdown.Psychotherapist'),
        },
        {
            key: this.props.t('common.dropdown.default.Doctor'),
            label: this.props.t('common.dropdown.Doctor'),
            value: this.props.t('common.dropdown.Doctor'),
        },

    ];
    selectRole          = [
        {
            value: 'staff',
            component: (<div>{this.props.t('common.Staff')}</div>),
            label: this.props.t('common.Staff')
        },
        {
            value: 'leader',
            component: (<div>{this.props.t('role.leader')}</div>),
            label: this.props.t('role.leader')
        },
    ];

    revokeLine = async () => {
        let result = await lineService.revokeLine();
        let user   = await userService.loadDetail(this.state.user.id);
        let teams  = await teamService.loadTeams();
        localStorage.setItem('token', result.data.token);
        let profile = jwt.verify(result.data.token, config.auth.authPrivateKey);
        localStorage.setItem('profile', JSON.stringify(profile));
        Promise.all([user, teams]).then(value => {
            this.setState({
                user: value[0],
                teams: value[1],
            }, this.props.revokeLine(user, teams));
        });
    };

    roleChoose(role) {
        this.handleChangeProfile(role, 'role');
    }

    handleChangeTags = (options) => {
        this.setState({
            currentCustomerTags: options ? options : this.state.defaultOption,
            isEdit: true,
        }, () => {
            this.currentTag(this.state.currentCustomerTags.concat(this.state.currentTeamTags))
        });
    };

    currentTag(tags) {
        this.handleChangeProfile(tags, 'tags');
    }

    handleChangeStaffNumber = (e) => {
        let regex  = e.target.value.replace(/[^0-9]/g, '');
        const user = this.state.user;
        if (regex.length <= 5) {
            user[e.target.name] = regex;
            this.setState({
                user: {
                    ...this.state.user,
                    staff_number: regex
                }
            });
        }
    };

    checkValidationStaffNumber = (e) => {
        const error = this.checkErrorStaffNumber(e.currentTarget.value);
        this.setState({
            errorStaffNumber: error
        });
    };

    checkErrorStaffNumber = (number) => {
        if(number.length < 5 && number.length > 0) {
            this.setState({errorStaffNumber: true});
            return 'ErrorStaffNumber';
        }
        return '';
    };

    render() {
        let profile       = JSON.parse(localStorage.getItem('profile'));
        const {
                  user,
            // errorStaffNumber,
                  currentCustomerTags,
                  groupCustomerOptions,
                  currentTeamTags,
                  groupTeamOptions,
                  currentQualification
              }      = this.state;
        let {t}           = this.props;
        return (
            <div>
                <div className='card'>
                    <div className='p-2'>
                        <h5>{t('CustomerEditor.PersonalInformation')}</h5>
                        <div>
                            <div className='pb-2'>
                                <label className='mt-1'>{t('common.Name')}&nbsp;*</label>
                                <KirokuInput
                                    name='name'
                                    onChange={this.handleChangeInput.bind(this)}
                                    value={user.name || ''}
                                />
                            </div>
                            <p hidden={!this.state.errorProfile.name}
                               style={{
                                   color: 'red',
                                   fontSize: '13px'
                               }}>
                                {t('errors.nameMustNotNull')}
                            </p>
                            {/*<div className='pb-2'>*/}
                            {/*    <span className='customer-info f-bold'>{t('common.StaffNumber')}:</span>*/}
                            {/*    <br/>*/}

                            {/*    <span className='personal-info'>*/}
                            {/*            <KirokuInput*/}
                            {/*                name='staff_number'*/}
                            {/*                disabled={!authService.isAdmin()}*/}
                            {/*                placeholder={t('common.StaffNumber')}*/}
                            {/*                onChange={this.handleChangeStaffNumber.bind(this)}*/}
                            {/*                onBlur={this.checkValidationStaffNumber.bind(this)}*/}
                            {/*                error={errorStaffNumber ? 'form-error' : ''}*/}
                            {/*                value={user.staff_number || ''}/>*/}
                            {/*                <p hidden={!errorStaffNumber} style={{*/}
                            {/*                    color: 'red',*/}
                            {/*                    fontSize: 12*/}
                            {/*                }}>*/}
                            {/*                     {t(`CustomerEditor.validate.${errorStaffNumber}`)}*/}
                            {/*                </p>*/}

                            {/*        </span>*/}
                            {/*</div>*/}
                            <div className="pb-2">
                                <label className='mt-1'>{t('staff.title')}</label>
                                <KirokuInput
                                    name='title'
                                    placeholder={t('staff.title')}
                                    onChange={this.handleChangeInput.bind(this)}
                                    value={user.title || ''}
                                />
                            </div>
                            <div className='pb-2'>
                                <label className='mt-1'>{t('common.WorkingForm')}</label>
                                <KirokuDropdownClose
                                    title={user['working_form'] ? t(`common.${user['working_form']}`) : t('common.WorkingForm')}
                                    items={this.workingForm}
                                    onSelect={event => {
                                        this.selectWorkingForm(event.kirokuSelected.value);
                                    }}
                                />
                            </div>
                            <div hidden={this.props.id.toString() === this.props.isAdmin.id.toString()}
                                 className='pb-2'>
                                <label className='mt-1'>{t('common.Role')}</label>
                                <KirokuDropdownClose
                                    title={user.role ? t(`common.${user.role}`) : t(`common.Role`)}
                                    items={this.selectRole}
                                    onSelect={event => {
                                        this.roleChoose(event.kirokuSelected.value);
                                    }}
                                />
                            </div>
                            <div className='pb-2'>
                                <label className='mt-1'>{t('common.Qualification')}</label>
                                <KirokuMultiSelect
                                    onChange={this.handleChangeQualification.bind(this)}
                                    value={currentQualification}
                                    options={this.KindOfQualification}

                                />
                            </div>
                            <div hidden={this.state.user.id === profile.id} className='pb-2'>
                                <label className='mt-1'>{t('common.Customer')}:</label>
                                <KirokuSectionDropdown
                                    onChange={this.handleChangeTags.bind(this)}
                                    value={currentCustomerTags}
                                    options={groupCustomerOptions}
                                />
                            </div>
                            <div hidden={this.state.user.id === profile.id} className='pb-2'>
                                <label className='mt-1'>{t('common.Team')}:</label>
                                <KirokuSectionDropdown
                                    onChange={this.handleChangeTagTeam.bind(this)}
                                    value={currentTeamTags}
                                    options={groupTeamOptions}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card mt-3'>
                    <div className='p-2'>
                        <h5>{t('common.Security')}</h5>
                        <div className="row">
                            <div className="col-md-4">
                                <div className='mb-10'>
                                    <span className='customer-info f-bold'>{t('common.Two-factorAuthentication')}:</span>
                                </div>
                                {!!user["2fa"] ? (
                                    <KirokuLabel color="primary">
                                        {t('common.enabled')}
                                    </KirokuLabel>
                                ) : (
                                    <KirokuLabel color="danger">
                                        {t('common.disabled')}
                                    </KirokuLabel>
                                )}
                            </div>
                        </div>
                    </div>
                </div>


                <div className='d-flex justify-content-center mt-24' style={{minHeight: '130px'}}>
                    <div>
                        <KirokuButton onClick={this.goBack.bind(this)} color='white'>
                            {t('common.Cancel')}
                        </KirokuButton>
                    </div>
                    <div className='ml-20'>
                        <KirokuButton disabled={this.isDisabled()}
                                      onClick={this.handleSubmitUser.bind(this)}
                                      color='primary'>
                            {t('common.Save')}
                        </KirokuButton>
                    </div>
                </div>
            </div>
        );
    }
}

export default TabProfileEditor;
