import React, { Component } from 'react';
import InputText            from '../QuestionTypeReviewMobile/InputText';
import SingleChoice         from '../QuestionTypeReviewMobile/SingleChoice';
import YesNo                from '../QuestionTypeReviewMobile/YesNo';
import Message              from '../QuestionTypeReviewMobile/Message';
import MultiChoice          from '../QuestionTypeReviewMobile/MultiChoice';
import DateTime             from '../QuestionTypeReviewMobile/DateTime';
import MediaFile            from '../QuestionTypeReviewMobile/MediaFile';
import { withNamespaces }   from 'react-i18next';
import Time                 from '../QuestionTypeReviewMobile/Time';
import OnlyDate             from '../QuestionTypeReviewMobile/OnlyDate';
import Number               from "../QuestionTypeReviewMobile/Number";
import {
    KirokuSortableContainer,
    KirokuSortableElement,
}                           from '../../../../ui';
@withNamespaces()
class Index extends Component {

    questionsMap = {
        InputText  : InputText,
        Single     : SingleChoice,
        YesNo      : YesNo,
        Message    : Message,
        MultiChoice: MultiChoice,
        Date       : DateTime,
        Media      : MediaFile,
        Time       : Time,
        OnlyDate   : OnlyDate,
        Number     : Number
    };

    onSortEnd = ({ oldIndex, newIndex }) => {
        if (this.props.onDragEnd) {
            this.props.onDragEnd({
                source     : {
                    index: oldIndex,
                },
                destination: {
                    index: newIndex,
                },
            });
        }
    };

    render() {
        const { t }     = this.props;
        const survey    = this.props.survey;
        const questions = survey.questions;
        return (
            <div className='pb-5'>
                <div className='padding-responsive'>
                    <div>
                        <div className="keeping-about-survey" style={{ backgroundColor: '#FFFFFF', top: 0}}>
                            <div className='wraper-question'>
                                <div className='wraper-question'>
                                    <div className='info-question' id='create-record-fix'>
                                        <div className='content-heading-question'>
                                            <div className='title-record-info'>{t('createSurvey.Title')}</div>
                                            {survey.title}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div style={{
                                height  : '80vh',
                                overflow: 'auto',
                            }}>
                                <div className='wraper-question pt-2'>
                                    <div className='wraper-question'>
                                        <div className="info-question">
                                            <div className='content-name-question'>
                                                <div className='title-record-info'>{t('createSurvey.Description')}</div>
                                                {survey.description}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <KirokuSortableContainer
                                onSortEnd={this.onSortEnd}
                                useDragHandle
                            >
                                {questions.map((question, index) => {
                                    let QuestionType = this.questionsMap[ question.type ];
                                    return (
                                        <KirokuSortableElement key={index} index={index}>
                                            <div className='pt-2' key={index}>
                                                <QuestionType key={index}
                                                              question={question}
                                                              showBehavior={this.props.showBehavior}
                                                              backToEditQuestion={this.props.backToEditQuestion}
                                                              changeUnrequitedQuestion={(questionIndex) => this.props.changeUnrequitedQuestion(questionIndex)}
                                                />
                                            </div>
                                        </KirokuSortableElement>
                                    );
                                })}
                            </KirokuSortableContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
