export const CREATE_SURVEY_MOBILE = 'CREATE_SURVEY_MOBILE';
export const CHANGE_TYPE_QUESTION = 'CHANGE_TYPE_QUESTION';
export const CREATE_QUESTION_MOBILE = 'CREATE_QUESTION_MOBILE';
export const EDIT_SURVEY_MOBILE = 'EDIT_SURVEY_MOBILE';
export const ADD_LOGIC_MOBILE = 'ADD_LOGIC';
export const BACK_QUESTION_MOBILE = 'BACK_QUESTION_MOBILE';
export const BACK_SURVEY_MOBILE = 'BACK_SURVEY_MOBILE';
export const EDIT_QUESTION_MOBILE = 'EDIT_QUESTION_MOBILE';
export const BACK_TO_ADD_QUESTION = 'BACK_TO_ADD_QUESTION';
export const EDIT_QUESTION_BACK = 'EDIT_QUESTION_BACK';
export const DETAIL_SURVEY_MOBILE = 'DETAIL_SURVEY_MOBILE';
export const NEXT_QUESTION_FROM_SURVEY = 'NEXT_QUESTION_FROM_SURVEY';
export const NEXT_QUESTION_MOBILE = 'NEXT_QUESTION_MOBILE';
export const DELETE_QUESTION_MOBILE = 'DELETE_QUESTION_MOBILE';
export const UPDATE_SURVEY_MOBILE = 'UPDATE_SURVEY_MOBILE';
export const SHOW_REVIEW_MOBILE = 'SHOW_REVIEW_MOBILE';
export const SHOW_ADD_QUESTION = 'SHOW_ADD_QUESTION';
export const SELECT_TYPE_QUESTION = 'SELECT_TYPE_QUESTION';
export const RESET_MOBILE = 'RESET_MOBILE';
export const CLICK_EDIT_REVIEW_TO_ADD_QUESTION = 'CLICK_EDIT_REVIEW_TO_ADD_QUESTION';
export const CLICK_EDIT_REVIEW_TO_EDIT_QUESTION = 'CLICK_EDIT_REVIEW_TO_EDIT_QUESTION';
export const CHANGE_BACK_QUESTION = 'CHANGE_BACK_QUESTION';
export const COPY_QUESTION_FOR_TEMPLATE_MOBILE = 'COPY_QUESTION_FOR_TEMPLATE_MOBILE';

export function clickEditReviewToAddQuestion(question) {
    return {
        type : CLICK_EDIT_REVIEW_TO_ADD_QUESTION,
        question : {
            ...question
        }
    }
}

export function clickEditReviewToEditQuestion(typeComponent, question) {
    return {
        type : CLICK_EDIT_REVIEW_TO_EDIT_QUESTION,
        question : {
            ...question
        },
        typeComponent
    }
}

export function removeQuestion(question) {
    return {
        type: DELETE_QUESTION_MOBILE,
        question
    }
}

export function resetMobile() {
    return {
        type: RESET_MOBILE
    }
}

export function selectTypeQuestion(question) {
    return {
        type: SELECT_TYPE_QUESTION,
        question: {
            ...question
        },
    }
}

export function showReview() {
    return {
        type: SHOW_REVIEW_MOBILE
    }
}

export function showAddQuestion() {
    return {
        type: SHOW_ADD_QUESTION
    }
}

export function updateSurvey(id, survey) {
    return {
        type: UPDATE_SURVEY_MOBILE,
        id,
        survey
    }
}

export function nextQuestionFromSurvey(typeComponent, defaultTpeQuestion = '') {
    return {
        type: NEXT_QUESTION_FROM_SURVEY,
        typeComponent: typeComponent,
        typeQuestion: defaultTpeQuestion
    }
}

export function nextQuestion(orderQuestion) {
    return {
        type: NEXT_QUESTION_MOBILE,
        index: orderQuestion
    }
}

export function detailSurvey(surveyId) {
    return {
        type: DETAIL_SURVEY_MOBILE,
        id: surveyId,
        loginRequired: true
    }

}

export function editQuestionBack(questionBack, error = {}) {
    return {
        type: EDIT_QUESTION_BACK,
        questionBack: {
            ...questionBack
        },
        error: {
            ...error
        }
    }
}

export function backToAddQuestion() {
    return {
        type: BACK_TO_ADD_QUESTION,
        loginRequired: true
    }
}

export function backSurvey() {
    return {
        type: BACK_SURVEY_MOBILE,
        loginRequired: true
    }
}

export function editQuestion(question, error) {
    return {
        type: EDIT_QUESTION_MOBILE,
        question: {
            ...question
        },

        error: {
            ...error
        }
    }
}

export function addLogic(question) {
    return {
        type: ADD_LOGIC_MOBILE,
        question: {
            ...question,
        }
    }
}

export function backQuestion() {
    return {
        type: BACK_QUESTION_MOBILE,
        loginRequired: true
    }
}

export function createSurvey(duplicate = false) {
    return {
        type: CREATE_SURVEY_MOBILE,
        loginRequired: true,
        duplicate
    }
}

export function changeTypeQuestion(typeQuestion) {
    return {
        type: CHANGE_TYPE_QUESTION,
        typeQuestion,
    }
}

export function createQuestion(question) {
    return {
        type: CREATE_QUESTION_MOBILE,
        question
    }
}

export function editSurvey(survey, error) {
    return {
        type: EDIT_SURVEY_MOBILE,
        survey,
        error
    }
}

export function changeBackQuestion(questionToBack) {
    return {
        type: CHANGE_BACK_QUESTION,
        questionToBack
    }
}

export function resetStateCreateSurveyMobile() {
    return {
        type: 'CREATE_SURVEY_MOBILE_DONE'
    }

}

export function copyQuestionForTemplateOnMobile(question) {
    return {
        type : COPY_QUESTION_FOR_TEMPLATE_MOBILE,
        question
    }
}
