import React, { Component } from 'react';
import { hiddenLayout }     from '../../actions/ActionLayout';
import { connect }          from 'react-redux';

@connect(state => {
    return {
        hiddenLayout: state.ReduceActionLayout.hiddenLayout,
    };
}, dispatch => {
    return {
        hiddenLayout: () => {
            dispatch(hiddenLayout());
        },
    };
})
class Privacy extends Component {

    componentWillMount() {
        this.props.hiddenLayout();
    }

    render() {
        return (
            <div className={'policy-page A4' }>
                <h2 className={'align-header font-weight-header'}>プライバシーポリシー</h2>
                <br/>
                <p style={{marginBottom: 0}}>
                    ピットアウト株式会社（以下「当社」といいます。）は、当社が提供する業務記録サービス『kirokuai』
                    （以下「本サービス」といいます。）の利用にあたって契約者、ユーザー、利用希望者、登録申請者（以下、「ユーザー等」といいます。）
                    から取得した個人情報（個人情報保護法第２条第１項により定義された「個人情報」をいい、以下同様とします。）
                    を含むユーザーの情報（以下、「ユーザー情報」といいます。）を以下のとおり取り扱います。
                </p>
                <p>なお、本ポリシーにおいて使用する用語は、別途規定する場合を除き、本サービスの利用規約に従うものとします。</p>
                <hr/>
                <h5 className={"font-weight-header"}>第１条（総則）</h5>
                <ol>
                    <li>当社は、ユーザー情報の適切な保護実現のため、個人情報保護法及びその他関連する法令、ガイドライン等を遵守し、個人情報の適切な取扱い及び保護に努めます。</li>
                    <li>本ポリシーは、本サービスを利用する全てのユーザー等に適用されます。また、当社が、当社の運営するウェブサイト上に掲載するプライバシーポリシーその他の個人情報保護方針又は利用規約等において個人情報等の取扱いについて規定する場合、当該規定も適用されるものとし、当該規定が本ポリシーと抵触する場合には、本ポリシーが優先されるものとします。</li>
                    <li>外部サービスについては、本ポリシーの規定は適用されません。外部サービスにおけるユーザー情報の取扱いについては、当該提携サービスを提供する事業者が別途定めるプライバシーポリシー等をご参照ください。</li>
                </ol>
                <hr/>
                <h5 className={"font-weight-header"}>第２条（当社が取得する個人情報及びその取得方法）</h5>
                <ol>
                    <li> 当社は、本サービスの利用にあたり、以下に定めるとおり、個人情報を取得します。
                        <ol className={'number-list'}>
                            <li>
                                ユーザー等からご提供いただく情報<br/>
                                本サービスを利用するために、または本サービスの利用を通じてユーザーからご提供いただく情報は以下のとおりです。<br/>
                                ア ユーザー等の氏名
                            </li>
                            <li>
                                本サービスその他これに付随するサービス（以下「本サービス等」といいます）の利用時にユーザー等から当社が収集する情報 <br/>
                                ア 端末情報<br/>
                                <div className={'text-justify'}>当社は、ユーザー等が端末又は携帯端末上で本サービスを利用する場合、本サービスの維持及び改善、又は不正行為防止のため、ユーザーが使用する端末情報（端末を識別可能なID情報等）を収集することがあります。</div>
                                <div className={'text-justify'}>イ ログ情報、行動履歴及びサービス利用状況に関する情報</div>
                                <div className={'text-justify'}>
                                    当社は、本サービスの維持及び改善、又は不正行為防止のため、本サービスの利用時に自動で生成、保存されるIP
                                    アドレス、ユーザー等からのリクエスト日時、本サービス利用の際の操作履歴の情報を収集することがあります。
                                </div>
                                <div>ウ Cookie 及び匿名ID</div>
                                <div className={'text-justify'}>
                                    本サービスの利用において、「Cookie（クッキー）」と呼ばれる技術及びこれに類する技術を使用する場合があります。Cookie
                                    とは、ウェブサーバがユーザーのコンピュータを識別する業界標準の技術です。なお、電子端末上の設定の変更によりCookie
                                    の機能を無効にすることはできますが、その場合、本サービスの利用ができなくなる場合があり得ますのであらかじめご承知おきください。
                                </div>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <div className={'text-justify'}>
                        当社は、ユーザー情報の取得にあたっては、偽りその他不正の手段によらず、適正に
                        取得します。また、当社は、ユーザーが本サービスを利用することによる取得以外の方法
                        でユーザー情報等を取得する場合には、事前にその利用目的を通知又は公表します。
                        </div>
                    </li>
                </ol>
                <hr/>
                <h5 className={"font-weight-header"}>第３条（利用目的)</h5>
                <p>当社は、以下の目的で、ユーザー情報を利用します。</p>
                <ul>
                    <li>当社サービスの運営管理のため</li>
                    <li>当社サービスに関する案内、サポート、ユーザー等からの問い合わせ、依頼、苦情、紛争処理対応のため</li>
                    <li>関連する当社サービス、外部サービスの案内</li>
                    <li>当社の情報、システム、およびネットワークのモニタリングと保護のため</li>
                    <li>当社社内業務調査の目的のため、リサーチと製品の開発および改良のため</li>
                    <li>当社の提供するコンテンツのユーザー等に対する広報、広告のため</li>
                    <li>当社サイトの機能向上のため</li>
                </ul>
                <hr/>
                <h5 className={"font-weight-header"}>第４条（利用目的の変更)</h5>
                <ol>
                    <li>当社は、前項の利用目的を、変更前の利用目的と関連性を有すると合理的に認められる範囲内において変更することがあります。</li>
                    <li>利用目的を変更した場合には、ユーザー等に対し、通知又は本サービス上若しくは当社の運営するウェブサイトでの掲示その他分かりやすい方法により公表します。</li>
                </ol>
                <hr/>
                <h5 className={"font-weight-header"}>第５条（第三者提供）</h5>
                <ol>
                    <li>
                        当社は、原則として、本人の同意を得ずに個人情報を第三者（日本国外にある者を含みます。）に提供しません。ただし、以下の場合は、関係法令に反しない範囲で、ユーザーの同意なく個人情報を提供することがあります。
                        <ol className={'number-list'}>
                            <li>法令に基づく場合</li>
                            <li>本サービス利用上、ユーザーが他人の利益を害しもしくは公序良俗に反する行為その他本サービスの利用規約に違反する行為を行い又はこれを行おうとするときに、当該行為に対して必要な措置をとる場合</li>
                            <li>人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</li>
                            <li>公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</li>
                            <li>国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
                            <li>合併、会社分割、事業譲渡その他の事由によりユーザーの個人情報を含む事業の承継がなされる場合</li>
                        </ol>
                    </li>
                    <li>
                        当社は、ユーザーの同意に基づき個人データ（個人情報保護法第２条第６項により定義された「個人データ」をいい、以下同様とします。）を第三者に提供した場合、以下の事項に関する記録を作成し、保管します。
                        <ol className="number-list">
                            <li>ユーザーから事前の同意を得ていること</li>
                            <li>当該第三者の氏名又は名称その他の当該第三者を特定するに足りる事項</li>
                            <li>当該個人データによって識別される者の氏名その他のその者を特定するに足りる情報</li>
                            <li>当該個人データの項目</li>
                        </ol>
                    </li>
                    <li>第１項の規定にかかわらず、当社は、個人情報保護委員会に届け出た場合には、第三者に対し、第３条第１項表中の第三者提供にかかるユーザー情報等の項目に含まれる個人データを、電子メールで送付する等の方法により、提供することができます。ただし、ユーザーが自身の個人データについて第三者に提供することを希望しないときは、本ポリシー第１０条に定める手続に従い当社にお申し付けいただければ、第三者への提供を停止いたします。</li>
                </ol>
                <hr/>
                <h5 className={"font-weight-header"}>第６条（個人情報の取扱いの委託）</h5>
                <p>当社は、利用目的の達成に必要な範囲内において、ユーザーから取得した個人情報の全部又は一部の取扱いを第三者に委託することがあります。この場合、当社は、当該委託先との間で本ポリシーに準じる内容の秘密保持契約等をあらかじめ締結するとともに、当該委託先において情報の適切な安全管理が図られるよう、必要かつ適切な監督を行います。</p>
                <hr/>
                <h5 className={"font-weight-header"}>第７条（情報収集モジュール）</h5>
                <p>本サービスには、本サービスの利用状況及び本サービスを含むサービスに関する広告効果等の情報を解析するため、当社が選定する以下の情報収集モジュールを組み込む場合があります。これに伴い、当社は、以下の情報収集モジュールの提供者に対しユーザー情報の提供を行う場合があります。これらの情報収集モジュールは、個人を特定する情報を含むことなくユーザー情報を収集し、収集された情報は、各情報収集モジュール提供者の定めるプライバシーポリシーその他の規定に基づき管理されます。
                    名称：Google Analytics
                    提供者：Google Inc.
                    プライバシーポリシー：<a href="http://www.google.com/intl/ja/policies/privacy/"
                                  rel="noopener noreferrer"
                                  target="_blank">
                        http://www.google.com/intl/ja/policies/privacy/
                    </a></p>
                <div className={'table-privacy'}>
                    <table className="table table-bordered">
                        <tbody>
                        <tr>
                            <td className={"width-table-privacy"}>(1) 情報収集モジュールの名称</td>
                            <td>Google Analytics</td>
                        </tr>
                        <tr>
                            <td className={"width-table-privacy"}>(2) 情報収集モジュールの提供者</td>
                            <td>Google Inc.</td>
                        </tr>
                        <tr>
                            <td className={"width-table-privacy"}>(3) 提供される利用者情報の項目</td>
                            <td>本サービスのご利用状況(アクセス回数、時間、閲覧ページ等)及びご利用の端末情報(ブラウザバージョン、言語、地域等)</td>
                        </tr>
                        <tr>
                            <td className={"width-table-privacy"}>(4) 提供の手段・方法</td>
                            <td>情報収集モジュールがGoogle Inc.に自動送信</td>
                        </tr>
                        <tr>
                            <td className={"width-table-privacy"}>(5) 上記提供者における利用目的</td>
                            <td>サービスの機能評価及び改善のために利用</td>
                        </tr>
                        <tr>
                            <td className={"width-table-privacy"}>(6) 上記提供者における第三者提供の有無</td>
                            <td>なし</td>
                        </tr>
                        <tr>
                            <td className={"width-table-privacy"}>(7) 上記提供者のプライバシーポリシーのURL</td>
                            <td>
                                <a href="http://www.google.com/intl/ja/policies/privacy/"
                                   rel="noopener noreferrer"
                                   target="_blank">
                                    http://www.google.com/intl/ja/policies/privacy/
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <hr/>
                <h5 className={"font-weight-header"}>第８条（行動ターゲティング広告）</h5>
                <ol>
                    <li className={'text-align'}>当社は、当社又は広告配信事業者等の第三者によるターゲティング広告（ユーザーのニーズないし興味、関心に合わせて広告を配信する広告手法をいいます。）の実施のため、本サービス又は外部サービスの提供に際し、前条に定める情報収集モジュールを利用して、以下の情報を収集し、これを蓄積又は利用することがあります。
                        <ol className="number-list">
                            <li>行動履歴及びサービス利用状況に関する情報（本サービスの利用履歴等、それを蓄積することによりユーザーのニーズや興味、関心の分析に供することができる情報で、特定の個人を識別するに至らないものをいいます。）</li>
                            <li>端末情報</li>
                        </ol>
                    </li>
                    <li>当社は、前項に基づき収集した情報を、ユーザーのニーズないし興味、関心に合わせてカスタマイズした最適な広告を配信することにより、ユーザーに有益な情報を届けることを目的として、行動ターゲティング広告を配信する事業者（以下「広告配信事業者」といいます。）に提供します。</li>
                    <li>広告配信事業者による各情報の取扱いについては、広告配信事業者の定めるプライバシーポリシーその他の条件に従うものとします。</li>
                </ol>
                <hr/>
                <h5 className={"font-weight-header"}>第９条（個人情報の開示）</h5>
                <p>当社は、ユーザーから、個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、手数料（1件あたり1,000円）を頂戴しておりますので、あらかじめ御了承ください。</p>
                <hr/>
                <h5 className={"font-weight-header"}>第１０条（個人情報の訂正及び利用停止等）</h5>
                <ol>
                    <li>当社は、ユーザー等から、(1)個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び(2)あらかじめ公表された利用目的の範囲を超えて取扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、ユーザー等ご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をユーザー等に通知します。なお、訂正または利用停止を行わない旨の決定をしたときは、ユーザー等に対しその旨を通知いたします。</li>
                    <li>当社は、ユーザー等から、ユーザー等の個人情報について消去を求められた場合、当社が当該請求に応じる必要があると判断した場合は、ユーザー等ご本人からのご請求であることを確認の上で、個人情報の消去を行い、その旨をユーザー等に通知します。</li>
                    <li>個人情報保護法その他の法令により、当社が訂正等または利用停止等の義務を負わない場合は、前2項の規定は適用されません。</li>
                </ol>
                <hr/>
                <h5 className={"font-weight-header"}>第１１条（本ポリシーの変更）</h5>
                <ul>
                    <li>当社は、ユーザー情報等の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、随時本ポリシーを変更することがあります。</li>
                    <li>変更後の本ポリシーについては、本サービス上又は当社の運営するウェブサイトでの掲示その他分かりやすい方法により告知します。ただし、法令上利用者の同意が必要となるような内容の変更を行うときは、別途当社が定める方法により、契約者の同意を取得します。</li>
                </ul>
                <hr/>
                <h5 className={"font-weight-header"}>第１２条（お問い合わせ）</h5>
                <p>当社のユーザー情報の取扱いに関するご意見、ご質問、苦情のお申出その他ユーザー情報等の取扱いに関するお問い合わせは、下記お問い合わせフォームからご連絡ください。</p>
                <p>【 &bull;年&bull;月&bull;日制定】</p>

                <p style={{marginBottom: 0}}>ピットアウト株式会社 </p>
                <p>
                    <a href="http://pitout.co.jp"
                       rel="noopener noreferrer"
                       target="_blank">
                        http://pitout.co.jp
                    </a>
                </p>

            </div>
        );
    }
}

export default Privacy;
