import React, {Component} from 'react';
import {withNamespaces}   from "react-i18next";
import _ from 'lodash';
@withNamespaces()
class KirokuLogicApplicated extends Component {
    render() {
        const {t, padding}      = this.props;
        const question = this.props.question;
        let isLogic  = question.nextQuestion === 0 || question.nextQuestion;

        if(question.type === 'YesNo') {
            isLogic = question.nextQuestionNo === 0 || question.nextQuestionNo ||
                      question.nextQuestionYes === 0 || question.nextQuestionYes ||
                      question.nextQuestion === 0 || question.nextQuestion
        }

        if(question.type === 'Single') {
            const singleQuestion = question.singleQuestion;
            const isLogicSingle = _.findIndex(singleQuestion, (o) => {return typeof o.nextQuestion === 'number'}) !== -1;
            isLogic  = question.nextQuestion === 0 || question.nextQuestion || isLogicSingle;
        }

        return (
            <label className='ml-2 pt-1'
                   style={{backgroundColor: '#f69931', fontSize: '13px', color: '#FFFFFF', borderRadius: 2, padding: padding ? padding : '2px 5px'}}
                   hidden={!isLogic}>
                {t('createSurvey.LogicApplied')}
            </label>
        )
    }
}

export default KirokuLogicApplicated;
