import React, {Component}    from 'react';
import KirokuModalHeader     from "../../../../ui/KirokuModalHeader";
import KirokuInputSearch     from "../../../../ui/KirokuInputSearch";
import KirokuModalBody       from "../../../../ui/KirokuModalBody";
import KirokuCardList        from "../../../../ui/KirokuCardList";
import KirokuCard            from "../../../../ui/KirokuCard";
import KirokuCheckBox        from "../../../../ui/KirokuCheckBox";
import KirokuModalFooter     from "../../../../ui/KirokuModalFooter";
import KirokuButton          from "../../../../ui/KirokuButton";
import KirokuModal           from "../../../../ui/KirokuModal";
import {withNamespaces}      from "react-i18next";
import connect               from "react-redux/es/connect/connect";
import _                     from "lodash";
import ModalAssignUserToTeam from "../ModalAssignUserToTeam";
import {loadTeam}            from "../../../../actions/Team";

@withNamespaces()
@connect(state => {
    return {
        profile: state.KirokuAIUser.profile,
        users: state.KirokuAIUser.users,
        loading: state.Loading
    }
}, (dispatch) => {
    return {
        loadAllTeam: () => {
            dispatch(loadTeam())
        }
    };
})
class ModalSelectStaff extends Component {
    state = {
        users: [],
        usersRecord: [],
        userChoice: [],
        statusCheck: false,
        showModalCustomer: false
    };

    componentWillMount() {
        const {users} = this.props;
        this.setState({
            users: users.map(user => ({
                ...user,
                isChecked: false
            })),
            usersRecord: users.map(user => ({
                ...user,
                isChecked: false
            })),
        })
    }

    handleCancel() {
        this.props.closeModal()
    }

    handleAssign() {
        let {users}    = this.state;
        let userChoice = users.filter(item => item.isChecked);
        this.setState({
            userChoice: userChoice,
            showModalCustomer: !this.state.showModalCustomer
        })
    }

    handleCheckUser(userId) {
        let {users, usersRecord} = this.state;
        let newUserRecords       = users.map(survey => ({...survey}));
        let user                 = _.find(newUserRecords, survey => survey.id === userId);
        user.isChecked           = !user.isChecked;
        let searchUsers          = usersRecord;
        let usersAssign          = _.find(searchUsers, survey => survey.id === userId);
        usersAssign.isChecked    = !usersAssign.isChecked;
        let checked              = _.filter(newUserRecords, (survey) => !survey.isChecked);

        this.setState({
            users: newUserRecords,
            usersRecord: searchUsers,
            statusCheck: !checked.length
        });
    }

    handleCheckAll() {
        let {users, usersRecord} = this.state;
        let newUserRecord        = users.map(user => ({
            ...user,
            isChecked: !this.state.statusCheck,
        }));
        let userChecked          = newUserRecord.map(item => item.id || []);
        let newUserAssign        = usersRecord.map(user => {
            if (userChecked.includes(user.id)) {
                return {
                    ...user,
                    isChecked: !this.state.statusCheck,
                }
            }
            return {
                ...user
            };
        });
        this.setState({
            users: newUserRecord,
            usersRecord: newUserAssign,
            statusCheck: !this.state.statusCheck,
        })
    }


    onSearch(e) {
        let input   = e.target.value.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
        let users   = this.state.usersRecord.filter(user =>
            user.name.toLowerCase().search(input.toLowerCase()) !== -1
        );
        let checked = _.filter(users, (user) => !user.isChecked);
        this.setState({users: users, statusCheck: !checked.length});
    }

    isSelect() {
        return !this.state.users.filter(item => item.isChecked).length
    }

    closeModalAssignUserToTeam() {
        this.setState({
            showModalCustomer: !this.state.showModalCustomer
        });
    }

    closeModalAssign() {
        this.setState({
            showModalCustomer: !this.state.showModalCustomer
        });
        this.props.closeModal()
    }

    render() {
        let {users} = this.state;
        let {t, tags, params} = this.props;
        return (
            <div>
                <KirokuModal show={this.props.show}>
                    <KirokuModalHeader>
                        <h6 className="mb-3">{t('common.AssignToUser')}</h6>
                        <KirokuInputSearch onChange={this.onSearch.bind(this)}
                                           placeholder={t('common.Staffs')}/>
                    </KirokuModalHeader>
                    <KirokuModalBody>
                        <div id="body-modal-as">
                            <KirokuCardList
                                headerLeft={t('common.Staffs')}
                                headerRight={
                                    <div className='text-primary' style={{cursor: 'pointer'}}>
                                        <span hidden={this.state.statusCheck}
                                              onClick={this.handleCheckAll.bind(this)}
                                        >{t('common.SelectAll')}</span>

                                        <span hidden={!this.state.statusCheck}
                                              onClick={this.handleCheckAll.bind(this)}
                                        >{t('common.UnSelect')}</span>
                                    </div>
                                }
                            >
                                <section className="scroll-data-survey">
                                    {users.map((team, index) => (
                                        <KirokuCard key={team.id}>
                                            <KirokuCheckBox
                                                onChange={this.handleCheckUser.bind(this, team.id)}
                                                key={index}
                                                checked={team.isChecked}
                                                content={team.name}/>
                                        </KirokuCard>
                                    ))}
                                </section>

                            </KirokuCardList>
                        </div>
                    </KirokuModalBody>
                    <KirokuModalFooter>
                        <KirokuButton onClick={this.handleCancel.bind(this)} color={'white'}>
                            {t('common.Cancel')}
                        </KirokuButton>
                        <KirokuButton disabled={this.isSelect()}
                                      onClick={this.handleAssign.bind(this)}
                                      color={'primary'}>
                            {t('common.Apply')}
                        </KirokuButton>
                    </KirokuModalFooter>
                </KirokuModal>
                <ModalAssignUserToTeam
                    params={params}
                    tags={tags}
                    closeModalAssign={this.closeModalAssign.bind(this)}
                    users={this.state.userChoice}
                    disableButtonApply={this.state.disableButtonApply}
                    close={this.closeModalAssignUserToTeam.bind(this)}
                    show={this.state.showModalCustomer}
                />
            </div>
        )
    }
}

export default ModalSelectStaff;
