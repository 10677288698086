import React, {Component} from "react";
import Datetime           from "react-datetime";
import KirokuActionSurvey from "../../ui/KirokuActionSurvey";
import {withNamespaces}   from "react-i18next";
import {KirokuButton}     from "../../ui";
import moment             from "moment";

@withNamespaces()
class MonitoringTermsMobile extends Component {
    state = {
        monitoringTerms: [{
            date: '',
            validateFormatDate: false
        }]
    };

    componentWillMount() {
        const {monitoringTerms} = this.props;
        if(monitoringTerms.length) {
            this.setState({
                monitoringTerms: monitoringTerms
            })
        }
    }

    getMonitoringTerms() {
        const monitoringTerms = JSON.parse(JSON.stringify(this.state.monitoringTerms));
        this.props.addMonitoringTerms(monitoringTerms);
    }

    editDateOfMonitoring(momentTime, serviceIndex) {
        let monitoringTermsState = [...this.state.monitoringTerms];

        monitoringTermsState[serviceIndex] = {
            ...monitoringTermsState[serviceIndex],
            date: typeof momentTime === 'object' ? moment(momentTime).unix()  : momentTime,
            validateFormatDate: !moment(momentTime, 'YYYY/MM', true).isValid() && momentTime
        };

        return monitoringTermsState;
    }


    handleOnChangeDate(indexService, momentTime) {
        this.setState({
            monitoringTerms: this.editDateOfMonitoring(momentTime, indexService)
        }, () => {
            this.getMonitoringTerms();
        })
    }

    addMonitoringTerm() {
        this.setState({
            monitoringTerms: [
                ...this.state.monitoringTerms, {date: ''}
            ]
        }, () => {
            this.getMonitoringTerms();
        })
    }

    decrementMonitoringTerm(index) {
        const {monitoringTerms} = this.state;
        let newMonitoringTerms = [...monitoringTerms];
        newMonitoringTerms.splice(index, 1);
        this.setState({
            monitoringTerms: newMonitoringTerms
        }, () => {
            this.getMonitoringTerms();
        })
    }

    checkGetValueInputDate = (date) => {
        return typeof date === 'number' ? moment.unix(date): date;
    };

    render() {
        const {t} = this.props;
        const {monitoringTerms} = this.state;
        return (
            <div>
                <h5>{t('CustomerEditor.monitoringTerms')}</h5>
                <div className="row pt-2">
                    {monitoringTerms.map((monitoringTerm, index) => {
                        const dateMonitoringTerm = this.checkGetValueInputDate(monitoringTerm.date);
                        return (
                            <div className={'col-lg-2 pt-2'} key={index}>
                                <div className="d-flex">
                                    <div style={{width: '100%'}}>
                                        <Datetime
                                            className='read-only-date'
                                            inputProps={{disabled: false, readOnly: true}}
                                            value={dateMonitoringTerm}
                                            timeFormat={false}
                                            closeOnSelect={false}
                                            dateFormat={"YYYY/MM"}
                                            onChange={this.handleOnChangeDate.bind(this, index)}
                                            locale={t('createRecord.Date')}
                                        />
                                        <p hidden={!monitoringTerm.validateFormatDate} style={{
                                            color: 'red',
                                            fontSize: 12
                                        }}>{t('CustomerEditor.validate.ValidDate')}
                                        </p>
                                    </div>
                                    <div className={'p-2'}/>
                                    <div className={'cursor-pointer pt-2'}
                                         onClick={this.decrementMonitoringTerm.bind(this, index)}
                                         hidden={!(monitoringTerms.length > 1)}
                                    >
                                        <KirokuActionSurvey icon={'minus'}/>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div className={'col-lg-2 pt-2'}>
                        <KirokuButton
                                      onClick={this.addMonitoringTerm.bind(this)}
                                      color={'primary'}>
                            {t('CustomerEditor.add')}
                        </KirokuButton>
                    </div>
                </div>
            </div>
        );
    }
}

export default MonitoringTermsMobile;
