import React, {Component}                            from 'react';
import {withNamespaces}                              from "react-i18next";
import {connect}                                     from "react-redux";
import {loadTeam}                                    from "../../actions/Team";
import {currentPage}                                 from "../../actions/CurrentPage";
import {CheckDivide, KirokuLoading, KirokuTitlepage} from "../../ui";
import {loadCustomerAll}                             from "../../actions/Customer";
import {loadAllSurveys}                              from "../../actions/Record";
import {loadUsers}                                   from "../../actions/User";
import ReportsWeb                                    from './ReportsWeb';
import {RESET_CONDITIONS}                            from "../../actions/Reports";

@withNamespaces()
@connect( state => {
    return {
        loading                   : state.Loading,
        isAllTeam                 : state.KirokuAITeam.allTeam.length,
        isListAllCustomer         : state.KirokuAICustomer.ListAllCustomer.length,
        isListAllSurveyTemplate   : state.KirokuAISurvey.ListAllSurveyTemplate.length,
    }
}, dispatch => {
    return {
        namePage: (name) => {
            dispatch(currentPage(name))
        },
        loadTeam: () => {
            dispatch(loadTeam())
        },
        loadCustomerAll: () => {
            dispatch(loadCustomerAll())
        },
        loadAllSurveys: () => {
            dispatch(loadAllSurveys())
        },
        loadUsers: () => {
            dispatch(loadUsers())
        },
        resetConditionsReport: () => {
            dispatch({
                type : RESET_CONDITIONS
            })
        }
    }
})
class Reports extends Component {

    componentWillMount() {
        this.props.resetConditionsReport();
    }

    componentDidMount() {
        this.props.namePage({
            title: <KirokuTitlepage title={this.props.t('Layout.Reports')}/>,
            actionPage: null
        });
        this.props.loadCustomerAll();
        this.props.loadAllSurveys();
        this.props.loadTeam();
        this.props.loadUsers();
    }

    render() {
        const {isAllTeam, isListAllSurveyTemplate, isListAllCustomer} = this.props;

        return <div>
            {isListAllSurveyTemplate && isAllTeam && isListAllCustomer ?
                <CheckDivide web={<ReportsWeb/>}/> : <KirokuLoading/>
            }
        </div>;
    }
}

export default Reports
