class LanguageService {
    constructor(axios, config) {
        this.axios  = axios;
        this.config = config;
    }

    getLanguages = () => {
        return [
            {
                name : 'Japanese',
                icon : 'jp',
                key  : 'ja',
                class: 'flag-icon ja '
            },
            {
                name : 'English',
                icon : 'gb',
                key  : 'en',
                class: 'flag-icon en '
            }
        ]
    }

}

export default LanguageService;