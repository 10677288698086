import {
    KirokuButton, KirokuModal,
    KirokuModalBody, KirokuModalHeader,
}                           from '../../../../../../ui';
import React, { Component } from 'react';
import { connect }          from 'react-redux';
import TabSelected          from './TabSelected';
import TabOptionsSurveys    from './TabOptionsSurveys';
import { withNamespaces }   from 'react-i18next';

@withNamespaces()
@connect(state => {
    return {
        isOpenTabSelected       : state.KirokuModal.ModalSelectSurveys.isOpenTabSelected,
        isOpenModalSelectSurveys: state.KirokuModal.ModalSelectSurveys.isOpenModalSelectSurveys,
        libSurveysSelected      : state.KirokuModal.ModalSelectSurveys.libSurveysSelected,
        libSurveys              : state.KirokuModal.ModalSelectSurveys.libSurveys,
    };
}, dispatch => {
    return {
        changeIsOpenModalSelectSurveys: () => {
            dispatch({
                type: 'CHANGE_IS_OPEN_MODAL_SELECT_SURVEYS',
            });
        },
        changeIsOpenSelected          : (isOpenTabSelected) => {
            dispatch({
                type: 'CHANGE_IS_OPEN_TAB_SELECTED_MODAL_SELECT_SURVEYS',
                isOpenTabSelected,
            });
        },
        handleConfirmModalSurvey      : (templateIds) => {
            dispatch({
                type       : 'HANDLE_CONFIRM_MODAL_SELECT_SURVEYS',
                templateIds: templateIds.map(template => template.id),
            });
        },
        handleCancelModalSurvey       : () => {
            dispatch({
                type: 'CANCEL_MODAL_SELECT_SURVEYS',
            });
        },

    };
})
class ModalSelectSurveys extends Component {

    render() {
        const { t, isOpenTabSelected,
                isOpenModalSelectSurveys,
                libSurveysSelected, libSurveys,
              } = this.props;
        // const selectedNumber = isOpenTabSelected ? libSurveysSelected.filter(itm => itm.selected).length : libSurveys.filter(itm => itm.selected).length;
        const surveysId      = isOpenTabSelected ? libSurveysSelected.filter(itm => itm.selected) : libSurveys.filter(itm => itm.selected);
        return (
            <div className={ 'modal-select-record-beta' }>
                <KirokuModal show={ isOpenModalSelectSurveys }
                             onCloseModal={ () => this.props.changeIsOpenModalSelectSurveys() }>
                    <KirokuModalHeader>
                        <div className={ 'text-center font-header-on-modal' }>{ t('RecordsBeta.headerSelectTemplate') }</div>
                    </KirokuModalHeader>
                    <KirokuModalBody style={ { backgroundColor: '#eef3f6', padding: '17px 5px 1px 5px' } }>
                        <div className="d-flex justify-content-around border-btn-status-group">
                            <div
                                className={ `border-width-each-status first-btn font-size-16 cursor-pointer ${ isOpenTabSelected ? 'btn-active' : '' }` }
                                onClick={ () => this.props.changeIsOpenSelected(true) }
                            >
                                { t('RecordsBeta.displaySelection') }
                            </div>
                            <div
                                className={ `border-width-each-status third-btn font-size-16 cursor-pointer ${ !isOpenTabSelected ? 'btn-active' : '' }` }
                                onClick={ () => this.props.changeIsOpenSelected(false) }
                            >
                                { t('RecordsBeta.showAll') }
                            </div>
                        </div>
                        { isOpenTabSelected ? <TabSelected/> : <TabOptionsSurveys/> }
                    </KirokuModalBody>

                    <div style={ { backgroundColor: '#101F41', padding: '2em' } }
                         className="d-flex justify-content-around">
                        <KirokuButton onClick={ () => {
                            this.props.handleCancelModalSurvey();
                            this.props.changeIsOpenModalSelectSurveys();
                        } } className={ 'btn-cancel-on-modal' }
                        >
                            { t('RecordsBeta.cancel') }
                        </KirokuButton>
                        <div className="align-self-center">
                            {/*{ selectedNumber ? t('RecordsBeta.isSelected', { count: selectedNumber }) : t('RecordsBeta.notSelected') }*/}
                        </div>
                        <KirokuButton className={'btn-confirm-on-modal'}
                                      onClick={ () => {
                                          this.props.changeIsOpenModalSelectSurveys();
                                          this.props.handleConfirmModalSurvey(surveysId);
                                      } }
                        >
                            { t('RecordsBeta.confirm') }
                        </KirokuButton>
                    </div>
                </KirokuModal>
            </div>
        );
    }
}

export default ModalSelectSurveys;
