import React, {Component} from 'react';
import {
    FormatTimestamp,
    KirokuCardDeletedScreen,
    KirokuLoading,
    SearchNotFound,
} from "../../../../ui";
import {withNamespaces}   from "react-i18next";
import {connect}          from "react-redux";

@withNamespaces()
@connect(state => {
    return {
        staffs :  state.DeletedStaffs.staffs,
        loading : state.Loading
    }
}, dispatch => {
    return {

    }
})

class TableStaffList extends Component{

    render() {
        const {t, staffs, loading} = this.props;
        return(
            <div className='bg-white'>
                {!loading ?
                <KirokuCardDeletedScreen>
                    { staffs.length ?
                    staffs.map((staff, index) => (
                        <div key={index}>
                            <div className='pl-3 pt-3' >
                                <div className='header-card-del pb-2'>{staff.name}</div>
                                <div className='title-card-del'>{t('DeletedList.deletedAt')} : <FormatTimestamp timestampUnix={staff.delete_at}/></div>
                                <div className='title-card-del'>{t('DeletedList.createdAt')} : <FormatTimestamp timestampUnix={staff.create_at}/></div>
                            </div>
                            <hr className='hr-table-mobile'/>
                        </div>
                    )) : <SearchNotFound/>}
                </KirokuCardDeletedScreen> : <KirokuLoading/> }
            </div>
        )
    }
}

export default TableStaffList;
