import React, {Component}  from 'react';
import { SortableElement } from 'react-sortable-hoc';

@SortableElement
class KirokuSortableElement extends Component {
    render() {
        return (
            <div {...this.props}>
                {this.props.children}
            </div>
        )
    }
}
export default KirokuSortableElement;
