import React, { Component } from 'react';
import KirokuButton         from '../../../ui/KirokuButton';
import { withNamespaces }   from 'react-i18next';

@withNamespaces()
class ButtonEditQuestion extends Component {

    state = {
        ...this.props
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (
            this.props.flagEdit !== nextProps.flagEdit ||
            this.props.disabled !== nextProps.disabled ||
            this.props.validated !== nextProps.validated
        ) {
            this.setState({
                ...nextProps
            })
        }
    }

    actionClick() {
        this.setState({
            disabled: !this.state.disabled,
            flagEdit: !this.state.flagEdit
        }, () => {
            this.props.clickEditQuestion(this.state)
        });
    }

    render() {
        const {t, isUnrequited} = this.props;
        const {disabled, flagEdit, validated} = this.state;
        return (
            <div className='overlay-action' style={{right: '-23px'}}>
                <KirokuButton hidden={!disabled} color={'primary'}
                              onClick={this.actionClick.bind(this)}>
                    {t('createRecord.Edit')}
                </KirokuButton>
                <KirokuButton hidden={!flagEdit} color={'success'}
                              disabled={isUnrequited ? !isUnrequited : !validated}
                              onClick={this.actionClick.bind(this)}>
                    {t('createRecord.Done')}
                </KirokuButton>
            </div>
        );
    }
}

export default ButtonEditQuestion;
