
const initialState = {
    dateRange: ""
};

const DateTimePicker = (state = initialState, action) => {
    switch (action.type) {
        case "CHANGE_DATE":
            return {
                ...state,
                dateRange: action.dateRange
            };
        case "REMOVE_DATE":
            return {
                ...state,
                dateRange: initialState.dateRange
            };
        default:
            return {...state}
    }
};

export default DateTimePicker
