import React, {Component} from 'react';
import {withNamespaces}   from "react-i18next";

@withNamespaces()
class TabResult extends Component {

    render() {
        const {t} = this.props;
        return (
            <div className='fix-center'>
                {/*<img className='pt-2 pb-2' src="/assets/img/team-icon@2x.png" alt="no team"/>*/}
                {this.props.children}
                <h5 className='mt-1'>{this.props.messageFirst ? t(`NullPage.${this.props.messageFirst}`) : ''}</h5>
                <h5 className='text-center'>{this.props.messageSecond ? t(`NullPage.${this.props.messageSecond}`) : ''}</h5>
            </div>
        )
    }
}

export default TabResult;