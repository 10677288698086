import {Component}        from "react";
import React              from "react";
import KirokuDropdownItem from "./KirokuDropdownItem";

export default class KirokuDropdownIcon extends Component {

    state = {
        selected: null
    };

    constructor(props) {
        super(props);

        this.handleClick        = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            popupVisible: false
        };
    }

    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
    }

    handleOutsideClick() {
        if (!this.node) {
            return;
        }
        this.handleClick();
    }

    handleSelected(event) {
        this.props.onSelect(event);
        this.setState({
            selected: event.kirokuSelected
        });
    }

    render() {
        const selectedLabel = this.state.selected ? this.state.selected.label : this.props.title;
        return (
            <div ref={node => {
                this.node = node
            }}>
                <div className="dropdown">
                    <button {...this.props} className="btn btn-kiroku" onClick={this.handleClick.bind(this)}>
                        <i className={`${this.props.icon} left text-kiroku pr-5px`}/>
                        <span
                            className={'text-kiroku left'}>{this.props.defaultValue ? this.props.defaultValue : selectedLabel}</span>
                        <i className='fa fa-chevron-down icon-kiroku'/>
                    </button>
                    {this.state.popupVisible && (
                        <div
                            className={`dropdown-kiroku dropdown-menu show ${this.props.location ? this.props.location : ''}`}
                            style={{position: 'absolute', willChange: 'top, left', left: 'auto', top: 'auto'}}>
                            <section className={'scroll-data-customer'}>
                                {this.props.items.map((item, index) => {
                                    const Item = item.component;
                                    return (
                                        <KirokuDropdownItem key={index} onClick={(event) => {
                                            event.kirokuSelected = item;
                                            this.handleClick(event);
                                            this.handleSelected(event)
                                        }}>
                                            {Item}
                                        </KirokuDropdownItem>
                                    )
                                })}
                            </section>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
