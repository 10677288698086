import React, {Component} from 'react';
import {KirokuRadio, KirokusSortableHandle,
    KirokuCardWeb, KirokuCardTitleWeb,
    KirokuCardBodyWeb, KirokuLogicApplicated}      from '../../../../../ui';
import ModalAddLogicYesNo from "../../../ModalAddLogicYesNo";
import {withNamespaces}          from "react-i18next";
import { connect }                  from 'react-redux';
import _                            from 'lodash';
import BtnAction                    from './BtnAction';
import {
    focusQuestion, pushQuestionEditInStore,
    removeQuestion,
    showReview,
    turnModalEditQuestionSurveyTemplate
}                                   from '../../../../../actions/SurveyTemplate';
import {checkUseHeadingQuestion}    from "../../../SurveyEditor/SurveyService";
import { setDefaultAnswerQuestion } from '../../../../../actions/Survey';
import ComponentUnrequited          from '../../../ReviewSurvey/ComponentUnrequited';

@withNamespaces()
@connect(null, dispatch => {
    return {
        removeQuestion: (question) => {
            dispatch(removeQuestion(question));
        },

        focusQuestion: (index) => {
            dispatch(focusQuestion(index));
        },
        showReview: () => {
            dispatch(showReview());
        },
        turnModalEditQuestionSurveyTemplate: () => {
            dispatch(turnModalEditQuestionSurveyTemplate())
        },
        pushQuestionEditInStore:(editedQuestion) => {
            dispatch(pushQuestionEditInStore(editedQuestion))
        },
        setDefaultAnswerQuestion: (question) => {
            dispatch(setDefaultAnswerQuestion(question))
        }
    }
})
class YesNo extends Component {
    state = {
        show: true
    };

    showModalAddLogic() {
        this.setState({
            show: !this.state.show
        })
    }

    closeModal() {
        this.setState({
            show: !this.state.show
        })
    }

    removeQuestion = () => {
        let question = this.props.question;
        this.props.removeQuestion(question);
    };

    showModalEdit = () => {
        const {question} = this.props;

        // this.props.showReview();
        // this.props.focusQuestion(question.index);
        //change questions
        let questionCopy = _.cloneDeep(question);
        this.props.turnModalEditQuestionSurveyTemplate();
        this.props.pushQuestionEditInStore(questionCopy);
    };

    handleSetDefaultAnswer(e) {
        const { question } = this.props;
        let newQuestion = { ...question };
        newQuestion = {
            ...newQuestion,
            defaultAnswer: e.target.value
        };
        this.props.setDefaultAnswerQuestion(newQuestion);
    }

    handleResetDefaultAnswer() {
        const { question } = this.props;
        let questionNew    = { ...question };
        if(questionNew.defaultAnswer) {
            delete questionNew.defaultAnswer;
            this.props.setDefaultAnswerQuestion(questionNew);
        }
    }

    render() {
        const {question, t, ReviewCreate} = this.props;
        const contentQuestion = checkUseHeadingQuestion(question);
        return (
            <div id='component-question'>
                <div id='web-q' className='pb-3'>
                    <KirokuCardWeb>
                        <KirokusSortableHandle>
                        <KirokuCardTitleWeb color={'card-web'} style={{paddingBottom:10, paddingTop:10}}>
                            <div className='d-flex justify-content-between'  style={{ flexWrap: 'wrap'}}>
                                    <div className="question-number pt-10px">{t('createSurvey.QuestionOrder', {index : question.index + 1})}</div>
                                <KirokuLogicApplicated padding={'5px 5px 5px 7px'} question={question}/>
                                <BtnAction
                                    resetAnswer={() => {this.handleResetDefaultAnswer()}}
                                    question={question}
                                    showModalAddLogic={this.showModalAddLogic.bind(this)}
                                />
                            </div>
                        </KirokuCardTitleWeb>
                        </KirokusSortableHandle>
                        <KirokuCardBodyWeb>
                            <div>
                                <div hidden={typeof question.nextQuestion === 'number' || typeof question.nextQuestionYes === 'number' || typeof question.nextQuestionNo === 'number'}>
                                    <ComponentUnrequited question={question}
                                                         changeUnrequitedQuestion={(questionIndex) => this.props.changeUnrequitedQuestion(questionIndex)}
                                    />
                                </div>
                                <span hidden={ReviewCreate} className='content-name-question pb-2'>{contentQuestion}</span>
                                <span hidden={!ReviewCreate} className='content-name-question'>{t('createSurvey.Heading')}: {question.heading}</span>
                                <span hidden={!ReviewCreate} className='content-name-question pb-2'>{t('createSurvey.Content')}: {question.content}</span>
                                <p hidden={typeof question.nextQuestion !== 'number'} >
                                    <i className='fas fa-arrow-right ml-1 text-info'>
                                        {t('createRecord.Question')}: {question.nextQuestion + 1}
                                    </i>
                                </p>
                                <div className='answer-question'>
                                    <KirokuRadio onChange={this.handleSetDefaultAnswer.bind(this)}
                                                 id={`yes-${question.index}`}
                                                 name={`questionYesNo${question.index}`}
                                                 checked={question.defaultAnswer === 'Yes'}
                                                 value={'Yes'}
                                    >
                                        {t('createSurvey.Yes')}
                                        <span hidden={typeof question.nextQuestionYes !== 'number'}>
                                        <i className='fas fa-arrow-right ml-1 text-info' >
                                        {t('createRecord.Question')}: {question.nextQuestionYes + 1}</i> </span>
                                    </KirokuRadio>
                                    <KirokuRadio onChange={this.handleSetDefaultAnswer.bind(this)}
                                                 checked={question.defaultAnswer === 'No'}
                                                 id={`no-${question.index}`}
                                                 name={`questionYesNo${question.index}`}
                                                 value={'No'}

                                    >
                                        {t('createSurvey.No')}
                                        <span hidden={typeof question.nextQuestionNo !== 'number'}>
                                        <i className='fas fa-arrow-right ml-1 text-info'>
                                            {t('createRecord.Question')}: {question.nextQuestionNo + 1}</i>
                                    </span>
                                    </KirokuRadio>
                                </div>
                            </div>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                    <ModalAddLogicYesNo show={this.state.show} question={question}
                                        closeModal={this.closeModal.bind(this)}/>
                </div>
            </div>
        );
    }
}

export default YesNo;
