import React, { Component }   from 'react'
import KirokuTable            from "../../../../ui/KirokuTabLe";
import THeader                from "../../../../ui/THeader";
import KirokuCheckBox         from "../../../../ui/KirokuCheckBox";
import TBody                  from "../../../../ui/TBody";
import { Link }               from "react-router-dom";
import { connect }            from "react-redux";
import { loadSurveyListPage } from "../../../../actions/Survey";
import moment                 from "moment";
import KirokuDropdownClose    from "../../../../ui/KirokuDropdownClose";
import config                 from '../../../../config';

@connect((state) => ({
    surveys: state.KirokuAISurvey.surveys
}), (dispatch) => ({
    loadSurveys: () => {
        dispatch(loadSurveyListPage())
    }
}))
class SurveyTemplates extends Component {

    state = {
        surveys: []
    };

    componentWillMount() {
        this.props.loadSurveys()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.surveys.length) {
            this.setState({
                surveys: nextProps.surveys
            })
        }
    }

    bulkAction = [
        {
            value    : 'assign',
            component: (<div>Assign to users</div>),
            label    : 'Assign to users'
        },
        {
            value    : 'delete',
            component: (<div>Delete</div>),
            label    : 'Delete'
        },
    ];

    render() {
        let {surveys} = this.state;
        return (
            <div>
                <div>
                    <p className='m-2'>Filter by:</p>
                    <KirokuDropdownClose
                        title={'LoveRain'}
                        items={[
                            {value: 'item2',
                                component: (<div><h3>Item 2</h3>
                                    <div><span className={'text-muted'}>This is select description</span></div>
                                </div>),
                                label: 'item2'
                            },
                            {value: 'item2',
                                component: (<div><h3>Item 2</h3>
                                    <div><span className={'text-muted'}>This is select description</span></div>
                                </div>),
                                label: 'item2'
                            },
                            {value: {foo: 'bar'},
                                component: (<div><h3>Item 3</h3>
                                    <div><span className={'text-muted'}>This is select description</span></div>
                                </div>),
                                label: 'item3'
                            },
                        ]}
                        onSelect={event => {
                            console.log(event.kirokuSelected)
                        }}
                    />
                </div>
                <KirokuTable>
                    <THeader>
                        <tr>
                            <th style={{width: 50}}>
                                <KirokuCheckBox/>
                            </th>
                            <th>Title</th>
                            <th>Description</th>
                            <th style={{width: 100}}>Creator</th>
                            <th>Date Created</th>
                            <th style={{width: 260}}>Action</th>
                        </tr>
                    </THeader>
                    <TBody>
                    {surveys.map((survey) => {
                        return <tr key={survey.id}>
                            <th><KirokuCheckBox/></th>
                            <td><Link to={`/surveys/survey-preview/${survey.id}`}><p>{survey.title}</p></Link></td>
                            <td>{survey.description}</td>
                            <td>{survey.create_by}</td>
                            <td>{moment.unix(survey['create_at']).format(config.formatDate)}</td>
                            <td>
                                <div className='d-flex justify-content-between p-1 fix-icon-align'>
                                    <Link to="/#">
                                        <i className="fas fa-pencil-alt text-dark"/><span
                                        className='ml-1  text-dark'>Edit</span>
                                    </Link>
                                    <Link to="/#">
                                        <i className="fas fa-clone text-dark"/><span
                                        className='ml-1  text-dark'>Duplicate</span>
                                    </Link>
                                    <Link to="/#">
                                        <i className="fas fa-binoculars  text-dark"/><span
                                        className='ml-1  text-dark'>Preview</span>
                                    </Link>
                                </div>
                            </td>
                        </tr>
                    })}
                    </TBody>
                </KirokuTable>
                <KirokuDropdownClose
                    title={'Bulk Action'}
                    location={'top'}
                    className='width-40'
                    items={this.bulkAction}
                    onSelect={event => {
                        console.log(event.kirokuSelected);
                    }}
                />
            </div>
        )
    }
}

export default SurveyTemplates;
