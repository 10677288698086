import React, {Component}        from 'react';
import {KirokuCheckBox}          from '../../../ui';
import {withNamespaces}          from 'react-i18next';
import {checkUseHeadingQuestion} from "../../Surveys/SurveyEditor/SurveyService";
import KirokuButtonLink          from '../../../ui/KirokuButtonLink';

@withNamespaces()
class MultiChoice extends Component {

    state = {
        answers: [...this.props.value]
    };

    componentDidMount() {
        const { value, question }   = this.props;
        const isAnswers = value.filter(answer => !answer['is_choice']).length !== value.length;
        this.setState({
            answers: [...this.props.value]
        });

        this.props.onAnswer({
            answer   : this.state.answers,
            validated: (question.unrequited || isAnswers) ? value : null,
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.question.answers !== nextProps.question.answers && this.props.question.content !== nextProps.question.content) {
            const {value}   = nextProps;
            const notAnswer = value.filter(answer => !answer['is_choice']).length === value.length;

            this.setState({
                answers: nextProps.value
            });
            nextProps.onAnswer({
                answer   : nextProps.value,
                validated: (nextProps.question.unrequited || !notAnswer) ? value : null,
            })
        }

    }

    handleOnChange(index, e) {
        const {value}    = this.props;
        const newAnswer  = [...this.state.answers];
        newAnswer[index] = {...newAnswer[index], is_choice: e.currentTarget.checked};
        this.setState({answers: newAnswer});
        const notAnswer = newAnswer.filter(answer => answer['is_choice'] === false).length === value.length;
        //this is best code I fix when I'm master by bachRuoi  answer['is_choice'] === false => !answer['is_choice']
        this.props.onAnswer({
            answer   : newAnswer,
            validated: notAnswer ? null : newAnswer
        })
    }

    handleResetAnswer() {
        let newAnswer  = [...this.state.answers];
        newAnswer = newAnswer.map((answer) => {
            return {...answer, is_choice: false}
        });
        this.setState({answers: newAnswer});
        this.props.onAnswer({
            answer   : newAnswer,
            validated: newAnswer,
        });
    }

    render() {
        const {
            question, t,
            // index
        } = this.props;
        const {answers}            = this.state;
        const contentQuestion = checkUseHeadingQuestion(question);
        return (
            <div id='component-question'>
                <div className='content-question'>
                    <div className='wraper-question'>
                        <div className="background-question">
                            <div className='number-question d-flex justify-content-between'>
                                <span className="question-number">
                                    {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                                </span>
                                <div/>
                                <div>
                                    <KirokuButtonLink
                                        className='padding-reset-button-link'
                                        onClick={() => {this.handleResetAnswer()}}>
                                        {t('createRecord.resetAnswer')}
                                    </KirokuButtonLink>
                                </div>
                            </div>

                        </div>
                        <div className={`answer-question`}>
                            <span className='content-name-question padding-question-content'>
                                {question.content}
                            </span>
                            <div className="force-overflow" >
                                {answers.map((item, index) =>
                                    <KirokuCheckBox key={index}
                                                    checked={item.is_choice}
                                                    id={`${question.index}-${index}`}
                                                    content={item.answer}
                                                    onChange={this.handleOnChange.bind(this, index)}
                                    />
                                )}
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default MultiChoice;
