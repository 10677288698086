import React, {Component} from "react";
import {withNamespaces}   from "react-i18next";
import connect            from "react-redux/es/connect/connect";
import {currentPage}      from "../../../actions/CurrentPage";
import KirokuTitlepage    from "../../../ui/KirokuTitlepage";
import {
    loadDashboard,
    comparisonPreviousTerm
}                         from "../../../actions/Dashboard";
import {KirokuLoading}    from "../../../ui";
import TaggedCustomers    from "../TaggedCustomers";
import PitOutRecord       from "./PitOutRecord";
import '../dashboard.css';
import LogsDashboard      from "./LogsDashboard";
import {authService}      from "../../../services";


@withNamespaces()
@connect(state => {
    return {
        dashboard: state.KirokuAIDashboard.dashboard,
        loadingDashboardSuccess: state.KirokuAIDashboard.loadingDashboardSuccess,
    }
}, dispatch => {
    return {
        loadDashboard: () => {
            dispatch(loadDashboard())
        },
        currentPage: (name) => {
            dispatch(currentPage(name))
        },
        comparisonPreviousTerm: () => {
            dispatch(comparisonPreviousTerm())
        },
        customerPin: () => {
            dispatch({
                type: "GET_PIN_CUSTOMER"
            })
        }
    }
})


class TotalWeb extends Component {

    page = {
        title: <KirokuTitlepage title={this.props.t('Dashboard.Home')}/>,
        actionPage: null
    };

    componentWillMount() {
        this.props.customerPin();
        this.props.loadDashboard();
        this.props.currentPage(this.page);
    }

    render() {
        const {customerForPin} = this.props;
        return (
            <div className='padding-responsive'>
                <div>
                    <div className={'row'}>
                        <div className={'col-3'} hidden={authService.isAdmin()}>
                            <TaggedCustomers customers={customerForPin}/>
                        </div>
                        {this.props.loadingDashboardSuccess ?
                            <div className={authService.isAdmin() ? 'col-12' : 'col-9 pl-0'}>
                                <PitOutRecord/>
                                <LogsDashboard dashboard={this.props.dashboard} history={this.props.history}/>
                            </div> :
                            <div className='fix-center col-12'>
                                <KirokuLoading/>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

}

export default TotalWeb;
