import React, { Component }                     from 'react';
import {
    KirokuLoading,
    KirokuUploadFile,
    KirokuCardWeb,
    KirokuCardTitleWeb,
    KirokuCardBodyWeb, KirokuButton,
    KirokuButtonLink
}                                               from '../../../../ui';
import { uploadService }                        from '../../../../services';
import { withNamespaces }                       from 'react-i18next';
import PropTypes                                from 'prop-types';
import { conditionUpload }                      from '../../../../services/FitlerData';
import ModalNotificationValidateUpload          from './ModalNotificationValidateUpload';
import { connect }                              from 'react-redux';
import { turnOnNotificationValidatedMediaFile } from '../../../../actions/TakeSurvey';
import { checkUseHeadingQuestion }              from '../../../Surveys/SurveyEditor/SurveyService';
import { withSmoothScrolling }                  from '../../../../ui/Scrolling.js';

@connect(null, dispatch => {
    return {
        turnOnNotificationValidatedMediaFile: () => {
            dispatch(turnOnNotificationValidatedMediaFile())
        }
    }
})

@withSmoothScrolling
@withNamespaces()
class MediaFileQuestion extends Component {

    state = {
        imageUrl    : this.props.value.answer ? this.props.value.answer : null,
        typeAnswer  : this.props.value.typeAnswer ? this.props.value.typeAnswer : null,
        loadingImage: false,
        validated   : false,
    };

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value.answer !== nextProps.value.answer || this.props.question.index !== nextProps.question.index) {
            this.setState({
                imageUrl  : nextProps.value.answer ? nextProps.value.answer : null,
                typeAnswer: nextProps.value.typeAnswer ? nextProps.value.typeAnswer : null
            });
        }
    }


    handleChangePhoto(e) {
        if (e.currentTarget.files[0]) {
            if (conditionUpload(e.currentTarget.files[0])) {

                this.setState({
                    typeAnswer  : e.currentTarget.files[0].type,
                    loadingImage: true
                });
                let formData = new FormData();
                formData.append('file', e.currentTarget.files[0]);
                formData.append('name', e.currentTarget.files[0].name);

                uploadService.uploadImage(formData, 'avatar').then(res => {
                    this.setState({
                        validated   : res.url.length !== 0,
                        imageUrl    : res.url,
                        loadingImage: false,
                    });

                    this.props.onAnswer({
                        answer   : {answer: res.url, typeAnswer: this.state.typeAnswer},
                        validated: res.Url ? res.Url : null,
                        questionIndex: this.props.question.index
                    })
                });
                this.props.isCurrentQuestion(this.props.question.index);
            } else {
                this.props.turnOnNotificationValidatedMediaFile();
            }
        }
    }

    moveToNextQuestion() {
        const {question} = this.props;
        let nextToQuestion;
        nextToQuestion = typeof question.nextQuestion === 'number' ? question.nextQuestion - 1 : question.index;
        this.props.smoothScroll.scrollTo(`question-${nextToQuestion}`);
        this.props.isCurrentQuestion(nextToQuestion + 1);

    }

    handleResetAnswer() {
        this.setState({
            validated   : false,
            imageUrl    : '',
        });

        this.props.onAnswer({
            answer   : {},
            validated: null,
            questionIndex: this.props.question.index
        });

        this.props.isCurrentQuestion(this.props.question.index);

    }

    render() {
        const {question, disabled,
                  t, isEndQuestion, currentQuestion} = this.props;
        const {loadingImage, imageUrl}       = this.state;
        const contentQuestion = checkUseHeadingQuestion(question);

        return (
            <div id='component-question'>

                <ModalNotificationValidateUpload/>

                <div id='web-q' className='pb-3'>
                    <KirokuCardWeb style={{ borderColor: disabled ? '#dfe2e5' : imageUrl ? '#d6e9c6' : (!question.unrequited ? '#ebccd1' : '#dfe2e5')}}>
                        <KirokuCardTitleWeb color={disabled ? 'disable-question' :
                            (imageUrl ? 'question-has-answer d-flex justify-content-between':
                                (!question.unrequited ? 'question-required-answer d-flex justify-content-between'
                                : 'card-web d-flex justify-content-between'))}
                                            style={{ paddingBottom: 15, alignItems : 'center'}}
                        >
                            <div className={`number-question text-overflow-elip ${disabled ? 'question-number' : imageUrl ? 'font-text-question-has-answer' 
                                : (!question.unrequited ? 'text-question-required-answer' : 'question-number') }`}>
                                {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                            </div>
                            <div/>
                            <div hidden={disabled}>
                                <KirokuButtonLink
                                    className='padding-reset-button-link'
                                    onClick={() => {this.handleResetAnswer()}}
                                >
                                    {t('createRecord.resetAnswer')}
                                </KirokuButtonLink>
                            </div>
                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb>
                            <div className={disabled ? 'wrap-overlay-action cursor-disable' : ''}>
                                <span className='content-name-question'>{question.content}</span>

                                <div className='answer-question'>
                                    {loadingImage ? <KirokuLoading/>
                                                             : <KirokuUploadFile disabled={disabled}
                                                                                 url={imageUrl}
                                                                                 onChange={this.handleChangePhoto.bind(this)}/>
                                    }

                                </div>
                                <div hidden={ disabled || isEndQuestion || question.index !== currentQuestion}
                                     style={{ textAlign : 'center' }}>
                                    <KirokuButton onClick={this.moveToNextQuestion.bind(this)} className='btn-kiroku-primary'>
                                        {this.props.t('common.nextToQuestion')}
                                    </KirokuButton>
                                </div>
                            </div>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                </div>
            </div>
        );
    }
}

MediaFileQuestion.propTypes = {
    value: PropTypes.object
};
export default MediaFileQuestion;
