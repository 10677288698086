import React, {Component}    from 'react';
import {Link}                from 'react-router-dom';
import {connect}             from 'react-redux';
import {withNamespaces}      from 'react-i18next'
import {
    KirokuActionPage,
    KirokuCardWeb,
    KirokuCheckBox,
    KirokuLoading,
    KirokuSectionDropdownShowMore,
    KirokuTable,
    KirokuTitlepage,
    TBody,
    THeader
} from '../../../ui';
import {currentPage}         from '../../../actions/CurrentPage';
import {loadUserPagination}  from '../../../actions/User';
import BulkAction            from './BulkAction';
import KirokuInputSearch     from "../../../ui/KirokuInputSearch";
import debounce              from "lodash/debounce";
import ModalFilterStaff      from "./Mobile/ModalFilterStaff";
import KirokuButton          from "../../../ui/KirokuButton";
import KirokuLabel           from "../../../ui/KirokuLabel";
import {currentCondition}    from "../../../actions/Behavior";
import {scrollService}       from "../../../services";
import withPermissions       from "../../HOC/WithPermissions";
import TdComponent           from "../../HOC/TdComponent";
import ThComponent           from "../../HOC/ThComponent";
import LinkComponent         from "../../HOC/LinkComponent";
import TagDivComponent       from "../../HOC/TagDivComponent";

const ActionPage    = withPermissions(KirokuActionPage);
const Td            = withPermissions(TdComponent);
const Th            = withPermissions(ThComponent);
const LinkKiroku    = withPermissions(LinkComponent);
const DivComponent  = withPermissions(TagDivComponent);
@connect(state => {
    return {
        usersForPagination: state.KirokuAIUser.usersForPagination,
        totalPage         : state.KirokuAIUser.totalPage,
        currentPage       : state.KirokuAIUser.currentPage,
        isLoading         : state.KirokuAIUser.isLoading,
        profile           : state.KirokuAIUser.profile,
        loading           : state.Loading,
        defaultCustomerTags: state.KirokuAIUser.defaultCustomerTags,
        defaultTeamTags   : state.KirokuAIUser.defaultTeamTags,
        currentConditions : state.KirokuAIBehavior.conditions,
    }
}, (dispatch) => {
    return {
        pageNow: (name) => {
            dispatch(currentPage(name))
        },

        loadUserPagination: (defaultPage, tags) => {
            dispatch(loadUserPagination(defaultPage, tags))
        },

        currentCondition: (cdt) => {
            dispatch(currentCondition(cdt))
        },

        saveConditions: conditions => {
            dispatch({
                type: 'CURRENT_CONDITION_SAVE',
                conditions
            })
        },

        updateConditionSuccess: () => dispatch({
            type: 'CURRENT_CONDITION_SUCCESS'
        }),

        filterCondition: (filterCondition) => dispatch({
            type: 'FILTER_CONDITION',
            filterCondition: filterCondition
        }),
    };
})
@withNamespaces()

class StaffListWeb extends Component {
    page = {
        title: <KirokuTitlepage title={this.props.t('common.Staffs')}/>,
        actionPage:
            <Link onClick={this.props.resetStateSurvey}
                  to={'/staffs/staff-creator'}>
                <ActionPage
                    resource-permission={'|staffList|button-new|'}
                >
                    {this.props.t('common.AddStaff')}
                </ActionPage>
            </Link>
    };

    state = {
        show: false,
        showModalDelete: false,
        showModal: true,
        showSurveyTemplate: false,
        name: '',
        status: '',
        teamid: '',
        page: 1,
        pageMin: 1,
        userSelected: {},
        params: {
            page: 1,
        },
        namePage: this.page,
        selected: false,
        usersForPagination: [],
        showModalDeleteUser: false,
        showModalFilter: true,
        conditions: [],
        keywordSearch: {
            value: '',
            query: 'name',
            label: ''
        },
        groupedCustomerOptions : [],
        groupedTeamOptions     : [],
        defaultTags        : [],
        currentCustomerTags: [],
        currentTeamTags    : [],
    };

    componentWillMount() {
        let defaultTags = [];

        let groupCustomerOptions = [
            {
                label  : 'Customers',
                options: this.props.customers
            }
        ];

        let groupTeamOptions = [
            {
                label  : 'Teams',
                options: this.props.teams
            }
        ];
        if (this.props.currentConditions.length) {
            let currentTags =  this.state.currentCustomerTags.concat(this.state.currentTeamTags);
            scrollService.top();
            this.setState({
                currentCustomerTags : this.props.defaultCustomerTags ? this.props.defaultCustomerTags : [],
                currentTeamTags : this.props.defaultTeamTags ? this.props.defaultTeamTags : [],
                groupedCustomerOptions: groupCustomerOptions,
                groupedTeamOptions : groupTeamOptions
            }, () => {
                this.props.currentCondition(this.props.currentConditions, {tags: currentTags});
                this.makeCondition(this.props.currentConditions);
                this.props.pageNow(this.state.namePage);
            });

        } else {
            scrollService.top();
            this.setState({
                currentCustomerTags : this.props.defaultCustomerTags ? this.props.defaultCustomerTags : (this.props.profile.role === 'admin' ? [] : defaultTags ),
                currentTeamTags : this.props.defaultTeamTags ? this.props.defaultTeamTags : (this.props.profile.role === 'admin' ? [] : defaultTags ),
                groupedCustomerOptions: groupCustomerOptions,
                groupedTeamOptions : groupTeamOptions
            }, () => {
                this.handleReloadPage();
                this.props.pageNow(this.state.namePage);
            });
        }
    }

    onChangeShowDropDown() {
        this.setState({
            show: !this.state.show
        })
    }

    filter() {
        let currentTags =  this.state.currentCustomerTags.concat(this.state.currentTeamTags);
        this.props.loadUserPagination(this.state.params, {tags : currentTags});
    }

    handleShowModal() {
        this.setState({
            showModalFilter: true,
            showModal: !this.state.showModal,
            showSurveyTemplate: !this.state.showSurveyTemplate
        })
    }

    closeModal() {
        this.setState({
            showModal: !this.state.showModal,
            showSurveyTemplate: !this.state.showSurveyTemplate
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.usersForPagination) {
            if (!nextProps.currentConditions.length) {
                this.setState({
                    params: {
                        page: 1
                    }
                })
            }
            this.setState({
                usersForPagination: nextProps.usersForPagination,
                selected          : false,
                conditions        : nextProps.currentConditions,
            })
        }
        if((nextProps.defaultCustomerTags !== this.props.defaultCustomerTags) || (nextProps.defaultTeamTags !== this.props.defaultTeamTags)) {
            this.setState({
                currentCustomerTags : nextProps.defaultCustomerTags,
                currentTeamTags : nextProps.defaultTeamTags
            })
        }
    }

    handleCheckAll() {
        const {usersForPagination} = this.state;
        let newUsersRecord         = usersForPagination.map(user => ({
            ...user,
            isChecked: !this.state.selected
        }));
        this.setState({
            usersForPagination: newUsersRecord,
            selected: !this.state.selected
        })
    }

    handleCheckUser(index) {
        let count                  = 0;
        const {usersForPagination} = this.state;
        usersForPagination[index]  = {
            ...usersForPagination[index],
            isChecked: !usersForPagination[index].isChecked
        };

        this.state.usersForPagination.forEach(result => {
            if (result.isChecked) {
                count++;
            }
        });
        this.setState({
            usersForPagination: usersForPagination,
            selected: count === usersForPagination.length
        });
    }

    handleSearchTags = () => {
        let currentTags = this.state.currentCustomerTags.concat(this.state.currentTeamTags);
        if (currentTags.length) {
            let conditionTags = currentTags.map(tag => {
                return {
                    ...tag,
                    key: tag.id,
                    label: tag.name,
                    value: `${tag.name}${tag.id}`,
                    tagId: tag.id || tag.key,
                    tagType: tag.tagType
                }
            });
            this.props.loadUserPagination({...this.state.params, page: 1}, {tags: conditionTags});
        } else {
            this.setState({params: {...this.state.params, page: 1}}, () => {
                this.filter();
            });
        }
        this.props.saveConditions([...this.props.currentConditions, {
            value: 1,
            query: 'page',
            label: ''
        }]);
    };

    disabledCheckbox() {
        this.setState({
            selected: false
        })
    }

    handleDeleteUser = (user) => {
        this.setState({
            showModalDeleteUser: !this.state.showModalDeleteUser,
            userSelected: {
                name: user.name,
                id: user.id
            }
        })
    };

    closeModalDel() {
        this.setState({
            showModalDeleteUser: false
        })
    }

    onChangeDebounce = debounce(value => {
        this.handleSearch(value)
    }, 500);

    showModalFilter() {
        this.setState({
            showModalFilter: !this.state.showModalFilter
        })
    }

    handleReloadPage(){
        let {...currentState} = this.state;
        let currentTags = this.state.currentCustomerTags.concat(this.state.currentTeamTags);
        this.props.loadUserPagination(currentState, {tags : currentTags})
    }

    handleChangeCustomerTags = (options) => {
        this.setState({currentCustomerTags: options ? options : []})
    };

    handleChangeTeamTags = (options) => {
        this.setState({currentTeamTags: options ? options : []})
    };

    handleSearch(value) {
        let keywordCondition = {
            value: value,
            query: 'name',
            label: ''
        };
        this.setState({
            keywordSearch: keywordCondition,
            params: {
                ...this.state.params,
                name: value,
                page: 1
            }
        });
        this.props.saveConditions([...this.props.currentConditions, keywordCondition]);
        this.filter()
    }

    makeCondition(conditions) {
        this.setState({
            conditions
        });
        let params = this.state.params;
        conditions.map(condition => {
            params[condition.query] = condition.value;
            return condition
        });
        this.setState({
            params: params,

        }, () => {

            this.filter();
        });
    }

    handleSelectPage(pageNumber) {
        let page = {
            value: pageNumber,
            query: 'page',
            label: ''
        };
        this.props.saveConditions([...this.props.currentConditions, page]);
        this.setState({params: {...this.state.params, page: pageNumber}}, () => {
            this.filter()
        });

    }

    filterCondition(conditions) {
        this.setState({
            params : {
                page : 1
            }
        }, () => {
            this.makeCondition(conditions);
            this.props.saveConditions([...conditions, this.state.keywordSearch])
        });
    }

    handleRemoveCondition(condition) {
        let newConditions = this.state.conditions.map(item => {
            if (condition.query === item.query) {
                return {
                    ...item,
                    value: '',
                    label: '',
                }
            }
            return item;
        });
        let params        = {page : 1};
        this.setState({
            conditions: newConditions,
            params: {
                ...this.state.params,
                ...params,
                [condition.query]: '',
            },
            page: 1
        }, () => {
            this.filter();
            this.props.currentCondition(newConditions)
        });
    }

    render() {
        let {show, showModalDelete, showModal, showSurveyTemplate, ...currentState} = this.state;
        let {usersForPagination, conditions, currentCustomerTags, currentTeamTags}  = this.state;
        let currentTags =  currentCustomerTags.concat(currentTeamTags);
        const {t}                                                                   = this.props;
        return (
            <div className='survey-list default-background-color'>
                <ModalFilterStaff
                    //currentFilterCondition={this.currentFilterCondition.bind(this)}
                    show={this.state.showModalFilter}
                    showModal={this.handleShowModal.bind(this)}
                    filterCondition={this.filterCondition.bind(this)}
                />
                <div hidden={!this.state.showModalFilter} className='padding-responsive'>
                    <div hidden={!usersForPagination.length}>
                        <BulkAction
                            currentTags={currentTags}
                            params={this.state.params}
                            className={'pb-3'}
                            closeModalDel={this.closeModalDel.bind(this)}
                            showModalDeleteUser={this.state.showModalDeleteUser}
                            userSelected={this.state.userSelected}
                            closeModal={this.onChangeShowDropDown.bind(this)}
                            handleSelectPage={this.handleSelectPage.bind(this)}
                            totalPage={this.props.totalPage}
                            currentPage={this.props.currentPage}
                            usersChoice={this.state.usersForPagination}
                            currentState={currentState}
                            disabledCheckbox={this.disabledCheckbox.bind(this)}
                            show={this.state.show}
                        />
                    </div>
                    <div className={'pb-3'}>
                        <KirokuSectionDropdownShowMore
                            isSearchTags
                            tagType={'Team'}
                            onChange={this.handleChangeTeamTags.bind(this)}
                            value={this.state.currentTeamTags || []}
                            options={this.state.groupedTeamOptions}
                            handleSearchTags={this.handleSearchTags.bind(this)}
                            placeholder={t('Team.placeholderSearch')}
                        />
                    </div>
                    <div className={'pb-3'}>
                        <KirokuSectionDropdownShowMore
                            isSearchTags
                            tagType={'Customer'}
                            onChange={this.handleChangeCustomerTags.bind(this)}
                            value={this.state.currentCustomerTags || []}
                            options={this.state.groupedCustomerOptions}
                            handleSearchTags={this.handleSearchTags.bind(this)}
                            placeholder={t('Customer.placeholderSearch')}
                        />
                    </div>

                    <KirokuCardWeb>
                        <div>
                            <div className='d-flex justify-content-between p-3'>
                                <KirokuButton color={'white'} onClick={this.showModalFilter.bind(this)}>
                                    <span className={'pr-2'}>{t('Surveys.FilterBy')}</span>
                                    <i className='fa fa-chevron-down text-secondary'
                                       style={{fontSize: '10px'}}/>
                                </KirokuButton>
                                <div className='pl-1' style={{minWidth: 100}}>
                                    <KirokuInputSearch
                                        value={this.state.params.name ? this.state.params.name : ''}
                                        onChange={e =>
                                            this.setState({
                                                params: {
                                                    ...this.state.params,
                                                    name: e.target.value,
                                                    page: 1
                                                }
                                            }, () => {
                                                this.onChangeDebounce(this.state.params.name)
                                            })}
                                        placeholder={t('common.Search')}/>
                                </div>
                            </div>
                        </div>
                        <div className='pl-3'>
                            {
                                conditions.map((condition, index) => {

                                    return condition.label.length ? <span key={index} className='mr-6'>
                                            <KirokuLabel
                                                style={{
                                                    border: 'solid 1px #1991eb'
                                                }}
                                                onClick={this.handleRemoveCondition.bind(this, condition)}
                                                hidden={!condition.label.length}
                                                color={'default'}>
                                                <span className='mt-1'>{condition.label}</span>
                                                <span className='align-icon'>&times;</span>
                                            </KirokuLabel>
                                    </span> : ''
                                })
                            }
                        </div>

                        <div className='pt-2'>
                            {!this.props.loading ?
                                <KirokuTable>
                                    <THeader>
                                        <tr>
                                            <Th style={{width: 50}}
                                                resource-permission={'|staffList|button-delete|'}
                                            >
                                                <KirokuCheckBox onChange={this.handleCheckAll.bind(this)}
                                                                checked={this.state.selected || false}/>
                                            </Th>
                                            <th style={{minWidth: 180}}>{t('common.Name')}</th>
                                            <th style={{width: 100}}>{t('common.Title')}</th>
                                            <th style={{width: 90}}>{t('common.Role')}</th>
                                            <th>{t('common.TeamName')}</th>
                                            <th style={{width: 100}}>{t('common.WorkingForm')}</th>
                                            <Th resource-permission={'|staffList|button-delete|'} style={{width: 300}}
                                                className='text-center'/>
                                        </tr>
                                    </THeader>
                                    <TBody>
                                    {usersForPagination.map((user, index) => {
                                        let teamName        = user.teams.map((team) => {
                                            return team.name
                                        });
                                        let currentTeamName = teamName.join(', ');
                                        return <tr key={user.id}>
                                            <Td resource-permission={'|staffList|button-delete|'}>
                                                <KirokuCheckBox
                                                    onChange={this.handleCheckUser.bind(this, index)}
                                                    checked={user.isChecked}
                                                />
                                            </Td>
                                            <td><Link to={`/staffs/staff/${user.id}`}>
                                                <span>{user.name}</span>
                                            </Link></td>
                                            <td>{user.title}</td>
                                            <td>{t(`common.${user.role}`)}</td>
                                            <td>{currentTeamName}
                                            </td>
                                            <td>{t(`common.${user['working_form']}`)}</td>
                                            <Td resource-permission={'|staffList|button-edit|'}>
                                                <div className='d-flex justify-content-between p-1 fix-icon-align'>
                                                    <LinkKiroku
                                                        resource-permission={'|staffList|button-edit|'}
                                                        to={`/staffs/staff-editor/${user.id}`}
                                                        text={<span className='ml-1  text-dark'>{t('common.Edit')}</span>}
                                                        icon={<i className="fas fa-pencil-alt text-secondary"/>}
                                                    />
                                                    <LinkKiroku
                                                        resource-permission={'|staffList|button-duplicate|'}
                                                        href={`/staffs/staff-duplicate/${user.id}`}
                                                        text={<span className='ml-1  text-dark'>{t('staff.duplicateStaff')}</span>}
                                                        icon={<i className="fas fa-clone text-secondary"/>}
                                                    />
                                                    <DivComponent resource-permission={'|staffList|button-delete|'}>
                                                        <div style={{cursor: 'pointer'}} onClick={() => this.handleDeleteUser(user)}>
                                                            <i className="far fa-trash-alt text-secondary"/>
                                                            <span className='ml-1  text-dark'>{t('common.Delete')}</span>
                                                        </div>
                                                    </DivComponent>
                                                </div>
                                            </Td>
                                        </tr>
                                    })}
                                    </TBody>
                                </KirokuTable> : <KirokuLoading/>
                            }

                        </div>
                    </KirokuCardWeb>
                    <div className='pt-3'>
                        <BulkAction
                            location={'top'}
                            currentTags={currentTags}
                            params={this.state.params}
                            closeModalDel={this.closeModalDel.bind(this)}
                            showModalDeleteUser={this.state.showModalDeleteUser}
                            userSelected={this.state.userSelected}
                            closeModal={this.onChangeShowDropDown.bind(this)}
                            handleSelectPage={this.handleSelectPage.bind(this)}
                            totalPage={this.props.totalPage}
                            currentPage={this.props.currentPage}
                            usersChoice={this.state.usersForPagination}
                            currentState={currentState}
                            disabledCheckbox={this.disabledCheckbox.bind(this)}
                            show={this.state.show}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default StaffListWeb;
