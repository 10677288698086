import React, { Component } from 'react';
import { KirokuButton, KirokuComment,
    CommentInputTextarea }  from '../../../../ui';
import { connect }          from 'react-redux';
import { withNamespaces }   from 'react-i18next';
import { scrollService }    from '../../../../services';

@withNamespaces()
@connect(state => {
    return {
        profile       : state.KirokuAIUser.profile,
        comments      : state.KirokuAIRecord.comments,
        contentComment: state.RecordsBeta.contentComment,
        isLoadingComment: state.RecordsBeta.isLoadingComment,

    };
}, dispatch => {
    return {
        handleChangeComment: (contentComment) => {
            dispatch({
                type: 'HANDLE_CHANGE_TEXT_COMMENT',
                contentComment,
            });
        },
        handleSubmitComment: (body) => {
            dispatch({
                type: 'HANDLE_SUBMIT_COMMENT_RECORD',
                body,
            });
        },
        loadingComment: () => {
            dispatch({
                type: 'LOADING_COMMENT_RECORD',
            });
        }
    };
})

class CommentTab extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.comments.length) {
            this.scrollToBottom();
        }
    }

    handleSubmitComment() {
        const { contentComment, record, comments } = this.props;
        this.props.loadingComment();
        delete record.customer.certificate;
        this.props.handleSubmitComment({ contentComment, record_id: record.id, title : record.survey_template.title, customer: record.customer });
        if(comments.length > 3) {
            this.scrollToBottom();
        }
    }

    scrollToBottom = () => {
        if (this.props.comments.length > 3 ) {
            this.messagesEnd.scrollIntoView({ behavior: "smooth" });
        }
    };

    componentDidMount() {
        this.scrollToBottom();
    }

    handleOnBlurInputTextarea() {
        if(this.props.comments.length <= 3) {
            scrollService.top();
        }
    }

    render() {
        const { show, contentComment, comments, t, isLoadingComment
        } = this.props;
        return (
            <div className='pt-3' hidden={ !show }>
                <div className='card pt-3 pb-3'>
                    <div className=''>
                        {comments.map((comment, index) => {
                            return (
                                <div key={ index }>
                                    <div className={ 'pl-3 pr-3' }>
                                        <KirokuComment
                                            className={'font-text-card-detail'}
                                            comment={comment}
                                        />
                                    </div>
                                    <hr hidden={ comments.length - 1 === index }/>
                                </div>
                            );
                        })}
                        <div style={{ float:"left", clear: "both" }}
                             ref={(el) => { this.messagesEnd = el; }}>
                        </div>
                        <div hidden={!isLoadingComment} className={'text-center'}>
                            <div className="lds-ellipsis-loading" >
                                <div/>
                                <div/>
                                <div/>
                                <div/>
                            </div>
                        </div>
                        <div className="d-flex pl-2 pr-2 align-items-center form-comment">
                            <div className="input-form-comment">
                                <CommentInputTextarea
                                    onBlur={this.handleOnBlurInputTextarea.bind(this)}
                                    onChange={ (e) => this.props.handleChangeComment(e.target.value) }
                                    contentComment={ contentComment }
                                    style={{minHeight: 43}}
                                />
                            </div>

                            <div style={ { float: 'right' } } className={'pl-3'}>
                                <KirokuButton disabled={ !contentComment }
                                              color={ 'primary' }
                                              onClick={ this.handleSubmitComment.bind(this) }
                                >
                                    <span className='font-text-card-btn'>{ t('RecordsBeta.submitComment') }</span>
                                </KirokuButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CommentTab;
