import React, { Component }                                     from 'react';
import Chart                                                    from 'react-google-charts';
import { buildDataNumberQuestion } from '../buildData';
import { withNamespaces }                                       from 'react-i18next';

@withNamespaces()

class NumberChart extends Component {
    render() {
        const { records, question, t }  = this.props;
        const dataAnalysis = buildDataNumberQuestion(records, question);
        return (

            <div id="chart">
                <Chart
                    height={'400px'}
                    chartType="ScatterChart"
                    loader={<div>Loading Chart</div>}
                    data={[
                        ['row label', t('Analysis.valueNumberAnswer')],
                        ...dataAnalysis.data
                    ]}
                    options={{
                        title: '',
                        hAxis: {
                            title: t('Analysis.servicedAt'),
                            ticks: [
                                {v: 0, f: ''},
                                ...dataAnalysis.ticks,
                                {v: dataAnalysis.maxColumn + 1, f: ''},
                            ]
                        },
                        vAxis: { title: t('Analysis.valueNumberAnswer')},
                        legend: 'none',
                    }}
                    />
            </div>

        );
    }
}

export default NumberChart;
