import React, {Component}                           from 'react';
import {createSurvey, clickEditReviewToAddQuestion} from "../../../../actions/SurveyMobile";
import KirokuButton                                 from "../../../../ui/KirokuButton";
import {connect}                                    from "react-redux";
import ReviewSurvey                                 from "./ReviewSurvey";
import {withNamespaces}                             from "react-i18next";
import { changeUnRequited }                         from '../../../../actions/SurveyTemplate';
import arrayMove                                    from 'array-move';
import { handleDragQuestion }                       from '../../../../actions/Survey';

@withNamespaces()
@connect(state => {
    return {
        survey: state.KirokuSurveyTemplateMobile
    }
}, dispatch => {
    return {
        createSurvey: () => {
            dispatch(createSurvey())
        },

        backToEditQuestion : (question) => {
            dispatch(clickEditReviewToAddQuestion(question))
        },
        handleDragQuestion                 : (questions, currentIndex, nextIndex) => handleDragQuestion(dispatch)(questions, currentIndex, nextIndex),
        changeUnrequitedQuestion: (questionIndex) => changeUnRequited(dispatch)(questionIndex),
    }
})
class Index extends Component {

    state = {
        showBehavior: true
    };

    backToQuestion = () => {
        this.props.history.goBack();
    };

    createSurvey = () => {
        this.props.createSurvey();
        this.props.history.push('/surveys');
    };

    backToEditQuestion = (question) => {
        this.props.backToEditQuestion(question);
        this.props.history.push('/surveys/survey');
    };

    onDragEnd(event) {
        const { survey } = this.props;
        const indexQuestionFor = event.source.index;
        const indexQuestionTo = event.destination.index;
        let newItems = [...survey.questions];
        this.props.handleDragQuestion(arrayMove(newItems,indexQuestionFor, indexQuestionTo), indexQuestionFor, indexQuestionTo);
    }

    render() {
        const {t} = this.props;
        const survey = this.props.survey;
        return (
            <>
                <div className='create-header'>
                    <div className='d-flex justify-content-between padding-responsive'>
                        <h4>{t('createSurvey.ReviewQuestions')}</h4>
                        <KirokuButton color='primary'
                                      disabled={survey.questions.length === 0}
                                      onClick={this.createSurvey.bind(this)}>
                            {t('createSurvey.Submit')}
                        </KirokuButton>
                    </div>
                </div>
                <div style={{paddingBottom: 60}}>
                    <ReviewSurvey survey={survey}
                                  showBehavior={this.state.showBehavior}
                                  backToEditQuestion={this.backToEditQuestion.bind(this)}
                                  changeUnrequitedQuestion={(questionIndex) => this.props.changeUnrequitedQuestion(questionIndex)}
                                  onDragEnd={this.onDragEnd.bind(this)}
                    />
                </div>
                <div className='action-record' style={{zIndex: '100'}}>
                    <div className="row">
                        <div className='col-6 p-0' style={{height: 46}}>
                            <KirokuButton style={{width: '100%', borderRadius: 0, padding: '10px 0'}}
                                          color={'light'}
                                          onClick={this.backToQuestion.bind(this)}
                            >
                                {t('createSurvey.Back')}
                            </KirokuButton>
                        </div>
                        <div className='col-6 p-0'>
                            <KirokuButton
                                color={'primary'}
                                style={{width: '100%', borderRadius: 0, padding: '10px 0'}}
                                disabled={survey.questions.length === 0}
                                onClick={this.createSurvey.bind(this)}
                            >
                                {t('createSurvey.Submit')}
                            </KirokuButton>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Index;
