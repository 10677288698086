import { GET_STAFFS_BETA } from '../../actions/User';
import _                   from 'lodash';

const defaultModalSelectStaffs = {
    isOpenModalSelectStaffs: false,
    //open modal select
    staffs                 : [],
    staffsSelected         : [],
    isOpenStaffSelected    : false,
    keywords               : '',
    staffList              : [],
    teamSelected           : [],
    staffListSelected      : [],
    keywordSelected        : '',
    searchHistory          : {
        staffs             : [],
        staffsSelected     : [],
        isOpenStaffSelected: false,
        keywords           : '',
        staffList          : [],
        teamSelected       : [],
        staffListSelected  : [],
        keywordSelected    : '',
    },
};

const staffsInTeam = (teamSelected, staffList) => {
    if (!teamSelected.length) {
        return staffList.map(staff => ({ ...staff, selected: false }));
    }

    let listStaffsInTeam = [];
    teamSelected.forEach(team => {
        listStaffsInTeam = _.concat(listStaffsInTeam, team.users);
    });
    return _.uniqBy([...listStaffsInTeam], 'id').map(item => ({ ...item, selected: false }));
};

const filterStaffUseKeyword = (staffName, keywords) => {
    return staffName.toUpperCase().search(keywords.toUpperCase()) !== -1;
};


function setSelectedForStaff(staffs, isSelected) {
    return staffs.map(staff => ({ ...staff, selected: isSelected }));
}

const getSelectedStaff = (staffSelected, staffList) => {
    return staffList.map(staff => ({
        ...staff,
        selected: _.findIndex(staffSelected, ['id', staff.id]) !== -1,
    }));
};

const ModalSelectStaffs = (state = defaultModalSelectStaffs, action) => {
    switch (action.type) {
        case 'GET_CUSTOMERS_BY_USER_AND_CUSTOMERS_BETA_INIT':
            return {
                ...state,
                staffList        : state.staffList.map(item => ({...item, selected: false})),
                staffs           : state.staffs.map(item => ({...item, selected: false})),
                staffsSelected   : [],
                staffListSelected: [],
                searchHistory    : {
                    ...state.searchHistory,
                    staffList        : state.staffList.map(item => ({...item, selected: false})),
                    staffs           : state.staffs.map(item => ({...item, selected: false})),
                    staffsSelected   : [],
                    staffListSelected: [],
                },
            };
        case 'CHANGE_IS_OPEN_MODAL_SELECT_STAFFS':
            return {
                ...state,
                keywords               : '',
                keywordSelected        : '',
                teamSelected           : [],
                isOpenModalSelectStaffs: !state.isOpenModalSelectStaffs,
                searchHistory          : {
                    ...state.searchHistory,
                    isOpenModalSelectStaffs: !state.isOpenModalSelectStaffs,
                },
            };

        case GET_STAFFS_BETA:
            return {
                ...state,
                staffList        : action.staffs,
                staffs           : action.staffs,
                staffsSelected   : [],
                staffListSelected: [],
                searchHistory    : {
                    ...state.searchHistory,
                    staffs           : action.staffs,
                    staffList        : action.staffs,
                    staffsSelected   : [],
                    staffListSelected: [],
                },
            };
        case 'CHANGE_TYPE_OF_MODAL_SELECT_STAFFS':
            if (action.isOpenStaffSelected === state.isOpenStaffSelected) {
                return state;
            }

            let staffs       = state.staffListSelected.filter(staff => staff.selected);
            let staffListNew = state.staffList.map(item => ({ ...item, selected: false }));
            staffs.forEach((itemStaff) => {
                const index         = _.findIndex(staffListNew, (staff) => staff.id === itemStaff.id);
                staffListNew[index] = {
                    ...staffListNew[index],
                    selected: itemStaff.selected,
                };
            });

            let newStaffSelected = state.staffList
                .filter(staff => staff.selected)
                .map(stf => ({
                    ...stf,
                    selected: true,
                }));
            return {
                ...state,
                isOpenStaffSelected: action.isOpenStaffSelected,
                staffs             : staffListNew,
                staffList          : staffListNew,
                staffsSelected     : newStaffSelected,
                staffListSelected  : newStaffSelected,
                teamSelected       : [],
                keywords           : '',
                keywordSelected    : '',
                searchHistory      : {
                    ...state.searchHistory,
                    isOpenStaffSelected: action.isOpenStaffSelected,
                },
            };

        case 'CHANGE_KEYWORDS' :
            let keywordSearch = action.keywords.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');

            let staffSearchByKeyword = [...state.staffList].filter(staff => {
                if (state.teamSelected.length) {
                    let staffInRaws = staffsInTeam(state.teamSelected, state.staffList);
                    return filterStaffUseKeyword(staff.name, keywordSearch)
                        && _.findIndex(staffInRaws, { id: staff.id }) !== -1;
                }
                return filterStaffUseKeyword(staff.name, keywordSearch);
            });

            return {
                ...state,
                keywords: action.keywords,
                staffs  : staffSearchByKeyword,
            };

        case 'HANDLE_SEARCH_TEAM' :
            let staffInRaw = staffsInTeam(action.teamSelected, state.staffList);

            staffInRaw = [...state.staffList].filter(staff => {
                    if (state.keywords) {
                        return filterStaffUseKeyword(staff.name, state.keywords)
                            && _.findIndex(staffInRaw, { id: staff.id }) !== -1;
                    }
                    return _.findIndex(staffInRaw, { id: staff.id }) !== -1;
                },
            );
            return {
                ...state,
                teamSelected: action.teamSelected,
                staffs      : staffInRaw,
            };

        case 'HANDLE_SEARCH_TEAM_SELECTED_STAFFS' :
            let hasStaffSelected = staffsInTeam(action.teamSelected, state.staffListSelected).filter(staff => {
                return _.findIndex(state.staffListSelected, { id: staff.id }) !== -1;
            });

            hasStaffSelected = [...state.staffListSelected].filter(staff => {
                if (state.keywords) {
                    return filterStaffUseKeyword(staff.name, action.keywords)
                        && _.findIndex(hasStaffSelected, { id: staff.id }) !== -1;
                }
                return _.findIndex(hasStaffSelected, { id: staff.id }) !== -1;
            });

            return {
                ...state,
                teamSelected  : action.teamSelected,
                staffsSelected: hasStaffSelected,
            };

        case 'CHANGE_KEYWORD_SEARCH_STAFF_SELECTED' :
            let keywordSelected  = action.keywordSelected.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
            let staffHasSelected = [...state.staffListSelected].filter(staff => {
                if (state.teamSelected.length) {
                    let staffInTeam = staffsInTeam(state.teamSelected, state.staffListSelected);
                    return (filterStaffUseKeyword(staff.name, keywordSelected)
                        && _.findIndex(staffInTeam, { id: staff.id }) !== -1);
                }
                return filterStaffUseKeyword(staff.name, keywordSelected);
            });

            return {
                ...state,
                keywordSelected: action.keywordSelected,
                staffsSelected : staffHasSelected,
            };

        case 'HANDLE_CHANGE_CHECKBOX_STAFF' :
            let staffsRaw           = [...state.staffs];
            staffsRaw[action.index] = {
                ...staffsRaw[action.index],
                selected: !staffsRaw[action.index].selected,
            };

            let listStaffRaw                     = [...state.staffList];
            const indexCheckboxStaffList         = _.findIndex(listStaffRaw, (listStaffRawItem) => listStaffRawItem.id === staffsRaw[action.index].id);
            listStaffRaw[indexCheckboxStaffList] = {
                ...listStaffRaw[indexCheckboxStaffList],
                selected: !listStaffRaw[indexCheckboxStaffList].selected,
            };
            return {
                ...state,
                staffs   : staffsRaw,
                staffList: listStaffRaw,
            };

        case 'HANDLE_SELECT_ALL_CHECKBOX_STAFFS' :
            let isSelectedStaff = _.findIndex(state.staffs, (staff) => (!staff.selected)) !== -1;
            let selectedAll     = setSelectedForStaff(state.staffs, isSelectedStaff);
            let getStaffList    = [...state.staffList];
            selectedAll.forEach(staff => {
                let setIndexStaff           = _.findIndex(getStaffList, ['id', staff.id]);
                getStaffList[setIndexStaff] = {
                    ...getStaffList[setIndexStaff],
                    selected: staff.selected,
                };
            });

            return {
                ...state,
                staffs   : selectedAll,
                staffList: getStaffList,
            };
        case 'HANDLE_SELECT_ALL_STAFF_SELECTED':
            let checkStaffHasSelected = _.findIndex(state.staffsSelected, (staff => (!staff.selected))) !== -1;
            let getStaffHasSelected   = setSelectedForStaff(state.staffsSelected, checkStaffHasSelected);
            let getListAllStaff       = [...state.staffListSelected];

            getStaffHasSelected.forEach(staff => {
                let indexStaff              = _.findIndex(getListAllStaff, ['id', staff.id]);
                getListAllStaff[indexStaff] = {
                    ...getListAllStaff[indexStaff],
                    selected: staff.selected,
                };
            });
            return {
                ...state,
                staffsSelected   : getStaffHasSelected,
                staffListSelected: getListAllStaff,
            };

        case 'HANDLE_CHANGE_CHECKBOX_STAFF_SELECTED':
            let staffHasCheck                            = [...state.staffsSelected];
            staffHasCheck[action.index]                  = {
                ...staffHasCheck[action.index],
                selected: !staffHasCheck[action.index].selected,
            };
            let staffListSelected                        = [...state.staffListSelected];
            let getIndexStaffSelectedList                = _.findIndex(state.staffListSelected, (staff) => staff.id === staffHasCheck[action.index].id);
            staffListSelected[getIndexStaffSelectedList] = {
                ...staffListSelected[getIndexStaffSelectedList],
                selected: !staffListSelected[getIndexStaffSelectedList].selected,
            };

            return {
                ...state,
                staffsSelected   : staffHasCheck,
                staffListSelected: staffListSelected,
            };
        case 'HANDLE_CLOSE_STAFF_MODAL':
            return {
                ...state,
                ...state.searchHistory,
                isOpenStaffSelected: state.searchHistory.staffListSelected.filter(item => item.selected).length,
            };

        case 'HANDLE_CONFIRM_STAFF_MODAL_SEARCH':
            let staffSelected     = state.isOpenStaffSelected ? state.staffListSelected : state.staffList;
            let checkListSelected = staffSelected.filter(item => item.selected);
            return {
                ...state,
                staffsSelected   : staffSelected.filter(item => item.selected),
                staffListSelected: staffSelected.filter(item => item.selected),
                staffs           : getSelectedStaff(checkListSelected, state.staffList),
                staffList        : getSelectedStaff(checkListSelected, state.staffList),
                teamSelected     : [],
                keywords         : '',
                keywordSelected  : '',
                isOpenStaffSelected: staffSelected.filter(item => item.selected).length,
                searchHistory    : {
                    ...state.searchHistory,
                    staffsSelected   : staffSelected.filter(item => item.selected),
                    staffListSelected: staffSelected.filter(item => item.selected),
                    staffs           : getSelectedStaff(checkListSelected, state.staffList),
                    staffList        : getSelectedStaff(checkListSelected, state.staffList),
                    teamSelected     : [],
                    keywords         : '',
                    keywordSelected  : '',
                    isOpenStaffSelected: staffSelected.filter(item => item.selected).length,
                },
            };

        default:
            return state;
    }
};
export default ModalSelectStaffs;
