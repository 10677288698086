import React, { Component }               from 'react';
import ModalAddLogicGeneral               from '../ModalAddLogicGeneral';
import BehaviorQuestionMobile             from './BehaviorQuestionMobile';
import { withNamespaces }                 from 'react-i18next';
import ActionQuestions                    from './ActionsQuestions';
import { checkUseHeadingQuestion }        from '../../SurveyEditor/SurveyService';
import moment                             from 'moment';
import { connect }                        from 'react-redux';
import { setDefaultAnswerQuestionMobile } from '../../../../actions/Survey';
import {
    KirokuPopoverItem, KirokuLogicApplicated,
    KirokuCardWeb, KirokuCardTitleWeb, KirokuInputIcon,
    KirokuCardBodyWeb, KirokusSortableHandle,
}                                         from '../../../../ui';
import ComponentUnrequited                from '../../ReviewSurvey/ComponentUnrequited';
import KirokuButtonLink                   from '../../../../ui/KirokuButtonLink';

@connect(null, dispatch => {
    return {
        setDefaultAnswerQuestion: (question) => {
            dispatch(setDefaultAnswerQuestionMobile(question));
        },
    };
})
@withNamespaces()
class Time extends Component {
    state = {
        show : true,
        clock: '',
    };

    componentWillMount() {
        if (this.props.question.defaultAnswer) {
            this.setState({
                clock: moment.unix(this.props.question.defaultAnswer).format('HH:mm'),
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.question.defaultAnswer !== nextProps.question.defaultAnswer) {
            if (nextProps.value) {
                this.setState({
                    value: nextProps.question.defaultAnswer,
                    clock: nextProps.question.defaultAnswer ? moment.unix(nextProps.question.defaultAnswer).format('HH:mm') : '',
                });
            }
        }
    }

    showModalAddLogic() {
        this.setState({
            show: !this.state.show,
        });
    }

    closeModal() {
        this.setState({
            show: !this.state.show,
        });
    }

    handleOnChangeDateTime() {
        const { question } = this.props;
        let newQuestion    = { ...question };
        let answer         = this.state.clock ? moment(`${this.state.clock}`, 'HH:mm').unix() : '';
        newQuestion        = {
            ...newQuestion,
            defaultAnswer: answer,
        };
        this.props.setDefaultAnswerQuestion(newQuestion);
    }

    handleResetDefaultAnswer() {
        const { question } = this.props;
        let questionNew    = { ...question };
        if(questionNew.defaultAnswer) {
            delete questionNew.defaultAnswer;
            this.props.setDefaultAnswerQuestion(questionNew);
        }
    }

    render() {
        const { t }           = this.props;
        const question        = this.props.question;
        const { clock }       = this.state;
        const contentQuestion = checkUseHeadingQuestion(question);
        return (
            <KirokuCardWeb style={{ borderRadius: 4 }}>

                <KirokuCardTitleWeb
                    style={{ backgroundColor: '#e9edf1', paddingTop: 10, paddingBottom: 7, paddingRight: 12, paddingLeft: 12 }}>
                    <div className="d-flex justify-content-between">
                        <div className={'pr-2'}>
                            <KirokusSortableHandle>
                                <div className='number-question d-flex'>
                                    <span className="question-number pb-2 pt-4px">
                                        {t('createSurvey.QuestionOrder', { index: question.index + 1 })}
                                    </span>
                                    <KirokuLogicApplicated question={question}/>
                                </div>
                            </KirokusSortableHandle>
                        </div>
                        <div className="pt-5px pr-4" hidden={typeof question.nextQuestion === 'number'}>
                            <ComponentUnrequited question={question}
                                                 changeUnrequitedQuestion={(questionIndex) => this.props.changeUnrequitedQuestion(questionIndex)}
                            />
                        </div>
                        <div className={'pr-2'}>
                            <KirokuButtonLink
                                className='padding-reset-button-link'
                                onClick={() => {
                                    this.setState({
                                        clock: ''
                                    }, () => {
                                        this.handleResetDefaultAnswer()
                                    })
                                }}
                            >
                                {t('createRecord.resetAnswer')}
                            </KirokuButtonLink>
                        </div>
                        <div>
                            <ActionQuestions isReviewSurvey>
                                <KirokuPopoverItem>
                                    <BehaviorQuestionMobile question={question}
                                                            showModalAddLogic={this.showModalAddLogic.bind(this)}
                                                            backToEditQuestion={this.props.backToEditQuestion}
                                    />
                                </KirokuPopoverItem>
                            </ActionQuestions>
                        </div>
                    </div>
                </KirokuCardTitleWeb>
                <div className='wrap-overlay-action'>
                    <KirokuCardBodyWeb>
                        <div>
                            <span className='content-name-question pb-2'>{contentQuestion}</span>
                            <p hidden={typeof question.nextQuestion !== 'number'} className='text-info'>
                                <i className='fas fa-arrow-right text-info'>
                                    {t('createRecord.Question')}: {question.nextQuestion + 1}
                                </i>
                            </p>
                            <div className='answer-question'>
                                <KirokuInputIcon name={`${question.index}clock`} icon={'far fa-clock'}
                                                 type={'time'}
                                                 value={clock}
                                                 onChange={e => this.setState({ clock: e.target.value },
                                                     this.handleOnChangeDateTime.bind(this))}

                                />
                            </div>
                        </div>
                    </KirokuCardBodyWeb>
                </div>

                <ModalAddLogicGeneral show={this.state.show} question={question}
                                      closeModal={this.closeModal.bind(this)}/>
            </KirokuCardWeb>
        );
    }
}

export default Time;
