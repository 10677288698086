import React, {Component}                           from 'react';
import KirokuModalHeader                            from "../../../ui/KirokuModalHeader";
import KirokuInputSearch                            from "../../../ui/KirokuInputSearch";
import KirokuModalBody                              from "../../../ui/KirokuModalBody";
import KirokuCardList                               from "../../../ui/KirokuCardList";
import KirokuCard                                   from "../../../ui/KirokuCard";
import KirokuCheckBox                               from "../../../ui/KirokuCheckBox";
import KirokuModalFooter                            from "../../../ui/KirokuModalFooter";
import KirokuButton                                 from "../../../ui/KirokuButton";
import KirokuModal                                  from "../../../ui/KirokuModal";
import {connect}                                    from "react-redux";
import {checkAllTeam, onSearch, selectTeamPageUser} from "../../../actions/Team";
import {assginUserToTeam}                           from "../../../actions/User";
import {withNamespaces}                             from "react-i18next";

@withNamespaces()
@connect((state) => {
    return {
        listTeam: state.KirokuAITeam.listTeam,
        checked: state.KirokuAITeam.checked
    }
}, (dispatch) => {
    return {
        checkAll: (checked) => {
            dispatch(checkAllTeam(checked))
        },
        selectTeam: (teamId) => {
            dispatch(selectTeamPageUser(teamId))
        },

        assginUserToTeam: (users, teams, params, tags) => {
            dispatch(assginUserToTeam(users, teams, params, tags))
        },
        handleSearchTeam: (keywords) => {
            dispatch(onSearch(keywords))
        }
    }
})
class ModalAssignUserToTeam extends Component {
    state = {
        checked: false,
    };

    handleCheckAll() {
        this.props.checkAll(this.state.checked);
    }

    handleCheckTeam(teamId) {
        this.props.selectTeam(teamId);
    }

    handleCancel() {
        this.props.close()
    }

    closeModalAssign() {
        this.props.closeModalAssign()
    }

    handleAssign() {
        let userSelect = this.props.users;
        const {params, tags} = this.props;
        let teamSelect = this.props.listTeam.filter(item => item.isChecked);
        this.props.assginUserToTeam(userSelect, teamSelect, params, {tags: tags});
        this.props.closeModalAssign()
    }

    onSearch(e) {
        this.props.handleSearchTeam(e.target.value.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, ''))
    }

    isSelectTeam() {
        return !this.props.listTeam.filter(item => item.isChecked).length
    }

    render() {
        let {t} = this.props;
        return (
            <KirokuModal show={this.props.show}>
                <KirokuModalHeader>
                    <h6 className="mb-3">{t('common.AssignToTeam')}</h6>
                    <KirokuInputSearch onChange={this.onSearch.bind(this)}
                                       placeholder={t('common.EnterTeamName')}/>
                </KirokuModalHeader>
                <KirokuModalBody>
                    <div id="body-modal-as">
                        <KirokuCardList
                            headerLeft={t('common.TeamList')}
                            headerRight={
                                <div className='text-primary' style={{cursor: 'pointer'}}>
                                        <span hidden={this.props.checked}
                                              onClick={this.handleCheckAll.bind(this)}
                                        >{t('common.SelectAll')}</span>

                                    <span hidden={!this.props.checked}
                                          onClick={this.handleCheckAll.bind(this)}
                                    >{t('common.UnSelect')}</span>
                                </div>
                            }
                        >
                            <section className="scroll-data-survey">
                                {this.props.listTeam.map((team, index) => (
                                    <KirokuCard key={team.id}>
                                        <KirokuCheckBox
                                            onChange={this.handleCheckTeam.bind(this, team.id)}
                                            key={index}
                                            checked={team.isChecked}
                                            content={team.name}/>
                                    </KirokuCard>
                                ))}
                            </section>

                        </KirokuCardList>
                    </div>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton onClick={this.handleCancel.bind(this)} color={'white'}>
                        {t('common.Cancel')}
                    </KirokuButton>
                    <KirokuButton disabled={this.props.disableButtonApply || this.isSelectTeam()}
                                  onClick={this.handleAssign.bind(this)}
                                  color={'primary'}>
                        {t('common.Apply')}
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>
        )
    }
}

export default ModalAssignUserToTeam
