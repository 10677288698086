export default class MakeSurveyServices {

    makeSurveyFroTags(surveys) {
        return surveys.map(survey => {
            return {
                ...survey,
                isChecked: false,
                tagType: "survey",
                tagContent: {
                    ...survey,
                },
                key: survey.id,
                label: survey.title,
                value: survey.title,
                type: 'Surveys'
            }
        });
    }
}