import { deletedList } from "../services";

export const DeletedStaffsMiddleware = store => next => action => {

    const conditionInDeletedStaffs               = store.getState().DeletedStaffs;
    const { nameScreen } = action;
    if (nameScreen !== 'staffs') {
        return next(action);
    }

    switch (action.type) {
        case 'CHANGE_SCREEN':
            let condition = {
                page   : 1,
                orderBy : {
                    field: 'delete_at',
                    type: 'DESC'
                },
                keyword   : '',
            };
            return deletedList.getStaffsByCondition(condition).then(res => {
                store.dispatch({
                    ...action,
                    ...res.data,
                    type: 'SET_USER_API',
                });
                next({
                    ...action,
                    ...res.data
                });
            });
        case 'CHANGE_PAGE_NUMBER':
            return deletedList.getStaffsByCondition({
                ...conditionInDeletedStaffs,
                orderBy: conditionInDeletedStaffs.useOrderBy,
                page    : action.numberPage,
                keyword : conditionInDeletedStaffs.keywordSubmit
            }).then(res => {
                store.dispatch({
                    ...res.data,
                    type   : 'SET_USER_API',
                });
                next({
                    ...action,
                    ...res.data
                })
            });
        case 'SUBMIT_SEARCH_KEYWORD':
            return deletedList.getStaffsByCondition({
                ...conditionInDeletedStaffs,
                orderBy: conditionInDeletedStaffs.useOrderBy,
                page    : 1,
                keyword : conditionInDeletedStaffs.keyword
            }).then(res => {
                store.dispatch({
                    ...action,
                    ...res.data,
                    hasNext: res.data.hasNext,
                    type   : 'SET_USER_API',
                });
                next({
                    ...action,
                    ...res.data,
                    type : 'SUBMIT_SEARCH_KEYWORD_STAFFS'
                })
            });
        case 'CHANGE_ORDER_BY':
            return deletedList.getStaffsByCondition({
                ...conditionInDeletedStaffs,
                keyword : conditionInDeletedStaffs.keywordSubmit,
                page    : 1,
                orderBy : {
                    field: action.field,
                    type : action.typeOrder
                }
            }).then(res => {
                store.dispatch({
                    ...res.data,
                    hasNext: res.data.hasNext,
                    type   : 'SET_USER_API',
                });
                next({
                    ...action,
                    ...res.data,
                    type: 'CHANGE_ORDER_BY_STAFFS'
                })
            });
        case 'CHANGE_SEARCH_KEYWORD':
            return next({
                ...action,
                type : 'CHANGE_KEY_WORD_STAFFS'
            });
        default :
            return next(action);
    }
};

