import GenericQuestion from "./GenericQuestion";


class Media extends GenericQuestion {

    setDefaultAnswer() {
        this.payload.answers = {};
        return this.payload.answers;
    }

    answer(answers) {
        if( !answers ) {
            this.payload.answers = {};
            return this.getNext({});
        }

        this.payload.answers = answers;
        return this.getNext(answers);
    }

    getNext(answer) {

        if( !answer ) {
            return this.payload.index + 1;
        }

        if(this.payload.nextQuestion === 0) {
            return this.payload.nextQuestion
        }

        return this.payload.nextQuestion || this.payload.index + 1
    }
}
export default Media;
