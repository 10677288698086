
export const DeletedList = (store) => next => action => {

    const nameScreen = store.getState().DeletedList.ComponentFlowScreen.name;
    const conditionInDeletedList = store.getState().DeletedList;

    switch (action.type) {
        case 'CHANGE_PAGE_NUMBER':
        case 'CHANGE_ORDER_BY':
        case 'SUBMIT_SEARCH_KEYWORD':
        case 'CHANGE_SEARCH_KEYWORD':
            return next({
                ...action,
                conditionInDeletedList,
                nameScreen
            });
        case 'CHANGE_SCREEN':
            return next({
                ...action,
                conditionInDeletedList,
                nameScreen: action.screen
            });

        default:
            return next(action);
    }
};
