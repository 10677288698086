import React, { Component }        from 'react';
import { checkUseHeadingQuestion } from "../../Surveys/SurveyEditor/SurveyService";

class TemplateOnly extends Component {

    static renderQuestion(question) {
        switch (question.type) {
            case "Media":
                return (
                    <div className="pb-3">
                        <img className="report-tab" src={question.answers} alt=""/>
                    </div>
                );
            case "YesNo":
            case "Date":
                return (<div className="answer-content">{question.answers}</div>);
            default:
                return (<div className="answer-content">{question.answers}</div>)
        }
    }

    render() {
        const {reports} = this.props;
        return (
            <div className="report-tab container">
                <div className="wrap-content">
                    <div className="A4">
                        <div className="content">
                            <div className="wrap-bg">
                                {reports.templates.map((template, index) => {
                                    return (
                                        <div className="pb-3" key={index}>
                                            <div>
                                                <label className="report-tab">
                                                    {reports.translate_title}
                                                </label>
                                                <div className="border-title-template">
                                                    {template.title}
                                                </div>
                                            </div>
                                            <div className="">
                                                {template.records.map((record, index) => {
                                                    return(
                                                        <div className="pt-3" key={index}>
                                                            <div
                                                                className="row fix-margin-row border-bottom-dashed">
                                                                <div className={'col-3 fix-padding-col pb-10px'}>
                                                                    <label className="report-tab">
                                                                        {reports.translate_customerName}
                                                                    </label>
                                                                    <div>
                                                                        {record.customerName}
                                                                    </div>
                                                                </div>
                                                                <div className="col-3 pb-10px">
                                                                    <label className="report-tab">
                                                                        {reports.translate_serviced}
                                                                    </label>
                                                                    <div>
                                                                        {record.serviced_date_time}
                                                                    </div>
                                                                </div>
                                                                <div className={'col-6 pb-10px'}>
                                                                    <label className="report-tab">
                                                                        {reports.translate_created_by}
                                                                    </label>
                                                                    <div>
                                                                        {record.created_by}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="answers-list">
                                                                {record.questions.map((question, index) => {
                                                                    return(
                                                                        <div key={index} className={'answer-item'}>
                                                                            <div className={`pt-2 pb-10px
                                                                            ${index === record.questions.length - 1 ?
                                                                                'border-bottom-inline row fix-margin-row' :
                                                                                'border-bottom-dashed row fix-margin-row'}`}>
                                                                                <label
                                                                                    className="report-tab col-3 fix-padding-col">
                                                                                    {checkUseHeadingQuestion(question)}
                                                                                </label>
                                                                                <div className="col-9">
                                                                                    {TemplateOnly.renderQuestion(question)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TemplateOnly
