import React, {Component}          from 'react';
import {KirokuButton, KirokuInput} from "../../ui";
import Datetime                    from "react-datetime";
import config                      from "../../config";
import moment                      from "moment";
import {withNamespaces}            from "react-i18next";
import KirokuActionSurvey          from "../../ui/KirokuActionSurvey";
import MonitoringTerms             from "./MonitoringTerms";
import {serviceValidateDateRanger} from "./serviceValidateCustomer";
import _                           from 'lodash';

moment.locale('ja');

@withNamespaces()
class Certification extends Component {

    state = {
        certification_number: '',
        validateCertificationNumber: false,
        validateCertificationNumberText: '',
        issuer: '',
        services: [{
            name: '',
            startDate: '',
            validateFormatStartDate: false,
            endDate: '',
            validateFormatEndDate: false,
            validateDateRanger: false
        }],
        monitoring_terms: []
    };

    componentWillMount() {
        if (this.props.certifications.length) {
            const {certifications} = this.props;
            let certificationsFirst = certifications[0];
            this.setState({
                certification_number: certificationsFirst.certification_number,
                issuer: certificationsFirst.issuer,
                monitoring_terms: certificationsFirst.monitoring_terms,
                services: certificationsFirst.services.length ? certificationsFirst.services.map(service => {
                    return {
                        name: service.name,
                        startDate: service.startDate,
                        validateFormatStartDate: false,
                        endDate: service.endDate,
                        validateFormatEndDate: false,
                    }
                }) : [{
                    name: '',
                    startDate: '',
                    validateFormatStartDate: false,
                    endDate: '',
                    validateFormatEndDate: false,
                }]
            })
        }
    }

    getCertification() {
        const certification = JSON.parse(JSON.stringify(this.state));

        let validateFormatEndDateIndex = _.findIndex(certification.services, ['validateFormatEndDate', true]);
        let validateFormatStartDateIndex = _.findIndex(certification.services, ['validateFormatStartDate', true]);
        let validateDateRangerIndex = _.findIndex(certification.services, ['validateDateRanger', true]);
        let validateFormatDateMoninterIndex = _.findIndex(certification.monitoring_terms, ['validateFormatDate', true]);

        delete certification.validateCertificationNumber;
        delete certification.validateCertificationNumberText;
        this.props.validateDateRanger(validateFormatEndDateIndex >= 0 || validateFormatStartDateIndex >= 0 || validateDateRangerIndex >= 0 || validateFormatDateMoninterIndex >= 0);

        const certificationToCustomer = {
            ...certification,
            monitoring_terms: [...certification.monitoring_terms].map(e => {
                return {
                    date: e.date
                }
            }).filter(e => e.date !== ''),
            services: [...certification.services].map(service => {
                delete service['validateFormatStartDate'];
                delete service['validateFormatEndDate'];
                delete service['validateDateRanger'];
                delete service['validateNameService'];
                if (!service['name'] && !service['startDate'] && !service['endDate']) {
                    return undefined
                }
                return {
                    ...service
                }
            }).filter((e) => e !== undefined)
        };

        this.props.addCertification(certificationToCustomer);

    };

    addService() {
        this.setState({
            services: [...this.state.services, {
                name: '',
                startDate: '',
                endDate: '',
            }]
        }, () => {
            this.getCertification();
        })
    }

    decrementService(index) {
        let newServices = [...this.state.services];
        newServices.splice(index, 1);
        this.setState({
            ...this.state,
            services: newServices
        }, () => {
            this.getCertification();
        })
    }

    checkShowValidate = (start, end, validateRanger) => {
        if (start || end) {
            return false
        }
        return validateRanger
    };

    editDateOfIndexService(timeLine, momentTime, serviceIndex) {
        let servicesState = this.state.services;
        if (timeLine === 'startDate') {
            servicesState[serviceIndex] = {
                ...servicesState[serviceIndex],
                startDate: typeof momentTime === 'object' ? moment(momentTime).unix() : momentTime,
                validateFormatStartDate: !moment(momentTime, 'YYYY/MM/DD', true).isValid() && momentTime
            };
        }

        if (timeLine === 'endDate') {
            servicesState[serviceIndex] = {
                ...servicesState[serviceIndex],
                endDate: typeof momentTime === 'object' ? moment(momentTime).unix() : momentTime,
                validateFormatEndDate: !moment(momentTime, 'YYYY/MM/DD', true).isValid() && momentTime
            };
        }
        return servicesState.map(service => {
            return {
                ...service,
                validateDateRanger: this.checkShowValidate(service.validateFormatStartDate, service.validateFormatEndDate, !serviceValidateDateRanger(service.startDate, service.endDate))
            }
        });
    }

    handleOnChangeStartDate(indexService, momentTime) {
        this.setState({
            services: this.editDateOfIndexService('startDate', momentTime, indexService)
        }, () => {
            this.getCertification();
        })
    }

    handleOnChangeEndDate(indexService, momentTime) {
        this.setState({
            services: this.editDateOfIndexService('endDate', momentTime, indexService)
        }, () => {
            this.getCertification();
        })
    }

    handleChangeNameService(indexNameSer, e) {
        let newServices = [...this.state.services];
        newServices[indexNameSer] = {
            ...newServices[indexNameSer],
            name: e.target.value
        };
        this.setState({
            services: newServices
        }, () => {
            this.getCertification();
        });
    }

    handleChangeIssuer(e) {
        this.setState({
            issuer: e.target.value
        }, () => {
            this.getCertification();
        })
    }

    handleChangeNumber(e) {
        let regex = e.target.value.replace(/[^0-9]/g, '');
        if (regex.length > 10) return;
        let state = this.state;
        state.certification_number = regex;
        this.props['validateNumberCertification'](false);
        this.setState({
            certification_number: state.certification_number,
            validateCertificationNumber: false
        }, () => {
            this.getCertification();
        })
    }

    checkError = (number) => {
        if (number.length > 0 && number.length < 10) {
            //let checkUsername = number.match(/^\w+$/);
            this.setState({validateCertificationNumber: true});
            return 'FormatNumber';
        }
        return ''
    };


    checkValidationCertification(e) {
        const error = this.checkError(e.currentTarget.value);
        this.props['validateNumberCertification'](!!error);
        this.setState({
            validateCertificationNumberText: error,
            validateCertificationNumber: !!error,
        });
    }

    addMonitoringTerms(monitoringTerms) {
        this.setState({
            monitoring_terms: monitoringTerms
        }, () => {
            this.getCertification();
        })
    }

    checkGetValueInputDate = (date) => {
        return typeof date === 'number' ? moment.unix(date) : date;
    };

    render() {
        const {
            services,
            validateCertificationNumberText,
            certification_number,
            issuer,
            monitoring_terms,
            validateCertificationNumber
        } = this.state;
        const {t} = this.props;
        return (
            <div>
                <h5>{t('CustomerEditor.Certification')}</h5>
                <div className={'row'}>
                    <div className={'col-3'}>
                        <label>{t('CustomerEditor.CertificationNumber')}</label>
                        <KirokuInput
                            value={certification_number}
                            onChange={this.handleChangeNumber.bind(this)}
                            onBlur={this.checkValidationCertification.bind(this)}
                        />
                        <p hidden={!validateCertificationNumber} style={{
                            color: 'red',
                            fontSize: 12
                        }}>
                            {t(`CustomerEditor.validate.${validateCertificationNumberText}`)}
                        </p>
                    </div>
                    <div className="col-9">
                        <label> {t('CustomerEditor.CertificationIssuer')} </label>
                        <KirokuInput
                            onChange={this.handleChangeIssuer.bind(this)}
                            value={issuer}
                        />
                    </div>
                </div>
                <div className={'p-2'}/>
                <div style={{
                    border: '1px solid rgb(200, 200, 200)',
                    padding: '20px'
                }}>
                    <div className="d-flex justify-content-between col-10 pl-0">
                        <h5>{t('CustomerEditor.Service')}</h5>
                        <KirokuButton className={'cursor-pointer pb-2'}
                                      color={'primary'}
                                      onClick={this.addService.bind(this)}>
                            {t('CustomerEditor.add')}
                        </KirokuButton>
                    </div>
                    {services.map((service, index) => {
                        const isFirst = index !== 0;
                        const startDate = this.checkGetValueInputDate(service.startDate);
                        const endDate = this.checkGetValueInputDate(service.endDate);
                        return (
                            <div className="row pt-2" key={index}>
                                <div className="col-md-4">
                                    <label hidden={isFirst}>{t('CustomerEditor.CertificationServiceName')} *</label>
                                    <KirokuInput
                                        value={service.name}
                                        onChange={this.handleChangeNameService.bind(this, index)}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <label hidden={isFirst}>{t('CustomerEditor.CertificationServiceStartDate')}</label>
                                    <Datetime
                                        inputProps={{disabled: false, readOnly : true}}
                                        value={startDate}
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        dateFormat={config.formatDate}
                                        onChange={this.handleOnChangeStartDate.bind(this, index)}
                                        locale={t('createRecord.Date')}
                                        className='read-only-date'
                                        monthYearLocale={localStorage.getItem('i18nextLng')}
                                    />
                                    <p hidden={!service.validateFormatStartDate} style={{
                                        color: 'red',
                                        fontSize: 12
                                    }}>
                                        {t('CustomerEditor.validate.ValidDate')}
                                    </p>
                                    <p hidden={!service.validateDateRanger} style={{
                                        color: 'red',
                                        fontSize: 12
                                    }}>
                                        {t('CustomerEditor.validate.ErrorStartDateCertification')}
                                    </p>
                                </div>
                                <div className="col-md-3">
                                    <label hidden={isFirst}>{t('CustomerEditor.CertificationServiceEndDate')}</label>
                                    <div>
                                        <Datetime
                                            className='read-only-date'
                                            inputProps={{disabled: false, readOnly: true}}
                                            value={endDate}
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            dateFormat={config.formatDate}
                                            onChange={this.handleOnChangeEndDate.bind(this, index)}
                                            locale={t('createRecord.Date')}
                                            monthYearLocale={localStorage.getItem('i18nextLng')}
                                        />
                                        <p hidden={!service.validateFormatEndDate} style={{
                                            color: 'red',
                                            fontSize: 12
                                        }}>
                                            {t('CustomerEditor.validate.ValidDate')}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <label hidden={isFirst}/>
                                    <div className={'cursor-pointer pt-2'}
                                         onClick={this.decrementService.bind(this, index)}
                                         hidden={services.length === 1}
                                         style={{width: 20}}
                                    >
                                        <KirokuActionSurvey icon={'minus'}/>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className={'pt-3'}/>
                <MonitoringTerms
                    monitoringTerms={monitoring_terms}
                    addMonitoringTerms={this.addMonitoringTerms.bind(this)}
                />

            </div>
        )
    }
}

export default Certification;
