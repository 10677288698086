import React, { Component }             from 'react';
import KirokuTable                      from '../../ui/KirokuTabLe';
import THeader                          from '../../ui/THeader';
import KirokuCheckBox                   from '../../ui/KirokuCheckBox';
import TBody                            from '../../ui/TBody';
import moment                           from 'moment';
import KirokuCardWeb                    from '../../ui/KirokuCardWeb';
import { withNamespaces }               from 'react-i18next';
import { connect }                      from 'react-redux';
import { checkRecord, handlerCheckAll } from '../../actions/AdminExport';
import BtnExport                        from './BtnExport';
import { SearchNotFound }               from '../../ui';
import config                           from '../../config';

@withNamespaces()
@connect(state => {
    return {
        arrayRecord: state.ReducerAdminExport.arrayRecord,
        statusCheckAllRecord: state.ReducerAdminExport.statusCheckAllRecord
    }
}, dispatch => {
    return {
        checkRecord: (id) => {
            dispatch(checkRecord(id))
        },
        handlerCheckAll: () => {
            dispatch(handlerCheckAll())
        }
    }
})
class ResultsSearchExport extends Component {

    checkedRecord(recordId, e) {
        this.props.checkRecord(recordId)
    }

    handlerCheckAll() {
        this.props.handlerCheckAll()
    }

    render() {
        const {t, arrayRecord, statusCheckAllRecord} = this.props;
        function getTime(dateTime, hasTime) {
            if (dateTime) {
                if (!hasTime) {
                    return moment.unix(dateTime).format(config.formatDateTh)
                }
                return moment.unix(dateTime).format(config.formatDateTimeTh)
            } else {
                return moment().format(config.formatDateTimeTh)
            }

        }

        return (
            <div className='pt-3 pb-3'>
                <KirokuCardWeb>
                    <h5 className='padding-responsive'>{t('Export.Result')}</h5>
                    {arrayRecord[0] ?
                        <div>
                            <KirokuTable>
                                <THeader>
                                    <tr>
                                        <th style={{width: 50}}>
                                            <KirokuCheckBox disabled={!arrayRecord[0]}
                                                            onChange={this.handlerCheckAll.bind(this)}
                                                            checked={statusCheckAllRecord}/>

                                        </th>
                                        <th>{t('common.Title')}</th>
                                        <th>{t('Export.RecordCreator')}</th>
                                        <th>{t('RecordList.servicedAt')}</th>
                                    </tr>
                                </THeader>

                                <TBody>
                                {arrayRecord.map((record, index) =>
                                    <tr key={record.id}>
                                        <th>
                                            <KirokuCheckBox
                                                onChange={this.checkedRecord.bind(this, record.id)}
                                                checked={record.isChecked}
                                            />
                                        </th>
                                        <td><span>{record.title}</span></td>
                                        <td><span>{record['staff_name']}</span></td>
                                        <td>
                                            {record.serviced_at ? getTime(record.serviced_at, record.has_time)
                                                : moment.unix(record.create_at).format(config.formatDateTimeTh)
                                            }
                                        </td>
                                    </tr>
                                )}

                                </TBody>

                            </KirokuTable>
                            <BtnExport/>
                        </div> :
                        <SearchNotFound
                            exportAdmin
                            message={t('Export.showResultSearch')}
                            description={t('Export.TrySearch')}
                        />
                    }

                </KirokuCardWeb>
            </div>
        );
    }
}

export default ResultsSearchExport;
