import moment                    from 'moment';
import {checkUseHeadingQuestion} from "../Surveys/SurveyEditor/SurveyService";
import config                    from '../../config';
import _                         from 'lodash';
export function getAnswerToQuestionFullLogic(flowedQuestions, questions) {
    flowedQuestions.forEach(question => {
        questions[question.index] = {
            ...questions[question.index],
            answers: question.answers
        }
    });
    return questions;
}

export function validateAnswer(currentQuestion) {
    switch (currentQuestion.type) {
        case 'Message': return 'message';
        case 'Single':
            if (currentQuestion.answers.is_choice < currentQuestion.choices.length) {
                return currentQuestion.answers;
            }
            return null;
        case 'MultiChoice' :
            const value = currentQuestion.answers;
            const isAnswers = value.filter(answer => answer['is_choice'] === false).length !== value.length;
            if (isAnswers) {
                return value
            }
            return null;
        case 'Media':
            if (currentQuestion.answers.answer) {
                return currentQuestion.answers.answer;
            }
            return null;
        case 'Date':
            if (currentQuestion.answers) {
                return currentQuestion.answers;
            }
            return null;
        case 'Time':
            if (currentQuestion.answers) {
                return currentQuestion.answers;
            }
            return null;
        case 'OnlyDate':
            if (currentQuestion.answers) {
                return currentQuestion.answers;
            }
            return null;
        default:

            if (!currentQuestion.answers) {
                return null;
            }

            if (currentQuestion.answers.length !== 0) {
                return currentQuestion.answers;
            }

            return null;
    }
}


export function getAnswersToQuestion(questions) {
    return questions.map(question => {
        switch (question.type) {
            case 'Number':
            case 'InputText':
            case 'YesNo':
                return question.answers;
            case 'Date':
                if(!question.answers) {
                    break;
                }
                return moment.unix(question.answers).format(config.formatDateTimeTh);
            case 'OnlyDate':
                if(!question.answers) {
                    break;
                }
                return moment.unix(question.answers).format(config.formatDateTh);
            case 'Time':
                if(!question.answers) {
                    break;
                }
                return moment.unix(question.answers).format('HH:mm');
            case 'Media':
            case 'Message':
                break;
            case 'Single':
                if(!question.answers) {
                    return null;
                }

                if(typeof question.answers === "string") {
                    return question.answers
                }

                if(question.answers.answer) {
                    return question.answers.answer
                }

                break;
            case 'MultiChoice':
                if(question.answers.length) {
                    if(typeof question.answers[0] === 'string') {
                        return question.answers.join(',')
                    }
                    return question.answers.filter(e => e.is_choice).map(ans => ans.answer).join(',')
                }
                break;
            default: return null;
        }
        return null;
    }).filter((answer) => !!answer).join('/')
}



export const getConditions = (conditions) => {
    let conditionStatus = [...conditions.status].filter((item) => { return item.style.backgroundColor !== '' })[0].status;
    return {
        params: {
            currentPage: conditions.currentPage,
            pageSize    : conditions.pageSize
        },
        body: {
            status      : conditionStatus,
            serviceAt   : {
                startDate   : conditions.serviceAt.momentStartDate,
                endDate     : conditions.serviceAt.momentEndDate,
            },
            customerIds: [...conditions.currentCustomerTags].map(ctm =>  ctm.customerId),
            templateIds: [...conditions.currentSurveyTags].map(sur => sur.surveyId),
            orders     : conditions.orders
        }
    }

};


export const getConditionsAdvance = (conditions) => {
    let conditionStatus = [...conditions.status].filter((item) => { return item.style.backgroundColor !== '' })[0].status;
    return {
        params: {
            currentPage: conditions.currentPageDetail,
            pageSize: conditions.pageSize
        },
        body: {
            status            : conditionStatus,
            customerIds       : [...conditions.currentCustomerTags].map(ctm => ctm.customerId),
            templateIds       : [...conditions.currentSurveyTags].map(ctm => ctm.surveyId),
            staffCreatedByIds : [...conditions.currentCreatedBy].map(item => item.staffId),
            staffUpdatedByIds : [...conditions.currentUpdatedBy].map(item => item.staffId),
            serviceAt         : {
                startDate : conditions.serviceAt.momentStartDate,
                endDate   : conditions.serviceAt.momentEndDate,
            },
            createdAt: {
                startDate : conditions.createdAt.momentStartDate,
                endDate: conditions.createdAt.momentEndDate,
            },
            updatedAt: {
                startDate : conditions.updatedAt.momentStartDate,
                endDate   : conditions.updatedAt.momentEndDate,
            },
            keywordSearch: conditions.keywordSearch,
            orders       : conditions.orders
        }

    }
};

const checkLogicQuestion = (condition, question) => {
    if (condition && question) {
        return checkUseHeadingQuestion(question);
    }
    return ''
};

export const getHeadingNextQuestion = (question, questions) => {

    switch (question.type) {
        case 'Single': {
            return question.choices.map((choice) => {
                return checkLogicQuestion(choice.nextQuestion >= 0, questions[choice.nextQuestion])
            })
        }
        case 'YesNo': {
            let logic = {
                yes: '',
                no: '',
                general: ''
            };
            if (question.nextQuestionYes >= 0) {
                logic = {
                    ...logic,
                    yes: checkLogicQuestion(question.nextQuestionYes >= 0, questions[question.nextQuestionYes])
                }
            }
            if (question.nextQuestionNo >= 0) {
                logic = {
                    ...logic,
                    no: checkLogicQuestion(question.nextQuestionNo >= 0, questions[question.nextQuestionNo])
                }
            }
            return logic = {
                ...logic,
                general: checkLogicQuestion(question.nextQuestion >= 0, questions[question.nextQuestion])
            };
        }
        default:
            return checkLogicQuestion(question.nextQuestion >= 0, questions[question.nextQuestion])

    }
};
export const convertArrayItem = (items) => {
    if(typeof items === 'string') {
        return [parseInt(items)];
    }

    if(typeof items === 'object') {
        return items.map(item => {
            if(typeof item === 'string') {
                return parseInt(item);
            }
            return item
        });
    }
    return [];
};

export const deleteVisiblyQuestion = (questions) => {
    return questions.map(question => {delete question.visibility; return question;})
} ;

export const isLogicQuestion = (question) => {
    if(question.type === 'YesNo') {
        return typeof question.nextQuestionNo === 'number' ||
            typeof question.nextQuestionYes === 'number' ||
            typeof question.nextQuestion === 'number';
    }

    if(question.type === 'Single') {
        const singleQuestion = question.choices.map(item =>  {
            return {nextQuestion: item.nextQuestion}
        });
        const isLogicSingle = _.findIndex(singleQuestion, (o) => {return typeof o.nextQuestion === 'number'}) !== -1;
        return typeof question.nextQuestion === 'number' || isLogicSingle;

    }
    return typeof question.nextQuestion === 'number';
};
