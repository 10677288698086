import momentTZ from "moment-timezone";

const lang = localStorage.getItem('i18nextLng');
export default class ReportsService {

    constructor(axios) {
        this.axios = axios;
    }

    searchReportsByCondition(condition) {
        return this.axios.post(`/reports/report/${condition.template || "TemplateRecord"}?lang=${lang || "jp"}&timezone=${momentTZ.tz.guess()}`, condition)
            .then(res => {
                return res.data
            })
    }
}
