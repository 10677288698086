import React, {Component}        from 'react';
import {
    KirokuRadio,
    KirokuCardWeb,
    KirokuCardTitleWeb,
    KirokuCardBodyWeb, KirokuButton,
    KirokuButtonLink
}                                from '../../../../ui';
import {withNamespaces}          from 'react-i18next';
import PropTypes                 from 'prop-types';
import InputText                 from './InputText';
import {checkUseHeadingQuestion} from "../../../Surveys/SurveyEditor/SurveyService";
import {withSmoothScrolling}     from '../../../../ui/Scrolling.js';

@withSmoothScrolling
@withNamespaces()
class YesNoQuestion extends Component {

    state = {
        value    : this.props.value,
        validated: null,
    };

    componentDidMount() {
        this.setState({
            value   : this.props.value,
        });

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.question.index !== nextProps.question.index || this.props.value !== nextProps.value) {
            this.setState({
                value: nextProps.value
            });
        }
    }

    handleOnChange(e) {

        if(!e) {
            e = { target: {value: ''}};
        }

        this.setState({
            value    : e.target.value,
            validated: e.target.value ? e.target.value : null,
        });
        this.props.onAnswer({
            answer   : e.target.value,
            validated: e.target.value ? e.target.value : null,
            questionIndex: this.props.question.index
        });
        this.props.isCurrentQuestion(this.props.question.index);
    }

    checkNextQuestionTo = (question) => {
        if (typeof question.nextQuestionYes === 'number' && this.props.value === 'Yes') {
            return question.nextQuestionYes - 1;
        } else if (typeof question.nextQuestionNo === 'number' && this.props.value === 'No') {
            return question.nextQuestionNo  - 1;
        } else if (typeof question.nextQuestion === 'number') {
            return question.nextQuestion  - 1;
        }
        return question.index;
    };

    handleMoveNextQuestion() {
        const {question} = this.props;
        let isNextQuestion = this.checkNextQuestionTo(question);
        this.props.smoothScroll.scrollTo(`question-${isNextQuestion }`);
        this.props.isCurrentQuestion(isNextQuestion + 1);
    }

    render() {
        const {question, t, disabled, isEndQuestion, currentQuestion} = this.props;
        const {value} = this.state;
        const contentQuestion = checkUseHeadingQuestion(question);
        return (
            <div id='component-question'>
                <div id='web-q' className='pb-3'>
                    <KirokuCardWeb style={{ borderColor: disabled ? '#dfe2e5' : value ? '#d6e9c6' : (!question.unrequited ? '#ebccd1' : '#dfe2e5')}}>
                        <KirokuCardTitleWeb color={disabled ? 'disable-question' :
                            ( value ? 'question-has-answer': (!question.unrequited ? 'question-required-answer' : 'card-web'))}
                        >
                            <div className='number-question d-flex justify-content-between'
                                 style={{ paddingBottom: 15, alignItems : 'center'}}
                            >
                                <div className={` text-overflow-elip ${disabled ? 'question-number' : value ? 'font-text-question-has-answer' : (!question.unrequited ? 'text-question-required-answer' : 'question-number')}`}>
                                    {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                                </div>
                                <div hidden={disabled}>
                                    <KirokuButtonLink
                                        className='padding-reset-button-link'
                                        onClick={() => {this.handleOnChange()}}
                                    >
                                        {t('createRecord.resetAnswer')}
                                    </KirokuButtonLink>
                                </div>
                            </div>

                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb>
                            <div className={disabled ? 'wrap-overlay-action cursor-disable' : ''}>
                                <span className='content-name-question'>{question.content}</span>
                                <div className='answer-question' style={{padding: 0, marginTop: '20px'}}>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <KirokuRadio onChange={disabled ? () => {} : this.handleOnChange.bind(this)}
                                                         id={`${question.index}yes`} value={'Yes'} name={`questionYesNo${question.index}`}
                                                         checked={value === 'Yes'}
                                                         style={{width: '100%'}}
                                            >
                                                <span style={{color : '#495057'}}>{t('createRecord.Yes')}</span>
                                            </KirokuRadio>
                                            <KirokuRadio onChange={disabled ? () => {} : this.handleOnChange.bind(this)}
                                                         id={`${question.index}no`} value={'No'} name={`questionYesNo${question.index}`}
                                                         checked={value === 'No'}
                                                         style={{width: '100%'}}
                                            >
                                                <span style={{color : '#495057'}}>{t('createRecord.No')}</span>
                                            </KirokuRadio>
                                        </div>
                                        <div/>
                                        <div hidden={ disabled || isEndQuestion || question.index !== currentQuestion}>
                                            <KirokuButton className="btn-kiroku-primary" onClick={this.handleMoveNextQuestion.bind(this)}>
                                                {this.props.t('common.nextToQuestion')}
                                            </KirokuButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                </div>
            </div>
        );
    }
}

InputText.propTypes = {
    value: PropTypes.string
};
export default YesNoQuestion;
