import {Component}        from "react";
import React              from "react";
import KirokuDropdownItem from "./KirokuDropdownItem";
import KirokuInputSearch  from "./KirokuInputSearch";
import ShortText          from "./ShortText";

export default class KirokuDropdownSearch extends Component {

    state = {
        selected: null,
        itemsShow: [],
        itemsSearch: [],
    };

    constructor() {
        super();

        this.handleClick        = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            popupVisible: false
        };
    }

    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
    }

    handleOutsideClick(e) {

        if (!this.node) {
            return;
        }

        if (this.node.contains(e.target)) {
            return;
        }

        this.handleClick();
    }

    handleSelected(event) {
        this.props.onSelect(event);
        this.setState({
            selected: event.kirokuSelected
        });
    }

    filterItems(e) {
        let input = e.target.value.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
        let items = this.state.itemsShow.filter(item =>
            item.label.toLowerCase().search(input.toLowerCase()) !== -1);
        this.setState({
            itemsSearch: items
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.items.length) {
            this.setState({
                itemsShow: nextProps.items,
                itemsSearch: nextProps.items
            });
        }
    }

    render() {
        const selectedLabel = this.state.selected ? this.state.selected.label : this.props.title;
        return (
            <div ref={node => {this.node = node}}>
                <div className="dropdown">
                    <button style={this.props.style} className="btn btn-kiroku" onClick={this.handleClick.bind(this)}>
                        <i className={`${this.props.icon} left text-kiroku pr-5px`}/>
                        <span className={'text-kiroku left'}><ShortText text={selectedLabel} length={17}/></span>
                        <i className='fa fa-chevron-down icon-kiroku'/>
                    </button>
                    {this.state.popupVisible && (
                        <div
                            className={`dropdown-kiroku dropdown-menu show ${this.props.location ? this.props.location : ''}`}
                            style={{position: 'absolute', willChange: 'top, left', left: 'auto', top: 'auto', width: `${this.props.widthIteam}`}}>
                            <div className='dropdown-item-kiroku bg-light p-2'>
                                <KirokuInputSearch onChange={this.filterItems.bind(this)}/>
                            </div>
                            <section className='scroll-data-dropdown-search'>
                                {this.state.itemsSearch.map((item, index) => {
                                    const Item = item.component;
                                    return (
                                        <KirokuDropdownItem key={index} onClick={(event) => {
                                            event.kirokuSelected = item;
                                            this.handleClick(event);
                                            this.handleSelected(event)
                                        }}>

                                            {/*<ShortText text={Item.props.children} length={20}/>*/}
                                            {Item}
                                        </KirokuDropdownItem>
                                    )
                                })}
                            </section>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
