import {Component}                                                    from "react";
import React                                                          from "react";
import {KirokuCertificate, KirokuStaffTagList, KirokuAge, KirokuDate} from "../../../ui";
import {withNamespaces}                                               from "react-i18next";
import moment                                                         from "moment";
import config                                                         from "../../../config";

@withNamespaces()
class CustomerProfileMobile extends Component {
    render() {
        const customer = this.props.customer;
        let {t}        = this.props;
        return (
            <div>
                <div className='card p-3'>
                    <h5>{t('CustomerEditor.PersonalInformation')}</h5>
                    <div className='mb-10'>
                        <span className='customer-info'>{t('common.Name')}:</span>
                        <br/>
                        <span className='personal-info'>{customer.name}</span>
                    </div>
                    <div className='mb-10'>
                        <span className='customer-info'>{t('common.Age')}:</span>
                        <br/>
                        <span className='personal-info'>
                                 <KirokuAge dob={customer.date_of_birth}/>
                            </span>
                    </div>
                    {/*<div className='mb-10'>*/}
                    {/*    <span className='customer-info'>{t('CustomerEditor.PhoneNumber')}:</span>*/}
                    {/*    <br/>*/}
                    {/*    <span className='personal-info'>{customer.phone_number}</span>*/}
                    {/*</div>*/}
                    <div className='mb-10'>
                        <span className='customer-info'>{t('CustomerEditor.BloodType')}:</span>
                        <br/>
                        <span
                            className='personal-info'>{(customer.blood_type === 'Unknown' ? t('CustomerEditor.Unknown') : customer.blood_type) || 'Unknown'}</span>
                    </div>
                    <div className='mb-10'>
                        <span className='customer-info'>{t('CustomerEditor.PostCode')}:</span>
                        <br/>
                        <span className='personal-info'>{customer.post_code}</span>
                    </div>
                    <div className='mb-10'>
                        <span className='customer-info'>{t('common.Address')}:</span>
                        <br/>
                        <span className='personal-info'>{customer.address}</span>
                    </div>
                    <div className='mb-10'>
                        <span className='customer-info'>{t('CustomerEditor.CustomerGender')}:</span>
                        <br/>
                        <span className='personal-info'>{t(`CustomerEditor.${customer.gender}`)}</span>
                    </div>
                    {/*<div className='mb-10'>*/}
                    {/*    <span className='customer-info'>{t('CustomerEditor.CustomerCode')}:</span>*/}
                    {/*    <br/>*/}
                    {/*    <span className='personal-info'>{customer.customer_code}</span>*/}
                    {/*</div>*/}
                    <div className='mb-10'>
                        <span className='customer-info'>{t('common.Characteristic')}:</span>
                        <br/>
                        <span className='personal-info'>{customer.characteristic}</span>
                    </div>
                    {/*<div className='mb-10'>*/}
                    {/*    <span className='customer-info'>{t('CustomerEditor.EmergencyContact')}:</span>*/}
                    {/*    <br/>*/}
                    {/*    <span className='personal-info'>{customer.emergency_contact}</span>*/}
                    {/*</div>*/}
                    {/*<div className='mb-10'>*/}
                    {/*    <span className='customer-info'>{t('CustomerEditor.Certificate')}:</span>*/}
                    {/*    <br/>*/}
                    {/*    <span className='personal-info'>{customer.certification_number}</span>*/}
                    {/*</div>*/}
                </div>
                <div className='mt-2'>
                    <div className='card p-3'>
                        <h5>{t('CustomerEditor.CustomerInformation')}</h5>
                        <div className='mb-10'>
                            <span className='customer-info'>{t('CustomerEditor.TeamName')}:</span>
                            <br/>
                            <span className='personal-info'>{customer.team ? customer.team.name : ''}</span>
                        </div>
                        {/*<div className='mb-10'>*/}
                        {/*    <span className='customer-info'>{t('CustomerEditor.TermCertificationStart')}:</span>*/}
                        {/*    <br/>*/}
                        {/*    <span className='personal-info'><KirokuDate date={customer.certification_start_date}*/}
                        {/*                                                timestamp={true}/></span>*/}
                        {/*</div>*/}
                        <div className='mb-10'>
                            <span className='customer-info'>{t('CustomerEditor.StartDateOfContact')}:</span>
                            <br/>
                            <span className='personal-info'><KirokuDate date={customer.contract_start}
                                                                        timestamp={true}/></span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info'>{t('CustomerEditor.EndDateOfContact')}:</span>
                            <br/>
                            <span className='personal-info'><KirokuDate date={customer.contract_end} timestamp={true}/> </span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info'>{t('CustomerEditor.SupportClassification')}:</span>
                            <br/>
                            <span
                                className='personal-info'>{(customer.classification === 0 ? t('CustomerEditor.NotApplicable') : customer.classification) || 0}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info'>{t('CustomerEditor.StaffInCharge')}:</span>
                            <span className='personal-info'>
                                <KirokuStaffTagList type={"users"} listTags={customer.staffs}/>
                             </span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info'>{t('common.team')}:</span>
                            <span className='personal-info'>
                                <KirokuStaffTagList type={"teams"} listTags={customer.teams}/>
                             </span>
                        </div>
                        {/*<div className='mb-10'>*/}
                        {/*    <span className='customer-info'>{t('CustomerEditor.TermCertificationEnd')}:</span>*/}
                        {/*    <br/>*/}
                        {/*    <span className='personal-info'><KirokuDate date={customer.certification_end_date}*/}
                        {/*                                                timestamp={true}/></span>*/}
                        {/*</div>*/}
                        <div className='mb-10'>
                            <span className='customer-info'>{t('CustomerEditor.KindOfCertificate')}:</span>
                            <span className='personal-info'>
                                <KirokuCertificate certificate={customer.certificate}/>
                            </span>
                        </div>
                    </div>
                </div>

                <div className='mt-2 card p-3'>
                    <h5>{t('CustomerEditor.contact.ContactInformation')}</h5>
                    {customer.contacts.length ? customer.contacts.map((contact, index) => (
                            <div className='' key={index}>
                                <div className='mb-10'>
                                    <span className='customer-info'>{t('common.Name')}:</span>
                                    <br/>
                                    <span className='personal-info'>{contact.name}</span>
                                </div>
                                <div className='mb-10'>
                                    <span className='customer-info'>{t('common.Relationship')}:</span>
                                    <br/>
                                    <span
                                        className='personal-info'>{t(`CustomerEditor.contact.${contact.relationship}`)}</span>
                                </div>
                                <div className='mb-10'>
                                    <span className='customer-info'>{t('common.Phone')}:</span>
                                    <br/>
                                    <span className='personal-info'>{contact.phone_number}</span>
                                </div>
                                <div className='mb-10'>
                                    <span className='customer-info'>{t('common.Memo')}:</span>
                                    <br/>
                                    <span className='personal-info'>{contact.memo}</span>
                                </div>
                                <hr/>
                            </div>
                        )) :
                        <div className=''>
                            <div className='mb-10'>
                                <span className='customer-info'>{t('common.Name')}:</span>
                                <br/>
                            </div>
                            <div className='mb-10'>
                                <span className='customer-info'>{t('common.Relationship')}:</span>
                                <br/>
                            </div>
                            <div className='mb-10'>
                                <span className='customer-info '>{t('common.Phone')}:</span>
                                <br/>
                            </div>
                            <div className='mb-10'>
                                <span className='customer-info'>{t('common.Memo')}:</span>
                                <br/>
                            </div>
                            <hr/>
                        </div>}
                </div>

                {customer.certifications.length ? customer.certifications.map((certification, index) => {
                    return (<div className='mt-2' key={index}>
                        <div className='card p-3'>
                            <h5>{t('CustomerEditor.Certification')}</h5>
                            <div className='mb-10'>
                                <span className='customer-info'>{t('CustomerEditor.CertificationNumber')}: <span
                                    className='personal-info'>{certification.certification_number}</span></span>
                                <br/>
                                <span
                                    className='customer-info'>{t('CustomerEditor.CertificationIssuer')}: <span
                                    className='personal-info'>{certification.issuer}</span></span>
                            </div>
                            <div className='mb-10'>
                                <span className='customer-info'>{t('CustomerEditor.Service')}:</span>
                                <br/>
                                <ul>
                                    {certification.services.length ? certification.services.map((service, index) => {
                                        return (
                                            <li key={index} className={'pt-2'}>
                                                <div
                                                    className={'customer-info'}>{t('CustomerEditor.CertificationServiceName')}: <span
                                                    className='personal-info'>{service.name}</span></div>
                                                <div
                                                    className={'customer-info'}>{t('CustomerEditor.CertificationServiceStartDate')}: <span
                                                    className='personal-info'>{service.startDate ? moment.unix(service.startDate).format(config.formatDateTh): ''}</span>
                                                </div>
                                                <div
                                                    className={'customer-info'}>{t('CustomerEditor.CertificationServiceEndDate')}: <span
                                                    className='personal-info'>{service.endDate ? moment.unix(service.endDate).format(config.formatDateTh) : ''}</span>
                                                </div>
                                            </li>
                                        )
                                    }) : ''}
                                </ul>
                                <br/>
                                <span className='customer-info'>{t('CustomerEditor.monitoringTerms')}:</span>
                                <ul>
                                    {certification.monitoring_terms.length ? certification.monitoring_terms.map((monitoring_term, index) => {
                                        return (
                                            <li key={index} className={'customer-info'}>
                                                {moment.unix(monitoring_term.date).format('YYYY/MM')}
                                            </li>
                                        )
                                    }) : ''}
                                </ul>
                            </div>
                        </div>
                    </div>)
                }) : <div className='mt-2'>
                    <div className='card p-3'>
                        <h5>{t('CustomerEditor.Certification')}</h5>
                        <div className='mb-10'>
                                <span
                                    className='customer-info'>{t('CustomerEditor.CertificationNumber')}:</span>
                            <br/>
                            <span
                                className='customer-info'>{t('CustomerEditor.CertificationIssuer')}:</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info'>{t('CustomerEditor.Service')}:</span>
                            <br/>
                            <span className='customer-info'>{t('CustomerEditor.monitoringTerms')}:</span>
                        </div>
                    </div>
                </div>
                }
                <div className='mt-2'>
                    <div className='card p-3'>
                        <h5>{t('CustomerEditor.Guardianship')}</h5>
                        <div className='mb-10'>
                            <span className='customer-info'>{t('CustomerEditor.GuardianshipType')}:</span>
                            <br/>
                            <span
                                className='personal-info'>{customer.guardianship_type ? t(`CustomerEditor.guardianshipTypes.${customer.guardianship_type}`) : ''}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info'>{t('CustomerEditor.AppointedType')}:</span>
                            <br/>
                            <span className='personal-info'>{customer.appointed_type || ''}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info'>{t('CustomerEditor.CompetentCourt')}:</span>
                            <br/>
                            <span className='personal-info'>{customer.competent_court || ''}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info'>{t('CustomerEditor.DecisionDate')}:</span>
                            <br/>
                            <span className='personal-info'>
                                {!!customer.decision_date ? <KirokuDate date={customer.decision_date}
                                                                        timestamp={true}/> : ''}
                            </span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info'>{t('CustomerEditor.CaseNumber')}:</span>
                            <br/>
                            <span className='personal-info'>{customer.case_number || ''}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info'>{t('CustomerEditor.RegistrationNumber')}:</span>
                            <br/>
                            <span className='personal-info'>{customer.registration_number || ''}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info'>{t('CustomerEditor.PermanentAddress')}:</span>
                            <br/>
                            <span className='personal-info'>{customer.permanent_address || ''}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info'>{t('CustomerEditor.ResidenceCardAddress')}:</span>
                            <br/>
                            <span className='personal-info'>{customer.residence_card_address || ''}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info'>{t('CustomerEditor.FacilityName')}:</span>
                            <br/>
                            <span className='personal-info'>{customer.facility_name || ''}</span>
                        </div>
                        <div className='mb-10'>
                            <span className='customer-info'>{t('CustomerEditor.ReportMonth')}:</span>
                            <br/>
                            <span className='personal-info'>{customer.report_month || ''}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomerProfileMobile;
