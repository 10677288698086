export const GET_ORGANIZATION    = 'GET_ORGANIZATION';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';


export function getOrganization() {
    return {
        type: GET_ORGANIZATION
    }
}

export function updateOrganization(id, currentOrganization) {
    return {
        type: UPDATE_ORGANIZATION,
        id: id,
        currentOrganization: currentOrganization
    }
}