import { withNamespaces }          from 'react-i18next';
import connect                     from 'react-redux/es/connect/connect';
import { turnOffModalConfirmExit } from '../../../../actions/TakeSurvey';
import KirokuModal                 from '../../../../ui/KirokuModal';
import KirokuModalHeader           from '../../../../ui/KirokuModalHeader';
import KirokuModalFooter           from '../../../../ui/KirokuModalFooter';
import KirokuButton                from '../../../../ui/KirokuButton';
import React, { Component }        from 'react';
import { scrollService }           from '../../../../services';

@withNamespaces()
@connect(state => {
    return {
        modalConfirmExitWeb: state.TakeSurveyWeb.modalConfirmExitWeb,
        recordToEdit: state.KirokuAIRecord.recordToEdit,
    }
}, dispatch => {
    return {
        turnOffModalExit: () => {
            dispatch(turnOffModalConfirmExit())
        },
        defaultTakeRecordPage : () =>{
            dispatch({
                type: "DEFAULT_TAKE_RECORD_PAGE"
            })
        },
        unLockRecord: (id, actionType) => {
            dispatch({
                type: "UNLOCK_RECORD",
                id, actionType
            })
        }
    }

})
class ModalExit extends Component {

    closeModal() {
        this.props.turnOffModalExit()
    }

    goBack() {
        const { editor, actionType, customerId } = this.props;
        if(window.history.previous === "/records/create-record" || window.history.previous === "/records/new-create-record") {
            this.props.history.push(`/customers/customer-detail/${customerId || this.props.recordToEdit.customer.id}`);
        } else if(window.history.previous === "/records-beta-detail") {
            this.props.history.push('/records-beta');
            window.history.previous = undefined;
            scrollService.top();
        } else{
            this.props.history.goBack();
        }
        if (editor && this.props.recordToEdit) {
            this.props.unLockRecord(this.props.recordToEdit.id, actionType)
        }
        window.history.previous = undefined;
        this.closeModal();
        this.props.defaultTakeRecordPage();
    }

    render() {
        const {t, modalConfirmExitWeb} = this.props;
        return (
            <KirokuModal show={modalConfirmExitWeb} onCloseModal={this.closeModal.bind(this)}>
                <KirokuModalHeader>
                    <div className={'text-center'}>
                        <div className='f-bold'>  {t('createRecord.AreYouSureYouWantToLeaveThisPage')}</div>
                        <div>{t('createRecord.ChangesYouMadeMayNotBeSaved')}</div>
                    </div>
                </KirokuModalHeader>
                <KirokuModalFooter>
                    <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                        {t('createRecord.No')}
                    </KirokuButton>
                    <KirokuButton color={'primary'} style={{width: 110}}
                                  onClick={this.goBack.bind(this)}>
                        {t('createRecord.Yes')}
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>
        );
    }
}

export default ModalExit;
















