import React, { Component } from "react";

export default class KirokuCard extends Component {
    render() {
        return (
            <div className='customize-media media media-single'>
                <div className="media-body">
                    {this.props.children}
                </div>
                <div className="media-right">
                    {this.props.action}
                </div>
            </div>
        )
    }
}