import {withNamespaces}    from 'react-i18next';
import AboutSurveyReview   from '../ReviewRecording/AboutSurvey';
import {ViewQuestioned}    from '../ReviewRecording/ViewQuestioned';
import KirokuButton        from '../../../ui/KirokuButton';
import KirokuButtonSpinner from '../../../ui/KirokuButtonSpinner';
import KirokuPageLoader    from '../../../ui/KirokuPageLoader';
import React, {Component}  from 'react';
import {connect}           from 'react-redux';
import {createRecord}      from "../../../actions/TakeSurvey";

@withNamespaces()
@connect(state => {
    return {
        reviewRecorded: state.TakeSurveyWeb.recorded,
        loadingSubmit: state.TakeSurveyWeb.loadingSubmit,
        customer: state.TakeSurveyWeb.customer,
        survey: state.TakeSurveyWeb.survey,
    }
}, dispatch => {
    return {
        createRecord : (record) => {
            dispatch(createRecord(record));
        }
    }
})
class ReviewRecoded extends Component {
    handleSubmit = () => {
        let {survey, customer, reviewRecorded} = this.props;
        let record = {
            surveyTemplate : {
                ...survey,
                flowedQuestions : reviewRecorded,
            },
            customer
        };
        this.props.createRecord(record);
        this.props.history.push('/customers');
    };
    handleBack = () => {
        this.props.history.goBack();
    };

    render() {
        const {survey, customer, t} = this.props;
        const {loadingSubmit, reviewRecorded} = this.props;
        return (
            <div>
                {reviewRecorded ?
                    <div className='survey'>
                        <AboutSurveyReview survey={survey} customer={customer} isRecord/>
                        <ViewQuestioned questions={reviewRecorded}/>
                        <div className="action-record">
                            <div>
                                <KirokuButton color={'light'} onClick={this.handleBack.bind(this)}>
                                    <span className="text-tranf">{t('createRecord.back')}</span>
                                </KirokuButton>
                                {loadingSubmit ?
                                    <KirokuButtonSpinner color={'primary'} style={{height: 42}}/>
                                    :
                                    <KirokuButton color={'primary'} onClick={this.handleSubmit.bind(this)}>
                                        <span className="text-tranf">{t('createRecord.Submit')}</span>
                                    </KirokuButton>
                                }
                            </div>
                        </div>
                    </div> : <KirokuPageLoader/>
                }

            </div>
        );
    }
}

export default ReviewRecoded;
