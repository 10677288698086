import React, {Component} from 'react';
import CheckDivide        from "../../../ui/CheckDivide";
import DuplicateMobile    from "./Mobile/DuplicateSurveyMobile";
import DuplicateWeb       from "./Web/DuplicateSurvey";
import KirokuTitlepage    from "../../../ui/KirokuTitlepage";
import {withNamespaces}   from "react-i18next";
import connect            from "react-redux/es/connect/connect";
import {currentPage}      from "../../../actions/CurrentPage";
import {KirokuLoading}    from "../../../ui";
import {detailSurvey}     from "../../../actions/SurveyTemplate";

@withNamespaces()
@connect(state => {
    return {
        currentPage: state.KirokuAITeam.currentPage,
        survey: state.KirokuAISurveyTemplate,
        loading: state.Loading,
        totalPage: state.KirokuAITeam.totalPage,
    }
}, dispatch => {
    return {
        pageNow: (name) => {
            dispatch(currentPage(name))
        },
        detail: surveyId => dispatch(detailSurvey(surveyId)),
    }
})

class DuplicateSurvey extends Component {
    page = {
        title: <KirokuTitlepage title={this.props.t('createSurvey.CreateNewSurvey')}/>,
        actionPage: null
    };

    state = {
        namePage: this.page,
    };

    componentWillMount() {
        this.props.pageNow(this.state.namePage);
        this.props.detail(this.props.match.params.id);
    }

    render() {
        return (
            this.props.survey.id ? (
                <CheckDivide mobile={<DuplicateMobile id={this.props.match.params.id} history={this.props.history}/>}
                             web={<DuplicateWeb id={this.props.match.params.id} history={this.props.history}/>}/>
            ) : (
                <KirokuLoading/>
            )

        )
    }
}

export default DuplicateSurvey;