import React, {Component}                       from 'react';
import { CheckRerenderResponsive } from "../../../ui";
import StaffListMobile                          from "./Mobile/StaffListMobile";
import StaffListWeb                    from "./StaffListWeb";
import {scrollService}                 from "../../../services";
import KirokuTitlepage                 from "../../../ui/KirokuTitlepage";
import {withNamespaces}                from "react-i18next";
import connect                         from "react-redux/es/connect/connect";
import {currentPage}                   from "../../../actions/CurrentPage";
import {loadTeam}                      from "../../../actions/Team";
import {loadCustomerAll}               from "../../../actions/Customer";
import KirokuLoading                   from "../../../ui/KirokuLoading";
import {loadUserPagination, loadUsers} from "../../../actions/User";

@withNamespaces()
@connect((state) => {
    return {
        customers: state.KirokuAICustomer.customers,
        profile: state.KirokuAIUser.profile,
        allTeam: state.KirokuAITeam.allTeam,
        listAllCustomer: state.KirokuAICustomer.ListAllCustomer,
        isLoading: state.KirokuAITeam.isLoading,
        loadingAllCustomer: state.KirokuAICustomer.loadingAllCustomer
    }
}, dispatch => {
    return {
        currentPageUsed: (name) => {
            dispatch(currentPage(name))
        },
        loadUserPagination: (defaultPage, tags) => {
            dispatch(loadUserPagination(defaultPage, tags))
        },
        loadTeam: () => {
            dispatch(loadTeam())
        },
        loadCustomerAll: () => {
            dispatch(loadCustomerAll())
        },
        loadUsers: () => {
            dispatch(loadUsers())
        }
    };
})
class Staffs extends Component {
    page = {
        title: <KirokuTitlepage title={this.props.t('common.staff')}/>,
    };

    componentWillMount() {
        scrollService.top();
        this.props.currentPageUsed(this.page);
        this.props.loadCustomerAll();
        this.props.loadTeam();
        this.props.loadUsers()
    }

    render() {
        const {listAllCustomer, allTeam} = this.props;
        let mobile                       = <StaffListMobile customers={listAllCustomer} teams={allTeam}/>;
        let web                          = <StaffListWeb customers={listAllCustomer} teams={allTeam}/>;
        return (
            <div>
                {this.props.isLoading && this.props.loadingAllCustomer ?
                    <CheckRerenderResponsive mobile={mobile} web={web}/> : <KirokuLoading/>}
            </div>
        )
    }
}

export default Staffs;
