import React, {Component}                                             from 'react';
import {connect}                                                      from "react-redux";
import {resetStateSurvey, createQuestion, editSurveyTemplate, showReview} from "../../../../actions/SurveyTemplate";
import {createSurvey}                                                 from "../../../../actions/Survey";
import ReviewSurvey                                                   from "../../Web/surveys/ReviewSurvey";
import ComponentSurveyEditor                                          from "../../Web/surveys/ComponentSurveyEditor";
import {withNamespaces}                                               from "react-i18next";

@withNamespaces()
@connect(state => {
    return {
        survey: state.KirokuAISurveyTemplate,
        loading: state.Loading,
        showReview: state.KirokuAISurveyTemplate.showReview
    }
}, dispatch => {
    return {
        editSurveyTemplate: surveyTemplate => dispatch(editSurveyTemplate(surveyTemplate)),
        createQuestion: question => dispatch(createQuestion(question)),
        createSurvey: () => dispatch(createSurvey(true)),
        showComponentReview: () => dispatch(showReview()),
        reset : () => dispatch(resetStateSurvey())
    }
})
class EditorSurveyWeb extends Component {

    createSurvey = () => {
        this.props.createSurvey();
        this.props.history.push('/surveys');
    };

    showReview = () => {
        this.props.showComponentReview()
    };

    componentWillUnmount() {
        this.props.reset();
    }

    render() {
        const {t} = this.props;
        return (
            <div className='default-background-color'>
                {

                    this.props.showReview ? (<ComponentSurveyEditor history={this.props.history}
                                                                    handleSave={this.showReview.bind(this)}
                                                                    nameButtonSubmit={t('createSurvey.Review')}
                        />) :
                        (<ReviewSurvey history={this.props.history} handleSubmit={this.createSurvey.bind(this)}/>)
                }
            </div>
        );
    }
}

export default EditorSurveyWeb;
