import React, {Component}                         from 'react';
import {KirokuButton, KirokuCard, KirokuCardList} from "../../../../ui";
import {filePathService}                          from "../../../../services";
import TabResult                                  from "../../../Staffs/StaffEditor/TabResult";
import {withNamespaces}                           from "react-i18next";
import {Link}                                     from "react-router-dom";
import moment                                     from "moment";
import KirokuLabel                                from "../../../../ui/KirokuLabel";
import KirokuCardAction                           from "../../../../ui/KirokuCardAction";
import KirokuPopoverItem                          from "../../../../ui/KirokuPopoverItem";

class ActionPageCustomerDetail extends Component {

    constructor(props) {
        super(props);

        this.handleClick        = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            popupVisible: false
        };
    }

    handleClick() {
        if (!this.state.popupVisible) {
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible
        }));
    }

    handleOutsideClick() {
        if (!this.node) {
            return;
        }
        this.handleClick()
    }

    render() {
        return (
            <div hidden={this.props.hidden} className="action-customer-surveys" ref={node => {
                this.node = node
            }}>
                <KirokuCardAction>
                    <div className="action-icon" onClick={this.handleClick.bind(this)}>
                        <i style={{color: '#1991eb'}} className="fas fa-ellipsis-h "/>
                        {this.state.popupVisible && (
                            <div onClick={this.handleClick.bind(this)}>
                                {this.props.children}
                            </div>
                        )}
                    </div>
                </KirokuCardAction>
            </div>
        )
    }
}

@withNamespaces()
class TabSurveysMobile extends Component {

    closeModal() {
        this.props.closeModal()
    }

    renderSurvey(survey, t) {
        const startDay = moment().startOf('day').unix();
        const endDay   = moment().endOf('day').unix();
        return (
            <KirokuCard key={survey.id} action={
                <ActionPageCustomerDetail surveyId={survey.id}>
                    <KirokuPopoverItem>
                        <div className={'d-flex justify-content-between p-1 fix-icon-align'}>
                            <Link
                                  to={{
                                      pathname: `/analysis`,
                                      state: {
                                          surveyId: survey.id,
                                          surveyName: survey.title,
                                          customerId: this.props.customer.id,
                                          customerName: this.props.customer.name
                                      }
                                  }}
                            >
                                <i className={'fa fa-line-chart pr-1'}/>
                                <span>{this.props.t('common.Analysis')}</span>
                            </Link>
                            <div className="p-1"/>
                            <Link
                                to={{
                                    pathname: `/records/new-create-record`,
                                    state: {
                                        surveyId: survey.id,
                                        customerId: this.props.customer.id
                                    }
                                }}
                            >
                                <i className="fas fa-file-signature pr-1"/>
                                <span>{this.props.t('common.Records')}</span>
                            </Link>
                            <div className="p-1"/>
                            <Link
                                to={{
                                      pathname: `/records/create-record`,
                                      state: {
                                          surveyId: survey.id,
                                          customerId: this.props.customer.id
                                      }
                                  }}
                            >
                                <i className="fas fa-pencil-alt pr-1"/>
                                <span> {this.props.t('common.RecordsNew')}</span>
                            </Link>
                        </div>
                    </KirokuPopoverItem>
                </ActionPageCustomerDetail>
            }>
                <span>{survey.title}&nbsp;&nbsp;</span>
                <span className='ml-6'>
                    {
                        (survey._pivot_record_at > startDay && survey._pivot_record_at < endDay) ?
                            <KirokuLabel
                                hidden={!(survey._pivot_record_at)}
                                style={{
                                    backgroundColor: '#337ab7',
                                    color: 'white'
                                }}>
                                {t('CustomerEditor.Recorded')}
                            </KirokuLabel> : ''
                    }
                </span>
                <span className='ml-6'>
                    {survey['_pivot_drafted_at'] ?
                        <KirokuLabel style={{
                            backgroundColor: '#f7981c',
                            color: 'white'
                        }}>{t('common.haveDraft')}</KirokuLabel> : ''}
                </span>
            </KirokuCard>
        )
    }

    render() {
        const {customer, t} = this.props;
        return (
            <div hidden={this.props.hidden} className='pb-5'>
                <KirokuCardList headerLeft={<span>{t('common.Surveys')}</span>}
                                headerRight={<span>{t('common.Action')}</span>}>
                    {customer.surveys.length ? customer.surveys.map(survey => this.renderSurvey(survey, t, customer)) :
                        <div>
                            <TabResult messageFirst={'survey'}>
                                <img
                                    className='pt-2 pb-2'
                                    src={`${filePathService.resolveFilePath('empty-survey.svg')}`}
                                    alt="error img"/>
                            </TabResult>
                            <div className='text-center pb-3'>
                                <KirokuButton color='primary' onClick={this.closeModal.bind(this)}>
                                    {t('Customer.AssignARecordingToCustomer')}
                                </KirokuButton>
                            </div>
                        </div>
                    }
                </KirokuCardList>
            </div>
        )
    }
}

export default TabSurveysMobile;
