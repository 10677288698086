import { combineReducers }          from 'redux';
import ModalSelectCustomers         from './ModalSelectCustomers';
import ModalSelectStaffs            from './ModalSelectStaffs';
import ModalSelectSurveys           from './ModalSelectSurveys';
import ModalViewDifferenceQuestions from './ModalViewDifferenceQuestions';

export default combineReducers({
    ModalSelectCustomers,
    ModalSelectStaffs,
    ModalSelectSurveys,
    ModalViewDifferenceQuestions
})
