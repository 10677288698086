import _ from 'lodash';

class AnalysisService {

    constructor(axios) {
        this.axios  = axios;
    }

    query(customerId, surveyId, startDate, endDate) {
        return this.axios.get(`/record-analytics?customerId=${customerId}&surveyId=${surveyId}&startDate=${startDate}&endDate=${endDate}`);
    }

    isOldData(records) {
        return records.map(record =>  !! _.filter(record.survey_template.questions, (question) => {
            return (question.type === 'Single' || question.type === 'MultiChoice') && (typeof question.choices[0]) === 'string';
            }
        ).length)
    }

}

export default AnalysisService;
