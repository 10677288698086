import axios            from 'axios';
import config           from '../config';
import store            from '../store';
import { hidden, show } from '../actions/LoadingAction';
import HandleError      from './HandleError';

const axiosConfigIsLoading = axios.create({
    baseURL: config.domain,
    timeout: 10000,
});

axiosConfigIsLoading.interceptors.request.use(axiosConfig => {
    store.dispatch(show());
    axiosConfig.headers.Authorization = localStorage.getItem('token') || '';
    return axiosConfig;
}, error => {
    return Promise.reject(error);
});

axiosConfigIsLoading.interceptors.response.use(
    response => {
        store.dispatch(hidden());
        return response;
    }, error => {
        store.dispatch(hidden());
        HandleError(error);
        return Promise.reject(error);
    },
)
;

export default axiosConfigIsLoading;
