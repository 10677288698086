import _ from "lodash";

const defaultApplicationSate = {
    profile: JSON.parse(localStorage.getItem('profile')),
    recordNewExport: [{
        id: 1,
        survey_template: {
            description: ''
        },
        customer: {name: ''},
        user: {name: ''}
    }],
    totalPage: 1,
    currentPage: 1,
    totalRecords : 1,
    statusCheck: false,
    conditionRemove: [
        {value: "", label: "", query: ""}
    ],
    isRemoved: false
};

const KirokuAIExport = (state = defaultApplicationSate, action) => {

    switch (action.type) {
        case 'NEW_EXPORT':
            return {
                ...state,
                recordNewExport: action.recordNewExport,
                currentPage: action.currentPage,
                totalPage: action.totalPage,
                totalRecords: action.totalRecords
            };
        case 'CHECK_ALL_RECORDS':
            let newRecords = state.recordNewExport.map(record => {
                return {
                    ...record,
                    isChecked: !state.statusCheck
                }
            });
            return {
                ...state,
                statusCheck: !state.statusCheck,
                recordNewExport: newRecords
            };
        case 'SELECTED_RECORD_NEW_EXPORT':
            let recordSelected                     = state.recordNewExport.map(record => ({
                ...record,
                isChecked: record.isChecked ? record.isChecked : false
            }));
            recordSelected[action.index].isChecked = !recordSelected[action.index].isChecked;
            return {
                ...state,
                recordNewExport: recordSelected,
                statusCheck: _.findIndex(recordSelected, ['isChecked', false]) === -1
            };
        case 'RESET_CHECKED_ALL':
            return {
                ...state,
                statusCheck: false
            };

        case 'CONDITION_REMOVED':
            return {
                ...state,
                conditionRemove: action.conditions,
                isRemoved: action.isRemoved
            };
        case 'UPDATE_NEW_STATUS_REMOVED' :
            return {
                ...state,
                isRemoved: false
            };
        default:
            return state
    }
};

export default KirokuAIExport;
