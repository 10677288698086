class SurveyFactory {

    makeEdit(rawSurvey) {
        return {
            id : rawSurvey.id,
            title: rawSurvey.title,
            form_number: rawSurvey.form_number,
            description: rawSurvey.description,
            questions: rawSurvey.questions.map(question => this.makeQuestionByType(question)),
            customers: rawSurvey.customers
        }
    }

    makeCreate(rawSurvey) {
        return {
            title: rawSurvey.title,
            form_number: rawSurvey.form_number,
            description: rawSurvey.description,
            questions: rawSurvey.questions.map(question => this.makeQuestionByType(question)),
        }
    }

    makeQuestionByType (question)  {
        let {type, choices, ...other} = question;
        switch (type) {
            case 'Single':
            case 'MultiChoice' :
                return {
                    type,
                    choices,
                    ...other
                };
            default :
                return {
                    type,
                    ...other
                }
        }
    };
}

export default SurveyFactory;