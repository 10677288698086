import React, {Component}           from 'react';
import CheckDivide                  from "../../../ui/CheckDivide";
import SurveyCreatorMobile            from "../Mobile/CreatorSurvey/CreatorSurvey";
import SurveyCreatorWeb               from "../Web/surveys/SurveyCreator";
import KirokuTitlepage              from "../../../ui/KirokuTitlepage";
import {withNamespaces}             from "react-i18next";
import connect                      from "react-redux/es/connect/connect";
import {currentPage}                from "../../../actions/CurrentPage";

@withNamespaces()
@connect(state => {
    return {
        currentPage: state.KirokuAITeam.currentPage,
        totalPage: state.KirokuAITeam.totalPage,
    }
}, dispatch => {
    return {
        pageNow: (name) => {
            dispatch(currentPage(name))
        },
    }
})

class SurveyCreator extends Component {
    page = {
        title: <KirokuTitlepage title= {this.props.t('createSurvey.CreateNewSurvey')}/>,
        actionPage: null
    };

    state = {
        namePage: this.page,
    };

    componentWillMount() {
        this.props.pageNow(this.state.namePage)
    }

    render() {
        return (
            <div className='default-background-color'>
                <CheckDivide mobile={<SurveyCreatorMobile history={this.props.history}/>}
                             web={<SurveyCreatorWeb history={this.props.history}/>}/>
            </div>
        )
    }
}

export default SurveyCreator;
