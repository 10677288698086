import React, {Component}                           from 'react';
import {KirokuButton, KirokuInput, KirokuInputArea} from "../../ui";
import {connect}                                    from "react-redux";
import {updateOrganization}                         from "../../actions/Organization";
import KirokuLoading                                from "../../ui/KirokuLoading";
import {withNamespaces}                             from "react-i18next";
import KirokuTitlepage                              from "../../ui/KirokuTitlepage";
import {currentPage}                                from "../../actions/CurrentPage";
import KirokuCardWeb                                from "../../ui/KirokuCardWeb";
import {organizationService}                        from "../../services";

@connect((state) => ({
    profile: state.KirokuAIOrganization.profile
}), (dispatch) => ({
    handleEditor: (currentOrganization) => {
        dispatch(updateOrganization(currentOrganization.id, currentOrganization))
    },
    currentPage: (name) => {
        dispatch(currentPage(name))
    }
}))
@withNamespaces()
class OrganizationWeb extends Component {

    page  = {
        title: <KirokuTitlepage title={this.props.t('common.Organization')}/>,
    };
    state = {
        organization: {},
        errors: {
            validationNumber: false
        },
        namePage: this.page
    };

    componentWillMount() {
        this.setState({
            isLoading: false
        });
        organizationService.getOrganization().then(res => {
            return this.setState({
                organization: res,
                isLoading: true
            })
        });
        this.props.currentPage(this.state.namePage)
    }

    handleChange(e) {
        const currentOrganization          = this.state.organization;
        currentOrganization[e.target.name] = e.target.value;
        this.setState({
            organization: currentOrganization,
            errors: {
                ...this.state.errors,
                [e.target.name]: !e.currentTarget.value.length
            }
        })
    }

    handleUpdateOrganization() {
        this.setState({
            organization: {
                ...this.state.organization,
                org_number: this.state.organization.org_number
            }
        }, () => {
            delete this.state.organization.org_number;
            this.props.handleEditor(this.state.organization)
        });
    }

    checkError() {
        return !Object.values(this.state.errors).every(item => !item)
    }

    handleChangeNumberOrganization(e) {
        let regex = e.target.value.replace(/[^0-9]/g, '');
        if (regex.length > 2) return;
        this.setState({
            organization: {
                ...this.state.organization,
                org_number: regex
            },
            errors: {
                ...this.state.errors,
                validationNumber: regex.length < 2
            }
        })
    }

    render() {
        let isAdmin  = this.props.profile.role;
        let {errors} = this.state;
        let {t}      = this.props;
        return (
            <>
                <div id='mobile' className='default-background-color'>
                    <div className='padding-responsive'>
                        <h5 className='pb-2'>{t('Organization.OrganizationDetail')}</h5>
                        {this.state.isLoading ?
                            <div className='card'>
                                <div className='p-3'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label className='mt-2'>{t('Organization.OrganizationName')}</label>
                                            <KirokuInput onChange={this.handleChange.bind(this)}
                                                         name={'name'}
                                                         value={this.state.organization.name || ''}
                                                         error={errors.name ? 'form-error' : ''}
                                                         disabled={isAdmin !== 'admin'}
                                            />
                                            <p hidden={!errors.name} style={{
                                                color: 'red',
                                                fontSize: 12
                                            }}>{t('errors.nameNotNull')}</p>
                                            {/*<label className='mt-2'>{t('Organization.OrganizationNumber')}</label>*/}
                                            {/*<KirokuInput*/}
                                            {/*    name='number_organization'*/}
                                            {/*    onChange={this.handleChangeNumberOrganization.bind(this)}*/}
                                            {/*    placeholder={''}*/}
                                            {/*    value={this.state.organization.org_number || ''}*/}
                                            {/*/>*/}
                                            {/*<p hidden={!errors.validationNumber} style={{*/}
                                            {/*    color: 'red',*/}
                                            {/*    fontSize: 12*/}
                                            {/*}}>{t('errors.organizationNumber')}</p>*/}
                                            <label className='mt-2'>{t('Organization.OrganizationDescription')}</label>
                                            <KirokuInputArea onChange={this.handleChange.bind(this)} name={'description'}
                                                             value={this.state.organization.description || ''}
                                                             disabled={isAdmin !== 'admin'}
                                            />
                                            <p hidden={!errors.description} style={{
                                                color: 'red',
                                                fontSize: 12
                                            }}>{t('errors.descriptionNotNull')}</p>
                                            <div className="mt-3">
                                                <KirokuButton
                                                    hidden={isAdmin !== 'admin'}
                                                    disabled={this.checkError()}
                                                    onClick={this.handleUpdateOrganization.bind(this)}
                                                    color='primary'>{t('common.Update')}</KirokuButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : <KirokuLoading/>}
                    </div>
                </div>
                <div id='web' className='default-background-color'>
                    <div className='padding-responsive'>
                        {this.state.isLoading ?
                            <KirokuCardWeb>
                                <div className='p-3'>
                                    <h5>{t('Organization.OrganizationDetail')}</h5>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label className='mt-2'>{t('Organization.OrganizationName')}</label>
                                            <KirokuInput onChange={this.handleChange.bind(this)}
                                                         name={'name'}
                                                         value={this.state.organization.name || ''}
                                                         error={errors.name ? 'form-error' : ''}
                                                         disabled={isAdmin !== "admin"}
                                            />
                                            <p hidden={!errors.name} style={{
                                                color: 'red',
                                                fontSize: 12
                                            }}>{t('errors.nameNotNull')}</p>
                                            {/*<label className='mt-2'>{t('Organization.OrganizationNumber')}</label>*/}
                                            {/*<KirokuInput*/}
                                            {/*    name='number_organization'*/}
                                            {/*    onChange={this.handleChangeNumberOrganization.bind(this)}*/}
                                            {/*    placeholder={''}*/}
                                            {/*    value={this.state.organization.org_number}*/}
                                            {/*/>*/}
                                            {/*<p hidden={!errors.validationNumber} style={{*/}
                                            {/*    color: 'red',*/}
                                            {/*    fontSize: 12*/}
                                            {/*}}>{t('errors.organizationNumber')}</p>*/}
                                            <label className='mt-2'>{t('Organization.OrganizationDescription')}</label>
                                            <KirokuInputArea onChange={this.handleChange.bind(this)} name={'description'}
                                                             value={this.state.organization.description || ''}
                                                             disabled={isAdmin !== "admin"}
                                            />
                                            <p hidden={!errors.description} style={{
                                                color: 'red',
                                                fontSize: 12
                                            }}>{t('errors.descriptionNotNull')}</p>
                                            <div className="mt-3">
                                                <KirokuButton
                                                    hidden={isAdmin !== "admin"}
                                                    disabled={this.checkError()}
                                                    onClick={this.handleUpdateOrganization.bind(this)}
                                                    color='primary'>{t('common.Update')}</KirokuButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </KirokuCardWeb> : <KirokuLoading/>}
                    </div>
                </div>
            </>
        )
    }
}

export default OrganizationWeb
