import { Component } from "react";
import React         from "react";

export default class KirokuButtonSpinner extends Component {
    render() {
        return (
            <button {...this.props} className={'btn btn-kiroku-' + this.props.color}>
                <div className="spinner"/>
                {this.props.children}
                </button>
        )
    }
}