import * as Sentry   from '@sentry/browser';
import store         from '../store';
import historyRouter from '../historyRouter';

const maintenanceCode = [502, 503, 504];


export function isErrorPermissionEditRecordLocking(error) {
    return error.response && error.response.status === 403
        && error.response.config.method === 'put'
        && error.response.config.url.search('/survey-record/') !== -1
}


export default (error) => {
    if (error) {
        if(
            !!error.config && !!error.config.url
            && error.config.url.search('lock-information/records') !== -1
            && (
                (error.message === 'timeout of 10000ms exceeded' && error.code === 'ECONNABORTED') ||
                error.message === 'Network Error'
            )
        ) {
            Sentry.captureException(error);
            if(store.getState().KirokuAINotification.show) {
                return null;
            }
            return store.dispatch({
                show            : true,
                type            : 'SET_NOTIFICATION',
                typeNotification: 'danger',
                message         : 'common.Unstable network connection',
                isNotAutomaticCloseNotice: true
            });
        }

        //not handle error of api update record with status 403
        if ( isErrorPermissionEditRecordLocking(error) ) {
            return null;
        }

        if (!error.response || !error.response.status) {
            Sentry.captureException(error);
            return store.dispatch({
                show            : true,
                type            : 'SET_NOTIFICATION',
                typeNotification: 'danger',
                message         : 'errors.UnexpectedError',
            });
        }
        if (maintenanceCode.includes(error.response.status)) {
            Sentry.captureException(error);
            historyRouter.push('/server-maintenance');
        }
        if (error.response.status === 500) {
            Sentry.captureException(error);
            historyRouter.push('/internal-server-error');
        }
    }

    if (error.response && +error.response.status === 403) {
        return window.location.reload();
    }

    if (error.response && +error.response.status === 401) {
        localStorage.removeItem('token');
        return window.location.replace('/login');
    }
}
