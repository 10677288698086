import {COMPARISON_PREVIOUS_TERM, DASHBOARD_ACTIVITIES, DASHBOARD_RESET, LOAD_DASHBOARD} from "../actions/Dashboard";

const defaultApplicationSate = {
    loadingDashboardSuccess: false,
    profile: JSON.parse(localStorage.getItem('profile')),
    dashboard: {},
    lastMonth: {
        isDecrease: false,
        newValue: 0,
        orginalValue: 0,
        percent: 0
    },
    lastWeek: {
        isDecrease: false,
        newValue: 0,
        orginalValue: 0,
        percent: 0
    },
    yesterday: {
        isDecrease: false,
        newValue: 0,
        orginalValue: 0,
        percent: null,
    },
    activitiesDashboard: {
        logs: [],
        resLogs: [],
        currentPage: 1
    },
};

const KirokuAIDashboard = (state = defaultApplicationSate, action) => {
    switch (action.type) {
        // case PIN_CUSTOMER:
        //     return  {
        //         ...state,
        //         loadingDashboardSuccess: false,
        //     };
        case LOAD_DASHBOARD :
            return {
                ...state,
                loadingDashboardSuccess: true,
                dashboard: action.dashboard,
                activitiesDashboard: {
                    ...state.activitiesDashboard,
                    ...action.dashboard['activities'],
                }
            };
        case COMPARISON_PREVIOUS_TERM:
            return {
                ...state,
                ...action
            };
        case DASHBOARD_ACTIVITIES:
            return {
                ...state,
                activitiesDashboard: {
                    ...action,
                    logs: [
                        ...state.activitiesDashboard.logs,
                        ...action.logs,
                    ],
                    resLogs: action.logs,
                    currentPage: action.currentPage
                }
            };
        case DASHBOARD_RESET: {
            return defaultApplicationSate;
        }
        default :
            return state
    }
};

export default KirokuAIDashboard;
