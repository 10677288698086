import React, { Component }                                  from 'react';
import connect                                               from 'react-redux/es/connect/connect';
import AboutSurvey                                           from '../../ReviewRecording/AboutSurvey';
import AnswerQuestion                                        from '../../RecordCreator/Web/AnswerQuestion';
import { withNamespaces }                                    from 'react-i18next';
import { discardDraftEditRecording, saveDraftEditRecording } from '../../../../actions/Draft';
import { updateRecord }                                      from '../../../../actions/Record';
import {
    turnOffFlagDraftEditRecordWeb,
    turnOfFlagEditingQuestion,
    turnOnLoadDraftEditRecord,
}                                                      from '../../../../actions/TakeSurvey';
// import { KirokuNotificationDraft }                           from '../../../../ui';
import moment                                          from 'moment';
import KiroNotificationBackup
                                                       from '../../newTakeRecord/RecordEditorFiledAll/KiroNotificationBackup';
import { recordService }                               from '../../../../services';
import { NOTIFICATION }                                from '../../../../actions/Notification';
import { deleteVisiblyQuestion, getAnswersToQuestion } from '../../sevicesInRecord';
import { isErrorPermissionEditRecordLocking }          from '../../../../axiosConfig/HandleError';

@withNamespaces()
@connect(state => {
    return {
        flagLoadDraftEditRecord: state.TakeSurveyWeb.flagLoadDraftEditRecord,
        recordToEdit           : state.KirokuAIRecord.recordToEdit,
        recordToEditBackup     : state.KirokuAIRecord.recordToEditBackup,
        dataDraftEditRecording : state.KirokuAIDraftRecording.dataDraftEditRecording,
    };
}, dispatch => {
    return {
        updateRecord              : (id, dataRecord) => {
            dispatch(updateRecord(id, dataRecord));
        },
        asyncUpdateRecord         : async (id, dataRecord) => {
            return recordService.updateRecord(id, dataRecord);
        },
        updateRecordFall          : () => {
            dispatch({
                type            : NOTIFICATION,
                typeNotification: 'danger',
                message         : 'createRecord.CreateRecordFail',
            });
        },
        updateRecordSuccess       : () => {
            dispatch(
                {
                    type            : NOTIFICATION,
                    typeNotification: 'success',
                    message         : 'createRecord.UpdateRecordSuccess',
                },
            );
        },
        saveDraftEditRecording    : (dataEditRecoding) => {
            dispatch(saveDraftEditRecording(dataEditRecoding));
        },
        discardDraftEditRecording : id => {
            dispatch(discardDraftEditRecording(id));
        },
        turnOffFlagDraftEditRecord: () => {
            dispatch(turnOffFlagDraftEditRecordWeb());
        },
        turnOnLoadDraftEditRecord : () => {
            dispatch(turnOnLoadDraftEditRecord());
        },
        turnOfFlagEditingQuestion : () => {
            dispatch(turnOfFlagEditingQuestion());
        },
        trackingQuickUpdateRecord : (record) => {
            dispatch({
                type: 'RECORD_QUICK_EDITED_TRACKING',
                record,
            });
        },
        trackingQuickDraftRecord  : (record) => {
            dispatch({
                type: 'RECORD_QUICK_DRAFTED_TRACKING',
                record,
            });
        },
        unLockRecord: (id, actionType) => {
            dispatch({
                type: "UNLOCK_RECORD",
                id, actionType
            })
        },
        pollingActionFirst: (lockableType, recordId) => {
            dispatch({
                type: 'LOCK_INFORMATION_FIRST',
                lockableType, recordId
            });
        },
    };
})
class RecordEditorWeb extends Component {

    state = {
        keyLoadData: null,  //keyLoadData: null(loadRecord) 'statusDraft'(loadDraft) 'backup'(loadDataBackup)
        serviced_at: this.props.recordToEdit.serviced_at ? this.props.recordToEdit.serviced_at : null,
        has_time   : this.props.recordToEdit.has_time,
    };

    handlerSaveDraft(flowedQuestions, questions) {
        const survey         = this.props.recordToEdit.survey_template;
        const surveyTemplate = {
            ...survey,
            questions      : deleteVisiblyQuestion(questions),
            flowedQuestions: deleteVisiblyQuestion(flowedQuestions),
        };

        const statusDataRecord = {
            surveyTemplate: surveyTemplate,
            customer      : this.props.recordToEdit.customer,
            hasDraft      : 'draft',
            draftBy       : JSON.parse(localStorage.getItem('profile')).id,
            draftedAt     : moment().unix(),
            serviced_at   : this.state.serviced_at,
            has_time      : this.state.has_time,
            answers       : getAnswersToQuestion(flowedQuestions),
        };
        this.props.updateRecord(this.props.recordToEdit.id, statusDataRecord);
        this.props.trackingQuickDraftRecord(statusDataRecord);
    }

    handlerSubmitTakeRecord(questionsRecord, questions) {
        const survey = this.props.recordToEdit.survey_template;

        const surveyTemplate = {
            ...survey,
            questions      : deleteVisiblyQuestion(questions),
            flowedQuestions: deleteVisiblyQuestion(questionsRecord),
        };

        const dataRecord = {
            surveyTemplate: surveyTemplate,
            customer      : this.props.recordToEdit.customer,
            hasDraft      : null,
            draftBy       : null,
            draftedAt     : null,
            serviced_at   : this.state.serviced_at,
            has_time      : this.state.has_time,
            answers       : getAnswersToQuestion(questionsRecord),
        };
        this.props.asyncUpdateRecord(this.props.recordToEdit.id, dataRecord).then(res => {
            this.props.unLockRecord(this.props.recordToEdit.id, 'records')
            this.props.trackingQuickUpdateRecord(dataRecord);
            this.props.updateRecordSuccess();
            if (window.history.previous === '/records/create-record') {
                window.history.previous = undefined;
                this.props.history.push(`/customers/customer-detail/${ this.props.recordToEdit.customer.id }`);
            } else {
                this.props.history.goBack();
            }
        }, error => {
            if(isErrorPermissionEditRecordLocking(error)) {
                this.props.pollingActionFirst('records', this.props.recordToEdit.id)
            }
            this.props.updateRecordFall();
        });

    }

    componentWillUnmount() {
        this.props.turnOffFlagDraftEditRecord();
        this.props.turnOfFlagEditingQuestion();
    }

    turnOnLoadDraftEditRecord() {
        this.setState({
            keyLoadData: 'statusDraft',
        });
    }

    turnOfLoadDraftEditRecord() {
        // this.props.discardDraftEditRecording(this.props.recordToEdit.id);
    }

    handleSaveBackupData(dataBackUpdate) {
        let draft = {
            id           : this.props.recordToEdit.id,
            // surveyTemplate: this.props.recordToEdit.survey_template,
            customer_id  : this.props.recordToEdit.customer.id,
            customer_name: this.props.recordToEdit.customer.name,
            dataDraft    : dataBackUpdate,
            create_at    : moment().unix(),
            serviced_at  : this.state.serviced_at,
            has_time     : this.state.has_time,
        };
        this.props.saveDraftEditRecording(draft);

    }

    onChangeDate = (serviced_at, hasTime) => {
        this.setState({
            serviced_at: hasTime ? serviced_at : moment.unix(serviced_at).startOf('day').unix(),
            has_time   : hasTime,
        });
    };

    confirmLoadBackupDraft() {
        this.props.turnOnLoadDraftEditRecord();
        this.setState({ keyLoadData: 'backup' });
    }

    render() {
        const { recordToEdit, dataDraftEditRecording, flagLoadDraftEditRecord, t, lockInfo } = this.props;
        const { keyLoadData }                                                                = this.state;

        return (
            <div className='padding-responsive default-background-color'>
                {/*Load status record*/ }
                {/*<KirokuNotificationDraft*/ }
                {/*    loadDraft={this.turnOnLoadDraftEditRecord.bind(this)}*/ }
                {/*    discardDraft={this.turnOfLoadDraftEditRecord.bind(this)}*/ }
                {/*    draft={window.history.previous === "/records/create-record" ? null : recordToEdit.statusRecord}*/ }
                {/*/>*/ }


                {/*Load draft record*/ }
                <div className="keeping-about-survey" style={ { top: 70, backgroundColor: '#eef3f6' } }>
                    <KiroNotificationBackup
                        confirm={ this.confirmLoadBackupDraft.bind(this) }
                        cancel={ () => {
                        } }
                        draft={ dataDraftEditRecording }
                    />

                    <div className='fix-center bg-white'>
                        <div style={ { width: '70%' } }>
                            <div id='about-survey' className='pt-4 pb-3'>
                                <AboutSurvey
                                    editor
                                    onChangeDate={ this.onChangeDate.bind(this) }
                                    user={ recordToEdit.user }
                                    survey={ recordToEdit['survey_template'] }
                                    customer={ recordToEdit.customer }
                                    serviced_at={ recordToEdit.serviced_at }
                                    hasTime={ recordToEdit.has_time }
                                    create_at={ recordToEdit.create_at }
                                    screen={ 'editRecord' }
                                    lockInfo={ lockInfo }
                                    isRecord/>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Survey description*/ }
                <div className="survey fix-center" style={ { backgroundColor: '#FFFFFF' } }>
                    <div className="pb-3" style={ { width: '70%' } }>
                        <div className='info-question'>
                            <div className='content-name-question d-flex'>
                                        <span style={ { wordBreak: 'keep-all' } }
                                              className='title-record-info'>{ t('createRecord.Description') }&nbsp;</span>
                                <pre style={ {
                                    wordWrap  : 'break-word',
                                    whiteSpace: 'pre-wrap',
                                } }>
                                            { recordToEdit['survey_template'].description }
                                        </pre>
                            </div>
                        </div>
                    </div>
                </div>
                <AnswerQuestion
                    editor
                    survey={ recordToEdit['survey_template'] }
                    history={ this.props.history }
                    createRecord={ this.handlerSubmitTakeRecord.bind(this) }
                    saveDraft={ this.handlerSaveDraft.bind(this) }
                    lockInfo={ lockInfo }
                    statusRecord={ {
                        statusDraft: recordToEdit.statusRecord,
                        backup     : flagLoadDraftEditRecord ? dataDraftEditRecording.dataDraft : null,
                    } }
                    keyLoadData={ keyLoadData }
                    // saveBackupData={this.handleSaveBackupData.bind(this)}
                    saveBackupData={ () => {
                    } }
                    customerId={this.props.recordToEdit.customer.id}
                />
            </div>
        );
    }
}

export default RecordEditorWeb;

