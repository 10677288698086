import { recordBeta } from '../services';


export const batchActionRecordBeta = (store) => next => action => {
    switch (action.type) {
        case 'LOAD_RECORD_BETA_FOR_BATCH_ACTION':
            return recordBeta.getRecordsByIds(action.body).then(res => {
                next({
                    ...action,
                    recordIds: res.recordIds,
                    records: res.records,
                    totalRecords: res.totalRecords,
                    useSortBy: action.body.orders[0].field,
                    useOrderBy: action.body.orders[0].type
                })
            });
        default: return next(action);
    }
};
