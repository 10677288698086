import {
    DateQuestion,
    DateTimeQuestion,
    InputText,
    MediaFileQuestion,
    Message,
    MultiChoice,
    SingleChoice,
    TimeQuestion,
    YesNoQuestion,
    NumberQuestion
}                                                       from '../../QuestionTypeWeb';
import { KirokuButton }                                 from '../../../../ui';
import { withNamespaces }                               from 'react-i18next';
import { connect }                                      from 'react-redux';
import CreatorLogic                                     from '../CreatorLogic';
import React, { Component }                             from 'react';
import '../CreatorLogic';
import { turnOnModalConfirmExit }                       from '../../../../actions/TakeSurvey';
import ModalExit                                        from './ModalConfirmExit';
import { getAnswerToQuestionFullLogic, validateAnswer } from '../../sevicesInRecord';
import {hasTimer}                                       from "../../../../Timer";

@hasTimer()
@withNamespaces()
@connect(state => {
    return {
        takeSurvey: state.TakeSurveyWeb,
        takeRecordPage: state.KirokuAIRecord.takeRecordPage,
    }
}, dispatch => {
    return {
        turnOnModalConfirmExit: () => {
            dispatch(turnOnModalConfirmExit())
        }
    }
})
class AnswerQuestion extends Component {

    constructor(props) {
        super(props);
        this.props.timer.record(() => {
            this.autoBackupData()
        });
        this.state = {
            currentAnswer      : null,
            reviewRecord       : false,
            loadingButtonSubmit: true,
            statusClick: false,
            profile: JSON.parse(localStorage.getItem('profile'))
        };
    }

    autoBackupData() {
        this.props['saveBackupData'](this.createLogic.toDraft());
    }

    questionsMap = {
        InputText,
        Message,
        MultiChoice,
        YesNo : YesNoQuestion,
        Date  : DateTimeQuestion,
        Single: SingleChoice,
        Media : MediaFileQuestion,
        OnlyDate: DateQuestion,
        Time: TimeQuestion,
        Number: NumberQuestion
    };


    turnOnModalConfirmExit() {
        const {previewTakeSurvey} = this.props;
        if(!previewTakeSurvey) {
           return this.props.turnOnModalConfirmExit();
        }
        return this.goBack();

    }


    getDraft(dataDraft, survey) {
        if (dataDraft) {
            this.createLogic    = CreatorLogic.fromDraft(dataDraft);
            const currentAnswer = validateAnswer(this.createLogic.current().payload);
            this.setState({
                questionsRecord: [...this.createLogic.toRecord()],
                reviewRecord   : this.createLogic.getEndQuestion(),
                currentAnswer  : currentAnswer ? currentAnswer : null
            });
        } else {
            this.createLogic = new CreatorLogic(survey.questions);
            this.setState({
                questionsRecord: [...this.createLogic.toRecord()],
                reviewRecord   : this.createLogic.getEndQuestion()
            })
        }
    }

    componentWillMount() {
        this.getInitQuestionLogic()
    }

    getInitQuestionLogic () {
        if(this.props.takeRecordPage) {
            this.setState({
                statusClick : true
            });
            setTimeout(() => this.setState({
                statusClick: false
            }), 2500);
        }
        const {survey, statusRecord} = this.props;
        if(window.history.previous === "/records/create-record") {
            this.getDraft(statusRecord.statusDraft, survey);
        } else {
            this.getDraft(null, survey);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.survey !== prevProps.survey) {
            this.getInitQuestionLogic();
        }
    }

    componentWillUnmount() {
        this.props.timer.unRegisterLastJob();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {keyLoadData, survey} = nextProps;
        if( keyLoadData !== this.props.keyLoadData && keyLoadData) {
            this.getDraft(nextProps.statusRecord[keyLoadData], survey)
        }
    }


    handleNextQuestion() {
        this.createLogic.next(this.state.currentAnswer);
        const currentQuestion = this.createLogic.toRecord()[this.createLogic.toRecord().length - 1];
        if (currentQuestion.type === 'Message') {
            this.createLogic.next(currentQuestion.answers);
        }

        this.setState({
            reviewRecord   : this.createLogic.getEndQuestion(),
            currentAnswer  : null,
            questionsRecord: [...this.createLogic.toRecord()]
        })
    }

    handleOnAnswer(answer, index) {
        if(!this.state.loadingButtonSubmit) {
            this.setState({
                loadingButtonSubmit: true
            });
        }
        if (index === this.createLogic.toRecord().length - 1) {
            this.createLogic.current().answer(answer.answer);
            this.setState({
                questionsRecord: [...this.createLogic.toRecord()],
                currentAnswer  : !answer.validated ? answer.validated : answer.answer
            })
        }
    }

    handleOnUpdate(answer, questionIndex, index) {
        if(!this.state.loadingButtonSubmit) {
            this.setState({
                loadingButtonSubmit: true
            });
        }
        this.createLogic.update(questionIndex, answer.answer);
        if (index === this.createLogic.toRecord().length-1) {
            this.createLogic.current().answer(answer.answer);
            this.setState({
                currentAnswer  :answer.answer,
                questionsRecord: [...this.createLogic.toRecord()]
            });
        }
        this.setState({
            reviewRecord   : this.createLogic.getEndQuestion(),
            questionsRecord: [...this.createLogic.toRecord()]
        });
    }

    goBack() {
        this.props.history.goBack();
    }

    handleTakeRecord() {
        const {questionsRecord, loadingButtonSubmit} = this.state;
        const {survey} = this.props;
        this.setState({
            loadingButtonSubmit: !loadingButtonSubmit
        });


        this.props.createRecord(
            questionsRecord,
            getAnswerToQuestionFullLogic(questionsRecord, [...survey.questions])
        );
    }

    handleSaveDraft() {
        const {questionsRecord} = this.state;
        const {survey} = this.props;
        this.props.saveDraft(
            questionsRecord,
            getAnswerToQuestionFullLogic(questionsRecord, [...survey.questions])
        );
        this.setState({
            statusClick: !this.state.statusClick
        }, () => {
            setTimeout(() => this.setState({
                statusClick: false
            }), 3500);
        })

    }


    render() {
        const {questionsRecord, reviewRecord, currentAnswer, loadingButtonSubmit, profile} = this.state;
        const { takeSurvey, t, history, lockInfo, editor, customerId }         = this.props;
        const currentQuestion          = this.createLogic.current().payload;
        const notPageReview               = history.location.pathname  !== '/surveys/survey-preview';

        const logicEditor = (disableAnsweredQuestion) => (lockInfo['user_id'] === profile.id ? disableAnsweredQuestion : true);
        return (
            <div>
                <div className={'bg-white'}>
                    <div className='fix-center'>
                        <div style={{width: '70%'}}>
                            {questionsRecord.map((question, index) => {
                                const Question                = this.questionsMap[question.type];
                                const disableAnsweredQuestion = questionsRecord.length === 1 ? !(questionsRecord.length === 1) : index < questionsRecord.length - 1;
                                return (
                                    <Question
                                        editor={editor && !(lockInfo['user_id'] === profile.id)}
                                        disabled={
                                            editor ? logicEditor(disableAnsweredQuestion) : disableAnsweredQuestion
                                        }
                                        index={index}
                                        key={index}
                                        question={question}
                                        value={question.answers}
                                        onAnswer={e => this.handleOnAnswer(e, index)}
                                        onUpdate={this.handleOnUpdate.bind(this)}
                                    />
                                )
                            })}
                            <div className='fix-center pt-2 pb-4'>
                                <div hidden={takeSurvey.flagEditing}>
                                    {loadingButtonSubmit ?
                                     <div>
                                         {notPageReview ?
                                          <KirokuButton
                                              color={'success'}
                                              onClick={this.handleTakeRecord.bind(this)}
                                              disabled={ (!currentQuestion.unrequited && !currentAnswer) || (editor && !(lockInfo[ 'user_id' ] === profile.id))}
                                              hidden={!reviewRecord}
                                          >
                                              {t('createRecord.Submit')}
                                          </KirokuButton> : <div/>
                                          }
                                     </div> :
                                        <KirokuButton color={'success'} disabled>{t('createRecord.Submit')}</KirokuButton> }

                                    <KirokuButton
                                        color={'success'}
                                        onClick={this.handleNextQuestion.bind(this)}
                                        hidden={reviewRecord}
                                        disabled={(!currentAnswer && !currentQuestion.unrequited) || (editor && !(lockInfo[ 'user_id' ] === profile.id)) }
                                    >
                                        {t('createRecord.next')}
                                    </KirokuButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'pt-4 fix-center'}>
                    <div className={'d-flex justify-content-between'}>
                        <KirokuButton color={'white'}
                                      onClick={this.turnOnModalConfirmExit.bind(this)}>
                            {t('createRecord.cancel')}
                        </KirokuButton>
                        {notPageReview ?

                        <KirokuButton disabled={this.state.statusClick || (editor && !(lockInfo[ 'user_id' ] === profile.id))} style={{marginLeft: 10}} color={'primary'}
                                      onClick={this.handleSaveDraft.bind(this)}>
                            {t('createRecord.SaveDraft')}
                        </KirokuButton> : <div/>
                        }

                    </div>
                </div>

                <ModalExit history={this.props.history} editor={editor && (lockInfo[ 'user_id' ] === profile.id)} actionType="records"
                           customerId={customerId}
                />
            </div>
        );
    }
}

export default AnswerQuestion;
