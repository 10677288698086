export default class DocumentUploadService {
    constructor(axios) {
        this.axios = axios;
    }

    upload(document) {
        return this.axios.post('/document', document);
    }

    getDocuments(params) {
        return this.axios.get('/documents-by-customer', {
            params: params
        }).then(res => res.data);
    }

    removeDocuments(customerId, listId) {
        return this.axios.put('/documents', {
            customerId: customerId,
            documents: listId
        }).then(res => res.data);
    }

    updatePrivateDocument(customerId, listId, privateDocument) {
        return this.axios.put('/documents/edit', {
            customerId: customerId,
            documents: listId,
            private: privateDocument
        }).then(res => res.data);
    }

    assignUsersToDocument(customerId, documentId, userIds) {
        return this.axios.post('/assignment-document-users', {
            customerId: customerId,
            documentId: documentId,
            users: userIds
        }).then(res => res.data);
    }

}
