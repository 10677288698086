import React, {Component} from 'react';
import {
    KirokuInputSearchOnBlur
}                         from "../../../../ui";
import {connect}          from "react-redux";
import {withNamespaces}   from "react-i18next";

@withNamespaces()
@connect(state => {
    return {
        keyword : state.DeletedSurveys.keyword
    }
}, dispatch => {
    return {
        changeKeywordInput        : (value) => {
            dispatch({
                type   : 'CHANGE_SEARCH_KEYWORD',
                keyword: value
            })
        },
        setValueKeywordSearch: () => {
            dispatch({
                type: 'SUBMIT_SEARCH_KEYWORD',
            })
        },
    }
})

class ConditionSurveyTemplate extends Component{

    handleChangeKeyword(e){
        this.props.changeKeywordInput(e.target.value)
    }

    handlerSubmitSearch() {
        this.props.setValueKeywordSearch();
    }

    render() {
        const {t, keyword} = this.props;
        return (
            <div className={'font-size-condition pd-top-10 width-full'}>
                <KirokuInputSearchOnBlur
                    placeholder={t('DeletedList.searchByName')}
                    value={keyword}
                    onSubmit={this.handlerSubmitSearch.bind(this)}
                    onBlur={this.handlerSubmitSearch.bind(this)}
                    onChange={this.handleChangeKeyword.bind(this)}
                    id={'inputSearchSurveysDeleted'}
                />
            </div>
        )
    }
}

export default ConditionSurveyTemplate;
