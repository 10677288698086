import React, {Component} from "react";
import {Link}             from "react-router-dom";
import {filePathService}  from "../services";

export default class KirokuStaffTag extends Component {
    render() {
        const tagShows = this.props['tag'];
        return (
            <span className="representative-tag">
                {tagShows.map((tagShow, index) =>
                    <span key={index}>
                        {this.props.type === "teams" ?
                            <Link key={index} to={`/teams/team-detail/${tagShow.tagId ? tagShow.tagId : tagShow.id}`}>
                                <div className="kiroku-btn-data mr-1 mb-1" key={index}>
                                    {tagShow.name ? tagShow.name : tagShow.value}
                                </div>
                            </Link>
                            :
                            <Link key={index} to={`/staffs/staff/${tagShow.tagId ? tagShow.tagId : tagShow.id}`}>
                                <div className="kiroku-btn-data mr-1 mb-1" key={index}>
                                    <i className="avatar-staff"
                                       style={{backgroundImage: `url(${tagShow.avatar ? tagShow.avatar : `${filePathService.resolveFilePath('profile-ic.svg')}`})`}}/>
                                       <span>
                                           {tagShow.name ? tagShow.name : tagShow.value}
                                       </span>
                                </div>
                            </Link>
                        }
                    </span>
                )}
            </span>
        );
    }
}