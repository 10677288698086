import React, {Component}         from 'react';
import {
    KirokuButton,
    KirokuSectionDropdown
}                                 from "../../../ui";
import {withNamespaces}           from "react-i18next";
import KirokuInput                from "../../../ui/KirokuInput";
import KirokuMultiSelect          from "../../../ui/KirokuMultiSelect";
import KirokuDropdownClose        from "../../../ui/KirokuDropdownClose";
import {userFactory, userService} from "../../../services";
import {connect}                  from "react-redux";
import {NOTIFICATION}             from "../../../actions/Notification";

@withNamespaces()
@connect(() => {
    return {
        //profile: state.KirokuAIUser.profile
    }
}, (dispatch) => {
    return {
        notification: (t) => {
            dispatch({
                type: NOTIFICATION,
                typeNotification: 'success',
                message: t('message.CreateSuccess')
            })
        },
        trackingCreateStaff : (staff) => {
            dispatch({
                type: "STAFF_CREATED",
                staff
            })
        },
        trackingDuplicateStaff : (staff) => {
            dispatch({
                type: "STAFF_DUPLICATED",
                staff
            })
        },
    }
})
class StaffCreatorMobile extends Component {
    state               = {
        showConfirmPass: false,
        validateUsername: false,
        showNewPass: false,
        errorUser: {
            name: false,
            password: false,
            confirmPassword: false,
            username: false,
            title: false
        },
        errorStaffNumber: false,
        user: {
            name: this.props.user ? this.props.user.name : '',
            password: '',
            confirmPassword: '',
            username: '',
            title: this.props.user ? this.props.user.title : '',
            avatar: this.props.user ? this.props.user.avatar : '',
            working_form: this.props.user ? this.props.user.working_form : '',
            qualification: this.props.user ? this.props.user.qualification: [],
            role: this.props.user ? this.props.user.role : '',
            staff_number: this.props.staff_number ? this.props.user.staff_number : '',
            tags: this.props.user ? this.props.user.tags : []
        },
        messageError: false,
        nameExisted: false,
        currentQualification: this.props.user ? this.getQualification(this.props.user.qualification, this.props.t): null,
        currentCustomerTags: [],
        currentTeamTags : []
    };


    KindOfQualification = [
        {
            key: this.props.t('common.dropdown.default.Social_worker'),
            label: this.props.t('common.dropdown.Social_worker'),
            value: this.props.t('common.dropdown.Social_worker'),
        },
        {
            key: this.props.t('common.dropdown.default.Psychiatric_Sosial_Worker'),
            value: this.props.t('common.dropdown.Psychiatric_Sosial_Worker'),
            label: this.props.t('common.dropdown.Psychiatric_Sosial_Worker'),
        },
        {
            key: this.props.t('common.dropdown.default.Care_Worker'),
            label: this.props.t('common.dropdown.Care_Worker'),
            value: this.props.t('common.dropdown.Care_Worker'),
        },
        {
            key: this.props.t('common.dropdown.default.Nurse'),
            label: this.props.t('common.dropdown.Nurse'),
            value: this.props.t('common.dropdown.Nurse'),
        },
        {
            key: this.props.t('common.dropdown.default.Childminder'),
            label: this.props.t('common.dropdown.Childminder'),
            value: this.props.t('common.dropdown.Childminder'),
        },
        {
            key: this.props.t('common.dropdown.default.Occupational_therapist'),
            label: this.props.t('common.dropdown.Occupational_therapist'),
            value: this.props.t('common.dropdown.Occupational_therapist'),
        },
        {
            key: this.props.t('common.dropdown.default.Psychotherapist'),
            label: this.props.t('common.dropdown.Psychotherapist'),
            value: this.props.t('common.dropdown.Psychotherapist'),
        },
        {
            key: this.props.t('common.dropdown.default.Doctor'),
            label: this.props.t('common.dropdown.Doctor'),
            value: this.props.t('common.dropdown.Doctor'),
        },

    ];
    workingForm         = [
        {
            value: 'Full_time',
            component: (<div>{this.props.t('common.Full_time')}</div>),
            label: this.props.t('common.Full_time')
        },
        {
            value: 'Part_time',
            component: (<div>{this.props.t('common.Part_time')}</div>),
            label: this.props.t('common.Part_time')
        },
    ];

    loadDataDuplicateStaff(nextProps) {
        const {user} = nextProps;

        let teams     = user.teams.map(team => {
            return {
                tagType: "team",
                tagContent: {
                    ...team,
                },
                key: team.id,
                label: team.name,
                value: `${team.name}${team.id}`,
                type: 'Teams'
            }
        });
        let customers = user.customers.length ? user.customers.map(customer => {
            return {
                tagType: "customer",
                tagContent: {
                    ...customer,
                },
                key: customer.id,
                label: customer.name,
                value: `${customer.name}${customer.id}`,
                type: 'Customer'
            }
        }) : [];
        let optionTeam = nextProps.teams.map(team => {
            return {
                tagType: "team",
                tagContent: {
                    ...team,
                },
                key: team.id,
                value: `${team.name}${team.id}`,
                label: team.name,
                type: 'Teams'
            }
        });

        let optionCustomers = nextProps.customers.map((customer) => {
            return {
                tagType: "customer",
                tagContent: {
                    ...customer,
                },
                key: customer.id,
                value: `${customer.name}${customer.id}`,
                label: customer.name,
                type: 'Customers'
            }
        });

        // let currentTags = customers.concat(teams);
        this.setState({
            currentCustomerTags: customers,
            currentTeamTags : teams,
            // optionTeam: optionTeam,
            groupedCustomerOptions: [
                {
                    label: 'Customers',
                    options: optionCustomers,
                }
            ],
            groupedTeamOptions : [
                {
                    label: 'Teams',
                    options: optionTeam,
                }
            ]
        });
    }

    componentDidMount() {
        if(this.props.isDuplicate) {
            this.loadDataDuplicateStaff(this.props)
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.user && this.props.isDuplicate) {
            this.loadDataDuplicateStaff(nextProps)
        }
    }

    handleChangeCustomerTags = (options) => {
        this.setState({
            currentCustomerTags: options ? options : this.state.defaultOption,
            isEdit: true,
        }, () => {
            this.currentTag(this.state.currentCustomerTags.concat(this.state.currentTeamTags))
        });
    };

    handleChangeTeamTags = (options) => {
        this.setState({
            currentTeamTags: options ? options : this.state.defaultOption,
            isEdit: true,
        }, () => {
            this.currentTag(this.state.currentTeamTags.concat(this.state.currentCustomerTags))
        });
    };

    currentTag(tags) {
        this.handleChangeProfile(tags, 'tags');
    }


    getQualification(qualification, t) {
        if(qualification.length) {
            return qualification.map(trans => {
                return {
                    key: t(`common.dropdown.default.${trans}`),
                    label: t(`common.dropdown.${trans}`),
                    value: t(`common.dropdown.${trans}`)
                }
            });
        } else {
            return  -1
        }
    }

    goBack() {
        this.props.history.goBack()
    }

    handleChangeInput(e) {

        if(e.target.name === 'username') {
            let val = e.target.value
                .replace(/[^A-z\u00C0-\u00ff\s'.,-/@+#?!|$%^&*;:{}><=\-"_`~()0-9]/g, '')
                .replace(/\s+/g, '')
            ;
            return this.handleChangeProfile(val, e.target.name);
        }

        return this.handleChangeProfile(e.target.value, e.target.name);
    }

    handleShowConfirmPass() {
        this.setState({
            showConfirmPass: !this.state.showConfirmPass
        })
    }

    handleShowNewPass() {
        this.setState({
            showNewPass: !this.state.showNewPass
        })
    }

    handleChangeProfile(value, name) {
        if (name === 'tags') {
            this.setState({
                user: {
                    ...this.state.user,
                    [name]: value,
                }
            })
        } else {
            this.setState({
                user            : {
                    ...this.state.user,
                    [name]: value,
                },
                errorUser       : {
                    ...this.state.errorUser,
                    [name]: !value.length,
                },
                validateUsername: false,
                nameExisted     : !value.length ? false : this.state.nameExisted
            })
        }
    }

    selectWorkingForm(workingForm) {
        this.handleChangeProfile(workingForm ? workingForm : '', 'working_form')
    }

    currentQualification(qualification) {
        let currentQualification = qualification.map(item => item.key);
        this.handleChangeProfile(currentQualification, 'qualification')
    }

    handleChangeQualification = (options) => {
        this.setState({currentQualification: options ? options : this.state.defaultOption}, () => {
            this.currentQualification(this.state.currentQualification)
        });
    };

    validation() {
        let {name, title, username, password, confirmPassword} = this.state.user;
        this.setState({
            errorUser: {
                name: !name.length,
                password: !(password.length > 5),
                confirmPassword: confirmPassword !== password,
                username: !username.length,
                title: !title.length,
                nameExisted: this.state.nameExisted
            }
        }, () => this.onSubmit());
    }

    onSubmit() {
        if (Object.values(this.state.errorUser).every(item => !item)) {
            if(this.props.isDuplicate) {
                let newUser = userFactory.makeUser(this.state.user, true);
                delete newUser.id;
                userService.duplicateCreateUser(newUser).then(res => {
                    this.props.trackingDuplicateStaff(res.data);
                    this.props.notification(this.props.t);
                    this.props.history.push(`/staffs/staff/${res.data.id}`)
                })
            } else {
                userService.createUser(this.state.user).then((res) => {
                    this.props.trackingCreateStaff(res.data);
                    this.props.notification(this.props.t);
                    this.props.history.push(`/staffs/staff/${res.data.id}`)
                })
            }
        }

    }

    handleCheckExisted(e) {
        let keyword = e.target.value;
        if (keyword.length === 0) {
            return this.setState({
                nameExisted: false,
                errorUser: {
                    ...this.state.errorUser,
                    username: !keyword.length,
                    validateUsername: false
                }
            })
        } else if (keyword.length > 5 && keyword.length < 65) {
            return userService.checkDuplicate(keyword).then((message) => {
                this.setState({
                    nameExisted: message
                })
            });
        } else {
            return this.setState({
                nameExisted: false,
                validateUsername: !this.state.validateUsername
            })
        }

    }

    handleCheckPassword(e) {
        let keyword = e.target.value;
        this.setState({
            errorUser: {...this.state.errorUser, password: !(keyword.length > 5)}
        })
    }

    checkRePassword(e) {
        let keyword = e.target.value;
        this.setState({
            errorUser: {...this.state.errorUser, confirmPassword: this.state.user.password !== keyword}
        })
    }

    validateName(e) {
        this.setState({
            errorUser: {...this.state.errorUser, name: !e.target.value.length}
        })
    }

    validateTitle(e) {
        this.setState({
            errorUser: {...this.state.errorUser, title: !e.target.value.length}
        })
    }

    isDisabled() {
        const name = {
            ...this.state.errorUser,
            nameExisted: this.state.nameExisted
        };
        return !Object.values(name).every(item => !item)
    }

    handleChangeStaffNumber = (e) => {
        let regex  = e.target.value.replace(/[^0-9]/g, '');
        const user = this.state.user;
        if (regex.length <= 5) {
            user[e.target.name] = regex;
            this.setState({
                user: {
                    ...this.state.user,
                    staff_number: regex
                }
            });
        }
    };

    checkValidationStaffNumber = (e) => {
        const error = this.checkErrorStaffNumber(e.currentTarget.value);
        this.setState({
                errorStaffNumber: error
        });
    };

    checkErrorStaffNumber = (number) => {
        if(number.length < 5 && number.length > 0) {
            this.setState({errorStaffNumber: true});
            return 'ErrorStaffNumber';
        }
        return '';
    };

    render() {
        let role   = JSON.parse(localStorage.getItem('profile'));
        const {t, isDuplicate} = this.props;
        const {
            user,
            // errorStaffNumber
        } = this.state;
        return (
            <div>
                <div className='p-2 d-flex justify-content-between'>
                    <KirokuButton onClick={this.goBack.bind(this)} color='white'>
                        {t('common.Back')}
                    </KirokuButton>
                </div>
                <div className='bg-white card-customer-editor'>
                    <h5 className='p-2'>{t('common.AddStaff')}</h5>
                    <div className="p-2">
                        <div className="pb-2">
                            <label className="mt-1">{t('common.Name')}</label>
                            <KirokuInput
                                name={'name'}
                                onBlur={this.validateName.bind(this)}
                                placeholder={t('common.Name')}
                                onChange={this.handleChangeInput.bind(this)}
                                value={user.name}
                            />
                        </div>
                        <p hidden={!this.state.errorUser.name}
                           style={{
                               color: 'red',
                               fontSize: '13px'
                           }}>
                            {t('staff.nameStaffMustNotNull')}
                        </p>
                        <div className="pb-2">
                            <label className="mt-1">{t('staff.title')}</label>
                            <KirokuInput
                                onBlur={this.validateTitle.bind(this)}
                                value={user.title}
                                name='title'
                                placeholder={t('staff.title')}
                                onChange={this.handleChangeInput.bind(this)}
                            />
                        </div>
                        <p hidden={!this.state.errorUser.title}
                           style={{
                               color: 'red',
                               fontSize: '13px'
                           }}>
                            {t('staff.titleNotNull')}
                        </p>
                        <div className="pb-2">
                            <label className="mt-1">{t('Login.username')}</label>
                            <KirokuInput
                                value={user.username}
                                name='username'
                                placeholder={t('Login.username')}
                                onBlur={this.handleCheckExisted.bind(this)}
                                onChange={this.handleChangeInput.bind(this)}
                            />
                            <p hidden={!this.state.errorUser.username}
                               style={{
                                   color: 'red',
                                   fontSize: '13px'
                               }}>
                                {t('staff.userNameNotNull')}
                            </p>
                            <p hidden={!this.state.nameExisted}
                               style={{
                                   color: 'red',
                                   fontSize: '13px'
                               }}>
                                {t('staff.nameExisted')}
                            </p>
                            <p hidden={!this.state.validateUsername}
                               style={{
                                   color: 'red',
                                   fontSize: '13px'
                               }}>
                                {t('staff.validateLength')}
                            </p>
                        </div>
                        <div className="pb-2">
                            <label className="mt-1">{t('Login.password')}</label>
                            <div onBlur={this.handleCheckPassword.bind(this)}
                                 style={{position: 'relative'}}>
                                <KirokuInput
                                    name='password'
                                    type={!this.state.showNewPass ? 'password' : 'text'}
                                    onChange={this.handleChangeInput.bind(this)}/>
                                <span className='change-status-password-user'>
                                    <i onClick={this.handleShowNewPass.bind(this)}
                                       className={this.state.showNewPass ? 'fas fa-eye-slash text-secondary' : 'fas fa-eye text-secondary'}/>
                                </span>
                            </div>
                            <p hidden={!this.state.errorUser.password}
                               style={{
                                   color: 'red',
                                   fontSize: '13px'
                               }}>
                                {t('errors.userDetail.mustNotNull')}
                            </p>
                        </div>
                        <div className="pb-2">
                            <label className="mt-1">{t('common.ConfirmNewPassword')}</label>
                            <div style={{position: 'relative'}}>
                                <KirokuInput
                                    name='confirmPassword'
                                    onBlur={this.checkRePassword.bind(this)}
                                    type={!this.state.showConfirmPass ? 'password' : 'text'}
                                    onChange={this.handleChangeInput.bind(this)}/>
                                <span className='change-status-password-user'>
                                    <i onClick={this.handleShowConfirmPass.bind(this)}
                                       className={this.state.showConfirmPass ? 'fas fa-eye-slash text-secondary' : 'fas fa-eye text-secondary'}/>
                                </span>
                            </div>
                            <p hidden={!this.state.errorUser.confirmPassword}
                               style={{
                                   color: 'red',
                                   fontSize: '13px'
                               }}>
                                {t('errors.userDetail.notMatch')}
                            </p>
                        </div>
                        {/*<div className="pb-2">*/}
                        {/*    <label className="mt-1">{t('common.StaffNumber')}</label>*/}
                        {/*    <div style={{position: 'relative'}}>*/}
                        {/*        <KirokuInput*/}
                        {/*            name='staff_number'*/}
                        {/*            placeholder={t('common.StaffNumber')}*/}
                        {/*            onChange={this.handleChangeStaffNumber.bind(this)}*/}
                        {/*            onBlur={this.checkValidationStaffNumber.bind(this)}*/}
                        {/*            error={errorStaffNumber ? 'form-error' : ''}*/}
                        {/*            value={user.staff_number || ''}*/}
                        {/*        />*/}
                        {/*        <p hidden={!errorStaffNumber} style={{*/}
                        {/*            color: 'red',*/}
                        {/*            fontSize: 12*/}
                        {/*        }}>*/}
                        {/*            {t(`CustomerEditor.validate.${errorStaffNumber}`)}*/}
                        {/*        </p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className='pt-3'>
                    <div className='bg-white card-customer-editor'>
                        <div className="p-2">
                            <div className="pb-2">
                                <label className="mt-1">{t('common.WorkingForm')}</label>
                                <KirokuDropdownClose
                                    // title={t(`common.WorkingForm`)}
                                    title={user.working_form ? t(`common.${user.working_form}`) : t(`common.WorkingForm`)}
                                    items={this.workingForm}
                                    onSelect={event => {
                                        this.selectWorkingForm(event.kirokuSelected.value);
                                    }}
                                />
                            </div>
                            <div className="pb-2">
                                <label className="mt-1">{t('common.Qualification')}</label>
                                <KirokuMultiSelect
                                    onChange={this.handleChangeQualification.bind(this)}
                                    value={this.state.currentQualification}
                                    options={this.KindOfQualification}
                                />
                            </div>
                            <div  hidden={user.id === role.id || !isDuplicate}>
                                <span className='customer-info f-bold'>{t('common.Customer')}:</span>
                                <br/>
                                <KirokuSectionDropdown
                                    onChange={this.handleChangeCustomerTags.bind(this)}
                                    value={this.state.currentCustomerTags}
                                    data={this.state.groupedCustomerOptions}
                                    options={this.state.groupedCustomerOptions}
                                />
                            </div>
                            <div  hidden={user.id === role.id || !isDuplicate}>
                                <span className='customer-info f-bold'>{t('common.Team')}:</span>
                                <br/>
                                <KirokuSectionDropdown
                                    onChange={this.handleChangeTeamTags.bind(this)}
                                    value={this.state.currentTeamTags}
                                    data={this.state.groupedTeamOptions}
                                    options={this.state.groupedTeamOptions}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='fix-center pt-3 pb-3'>
                    <div className='d-flex'>
                        <KirokuButton color='white'
                                      onClick={this.goBack.bind(this)}
                        >
                            {t('common.Cancel')}
                        </KirokuButton>
                        <div className='pr-1 pl-1'/>
                        <KirokuButton
                            disabled={this.isDisabled()}
                            onClick={this.validation.bind(this)} color='primary'>
                            {t('common.Save')}
                        </KirokuButton>
                    </div>
                </div>
            </div>
        )
    }
}

export default StaffCreatorMobile;
