import React, { Component }                                           from 'react';
import { KirokuCheckBox, KirokuInput, KirokuSectionDropdownShowMore } from '../../../../../../ui';
import { getGroupedOptions }                                          from '../../../../NewRecords/serviceNewRecords';
import _                                                              from 'lodash';
import { withNamespaces }                                             from 'react-i18next';
import { connect }                                                    from 'react-redux';

@withNamespaces()
@connect(state => {
    return {
        teams                     : state.KirokuAITeam.teamsBeta,
        keyword                   : state.KirokuModal.ModalSelectCustomers.keyword,
        teamsSelected             : state.KirokuModal.ModalSelectCustomers.teamsSelected,
        isOpenSelected            : state.KirokuModal.ModalSelectCustomers.isOpenSelected,
        customersSelected         : state.KirokuModal.ModalSelectCustomers.customersSelected,
    };
}, dispatch => {
    return {
        changeIsOpenSelected                 : (isOpenSelected) => {
            dispatch({
                type: 'CHANGE_IS_OPEN_SELECTED_CUSTOMERS',
                isOpenSelected,
            });
        },
        handleSelectCustomerInTeamSelected           : (teamsSelected) => {
            dispatch({
                type: 'SELECT_CUSTOMERS_IN_TEAMS_SELECTED',
                teamsSelected,
            });
        },
        handleChangeKeywordSelected                  : (keyword) => {
            dispatch({
                type: 'CHANGE_KEYWORD_SEARCH_CUSTOMERS_MODAL_SELECTED',
                keyword,
            });
        },
        handleChangeCheckboxCustomers        : (index) => {
            dispatch({
                type: 'HANDLE_CHANGE_CHECKBOX_CUSTOMERS',
                index,
            });
        },
        handleChangeCheckboxCustomersSelected: (index) => {
            dispatch({
                type: 'HANDLE_CHANGE_CHECKBOX_CUSTOMERS_SELECTED',
                index,
            });
        },
        handleSelectAllCustomersSelected     : () => {
            dispatch({
                type: 'HANDLE_SELECT_ALL_CUSTOMERS_SELECTED',
            });
        },
        handleSelectAllCustomers             : () => {
            dispatch({
                type: 'HANDLE_SELECT_ALL_CUSTOMERS',
            });
        },
        handleResetUserChanged               : () => {
            dispatch({
                type: 'HANDLE_RESET_USER_CHANGED',
            });
        },
    };
})
class TabSelected extends Component{
    render() {
        const {
                 isOpenSelected, customersSelected,
                  teams, t, teamsSelected, keyword,
              } = this.props;
        return(
            <div>
                <div className="d-flex justify-content-around border-btn-status-group">
                    <div className={`border-width-each-status first-btn font-text-on-btn cursor-pointer
                                    ${isOpenSelected ? 'btn-active' : ''}
                            `} onClick={() => this.props.changeIsOpenSelected(true)}>
                        {t('RecordsBeta.displaySelection')}
                    </div>
                    <div className={`border-width-each-status third-btn font-text-on-btn cursor-pointer
                                    ${!isOpenSelected ? 'btn-active' : ''}
                            `} onClick={() => this.props.changeIsOpenSelected(false)}>
                        {t('RecordsBeta.showAll')}
                    </div>
                </div>
                <div className="pt-3"/>
                <div className={'pt-2 pb-2 kiroku-section-drop-down-show-more-scroll'}>
                    <div className={'set-height-42'}>
                        <KirokuSectionDropdownShowMore
                            tagType={'Team'}
                            placeholder={t('RecordsBeta.refineByTeam')}
                            value={teamsSelected}
                            options={getGroupedOptions('TeamId', teams)}
                            onChange={(teamsSelected) => {
                                this.props.handleSelectCustomerInTeamSelected(teamsSelected);
                            }}
                        />
                    </div>
                </div>
                <div className={'pt-2'} >
                    <KirokuInput
                        style={{ height : 42, fontSize: 16}}
                        placeholder={t('RecordsBeta.filterByCustomerName')}
                        value={keyword}
                        onChange={(e) => this.props.handleChangeKeywordSelected(e.target.value)}
                    />
                </div>
                <div style={{padding: '15px 1px 5px 1px'}}/>
                <div>
                    {customersSelected.length ?
                        <div className='background-color' style={{ overflow: 'scroll' ,
                            minHeight: 'calc(100vh - 450px)',
                            maxHeight: 'calc(100vh - 450px)',
                            height: 'auto'}}>
                            <div className={'checkbox-bigger p-3 border-bottom d-flex justify-content-between'}>
                                <KirokuCheckBox content={<div>&nbsp;</div>} id={'customer-id'}
                                                checked={_.findIndex(customersSelected, (ctm) => (!ctm.selected)) === -1}
                                                onChange={() => this.props.handleSelectAllCustomersSelected()}
                                />
                                <div onClick={() => this.props.handleResetUserChanged()}
                                     className={'cursor-pointer font-reset-user-changed'}
                                     style={{ float: 'right', color: '#007BFF'}}
                                >
                                    {t('RecordsBeta.resetSelection')}
                                </div>
                            </div>
                            {customersSelected.map((customer, i) => {
                                return (
                                    <div className={'border-bottom'} key={i}>
                                        <div className={'checkbox-bigger p-3'}>
                                            <KirokuCheckBox content={<span
                                                className={'font-text-in-checkbox'}>{customer.name}</span>}
                                                            id={`${customer.id}-${i}`}
                                                            checked={!!customer.selected}
                                                            onChange={() => {
                                                                this.props.handleChangeCheckboxCustomersSelected(i);
                                                            }}
                                            />
                                        </div>
                                    </div>

                                );
                            })}
                        </div> :
                        <div className='background-color d-flex' style={ { fontSize: '16px', height: 'auto', minHeight: 'calc(100vh - 450px)'} }>
                            <div style={{ margin: 'auto'}}>
                                {t('RecordsBeta.noCustomersSelected')}
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}
export default TabSelected;
