import GenericQuestion from "./GenericQuestion";

class OnlyDate extends GenericQuestion {

    setDefaultAnswer() {
        this.payload.answers = this.payload['defaultAnswer'];
        return this.payload.answers;
    }

    getNext(answer) {
        if(this.payload.nextQuestion === 0) {
            return this.payload.nextQuestion
        }
        return this.payload.nextQuestion || this.payload.index + 1
    }
}
export default OnlyDate;
