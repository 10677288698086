import React, { Component }        from 'react';
import { withNamespaces }          from 'react-i18next';
import KirokuLogicApplicated       from '../../../../../../ui/KirokuLogicApplicated';
import KirokuCheckBox              from '../../../../../../ui/KirokuCheckBox';

@withNamespaces()
class MultiChoice extends Component {

    render() {
        const {question, disabled, t, surveyDetail, headerNextQuestion} = this.props;

        return (
            <div className='content-question-type'>
                <div className='wraper-question none-border-question pt-2'>
                    <div className="background-question">
                        <div className=' d-flex justify-content-between'>
                            <div
                                className="text-overflow-elip font-heading-questions"> {question.heading ? question.heading : ""}</div>
                            <div hidden={!surveyDetail} className={'float-right'}>
                                <KirokuLogicApplicated padding={'5px 5px 5px 7px'} question={question}/>
                            </div>
                        </div>
                        <div className='padding-content-question'>
                            <span className='font-content-question'>
                                {question.unrequited ? t('createRecord.option'):''}{question.content}
                            </span>
                        </div>
                        <div
                            hidden={typeof question.nextQuestion !== 'number' || !surveyDetail}
                            className='text-info pl-10 text-overflow-elip'>
                            <i className='fas fa-arrow-right text-info' style={{paddingTop: 4}}/>
                            <span> {headerNextQuestion}</span>
                        </div>
                    </div>
                    <div className='answer-question padding-16-answer-question answer-checkbox-beta'>
                        {this.props.value.map((item, index) =>
                            <KirokuCheckBox key={index} checked={item.is_choice}
                                            id={`MultiChoice${question.index}`}
                                            content={<span className='content-checkbox-beta'>{item.answer}</span>}
                                            disabled={disabled}
                                            readOnly
                            />
                        )}
                    </div>

                </div>
            </div>
        )
    }
}

export default MultiChoice;
