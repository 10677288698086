import {
    CLOSE_SCREEN_DETAIL,
    CREATE_SURVEY,
    DELETE_SURVEY,
    FILTER_SURVEY_WITH_CONDITION,
    GET_SURVEY_BY_ID, GET_SURVEYS_BETA,
    LOAD_SURVEY_LIST_PAGE,
    LOAD_SURVEY_MODAL,
    LOAD_SURVEY_TEMPLATE_OF_CUSTOMER,
    RESET_SURVEYS, SAVE_QUERY_CONDITION,
    SEARCH_SURVEYS, SET_CURRENT_PAGE,
} from '../actions/Survey';
import {CLEAR_TAKE_SURVEY} from '../actions/TakeSurvey';

const defaultApplicationSate = {
    title: 'Kiroku',
    surveys: [{
        id: 1,
        title: '',
        description: ''
    }],
    profile: JSON.parse(localStorage.getItem('profile')),
    customers: [],
    defaultSurveys: [],
    disableButtonApply: false,
    survey: {},
    surveyTemplateOfCustomer: null,
    surveyTemplateOfCustomerBackup: null,
    loading: false,
    totalPage: 1,
    currentPage: 1,
    surveyForModal: [],
    defaultSurveysModal: [],
    detailSurvey: null,
    ListAllSurveyTemplate: [],
    conditions: [],
    checked: false,
    isLoadingSurvey: false,
    surveysBeta: [],
    isFinish: false
};

const checkDisable   = (surveys) => {
    let checked = false;
    surveys.map(survey => {
        if (survey.isChecked) {
            checked = true
        }
        return survey
    });
    return checked
};
const KirokuAISurvey = (state = defaultApplicationSate, action) => {
    switch (action.type) {
        case GET_SURVEYS_BETA:
            return {
                ...state,
                surveysBeta: action.surveys
            };
        case 'SET_FINISH_LOAD_API_SURVEYS':
            return {
                ...state,
                isFinish: true
            };
        case 'RESET_DEFAULT_SURVEY' :
            return defaultApplicationSate;
        case LOAD_SURVEY_LIST_PAGE:
            return {
                ...state,
                defaultSurveys: [...action.page],
                surveys: action.page.map(item => ({...item})),
                totalPage: action.totalPage,
                currentPage: action.currentPage,
                loading: true
            };

        case LOAD_SURVEY_MODAL :
            return {
                ...state,
                surveyForModal: action.surveyForModal,
                isLoadingSurvey: true,
                defaultSurveysModal: [...action.surveyForModal]
            };

        case 'CHECK_SURVEY' :
            let surveysRecord                     = state.surveyForModal.map(survey => ({...survey}));
            surveysRecord[action.index].isChecked = !surveysRecord[action.index].isChecked;

            return {
                ...state,
                disableButtonApply: checkDisable(surveysRecord),
                surveyForModal: surveysRecord,
                checked: !surveysRecord.filter(survey => !survey.isChecked).length
            };

        case 'CHECK_ALL_SURVEYS' :
            let newSurveys = state.surveyForModal.map(survey => {
                return {
                    ...survey,
                    isChecked: !action.isChecked
                }
            });
            return {
                ...state,
                disableButtonApply: checkDisable(newSurveys),
                surveyForModal: newSurveys,
                checked: !state.checked
            };

        case 'ASSIGN_SUCCESS':
            return {
                ...state,
                surveys: [...state.defaultSurveys]
            };
        case RESET_SURVEYS :
            return {
                ...state,
                disableButtonApply: checkDisable([...state.defaultSurveys]),
                surveyForModal: [...state.defaultSurveysModal]
            };

        case LOAD_SURVEY_TEMPLATE_OF_CUSTOMER: {
            return {
                ...state,
                surveyTemplateOfCustomer: action.surveyTemplateOfCustomer,
                surveyTemplateOfCustomerBackup: JSON.parse(JSON.stringify(action.surveyTemplateOfCustomer))
            }
        }

        case CLEAR_TAKE_SURVEY: {
            return {
                ...state,
                surveyTemplateOfCustomer: null,
                surveyTemplateOfCustomerBackup: null,
            }
        }

        case SEARCH_SURVEYS :
            let keywords = action.keywords;
            let surveys  = [...state.defaultSurveys].filter(survey =>
                survey.title.toLowerCase().search(keywords.toLowerCase()) !== -1);
            return {
                ...state,
                surveys: surveys,
                loading: true
            };
        case FILTER_SURVEY_WITH_CONDITION :
            return {
                ...state,
                surveys: action.page,
                totalPage: action.totalPage,
                currentPage: action.currentPage
            };
        case CREATE_SURVEY :
            return state;
        case DELETE_SURVEY :
            let newSurvey = state.surveyForModal.filter(item => !action.listId.includes(item.id));
            return {
                ...state,
                defaultSurveysModal: [...newSurvey],
                surveyForModal: newSurvey,
                disableButtonApply: checkDisable(newSurvey)
            };
        case GET_SURVEY_BY_ID:
            return {
                ...state,
                detailSurvey: action.detailSurvey
            };
        case CLOSE_SCREEN_DETAIL:
            return {
                ...state,
                detailSurvey: null
            };
        case SAVE_QUERY_CONDITION:
            return {
                ...state,
                conditions: action.conditions
            };
        case 'LOAD_ALL_SURVEY_TEMPLATE':
            return {
                ...state,
                ListAllSurveyTemplate: action.ListAllSurveyTemplate
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.numberPage
            };
        default:
            return state
    }
};

export default KirokuAISurvey;

