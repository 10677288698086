import React, {Component} from 'react';
import {withNamespaces}   from "react-i18next";
import {Link}             from "react-router-dom";
import {KirokuInputCode}  from "../../ui";
import {connect}          from "react-redux";


@withNamespaces()
@connect(state => {
    return {
        inputNumFirst : state.LoginSupportPage.inputNumFirst,
        inputNumSecond: state.LoginSupportPage.inputNumSecond,
        inputNumThird : state.LoginSupportPage.inputNumThird,
        isConnect     : state.LoginSupportPage.isConnect,
        disabledInputs: state.LoginSupportPage.disabledInputs,
        disabledThirdInput: state.LoginSupportPage.disabledThirdInput,

    }
}, dispatch => {
    return {
        changeInputNum: (name, value) => {
            dispatch({
                type: 'CHANGE_INPUT_NUM',
                name, value
            })
        },
        submitConnect     : () => {
        dispatch({
            type: 'CONNECT_PAGE_SUPPORT',
        })
        },
        setInitPageSupport: () => {
            dispatch({
                type: 'SET_INIT_STATE_PAGE_SUPPORT',
            })
        }

    }
})

class SupportPageWeb extends Component {

    handleChangeInput = (e) => {
        this.props.changeInputNum(e.target.name, e.target.value);
    };

    handleSubmitConnect() {
        this.props.submitConnect();
    }

    handleSetInitPageSupport() {
        this.props.setInitPageSupport();
    }

    render() {
        const {t, inputNumFirst, inputNumSecond, inputNumThird, isConnect, disabledInputs, disabledThirdInput} = this.props;

        return (
            <div className='page-support'>
                <div className='box-login'>
                    <div className='tile'>
                        <div className='heading-support-page'>
                            <div className='heading-support-page-form'>
                                {t('common.KirokuSupport')}
                            </div>
                            <div className='sub-title-support-page-form'>
                                    <span>
                                        {t('common.numberConnect')}
                                    </span>
                            </div>
                        </div>
                        <div className='card-login'>
                            <div className='support-page-header pt-5'>
                                <div className='d-flex justify-content-center'>
                                    <KirokuInputCode  name='inputNumFirst' autoFocus style={{textAlign: 'center', width: '90px'}}
                                                      id='inputNumFirst' maxLength={3} value={inputNumFirst}
                                                     onChange={this.handleChangeInput.bind(this)}/>
                                    &mdash;
                                    <KirokuInputCode name='inputNumSecond' style={{textAlign: 'center', width: '90px'}}
                                                     id='inputNumSecond' readOnly={!disabledInputs}
                                                     maxLength={3} value={inputNumSecond}
                                                     onChange={this.handleChangeInput.bind(this)}/>
                                    &mdash;
                                    <KirokuInputCode name='inputNumThird' style={{textAlign: 'center', width: '90px'}}
                                                     maxLength={3} id='inputNumThird'
                                                     value={inputNumThird} readOnly={!disabledThirdInput}
                                                     onChange={this.handleChangeInput.bind(this)}/>
                                </div>
                            </div>
                            <div className='support-page-body'>

                                <div className="submit-container clearfix pt-5" style={{ height : 170}}>
                                    {isConnect ?
                                        <button className="btn btn-connect-active" onClick={this.handleSubmitConnect.bind(this)}>
                                            {t('common.connect')}
                                        </button> :
                                        <button className={'btn btn-connect-disabled'}>
                                            {t('common.connect')}
                                        </button>
                                    }
                                </div>
                                <div className={'pt-3'}>
                                    <div className="hr-login">
                                        <span className='hr-login-content'>{t('common.Back')}</span>
                                    </div>
                                </div>
                                {localStorage.getItem('token') ?
                                    <div style={{textAlign: 'center'}} className={'pt-2'} onClick={() => {
                                        this.handleSetInitPageSupport();
                                        window.location = '/'
                                    }}>
                                        <Link
                                            to={'/'}>{t('common.BackHome')}</Link>
                                    </div> :
                                    <div style={{textAlign: 'center'}} className={'pt-2'}>
                                        <Link onClick={this.handleSetInitPageSupport.bind(this)}
                                              to={'/login'}>{t('common.loginWithKirokuAI')}</Link>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default SupportPageWeb
