import React, {Component}                              from "react";
import {RenderComponentDevice}                         from "../../../../ui";
import RecordCreatorFiledAllWeb                        from "./web";
import connect                                         from "react-redux/es/connect/connect";
import {loadDraftRecording, restartDataDraftRecording} from "../../../../actions/Draft";
import {loadSurveyTemplateOfCustomer}                  from "../../../../actions/Survey";
import {clearTakeSurvey}                               from "../../../../actions/TakeSurvey";
import KirokuLoading                                   from "../../../../ui/KirokuLoading";
import {resetRecordEditorNew}                          from "../../../../actions/recordEditorNew";
import RecordCreatorFiledAllMobile                     from "./mobile";
import KirokuTitlepage                                 from "../../../../ui/KirokuTitlepage";
import {currentPage}                                   from "../../../../actions/CurrentPage";
import {withNamespaces}                                from "react-i18next";

@withNamespaces()
@connect(state => {
    return {
        surveyTemplateOfCustomer: state.KirokuAISurvey.surveyTemplateOfCustomer,
        loading: state.Loading
    }
}, dispatch => {
    return {
        loadDraft: (surveyId, customerId) => {
            dispatch(loadDraftRecording(surveyId, customerId))
        },

        loadSurveyTemplateOfCustomer: (surveyId, customerId) => {
            dispatch(loadSurveyTemplateOfCustomer(surveyId, customerId))
        },

        clearSurveyTemplateOfCustomer: () => {
            dispatch(clearTakeSurvey())
        },
        restartDataDraftRecording: () => {
            dispatch(restartDataDraftRecording())
        },
        resetRecordEditorNew: () => {
            dispatch(resetRecordEditorNew())
        },
        namePage : (name) => {
            dispatch(currentPage(name))
        }

    }
})
class RecordCreatorFiledAll extends Component {

    page(t) {
        return {
            title: <KirokuTitlepage title={t('Layout.Records')}/>,
            actionPage: null
        }
    }

    componentWillMount() {
        const surveyId = this.props.location.state.surveyId;
        const customerId = this.props.location.state.customerId;
        this.props.namePage(this.page(this.props.t));
        // this.props.loadDraft(surveyId, customerId);
        this.props.loadSurveyTemplateOfCustomer(surveyId, customerId);
    }

    componentWillUnmount() {
        this.props.clearSurveyTemplateOfCustomer();
        // this.props.restartDataDraftRecording();
        this.props.resetRecordEditorNew();
    }

    render() {
        const {surveyTemplateOfCustomer} = this.props;
        return surveyTemplateOfCustomer ? (
            <RenderComponentDevice
                web={<RecordCreatorFiledAllWeb
                    history={this.props.history}
                />}
                mobile={<RecordCreatorFiledAllMobile
                    isMobile
                    history={this.props.history}
                />}
            />
        ) : <KirokuLoading/>
    }
}

export default RecordCreatorFiledAll;
