import React, {Component} from 'react';
import InputText          from './InputText';
import MediaFile          from './MediaFile';
import Message            from './Message';
import SingleChoice       from "./SingleChoice";
import YesNo              from "./YesNo";
import MultiChoice        from "./MultiChoice";
import DateTime           from "./DateTime";
import Time               from "./Time";
import OnlyDate           from "./OnlyDate";
import Number             from "./Number";
import {connect}          from "react-redux";
import {
    changeTypeQuestion,
    createQuestion,
    editQuestion,
    backSurvey
}                         from "../../../../actions/SurveyMobile";
import KirokuButton       from "../../../../ui/KirokuButton";
import {Link}             from "react-router-dom";
import {withNamespaces}   from "react-i18next";

@withNamespaces()
@connect(state => {
    return {
        type: state.KirokuSurveyTemplateMobile.typeQuestion,
        questions: state.KirokuSurveyTemplateMobile.questions,
        questionBack: state.KirokuSurveyTemplateMobile.questionBack,
        backToSurvey: state.KirokuSurveyTemplateMobile.backToSurvey,
        question: state.KirokuSurveyTemplateMobile.question,
        error: state.KirokuSurveyTemplateMobile.errorQuestion,
        errorSurvey: state.KirokuSurveyTemplateMobile.errorSurvey
    }
}, dispatch => {
    return {
        changeTypeQuestion: (type) => {
            dispatch(changeTypeQuestion(type))
        },

        createQuestion: (question) => {
            dispatch(createQuestion(question))
        },

        backSurvey: () => {
            dispatch(backSurvey())
        },

        onChangeQuestion: (question) => {
            dispatch(editQuestion(question))
        }
    }
})
class ComponentAddQuestion extends Component {

    questionMap = {
        InputText: InputText,
        Single: SingleChoice,
        YesNo: YesNo,
        Message: Message,
        MultiChoice: MultiChoice,
        Date: DateTime,
        Media: MediaFile,
        Time : Time,
        OnlyDate : OnlyDate,
        Number   : Number
    };

    state = {
        question: {
            heading: '',
            content: '',
            choices: [{answer: ''}, {answer: ''}]
        },
        error: {
            content: false,
            answer: false,
        },
    };

    onSelectQuestion = (e) => {
        let typeQuestion = e.kirokuSelected.value;
        this.props.changeTypeQuestion(typeQuestion);
    };

    disabledReview = () => {
        return !Object.values(this.props.errorSurvey).every(error => !error);
    };

    showReview = () => {

    };

    resetState = () => {
        this.setState({
            ...this.state,
            question: {
                heading: '',
                content: '',
                choices: [{answer: ''}, {answer: ''}]
            },
            error: {
                answer: false,
                content: false
            }
        })
    };

    disabledCreateQuestion = () => {
        const {error} = this.state;
        const {type} = this.props;
        switch (type) {
            case 'Single':
            case 'MultiChoice' :
                return !Object.values(error).every(error => error);
            default :
                return !error.content;
        }
    };

    createQuestion = () => {
        let {question} = this.state;
        let {type} = this.props;
        let index = this.props.questions.length;
        this.props.createQuestion({...question, type, index});
        this.resetState();
    };

    onChangeHeading = (heading) => {
        this.setState({
            question: {
                ...this.state.question,
                heading: heading
            },
        })
    };

    onChangeContent = (content) => {
        this.setState({
            ...this.state,
            question: {
                ...this.state.question,
                content: content
            },
            error: {
                ...this.state.error,
                content: !!content
            }
        })
    };

    removeAnswer(e, index) {
        if (this.state.question.choices.length > 2) {
            let newChoices = this.state.question.choices;
            newChoices.splice(index, 1);
            return this.setState({
                choices: newChoices
            });
        }
    }

    addAnswer() {
        let {choices} = this.state.question;
        choices.push({answer: ''});
        let errorChoices = choices.every(choice => choice.answer !== '');
        this.setState({
            question: {
                ...this.state.question,
                choices: choices
            },
            error: {
                ...this.state.error,
                answer: errorChoices
            }
        }, this.getLastChoiceElement);
    }

    getLastChoiceElement() {
        window.scrollTo(0,document.body.scrollHeight);
        const {question} = this.state;
        const choices = question.choices;
        const element = document.querySelectorAll('#choicesQuestions > div > .d-flex > input[name="answer"]')[choices.length-1];
        element.focus();
    }

    answerOnchange(answer, index) {
        let {choices} = this.state.question;
        choices[index].answer = answer;
        let errorChoices = choices.every(choice => choice.answer !== '');
        this.setState({
            ...this.state,
            question: {
                ...this.state.question,
                choices: choices
            },
            error: {
                ...this.state.error,
                answer: errorChoices
            }
        });
    }

    backQuestion = () => {
        this.props.backQuestion();
    };

    render() {
        const {t} = this.props;
        const typeQuestion = this.props.type;
        const Question = this.questionMap[typeQuestion];
        const {question} = this.state;
        const numberQuestion = this.props.questions.length + 1;
        return (
            <div>
                <div className='create-header'>
                    <div className='d-flex justify-content-between padding-responsive'>
                        <h4>{t('createSurvey.CreateQuestion', {index: numberQuestion})}</h4>
                        <Link to={'/surveys/review-survey/'}>
                            <KirokuButton color='success' disabled={this.disabledReview()}
                                          onClick={this.showReview.bind(this)}>
                                {t('createSurvey.Review')}
                            </KirokuButton>
                        </Link>
                    </div>
                </div>
                <Question type={typeQuestion}
                          heading={question.heading || ''}
                          content={question.content || ''}
                          choices={question.choices}
                          onChangeHeading={this.onChangeHeading.bind(this)}
                          onChangeContent={this.onChangeContent.bind(this)}
                          answerOnchange={this.answerOnchange.bind(this)}
                          addAnswer={this.addAnswer.bind(this)}
                          removeAnswer={this.removeAnswer.bind(this)}
                          onSelect={this.onSelectQuestion.bind(this)}
                          isCreateSurvey
                />
                <div className='action-record'>
                    <div className="row">
                        <KirokuButton
                            disabled={this.disabledCreateQuestion()}
                            color={'primary'}
                            style={{borderRadius: 0, width: '100%', marginBottom: 3}}
                            onClick={this.createQuestion.bind(this)}
                        >
                            {t('createSurvey.SaveQuestion')}
                        </KirokuButton>
                        <div className='col-6 p-0'>
                            <KirokuButton style={{width: '100%', borderRadius: 0}} color={'light'}
                                          onClick={this.backQuestion.bind(this)}>
                                {t('createSurvey.Previous')}
                            </KirokuButton>
                        </div>
                        <div className='col-6 p-0'>
                            <KirokuButton disabled style={{width: '100%', borderRadius: 0}} color={'light'}>
                                {t('common.Next')}
                            </KirokuButton>


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ComponentAddQuestion;
