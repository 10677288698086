import {Link}             from 'react-router-dom';
import {
    KirokuButton,
    KirokuCardWeb,
    KirokuCheckDraft,
    KirokuDropdownClose,
    KirokuInputSearcher,
    KirokuLoading,
    KirokuModal,
    KirokuModalBody,
    KirokuModalFooter,
    KirokuTable,
    SearchNotFound,
    TBody,
    THeader,
    KirokuPagination,
    KirokuTitlepage,
    KirokuCheckBox,
    KirokuLabel,
    KirokuSectionDropdownShowMore
} from '../../../ui';
import React, {Component} from 'react';
import {connect}          from 'react-redux';
import {
    checkAllRecords, conditionRemoved,
    deleteRecords,
    loadAllSurveys,
    loadRecords,
    saveQueryCondition,
    selectedRecord, setNumberPageRecords
}                         from '../../../actions/Record';
import {loadCustomerAll}  from '../../../actions/Customer';

import {withNamespaces} from 'react-i18next';
import moment           from 'moment'

import {currentPage}                           from '../../../actions/CurrentPage';
import {loadUsers}                             from '../../../actions/User';
import {recordBuilderCondition, scrollService} from '../../../services';
import FilterRecordsWeb                        from "./FilterRecordsWeb";
import withPermission                          from "../../HOC/WithPermissions";
import {authService}                           from "../../../services";
import {setSizePagination}                     from "../NewRecords/serviceNewRecords";
import config                                  from '../../../config';

const BulkAction = withPermission(KirokuDropdownClose);

function page(t) {
    return {
        title: <KirokuTitlepage title={t('Layout.Records')}/>,
        actionPage: null
    }
}

@withNamespaces()
@connect(state => {
    return {
        customerTagged: state.KirokuAIRecord.customerTagged,
        records: state.KirokuAIRecord.records,
        ListAllSurveyTemplate: state.KirokuAISurvey.ListAllSurveyTemplate,
        ListAllCustomer: state.KirokuAICustomer.ListAllCustomer,
        users: state.KirokuAIUser.users,
        currentPage: state.KirokuAIRecord.currentPage,
        totalPage: state.KirokuAIRecord.totalPage,
        showNotificatieon: state.KirokuAIRecord.showNotification,
        loading: state.Loading,
        profile: state.KirokuAIUser.profile,
        statusCheck: state.KirokuAIRecord.statusCheck,
        conditions: state.KirokuAIBehavior.conditionRecords.conditions,
        params: state.KirokuAIBehavior.conditionRecords.params,
        pagination: state.KirokuAIBehavior.conditionRecords.pagination,
        defaultTags: state.KirokuAIBehavior.conditionRecords.defaultTags,
        pageSize : state.KirokuAIBehavior.conditionRecords.pageSize
    }
}, dispatch => {
    return {
        namePage: (name) => {
            dispatch(currentPage(name))
        },
        checkAllRecords: (checked) => {
            dispatch(checkAllRecords(checked))
        },

        loadUsers: () => {
            dispatch(loadUsers())
        },

        loadRecords: (params, tags) => {
            dispatch(loadRecords(params, tags));
        },

        loadAllSurveys: () => {
            dispatch(loadAllSurveys())
        },

        loadAllCustomer: () => {
            dispatch(loadCustomerAll())
        },

        selectedRecord: (index) => {
            dispatch(selectedRecord(index))
        },

        deleteRecords: (records, params, tags) => {
            dispatch(deleteRecords(records, params, tags))
        },

        saveQueryCondition: (conditions, params, pagination, defaultTags, pageSize) => {
            dispatch(saveQueryCondition(conditions, params, pagination, defaultTags, pageSize))
        },

        removedCondition: (removedCondition) => {
            dispatch(conditionRemoved(removedCondition))
        },
        setNumberPageRecords: (numberPage) => {
            dispatch(setNumberPageRecords(numberPage))
        }
    }
})
class RecordsWeb extends Component {

    state = {
        showModal: true,
        showRecords: false,
        show: false,
        conditions: {},
        title: '',
        page: 1,
        pageMin: 1,
        showBulkAction: false,
        namePage: page(this.props.t),
        disabledPrev: true,
        disabledNext: false,
        showModalDeleteRecords: false,
        countRecords: {},
        currentTags: [],
        groupedOptions: [],
        params: {
            page: 1
        },
        pageSize: 10,
        defaultValue: 10
    };

    componentWillMount() {
        if (this.props.conditions.length) {
            this.setState({
                currentTags: this.props.defaultTags,
                groupedOptions: this.props.ListAllCustomer
            });
            return this.makeConditions(this.props.conditions);
        } else {
            this.setState({
                currentTags: this.props.customerTagged ? this.props.customerTagged : [],
                groupedOptions: this.props.ListAllCustomer
            });
            this.props.loadRecords({page: 1}, this.props.customerTagged);
            this.setState({ defaultValue: this.state.pageSize})
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.params !== this.props.params) {
            this.setState({
                conditions: {
                    ...nextProps.params,
                },
                title: nextProps.params.title
            })
        }
        if (nextProps.currentPage || nextProps.records) {
            this.setState({
                //page: nextProps.currentPage,
                disabledPrev: nextProps.currentPage === 1,
                disabledNext: nextProps.currentPage === nextProps.totalPage,
                pageSize    : nextProps.pageSize,
                defaultValue: nextProps.pageSize,
            })
        }
        if (this.props.defaultTags !== nextProps.defaultTags) {
            this.setState({
                currentTags : nextProps.defaultTags
            })
        }
    }

    makeConditions = (conditions) => {
        let params = recordBuilderCondition.makeParamsFromCondition(conditions);
        this.setState({
            conditions: {
                ...this.props.params,
            },
            title: params.title,
            page: params.page,
            pageSize : params.pageSize,
            ...this.props.pagination
        });
        this.props.defaultTags.length ? this.props.loadRecords(params, this.props.defaultTags) : this.props.loadRecords(params);
    };

    increasePage() {
        let nextPage = this.props.currentPage === this.props.totalPage ? this.props.totalPage : this.props.currentPage + 1;
        if (nextPage === this.props.totalPage) {
            this.setState({
                disabledNext: !this.state.disabledNext
            })
        }
        scrollService.top();
        this.setState({
            page: nextPage,
            disabledPrev: false,
            defaultValue: this.props.pageSize,
            pageSize : this.props.pageSize
        }, () => {
            this.props.setNumberPageRecords(nextPage);
            this.filterRecords();
            this.saveQueryCondition();
        });
    }

    decreasePage() {
        let previousPage = this.props.currentPage === this.state.pageMin ? 1 : this.props.currentPage - 1;
        if (previousPage === this.state.pageMin) {
            this.setState({
                disabledPrev: true
            })
        }
        scrollService.top();
        this.setState({
            page: previousPage,
            disabledNext: false,
            defaultValue: this.props.pageSize,
            pageSize : this.props.pageSize
        }, () => {
            this.props.setNumberPageRecords(previousPage);
            this.filterRecords();
            this.saveQueryCondition();
        });
    }

    inputSearch = (e) => {
        this.setState({
            title: e.target.value,
            conditions: {
                ...this.state.conditions,
                title: e.target.value
            }
        })
    };

    onSubmitInputSearch = () => {
        this.setState({
            page: 1
        }, () => {
            const {page, title} = this.state;
            this.props.loadRecords({...this.state.conditions, page, title}, this.state.currentTags);
            this.saveQueryCondition();
        });
    };

    closeDateRange() {

    };

    bulkActionItems = [
        {
            value: 'delete',
            component: (
                <div>{this.props.t('common.Delete')}</div>
            ),
            label: this.props.t('common.Delete')
        }
    ];

    makeQueryConditions = (params) => {
        return Object.keys(params).map(key => (
            {[key]: params[key], query: key, value: params[key], label: this.makeLabelCondition(key)}
        ));
    };

    selectPaginationForPage = (valuePaginations) => {
        return valuePaginations.map(pagination => ({
            value: pagination.value,
            label : pagination.name,
            component : (<div><span className={'text-muted'}>{pagination.name}</span></div>)
        }));
    };

    choicePagination = (paginate) => {
        this.setState({
            conditions: {
                ...this.state.conditions,
                pageSize: paginate
            },
            page: 1,
            pageSize : paginate,
        }, () => {
            this.filterRecords();
            this.saveQueryCondition();
        });
    };

    makeLabelCondition = (key) => {
        switch (key) {
            case 'status' :
                return this.state.conditions.status ? this.props.t('Surveys.Draft') : '';
            case 'customerId':
                return this.state.conditions.customerName;
            case 'templateId':
                return this.state.conditions.titleSurvey;
            case 'staffId':
                return this.state.conditions.staffName;
            case 'startDate':
                let startDate = this.state.conditions.startDate ? `${moment.unix(this.state.conditions.startDate).format(config.formatDateTh)}` : '';
                return this.props.t(`Surveys.${key}`) + `${startDate}`;
            case 'endDate':
                let endDate = this.state.conditions.endDate ? `${moment.unix(this.state.conditions.endDate).format(config.formatDateTh)}` : '';
                return this.props.t(`Surveys.${key}`) + `${endDate}`;
            default:
                return this.props.t(`RecordList.${key}`)
        }
    };

    filterCondition = (params) => {
        this.setState({
            conditions: {
                ...params,
                title: this.state.title,
                pageSize: this.state.pageSize
            },
            page: 1
        }, () => {
            this.filterRecords();
            this.saveQueryCondition();
            this.props.setNumberPageRecords(this.props.currentPage);
        })
    };

    saveQueryCondition = () => {
        const {disabledPrev, disabledNext} = this.state;
        const stateConditions              = recordBuilderCondition.makeParams(this.state.conditions);
        const conditions                   = this.makeQueryConditions({...stateConditions, page: this.state.page});
        this.props.saveQueryCondition(conditions, this.state.conditions, {
            disabledNext,
            disabledPrev
        }, this.state.currentTags, this.state.pageSize);
    };

    filterRecords() {
        const params = recordBuilderCondition.makeParams(this.state.conditions);
        this.props.loadRecords({...params, page: this.state.page, title: this.state.title}, this.state.currentTags);
        this.setState({selected: false});
        this.props.setNumberPageRecords(this.props.currentPage);
    }

    isChecked = () => {
        return !this.props.records.filter(record => record.isChecked).length
    };

    handleCheckRecords = (index) => {
        this.props.selectedRecord(index);
    };

    handleCheckAll = () => {
        this.setState({
            selected: !this.state.selected
        }, () => this.props.checkAllRecords(!this.state.selected))
    };

    deleteRecords = () => {
        const {recordsSelected} = this.state.countRecords;
        this.props.deleteRecords(recordsSelected, {...this.props.params, page: 1}, this.state.currentTags);
        this.setState({
            showModalDeleteRecords: !this.state.showModalDeleteRecords
        });
    };

    countRecordsDelete = (records) => {
        let countRecords = records.filter(record => record.isChecked);
        return {
            recordsSelected: countRecords,
            count: countRecords.length,
            name: countRecords.map(record => record.title).join(', ')
        }
    };

    closeModalDeleteRecords = () => {
        const {records} = this.props;
        this.setState({
            showModalDeleteRecords: !this.state.showModalDeleteRecords,
            countRecords: this.countRecordsDelete(records)
        })
    };

    handleShowModal       = () => {
        this.setState({
            show: !this.state.show
        })
    };

    handleRemoveCondition = (condition) => {
        const {conditions}                           = this.props;
        const {staffName, customerName, titleSurvey} = this.state.conditions;
        const {disabledPrev, disabledNext}           = this.state;
        let newConditions                            = conditions.filter(itemCondition => itemCondition.label !== condition.label);
        let conditionRemove                          = conditions.filter(itemCondition => itemCondition.label === condition.label);
        let removeParams                             = recordBuilderCondition.makeParamsRemoved(conditionRemove);
        this.props.removedCondition(removeParams);
        const newParams = recordBuilderCondition.makeParamsFromCondition(newConditions);
        this.setState({
            conditions: {
                ...newParams,
                staffName,
                customerName,
                titleSurvey,
                title: this.state.title
            },
            page: 1,
            defaultValue: this.state.pageSize
        }, () => {
            this.props.saveQueryCondition(newConditions, this.state.conditions, {
                disabledPrev,
                disabledNext
            }, this.state.currentTags, this.state.pageSize);
            this.filterRecords();
        })
    };

    handleChangeTags = (options) => {
        this.setState({
            currentTags: options ? options : [],
            page: 1
        }, () => this.saveQueryCondition())
    };

    handleSearchTags = () => {
        let currentState = {
            title: this.state.title,
            page: 1,
            ...this.state.conditions
        };
        this.props.loadRecords(currentState, this.state.currentTags);
        this.setState({
            page: 1
        }, () => {
            this.saveQueryCondition();
        });
    };

    render() {
        const {records, t, loading, conditions, currentPage, totalPage} = this.props;
        return (
            <div className='survey-list default-background-color'>
                <FilterRecordsWeb
                    show={!this.state.show}
                    showModal={this.handleShowModal.bind(this)}
                    filterCondition={this.filterCondition.bind(this)}
                />
                <div hidden={this.state.show} className='padding-responsive'>
                    {
                        <div>
                            <div
                                className={`d-flex justify-content-between ${!records.length ? 'display-none-important' : ''}`}>
                                {authService.isAdmin() ?
                                <BulkAction
                                    disabled={this.isChecked()}
                                    title={t('RecordList.BulkAction')}
                                    style={{minWidth: 180}}
                                    items={this.bulkActionItems}
                                    onSelect={event => {
                                        if (event.kirokuSelected.value === 'delete') {
                                            this.closeModalDeleteRecords()
                                        }
                                    }}
                                    resource-permission={'|recordList|button-bulkAction|'}
                                /> : '' }
                                <div/>
                                <div className='row pr-3'>
                                    <span className='pr-2 pt-2 text-page-size' >{t('common.PageSize')}</span>
                                    <KirokuDropdownClose
                                        className={'pr-1 mr-2 dropdown-page-size'}
                                        title={this.state.defaultValue}
                                        items={this.selectPaginationForPage(setSizePagination)}
                                        onSelect={ event => {
                                            this.choicePagination(event.kirokuSelected.value)
                                        }}
                                    />
                                    <KirokuPagination
                                        className={'pb-2'}
                                        disabledPrev={currentPage === 1}
                                        disabledNext={currentPage === totalPage}
                                        increasePage={this.increasePage.bind(this)}
                                        decreasePage={this.decreasePage.bind(this)}
                                        currentPage={currentPage}
                                        totalPage={totalPage}
                                    />
                                </div>
                            </div>
                            <div className={'pb-3'}>
                                <KirokuSectionDropdownShowMore
                                    isSearchTags
                                    tagType={'Customer'}
                                    onChange={this.handleChangeTags.bind(this)}
                                    value={this.state.currentTags}
                                    options={this.state.groupedOptions}
                                    handleSearchTags={this.handleSearchTags.bind(this)}
                                    placeholder={t('Customer.placeholderSearch')}
                                />
                            </div>
                            <KirokuCardWeb>
                                <div className='d-flex justify-content-between p-3'>
                                    <KirokuButton color={'white'} onClick={this.handleShowModal.bind(this)}>
                                        <span className={'pr-2'}>{t('Surveys.FilterBy')}</span>
                                        <i className='fa fa-chevron-down text-secondary'
                                           style={{fontSize: '10px'}}/>
                                    </KirokuButton>
                                    <div className='pl-1' style={{minWidth: 100}}>
                                        <KirokuInputSearcher
                                            value={this.state.title}
                                            onSubmit={this.onSubmitInputSearch.bind(this)}
                                            onChange={this.inputSearch.bind(this)}
                                            placeholder={t('common.Search')}/>
                                    </div>
                                </div>
                                <div className='pl-3'>
                                    {
                                        conditions.map((condition, index) => {
                                            return condition.value && condition.query !== 'page' && condition.query !== 'title' && condition.query !== 'pageSize' ?
                                                <span key={index} className='mr-6'>
                                            <KirokuLabel
                                                style={{
                                                    border: 'solid 1px #1991eb'
                                                }}
                                                onClick={this.handleRemoveCondition.bind(this, condition)}
                                                color={'default'}>
                                                <span className='mt-1'>{condition.label}</span>
                                                <span className='align-icon'>&times;</span>
                                            </KirokuLabel>
                                    </span> : ''
                                        })
                                    }
                                </div>
                                {records.length ?
                                    <div className='pt-2'>
                                        {!loading ?
                                            <KirokuTable>
                                                <THeader>
                                                    <tr>
                                                        {authService.isAdmin() ?
                                                        <th style={{width: 50}}>
                                                            <KirokuCheckBox onChange={this.handleCheckAll.bind(this)}
                                                                            checked={this.props.statusCheck}/>

                                                        </th> : <th style={{width: 0}}/> }
                                                        <th style={{minWidth: 180}}>{t('common.Title')}</th>
                                                        <th style={{minWidth: 180}}>{t('common.Description')}</th>
                                                        <th style={{minWidth: 100}}>{t('common.Customer')}</th>
                                                        <th style={{width: 100}}>{t('common.Staff')}</th>
                                                        <th style={{maxWidth: 140}}>{t('common.DateCreated')}</th>
                                                        <th className='text-center'
                                                            style={{width: 220}}>{t('common.Action')}</th>
                                                    </tr>
                                                </THeader>
                                                <TBody>
                                                    {records.map((record, index) =>
                                                        <tr key={record.id}>
                                                            {authService.isAdmin() ?
                                                                <th style={{width: 50}}>
                                                                <KirokuCheckBox
                                                                    onChange={(e) => this.handleCheckRecords(index)}
                                                                    checked={record.isChecked || false}
                                                                />
                                                            </th>: <th/> }
                                                            <td>
                                                                <Link to={`/records/record/${record.id}`}>
                                                                    <span>{record.title}</span>
                                                                    <span className='pl-2'><KirokuCheckDraft
                                                                        draftable={record}/></span>
                                                                </Link>
                                                            </td>
                                                            <td>{record['survey_template'].description}</td>
                                                            <td>{record.customer.name}</td>
                                                            <td>{record['user'].name}</td>
                                                            <td style={{whiteSpace : 'nowrap'}}>{moment.unix(record.create_at).format(config.formatDateTimeTh)}</td>
                                                            <td>
                                                                <div className='d-flex justify-content-between p-1 fix-icon-align' style={{minWidth: 179}}>
                                                                    <Link to={`/records/edit-filedAll/${record.id}`}>
                                                                        <i className="fas fa-pencil-alt text-secondary"/><span
                                                                        className='ml-1  text-dark'>{t('common.Edit')}</span>
                                                                    </Link>

                                                                    <Link to={`/records/edit/${record.id}`}>
                                                                        <i className="fas fa-pencil-alt text-secondary"/><span
                                                                        className='ml-1  text-dark'>{t('common.EditRecordOldUI')}</span>
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </TBody>
                                            </KirokuTable> : <KirokuLoading/>}
                                    </div> : <SearchNotFound/>}
                            </KirokuCardWeb>
                            <div
                                className={`pt-10 d-flex justify-content-between ${!records.length ? 'display-none-important' : ''}`}>
                                {authService.isAdmin() ?
                                    <BulkAction
                                        disabled={this.isChecked()}
                                        location={'top'}
                                        title={t('RecordList.BulkAction')}
                                        items={this.bulkActionItems}
                                        onSelect={event => {
                                            if (event.kirokuSelected.value === 'delete') {
                                                this.closeModalDeleteRecords()
                                            }
                                        }}
                                        resource-permission={'|recordList|button-bulkAction|'}
                                    /> : ''}
                                <div/>
                                <div>
                                    <KirokuPagination
                                        disabledPrev={this.state.disabledPrev || this.state.pageMin === this.state.page}
                                        disabledNext={this.state.disabledNext || this.state.pageMin === this.props.totalPage}
                                        increasePage={this.increasePage.bind(this)}
                                        decreasePage={this.decreasePage.bind(this)}
                                        currentPage={currentPage}
                                        totalPage={this.props.totalPage}
                                    />
                                </div>
                            </div>
                            <KirokuModal show={this.state.showModalDeleteRecords}
                                         onClick={this.closeModalDeleteRecords.bind(this)}>
                                <KirokuModalBody>
                                    <div className='text-center pt-3'>
                                        <b>{t('RecordList.AreYouWantDelete')}</b>
                                        <p>{this.state.countRecords.name}</p>
                                    </div>
                                </KirokuModalBody>
                                <KirokuModalFooter>
                                    <KirokuButton onClick={this.closeModalDeleteRecords.bind(this)} color={'white'}>
                                        {t('common.Cancel')}
                                    </KirokuButton>
                                    <KirokuButton onClick={this.deleteRecords.bind(this)} color={'primary'}>
                                        {t('common.Confirm')}
                                    </KirokuButton>
                                </KirokuModalFooter>
                            </KirokuModal>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default RecordsWeb;

