import React, { Component }        from 'react';
import '../templates.css'
import { checkUseHeadingQuestion } from "../../Surveys/SurveyEditor/SurveyService";

class TemplateQuestion extends Component {

    static renderQuestion(answer) {
        switch (answer.type) {
            case "Media":
                return (
                    <div className="pb-3">
                        <img className="report-tab" src={answer.answer} alt=""/>
                    </div>
                );
            case "YesNo":
            case "Date":
                return (<div className="answer-content">{answer.answer}</div>);
            default:
                return (<div className="answer-content">{answer.answer}</div>)
        }
    }

    render() {
        const {reports} = this.props;
        return (
             <div className="report-tab container">
                <div className="wrap-content">
                    <div className="A4">
                        <div className="content">
                            <div className="wrap-bg">
                                {reports.templates.map((template, index) => {
                                    return (
                                        <div className="pb-4" key={index}>
                                            <div>
                                                <label className="report-tab">
                                                    {template.translate_title}
                                                </label>
                                                <div className="border-title-template">
                                                    {template.title}
                                                </div>
                                                {template.groupCustomer.map((customer, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <div className="pb-2 pt-3">
                                                                <label className="report-tab">
                                                                    {reports.translate_customerName}
                                                                </label>
                                                                <div className="border-customer-name">
                                                                    {customer.customerName}
                                                                </div>
                                                            </div>
                                                            {customer.questions.map((question, index) => {
                                                                return (
                                                                    <div className="pb-3 " key={index}>
                                                                        <div className="content-question pb-2 pt-2">
                                                                            {checkUseHeadingQuestion(question)}
                                                                        </div>
                                                                        <div className="row ml-0 mr-0 ">
                                                                            <div className="col-3 pl-0 ">
                                                                                <label className="report-tab">
                                                                                    {reports.translate_serviced}
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-3 ">
                                                                                <label className="report-tab">
                                                                                    {reports.translate_created_by}
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-6 ">
                                                                                <label className="report-tab">
                                                                                    {reports.translate_answer}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="answers-list">
                                                                            {question.answers.map((answer, index) => {
                                                                                return(
                                                                                    <div
                                                                                        className={`${index === 0 ? '' : 'pt-10px'} 
                                                                                        ${answer.answerEnd ? "end-answer" : "answer"}
                                                                                        pb-10px row ml-0 mr-0 answer-item`}
                                                                                        key={index}>
                                                                                        <div className="col-3 pl-0">
                                                                                            {answer.serviced_date_time}
                                                                                        </div>
                                                                                        <div className="col-3  ">
                                                                                            {answer.record_by}
                                                                                        </div>
                                                                                        <div className="col-6">
                                                                                            {TemplateQuestion.renderQuestion(answer)}
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TemplateQuestion;
