import React, {Component}        from 'react';
import {withNamespaces}          from 'react-i18next';
import KirokuCardWeb             from '../../../ui/KirokuCardWeb';
import KirokuCardTitleWeb        from '../../../ui/KirokuCardTitleWeb';
import KirokuCardBodyWeb         from '../../../ui/KirokuCardBodyWeb';
import {checkUseHeadingQuestion} from "../../Surveys/SurveyEditor/SurveyService";

@withNamespaces()
class Message extends Component {

    componentDidMount() {
        this.props.onAnswer({answer: 'message', validated: {}})
    }


    render() {
        const {
            question,
            // t, index
        } = this.props;
        const contentQuestion = checkUseHeadingQuestion(question);

        return (
            <div id='component-question'>
                <div id='web-q' className='pb-3'>
                    <KirokuCardWeb>
                        <KirokuCardTitleWeb color={'card-web'}>
                            <div className='number-question question-number text-overflow-elip'>
                                {contentQuestion}
                            </div>
                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb>
                            <span className='content-name-question'>{question.content}</span>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                </div>
            </div>
        );
    }
}

export default Message;
