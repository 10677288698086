import config from '../config';

class FilePathService {

    resolveFilePath(fileName) {
        const directory = config.filePath.directory;
        return directory ?  `${config.filePath.directory}assets/img/${fileName}` : `/assets/img/${fileName}`
    }

    resolvePathFileLanguage(fileName) {
        const directory = config.filePath.directory;
        return directory ? `${config.filePath.directory}locales/${fileName}` : `/locales/${fileName}`
    }

    getUrlToJsonPage(url) {
        let hash;
        let myJson = {};
        let hashes = url.slice(url.indexOf('?') + 1).split('&');
        for (let i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            myJson[hash[0]] = hash[1];
        }
        return myJson;
    }
}

export default FilePathService;
