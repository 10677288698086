import { ANALYSIS_QUERY }   from '../actions/Analysis';
import { groupRecordByDay } from '../reducers/Analysis';
import moment               from 'moment';
import _                    from 'lodash';

function shiftDate(date, numDays) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + numDays);
    return moment(newDate).format('YYYY/M/D');
}

function getRange(count) {
    return Array.from({ length: count }, (_, i) => i);
}

function renderRecordsAfterSearch(recordsByDate, startDate, endDate) {
    const newStartDate = moment.unix(startDate).subtract(1, 'day');
    const newEndDate   = moment.unix(endDate);
    const numberDays   = newEndDate.diff(newStartDate, 'day');
    let rangeOfDate = getRange(numberDays).map(index => {
        return {
            date : shiftDate(newEndDate, -index),
            count: 0,
        };
    });

    recordsByDate.map(key => {
        const indexRecordsDateWithRangeOfDate = _.findIndex(rangeOfDate, (item) => item.date === key.servicedAtForChart);
        if (indexRecordsDateWithRangeOfDate !== -1) {
            rangeOfDate[indexRecordsDateWithRangeOfDate] = {
                ...rangeOfDate[indexRecordsDateWithRangeOfDate],
                count: key.recordOnlyQuestions.length,
            };
        }
        return {
            date : key.day,
            count: key.recordOnlyQuestions.length,
        };
    });

    return {
        data : rangeOfDate,
        condition: {
            customerId: recordsByDate.map(item => item.customerId)[0],
            templateId : recordsByDate.map(item => item.templateId)[0]
        }
    }
}

export const RecordsForAnalysis = (store) => next => action => {
    switch (action.type) {
        case ANALYSIS_QUERY :
            return next({
                ...action,
                dataForAnalysis: renderRecordsAfterSearch(groupRecordByDay(action.records), action.query.startDate, action.query.endDate),
            });
        default:
            return next(action);
    }
};
