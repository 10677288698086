export const LOAD_DASHBOARD           = "LOAD_DASHBOARD";
export const COMPARISON_PREVIOUS_TERM = "COMPARISON_PREVIOUS_TERM";
export const DASHBOARD_ACTIVITIES     = "DASHBOARD_ACTIVITIES";
export const DASHBOARD_RESET          = "DASHBOARD_RESET";

export function loadDashboard() {
    return {
        type: LOAD_DASHBOARD
    }
}
export function comparisonPreviousTerm() {
    return {
        type: COMPARISON_PREVIOUS_TERM
    }
}
export function dashboardActivities(page) {
    return {
        type: DASHBOARD_ACTIVITIES,
        ...page
    }
}
export function dashboardReset() {
    return {
        type: DASHBOARD_RESET,
    }
}