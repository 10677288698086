import { getItems } from './DeletedList';
import moment       from 'moment';

const initState = {
    keywords            : '',
    keywordsEd          : '',
    items               : getItems(20),
    currentPage         : 1,
    totalPage           : 20,
    sortByUpdateAt      : 'update_at',
    orderByUpdateAt     : 'DESC',
    sortByServiceAt     : 'serviced_at',
    orderByServiceAt    : 'DESC',
    useSortBy           : 'serviced_at',
    useOrderBy          : 'DESC',
    statusRecord        : 'recorded',
    typeSearch          : 'SEARCH_BY_CUSTOMER_TAG', // 'SEARCH_BY_TEAM_TAG', SEARCH_CUSTOM_CUSTOMER
    recordsBeta         : [],
    userLogged          : null,
    customersKey        : [],
    servicedAt          : {
        startDate      : null,
        endDate        : null,
        momentStartDate: null,
        momentEndDate  : null,
    },
    updatedAt           : {
        startDate      : null,
        endDate        : null,
        momentStartDate: null,
        momentEndDate  : null,
    },
    customerIds         : [],
    customerTagByUser   : [],
    customerOfTeamByUser: [],
    templateIds: [],
    updateByIds: [],
    totalRecords: 0,
    recordIds  : [],
    tabType    : 'RECORD_TAB',
    contentComment : '',
    isLoadingComment: false,
    historyLogs         : {
        logs: [],
        hasNext: true,
        currentPage: 1
    },
    logView             : [],
    isLoading           : false,
    isFinish            : false,
    typeSearchRead      : 'UNREAD'
};

const RecordsBeta = (state = initState, action) => {
    switch (action.type) {
        case 'SET_NULL_RECORD':
            return {
                ...state,
                logView     : [],
                historyLogs: {
                    logs: [],
                    hasNext: true,
                    currentPage: 1
                },
            };
        case 'LOAD_RECORD_DETAIL_BETA':
            return  {
                ...state,
                contentComment: ''
            };
        case 'LOADING_COMMENT_RECORD':
            return  {
                ...state,
                isLoadingComment: !state.isLoadingComment
            };
        case 'HANDLE_SUBMIT_COMMENT_RECORD':
            return {
                ...state,
                contentComment: '',
            };
        case 'SET_FINISH_LOAD_API' :
            return  {
                ...state,
                isFinish: true
            };
        case 'GET_CUSTOMERS_BY_USER_AND_CUSTOMERS_BETA_INIT':
            return {
                ...state,
                items            : getItems(action.totalPage),
                recordsBeta      : action.records,
                totalPage        : action.totalPage,
                currentPage      : action.currentPage,
                totalRecords     : action.totalRecords,
                keywords         : '',
                keywordsEd       : '',
                sortByUpdateAt   : 'update_at',
                orderByUpdateAt  : 'DESC',
                sortByServiceAt  : 'serviced_at',
                orderByServiceAt : 'DESC',
                useSortBy        : 'serviced_at',
                useOrderBy       : 'DESC',
                statusRecord     : 'recorded',
                typeSearch       : 'SEARCH_BY_CUSTOMER_TAG', // 'SEARCH_BY_TEAM_TAG', SEARCH_CUSTOM_CUSTOMER
                userLogged       : null,
                customersKey     : [],
                servicedAt       : {
                    startDate: null,
                    endDate  : null,
                },
                updatedAt        : {
                    startDate: null,
                    endDate  : null,
                },
                customerIds      : action.customersByUserId.map(itm => itm.id),
                customerTagByUser: action.customersByUserId,
                templateIds      : [],
                updateByIds      : [],
                typeSearchRead   : 'UNREAD'
            };
        case 'GET_CUSTOMERS_BY_USER_AND_CUSTOMERS_BETA':
            return {
                ...state,
                customerTagByUser: action.customersByUserId,
                customerIds      : action.customersByUserId.map(item => item.id),
            };
        case 'GET_CUSTOMERS_OF_TEAM_BY_USER':
            return {
                ...state,
                customerOfTeamByUser: action.customers,
            };
        case 'GET_CUSTOMERS_BY_USER':
            return {
                ...state,
                customerTagByUser: action.customers,
            };
        case 'SET_RECORDS_BETA':
            return {
                ...state,
                items       : getItems(action.totalPage),
                recordsBeta : action.records,
                totalPage   : action.totalPage,
                currentPage : action.currentPage,
                totalRecords: action.totalRecords,
            };
        case 'CHANGE_TYPE_SEARCH':
            return {
                ...state,
                typeSearch : action.typeSearch,
                customerIds: action.customerIds,
            };
        case 'CHANGE_STATUS_RECORDED':
            return {
                ...state,
                statusRecord: action.statusRecord,
            };
        case 'CHANGE_KEYWORD':
            return {
                ...state,
                keywords: action.keywords,
            };
        case 'CHANGE_KEYWORD_SUBMIT':
            return {
                ...state,
                keywordsEd: state.keywords,
            };
        case 'CHANGE_NUMBER_PAGE_RECORDS':
            return {
                ...state,
                currentPage: action.currentPage,
            };
        case 'CHANGE_ORDER_RECORD_BETA':
            if (action.sortBy === 'serviced_at') {
                return {
                    ...state,
                    sortByServiceAt : 'serviced_at',
                    orderByServiceAt: action.orderByNew,
                    useSortBy       : action.sortBy,
                    useOrderBy      : action.orderByNew,
                };
            }
            if (action.sortBy === 'update_at') {
                return {
                    ...state,
                    sortByUpdateAt : 'update_at',
                    orderByUpdateAt: action.orderByNew,
                    useSortBy      : action.sortBy,
                    useOrderBy     : action.orderByNew,

                };
            }
            return {
                ...state,
            };
        case 'CHANGE_SERVICE_AT_RECORD_BETA':
            return {
                ...state,
                servicedAt: {
                    ...action.dateRange,
                },
            };
        case 'CHANGE_UPDATE_AT_RECORD_BETA':
            return {
                ...state,
                updatedAt: {
                    ...action.dateRange,
                },
            };
        case 'HANDLE_CONFIRM_MODAL_SELECT_SURVEYS':
            return {
                ...state,
                templateIds: action.templateIds,
            };
        case 'HANDLE_CONFIRM_STAFF_MODAL_SEARCH':
            return {
                ...state,
                updateByIds: action.staffListSelected.map(item => item.id),
            };
        case 'CHANGE_TAB_CARD_RECORD_DETAIL':
            return {
                ...state,
                tabType: action.tabType,
            };
        case 'HANDLE_CHANGE_TEXT_COMMENT':
            return {
                ...state,
                contentComment: action.contentComment,
            };
        case 'LOAD_HISTORY_OF_RECORD' :
            return {
                ...state,
                historyLogs: action.historyLogs,
                logView    : [
                    ...state.logView,
                    ...action.historyLogs.logs,

                ],
            };
        case 'SET_INIT_RECORD_LOG' :
            return {
                ...state,
                historyLogs: {
                    logs: [],
                    hasNext: true,
                    currentPage: 1
                },
                logView    : [],
            };
        case 'HAS_LOAD_RECORD_LOG' :
            return {
                ...state,
                isLoading : false
            };
        case 'LOADING_RECORD_LOG' :
            return {
                ...state,
                isLoading : true
            };
        case 'SET_INIT_RECORD_DETAIL' :
            return {
                ...state,
                tabType    : 'RECORD_TAB'
            };
        case 'CHANGE_TYPE_SEARCH_READ_UNREAD' :
            return {
                ...state,
                typeSearchRead : action.typeSearchRead
            };
        case 'LOAD_RECORD_FROM_ANALYSIS':
            return {
                ...state,
                customerIds: [action.condition.customerId],
                templateIds: [action.condition.templateId],
                servicedAt : {
                    startDate : moment.unix(action.condition.startDate),
                    endDate : moment.unix(action.condition.endDate),
                    momentStartDate: action.condition.startDate,
                    momentEndDate  : action.condition.endDate,
                },
                typeSearchRead : 'ALL',
                typeSearch     : 'SEARCH_CUSTOM_CUSTOMER'
            };
        default :
            return state;
    }


};

export default RecordsBeta;
