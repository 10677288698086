import  React , { Component} from   'react';
import {buildInputText} from "../buildData";
import Chart from 'react-apexcharts'

class InputTextChart extends Component {
    render() {
        const {records ,questionIndex} = this.props;
        const data = buildInputText(records, questionIndex);
        const options = {
            xaxis: {
                type: 'datetime',
            },
            yaxis: {
                max: 24
            }
        };

        return <div>
            <Chart options={options} series={data} type="scatter" width={600} height={400} />
        </div>
    }
}

export default InputTextChart
