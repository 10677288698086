import React, { Component } from 'react';
import { withNamespaces }   from 'react-i18next';

@withNamespaces()
class AboutSurvey extends Component {
    state = {
        show: false,
        dateTime: null,
        profile         : JSON.parse(localStorage.getItem('profile'))
    };

    showModalSelectDateTime() {
        this.setState({
            show: !this.state.show
        })
    }

    handleOnChangeDateTime(datetime) {
        this.setState({
            dateTime: datetime
        })
    }

    onSelectValue() {
        this.setState({
            show: false
        })
    }

    render() {
        const {survey, customer, hidden, t, user} = this.props;
        const {profile} = this.state;
        return (
            <div id={'create-record'}>
                <div className='content-question' hidden={hidden}>
                    <div className='wraper-question'>
                        <div className='wraper-question'>
                            <div className="background-question">
                                <div className='number-question '>
                                    <span className="question-number">{survey.title} </span>
                                </div>
                                {customer ?
                                    <div className='content-heading-question' style={{paddingTop: 0}}>
                                        <div className="d-flex">
                                            <div className='title-record-info'>{t('createRecord.Customer')}</div>
                                            <div className={'pl-2'}>{customer.name}</div>
                                        </div>

                                    </div> : ''
                                }
                                <div className='content-heading-question' style={{paddingTop: 0}}>
                                    <div className="d-flex">
                                        <div className='title-record-info'>{t('createRecord.Staff')}</div>
                                        <div className={'pl-2'}>{user ? user.name : profile.name}</div>
                                    </div>
                                </div>
                                <div className='content-name-question' style={{paddingTop: 0}}>
                                    <div className='title-record-info'>{t('createRecord.Description')}</div>
                                    {survey.description}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{paddingBottom: 30}}>&nbsp;</div>
                </div>
            </div>
        );
    }
}

export default AboutSurvey;
