import React, {Component}        from 'react';
import {withNamespaces}          from 'react-i18next';
import KirokuLogicApplicated     from "../../../../ui/KirokuLogicApplicated";
import {checkUseHeadingQuestion} from "../../../Surveys/SurveyEditor/SurveyService";

@withNamespaces()
class Message extends Component {

    render() {
        const {question, surveyDetail, headerNextQuestion} = this.props;
        const contentQuestion = checkUseHeadingQuestion(question);
        return (
            <div className='content-question'>
                <div className='wraper-question'>
                    <div className="background-question">
                        <div className='number-question d-flex justify-content-between'>
                            <div
                                className="question-number text-overflow-elip">{contentQuestion}</div>
                            <div hidden={!surveyDetail} className={'float-right'}><KirokuLogicApplicated
                                padding={'5px 5px 5px 7px'} question={question}/></div>
                        </div>
                        <span className='content-name-question'>{question.content}</span>
                        <div
                            hidden={typeof question.nextQuestion !== 'number' || !surveyDetail}
                            className='pl-10 text-overflow-elip text-info'>
                            <i className="fas fa-arrow-right"/>
                            <span> {headerNextQuestion}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Message
