import config from '../config';
import QueryString from 'query-string';

class RecordService {
    constructor(axios) {
        this.axios = axios;
    }

    detail(recordId) {
        return this.axios.get(`/survey-record/${recordId}`).then(res => res.data);
    }

    customerForTagRecords () {
        return this.axios.get(`/customer-tag-of-user`).then(res => res.data)
    }
    loadRecords(params, tags) {
        let profile = JSON.parse(localStorage.getItem('profile'));
        let tagCondition = tags.map(tag => {
                return {tagId: tag.tagId, tagType: tag.tagType}
            });
        if (config.orgUsingElasticsearch.includes(profile.organization_id)) {
            return this.axios.post(`/filter-recordsElasticsearch`, {tags: tagCondition}, {
                params: {
                    ...params,
                }
            }).then(res => res.data);
        } else {
            return this.axios.post(`/filter-records`, {tags: tagCondition}, {
                params: {
                    ...params,
                }
            }).then(res => res.data);
        }
    };

    loadRecordsDashBoard() {
        return this.axios.get('/survey-records').then(res => res.data);
    }

    createSurveyRecord(data) {
        return this.axios.post(`/survey-record`, data)
    }

    deleteRecords(records) {
        return this.axios.put('/bulk-delete-records', {records: records})
    }

    deleteRecord(recordId) {
        return this.axios.delete(`/survey-record/${recordId}`)
    }

    updateRecord(id, dataRecord) {
        return this.axios.put(`/survey-record/${id}`, dataRecord);
    }


    searchRecordByCondition(condition) {
        const params = {
            startDate_created: condition.startDate_created ? condition.startDate_created : null,
            endDate_created: condition.endDate_created ? condition.endDate_created : null,
            startDate_serviced: condition.startDate_serviced ? condition.startDate_serviced : null,
            endDate_serviced: condition.endDate_serviced ? condition.endDate_serviced : null
        };
        return this.axios.get(`/record-by-customer/${condition.customerId}`, {params: params}).then(res => res.data)
    }


    exportFile(data, type) {
        return this.axios.post(`/survey-record/export/${type}`, data);
    }

    loadRecordsByConditions(params, conditions) {
        return this.axios.post(`/search-basic`, conditions,
            {
                params: {
                ...params,
            }
        }).then(res => res.data);
    };

    searchAdvancedNewRecords(params, condition) {
        return this.axios.post('/search-advance', condition, {
            params : {
                ...params
            }}).then(res => res.data)
    }

    getRecordsBeta() {
        return this.axios.get('/records-beta').then(res => res.data)
    }

    getWeatherDarkSky(location, timeMachine) {
        let currently = QueryString.stringify({exclude: config.excludeDarkSky}, config.formatParams);
        let url = `https://api.darksky.net/forecast/${config.secretKeyDarkSkyAPI}/${location.latitude},${location.longitude}/${timeMachine}?${currently}`;
        return this.axios
            .get(url)
            .then(res => {
                console.log(res);
        })
    }

    getRecordForDetailPage(params) {
        return this.axios.get('/filter-records-by-customer-detail', {
            params: {
                ...params
            }
        }).then(res => res.data)
    }


}

export default RecordService;
