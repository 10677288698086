import React           from 'react';
import {KirokuLoading} from "../../ui";
import '../Login/style.css';
import {connect}       from "react-redux";
import {KirokuError}   from "../../ui";

@connect(state => {
    return {
        error: state.KirokuAIReject.error,
        message: state.KirokuAIReject.message,
    }
}, dispatch => {

})
class LoginCallback extends React.Component {

    render() {
        return (
            !this.props.error ? (
                <div style={{minHeight: '100vh'}}>
                    <div style={{position: 'relative', height: '100%', width: '100%'}}>
                        <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, margin: 'auto'}}>
                            <div style={{marginTop: '33%'}}>
                                <KirokuLoading/>
                            </div>
                        </div>
                    </div>
                </div>
            ) : <KirokuError message={this.props.message.message}/>

        );
    }

}

export default LoginCallback;
