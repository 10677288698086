import React, { Component } from 'react';

class KirokuForm extends Component {

    handlerOnSubmit(event) {
        event.preventDefault();
        this.props.onSubmit()
    }

    render() {
        return (
            <form onSubmit={this.handlerOnSubmit.bind(this)}>
                {this.props.children}
            </form>
        );
    }
}

export default KirokuForm;