export function turnOnModalAddMember() {
    return {
        type: 'TURN_ON_ADD_MEMBER_TO_TEAM',
    }
}

export function turnOffModalAddMember() {
    return {
        type: 'TURN_OFF_ADD_MEMBER_TO_TEAM',
    }

}

export function turnOnModalSetRole() {
    return {
        type: 'TURN_ON_SET_ROLE_MEMBER'
    }
}

export function turnOffModalSetRole() {
    return {
        type: 'TURN_OFF_SET_ROLE_MEMBER'
    }
}

export function turnOnModalDelete() {
    return {
        type: 'TURN_ON_DELETE'
    }
}

export function turnOffModalDelete() {
    return {
        type: 'TURN_OFF_DELETE'
    }
}

export function checkMemberAddTeam(index) {
    return {
        type: 'CHECK_MEMBER_THIS',
        index
    }
}

export function removeCheckAllListMember() {
    return {
        type: 'REMOVE_CHECK_ALL_MEMBER'
    }
}

export function checkIsChecked() {
    return {
        type: 'CHECK_IS_CHECKED'
    }
}

export function callCreateTeam() {
    return {
        type: 'CALL_CREATE_TEAM'
    }
}

export function getListMember() {
    return {
        type: 'GET_LIST_MEMBER_IS_CHECKED'
    }

}

export function searchMember(name) {
    return {
        type: 'SEARCH_MEMBER_ADD_TEAM',
        name
    }
}

export function checkAllListMember() {
    return {
        type: 'CHECK_ALL_MEMBER'
    }
}

export function getListMemberTHisRemove() {
    return {
        type: 'GET_LIST_MEMBER_CHOICE_THIS_REMOVE'
    }

}

export function searchMemberRemove(name) {
    return {
        type: 'SEARCH_MEMBER_REMOVE_TEAM',
        name
    }
}

export function checkMemberRemoveTeam(index) {
    return {
        type: 'CHECK_MEMBER_THIS_REMOVE',
        index
    }
}

export function checkAllListMemberRemove() {
    return {
        type: 'CHECK_ALL_MEMBER_REMOVE'
    }
}

export function removeCheckAllListMemberRemove() {
    return {
        type: 'REMOVE_CHECK_ALL_MEMBER_REMOVE'
    }
}

export function getListMemberSetRole() {
    return {
        type: 'GET_LIST_MEMBER_SET_ROLE'
    }
}

export function checkMemberSetRole(index) {
    return {
        type: 'CHECK_MEMBER_SET_ROLE',
        index
    }
}

export function searchMemberRole(name) {
    return {
        type: 'SEARCH_MEMBER_ROLE',
        name
    }
}

export function checkAllListMemberSetRole() {
    return {
        type: 'CHECK_ALL_MEMBER_SET_ROLE',
    }
}

export function removeCheckAllListMemberSetRole() {
    return {
        type: 'REMOVE_ALL_MEMBER_SET_ROLE'
    }
}
