import React, {Component} from 'react';
import KirokuModalHeader  from "../../../ui/KirokuModalHeader";
import KirokuInputSearch  from "../../../ui/KirokuInputSearch";
import KirokuModalBody    from "../../../ui/KirokuModalBody";
import KirokuCardList     from "../../../ui/KirokuCardList";
import KirokuModalFooter  from "../../../ui/KirokuModalFooter";
import KirokuButton       from "../../../ui/KirokuButton";
import KirokuModal        from "../../../ui/KirokuModal";
import KirokuCard         from "../../../ui/KirokuCard";
import KirokuCheckBox     from "../../../ui/KirokuCheckBox";
import {withNamespaces}   from "react-i18next";
import {connect}          from "react-redux";
import {
    checkAllListMemberSetRole,
    checkMemberSetRole,
    searchMemberRole,
    turnOffModalSetRole,
    getListMemberSetRole
}                         from "../../../actions/EditorTeam";

@withNamespaces()
@connect(state => {
    return {
        statusModalSetRole: state.EditorTeam.showModalAddRole,
        statusSet: state.EditorTeam.statusSet,
        listMemberSetRole: state.EditorTeam.listMemberSetRole
    }
}, dispatch => {
    return {
        turnOffModalSetRole: () => {
            dispatch(turnOffModalSetRole())
        },
        checkMemberSetRole: (index) => {
            dispatch(checkMemberSetRole(index))
        },
        checkAllListMemberSetRole: () => {
            dispatch(checkAllListMemberSetRole())

        },
        searchMemberRole: (name) => {
            dispatch(searchMemberRole(name))
        },
        getListMemberSetRole: () => {
            dispatch(getListMemberSetRole())
        },
        applySetRole: () => {
            dispatch({
                type: 'APPLY_SET_ROLE'
            })
        }
    }
})
class ModalSetRoleAdd extends Component {

    state = {
        keyword: ''
    };

    componentWillMount() {
        this.props.getListMemberSetRole()
    }

    handleCheckAll() {
        this.props.checkAllListMemberSetRole();
    }

    handleSetRole(index) {
        this.props.checkMemberSetRole(index);
    }

    handleCancel() {
        this.props.turnOffModalSetRole();
        this.setState({
            keyword: ''
        })
    }

    handleApply() {
        this.props.turnOffModalSetRole();
        this.props.applySetRole();
        this.setState({
            keyword: ''
        })
    }

    onSearch(e) {
        this.props.searchMemberRole(e.target.value);
        this.setState({
            keyword: e.target.value
        })
    }

    render() {
        const {t, statusModalSetRole, listMemberSetRole, statusSet} = this.props;
        const {keyword}                                             = this.state;
        return (
            <div>
                <KirokuModal show={statusModalSetRole}>
                    <KirokuModalHeader>
                        <h6 className="mb-3">{t('Team.SetLeader')}</h6>
                        <KirokuInputSearch value={keyword}
                                           onChange={this.onSearch.bind(this)}
                                           placeholder={t('Team.Customer name or team name')}/>
                    </KirokuModalHeader>
                    <KirokuModalBody>
                        <div id="body-modal-as">
                            <KirokuCardList
                                headerLeft={t('common.Members')}
                                headerRight={listMemberSetRole[0] ?
                                    <div className='text-primary' style={{cursor: 'pointer'}}>
                                        <span hidden={statusSet}
                                              onClick={this.handleCheckAll.bind(this)}
                                        >{t('common.SelectAll')}</span>

                                        <span hidden={!statusSet}
                                              onClick={this.handleCheckAll.bind(this)}
                                        >{t('common.UnSelect')}</span>
                                    </div> : <div/>
                                }
                            >
                                <section className="scroll-data-survey">
                                    {listMemberSetRole.map((member, index) => (
                                        <KirokuCard key={member.id} action={<p>{t(`role.${member._pivot_role}`)}</p>}>
                                            <KirokuCheckBox
                                                onChange={this.handleSetRole.bind(this, index)}
                                                key={index}
                                                checked={member._pivot_role === 'leader'}
                                                content={member.name}/>
                                        </KirokuCard>
                                    ))}
                                </section>

                            </KirokuCardList>
                        </div>
                    </KirokuModalBody>
                    <KirokuModalFooter>
                        <KirokuButton color={'white'} onClick={this.handleCancel.bind(this)}>
                            {t('common.Cancel')}
                        </KirokuButton>
                        <KirokuButton color={'primary'}
                                      onClick={this.handleApply.bind(this)}>
                            {t('common.Apply')}
                        </KirokuButton>
                    </KirokuModalFooter>
                </KirokuModal>
            </div>
        )
    }

}

export default ModalSetRoleAdd;