import React, { Component }         from 'react';
import { KirokuCheckBox }           from '../../../ui';
import { withNamespaces }           from 'react-i18next';
import KirokuCardWeb                from '../../../ui/KirokuCardWeb';
import KirokuCardTitleWeb           from '../../../ui/KirokuCardTitleWeb';
import KirokuCardBodyWeb            from '../../../ui/KirokuCardBodyWeb';
import ButtonEditQuestion           from './ButtonEditQuestion';
import PropTypes                    from 'prop-types';
import connect                      from 'react-redux/es/connect/connect';
import { notificationEditQuestion } from '../../../actions/Survey';
import { checkUseHeadingQuestion }  from '../../Surveys/SurveyEditor/SurveyService';
import KirokuButtonLink             from '../../../ui/KirokuButtonLink';

@withNamespaces()
@connect(state => {
    return { takeSurvey: state.TakeSurveyWeb };
}, dispatch => {
    return {
        editQuestion: (flagEdit, indexQuestion) => {
            dispatch(notificationEditQuestion(flagEdit, indexQuestion));
        },
    };
})
class MultiChoice extends Component {

    state = {
        answers  : this.props.value,
        disabled : this.props.disabled,
        flagEdit : false,
        validated: false,
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            const value = nextProps.value;
            this.setState({
                answers: value,
            });
        }

        if (this.props.disabled !== nextProps.disabled || this.props.flagEdit !== nextProps.flagEdit) {
            this.setState({
                disabled: nextProps.disabled,
                flagEdit: nextProps.flagEdit,
            });
        }
    }

    componentDidMount() {
        const { value, question } = this.props;
        const isAnswers = value.filter(answer => !answer[ 'is_choice' ]).length !== value.length;
        this.props.onAnswer({
            answer   : value,
            validated: (question.unrequited || isAnswers) ? value : null,
        });
    }

    handleActionEditQuestion(state) {
        this.setState({
            disabled: state.disabled,
            flagEdit: state.flagEdit,
        });
        this.props.editQuestion(state.flagEdit, this.props.question.index);
        if (!state.flagEdit) {
            this.props.onUpdate({
                answer   : this.state.answers,
                validated: this.state.validated,
            }, this.props.question.index, this.props.index);
        }
    }

    handleOnChange(index, e) {
        const { question } = this.props;
        const newAnswer    = [...this.state.answers];
        newAnswer[ index ] = { ...newAnswer[ index ], is_choice: e.currentTarget.checked };
        const notAnswer    = newAnswer.filter(answer => !answer[ 'is_choice' ]).length === question.answers.length;
        this.setState({ answers: newAnswer, validated: !notAnswer });
        this.props.onAnswer({
            answer   : newAnswer,
            validated: !notAnswer ? newAnswer : null,
        });
    }

    handleResetAnswer() {
        const { question } = this.props;
        let newAnswer  = [...this.state.answers];
        newAnswer = newAnswer.map((answer) => {
            return {...answer, is_choice: false}
        });
        this.setState({answers: newAnswer, validated: false});
        const notAnswer    = newAnswer.filter(answer => !answer[ 'is_choice' ]).length === question.answers.length;
        this.props.onAnswer({
            answer       : newAnswer,
            validated    : (question.unrequited || !notAnswer) && newAnswer
        });
    }

    render() {
        const {
                  question, t,
                  // index,
                  editor,
                  takeSurvey,
              }                     = this.props;
        const { answers, disabled } = this.state;
        const hiddenButtonEdit      = takeSurvey.flagEditing ? takeSurvey.indexQuestion !== question.index : takeSurvey.flagEditing;
        const contentQuestion       = checkUseHeadingQuestion(question);

        return (
            <div id='component-question'>
                <div id='web-q' className='pb-3'>
                    <KirokuCardWeb>
                        <KirokuCardTitleWeb color={'card-web'}>
                            <div className="d-flex justify-content-between">
                                <div className='number-question question-number text-overflow-elip'>
                                    {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                                </div>
                                <div/>
                                <div hidden={disabled}>
                                    <KirokuButtonLink
                                        className='padding-reset-button-link'
                                        onClick={() => {this.handleResetAnswer()}}
                                    >
                                        {t('createRecord.resetAnswer')}
                                    </KirokuButtonLink>
                                </div>
                            </div>
                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb>
                            <div className={disabled ? 'wrap-overlay-action' : ''}>
                                <span className='content-name-question'>{question.content}</span>
                                <div className='answer-question'>
                                    {answers.map((item, index) =>
                                        <KirokuCheckBox key={index}
                                                        checked={item.is_choice}
                                                        id={`${question.index}-${index}`}
                                                        content={item.answer}
                                                        disabled={disabled}
                                                        onChange={disabled ? () => {
                                                        } : this.handleOnChange.bind(this, index)}
                                        />,
                                    )}

                                </div>

                            </div>
                            <div hidden={hiddenButtonEdit || editor}>

                                <ButtonEditQuestion {...this.state}
                                                    isUnrequited={question.unrequited}
                                                    clickEditQuestion={this.handleActionEditQuestion.bind(this)}
                                />
                            </div>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                </div>
            </div>
        );
    }
}

MultiChoice.propTypes = {
    value: PropTypes.array,
};
export default MultiChoice;
