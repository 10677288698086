const initStatus = {
    surveyTemplates: [],
    keyword: '',
    keywordSubmit: '',
    orderByDelete_at: {
        field: 'delete_at',
        type: 'DESC'
    },
    orderByCreate_at: {
        field: 'create_at',
        type: 'DESC'
    },
    useOrderBy: {
        field: 'delete_at',
        type: 'DESC'
    },
    isFinish: false
};

const DeletedSurveys = (state = initStatus, action) => {

    switch (action.type) {
        case 'SET_FINISH_LOAD_API_DEL_SURVEY':
            return  {
                ...state,
                isFinish: true
            };
        case 'SET_SURVEYS_API':
            return {
                ...state,
                surveyTemplates: action.surveys,
            };
        case 'CHANGE_SCREEN':
            return {
                ...state,
                keyword: '',
                keywordSubmit: '',
                orderByDelete_at: {
                    field: 'delete_at',
                    type: 'DESC'
                },
                orderByCreate_at: {
                    field: 'create_at',
                    type: 'DESC'
                },
                useOrderBy: {
                    field: 'delete_at',
                    type: 'DESC'
                },
            };
        case 'CHANGE_KEY_WORD_SURVEYS':
            return {
                ...state,
                keyword: action.keyword
            };
        case 'CHANGE_ORDER_BY_SURVEYS':
            if (action.field === 'delete_at') {
                return  {
                    ...state,
                    orderByDelete_at: {
                        field: action.field,
                        type: action.typeOrder
                    },
                    useOrderBy: {
                        field: action.field,
                        type: action.typeOrder
                    }
                }
            }

            return {
                ...state,
                orderByCreate_at: {
                    field: action.field,
                    type: action.typeOrder
                },
                useOrderBy: {
                    field: action.field,
                    type: action.typeOrder
                }
            };
        case 'SUBMIT_SEARCH_KEYWORD_SURVEYS':
            return {
                ...state,
                keywordSubmit: state.keyword
            };
        default :
            return state;
    }
};
export default DeletedSurveys;
