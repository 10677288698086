import React, {Component}                       from 'react';
import { CheckRerenderResponsive } from "../../ui";
import TotalMobile                              from "./mobile/TotalMobile";
import TotalWeb                 from "./web/TotalWeb";
import {connect}                               from "react-redux";
import { dashboardActivities, dashboardReset } from "../../actions/Dashboard";
import {customerForTagsRecords}                from "../../actions/Record";
import {scrollService}          from "../../services";


@connect(state => {
    return {
        loadingCustomer: state.KirokuAIRecord.loadingCustomer,
    }
}, dispatch => {
    return {
        customerForTagsRecords: () => {
            dispatch(customerForTagsRecords())
        },
        defaultState: () => {
            dispatch({
                type: 'INIT_STATE'
            })
        },
        dashboardReset: () => {
            dispatch(dashboardReset())
        },
        dashboardActivities: (page) => {
            dispatch(dashboardActivities(page))
        }
    }
})

class Dashboard extends Component {
    componentWillMount() {
        scrollService.top();
        this.props.customerForTagsRecords();
        this.props.dashboardActivities({page: 1});
    }

    componentWillUnmount() {
        this.props.dashboardReset();
        this.props.defaultState();
    }

    render() {
        const {history, customerForPin} = this.props;

        return (
            this.props.loadingCustomer ?
                <div className="dashboard default-background-color">
                    <CheckRerenderResponsive mobile={<TotalMobile customerForPin={customerForPin} history={history}/>}
                                 web={<TotalWeb customerForPin={customerForPin} history={history}/>}
                                 class='pb-5'/>
                </div> : <div/>
        )
    }
}

export default Dashboard;
