import React, {Component}                          from 'react';
import { teamService, userService }                from "../../../services";
import KirokuLoading                               from "../../../ui/KirokuLoading";
import {withNamespaces}                            from "react-i18next";
import KirokuCardWeb                               from "../../../ui/KirokuCardWeb";
import "../staff.css"
import TabProfile                                  from "./TabProfile";
import TabChangePassword                           from "./TabChangePassword";
import TabManageTeam                               from "./TabManageTeam";
import KirokuButton                                from "../../../ui/KirokuButton";
import {synchronizeLine, revokeLine}               from "../../../actions/Line";
import {lineService}                               from "../../../services";
import {connect}                                   from "react-redux";
import jwt                                         from "jsonwebtoken";
import config                                      from "../../../config";
import {KirokuLabel} from "../../../ui";

//import StateFormProvider           from "../../../FormWithControl/StateFormProvider";

@withNamespaces()
@connect(state => {
    return {
        profile: state.KirokuAIUser.profile,
        loadingAllCustomer: state.KirokuAICustomer.loadingAllCustomer,
        customers: state.KirokuAICustomer.ListAllCustomer
    }
}, dispatch => {
    return {
        synchronizeLine: (code) => dispatch(synchronizeLine(code)),
        revokeLine: () => dispatch(revokeLine())
    }
})
class StaffEditorWeb extends Component {
    state = {
        user: {},
        customerRecords: {},
        customer: {},
        teams: [],
        loadingImg: false,
        errors: this.props.errors,
        showTeams: true,
        showChangePassword: true,
        showProfile: false,
        isLoading: false
    };

    async service(id) {
        let user  = await userService.loadDetail(id);
        let teams = await teamService.loadTeams();
        Promise.all([user, teams]).then(value => {
            this.setState({
                user     : {...value[0], tags: value[0].tags ? value[0].tags : []},
                teams    : value[1],
                id       : this.props.id,
                isLoading: true
            })
        })
    }

    componentWillMount() {
        this.service(this.props.id)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.id) {
            this.service(nextProps.id)
        }
    }

    showTeams() {
        this.setState({
            showTeams         : false,
            showChangePassword: true,
            showProfile       : true
        })
    }

    showChangePassword() {
        this.setState({
            showTeams         : true,
            showChangePassword: false,
            showProfile       : true
        })
    }

    showProfile() {
        this.setState({
            showTeams         : true,
            showChangePassword: true,
            showProfile       : false
        })
    }

    onChangeCustomer(customer) {
        let newsCustomer = Object.assign(this.state.customerRecords, customer);
        this.setState({
            customerRecords: newsCustomer
        })
    }

    currentError = (errors) => {
        this.setState({errors: {...this.state.errors, ...errors}})
    };

    goBack() {
        this.props.history.goBack()
    }

    newRoleTeam(newTeam) {
        this.setState({
            teams: newTeam
        })
    }

    revokeLine = async () => {
        let result = await lineService.revokeLine();
        let user   = await userService.loadDetail(this.props.id);
        let teams  = await teamService.loadTeams();
        localStorage.setItem('token', result.data.token);
        let profile = jwt.verify(result.data.token, config.auth.authPrivateKey);
        localStorage.setItem('profile', JSON.stringify(profile));
        Promise.all([user, teams]).then(value => {
            this.setState({
                user: value[0],
                teams: value[1],
            })
        });
    };

    currentTeam() {
        this.service(this.props.id)
    }

    render() {
        const {showProfile, showTeams, showChangePassword, user, teams} = this.state;
        let {t}                                                         = this.props;
        return (
            <div className='default-background-color'>
                <div className='padding-responsive staff-detail'>
                    {this.state.isLoading && this.props.loadingAllCustomer ?
                        <div>
                            <div className='d-flex justify-content-between pb-3'>
                                <KirokuButton onClick={this.goBack.bind(this)} color={'white'}>
                                    {t('common.Back')}
                                </KirokuButton>
                            </div>
                            <div className="pb-2">
                                <KirokuCardWeb>
                                    <div className='p-3 row'>
                                        <div className='col-md-12' style={{borderBottom: 'solid 1px #dfe3e9'}}>
                                            <div className='d-flex justify-content-between pt-10' style={{width: 400}}>
                                                <div style={{cursor: 'pointer'}}
                                                     className={`nav-bar ${!showProfile ? 'active' : ''}`}
                                                     onClick={this.showProfile.bind(this)}>{t('staff.ProfileDetail')}
                                                </div>
                                                <div style={{cursor: 'pointer'}}
                                                     className={`nav-bar ${!showChangePassword ? 'active' : ''}`}
                                                     onClick={this.showChangePassword.bind(this)}>{t('common.ChangePassword')}
                                                </div>
                                                <div style={{cursor: 'pointer'}}
                                                     className={`nav-bar ${!showTeams ? 'active' : ''}`}
                                                     onClick={this.showTeams.bind(this)}>{t('common.Team')}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='pt-0 padding-responsive'>
                                        <TabProfile history={this.props.history}
                                                    show={this.state.showProfile}
                                                    customers={this.props.customers}
                                                    id={this.props.id}
                                                    profile={user}
                                                    teams={teams}/>
                                        <TabChangePassword history={this.props.history} id={user.id}
                                                           show={this.state.showChangePassword} isTFA={user["2fa"]} />
                                        <TabManageTeam
                                            history={this.props.history}
                                            show={this.state.showTeams}
                                            user={user}
                                            currentTeam={this.currentTeam.bind(this)}
                                            teams={user.staffInTeam}
                                        />
                                    </div>

                                </KirokuCardWeb>
                            </div>
                            <div hidden={this.state.showProfile} className='pt-3 pb-3'>
                                <KirokuCardWeb>
                                    <h5 className='pt-3 pl-3 pr-3  m-0'>{t('common.Security')}</h5>
                                    <div className="row pl-3 pr-3 pb-3">
                                        <div className="col-md-8 pt-2">
                                            <span className='customer-info f-bold'>{t('common.Two-factorAuthentication')}:</span>
                                        </div>
                                        <div className="col-md-4">
                                            {!!user["2fa"] ? (
                                                <KirokuLabel color="primary">
                                                    {t('common.enabled')}
                                                </KirokuLabel>
                                            ) : (
                                                <KirokuLabel color="danger">
                                                    {t('common.disabled')}
                                                </KirokuLabel>
                                            )}
                                        </div>
                                    </div>
                                </KirokuCardWeb>
                            </div>
                        </div> : <KirokuLoading/>
                    }
                </div>
            </div>
        );
    }

}

export default StaffEditorWeb;
