import React  from 'react';
import {Link} from 'react-router-dom';

export default class LinkComponent extends React.Component {
    render() {
        return (
            this.props.hidden ? "" :
                (
                    <span className={`${this.props.disabled === true ? "disabled-link" : ""}`}>
                        <Link to={this.props.href} {...this.props}>
                            {this.props.icon}
                            {/*<i className="fas fa-pencil-alt text-secondary"/>*/}
                            <span className={`ml-1 ${this.props.color}`}>{this.props.text}</span>
                        </Link>
                    </span>
                )
        );
    }
}