import React, {Component} from 'react';
import moment             from "moment";
import KirokuButton       from "../../../ui/KirokuButton";
import {withNamespaces}   from "react-i18next";
import {KirokuDateRangePicker} from "../../../ui";

moment.locale('ja');
@withNamespaces()
class ModalDateTimeRangePicker extends Component {

    state = {
        startDate: null,
        endDate: null,
        focusedInput: null
    };

    onChangeDate(date) {
        this.setState({
            startDate: date.startDate,
            endDate: date.endDate
        })
    }

    closeModalDateTime = () => {
        const {startDate, endDate} = this.state;

        this.props.applyDateRange({
            startDate: startDate ? moment(startDate, 'YYYY-MM-DD').startOf('day').unix() : null,
            endDate: endDate ? moment(endDate, 'YYYY-MM-DD').endOf('day').unix(): null
        });
    };

    render() {
        const {t} = this.props;
        return (
            <div hidden={this.props.show}>
                <div className='d-flex'>
                    <div className='pr-5 pl-4'>
                        <KirokuDateRangePicker
                            onChangeDate={this.onChangeDate.bind(this)}
                        />
                    </div>
                    <div>
                        <KirokuButton onClick={this.closeModalDateTime.bind(this)} color={'primary'}>
                            {t('common.Apply')}
                        </KirokuButton>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalDateTimeRangePicker;
