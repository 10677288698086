import React, { Component }      from 'react';
import moment                    from 'moment';
import KirokuInputIcon           from '../../../../ui/KirokuInputIcon';
import { withNamespaces }        from 'react-i18next';
import KirokuLogicApplicated     from "../../../../ui/KirokuLogicApplicated";
import {checkUseHeadingQuestion} from "../../../Surveys/SurveyEditor/SurveyService";
import config                    from '../../../../config';

@withNamespaces()
class DateTime extends Component {

    render() {
        const {question, value, surveyDetail, headerNextQuestion, t} = this.props;
        const contentQuestion      = checkUseHeadingQuestion(question);
        return (
            <div className='content-question'>
                <div className='wraper-question'>
                    <div className="background-question">
                        <div className='number-question d-flex justify-content-between'>

                            <div className="question-number text-overflow-elip">
                                {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                            </div>
                            <div hidden={!surveyDetail} className={'float-right'}>
                                <KirokuLogicApplicated padding={'5px 5px 5px 7px'} question={question}/>
                            </div>
                        </div>
                        <span className='content-name-question'>{question.content}</span>
                        <div
                            hidden={ typeof question.nextQuestion !== 'number' || !surveyDetail }
                            className='pl-10 height-text-next-question text-overflow-elip text-info'>
                            <i className='fas fa-arrow-right' style={{paddingTop: 4}}/>
                            <span> {headerNextQuestion}</span>
                        </div>
                    </div>
                    <div className='answer-question'>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <KirokuInputIcon readOnly name={'date'} icon={'far fa-calendar-alt'}
                                                 style={{
                                                     height: "35px",
                                                     paddingLeft: '2rem',
                                                     color: "#0a1d42",
                                                     pointerEvents: "none"
                                                 }}
                                                 value={value ? moment.unix(this.props.value).format(config.formatDateTh) : ''}
                                />
                                <div className={'mb-2 mt-2'}/>
                                <KirokuInputIcon readOnly name={'clock'} icon={'far fa-clock'}
                                                 style={{
                                                     height: "35px",
                                                     paddingLeft: '2rem',
                                                     color: "#0a1d42",
                                                     pointerEvents: "none"
                                                 }}
                                                 type={'time'}
                                                 value={value ? moment.unix(this.props.value).format(config.formatTime) : ''}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DateTime;
