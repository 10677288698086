import KirokuStaffTag     from "./KirokuStaffTag";
import React, {Component} from "react";
import * as _             from "lodash";
import {withNamespaces}   from "react-i18next";
import Link               from "react-router-dom/es/Link";
import KirokuButton       from "./KirokuButton";
import {filePathService}  from "../services";

@withNamespaces()
class KirokuTeamList extends Component {

    state = {
        show: true,
    };

    handleClick() {
        this.setState( {
            show: !this.state.show
        })
    }

    closeModal() {
        this.setState( {
            show: !this.state.show
        })
    }

    render() {
        const tags   = this.props['listTags'];
        let {t}      = this.props;
        let tagShow  = tags.slice(0, this.props['numberTags'] || 1);
        let showTags = _.differenceBy(tags, tagShow, 'key');
        let tagMore  = tags.length - tagShow.length;
        return (
            <span className="m-2 tags-list">
                <KirokuStaffTag type={this.props.type} tag={tagShow}/>
                <span style={{display: "inline-block"}} hidden={tagMore === 0}>
                    {this.props.type === "teams" ?
                        <span className="kiroku-btn-data-more"
                              onClick={this.handleClick.bind(this)}>{t('common.viewMoreTeam', {value: tagMore})}</span> :
                        <span className="kiroku-btn-data-more"
                              onClick={this.handleClick.bind(this)}>{t('common.viewMore', {value: tagMore})}</span>
                    }
                        <div>
                            <div className={!this.state.show ? "opacity-main" : ""}>
                                <div className="overlay"/>
                            </div>
                            <div className={this.state.show ? "display-none" : "modal-kiroku fade show"}>
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div style={{maxHeight: '70vh', overflowY: 'auto'}}>
                                                {showTags.map(tag =>
                                                    <div key={tag.tagId} className='dropdown-item-kiroku' style={{whiteSpace: 'normal'}}>
                                                    <div className="mr-1">
                                                        {this.props.type === "teams" ?
                                                            <Link to={`/teams/team-detail/${tag.tagId ? tag.tagId : tag.id}`}>
                                                                <span>
                                                                    {tag.name ? tag.name : tag.value}
                                                                </span>
                                                            </Link> :
                                                            <Link to={`/staffs/staff/${tag.tagId ? tag.tagId : tag.id}`}>
                                                                <div className="avatar-staff"
                                                                     style={{backgroundImage: `url(${tag.avatar ? tag.avatar : `${filePathService.resolveFilePath('profile-ic.svg')}`})`}}/>
                                                                <span>
                                                                    {tag.name ? tag.name : tag.value}
                                                                </span>
                                                            </Link>
                                                        }
                                                    </div>
                                                    </div>)}
                                            </div>
                                        </div>
                                        <div className="modal-footer-kiroku modal-footer" style={{padding: '0.4rem'}}>
                                            <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                                                 {t('common.Cancel')}
                                            </KirokuButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </span>
            </span>
        );
    }
}

export default KirokuTeamList;
