import React, { Component } from 'react';
import { withNamespaces }   from 'react-i18next';
import { Link }             from 'react-router-dom';
import FormatTimestamp      from '../../HelperComponents/FormatTimestamp';
import { connect }          from 'react-redux';
import { currentPage }      from '../../../../../actions/CurrentPage';
import { KirokuTitlepage }  from '../../../../../ui';

@withNamespaces()
@connect(() => {
    return {}
}, dispatch => {
    return {
        namePage        : (name) => {
            dispatch(currentPage(name));
        },
    }
})
class CardRecordDetailsWeb extends Component {
    componentWillMount() {
        const {t} = this.props;
        this.props.namePage({
            title     : <KirokuTitlepage title={ t('Layout.RecordSimpleDetail') }/>,
            actionPage: null,
        })
    }

    render() {
        const { record, t } = this.props;
        return (
            <div className='pl-3'>
                <div className="row">
                    <div className=' col-xl-4 col-lg-4 font-customer-name-detail pl-3'>
                        <div>
                            <p className={'label-record-beta'} style={{margin: 0, fontSize: 20}}>
                                                <span className="label-name">
                                                    <Link to={ `/customers/customer-detail/${ record.customer.id }` }>
                                                    {record.customer.name } </Link>
                                                </span>
                                <span className="font-name-san">{t('RecordsBeta.noun')}</span>
                            </p>
                        </div>
                        <div>
                            {record.title}
                        </div>
                    </div>
                    <div className='col-xl-4 col-lg-4 '>
                        <div className="d-flex justify-content-start font-text-card-detail">
                            <div
                                style={ { textAlign: 'end', padding: 0, minWidth: 112 } }>{ t('RecordsBeta.servicedAt') } : &nbsp;</div>
                            <div style={ { padding: 0, minWidth: 167 } }>
                                <FormatTimestamp className={'font-text-card-detail'} timestampUnix={record.serviced_at} hasTime={record.has_time}/>

                            </div>
                        </div>
                        <div className="d-flex justify-content-start font-text-card-detail">
                            <div
                                style={ { textAlign: 'end', padding: 0, minWidth: 112 } }> { t('RecordsBeta.updatedDate') } : &nbsp;</div>
                            <div style={ { padding: 0, minWidth: 167 } }>
                                <FormatTimestamp className={'font-text-card-detail'} timestampUnix={ record.last_action_at } hasTime={true}/></div>
                        </div>
                    </div>
                    <div className='col-xl-4 col-lg-4'>
                        <div className="d-flex justify-content-start font-text-card-detail pl-3">
                            <div
                                style={ { textAlign: 'end', padding: 0, minWidth: 112 } }> { t('RecordsBeta.lastUpdatedBy') } : &nbsp;</div>
                            <div style={ { padding: 0 , minWidth: 'max-content'} }>
                                { record.lastUpdatedBy.delete_at ?
                                    <span>{record.lastUpdatedBy.name}</span> :
                                    <Link to={ `/staffs/staff/${ record.lastUpdatedBy.id }` }
                                          className={ 'cursor-pointer' }>
                                        { record.lastUpdatedBy.name } </Link>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-3 font-text-card-detail">
                    {record.survey_template.description}
                </div>
            </div>
        );
    }
}

export default CardRecordDetailsWeb;
