import React, {Component}        from 'react';
import {KirokuRadio}             from '../../../ui';
import {withNamespaces}          from 'react-i18next';
import {checkUseHeadingQuestion} from "../../Surveys/SurveyEditor/SurveyService";
import KirokuButtonLink          from '../../../ui/KirokuButtonLink';

@withNamespaces()
class YesNoQuestion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        }
    }
    componentDidMount() {
        this.props.onAnswer({
            answer: this.state.value,
            validated: this.state.value ? this.state.value : null
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.question !== nextProps.question) {
            this.setState({
                value: nextProps.value
            });
            nextProps.onAnswer({
                answer: nextProps.value,
                validated: nextProps.value === 'Yes' || nextProps.value === 'No' ? nextProps.value : null
            })
        }
    }


    handleOnChange(e) {
        if (!e) {
            e = { target: {value: ''}};
        }
        this.setState({
            value: e.target.value
        });
        this.props.onAnswer({
            answer: e.target.value,
            validated: e.target.value ? e.target.value : null
        });
    }


    render() {
        const {
            question, t,
            // index
        } = this.props;
        const contentQuestion = checkUseHeadingQuestion(question);
        return (
            <div id='component-question'>
                <div className='content-question'>
                    <div className='wraper-question'>
                        <div className="background-question">
                            <div className='number-question d-flex justify-content-between'>
                                <span className="question-number">
                                    {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                                </span>
                                <div/>
                                <div>
                                    <KirokuButtonLink
                                        className='padding-reset-button-link'
                                        onClick={() => {this.handleOnChange()}}>
                                        {t('createRecord.resetAnswer')}
                                    </KirokuButtonLink>
                                </div>
                            </div>

                        </div>
                        <div className='answer-question' style={{padding: 0, marginTop: '20px', marginBottom: '40px'}}>
                            <span className='content-name-question padding-question-content'>
                                {question.content}
                            </span>
                            <div className="yes-check">
                                <KirokuRadio onChange={this.handleOnChange.bind(this)}
                                             id={`${question.index}yes`} value={'Yes'} name={`questionYesNo${question.index}`}
                                             checked={this.props.value === 'Yes'}
                                             style={{width: '100%'}}
                                >
                                    {t('createRecord.Yes')}
                                </KirokuRadio>
                            </div>
                            <div className="no-check">
                                <KirokuRadio onChange={this.handleOnChange.bind(this)}
                                             id={`${question.index}no`} value={'No'} name={`questionYesNo${question.index}`}
                                             checked={this.props.value === 'No'}
                                             style={{width: '100%'}}
                                >
                                    {t('createRecord.No')}
                                </KirokuRadio>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default YesNoQuestion;
