import React, {Component}        from 'react';
import {withNamespaces}          from 'react-i18next';
import {checkUseHeadingQuestion} from "../../../Surveys/SurveyEditor/SurveyService";
import { KirokuButton }          from '../../../../ui';
import { withSmoothScrolling }           from '../../../../ui/Scrolling.js';

@withSmoothScrolling
@withNamespaces()
class Message extends Component {

    componentDidMount() {
        if (!this.props.disabled) {
            this.props.onAnswer({answer: 'message', validated: {}, questionIndex: this.props.question.index});
            this.props.isCurrentQuestion(this.props.question.index);
        }
    }

    moveToNextQuestion() {
        const {question} = this.props;
        let hasNextQuestion = question.nextQuestion ? question.nextQuestion - 1 : question.index;
        this.props.smoothScroll.scrollTo(`question-${hasNextQuestion }`);
        this.props.isCurrentQuestion(hasNextQuestion + 1);
    }

    render() {
        const {question, disabled,
                  t, isEndQuestion, currentQuestion} = this.props;
        const contentQuestion = checkUseHeadingQuestion(question);
        return (
            <div id='component-question'>
                <div className='content-question'>
                    <div className='wraper-question'>
                        <div className='wraper-question'>
                            <div className={ disabled ? 'number-question bg-disable-question' : 'number-question'}>
                                <div className="question-number text-overflow-elip">
                                    {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                                </div>
                            </div>
                            <span className='content-name-question' style={{opacity: disabled ? 0.3 : 1}}>
                                {question.content}
                            </span>
                            <div className='p-2' hidden={ isEndQuestion || disabled || question.index !== currentQuestion }>
                                <KirokuButton className='btn-kiroku-primary' onClick={this.moveToNextQuestion.bind(this)}>
                                    {this.props.t('common.nextToQuestion')}
                                </KirokuButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Message;
