import React, {Component}           from 'react'
import KirokuNotification           from "./ui/KirokuNotification";
import {filePathService}            from "./services";
import MainMenu                     from "./components/MainMenu";
import RouterProtected              from "./RouterProtect";
import {connect}                    from "react-redux";
import { Link, Router, withRouter } from "react-router-dom";
import { reloadAvatar }             from './actions/ActionLayout';
import config                       from './config';

@withRouter
@connect((state) => {
    return {
        profile     : state.KirokuAICustomer.profile,
        title       : state.KirokuAICurrentPage.title,
        actionPage  : state.KirokuAICurrentPage.actionPage,
        hiddenLayout: state.ReduceActionLayout.hiddenLayout,
        reloadAvatar :state.ReduceActionLayout.reloadAvatar,
    }
}, dispatch => {
    return {
        logout: () => dispatch({ type: 'LOGOUT' }),
        reloadAvatar : () => {
            dispatch(reloadAvatar())
        }
    }
})


class LayoutWeb extends Component {
    state = {
        open: true,
    };

    actionSlider() {
        this.setState({
            open: !this.state.open
        });
    }

    handleLogout(){
        this.props.logout();
    }

    controllerActive() {
    }

    render() {
        let profile = JSON.parse(localStorage.getItem('profile'));
        let newProfile = profile ? profile : {avatar: ''};
        const pathNamesDoNotUseLayout = config.routerNotUseLayout;
        const { history } = this.props;

        return (
            <Router history={history}>
                {pathNamesDoNotUseLayout.includes(history['location'].pathname) ?
                    (
                        <RouterProtected/>
                    ):
                    (
                        <div>
                            <div hidden={this.props.hiddenLayout} id='main' className={this.state.open ? "opacity-mobile" : ""}>
                                <div id='overlay-main' className="overlay" onClick={this.actionSlider.bind(this)}/>
                                <div className="slider-close"/>
                                <KirokuNotification/>
                            </div>
                            <div hidden={this.props.hiddenLayout}
                                 className={this.state.open ? "sidenav open-slide" : "sidenav close-slider"}>
                                <div className="header-slidenav d-flex justify-content-between pr-3">
                                    <div style={{
                                        height: 30,
                                        width: 113,
                                        background: 'no-repeat',
                                        backgroundImage: `url(${filePathService.resolveFilePath('logo-kiroku.svg')})`
                                    }}/>
                                    <div className='divclass'>
                                        <i className="fa fa-bars" onClick={this.actionSlider.bind(this)}/>
                                    </div>
                                </div>
                                <MainMenu shouldUseTooltip={this.state.open} onChangeNavLink={this.controllerActive.bind(this)}/>
                            </div>
                            <div>
                                <div hidden={this.props.hiddenLayout}
                                     className={this.state.open ? "header-mobile" : "header-mobile opacity-header"}>
                                    <div className='header-mobile-left'>
                                        <i className="fa fa-bars back-up-bars" onClick={this.actionSlider.bind(this)}/>
                                        {this.props.title}
                                    </div>
                                    <div className='header-mobile-right'>
                                        <div className="pt-1 pr-2 cursor-pointer">
                                            <i className="fa fa-power-off" onClick={this.handleLogout.bind(this)} style={{fontSize: "1.8em", color: '#FF5733'}}/>
                                        </div>
                                        <div style={{paddingLeft: '3rem'}}/>
                                        <Link to={`/edit-my-profile`}>
                                            <div className='pl-3 font-username hidden-username-on-header'>
                                                {profile.username}
                                            </div>
                                        </Link>

                                        <Link to={`/edit-my-profile`}>
                                            <div className='pl-2 header-user-avatar '>
                                                <div className="avatar"
                                                     style={{backgroundImage: `url(${newProfile.avatar ? newProfile.avatar : filePathService.resolveFilePath('profile-ic.svg')})`}}/>
                                            </div>
                                        </Link>
                                        <div className='header-notify'>
                                            <div>
                                                <span className='ant-badge'/>
                                            </div>
                                        </div>
                                        {this.props.actionPage}
                                    </div>
                                </div>
                                <div className={this.state.open ? "main-container" : "main-container main-collapse"}
                                     style={{marginLeft: `${this.props.hiddenLayout ? '0' : ''}`}}>
                                    <div
                                        className={`${this.props.hiddenLayout ? 'main-content-mobile pt-0' : 'main-content-mobile'}`}>
                                        <RouterProtected/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

            </Router>
        );
    }
}

export default LayoutWeb;
