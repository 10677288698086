import React, {Component}                               from 'react';
import {Link}                                           from 'react-router-dom';
import connect                                          from 'react-redux/es/connect/connect';
import {currentPage}                                    from '../../../actions/CurrentPage';
import {loadTeam, loadTeamsForPage, saveConditionsTeam} from '../../../actions/Team';
import KirokuCardList                                   from '../../../ui/KirokuCardList';
import KirokuCard                                       from '../../../ui/KirokuCard';
import KirokuPopoverItem                                from '../../../ui/KirokuPopoverItem';
import KirokuCardAction                                 from '../../../ui/KirokuCardAction';
import KirokuTitlepage                                  from '../../../ui/KirokuTitlepage';
import {withNamespaces}                                 from 'react-i18next';
import {KirokuInputSearcher, KirokuLoading}             from '../../../ui';
import {scrollService}                                  from "../../../services";
import KirokuPaginationTop                              from '../../../ui/KirokuPaginationTop';
import withPermissions                                  from "../../HOC/WithPermissions";
import ButtonComponent                                  from "../../HOC/ButtonComponent";
import SpanComponent                                    from "../../HOC/SpanComponent";


class Action extends Component {

    constructor(props) {
        super(props);

        this.handleClick        = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            popupVisible: false
        };
    }

    handleClick() {
        if (!this.state.popupVisible) {
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible
        }));
    }

    handleOutsideClick() {
        if (!this.node) {
            return;
        }
        this.handleClick()
    }

    render() {
        return (
            <div hidden={this.props.hidden} className="action-customer-surveys" id='edit-record' ref={node => {
                this.node = node
            }}>
                <KirokuCardAction>
                    <div className="action-icon" onClick={this.handleClick.bind(this)}>
                        <i style={{color: '#1991eb'}} className="fas fa-ellipsis-h "/>
                        {this.state.popupVisible && (
                            <div onClick={this.handleClick.bind(this)}>
                                {this.props.children}
                            </div>
                        )}
                    </div>
                </KirokuCardAction>
            </div>
        )
    }
}

const Button         = withPermissions(ButtonComponent);
const ActionPageTeam = withPermissions(Action);
const Span           = withPermissions(SpanComponent);

@withNamespaces()
@connect(state => {
    return {
        teams: state.KirokuAITeam.teams,
        currentPage: state.KirokuAITeam.currentPage,
        totalPage: state.KirokuAITeam.totalPage,
        profile: state.KirokuAITeam.profile,
        conditions: state.KirokuAIBehavior.conditionsTeam.conditions,
        params: state.KirokuAIBehavior.conditionsTeam.params,
        loading: state.Loading
    }
}, dispatch => {
    return {
        pageNow: (name) => {
            dispatch(currentPage(name))
        },

        loadTeam: () => {
            dispatch(loadTeam())
        },
        loadTeamForPage: (currentState) => {
            dispatch(loadTeamsForPage(currentState))
        },
        saveConditionsTeam: (conditions, params) => {
            dispatch(saveConditionsTeam(conditions, params))
        }


    }
})
class TeamListMobile extends Component {

    page = {
        title: <KirokuTitlepage title={this.props.t('Team.Team')}/>,
        actionPage: null
    };

    state = {
        namePage: this.page,
        team: [],
        pageMin: 1,
        page: 1,
        disabledPrev: true,
        disabledNext: false,
        selected: false
    };

    componentWillMount() {
        if (this.props.conditions.length) {
            this.setState({
                page: this.props.params.page,
                name: this.props.params.name
            });
            return this.props.loadTeamForPage(this.props.params);

        }
        this.props.loadTeamForPage({page: 1});
        this.props.pageNow(this.state.namePage);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.params !== this.props.params) {
            this.setState({
                page: nextProps.params.page,
                name: nextProps.params.name
            })
        }
    }

    onSearch(e) {
        this.setState({
            name: e.target.value,
            page: 1
        }, () => {

        })
    }

    makeConditions = (params) => {
        let conditions = Object.keys(params).map(key => {
            if (params[key]) {
                return {[key]: params[key], query: key, value: params[key]}
            }
            return '';
        });

        return conditions.filter(condition => condition !== '');
    };

    saveConditions() {
        let {namePage, team, disabledPrev, disabledNext, selected, pageMin, ...currentState} = this.state;
        this.props.saveConditionsTeam(this.makeConditions(currentState), currentState)
    }

    reloadListTeam() {
        let {namePage, team, disabledPrev, disabledNext, selected, pageMin, ...currentState} = this.state;
        this.props.loadTeamForPage(currentState);
        this.saveConditions();
    }

    increasePage() {
        let nextPage = this.props.currentPage === this.props.totalPage ? this.props.totalPage : this.props.currentPage + 1;
        if (nextPage === this.props.totalPage) {
            this.setState({
                disabledNext: !this.state.disabledNext
            })
        }
        scrollService.top();
        this.setState({
            page: nextPage,
            disabledPrev: false,
            selected: false
        }, () => this.reloadListTeam());


    }

    decreasePage() {
        let previousPage = this.props.currentPage === this.state.pageMin ? 1 : this.props.currentPage - 1;
        if (previousPage === this.state.pageMin) {
            this.setState({
                disabledPrev: true
            })
        }
        scrollService.top();
        this.setState({
            page: previousPage,
            disabledNext: false,
            selected: false
        }, () => this.reloadListTeam());
    }

    handlerSubmitSearch() {
        this.setState({
            page: 1
        }, () => {
            this.reloadListTeam();
            this.saveConditions();
        })

    }

    render() {
        let {t}     = this.props;
        const teams = this.props.teams;
        return (
            <div id='mobile'>
                <div>
                    <div className='d-flex justify-content-between '>
                        <h5 className='mt-1'>{t('Team.TeamsList')}</h5>
                        <Link to={'/teams/team-creator'}>
                            <Button color='primary'
                                    resource-permission={'|teamList|button-new|'}
                            >
                                {t('Team.AddTeam')}
                            </Button>
                        </Link>
                    </div>
                    <div className='mt-10'>
                        <KirokuInputSearcher
                            value={this.state.name}
                            onSubmit={this.handlerSubmitSearch.bind(this)}
                            onChange={this.onSearch.bind(this)}
                            id={'inputSearchMobileTeam'}
                        />
                        {/*<KirokuInputSearch placeholder={t('common.Search')} onBlur={this.onSearch.bind(this)}/>*/}
                    </div>
                    <div className='pt-2 d-flex justify-content-between'>
                        <div/>
                        <div hidden={!teams.length}>
                            <KirokuPaginationTop
                                className={'pb-2'}
                                disabledPrev={this.props.currentPage === 1}
                                disabledNext={this.props.currentPage === this.props.totalPage}
                                increasePage={this.increasePage.bind(this)}
                                decreasePage={this.decreasePage.bind(this)}
                                currentPage={this.props.currentPage}
                                totalPage={this.props.totalPage}
                            />
                        </div>
                    </div>
                    <div className='pt-10'>
                        <KirokuCardList
                            headerLeft={<span className='customer-name'>{t('Team.Team')}</span>}
                            headerRight={<Span resource-permission={'|teamList|table-td|'}
                                               className='customer-name'>{t('common.Action')}</Span>}
                        >
                            {!this.props.loading ?
                                <div>
                                    {teams.map(team =>
                                        <KirokuCard
                                            key={team.id} action={
                                            <ActionPageTeam teamId={team.id}
                                                            resource-permission={'|teamList|button-edit|'}>
                                                <KirokuPopoverItem>
                                                    <div className='d-flex justify-content-between p-1 fix-icon-align'>
                                                        <Link to={`/teams/edit/${team.id}`}>
                                                            <i className="fas fa-pencil-alt"/><span
                                                            className='ml-1'>{t('common.Edit')}</span>
                                                        </Link>
                                                    </div>
                                                </KirokuPopoverItem>
                                            </ActionPageTeam>}
                                        >
                                            <p>
                                                <Link to={`/teams/team-detail/${team.id}`}>{team.name}</Link>
                                            </p>
                                            <p className='customer-name'>{team.description}</p>
                                        </KirokuCard>
                                    )}
                                </div> : <KirokuLoading/>}
                        </KirokuCardList>
                    </div>
                    <div className='pt-10 d-flex justify-content-between'>
                        <div/>
                        <div>
                            <KirokuPaginationTop
                                disabledPrev={this.props.currentPage === 1}
                                disabledNext={this.props.currentPage === this.props.totalPage}
                                increasePage={this.increasePage.bind(this)}
                                decreasePage={this.decreasePage.bind(this)}
                                currentPage={this.props.currentPage}
                                totalPage={this.props.totalPage}
                            />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default TeamListMobile;
