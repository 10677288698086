import React, { Component } from 'react';
import _                    from 'lodash';
import { connect }          from 'react-redux';
import { withNamespaces }     from 'react-i18next';
import SelectTypeQuestion     from './SelectTypeQuestion';
import { handleDragQuestion } from '../../../../actions/Survey';
import arrayMove              from 'array-move';
import {
    InputText, SingleChoice, YesNo,
    MultiChoice, Message, DateTime,
    MediaFile, Time, OnlyDate, Number
}                           from './QuestionType';
import {
    KirokuButton, KirokuCardWeb,
    KirokuSortableContainer, KirokuSortableElement,
    KirokuModalSurvey,KirokuCardTitleWeb,
    KirokuCardBodyWeb, KirokuInput
}                           from '../../../../ui';

import {
    changeUnRequited,
    editQuestion, editTypeQuestion,
    showReview, turnModalEditQuestionSurveyTemplate,
    upQuestion,
} from '../../../../actions/SurveyTemplate';

@withNamespaces()
@connect(state => {
    return {
        surveyTemplate   : state.KirokuAISurveyTemplate,
        modalEditQuestion: state.KirokuAISurveyTemplate.modalEditQuestion,
        editedQuestion   : state.KirokuAISurveyTemplate.editedQuestion,
    };
}, dispatch => {
    return {

        showPreview                        : () => {
            dispatch(showReview());
        },
        upQuestion                         : (currentIndex, nextIndex) => {
            dispatch(upQuestion(currentIndex, nextIndex));
        },
        turnModalEditQuestionSurveyTemplate: () => {
            dispatch(turnModalEditQuestionSurveyTemplate());
        },
        onChangeTypeQuestion               : (question, index) => {
            dispatch(editTypeQuestion(question, index));
        },
        editQuestionSurvey                 : (newQuestion, index) => {
            dispatch(editQuestion(newQuestion, index));
        },
        handleDragQuestion                 : (questions, currentIndex, nextIndex) => handleDragQuestion(dispatch)(questions, currentIndex, nextIndex),
        changeUnrequitedQuestion           : (questionIndex) => changeUnRequited(dispatch)(questionIndex),
    }
})

class ReviewSurvey extends Component {

    state = {
        InputText        : InputText,
        Single           : SingleChoice,
        YesNo            : YesNo,
        Message          : Message,
        MultiChoice      : MultiChoice,
        Date             : DateTime,
        Media            : MediaFile,
        Time             : Time,
        OnlyDate         : OnlyDate,
        Number           : Number,
        questionToEdit   : {
            content: '',
            heading: '',
        },
        typeQuestion     : 'InputText',
        questionChoices  : [],
        error_content    : false,
        modalNotification: true,
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.editedQuestion !== this.props.editedQuestion) {
            this.setState({
                questionToEdit : {
                    ...nextProps.editedQuestion,
                },
                typeQuestion   : nextProps.editedQuestion.type,
                questionChoices: nextProps.editedQuestion.choices,
            });
        }
        // let isLoopAnswerQuestion = [...nextProps.surveyTemplate.questions].map(question => {
        //     return {isLoopAnswerQuestion: this.checkLoopAnswerQuestion(question)}
        // });
        // if(_.findIndex(isLoopAnswerQuestion, 'isLoopAnswerQuestion') !== -1) {
        //     this.setState({
        //         modalNotification: true
        //     })
        // }
    }

    handleSubmit = () => {
        this.props.handleSubmit();
    };

    goBack = () => {
        this.props.history.push('/surveys');
    };

    handleCancel = () => {
        this.props.showPreview();
    };

    disabledSubmit = () => {
        return !this.props.surveyTemplate.questions.length;
    };

    upQuestions = (minList, index) => {
        if (minList === index) {
            return;
        }
        this.props.upQuestion(index, index - 1);
    };

    downQuestion = (maxList, index) => {
        if (maxList === index) {
            return;
        }
        this.props.upQuestion(index, index + 1);
    };

    disableArrange = (limit, index) => {
        return limit === index;
    };

    /*
     return Boolean
     */
    validateEditQuestion = () => {
        const { questionToEdit, typeQuestion, questionChoices } = this.state;
        let newQuestion                                         = {
            ...questionToEdit,
            type   : typeQuestion,
            choices: questionChoices,
        };

        if (newQuestion.nextQuestion) {
            delete newQuestion[ 'nextQuestion' ];
        }

        if (typeQuestion !== 'MultiChoice' && typeQuestion !== 'Single') {
            delete newQuestion[ 'choices' ];
            return !newQuestion.content;
        } else {
            return _.findIndex(newQuestion.choices, { 'answer': '' }) !== -1 || !newQuestion.content;
        }
    };

    applyQuestions() {
        this.props.turnModalEditQuestionSurveyTemplate();
        const { questionToEdit, typeQuestion, questionChoices } = this.state;
        let newQuestion                                         = {
            ...questionToEdit,
            type   : typeQuestion,
            choices: questionChoices,
        };

        if (typeQuestion !== 'MultiChoice' && typeQuestion !== 'Single') {
            delete newQuestion[ 'choices' ];
        }

        if (typeQuestion !== 'YesNo') {
            delete newQuestion[ 'nextQuestionYes' ];
            delete newQuestion[ 'nextQuestionNo' ];
        }

        if (newQuestion.type === 'Single' || newQuestion.type === 'MultiChoice') {
            newQuestion[ 'choices' ] = newQuestion[ 'choices' ].map(choice => {
                delete choice[ 'defaultAnswer' ];
                return choice;
            });
        }
        delete newQuestion[ 'defaultAnswer' ];

        this.props.editQuestionSurvey(newQuestion, newQuestion.index);
    }

    cancelEditQuestions() {
        this.props.turnModalEditQuestionSurveyTemplate();

    }

    selectTypeQuestionEdit(type, choices) {
        this.setState({
            typeQuestion   : type,
            questionChoices: choices,
        });
    }

    onChangeHeading(e) {
        this.setState({
            questionToEdit: {
                ...this.state.questionToEdit,
                heading: e.target.value,
            },
        });
    }


    onChangeContent(e) {
        this.setState({
            questionToEdit: {
                ...this.state.questionToEdit,
                content: e.target.value,
            },
            error_content : !e.target.value,
        });
    }

    checkLoopAnswerQuestion(question) {
        if (question.type === 'Single') {
            let checkQuestionChoice = [...question.choices].map(answer => {
                return { isCheckQuestionChoice: answer[ 'nextQuestion' ] < question.index };
            });
            return _.findIndex(checkQuestionChoice, 'isCheckQuestionChoice') !== -1;
        }

        if (question.type === 'YesNo') {
            if (question.nextQuestion) {
                return question.nextQuestion < question.index;
            } else {
                return question.nextQuestionYes < question.index || question.nextQuestionNo < question.index;
            }
        }
        return question.nextQuestion < question.index;
    }

    closeModalNotification() {
        this.setState({
            modalNotification: false,
        });
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        const { surveyTemplate }    = this.props;
        const indexQuestionBeDrag   = oldIndex;
        const indexQuestionWillDrag = newIndex;
        let newItems                = [...surveyTemplate.questions];
        this.props.handleDragQuestion(arrayMove(newItems, oldIndex, newIndex), indexQuestionBeDrag, indexQuestionWillDrag);
    };

    render() {
        const { surveyTemplate, editedQuestion, t, modalEditQuestion }         = this.props;
        const minList                                                          = 0;
        const maxList                                                          = surveyTemplate.questions.length - 1;
        const { questionToEdit, typeQuestion, error_content, questionChoices } = this.state;
        return (
            <div>
                <div className='padding-responsive default-background-color'>
                    <div className="keeping-about-survey" style={{ top : 70, backgroundColor: '#eef3f6'}}>
                        <div className='pb-3 d-flex justify-content-between' style={{ paddingTop : 12}}>
                            <KirokuButton color={'light'} onClick={this.handleCancel.bind(this)}>
                                <span className="text-tranf">{t('createSurvey.Back')}</span>
                            </KirokuButton>
                            <KirokuButton color={'primary'} disabled={this.disabledSubmit()}
                                          onClick={this.handleSubmit.bind(this)}>
                                <span className="text-tranf">{t('createSurvey.Submit')}</span>
                            </KirokuButton>
                        </div>

                        <div className='fix-center bg-white pt-3'>
                            <div className='col-10'>
                                <div className='content-question row'>
                                    <div className='wraper-question col-11'>
                                        <div className='wraper-question'>
                                            <div className="background-question-review pr-0 pl-0 pb-3">
                                                <div className='info-question' id='create-record-fix'>
                                                    <div className='number-question'>
                                                        <span
                                                            className="question-number">{t('createSurvey.Title')}</span>
                                                    </div>
                                                    <div className='content-heading-question'>
                                                        <div className='title-record-info'/>
                                                        {surveyTemplate.title}
                                                    </div>
                                                    {/*<div className='content-heading-question'>*/}
                                                    {/*    <div className='title-record-info'/>*/}
                                                    {/*    {t('createSurvey.FormNumber')}*/}
                                                    {/*</div>*/}
                                                    {/*<div className='content-name-question'>*/}
                                                    {/*    <div className='title-record-info'/>*/}
                                                    {/*    {surveyTemplate.form_number}*/}
                                                    {/*</div>*/}
                                                    <div className='content-heading-question'>
                                                        <div className='title-record-info'/>
                                                        {t('createSurvey.Description')}
                                                    </div>
                                                    <div className='content-name-question'>
                                                        <div className='title-record-info'/>
                                                        {surveyTemplate.description}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        height: '90vh',
                        overflow: 'scroll'
                    }}>
                    <div className='fix-center bg-white pt-3 pb-3'>
                        <div className='col-10'>
                            <KirokuSortableContainer
                                onSortEnd={this.onSortEnd}
                                useDragHandle
                            >
                            {surveyTemplate.questions.map((question, index) => {
                                let QuestionType = this.state[ question.type ];
                                return (
                                    <KirokuSortableElement key={index} index={index}>
                                        <div className='row'>
                                            <div className={'col-11'}>
                                                <QuestionType
                                                    ReviewCreate
                                                    edit={this.handleCancel.bind(this)} question={question}
                                                    changeUnrequitedQuestion={(questionIndex) => this.props.changeUnrequitedQuestion(questionIndex)}
                                                    key={index}/>
                                            </div>
                                            <div className='col-1 as'>
                                                <div className={'fix-center'}>
                                                    <button className='position-survey'
                                                            hidden={this.disableArrange(minList, index)}
                                                            disabled={this.disableArrange(minList, index)}
                                                            onClick={() => this.upQuestions(minList, index)}
                                                            color={'light'}
                                                    >
                                                        <i className="fas fa-arrow-up text-secondary"
                                                           style={{ fontSize: '1.5rem' }}/>
                                                    </button>
                                                    <button className='position-survey'
                                                            disabled={this.disableArrange(maxList, index)}
                                                            hidden={this.disableArrange(maxList, index)}
                                                            onClick={() => this.downQuestion(maxList, index)}
                                                            color={'light'}
                                                    >
                                                        <i className="fas fa-arrow-down text-secondary"
                                                           style={{ fontSize: '1.5rem' }}/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </KirokuSortableElement>);})
                                }
                            </KirokuSortableContainer>
                        </div>
                    </div>
                    </div>

                    <div className='fix-center pt-5'>
                        <div className='d-flex justify-content-between'>
                            <KirokuButton color='white' onClick={this.handleCancel.bind(this)}>
                                {t('createSurvey.Back')}
                            </KirokuButton>
                            <div style={{ width: '15px' }}/>
                            <KirokuButton disabled={this.disabledSubmit()} color='primary'
                                          onClick={this.handleSubmit.bind(this)}>
                                {t('createSurvey.Submit')}
                            </KirokuButton>
                        </div>
                    </div>
                </div>

                <KirokuModalSurvey
                    show={modalEditQuestion}
                >
                    <KirokuCardWeb>
                        <KirokuCardTitleWeb color='success'>
                            <h4 className='text-dark'>{t('createSurvey.QuestionOrder', { index: editedQuestion.index + 1 })}</h4>
                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb style={{ height: '70vh', overflowY: 'auto' }}>
                            <div className='sumary-content2'>
                                <p>{t('createSurvey.Heading')}</p>
                                <KirokuInput value={questionToEdit.heading} name={'heading'}
                                             onChange={this.onChangeHeading.bind(this)}
                                />
                                <p className='pt-3'>{t('createSurvey.YourQuestion')}</p>
                                <KirokuInput value={questionToEdit.content} name={'content'}
                                             onChange={this.onChangeContent.bind(this)}
                                             error={error_content ? 'form-error' : ''}
                                />
                                <p hidden={!error_content} style={{
                                    color   : 'red',
                                    fontSize: 12,
                                }}>{t('createSurvey.QuestionMustNotNull')}</p>
                            </div>

                            <SelectTypeQuestion
                                number={editedQuestion.index} choices={questionChoices}
                                type={typeQuestion}
                                isCreateSurvey={editedQuestion.type !== 'YesNo'}
                                handleSelectType={this.selectTypeQuestionEdit.bind(this)}
                            />

                        </KirokuCardBodyWeb>
                        <hr/>
                        <div className='fix-center pb-3'>
                            <div className='d-flex justify-content-between'>
                                <KirokuButton color={'primary'}
                                              onClick={this.applyQuestions.bind(this)}
                                              disabled={this.validateEditQuestion()}
                                >
                                    {t('common.Apply')}
                                </KirokuButton>
                                <div className='pr-1 pl-1'/>
                                <KirokuButton color={'white'} onClick={this.cancelEditQuestions.bind(this)}>
                                    {t('common.Cancel')}
                                </KirokuButton>
                            </div>
                        </div>
                    </KirokuCardWeb>
                </KirokuModalSurvey>
            </div>
        );
    }
}

export default ReviewSurvey;



