export const ANALYSIS_QUERY = 'ANALYSIS_QUERY';
export const RESET_VALUE_ANALYSIS = 'RESET_VALUE_ANALYSIS';

export const queryForAnalysis = (customerId, surveyId, startDate, endDate) => {
    return {
        type: ANALYSIS_QUERY,
        query: {
            customerId,
            surveyId,
            startDate,
            endDate
        }
    }
};

export const resetValueAnalysis = () => {
    return {
        type: RESET_VALUE_ANALYSIS
    }
};
