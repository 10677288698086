import React, { Component }  from 'react';
import ConditionSearchExport from './ConditionSearchExport';
import { connect }           from 'react-redux';
import { loadCustomerAll }   from '../../actions/Customer';
import ResultsSearchExport   from './ResultsSearchExport';
import { withNamespaces }    from 'react-i18next';
import { KirokuPageLoader }  from '../../ui';
import { resetStateExport }  from '../../actions/AdminExport';
import { currentPage }       from '../../actions/CurrentPage';
import KirokuTitlepage       from '../../ui/KirokuTitlepage';
import {scrollService}       from "../../services";

@withNamespaces()
@connect(state => {
    return {
        beginPageExport: state.ReducerAdminExport.beginPageExport,
        loadingSearch: state.ReducerAdminExport.loadingSearch,
    }
}, dispatch => {
    return {
        loadCustomerAll: () => {
            dispatch(loadCustomerAll())
        },
        resetStateExport: () => {
            dispatch(resetStateExport())
        },
        pageNow: (name) => {
            dispatch(currentPage(name))
        }
    }
})
class AdminExport extends Component {

    page = {
        title: <KirokuTitlepage title={this.props.t('Export.Export')}/>
    };
    componentWillMount() {
        scrollService.top();
        this.props.loadCustomerAll();
        this.props.pageNow(this.page);
    }

    componentWillUnmount() {
        this.props.resetStateExport();
    }

    render() {
        const {beginPageExport, loadingSearch} = this.props;
        return (
            <div className='default-background-color'>
                <div className='padding-responsive'>
                    <ConditionSearchExport/>
                    {beginPageExport ? <div/> :

                     loadingSearch ? <KirokuPageLoader/> :
                        <ResultsSearchExport/>
                     }
                </div>
            </div>
        )
    }
}

export default AdminExport;
