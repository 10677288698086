export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDDEN_LOADING = 'HIDDEN_LOADING';
export const TYPING = 'TYPING';
export const TYPED = 'TYPED';

export function show() {
    return {
        type : SHOW_LOADING
    }
}

export function hidden() {
    return {
        type: HIDDEN_LOADING
    }
}

export function typingText() {
    return {
        type: TYPING
    }
}

export function typedText() {
    return {
        type: TYPED
    }
}