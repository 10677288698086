import {Component} from "react";
import React       from "react";

export default class KirokuButton extends Component {
    render() {
        return (
            <button {...this.props} className={`${this.props.className} btn btn-kiroku-` + this.props.color}>{this.props.children}</button>
        )
    }
}
