import React, { Component }  from 'react';
import { withNamespaces }    from 'react-i18next';
import KirokuLogicApplicated from '../../../../../../ui/KirokuLogicApplicated';
import KirokuRadio           from '../../../../../../ui/KirokuRadio';

@withNamespaces()
class YesNo extends Component {

    render() {
        const {question, value, t, surveyDetail, headerNextQuestion} = this.props;

        return (
            <div className='content-question-type'>
                <div className='wraper-question none-border-question pt-2 '>
                    <div className="background-question">
                        <div className=' d-flex justify-content-between'>
                            <div
                                className="text-overflow-elip font-heading-questions">{question.heading ? question.heading : ""}</div>
                            <div hidden={!surveyDetail} className={'float-right'}>
                                <KirokuLogicApplicated padding={'5px 5px 5px 7px'} question={question}/>
                            </div>
                        </div>
                        <div className='padding-content-question'>
                            <span className='font-content-question'>
                                {question.unrequited ? t('createRecord.option'):''}{question.content}</span>
                        </div>
                        <div
                            hidden={typeof question.nextQuestion !== 'number' || !surveyDetail}
                            className='text-info text-overflow-elip pl-10'>
                            <i className='fas fa-arrow-right' style={{paddingTop: 4}}/>
                            <span> {headerNextQuestion.general}</span>
                        </div>
                    </div>
                    <div className='answer-question size-select-question padding-16-answer-question'>
                        <KirokuRadio onChange={() => {}}
                                     checked={value === 'Yes'}
                                     readOnly
                        >
                            <span className='text-form-area'>{t('createRecord.Yes')}</span>
                        </KirokuRadio>
                        <div
                            hidden={ typeof question.nextQuestionYes !== 'number' || !surveyDetail}
                            className={'text-info text-overflow-elip pl-10'}>
                            <i className='fas fa-arrow-right' style={{paddingTop: 4}}/>
                            <span> {headerNextQuestion.yes}</span>
                        </div>
                        <KirokuRadio onChange={() => {}}
                                     checked={value === 'No'}
                                     readOnly
                        >
                            <span className='text-form-area'>{t('createRecord.No')}</span>
                        </KirokuRadio>
                        <div
                            hidden={ typeof question.nextQuestionNo !== 'number' || !surveyDetail}
                            className={'text-info text-overflow-elip pl-10'}>
                            <i className='fas fa-arrow-right' style={{paddingTop: 4}}/>
                            <span> {headerNextQuestion.no}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default YesNo;
