import lodash                           from "lodash";
import moment                           from  'moment';
import i18n                             from './../../i18n/i18n';
const t = i18n.getFixedT();

export function buildDataTimeQuestion(records, question) {
    let questions = records.map(record => {
        let answeredQuestion = getAnsweredQuestions(record);
        return answeredQuestion.filter(q => q.content === question.content && q.type === question.type)
    });
    questions = questions.filter(question => {return !! question[0] && !! question[0].answers});
    questions = questions.map(question => {
        return {
            ...question[0],
            momentAnswers:moment.unix(question[0].answers).format('HH')
        }
    });
    const questionRaw = questions.filter( question => isValid(question));
    const questionsGroupByHour = lodash.groupBy(questionRaw, (question) => question.momentAnswers);
    const valueAnswers = Object.keys(questionsGroupByHour).sort().map(key =>{
        return [ parseInt(key) + t('common.hour'), questionsGroupByHour[key].length ];
    });
    return [ ['TitleHour', 'H'], ...valueAnswers];
}


export function buildDataYesNo(records, question) {
    let questions = records.map(record => {
        let answeredQuestion = getAnsweredQuestions(record);
        return answeredQuestion.filter(q => q.content === question.content && q.type === question.type)
    });
    questions = questions.filter(question => !! question[0]);
    questions = questions.map(question => question[0]);
    const dataWithCount = lodash.countBy(questions, function (question) {
        return question.answers
    });

    return [
        ['Task', 'Yes No chart.'],
        [
            t('createRecord.Yes'),
             dataWithCount.Yes || 0
        ],
        [
            t('createRecord.No'),
            dataWithCount.No || 0
        ]
    ]
}

export function buildDataDate(records, index) {

    return {
        values: [{x: 0, y: 2}, {x: 1.3, y: 5}, {x: 3, y: 6}, {x: 3.5, y: 6.5}, {x: 4, y: 6}, {x: 4.5, y: 6}, {x: 5, y: 7}, {x: 5.5, y: 8}]
    };
}

export function buildInputText(records, index) {
    return records.map(record => {
        let answeredQuestion = getAnsweredQuestions(record);
        return {
            name : answeredQuestion[index].answers,
            data : [[moment.unix(record.serviced_at), parseInt(moment.unix(record.serviced_at).format('HH'))]]
        }
    });
}

export function buildDataNumberQuestion(records, question) {
    let questionsFlowRecords = records.map( record => {
        let answeredQuestion = getAnsweredQuestions(record);
        return {
            ...answeredQuestion.filter(item => item.content === question.content && item.type ===  question.type).map((itm, index) => {
                return {
                    ...itm,
                    servicedDate: buildDateToAnalysis(moment.unix(record.serviced_at).startOf('day')),
                }
            })
        }
    }).map(itm => itm['0']);

    let questionsGroupByDay = lodash.groupBy(questionsFlowRecords, 'servicedDate');
    let listKeyObject = Object.keys(questionsGroupByDay);
    listKeyObject.forEach((key, index) => {
        questionsGroupByDay[key] = questionsGroupByDay[key].map(item => {
            return {
                ...item,
                keyQuestion: index + 1
            }
        })
    });

    let data = [];

    listKeyObject.forEach(key => {
        data.push(...questionsGroupByDay[key])
    });


    let ticks = listKeyObject.map((key, index) => {
        return {
            v: index + 1,
            f: key
        }
    });
    return {
        data: data.map(question => {
            return [
                {
                    v: question.keyQuestion,
                    f: question.servicedDate
                },
                parseFloat(question.answers)
            ]
        }),
        ticks,
        maxColumn: listKeyObject.length
    };
}


export function buildDateToAnalysis(momentDate) {
    if (moment.locale() === 'en') {
        return momentDate.format('YYYY/D/M(ddd)')
    }
    return momentDate.format('YYYY年M月D日(ddd)')
}

export function buildDataMedia(records, index) {
    return [
        //TODO
    ];
}

export function buildDataMessage(records, index) {
    //todo
    return [];
}

export function buildDataMultiChoice(records, question) {
    let questions = records.map(record => {
        let answeredQuestion = getAnsweredQuestions(record);
        return answeredQuestion.filter(q => q.content === question.content && q.type === question.type)
    });
    questions = questions.filter(question => !! question[0] && question[0].type === 'MultiChoice');
    questions = questions.map(question => question[0]);
    const choices = getChoices(questions);
    let data = choices.map((choice, index) => {
        return [
            choice,
            lodash.countBy(questions, function (question) {
                if (question.answers[index]){
                    return question.answers[index].is_choice;
                }
            })[true] || 0
        ]
    });
    data.unshift([t('createRecord.Choices'), t('createRecord.Selected')]);
    return data;
}

export function buildDataSingle(records, question) {
    let questions = records.map(record => {
        let answeredQuestion = getAnsweredQuestions(record);
        return answeredQuestion.filter(q => q.content === question.content && q.type === question.type)
    });
    questions = questions.filter(question => !! question[0] && question[0].type === 'Single');
    questions = questions.map(question => question[0]);
    const choices = getChoices(questions);
    let data = choices.map((choice, index) => {
        return [
            choice,
            lodash.countBy(questions, function (question) {
                return question.answers.is_choice
            })[index] || 0
        ]
    });
    data.unshift(['Task', 'Single chart.']);
    return data;
}

function getChoices(questions){
    let choices = [];
    questions.forEach( item => {
        item.choices.forEach( choice => {
            if (choices.indexOf(choice.answer) < 0){
                choices.push(choice.answer);
            }
        })
    });
    return choices;
}

function getAnsweredQuestions (record){
    return ! record.survey_template.flowedQuestions ? record.survey_template.questions : record.survey_template.flowedQuestions;
}


function shiftDate(date, numDays) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + numDays);
    return moment(newDate).format('YYYY/M/D');
}

function getRange(count) {
    return Array.from({ length: count }, (_, i) => i);
}

export function buildDataOnlyDate(question, records)  {
    let flowedQuestions = records.map(record => {
        let answeredQuestion = getAnsweredQuestions(record);
        return {
           ...answeredQuestion.filter(item => item.content === question.content && item.type ===  question.type).map(item => {
               return {
                   ...item,
                   answerQuestions : moment(item.answers*1000).unix()
               }
           })
       };
    }).map(itm => itm['0']).filter(itm => itm);

    let recordGroupDays = lodash.groupBy(flowedQuestions, 'answerQuestions');
    const arraySearchRangeAnswerQuestion = flowedQuestions.map(item => item.answerQuestions).filter(question => !isNaN(question));
    const startDate = moment.unix(lodash.min(arraySearchRangeAnswerQuestion)).subtract(2,'days');
    const endDate = moment.unix(lodash.max(arraySearchRangeAnswerQuestion)).add(1,'days');
    const numberDay = endDate.diff(startDate, 'day');
    let resultRangeSearch = getRange(numberDay).map(index => {
        return {
            date: shiftDate(endDate, -index),
            count: 0
        }
    });

     Object.keys(recordGroupDays).map(key => {
        const indexResultRecordsAnswersOfResultRangeSearch =  lodash.findIndex(resultRangeSearch, (item) => item.date === moment.unix(key).format('YYYY/M/D'));
        if(indexResultRecordsAnswersOfResultRangeSearch !== -1) {
            resultRangeSearch[indexResultRecordsAnswersOfResultRangeSearch] = {
                ...resultRangeSearch[indexResultRecordsAnswersOfResultRangeSearch],
                count: recordGroupDays[key].length,
            }
        }
        return {
            date  : moment.unix(key).format('YYYY/M/D'),
            count: recordGroupDays[key].length,
        };
    });
    const minimumDate = lodash.min(flowedQuestions.map(item => item.answers));
    const maxDate = lodash.max(flowedQuestions.map(item => item.answers));
    return {
        data : resultRangeSearch,
        startDate: minimumDate,
        endDate: maxDate,

    };

}

function isValid(question){
    return question.momentAnswers !== 'Invalid date' && !!question.answers
        && typeof question.answers === 'number' && question.answers !== 0 && question.answers !== ''
}