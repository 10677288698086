import React, { Component }                              from 'react';
import { connect }                                       from 'react-redux';
import { KirokuButton, KirokuCheckBox, KirokuTitlepage } from '../../../../ui';
import { withNamespaces }                                from 'react-i18next';
import _                                                 from 'lodash';
import { currentPage }                                   from '../../../../actions/CurrentPage';

@connect(state => {
    return {
        customers           : state.KirokuModal.ModalSelectCustomers.customers,
        libCustomers        : state.KirokuModal.ModalSelectCustomers.libCustomers,
        isOpenSelected      : state.KirokuModal.ModalSelectCustomers.isOpenSelected,
        customersSelected   : state.KirokuModal.ModalSelectCustomers.customersSelected,
        libCustomersSelected: state.KirokuModal.ModalSelectCustomers.libCustomersSelected,
    };
}, dispatch => {
    return {
        handleChangeCheckboxCustomers        : (index) => {
            dispatch({
                type: 'HANDLE_CHANGE_CHECKBOX_CUSTOMERS',
                index,
            });
        },
        handleSelectAllCustomersSelected     : () => {
            dispatch({
                type: 'HANDLE_SELECT_ALL_CUSTOMERS_SELECTED',
            });
        },
        handleChangeCheckboxCustomersSelected: (index) => {
            dispatch({
                type: 'HANDLE_CHANGE_CHECKBOX_CUSTOMERS_SELECTED',
                index,
            });
        },
        handleSelectAllCustomers             : () => {
            dispatch({
                type: 'HANDLE_SELECT_ALL_CUSTOMERS',
            });
        },
        handleResetUserChanged               : () => {
            dispatch({
                type: 'HANDLE_RESET_USER_CHANGED',
            });
        },
        confirmCustomersCustom               : () => {
            dispatch({
                type: 'CONFIRM_MODAL_CUSTOMERS',
            });
        },
        changeTypeSearch                     : (typeSearch) => {
            dispatch({
                type: 'CHANGE_TYPE_SEARCH',
                typeSearch,
            });
        },
        setStatusOldModalSelect              : () => {
            dispatch({
                type: 'CANCEL_SELECT_CUSTOMER',
            });
        },
        changeIsOpenModalSelectCustomers     : () => {
            dispatch({
                type: 'CHANGE_IS_OPEN_MODAL_SELECT_CUSTOMERS',
            });
        },
        namePage        : (name) => {
            dispatch(currentPage(name));
        }
    };
})

@withNamespaces()

class TableCustomerSelected extends Component {

    handleConfirmListCustomCustomer() {
        const {t} = this.props;
        this.props.namePage({
            title     : <KirokuTitlepage title={ t('Layout.RecordSimpleDetail') }/>,
            actionPage: null,
        });
        this.props.changeIsOpenModalSelectCustomers();
        this.props.confirmCustomersCustom();
        this.props.changeTypeSearch('SEARCH_CUSTOM_CUSTOMER');
    }

    render() {
        const { customers, customersSelected, t, isOpenSelected,
                  // libCustomersSelected, libCustomers
              } = this.props;

        // const countCustomerSelect = isOpenSelected ? libCustomersSelected.filter(ctm => ctm.selected).length : libCustomers.filter(ctm => !!ctm.selected).length;

        return (

            <div>
                <div className='card'>
                    {
                        isOpenSelected ?
                            <div>
                                { customersSelected.length ?
                                    <div>
                                        <div className={ 'd-flex p-3 border-bottom justify-content-between' }>
                                            <div className='checkbox-bigger pb-3'>
                                                <KirokuCheckBox
                                                    checked={ _.findIndex(customersSelected, (ctm) => (!ctm.selected)) === -1 }
                                                    onChange={ () => this.props.handleSelectAllCustomersSelected() }
                                                />
                                            </div>
                                            <div/>
                                            <div className={ 'font-text-reset' } style={ { color: '#007BFF' } }
                                                 onClick={ () => this.props.handleResetUserChanged() }>
                                                { t('RecordsBeta.resetSelection') }
                                            </div>
                                        </div>
                                        { customersSelected.map((customer, index) => (
                                            <div key={ index } className={ ' border-bottom p-3' }>
                                                <div className='checkbox-bigger d-flex justify-content-start'>
                                                    <KirokuCheckBox checked={ !!customer.selected }
                                                                    id={ `${ customer.id }-${ index }` }
                                                                    onChange={ () => this.props.handleChangeCheckboxCustomersSelected(index) }
                                                                    content={ <span
                                                                        className={ 'font-text-in-checkbox' }> { customer.name }</span> }
                                                    />
                                                </div>
                                            </div>
                                        )) }
                                    </div> : <div className='d-flex' style={ { height: '50vh' } }>
                                        <div className={ 'font-text-not-found pt-3' }
                                             style={ { margin: 'auto' } }>
                                            { t('RecordsBeta.noCustomersSelected') }
                                        </div>
                                    </div> }
                            </div> :
                            <div>
                                { customers.length ?
                                    <div>
                                        <div className={ 'd-flex p-3 border-bottom justify-content-between' }>
                                            <div className='checkbox-bigger pb-3'>
                                                <KirokuCheckBox
                                                    checked={ _.findIndex(customers, (ctm) => (!ctm.selected)) === -1 }
                                                    onChange={ () => this.props.handleSelectAllCustomers() }
                                                />
                                            </div>
                                            <div/>
                                            <div className={ 'font-text-reset' } style={ { color: '#007BFF' } }
                                                 onClick={ () => this.props.handleResetUserChanged() }
                                            >
                                                { t('RecordsBeta.resetSelection') }
                                            </div>
                                        </div>
                                        { customers.map((customer, index) => (
                                            <div key={ index } className={ ' border-bottom p-3' }>
                                                <div className='checkbox-bigger d-flex justify-content-start'>
                                                    <KirokuCheckBox checked={ !!customer.selected }
                                                                    id={ `${ customer.id }-${ index }` }
                                                                    onChange={ () => this.props.handleChangeCheckboxCustomers(index) }
                                                                    content={ <span
                                                                        className={ 'font-text-in-checkbox' }> { customer.name }</span> }
                                                    />
                                                </div>
                                            </div>
                                        ))
                                        }
                                    </div> : <div className='d-flex' style={ { height: '50vh' } }>
                                        <div className={ 'font-text-not-found pt-3' }
                                             style={ { margin: 'auto' } }>
                                            { t('RecordsBeta.noCustomers') }
                                        </div>
                                    </div> }
                            </div>
                    }
                </div>
                <div className="fixed-footer">
                    <div style={ { backgroundColor: '#101F41', padding: '2em', height: 103 } }
                         className="d-flex justify-content-around">
                        <KirokuButton onClick={ () => {
                            this.props.setStatusOldModalSelect();
                            this.props.changeIsOpenModalSelectCustomers();
                            this.props.namePage({
                                title     : <KirokuTitlepage title={ t('Layout.RecordSimpleDetail') }/>,
                                actionPage: null,
                            })
                        } } style={ { width: 100 } }
                                      className={ 'btn-cancel-on-modal' }
                        >
                            { t('RecordsBeta.cancel') }
                        </KirokuButton>
                        <div className="align-self-center">
                            {/*{countCustomerSelect ? t(`RecordsBeta.isSelected`, { count: countCustomerSelect }) : t('RecordsBeta.notSelected')}*/}
                        </div>
                        <KirokuButton className={'btn-confirm-on-modal'}
                                      style={ { width: 100 } }
                                      onClick={ this.handleConfirmListCustomCustomer.bind(this) }
                        >
                            { t('RecordsBeta.confirm') }
                        </KirokuButton>
                    </div>
                </div>
            </div>
        );
    }
}

export default TableCustomerSelected;
