import React, { Component }             from 'react';
import KirokuButton                     from '../../ui/KirokuButton';
import { withNamespaces }               from 'react-i18next';
import connect                          from 'react-redux/es/connect/connect';
import { checkRecord, handlerCheckAll } from '../../actions/AdminExport';
import momentTZ                         from 'moment-timezone';
import { recordService }                from '../../services';
import { KirokuButtonSpinner }          from '../../ui';

@withNamespaces()
@connect(state => {
    return {
        arrayRecord         : state.ReducerAdminExport.arrayRecord,
        statusCheckAllRecord: state.ReducerAdminExport.statusCheckAllRecord,
        statusBtnExport     : state.ReducerAdminExport.statusBtnExport,
        condition           : state.ReducerAdminExport.condition,
        surveyChecked       : state.ReducerAdminExport.surveyChecked
    }
}, dispatch => {
    return {
        checkRecord    : (id) => {
            dispatch(checkRecord(id))
        },
        handlerCheckAll: () => {
            dispatch(handlerCheckAll())
        }
    }
})
class BtnExport extends Component {

    state = {
        loadingBtnExcel: true,
        loadingBtnCSV  : true
    };


    filterQuestion() {
        const {surveyChecked} = this.props;
        return surveyChecked.map(surveyRecord => {
            const flowedQuestions = surveyRecord['survey_template'].flowedQuestions ? surveyRecord['survey_template'].flowedQuestions : surveyRecord['survey_template'].questions;
            const questions       = flowedQuestions.map(question => {
                if (question.type === 'Single' && (typeof question.answers !== 'string')) {
                    return {
                        ...question,
                        answers: question.answers.answer
                    }
                }
                if (question.type === 'MultiChoice' && typeof question.answers[0] === 'object') {
                    return {
                        ...question,
                        answers: question.answers.filter(item => item.is_choice).map(itemAnswer => {
                            return itemAnswer.answer
                        })
                    }
                }
                return question;
            });

            const newQuestions = questions.filter(question => question.type !== 'Media');

            surveyRecord['survey_template'].questions = [...newQuestions];
            return {
                ...surveyRecord
            }
        });

    }

    handlerExportToExcel() {
        const {condition} = this.props;
        this.setState({
            loadingBtnExcel: false
        });
        let dataReq = {
            customer          : condition.customer,
            startDate_created : condition.startDate_created,
            endDate_created   : condition.endDate_created,
            startDate_serviced : condition.startDate_serviced,
            endDate_serviced   : condition.endDate_serviced,
            surveyRecords     : this.filterQuestion(),
            lang              : localStorage.getItem('i18nextLng') || 'ja',
            timezone          : momentTZ.tz.guess()
        };
        recordService.exportFile(dataReq, 'excel').then(res => {
            let linkDownload = document.getElementById('downloadCsvNote');
            linkDownload.setAttribute('href', res.data.url);
            linkDownload.click();
            this.setState({
                loadingBtnExcel: true
            });
        }).catch(error => console.log(error));
    }

    handlerExportToCSV() {
        const {condition} = this.props;
        this.setState({
            loadingBtnCSV: false
        });
        let dataReq = {
            customer     : condition.customer,
            startDate    : condition.startDate,
            endDate      : condition.endDate,
            surveyRecords: this.filterQuestion(),
            lang         : localStorage.getItem('i18nextLng') || 'ja',
            timezone     : momentTZ.tz.guess()
        };
        recordService.exportFile(dataReq, 'csv').then(res => {
            let linkDownload = document.getElementById('downloadCsvNote');
            linkDownload.setAttribute('href', res.data.url);
            linkDownload.click();
            this.setState({
                loadingBtnCSV: true
            });
        }).catch(error => console.log(error));
    }

    render() {
        const {statusBtnExport, t}             = this.props;
        const {loadingBtnExcel, loadingBtnCSV} = this.state;
        return (
            <div className='col-3 pb-3 pt-1'>
                <div className='d-flex justify-content-between'>
                    {loadingBtnExcel ?
                     <KirokuButton
                         color={'primary'}
                         disabled={statusBtnExport}
                         onClick={this.handlerExportToExcel.bind(this)}
                     >
                         {t('Export.ExportToExcel')}
                     </KirokuButton> :
                        <KirokuButton
                            color={'primary'}
                            disabled>
                            {t('Export.ExportToExcel')}
                        </KirokuButton>
                    }
                    <div className='p-2'/>
                    {loadingBtnCSV ?
                     <KirokuButton
                         hidden
                         disabled={statusBtnExport}
                         color={'success'}
                         onClick={this.handlerExportToCSV.bind(this)}
                     >
                         {t('Export.ExportToCSV')}
                     </KirokuButton> : <KirokuButtonSpinner color='success'>
                         <div className='pl-5 pr-5'/>
                     </KirokuButtonSpinner>
                    }
                    <a href={'xxx'} id={'downloadCsvNote'} hidden download>xxx</a>
                </div>
            </div>
        );
    }
}

export default BtnExport;
