import _ from 'lodash';

const defaultModalSelectCustomers = {
    keyword                   : '',
    customers                 : [],
    libCustomers              : [],
    teamsSelected             : [],
    isOpenSelected            : true,
    customersSelected         : [],
    libCustomersSelected      : [],
    isOpenModalSelectCustomers: false,
    customerTagByUser         : [],
    customerAllSystems        : [],
    historyModalSelectCustomer: {
        keyword                   : '',
        customers                 : [],
        libCustomers              : [],
        teamsSelected             : [],
        isOpenSelected            : true,
        customersSelected         : [],
        libCustomersSelected      : [],
        isOpenModalSelectCustomers: false,
        customerTagByUser         : [],
        customerAllSystems        : [],
    }
};
const isSearchByKeyword           = (customerName, keyword) => {
    return customerName.toLowerCase().search(keyword.toLowerCase()) !== -1;
};

const customersInTeams = (teamsSelected, libCustomers) => {
    if (!teamsSelected.length) {
        return libCustomers.map(item => ({ ...item, selected: false }));
    }

    let listCustomersInTeams = [];
    teamsSelected.forEach(tem => {
        listCustomersInTeams = _.concat(listCustomersInTeams, tem.customers);
    });

    return _.uniqBy([...listCustomersInTeams], 'id').map(item => ({ ...item, selected: false }));
};

const checkedCustomerSelectedOfCustomer = (customersSelected, customers) => {
    return customers.map(customer => {
        return {
            ...customer,
            selected: _.findIndex(customersSelected, ['id', customer.id]) !== -1,
        };
    });
};

function setSelectedCustomer(customers, isSelected) {
    return customers.map(item => ({ ...item, selected: isSelected }));
}

const ModalSelectCustomer = (state = defaultModalSelectCustomers, action) => {
    switch (action.type) {
        case 'CONFIRM_MODAL_CUSTOMERS':
            const listCustomerSelect = state.isOpenSelected ? state.libCustomersSelected : state.libCustomers;
            return {
                ...state,
                keyword             : '',
                teamsSelected       : [],
                customersSelected   : listCustomerSelect.filter((item) => (item.selected)),
                libCustomersSelected: listCustomerSelect.filter((item) => (item.selected)),
                libCustomers        : checkedCustomerSelectedOfCustomer(listCustomerSelect.filter((item) => (item.selected)), state.libCustomers),
                customers           : checkedCustomerSelectedOfCustomer(listCustomerSelect.filter((item) => (item.selected)), state.libCustomers),
                isOpenSelected      :listCustomerSelect.filter((item) => (item.selected)).length,
                historyModalSelectCustomer: {
                    ...state,
                    keyword             : '',
                    teamsSelected       : [],
                    customersSelected   : listCustomerSelect.filter((item) => (item.selected)),
                    libCustomersSelected: listCustomerSelect.filter((item) => (item.selected)),
                    libCustomers        : checkedCustomerSelectedOfCustomer(listCustomerSelect.filter((item) => (item.selected)), state.libCustomers),
                    customers           : checkedCustomerSelectedOfCustomer(listCustomerSelect.filter((item) => (item.selected)), state.libCustomers),
                    isOpenSelected      :listCustomerSelect.filter((item) => (item.selected)).length,
                }
            };
        case 'HANDLE_RESET_USER_CHANGED':
            if (state.isOpenSelected) {
                return {
                    ...state,
                    customersSelected   : state.customerTagByUser,
                    libCustomersSelected: state.customerTagByUser,
                    teamsSelected       : [],
                    keyword             : '',
                };
            }

            return {
                ...state,
                customers    : checkedCustomerSelectedOfCustomer(state.customerTagByUser, state.customerAllSystems),
                libCustomers : checkedCustomerSelectedOfCustomer(state.customerTagByUser, state.customerAllSystems),
                teamsSelected: [],
                keyword      : '',
            };
        case 'HANDLE_SELECT_ALL_CUSTOMERS_SELECTED':
            let isSelectAllCustomerSelected = _.findIndex(state.customersSelected, (ctm) => (!ctm.selected)) === -1;
            let ctmSelectedCheckAll         = setSelectedCustomer(state.customersSelected, !isSelectAllCustomerSelected);
            let libCustomersSelectedNew     = [...state.libCustomersSelected];

            ctmSelectedCheckAll.forEach(ctm => {
                let indexCtmOfCustomers                        = _.findIndex(state.libCustomersSelected, ['id', ctm.id]);
                libCustomersSelectedNew[ indexCtmOfCustomers ] = {
                    ...libCustomersSelectedNew[ indexCtmOfCustomers ],
                    selected: ctm.selected,
                };
            });

            return {
                ...state,
                customersSelected   : ctmSelectedCheckAll,
                libCustomersSelected: libCustomersSelectedNew,

            };
        case 'HANDLE_SELECT_ALL_CUSTOMERS':
            let isSelectAllCustomers = _.findIndex(state.customers, (ctm) => (!ctm.selected)) === -1;
            let ctmCheckAll          = setSelectedCustomer(state.customers, !isSelectAllCustomers);
            let libCustomersNew      = [...state.libCustomers];
            ctmCheckAll.forEach(ctm => {
                let indexCtmOfCustomers                = _.findIndex(state.libCustomers, ['id', ctm.id]);
                libCustomersNew[ indexCtmOfCustomers ] = {
                    ...libCustomersNew[ indexCtmOfCustomers ],
                    selected: ctm.selected,
                };
            });
            return {
                ...state,
                customers   : state.customers.map(ctm => ({ ...ctm, selected: !isSelectAllCustomers })),
                libCustomers: libCustomersNew,
            };
        case 'HANDLE_CHANGE_CHECKBOX_CUSTOMERS':
            let customersRaw = [...state.customers];

            customersRaw[ action.index ] = {
                ...customersRaw[ action.index ],
                selected: !customersRaw[ action.index ].selected,
            };

            let libCustomersRaw                     = [...state.libCustomers];
            const indexCustomerCheckedOfLibCustomer = _.findIndex(libCustomersRaw, ['id', customersRaw[ action.index ].id]);

            libCustomersRaw[ indexCustomerCheckedOfLibCustomer ] = {
                ...libCustomersRaw[ indexCustomerCheckedOfLibCustomer ],
                selected: !libCustomersRaw[ indexCustomerCheckedOfLibCustomer ].selected,
            };
            return {
                ...state,
                customers   : customersRaw,
                libCustomers: libCustomersRaw,
            };
        case 'HANDLE_CHANGE_CHECKBOX_CUSTOMERS_SELECTED':
            let customersRawSelected = [...state.customersSelected];

            customersRawSelected[ action.index ] = {
                ...customersRawSelected[ action.index ],
                selected: !customersRawSelected[ action.index ].selected,
            };

            let libCustomersRawSelected                     = [...state.libCustomersSelected];
            const indexCustomerCheckedOfLibCustomerSelected = _.findIndex(libCustomersRawSelected, ['id', customersRawSelected[ action.index ].id]);

            libCustomersRawSelected[ indexCustomerCheckedOfLibCustomerSelected ] = {
                ...libCustomersRawSelected[ indexCustomerCheckedOfLibCustomerSelected ],
                selected: !libCustomersRawSelected[ indexCustomerCheckedOfLibCustomerSelected ].selected,
            };

            return {
                ...state,
                customersSelected   : customersRawSelected,
                libCustomersSelected: libCustomersRawSelected,
            };
        case 'CHANGE_KEYWORD_SEARCH_CUSTOMERS_MODAL_SELECTED':
            let keySearchSelected = action.keyword.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');

            let customersNewSelected = [...state.libCustomersSelected].filter(customer => {
                    if (state.teamsSelected.length) {
                        let customersIsTeams = customersInTeams(state.teamsSelected, state.libCustomersSelected);
                        return (isSearchByKeyword(customer.name, keySearchSelected) && _.findIndex(customersIsTeams, { id: customer.id }) !== -1);
                    }
                    return isSearchByKeyword(customer.name, keySearchSelected);
                },
            );

            return {
                ...state,
                keyword          : action.keyword,
                customersSelected: customersNewSelected,
            };

        case 'CHANGE_KEYWORD_SEARCH_CUSTOMERS_MODAL':
            let keySearch = action.keyword.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');

            let customersNew = [...state.libCustomers].filter(customer => {
                    if (state.teamsSelected.length) {
                        let customersIsTeams = customersInTeams(state.teamsSelected, state.libCustomers);
                        return (isSearchByKeyword(customer.name, keySearch)
                            && _.findIndex(customersIsTeams, { id: customer.id }) !== -1);
                    }
                    return isSearchByKeyword(customer.name, keySearch);
                },
            );

            return {
                ...state,
                keyword  : action.keyword,
                customers: customersNew,
            };
        case 'SELECT_CUSTOMERS_IN_TEAMS_SELECTED':

            let customersIsTeamsRawSelected = customersInTeams(action.teamsSelected, state.libCustomersSelected).filter(customer => {
                return _.findIndex(state.libCustomersSelected, { id: customer.id }) !== -1;
            });

            customersIsTeamsRawSelected = [...state.libCustomersSelected].filter(customer => {
                    if (state.keyword) {
                        return isSearchByKeyword(customer.name, state.keyword)
                            && _.findIndex(customersIsTeamsRawSelected, { id: customer.id }) !== -1;
                    }
                    return _.findIndex(customersIsTeamsRawSelected, { id: customer.id }) !== -1;
                },
            );

            return {
                ...state,
                teamsSelected    : action.teamsSelected,
                customersSelected: customersIsTeamsRawSelected,
            };

        case 'SELECT_CUSTOMERS_IN_TEAMS':
            let customersIsTeamsRaw = customersInTeams(action.teamsSelected, state.libCustomers);

            customersIsTeamsRaw = [...state.libCustomers].filter(customer => {
                    if (state.keyword) {
                        return isSearchByKeyword(customer.name, state.keyword)
                            && _.findIndex(customersIsTeamsRaw, { id: customer.id }) !== -1;
                    }
                    return _.findIndex(customersIsTeamsRaw, { id: customer.id }) !== -1;
                },
            );

            return {
                ...state,
                teamsSelected: action.teamsSelected,
                customers    : customersIsTeamsRaw,
            };
        case 'GET_CUSTOMERS_BY_USER_AND_CUSTOMERS_BETA':
        case 'GET_CUSTOMERS_BY_USER_AND_CUSTOMERS_BETA_INIT':
            return {
                ...state,
                customers           : checkedCustomerSelectedOfCustomer(action.customersByUserId, action.customersBeta),
                libCustomers        : checkedCustomerSelectedOfCustomer(action.customersByUserId, action.customersBeta),
                customerAllSystems  : checkedCustomerSelectedOfCustomer(action.customersByUserId, action.customersBeta),
                customersSelected   : setSelectedCustomer(action.customersByUserId, true),
                libCustomersSelected: setSelectedCustomer(action.customersByUserId, true),
                customerTagByUser   : setSelectedCustomer(action.customersByUserId, true),
                isOpenSelected      : setSelectedCustomer(action.customersByUserId, true).length,
                isOpenModalSelectCustomers: false,
                historyModalSelectCustomer: {
                    ...state,
                    customers           : checkedCustomerSelectedOfCustomer(action.customersByUserId, action.customersBeta),
                    libCustomers        : checkedCustomerSelectedOfCustomer(action.customersByUserId, action.customersBeta),
                    customerAllSystems  : checkedCustomerSelectedOfCustomer(action.customersByUserId, action.customersBeta),
                    customersSelected   : setSelectedCustomer(action.customersByUserId, true),
                    libCustomersSelected: setSelectedCustomer(action.customersByUserId, true),
                    customerTagByUser   : setSelectedCustomer(action.customersByUserId, true),
                    isOpenSelected      : setSelectedCustomer(action.customersByUserId, true).length,
                    isOpenModalSelectCustomers: false,
                }
            };
        case 'CHANGE_IS_OPEN_MODAL_SELECT_CUSTOMERS':
            return {
                ...state,
                isOpenModalSelectCustomers: !state.isOpenModalSelectCustomers,
                historyModalSelectCustomer: {
                    ...state,
                    isOpenModalSelectCustomers: !state.isOpenModalSelectCustomers,
                }
            };
        case 'CHANGE_IS_OPEN_SELECTED_CUSTOMERS':
            let ctmSelected = state.libCustomers.filter(customer => customer.selected);
            if (action.isOpenSelected === state.isOpenSelected) {
                return state;
            }
            return {
                ...state,
                isOpenSelected      : action.isOpenSelected,
                teamsSelected       : [],
                customers           : checkedCustomerSelectedOfCustomer(state.libCustomersSelected.filter(ctm => ctm.selected), state.libCustomers),
                libCustomers        : checkedCustomerSelectedOfCustomer(state.libCustomersSelected.filter(ctm => ctm.selected), state.libCustomers),
                customersSelected   : setSelectedCustomer(ctmSelected, true),
                libCustomersSelected: setSelectedCustomer(ctmSelected, true),
                keyword             : '',
                historyModalSelectCustomer: {
                    ...state.historyModalSelectCustomer,
                    isOpenSelected      : action.isOpenSelected,
                }
            };
        case 'CANCEL_SELECT_CUSTOMER':
            return {
                ...state.historyModalSelectCustomer,
                isOpenSelected: state.historyModalSelectCustomer.libCustomersSelected.length
            };
        case 'LOAD_RECORD_FROM_ANALYSIS':
            const customerSelected = setSelectedCustomer(action.metaDataCustomers.filter(item => item.id === action.condition.customerId), true);
            return {
                ...state,
                customers: action.metaDataCustomers,
                libCustomers: action.metaDataCustomers,
                customersSelected : customerSelected,
                libCustomersSelected: customerSelected,
                isOpenSelected : true,
                historyModalSelectCustomer: {
                    ...state.historyModalSelectCustomer,
                    customersSelected :  customerSelected,
                    libCustomersSelected: customerSelected,
                    isOpenSelected : true,
                }

            };
        default:
            return state;
    }
};
export default ModalSelectCustomer;
