import React, { Component }                       from 'react';
import RenderComponentDevice                      from '../../../ui/RenderComponentDevice';
import PreviewTakeSurveyWeb                       from './web';
import PreviewTakeSurveyMobile                    from './mobile';
import { KirokuPageLoader, KirokuTitlepage }      from '../../../ui';
import { connect }                                from 'react-redux';
import { currentPage }                            from '../../../actions/CurrentPage';
import { withNamespaces }                         from 'react-i18next';
import { closeScreenDetail, getDetailSurveyById } from '../../../actions/Survey';
@withNamespaces()
@connect(state => {
    return {
        detailSurvey: state.KirokuAISurvey.detailSurvey
    }
}, dispatch => {
    return {
        currentPage: (name) => {
            dispatch(currentPage(name))
        },
        getDetailSurveyById: (id) => {
            dispatch(getDetailSurveyById(id))
        },
        closeScreenDetail: () => {
            dispatch(closeScreenDetail())
        },
        trackPreviewAction: (template) =>{
            dispatch({
                type: 'PREVIEW_TEMPLATE',
                template
            })
        }
    }
})
class PreviewTakeSurvey extends Component {

    state = {
        page: {
            title: <KirokuTitlepage title={this.props.t('common.Survey')}/>,
            actionPage: null
        }
    };


    componentWillMount() {
        this.props.getDetailSurveyById(this.props.location.state.id);
        this.props.currentPage(this.state.page);
    }

    componentWillUnmount() {
        this.props.closeScreenDetail()
    }

    componentDidMount() {
        this.props.trackPreviewAction({
            survey_id:this.props.location.state.id
        });
    }

    render() {
        const {detailSurvey} = this.props;
        return (
            detailSurvey ?
            <RenderComponentDevice
                mobile={<PreviewTakeSurveyMobile
                    survey={detailSurvey}
                    history={this.props.history}
                />}
                web={<PreviewTakeSurveyWeb
                    survey={detailSurvey}
                    history={this.props.history}
                />}
            />
                   : <KirokuPageLoader/>
        );
    }
}

export default PreviewTakeSurvey;
