export const LOAD_USERS                  = 'LOAD_USERS';
export const LOAD_STAFF_IN_TEAM          = 'LOAD_STAFF_IN_TEAM';
export const CHECK_MEMBER                = 'CHECK_MEMBER';
export const CHECK_ALL_MEMBER            = 'CHECK_ALL_MEMBER';
export const RESET_MEMBER_CHOICE         = "RESET_MEMBER_CHOICE";
export const LOAD_USER_PAGINATION        = "LOAD_USER_PAGINATION";
export const ASSIGN_USER_TO_TEAM         = "ASSIGN_USER_TO_TEAM";
export const DELETE_USERS                = "DELETE_USERS";
export const LOAD_USER_BY_ID             = "LOAD_USER_BY_ID";
export const FILTER_STAFF_WITH_CONDITION = "FILTER_STAFF_WITH_CONDITION";
export const SYNC_PROFILE                = 'SYNC_PROFILE';
export const LOAD_ALL_USER               = "LOAD_ALL_USER";
export const SET_ROLE                    = "SET_ROLE";
export const GET_STAFFS_BETA             = "GET_STAFFS_BETA";


export function setRole(role, userId, params, tags) {
    return {
        type: SET_ROLE,
        role,
        userId,
        params,
        tags
    }
}

export function loadUsers() {
    return {
        type: LOAD_USERS,
        loginRequired: true,
    }
}
export function getStaffsBeta() {
    return {
        type: GET_STAFFS_BETA,
    }
}

export function loadStaffInTeam(teamId) {
    return {
        type: LOAD_STAFF_IN_TEAM,
        loginRequired: true,
        teamId: teamId
    }
}

export function checkMember(index) {
    return {
        type: CHECK_MEMBER,
        index
    }
}

export function checkAllMember(checked) {
    return {
        type: CHECK_ALL_MEMBER,
        isChecked: checked
    }
}

export function resetSelectMember() {
    return {
        type: RESET_MEMBER_CHOICE
    }
}

export function loadUserPagination(params, tags) {
    return {
        type: LOAD_USER_PAGINATION,
        params: params,
        tags
    }
}

export function assginUserToTeam(users, teams, params, tags) {
    return {
        type: ASSIGN_USER_TO_TEAM,
        users: users,
        teams: teams,
        params, tags
    }
}

export function deleteUsers(userId, params, tags) {
    return {
        type: DELETE_USERS,
        userId: userId,
        params, tags
    }
}

export function loadUserById(listId) {
    return {
        type: LOAD_USER_BY_ID,
        listId: listId
    }
}


export function loadAllUser() {
    return {
        type: LOAD_ALL_USER
    }

}

