import React, { Component }                     from 'react';
import KirokuUploadFile                         from '../../../ui/KirokuUpLoadFile';
import { KirokuLoading }                        from '../../../ui';
import { uploadService }                        from '../../../services';
import { withNamespaces }                       from 'react-i18next';
import { conditionUpload }                      from '../../../services/FitlerData';
import ModalNotificationValidateUpload          from '../QuestionTypeWeb/ModalNotificationValidateUpload';
import { connect }                              from 'react-redux';
import { turnOnNotificationValidatedMediaFile } from '../../../actions/TakeSurvey';
import {checkUseHeadingQuestion}                from "../../Surveys/SurveyEditor/SurveyService";
import KirokuButtonLink                         from '../../../ui/KirokuButtonLink';

@withNamespaces()
@connect(() => {
    return {}
}, dispatch => {
    return {
        turnOnNotificationValidatedMediaFile: () => {
            dispatch(turnOnNotificationValidatedMediaFile())
        }
    }
})
class MediaFileQuestion extends Component {

    state = {
        imageUrl        : this.props.value.answer ? this.props.value.answer : null,
        typeAnswer      : this.props.value.typeAnswer ? this.props.value.typeAnswer : null,
        loadingImage    : false,
        openNotification: false
    };

    componentWillMount() {
        this.props.onAnswer({
            answer   : this.props.value,
            validated: this.props.value.answer ? this.props.value : null
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                imageUrl  : nextProps.value.answer,
                typeAnswer: nextProps.value.typeAnswer ? nextProps.value.typeAnswer : null
            });
            this.props.onAnswer({
                answer   : nextProps.value,
                validated: nextProps.value.answer ? nextProps.value : null
            });
        }
    }


    handleChangePhoto(e) {

        if (e.currentTarget.files[0]) {
            if (conditionUpload(e.currentTarget.files[0])) {
                const {question}   = this.props;
                const nextQuestion = question.nextQuestion || question.index + 1;
                this.setState({
                    nextQuestion: nextQuestion,
                    typeAnswer  : e.currentTarget.files[0].type,
                    loadingImage: true
                });
                let formData = new FormData();
                formData.append('file', e.currentTarget.files[0]);
                formData.append('name', e.currentTarget.files[0].name);
                uploadService.uploadImage(formData, 'avatar').then(res => {
                    this.setState({
                        imageUrl    : res.url,
                        loadingImage: false
                    });
                    const answer = {
                        answer: res.url, typeAnswer: this.state.typeAnswer
                    };
                    this.props.onAnswer({
                        answer   : answer,
                        validated: answer ? answer : null
                    });
                }).catch(e => {
                    console.log(e);
                });
            } else {
                this.props.turnOnNotificationValidatedMediaFile()
            }


        }
    }

    handleResetAnswer() {
        this.setState({
            imageUrl    : '',
            loadingImage: false
        });
        this.props.onAnswer({
            answer   : { answer: '', typeAnswer: this.state.typeAnswer },
            validated:  null
        });
    }


    render() {
        const {
            question, t,
            // index
        } = this.props;
        const contentQuestion = checkUseHeadingQuestion(question);
        return (
            <div id='component-question'>

                <ModalNotificationValidateUpload/>

                <div className='content-question'>
                    <div className='wraper-question'>
                        <div className="background-question">
                            <div className='number-question d-flex justify-content-between'>
                                <span className="question-number">
                                    {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                                </span>
                                <div/>
                                <div>
                                    <KirokuButtonLink
                                        className='padding-reset-button-link'
                                        onClick={() => {this.handleResetAnswer()}}>
                                        {t('createRecord.resetAnswer')}
                                    </KirokuButtonLink>
                                </div>
                            </div>
                        </div>
                        <div className='answer-question'>
                            <span className='content-name-question padding-question-content'>
                                {question.content}
                            </span>
                            {this.state.loadingImage ? <KirokuLoading/>
                            : <div>
                                    <KirokuUploadFile url={this.state.imageUrl} onChange={this.handleChangePhoto.bind(this)}/>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MediaFileQuestion;
