import React, {Component}        from 'react';
import {
    KirokuModalHeader,
    KirokuModalBody,
    KirokuModalFooter,
    KirokuButton,
    KirokuModal
}                                from "../../../ui";
import {connect}                 from 'react-redux';
import {addLogic}                from "../../../actions/SurveyMobile";
import KirokuDropdownLogicSingle from "./KirokuDropdownLogicSingle";
import {withNamespaces}          from "react-i18next";

@withNamespaces()
@connect(state => {
    return {
        questions: state.KirokuSurveyTemplateMobile.questions
    }
}, dispatch => {
    return {
        addLogic: (question) => {
            dispatch(addLogic(question))
        },
    }
})

class ModalAddLogicYesNo extends Component {

    state = {
        defaultYesNo: false,
        defaultSkip: false,
        nextQuestion   : this.props.question.nextQuestion,
        nextQuestionYes: this.props.question.nextQuestionYes,
        nextQuestionNo : this.props.question.nextQuestionNo,
        errors: {}
    };


    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.question !== this.props.question) {
            const {question} = nextProps;
            this.setState({
                nextQuestion   : question.nextQuestion,
                nextQuestionYes: question.nextQuestionYes,
                nextQuestionNo : question.nextQuestionNo,
            })
        }
    }

    handleSelectYes = (e) => {
        this.setState({
            nextQuestionYes: e.kirokuSelected.value,
            nextQuestion: '',
            defaultSkip: !this.state.defaultSkip,
        });
    };

    handleSelectNo = (e) => {
        this.setState({
            nextQuestionNo: e.kirokuSelected.value,
            nextQuestion: '',
            defaultSkip: !this.state.defaultSkip,
        })
    };

    validateYesNoQuestion = () => {
        let {nextQuestionNo, nextQuestionYes, nextQuestion} = this.state;
        let questionIsEmpty, chooseSkipOrYesNo, isDuplicate = false;
        if (nextQuestionNo === '' && nextQuestionYes === '' && nextQuestion === '') {
            questionIsEmpty = true;
        }

        if (nextQuestionYes !== '' && nextQuestionNo !== '' && nextQuestion !== '') {
            chooseSkipOrYesNo = true;
        }

        if ((nextQuestionYes === nextQuestionNo) && (nextQuestionYes !== '' && nextQuestionNo !== '')) {
            isDuplicate = true
        }

        this.setState({
            errors: {
                questionIsEmpty,
                chooseSkipOrYesNo,
                isDuplicate
            }
        }, () => this.handleConfirm())
    };

    handleSelectSkip = (e) => {
        this.setState({
            nextQuestionYes: '',
            nextQuestionNo: '',
            nextQuestion: e.kirokuSelected.value,
            defaultYesNo: !this.state.defaultYesNo,
        })
    };
    resetState       = () => {
        this.setState({
            message: '',
        });
    };

    handleConfirm = () => {
        if (Object.values(this.state.errors).every(error => !error)) {
            let {nextQuestionYes, nextQuestionNo, nextQuestion} = this.state;
            let question                                        = {
                ...this.props.question,
                nextQuestion,
                nextQuestionNo,
                nextQuestionYes
            };
            this.props.addLogic(question);
            this.props.closeModal();
            this.resetState();
        }
    };

    dropDownItems(question, questions) {
        return questions.map((question) => {
            return {
                value: question.index,
                component: (<div>
                    <div><span className={'text-muted'}>{question.content}</span></div>
                </div>),
                label: question.content
            }
        });
    }

    handleCancel() {
        const {question} = this.props;
        this.setState({
            nextQuestion: question.nextQuestion,
            nextQuestionNo: question.nextQuestionNo,
            nextQuestionYes: question.nextQuestionYes
        });
        this.props.closeModal();
    }
    render() {
        const {question, questions, t} = this.props;
        const items      = this.dropDownItems(question, questions);
        const {nextQuestionNo, nextQuestion, nextQuestionYes} = this.state;
        return (
            <div>
                <KirokuModal show={!this.props.show}>
                    <KirokuModalHeader color={'card-web'}>
                        {t('createRecord.Question')} {question.index + 1}
                    </KirokuModalHeader>
                    <KirokuModalBody>
                        <div className='p-3'>
                            <b>{question.heading}</b>
                            <p>{question.content}</p>
                            <p>{t('createSurvey.IfAnswerIsYesThenSkipTo')}</p>
                            <KirokuDropdownLogicSingle items={items} default={this.state.defaultYesNo}
                                                       question={question}
                                                       nextQuestion={nextQuestionYes}
                                                       onSelect={this.handleSelectYes.bind(this)}/>
                            <p>{t('createSurvey.IfAnswerIsNoThenSkipTo')}</p>
                            <p hidden={!this.state.errors.isDuplicate}
                               style={{color: 'red', fontSize: 12}} className='mt-2'
                            > {t('createSurvey.DuplicateQuestion')} </p>
                            <p hidden={!this.state.errors.chooseSkipOrYesNo}
                               style={{color: 'red', fontSize: 12}} className='mt-2'
                            >{t('createSurvey.ChooseQuestionYesNoOrSkip')}</p>
                            <p hidden={!this.state.errors.questionIsEmpty}
                               style={{color: 'red', fontSize: 12}} className='mt-2'
                            >{t('createSurvey.QuestionMustNotEmpty')}</p>
                            <KirokuDropdownLogicSingle items={items} default={this.state.defaultYesNo}
                                                       nextQuestion={nextQuestionNo}
                                                       question={question}
                                                       onSelect={this.handleSelectNo.bind(this)}/>
                            <hr/>
                            <p>{t('createSurvey.OrAnswerAndSkipTo')}</p>
                            <KirokuDropdownLogicSingle items={items} default={this.state.defaultSkip}
                                                       location={questions.length >= 3 ? 'top' : ''}
                                                       nextQuestion={nextQuestion}
                                                       question={question}
                                                       onSelect={this.handleSelectSkip.bind(this)}/>
                        </div>
                    </KirokuModalBody>
                    <KirokuModalFooter>
                        <KirokuButton onClick={this.handleCancel.bind(this)} color={'white'}>
                            {t('common.Cancel')}
                        </KirokuButton>
                        <KirokuButton onClick={this.validateYesNoQuestion.bind(this)} color={'primary'}>
                            {t('common.Confirm')}
                        </KirokuButton>
                    </KirokuModalFooter>
                </KirokuModal>
            </div>
        );
    }
}

export default ModalAddLogicYesNo;
