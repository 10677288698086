import React, { Component } from 'react';

export default class KirokuInputToSearch extends Component {

    render() {

        return (
            <div style={{position: 'relative'}}>
                <input value={this.props.value ? this.props.value : ''} placeholder={this.props.placeholder} id={this.props.id} onChange={this.props.onBlur.bind(this)} type="text" className="form-control-kiroku pr-4" />
                <div className='action-input'>
                    {this.props.children}
                </div>
            </div>
        )
    }
}
