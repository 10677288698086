import React, { Component } from "react";

export default class KirokuModalFooter extends Component {
    render() {
        return (
            <div className="modal-footer-kiroku modal-footer">
                {this.props.children}
            </div>
        )
    }
}