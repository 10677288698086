import React, { Component } from 'react';
import { filePathService }  from '../services';
import { Link }             from 'react-router-dom';
import FormatTimestamp      from '../components/Records/RecordsBeta/HelperComponents/FormatTimestamp';

class KriokuComment extends Component{
    render() {
        const { comment } = this.props;
        return(
            <div className={'comment'}>
                <div className={'content-comment'}>
                    <div className="d-flex justify-content-between align-items-center">
                       <Link to={`/staffs/staff/${comment['user_id']}`}>
                            <div className={'d-flex align-items-center'}>
                               <div className={'avatar-user-comment'}
                                    style={{backgroundImage: `url(${comment.avatar ? comment.avatar : filePathService.resolveFilePath('profile-ic.svg')})`}}
                               />
                               <div className={'author'}> {comment['user_name']} </div>
                            </div>
                       </Link>
                        <div className={'meta-data'}>
                            <span className={'date'}><FormatTimestamp  className={this.props.className} timestampUnix={comment.create_at} hasTime={true}/></span>
                        </div>
                    </div>
                    <blockquote  className={'mt-2'}>
                        {comment.content.split("\n").map((comment, index) => <p key={index} className={`mb-0 ${this.props.className}`}>{comment}</p>)}
                    </blockquote>
                </div>
            </div>
        )
    }
}

export default KriokuComment;
