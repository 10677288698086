import {GET_ORGANIZATION, UPDATE_ORGANIZATION} from "../actions/Organization";
import {organizationService}                   from "../services";
import {NOTIFICATION}                          from "../actions/Notification";

export const organizationMiddleware = (store) => next => action => {
    switch (action.type) {
        case GET_ORGANIZATION :
            return organizationService.getOrganization().then(res => {
                next({
                    type: GET_ORGANIZATION,
                    organization: res
                })
            });
        case UPDATE_ORGANIZATION :
            return organizationService.putOrganization(action.id, action.currentOrganization).then(organization => {
                next({
                    type: UPDATE_ORGANIZATION,
                    organizationCurrent: {
                        ...organization,
                        id: action.id
                    }
                });
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'success',
                    message: 'message.UpdateSuccess'
                })
            });
        default :
            return next(action);
    }
};