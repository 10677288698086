import React, { Component }         from 'react';
import { connect }                  from 'react-redux';
import { withNamespaces }           from 'react-i18next';
import BtnAction                    from './BtnAction';
import { setDefaultAnswerQuestion } from '../../../../../actions/Survey';
import { checkUseHeadingQuestion }  from '../../../SurveyEditor/SurveyService';
import {
    KirokuRadio, KirokuCardWeb,
    KirokuCardTitleWeb, KirokuCardBodyWeb,
    KirokuLogicApplicated, KirokusSortableHandle
}                          from '../../../../../ui';
import ModalAddLogicSingle from '../../../ModalAddLogicSingle';
import {
    focusQuestion, pushQuestionEditInStore,
    removeQuestion,
    showReview,
    turnModalEditQuestionSurveyTemplate,
}                          from '../../../../../actions/SurveyTemplate';
import ComponentUnrequited from '../../../ReviewSurvey/ComponentUnrequited';
import _                   from 'lodash';

@withNamespaces()
@connect(null, dispatch => {
    return {
        removeQuestion: (question) => {
            dispatch(removeQuestion(question));
        },

        focusQuestion                      : (index) => {
            dispatch(focusQuestion(index));
        },
        showReview                         : () => {
            dispatch(showReview());
        },
        turnModalEditQuestionSurveyTemplate: () => {
            dispatch(turnModalEditQuestionSurveyTemplate());
        },
        pushQuestionEditInStore            : (editedQuestion) => {
            dispatch(pushQuestionEditInStore(editedQuestion));
        },
        setDefaultAnswerQuestion           : (question) => {
            dispatch(setDefaultAnswerQuestion(question));
        },
    };
})
class SingleChoice extends Component {
    state = {
        show: true,
    };

    showModalAddLogic() {
        this.setState({
            show: !this.state.show,
        });
    }

    closeModal() {
        this.setState({
            show: !this.state.show,
        });
    }

    setDefaultAnswer(indexAnswerDefault) {
        const { question }  = this.props;
        let questionNew     = { ...question };
        questionNew.choices = questionNew.choices.map((choice, index) => {
            return {
                ...choice,
                defaultAnswer: index === indexAnswerDefault,
            };
        });
        this.props.setDefaultAnswerQuestion(questionNew);

    }
    handleResetDefaultAnswer() {
        const { question } = this.props;
        let questionNew    = { ...question };
        questionNew.choices = questionNew.choices.map((choice) => {
            if(choice.defaultAnswer) {
                delete choice.defaultAnswer;
            }
            return choice;
        });
        this.props.setDefaultAnswerQuestion(questionNew);
    }


    render() {
        const { question, t, ReviewCreate } = this.props;
        const contentQuestion               = checkUseHeadingQuestion(question);
        const singleQuestion                = question.choices.map(item => {
            return { nextQuestion: item.nextQuestion };
        });

        const isLogicSingle = _.findIndex(singleQuestion, (o) => {return typeof o.nextQuestion === 'number'}) !== -1;
        let isLogic  = question.nextQuestion === 0 || question.nextQuestion || isLogicSingle;

        return (
            <div id='component-question'>
                <div id='web-q' className='pb-3'>
                    <KirokuCardWeb>
                        <KirokusSortableHandle>
                        <KirokuCardTitleWeb color={'card-web'} style={{ paddingBottom: 10, paddingTop: 10 }}>
                            <div className='d-flex justify-content-between'  style={{ flexWrap: 'wrap'}}>
                                    <div className="question-number pt-10px">{t('createSurvey.QuestionOrder', { index: question.index + 1 })}</div>
                                <KirokuLogicApplicated padding={'5px 5px 5px 7px'}
                                                       question={{ ...question, singleQuestion }}/>
                                <BtnAction
                                    resetAnswer={() => {this.handleResetDefaultAnswer()}}
                                    question={question}
                                    showModalAddLogic={this.showModalAddLogic.bind(this)}
                                />
                            </div>
                        </KirokuCardTitleWeb>
                        </KirokusSortableHandle>
                        <KirokuCardBodyWeb>
                            <div hidden={isLogic}>
                                <ComponentUnrequited question={question}
                                                     changeUnrequitedQuestion={(questionIndex) => this.props.changeUnrequitedQuestion(questionIndex)}
                                />
                            </div>
                            <span hidden={ReviewCreate} className='content-name-question pb-2'>{contentQuestion}</span>
                            <span hidden={!ReviewCreate}
                                  className='content-name-question'>{t('createSurvey.Heading')}: {question.heading}</span>
                            <span hidden={!ReviewCreate}
                                  className='content-name-question pb-2'>{t('createSurvey.Content')}: {question.content}</span>
                            <p hidden={!question.nextQuestion} className='text-info'><i
                                className='fas fa-arrow-right text-info'> {t('createRecord.Question')} : {question.nextQuestion + 1}</i>
                            </p>
                            <div className='answer-question'>
                                {question.choices.map((choice, index) =>
                                    <div key={index}>
                                        <KirokuRadio
                                            key={index}
                                            id={`${question.index}-${index}`}
                                            name={`singleChoice_${question.index}`}
                                            checked={!!choice.defaultAnswer}
                                            onChange={this.setDefaultAnswer.bind(this, index)}
                                        >
                                            {choice.answer}
                                        </KirokuRadio>
                                        <p hidden={typeof choice.nextQuestion !== 'number'}
                                           className='text-info'><i
                                            className='fas fa-arrow-right text-info'>  {t('createRecord.Question')}: {choice.nextQuestion + 1}</i>
                                        </p>
                                    </div>
                                )}
                            </div>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                    <ModalAddLogicSingle show={this.state.show} question={question}
                                         closeModal={this.closeModal.bind(this)}/>
                </div>
            </div>
        );
    }
}

export default SingleChoice;
