import React, {Component} from 'react';

export default class KirokuCheckboxList extends Component{
    render() {
        const HeaderLeft  = this.props.headerLeft;
        const HeaderRight = this.props.headerRight;

        return (
            <div className="body-data">
                <div style={{height: '40px'}} className="title-data">
                    <div className="title-data-pad">
                        <div style={{float: "left"}}>
                            {HeaderLeft}
                        </div>
                        <div style={{float: "right"}}>
                            {HeaderRight}
                        </div>
                    </div>
                </div>
                <div className="content-data">
                    {this.props.children}
                </div>
            </div>
        );
    }
}