import {lockService} from '../services/index'


export const locks = (store) => next => action => {
const profile = JSON.parse(localStorage.getItem('profile'));
    switch (action.type) {
        case 'LOCK_INFORMATION':
            return lockService.lockInfo(action.lockableType, action.recordId).then(result => {
                if(result.previous_lock && (result.user_id !== result.previous_lock.user_id)) {
                    store.dispatch({
                        type: 'MODAL_ASK_RELOAD',
                        isOpen: true
                    })
                }

                if(result.user_id !== profile.id) {
                    store.dispatch({
                        type: 'ALTER_LOCKING',
                        alterLocking: true
                    })
                }

                next({
                    type: 'LOCK_INFORMATION',
                    lockInfo: result
                })
            }).catch(err => {
                store.dispatch({
                    type: 'SET_CALLING_LOCK_STATUS',
                    isCalling: false
                })
                console.log(err);
            });

        case 'LOCK_INFORMATION_FIRST':
            return lockService.lockInfo(action.lockableType, action.recordId).then(result => {
                next({
                    type: 'LOCK_INFORMATION_FIRST',
                    lockInfo: result
                })
            }).catch(err => {
                console.log(err);
            });
        case 'UNLOCK_RECORD':
            return lockService.unLock( action.actionType, action.id ).then(result => {
                next({
                    type: 'UNLOCK_RECORD',
                    message: result.message
                })
            }).catch(err => {
                console.log(err);
            });
        default:
            next({
                ...action
            })
    }
};
