import React, { Component }         from 'react';
import { connect }                  from 'react-redux';
import { withNamespaces }           from 'react-i18next';
import KirokuButton                 from '../../../../ui/KirokuButton';
import ModalNotificationConfirmExit from '../../RecordCreator/Mobile/ModalNoticationExit';
import AboutSurvey                  from '../../RecordCreator/AboutSurvey';
import Survey                       from '../../RecordCreator/Mobile/Survey';
import AboutSurveyReview            from '../../ReviewRecording/AboutSurvey';
import { ViewQuestioned }           from '../../ReviewRecording/ViewQuestioned';
import KirokuPageLoader             from '../../../../ui/KirokuPageLoader';
import {
    actionBackAnswer,
    actionBackScreenDetail,
    actionBeginAnswer,
    showConfirmExit
}                                   from '../../../../actions/TakeSurvey';

@withNamespaces()
@connect(state => {
    return {
        dataDraftRecording: state.KirokuAIDraftRecording.dataDraftRecording,
        flagLoadDraft     : state.TakeSurveyMobile.flagLoadDraft,
        beginAnswer       : state.TakeSurveyMobile.beginAnswer,
        reviewRecorded    : state.TakeSurveyMobile.reviewRecorded,
        answeredQuestions : state.TakeSurveyMobile.recorded
    }
}, dispatch => {
    return {
        actionBackScreenDetail: () => {
            dispatch(actionBackScreenDetail())
        },
        actionBeginAnswer     : () => {
            dispatch(actionBeginAnswer())
        },

        actionBackAnswer: () => {
            dispatch(actionBackAnswer())
        },

        showConfirmExit: () => {
            dispatch(showConfirmExit())
        }
    }
})
class PreviewTakeSurveyMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingSubmit   : false,
            loadingSaveDraft: false
        };
    }

    handleBeginAnswer() {
        this.props.actionBeginAnswer();

    }

    handleCancel() {
        this.props.history.goBack();
    }

    handleBack() {
        this.props.actionBackAnswer();
    }

    handleSaveDraft(dataDraft) {

    }

    componentWillUnmount() {
        this.props.actionBackScreenDetail();
        this.props.actionBackAnswer();
    }

    render() {
        const {
                  survey, customer, t,
                  beginAnswer, reviewRecorded,
                  answeredQuestions
              } = this.props;
        return (
            <div>
                <div hidden={reviewRecorded}>
                    <div className="p-2" style={{backgroundColor: '#eef3f6'}}>

                        <div className="status-survey">
                            <KirokuButton color={'light'} onClick={this.handleCancel.bind(this)}>
                                <span>{t('createRecord.cancel')}</span>
                            </KirokuButton>
                            <span className="status-survey-content">
                        </span>
                        </div>
                        <ModalNotificationConfirmExit history={this.props.history} customer={customer}/>
                    </div>
                    <div className="keeping-about-survey" style={{ backgroundColor: '#FFFFFF', top : 0}}>
                        <AboutSurvey hidden={beginAnswer} survey={survey} shouldNotShowServicedAt
                                     screen={'takeRecord'}
                                     customer={customer} isRecord/>
                    </div>

                    <Survey onRef={ref => { this.Survey = ref; }}
                            hidden={!beginAnswer}
                            survey={survey}
                            customer={customer}
                            history={this.props.history}
                            saveDraft={this.handleSaveDraft.bind(this)}
                            saveBackupData={() =>{}}
                    />

                    <div className="action-record" hidden={beginAnswer}>
                        <KirokuButton color={'primary'} style={{width: '100%', borderRadius: 0, height: 42}}
                                      onClick={this.handleBeginAnswer.bind(this)}>
                            <span className="text-tranf">
                                {t('createRecord.next')}
                            </span>
                        </KirokuButton>
                    </div>
                </div>

                <div hidden={!reviewRecorded}>
                    {answeredQuestions ? <div>
                        <div className='survey'>
                            <div className="keeping-about-survey" style={{ backgroundColor: '#FFFFFF', top : 0}}>
                                <AboutSurveyReview survey={survey} customer={customer} isMobile shouldNotShowServicedAt/>
                            </div>
                            <div className="survey" style={{backgroundColor: '#FFFFFF'}}>
                                <div className='wraper-question' >
                                    <div className='wraper-question'>
                                        <div className="background-question-review">
                                            <div className='info-question'>
                                                <div className='content-name-question'>
                                                    <div className='title-record-info'>{t('createRecord.Description')}</div>
                                                    {survey.description}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ViewQuestioned questions={answeredQuestions}/>
                            <div className="action-record">
                                <div>
                                    <KirokuButton color={'light'} style={{width: '100%'}}
                                                  onClick={this.handleBack.bind(this)}>
                                        <span className="text-tranf">{t('createRecord.back')}</span>
                                    </KirokuButton>
                                </div>
                            </div>
                        </div>
                    </div> : <KirokuPageLoader/>
                    }
                </div>
            </div>
        );
    }
}

export default PreviewTakeSurveyMobile;
