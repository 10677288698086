import React, { Component }        from 'react';
import OrganizationWeb             from './OrganizationWeb';

class Organization extends Component {

    render() {
        return (
            <OrganizationWeb/>
        );
    }
}

export default Organization;
