import React, { Component, Fragment }                                                   from 'react';
import moment                                                                           from 'moment';
import Dropzone                                                                         from 'react-dropzone';
import { connect }                                                                      from 'react-redux';
import { withNamespaces }                                                               from 'react-i18next';
import {
    KirokuCardWeb,
    KirokuCheckBox,
    KirokuInputSearch,
    KirokuLoading,
    KirokuPagination,
    KirokuTable, TBody, THeader,
    KirokuResetFilterCondition,
    KirokuModalHeader, KirokuModalBody, KirokuModalFooter, KirokuModal,
    KirokuButton,
    KirokuCard, KirokuCardList,
}                                                                                       from '../../../../ui';
import FormUploadCustomer                                                               from './FormUploadCustomer';
import FilterType                                                                       from './FilterType';
import { uploadService, downloadService, previewService, filePathService, authService } from '../../../../services';
import {
    filterByCondition, loadDocuments,
}                                                                                       from '../../../../actions/Customer';
import {
    documentSelect, documentSelectAll, setPrivateDocument, assignUsersDocument,
}                                                                                       from '../../../../actions/Document';
import ModalRemoveDocument                                                              from '../ModalRemoveDocument';
import ModalConfirm                                                                     from '../ModalConfirm';
import ModalPreview                                                                     from './ModalPreview';
import { NOTIFICATION }                                                                 from '../../../../actions/Notification';
import TabResult
                                                                                        from '../../../Staffs/StaffEditor/TabResult';
import _                                                                                from 'lodash';
import config                                                                           from '../../../../config';

let allowFileType   = ['jpg', 'jpeg', 'png', 'gif', 'pdf', 'PNG', 'JPG', 'JPEG', 'GIF', 'PDF'];
let acceptFileTypes = ['text/*',
    'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'image/*',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

@withNamespaces()
@connect(state => {
    return {
        customer              : state.KirokuAICustomer.customer,
        token                 : state.KirokuAICustomer.token,
        documents             : state.KirokuAICustomer.documents,
        loading               : state.Loading,
        loadingResultDocuments: state.KirokuAICustomer.loadingResultDocuments,
        flag                  : state.KirokuAICustomer.flag,
        profile               : state.KirokuAIUser.profile,
        users                 : state.KirokuAIUser.users,
    };
}, dispatch => {
    return {
        searchDocument     : (params) => {
            dispatch(loadDocuments(params));
        },
        reloadListDocument : (params) => {
            dispatch(loadDocuments(params));
        },
        filterByCondition  : (params) => {
            dispatch(filterByCondition(params));
        },
        documentSelect     : (index) => {
            dispatch(documentSelect(index));
        },
        documentSelectAll  : (status) => {
            dispatch(documentSelectAll(status));
        },
        notification       : () => {
            dispatch({
                type            : NOTIFICATION,
                typeNotification: 'success',
                message         : 'message.Upload',
            });
        },
        notificationMaximum: () => {
            dispatch({
                type            : NOTIFICATION,
                typeNotification: 'danger',
                message         : 'message.UploadFail',
            });
        },
        updateDocument     : (customerId, listId, privateDocument) => {
            dispatch(setPrivateDocument(customerId, listId, privateDocument));
        },
        assignDocument     : (customerId, documentId, userIds) => {
            dispatch(assignUsersDocument(customerId, documentId, userIds));
        },
    };
})
class TabUpload extends Component {

    state = {
        loadingImg         : false,
        showModalDelete    : false,
        statusChecked      : false,
        files              : [],
        conditions         : [],
        pageMin            : 1,
        currentPage        : 1,
        disabledPrev       : true,
        disabledNext       : false,
        show               : false,
        url                : '',
        name               : '',
        page               : 1,
        startDate          : '',
        endDate            : '',
        day                : '',
        week               : '',
        month              : '',
        nameStatus         : '',
        params             : {},
        dateRanger         : {},
        response           : {},
        showModalAssignUser: false,
        document           : {},
        usersForModal      : [],
        usersAssign        : [],
        statusCheck        : false,
        showModalConfirm   : false,
        documentId         : '',
        keywordSearchStaff : '',
    };

    closeModal() {
        this.setState({
            show: !this.state.show,
        });
    }

    increasePage() {
        let nextPage = this.state.currentPage === this.props.documents.totalPages ? this.props.documents.totalPages : this.state.currentPage + 1;
        if (nextPage === this.props.documents.totalPages) {
            this.setState({
                disabledNext: !this.state.disabledNext,
            });
        }
        this.setState({
            currentPage : nextPage,
            page        : nextPage || 1,
            disabledPrev: false,
            selected    : false,
        }, () => this.props.reloadListDocument({
            type       : this.state.params.type ? this.state.params.type : '',
            customerId : this.props.customer.id,
            page       : nextPage,
            currentPage: nextPage,
            name       : this.state.name ? this.state.name : '',
        }));
    }

    decreasePage() {
        let previousPage = this.state.currentPage === this.state.pageMin ? 1 : this.state.currentPage - 1;
        if (previousPage === this.state.pageMin) {
            this.setState({
                disabledPrev: true,
            });
        }
        this.setState({
            currentPage : previousPage,
            page        : previousPage || 1,
            disabledNext: false,
            selected    : false,
        }, () => this.props.reloadListDocument({
            type       : this.state.params.type ? this.state.params.type : '',
            customerId : this.props.customer.id,
            page       : previousPage,
            currentPage: previousPage,
            name       : this.state.name ? this.state.name : '',
        }));
    }

    handleChoiceDate(e) {
        this.setState({
            page  : 1,
            params: {
                type                    : this.state.params.type,
                [e.kirokuSelected.value]: e.kirokuSelected.value === 'All date' ? '' : moment().startOf(e.kirokuSelected.value).unix(),
            },
        }, () => {
            this.filter();
        });
    }

    onDrop(files) {
        if (files[0].size > 10000000) {
            this.props.notificationMaximum();
        } else {
            this.setState({
                loadingImg: !this.state.loadingImg,
            });
            this.setState({ files }, () => {
                let file     = files[0];
                let formData = new FormData();
                formData.append('file', file);
                formData.append('name', file.name);
                uploadService.uploadImage(formData).then(res => {
                    this.setState({
                        loadingImg: false,
                    });
                    this.props.notification();
                    let document = {
                        url       : res.url,
                        type      : res.type,
                        name      : res.name,
                        filePath  : res.filePath,
                        customerId: this.props.id,
                    };
                    this.props.handleUploadFile(document);
                });
            });
        }
    }

    onCancel() {
        this.setState({
            files: [],
        });
    }

    onSearchDocument(e) {
        this.setState({
            page: 1,
            name: e.target.value,
        }, () => {
            this.filter();
        });
    }

    makeCondition(conditions) {
        this.setState({
            conditions,
        });
        let params = this.state.params;
        conditions.map(condition => {
            params[condition.query] = condition.value;
            return condition;
        });
        this.setState({
            currentPage: 1,
            page       : 1,
            params     : params,
        }, () => {
            this.filter(params);
        });
    }

    filterCondition(conditions) {
        this.makeCondition(conditions);
        //filter theo type
    }

    filter() {
        this.props.filterByCondition({
            ...this.state.params,
            page      : this.state.page,
            customerId: this.props.customer.id,
            name      : this.state.name ? this.state.name : '',
        });
    }

    download(documentId) {
        downloadService.download({ documentId: documentId, customerId: this.props.id });
    }


    async preview(index, fileType) {
        let document   = this.props.documents.documents[index];
        let fileReview = await previewService.preview({ customerId: document.customer_id, documentId: document.id });

        const response = {
            path    : `${ fileReview.url }`,
            fileType: fileType,
        };
        this.setState({
            show: true,
            response,
        });
    }

    dropDownItemDate = [
        {
            value    : 'All date',
            component: (<div>{ this.props.t('RecordList.allDate') } </div>),
            label    : this.props.t('RecordList.allDate'),
        },
        {
            value    : 'day',
            component: (<div>{ this.props.t('RecordList.day') } </div>),
            label    : this.props.t('RecordList.day'),
        },
        {
            value    : 'week',
            component: (<div>{ this.props.t('RecordList.week') }</div>),
            label    : this.props.t('RecordList.week'),
        },
        {
            value    : 'month',
            component: (<div>{ this.props.t('RecordList.month') }</div>),
            label    : this.props.t('RecordList.month'),
        },
    ];

    itemDelete = [
        {
            value    : 'Delete',
            component: (<div>{ this.props.t('common.Delete') } </div>),
            label    : this.props.t('common.Delete'),
        },
    ];

    handleCheckFile(index) {
        this.props.documentSelect(index);
        let documentChecked = this.props.documents.documents.filter(item => item.isChecked).length;
        if (this.props.documents.documents.length === documentChecked) {
            this.setState({
                statusChecked: true,
            });
        } else {
            this.setState({
                statusChecked: false,
            });
        }
    }

    handleCheckAllFile() {
        this.setState({
            statusChecked: !this.state.statusChecked,
        }, () => {
            this.props.documentSelectAll(this.state.statusChecked);
        });
    }

    handleDelete() {
        this.setState({
            showModalDelete: !this.state.showModalDelete,
        });
    }

    closeModalDelete() {
        this.setState({
            showModalDelete: false,
            nameStatus     : this.props.t('common.BulkAction'),
        });
    }

    onSelect() {
        return !this.props.documents.documents.filter(item => item.isChecked).length;
    }

    documentsChoice() {
        return this.props.documents.documents.filter(item => item.isChecked);
    }

    updateDocument(listId, privateDocument) {

        this.props.updateDocument(this.props.customer.id, listId, privateDocument);
    }

    checkAssignUser(documents, user) {
        let documentUsers = documents.users.map(user => user.id);
        return documentUsers.includes(user.id);
    }

    assignUser() {
        let userForModal = this.state.usersAssign.filter(user => user.isChecked);
        let customer     = this.props.customer;
        let document     = this.state.document;
        this.props.assignDocument(customer.id, document.id, userForModal.map(item => item.id));
        this.setState({
            showModalAssignUser: !this.state.showModalAssignUser,
            document           : {},
            keywordSearchStaff : '',
        });
    }

    handlerModalAssignUser() {
        this.setState({
            showModalAssignUser: !this.state.showModalAssignUser,
            document           : {},
            keywordSearchStaff : '',
        });
    }

    shareDocument(index) {
        this.setState({
            showModalAssignUser: !this.state.showModalAssignUser,
            document           : this.props.documents.documents[index],
            usersForModal      : this.props.users,
        }, () => {
            this.filterUser(this.state.document, this.state.usersForModal);
        });
    };

    filterUser(document, users) {
        let usersDocument = document.users.map(item => item.id);
        let userAssigned  = users.map(user => ({
            ...user,
            isChecked: usersDocument.includes(user.id),
        }));

        let checked = _.filter(userAssigned, (user) => !user.isChecked);
        this.setState({
            usersForModal: userAssigned,
            usersAssign  : userAssigned,
            statusCheck  : !checked.length,
        });
    }

    handleCheckAll() {
        let { usersForModal, usersAssign } = this.state;
        let newUserRecord                  = usersForModal.map(user => ({
            ...user,
            isChecked: !this.state.statusCheck,
        }));

        let usersChecked = newUserRecord.map(item => item.id || []);

        let newUsersAssign = usersAssign.map(user => {
            if (usersChecked.includes(user.id)) {
                return {
                    ...user,
                    isChecked: !this.state.statusCheck,
                };
            }
            return {
                ...user,
            };
        });
        this.setState({
            usersForModal: newUserRecord,
            usersAssign  : newUsersAssign,
            statusCheck  : !this.state.statusCheck,
        });
    }

    handleCheckUsers(userId) {
        let { usersForModal, usersAssign } = this.state;
        let userRecords                    = usersForModal.map(user => ({ ...user }));
        let user                           = _.find(userRecords, user => user.id === userId);
        user.isChecked                     = !user.isChecked;
        let searchUsers                    = usersAssign;
        let userAssign                     = _.find(searchUsers, user => user.id === userId);
        userAssign.isChecked               = !userAssign.isChecked;
        let checked                        = _.filter(userRecords, (user) => !user.isChecked);
        this.setState({
            usersForModal: userRecords,
            surveysAssign: searchUsers,
            statusCheck  : !checked.length,
        });
    }


    onChangeSearchUsers(e) {
        let input   = e.target.value.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
        let users   = this.state.usersAssign.filter(user =>
            user.name.toLowerCase().search(input.toLowerCase()) !== -1,
        );
        let checked = _.filter(users, (user) => !user.isChecked);
        this.setState({ usersForModal: users, statusCheck: !checked.length, keywordSearchStaff: input });
    }

    showModalConfirm(documents) {
        this.setState({
            showModalConfirm: !this.state.showModalConfirm,
            documentId      : documents,
        });
    }

    closeModalConfirm() {
        this.setState({
            showModalConfirm: !this.state.showModalConfirm,
            documentId      : '',
        });
    }

    render() {
        const { documents, t, token, profile }      = this.props;
        const { usersForModal, keywordSearchStaff } = this.state;

        // const documentsFilter = authService.isAdmin() ? documents.documents : documents.documents.filter(document => {
        //     return !document.private || this.checkAssignUser(document, profile);
        // });

        return (
            <div hidden={ this.props.hidden }>
                <div className='form-upload-data cursor-pointer'>
                    <Dropzone
                        multiple={ false }
                        accept={ acceptFileTypes }
                        onDrop={ this.onDrop.bind(this) }
                        onFileDialogCancel={ this.onCancel.bind(this) }
                    >
                        { ({ getRootProps, getInputProps, isDragActive }) => (
                            <div { ...getRootProps() }>
                                <input { ...getInputProps() } />
                                { isDragActive ?
                                    <FormUploadCustomer>
                                        <span>{ t('common.DragFile') }</span>
                                    </FormUploadCustomer> :
                                    <div className='wrap-upload-customer'>
                                        <div className='fix-center pt-3 pb-3'>
                                            <div
                                                className={ `border-icon-upload ${ this.state.files.length ? `${ !this.state.loadingImg ? '' : 'active' }` : '' }` }>
                                                <div className='main-loading'>
                                                    <div className={ this.state.loadingImg ? 'opacity-main' : '' }>
                                                        <div className="overlay"/>
                                                    </div>
                                                    <div
                                                        className={ !this.state.loadingImg ? 'display-none' : 'modal-kiroku fade show' }>
                                                        <div className="modal-dialog modal-dialog-centered fix-center">
                                                            <div className='as'>
                                                                <button className='btn' style={ {
                                                                    padding   : '.5rem',
                                                                    background: 'transparent',
                                                                } }>
                                                                    <div className="spinner"
                                                                         style={ { width: 60, height: 60 } }/>
                                                                    <p className='text-white pt-2'>{ t('common.Uploading') }</p>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <i hidden={ this.state.loadingImg }
                                                   className="fas fa-file-upload color-icon-upload-customer"/>
                                            </div>
                                            <div className='text-upload-customer'>
                                                <span>{ t('common.DragFile') }</span>
                                                {/*{!this.state.files.length ?*/ }
                                                {/*<span>{t('common.DragFile')}</span> :*/ }
                                                {/*<span>{this.state.files[0].name} - {(this.state.files[0].size)}byte</span>*/ }
                                                {/*}*/ }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        ) }
                    </Dropzone>
                </div>
                <div
                    className={ `d-flex ${ authService.isAdmin() ? 'justify-content-between' : 'justify-content-end' } pt-3 pb-3` }>
                    { authService.isAdmin() && (
                        <KirokuResetFilterCondition
                            disabled={ this.onSelect() }
                            style={ { width: 160 } }
                            title={ this.state.nameStatus }
                            defaultValue={ t('common.BulkAction') }
                            items={ this.itemDelete }
                            onSelect={ this.handleDelete.bind(this) }
                        />) }
                    <KirokuPagination
                        className='pb-0'
                        disabledPrev={ this.state.pageMin === this.state.currentPage }
                        disabledNext={ this.state.currentPage === documents.totalPages }
                        increasePage={ this.increasePage.bind(this) }
                        decreasePage={ this.decreasePage.bind(this) }
                        currentPage={ documents.currentPage }
                        totalPage={ documents.totalPages === 0 ? 1 : documents.totalPages }
                    />
                </div>
                <KirokuCardWeb>
                    <div className="p-3">
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex'>
                                <KirokuResetFilterCondition
                                    style={ { width: 150 } }
                                    icon='far fa-calendar-alt text-secondary'
                                    title={ this.state.nameStatus }
                                    defaultValue={ t('Surveys.AllDate') }
                                    items={ this.dropDownItemDate }
                                    onSelect={ this.handleChoiceDate.bind(this) }
                                />
                                <div className='pr-2'/>
                                <FilterType
                                    t={ this.props.t }
                                    filterCondition={ this.filterCondition.bind(this) }/>
                            </div>
                            <KirokuInputSearch onChange={ this.onSearchDocument.bind(this) }
                                               placeholder={ t('common.Search') }/>
                        </div>
                        { documents.documents.length ? <div>
                                { this.props.loadingResultDocuments ? <KirokuTable>
                                    <THeader>
                                        <tr>
                                            { !authService.isStaff() && (
                                                <th style={ { width: 50 } }>
                                                    <KirokuCheckBox
                                                        onChange={ this.handleCheckAllFile.bind(this) }
                                                        checked={ this.state.statusChecked }
                                                    />
                                                </th>
                                            ) }
                                            <th>{ t('common.Name') }</th>
                                            <th>{ t('common.Type') }</th>
                                            <th>{ t('common.DateCreated') }</th>
                                            <th className='text-center'>{ t('common.Action') }</th>
                                        </tr>
                                    </THeader>
                                    <TBody>
                                        { documents.documents.map((file, index) =>
                                            <tr key={ file.id }>
                                                { !authService.isStaff() && (
                                                    <th>
                                                        <KirokuCheckBox
                                                            onChange={ this.handleCheckFile.bind(this, index) }
                                                            checked={ file.isChecked || false }
                                                        />
                                                    </th>
                                                ) }
                                                <td>{ file.name }
                                                    { file.private && !this.checkAssignUser(file, profile) ? (
                                                        <i className="pl-2 fa fa-lock"/>) : '' }
                                                </td>
                                                <td>{ file.type }</td>
                                                <td style={ { whiteSpace: 'nowrap' } }>{ moment.unix(file.create_at).format(config.formatDateTimeTh) }</td>
                                                <td className='fix-center'>
                                                    <div className='d-flex justify-content-between'>
                                                        { !file.private || !authService.isStaff() || this.checkAssignUser(file, profile) ? (
                                                            <Fragment>
                                                                <a href={ `${ config.domain }/download?customerId=${ this.props.id }&documentId=${ file.id }&token=${ token }` }
                                                                   style={ { margin: 'auto' } }>
                                                                    <div className='pr-3' style={ { cursor: 'pointer' } }>
                                                                        <i className="fas fa-cloud-download-alt text-secondary"/>
                                                                        <span
                                                                            className='ml-1 text-dark'>{ t('common.Download') }</span>
                                                                    </div>
                                                                </a>
                                                                <div/>
                                                                <div onClick={ this.preview.bind(this, index, file.type) }
                                                                     className="pr-3"
                                                                     style={ { cursor: 'pointer', pointerEvents: `${ !allowFileType.includes(file.type) ? 'none' : 'stroke' }`, margin: 'auto' } }>
                                                                    <i className={ `${ !allowFileType.includes(file.type) ? 'fas fa-binoculars tranfer-text' : 'fas fa-binoculars text-secondary' }` }/>
                                                                    <span
                                                                        className={ `${ !allowFileType.includes(file.type) ? 'ml-1  tranfer-text' : 'ml-1 text-dark' }` }>{ t('common.Preview') }</span>
                                                                </div>
                                                                <div/>
                                                            </Fragment>
                                                        ) : '' }
                                                        { !authService.isStaff() && (
                                                            file.private ? (
                                                                <Fragment>
                                                                    <div
                                                                        onClick={ this.showModalConfirm.bind(this, file.id) }
                                                                        className="pr-3"
                                                                        style={ { cursor: 'pointer', pointerEvents: 'stroke', margin: 'auto' } }>
                                                                        <i className='fas fa-unlock text-secondary'/>
                                                                        <span
                                                                            className='ml-1 text-dark'>{ t('common.makePublic') }</span>
                                                                    </div>
                                                                    <div
                                                                        onClick={ this.shareDocument.bind(this, index) }
                                                                        className="pr-3"
                                                                        style={ { cursor: 'pointer', pointerEvents: 'stroke', margin: 'auto' } }>
                                                                        <i className='fas fa-share text-secondary'/>
                                                                        <span
                                                                            className='ml-1 text-dark'>{ t('common.share') }</span>
                                                                    </div>
                                                                </Fragment>
                                                            ) : (
                                                                <div
                                                                    onClick={ this.updateDocument.bind(this, file.id, true) }
                                                                    className="pr-3"
                                                                    style={ { cursor: 'pointer', pointerEvents: 'stroke', margin: 'auto' } }>
                                                                    <i className='fas fa-lock text-secondary'/>
                                                                    <span
                                                                        className='ml-1 text-dark'>{ t('common.makePrivate') }</span>
                                                                </div>
                                                            )
                                                        ) }
                                                    </div>
                                                </td>
                                            </tr>,
                                        ) }
                                    </TBody>
                                </KirokuTable> : <KirokuLoading/> }
                            </div> :
                            <div className='pt-3'>
                                <TabResult messageFirst={ `${ this.props.flag ? 'FileNotFound' : 'FilterFound' }` }>
                                    <img
                                        className='pt-2 pb-2'
                                        src={ `${ filePathService.resolveFilePath('empty-survey.svg') }` }
                                        alt="error img"/>
                                </TabResult>
                            </div>
                        }
                    </div>
                </KirokuCardWeb>
                <div
                    className={ `d-flex ${ authService.isAdmin() ? 'justify-content-between' : 'justify-content-end' } pt-3 pb-3` }>
                    { authService.isAdmin() && (
                        <KirokuResetFilterCondition
                            disabled={ this.onSelect() }
                            style={ { width: 160 } }
                            location={ 'top' }
                            title={ this.state.nameStatus }
                            defaultValue={ t('common.BulkAction') }
                            items={ this.itemDelete }
                            onSelect={ this.handleDelete.bind(this) }
                        />
                    ) }
                    <KirokuPagination
                        disabledPrev={ this.state.pageMin === this.state.currentPage }
                        disabledNext={ this.state.currentPage === documents.totalPages }
                        increasePage={ this.increasePage.bind(this) }
                        decreasePage={ this.decreasePage.bind(this) }
                        currentPage={ documents.currentPage }
                        totalPage={ documents.totalPages === 0 ? 1 : documents.totalPages }
                    />
                </div>
                <ModalPreview
                    show={ this.state.show }
                    closeModal={ this.closeModal.bind(this) }
                    response={ this.state.response }/>
                <ModalRemoveDocument
                    closeModal={ this.closeModalDelete.bind(this) }
                    documents={ this.documentsChoice() }
                    customerId={ this.props.customer.id }
                    show={ this.state.showModalDelete }
                />
                <ModalConfirm
                    show={ this.state.showModalConfirm }
                    submit={ this.updateDocument.bind(this, this.state.documentId, false) }
                    closeModal={ this.closeModalConfirm.bind(this) }
                />

                <KirokuModal show={ this.state.showModalAssignUser }
                             onCloseModal={ this.handlerModalAssignUser.bind(this) }>
                    <KirokuModalHeader>
                        <h6 className="mb-3">{ t('CustomerEditor.AssignToUsers') }</h6>
                        <KirokuInputSearch onChange={ this.onChangeSearchUsers.bind(this) }
                                           value={ keywordSearchStaff }
                                           placeholder={ t('CustomerEditor.EnterNameUser') }/>
                    </KirokuModalHeader>
                    <KirokuModalBody>
                        <div id="body-modal-as">
                            <KirokuCardList
                                headerLeft={ t('Dashboard.Users') }
                                headerRight={
                                    <div className='text-primary' style={ { cursor: 'pointer' } }>
                                                <span hidden={ this.state.statusCheck }
                                                      onClick={ this.handleCheckAll.bind(this) }
                                                >{ t('common.SelectAll') }</span>
                                        <span hidden={ !this.state.statusCheck }
                                              onClick={ this.handleCheckAll.bind(this) }
                                        >{ t('common.UnSelect') }</span>
                                    </div>
                                }
                            >
                                <section className="scroll-data-customer">
                                    { usersForModal.map((user) => (
                                        <KirokuCard key={ user.id }>
                                            <KirokuCheckBox
                                                onChange={ this.handleCheckUsers.bind(this, user.id) }
                                                checked={ user.isChecked } content={ user.name }/>
                                        </KirokuCard>
                                    )) }
                                </section>
                            </KirokuCardList>
                        </div>
                    </KirokuModalBody>
                    <KirokuModalFooter>
                        <KirokuButton onClick={ this.handlerModalAssignUser.bind(this) } color={ 'white' }>
                            { t('common.Cancel') }
                        </KirokuButton>
                        <KirokuButton color={ 'primary' }
                                      onClick={ this.assignUser.bind(this) }>
                            { t('common.Assign') }
                        </KirokuButton>
                    </KirokuModalFooter>
                </KirokuModal>
            </div>
        );
    }
}

export default TabUpload;
