import { KirokuButton, KirokuDropdownResetTitle } from "./index";
import { OptionsSizePaginations }                   from "../components/Records/NewRecords/serviceNewRecords";
import React, { Component }                         from "react";
import { withNamespaces }                           from "react-i18next";

function OptionsSizePaginationsDel(items) {
    return items.map(pagination => ({
        value    : pagination.value,
        label    : pagination.name,
        component: (<div><span className={`font-size-16 ${window.location.pathname === '/records-beta' ? 'color-dropdown-select-pagination' : 'color-text-drop'}`}>{pagination.name}</span></div>)
    }));
}

@withNamespaces()
class Paginator extends Component {
    render() {
        const { sizePagination, onPaginatorChange,
                  numberPage, t, location, onClickBack,
                  onClickNext, page, hasNext, items
        } = this.props;
        return (
            <div className={'d-flex'}>
                <KirokuButton className={'border-btn-paginator bg-white btn-back'}
                              disabled={page === 1}
                              onClick={(e) => onClickBack(e)}
                > {t('DeletedList.back')} </KirokuButton>
                <KirokuDropdownResetTitle
                    location={location || ''}
                    style={{minWidth : '70px', height: 38}}
                    title={numberPage || sizePagination[0].value}
                    items={items ? OptionsSizePaginationsDel(items) : OptionsSizePaginations(sizePagination)}
                    onSelect={event => {onPaginatorChange(event)}}
                />
                <KirokuButton className={'border-btn-paginator bg-white btn-next'}
                              onClick={(e) => onClickNext(e)}
                              disabled={!hasNext}
                > {t('DeletedList.next')} </KirokuButton>
            </div>
        )
    }
}
export default Paginator;
