import React          from 'react';
import { withSentry } from '../../services/Sentry';

@withSentry
class ErrorBoundary extends React.Component {

    componentDidCatch(error, errorInfo) {
        if (error) {
            this.props.sentry.captureException({error, errorInfo});
        }
    }

    render() {
        return this.props.children;
    }
}

export default ErrorBoundary;