import React, {Component} from 'react';
import {KirokuButton}     from "../../ui";
import {withNamespaces}   from "react-i18next";
import {connect}          from "react-redux";

@withNamespaces()
@connect(state => {
    return {
        screenName  : state.DeletedList.ComponentFlowScreen.name,
        orderByStaffs: state.DeletedStaffs.useOrderBy,
        orderBySurveys: state.DeletedSurveys.useOrderBy,
        orderByRecords: state.DeletedRecords.useOrderBy,
        orderByCustomer: state.DeletedCustomer.useOrderBy,
    }
}, dispatch => {
    return {
        changeOrderby: (field, typeOrder) => {
            dispatch({
                type: 'CHANGE_ORDER_BY',
                field,
                typeOrder
            })
        }
    }
})

class ActionDateButton extends Component {

    handleCheckScreen(screenName) {
        const {orderByStaffs, orderBySurveys, orderByCustomer, orderByRecords} = this.props;

        switch (screenName) {
            case 'staffs' :
                return orderByStaffs;
            case 'surveys' :
                return orderBySurveys;
            case 'records':
                return orderByRecords;
            case 'customers':
                return orderByCustomer;
            default:
                return ''
        }
    }

    handleClickButton(typeBtn) {
        this.props.changeOrderby(typeBtn, 'DESC');

    }

    render() {
        const {t, screenName} = this.props;
        const orderBy         = this.handleCheckScreen(screenName);
        return (
            <div className='d-flex justify-content-center'>
                <KirokuButton className={orderBy.field === 'delete_at' ? 'btn-action-active btn-del' : 'btn-action-deActive btn-del'}
                              onClick={this.handleClickButton.bind(this, 'delete_at')}
                >{t('DeletedList.deletedAtBtn')}</KirokuButton>
                <KirokuButton className={orderBy.field === 'create_at' ? 'btn-action-active btn-cre' : 'btn-action-deActive btn-cre'}
                              onClick={this.handleClickButton.bind(this, 'create_at')}>{t('DeletedList.createdAtBtn')}</KirokuButton>
            </div>
        )
    }
}

export default ActionDateButton;
