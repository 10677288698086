import React, {Component} from 'react';
import {
    KirokuCard, KirokuCardAction,
    KirokuCardList, KirokuLoading,
    KirokuPopoverItem,
} from '../../../../ui';
import {Link}                             from "react-router-dom";
import moment                             from 'moment';
import { filePathService, scrollService } from "../../../../services";
import {withNamespaces}                   from "react-i18next";
import TabResult                          from "../../../Staffs/StaffEditor/TabResult";
import KirokuCheckDraft                   from "../../../../ui/KirokuCheckDraft";
import config                             from '../../../../config';
import { connect }                        from 'react-redux';
import { getRecordForDetailPage } 		  from '../../../../actions/Record';
import $                                  from 'jquery';
class Action extends Component {

	constructor(props) {
		super(props);

		this.handleClick        = this.handleClick.bind(this);
		this.handleOutsideClick = this.handleOutsideClick.bind(this);

		this.state = {
			popupVisible: false
		};
	}

	handleClick() {
		if (!this.state.popupVisible) {
			// attach/remove event handler
			document.addEventListener('click', this.handleOutsideClick, false);
		} else {
			document.removeEventListener('click', this.handleOutsideClick, false);
		}

		this.setState(prevState => ({
			popupVisible: !prevState.popupVisible
		}));
	}

	handleOutsideClick(e) {
		if (!this.node) {
			return;
		}
		this.handleClick();
	}

	render() {
		return (
			<div className="action-customer-surveys" id='edit-record' ref={node => {
				this.node = node
			}}>
				<KirokuCardAction surveyId={this.props.surveyId}>
					<div className="action-icon" onClick={this.handleClick.bind(this)}>
						<i style={{color: '#1991eb'}} className="fas fa-ellipsis-h "/>
						{
							this.state.popupVisible &&
							(
								<div onClick={this.handleClick.bind(this)}>
									{this.props.children}
								</div>
							)
						}
					</div>
				</KirokuCardAction>
			</div>
		)
	}
}

@withNamespaces()
@connect(state => {
	return {
		records    : state.KirokuAIRecord.recordForDetail,
		loading    : state.Loading,
		currentPage: state.KirokuAIRecord.currentPage,
		hasNext    : state.KirokuAIRecord.hasNext,
	}
}, dispatch => {
	return {
		getRecordForDetailPage: (params, tags) => {
			dispatch(getRecordForDetailPage(params, tags));
		},
	};
})
class TabRecordsMobile extends Component {
	state = {
		page : 1
	}

	componentWillMount() {
		scrollService.top();
		window.addEventListener('scroll', this.listener);
	}

	componentWillUnmount() {
		scrollService.top();
		window.removeEventListener('scroll', this.listener);
	}

	listener = () => {
		if ((Math.ceil($(window).scrollTop()) >= $(document).height() - ($(window).height() + 100))
			&& !this.props.loading
			&& !this.props.hidden
			&& this.props.hasNext
		) {
			return this.props.getRecordForDetailPage({ page: this.props.currentPage + 1 , customerId: this.props.customer.id });
		}
		return null;
	};

	render () {
		const { t, records } = this.props;
		return (
			<div hidden={this.props.hidden} className='pb-5'>
				<KirokuCardList headerLeft={<span>{t('common.Records')}</span>}
								headerRight={<span>{t('common.Action')}</span>}>
					{records.length ? records.map(record =>
							<KirokuCard
								key={record.id} action={
								<Action surveyId={record.id}>
									<KirokuPopoverItem>
										<div className='d-flex justify-content-between p-1 fix-icon-align'>
											<Link to={`/records/edit-filedAll/${record.id}`}>
												<i className="fas fa-pencil-alt"/><span
												className='ml-1'>{t('common.Edit')}</span>
											</Link>
											<Link to={`/records/edit/${record.id}`}>
												<i className="fas fa-pencil-alt"/><span
												className='ml-1'>{t('common.EditRecordOldUI')}</span>
											</Link>
										</div>
									</KirokuPopoverItem>
								</Action>}
							>
								<Link to={`/records/record/${record.id}`}>{record.title}&nbsp;&nbsp;
									<KirokuCheckDraft draftable={record}/>
								</Link>
								{/*<p>{t('common.CreatedAt')}: {moment.unix(record.create_at).format('YYYY/M/D')}</p>*/}
								<p>{t('common.ServiceTime')}: {record.has_time ? moment.unix(record.serviced_at).format(config.formatDateTimeTh): moment.unix(record.serviced_at).format(config.formatDateTh)}</p>
							</KirokuCard>
						) :
						<TabResult messageFirst={'record'}>
							<img
								className='pt-2 pb-2'
								src={`${filePathService.resolveFilePath('empty-survey.svg')}`}
								alt="error img"/>
						</TabResult>}
					{ this.props.loading && records.length ? <KirokuLoading/> : null }
				</KirokuCardList>
			</div>
		)
	}

}

export default TabRecordsMobile;
