import React, {Component}  from 'react';
import { sortableHandle } from 'react-sortable-hoc';

@sortableHandle
class KirokusSortableHandle extends Component {
    render() {
        return (
            <div style={{cursor: 'move'}}>
                {this.props.children}
            </div>
        )
    }
}
export default KirokusSortableHandle;
