import React, { Component } from 'react';
import { withNamespaces }   from 'react-i18next';
import KirokuButton         from '../../../../ui/KirokuButton';
import AboutSurveyReview    from '../../../Records/ReviewRecording/AboutSurvey';

import {
    DateTime,
    InputText,
    Message,
    MediaFile,
    MultiChoice,
    YesNo,
    SingleChoice,
    DateQuestion,
    TimeQuestion,
    NumberQuestion,
}                                 from '../../../Records/ReviewRecording/QuestionTypeReview';
import { getHeadingNextQuestion } from '../../../Records/sevicesInRecord';

@withNamespaces()
class DetailSurveyWeb extends Component {

    questionsMap = {
        InputText,
        Message,
        MultiChoice,
        YesNo   : YesNo,
        Date    : DateTime,
        Single  : SingleChoice,
        Media   : MediaFile,
        OnlyDate: DateQuestion,
        Time    : TimeQuestion,
        Number  : NumberQuestion,
    };

    render() {
        const { survey, t } = this.props;
        return (
            <div className='survey default-background-color padding-responsive'>
                <div className="keeping-about-survey" style={{ top: 70, backgroundColor: '#eef3f6' }}>
                    <div className='pb-3' style={{ paddingTop: 12 }}>
                        <KirokuButton color={'light'} onClick={() => this.props.history.goBack()}>
                            <span className="text-tranf">{t('createRecord.back')}</span>
                        </KirokuButton>
                    </div>
                    <div className='fix-center pt-3 bg-white'>
                        <div style={{ width: '70%' }}>
                            <AboutSurveyReview survey={survey} shouldNotShowServicedAt/>
                        </div>
                    </div>

                </div>
                <div className="survey fix-center" style={{backgroundColor: '#FFFFFF'}}>
                    <div className="background-question-review" style={{width:'70%'}}>
                        <div className='info-question'>
                            <div className='content-name-question d-flex'>
                                        <span style={{ wordBreak: 'keep-all' }}
                                              className='title-record-info'>{t('createRecord.Description')}&nbsp;</span>
                                <pre style={{
                                    wordWrap  : 'break-word',
                                    whiteSpace: 'pre-wrap',
                                }}>
                                            {survey.description}
                                        </pre>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='fix-center pt-3 pb-3 bg-white'>
                    <div style={{ width: '70%' }}>
                        <div id='question-review' style={{ paddingBottom: '50px', ...this.props.style }}>
                            {survey.questions.map((question, index) => {
                                const Question = this.questionsMap[ question.type ];
                                index++;
                                return <Question
                                    surveyDetail
                                    disabled
                                    key={question.index}
                                    question={{ ...question, index: index }}
                                    headerNextQuestion={getHeadingNextQuestion(question, survey.questions)}
                                    value={question.answers}
                                />;
                            })}
                        </div>
                    </div>
                </div>
                <div className='text-center pt-4'>
                    <KirokuButton color={'light'} onClick={() => this.props.history.goBack()}>
                        <span className="text-tranf">{t('createRecord.back')}</span>
                    </KirokuButton>
                </div>
            </div>
        );
    }
}

export default DetailSurveyWeb;
