import React, {Component} from "react";
import Datetime           from 'react-datetime';
import 'moment/locale/ja';
import 'moment/locale/en-au';
import '../../node_modules/react-datetime/css/react-datetime.css'
import { withUserAgent }        from 'react-useragent';

class KirokuInputDate extends Component {

    render() {
        const { ua } = this.props;
        return (
            <Datetime
                dateFormat={'YYYY/MM/DD'}
                {...this.props}
                timeFormat={false}
                closeOnSelect={!ua.mobile}
                monthYearLocale={localStorage.getItem('i18nextLng')}
            />
        )
    }
}
export default withUserAgent(KirokuInputDate);
