import React, { Component } from 'react';
import { KirokuCheckBox }   from '../../../ui';
import { withNamespaces }   from 'react-i18next';

@withNamespaces()
class ComponentUnrequited extends Component {

    changeUnrequitedQuestion(questionIndex) {
        const { changeUnrequitedQuestion } = this.props;
        if (changeUnrequitedQuestion) {
            this.props.changeUnrequitedQuestion(questionIndex);
        }
    }

    render() {
        const { question, t, changeUnrequitedQuestion } = this.props;

        if (changeUnrequitedQuestion) {
            return <div className={`float-right unrequited-checkbox`}>
                <KirokuCheckBox
                    id={`un-quited-${question.index}`}
                    content={<div className={'cursor-pointer'}>{t('common.unrequited')}</div>}
                    checked={!!question[ 'unrequited' ]}
                    onChange={this.changeUnrequitedQuestion.bind(this, question.index)}
                />
            </div>;
        }
        return null;
    }
}

export default ComponentUnrequited;
