import {
    ANALYSIS_QUERY,
    RESET_VALUE_ANALYSIS,
}             from '../actions/Analysis';
import {
    InputTextChart,
    MediaChart, MessageChart,
    MultiChoiceChart, SingleChart,
    YesNoChart, DateChart,
    TimeChart, OnlyDateChart,
    NumberChart,
}             from '../components/Analysis/Charts';
import moment from 'moment';

import _      from 'lodash';
import config from '../config';

const defaultState = {
    records        : [],
    listView       : [],
    survey         : [],
    recordsByDate  : [],
    startDate      : null,
    endDate        : null,
    dataForAnalysis: [],
};

const renderQuestionChartByType = {
    InputText  : InputTextChart,
    YesNo      : YesNoChart,
    Date       : DateChart,
    Single     : SingleChart,
    MultiChoice: MultiChoiceChart,
    Media      : MediaChart,
    Message    : MessageChart,
    Time       : TimeChart,
    OnlyDate   : OnlyDateChart,
    Number     : NumberChart,
};

function getChart(keys, index) {
    return renderQuestionChartByType[ keys[ index ].type ];
}

// function getAvailableChart(record, questionIndex) {
//     return renderQuestionChartByType[record.survey_template.questions[questionIndex].type]
// }

export function groupRecordByDay(records) {
    const convertDate = records.map(record => {
        return {
            ...record,
            servicedDateRecord: moment(record.serviced_at * 1000).endOf('day').unix(),
        };
    });
    let groupDate     = _.groupBy(convertDate, 'servicedDateRecord');
    return Object.keys(groupDate).map(key => {
        const records = groupDate[ key ].map(item => {
            return {
                ...item,
                survey_template: {
                    ...item[ 'survey_template' ],
                    flowedQuestions: item[ 'survey_template' ].flowedQuestions.filter(question => config.questionsTypeAnalyst.includes(question.type)),
                },
            };
        });
        return {
            day                : moment.unix(key).format(config.formatDateYMD),
            recordOnlyQuestions: records,
            servicedAtForChart : moment.unix(key).format('YYYY/M/D'),
            customerId         : records.map(item => item.customer_id)[0],
            templateId       : records.map(item => item.survey_id)[0],
        };
    });
}

export const KirokuAIAnalysis = (state = defaultState, action) => {
    switch (action.type) {
        case ANALYSIS_QUERY:
            let keys             = [];
            let recordsQuestions = action.records.map(record => {
                return record.survey_template.flowedQuestions ? record.survey_template.flowedQuestions : record.survey_template.questions;
            });
            recordsQuestions.forEach(record => record.forEach(question => {
                keys.push({ type: question.type, content: question.content, heading: question.heading });
            }));
            keys         = _.uniqBy(keys, k => [k.content, k.type].join())
                .filter(key => config.questionsTypeAnalyst.includes(key.type));
            let listView = keys ? keys.map((question, i) => {
                return {
                    chart  : getChart(keys, i),
                    content: question.content,
                    heading: question.heading,
                    type   : question.type,
                };
            }) : [];
            /*
             const record = action.records[0];
             let chartList = record ? record.survey_template.questions.map(question => {
             return {
             chart : getAvailableChart(record , question.index),
             questionIndex : question.index,
             content : question.content,
             type : question.type,
             detail: question,
             }
             }) : [];

             let listView = chartList.filter(chart => chart.type === 'YesNo' || chart.type === 'Single' || chart.type === 'MultiChoice');
             */
            return {
                ...state,
                records        : action.records,
                recordsByDate  : groupRecordByDay(action.records),
                listView,
                survey         : action.survey,
                keys           : keys,
                startDate      : action.query.startDate,
                endDate        : action.query.endDate,
                dataForAnalysis: action.dataForAnalysis,
            };

        case RESET_VALUE_ANALYSIS:
            return {
                records : [],
                listView: [],
            };
        default:
            return state;
    }
};
