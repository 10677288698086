import React, {Component}                                                                 from 'react';
import {KirokuButton, KirokuModal, KirokuModalBody, KirokuModalFooter, KirokuModalHeader} from "../../../ui";
import DateRange                                                                          from "../../Surveys/Web/ModalDateTimeRangePicker";
import {withNamespaces}                                                                   from "react-i18next";

@withNamespaces()
class DateRangeWeb extends Component {

    handleCustomDate = (dateRange) => {
        this.props.customDate({...dateRange})
    };

    closeModal = () => {
        this.props.closeDateRange();
    };

    render() {
        const {t} = this.props;
        return (
            <KirokuModal show={this.props.show} style={{marginTop: '-180px'}}>
                <KirokuModalHeader color={'card-web'}>
                    <h5 className='m-0'>
                        {t('Surveys.CustomDate')}
                    </h5>
                </KirokuModalHeader>
                <KirokuModalBody>
                    <div className='p-1'>
                        <DateRange
                            applyDateRange={this.handleCustomDate.bind(this)}
                        />
                    </div>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                        {t('createSurvey.Cancel')}
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>
        );
    }
}

export default DateRangeWeb;
