import React, {Component}                                from 'react';
import {connect}                                         from 'react-redux';
import {loadSurveyListPage}                              from "../../../actions/Survey";
import {loadUsers}                                       from "../../../actions/User";
import KirokuButton                                      from "../../../ui/KirokuButton";
import {withNamespaces}                                  from "react-i18next";
import DateRange                                         from "./ModalDateTimeRangePicker";
import moment                                            from "moment";
import {KirokuModalFooter, KirokuModalHeader, ShortText} from "../../../ui";
import KirokuModal                                       from "../../../ui/KirokuModal";
import KirokuModalBody                                   from "../../../ui/KirokuModalBody";
import KirokuResetFilterCondition                        from "../../../ui/KirokuResetFilterCondition";
import KirokuResetFilterConditionSearch                  from "../../../ui/KirokuResetFilterConditionSearch";

@withNamespaces()
@connect(state => {
    return {
        customers: state.KirokuAICustomer.customers,
        users: state.KirokuAIUser.users,
        conditions: state.KirokuAIBehavior.conditionSurveys.conditions,
        conditionRemoved: state.KirokuAIBehavior.conditionSurveys.conditionRemoved
    }
}, dispatch => {
    return {
        loadSurveys: () => {
            dispatch(loadSurveyListPage());
        },
        loadUsers: () => {
            dispatch(loadUsers())
        },
    }
})
class ModalFilterSurveyWeb extends Component {

    state = {
        showDateRange: false,
        showFilterRecords: false,
        show: false,
        selectItem: false,
        status: '',
        nameStatus: '',
        nameDate: '',
        startDate: null,
        endDate: null,
        day: null,
        week: null,
        month: null,
        creator: '',
        page: 1,
        nameStaff: ''
    };

    dropDownItemsStaffs = (staffs) => {
        let newStaffs = staffs.map(staff => ({
            value: staff.id,
            component: (<div>{staff.name}</div>),
            label: staff.name
        }));
        let defaultSearch = [
            {
                value: '',
                component: (<div>{this.props.t('common.All')}</div>),
                label: this.props.t('common.All')
            }
        ];
        return [...defaultSearch, ...newStaffs]
    };

    closeDateRange() {
        this.setState({
            showDateRange: !this.state.showDateRange
        });
    }

    resetSelectTime() {
        this.setState({
            startDate: '',
            endDate: '',
            day: '',
            week: '',
            month: '',
        })
    }

    selectTime(event) {
        const time = event.kirokuSelected.value;
        if (event.kirokuSelected.value === 'CustomDate') {
            this.setState({nameDate: event.kirokuSelected.label === "All Date" || event.kirokuSelected.label === "全期間" ? '' : event.kirokuSelected.label});
            return this.closeDateRange();
        }
        this.resetSelectTime();
        if (time) {
            this.setState({
                [time]: moment().startOf(time).unix(),
                nameDate: event.kirokuSelected.label === "All Date" || event.kirokuSelected.label === "全期間" ? '' : event.kirokuSelected.label
            });
        }
    }

    dropDownDate = () => {
        return [
            {
                value: '',
                component: (<div>{this.props.t('Surveys.AllDate')}</div>),
                label: this.props.t('Surveys.AllDate')
            },
            {
                value: 'day',
                component: (<div>{this.props.t('Surveys.day')}</div>),
                label: this.props.t('Surveys.day')
            },
            {
                value: 'week',
                component: (<div>{this.props.t('Surveys.week')}</div>),
                label: this.props.t('Surveys.week')
            },
            {
                value: 'month',
                component: (<div>{this.props.t('Surveys.month')}</div>),
                label: this.props.t('Surveys.month')
            },
            {
                value: 'CustomDate',
                component: (<div>
                    <ShortText text={this.props.t('Surveys.CustomDate')} length={8}/>
                </div>),
                label: this.props.t('Surveys.CustomDate')
            }
        ]
    };

    filterStaff(event) {
        this.setState({
            creator: event.kirokuSelected.value,
            nameStaff: event.kirokuSelected.label
        })
    }

    handleClickApply() {
        let newParams = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            day: this.state.day,
            week: this.state.week,
            month: this.state.month,
            creator: this.state.creator,
            status: this.state.status,
            nameStaff: this.state.nameStaff
        };
        this.props.filterCondition(newParams);
        this.props.showModal();
    }

    applyDateRange(dateRange) {
        this.setState({
            ...this.state,
            ...dateRange,
            showDateRange: !this.state.showDateRange
        });
    }

    componentWillMount() {
        this.props.loadUsers();
    }

    dropDownItemStatus =
        [
            {
                value: '',
                component: (<div>{this.props.t('Surveys.All')}</div>),
                label: this.props.t('Surveys.All')
            },
            {
                value: 'draft',
                component: (<div>{this.props.t('Surveys.Draft')}</div>),
                label: this.props.t('Surveys.Draft')
            }
        ];

    handleChoiceStatus(e) {
        this.setState({
            status: e.kirokuSelected.value,
            nameStatus: e.kirokuSelected.label
        })
    }

    closeModal() {
        this.setState({
                showDateRange: !this.state.showDateRange
            }
        )
    }

    handleCloseModal() {
        this.props.showModal();
    }

    handleCancelClick() {
        this.props.showModal();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.conditionRemoved !== this.props.conditionRemoved) {
            this.setState({
                ...nextProps.conditionRemoved
            })
        }
    }

    render() {
        const staffs = this.props.users;
        let {t, show} = this.props;
        return (
            <div hidden={show} className='padding-responsive'>
                <div className={'view-1vh'}>
                    <div className='survey-list'>
                        <div className='p-3'>
                            <button className='close' style={{float: 'left'}}
                                    onClick={this.handleCloseModal.bind(this)}>
                                <span style={{fontSize: '24px', color: '#a8aab7'}}>&times;</span>
                            </button>
                            <div className='text-center'>
                                <span className='filter-record'>{t('Surveys.FilterBy')}</span>
                            </div>
                        </div>
                    </div>
                    <div className='p-2 mt-4 filter-modal'>
                        <div className='pl-1 pr-1 pb-3 mt-10'>
                            <KirokuResetFilterCondition
                                title={this.state.nameDate}
                                defaultValue={t('Surveys.AllDate')}
                                items={this.dropDownDate()}
                                icon={'far fa-calendar-alt text-secondary'}
                                onSelect={this.selectTime.bind(this)}
                            />
                        </div>
                        {/*<div className='pl-1 pr-1 pb-3 mt-10'>*/}
                            {/*<KirokuResetFilterCondition*/}
                                {/*title={this.state.nameStatus}*/}
                                {/*defaultValue={t('Surveys.All')}*/}
                                {/*items={this.dropDownItemStatus}*/}
                                {/*icon={'far fa-file-alt text-secondary'}*/}
                                {/*onSelect={this.handleChoiceStatus.bind(this)}*/}
                            {/*/>*/}
                        {/*</div>*/}
                        <div className='pl-1 pr-1 pb-3 mt-10'>
                            <KirokuResetFilterConditionSearch
                                title={this.state.nameStaff}
                                defaultValue={t('Surveys.AllStaff')}
                                filterItems={this.filterStaff.bind(this)}
                                items={this.dropDownItemsStaffs(staffs)}
                                icon={'far fa-user text-secondary'}
                                onSelect={this.filterStaff.bind(this)}
                            />
                        </div>
                    </div>
                    <div>
                        <div className='fix-center'>
                            <div className='d-flex justify-content-between'>
                                <KirokuButton color={'white'}
                                              onClick={this.handleCancelClick.bind(this)}
                                >
                                    {t('RecordList.cancel')}
                                </KirokuButton>
                                <div className={'pr-1 pl-1'}/>
                                <KirokuButton color={'primary'}
                                              onClick={this.handleClickApply.bind(this)}>{t('common.Apply')}</KirokuButton>
                            </div>
                        </div>
                    </div>
                </div>
                <KirokuModal show={this.state.showDateRange} style={{marginTop: '-180px'}}>
                    <KirokuModalHeader color={'card-web'}>
                        <h5 className='m-0'>
                            {t('Surveys.CustomDate')}
                        </h5>
                    </KirokuModalHeader>
                    <KirokuModalBody>
                        <div className='p-1'>
                            <DateRange
                                applyDateRange={this.applyDateRange.bind(this)}
                            />
                        </div>
                    </KirokuModalBody>
                    <KirokuModalFooter>
                        <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                            {t('createSurvey.Cancel')}
                        </KirokuButton>
                    </KirokuModalFooter>
                </KirokuModal>
            </div>

        );
    }
}

export default ModalFilterSurveyWeb;
