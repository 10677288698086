import React, { Component }                                             from 'react';
import { KirokuButton, KirokuDateRangePicker, KirokuInputSearchOnBlur } from '../../../../ui';
import { connect }                                                      from 'react-redux';
import { ModalSelectCustomers, ModalSelectStaffs, ModalSelectSurveys }  from './ModalSelect';
import { withNamespaces }                                               from 'react-i18next';

@withNamespaces()
@connect(state => {
    return {
        keywords                  : state.RecordsBeta.keywords,
        statusRecord              : state.RecordsBeta.statusRecord,
        typeSearch                : state.RecordsBeta.typeSearch,
        servicedAt                : state.RecordsBeta.servicedAt,
        updatedAt                 : state.RecordsBeta.updatedAt,
        templateIds               : state.RecordsBeta.templateIds,
        isOpenModalSelectCustomers: state.KirokuModal.ModalSelectCustomers.isOpenModalSelectCustomers,
        staffListSelected         : state.KirokuModal.ModalSelectStaffs.staffListSelected,
        customerIds               : state.RecordsBeta.customerIds,
        typeSearchRead            : state.RecordsBeta.typeSearchRead
    };
}, dispatch => {
    return {
        onChangeKeyWords                : (keywords) => {
            dispatch({
                type: 'CHANGE_KEYWORD',
                keywords,
            });
        },
        submitChangeKeyword             : () => {
            dispatch({
                type: 'CHANGE_KEYWORD_SUBMIT',
            });
        },
        changeStatusRecords             : (statusRecord) => {
            dispatch({
                type: 'CHANGE_STATUS_RECORDED',
                statusRecord,
            });
        },
        changeTypeSearch                : (typeSearch) => {
            dispatch({
                type: 'CHANGE_TYPE_SEARCH',
                typeSearch,
            });
        },
        changeIsOpenModalSelectCustomers: () => {
            dispatch({
                type: 'CHANGE_IS_OPEN_MODAL_SELECT_CUSTOMERS',
            });
        },
        changeIsOpenModalSelectStaffs   : () => {
            dispatch({
                type: 'CHANGE_IS_OPEN_MODAL_SELECT_STAFFS',
            });
        },
        changeIsOpenModalSelectSurveys  : () => {
            dispatch({
                type: 'CHANGE_IS_OPEN_MODAL_SELECT_SURVEYS',
            });
        },
        changeServiceAtRecordBeta       : (dateRange) => {
            dispatch({
                type: 'CHANGE_SERVICE_AT_RECORD_BETA',
                dateRange,
            });
        },
        changeUpdateAtRecordBeta        : (dateRange) => {
            dispatch({
                type: 'CHANGE_UPDATE_AT_RECORD_BETA',
                dateRange,
            });
        },
        changeTypeSearchRecordUnRead: (typeSearchRead) => {
            dispatch({
                type : 'CHANGE_TYPE_SEARCH_READ_UNREAD',
                typeSearchRead
            })
        }
    };
})
class ConditionRecordsBeta extends Component {

    handleChangeKeyword(e) {
        this.props.onChangeKeyWords(e.target.value);
    }

    changeStatusRecords(statusRecord) {
        this.props.changeStatusRecords(statusRecord);
    }

    changeTypeSearch(typeSearch) {
        this.props.changeTypeSearch(typeSearch);
    }

    handleOnChangeDateRangerServiceAt(dateRange) {
        this.props.changeServiceAtRecordBeta(dateRange);
    }

    handleOnChangeDateRangerUpdatedAt(dateRange) {
        this.props.changeUpdateAtRecordBeta(dateRange);
    }

    render() {
        const {
                  keywords, statusRecord, typeSearch,
                  changeIsOpenModalSelectSurveys,
                  changeIsOpenModalSelectStaffs,
                  changeIsOpenModalSelectCustomers, t,
                  isOpenModalSelectCustomers, servicedAt,
                  updatedAt, templateIds,
                  staffListSelected, typeSearchRead
                  // customerIds
              } = this.props;
        return (
            <div>
                <div className={'row'}>
                    <div className='col-md-6 pt-2'>
                        <div className="d-flex justify-content-around border-btn-status-group cursor-pointer">
                            <div className={`border-width-each-status first-btn font-text-on-btn 
                                  ${statusRecord === 'recorded' ? 'btn-active' : ''}`}
                                 onClick={() => this.changeStatusRecords('recorded')}
                            >
                                {t('RecordsBeta.recorded')}
                            </div>
                            <div className={`border-width-each-status-center font-text-on-btn
                                ${statusRecord === 'draft' ? 'btn-active' : ''}`}
                                 onClick={() => this.changeStatusRecords('draft')}
                            >
                                {t('RecordsBeta.draft')}
                            </div>
                            <div className={`border-width-each-status third-btn font-text-on-btn
                                ${statusRecord === 'all' ? 'btn-active' : ''}`}
                                 onClick={() => this.changeStatusRecords('all')}
                            >
                                {t('RecordsBeta.all')}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="p-0 pt-2">
                            <div className="d-flex justify-content-around border-btn-status-group cursor-pointer">
                                <div className={`border-width-each-status first-btn font-text-on-btn 
                                    ${(typeSearch === 'SEARCH_BY_CUSTOMER_TAG' && !isOpenModalSelectCustomers) ? 'btn-active' : ''}`}
                                     onClick={() => this.changeTypeSearch('SEARCH_BY_CUSTOMER_TAG')}
                                >
                                    {t('RecordsBeta.customerTagByUser')}
                                </div>
                                <div className={`border-width-each-status-center font-text-on-btn
                                     ${(typeSearch === 'SEARCH_BY_TEAM_TAG' && !isOpenModalSelectCustomers) ? 'btn-active' : ''}`}
                                     onClick={() => this.changeTypeSearch('SEARCH_BY_TEAM_TAG')}
                                >
                                    {t('RecordsBeta.team')}

                                </div>
                                <div className={`border-width-each-status third-btn font-text-on-btn
                                     ${(typeSearch === 'SEARCH_CUSTOM_CUSTOMER' || isOpenModalSelectCustomers) ? 'btn-active' : ''}`}
                                     onClick={() => {
                                         changeIsOpenModalSelectCustomers();
                                     }}
                                >
                                    {t('RecordsBeta.choice')}
                                </div>
                            </div>
                            {/*<div className="col-4 text-customer-selected">*/}
                                {/*<div hidden={customerIds.length === 0}>*/}
                                {/*    {t('RecordsBeta.isSelected', {count : customerIds.length})}*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
                <div className="pt-2 d-none d-lg-block d-xl-block">
                    <div className="row">
                    <div className='col-lg-7 col-xl-6'>
                        <div className={'pt-2 pb-2'}>
                            <div className="row">
                                <div className="col-lg-4 pr-0">
                                    <div className={'pt-8 title-date-time'}>{t('RecordsBeta.servicedAt')}</div>
                                </div>
                                <div className="col-lg-8">
                                    <div className={'fix-height-date-range'}>
                                        <div className="date-time-range custom-clear-date">
                                            <KirokuDateRangePicker
                                                minimumNights={0}
                                                startDate={servicedAt.startDate}
                                                endDate={servicedAt.endDate}
                                                onChangeDate={this.handleOnChangeDateRangerServiceAt.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='pt-3'>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className={'pt-8 title-date-time'} style={{minWidth: 98}}>{t('RecordsBeta.updatedAt')}</div>
                                </div>
                                <div className="col-lg-8">
                                    <div className={'fix-height-date-range'}>
                                        <div className="date-time-range custom-clear-date">
                                            <KirokuDateRangePicker
                                                minimumNights={0}
                                                startDate={updatedAt.startDate}
                                                endDate={updatedAt.endDate}
                                                onChangeDate={this.handleOnChangeDateRangerUpdatedAt.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-5 col-xl-6' style={{paddingTop: 8}}>
                        <div className="d-flex justify-content-around border-btn-status-group cursor-pointer">
                            <div className={`border-width-each-status font-text-on-btn first-btn
                                    ${typeSearchRead === 'UNREAD' ? 'btn-active' : ''}`} onClick={() => this.props.changeTypeSearchRecordUnRead('UNREAD')}
                            >
                                {t('RecordsBeta.unRead')}
                            </div>
                            <div/>
                            <div className={`border-width-each-status font-text-on-btn third-btn
                                     ${typeSearchRead === 'ALL' ? 'btn-active' : ''}`} onClick={() => this.props.changeTypeSearchRecordUnRead('ALL')}
                            >
                                {t('RecordsBeta.all')}
                            </div>
                        </div>
                        <div className={'btn-group-select d-flex p-0 pt-3'}>
                            <div className="pl-0">
                                <KirokuButton className={`${staffListSelected.length ? 'btn-active' : 'btn-deActive'}`}
                                              onClick={() => changeIsOpenModalSelectStaffs()}
                                              style={{ height: 42 }}
                                >{t('RecordsBeta.selectUpdater')}</KirokuButton>
                                {/*    <span className={'text-span-selected pl-2 pr-2'} hidden={ staffListSelected.length === 0}>*/}
                                {/*{ t('RecordsBeta.isSelected', { count: staffListSelected.length })}</span>*/}
                            </div>
                            <div className="pl-3">
                                <KirokuButton className={`${templateIds.length ? 'btn-active' : 'btn-deActive'}`}
                                              onClick={() => changeIsOpenModalSelectSurveys()}
                                              style={{ height: 42 }}
                                >{t('RecordsBeta.selectTemplate')}</KirokuButton>
                                {/*<span hidden={ templateIds.length === 0} className={'text-span-selected pl-2'}>*/}
                                {/*    { t('RecordsBeta.isSelected', { count: templateIds.length })}*/}
                                {/*</span>*/}
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="row pt-3">
                        <div className='col-lg-7 col-xl-6'>
                            <div className={'fix-icon-search placeholder-beta-color-757575'}>
                                <KirokuInputSearchOnBlur
                                    style={{ height: 42, fontSize: 16 }}
                                    placeholder={t('RecordsBeta.keyword')}
                                    value={keywords}
                                    onSubmit={() => {
                                        this.props.submitChangeKeyword();
                                    }}
                                    onBlur={() => {
                                        this.props.submitChangeKeyword();
                                    }}
                                    onChange={this.handleChangeKeyword.bind(this)}
                                    id={'inputSearchRecordsBeta-1'}
                                />
                            </div>
                        </div>
                        <div className='col-lg-5 col-xl-6'/>
                    </div>
                </div>
                {/*for md screen   */}
                <div className=" pt-2  d-none d-md-block d-lg-none">
                    <div className='row'>
                        <div className="col-md-6">
                            <div className="">
                                <div className={'pt-8 title-date-time'}>{t('RecordsBeta.servicedAt')}</div>
                            </div>
                            <div className="">
                                <div className={'fix-height-date-range'}>
                                    <div className="date-time-range custom-clear-date">
                                        <KirokuDateRangePicker
                                            minimumNights={0}
                                            startDate={servicedAt.startDate}
                                            endDate={servicedAt.endDate}
                                            onChangeDate={this.handleOnChangeDateRangerServiceAt.bind(this)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="">
                                <div className={'pt-8 title-date-time'} style={{minWidth: 98}}>{t('RecordsBeta.updatedAt')}</div>
                            </div>
                            <div className="">
                                <div className={'fix-height-date-range'}>
                                    <div className="date-time-range custom-clear-date">
                                        <KirokuDateRangePicker
                                            minimumNights={0}
                                            startDate={updatedAt.startDate}
                                            endDate={updatedAt.endDate}
                                            onChangeDate={this.handleOnChangeDateRangerUpdatedAt.bind(this)}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='row pt-3'>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-around border-btn-status-group cursor-pointer p-0 pt-2">
                                <div className={`border-width-each-status first-btn font-text-on-btn 
                                    ${typeSearchRead === 'UNREAD' ? 'btn-active' : ''}`} onClick={() => this.props.changeTypeSearchRecordUnRead('UNREAD')}
                                >
                                    {t('RecordsBeta.unRead')}
                                </div>
                                <div className={`border-width-each-status font-text-on-btn third-btn 
                                     ${typeSearchRead === 'ALL' ? 'btn-active' : ''}`} onClick={() => this.props.changeTypeSearchRecordUnRead('ALL')}
                                >
                                    {t('RecordsBeta.all')}
                                </div>
                                <div/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={'btn-group-select d-flex p-0 pt-2'}>
                                <div className="pl-0">
                                    <KirokuButton className={`${staffListSelected.length ? 'btn-active' : 'btn-deActive'}`}
                                                  onClick={() => changeIsOpenModalSelectStaffs()}
                                                  style={{ height: 42 }}
                                    >{t('RecordsBeta.selectUpdater')}</KirokuButton>
                                    {/*    <span className={'text-span-selected pl-2 pr-2'} hidden={ staffListSelected.length === 0}>*/}
                                    {/*{ t('RecordsBeta.isSelected', { count: staffListSelected.length })}</span>*/}
                                </div>
                                <div className="pl-3">
                                    <KirokuButton className={`${templateIds.length ? 'btn-active' : 'btn-deActive'}`}
                                                  onClick={() => changeIsOpenModalSelectSurveys()}
                                                  style={{ height: 42 }}
                                    >{t('RecordsBeta.selectTemplate')}</KirokuButton>
                                    {/*<span hidden={ templateIds.length === 0} className={'text-span-selected pl-2'}>*/}
                                    {/*    { t('RecordsBeta.isSelected', { count: templateIds.length })}*/}
                                    {/*</span>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-6'>
                            <div className={'fix-icon-search placeholder-beta-color-757575 p-0 pt-3'}>
                                <KirokuInputSearchOnBlur
                                    style={{ height: 42, fontSize: 16 }}
                                    placeholder={t('RecordsBeta.keyword')}
                                    value={keywords}
                                    onSubmit={() => {
                                        this.props.submitChangeKeyword();
                                    }}
                                    onBlur={() => {
                                        this.props.submitChangeKeyword();
                                    }}
                                    onChange={this.handleChangeKeyword.bind(this)}
                                    id={'inputSearchRecordsBeta'}
                                />
                            </div>
                        </div>
                    <div className='col-md-6'/>
                </div>
                </div>

                <ModalSelectCustomers/>
                <ModalSelectStaffs/>
                <ModalSelectSurveys/>
            </div>
        );
    }
}

export default ConditionRecordsBeta;
