import React, {Component}               from 'react';
import moment                           from "moment";
import {connect}                        from "react-redux";
import {loadCustomerFromDraft}          from "../../../actions/Customer";
import ComponentCustomerEditorMobile
                                        from "../CustomerEditor/ComponentCustomerEditorMobile";
import ComponentCustomerEditorWeb
                                        from "../CustomerEditor/ComponentCustomerEditorWeb";
import {CheckDivide, KirokuLoading}     from "../../../ui";
import {customerService, scrollService} from "../../../services";
import KirokuTitlepage                  from "../../../ui/KirokuTitlepage";
import {currentPage}                    from "../../../actions/CurrentPage";
import {withNamespaces}                 from "react-i18next";
import {loadUsers}                      from "../../../actions/User";
import {loadTeam}                       from "../../../actions/Team";
import {NOTIFICATION}                   from "../../../actions/Notification";

@withNamespaces()
@connect((state) => ({
    surveys: state.KirokuAISurvey.surveys,
    draftCustomer: state.KirokuAICustomer.draftCustomer,
    isDraftLoading: state.KirokuAICustomer.isDraftLoading,
    allTeam: state.KirokuAITeam.allTeam,
    users: state.KirokuAIUser.users,
    loadingUserSuccess: state.KirokuAIUser.loadingUserSuccess,
    isLoading: state.KirokuAITeam.isLoading,
}), (dispatch) => ({
    notification: () => {
        dispatch({
            type: NOTIFICATION,
            typeNotification: 'success',
            message: 'message.CreateSuccess'
        })
    },
    currentPageUsed: (name) => {
        dispatch(currentPage(name))
    },
    loadDraft: (typeDraft, customerId) => {
        dispatch(loadCustomerFromDraft(typeDraft, customerId))
    },
    loadUsers: () => {
        dispatch(loadUsers())
    },
    loadTeams: () => {
        dispatch(loadTeam())
    },
    trackingCreateCustomer: (customer) => {
        dispatch({
            type: "CUSTOMER_CREATED",
            customer,
        })
    }
}))
class CustomerCreator extends Component {
    page = {
        title: <KirokuTitlepage title={this.props.t('common.Customer')}/>,
    };

    state = {
        errors: {
            name: true,
            certification_number: false,
            dateOfBirth: false
        },
        defaultCustomer: {
            id: null,
            active: "",
            address: "",
            avatar: "",
            blood_type: "Unknown",
            certificate: [],
            // certification_end_date: moment(new Date()).unix(),
            certification_number: "",
            // certification_start_date: moment(new Date()).unix(),
            characteristic: "",
            classification: 0,
            contract_end: null,
            contract_start: moment(new Date()).unix(),
            create_at: null,
            date_of_birth: '',
            delete_at: null,
            // emergency_contact: "",
            name: "",
            // phone_number: "",
            staffs: [],
            surveys       : [],
            teams         : [],
            team          : {},
            team_id       : null,
            team_name     : '',
            currentStaffs : [],
            tags          : [],
            gender        : 'unselected',
            certifications: [{
                certification_number: '',
                issuer: '',
                services: [],
                monitoring_terms: []
            }],
            relationship  : 'unknown',
            contacts      : [{name: '', phone_number: '', memo: '', relationship: 'unknown'}],
            post_code     : ''
        }

    };

    handleCreator(customer) {
        return customerService.create(customer).then(customerResult => {
            if (customerResult.id) {
                this.props.trackingCreateCustomer(customerResult);
                this.props.history.push(`/customers/customer-detail/${customerResult.id}`);
                this.props.notification()
            } else {
                this.props.history.push(`/customers`)
            }
        });
    }

    componentWillMount() {
        scrollService.top();
        this.props.loadTeams();
        this.props.loadUsers();
        this.props.currentPageUsed(this.page);
    }

    render() {
        let mobile = <ComponentCustomerEditorMobile
            handleSubmit={this.handleCreator.bind(this)}
            history={this.props.history}
            users={this.props.users}
            teams={this.props.allTeam}
            customer={this.state.defaultCustomer}
            errors={this.state.errors}
            surveys={this.props.surveys}
        />;
        let web    = <ComponentCustomerEditorWeb
            history={this.props.history}
            users={this.props.users}
            teams={this.props.allTeam}
            handleSubmit={this.handleCreator.bind(this)}
            customer={this.state.defaultCustomer}
            errors={this.state.errors}
            surveys={this.props.surveys}
        />;
        return ((this.props.isLoading && this.props.loadingUserSuccess) ?
                <div className='customer-detail default-background-color'>
                    <CheckDivide mobile={mobile} web={web}/>
                </div> : <KirokuLoading/>
        )
    }
}
export default CustomerCreator;
