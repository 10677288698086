import React, { Component }          from 'react';
import { KirokuButton, KirokuRadio, KirokuButtonLink } from '../../../../ui';
import { withNamespaces }            from 'react-i18next';
import { checkUseHeadingQuestion }   from '../../../Surveys/SurveyEditor/SurveyService';
import { withSmoothScrolling }       from '../../../../ui/Scrolling.js';

@withSmoothScrolling
@withNamespaces()
class YesNoQuestion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value      : this.props.value,
            hideNextBtn: true,
            validated  : null,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                value: nextProps.value,
            });
        }
    }


    handleOnChange(e) {
        if(!e) {
            e = { target: {value: ''}};
        }

        if (!this.props.disabled) {
            this.setState({
                value      : e.target.value,
                validated  : e.target.value ? e.target.value : null,
                hideNextBtn: false,
            });
            this.props.onAnswer({
                answer   : e.target.value,
                validated: e.target.value ? e.target.value : null,
                questionIndex: this.props.question.index
            });
            this.props.isCurrentQuestion(this.props.question.index);
        }
    }

    checkNextQuestionTo = (question) => {
        if (typeof question.nextQuestionYes === 'number' && this.props.value === 'Yes') {
            return question.nextQuestionYes - 1;
        } else if (typeof question.nextQuestionNo === 'number' && this.props.value === 'No') {
            return question.nextQuestionNo - 1;
        } else if (typeof question.nextQuestion === 'number') {
            return question.nextQuestion - 1;
        }
        return question.index;
    };

    handleMoveNextQuestion() {
        const { question, smoothScroll } = this.props;
        let isNextQuestion = this.checkNextQuestionTo(question);
        smoothScroll.scrollTo(`question-${isNextQuestion }`);
        this.props.isCurrentQuestion(isNextQuestion + 1);

    }

    render() {
        const { question, t, disabled, isEndQuestion, currentQuestion } = this.props;
        const contentQuestion                                           = checkUseHeadingQuestion(question);

        return (
                <div id='component-question'>
                    <div className='content-question'>
                        <div className='wraper-question'>
                            <div className={disabled ? 'bg-disable-question number-question' :
                                (this.state.value ? 'bg-question-has-answer d-flex justify-content-between number-question'
                                    : (!question.unrequited ? 'bg-question-required-answer d-flex justify-content-between' : 'd-flex justify-content-between number-question'))}
                                 style={ { padding : 8 } }
                            >
                                <div className={`text-overflow-elip ${ disabled ? 'question-number' 
                                    :this.state.value ? 'font-text-question-has-answer'
                                        : (!question.unrequited ? 'text-question-required-answer' : 'question-number')}`}>
                                    {question.unrequited ? t('createRecord.option'):''}{ contentQuestion }
                                </div>
                                <div/>
                                <div hidden={disabled}>
                                    <KirokuButtonLink
                                        className='padding-reset-button-link'
                                        onClick={() => {this.handleOnChange()}}>
                                        {t('createRecord.resetAnswer')}
                                    </KirokuButtonLink>
                                </div>
                            </div>
                            <span className='content-name-question' style={ { opacity: disabled ? 0.3 : 1 } }>
                            { question.content }
                        </span>
                            <div className='answer-question'
                                 style={ { padding: 0, marginTop: '20px', opacity: disabled ? 0.3 : 1 } }>
                                <div className="yes-check">
                                    <KirokuRadio onChange={ this.handleOnChange.bind(this) }
                                                 disabled={ disabled }
                                                 id={ `${ question.index }yes` } value={ 'Yes' }
                                                 name={ `questionYesNo${ question.index }` }
                                                 checked={ this.state.value === 'Yes' }
                                                 style={ { width: '100%' } }
                                    >
                                        { t('createRecord.Yes') }
                                    </KirokuRadio>
                                </div>
                                <div className="no-check">
                                    <KirokuRadio onChange={ this.handleOnChange.bind(this) }
                                                 disabled={ disabled }
                                                 id={ `${ question.index }no` } value={ 'No' }
                                                 name={ `questionYesNo${ question.index }` }
                                                 checked={ this.state.value === 'No' }
                                                 style={ { width: '100%' } }
                                    >
                                        { t('createRecord.No') }
                                    </KirokuRadio>
                                </div>
                                <div>
                                    <div className='p-2'
                                         hidden={ disabled || isEndQuestion || question.index !== currentQuestion }>
                                        <KirokuButton className="btn-kiroku-primary"
                                                      onClick={ this.handleMoveNextQuestion.bind(this) }>
                                            { this.props.t('common.nextToQuestion') }
                                        </KirokuButton>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
        );
    }
}

export default YesNoQuestion;
