import React, {Component} from 'react';
import {LineChart as D3LineChart} from "react-d3-components";
import {buildDataDate} from "../buildData";

class DateChart extends Component {

    render() {
        const {records ,questionIndex} = this.props;
        const data = buildDataDate(records, questionIndex);
        return (
            <div>
                <D3LineChart
                    data={data}
                    width={400}
                    height={400}
                    margin={{top: 10, bottom: 50, left: 50, right: 10}}/>
            </div>
        )

    }
}

export default DateChart;
