import React, {Component} from 'react';
import KirokuCardWeb from '../../ui/KirokuCardWeb';
import KirokuDropdownClose from '../../ui/KirokuDropdownClose';
import connect from 'react-redux/es/connect/connect';
import {KirokuButton} from '../../ui';
import {searchRecord, setConditionSearch} from '../../actions/AdminExport';
import {withNamespaces} from 'react-i18next';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import _ from 'lodash';
import KirokuDateRangePicker from "../../ui/KirokuDateRangePicker";

moment.locale(localStorage.getItem('i18nextLng'));

@withNamespaces()
@connect(state => {
    return {
        listAllCustomer: state.KirokuAICustomer.ListAllCustomer,
        loadingSearch: state.ReducerAdminExport.loadingSearch
    }
}, dispatch => {
    return {
        searchRecord: (condition) => {
            dispatch(searchRecord(condition))
        },
        setConditionSearch: (condition) => {
            dispatch(setConditionSearch(condition))
        }
    }
})
class ConditionSearchExport extends Component {

    state = {
        customerId: null,
        startDate_created: null,
        endDate_created: null,
        startDate_serviced: null,
        endDate_serviced: null,
        focusedInput: null,
        momentStartDate: null,
        momentEndDate: null,
        statusBtnSearch: true
    };

    handlerOnSelectCustomer(e) {
        this.setState({
            customerId: e.kirokuSelected.value,
            statusBtnSearch: !e.kirokuSelected.value
        });
    }


    handleSearchRecord() {
        const customer = this.props.listAllCustomer[_.findIndex(this.props.listAllCustomer, ['id', this.state.customerId])];
        this.props.setConditionSearch({
            customer: customer,
            startDate_created: this.state.startDate_created,
            endDate_created: this.state.endDate_created,
            startDate_serviced: this.state.startDate_serviced,
            endDate_serviced: this.state.endDate_serviced
        });
        this.props.searchRecord({
            customerId: this.state.customerId,
            startDate_created: this.state.startDate_created,
            endDate_created: this.state.endDate_created,
            startDate_serviced: this.state.startDate_serviced,
            endDate_serviced: this.state.endDate_serviced
        });


    }

    onChangeCreatedAt(date) {
        console.log('created_at');
        this.setState({
            startDate_created: date.momentStartDate,
            endDate_created: date.momentEndDate
        })
    }
    onChangeServicedAt(date) {
        this.setState({
            startDate_serviced: date.momentStartDate,
            endDate_serviced: date.momentEndDate
        })
    }

    render() {
        // console.log(this.state.startDate_created,
        //     this.state.endDate_created,
        //     this.state.startDate_serviced,
        //     this.state.endDate_serviced);
        const {listAllCustomer, t, loadingSearch} = this.props;
        const {statusBtnSearch} = this.state;
        return (
            <KirokuCardWeb>
                <div className="padding-responsive exports">
                    <div className='d-flex justify-content-between mt-1 pb-2'>
                        <h5 className='mt-1'>{t('Export.Export')}</h5>
                    </div>
                    <div className='pb-2'>{t('Export.SelectCustomer')}</div>
                    <div style={{width: 286}}>
                        <KirokuDropdownClose
                            title={t('Export.SelectCustomer')}
                            items={listAllCustomer.map(customer => {
                                return {
                                    value: customer.id,
                                    component: (<div><h6>{customer.name}</h6></div>),
                                    label: customer.name
                                }
                            })}
                            onSelect={this.handlerOnSelectCustomer.bind(this)}
                        />
                    </div>

                    <div className='pt-3 pb-2'>{t('Export.SelectCreatedAt')}</div>

                    <div className="date-time-range">
                        <KirokuDateRangePicker
                            onChangeDate={this.onChangeCreatedAt.bind(this)}
                        />
                    </div>

                    <div className='pt-3 pb-2'>{t('Export.SelectServicedAt')}</div>

                    <div className="date-time-range">
                        <KirokuDateRangePicker
                            onChangeDate={this.onChangeServicedAt.bind(this)}
                        />
                    </div>

                    <div className='pt-3'>
                        <KirokuButton
                            disabled={statusBtnSearch || loadingSearch}
                            color={'primary'}
                            onClick={this.handleSearchRecord.bind(this)}
                            style={{width: 86, height: 36}}
                        >
                            {t('Export.Search')}
                        </KirokuButton>

                    </div>
                </div>
            </KirokuCardWeb>
        );
    }
}

export default ConditionSearchExport;
