import React, { Component }    from 'react';
import { KirokuInputSearchOnBlur } from "../../../../ui";
import { connect }             from "react-redux";
import {withNamespaces}        from "react-i18next";

@withNamespaces()
@connect(state => {
    return {
        keyword      : state.DeletedStaffs.keyword,
    }
}, dispatch => {
    return {
        changeKeywordInput: (value) => {
            dispatch({
                type   : 'CHANGE_SEARCH_KEYWORD',
                keyword: value
            })
        },
        setValueKeywordSearchStaff: () => {
            dispatch({
                type   : 'SUBMIT_SEARCH_KEYWORD',
            })
        },
    }
})
class ConditionStaffs extends Component {

    handlerSubmitSearch() {
        this.props.setValueKeywordSearchStaff();
    }

    handleSearch(e) {
        this.props.changeKeywordInput(e.target.value)
    }

    render() {
        const {t, keyword } = this.props;
        return (
            <div className={'font-size-condition'}>
                <KirokuInputSearchOnBlur
                    value={keyword}
                    onSubmit={this.handlerSubmitSearch.bind(this)}
                    onBlur={this.handlerSubmitSearch.bind(this)}
                    onChange={this.handleSearch.bind(this)}
                    placeholder={t('DeletedList.searchByName')}
                    id={'inputSearchStaffsDeleted'}
                />
            </div>
        )
    }
}

export default ConditionStaffs
