import {Component} from "react";
import React from "react";

export default class KirokuDropdownItem extends Component {
    render() {
        return (
            <div className='dropdown-item-kiroku' style={{overflowX: 'hidden'}} {...this.props} >
                {this.props.children}
            </div>
        )
    }
}