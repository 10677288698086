import React, {Component} from 'react';
import KirokuModalBody    from "../../ui/KirokuModalBody";
import KirokuModalFooter  from "../../ui/KirokuModalFooter";
import KirokuButton       from "../../ui/KirokuButton";
import KirokuModal        from "../../ui/KirokuModal";
import {connect}          from "react-redux";
import {deactiveCustomer} from "../../actions/Customer";
import {withNamespaces}   from "react-i18next";

@withNamespaces()
@connect(() => ({}), (dispatch) => {
    return {
        deactiveCustomers: (customerIds) => {
            dispatch(deactiveCustomer(customerIds))
        }
    }
})

class ModalOpenNewTab extends Component {

    closeModal() {
        this.props.onCloseModalPreviewMobile()
    }

    previewFile() {
        this.props.onCloseModalPreviewMobile()
    }

    render() {
        const {t} = this.props;
        return (
            <KirokuModal show={this.props.show} onClick={this.closeModal.bind(this)}>
                <KirokuModalBody>
                    <div className='text-center'>
                        <p className={'pt-3'}>
                            {t('common.notifyOpenModalNewTab1')}
                        </p>
                        <p>
                            {t('common.notifyOpenModalNewTab2')}
                        </p>
                    </div>

                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                        {t('common.Cancel')}
                    </KirokuButton>
                    <KirokuButton onClick={this.previewFile.bind(this)} color={'primary'}>
                        <a style={{color: 'white'}} href={`${this.props.response.path}`}
                           rel="noopener noreferrer"
                           target='_blank'>{t('common.Confirm')}</a>
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>
        )
    }
}

export default ModalOpenNewTab;