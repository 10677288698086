import moment from "../components/Records/RecordList/FilterRecordsWeb";
import config from '../config';

class RecordBuilderCondition {

    makeParams = (rawParams) => {
        return {
            customerId: rawParams.customerId,
            templateId: rawParams.templateId,
            staffId: rawParams.staffId,
            title: rawParams.title,
            startDate: rawParams.startDate,
            endDate: rawParams.endDate,
            day: rawParams.day,
            week: rawParams.week,
            month: rawParams.month,
            page: rawParams.page,
            status: rawParams.status,
            pageSize: rawParams.pageSize
        }
    };

    makeParamsFromCondition = (conditions) => {
        let params = {};
        conditions.map(condition => {
            params[condition.query] = condition.value;
            return condition;
        });
        return params;
    };

    makeParamsRemoved = (removeConditions) => {
        let params = {};
        removeConditions.map(condition => {
            if (condition.query === 'status') {
                params['statusRecord'] = '';
            }
            if (condition.query === 'customerId') {
                params['customerName'] = '';
            }
            if (condition.query === 'templateId') {
                params['titleSurvey'] = '';
            }
            if (condition.query === 'staffId') {
                params['staffName'] = '';
            }
            if (condition.query === 'startDate' || condition.query === 'endDate') {
                params['nameStatus'] = '';
            }
            if (condition.query === 'dateRange') {
                params['dateRange'] = {
                    startDate: null,
                    endDate: null
                };
            }
            if (condition.query === 'month' || condition.query === 'day' || condition.query === 'week') {
                params['nameStatus'] = '';
            }
            return condition;
        });
        removeConditions.map(condition => {
            params[condition.query] = '';
            return condition;
        });
        return params;
    };

    makeConditions = (state, params, t) => {
        return Object.keys(params).map(key => (
            {[key]: params[key], query: key, value: params[key], label: this.makeLabelCondition(state, key, t)}
        ));
    };

    makeLabelCondition = (state, key, t) => {
        switch (key) {
            case 'customerId':
                return state.customerName;
            case 'templateId':
                return state.titleSurvey;
            case 'staffId':
                return state.staffName;
            case 'startDate':
                let startDate = state.startDate ? `:${moment.unix(state.startDate).format(config.formatDateTh)}` : '';
                return t(`Surveys.${key}`) + `${startDate}`;
            case 'endDate':
                let endDate = state.endDate ? `:${moment.unix(state.endDate).format(config.formatDateTh)}` : '';
                return t(`Surveys.${key}`) + `${endDate}`;
            default:
                return t(`RecordList.${key}`)
        }
    };


}

export default RecordBuilderCondition;
