import React, { Component }                                      from 'react';
import RenderComponentDevice                                     from '../../../ui/RenderComponentDevice';
import RecordEditorMobile                                        from './Mobile';
import RecordEditorWeb                                           from './Web';
import { connect }                                               from 'react-redux';
import {  KirokuPageLoader }                                     from '../../../ui';
import { loadDraftEditRecording, restartDataDraftEditRecording } from '../../../actions/Draft';
import { clearRecordToEditInStore, loadRecordToEdit }            from '../../../actions/Record';
import { withPolling }                                           from '../../HOC/withPolling';
import config                                                    from '../../../config';
import { withNamespaces }                                        from 'react-i18next';
import ModalAskReload                                            from '../ModalAskReload';

@withNamespaces()
@connect(state => {
    return {
        recordToEdit        : state.KirokuAIRecord.recordToEdit,
        lockInfo            : state.KirokuLockInfo.lockInfo,
        isDisplayAskReload  : state.KirokuLockInfo.isDisplayAskReload,
        alterLocking        : state.KirokuLockInfo.alterLocking,
    };
}, dispatch => {
    return {
        loadRecordById: (id) => {
            dispatch(loadRecordToEdit(id));
        },

        loadDraftEditRecoding: (id) => {
            dispatch(loadDraftEditRecording(id));
        },

        clearRecordToEditInStore     : () => {
            dispatch(clearRecordToEditInStore());
        },
        restartDataDraftEditRecording: () => {
            dispatch(restartDataDraftEditRecording());
        },
        closeModalAskReload          : (isOpen) => {
            dispatch({
                type: 'MODAL_ASK_RELOAD',
                isOpen
            })
        },
        setCallingLockStatus         : (isCalling) => {
            dispatch({
                type: 'SET_CALLING_LOCK_STATUS',
                isCalling
            })
        }
    };
})

@withPolling('records', config.pollingTime)
class RecordEditor extends Component {

    componentDidMount() {
        const recordId = this.props.match.params.recordId;
        // this.props.loadDraftEditRecoding(recordId);
        this.props.loadRecordById(recordId);
    }

    componentWillUnmount() {
        this.props.clearRecordToEditInStore();
        // this.props.restartDataDraftEditRecording();
    }


    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.lockInfo.user_id !== this.props.lockInfo.user_id
            || nextProps.recordToEdit !== this.props.recordToEdit
        || nextProps.isDisplayAskReload !== this.props.isDisplayAskReload;
    }

    handleActionUser() {
        if(!this.props.isCalling) {
            this.props.setCallingLockStatus(true)
        }
    }

    render() {
        const { recordToEdit, lockInfo, isDisplayAskReload, alterLocking } = this.props;

        return recordToEdit ?
            <div
                onMouseMove={() => this.handleActionUser()}
                onMouseEnter={() => this.handleActionUser()}
                onTouchStart={() => this.handleActionUser()}
            >
                <ModalAskReload
                    isOpen={isDisplayAskReload}
                    userName={ lockInfo.previous_lock && lockInfo.previous_lock.user_name }
                    handleCancel={ () => this.props.closeModalAskReload(false) }
                    handleConfirm={ () => window.location.reload() }
                    closeModal={() => this.props.closeModalAskReload(false)}
                />

                <RenderComponentDevice
                    mobile={ <RecordEditorMobile
                        history={ this.props.history } lockInfo={ lockInfo }
                        alterLocking={alterLocking}
                    /> }

                    web={ <RecordEditorWeb
                        history={ this.props.history } lockInfo={ lockInfo }
                    /> }
                />
            </div>
            : <KirokuPageLoader/>;
    }
}

export default RecordEditor;
