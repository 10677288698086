import React, {Component}     from 'react';
import ModalAddLogicGeneral   from "../ModalAddLogicGeneral";
import BehaviorQuestionMobile    from "./BehaviorQuestionMobile";
import {withNamespaces}          from "react-i18next";
import ActionQuestions           from "./ActionsQuestions";
import {checkUseHeadingQuestion} from "../../SurveyEditor/SurveyService";
import {
    KirokuPopoverItem, KirokuLogicApplicated,
    KirokuCardWeb, KirokuCardTitleWeb,
    KirokuCardBodyWeb, KirokusSortableHandle
}                                  from '../../../../ui';
@withNamespaces()
class Message extends Component {
    state = {
        show: true
    };

    showModalAddLogic() {
        this.setState({
            show: !this.state.show
        })
    }

    closeModal() {
        this.setState({
            show: !this.state.show
        })
    }

    render() {
        const {t} = this.props;
        const question = this.props.question;
        const contentQuestion = checkUseHeadingQuestion(question);
        return (
            <KirokuCardWeb style={{borderRadius: 4}}>
                <KirokuCardTitleWeb style={{backgroundColor: '#e9edf1', paddingTop: 10, paddingBottom: 7, paddingRight: 12, paddingLeft: 12}}>
                    <div className="d-flex justify-content-between">

                        <div style={{width: '100%'}}>
                            <KirokusSortableHandle>
                                <div className='number-question d-flex' >
                                    <span className="question-number pb-2 ">{t('createSurvey.QuestionOrder', {index: question.index + 1})} </span>
                                    <KirokuLogicApplicated question={question}/>
                                </div>
                            </KirokusSortableHandle>
                        </div>

                        <div className='number-question d-flex justify-content-between'>
                            <div style={{float: 'right'}}>
                                <ActionQuestions>
                                    <KirokuPopoverItem>
                                        <BehaviorQuestionMobile question={question}
                                                                showModalAddLogic={this.showModalAddLogic.bind(this)}
                                                                backToEditQuestion={this.props.backToEditQuestion}
                                        />
                                    </KirokuPopoverItem>
                                </ActionQuestions>
                            </div>
                        </div>
                    </div>
                </KirokuCardTitleWeb>
                <div className='wrap-overlay-action'>
                    <KirokuCardBodyWeb>
                        <span className='content-name-question pb-2'>{contentQuestion}</span>
                        <p hidden={typeof question.nextQuestion !== 'number'} className='text-info'><i className="fas fa-arrow-right text-info">{t('createRecord.Question')}: {question.nextQuestion + 1}</i> </p>
                    </KirokuCardBodyWeb>
                </div>

                <ModalAddLogicGeneral show={this.state.show} question={question}
                                      closeModal={this.closeModal.bind(this)}/>
            </KirokuCardWeb>
        );
    }
}

export default Message;
