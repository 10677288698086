import {Component} from "react";
import React from "react";

export default class KirokuCardHeader extends Component {
    render() {
        return (
            <div className='media media-single'>
                <div className="media-body">
                    <h6>{this.props.headerLeft}</h6>
                </div>
                <div className="media-right">
                    {this.props.headerRight}
                </div>
            </div>
        )
    }
}
