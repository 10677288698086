import React, {Component} from "react";
import {connect}          from 'react-redux';
import {withNamespaces}   from "react-i18next";
import {Link}             from "react-router-dom";
import {filePathService}  from '../services';
import {reloadAvatar}     from "../actions/ActionLayout";

@withNamespaces()
@connect(state => {
    return {
        profile: state.KirokuAICustomer.profile,
        reloadAvatar :state.ReduceActionLayout.reloadAvatar,
    }
}, dispatch => {
    return {
        logout: () => dispatch({type: 'LOGOUT'}),
        reloadAvatar : () => {
            dispatch(reloadAvatar())
        }
    };
})
class DropDownUser extends Component {

    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            popupVisible: false
        };
    }

    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => (
            {
                popupVisible: !prevState.popupVisible,
            }
        ));
    }

    handleOutsideClick(e) {
        if (!this.node) {
            return;
        }
        this.handleClick();
    }

    handleLogout() {
        this.props.logout();
    }

    render() {
        let profile = JSON.parse(localStorage.getItem('profile'));
        let newProfile = profile ? profile : {avatar: ''};
        const { t } = this.props;
        return (
            <div className='header-user-avatar' ref={node => {
                this.node = node
            }}>
                <div onClick={this.handleClick.bind(this)}>
                    <div className="avatar"
                         style={{backgroundImage: `url(${newProfile.avatar ? newProfile.avatar : filePathService.resolveFilePath('profile-ic.svg')})`}}/>
                </div>
                {this.state.popupVisible && (
                    <div className="dropdown-kiroku dropdown-menu headerm show"
                         style={{position: 'absolute', willChange: 'top, left', left: 'auto', zIndex: '998'}}>
                        <Link to={`/edit-my-profile`}>
                            <div className="item-user-profile dropdown-item"
                                 onClick={this.handleClick.bind(this)}>
                                <i className="fa fa-user" style={{fontSize: "1.3em"}}/>
                                <span className='ml-3'><strong
                                    style={{textTransform: 'uppercase'}}>{profile.name}</strong></span>
                            </div>
                        </Link>
                        <div className="item-user-profile dropdown-item" onClick={this.handleLogout.bind(this)}>
                            <i className="fa fa-power-off" style={{fontSize: "1.3em"}}/>
                            <span className='ml-3'>{t('Dashboard.Logout')}</span>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default DropDownUser;
