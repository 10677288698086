import React, { Component }           from 'react';
import { buildDataMultiChoice }       from "../buildData";
import Chart from "react-google-charts";
import i18n from "../../../i18n/i18n";
const t = i18n.getFixedT();

class MultiChoiceChart extends Component {
    render() {
        const {records, question} = this.props;
        const data = buildDataMultiChoice(records, question);

        const options = {
            vAxis: {
                title: t('createRecord.Choices')
            },
            hAxis: {
                type: 'number',
                format: '#'
            },
            legend: { position: 'none' },
        };
        return (
            <Chart
                chartType="BarChart"
                width="100%"
                height="500px"
                data={data}
                options={options}
            />
        )
    }
}

export default MultiChoiceChart;
