export default class DraftSurveyService {

    constructor(axios) {
        this.axios = axios;
    }

    loadDraft(type, id = '') {
        return this.axios.get(`/load-draft/${type}?id=${id}`).then(res => {
            if (res.data.length !== 0) {
                return res.data[0]
            }
            return null
        });
    }

    discard(type, surveyId = '') {
        return this.axios.delete(`/draft/${type}?id=${surveyId}`)
    }
}
