import React, {Component}                           from 'react';
import {KirokuCardWeb, KirokuTable, TBody, THeader} from "../../../../ui";
import moment                                       from 'moment';
import {Link}                                       from "react-router-dom";
import {filePathService}                            from "../../../../services";
import TabResult
                                                    from "../../../Staffs/StaffEditor/TabResult";
import {withNamespaces}                             from "react-i18next";
import withPermissions                              from "../../../HOC/WithPermissions";
import ButtonComponent                              from "../../../HOC/ButtonComponent";
import KirokuLabel                                  from "../../../../ui/KirokuLabel";

const ButtonAssignSurvey = withPermissions(ButtonComponent);

@withNamespaces()
class TabSurveys extends Component {

    render() {
        const {customer, t} = this.props;
        return (
            <div hidden={this.props.hidden}>
                <KirokuCardWeb>
                    <div className="p-3">
                        {customer.surveys.length ?
                            <KirokuTable>
                                <THeader>
                                    <tr>
                                        <th>{t('common.Name')}</th>
                                        <th>{t('common.Description')}</th>
                                        <th className='text-right'/>
                                    </tr>
                                </THeader>
                                <TBody>
                                    {customer.surveys.map(survey => {
                                        const startDay = moment().startOf('day').unix();
                                        const endDay   = moment().endOf('day').unix();
                                        return <tr key={survey.id}>
                                            <td>
                                                <Link to={`/surveys/survey-detail/${survey.id}`}>
                                                    <span>{survey.title}</span>
                                                    {/*<span className='ml-6'><KirokuCheckDraft*/}
                                                    {/*draftable={survey}/></span>*/}
                                                    <span className='ml-6'>
                                                        {(survey['_pivot_record_at'] > startDay && survey['_pivot_record_at'] < endDay) ?
                                                            <KirokuLabel
                                                                hidden={!(survey['_pivot_record_at']) || !!customer.delete_at}
                                                                style={{
                                                                    backgroundColor: '#337ab7',
                                                                    color: 'white'
                                                                }}>
                                                                {t('CustomerEditor.Recorded')}
                                                            </KirokuLabel> : ''
                                                        }
											    	</span>
                                                    <span className='ml-6'>
                                                        {survey['_pivot_drafted_at'] ?
                                                            <KirokuLabel style={{
                                                                backgroundColor: '#f7981c',
                                                                color: 'white'
                                                            }}>{t('common.haveDraft')}</KirokuLabel> : ''}
                                                    </span>
                                                </Link>
                                            </td>
                                            <td>{survey.description}</td>
                                            <td className='text-right d-flex'>
                                                <Link
                                                    className='btn btn-kiroku-primary'
                                                    to={{
                                                        pathname: `/analysis`,
                                                        state: {
                                                            surveyId: survey.id,
                                                            surveyName: survey.title,
                                                            customerId: customer.id,
                                                            customerName: customer.name
                                                        }

												}}
											>
												{t('common.Analysis')}
											</Link>
											<div className={'pr-1 pl-1'}/>
                                            <Link
                                                className='btn btn-kiroku-light'
                                                to={{
                                                    pathname: `/records/new-create-record`,
                                                    state: {
                                                        surveyId: survey.id,
                                                        customerId: customer.id
                                                    }
                                                }}
                                            >
                                                {t('common.Records')}
                                            </Link>
											<div className={'pr-1 pl-1'}/>
											<Link
												className='btn btn-kiroku-success'
												to={{
													pathname: `/records/create-record`,
													state: {
														surveyId: survey.id,
														customerId: customer.id
													}
												}}
											>
												 {t('common.RecordsNew')}
											</Link>
										</td>
									</tr>
								})}
								</TBody>
							</KirokuTable> :
							<div>
								<TabResult messageFirst={'survey'}>
									<img
										className='pt-2 pb-2'
										src={`${filePathService.resolveFilePath('empty-survey.svg')}`}
										alt="error img"/>
								</TabResult>
								<div className='text-center'>
									<ButtonAssignSurvey
                                        resource-permission="|customerDetail|button-assignSurvey|"
                                        color='primary'
                                        onClick={() => this.props.closeModal()}>{t('Customer.AssignARecordingToCustomer')}
									</ButtonAssignSurvey>
								</div>
							</div>
						}
					</div>
				</KirokuCardWeb>
			</div>
		)
	}
}

export default TabSurveys;
