class LogsService {
    constructor(axios) {
        this.axios = axios;
    }

    load() {
        return this.axios.get('/logs').then(res => res.data)
    }

    loadPage(params) {
        return this.axios.get(`/logs`, {
            params: {
                ...params
            }
        }).then(res => res.data);
    }

    //
    // loadPage(params) {
    //     return this.axios.get(`/logs`, {
    //         params: {
    //             ...params
    //         }
    //     }).then(res => res.data.logs);
    // }

    search(params) {
        return this.axios.get('/logs', {
            params: {
                ...params
            }
        }).then(res => res.data)
    }


}

export default LogsService;