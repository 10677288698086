import React, {Component} from 'react';
import Question           from "./Question";
import {connect}          from 'react-redux';

@connect(state => {
    return {
        surveyTemplate: state.KirokuAISurveyTemplate
    }
})
class ListQuestion extends Component {

    render() {
        const {isCreateSurvey} = this.props;
        return (
            <div className='pt-3'>
                {this.props.surveyTemplate.questions.map((question, index) =>
                    (
                        <Question isCreateSurvey={isCreateSurvey} question={question} key={index} index={index}/>
                    )
                )}
            </div>
        );
    }
}

export default ListQuestion;
