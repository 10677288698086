import {Component} from "react";
import React from "react";
import KirokuDropdownItem from "./KirokuDropdownItem";

export default class KirokuDropdownClose extends Component {

    state = {
        selected: null
    };

    handleSelected(event) {
        this.props.onSelect(event);
        this.setState({
            popupVisible: false,
            selected: event.kirokuSelected
        });
    }

    constructor() {
        super();

        this.handleClick = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            popupVisible: false
        };
    }

    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
    }

    handleOutsideClick(e) {
        if (!this.node) {
            return;
        }
        this.handleClick();
    }

    render() {
        const selectedLabel = this.state.selected ? this.state.selected.label : this.props.title;
        return (
            <div ref={node => {this.node = node}}>
                <div className={`dropdown ${this.props.className ? this.props.className : ''}`}>
                    <button hidden={this.props.hidden}
                            disabled={this.props.disabled}
                            style={this.props.style}
                            className={`overflow btn btn-kiroku ${this.props.className ? this.props.className : ''}`}
                            onClick={this.handleClick.bind(this)}>
                        <span className={'text-kiroku left'}>{selectedLabel}</span>
                        <i className={`${! this.state.popupVisible ? 'fa fa-chevron-down icon-kiroku' : 'fa fa-chevron-up icon-kiroku' }`}/>
                    </button>
                    {this.state.popupVisible && (
                        <div className={`dropdown-kiroku dropdown-menu show ${this.props.location ? this.props.location : ''}`}
                             style={{position: 'absolute', willChange: 'top, left', left:'auto', top: 'auto'}}>
                            <section className='scroll-data-dropdown-search'>
                                {this.props.items.map((item, index) => {
                                    const Item = item.component;
                                    return (
                                        <KirokuDropdownItem key={index} onClick={(event) => {
                                            event.kirokuSelected = item;
                                            this.handleClick(event);
                                            this.handleSelected(event)
                                        }}>
                                            {Item}
                                        </KirokuDropdownItem>
                                    )
                                })}
                            </section>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

