import React, {PureComponent}   from 'react';
import KirokuTabLe              from "../../ui/KirokuTabLe";
import {KirokuLoading, THeader} from "../../ui";
import TBody                    from "../../ui/TBody";
import {withNamespaces}         from "react-i18next";
import {connect}                from "react-redux";
import {pinCustomer}            from "../../actions/Customer";
import {Link}                   from "react-router-dom";
import {authService}            from "../../services";

@connect(state => {
    return {
        loadingCustomerPinSuccess: state.KirokuAICustomer.loadingCustomerPinSuccess,
        customerForPin: state.KirokuAICustomer.customerForPin,
    }
}, dispatch => {
    return {
        pinCustomer: (customer) => {
            dispatch(pinCustomer(customer))
        },
        hoverStartOut: (status, index) => {
            dispatch({
                type: 'HOVER_START_OUT',
                status,
                index
            })
        }
    }

})
@withNamespaces()
class TaggedCustomers extends PureComponent {
    state = {
        hoverStar: false,
    };

    pinCustomer(customer) {
        this.props.pinCustomer(customer)
    }

    hoverStar(id, status) {
        this.props.hoverStartOut(!status, id)
    }

    hoverStarOut(id, status) {
        this.props.hoverStartOut(!status, id)
    }

    render() {
        const {t, customerForPin} = this.props;
        return (
            <div hidden={authService.isAdmin()}>
                <h5 className='pt-3 pb-3 pr-3 recent-title'>{t('Dashboard.myCustomers')}</h5>
                <div className={'bg-white'}>
                    {this.props.loadingCustomerPinSuccess ? <div>
                        {customerForPin.length ?
                            <KirokuTabLe>
                                <THeader className={'tbl-header'}>
                                    <tr>
                                        <th>{t('Dashboard.customerName')}</th>
                                        <th/>
                                    </tr>
                                </THeader>
                                <TBody>
                                    {customerForPin.map((customer, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <Link to={`/customers/customer-detail/${customer.id}`}>
                                                        {customer.name}
                                                    </Link>
                                                </td>
                                                <td>
                                                    <div
                                                        onMouseEnter={this.hoverStar.bind(this, index, customer.hoverStar)}
                                                        onMouseLeave={this.hoverStarOut.bind(this, index, customer.hoverStar)}
                                                        className='group-pin'
                                                        onClick={this.pinCustomer.bind(this, customer)}
                                                        style={{textAlign: 'right', cursor: 'pointer'}}>

                                                        <i style={{color: '#7f8fa4'}}
                                                           className={`${customer.pinned_at ?
                                                               `${customer.hoverStar ? `far fa-star` : `fas fa-star`}` : `${customer.hoverStar ? `far fa-star` : `fas fa-star`}`}`}/>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </TBody>
                            </KirokuTabLe> :
                            <div>
                                <KirokuTabLe>
                                    <THeader className={'tbl-header'}>
                                        <tr>
                                            <th>{t('Dashboard.customerName')}</th>
                                            <th/>
                                        </tr>
                                    </THeader>
                                </KirokuTabLe>
                                <div className={'text-center p-2'}><h5>{t('Dashboard.noCustomer')}</h5></div>
                            </div>
                        }
                    </div> : <KirokuLoading/>}
                </div>
            </div>
        )
    }
}

export default TaggedCustomers;
