import {
    ADD_LOGIC_MOBILE,
    CHANGE_TYPE_QUESTION,
    CREATE_QUESTION_MOBILE,
    EDIT_SURVEY_MOBILE,
    BACK_QUESTION_MOBILE,
    EDIT_QUESTION_MOBILE,
    BACK_TO_ADD_QUESTION,
    EDIT_QUESTION_BACK,
    NEXT_QUESTION_FROM_SURVEY,
    NEXT_QUESTION_MOBILE,
    DELETE_QUESTION_MOBILE,
    SHOW_REVIEW_MOBILE,
    SHOW_ADD_QUESTION,
    UPDATE_SURVEY_MOBILE,
    SELECT_TYPE_QUESTION,
    RESET_MOBILE,
    CLICK_EDIT_REVIEW_TO_ADD_QUESTION,
    CLICK_EDIT_REVIEW_TO_EDIT_QUESTION, COPY_QUESTION_FOR_TEMPLATE_MOBILE,
} from '../actions/SurveyMobile';
import {LOADING_SURVEY_FROM_DRAFT}                                  from "../actions/DraftSurvey";
import {DETAIL_SURVEY, CHANGE_UNREQUITED_QUESTION}                  from "../actions/SurveyTemplate";
import {cleanQuestions, keepLogicWhenDelete}                        from "../components/Surveys/SurveyEditor/SurveyService";
import { DRAG_QUESTION_SURVEY, SET_DEFAULT_ANSWER_QUESTION_MOBILE } from '../actions/Survey';
import { keepLogic }                                                from './SurveyTemplate';
import  _                                                           from 'lodash';

const defaultStateSurvey = {
    title: '',
    description: '',
    form_number: '',
    questions: [],
    showSurvey: true,
    showReview: true,
    typeComponent: 'Survey',
    typeQuestion: '',
    flowedQuestions: [],
    questionBack: null,
    surveyToEdit: null,
    lastQuestion: null,
    errorQuestion: {
        answer: false,
        content: false
    },
    orderQuestion: 0,
    errorSurvey: {
        title: true,
        description: true,
    },
};

const resetSate = () => {
    return {
        title: '',
        description: '',
        form_number: '',
        questions: [],
        showSurvey: true,
        showReview: true,
        typeComponent: 'Survey',
        typeQuestion: '',
        flowedQuestions: [],
        questionBack: null,
        surveyToEdit: null,
        lastQuestion: null,
        errorQuestion: {
            answer: false,
            content: false
        },
        orderQuestion: 0,
        errorSurvey: {
            title: true,
            description: true,
        },
    }
};

const resetLogicQuestion = (question) => {
    switch (question.type) {
        case 'Single':
        case 'MultiChoice' :
            return {
                content: question.content,
                heading: question.heading,
                type: question.type,
                choices: question.choices.map(choice => ({
                    answer: choice.answer
                }))
            };
        default :
            return {
                content: question.content,
                heading: question.heading,
                type: question.type
            }
    }
};

const KirokuSurveyTemplateMobile = (state = defaultStateSurvey, action) => {
    switch (action.type) {
        case CHANGE_UNREQUITED_QUESTION:
            let questionsBeRequited = [...state.questions];
            if(action.questionIndex <= questionsBeRequited.length && questionsBeRequited[action.questionIndex]) {
                questionsBeRequited[action.questionIndex] = {
                    ...questionsBeRequited[action.questionIndex],
                    unrequited: !questionsBeRequited[action.questionIndex].unrequited
                };
                return {
                    ...state,
                    questions: questionsBeRequited
                };
            }
            return state;
        case DRAG_QUESTION_SURVEY:
            let questionsAfterDrag = action.questions.map((question, index) => {
                return {
                    ...question,
                    index
                }
            });
            if(action.currentIndex < action.nextIndex) {
                for(let i = action.currentIndex; i < action.nextIndex; i ++) {
                    questionsAfterDrag = keepLogic(questionsAfterDrag, i, i + 1);
                }
            }else {
                for(let i = action.currentIndex; i > action.nextIndex; i --) {
                    questionsAfterDrag = keepLogic(questionsAfterDrag, i, i - 1);
                }
            }

            return {
                ...state,
                questions: questionsAfterDrag
            };
        case CHANGE_TYPE_QUESTION:
            return {
                ...state,
                typeQuestion: action.typeQuestion,
                flowedQuestions: state.questions.map(question => ({...question}))
            };
        case CREATE_QUESTION_MOBILE:
            // let question = createQuestionByType(action.question);
            let newQuestions = state.questions.map(question => ({...question}));
            newQuestions.push(action.question);
            return {
                ...state,
                flowedQuestions: newQuestions.map(question => ({...question})),
                questions: newQuestions,
                lastQuestion : {...action.question},
                questionBack: null
            };
        case EDIT_SURVEY_MOBILE :
            let newsSurveyTemplate = Object.assign(state, action.survey);
            return {
                ...newsSurveyTemplate,
                errorSurvey: {
                    ...state.errorSurvey,
                    ...action.error,
                }
            };
        case ADD_LOGIC_MOBILE :
            state.questions[action.question.index] = action.question;
            return {
                ...state,
            };
        case BACK_QUESTION_MOBILE :
            let backQuestion = state.flowedQuestions.pop();
            return backQuestion ? {
                ...state,
                showReview: true,
                questionBack: backQuestion,
                type: backQuestion.type,
                typeQuestion: backQuestion.type,
                backToSurvey: !backQuestion
            } : {
                ...state,
                showReview: true,
                questionBack: null,
                type: '',
                typeQuestion: '',
            };
        case BACK_TO_ADD_QUESTION :
            return {
                ...state,
                flowedQuestions: state.questions.map(question => ({...question})),
                questionBack: null
            };
        case EDIT_QUESTION_BACK :
            return {
                ...state,
                questionBack: {
                    ...state.questionBack,
                    ...action.questionBack
                },
                errorQuestion: {
                    ...state.errorQuestion,
                    ...action.error
                }
            };
        case NEXT_QUESTION_FROM_SURVEY :
            return {
                ...state,
                typeComponent: action.typeComponent,
                typeQuestion: action.typeQuestion
            };
        case CLICK_EDIT_REVIEW_TO_EDIT_QUESTION :
            return {
                ...state,
                typeComponent : action.typeComponent,
                typeQuestion : action.question.type,
                orderQuestion : action.question.index
            };
        case EDIT_QUESTION_MOBILE :
            let questions = state.questions;
            questions[action.question.index] = {...action.question};
            return {
                ...state,
                questions: questions,
                questionBack: questions[action.question.index],
                errorQuestion: {
                    answer: false,
                    content: false
                },
            };
        case CLICK_EDIT_REVIEW_TO_ADD_QUESTION :
            return{
                ...state,
                questionBack : state.questions[action.question.index],
                errorQuestion: {
                    answer: false,
                    content: false
                },
            };
        case SELECT_TYPE_QUESTION :
            state.questions[action.question.index] = {...action.question};
            return {
                ...state,
                // questions: resetLogicQuestions(state.questions),
                questions: cleanQuestions(state.questions),
                questionBack: state.questions[action.question.index],
            };
        case DETAIL_SURVEY :
            let lastQuestion = action.survey.questions[action.survey.questions.length - 1];
            return {
                ...state,
                ...action.survey,
                orderQuestion: 0,
                showSurvey: true,
                lastQuestion: lastQuestion
            };
        case NEXT_QUESTION_MOBILE :
            return {
                ...state,
                orderQuestion: action.index
            };

        case DELETE_QUESTION_MOBILE :
            let questionRemove = state.questions.map(question => ({...question}));
            questionRemove.splice(action.question.index, 1);
            questionRemove = questionRemove.map((question, index) => {
                question.index = index;
                return question;
            });
            questionRemove = keepLogicWhenDelete(action.question.index, questionRemove);


            let flowedQuestionsRemove = state.flowedQuestions.map(question => ({...question}));
            flowedQuestionsRemove.splice(action.question.index, 1);
            flowedQuestionsRemove = flowedQuestionsRemove.map((question, index) => {
                question.index = index;
                return question;
            });
            flowedQuestionsRemove = keepLogicWhenDelete(action.question.index, flowedQuestionsRemove);
            return {
                ...state,
                questions: questionRemove,
                errorSurvey: {
                    ...state.errorSurvey,
                },
                flowedQuestions: flowedQuestionsRemove,
                questionBack: !questionRemove.length ? null : state.questionBack
            };
        case SHOW_REVIEW_MOBILE :
            return {
                ...state,
                showReview: !state.showReview
            };
        case SHOW_ADD_QUESTION :
            return {
                ...state,
                showAddQuestion: !state.showAddQuestion,
                typeQuestion: 'InputText'
            };
        case RESET_MOBILE :
            return resetSate();
        case "CREATE_SURVEY_MOBILE_DONE" :
            return resetSate();
        case UPDATE_SURVEY_MOBILE :
            return resetSate();
        case LOADING_SURVEY_FROM_DRAFT :
            return {
                ...state,
                ...action.survey
            };
        case 'CHANGE_BACK_QUESTION':
            return {
                ...state,
                questionBack: action.questionToBack
            };
        case SET_DEFAULT_ANSWER_QUESTION_MOBILE:
            let newQuestionsDefaultAnswer = [...state.questions];
            newQuestionsDefaultAnswer[action.question.index] = action.question;
            return {
                ...state,
                questions: newQuestionsDefaultAnswer
            };
        case COPY_QUESTION_FOR_TEMPLATE_MOBILE :
            const questionsAfterCopy = [...state.questions];
            questionsAfterCopy.push(resetLogicQuestion(action.question));
            const questionNew = questionsAfterCopy.map((questionCopy, index) => {
                return {
                    ...questionCopy,
                    index
                }
            });
            return {
                ...state,
                questions : questionNew,
                lastQuestion : _.last(questionNew)
            };
        default :
            return state;
    }
};

export default KirokuSurveyTemplateMobile;
