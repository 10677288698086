import moment from "moment";

export default class MakeCustomerService {

    listAllCustomerForTags(customer) {
        return customer.map(customer => {
            return {
                ...customer,
                tagId: customer.id,
                key: `customer_id${customer.id}`,
                value: `${customer.name}${customer.id}`,
                label: customer.name,
                type: 'Customers',
                tagType: "customer",
                tagContent: {
                    ...customer,
                },
            }
        });

    }

    checkContact(contacts) {
        if (contacts.length) {
            return contacts
        } else {
            return [{
                name: '',
                phone_number: '',
                memo: '',
                relationship: 'unknown'
            }];
        }
    }

    getCustomerFromApi(customer, isDetail) {
        let teams       = customer.teams ? customer.teams.map((team) => {
            return {
                ...team,
                tagId: team.id,
                tagType: "team",
                tagContent: {
                    ...team,
                },
                key: `team_id${team.id}`,
                label: team.name,
                value: `${team.name}${team.id}`,
                type: "Teams"
            }
        }) : [];
        let certificate = customer.certificate.map(item => ({
            key: item,
            label: item,
            value: item,
        }));
        let staffs      = customer.staffs.map(item => ({
            ...item,
            tagId: item.id,
            tagType: "users",
            tagContent: {
                ...item,
            },
            key: `staff_id${item.id}`,
            label: item.name,
            value: `${item.name}${item.id}`,
            type: 'Users'
        }));

        return {
            contacts: isDetail ? customer.contacts : this.checkContact(customer.contacts),
            certifications: customer.certifications,
            edit: customer.edit,
            editRecord: customer.editRecord,
            record: customer.record,
            active: customer.active,
            address: customer.address,
            avatar: customer.avatar,
            blood_type: customer.blood_type,
            certificate: certificate,
            // certification_end_date: customer.certification_end_date,
            // certification_number: customer.certification_number,
            // certification_start_date: customer.certification_start_date,
            characteristic: customer.characteristic,
            customer_code: customer.customer_code,
            classification: customer.classification,
            contract_end: customer.contract_end,
            contract_start: customer.contract_start,
            create_at: customer.create,
            date_of_birth: (customer.date_of_birth === null || customer.date_of_birth === '') ? '' : moment.unix(customer.date_of_birth),
            delete_at: customer.delete_at,
            // emergency_contact: customer.emergency_contact,
            gender: customer.gender || null,
            id: customer.id,
            name: customer.name,
            organization_id: customer.organization_id,
            // phone_number: customer.phone_number,
            records: customer.records,
            staffs: staffs || [],
            surveys: customer.surveys || [],
            team: customer.team,
            team_id: customer.team_id,
            term: customer.term,
            update_at: customer.update_at,
            teams: teams || [],
            team_name: customer.team ? customer.team.name : '',
            tags: customer.tags ? customer.tags : [],
            guardianship_type     : customer.guardianship_type || null,
            appointed_type        : customer.appointed_type || '',
            competent_court       : customer.competent_court || '',
            decision_date         : customer.decision_date || null,
            case_number           : customer.case_number || '',
            registration_number   : customer.registration_number || '',
            permanent_address     : customer.permanent_address || '',
            residence_card_address: customer.residence_card_address || '',
            facility_name         : customer.facility_name || '',
            report_month          : customer.report_month || 0,
            post_code             : customer.post_code || ''
        }
    }

    getCustomerFromApiEdit(customer) {
        return {
            ...customer,
            staffs: customer.staffs ? customer.staffs.map(item => ({
                ...item,
                label: item.name,
                value: `${item.name}${item.id}`,
                tagId: item.id,
                type: "Users",
                tagType: "users",
                tagContent: {...item},
                key: `user_id${item.id}`,
            })) : [],
            date_of_birth: moment.unix(customer.date_of_birth),
        }
    }

    makeCustomerForDraft(customer) {
        return {
            id: customer.id || null,
            address: customer.address || '',
            avatar: customer.avatar || '',
            blood_type: customer.blood_type || 'Unknown',
            certificate: customer.certificate.map(item => item.key) || [],
            certification_end_date: customer.certification_end_date || null,
            certification_number: customer.certification_number || '',
            certification_start_date: customer.certification_start_date || null,
            customer_code: customer.customer_code || '',
            characteristic: customer.characteristic || '',
            classification: customer.classification,
            contract_end: customer.contract_end || null,
            contract_start: customer.contract_start || null,
            date_of_birth: moment(customer.date_of_birth).unix() || moment().unix(),
            emergency_contact: customer.emergency_contact || '',
            gender: customer.gender || '',
            name: customer.name || '',
            phone_number: customer.phone_number || '',
            team: customer.team ? customer.team : {},
            staffs: customer.staffs.length ? customer.staffs : [],
            surveyTemplates: customer.surveys ? customer.surveys.map(item => item.id) : [],
            team_id: customer.team_id || null,
            team_name: customer.team_name || '',
            currentStaffs: customer.currentStaffs ? customer.currentStaffs : customer.staffs
        }
    };

    makeCustomerForTag(customer, isEdit) {
        let user = customer.tags.filter(item => item.tagType === 'users').map(user => user.tagContent.id);
        return {
            id: customer.id || null,
            address: customer.address || '',
            avatar: customer.avatar || '',
            gender: customer.gender || null,
            blood_type: customer.blood_type || 'Unknown',
            certificate: customer.certificate ? customer.certificate.map(item => item.key) : [],
            // certification_end_date: customer.certification_end_date || null,
            // certification_number: customer.certification_number || '',
            // certification_start_date: customer.certification_start_date || null,
            characteristic: customer.characteristic || '',
            classification: customer.classification,
            contacts: customer.contacts ? customer.contacts.filter(contact => contact.phone_number) : [],
            contract_end: customer.contract_end || null,
            contract_start: customer.contract_start || null,
            customer_code: customer.customer_code || '',
            date_of_birth: customer.date_of_birth ? moment(customer.date_of_birth).unix() : null,
            // emergency_contact: customer.emergency_contact || '',
            name: customer.name || '',
            // phone_number: customer.phone_number || '',
            staffs: !isEdit ? customer.staffs.map(staff => staff.id) : user || [],
            surveyTemplates: customer.surveys.length ? customer.surveys.map(item => item.id) : [],
            team_id: customer.team_id || null,
            tags: customer.tags || [],
            guardianship_type: customer.guardianship_type || null,
            appointed_type        : customer.appointed_type || '',
            competent_court       : customer.competent_court || '',
            decision_date         : customer.decision_date ? customer.decision_date : null,
            case_number           : customer.case_number || '',
            registration_number   : customer.registration_number || '',
            permanent_address     : customer.permanent_address || '',
            residence_card_address: customer.residence_card_address || '',
            facility_name         : customer.facility_name || '',
            report_month          : customer.report_month || 0,
            post_code             : customer.post_code || ''
        }
    }
}
