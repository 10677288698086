import {
    SAVE_DRAFT_RECORDING,
    LOAD_DRAFT_RECORDING,
    DISCARD_DRAFT_RECORDING,
    RESTART_DATA_DRAFT_RECORDING,
    LOAD_DRAFT_EDIT_RECORDING,
    RESTART_DATA_DRAFT_EDIT_RECORDING,
    DISCARD_DRAFT_EDIT_RECORDING
} from '../actions/Draft';

const DefaultDataDraft = {
    dataDraftRecording    : null,
    dataDraftEditRecording: null

};

const KirokuAIDraftRecording = (state = DefaultDataDraft, action) => {
    switch (action.type) {

        case DISCARD_DRAFT_EDIT_RECORDING:
            return {
                ...action,
                ...state,
                dataDraftEditRecording: action.dataDraftEditRecording
            };

        case LOAD_DRAFT_EDIT_RECORDING:
            return {
                ...action,
                dataDraftEditRecording: action.dataDraftEditRecording
            };
        case SAVE_DRAFT_RECORDING:
            return {
                ...state
            };
        case LOAD_DRAFT_RECORDING:
            return {
                ...action,
                ...state,
                dataDraftRecording: action.recordingDraft
            };
        case DISCARD_DRAFT_RECORDING:
            return {
                ...action,
                ...state,
                dataDraftRecording: null
            };
        case RESTART_DATA_DRAFT_RECORDING:
            return {
                ...action,
                ...state,
                dataDraftRecording      : null,
                surveyTemplateOfCustomer: null
            };
        case RESTART_DATA_DRAFT_EDIT_RECORDING:
            return {
                ...action,
                dataDraftEditRecording: null
            };
        default:
            return state
    }
};

export default KirokuAIDraftRecording;
