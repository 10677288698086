import React, { Component } from "react";

export default class KirokuCardDeletedScreen extends Component {
    render() {
        return (
            <div className='card-media-screen media-single'>
                <div className="card-body-del">
                    {this.props.children}
                </div>
                <div className="media-right">
                    {this.props.action}
                </div>
            </div>
        )
    }
}
