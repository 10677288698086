import React, { Component }                                    from 'react';
import { KirokuDateRangePicker, KirokuInput, KirokuTitlepage } from '../../../../ui';
import { connect }                                             from 'react-redux';
import { withNamespaces }                                      from 'react-i18next';
import { currentPage }                                         from '../../../../actions/CurrentPage';

@connect(state => {
    return {
        keywords      : state.RecordsBeta.keywords,
        statusRecord  : state.RecordsBeta.statusRecord,
        typeSearch    : state.RecordsBeta.typeSearch,
        servicedAt    : state.RecordsBeta.servicedAt,
        typeSearchRead: state.RecordsBeta.typeSearchRead,
    };
}, dispatch => {
    return {
        onChangeKeyWords                : (keywords) => {
            dispatch({
                type: 'CHANGE_KEYWORD',
                keywords,
            });
        }, changeStatusRecord           : (statusRecord) => {
            dispatch({
                type: 'CHANGE_STATUS_RECORDED',
                statusRecord,
            });
        }, handleSearchKeyword          : () => {
            dispatch({
                type: 'CHANGE_KEYWORD_SUBMIT',
            });
        },
        changeTypeSearch                : (typeSearch) => {
            dispatch({
                type: 'CHANGE_TYPE_SEARCH',
                typeSearch,
            });
        },
        changeServiceAtRecordBeta       : (dateRange) => {
            dispatch({
                type: 'CHANGE_SERVICE_AT_RECORD_BETA',
                dateRange,
            });
        },
        changeIsOpenModalSelectCustomers: () => {
            dispatch({
                type: 'CHANGE_IS_OPEN_MODAL_SELECT_CUSTOMERS',
            });
        },
        namePage: (name) => {
            dispatch(currentPage(name));
        },
        changeTypeSearchRecordUnRead: (typeSearchRead) => {
            dispatch({
                type : 'CHANGE_TYPE_SEARCH_READ_UNREAD',
                typeSearchRead
            })
        }
    };
})

@withNamespaces()

class RecordConditions extends Component {

    handleChangeKeyword(e) {
        this.props.onChangeKeyWords(e.target.value);
    }

    changeStatusRecords(status) {
        this.props.changeStatusRecord(status);
    }

    changeTypeSearch(typeSearch) {
        this.props.changeTypeSearch(typeSearch);
    }

    handleOnChangeDateRangerServiceAt(dateRange) {
        this.props.changeServiceAtRecordBeta(dateRange);
    }

    handleSearchKeyword() {
        this.props.handleSearchKeyword();
    }


    render() {
        const { keywords, t, statusRecord, typeSearch, servicedAt, changeIsOpenModalSelectCustomers, typeSearchRead } = this.props;

        return (
            <div>
                <div className='pt-3'>
                    <div className="d-flex justify-content-around border-btn-status-group">
                        <div
                            className={`border-width-each-status first-btn font-text-on-btn ${statusRecord === 'recorded' ? 'btn-active' : ''}`}
                            onClick={() => this.changeStatusRecords('recorded')}
                        >
                            {t('RecordsBeta.recorded')}
                        </div>
                        <div
                            className={`border-width-each-status-center  font-text-on-btn ${statusRecord === 'draft' ? 'btn-active' : ''}`}
                            onClick={() => this.changeStatusRecords('draft')}
                        >
                            {t('RecordsBeta.draft')}
                        </div>
                        <div
                            className={`border-width-each-status third-btn font-text-on-btn ${statusRecord === 'all' ? 'btn-active' : ''}`}
                            onClick={() => this.changeStatusRecords('all')}
                        >
                            {t('RecordsBeta.all')}
                        </div>
                    </div>
                </div>
                <div className="pt-3">
                    <div className="d-flex justify-content-around border-btn-status-group">
                        <div
                            className={`border-width-each-status first-btn font-text-on-btn 
                                ${typeSearch === 'SEARCH_BY_CUSTOMER_TAG' ? 'btn-active' : ''}`}
                            onClick={() => this.changeTypeSearch('SEARCH_BY_CUSTOMER_TAG')}
                        >
                            {t('RecordsBeta.customerTagByUser')}
                        </div>
                        <div className={`border-width-each-status-center font-text-on-btn
                                 ${typeSearch === 'SEARCH_BY_TEAM_TAG' ? 'btn-active' : ''}`}
                             onClick={() => this.changeTypeSearch('SEARCH_BY_TEAM_TAG')}
                        >
                            {t('RecordsBeta.team')}
                        </div>
                        <div className={`border-width-each-status third-btn font-text-on-btn 
                                ${typeSearch === 'SEARCH_CUSTOM_CUSTOMER' ? 'btn-active' : ''}
                        `}
                             onClick={() => {
                                 changeIsOpenModalSelectCustomers();
                                 this.props.namePage({
                                     title     : <KirokuTitlepage title={ t('RecordsBeta.customerSelection') }/>,
                                     actionPage: null,
                                 })
                             }}
                        >
                            {t('RecordsBeta.choice')}

                            {/*<Link to={'/select-customer'} style={{color: `${typeSearch === 'SEARCH_CUSTOM_CUSTOMER' ? '#FFF' : ''}`}}>*/}
                            {/*    {t('RecordsBeta.choice')}*/}
                            {/*</Link>*/}
                        </div>
                    </div>
                </div>
                <div className='pt-3'>
                    <div className="d-flex justify-content-around border-btn-status-group cursor-pointer p-0">
                        <div className={`border-width-each-status  font-text-on-btn first-btn
                                    ${typeSearchRead === 'UNREAD' ? 'btn-active' : ''}`} onClick={() => this.props.changeTypeSearchRecordUnRead('UNREAD')}
                        >
                            {t('RecordsBeta.unRead')}
                        </div>
                        <div className={`border-width-each-status font-text-on-btn third-btn 
                                     ${typeSearchRead === 'ALL' ? 'btn-active' : ''}`} onClick={() => this.props.changeTypeSearchRecordUnRead('ALL')}
                        >
                            {t('RecordsBeta.all')}
                        </div>
                        <div/>
                    </div>
                </div>
                <div className="fix-height-date-range">
                    <div className="pt-3 date-time-range ">
                        <KirokuDateRangePicker
                            isMobile
                            numberMonthSelected={1}
                            startDate={servicedAt.startDate}
                            endDate={servicedAt.endDate}
                            onChangeDate={this.handleOnChangeDateRangerServiceAt.bind(this)}
                        />
                    </div>
                </div>
                <div className="pt-3">
                    <KirokuInput name='keyword' placeholder={t('RecordsBeta.keyword')}
                                 value={keywords}
                                 style={{height: 42, fontSize: 16}}
                                 onChange={this.handleChangeKeyword.bind(this)}
                                 onBlur={this.handleSearchKeyword.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

export default RecordConditions;
