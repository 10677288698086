import React, { Component } from 'react';

class ComponentImage extends Component {

    render() {
        const { rotation, path } = this.props;

        return (
            <div className={'rotatable-image'} style={{
                backgroundImage: `url("${path}")`,
                transform      : `rotate(${rotation}deg)`,

            }}/>
        );
    }
}


class ComponentPDF extends Component {
    render() {
        return (
            <iframe title='document' id="iframe" src={this.props.path}/>
        );
    }
}

class ModalPreview extends Component {

    componentByType = {
        gif : ComponentImage,
        png : ComponentImage,
        jpg : ComponentImage,
        jpeg: ComponentImage,
        pdf : ComponentPDF,
        GIF : ComponentImage,
        PNG : ComponentImage,
        JPG : ComponentImage,
        JPEG: ComponentImage,
        PDF : ComponentPDF,
        // txt: ComponentPDF
    };

    state = {
        rotation: 0,
    };

    rotate(increase) {
        if (this.state.rotation >= 360) {
            return this.setState({
                rotation: 90,
            });
        }
        if (this.state.rotation <= -360) {
            return this.setState({
                rotation: -90,
            });
        }
        this.setState({
            rotation: increase ? this.state.rotation + 90 : this.state.rotation - 90,
        });
    }

    closeModal() {
        this.props.closeModal();
        this.setState({
            rotation: 0,
        });
    }

    render() {
        let TagHTML        = this.componentByType[ this.props.response.fileType ];
        TagHTML            = !this.props.show ? undefined : TagHTML;
        const { rotation } = this.state;
        return (
            <div className='modal-preview-file'>
                <div className={this.props.show ? 'opacity-main' : ''}>
                    <div className="overlay"/>
                </div>
                <div className={!this.props.show ? 'display-none' : 'modal-kiroku fade show'} style={{overflow: "hidden !important"}}>
                    <div className=" modal-dialog modal-dialog-centered" style={this.props.style}>
                        <div
                            className={`${['pdf', 'PDF'].includes(this.props.response.fileType) ? 'pdf' : 'png'} modal-content`}>

                            <div className="group-btn d-flex">
                                {!['pdf', 'PDF'].includes(this.props.response.fileType) && (
                                    <React.Fragment>
                                        <div>
                                            <i className="fas fa-2x fa-undo cursor-pointer"
                                               style={{ color: '#fff', paddingTop: 6 }}
                                               onClick={this.rotate.bind(this, false)}/>
                                        </div>
                                        <div>
                                            <i className="fas fa-2x fa-redo cursor-pointer pl-3 pr-3"
                                               style={{ color: '#fff', paddingTop: 6 }}
                                               onClick={this.rotate.bind(this, true)}/>
                                        </div>
                                    </React.Fragment>
                                )}
                                <div>
                                    <i className="fas fa-times cursor-pointer"
                                       onClick={() => this.closeModal()}
                                       style={{ color: '#fff', fontSize: 38 }}
                                    />
                                </div>
                            </div>
                            {TagHTML ?
                                <TagHTML rotation={rotation} path={this.props.response.path}/>
                                : ''}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default ModalPreview;
