import React, {Component}  from 'react';
import {withNamespaces}    from "react-i18next";
import KirokuInput         from "../../ui/KirokuInput";
import KirokuInputDate     from "../../ui/KirokuInputDate";
import KirokuDropdownClose from "../../ui/KirokuDropdownClose";
import moment              from "moment";

@withNamespaces()
class Guardianship extends Component {

    state = {
        customer: [],
        errors: {},
    };

    componentWillMount() {
        this.setState({
            customer: this.props.customer,
            startDate: this.props.customer.decision_date ? this.props.customer.decision_date : null
        })
    }

    selectGuardianshipType = (guardianshipTypes) => {
        return guardianshipTypes.map(guardianshipType => ({
            value: guardianshipType.value,
            component: (
                <div>
                    <span className={'text-muted'}>{guardianshipType.type}</span>
                </div>
            ),
            label: guardianshipType.type
        }))
    };

    handleChangeMonth(e) {
        let maximum = 12;
        let minimum = 0;
        this.props.onChangeCustomer(this.state.customer);
        if (e.target.value <= maximum && e.target.value >= minimum) {
            const customer          = this.state.customer;
            customer[e.target.name] = e.target.value;
            this.setState({
                customer: customer
            });
            this.props.onChangeCustomer(this.state.customer)
        }
    }

    handleChangeInput(e) {
        const customer          = this.state.customer;
        customer[e.target.name] = e.target.value;
        this.setState({
            customer: customer
        });
        this.props.onChangeCustomer(this.state.customer)
    }

    guardianshipTypes = [
        {
            id: 1,
            name: this.props.t('CustomerEditor.guardianshipTypes.Null'),
            type: this.props.t('CustomerEditor.guardianshipTypes.Null'),
            value: ''
        }, {
            id: 2,
            name: this.props.t('CustomerEditor.guardianshipTypes.Curatorship'),
            type: this.props.t('CustomerEditor.guardianshipTypes.Curatorship'),
            value: 'Curatorship'
        },
        {
            id: 3,
            name: this.props.t('CustomerEditor.guardianshipTypes.Guardianship'),
            type: this.props.t('CustomerEditor.guardianshipTypes.Guardianship'),
            value: 'Guardianship'
        },
        {
            id: 4,
            name: this.props.t('CustomerEditor.guardianshipTypes.Assistance'),
            type: this.props.t('CustomerEditor.guardianshipTypes.Assistance'),
            value: 'Assistance'
        },
        {
            id: 5,
            name: this.props.t('CustomerEditor.guardianshipTypes.Optional'),
            type: this.props.t('CustomerEditor.guardianshipTypes.Optional'),
            value: 'Optional'
        }
    ];

    currentGuardianshipType(guardianshipTypes) {
        const newCustomer                = this.state.customer;
        newCustomer['guardianship_type'] = guardianshipTypes.value;
        this.setState({
            customer: newCustomer
        });
        this.props.onChangeCustomer(this.state.customer)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.customer) {
            this.setState({
                customer: nextProps.customer,
                startDate: nextProps.customer.decision_date ? nextProps.customer.decision_date : null
            })
        }
    }

    handleDate = (date, nameDatePicker) => {
        if (moment(date, 'YYYY/MM/DD', true).isValid()) {
            let formatDate              = moment(date).unix();
            const newCustomer           = this.state.customer;
            newCustomer[nameDatePicker] = formatDate;
            this.setState({
                customer: newCustomer,
                errors: {
                    ...this.state.errors,
                    decisionDate: false
                }
            }, () => this.props.currentError(this.state.errors));
            this.props.onChangeCustomer(this.state.customer)
        } else {
            let formatDate              = moment(date).unix();
            const newCustomer           = this.state.customer;
            newCustomer[nameDatePicker] = formatDate;
            this.setState({
                errors: {
                    ...this.state.errors,
                    decisionDate: date.length
                }
            }, () => this.props.currentError(this.state.errors));
            this.props.onChangeCustomer(this.state.customer);

        }
    };

    render() {
        const {customer, startDate, errors} = this.state;
        let {t}                             = this.props;
        return (
            <div>
                <h5>{t('CustomerEditor.Guardianship')}</h5>
                <div className='row'>
                    <div className="col-md-8">
                        <label className="mt-1">{t('CustomerEditor.GuardianshipType')}</label>
                        <KirokuDropdownClose
                            title={customer.guardianship_type ? t(`CustomerEditor.guardianshipTypes.${customer.guardianship_type}`) : t('CustomerEditor.guardianshipTypes.Null')}
                            items={this.selectGuardianshipType(this.guardianshipTypes)}
                            onSelect={event => {
                                this.currentGuardianshipType(event.kirokuSelected)
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-4'>
                        <label className='mt-1'>{t('CustomerEditor.DecisionDate')}</label>
                        <KirokuInputDate
                            onChange={(date) => this.handleDate(date, 'decision_date')}
                            name={t('CustomerEditor.DecisionDate')}
                            defaultValue={startDate ? moment.unix(startDate) : ''}
                        />
                        <p hidden={!errors.decisionDate} style={{
                            color: 'red',
                            fontSize: 12
                        }}>{t('CustomerEditor.validate.ValidDate')}</p>
                        <label className='mt-1'>{t('CustomerEditor.AppointedType')}</label>
                        <KirokuInput
                            name='appointed_type'
                            placeholder={t('CustomerEditor.AppointedType')}
                            onChange={this.handleChangeInput.bind(this)}
                            value={customer.appointed_type || ''}
                        />

                        <label className='mt-1'>{t('CustomerEditor.CompetentCourt')}</label>
                        <KirokuInput
                            name='competent_court'
                            placeholder={t('CustomerEditor.CompetentCourt')}
                            onChange={this.handleChangeInput.bind(this)}
                            value={customer.competent_court || ''}
                        />
                    </div>
                    <div className="col-md-4">
                        <label className="mt-1">{t('CustomerEditor.CaseNumber')}</label>
                        <KirokuInput
                            name='case_number'
                            placeholder={t('CustomerEditor.CaseNumber')}
                            onChange={this.handleChangeInput.bind(this)}
                            value={customer.case_number || ''}
                        />
                        <label className="mt-1">{t('CustomerEditor.RegistrationNumber')}</label>
                        <KirokuInput
                            name='registration_number'
                            placeholder={t('CustomerEditor.RegistrationNumber')}
                            onChange={this.handleChangeInput.bind(this)}
                            value={customer.registration_number || ''}
                        />
                        <label className="mt-1">{t('CustomerEditor.PermanentAddress')}</label>
                        <KirokuInput
                            name='permanent_address'
                            placeholder={t('CustomerEditor.PermanentAddress')}
                            onChange={this.handleChangeInput.bind(this)}
                            value={customer.permanent_address || ''}
                        />
                    </div>
                    <div className="col-md-4">
                        <label className="mt-1">{t('CustomerEditor.ResidenceCardAddress')}</label>
                        <KirokuInput
                            name='residence_card_address'
                            placeholder={t('CustomerEditor.ResidenceCardAddress')}
                            onChange={this.handleChangeInput.bind(this)}
                            value={customer.residence_card_address || ''}
                        />
                        <label className="mt-1">{t('CustomerEditor.FacilityName')}</label>
                        <KirokuInput
                            name='facility_name'
                            placeholder={t('CustomerEditor.FacilityName')}
                            onChange={this.handleChangeInput.bind(this)}
                            value={customer.facility_name || ''}
                        />
                        <label className="mt-1">{t('CustomerEditor.ReportMonth')}</label>
                        <KirokuInput
                            name='report_month'
                            type='number'
                            min='1'
                            max='12'
                            onChange={this.handleChangeMonth.bind(this)}
                            placeholder={t('CustomerEditor.ReportMonth')}
                            value={customer.report_month || ''}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default Guardianship;
