import {
    ASSIGN_SURVEY_TO_CUSTOMER,
    CREATE_SURVEY,
    DELETE_SURVEY,
    FILTER_SURVEY_WITH_CONDITION,
    GET_SURVEY_BY_ID, GET_SURVEYS_BETA,
    LOAD_SURVEY_LIST_PAGE,
    LOAD_SURVEY_MODAL,
    LOAD_SURVEY_TEMPLATE_OF_CUSTOMER,
} from '../actions/Survey';
import {NOTIFICATION}                         from '../actions/Notification';
import {DETAIL_SURVEY}                        from '../actions/SurveyTemplate';
import {surveyFactory, surveyTemplateService} from '../services';

export const surveyMiddleware = (store) => next => action => {
    switch (action.type) {
        case GET_SURVEYS_BETA:
            return surveyTemplateService.getSurveysBeta().then(res => {
                next({
                    ...action,
                    ...res,
                })
            });
        case LOAD_SURVEY_LIST_PAGE :
            return surveyTemplateService.loadSurveys().then(loadSurveys => {
                next({
                    ...action,
                    page: loadSurveys.surveys.map(survey => ({
                        ...survey,
                        isChecked: false
                    })),
                    totalPage: loadSurveys.totalPage || 1,
                    currentPage: loadSurveys.currentPage || 1
                })
            }).catch(err => console.log(err));

        case LOAD_SURVEY_MODAL :
            return surveyTemplateService.loadSurveysModal().then(survey => {
                next({
                    type: LOAD_SURVEY_MODAL,
                    surveyForModal: survey.map(item => ({
                        ...item,
                        isChecked: false
                    }))
                })
            }).catch(error => console.log(error));
        case ASSIGN_SURVEY_TO_CUSTOMER :
            return surveyTemplateService.assignSurveys(action.surveys, action.customers).then(res => {
                // next({
                //     type: 'ASSIGN_SUCCESS'
                // });
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'success',
                    message: 'message.UpdateSuccess'
                })
            });

        case LOAD_SURVEY_TEMPLATE_OF_CUSTOMER:
            return surveyTemplateService.loadSurveyTemplateOfCustomer(action.surveyId, action.customerId).then(surveyTemplateOfCustomer => {
                next({
                    ...action,
                    surveyTemplateOfCustomer: surveyTemplateOfCustomer
                })
            }).catch(error => console.log(error));
        case FILTER_SURVEY_WITH_CONDITION :
            return surveyTemplateService.filterSurvey(action.params).then(page => {
                store.dispatch({
                    type: 'SET_FINISH_LOAD_API_SURVEYS'
                });
                next({
                    type: FILTER_SURVEY_WITH_CONDITION,
                    page: page.surveys,
                    totalPage: page.totalPage,
                    currentPage: page.currentPage
                })
            }).catch(err => console.log(err));
        case CREATE_SURVEY :
            const surveyCreate = surveyFactory.makeCreate(store.getState().KirokuAISurveyTemplate);
            return surveyTemplateService.createSurvey(surveyCreate).then(res => {
                next(action);
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'success',
                    message: "Surveys.Created"
                })
            });
        case DELETE_SURVEY :
            return surveyTemplateService.deleteSurveys(action.listId).then((res) => {
                next({
                    type: DELETE_SURVEY,
                    listId: action.listId
                });
                store.dispatch({
                    type: FILTER_SURVEY_WITH_CONDITION,
                    params: ! ! action.params ? ( ! !action.params.page ? action.params : {...action.params, page: 1})
                        : {page : 1}
                });
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'success',
                    message: 'Surveys.DeletedSuccessfully'
                })
            });

        case DETAIL_SURVEY :
            return surveyTemplateService.detail(action.id).then((survey) => {
                let newQuestions = [...survey.questions].map(question => {
                    if ((question.type === 'Single' || question.type === 'MultiChoice') && typeof question.choices[0] === 'string') {
                        return {
                            ...question,
                            choices: question.choices.map(choice => {
                                return {answer: choice}
                            }),
                        }
                    }
                    return {...question}
                });
                next({
                    ...action,
                    survey: {
                        ...survey,
                        questions: newQuestions
                    }
                })
            });
        case GET_SURVEY_BY_ID:
            return surveyTemplateService.detail(action.id).then((survey) => {
                next({
                    ...action,
                    detailSurvey: survey
                })
            });
        case 'LOAD_ALL_SURVEY_TEMPLATE':
            return surveyTemplateService.loadSurveysModal().then(res => {
                next({
                    ...action,
                    ListAllSurveyTemplate: res
                })
            }).catch(error => console.log(error));
        default:
            return next(action)
    }
};
