import React, {Component}      from 'react';
import {
    CheckDivide,
    KirokuCardBodyWeb,
    KirokuCardTitleWeb,
    KirokuCardWeb,
    KirokuTable,
    TBody,
    THeader,
    KirokuCheckBox, KirokuLabel
} from "./ui";
import {Link} from "react-router-dom";


export default class DemoWeb extends Component {
    render() {
        return (
            <div style={{background: '#eef3f6', minHeight: '100vh'}}>
                <div className='p-2'>
                    <h2>Table Web</h2>
                    <KirokuTable>
                        <THeader>
                            <tr>
                                <th style={{width: 50}}>
                                    <KirokuCheckBox/>
                                </th>
                                <th>Name</th>
                                <th style={{width: 50}}>Age</th>
                                <th>Kind of Certificate</th>
                                <th>Last Recorded Date</th>
                                <th>Action</th>
                            </tr>
                        </THeader>
                        <TBody>
                        <tr>
                            <td style={{width: 50}}>
                                <KirokuCheckBox/>
                            </td>
                            <td>Last Recorded Date</td>
                            <td style={{width: 50}}>53</td>
                            <td>Intellectual, Physical</td>
                            <td>8/10/2018</td>
                            <td>
                                <i className="fas fa-pencil-alt"/><span className='ml-1'>Edit</span>
                                <i className="fas fa-clone ml-3"/><span className='ml-1'>Duplicate</span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: 50}}>
                                <KirokuCheckBox/>
                            </td>
                            <td>Last Recorded Date</td>
                            <td style={{width: 50}}>53</td>
                            <td>Intellectual, Physical</td>
                            <td>8/10/2018</td>
                            <td>
                                <i className="fas fa-pencil-alt"/><span className='ml-1'>Edit</span>
                                <i className="fas fa-clone ml-3"/><span className='ml-1'>Duplicate</span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: 50}}>
                                <KirokuCheckBox/>
                            </td>
                            <td>Last Recorded Date</td>
                            <td style={{width: 50}}>53</td>
                            <td>Intellectual, Physical</td>
                            <td>8/10/2018</td>
                            <td>
                                <i className="fas fa-pencil-alt"/><span className='ml-1'>Edit</span>
                                <i className="fas fa-clone ml-3"/><span className='ml-1'>Duplicate</span>
                            </td>
                        </tr>
                        </TBody>
                    </KirokuTable>
                    <hr/>
                    <h2>Table combine or merge cells</h2>

                    <KirokuCardWeb>
                        <KirokuCardTitleWeb color={'white'}>
                            <div className='row'>
                                <div className="col-md-2">利用者名</div>
                                <div className="col-md-2">サービス提供時</div>
                                <div className="col-md-2">作成日時</div>
                                <div className="col-md-2">作成者</div>
                                <div className="col-md-2">最終更新</div>
                                <div className="col-md-2">者更新日時</div>
                            </div>
                            <hr className='mb-0'/>
                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb>
                            <div>
                                <div className='d-flex'>
                                    <Link to={'#'}><h6 className='mr-3 f-bold'>放課後デイ</h6></Link>
                                    <Link to={'#'}><h6 className='mr-3 f-bold'>放課後デイ</h6></Link>
                                    <KirokuLabel color='warning' style={{
                                        padding: '4px',
                                        width: 93
                                    }}>チーム名</KirokuLabel>
                                </div>
                            </div>
                            <div className='row pb-2'>
                                <div className="col-md-10">
                                    <div>https://sphinx.backlog.com/ViewAttachment.action?attachmentId=126701/sphinx.backlog.com/ViewAttachment.action?</div>
                                </div>
                                <div className="col-md-2 as">
                                    <i className="fas fa-pencil-alt"/><span className='ml-1'>Edit</span>
                                    <i className="fas fa-clone ml-3"/><span className='ml-1'>Duplicate</span>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-md-2">利用者名</div>
                                <div className="col-md-2">サービス提供時</div>
                                <div className="col-md-2">作成日時</div>
                                <div className="col-md-2">作成者</div>
                                <div className="col-md-2">最終更新</div>
                                <div className="col-md-2">者更新日時</div>
                            </div>
                            <hr/>
                            <div>
                                <div className='d-flex'>
                                    <Link to={'#'}><h6 className='mr-3 f-bold'>放課後デイ</h6></Link>
                                    <Link to={'#'}><h6 className='mr-3 f-bold'>放課後デイ</h6></Link>
                                    <KirokuLabel color='warning' style={{
                                        padding: '4px',
                                        width: 93
                                    }}>チーム名</KirokuLabel>
                                </div>
                            </div>
                            <div className='row pb-2'>
                                <div className="col-md-10">
                                    <div>https://sphinx.backlog.com/ViewAttachment.action?attachmentId=126701/sphinx.backlog.com/ViewAttachment.action?</div>
                                </div>
                                <div className="col-md-2 as">
                                    <i className="fas fa-pencil-alt"/><span className='ml-1'>Edit</span>
                                    <i className="fas fa-clone ml-3"/><span className='ml-1'>Duplicate</span>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-md-2">利用者名</div>
                                <div className="col-md-2">サービス提供時</div>
                                <div className="col-md-2">作成日時</div>
                                <div className="col-md-2">作成者</div>
                                <div className="col-md-2">最終更新</div>
                                <div className="col-md-2">者更新日時</div>
                            </div>
                            <hr/>
                            <div>
                                <div className='d-flex'>
                                    <Link to={'#'}><h6 className='mr-3 f-bold'>放課後デイ</h6></Link>
                                    <Link to={'#'}><h6 className='mr-3 f-bold'>放課後デイ</h6></Link>
                                    <KirokuLabel color='warning' style={{
                                        padding: '4px',
                                        width: 93
                                    }}>チーム名</KirokuLabel>
                                </div>
                            </div>
                            <div className='row pb-3'>
                                <div className="col-md-10">
                                    <div>https://sphinx.backlog.com/ViewAttachment.action?attachmentId=126701/sphinx.backlog.com/ViewAttachment.action?</div>
                                </div>
                                <div className="col-md-2 as">
                                    <i className="fas fa-pencil-alt"/><span className='ml-1'>Edit</span>
                                    <i className="fas fa-clone ml-3"/><span className='ml-1'>Duplicate</span>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-md-2">利用者名</div>
                                <div className="col-md-2">サービス提供時</div>
                                <div className="col-md-2">作成日時</div>
                                <div className="col-md-2">作成者</div>
                                <div className="col-md-2">最終更新</div>
                                <div className="col-md-2">者更新日時</div>
                            </div>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>

                    <hr/>
                    <h2>Card Web</h2>
                    <KirokuCardWeb>
                        <KirokuCardTitleWeb color={'white'}>
                            <h4 className='text-dark'>Table record</h4>
                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb>
                            <KirokuTable>
                                <THeader>
                                    <tr>
                                        <th style={{width: 50}}>
                                            <KirokuCheckBox/>
                                        </th>
                                        <th>Name</th>
                                        <th style={{width: 100}}>Age</th>
                                        <th>Kind of Certificate</th>
                                        <th>Last Recorded Date</th>
                                        <th>Action</th>
                                    </tr>
                                </THeader>
                                <TBody>
                                <tr>
                                    <td style={{width: 50}}>
                                        <KirokuCheckBox/>
                                    </td>
                                    <td>Last Recorded Date</td>
                                    <td style={{width: 100}}>53</td>
                                    <td>Intellectual, Physical</td>
                                    <td>8/10/2018</td>
                                    <td>
                                        <i className="fas fa-pencil-alt"/><span className='ml-1'>Edit</span>
                                        <i className="fas fa-clone ml-3"/><span className='ml-1'>Duplicate</span>
                                    </td>
                                </tr>
                                </TBody>
                            </KirokuTable>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                    <hr/>
                    <CheckDivide/>
                </div>
            </div>
        )
    }
}
