import React, {Component}        from 'react';
import KirokuInput               from "../../../../ui/KirokuInput";
import KirokuInputArea           from "../../../../ui/KirokuInputArea";
import DropDownTypeQuestions     from "../CreatorSurvey/DropDownTypeQuestions";
import {withNamespaces}          from "react-i18next";
import { connect }               from 'react-redux';
import { typedText, typingText } from '../../../../actions/LoadingAction';

@connect(state => {
    return {}
}, dispatch => {
    return {
        typing: () => {
            dispatch(typingText())
        },
        typed: () => {
            dispatch(typedText())
        }
    }
})
@withNamespaces()
class YesNo extends Component {


    onChangeHeading = (e) => {
        const heading = e.target.value;
        this.props.typing();
        this.props.onChangeHeading(heading);

    };

    onChangeContent = (e) => {
        let content = e.target.value;
        this.props.typing();
        this.props.onChangeContent(content)
    };
    handleOnBlur() {
        this.props.typed();
    }
    render() {
        const {t, isCreateSurvey} = this.props;
        return (
            <div>
                <div className='padding-responsive'>
                    <div className='mt-1 mb-1'>{t('createSurvey.Title')}</div>
                    <KirokuInput
                        value={this.props.heading}
                        onBlur={this.handleOnBlur.bind(this)}
                        onChange={this.onChangeHeading.bind(this)}
                    />
                    <div className='mt-1 mb-1'>{t('createSurvey.YourQuestion')}</div>
                    <KirokuInputArea
                        value={this.props.content}
                        onBlur={this.handleOnBlur.bind(this)}
                        onChange={this.onChangeContent.bind(this)}
                    />
                </div>
                <div className='bg-light'>
                    <div className='padding-responsive'>
                        <div className='mt-1 mb-1'>{t('createSurvey.ChooseType')}</div>
                        <DropDownTypeQuestions type={this.props.type} onSelect={(e) => {
                            this.props.onSelect(e)
                        }}
                                               isCreateSurvey={isCreateSurvey}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default YesNo;

