import React, {Component}         from 'react';
import {
    KirokuModalHeader,
    KirokuModalBody,
    KirokuModalFooter,
    KirokuButton,
    KirokuModal
}                                 from "../../../ui";
import {connect}                  from 'react-redux';
import {addLogic}                 from "../../../actions/SurveyMobile";
import {withNamespaces}           from "react-i18next";
import KirokuDropdownItem         from "../../../ui/KirokuDropdownItem";

@withNamespaces()
@connect(state => {
    return {
        questions: state.KirokuSurveyTemplateMobile.questions
    }
}, dispatch => {
    return {
        addLogic: (question, nextQuestion) => {
            dispatch(addLogic(question, nextQuestion))
        }
    }
})
class ModalAddLogicGeneral extends Component {

    state = {
        nextQuestion: '',
        label: '',
    };

    handleOnSelect(e) {
        this.setState({
            nextQuestion: e.kirokuSelected.value,
            label:e.kirokuSelected.label
        })
    }

    componentWillMount() {
        const {question, questions} = this.props;
        let label = '';
        questions.forEach(itemQuestion => {
            if(itemQuestion.index === question.nextQuestion) {
                label = itemQuestion.content
            }
        });
        this.setState({
            nextQuestion: question.nextQuestion,
            label: label
        });
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.question !== this.props.question) {
            const {question, questions} = nextProps;
            let label = '';
            questions.forEach(itemQuestion => {
                if(itemQuestion.index === question.nextQuestion) {
                    label = itemQuestion.content
                }
            });
            this.setState({
                nextQuestion: question.nextQuestion,
                label: label
            });
        }
    }


    handleConfirm() {
        let questionProps = this.props.question;
        let nextQuestion = this.state.nextQuestion;
        let question = {
            ...questionProps,
            nextQuestion
        };
        this.props.addLogic(question);
        this.props.closeModal();
    }

    dropDownItems(question, questions) {
        return questions.map((question) => {
            return {
                value: question.index,
                component: (<div>
                    <div><span className={'text-muted'}>{question.content}</span></div>
                </div>),
                label: question.content
            }
        });
    }

    handleCancel() {
        const {question, questions} = this.props;
        let label = '';
        questions.forEach(itemQuestion => {
           if(itemQuestion.index === question.nextQuestion) {
               label = itemQuestion.content
           }
        });
        this.setState({
            nextQuestion: question.nextQuestion,
            label: label
        });

        this.props.closeModal();
    }

    render() {
        const {question, questions, t} = this.props;
        return (
            <div>
                <KirokuModal show={!this.props.show}>
                    <KirokuModalHeader color={'card-web'}>
                        {t('createRecord.Question')} {question.index + 1}
                    </KirokuModalHeader>
                    <KirokuModalBody>
                        <div className='p-3'>
                            <b>{question.heading}</b>
                            <p>{question.content}</p>
                            <KirokuResetFilterConditionGeneral
                                title={this.state.label}
                                question={question}
                                items={this.dropDownItems(question, questions)}
                                onSelect={this.handleOnSelect.bind(this)}
                            />
                        </div>
                    </KirokuModalBody>
                    <KirokuModalFooter>
                        <KirokuButton onClick={this.handleCancel.bind(this)} color={'white'}>
                            {t('common.Cancel')}
                        </KirokuButton>
                        <KirokuButton onClick={this.handleConfirm.bind(this)} color={'primary'}>
                            {t('common.Confirm')}
                        </KirokuButton>
                    </KirokuModalFooter>
                </KirokuModal>
            </div>
        );
    }
}
class KirokuResetFilterConditionGeneral extends Component {

    state = {
        selected: {
            label: ''
        },
        value: '',
        popupVisible: false
    };

    handleSelected(event) {

        if(event.kirokuSelected.value > this.props.question.index) {
            this.props.onSelect(event);
            this.setState({
                popupVisible: false,
                selected: event.kirokuSelected
            });
        }
    }

    constructor() {
        super();

        this.handleClick        = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
    }

    handleOutsideClick(e) {
        if (!this.node) {
            return;
        }
        this.handleClick();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.title !== this.state.selected.label && nextProps.title !== this.state.value) {
            const newSelected = {...this.state.selected, label: nextProps.title};
            this.setState({
                selected: newSelected,
                value: nextProps.title
            });
        }
    }

    render() {
        return (
            <div ref={node => {
                this.node = node
            }}>
                <div className={`dropdown ${this.props.className ? this.props.className : ''}`}>
                    <button hidden={this.props.hidden}
                            disabled={this.props.disabled}
                            style={this.props.style}
                            className={`overflow btn btn-kiroku ${this.props.className ? this.props.className : ''}`}
                            onClick={this.handleClick.bind(this)}>
                        <i className={`${this.props.icon} left text-kiroku pr-5px`}/>
                        <span
                            className={'text-kiroku left'}>{this.state.selected.label ? this.state.selected.label : this.props.defaultValue}</span>
                        <i className={`${!this.state.popupVisible ? 'fa fa-chevron-down icon-kiroku' : 'fa fa-chevron-up icon-kiroku'}`}/>
                    </button>
                    {this.state.popupVisible && (
                        <div
                            className={`dropdown-kiroku dropdown-menu show ${this.props.location ? this.props.location : ''}`}
                            style={{position: 'absolute', willChange: 'top, left', left: 'auto', top: 'auto'}}>
                            <section className='scroll-data-dropdown-search'>
                                {this.props.items.map((item, index) => {
                                    const Item = item.component;
                                    if(item.value <= this.props.question.index) {
                                        return <KirokuDropdownItem
                                            style={{backgroundColor: '#e8ecf1', cursor: 'not-allowed'}}
                                            key={index} onClick={(event) => {
                                            event.kirokuSelected = item;
                                            this.handleClick(event);
                                            this.handleSelected(event)
                                        }}>
                                            {Item}
                                        </KirokuDropdownItem>
                                    }
                                    return (
                                        <KirokuDropdownItem key={index} onClick={(event) => {
                                            event.kirokuSelected = item;
                                            this.handleClick(event);
                                            this.handleSelected(event)
                                        }}>
                                            {Item}
                                        </KirokuDropdownItem>
                                    )
                                })}
                            </section>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
export default ModalAddLogicGeneral;
