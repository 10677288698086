import React, {Component} from 'react';
import "../teams.css";
import {createTeam}       from "../../../actions/Team";
import {connect}          from "react-redux";
import TeamUpdateMobile   from "../TeamEditor/TeamUpdateMobile";
import {loadUsers}        from "../../../actions/User";
import {callCreateTeam}   from "../../../actions/EditorTeam";
import {teamService}      from "../../../services";
import {NOTIFICATION}     from "../../../actions/Notification";
import {currentPage}      from "../../../actions/CurrentPage";
import KirokuTitlepage    from "../../../ui/KirokuTitlepage";

@connect(state => {
    return {
        team: state.KirokuAITeam.team,
        isLoading: state.Loading
    }
}, dispatch => {
    return {
        pageNow: (name) => {
            dispatch(currentPage(name))
        },
        createTeam: (team) => {
            dispatch(createTeam(team))
        },
        getUsers: () => {
            dispatch(loadUsers())
        },
        callCreateTeam: () => {
            dispatch(callCreateTeam())
        },
        notification: (t) => {
            dispatch({
                type: NOTIFICATION,
                typeNotification: 'success',
                message: t('Team.YouUpdateTeamAreSuccessful')
            })
        }
    }
})

class TeamCreatorMobile extends Component {
    page  = {
        title: <KirokuTitlepage title={this.props.t('Team.Team')}/>,
        actionPage: null
    };
    state = {
        team: {
            name: "",
            description: "",
            members: []
        }
    };

    componentWillMount(team) {
        this.props.callCreateTeam(team);
        this.props.getUsers()
    }

    createTeam(team) {
        this.props.pageNow(this.page);
        return teamService.create(team).then((team) => {
                this.props.notification(this.props.t);
                this.props.createTeam(team);
                this.props.history.push(`/teams/team-detail/${team.id}`)
            }
        );
        // this.props.createTeam(team);
        // this.props.history.push('/teams')

    }

    render() {
        return (
            <div id='mobile'>
                <TeamUpdateMobile
                    team={this.state.team}
                    history={this.props.history}
                    handleSubmit={this.createTeam.bind(this)}
                />
            </div>
        )
    }
}

export default TeamCreatorMobile;
