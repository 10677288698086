export const LOAD_SURVEY_LIST_PAGE            = 'LOAD_SURVEY_LIST_PAGE';
export const CHECK_SURVEY                     = 'CHECK_SURVEY';
export const CHECK_ALL_SURVEYS                = 'CHECK_ALL_SURVEYS';
export const ASSIGN_SURVEY_TO_CUSTOMER        = 'ASSIGN_SURVEY_TO_CUSTOMER';
export const RESET_SURVEYS                    = 'RESET_SURVEYS';
export const LOAD_SURVEY_TEMPLATE_OF_CUSTOMER = 'LOAD_SURVEY_TEMPLATE_OF_CUSTOMER';
export const SEARCH_SURVEYS                   = 'SEARCH_SURVEYS';
export const FILTER_SURVEY_WITH_CONDITION     = 'FILTER_SURVEY_WITH_CONDITION';
export const LOAD_SURVEY_MODAL                = 'LOAD_SURVEY_MODAL';
export const DELETE_SURVEY                    = 'DELETE_SURVEY';
export const CREATE_SURVEY                    = 'CREATE_SURVEY';
export const GET_SURVEY_BY_ID                 = 'GET_SURVEY_BY_ID';
export const CLOSE_SCREEN_DETAIL              = 'CLOSE_SCREEN_DETAIL';
export const UPDATE_SURVEY                    = 'UPDATE_SURVEY';
export const SAVE_QUERY_CONDITION             = 'SAVE_QUERY_CONDITION';
export const SET_CURRENT_PAGE                 = 'SET_CURRENT_PAGE';
export const GET_SURVEYS_BETA                 = 'GET_SURVEYS_BETA';
export const SET_DEFAULT_ANSWER_QUESTION      = 'SET_DEFAULT_ANSWER_QUESTION';

export const SET_DEFAULT_ANSWER_QUESTION_MOBILE = 'SET_DEFAULT_ANSWER_QUESTION_MOBILE';

export const DRAG_QUESTION_SURVEY = 'DRAG_QUESTION_SURVEY';

export function setDefaultAnswerQuestion(question) {
    return {
        type: SET_DEFAULT_ANSWER_QUESTION,
        question
    }
}

export function setDefaultAnswerQuestionMobile(question) {
    return {
        type: SET_DEFAULT_ANSWER_QUESTION_MOBILE,
        question
    }
}

export function updateSurvey(id, survey) {
    return {
        type : UPDATE_SURVEY,
        loginRequired : true,
        id,
        survey
    }
}
export function getSurveysBeta() {
    return {
        type : GET_SURVEYS_BETA,
    }
}

export function createSurvey(duplicate = false) {
    return {
        type         : CREATE_SURVEY,
        loginRequired: true,
        duplicate
    }
}

export function loadSurveyListPage(currentPage) {
    return {
        type         : LOAD_SURVEY_LIST_PAGE,
        currentPage  : currentPage,
        loginRequired: true
    }
}

export function loadSurveyModal() {
    return {
        type: LOAD_SURVEY_MODAL
    }
}

export function checkSurvey(index) {
    return {
        type         : CHECK_SURVEY,
        loginRequired: true,
        index        : index
    }
}

export function checkAllSurveys(checked) {
    return {
        type         : CHECK_ALL_SURVEYS,
        loginRequired: true,
        isChecked    : checked
    }
}

export function resetSurvey() {
    return {
        type         : RESET_SURVEYS,
        loginRequired: true
    }
}

export function assignSurveyToCustomer(surveys, customers) {
    return {
        type         : ASSIGN_SURVEY_TO_CUSTOMER,
        loginRequired: true,
        customers    : customers,
        surveys      : surveys
    }
}

export function loadSurveyTemplateOfCustomer(surveyId, customerId) {
    return {
        type         : LOAD_SURVEY_TEMPLATE_OF_CUSTOMER,
        loginRequired: true,
        surveyId,
        customerId
    }
}

export function handleSearchSurvey(keywords) {
    return {
        type    : SEARCH_SURVEYS,
        keywords: keywords
    }
}

export function filterSurveyWithCondition(params) {
    return {
        type  : FILTER_SURVEY_WITH_CONDITION,
        params: params
    }
}

export function deleteListSurvey(listId, params) {
    return {
        type  : DELETE_SURVEY,
        listId: listId,
        params: params
    }
}


export function notificationEditQuestion(flagEdit, indexQuestion) {
    return {
        type    : 'NOTIFICATION_EDIT_QUESTION',
        flagEdit: flagEdit,
        indexQuestion
    }
}

export function getDetailSurveyById(id) {
    return {
        type: GET_SURVEY_BY_ID,
        id
    }

}

export function closeScreenDetail() {
    return {
        type: CLOSE_SCREEN_DETAIL
    }
}

export function saveQueryCondition(conditions) {
    return {
        type : SAVE_QUERY_CONDITION,
        conditions
    }
}

export function setCurrentPage(numberPage) {
    return {
        type: SET_CURRENT_PAGE,
        numberPage
    }
}
export const handleDragQuestion = (dispatch) => (questions, currentIndex, nextIndex) => {
  dispatch({
      type: DRAG_QUESTION_SURVEY,
      questions, currentIndex, nextIndex
  })
};
