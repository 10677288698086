import React, {Component} from "react";

export default class KirokuShortDate extends Component {
    render() {
        const {isMobile} = this.props;
        return (
            <div className={`${isMobile ? 'row' : ''} shortcut-wrapper`}>
                <div className={`${isMobile ? 'col-10 wrap-text-customs-date' : 'wrap-text-customs-date'} d-flex`}>
                    {this.props.children}
                </div>
                <div hidden={!isMobile} className={'col-2 font-size-20 p-0'} onClick={() => this.props.closeSelectModal()}>x</div>
            </div>
        )
    }
}
