import React, {Component} from 'react';
import InputText          from '../../Mobile/QuestionTypeSurveyMobile/InputText';
import DateTime           from '../../Mobile/QuestionTypeSurveyMobile/DateTime';
import MediaFile          from '../../Mobile/QuestionTypeSurveyMobile/MediaFile';
import Message            from '../../Mobile/QuestionTypeSurveyMobile/Message';
import MultiChoice        from '../../Mobile/QuestionTypeSurveyMobile/MultiChoice';
import SingleChoice       from '../../Mobile/QuestionTypeSurveyMobile/SingleChoice';
import YesNo              from '../../Mobile/QuestionTypeSurveyMobile/YesNo';
import Time               from '../../Mobile/QuestionTypeSurveyMobile/Time';
import OnlyDate           from '../../Mobile/QuestionTypeSurveyMobile/OnlyDate';
import Number           from '../../Mobile/QuestionTypeSurveyMobile/Number';
import {
    nextQuestionFromSurvey,
    nextQuestion,
    editQuestion,
    showReview,
    showAddQuestion,
    selectTypeQuestion, copyQuestionForTemplateOnMobile,
} from '../../../../actions/SurveyMobile';
import {connect}          from "react-redux";

import { KirokuButton, KirokuNotification } from '../../../../ui';
import {withNamespaces}                     from "react-i18next";
import {filePathService}                    from "../../../../services";
import { NOTIFICATION }                     from '../../../../actions/Notification';

@withNamespaces()
@connect(state => {
    return {
        typingText: state.LibState.typingText,
        questions: state.KirokuSurveyTemplateMobile.questions,
        error: state.KirokuSurveyTemplateMobile.errorQuestion
    }
}, dispatch => {
    return {
        backToComponent: (typeComponent, options) => {
            dispatch(nextQuestionFromSurvey(typeComponent, options))
        },

        nextQuestion: (orderQuestion) => {
            dispatch(nextQuestion(orderQuestion))
        },

        onChangeQuestion: async (question, error) => {
            dispatch(editQuestion(question, error))
        },

        showReview: () => {
            dispatch(showReview())
        },

        newQuestion: () => {
            dispatch(showAddQuestion())
        },

        selectTypeQuestion : (question) => {
            dispatch(selectTypeQuestion(question))
        },
        copyQuestionForTemplateOnMobile : (question) => {
            dispatch(copyQuestionForTemplateOnMobile(question))
        },
        notification : (t) => {
            dispatch({
                type : NOTIFICATION,
                typeNotification : 'success',
                message : t('message.questionCopiedSuccess')
            })
        }
    }
})
class Question extends Component {

    questionMap = {
        InputText: InputText,
        Single: SingleChoice,
        YesNo: YesNo,
        Message: Message,
        MultiChoice: MultiChoice,
        Date: DateTime,
        Media: MediaFile,
        OnlyDate : OnlyDate,
        Time : Time,
        Number : Number

    };

    state = {
        choices: [{answer: ''}, {answer: ''}],
        showReview: true,
        hiddenCopiedIcon : false
    };

    onSelectQuestion = (e) => {
        let type = e.kirokuSelected.value;
        this.props.selectTypeQuestion({...this.props.question, choices: this.state.choices, type: type})
    };

    disableNextQuestion = () => {
        const {question, lastQuestion, error} = this.props;
        const choices = question.choices || this.state.choices;
        const errorChoices = choices.every(choice => choice.answer !== '');
        switch (question.type) {
            case 'Single':
            case 'MultiChoice':
                return (question.index === lastQuestion.index) || (!question.content || !errorChoices) || error.content;
            default :
                return (question.index === lastQuestion.index) || error.content || !question.content
        }
    };

    nextQuestion = () => {
        const {question} = this.props;
        this.props.nextQuestion(question.index + 1)
    };


    onClickNewQuestion = () => {
        this.props.backToComponent('AddQuestion', 'InputText');
    };

    showReview = () => {
        this.props.backToComponent('Review');
    };

    createQuestion = () => {

    };

    onChangeHeading = (heading) => {
        this.props.onChangeQuestion({...this.props.question, heading: heading});
    };

    onChangeContent = (content) => {
        this.props.onChangeQuestion({...this.props.question, content: content})
    };

    removeAnswer(e, index) {
        let choices = this.props.question.choices || this.state.choices;
        if (choices.length > 2) {
            let newChoices = choices.map(choice => ({...choice}));
            newChoices.splice(index, 1);
            this.props.onChangeQuestion({...this.props.question, choices: newChoices})
        }
    }

    addAnswer() {
        let choices = this.props.question.choices || this.state.choices;
        choices = choices.map(choice => ({...choice}));
        choices.push({answer: ''});
        this.props.onChangeQuestion({...this.props.question, choices: choices}).then(() => {
            return this.getLastChoiceElement(choices)
        });
    }

    getLastChoiceElement = (choices) => {
        window.scrollTo(0,document.body.scrollHeight);
        const element = document.querySelectorAll('#choicesQuestions > div > .d-flex > input[name="answer"]')[choices.length-1];
        element.focus();
    };

    answerOnchange(answer, index) {
        let choices = this.props.question.choices || this.state.choices;
        let newChoices = choices.map(choice => ({...choice}));
        newChoices[index].answer = answer;
        this.props.onChangeQuestion({...this.props.question, choices: newChoices});
    }

    backQuestion = () => {
        const {question} = this.props;
        if (question.index === 0) {
            return this.props.backToComponent('Survey');
        }
        this.props.nextQuestion(question.index - 1);
    };

    submitEdit = () => {
        this.props.handleSubmit();
    };

    disableSubmitSurvey() {
        const {question} = this.props;
        switch (question.type) {
            case 'Single':
            case 'MultiChoice':
                const choices = question.choices || this.state.choices;
                const errorChoices = choices.every(choice => choice.answer !== '');
                return !question.content || !errorChoices;
            default :
                return !question.content
        }
    }

    disablePreview() {
        const {question, error} = this.props;
        const choices = question.choices || this.state.choices;
        const errorChoices = choices.every(choice => choice.answer !== '');
        switch (question.type) {
            case 'Single' :
            case 'MultiChoice':
                return (!question.content || !errorChoices) || error.content;
            default :
                return !question.content
        }
    }

    handleCopyQuestion = (question) => {
        const {t} = this.props;
        this.props.notification(t);
        this.props.copyQuestionForTemplateOnMobile(question);
        this.setState({
            hiddenCopiedIcon : true
        }, () => {
            setTimeout(() => this.setState({
                hiddenCopiedIcon : false
            }), 3000)
        });
    };


    render() {
        const {question, t, typingText, questions} = this.props;
        const Question = this.questionMap[question.type];
        const numberQuestion = question.index + 1;
        return (
            <div>
                <div className='create-header'>
                    <KirokuNotification/>
                    <div className='d-flex justify-content-between pt-2 pl-2 pr-2'>
                        <div className="d-flex">
                            <h5 className='ass'>{t('createSurvey.QuestionOrder', {index : numberQuestion})}</h5>
                            &nbsp;
                            <i className="fas fa-copy fa-lg" style={{ paddingTop : 4}} hidden={this.state.hiddenCopiedIcon}
                               onClick={ () => this.handleCopyQuestion(question)}/>
                        </div>
                        <span className='pl-1 pt-10px text-success'>
                            <i className="fas fa-check-circle pr-1 text-success" hidden={typingText}/>
                                { typingText ? t('common.Saving') : t('common.Save')}
                        </span>
                        <div className='d-flex p-2' style={{width: 150}}>
                            <KirokuButton
                                disabled={this.disablePreview()}
                                onClick={this.showReview.bind(this)}
                                style={{width: '50%'}}
                                color={this.state.showReview ? 'success' : 'white'}>
                                <img width='18px' height='18px' alt='' src={filePathService.resolveFilePath('preview.png')}/>
                            </KirokuButton>
                            <div className='pl-1 pr-1'/>
                            <KirokuButton
                                onClick={this.onClickNewQuestion.bind(this)}
                                style={{width: '50%'}}
                                color={this.state.showReview ? 'white' : 'success'}>
                                <i className="fas fa-plus"/>
                            </KirokuButton>
                        </div>
                    </div>
                    {/*<div className='pl-2 pr-2 text-danger'>{t('createSurvey.EditLossLogic')}</div>*/}
                </div>
                <div className='pb-5'>
                    <Question type={question.type}
                              heading={question.heading || ''}
                              content={question.content || ''}
                              choices={question.choices || this.state.choices}
                              onChangeHeading={this.onChangeHeading.bind(this)}
                              onChangeContent={this.onChangeContent.bind(this)}
                              answerOnchange={this.answerOnchange.bind(this)}
                              addAnswer={this.addAnswer.bind(this)}
                              removeAnswer={this.removeAnswer.bind(this)}
                              onSelect={this.onSelectQuestion.bind(this)}
                              isCreateSurvey={question.type !== 'YesNo'}
                    />
                </div>
                <div className='action-record'>
                    <div className="row" style={{height: 42}}>
                        <div className='col-6 p-0'>
                            <KirokuButton style={{width: '100%', borderRadius: 0, height: '100%'}}
                                          color={'light'}
                                          onClick={this.backQuestion.bind(this)}>
                                {t('createSurvey.Back')}
                            </KirokuButton>
                        </div>
                        <div className='col-6 p-0'>
                            {
                                question.index === questions.length - 1 ? (<KirokuButton
                                    color={'primary'}
                                    style={{width: '100%', borderRadius: 0, height: '100%'}}
                                    onClick={this.submitEdit.bind(this)}
                                    disabled={this.disableSubmitSurvey()}
                                >
                                    {t('createSurvey.Submit')}
                                </KirokuButton>) : (<KirokuButton
                                    disabled={this.disableNextQuestion()}
                                    color={'primary'}
                                    style={{width: '100%', borderRadius: 0, height: '100%'}}
                                    onClick={this.nextQuestion.bind(this)}
                                >
                                    {t('createSurvey.NextQuestion')}
                                </KirokuButton>)
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Question;
