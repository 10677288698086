import React, {Component}       from 'react';
import CardTotalWeb             from "./CardTotalWeb";
import {connect}                from "react-redux";
import {comparisonPreviousTerm} from "../../../actions/Dashboard";
import {withNamespaces}         from "react-i18next";

@withNamespaces()
@connect(state => {
    return {
        lastMonth: state.KirokuAIDashboard.lastMonth,
        lastWeek: state.KirokuAIDashboard.lastWeek,
        yesterday: state.KirokuAIDashboard.yesterday,
        myCustomers: state.KirokuAIDashboard.myCustomers,
        dashboard: state.KirokuAIDashboard.dashboard,
        loading: state.Loading,
        profile: state.KirokuAIDashboard.profile,
        loadingDashboardSuccess: state.KirokuAIDashboard.loadingDashboardSuccess,
    }
}, dispatch => {
    return {
        comparisonPreviousTerm: () => {
            dispatch(comparisonPreviousTerm())
        }
    }
})

class PitOutRecord extends Component {

    componentWillMount() {
        // this.props.comparisonPreviousTerm();
    }

    render() {
        const {t, dashboard} = this.props;
        return (
            <div>
                <div>
                    <h5 className='pt-3 pb-3 pr-3 recent-title'>{dashboard['organization_name'] + t('Dashboard.pitOutRecord')}</h5>
                    <div className='d-flex justify-content-between'>
                        <div className='card-dashboard mr-1'>
                            <CardTotalWeb text={t(`Dashboard.todayRecordNumber`)}
                                          icon={'fas fa-file-signature'}
                                          changeData={dashboard['comparedData'].yesterday}
                            />
                        </div>
                        <div className='card-dashboard mr-1'>
                            <CardTotalWeb text={t(`Dashboard.lastSevenDayRecordNumber`)}
                                          icon={'fas fa-file-signature'}
                                          changeData={dashboard['comparedData'].lastWeek}
                            />
                        </div>
                        <div className='card-dashboard mr-1'>
                            <CardTotalWeb text={t(`Dashboard.lastThirtyDayRecordNumber`)}
                                          icon={'fas fa-file-signature'}
                                          changeData={dashboard['comparedData'].lastMonth}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PitOutRecord;