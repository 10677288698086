import React, {Component} from 'react';
import {CheckDivide}      from "../../../ui";
import StaffCreatorWeb    from "./StaffCreatorWeb";
import StaffCreatorMobile from "./StaffCreatorMobile";
import {scrollService}    from "../../../services";
import connect            from "react-redux/es/connect/connect";
import {currentPage}      from "../../../actions/CurrentPage";
import {withNamespaces}   from "react-i18next";
import KirokuTitlepage    from "../../../ui/KirokuTitlepage";

@withNamespaces()
@connect(state => {
    return {}
}, dispatch => {
    return {
        currentPageUsed: (name) => {
            dispatch(currentPage(name))
        }
    };
})
class Staffs extends Component {
    page = {
        title: <KirokuTitlepage title={this.props.t('common.staff')}/>,
    };

    componentWillMount() {
        scrollService.top();
        this.props.currentPageUsed(this.page);
    }

    render() {
        let mobile = <StaffCreatorMobile history={this.props.history}/>;
        let web    = <StaffCreatorWeb history={this.props.history}/>;

        return (
            <div className='default-background-color'>
                <CheckDivide mobile={mobile} web={web}/>
            </div>
        )
    }
}

export default Staffs;