import GenericQuestion from "./GenericQuestion";

class Single extends GenericQuestion {

    setDefaultAnswer() {
        let answers = {};
        // convert data old choice = ['answer_1', 'answer_2']
        this.payload.choices = this.payload.choices.map(choice => {
            if(typeof choice === 'string') {
                return {answer: choice}
            }
            return {
                ...choice,
            }
        });

        this.payload.choices.forEach((choice, index) => {
            if(choice['defaultAnswer']) {
                answers = {...choice, is_choice: index}
            }
        });

        this.payload.answers = answers;
        delete answers['defaultAnswer'];
        return this.payload.answers;
    }

    deleteDefaultAnswer() {
        this.payload.choices = this.payload.choices.map(choice => {
            delete choice.defaultAnswer;
            return choice;
        })
    }

    answer(answers) {

        if(!answers) {
            this.payload.answers = {};
            return this.getNext({});
        }

        this.payload.answers = answers;
        return this.getNext(answers);
    }

    getNext(answer) {

        if(!answer) {
            return this.payload.index + 1;
        }

        if(answer.nextQuestion === 0) {
            return answer.nextQuestion
        }

        return answer.nextQuestion || this.payload.index + 1
    }

    getNextQuestion() {
        return this.payload.choices
    }
}
export default Single;
