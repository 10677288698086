import React, { Component }      from 'react';
import KirokuRadio               from '../../../../ui/KirokuRadio';
import { withNamespaces }        from 'react-i18next';
import KirokuLogicApplicated     from "../../../../ui/KirokuLogicApplicated";
import {checkUseHeadingQuestion} from "../../../Surveys/SurveyEditor/SurveyService";

@withNamespaces()
class SingleChoice extends Component {

    render() {
        const {question, value, surveyDetail, headerNextQuestion, t} = this.props;
        const contentQuestion      = checkUseHeadingQuestion(question);
        const is_choice = value.is_choice || value.is_choice === 0 ? value.is_choice : value.index;
        const singleQuestion = question.choices.map(item =>  {
            return {nextQuestion: item.nextQuestion}
        });
        return (
            <div className='content-question'>
                <div className='wraper-question'>
                    <div className="background-question">
                        <div className='number-question d-flex justify-content-between'>
                            <div className="question-number text-overflow-elip">
                                {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                            </div>
                            <div hidden={!surveyDetail} className={'float-right'}>
                                <KirokuLogicApplicated padding={'5px 5px 5px 7px'} question={{...question, singleQuestion}}/>
                            </div>
                        </div>
                        <span className='content-name-question'>{question.content}</span>
                    </div>
                    <div className='answer-question'>
                        {question.choices.map((choice, index) =>
                            <div key={index}>
                                <KirokuRadio
                                    key={index}
                                    checked={is_choice === index}
                                    readOnly
                                    onChange={() => {}}
                                >
                                    <span style={{color : '#495057', cursor : 'no-drop' }}>{choice.answer}</span>
                                </KirokuRadio>
                                <div
                                    hidden={ typeof choice.nextQuestion !== 'number' || !surveyDetail }
                                    className='pl-10 text-overflow-elip text-info'>
                                    <i className='fas fa-arrow-right' style={{paddingTop: 4}}/>
                                    <span> {headerNextQuestion[index]}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default SingleChoice
