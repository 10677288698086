export default class DashboardService {
    constructor(axios) {
        this.axios = axios;
    }

    dashboard() {
        return this.axios.get(`/dashboard-statistic`).then(res => res.data)
    }

    comparisonPreviousTerm() {
        return this.axios.get(`/comparison-previous-term`).then(res => res.data)
    }

    dashboardActivities(page) {
        return this.axios.get(`/dashboard-activities`, {
            params: {
                page
            }
        }).then(res => res.data)
    }

}
