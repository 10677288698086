class GenericQuestion {
    constructor(questionPayload) {
        this.payload = questionPayload;
    }

    setDefaultAnswer() {
        this.payload.answers = this.payload['defaultAnswer'] || '';
        return this.payload.answers;
    }

    deleteDefaultAnswer() {
        delete this.payload.defaultAnswer;
        return this.payload;
    }

    getAnswer() {
        return this.payload.answers
    }

    getIndex() {
        return this.payload.index;
    }

    answer(answer) {
        this.payload.answers = answer;

        return this.getNext(answer);
    }

    isDisabled() {
        return this.payload.visibility
    }

    disable() {
        return this.payload.visibility = true;
    }

    enable() {
        return this.payload.visibility = false;
    }

    getType() {
        return this.payload.type;
    }

    getNext(answer) {
        throw new Error('Please specify the next index in concurrent sub Question type');
    }

    getNextQuestion() {
        if(typeof this.payload.nextQuestion === 'string') {
            return this.payload.index + 1;
        }
        return this.payload.nextQuestion
    }

    toJSON() {
        return this.payload
    }
}

export default GenericQuestion;
