import {Component} from "react";
import React from "react";

export default class KirokuInputTel extends Component {

    render() {
        const props = this.props;
        return (
            <input {...props} className="form-control-kiroku"/>
        )
    }
}