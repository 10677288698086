import React, {Component}          from 'react';
import {withNamespaces}            from 'react-i18next';
import KirokuLogicApplicated       from '../../../../../../ui/KirokuLogicApplicated';

@withNamespaces()
class Message extends Component {

    render() {
        const {question, surveyDetail, headerNextQuestion} = this.props;

        return (
            <div className='content-question-type'>
                <div className='wraper-question none-border-question pt-2'>
                    <div className="background-question">
                        <div className=' d-flex justify-content-between'>
                            <div
                                className="text-overflow-elip font-heading-questions">{question.heading ? question.heading : ""}</div>
                            <div hidden={!surveyDetail} className={'float-right'}><KirokuLogicApplicated
                                padding={'5px 5px 5px 7px'} question={question}/></div>
                        </div>
                        <div className='padding-content-question'>
                            <span className='font-content-question'>{question.content}</span>
                        </div>
                        <div
                            hidden={typeof question.nextQuestion !== 'number' || !surveyDetail}
                            className='pl-10 text-overflow-elip text-info'>
                            <i className="fas fa-arrow-right"/>
                            <span> {headerNextQuestion}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Message
