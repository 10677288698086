import {combineReducers}          from 'redux';
import KirokuSurveyTemplateMobile from './SurveyMobile';
import KirokuAISurvey             from './Survey';
import KirokuAITeam               from './Team';
import KirokuAIDraftRecording     from './Draft';
import KirokuAICustomer           from './Customer';
import KirokuAIRecord             from './Record';
import KirokuAIUser               from './User';
import KirokuAINotification       from './Notification';
import KirokuAISurveyTemplate     from './SurveyTemplate';
import KirokuAICurrentPage        from './CurrentPage';
import Loading              from './Loading';
import TakeSurveyWeb        from './TakeSurveyWeb';
import TakeSurveyMobile     from './TakeSurveyMobile';
import KirokuAIOrganization from './Oganization'
import KirokuAILog          from './Logs'
import KirokuAIReject       from './Auth';
import EditorTeam           from './EditorTeam';
import ReducerAdminExport   from './AdminExport';
import KirokuAILine         from "./Line";
import ReduceActionLayout   from "./ActionLayout";
import KirokuDraftSurvey    from "./DraftSurvey";
import KirokuAIDashboard      from './Dashboard'
import {KirokuAIAnalysis}       from './Analysis';
import LibState               from './LibState';
import KirokuAIBehavior       from "./Behavior";
import RecordEditorNew        from "./RecordEditorNew";
import KirokuExport           from "./Export"
import RecordsNew             from "./RecordsNew";
import Reports                from "./Reports";
import DateTimePicker         from "./DateTimePicker"
import LoginSupportPage       from "./loginSupportPage";
import { DeletedList }        from "./DeletedList";
import DeletedSurveys         from "./DeletedSurveys";
import DeletedRecords         from "./DeletedRecords";
import DeletedStaffs          from "./DeletedStaffs";
import DeletedCustomer        from "./DeletedCustomer";
import RecordsBeta            from "./RecordsBeta";
import KirokuModal            from "./Modal";
import KirokuScreens          from "./Screen";
import KirokuLockInfo         from "./Locks"
export default combineReducers({
    KirokuExport,
    KirokuAIDashboard,
    KirokuAIReject,
    KirokuAILog,
    KirokuAICustomer,
    KirokuAISurvey,
    KirokuAITeam,
    KirokuAIDraftRecording,
    KirokuAIRecord,
    KirokuAIUser,
    KirokuAINotification,
    KirokuAISurveyTemplate,
    KirokuAICurrentPage,
    Loading,
    TakeSurveyWeb,
    TakeSurveyMobile,
    KirokuAIOrganization,
    KirokuSurveyTemplateMobile,
    EditorTeam,
    ReducerAdminExport,
    KirokuAILine,
    ReduceActionLayout,
    KirokuDraftSurvey,
    KirokuAIAnalysis,
    LibState,
    KirokuAIBehavior,
    RecordEditorNew,
    RecordsNew,
    Reports,
    DateTimePicker,
    LoginSupportPage,
    DeletedList,
    DeletedSurveys,
    DeletedRecords,
    DeletedCustomer,
    DeletedStaffs,
    RecordsBeta,
    KirokuModal,
    KirokuScreens,
    KirokuLockInfo
})
