import {
    CHECK_ALL_MEMBER,
    CHECK_MEMBER, FILTER_STAFF_WITH_CONDITION, GET_STAFFS_BETA,
    LOAD_STAFF_IN_TEAM, LOAD_USER_BY_ID,
    LOAD_USER_PAGINATION,
    LOAD_USERS,
    RESET_MEMBER_CHOICE, SET_ROLE, SYNC_PROFILE,
} from '../actions/User';
import * as _             from "lodash";
import {ON_SEARCH}        from "../actions/Behavior";

const defaultApplicationSate = {
    users: [],
    user: {},
    staffs: [],
    usersForPagination: [],
    defaultUser: [],
    defaultMemberSelect: [],
    totalPage: 1,
    currentPage: 1,
    profile: JSON.parse(localStorage.getItem('profile')),
    isLoading: true,
    defaultTags: [],
    loadingUserSuccess: false,
    defaultCustomerTags: [],
    defaultTeamTags : [],
    staffsBeta: [],
};

function groupUser(userBefore, userAfter) {
    return _.differenceBy(userBefore, userAfter, 'id');
}

const KirokuAIUser = (state = defaultApplicationSate, action) => {
    switch (action.type) {
        case GET_STAFFS_BETA:
            return {
                ...state,
                staffsBeta: action.staffs
            };
        case 'RESET_STAFF_TYPE':
            return  {
                ...state,
                defaultCustomerTags: [],
                defaultTeamTags : []
            };
        case LOAD_USER_PAGINATION:
            return {
                ...state,
                usersForPagination: action.users,
                totalPage: action.totalPage,
                currentPage: action.currentPage,
                isLoading: true,
                profile: state.profile,
                defaultCustomerTags: action.tags.tags.filter(tag => tag.type === 'Customers'),
                defaultTeamTags: action.tags.tags.filter(tag => tag.type === 'Teams')
            };
        case LOAD_USERS:
            let newUser = state.users.concat(groupUser(action.users, state.users));
            return {
                ...state,
                loadingUserSuccess: true,
                users: newUser,
                defaultUser: newUser.map(item => ({...item}))
            };
        case LOAD_STAFF_IN_TEAM :
            return {
                ...state,
                staffs: action.staffs
            };

        case CHECK_MEMBER:
            let memberRecord                     = state.users.map(survey => ({...survey}));
            memberRecord[action.index].isChecked = !memberRecord[action.index].isChecked;
            return {
                ...state,
                users: memberRecord
            };
        case CHECK_ALL_MEMBER:
            let newMember = state.users.map(user => {
                return {
                    ...user,
                    isChecked: !action.isChecked
                }
            });
            return {
                ...state,
                users: newMember
            };
        case 'GROUP_USER' :
            let users = state.users.concat(groupUser(action.users, state.users));
            return {
                ...state,
                users: users
            };
        case RESET_MEMBER_CHOICE :
            return {
                ...state,
                users: state.defaultUser

            };
        case ON_SEARCH :
            let keywords      = action.keywords;
            let currentMember = [...state.defaultUser].filter(member =>
                member.name.toLowerCase().search(keywords.toLowerCase()) !== -1);
            return {
                ...state,
                users: currentMember,
            };

        case LOAD_USER_BY_ID :
            return {
                ...state,
                user: action.user,
                isLoading: false
            };
        case SYNC_PROFILE :
            return {
                ...state,
                profile: {
                    ...action.profile,
                },
            };
        case 'INIT_STATE':
            return {
                ...defaultApplicationSate,
                defaultCustomerTags: state.defaultCustomerTags,
                defaultTeamTags: state.defaultTeamTags,
            };
        case SET_ROLE :
            return {
                ...state,
                usersForPagination: action.users,
                totalPage: action.totalPage,
                currentPage: action.currentPage
            };
        case FILTER_STAFF_WITH_CONDITION :
            return {
                ...state,
                usersForPagination: action.users,
                totalPage : action.totalPage,
                currentPage :  action.currentPage
            };
        default:
            return state;
    }
};

export default KirokuAIUser;
