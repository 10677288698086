import React, { Component } from 'react';
import {
    KirokuInputSearchOnBlur
}                           from "../../../../ui";
import { connect }          from "react-redux";
import {withNamespaces}     from "react-i18next";

@withNamespaces()
@connect(state => {
    return {
        keyword: state.DeletedCustomer.keyword
    }
}, dispatch => {
    return {
        changeKeywordInput: (value) => {
            dispatch({
                type: 'CHANGE_SEARCH_KEYWORD',
                keyword: value
            })
        },
        searchKeyWord: () => {
            dispatch({
                type : 'SUBMIT_SEARCH_KEYWORD'
            })
        }
    }
})
class ConditionCustomer extends Component {

    handleChangeKeyword(e) {
        this.props.changeKeywordInput(e.target.value)
    }

    handleSearchKeyWord() {
        this.props.searchKeyWord();
    }

    render() {
        const {t, keyword } = this.props;
        return (
            <div className={'font-size-condition'}>
                <KirokuInputSearchOnBlur
                    value={keyword}
                    onSubmit={this.handleSearchKeyWord.bind(this)}
                    onBlur={this.handleSearchKeyWord.bind(this)}
                    placeholder={t('DeletedList.searchByName')}
                    onChange={this.handleChangeKeyword.bind(this)}
                />
            </div>
        )
    }
}
export default ConditionCustomer
