import React, { Component } from 'react';
import ConditionRecordsBeta from './ConditionRecordsBeta';
import TableRecord          from './TableRecord';
import { Paginator }        from '../../../../ui';
import { Link }             from 'react-router-dom';
import { withNamespaces }   from 'react-i18next';
import { connect }          from 'react-redux';
import qs                   from 'query-string';
import config               from '../../../../config';
@connect(state => {
    return {
        items       : state.RecordsBeta.items,
        totalPage   : state.RecordsBeta.totalPage,
        currentPage : state.RecordsBeta.currentPage,
        recordsBeta : state.RecordsBeta.recordsBeta,
        statusRecord: state.RecordsBeta.statusRecord,
        totalRecords: state.RecordsBeta.totalRecords,
        customerIds : state.RecordsBeta.customerIds,
        servicedAt  : state.RecordsBeta.servicedAt,
        updatedAt   : state.RecordsBeta.updatedAt,
        templateIds : state.RecordsBeta.templateIds,
        updateByIds : state.RecordsBeta.updateByIds,
        useSortBy   : state.RecordsBeta.useSortBy,
        useOrderBy  : state.RecordsBeta.useOrderBy,
        keywordsEd  : state.RecordsBeta.keywordsEd,
        containRead  : state.RecordsBeta.typeSearchRead === 'ALL',
    };
}, dispatch => {
    return {
        changeNumberPage: (currentPage) => {
            dispatch({
                type: 'CHANGE_NUMBER_PAGE_RECORDS',
                currentPage
            })
        },
        handleChangeInitRecordBeta: () => {
            dispatch({
                type : 'SET_INIT_CONDITION_RECORD_BETA',
            })
        }
    };
})
@withNamespaces()
class RecordsWeb extends Component {


    handleChangePageSize(e) {
        this.props.changeNumberPage(e.kirokuSelected.value)
    }

    handlerOnClickChangePage(e) {
        const {currentPage} = this.props;
        let currentPageNew = e === 'next' ? currentPage + 1 : currentPage -1;
        this.props.changeNumberPage(currentPageNew)
    }

    render() {
        const { items, currentPage, t, recordsBeta, useSortBy,
                  totalPage, statusRecord, totalRecords,
                  customerIds,templateIds, updateByIds,
                  servicedAt, updatedAt, useOrderBy, keywordsEd,
                  containRead
        } = this.props;
        const params = {
            status: statusRecord,
            customerIds: customerIds,
            templateIds: templateIds,
            updateByIds: updateByIds,
            serviceAtStartDate: servicedAt.momentStartDate,
            serviceAtEndDate: servicedAt.momentEndDate,
            updatedAtStartDate: updatedAt.momentStartDate,
            updatedAtEndDate: updatedAt.momentEndDate,
            ordersField: useSortBy === 'serviced_at' ? "records.serviced_at" : "records.last_action_at",
            ordersType: useOrderBy,
            keywordSearch: keywordsEd,
            containRead : containRead
        };
        return (
            <div className={'default-background-color padding-responsive'}>
                <ConditionRecordsBeta/>
                <div className={'d-flex justify-content-between pt-4'}>
                    <div className="color-btn-paginator">
                        <Paginator numberPage={currentPage}
                                   items={items}
                                   onPaginatorChange={this.handleChangePageSize.bind(this)}
                                   onClickNext={this.handlerOnClickChangePage.bind(this, 'next')}
                                   onClickBack={this.handlerOnClickChangePage.bind(this, 'back')}
                                   page={currentPage}
                                   hasNext={currentPage <  totalPage}
                        />
                    </div>
                    <div>
                        <div className="d-flex justify-content-between">
                            <div className={'pt-2 font-clear-condition-btn re-native-link'} onClick={() => this.props.handleChangeInitRecordBeta()}>
                                {t('RecordsBeta.clearCondition')}
                            </div>
                            <div className={'pt-2 pl-1 pr-1'} style={{color: '#757575'}}> | </div>
                            <div className={'group-batch-action pt-2'}>
                                {!(!!recordsBeta.length && statusRecord === 'recorded') ?
                                    <div style={{color: 'gray'}}>{t('RecordsBeta.batchActionBtn')}</div> :
                                    <div className={'batch-action'}>
                                        <Link to={{
                                            pathname: `/batch-action-records-beta/search`,
                                            search  : `${qs.stringify(params, config.formatParams)}`,
                                        }}
                                              target={'_blank'}
                                        >{t('RecordsBeta.batchAction', { count: totalRecords })}
                                        </Link>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-1 pt-2"/>
                <TableRecord history={this.props.history}/>
                <div className={'d-flex justify-content-between pt-2'}>
                    <div className="color-btn-paginator">
                        <Paginator numberPage={currentPage}
                                   location={'top'}
                                   items={items}
                                   onPaginatorChange={this.handleChangePageSize.bind(this)}
                                   onClickNext={this.handlerOnClickChangePage.bind(this, 'next')}
                                   onClickBack={this.handlerOnClickChangePage.bind(this, 'back')}
                                   page={currentPage}
                                   hasNext={currentPage < totalPage}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default RecordsWeb;
