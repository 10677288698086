import React, {PureComponent}       from 'react'
import KirokuModalBody              from "../../ui/KirokuModalBody";
import KirokuCardList               from "../../ui/KirokuCardList";
import KirokuModalFooter            from "../../ui/KirokuModalFooter";
import KirokuButton                 from "../../ui/KirokuButton";
import KirokuModal                  from "../../ui/KirokuModal";
import KirokuInputSearch        from "../../ui/KirokuInputSearch";
import KirokuModalHeader        from "../../ui/KirokuModalHeader";
import {connect}                from "react-redux";
import {assignSurveyToCustomer} from "../../actions/Survey";
import './Surveys.css';
import KirokuLoading            from "../../ui/KirokuLoading";
import {withNamespaces}         from "react-i18next";
import {
    KirokuCard,
    KirokuCheckBox,
    KirokuSectionDropdownShowMore
} from "../../ui";
import _                        from "lodash";

@withNamespaces()
@connect((state) => {
    return {
        isLoading: state.KirokuAITeam.isLoading,
        teams: state.KirokuAITeam.allTeam,
        customerForSurvey: state.KirokuAICustomer.customerForSurvey,
    }
}, (dispatch) => {
    return {
        assignSurveyToCustomer: (surveys, customers) => {
            dispatch(assignSurveyToCustomer(surveys, customers))
        }
    }
})


class ModalAssignSurveyToCustomer extends PureComponent {

    state = {
        statusCheck: false,
        show: false,
        customersSelected: [],
        teams: [],
        customerForSurvey: [],
        initState: [],
        customerForSurveyRecord: [],
        loadingSearch: false,
        currentTags: [],
        defaultOption: [],
        defaultCustomer: [],
        keywordCustomer: '',
        optionsChangeTags: []
    };

    assignSurvey() {
        let customerSelected = this.state.customerForSurvey.filter(item => item.isChecked);
        let customers        = customerSelected.map(customer => ({
            id: customer.id,
            organization_id: customer.organization_id
        }));
        let surveyChoice     = this.props.surveys.map(survey => ({
            id: survey.id
        }));
        this.props.assignSurveyToCustomer(surveyChoice, customers);
        this.props.closeModalAssignCustomer();
    }

    closeModal() {
        this.setState({
            customersSelected: [],
            customerForSurveyRecord: this.state.initState,
            customerForSurvey: this.state.initState.map(item => ({...item})),
        });
        this.props.closeModalAssignCustomer();
    }

    handleStateApply = (value) => {
        let total = 0;
        value.map(item => item.customers.map(customer => customer.selected && total++));
        return total;
    };

    handleChangeTags = (options) => {
        let customers = [];
        if (options.length) {
            options.map(item => {
                customers = customers.concat(item.customers);
                return item;
            });
            customers = customers.map(customer => {
                const indexCustomerNew = _.findIndex([...this.state.defaultCustomer], {'id': customer.id});
                if (customer.id === this.state.defaultCustomer[indexCustomerNew].id) {
                    return {
                        ...customer,
                        isChecked: this.state.defaultCustomer[indexCustomerNew].isChecked
                    }
                }
                return {
                    ...customer,
                }
            });
            customers = _.uniqBy(customers, 'id');
            this.setState({
                currentTags: options ? options : this.state.defaultOption,
                customerForSurveyRecord: customers,
                customerForSurvey: customers,
                statusCheck: _.findIndex(customers, {'isChecked': false}) === -1
            });
        } else {
            this.setState({
                currentTags: options ? options : this.state.defaultOption,
                customerForSurveyRecord: this.state.defaultCustomer,
                customerForSurvey: this.state.defaultCustomer,
                statusCheck: _.findIndex(this.state.defaultCustomer, {'isChecked': false}) === -1
            })
        }
        this.setState({
            optionsChangeTags : options,
        }, () => {
            if(this.state.keywordCustomer) {
                this.searchCustomer({
                    target: {
                        value: this.state.keywordCustomer
                    }
                })
            }
        });

    };

    searchCustomer(e) {
        let input       = e.target.value.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
        this.setState({keywordCustomer: input});
        let customer    = this.state.customerForSurveyRecord.filter(customer =>
            customer.name.toLowerCase().search(input.toLowerCase()) !== -1
        );
        let checkedUser = customer.filter(item => item.isChecked).length;
        this.handleCheckStatus(checkedUser, customer);
        this.setState({
            customerForSurvey: customer,
        });
        if(!this.state.optionsChangeTags.length) {
            let customer    = [...this.state.defaultCustomer].filter(customer =>
                customer.name.toLowerCase().search(input.toLowerCase()) !== -1
            );
            let checkedUser = customer.filter(item => item.isChecked).length;
            this.handleCheckStatus(checkedUser, customer);
            this.setState({
                customerForSurvey: customer,
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.customerForSurvey.length) {
            this.setState({
                customerForSurvey: nextProps.customerForSurvey.map(item => ({...item})),
                customerForSurveyRecord: nextProps.customerForSurvey.map(item => ({...item})),
                defaultCustomer: nextProps.customerForSurvey.map(item => ({...item})),
                initState: nextProps.customerForSurvey.map(item => ({...item})),
                loadingSearch: true
            });
        }
    }

    handleCheckAll() {
        let {customerForSurvey, defaultCustomer} = this.state;
        let customers           = customerForSurvey.map(customer => {
            const indexCustomerInDefaultCustomer = _.findIndex([...defaultCustomer], {'id': customer.id});
            defaultCustomer[indexCustomerInDefaultCustomer] = {
                ...defaultCustomer[indexCustomerInDefaultCustomer],
                isChecked: !this.state.statusCheck
            };
            return {
                ...customer,
                isChecked: !this.state.statusCheck
            }
        });
        this.setState({
            statusCheck: !this.state.statusCheck,
            customerForSurvey: this.make(this.state.customerForSurvey, customers, !this.state.statusCheck),
            customerForSurveyRecord: this.make(this.state.customerForSurveyRecord, customers, !this.state.statusCheck)
        });
    }

    make(customerForSurvey, customerForCompare, status) {
        return customerForSurvey.map(customer => {
            return {
                ...customer,
                isChecked: (_.findIndex(customerForCompare, (item) => customer.id === item.id) >= 0) ? status : customer.isChecked
            }
        })
    }

    handleCheckCustomer(customerCompare) {
        let {customerForSurvey, customerForSurveyRecord, defaultCustomer} = this.state;
        let arrayCustomer                                = customerForSurvey.map(customer => {
            if (customer.id === customerCompare.id) {
                return {
                    ...customer,
                    isChecked: !customer.isChecked
                }
            }
            return customer;
        });
        let arrayCustomerRecord                          = customerForSurveyRecord.map(customer => {
            if (customer.id === customerCompare.id) {
                return {
                    ...customer,
                    isChecked: !customer.isChecked
                }
            }
            return customer;
        });
        let arrayDefaultCustomerRecord                          = defaultCustomer.map(customer => {
            if (customer.id === customerCompare.id) {
                return {
                    ...customer,
                    isChecked: !customer.isChecked
                }
            }
            return customer;
        });
        let checkedCustomer                              = arrayCustomer.filter(item => item.isChecked).length;
        this.handleCheckStatus(checkedCustomer, arrayCustomer);
        this.setState({
            customerForSurvey: arrayCustomer,
            customerForSurveyRecord: arrayCustomerRecord,
            defaultCustomer: arrayDefaultCustomerRecord
        })
    }

    handleCheckStatus(checkedUser, array) {
        if (checkedUser === array.length) {
            this.setState({
                statusCheck: true
            })
        } else {
            this.setState({
                statusCheck: false
            })
        }
    }

    isSelect() {
        return !this.state.customerForSurvey.filter(item => item.isChecked).length
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <KirokuModal show={this.props.show}>
                    <KirokuModalHeader>
                        <h6 className="mb-3">{t('createSurvey.AssignSurveyTemplateToCustomer')} </h6>
                        <div>
                            <KirokuInputSearch onChange={this.searchCustomer.bind(this)}
                                               placeholder={t('createSurvey.EnterCustomerNameOrTeamName')}/>
                        </div>
                        <div className={'pt-3'}>
                            <KirokuSectionDropdownShowMore
                                tagType={'Team'}
                                placeholder={t('Team.placeholderSearch')}
                                searchCustomerTemplate
                                onChange={this.handleChangeTags.bind(this)}
                                value={this.state.currentTags}
                                options={this.props.teams}
                            />
                        </div>
                    </KirokuModalHeader>
                    <KirokuModalBody>
                        <div id="body-modal-as">
                            <KirokuCardList
                                headerLeft={t('common.Name')}
                                headerRight={
                                    <div className='text-primary' style={{cursor: 'pointer'}}>
                                        <span hidden={this.state.statusCheck}
                                              onClick={this.handleCheckAll.bind(this)}
                                        >{t('common.SelectAll')}</span>

                                        <span hidden={!this.state.statusCheck}
                                              onClick={this.handleCheckAll.bind(this)}
                                        >{t('common.UnSelect')}</span>
                                    </div>
                                }
                            >
                                <section className="scroll-data-survey">
                                    {
                                        this.state.loadingSearch ?
                                            this.state.customerForSurvey.map((customer, index) => {
                                                return <KirokuCard key={index}>
                                                    <KirokuCheckBox
                                                        onChange={this.handleCheckCustomer.bind(this, customer)}
                                                        key={index}
                                                        checked={customer.isChecked || false} content={customer.name}/>
                                                </KirokuCard>
                                            }) :
                                            <KirokuLoading/>
                                    }
                                </section>
                            </KirokuCardList>
                        </div>
                    </KirokuModalBody>
                    <KirokuModalFooter>
                        <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                            {t('common.Cancel')}
                        </KirokuButton>
                        <KirokuButton disabled={this.isSelect()}
                                      onClick={this.assignSurvey.bind(this)}
                                      color={'primary'}>
                            {t('common.Apply')}
                        </KirokuButton>
                    </KirokuModalFooter>
                </KirokuModal>
            </div>


        )
    }
}

export default ModalAssignSurveyToCustomer;
