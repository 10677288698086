import React, {Component} from 'react';
import {KirokuCardWeb}    from "../../../../ui";
import CustomerProfile    from "../../CustomerProfile/CustomerProfile";

class TabProfileCustomer extends Component {
	render () {
		const {customer} = this.props;
		return (
			<div hidden={this.props.hidden}>
				<KirokuCardWeb>
					<div className="p-3">
						{<CustomerProfile customer={customer}/>}
					</div>
				</KirokuCardWeb>
			</div>
		)
	}
}

export default TabProfileCustomer;