import momentTZ from "moment-timezone";

export default class ExportService {
    constructor(axios) {
        this.axios = axios;
    }

    exportAll(condition, tags) {
        return this.axios.post(`/export/all`, {
            ...condition,
            tags,
            recordIds: [],
            lang: localStorage.getItem('i18nextLng') || 'ja',
            timezone: momentTZ.tz.guess()
        }, {
            params: {
                ...condition,
            }
        }).then(res => res.data)
    }
    newExportAll(condition) {
        return this.axios.post(`/export/search-condition`, {
            ...condition,
            lang: localStorage.getItem('i18nextLng') || 'ja',
            timezone: momentTZ.tz.guess()
        }).then(res => res.data)
    }

    exportCSVAll(condition) {
        return this.axios.post(`/export/csv`, {
            ...condition,
            lang: localStorage.getItem('i18nextLng') || 'ja',
            timezone: momentTZ.tz.guess()
        }).then(res => res.data)
    }

    loadRecordsNewExport(tags, params) {
        return this.axios.post(`/records-export`, {tags: tags}, {
            params: {
                customerId: params.customerId,
                page: params.page || 1,
                pageSize: params.pageSize || 10,
                staffId: params.staffId || '',
                endDate: params.dateRange ? isNaN(params.dateRange.endDate) ? '' : params.dateRange.endDate : '',
                startDate: params.dateRange ? params.dateRange.startDate : '',
                status: params.status || '',
                templateId: params.templateId || '',
                title: params.title || ''
            }
        }).then(res => res.data);
    }

    selectedRecord(records, params, tags) {
        let recordIds = records.map(record => record.id);
        return this.axios.post(`/export/ids`, {
            tags,
            recordIds: recordIds,
            lang: localStorage.getItem('i18nextLng') || 'ja',
            timezone: momentTZ.tz.guess()
        }, {
            params: {
                ...params,
            }
        }).then(res => res.data)
    }

    selectedRecordCsv(records, params, tags) {
        let recordIds = records.map(record => record.id);
        return this.axios.post(`/export/csv-ids`, {
            tags,
            recordIds: recordIds,
            lang: localStorage.getItem('i18nextLng') || 'ja',
            timezone: momentTZ.tz.guess()
        }, {
            params: {
                ...params,
            }
        }).then(res => res.data)
    }

    exportDocx(params, records, dateRange) {
        let token     = localStorage.getItem('token');
        let lang      = localStorage.getItem('i18nextLng') || 'ja';
        let timezone  = momentTZ.tz.guess();
        let recordIds = records.map(record => record.id);

        return this.axios.post(`/report/docx/ids/${params}`,{
            recordIds: recordIds,
        },{
            params: {
                startDate: dateRange.startDate || '',
                endDate: dateRange.endDate || '',
                page: 1,
                lang: lang,
                timezone: timezone,
                token: token
            }
        }).then(res => res.data)
    }

    printReport(type, tags, params){
        // return this.axios.get(`/all/${type}`, {
        //     tags,
        //     recordIds: [],
        //     lang     : localStorage.getItem('i18nextLng') || 'ja',
        //     timezone : momentTZ.tz.guess()
        // }, {
        //     params: {
        //         customerId: params.customerId,
        //         endDate   : params.endDate ? params.endDate : '',
        //         page      : params.page || 1,
        //         pageSize  : params.pageSize || 10,
        //         staffId   : params.staffId || '',
        //         startDate : params.startDate ? params.startDate : '',
        //         status    : params.status || '',
        //         templateId: params.templateId || '',
        //         title     : params.title || ''
        //     }
        // })
    }
}
