import React, {Component}        from 'react';
import KirokuInput               from "../../../../ui/KirokuInput";
import KirokuInputArea           from "../../../../ui/KirokuInputArea";
import DropDownTypeQuestions     from "../CreatorSurvey/DropDownTypeQuestions";
import KirokuActionSurvey        from "../../../../ui/KirokuActionSurvey";
import {withNamespaces}          from "react-i18next";
import { connect }               from 'react-redux';
import { typedText, typingText } from '../../../../actions/LoadingAction';

@connect(state => {
    return {}
}, dispatch => {
    return {
        typing: () => {
            dispatch(typingText())
        },
        typed: () => {
            dispatch(typedText())
        }
    }
})
@withNamespaces()
class SingleChoice extends Component {

    onChangeHeading = (e) => {
        const heading = e.target.value;
        this.props.typing();
        this.props.onChangeHeading(heading);

    };

    onChangeContent = (e) => {
        let content = e.target.value;
        this.props.typing();
        this.props.onChangeContent(content)
    };

    addAnswer = () => {
        this.props.addAnswer();
    };

    answerOnchange(e, index) {
        this.props.answerOnchange(e.target.value, index);
    }

    removeAnswer(e, index) {
        this.props.removeAnswer(e, index);
    }
    handleOnBlur() {
        this.props.typed();
    }
    render() {
        const {choices, t, isCreateSurvey} = this.props;
        return (
            <div className='pb-5'>
                <div className='padding-responsive'>
                    <div className='mt-1 mb-1'>{t('createSurvey.Title')}</div>
                    <KirokuInput
                        value={this.props.heading}
                        onBlur={this.handleOnBlur.bind(this)}
                        onChange={this.onChangeHeading.bind(this)}
                    />
                    <div className='mt-1 mb-1'>{t('createSurvey.YourQuestion')}</div>
                    <KirokuInputArea
                        value={this.props.content}
                        onBlur={this.handleOnBlur.bind(this)}
                        onChange={this.onChangeContent.bind(this)}
                    />
                </div>
                <div className='bg-light'>
                    <div className='padding-responsive'>
                        <div className='mt-1 mb-1'>{t('createSurvey.ChooseType')}</div>
                        <DropDownTypeQuestions type={this.props.type} onSelect={(e) => {
                            this.props.onSelect(e)
                        }}
                                               isCreateSurvey={isCreateSurvey}
                        />
                    </div>
                </div>
                <div className='padding-responsive' id={'choicesQuestions'}>
                    {
                        choices.map((choice, index) =>
                            <div key={index}>
                                <p className='pt-3'>{t('createSurvey.Answer')}</p>
                                <div className='d-flex'>
                                    <KirokuInput value={choice.answer}
                                                 name={'answer'}
                                                 onChange={(e) => this.answerOnchange(e, index)}
                                                 placeholder={t('createSurvey.EnterAnAnswerChoice')}/>
                                    <div className='action-fix pl-4'>
                                        <div onClick={(e) => this.removeAnswer(e, index)}>
                                            <KirokuActionSurvey icon={'minus'}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className='pt-3 pb-5 action-fix-plus'>
                        <div onClick={this.addAnswer.bind(this)} className='d-flex'>
                            <KirokuActionSurvey icon={'plus'}/>
                            <span className='pl-2'>{t('createSurvey.AddOtherAnswer')}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SingleChoice;
