import {
    ACTION_BACK_ANSWER,
    ACTION_BACK_SCREEN_DETAIL,
    ACTION_BEGIN_ANSWER,
    ACTION_LOAD_PAGE_DETAIL,
    CLOSE_CONFIRM_EXIT,
    SHOW_CONFIRM_EXIT, TURN_OFF_FLAG_LOAD_DRAFT_EDIT_RECORD_MOBILE,
    TURN_OFF_FLAG_LOAD_DRAFT_MOBILE,
    TURN_ON_LOAD_DRAFT,
    TURN_ON_LOAD_DRAFT_EDIT_RECORD
} from '../actions/TakeSurvey';

const defaultApplicationSate = {
    flagLoadDraft          : false,
    beginAnswer            : false,
    reviewRecorded         : false,
    recorded               : null,
    modalConfirmExit       : false,
    flagLoadDraftEditRecord: false
};


const TakeSurveyMobile = (state = defaultApplicationSate, action) => {
    switch (action.type) {
        case TURN_ON_LOAD_DRAFT:
            return {
                ...action,
                ...state,
                flagLoadDraft: true
            };
        case TURN_OFF_FLAG_LOAD_DRAFT_MOBILE:
            return {
                ...state,
                flagLoadDraft: false
            };
        case ACTION_BEGIN_ANSWER:
            return {
                ...action,
                ...state,
                beginAnswer: true
            };
        case ACTION_BACK_SCREEN_DETAIL:
            return {
                ...action,
                ...state,
                beginAnswer: false
            };
        case ACTION_LOAD_PAGE_DETAIL:
            return {
                ...state,
                ...action,
                reviewRecorded: true,
                recorded      : action.recorded
            };
        case ACTION_BACK_ANSWER:
            return {
                ...action,
                ...state,
                reviewRecorded: false,
                recorded      : null
            };
        case SHOW_CONFIRM_EXIT:
            return {
                ...state,
                modalConfirmExit: true
            };
        case CLOSE_CONFIRM_EXIT:
            return {
                ...state,
                modalConfirmExit: false
            };
        case TURN_ON_LOAD_DRAFT_EDIT_RECORD:
            return {
                ...state,
                flagLoadDraftEditRecord: true
            };
        case TURN_OFF_FLAG_LOAD_DRAFT_EDIT_RECORD_MOBILE:
            return {
                ...state,
                flagLoadDraftEditRecord: false
            };
        default:
            return state
    }
};

export default TakeSurveyMobile;