import React, {Component}                 from 'react';
import {connect}                          from 'react-redux';
import './style.css';
import {languageService, filePathService} from '../../services';
import {withNamespaces}                   from 'react-i18next';
import config                             from '../../config';
import qs                                 from 'qs';
import createBrowserHistory               from 'history/createBrowserHistory';
import { Link }                           from "react-router-dom";

const newHistory = createBrowserHistory();

@withNamespaces()
@connect(state => {
    return {
        token: state.token,
        profile: state['userProfile'],
        message: state.KirokuAIReject.message,
        error: state.KirokuAIReject.error,
        isTFA: state.KirokuAIReject.isTfa
    };
}, dispatch => {
    return {
        login: (credential, typeLogin) => {
            dispatch({
                type: 'LOGIN',
                credential,
                typeLogin
            })
        }
    };
})

class LoginForm extends Component {
    state = {
        languages: languageService.getLanguages(),
        activeLang: localStorage['i18nextLng'],
        status: false,
        type: 'password',
        error: false
    };

    credential = {
        username: '',
        password: '',
        otp     : ''
    };


    choiceLanguage(language) {
        this.props.i18n.changeLanguage(language);
        this.setState({
            activeLang: language
        })
    }

    changeStatus() {
        this.setState({
            status: !this.state.status,
        });
    }

    componentWillMount() {
        let querySearch = qs.parse(newHistory['location'].search, {
            plainObjects: true,
            ignoreQueryPrefix: true
        });

        if (querySearch['state'] === config.line.state_login) {
            this.props.login({code: querySearch['code']}, 'line');
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.error) {
            this.setState({
                error: nextProps.error,
            })
        }
    }

    submitLogin(e) {
        e.preventDefault();
        this.props.login(this.credential, 'username')
    }

    handleUsernameChange(e) {
        this.credential.username = e.target.value;
        this.setState({
            error: false
        })
    }

    handleOtpChange(e) {
        this.credential.otp = e.target.value;
        this.setState({
            error: false
        })
    }

    handlePasswordChange(e) {
        this.credential.password = e.target.value;
        this.setState({
            error: false
        })
    }

    render() {
        let {t} = this.props;
        return (
            <div className='page-login'>
                <div className="box-login">
                    <div className="tile">
                        <div className="language">
                            <div className="language d-flex" style={{justifyContent: 'space-between'}}>
                                <div style={{
                                    height: 30,
                                    width: 113,
                                    background: 'no-repeat',
                                    backgroundImage: `url(${filePathService.resolveFilePath('logo-kiroku.svg')})`}} />
                                <div/>
                                <div className="flag">
                                    {this.state.languages.map((lang, index) => (
                                        <div key={index} onClick={this.choiceLanguage.bind(this, lang.key, index)}
                                             className={this.state.activeLang === lang.key ? lang.class + ' activeLang' : lang.class + 'unActive'}
                                             style={{
                                                 float: 'right',
                                                 backgroundImage : `url(${filePathService.resolveFilePath(`${lang.key}.png`)})`}}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="card-login">
                            <div className="tile-header">
                                <h2 className="login-logo" style={{height: 50, marginBottom: 35}}>
                                    {/*{t('Login.login')}*/}
                                </h2>
                            </div>
                            <div className="tile-body">
                                <form id="form-login" onSubmit={this.submitLogin.bind(this)}>
                                    {!!this.props.isTFA ? (
                                        <div>
                                            <div className="auth-form-header p-0">
                                                <h1>{t('Login.TwoFactorRecovery')}</h1>
                                            </div>
                                            <label className="form-input">
                                                <input name="otp"
                                                       type="text"
                                                       value={this.credential.otp}
                                                       onChange={this.handleOtpChange.bind(this)}
                                                       autoComplete='otp'
                                                />
                                                <span className="label">{t('Login.authenticationCode')}</span>
                                                <div className="underline"/>
                                            </label>
                                        </div>
                                    ) : (
                                        <div>
                                            <label className="form-input">
                                                <input name="username"
                                                       type="text"
                                                       value={this.credential.username}
                                                       onChange={this.handleUsernameChange.bind(this)}
                                                       autoComplete='username'
                                                />
                                                <span className="label">{t('Login.username')}</span>
                                                <div className="underline"/>
                                            </label>
                                            <label className="form-input">
                                                <input type={!this.state.status ? 'password' : 'text'}
                                                       onChange={this.handlePasswordChange.bind(this)}
                                                       value={this.credential.password}
                                                       name="password"
                                                       autoComplete='password'
                                                />
                                                <span className="label">{t('Login.password')}</span>
                                                <span className='change-status-password'>
                                            <i onClick={this.changeStatus.bind(this)}
                                               className={this.state.status ? 'fas fa-eye-slash' : 'fas fa-eye'}/>
                                        </span>
                                                <div className="underline"/>
                                            </label>
                                        </div>
                                    )}
                                    <p className='text-danger text-center'
                                       style={{textAlight: 'justify'}}
                                       hidden={!this.state.error}>
                                        {t(`Login.${this.props.message.message}`)}
                                    </p>
                                    <div className="submit-container clearfix">
                                        <button id="submit" type='submit' className="btn btn-login">
                                            {t('Login.login')}
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div hidden={!this.props.isTFA}>
                                <div className="hr-login">
                                    <span className='hr-login-content'>{t('Login.Two-factor-help')}</span>
                                </div>
                                <div className='login-tfa'>
                                    <div>
                                        {t('Login.tfaDescription')}
                                    </div>
                                </div>
                            </div>

                            <div hidden={!!this.props.isTFA}>
                                <div className="hr-login">
                                    <span className='hr-login-content'>{t('common.support')}</span>
                                </div>
                                <div style={{textAlign: 'center'}} className={'pt-2'}>
                                    <Link to={'/surfly'}>{t('common.support')}</Link>
                                </div>
                                <div className="hr-login">
                                    <span className='hr-login-content'>{t('common.about')}</span>
                                </div>
                                <div style={{textAlign: 'center'}} className={'pt-2'}>
                                    <a href={'https://pitout.co.jp/kirokuai/'} target={"_blank"}>{t('common.aboutKiroKuAI')}</a>
                                </div>
                                <div style={{textAlign: 'center'}} className={'pt-2'}>
                                    <Link to={'/terms'} target={"_blank"}>{t('common.terms')}</Link>
                                </div>
                                <div style={{textAlign: 'center'}} className={'pt-2'}>
                                    <Link to={'/privacy'} target={"_blank"}>{t('common.policy')}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces('translations')(LoginForm);
