import React, {Component} from 'react';
import {withNamespaces}   from "react-i18next";
import connect            from "react-redux/es/connect/connect";
import {hiddenLayout}     from "../../../actions/ActionLayout";

@withNamespaces()
@connect(state => {
    return {
        hiddenLayout: state.ReduceActionLayout.hiddenLayout
    }
}, dispatch => {
    return {
        hiddenLayout: () => {
            dispatch(hiddenLayout())
        }
    }
})
class NotRecord extends Component {

    componentWillMount() {
        this.props.hiddenLayout();
    }

    render() {
        const {t} = this.props;
        return (
            <div className='page-error default-background-color'>
                <div className="wrapper-page-error">
                    <main>
                        <div>
                            <i className="fas fa-trash-alt fa-8x"/>
                            <h2 className='f-bold pt-3'>{t('common.RecordInvalid')}</h2>
                            <a href="/" className="btn btn-kiroku btn-kiroku-primary" style={{width: 150}}>
                                {t('common.BackHome')}
                            </a>
                        </div>
                    </main>
                </div>
            </div>
        )
    }
}
export default NotRecord;