import React, {Component}          from 'react';
import {KirokuButton, KirokuInput} from "../../ui";
import KirokuActionSurvey          from "../../ui/KirokuActionSurvey";
import {withNamespaces}            from "react-i18next";
import KirokuDropdownClose         from "../../ui/KirokuDropdownClose";

@withNamespaces()
class Contacts extends Component {
    state = {};

    componentWillMount() {
        this.setState({
            customer: this.props.customer,
            contacts: this.props.customer.contacts,
        })
    }

    addNewContact() {
        let newChoices = this.state.contacts;
        newChoices.push({name: '', phone_number: '', memo: '', relationship: 'unknown'});
        this.setState({
            contacts: newChoices
        }, () => {
            this.props.onChangeCustomer({
                ...this.state.customer,
                contacts: this.state.contacts
            })
        });
    }

    removeContact(e, index) {
        if (this.state.contacts.length > 1) {
            let newChoices = this.state.contacts;
            newChoices.splice(index, 1);
            return this.setState({
                contacts: newChoices
            }, () => {
                this.props.onChangeCustomer({
                    ...this.state.customer,
                    contacts: this.state.contacts
                })
            });
        }
    }

    selectRelationship = (relationship) => {
        return relationship.map(relationship => ({
            value: relationship.type,
            component: (
                <div>
                    <span className={'text-muted'}>{this.props.t(`CustomerEditor.contact.${relationship.type}`)}</span>
                </div>
            ),
            label: this.props.t(`CustomerEditor.contact.${relationship.type}`)
        }))
    };

    relationshipType = [
        {
            id: 1,
            type: 'unknown'
        },
        {
            id: 2,
            type: 'self'
        },
        {
            id: 3,
            type: 'father'
        },
        {
            id: 4,
            type: 'mother'
        },
        {
            id: 5,
            type: 'brotherOrSister'
        },
        {
            id: 6,
            type: 'family'
        },
        {
            id: 7,
            type: 'other'
        }
    ];

    currentRelationship(relationship, index) {
        const newChoices                  = this.state.contacts;
        newChoices[index]['relationship'] = relationship;
        this.setState({
            contacts: newChoices
        }, () => {
            this.props.onChangeCustomer({
                ...this.state.customer,
                contacts: newChoices
            })
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.customer) {
            this.setState({
                customer: nextProps.customer,
            })
        }
    }

    onChangeContact(e, index) {
        if (e.target.name === "phone_number") {
            let regex                       = e.target.value.replace(/[^0-9]/g, '');
            const newChoice                 = this.state.contacts;
            newChoice[index][e.target.name] = regex;
            this.setState({
                contacts: newChoice,
            }, () => {
                this.props.onChangeCustomer({
                    ...this.state.customer,
                    contacts: newChoice
                })
            });
        } else {
            const newChoice                 = this.state.contacts;
            newChoice[index][e.target.name] = e.target.value;
            this.setState({
                contacts: newChoice,
            }, () => {
                this.props.onChangeCustomer({
                    ...this.state.customer,
                    contacts: newChoice
                })
            });
        }
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <h5>{t('CustomerEditor.contact.ContactInformation')}</h5>
                <div style={{float: 'right'}}>
                    <KirokuButton
                        color='primary'
                        onClick={this.addNewContact.bind(this)}
                    >
                        {t('CustomerEditor.contact.AddContact')}
                    </KirokuButton>
                </div>
                {this.state.contacts.map((contact, index) =>
                    <div key={index}>
                        <div className='pt-3 d-flex' style={{width: '100%'}}>
                            <div className='row'>
                                <div className='col-md-8 pb-3'>
                                    <div className="d-flex">

                                    <KirokuInput value={contact.name ? contact.name : ''}
                                                 name={'name'}
                                                 onChange={(e) => this.onChangeContact(e, index)}
                                                 placeholder={t('common.Name')}/>
                                    <div className='action-fix pl-3 pr-2'
                                         hidden={this.state.contacts.length === 1}
                                    >
                                        <div className='' >
                                            <div onClick={(e) => this.removeContact(e, index)}>
                                                <KirokuActionSurvey icon={'minus'}/>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>

                                <div className='col-md-8 pb-3'>
                                    <KirokuInput
                                        value={contact.phone_number ? contact.phone_number : ''}
                                        name='phone_number'
                                        placeholder={t('common.Phone')}
                                        onChange={(e) => this.onChangeContact(e, index)}
                                    />
                                </div>

                                <div className='col-md-8 pb-3'>
                                    <KirokuDropdownClose
                                        title={contact.relationship ? t(`CustomerEditor.contact.${contact.relationship}`) : t('CustomerEditor.contact.unknown')}
                                        items={this.selectRelationship(this.relationshipType)}
                                        onSelect={event => {
                                            this.currentRelationship(event.kirokuSelected.label === "不明" ? 'unknown' : event.kirokuSelected.value, index)
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <KirokuInput value={contact.memo ? contact.memo : ''}
                                             name={'memo'}
                                             onChange={(e) => this.onChangeContact(e, index)}
                                             placeholder={t('common.Memo')}/>
                            </div>
                            <hr className='mb-0' style={{width: '90%', float: 'left'}}
                                hidden={this.state.contacts.length === 1}/>
                        </div>

                    </div>
                )}
            </div>
        );
    }
}

export default Contacts;
