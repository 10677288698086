import React, { Component }          from 'react';
import { KirokuButton, KirokuRadio, KirokuButtonLink } from '../../../../ui';
import { withNamespaces }            from 'react-i18next';
import { checkUseHeadingQuestion }   from '../../../Surveys/SurveyEditor/SurveyService';
import { withSmoothScrolling }       from '../../../../ui/Scrolling.js';

@withSmoothScrolling
@withNamespaces()
class SingleChoice extends Component {

    state = {
        answers: this.props.value,
    };

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.value !== nextProps.value || this.props.question.index !== nextProps.question.index) {
            this.setState({
                answers  : nextProps.value,
                validated: nextProps.value.answer ? nextProps.value : null
            });
        }
    }

    onChangeItem(index) {
        const {question} = this.props;
        const answer     = question.choices[index];
        this.setState({
            answers: {...answer, is_choice: index},
        });
        this.props.onAnswer({
            answer: {...answer, is_choice: index},
            validated: index <= question.choices.length ? {...answer, is_choice: index} : null,
            questionIndex: this.props.question.index
        });
        this.props.isCurrentQuestion(this.props.question.index);
    }

    handleResetAnswer() {
        this.setState({
            answers    : {},
            validated  : false,
        });

        this.props.onAnswer({
            answer: {},
            validated: null,
            questionIndex: this.props.question.index
        });

        this.props.isCurrentQuestion(this.props.question.index);
    }


    moveToNextQuestion() {
        const {answers} = this.state;
        const {question } = this.props;
        let nextToQuestion = answers.nextQuestion ? answers.nextQuestion - 1 : question.index;
        this.props.smoothScroll.scrollTo(`question-${nextToQuestion}`);
        this.props.isCurrentQuestion(nextToQuestion + 1);

    }

    render() {
        const { question, disabled, t, isEndQuestion, currentQuestion } = this.props;
        const { answers }              = this.state;
        const contentQuestion = checkUseHeadingQuestion(question);

        return (
            <div id='component-question'>
                <div className='content-question'>
                    <div className='wraper-question'>
                        <div className={disabled ? 'bg-disable-question number-question' :
                            (answers.answer ? 'bg-question-has-answer d-flex justify-content-between number-question'
                                : (!question.unrequited ? 'bg-question-required-answer d-flex justify-content-between' : 'd-flex justify-content-between number-question'))}
                             style={{ padding : 8 }}
                        >
                            <div className={`text-overflow-elip 
                            ${disabled ? 'question-number' : answers.answer? 'font-text-question-has-answer'
                                : (!question.unrequited ? 'text-question-required-answer' : 'question-number') }`}
                            >
                                {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                            </div>
                            <div/>
                            <div hidden={disabled}>
                                <KirokuButtonLink
                                    className='padding-reset-button-link'
                                    onClick={() => {this.handleResetAnswer()}}>
                                    {t('createRecord.resetAnswer')}
                                </KirokuButtonLink>
                            </div>
                        </div>
                        <span className='content-name-question' style={{opacity: disabled ? 0.3 : 1}}>{question.content}</span>
                        <div className='answer-question' style={{opacity: disabled ? 0.3 : 1}}>
                            {question.choices.map((choice, index) =>
                                <KirokuRadio
                                    disabled={disabled}
                                    key={index}
                                    id={`${question.index}-${index}`}
                                    name={`${question.index}SingleChoice`}
                                    checked={
                                        answers.is_choice === index
                                    }
                                    onChange={this.onChangeItem.bind(this, index)}
                                >
                                    {choice.answer}

                                </KirokuRadio>
                            )}
                        </div>
                        <div className='p-2'
                                hidden={ disabled || isEndQuestion|| question.index !== currentQuestion}>
                            <KirokuButton className="btn-kiroku-primary"
                                          onClick={ this.moveToNextQuestion.bind(this) }>
                                {t('common.nextToQuestion')}
                            </KirokuButton>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SingleChoice;
