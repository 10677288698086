import {
    KirokuButton, KirokuModal, KirokuModalBody, KirokuModalHeader,
}                           from '../../../../../../ui';
import React, { Component } from 'react';
import { connect }          from 'react-redux';
import { withNamespaces }   from 'react-i18next';
import StaffSelectedTab     from './StaffSelectedTab';
import StaffOptionsTab      from './StaffOptionsTab';

@connect(state => {
    return {
        typeSearch: state.RecordsBeta.typeSearch,

        staffs                 : state.KirokuModal.ModalSelectStaffs.staffs,
        staffList              : state.KirokuModal.ModalSelectStaffs.staffList,
        staffsSelected         : state.KirokuModal.ModalSelectStaffs.staffsSelected,
        staffListSelected      : state.KirokuModal.ModalSelectStaffs.staffListSelected,
        isOpenModalSelectStaffs: state.KirokuModal.ModalSelectStaffs.isOpenModalSelectStaffs,
        isOpenStaffSelected    : state.KirokuModal.ModalSelectStaffs.isOpenStaffSelected,
        teamSelected           : state.KirokuModal.ModalSelectStaffs.teamSelected,
    };
}, dispatch => {
    return {
        changeIsOpenModalSelectStaffs: () => {
            dispatch({
                type: 'CHANGE_IS_OPEN_MODAL_SELECT_STAFFS',
            });
        },
        changeOpenStaffSelected      : (isOpenStaffSelected) => {
            dispatch({
                type: 'CHANGE_TYPE_OF_MODAL_SELECT_STAFFS',
                isOpenStaffSelected,
            });
        },
        handleCloseStaffModal        : () => {
            dispatch({
                type: 'HANDLE_CLOSE_STAFF_MODAL',
            });
        },
        handleSearchModal            : (staffListSelected) => {
            dispatch({
                type: 'HANDLE_CONFIRM_STAFF_MODAL_SEARCH',
                staffListSelected,
            });
        },
    };
})

@withNamespaces()
class ModalSelectStaffs extends Component {


    onCloseModal() {
        this.props.changeIsOpenModalSelectStaffs();
        this.props.handleCloseStaffModal();
    }

    handleSearchStaffModal() {
        const { isOpenStaffSelected, staffListSelected, staffList } = this.props;
        let newStaffList                                            = [];
        if (isOpenStaffSelected) {
            newStaffList = staffListSelected.filter(item => item.selected);
        } else {
            newStaffList = staffList.filter(item => item.selected);
        }
        this.props.handleSearchModal(newStaffList);
        this.props.changeIsOpenModalSelectStaffs();
    }

    render() {
        const {   // staffs, staffsSelected,
                  isOpenStaffSelected, t,
                  isOpenModalSelectStaffs,
              }     = this.props;
        // const count = isOpenStaffSelected ? staffsSelected.filter(staff => staff.selected).length : staffs.filter(staff => staff.selected).length;
        return (
            <div className={ 'modal-select-record-beta' }>
                <KirokuModal show={ isOpenModalSelectStaffs }
                             onCloseModal={ () => this.props.changeIsOpenModalSelectStaffs() }>
                    <KirokuModalHeader>
                        <div className={ 'text-center font-header-on-modal' }>{t('RecordsBeta.selectStaff')}</div>
                    </KirokuModalHeader>
                    <KirokuModalBody style={ { backgroundColor: '#eef3f6', padding: '17px 5px 1px 5px' } }>
                        <div className="d-flex justify-content-around border-btn-status-group">
                            <div
                                className={ `border-width-each-status first-btn font-size-16 cursor-pointer ${ isOpenStaffSelected ? 'btn-active' : '' }` }
                                onClick={ () => this.props.changeOpenStaffSelected(true) }>
                                {t('RecordsBeta.displaySelection')}
                            </div>
                            <div
                                className={ `border-width-each-status third-btn font-size-16 cursor-pointer ${ !isOpenStaffSelected ? 'btn-active' : '' }` }
                                onClick={ () => this.props.changeOpenStaffSelected(false) }>
                                {t('RecordsBeta.showAll')}
                            </div>
                        </div>
                        { isOpenStaffSelected ? <StaffSelectedTab/> : <StaffOptionsTab/> }
                    </KirokuModalBody>

                    <div style={ { backgroundColor: '#101F41', padding: '2em' } }
                         className="d-flex justify-content-around">
                        <KirokuButton onClick={ this.onCloseModal.bind(this) }
                                      className={'btn-cancel-on-modal'}
                        >
                            {t('RecordsBeta.cancel')}
                        </KirokuButton>
                        <div className="align-self-center">
                            {/*{ count ? t('RecordsBeta.isSelected', { count: count }) : t('RecordsBeta.notSelected') }*/}
                        </div>
                        <div>
                            <KirokuButton className={'btn-confirm-on-modal'}
                                          onClick={ this.handleSearchStaffModal.bind(this) }
                            >
                                {t('RecordsBeta.confirm')}
                            </KirokuButton>
                        </div>
                    </div>
                </KirokuModal>
            </div>
        );
    }
}

export default ModalSelectStaffs;
