export function returnAnswerMultiChoice(answers) {
    if (answers.length !== 0) {
        return answers;
    }
    return []
}

export function returnAnswerSingle(answers) {
    if (answers.answer) {
        return {
            ...answers,
            is_choice: answers.index
        }
    }
    return {}
}

export function returnAnswerMedia(question) {
    if (typeof question.answers === 'string') {
        return {
            answer    : question.answers,
            typeAnswer: question.typeAnswer
        }
    }
    return {}
}

export function returnAnswerInputText(answers) {
    if (answers.length > 0) {
        return answers
    }
    return ''
}

export function returnAnswerDate(answers) {
    if (typeof answers === 'number') {
        return answers
    }
    return null
}

export function returnAnswerYesNo(answers) {
    if (answers === 'Yes' || answers === 'No') {
        return answers
    }
    return ''
}
