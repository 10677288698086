import React, {Component}                                         from 'react';
import '../../../Customers/customers.css';
import {
    KirokuButton,
}                                                                 from '../../../../ui';
import {
    KirokuLoading
}                                                                 from '../../../../ui';
import {connect}                                                  from 'react-redux';
import {loginRequired}                                            from '../../../../middleware-redux/Auth';
import {withNamespaces}                                           from "react-i18next";
import {assignSurveys}                                            from "../../../../actions/Customer";
import {userService, uploadService, teamService, filePathService} from "../../../../services";
import TabProfileEditor                                           from "./TabProfileEditor";
import TabPassMobile                                              from "./TabPassMobile";
import TabManageTeamMobile                                        from "./TabManageTeamMobile";

@withNamespaces()
@loginRequired
@connect(state => {
    return {
        //loading: state.Loading,
        loadingAllCustomer: state.KirokuAICustomer.loadingAllCustomer,
        customers: state.KirokuAICustomer.ListAllCustomer
    }
}, dispatch => {
    return {
        assignSurvey: (id, customer) => {
            dispatch(assignSurveys(id, customer))
        },

    };
})
class StaffEditorMobile extends Component {

    state = {
        loading: false,
        showSurveys: false,
        showRecords: true,
        showProfile: true,
        statusCheck: false,
        show: false,
        user: {},
        teams: [],
        loadingImg: false,
        avatar: '',
        id: '',
        isLoading: false
    };


    async service(id) {
        let user  = await userService.loadDetail(id);
        let teams = await teamService.loadTeams();
        Promise.all([user, teams]).then(value => {
            this.setState({
                user: value[0],
                teams: value[1],
                id: this.props.id,
                isLoading: true
            })
        })
    }

    componentWillMount() {
        this.service(this.props.id)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.id) {
            this.service(nextProps.id)
        }
    }

    showSurveys() {
        this.setState({
            showSurveys: false,
            showRecords: true,
            showProfile: true
        })
    }

    showRecords() {
        this.setState({
            showSurveys: true,
            showRecords: false,
            showProfile: true
        })
    }

    showProfile() {
        this.setState({
            showSurveys: true,
            showRecords: true,
            showProfile: false
        })
    }

    closeModal() {
        this.setState({
            show: !this.state.show
        })
    }

    conditionUpload = (file) => {
        return (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') && file.size < 10485760;
    };

    handleChangeImage() {
        this.setState({
            loadingImg: !this.state.loadingImg && this.inputElement.files[0]
        });
        if (this.inputElement.files[0] && this.conditionUpload(this.inputElement.files[0])) {
            let image = new FormData();
            image.append('file', this.inputElement.files[0]);
            image.append('name', this.inputElement.files[0].name);
            image.append('description', 'some value user types');
            uploadService.uploadImage(image, 'avatar').then((res) => {
                this.setState({
                    loadingImg: false,
                    user: {
                        ...this.state.user,
                        avatar: res.url
                    }
                })
            });
        }
    };


    renderStaffDetail(user) {
        const {showProfile, showRecords, showSurveys} = this.state;
        let {t}                                       = this.props;
        return (
            <div className='pr-2 pl-2'>
                <div className='bg-white card-customer-detail'>
                    <div className="fix-center">
                        <div>
                            {!this.state.loadingImg ?
                                <div className='customer-detail height-avatar'
                                     style={{backgroundImage: `url(${user.avatar ? user.avatar : `${filePathService.resolveFilePath('profile-ic.svg')}`})`}}
                                /> : <KirokuLoading/>}
                        </div>
                        <div className='mt-24'>
                            <div>
                                <KirokuButton color={'success'} onClick={() => this.inputElement.click()}>
                                    {t('CustomerEditor.ChangeAvatar')}
                                </KirokuButton>
                                <input type='file' ref={input => this.inputElement = input} hidden
                                       accept="image/jpeg, image/png"
                                       onChange={this.handleChangeImage.bind(this)}
                                />
                            </div>
                        </div>
                        <div className='content-avatar'>
                            <span>{t('Customer.SupportedFile')}</span>
                            <br/>
                            <span>{t('Customer.Maximum')}</span>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between pt-10'>
                        <div style={{cursor: 'pointer'}} className={`nav-bar ${!showSurveys ? 'active' : ''}`}
                             onClick={this.showSurveys.bind(this)}>{t('staff.ProfileDetail')}
                        </div>
                        <div style={{cursor: 'pointer'}} className={`nav-bar ${!showRecords ? 'active' : ''}`}
                             onClick={this.showRecords.bind(this)}>{t('common.ChangePassword')}
                        </div>
                        <div style={{cursor: 'pointer'}} className={`nav-bar ${!showProfile ? 'active' : ''}`}
                             onClick={this.showProfile.bind(this)}>{t('common.Team')}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    assignSurvey() {
        let surveyForModal = this.state.surveyForModal.filter(survey => survey.isChecked);
        let customer       = this.props.customer;
        let certificate    = customer.certificate.map(item => item.value);
        this.props.assignSurvey(customer.id, {
            ...customer,
            certificate: certificate,
            staffs: customer.staffs.map(staff => staff.id),
            surveyTemplates: surveyForModal.map(survey => survey.id)
        });
        this.props.notification(this.props.t);
        this.setState({
            show: !this.state.show
        })
    }

    goBack() {
        this.props.history.goBack()
    }

    revokeLine = (user, teams) => {
        this.setState({
            user: user,
            teams: teams
        })
    };

    currentTeam() {
        this.service(this.props.id)
    }

    render() {
        const {user, teams} = this.state;
        let {t}             = this.props;
        return (
            <div>
                {this.props.loadingAllCustomer && this.state.isLoading ?
                    <div className='customer-detail default-background-color'>
                        <div className='d-flex justify-content-between p-2'>
                            <KirokuButton onClick={this.goBack.bind(this)}
                                          color='white'>{t('common.Back')}</KirokuButton>
                        </div>
                        {this.renderStaffDetail(user)}
                        <div className='padding-responsive'>
                            <div className='pt-8'>
                                <div hidden={this.state.showSurveys}>
                                    {<TabProfileEditor
                                        id={this.props.id}
                                        history={this.props.history}
                                        avatar={this.state.avatar}
                                        teams={teams} user={user}
                                        customers={this.props.customers}
                                        revokeLine={this.revokeLine.bind(this)}
                                    />
                                    }
                                </div>
                                <div hidden={this.state.showRecords}>
                                    <div className='card p-2'>
                                        <TabPassMobile history={this.props.history} id={user.id} isTFA={user["2fa"]} />
                                    </div>
                                </div>
                                <div hidden={this.state.showProfile}>
                                    {<TabManageTeamMobile avatar={this.state.avatar} history={this.props.history}
                                                          currentTeam={this.currentTeam.bind(this)}
                                                          user={user}
                                                          teams={user.staffInTeam}/>}
                                </div>
                            </div>
                        </div>
                    </div> : <KirokuLoading/>}
            </div>
        );
    }
}

export default StaffEditorMobile;
