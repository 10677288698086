import React, {Component} from 'react';
import {CheckDivide}      from "../../ui";
import LogsMobile         from "./LogsMobile";
import LogsWeb            from "./LogsWeb";
import KirokuTitlepage    from "../../ui/KirokuTitlepage";
import {scrollService}    from "../../services";
import {withNamespaces}   from "react-i18next";
import connect            from "react-redux/es/connect/connect";
import {currentPage}      from "../../actions/CurrentPage";

@withNamespaces()
@connect(() => {
    return {}
}, dispatch => {
    return {
        currentPageUsed: (name) => {
            dispatch(currentPage(name))
        }
    };
})
class Logs extends Component {
    page = {
        title: <KirokuTitlepage title={this.props.t('Layout.Logs')}/>,
    };
    componentWillMount() {
        scrollService.top();
        this.props.currentPageUsed(this.page);
    }
    render() {
        return (
            <div className='default-background-color'>
                <div className='padding-responsive'>
                    <CheckDivide
                        mobile={<LogsMobile history={this.props.history}/>}
                        web={<LogsWeb history={this.props.history}/>}
                    />
                </div>
            </div>
        )
    }
}

export default Logs;