import React, { Component } from 'react';
import KirokuModalBody      from "../../ui/KirokuModalBody";
import KirokuModalFooter    from "../../ui/KirokuModalFooter";
import KirokuButton         from "../../ui/KirokuButton";
import KirokuModal          from "../../ui/KirokuModal";
import { connect }          from "react-redux";
import { deactiveCustomer } from "../../actions/Customer";
import {withNamespaces}     from "react-i18next";

@withNamespaces()
@connect(() => ({}), (dispatch) => {
    return {
        deactiveCustomers: (customerIds, params, tags) => {
            dispatch(deactiveCustomer(customerIds, params, tags))
        }
    }
})

class ModalDeactiveCustomers extends Component {

    closeModal() {
        this.props.onCloseModalDeactiveCustomer()
    }

    deactive() {
        this.props.deactiveCustomers(this.props.customers.id, this.props.params, {tags: this.props.tags});
        this.props.onCloseModalDeactiveCustomer()
    }

    render() {
        const {t} = this.props;
        return (
            <KirokuModal show={this.props.show} onClick={this.closeModal.bind(this)}>
                <KirokuModalBody>
                    <p className='text-center pt-3'>
                        {t('Customer.Verify', {customers: this.props.customers.name})}
                    </p>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton onClick={this.closeModal.bind(this)} color={'white'}>
                        {t('common.Cancel')}
                    </KirokuButton>
                    <KirokuButton onClick={this.deactive.bind(this)} color={'primary'}>
                        {t('common.Confirm')}
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>
        )
    }
}

export default ModalDeactiveCustomers;
