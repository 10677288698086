import React, {Component}                                     from 'react';
import {KirokuAge, KirokuCertificateList, KirokuStaffTagList} from "../../../../ui";
import withPermissions                                        from "../../../HOC/WithPermissions";
import {withNamespaces}                                       from "react-i18next";
import LinkComponent                                          from "../../../HOC/LinkComponent";

const LinkButton = withPermissions(LinkComponent);

@withNamespaces()
class InfoCustomer extends Component {
    render() {
        let {t, profile, customer} = this.props;
        let isStaff                = profile.role === 'staff';
        return (
            <div>
                <div className='pt-10'>
                    <div className='d-flex'>
                        <h5 className='pr-3'>{customer.name}</h5>
                        <span hidden={isStaff} className='pr-1 pt-edit'>
							<i className='fas fa-pencil-alt text-secondary'/>
						</span>
                        <span className='pt-edit'>
							<LinkButton
                                resource-permission={"|customerDetail|button-editProfile|"}
                                href={`/customers/customer/${customer.id}`}
                                text={t('common.EditProfile')}
                            />
						</span>
                    </div>
                </div>
                <div className="text-left">
                    <div className='mb-10'>
						<span className='customer-info f-bold'>
							<span className="mr-2">{t('common.Age')}:</span>
							<KirokuAge dob={customer.date_of_birth}/>
						</span>
                    </div>
                    <div className='mb-10'>
						<span className='customer-info f-bold'>
							<span className="mr-2">{t('CustomerEditor.KindOfCertificate')}:</span>
						</span>
                        <KirokuCertificateList certificate={customer.certificate} numberCerlist={1}/>
                    </div>
                    <div className='mb-10'>
						<span className='customer-info f-bold'>
							<span className="mr-2">{t('common.Characteristic')}:</span>
						</span>
                        <span>{customer.characteristic}</span>
                    </div>
                    <div className='mb-10'>
						<span className='customer-info f-bold'>
							<span className="mr-2">{t('common.Staff')}:</span>
						</span>
                        <KirokuStaffTagList type={'users'} listTags={customer['staffs']}/>
                    </div>
                    <div className='mb-10'>
						<span className='customer-info f-bold'>
							<span className="mr-2">{t('common.team')}:</span>
						</span>
                        <KirokuStaffTagList type={'teams'} listTags={customer.teams}/>

                    </div>
                </div>
            </div>
        )
    }

}

export default InfoCustomer;