import React, {Component} from 'react';
import {scrollService}    from "../services";

export default class KirokuCircleUp extends Component{

    handleClickMoveToTop = () => {
        scrollService.top();
    };

    render() {
        const {style} = this.props;
        return (
            <div className='circle-up-btn' style={{ textAlign : 'center', width: '100%', ...style}}>
                <i className='fa fa-chevron-circle-up fa-3x' onClick={this.handleClickMoveToTop.bind(this)}/>
            </div>
        )
    }
}
