import {Component} from "react";
import React from "react";

export default class TBody extends Component {

    render() {
        return (
            <tbody className="tbl-content">
                {this.props.children}
            </tbody>
        )
    }
}