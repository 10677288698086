import React, {Component}             from "react";
import AboutSurvey                    from "../../../ReviewRecording/AboutSurvey";
import {withNamespaces}               from "react-i18next";
import RecordEditorNew                from "../../RecordEditorFiledAll/Web/RecordEditorNew";
import {connect}                                            from "react-redux";
import {createRecord}                                       from "../../../../../actions/Record";
import {recordService}                                      from "../../../../../services";
import moment                                               from "moment";
import {NOTIFICATION}                                       from "../../../../../actions/Notification";
import {saveDraftRecoding}                                  from "../../../../../actions/Draft";
import KiroNotificationBackup                               from "../../RecordEditorFiledAll/KiroNotificationBackup";
import {getAnswersToQuestion, getAnswerToQuestionFullLogic} from "../../../sevicesInRecord";
import {turnOnModalConfirmExit}                             from "../../../../../actions/TakeSurvey";
import ModalExit                                            from "../../../RecordCreator/Web/ModalConfirmExit";


@withNamespaces()
@connect(state => {
    return {
        surveyTemplateOfCustomer: state.KirokuAISurvey.surveyTemplateOfCustomer,
        surveyTemplateOfCustomerBackup: state.KirokuAISurvey.surveyTemplateOfCustomerBackup,
        dataDraftRecording: state.RecordEditorNew.dataDraftRecording
    }
}, dispatch => {
    return {
        createRecord: (dataRecord) => {
            dispatch(createRecord(dataRecord))
        },
        dispatchNotificationDraft: () => {
            dispatch({
                type: NOTIFICATION,
                typeNotification: 'success',
                message: 'createRecord.SaveDraftSuccess'
            })
        },
        saveDraft: (dataDraft) => {
            dispatch(saveDraftRecoding(dataDraft))
        },
        pageTakeRecord: () => {
            dispatch({
                type: 'TAKE_RECORD_PAGE'
            })
        },
        turnOnModalConfirmExit: () => {
            dispatch(turnOnModalConfirmExit())
        },
        trackingTakeRecord : (record) => {
            dispatch({
                type : "RECORD_CREATED_TRACKING",
                record
            });
        },
        trackingDraftRecord : (record) => {
            dispatch({
                type : "NEW_RECORD_DRAFTED_TRACKING",
                record
            });
        },
    }
})
class RecordCreatorFiledAllWeb extends Component {

    state = {
        keyLoadData: null,  //keyLoadData: null(loadRecord) 'statusDraft'(loadDraft) 'backup'(loadDataBackup)
        serviced_at: null,
        has_time: false
    };

    handleCancel() {
        // this.props.history.goBack();
        this.props.turnOnModalConfirmExit();
    }

    handleSaveStatusRecord(flowedQuestion) {
        const {surveyTemplateOfCustomer} = this.props;
        const surveyTemplate             = {
            ...surveyTemplateOfCustomer.survey,
            questions: getAnswerToQuestionFullLogic(flowedQuestion, surveyTemplateOfCustomer.survey.questions).map(question => {delete question.visibility; return question}),
            flowedQuestions: flowedQuestion.map(question => {delete question.visibility; return question}),
        };

        const statusDataRecord = {
            surveyTemplate: surveyTemplate,
            customer: surveyTemplateOfCustomer.customer,
            hasDraft: 'draft',
            draftBy: JSON.parse(localStorage.getItem('profile')).id,
            draftedAt: moment().unix(),
            serviced_at: this.state.serviced_at,
            has_time: this.state.has_time,
            answers: getAnswersToQuestion(flowedQuestion)
        };
        recordService.createSurveyRecord(statusDataRecord).then(res => {
            this.props.dispatchNotificationDraft();
            window.history.previous = window.location.pathname;
            this.props.pageTakeRecord();
            this.props.history.push(`/records/edit-filedAll/${res.data.id}`);
        }).catch(error => console.log(error));
        this.props.trackingDraftRecord(statusDataRecord);
    }

    handleSubmitRecord(flowedQuestion) {
        const {surveyTemplateOfCustomer} = this.props;
        const surveyTemplate             = {
            ...surveyTemplateOfCustomer.survey,
            questions: getAnswerToQuestionFullLogic(flowedQuestion, surveyTemplateOfCustomer.survey.questions).map(question => {delete question.visibility; return question}),
            flowedQuestions: flowedQuestion.map(question => {delete question.visibility; return question}),
        };

        const dataRecord = {
            surveyTemplate: surveyTemplate,
            customer: surveyTemplateOfCustomer.customer,
            hasDraft: null,
            draftBy: null,
            draftedAt: null,
            serviced_at: this.state.serviced_at,
            has_time: this.state.has_time,
            answers : getAnswersToQuestion(flowedQuestion)
        };
        this.props.createRecord(dataRecord);
        this.props.trackingTakeRecord(dataRecord)
    }

    confirmLoadBackupDraft() {
        this.setState({
            keyLoadData: 'backup'
        })
    }

    saveBackupDataRecord(dataBackup) {
        const {surveyTemplateOfCustomer} = this.props;
        const requestSaveDraft           = {
            customer: surveyTemplateOfCustomer.customer,
            // survey: surveyTemplateOfCustomer.survey,
            dataDraft: dataBackup,
            create_at: moment().unix()
        };
        this.props.saveDraft(requestSaveDraft);
    }

    onChangeDate(serviced_at, hasTime) {
        this.setState({
            serviced_at: hasTime ? serviced_at : moment.unix(serviced_at).startOf('day').unix(),
            has_time: hasTime
        })
    }

    render() {
        const {surveyTemplateOfCustomer, dataDraftRecording, t} = this.props;
        const {keyLoadData} = this.state;

        return surveyTemplateOfCustomer ? (
            <div className='padding-responsive default-background-color'>
                <div className='keeping-about-survey' style={{ top : 70, backgroundColor: '#FFFFFF'}}>
                    <KiroNotificationBackup
                        confirm={this.confirmLoadBackupDraft.bind(this)}
                        cancel={() => {
                        }}
                        draft={dataDraftRecording}
                    />
                        <div className='fix-center'>
                            <div style={{width: '70%'}}>
                                <div id='about-survey' className='pt-4 pb-3'>
                                    <AboutSurvey
                                        screen={'takeRecord'}
                                        onChangeDate={this.onChangeDate.bind(this)}
                                        hasTime={false}
                                        survey={surveyTemplateOfCustomer.survey}
                                        customer={surveyTemplateOfCustomer.customer} isRecord/>
                                </div>
                            </div>
                        </div>
                </div>
                {/*Survey description*/}
                <div className="survey fix-center" style={{backgroundColor: '#FFFFFF'}}>
                    <div className="pb-3" style={{width:'70%'}}>
                        <div className='info-question'>
                            <div className='content-name-question d-flex'>
                                        <span style={{ wordBreak: 'keep-all' }}
                                              className='title-record-info'>{t('createRecord.Description')}&nbsp;</span>
                                <pre style={{
                                    wordWrap  : 'break-word',
                                    whiteSpace: 'pre-wrap',
                                }}>
                                            {surveyTemplateOfCustomer.survey.description}
                                        </pre>
                            </div>
                        </div>
                    </div>
                </div>

                <RecordEditorNew
                    recordToEdit={{survey_template: {...surveyTemplateOfCustomer.survey}}}
                    updateRecord={this.handleSubmitRecord.bind(this)}
                    saveStatusRecord={this.handleSaveStatusRecord.bind(this)}
                    // saveBackupDataRecord={this.saveBackupDataRecord.bind(this)}
                    saveBackupDataRecord={() => {}}
                    statusRecord={{
                        statusDraft: surveyTemplateOfCustomer.statusRecord,
                        backup: dataDraftRecording
                    }}
                    keyLoadData={keyLoadData}
                    handleCancel={this.handleCancel.bind(this)}
                />
                <ModalExit history={this.props.history} customerId={surveyTemplateOfCustomer.customer_id}/>
            </div>
        ) : <div>not surveyTemplateOfCustomer</div>
    }
}

export default RecordCreatorFiledAllWeb;
