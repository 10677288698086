import React, {Component}              from 'react';
import {KirokuButton, KirokuInputIcon} from "../../../ui/index";
import moment                          from "moment";
import {withNamespaces}                from "react-i18next";


@withNamespaces()
class DateRange extends Component {

    state = {
        startDate : null,
        endDate : null
    };


    handleOnChangeStartTime(e) {
        this.setState({
            startDate : e.target.value ? moment(`${e.target.value}`, 'YYYY-MM-DD').startOf('day').unix(): null,
        });
    }

    handleOnChangeEndTime(e) {
        this.setState({
            endDate : e.target.value ? moment(`${e.target.value}`, 'YYYY-MM-DD').endOf('day').unix() : null,
        });
    }

    applyCustomDate(){
        this.props.customDate(this.state.startDate, this.state.endDate);
    }

    render() {
        let {t} = this.props;
        return (
            <div hidden={this.props.show} className='bg-white'>
                <div className='p-3'>
                    <div style={{float: 'left'}}>
                        <span onClick={this.props['closeDateRange']} style={{fontSize: '24px', verticalAlign: 'top'}}>
                            <i style={{color: '#1991eb'}} className={'fas fa-chevron-left'}/>
                        </span>
                    </div>
                    <div className='text-center'>
                        <span className='filter-record'>{t('Surveys.ChooseDateRange')}</span>
                    </div>
                </div>
                <div className='p-3'>
                    <KirokuInputIcon name={'date'}
                                     placeholder={t('Surveys.startDate')}
                                     icon={'far fa-calendar-alt'}
                                     type={'date'}
                                     onChange={this.handleOnChangeStartTime.bind(this)}
                    />
                </div>
                <div className='p-3'>
                    <KirokuInputIcon name={'date'} icon={'far fa-calendar-alt'}
                                     placeholder={t('Surveys.endDate')}
                                     type={'date'}
                                     onChange={this.handleOnChangeEndTime.bind(this)}
                    />
                </div>
                <div className='action-record-modal text-center'>
                    <KirokuButton color = "primary" onClick={this.applyCustomDate.bind(this)}>
                        {t('Surveys.ApplyCustomDate')}
                    </KirokuButton>
                </div>
            </div>
        );
    }
}

export default DateRange;
