import {documentUploadService} from "../services";
import {REMOVE_DOCUMENT, SET_PRIVATE_DOCUMENT, ASSIGN_USERS}       from "../actions/Document";
import {NOTIFICATION}          from "../actions/Notification";

export const documentMiddleware = (store) => next => action => {
    switch (action.type) {
        case "LOAD_DOCUMENT":
            return documentUploadService.getDocuments(action.params).then(documents => {
                let raw = documents.documents.map(item => {
                    return {
                        ...item,
                        isChecked: false
                    }
                });
                next({
                    type: "LOAD_DOCUMENT",
                    documents: {
                        documents: raw,
                        currentPage: documents.currentPage || 1,
                        pageSize: documents.pageSize,
                        totalDocuments: documents.totalDocuments,
                        totalPages: documents.totalPages || 1
                    }
                })
            }).catch(err => console.log(err));
        case SET_PRIVATE_DOCUMENT:
            return documentUploadService.updatePrivateDocument(action.customerId, action.listId, action.privateDocument).then(documents => {
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'success',
                    message: 'Surveys.UpdatedSuccessfully'
                });
                next({
                    type: SET_PRIVATE_DOCUMENT,
                    documents: {
                        ...documents,
                        currentPage: documents.currentPage || 1,
                        pageSize: documents.pageSize,
                        totalDocuments: documents.totalDocuments,
                        totalPages: documents.totalPages || 1
                    }
                })
            });

        case ASSIGN_USERS:
            return documentUploadService.assignUsersToDocument(action.customerId, action.documentId, action.users).then(documents => {
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'success',
                    message: 'Surveys.UpdatedSuccessfully'
                });
                next({
                    type: SET_PRIVATE_DOCUMENT,
                    documents: {
                        ...documents,
                        currentPage: documents.currentPage || 1,
                        pageSize: documents.pageSize,
                        totalDocuments: documents.totalDocuments,
                        totalPages: documents.totalPages || 1
                    }
                })
            });

        case REMOVE_DOCUMENT :
            return documentUploadService.removeDocuments(action.customerId, action.listId).then(documents => {
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'success',
                    message: 'message.DeleteSuccess'
                });

                next({
                    type: REMOVE_DOCUMENT,
                    documents: {
                        ...documents,
                        currentPage: documents.currentPage || 1,
                        pageSize: documents.pageSize,
                        totalDocuments: documents.totalDocuments,
                        totalPages: documents.totalPages || 1
                    }
                })
            });
        default:
            return next(action)
    }
};
