import {Component} from "react";
import moment      from 'moment';
import config      from '../config';

export default class KirokuDate extends Component{
    render() {
        if ( ! this.props.date ){
            return ' ';
        }
        const date   = moment.unix(this.props.timestamp ? this.props.date : this.props.date);
        const format = this.props.format || config.formatDateTh;

        return date.format(format);
    }
}
