import React, {Component} from 'react';

export default class KirokuPageLoader extends Component {
    render() {
        return (
            <div className={'text-center'} style={{marginTop: '40vh'}}>
                <div className="lds-ellipsis">
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </div>
            </div>
        )
    }
}