import {
    LOAD_RECORDS_WITH_CONDITIONS
} from "../actions/RecordsNew";
import {recordService}                from "../services";

import createBrowserHistory           from 'history/createBrowserHistory'
import {NOTIFICATION} from "../actions/Notification";

export const history = createBrowserHistory();

export const newRecordsMiddleware = (store) => next => action => {
    switch(action.type) {
        case LOAD_RECORDS_WITH_CONDITIONS :
            return recordService.searchAdvancedNewRecords(action.params, action.conditions).then(res => {
                next({
                    ...action,
                    ...res,
                })
            });
        case 'LOAD_RECORD_BY_CONDITION_BASIC' :
            return recordService.loadRecordsByConditions(action.params, action.conditions).then(res => {
                next({
                    ...action,
                    ...res
                })
            });

        case 'DELETE_RECORDS_AT_RECORD_LIST':
            return recordService.deleteRecords(action.records).then(res => {
                next({
                    ...action,
                    ...res
                });

                store.dispatch({
                    type: 'LOAD_RECORD_BY_CONDITION_BASIC',
                    params: action.params,
                    conditions : action.conditions
                });
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'success',
                    message: 'Surveys.DeletedSuccessfully'
                })
            });
        case 'DELETE_RECORDS_AT_RECORD_LIST_ADVANCE':
            return recordService.deleteRecords(action.records).then(res => {
                next({
                    ...action,
                    ...res
                });

                store.dispatch({
                    type: LOAD_RECORDS_WITH_CONDITIONS,
                    params: action.params,
                    conditions : action.conditions
                });
                store.dispatch({
                    type: NOTIFICATION,
                    typeNotification: 'success',
                    message: 'Surveys.DeletedSuccessfully'
                })
            });


        default:
            next(action)
    }
};
