import React, { Component }                                                             from 'react';
import moment                                                                           from 'moment';
import Dropzone                                                                         from 'react-dropzone';
import { connect }                                                                      from 'react-redux';
import { withNamespaces }                                                               from 'react-i18next';
import {
    KirokuButton, KirokuCard, KirokuCardAction, KirokuCardList, KirokuCheckBox,
    KirokuInputSearch,
    KirokuLoading, KirokuModal, KirokuModalBody, KirokuModalFooter, KirokuModalHeader,
    KirokuPagination, KirokuPopoverItem,
}                                                                                       from '../../../../ui';
import KirokuResetFilterCondition
                                                                                        from '../../../../ui/KirokuResetFilterCondition';
import { uploadService, downloadService, previewService, filePathService, authService } from '../../../../services';
import {
    filterByCondition, loadDocuments,
}                                                                                       from '../../../../actions/Customer';
import FilterType                                                                       from '../Web/FilterType';
import ModalPreview                                                                     from '../Web/ModalPreview';
import ModalSelectDocument                                                              from './ModalSelectDocument';
import config                                                                           from '../../../../config';
import { NOTIFICATION }                                                                 from '../../../../actions/Notification';
import TabResult
                                                                                        from '../../../Staffs/StaffEditor/TabResult';
import ModalOpenNewTab                                                                  from '../../ModalOpenNewTab';
import {
    assignUsersDocument, setPrivateDocument,
}                                                                                       from '../../../../actions/Document';
import _                                                                                from 'lodash';

let allowFileType   = ['jpg', 'jpeg', 'png', 'gif', 'pdf', 'PNG', 'JPG', 'JPEG', 'GIF', 'PDF'];
let acceptFileTypes = ['text/*',
    'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'image/*',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

class ActionDocument extends Component {

    constructor(props) {
        super(props);

        this.handleClick        = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            popupVisible: false,
        };
    }

    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
    }

    handleOutsideClick(e) {
        if (!this.node) {
            return;
        }
        this.handleClick();
    }

    render() {
        return (
            <div className="action-customer-surveys" ref={ node => {
                this.node = node;
            } }>
                <KirokuCardAction surveyId={ this.props.surveyId }>
                    <div className="action-icon" onClick={ this.handleClick.bind(this) }>
                        <i style={ { color: '#1991eb' } } className="fas fa-ellipsis-h "/>
                        {
                            this.state.popupVisible &&
                            (
                                <div onClick={ () => this.handleClick.bind(this) }>
                                    { this.props.children }
                                </div>
                            )
                        }
                    </div>
                </KirokuCardAction>
            </div>
        );
    }
}

@withNamespaces()
@connect(state => {
    return {
        customer              : state.KirokuAICustomer.customer,
        documents             : state.KirokuAICustomer.documents,
        token                 : state.KirokuAICustomer.token,
        loading               : state.Loading,
        flag                  : state.KirokuAICustomer.flag,
        loadingResultDocuments: state.KirokuAICustomer.loadingResultDocuments,
        profile               : state.KirokuAIUser.profile,
        users                 : state.KirokuAIUser.users,
    };
}, dispatch => {
    return {
        searchDocument     : (params) => {
            dispatch(loadDocuments(params));
        },
        reloadListDocument : (params) => {
            dispatch(loadDocuments(params));
        },
        filterByCondition  : (params) => {
            dispatch(filterByCondition(params));
        },
        notification       : () => {
            dispatch({
                type            : NOTIFICATION,
                typeNotification: 'success',
                message         : 'message.Upload',
            });
        },
        notificationMaximum: () => {
            dispatch({
                type            : NOTIFICATION,
                typeNotification: 'danger',
                message         : 'message.UploadFail',
            });
        },
        updateDocument     : (customerId, listId, privateDocument) => {
            dispatch(setPrivateDocument(customerId, listId, privateDocument));
        },
        assignDocument     : (customerId, documentId, userIds) => {
            dispatch(assignUsersDocument(customerId, documentId, userIds));
        },
    };
})
class TabUploadMobile extends Component {

    state = {
        showModalPreviewMobile: false,
        loadingImg            : false,
        files                 : [],
        conditions            : [],
        pageMin               : 1,
        currentPage           : 1,
        disabledPrev          : true,
        disabledNext          : false,
        show                  : false,
        url                   : '',
        name                  : '',
        startDate             : '',
        endDate               : '',
        day                   : '',
        week                  : '',
        month                 : '',
        nameStatus            : '',
        page                  : 1,
        params                : {},
        dateRanger            : {},
        response              : {},
        showModalSelect       : false,
        showModalAssignUser   : false,
        document              : {},
        usersForModal         : [],
        usersAssign           : [],
        statusCheck           : false,
        keywordSearchStaff    : '',
    };

    closeModal() {
        this.setState({
            show: !this.state.show,
        });
    }

    increasePage() {
        let nextPage = this.state.currentPage === this.props.documents.totalPages ? this.props.documents.totalPages : this.state.currentPage + 1;
        if (nextPage === this.props.documents.totalPages) {
            this.setState({
                disabledNext: !this.state.disabledNext,
            });
        }
        this.setState({
            currentPage : nextPage,
            page        : nextPage || 1,
            disabledPrev: false,
            selected    : false,
        }, () => this.props.reloadListDocument({
            ...this.state.params,
            customerId : this.props.customer.id,
            page       : nextPage,
            currentPage: nextPage,
            name       : this.state.name ? this.state.name : '',
        }));
    }

    decreasePage() {
        let previousPage = this.state.currentPage === this.state.pageMin ? 1 : this.state.currentPage - 1;
        if (previousPage === this.state.pageMin) {
            this.setState({
                disabledPrev: true,
            });
        }
        this.setState({
            currentPage : previousPage,
            page        : previousPage || 1,
            disabledNext: false,
            selected    : false,
        }, () => this.props.reloadListDocument({
            ...this.state.params,
            customerId : this.props.customer.id,
            page       : previousPage,
            currentPage: previousPage,
            name       : this.state.name ? this.state.name : '',
        }));
    }

    handleChoiceDate(e) {
        this.setState({
            page  : 1,
            params: {
                type                    : this.state.params.type,
                [e.kirokuSelected.value]: e.kirokuSelected.value === 'All date' ? '' : moment().startOf(e.kirokuSelected.value).unix(),
            },
        }, () => {
            this.filter();
        });
    }

    onDrop(files) {
        if (files[0].size > 10000000) {
            this.props.notificationMaximum();
        } else {
            this.setState({
                loadingImg: !this.state.loadingImg,
            });
            this.setState({ files }, () => {
                let file     = files[0];
                let formData = new FormData();
                formData.append('file', file);
                formData.append('name', file.name);
                uploadService.uploadImage(formData).then(res => {
                    this.setState({
                        loadingImg: false,
                    });
                    this.props.notification();
                    let document = {
                        url       : res.url,
                        type      : res.type,
                        name      : res.name,
                        filePath  : res.filePath,
                        customerId: this.props.id,
                    };
                    this.props.handleUploadFile(document);
                });
            });
        }
    }

    onCancel() {
        this.setState({
            files: [],
        });
    }

    itemDelete = [
        {
            value    : 'Delete',
            component: (<div>{ this.props.t('common.Delete') } </div>),
            label    : this.props.t('common.Delete'),
        },
    ];

    handleDelete() {
        this.setState({
            showModalSelect: !this.state.showModalSelect,
        });
    }

    onSearchDocument(e) {
        this.setState({
            name: e.target.value,
            page: 1,
        }, () => {
            this.filter();
        });
    }

    makeCondition(conditions) {
        this.setState({
            conditions,
        });
        let params = this.state.params;
        conditions.map(condition => {
            params[condition.query] = condition.value;
            return condition;
        });
        this.setState({
            params: params,
        }, () => {
            this.filter(params);
        });
    }

    filterCondition(conditions) {
        this.makeCondition(conditions);
        //filter theo type
    }

    filter() {
        this.props.filterByCondition({
            ...this.state.params,
            page      : 1,
            customerId: this.props.customer.id,
            name      : this.state.name ? this.state.name : '',
        });
    }

    download(documentId) {
        downloadService.download({ documentId: documentId, customerId: this.props.id });
    }

    async preview(index, fileType) {
        let document   = this.props.documents.documents[index];
        let fileReview = await previewService.preview({ customerId: document.customer_id, documentId: document.id });
        const response = {
            path    : `${ fileReview.url }`,
            fileType: fileType,
        };
        if (response.fileType === 'pdf' || response.fileType === 'PDF') {
            let newTab = window.open(fileReview.url);
            if (!newTab || newTab.closed || typeof newTab.closed == 'undefined') {
                //POPUP BLOCKED
                this.setState({
                    showModalPreviewMobile: !this.state.showModalPreviewMobile,
                    response,
                });
            }
        } else {
            this.setState({
                show: true,
                response,
            });
        }
    }

    onCloseModalPreviewMobile() {
        this.setState({
            showModalPreviewMobile: false,
        });
    }

    dropDownItemDate = [
        {
            value    : 'All date',
            component: (<div>{ this.props.t('RecordList.allDate') } </div>),
            label    : this.props.t('RecordList.allDate'),
        },
        {
            value    : 'day',
            component: (<div>{ this.props.t('RecordList.day') } </div>),
            label    : this.props.t('RecordList.day'),
        },
        {
            value    : 'week',
            component: (<div>{ this.props.t('RecordList.week') }</div>),
            label    : this.props.t('RecordList.week'),
        },
        {
            value    : 'month',
            component: (<div>{ this.props.t('RecordList.month') }</div>),
            label    : this.props.t('RecordList.month'),
        },
    ];

    closeModalDelete() {
        this.setState({
            showModalSelect: false,
            nameStatus     : this.props.t('common.BulkAction'),
        });
    }

    checkAssignUser(documents, user) {
        let documentUsers = documents.users.map(user => user.id);
        return documentUsers.includes(user.id);
    }

    updateDocument(listId, privateDocument) {
        this.props.updateDocument(this.props.customer.id, listId, privateDocument);
    }

    shareDocument(index) {
        this.setState({
            showModalAssignUser: !this.state.showModalAssignUser,
            document           : this.props.documents.documents[index],
            usersForModal      : this.props.users,
        }, () => {
            this.filterUser(this.state.document, this.state.usersForModal);
        });
    };

    filterUser(document, users) {
        let usersDocument = document.users.map(item => item.id);
        let userAssigned  = users.map(user => ({
            ...user,
            isChecked: usersDocument.includes(user.id),
        }));

        let checked = _.filter(userAssigned, (user) => !user.isChecked);
        this.setState({
            usersForModal: userAssigned,
            usersAssign  : userAssigned,
            statusCheck  : !checked.length,
        });
    }

    handlerModalAssignUser() {
        this.setState({
            showModalAssignUser: !this.state.showModalAssignUser,
            document           : {},
            keywordSearchStaff : '',
        });
    }

    handleCheckAll() {
        let { usersForModal, usersAssign } = this.state;
        let newUserRecord                  = usersForModal.map(user => ({
            ...user,
            isChecked: !this.state.statusCheck,
        }));

        let usersChecked = newUserRecord.map(item => item.id || []);

        let newUsersAssign = usersAssign.map(user => {
            if (usersChecked.includes(user.id)) {
                return {
                    ...user,
                    isChecked: !this.state.statusCheck,
                };
            }
            return {
                ...user,
            };
        });
        this.setState({
            usersForModal: newUserRecord,
            usersAssign  : newUsersAssign,
            statusCheck  : !this.state.statusCheck,
        });
    }

    onChangeSearchUsers(e) {
        let input   = e.target.value.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
        let users   = this.state.usersAssign.filter(user =>
            user.name.toLowerCase().search(input.toLowerCase()) !== -1,
        );
        let checked = _.filter(users, (user) => !user.isChecked);
        this.setState({ usersForModal: users, statusCheck: !checked.length, keywordSearchStaff: input });
    }

    handleCheckUsers(userId) {
        let { usersForModal, usersAssign } = this.state;
        let userRecords                    = usersForModal.map(user => ({ ...user }));
        let user                           = _.find(userRecords, user => user.id === userId);
        user.isChecked                     = !user.isChecked;
        let searchUsers                    = usersAssign;
        let userAssign                     = _.find(searchUsers, user => user.id === userId);
        userAssign.isChecked               = !userAssign.isChecked;
        let checked                        = _.filter(userRecords, (user) => !user.isChecked);
        this.setState({
            usersForModal: userRecords,
            surveysAssign: searchUsers,
            statusCheck  : !checked.length,
        });
    }

    assignUser() {
        let userForModal = this.state.usersAssign.filter(user => user.isChecked);
        let customer     = this.props.customer;
        let document     = this.state.document;
        this.props.assignDocument(customer.id, document.id, userForModal.map(item => item.id));
        this.setState({
            showModalAssignUser: !this.state.showModalAssignUser,
            document           : {},
            keywordSearchStaff : '',
        });
    }

    render() {
        const { documents, t, token }      = this.props;
        const { usersForModal, keywordSearchStaff } = this.state;

        // const documentsFilter = authService.isAdmin() ? documents.documents : documents.documents.filter(document => {
        //     return !document.private || this.checkAssignUser(document, profile);
        // });

        return (
            <div hidden={ this.props.hidden }>
                <div className='pt-2'>
                    <div className='bg-white'>
                        <div className='padding-responsive'>
                            <div className='wrap-upload-customer'>
                                <div className='fix-center pt-3 pb-3'>
                                    <div className="border-icon-upload">
                                        <div className='main-loading'>
                                            <div className={ this.state.loadingImg ? 'opacity-main' : '' }>
                                                <div className="overlay"/>
                                            </div>
                                            <div
                                                className={ !this.state.loadingImg ? 'display-none' : 'modal-kiroku fade show' }>
                                                <div className="modal-dialog modal-dialog-centered fix-center">
                                                    <div className='as'>
                                                        <button className='btn' style={ {
                                                            padding   : '.5rem',
                                                            background: 'transparent',
                                                        } }>
                                                            <div className="spinner"
                                                                 style={ { width: 40, height: 40 } }/>
                                                            <p className='text-white pt-2'>{ t('common.Uploading') }</p>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <i hidden={ this.state.loadingImg }
                                           className="fas fa-file-upload color-icon-upload-customer"/>
                                    </div>
                                    <div className="pt-3">
                                        <div className='form-upload-data'>
                                            <Dropzone
                                                multiple={ false }
                                                accept={ acceptFileTypes }
                                                onDrop={ this.onDrop.bind(this) }
                                                onFileDialogCancel={ this.onCancel.bind(this) }
                                            >
                                                { ({ getRootProps, getInputProps }) => (
                                                    <div { ...getRootProps() } className='fix-center'>
                                                        <input { ...getInputProps() } />
                                                        {/*{!this.state.files.length ? '' :*/ }
                                                        {/*<p hidden={!this.state.loadingImg}*/ }
                                                        {/*className='info-file-mobile'>{this.state.files[0].name} - {this.state.files[0].size}byte</p>}*/ }
                                                        <KirokuButton
                                                            color='success'>{ t('common.ChoseFile') }</KirokuButton>
                                                    </div>
                                                ) }
                                            </Dropzone>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='padding-responsive'>
                        <div className='pb-2 d-flex justify-content-between'>
                            <KirokuResetFilterCondition
                                style={ { width: 140 } }
                                icon='far fa-calendar-alt text-secondary'
                                title={ this.state.nameStatus }
                                defaultValue={ t('Surveys.AllDate') }
                                items={ this.dropDownItemDate }
                                onSelect={ this.handleChoiceDate.bind(this) }
                            />
                            <FilterType
                                t={ this.props.t }
                                filterCondition={ this.filterCondition.bind(this) }/>
                        </div>
                        <KirokuInputSearch onChange={ this.onSearchDocument.bind(this) }
                                           placeholder={ t('common.Search') }/>
                        <div className='d-flex justify-content-between pt-2'>
                            { authService.isAdmin() && (
                                <KirokuResetFilterCondition
                                    style={ { width: 130 } }
                                    title={ this.state.nameStatus }
                                    defaultValue={ t('common.BulkAction') }
                                    items={ this.itemDelete }
                                    onSelect={ this.handleDelete.bind(this) }
                                />
                            ) }
                            <div/>
                            <KirokuPagination
                                className='pb-1'
                                disabledPrev={ this.state.pageMin === documents.currentPage }
                                disabledNext={ documents.currentPage === documents.totalPages }
                                increasePage={ this.increasePage.bind(this) }
                                decreasePage={ this.decreasePage.bind(this) }
                                currentPage={ documents.currentPage }
                                totalPage={ documents.totalPages }
                            />
                        </div>
                    </div>
                    <div className='pb-2'>
                        <KirokuCardList headerLeft={ <span>{ t('common.Name') }</span> }
                                        headerRight={ <span>{ t('common.Action') }</span> }>
                            { documents.documents.length ? <div>
                                    { this.props.loadingResultDocuments ? documents.documents.map((file, index) =>
                                        <KirokuCard
                                            key={ index }
                                            action={ !file.private || !authService.isStaff() || this.checkAssignUser(file, this.props.profile) ? (
                                                <ActionDocument>
                                                    <KirokuPopoverItem>
                                                        <div
                                                            className='d-flex justify-content-between p-1 fix-icon-align'>
                                                            <a href={ `${ config.domain }/download?customerId=${ this.props.customer.id }&documentId=${ file.id }&token=${ token }` }>
                                                                <div style={ { cursor: 'pointer' } }>
                                                                    <i className="fas fa-cloud-download-alt text-white"/>
                                                                    { authService.isStaff() && (
                                                                        <span
                                                                            className='ml-1 text-white'>{ t('common.Download') }</span>) }
                                                                </div>
                                                            </a>
                                                            <div style={ { cursor: 'pointer' } }
                                                                 onClick={ this.preview.bind(this, index, file.type) }
                                                                 hidden={ !allowFileType.includes(file.type) }>
                                                                <i className="fas fa-binoculars text-white"/>
                                                                { authService.isStaff() && (
                                                                    <span
                                                                        className='ml-1 text-white'>{ t('common.Preview') }</span>
                                                                ) }
                                                            </div>
                                                            { !authService.isStaff() && (
                                                                file.private ? (
                                                                    <>
                                                                        <div style={ { cursor: 'pointer' } }
                                                                             onClick={ this.updateDocument.bind(this, file.id, false) }>
                                                                            <i className="fas fa-unlock text-white"/>
                                                                            {/*<span
                                                                             className='ml-1 text-white'>{t('common.Preview')}</span>*/ }
                                                                        </div>
                                                                        <div style={ { cursor: 'pointer' } }
                                                                             onClick={ this.shareDocument.bind(this, index) }>
                                                                            <i className="fas fa-share text-white"/>
                                                                            {/*<span
                                                                             className='ml-1 text-white'>{t('common.Preview')}</span>*/ }
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <div style={ { cursor: 'pointer' } }
                                                                         onClick={ this.updateDocument.bind(this, file.id, true) }>
                                                                        <i className="fas fa-lock text-white"/>
                                                                        {/*<span
                                                                         className='ml-1 text-white'>{t('common.Preview')}</span>*/ }
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </KirokuPopoverItem>
                                                </ActionDocument>
                                            ) : '' }
                                        >
                                            <p>
                                                { file.name }
                                                { file.private && !this.checkAssignUser(file, this.props.profile) ? (
                                                    <i className="pl-2 fa fa-lock"/>) : '' }
                                            </p>
                                            <p className='text-secondary'>{ file.type }</p>
                                        </KirokuCard>,
                                    ) : <KirokuLoading/> }
                                </div> :
                                <div className='pt-3'>
                                    <TabResult messageFirst={ `${ this.props.flag ? 'FileNotFound' : 'FilterFound' }` }>
                                        <img
                                            className='pt-2 pb-2'
                                            src={ `${ filePathService.resolveFilePath('empty-survey.svg') }` }
                                            alt="error img"/>
                                    </TabResult>
                                </div>
                            }
                        </KirokuCardList>
                    </div>
                    <div className='d-flex justify-content-between pt-2 pl-2 pr-2 pb-5'>
                        { authService.isAdmin() && (
                            <KirokuResetFilterCondition
                                style={ { width: 130 } }
                                title={ this.state.nameStatus }
                                defaultValue={ t('common.BulkAction') }
                                items={ this.itemDelete }
                                onSelect={ this.handleDelete.bind(this) }
                            />
                        ) }
                        <div/>
                        <KirokuPagination
                            className='pb-1'
                            disabledPrev={ this.state.pageMin === documents.currentPage }
                            disabledNext={ documents.currentPage === documents.totalPages }
                            increasePage={ this.increasePage.bind(this) }
                            decreasePage={ this.decreasePage.bind(this) }
                            currentPage={ documents.currentPage }
                            totalPage={ documents.totalPages }
                        />
                    </div>
                </div>
                <ModalPreview
                    show={ this.state.show }
                    closeModal={ this.closeModal.bind(this) }
                    response={ this.state.response }/>
                <ModalSelectDocument
                    show={ this.state.showModalSelect }
                    closeModal={ this.closeModalDelete.bind(this) }
                />
                <ModalOpenNewTab
                    response={ this.state.response }
                    file={ this.state.response }
                    show={ this.state.showModalPreviewMobile }
                    onCloseModalPreviewMobile={ this.onCloseModalPreviewMobile.bind(this) }
                />
                <KirokuModal show={ this.state.showModalAssignUser }
                             onCloseModal={ this.handlerModalAssignUser.bind(this) }>
                    <KirokuModalHeader>
                        <h6 className="mb-3">{ t('CustomerEditor.AssignToUsers') }</h6>
                        <KirokuInputSearch onChange={ this.onChangeSearchUsers.bind(this) }
                                           value={ keywordSearchStaff }
                                           placeholder={ t('CustomerEditor.EnterNameUser') }/>
                    </KirokuModalHeader>
                    <KirokuModalBody>
                        <div id="body-modal-as">
                            <KirokuCardList
                                headerLeft={ t('Dashboard.Users') }
                                headerRight={
                                    <div className='text-primary' style={ { cursor: 'pointer' } }>
                                                <span hidden={ this.state.statusCheck }
                                                      onClick={ this.handleCheckAll.bind(this) }
                                                >{ t('common.SelectAll') }</span>
                                        <span hidden={ !this.state.statusCheck }
                                              onClick={ this.handleCheckAll.bind(this) }
                                        >{ t('common.UnSelect') }</span>
                                    </div>
                                }
                            >
                                <section className="scroll-data-customer">
                                    { usersForModal.map((user) => (
                                        <KirokuCard key={ user.id }>
                                            <KirokuCheckBox
                                                onChange={ this.handleCheckUsers.bind(this, user.id) }
                                                checked={ user.isChecked } content={ user.name }/>
                                        </KirokuCard>
                                    )) }
                                </section>
                            </KirokuCardList>
                        </div>
                    </KirokuModalBody>
                    <KirokuModalFooter>
                        <KirokuButton onClick={ this.handlerModalAssignUser.bind(this) } color={ 'white' }>
                            { t('common.Cancel') }
                        </KirokuButton>
                        <KirokuButton color={ 'primary' }
                                      onClick={ this.assignUser.bind(this) }>
                            { t('common.Assign') }
                        </KirokuButton>
                    </KirokuModalFooter>
                </KirokuModal>
            </div>
        );
    }
}

export default TabUploadMobile;
