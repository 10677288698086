import React, {Component} from 'react';
import CheckDivide        from "../../../ui/CheckDivide";
import TeamCreatorMobile  from "./TeamCreatorMobile";
import TeamCreatorWeb     from "./TeamCreatorWeb";
import "../teams.css";
import KirokuTitlepage    from "../../../ui/KirokuTitlepage";
import {withNamespaces}   from "react-i18next";
import connect            from "react-redux/es/connect/connect";
import {currentPage}      from "../../../actions/CurrentPage";

@withNamespaces()
@connect(state => {
    return {
        currentPage: state.KirokuAITeam.currentPage,
        totalPage: state.KirokuAITeam.totalPage,
    }
}, dispatch => {
    return {
        pageNow: (name) => {
            dispatch(currentPage(name))
        },
    }
})

class TeamCreator extends Component {
    page = {
        title: <KirokuTitlepage title={this.props.t('Team.Team')}/>,
        actionPage: null
    };

    state = {
        namePage: this.page,
    };

    componentWillMount() {
        this.props.pageNow(this.state.namePage)
    }

    render() {
        return (
            <div className='default-background-color'>
                <CheckDivide mobile={<TeamCreatorMobile t={this.props.t} history={this.props.history}/>}
                             web={<TeamCreatorWeb t={this.props.t} history={this.props.history}/>}/>
            </div>
        )
    }
}

export default TeamCreator;