import React, { Component }                from 'react';
import { KirokuForm, KirokuInputToSearch } from './index';
import { withNamespaces }                  from 'react-i18next';


@withNamespaces()
class KirokuInputSearcher extends Component {

    searcherBeginSearch() {
        this.props.onSubmit()
    }

    searcherTypingKeyWord(e) {
        this.props.onChange(e)
    }

    render() {
        const {placeholder, t, id, isNotIconSearch} = this.props;
        return (
            <KirokuForm onSubmit={this.searcherBeginSearch.bind(this)}>
                <KirokuInputToSearch {...this.props} id={id || 'KirokuInputSearcher'} onBlur={this.searcherTypingKeyWord.bind(this)}
                                   placeholder={placeholder || t('common.Search')}>
                    <div id={'input-form-searcher'} hidden={isNotIconSearch}>
                        <button className='fix-icon-input-search'>
                            <i className="fa fa-search " onClick={this.searcherBeginSearch.bind(this)}/>
                        </button>
                    </div>
                </KirokuInputToSearch>
            </KirokuForm>
        );
    }
}

export default KirokuInputSearcher;
