import React, {Component}  from 'react';
import {
    KirokuCircleUp,
    KirokuDropdownResetTitle,
    Paginator
} from "../../ui";
import {withNamespaces}    from "react-i18next";
import {connect}           from "react-redux";
import {setSizePagination} from "../Records/NewRecords/serviceNewRecords";
import ActionDateButton    from "./ActionDateButton";

@withNamespaces()
@connect(state => {
    return {
        ComponentFlowScreen: state.DeletedList.ComponentFlowScreen,
        listScreen         : state.DeletedList.listScreen,
        page               : state.DeletedList.page,
        items              : state.DeletedList.items,
        hasNext            : state.DeletedList.hasNext,

    }
}, dispatch => {
    return {
        handleChangeScreen: (screen) => {
            dispatch({
                type: 'CHANGE_SCREEN',
                screen,
            })
        },
        checkIsMobile     : () => {
            dispatch({
                type    : 'IS_MOBILE',
                isMobile: true
            })
        },
        changePageNumber  : (numberPage) => {
            dispatch({
                type: 'CHANGE_PAGE_NUMBER',
                numberPage
            })
        }
    }
})

class DeletedMobilePage extends Component {

    componentWillMount() {
        this.props.handleChangeScreen('surveys');
        this.props.checkIsMobile();
    }

    handlerOnSelectScreen(e) {
        this.props.handleChangeScreen(e.kirokuSelected.value);
    }


    handleChangePageSize(e) {
        this.props.changePageNumber(e.kirokuSelected.value)
    }

    handleOnClickChangePage(e) {
        const {page}  = this.props;
        const numberPage = e === 'next' ? page + 1 : page -1 ;
        this.props.changePageNumber(numberPage)
    }

    render() {
        const {t, listScreen, ComponentFlowScreen, page, items, hasNext} = this.props;
        const Table      = ComponentFlowScreen.table;
        const Conditions = ComponentFlowScreen.condition;
        return (
            <div>
                <div className='default-background-color'>
                    <div className='padding-responsive'>
                        <div className='pl-2' style={{ width: 161, paddingTop: 12}}>
                            <KirokuDropdownResetTitle
                                selectScreen
                                title={t(`DeletedList.${ComponentFlowScreen.name}`)}
                                items={listScreen.map(screen => {
                                    return {
                                        value    : screen.screenName,
                                        component: (<div><h6 style={{margin: 0, color: '#202528'}}>{t(`DeletedList.${screen.screenName}`)}</h6></div>),
                                        label    : t(`DeletedList.${screen.screenName}`)
                                    }
                                })}
                                onSelect={this.handlerOnSelectScreen.bind(this)}
                            />
                        </div>

                        <div className="pl-2 pr-2">
                            <Conditions/>
                        </div>
                        <div className="pt-3 pl-2 pr-2">
                            <Paginator sizePagination={setSizePagination}
                                       numberPage={page}
                                       onPaginatorChange={this.handleChangePageSize.bind(this)}
                                       onClickNext={this.handleOnClickChangePage.bind(this, 'next')}
                                       onClickBack={this.handleOnClickChangePage.bind(this, 'back')}
                                       page={page}
                                       items={items}
                                       hasNext={hasNext}
                            />
                        </div>
                        <div className='pt-3 pl-2 pr-2'>
                            <ActionDateButton/>
                        </div>
                        <div className="pt-3 pl-2 pr-2 pb-3">
                            <Table/>
                        </div>
                        <div className="pt-2 pl-2 pr-2 pb-3">
                            <Paginator sizePagination={setSizePagination}
                                       numberPage={page}
                                       onPaginatorChange={this.handleChangePageSize.bind(this)}
                                       onClickNext={this.handleOnClickChangePage.bind(this, 'next')}
                                       onClickBack={this.handleOnClickChangePage.bind(this, 'back')}
                                       location={'top'}
                                       page={page}
                                       items={items}
                                       hasNext={hasNext}
                            />
                        </div>
                        <div style={{marginBottom:80}}>
                            <KirokuCircleUp/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DeletedMobilePage;
