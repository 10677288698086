import React, {Component}  from 'react';
import KirokuInput         from "../../../ui/KirokuInput";
import KirokuInputDate     from "../../../ui/KirokuInputDate";
// import KirokuPhoneNumber   from "../../../ui/KirokuPhoneNumber";
import moment              from "moment";
import KirokuDropdownClose from "../../../ui/KirokuDropdownClose";
import {withNamespaces}    from 'react-i18next'
import customerConfig      from './CustomerConfig';

import {Core}                       from '../../../services';
import ModalConfirmOverwriteAddress from "../ModalConfirmOverwriteAddress";
import { PostalCodeInput }          from "../../../ui";


@withNamespaces()
class PersonalInformation extends Component {
    state = {
        customer          : [],
        errors            : {},
        addressCode       : '',
        modalOverWriter   : false,
        newPostCode       :  ''
    };

    componentWillMount() {
        let currentCustomer = this.props.customer;
        this.setState({
            customer: currentCustomer,
            startDate: this.props.customer.date_of_birth ? this.props.customer.date_of_birth : null,
            newPostCode : currentCustomer.post_code || ''

        })
    }

    handleChangeInput(e) {
        const customer          = this.state.customer;
        customer[e.target.name] = e.target.value;
        this.setState({
            customer: customer
        });
        this.props.onChangeCustomer(this.state.customer)
    }


    // certificationNumber(e) {
    //     let regex      = e.target.value.replace(/[^0-9]/g, '');
    //     const customer = this.state.customer;
    //     if (regex.length > 10) return;
    //     customer[e.target.name] = regex;
    //     this.setState({
    //         customer: customer
    //     });
    //     this.props.onChangeCustomer(this.state.customer);
    // }
    //
    // handleChangePhoneNumber = (phone) => {
    //     const newCustomer        = this.state.customer;
    //     newCustomer.phone_number = phone;
    //     this.setState({
    //         customer: newCustomer
    //     });
    //     this.props.onChangeCustomer(this.state.customer)
    // };
    //
    // handChangeEmergencyContact = (phone) => {
    //     const newCustomer             = this.state.customer;
    //     newCustomer.emergency_contact = phone;
    //     this.setState({
    //         customer: newCustomer
    //     });
    //     this.props.onChangeCustomer(this.state.customer)
    // };

    selectBloodType = (bloodType) => {
        return bloodType.map(blood => ({
            value: blood.id,
            component: (
                <div>
                    <span className={'text-muted'}>{blood.type}</span>
                </div>
            ),
            label: blood.type
        }))
    };

    onChangeDate(value) {
        if (moment(value, 'YYYY/MM/DD', true).isValid()) {
            const newCustomer         = this.state.customer;
            newCustomer.date_of_birth = value;
            this.setState({
                customer: newCustomer,
                errors: {
                    ...this.state.errors,
                    dateOfBirth: false
                }
            }, () => this.props.currentError(this.state.errors));
            this.props.onChangeCustomer(this.state.customer)
        } else {
            const newCustomer         = this.state.customer;
            newCustomer.date_of_birth = value;
            this.setState({
                errors: {
                    ...this.state.errors,
                    dateOfBirth: value.length
                }
            }, () => this.props.currentError(this.state.errors));
            this.props.onChangeCustomer(this.state.customer);

        }
    }

    currentBloodType(bloodType) {
        const newCustomer      = this.state.customer;
        newCustomer.blood_type = bloodType;
        this.setState({
            customer: newCustomer
        });
        this.props.onChangeCustomer(this.state.customer)
    }

    checkValidation(e) {
        this.setState({
            errors: {
                ...this.state.errors,
                [e.target.name]: !e.currentTarget.value.length
            }
        }, () => this.props.currentError(this.state.errors));

    }

    checkValidationCertification(e) {
        const error = this.checkError(e.currentTarget.value);
        this.setState({
            errors: {
                ...this.state.errors,
                certification_number: error,
            }
        }, () => this.props.currentError(this.state.errors))
        ;
    }

    checkError = (number) => {
        if (number.length > 0 && number.length < 10) {
            //let checkUsername = number.match(/^\w+$/);
            this.props.currentError({certification_number: true});
            return 'FormatNumber';
        }
        return ''
    };

    handleChangeCustomerCode = (e) => {
        let regex  = e.target.value.replace(/[^0-9]/g, '');
        const newCustomer = this.state.customer;
        if (regex.length > 6)  return;
        newCustomer.customer_code = regex;
        this.setState({
            customer : newCustomer
        });
        this.props.onChangeCustomer(this.state.customer)
    };

    selectGender = (genderType) => {
        return genderType.map(gender => ({
            value: gender.type,
            component: (
                <div>
                    <span className={'text-muted'}>{this.props.t(`CustomerEditor.${gender.type}`)}</span>
                </div>
            ),
            label: this.props.t(`CustomerEditor.${gender.type}`)
        }))
    };

    genderCurrent(genderType) {
        const newCustomer      = this.state.customer;
        newCustomer.gender = genderType;
        this.setState({
            customer: newCustomer
        });
        this.props.onChangeCustomer(this.state.customer);
    }

    bloodType = [
        {
            id: 1,
            name: 'Unknown',
            type: this.props.t('CustomerEditor.Unknown')
        }, {
            id: 2,
            name: 'A',
            type: 'A'
        },
        {
            id: 3,
            name: 'B',
            type: 'B'
        },
        {
            id: 4,
            name: 'AB',
            type: 'AB'
        },
        {
            id: 5,
            name: 'O',
            type: 'O'
        }
    ];

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.customer) {
            this.setState({
                customer: nextProps.customer,
                startDate: nextProps.customer.date_of_birth ? nextProps.customer.date_of_birth : null,
                newPostCode : nextProps.customer.post_code || ''
            })
        }
    }

    setPostalCode() {
        const {customer} =  this.state;
        new Core(customer.post_code, addr => {
            const address = addr.region + addr.l+ addr.m + addr.o;
            this.setState({
                addressCode :  address
            }, () => {
                this.showOverWriteAddress()
            })
        });
    }

    showOverWriteAddress(){
        const {customer, newPostCode, addressCode, modalOverWriter} = this.state;
        if(customer.address && ((addressCode !== customer.address) || (newPostCode !== customer.post_code)) ) {
            this.setState({
                modalOverWriter : !modalOverWriter
            })
        }
        else {
            this.setState({
                customer: {
                    ...customer,
                    address: addressCode
                }
            }, () => {
                this.props.currentError(this.state.errors);
                this.props.onChangeCustomer(this.state.customer)
            });
        }
    }

    actionModal() {
        this.setState({
            modalOverWriter : !this.state.modalOverWriter
        })
    }

    confirmOverWriteAddress() {
        const {customer, addressCode} = this.state;
        this.setState({
            customer: {
                ...customer,
                address: addressCode
            },

        }, () => {
            this.props.onChangeCustomer(this.state.customer)
        });
        this.actionModal();
    }

    checkValidationCustomerCode = (e) => {
        const error = this.checkErrorCustomerCode(e.currentTarget.value);
        this.setState({
            errors: {
                ...this.state.errors,
                customer_code: error,
            }
        }, () => this.props.currentError(this.state.errors));
    };

    checkErrorCustomerCode = (number) => {
        if(number.length !== 6 && number.length > 0) {
            this.props.currentError({customer_code: true});
            return 'ErrorCustomerCode';
        }
        return '';
    };

    handleChangePostCode(postalCode) {
        this.setState({
            newPostCode: postalCode,
            customer   : {
                ...this.state.customer,
                post_code: postalCode
            }
        },() => {this.props.onChangeCustomer(this.state.customer)})
    }

    render() {
        const {customer, errors, startDate, addressCode, modalOverWriter} = this.state;
        let {t}                                                           = this.props;
        return (
            <div>
                <h5>{t('CustomerEditor.PersonalInformation')}</h5>
                <div>
                    <label className='mt-1'>{t('CustomerEditor.Name')}</label>
                    <KirokuInput
                        name='name'
                        placeholder={t('common.Name')}
                        onChange={this.handleChangeInput.bind(this)}
                        onBlur={this.checkValidation.bind(this)}
                        value={customer.name || ''}
                        error={errors.name ? 'form-error' : ''}
                    />
                    <p hidden={!errors.name} style={{
                        color: 'red',
                        fontSize: 12
                    }}>{t('CustomerEditor.validate.Name')}</p>
                    <label className='mt-1'>{t('CustomerEditor.DateOfBirth')}</label>
                    <KirokuInputDate
                        name='date_of_birth'
                        onChange={this.onChangeDate.bind(this)}
                        value={startDate ? moment(startDate) : ''}
                    />
                    <p hidden={!errors.dateOfBirth} style={{
                        color: 'red',
                        fontSize: 12
                    }}>{t('CustomerEditor.validate.ValidDate')}</p>
                    <label className='mt-1'>{t('CustomerEditor.CustomerGender')}</label>
                    <KirokuDropdownClose
                        title={ t('CustomerEditor.' + customer.gender)}
                        items={this.selectGender(customerConfig.genderType)}
                        onSelect={event => {
                            this.genderCurrent(event.kirokuSelected.label === "未選択" ? 'unselected' : event.kirokuSelected.value)
                        }}
                    />
                    {/*<label className='mt-1'>{t('CustomerEditor.PhoneNumber')}</label>*/}
                    {/*<KirokuPhoneNumber*/}
                    {/*    placeholder={t('common.Phone')}*/}
                    {/*    value={customer.phone_number ? customer.phone_number : ''}*/}
                    {/*    name='phone_number'*/}
                    {/*    onChange={this.handleChangePhoneNumber.bind(this)}*/}
                    {/*/>*/}
                    <label className='mt-1'>{t('CustomerEditor.BloodType')}</label>
                    <KirokuDropdownClose
                        title={(customer.blood_type === 'Unknown' ? t('CustomerEditor.Unknown') : customer.blood_type) || t('CustomerEditor.Unknown')}
                        items={this.selectBloodType(this.bloodType)}
                        onSelect={event => {
                            this.currentBloodType(event.kirokuSelected.label === "不明" ? 'Unknown' : event.kirokuSelected.label)
                        }}
                    />
                    <label className='mt-1'>{t('CustomerEditor.PostCode')}</label>
                    <span className={'cursor-pointer mt-1 ml-1'}
                          style={{color: '#1891eb'}}
                          onClick={this.setPostalCode.bind(this)}>
                        {t('CustomerEditor.InsertAddr')}
                    </span>
                    <PostalCodeInput initial={customer.post_code}
                                     onPostalCodeChange={this.handleChangePostCode.bind(this)}/>
                    <ModalConfirmOverwriteAddress
                        closeModalConfirmOverwrite={this.showOverWriteAddress.bind(this)}
                        handleConfirmOverwrite={this.confirmOverWriteAddress.bind(this)}
                        show={modalOverWriter}
                        addr={{new: addressCode, old: customer.address}}/>
                    <label className='mt-1'>{t('CustomerEditor.Address')}</label>
                    <KirokuInput
                        name='address'
                        placeholder={t('common.Address')}
                        onChange={this.handleChangeInput.bind(this)}
                        value={customer.address || ''}
                    />
                    <label className='mt-1'>{t('CustomerEditor.Characteristic')}</label>
                    <KirokuInput
                        name='characteristic'
                        placeholder={t('common.Characteristic')}
                        onChange={this.handleChangeInput.bind(this)}
                        value={customer.characteristic || ''}
                    />
                    {/*<label className='mt-1'>{t('CustomerEditor.EmergencyContact')}</label>*/}
                    {/*<KirokuPhoneNumber*/}
                    {/*    name='emergency_contact'*/}
                    {/*    placeholder={t('common.Phone')}*/}
                    {/*    onChange={this.handChangeEmergencyContact.bind(this)}*/}
                    {/*    value={customer.emergency_contact || ''}*/}
                    {/*/>*/}
                    {/*<label className='mt-1'>{t('CustomerEditor.Certificate')}</label>*/}
                    {/*<KirokuInput*/}
                    {/*    name='certification_number'*/}
                    {/*    placeholder={t('common.Certification_Number')}*/}
                    {/*    onChange={this.certificationNumber.bind(this)}*/}
                    {/*    onBlur={this.checkValidationCertification.bind(this)}*/}
                    {/*    value={customer.certification_number || ''}*/}
                    {/*    error={errors.certification_number ? 'form-error' : ''}*/}
                    {/*/>*/}
                    {/*<p hidden={!errors.certification_number} style={{*/}
                    {/*    color: 'red',*/}
                    {/*    fontSize: 12*/}
                    {/*}}>*/}
                    {/*    {t(`CustomerEditor.validate.${errors.certification_number}`)}*/}
                    {/*</p>*/}
                    {/*<label className='mt-1'>{t('CustomerEditor.CustomerCode')}</label>*/}
                    {/*<KirokuInput*/}
                    {/*    name='customer_code'*/}
                    {/*    placeholder={t('CustomerEditor.CustomerCode')}*/}
                    {/*    onChange={this.handleChangeCustomerCode.bind(this)}*/}
                    {/*    error={errors.customer_code ? 'form-error' : ''}*/}
                    {/*    value={customer.customer_code || ''}*/}
                    {/*    onBlur={this.checkValidationCustomerCode.bind(this)}*/}
                    {/*/>*/}
                    {/*<p hidden={!errors.customer_code} style={{*/}
                    {/*    color: 'red',*/}
                    {/*    fontSize: 12*/}
                    {/*}}>*/}
                    {/*    {t(`CustomerEditor.validate.${errors.customer_code}`)}*/}
                    {/*</p>*/}
                </div>
            </div>
        )
    }
}

export default PersonalInformation;
