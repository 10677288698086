import '../../../../record.css'
import React, { Component }        from 'react';
import { withNamespaces }          from 'react-i18next';
import KirokuLogicApplicated       from '../../../../../../ui/KirokuLogicApplicated';
import KirokuInput                 from '../../../../../../ui/KirokuInput';

@withNamespaces()
class NumberQuestion extends Component {

    render() {
        const {question, surveyDetail, headerNextQuestion, t} = this.props;

        return (
            <div className='content-question-type'>
                <div className='wraper-question none-border-question pt-2 pt-2'>
                    <div className="background-question">
                        <div className='d-flex justify-content-between'>
                            <div
                                className="text-overflow-elip font-heading-questions">{question.heading ? question.heading : ""}</div>
                            <div hidden={!surveyDetail} className={'float-right'}>
                                <KirokuLogicApplicated padding={'5px 5px 5px 7px'} question={question}/>
                            </div>
                        </div>
                        <div className='padding-content-question'>
                            <span className='font-content-question'>
                                {question.unrequited ? t('createRecord.option'):''}{question.content}
                            </span>
                        </div>
                        <div
                            hidden={ typeof question.nextQuestion !== 'number' || !surveyDetail }
                            className='pl-10 text-info text-overflow-elip'>
                            <i className='fas fa-arrow-right' style={{paddingTop: 4}}/>
                            <span> {headerNextQuestion}</span>
                        </div>
                    </div>
                    <div className='answer-question padding-16-answer-question'>
                        <KirokuInput readOnly value={this.props.value || ''}
                            onChange={null}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default NumberQuestion;
