import { Component } from "react";
import React         from "react";

export default class KirokuLabel extends Component {

    render() {
        return (
            <label {...this.props}
                   className={'badge-kiroku badge-kiroku-' + this.props.color}>{this.props.children}</label>
        )
    }
}
