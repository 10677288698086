import React, {Component} from 'react';
import {
    FormatTimestamp,
    KirokuCardDeletedScreen, KirokuLoading, SearchNotFound,
}                         from "../../../../ui";
import {withNamespaces}   from "react-i18next";
import { connect }        from "react-redux";

@withNamespaces()
@connect(state => {
    return {
        records: state.DeletedRecords.records,
        loading: state.Loading
    }
}, dispatch => {
    return {

    }
})
class TableRecordList extends Component{

    render() {
        const {t, records, loading} = this.props;
        return(
            <div className='bg-white'>
                {!loading ?
                    records.length ?
                        <KirokuCardDeletedScreen>
                            {records.map((record, index) => (
                                <div key={index}>
                                    <div className='pl-3 pt-3' >
                                        <div className='header-card-del'>{record.name}</div>
                                        <div className='header-card-del pb-2'>{record.title}</div>
                                        <div className='title-card-del'>{t('DeletedList.deletedAt')} : <FormatTimestamp timestampUnix={record.delete_at}/></div>
                                        <div className='title-card-del'>{t('DeletedList.createdAt')} : <FormatTimestamp timestampUnix={record.create_at}/></div>
                                    </div>
                                    <hr className='hr-table-mobile'/>
                                </div>
                            ))}
                        </KirokuCardDeletedScreen> :<SearchNotFound/> : <KirokuLoading/> }
            </div>
        )
    }
}

export default TableRecordList;
