import React, { Component }                                      from 'react';
import { connect }                                               from 'react-redux';
import {
    clearRecordToEditInStore,
    loadRecordToEdit,
}                                                                from '../../../../actions/Record';
import RenderComponentDevice                                     from '../../../../ui/RenderComponentDevice';
import { KirokuPageLoader }                                      from '../../../../ui';
import RecordEditorFiledAllWeb                                   from './Web/RecordEditorFiledAllWeb';
import RecordEditorFiledAllMobile                                from './mobile/RecordEditorFiledAllMobile';
import { loadDraftEditRecording, restartDataDraftEditRecording } from '../../../../actions/Draft';
import { resetRecordEditorNew }                                  from '../../../../actions/recordEditorNew';
import KirokuTitlepage                                           from '../../../../ui/KirokuTitlepage';
import { currentPage }                                           from '../../../../actions/CurrentPage';
import { withNamespaces }                                        from 'react-i18next';
import { withPolling }                                           from '../../../HOC/withPolling';
import config                                                    from '../../../../config';
import ModalAskReload                                            from '../../ModalAskReload';

@withNamespaces()
@connect(state => {
    return {
        recordToEdit        : state.KirokuAIRecord.recordToEdit,
        lockInfo            : state.KirokuLockInfo.lockInfo,
        isDisplayAskReload  : state.KirokuLockInfo.isDisplayAskReload,
        isCalling           : state.KirokuLockInfo.isCalling,
    };
}, dispatch => {
    return {
        loadRecordById               : (id) => {
            dispatch(loadRecordToEdit(id));
        },
        clearRecordToEditInStore     : () => {
            dispatch(clearRecordToEditInStore());
        },
        restartDataDraftEditRecording: () => {
            dispatch(restartDataDraftEditRecording());
        },
        loadDraftEditRecoding        : (id) => {
            dispatch(loadDraftEditRecording(id));
        },
        resetRecordEditorNew         : () => {
            dispatch(resetRecordEditorNew());
        },
        namePage                     : (name) => {
            dispatch(currentPage(name));
        },
        closeModalAskReload          : (isOpen) => {
            dispatch({
                type: 'MODAL_ASK_RELOAD',
                isOpen
            })
        },
        setCallingLockStatus         : (isCalling) => {
            dispatch({
                type: 'SET_CALLING_LOCK_STATUS',
                isCalling
            })
        }
    };
})
@withPolling('records', config.pollingTime)
class RecordEditorFiledAll extends Component {


    page(t) {
        return {
            title     : <KirokuTitlepage title={ t('Layout.Records') }/>,
            actionPage: null,
        };
    }

    componentDidMount() {
        const recordId = this.props.match.params.recordId;
        this.props.namePage(this.page(this.props.t));
        this.props.loadRecordById(recordId);
        // this.props.loadDraftEditRecoding(recordId);
    }

    componentWillUnmount() {
        this.props.clearRecordToEditInStore();
        // this.props.restartDataDraftEditRecording();
        this.props.resetRecordEditorNew();
    }


    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.lockInfo.user_id !== this.props.lockInfo.user_id ||
            nextProps.recordToEdit !== this.props.recordToEdit ||
            nextProps.isDisplayAskReload !== this.props.isDisplayAskReload;
    }

    handleActionUser() {
        if(!this.props.isCalling) {
            this.props.setCallingLockStatus(true)
        }
    }

    render() {
        const { recordToEdit, lockInfo, isDisplayAskReload } = this.props;

        return recordToEdit ?
            <div
                onMouseMove={() => this.handleActionUser()}
                onMouseEnter={() => this.handleActionUser()}
                onTouchStart={() => this.handleActionUser()}
            >
                <ModalAskReload
                    isOpen={isDisplayAskReload}
                    userName={ lockInfo.previous_lock && lockInfo.previous_lock.user_name }
                    handleCancel={ () => this.props.closeModalAskReload(false) }
                    handleConfirm={ () => window.location.reload() }
                    closeModal={() => this.props.closeModalAskReload(false)}
                />
                <RenderComponentDevice
                    mobile={ <RecordEditorFiledAllMobile
                        history={ this.props.history } lockInfo={ lockInfo }
                    /> }

                    web={ <RecordEditorFiledAllWeb
                        history={ this.props.history } lockInfo={ lockInfo }
                    /> }
                />
            </div>
            : <KirokuPageLoader/>;
    }
}

export default RecordEditorFiledAll;
