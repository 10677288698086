import {LOAD_USERS} from "../actions/User";
import _            from 'lodash';

const defaultApplicationSate = {
    showModalAddMember: false,
    showModalAddRole: false,
    showModalDelete: false,
    statusCheck: false,
    statusSet: false,
    statusDell: false,
    memberAll: [],
    listMemberSetRole: [],
    listMemberSetRoleCheckAll: [],
    memberList: [],
    membersInTeam: [],
    membersChoice: [],
    listMemberThisRemove: [],
    listAllMemberSetRole: []
};

const EditorTeam = (state = defaultApplicationSate, action) => {
          switch (action.type) {
              case 'TURN_ON_ADD_MEMBER_TO_TEAM':
                  return {
                      ...state,
                      showModalAddMember: true
                  };
              case 'TURN_OFF_ADD_MEMBER_TO_TEAM':
                  return {
                      ...state,
                      showModalAddMember: false,
                      statusCheck: _.findIndex(state.memberList, ['isChecked', false]) !== -1,
                  };
              case 'TURN_ON_DELETE':
                  return {
                      ...state,
                      showModalDelete: true,
                  };
              case 'TURN_OFF_DELETE':
                  return {
                      ...state,
                      showModalDelete: false,
                      statusDell: false
                  };
              case 'TURN_ON_SET_ROLE_MEMBER':
                  return {
                      ...state,
                      showModalAddRole: true
                  };
              case 'TURN_OFF_SET_ROLE_MEMBER':
                  return {
                      ...state,
                      showModalAddRole: false,
                      statusSet: false,
                  };
              case LOAD_USERS:
                  return {
                      ...state,
                      memberList: action.users,
                      memberAll: action.users,
                  };
              case 'CHECK_MEMBER_THIS':
                  let memberCheck                     = [...state.memberList];
                  memberCheck[action.index].isChecked = !memberCheck[action.index].isChecked;
                  const memberAllNew                  = state.memberAll.map(member => {
                      if (member.id === memberCheck[action.index].id && member.name === memberCheck[action.index].name) {
                          return {
                              ...member, isChecked:
                              memberCheck[action.index].isChecked
                          }
                      }
                      return {...member}
                  });
                  return {
                      ...state,
                      memberList: memberCheck,
                      memberAll: memberAllNew,
                      statusCheck: _.findIndex(memberCheck, ['isChecked', false]) === -1
                  };
              case 'GET_LIST_MEMBER_IS_CHECKED':
                  const memberList = [...state.memberAll];
                  return {
                      ...state,
                      membersChoice: memberList.filter(member => member.isChecked),
                      memberSetInTeam: memberList.filter(member => member.isChecked),
                  };
              case 'SEARCH_MEMBER_ADD_TEAM':
                  const memberAll = [...state.memberAll];

                  const memberListSearch = memberAll.filter(member => member.name.toLowerCase().search(action.name.toLowerCase()) !== -1);
                  return {
                      ...state,
                      memberList: memberListSearch,
                      statusCheck: _.findIndex(memberListSearch, ['isChecked', false]) === -1
                  };
              case 'CHECK_ALL_MEMBER':
                  return {
                      ...state,
                      memberList: [...state.memberList].map(member => {
                          const indexChecked            = _.findIndex([...state.memberAll], member);
                          state.memberAll[indexChecked] = {
                              ...state.memberAll[indexChecked],
                              isChecked: true
                          };

                          return {
                              ...member,
                              isChecked: true,
                          }
                      }),
                      statusCheck: true
                  };
              case 'REMOVE_CHECK_ALL_MEMBER':
                  return {
                      ...state,
                      memberList: [...state.memberList].map(member => {
                          const indexChecked            = _.findIndex([...state.memberAll], member);
                          state.memberAll[indexChecked] = {
                              ...state.memberAll[indexChecked],
                              isChecked: false
                          };

                          return {
                              ...member,
                              isChecked: false,

                          }
                      }),
                      statusCheck: false
                  };
              case 'GET_DETAIL_TEAM':
                  return {
                      ...state,
                      membersChoice: action.team.members
                  };
              case 'CHECK_IS_CHECKED':

                  const newMember = [...state.memberAll].map(member => {
                      return {
                          ...member,
                          isChecked: false
                      }
                  });

                  const newMemberChoice = state.membersChoice.map((member) => {
                      const indexMember = _.findIndex(newMember, ['id', member.id, 'username', member.username]);
                      return newMember[indexMember] = {
                          ...state.memberAll[indexMember],
                          isChecked: true,
                          _pivot_role: member._pivot_role
                      }
                  });
                  return {
                      ...state,
                      membersChoice: newMemberChoice,
                      memberList: newMember,
                      memberAll: newMember,
                      statusCheck: _.findIndex(newMember, ['isChecked', false]) === -1,
                  };
              case 'CALL_CREATE_TEAM':
                  return {
                      ...state,
                      membersChoice: []
                  };
              case 'GET_LIST_MEMBER_CHOICE_THIS_REMOVE':
                  const listMemberThisRemove    = [...state.membersChoice];
                  const newListMemberThisRemove = listMemberThisRemove.map(member => {
                      return {
                          ...member,
                          isChecked: false
                      }
                  });
                  return {
                      ...state,
                      listMemberThisRemove: newListMemberThisRemove,
                      membersChoice: newListMemberThisRemove
                  };
              case 'MEMBER_CHOICE_REMOVE':
                  let memberRemove = [...state.membersChoice];
                  return {
                      ...state,
                      listMemberThisRemove: memberRemove.filter(item => !item.isChecked),
                      membersChoice: memberRemove.filter(item => !item.isChecked),
                      memberList: memberRemove.filter(item => !item.isChecked),
                      listMemberSetRole: memberRemove.filter(item => !item.isChecked)
                  };
              case 'CHECK_MEMBER_THIS_REMOVE':
                  let memberCheckRemove                     = [...state.listMemberThisRemove];
                  memberCheckRemove[action.index].isChecked = !memberCheckRemove[action.index].isChecked;
                  const memberCheckRemoveAll                = memberCheckRemove.map(member => {
                      if (member.id === memberCheckRemove[action.index].id && member.name === memberCheckRemove[action.index].name) {
                          return {
                              ...member, isChecked:
                              memberCheckRemove[action.index].isChecked
                          }
                      }
                      return {...member}
                  });
                  return {
                      ...state,
                      listMemberThisRemove: memberCheckRemove,
                      listMemberThisRemoveAll: memberCheckRemoveAll,
                      statusDell: _.findIndex(memberCheckRemove, ['isChecked', false]) === -1
                  };
              case 'CHECK_ALL_MEMBER_REMOVE':
                  return {
                      ...state,
                      listMemberThisRemove: [...state.listMemberThisRemove].map(member => {
                          const indexCheckedRemove                = _.findIndex([...state.membersChoice], member);
                          state.membersChoice[indexCheckedRemove] = {
                              ...state.membersChoice[indexCheckedRemove],
                              isChecked: true
                          };
                          return {
                              ...member,
                              isChecked: true,
                          }
                      }),
                      statusDell: true
                  };
              case 'REMOVE_CHECK_ALL_MEMBER_REMOVE':
                  return {
                      ...state,
                      listMemberThisRemove: [...state.listMemberThisRemove].map(member => {
                          const indexCheckedRemove                = _.findIndex([...state.membersChoice], member);
                          state.membersChoice[indexCheckedRemove] = {
                              ...state.membersChoice[indexCheckedRemove],
                              isChecked: false
                          };
                          return {
                              ...member,
                              isChecked: false,

                          }
                      }),
                      statusDell: false
                  };
              case 'SEARCH_MEMBER_REMOVE_TEAM':
                  const membersChoiceNew       = state.membersChoice;
                  const memberListSearchRemove = membersChoiceNew.filter(member => member.name.toLowerCase().search(action.name.toLowerCase()) !== -1);
                  return {
                      ...state,
                      listMemberThisRemove: memberListSearchRemove,
                      statusDell: _.findIndex(memberListSearchRemove, ['isChecked', false]) === -1
                  };

              case 'GET_LIST_MEMBER_SET_ROLE':
                  const listMemberSetRole    = [...state.membersChoice];
                  const newListMemberSetRole = listMemberSetRole.map(member => {
                      return {
                          ...member,
                          _pivot_role: member._pivot_role
                      }
                  });
                  return {
                      ...state,
                      listAllMemberSetRole: newListMemberSetRole,
                      listMemberSetRole: newListMemberSetRole
                  };
              case 'SEARCH_MEMBER_ROLE':
                  const listSearchSetRole = [...state.listAllMemberSetRole].filter(member => member.name.toLowerCase().search(action.name.toLowerCase()) !== -1);
                  return {
                      ...state,
                      listMemberSetRole: listSearchSetRole,
                      statusSet: _.findIndex(listSearchSetRole, ['_pivot_role', 'staff']) === -1
                  };
              case 'CHECK_MEMBER_SET_ROLE':
                  let memberCheckSetRole           = [...state.listMemberSetRole];
                  memberCheckSetRole[action.index] = {
                      ...memberCheckSetRole[action.index],
                      _pivot_role: memberCheckSetRole[action.index]._pivot_role === 'leader' ? 'staff' : 'leader',
                  };
                  const memberCheckSetRoleAll      = state.listAllMemberSetRole.map(member => {
                      if (member.id === memberCheckSetRole[action.index].id && member.name === memberCheckSetRole[action.index].name) {
                          return {
                              ...member,
                              _pivot_role: memberCheckSetRole[action.index]._pivot_role
                          }
                      }
                      return {
                          ...member,
                      }
                  });
                  return {
                      ...state,
                      listMemberSetRole: memberCheckSetRole,
                      listAllMemberSetRole: memberCheckSetRoleAll,
                      statusSet: _.findIndex(memberCheckSetRole, ['_pivot_role', !state.statusSet ? 'staff' : 'leader']) === -1
                  };
              case 'CHECK_ALL_MEMBER_SET_ROLE':
                  //state.statusSet === true then member is role staff
                  const newListMemberSetRoleCheckAll = state.listMemberSetRole.map(member => {
                      return {
                          ...member,
                          _pivot_role: state.statusSet ? 'staff' : 'leader'
                      }
                  });
                  newListMemberSetRoleCheckAll.forEach(member => {
                      const indexMember                       = _.findIndex(state.membersChoice, ['id', member.id]);
                      state.listAllMemberSetRole[indexMember] = {
                          ...state.listAllMemberSetRole[indexMember],
                          _pivot_role: member._pivot_role
                      }
                  });
                  return {
                      ...state,
                      listMemberSetRole: newListMemberSetRoleCheckAll,
                      statusSet: !state.statusSet
                  };
              case 'APPLY_SET_ROLE':
                  const applyAddRole = [...state.listAllMemberSetRole];
                  applyAddRole.forEach(member => {
                      const indexMemberSetRole            = _.findIndex(state.memberAll, ['id', member.id])
                      state.memberAll[indexMemberSetRole] = {
                          ...state.memberAll[indexMemberSetRole],
                          _pivot_role: member._pivot_role
                      }
                  });
                  return {
                      ...state,
                      membersChoice: applyAddRole,
                      listMemberThisRemove: applyAddRole,
                      memberList: applyAddRole,
                      listMemberSetRole: applyAddRole,
                      listAllMemberSetRole: applyAddRole,
                  };
              default:
                  return state
          }

      }
;

export default EditorTeam;