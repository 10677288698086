import {deletedList} from "../services";


export const DeletedCustomerMiddleware = store => next => action => {
    const conditionInDeletedCustomer = store.getState().DeletedCustomer;

    if(action.nameScreen !== 'customers') {
        return next(action);
    }
    switch (action.type) {
        case 'CHANGE_SCREEN':
            let condition = {
                page   : 1,
                orderBy: {
                    field: 'delete_at',
                    type : 'DESC'
                },
                keyword: '',
            };
            return deletedList.getCustomersByCondition(condition).then(res => {
                store.dispatch({
                    ...action,
                    ...res.data,
                    type: 'SET_CUSTOMERS_API'
                });
                next({
                    ...action,
                    ...res.data
                });
            });
        case 'CHANGE_SEARCH_KEYWORD':
            return next({
                ...action,
                type: 'CHANGE_KEY_WORD_CUSTOMER'
            });
        case 'SUBMIT_SEARCH_KEYWORD':
            return  deletedList.getCustomersByCondition({
                page    : 1,
                orderBy : conditionInDeletedCustomer.useOrderBy,
                keyword : conditionInDeletedCustomer.keyword,
            }).then(res => {
                store.dispatch({
                    ...action,
                    ...res.data,
                    hasNext : res.data.hasNext,
                    type: 'SET_CUSTOMERS_API'
                });
                next({
                    ...action,
                    ...res.data,
                    type: 'SUBMIT_SEARCH_KEYWORD_CUSTOMERS'
                });
            });
        case 'CHANGE_ORDER_BY':
            return deletedList.getCustomersByCondition({
            keyword : conditionInDeletedCustomer.keywordSubmit,
            page : 1,
            orderBy: {
                field: action.field,
                type : action.typeOrder
            }
        }).then(res => {
            store.dispatch({
                ...res.data,
                hasNext: res.data.hasNext,
                type   : 'SET_CUSTOMERS_API',
            });
            next({
                ...res.data,
                ...action,
                type: 'CHANGE_ORDER_BY_CUSTOMER'
            })
        });
        case 'CHANGE_PAGE_NUMBER':
            return  deletedList.getCustomersByCondition({
                orderBy : conditionInDeletedCustomer.useOrderBy,
                keyword : conditionInDeletedCustomer.keywordSubmit,
                page    : action.numberPage,
            }).then(res => {
                store.dispatch({
                    ...res.data,
                    type  : 'SET_CUSTOMERS_API',
                });
                next({
                    ...res.data,
                    ...action,
                })
            });

        default :
            return next(action);
    }
};
