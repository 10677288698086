import {LOAD_LOG, LOAD_LOG_PAGE, RESET_LOGS, SEARCH_LOGS} from "../actions/Log";

const defaultApplicationSate = {
    profile: JSON.parse(localStorage.getItem('profile')),
    logs: [],
    totalPages: 1,
    currentPage: 1,
    defaultLogs: [],
    hasNext: true
};

const KirokuAILog = (state = defaultApplicationSate, action) => {
    switch (action.type) {
        case LOAD_LOG:
            return {
                ...state,
                logs: action.logs,
            };
        case SEARCH_LOGS:
            return {
                ...state,
                logs: action.logs,
                totalPages: action.totalPages,
                currentPage: action.currentPage,
                hasNext: action.hasNext
            };
        case LOAD_LOG_PAGE:
            return {
                ...state,
                logs: action.page === 1 ? action.logs : state.logs.concat(action.logs),
                totalPages: action.totalPages,
                currentPage: action.currentPage,
                hasNext: action.hasNext
            };
        case RESET_LOGS :
            return {
                ...state,
                logs: [],
            };
        default:
            return state
    }
};

export default KirokuAILog;
