import {Component} from "react";
import React from "react";


export default class KirokuCardAction extends Component {
    render() {

        return (
            <div className="dropdown">
                {this.props.children}
            </div>
        )
    }
}
