import React, {Component}        from 'react';
import {withNamespaces}          from 'react-i18next';
import {checkUseHeadingQuestion} from "../../Surveys/SurveyEditor/SurveyService";

@withNamespaces()
class Message extends Component {

    componentDidMount() {
        this.props.onAnswer({answer: 'message', validated: {}})
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.question !== nextProps.question) {
            nextProps.onAnswer({
                answer: 'message',
                validated: {}
            })
        }
    }

    render() {
        const {
            question,
            t,
        } = this.props;
        const contentQuestion = checkUseHeadingQuestion(question);
        return (
            <div id='component-question'>
                <div className='content-question'>
                    <div className='wraper-question'>
                        <div className='wraper-question'>
                            <div className="background-question">
                                <div className='number-question '>
                                    <span className="question-number">
                                        {/*{t('createRecord.Question')}{index}:*/}
                                    </span>
                                </div>
                                <span className='content-name-question'>
                                    {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Message;
