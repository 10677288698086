import React, {Component}        from 'react';
import KirokuRadio               from '../../../ui/KirokuRadio';
import {withNamespaces}          from 'react-i18next';
import {checkUseHeadingQuestion} from "../../Surveys/SurveyEditor/SurveyService";
import KirokuButtonLink          from '../../../ui/KirokuButtonLink';
@withNamespaces()
class SingleChoice extends Component {

    state = {
        answers: this.props.value
    };

    componentDidMount() {
        this.props.onAnswer({
            answer   : this.props.value,
            validated: this.props.value.answer ? this.props.value : null
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                answers  : nextProps.value,
                validated: nextProps.value.answer ? nextProps.value : null
            });
            nextProps.onAnswer({
                answer   : nextProps.value,
                validated: nextProps.value.answer ? nextProps.value : null
            })
        }
    }

    onChangeItem(index) {
        const {question} = this.props;
        const answer     = question.choices[index];
        this.setState({
            answers: {...answer, is_choice: index}
        });
        this.props.onAnswer({
            answer   : {...answer, is_choice: index},
            validated: index <= question.choices.length ? {...answer, is_choice: index} : null
        })
    }

    handleResetAnswer() {
        this.setState({
            answers   : {},
            validated : false,
        });

        this.props.onAnswer({
            answer : {},
            validated: null,
        });
    }

    render() {
        const {
            question, t,
            // index
        } = this.props;
        const {answers}            = this.state;
        const contentQuestion = checkUseHeadingQuestion(question);
        return (
            <div id='component-question'>
                <div className='content-question'>
                    <div className='wraper-question'>
                        <div className="background-question">
                            <div className='number-question d-flex justify-content-between'>
                                <span
                                    className="question-number">
                                    {question.unrequited ? t('createRecord.option'):''}{contentQuestion}
                                </span>
                                <div/>
                                <div>
                                    <KirokuButtonLink
                                        className='padding-reset-button-link'
                                        onClick={() => {this.handleResetAnswer()}}
                                    >
                                        {t('createRecord.resetAnswer')}
                                    </KirokuButtonLink>
                                </div>
                            </div>
                        </div>
                        <div className='answer-question'>
                            <span className='content-name-question padding-question-content '>
                                {question.content}
                            </span>
                            {question.choices.map((choice, index) =>
                                <KirokuRadio
                                    key={index}
                                    id={`${question.index}-${index}`}
                                    name={`${question.index}SingleChoice`}
                                    checked={
                                        answers.is_choice === index
                                    }
                                    onChange={this.onChangeItem.bind(this, index)}
                                >
                                    {choice.answer}

                                </KirokuRadio>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SingleChoice;
