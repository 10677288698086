import lodash            from 'lodash';
import {factoryQuestion} from "../ClassTypeQuestions/facetoryQuestion";

class CreatorLogic {

    constructor(questions, answeredOrder = []) {
        this.questions = questions.map(questionData => factoryQuestion(questionData));
        if (!answeredOrder.length) {
            this.flow = [this.questions[0]];
        } else {
            this.flow = answeredOrder.map(questionData => factoryQuestion(questionData));
        }

        this.finish = this.questions.length === 1;

        if (answeredOrder.length > 0) {
            const indexQuestionEndDraft = answeredOrder[answeredOrder.length - 1].index;
            this.finish = !questions[indexQuestionEndDraft + 1]
        }

    }

    current() {
        return this.flow[this.flow.length - 1];
    }

    getEndQuestion() {
        return this.finish;
    }

    next(answer) {
        const currentQuestion = this.current();
        const nextQuestionIndex = currentQuestion.answer(answer);
        this.finish = !this.questions[nextQuestionIndex + 1];
        if (!this.questions[nextQuestionIndex]) {
            return null;
        }

        const willBeNextQuestion = this.questions[nextQuestionIndex];
        this.flow.push(willBeNextQuestion);
        return willBeNextQuestion;
    }

    actionClearQuestion = {
        InputText  : () => {},
        YesNo      : (tobeUpdatedQuestionOrder) => {
            this.finish = false;
            return this.flow = lodash.take(this.flow, tobeUpdatedQuestionOrder + 1)
        },
        Message    : () => {},
        MultiChoice: () => {},
        Date       : () => {},
        OnlyDate   : () => {},
        Time       : () => {},
        Number     : () => {},
        Single     : (tobeUpdatedQuestionOrder) => {
            this.finish = false;
            return this.flow = lodash.take(this.flow, tobeUpdatedQuestionOrder + 1)
        },
        Media      : () => {}
    };

    findQuestion(flow, question) {
        for (let index = 0; index < flow.length; index++) {
            if (flow[index].getIndex() === question.getIndex()) {
                return index;
            }
        }

        return -1;
    }

    setAnswerQuestionFlow(flow, question, answer) {
        for (let index = 0; index < flow.length; index++) {
            if (flow[index].getIndex() === question.getIndex()) {
                this.flow[index].answer(answer);
                return index;
            }
        }
        return -1;
    }

    update(questionIndex, answer) {
        const tobeUpdatedQuestion = this.questions[questionIndex];
        const tobeUpdatedQuestionOrder = this.findQuestion(this.flow, tobeUpdatedQuestion);
        this.setAnswerQuestionFlow(this.flow, tobeUpdatedQuestion, answer);
        if (tobeUpdatedQuestionOrder < 0) {
            throw new Error(`Answer list does not include question with index ${questionIndex}`);
        }
        const nextQuestionIndexAfterUpdated = tobeUpdatedQuestion.answer(answer);
        const clearQuestionAfterEditQuestionLogic = this.actionClearQuestion[tobeUpdatedQuestion.payload.type];
        clearQuestionAfterEditQuestionLogic(tobeUpdatedQuestionOrder);
        return this.questions[nextQuestionIndexAfterUpdated]
    }

    back(answer = null) {
        if (this.flow.length < 1) {
            return null;
        }
        if (answer) {
            this.current().answer(answer);
        }
        return this.flow.pop();
    }

    toRecord() {
        return this.flow.map(question => question.toJSON())
    }

    toDraft() {
        return {
            questions: this.questions.map(question => question.toJSON()),
            flow: this.flow.map(question => question.toJSON())
        }
    }

    static fromDraft(draftData) {
        return new CreatorLogic(draftData.questions, draftData.flow)
    }
}

export default CreatorLogic;
// const questions = [
//     {
//         index  : 0,
//         content: 'heading',
//         type   : 'InputText'
//     },
//     {
//         index          : 1,
//         content        : 'YesNo?',
//         type           : 'YesNo',
//         nextQuestionYes: 2,
//         nextQuestionNo : 3
//     },
//     {
//         index  : 2,
//         content: 'Yes ?',
//         type   : 'InputText'
//     },
//     {
//         index  : 3,
//         content: 'No ?',
//         type   : 'InputText'
//     },
//     {
//         index  : 4,
//         content: 'index4',
//         type   : 'InputText'
//     }
// ];
//
//
// const logic = new CreatorLogic(questions);
//
//
// console.log(logic.current());


// console.log(dataDraft[0]);
//
//
// const createLogic = CreatorLogic.fromDraft(dataDraft[0]);
// createLogic.update(1, 'No');
//

