export default class MakeTeamServices {

    makeTeamForTags(teams) {
        return teams.map(team => {
            return {
                tagId: team.id,
                ...team,
                isChecked: false,
                tagType: "team",
                tagContent: {
                    ...team,
                },
                key: `team_id${team.id}`,
                label: team.name,
                value: `${team.name}${team.id}`,
                type: 'Teams'
            }
        });
    }
}