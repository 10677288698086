import React, {Component} from 'react';
import KirokuNotification from "./ui/KirokuNotification";
import {filePathService}  from "./services";
import MainMenu           from "./components/MainMenu";
import DropDownUser       from "./components/DropDownUser";
import RouterProtected    from "./RouterProtect";
import {connect}          from "react-redux";
import {Router}           from "react-router-dom";
import config             from './config';
import historyRouter      from './historyRouter';

@connect((state) => {
    return {
        title: state.KirokuAICurrentPage.title,
        actionPage: state.KirokuAICurrentPage.actionPage,
        hiddenLayout: state.ReduceActionLayout.hiddenLayout
    }
}, () => ({}))
class LayoutMobile extends Component {
    state = {
        open: true,
        prevScrollpos : window.pageYOffset,
        visible: false
    };

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        const { prevScrollpos } = this.state;
        const currentScrollPos = window.pageYOffset;
        this.setState({
                visible : prevScrollpos < 0,
                prevScrollpos : currentScrollPos
            })
    };

    actionSlider() {
        this.setState({
            open: !this.state.open
        });
    }

    controllerActive() {
        this.setState({
            open: true
        })
    }

    render() {
        const  { visible} = this.state;
        const scrollHeader = visible ? "header-mobile opacity-header" : "header-scroll-mobile scroll-header-on-mobile";
        const showHeader = !this.state.open ? "header-mobile" : scrollHeader;
        const pathNamesDoNotUseLayout = config.routerNotUseLayout;
        return (
            <Router history={historyRouter}>
                {
                    pathNamesDoNotUseLayout.includes(historyRouter['location'].pathname) ? (<RouterProtected/>): (
                    <div>
                        <div hidden={this.props.hiddenLayout} id='main'
                             className={!this.state.open ? "opacity-mobile" : ""}>
                            <div id='overlay-main' className="overlay" onClick={this.actionSlider.bind(this)}/>
                            <div className="slider-close"/>
                            <KirokuNotification/>
                        </div>
                        <div hidden={this.props.hiddenLayout}
                             className={!this.state.open ? "sidenav open-slide" : "sidenav close-slider"}>
                            <div className="header-slidenav d-flex justify-content-between pr-3">
                                <div style={{
                                    height: 30,
                                    width: 113,
                                    background: 'no-repeat',
                                    backgroundImage: `url(${filePathService.resolveFilePath('logo-kiroku.svg')})`
                                }}/>
                                <div className='divclass'>
                                    <i className="fa fa-bars" onClick={this.actionSlider.bind(this)}/>
                                </div>
                            </div>
                            <MainMenu shouldUseTooltip={true} onChangeNavLink={this.controllerActive.bind(this)}/>
                        </div>
                        <div>
                            <div hidden={this.props.hiddenLayout}
                                 className={showHeader}>
                                <div className='header-mobile-left'>
                                    <i className="fa fa-bars back-up-bars" onClick={this.actionSlider.bind(this)}/>
                                    {this.props.title}
                                </div>
                                <div className='header-mobile-right'>
                                    <div className='pl-5'>
                                        <DropDownUser history={this.props.history}/>
                                    </div>
                                    <div className='header-notify'>
                                        <div>
                                            <span className='ant-badge'/>
                                        </div>
                                    </div>
                                    {this.props.actionPage}
                                </div>
                            </div>
                            <div className={!this.state.open ? "main-container" : "main-container main-collapse"}
                                 style={{marginLeft: `${this.props.hiddenLayout ? '0' : ''}`}}>
                                <div className={visible ? 'main-content-mobile' : "pt-0 main-content-mobile"}>
                                    <RouterProtected/>
                                </div>
                            </div>
                        </div>
                    </div>)
                }
            </Router>
        );
    }
}

export default LayoutMobile;
