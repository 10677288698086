import React, { Component } from "react";
import Select               from 'react-select';
import makeAnimated         from 'react-select/lib/animated';
import { withNamespaces }   from "react-i18next";

@withNamespaces()
class KirokuMultiSelect extends Component {
    render() {
        let {t} = this.props;
        return (
            <div id='multi-select'>
                <Select
                    isMulti
                    placeholder={t('common.Select')}
                    components={makeAnimated()}
                    {...this.props}
                    closeMenuOnSelect={false}
                    noOptionsMessage={() => t('common.noOptions')}
                />
            </div>
        )
    }
}

export default KirokuMultiSelect;