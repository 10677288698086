import React, { Component }       from 'react';
import AboutSurveyReview          from '../../../Records/ReviewRecording/AboutSurvey';
import KirokuButton               from '../../../../ui/KirokuButton';
import { withNamespaces }         from 'react-i18next';
import InputText                  from '../../../Records/ReviewRecording/QuestionTypeReview/InputText';
import Message                    from '../../../Records/ReviewRecording/QuestionTypeReview/Message';
import MultiChoice                from '../../../Records/ReviewRecording/QuestionTypeReview/MultiChoice';
import YesNo                      from '../../../Records/ReviewRecording/QuestionTypeReview/YesNo';
import DateTime                   from '../../../Records/ReviewRecording/QuestionTypeReview/DateTime';
import SingleChoice               from '../../../Records/ReviewRecording/QuestionTypeReview/SingleChoice';
import MediaFile                  from '../../../Records/ReviewRecording/QuestionTypeReview/MediaFile';
import DateQuestion               from '../../../Records/ReviewRecording/QuestionTypeReview/DateQuestion';
import TimeQuestion               from '../../../Records/ReviewRecording/QuestionTypeReview/TimeQuestion';
import NumberQuestion             from '../../../Records/ReviewRecording/QuestionTypeReview/NumberQuestion';
import { getHeadingNextQuestion } from '../../../Records/sevicesInRecord';

@withNamespaces()
class DetailSurveyMobile extends Component {

    questionsMap = {
        InputText,
        Message,
        MultiChoice,
        YesNo   : YesNo,
        Date    : DateTime,
        Single  : SingleChoice,
        Media   : MediaFile,
        OnlyDate: DateQuestion,
        Time    : TimeQuestion,
        Number  : NumberQuestion,
    };

    render() {
        const { survey, t, history } = this.props;
        return (
            <div id='mobile'>
                <div className='survey'>
                    <div className="keeping-about-survey" style={{ backgroundColor: '#FFFFFF', top: 0 }}>
                        <AboutSurveyReview survey={survey} customer={survey.customer} isMobile shouldNotShowServicedAt/>
                    </div>
                    <div className='wraper-question'>
                        <div className='wraper-question'>
                            <div className="background-question-review">
                                <div className='info-question'>
                                    <div className='content-name-question d-flex'>
                                    <span style={{ wordBreak: 'keep-all' }}
                                          className='title-record-info'>{t('createRecord.Description')}&nbsp;</span>
                                        <pre style={{
                                            wordWrap  : 'break-word',
                                            whiteSpace: 'pre-wrap',
                                        }}>
                                        {survey.description}
                                    </pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id='question-review'>
                        {survey.questions.map((question, index) => {
                            const Question = this.questionsMap[ question.type ];
                            index++;
                            return <Question
                                disabled
                                surveyDetail
                                key={index}
                                question={{ ...question, index: index }}
                                value={question.answers}
                                headerNextQuestion={getHeadingNextQuestion(question, survey.questions)}
                            />;
                        })}
                    </div>
                    <div className='text-center pt-1 pl-2 pr-2 pb-5 mb-2'>
                        <KirokuButton color={'light'} onClick={() => history.goBack()}>
                            <span className="text-tranf">{t('createRecord.back')}</span>
                        </KirokuButton>
                    </div>
                </div>
            </div>
        );
    }
}

export default DetailSurveyMobile;
