import React, {Component} from 'react';
import {
    KirokuCardWeb,
    KirokuCardTitleWeb,
    KirokuButton,
    KirokuCardBodyWeb,
    KirokuInput,
    KirokuInputArea
}                         from "../../../../ui";

import ListQuestion         from "./ListQuestion";
import {connect}            from 'react-redux';
import {
    createQuestion,
    editSurveyTemplate,
    showReview,
    throwErrorSurvey,
    throwErrorQuestion
}                           from "../../../../actions/SurveyTemplate";
// import ComponentAddQuestion from "./ComponentAddQuestion";
import {withNamespaces}     from "react-i18next";
import {hasTimer}           from "../../../../Timer";
import {saveDraft}          from "../../../../actions/DraftSurvey";
import _                    from 'lodash';
import CheckBoxTypeQuestion from './CheckBoxTypeQuestion';
// import NotificationDraftWeb from "./NotificationDraftWeb";

@hasTimer()
@withNamespaces()
@connect(state => {
    return {
        surveyTemplate: state.KirokuAISurveyTemplate
    }
}, dispatch => {
    return {
        editSurveyTemplate: surveyTemplate => dispatch(editSurveyTemplate(surveyTemplate)),
        createQuestion: question => dispatch(createQuestion(question)),
        showReview: () => dispatch(showReview()),
        throwErrorSurvey: (name, value) => dispatch(throwErrorSurvey(name, value)),
        throwErrorQuestion: (name, value) => dispatch(throwErrorQuestion(name, value)),
        saveDraft: (draft) => dispatch(saveDraft(draft))
    }
})
class Survey extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: '',
            form_number: '',
            error_title: false,
            error_description: false,
            error_form_number: false,
            choices: [{answer: ''}, {answer: ''}],
            content: "",
            heading: "",
            error_content : false,
            type: "InputText",
            showAddQuestion: false,
            erroFormNumber : false
        };
        // this.props.timer.record(() => {
        //     this.autoSaveDraft()
        // }, 10000);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.surveyTemplate) {
            this.setState({
                title: nextProps.surveyTemplate.title,
                description: nextProps.surveyTemplate.description,
                form_number: nextProps.surveyTemplate.form_number
            })
        }
    }
    handleCheckType(type, choices) {
        switch (type) {
            case 'Single':
            case 'MultiChoice':
                this.setState({
                    type: type,
                    choices: choices
                });
                break;
            default:
                this.setState({
                    type: type,
                });
                break;
        }
    }

    actionQuestion() {
        const question = this.createQuestionByType(this.state);
        this.props.createQuestion(question);
    }

    validation () {
        let {choices, type} = this.state;
        switch (type) {
            case 'Single' :
            case 'MultiChoice':
                let errorChoices = choices.every(choice => choice.answer !== '');
                return errorChoices ? this.addQuestion() :
                    this.props.throwErrorQuestion('answer', errorChoices);
            default :
                return this.addQuestion();
        }
    }

    createQuestionByType(question) {
        let {content, heading, choices, type} = question;
        let index = this.props.surveyTemplate.questions.length;
        switch (type) {
            case 'Single':
            case 'MultiChoice' :
                return {
                    content, heading, type, choices, index
                };
            default:
                return {
                    content, heading, type, index
                }
        }
    }

    addQuestion() {
        let {type} = this.state;
        switch (type) {
            case 'Single':
            case 'MultiChoice':
                this.actionQuestion();
                this.setState({
                    choices: [{answer: ''}, {answer: ''}],
                    content: '',
                    heading: '',
                });

                break;
            default:
                this.actionQuestion();
                this.setState({
                    choices: [{answer: ''}, {answer: ''}],
                    content: '',
                    heading: '',
                });

                break;
        }
    }

    validateAnswerQuestionWithSingleAndMultiChoiceQuestion() {
        const { type, choices } = this.state;
        if(type === 'Single' || type === 'MultiChoice') {
            return _.findIndex(choices, ['answer', '']) !== -1;
        }
    }

    disableAddQuestion = () => {
        const {content} = this.state;
        return this.props.surveyTemplate.errorQuestion.content || this.validateAnswerQuestionWithSingleAndMultiChoiceQuestion() || !content
    };

    onChangeHeading(e) {
        this.setState({
            heading: e.target.value
        })
    }

    onChangeContent(e) {
        this.setState({
            content: e.target.value,
            error_content : !e.target.value.length
        }, () => {
            this.actionSurveyTemplate();
        });
        this.validateInputQuestion(e);
    }

    validateInputQuestion(e) {
        this.props.throwErrorQuestion(e.target.name, !e.target.value.length)
    }

    onChangeQuestion(index, question) {
        const newsQuestions = this.state.questions;
        newsQuestions[index] = question;
        this.setState({
            questions: newsQuestions
        })
    }


    actionSurveyTemplate(surveyTemplate) {
        this.props.editSurveyTemplate(surveyTemplate)
    }

    onChangeTitle(e) {
        let title = e.target.value;
        this.setState({
            title: title,
            error_title: !title.length
        }, () => this.actionSurveyTemplate({title: title}));

    }

    onChangeFormNumber(e) {
        let form_number = e.target.value.replace(/[^0-9]/g, '');
        this.setState({
            form_number: form_number,
            error_form_number: (form_number.length !== 2 && form_number.length !== 0) || isNaN(form_number)
        }, () => this.actionSurveyTemplate({form_number: form_number}));

    }

    onChangeDescription(e) {
        let description = e.target.value;
        this.setState({
            description: description,
            error_description: !description.length
        }, () => this.actionSurveyTemplate({description: description}));

    }

    showReview = () => {
        this.props.showReview();
    };

    validateInputSurvey(e) {
        this.props.throwErrorSurvey(e.target.name, !e.target.value.length)
    }

    validateFormNumber(e) {
        let form_number = e.target.value;
        this.props.throwErrorSurvey(e.target.name, (form_number.length !== 2 && form_number.length !== 0) || isNaN(form_number));
    }

    checkValidationFormNumber = (e) => {
        const error = this.checkErrorFormNumber(e.currentTarget.value);
        this.setState({
            errorFormNumber: error
        });
    };

    checkErrorFormNumber = (number) => {
        if(number.length === 1) {
            this.setState({errorFormNumber: true});
            return 'errorFormNumber';
        }
        return '';
    };

    validateContentQuestions() {
        const {surveyTemplate} = this.props;
        return _.findIndex(surveyTemplate.questions, ['content', '']) !== -1
    }

    validateChoiceQuestion() {
        const {surveyTemplate} = this.props;
        const questions = [...surveyTemplate.questions];
        let arrayChecksAnswerQuestions = questions.map(question => {
            if(question['choices']) {
                return _.findIndex(question.choices, ['answer', '']) !== -1
            }
            return !!question['choices']
        });
        return !!(arrayChecksAnswerQuestions.find((item) => item === true))
    }

    disabledReview = () => {
        const {surveyTemplate} = this.props;
        return !surveyTemplate.title || !surveyTemplate.description || !surveyTemplate.questions.length || this.validateContentQuestions() || this.validateChoiceQuestion()
    };

    componentWillMount() {
        this.props.throwErrorSurvey('questions', !this.props.surveyTemplate.questions.length)
    }

    autoSaveDraft = () => {
        this.props.saveDraft(this.props.surveyTemplate);
    };

    // componentWillUnmount() {
    //     this.props.timer.unRegisterLastJob();
    // }

    cancel = () => {
        this.props.history.push('/surveys');
    };

    render() {
        let {t} = this.props;
        const {questions} = this.props.surveyTemplate;
        let {
            title, description,
            // form_number, errorFormNumber
        } = this.state;
        return (
            <div className='p-3 default-background-color'>
                <div>
                    {/*<NotificationDraftWeb/>*/}
                    <KirokuCardWeb>
                        <KirokuCardTitleWeb>
                            <div className='d-flex justify-content-between font-text-header-card'>
                                <h4>{t('createSurvey.Summary')}</h4>
                                <KirokuButton color='success' disabled={this.disabledReview()}
                                              onClick={this.showReview.bind(this)}>
                                    {t('createSurvey.Review')}
                                </KirokuButton>
                            </div>
                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb>
                            <div className='sumary-content'>
                                <p>{t('createSurvey.Title')}</p>
                                <KirokuInput name={'title'}
                                             value={title}
                                             onBlur={this.validateInputSurvey.bind(this)}
                                             onChange={this.onChangeTitle.bind(this)}
                                             error={this.state.error_title ? 'form-error' : ''}
                                />
                                <p hidden={!this.state.error_title} style={{
                                    color: 'red',
                                    fontSize: 12
                                }}>{t('createSurvey.TitleMustNotNull')}</p>
                                {/*<p className='pt-3'>{t('createSurvey.FormNumber')}</p>*/}
                                {/*<KirokuInput name={'form_number'}*/}
                                {/*             value={form_number}*/}
                                {/*             maxLength={2}*/}
                                {/*             onBlur={this.checkValidationFormNumber.bind(this)}*/}
                                {/*             onChange={this.onChangeFormNumber.bind(this)}*/}
                                {/*             error={errorFormNumber ? 'form-error' : ''}*/}
                                {/*/>*/}
                                {/*<p hidden={!errorFormNumber} style={{ fontSize : 12, color: "red"}}>{t(`staff.${errorFormNumber}`)}</p>*/}

                                <p className='pt-3'>{t('createSurvey.Description')}</p>
                                <KirokuInputArea name={'description'}
                                                 value={description}
                                                 onBlur={this.validateInputSurvey.bind(this)}
                                                 onChange={this.onChangeDescription.bind(this)}
                                                 error={this.state.error_description ? 'form-error' : ''}
                                />
                                <p hidden={!this.state.error_description} style={{
                                    color: 'red',
                                    fontSize: 12
                                }}>{t('createSurvey.DescriptionMustNotNull')}</p>
                            </div>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                </div>
                <ListQuestion isCreateSurvey/>
                {/*<ComponentAddQuestion/>*/}
                <div className='pt-3'>
                    <KirokuCardWeb>
                        <KirokuCardTitleWeb color='success'>
                            <h4 className='text-dark'>{t('createSurvey.CreateQuestion',{index: questions.length + 1})}</h4>
                        </KirokuCardTitleWeb>
                        <KirokuCardBodyWeb>
                            <div className='sumary-content2'>
                                <p>{t('createSurvey.Heading')}</p>
                                <KirokuInput value={this.state.heading}
                                             onChange={this.onChangeHeading.bind(this)}
                                />
                                <p className='pt-3'>{t('createSurvey.YourQuestion')}</p>
                                <KirokuInput name={'content'}
                                    // onBlur={this.validateInputQuestion.bind(this)}
                                             value={this.state.content}
                                             onChange={this.onChangeContent.bind(this)}
                                             error={this.state.error_content ? 'form-error' : ''}
                                />
                                <p hidden={!this.state.error_content} style={{
                                    color: 'red',
                                    fontSize: 12
                                }}>{t('createSurvey.QuestionMustNotNull')}</p>
                            </div>
                            <CheckBoxTypeQuestion
                                isCreateSurvey
                                number={'newQuestion'}
                                type={this.state.type} choices={this.state.choices}
                                handleCheckType={this.handleCheckType.bind(this)}/>
                            <div className='text-center pt-3'>

                            </div>
                        </KirokuCardBodyWeb>
                    </KirokuCardWeb>
                </div>

                <div className='d-flex justify-content-between pt-4'>
                    <div>
                        <KirokuButton
                            onClick={this.validation.bind(this)}
                            color='primary'
                            disabled={this.disableAddQuestion()}
                        >
                            <i className='p-1 fas fa-plus'/>
                            <span className='pl-1'>{t('createSurvey.SaveQuestion')}</span>
                        </KirokuButton>
                    </div>

                    <div className='d-flex'>
                        <KirokuButton color='white' onClick={this.cancel.bind(this)}>
                            {t('createSurvey.Cancel')}
                        </KirokuButton>
                        <div className='pr-2'/>
                        <KirokuButton disabled={this.disabledReview()} onClick={this.showReview.bind(this)}
                                      color='success'>
                            {t('createSurvey.Review')}
                        </KirokuButton>
                    </div>
                    <div/>
                </div>
            </div>
        );
    }
}

export default Survey;
