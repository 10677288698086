import { deletedList } from "../services";

function callAPIDeletedRecords(condition, store, next, action) {
    return deletedList.getRecordsByCondition(condition).then(res => {
        store.dispatch({
            ...action,
            ...res.data,
            type: 'SET_RECORDS_API'
        });
        next({
            ...action,
            ...res.data
        })
    })
}

export const DeletedRecordsMiddleware = store => next => action => {
    if(action.nameScreen !== 'records') {
        return next(action)
    }
    const conditionInDeletedRecords = store.getState().DeletedRecords;

    switch (action.type) {
        case 'CHANGE_SCREEN':
            let condition = {
                page        : 1,
                orderBy     : {
                    field: 'delete_at',
                    type : 'DESC'
                },
                title       : '',
                customerName: '',
            };
            return callAPIDeletedRecords(condition, store, next, action);
        case 'CHANGE_ORDER_BY':
            return callAPIDeletedRecords({
                page    : 1,
                orderBy : {
                    field: action.field,
                    type: action.typeOrder,
                },
                title : conditionInDeletedRecords.titleSubmit,
                customerName : conditionInDeletedRecords.customerNameSubmit,
            }, store, next, {...action, type: 'CHANGE_ORDER_BY_RECORDS'});
        case 'CHANGE_PAGE_NUMBER':
            return callAPIDeletedRecords({
                page    : action.numberPage,
                orderBy : conditionInDeletedRecords.useOrderBy,
                title : conditionInDeletedRecords.titleSubmit,
                customerName : conditionInDeletedRecords.customerNameSubmit,
            }, store, next, action);
        case 'CHANGE_SEARCH_KEYWORD':
            return next({
                ...action,
                type: 'CHANGE_SEARCH_KEYWORD_RECORDS'
            });
        case 'SUBMIT_SEARCH_KEYWORD':
             return callAPIDeletedRecords({
                page    : 1,
                orderBy : conditionInDeletedRecords.useOrderBy,
                title : conditionInDeletedRecords.title,
                customerName : conditionInDeletedRecords.customerName,
            }, store, next, {...action, type: 'SUBMIT_SEARCH_KEYWORD_RECORDS'});
        default :
            return next(action);
    }
};

