import React, {Component} from 'react';
import KirokuLabel        from "./KirokuLabel";
import {withNamespaces}   from "react-i18next";

@withNamespaces()
class KirokuCheckDraft extends Component {
    render() {
        let {t} = this.props;
        const draftable = this.props['draftable'];
        return (
            <KirokuLabel hidden={!draftable['drafted_at']} color={'warning'}>{t('Surveys.Draft')}</KirokuLabel>
        )
    }
}

export default KirokuCheckDraft